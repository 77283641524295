import React, { useEffect, useRef, useState } from "react";
import "../../../pages/People/ClientPage/Styles/ClientAssessmentNote.css";
import ReplySvg, {
  ReplyPlusSvg,
} from "../../../../Model/Assets/SVG/Common/ReplySvg";
import Tag from "./Tag";
import InnerNote from "./InnerNote";
import _ from "lodash";
// import { convertDateTimeFormat } from "../../../../Model/utils/Helper/formatTime";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  deleteGoalThunk,
  getGoalsThunk,
  updateGoalsThunk,
} from "../../../../Controller/redux/features/ClinicalFeature/clinicalSlice";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../Controller/redux/Hooks";
import {
  GreenCircleSvg,
  // PlusSvg,
} from "../../../pages/People/ClientPage/Components/UniqueClientScreen/Components/ClinicalTab/Data/SVGs";
import TrafficLights from "../../CommonModal/Components/TrafficLights";
import DeleteSVG from "../../../pages/People/ClientPage/Components/UniqueClientScreen/Components/FinanceTab/Data/DeleteSVG";
import TagsInput from "../../Tags/TagBuilder";
import { GoalsPlusSVG } from "../../CommonModal/Data/SVGs";
import { getUserNotificationStats } from "../../../../Controller/redux/features/NotificationsFeature/NotificaionsSlice";
const ExpandedNote = ({
  onClick = () => {},
  goalIndex,
  title,
  currentGoal,
  activities,
  forSticky,
  isOpen,
  isThereAnyChangesGoal,
  setisThereAnyChangesGoal = () => {},
  goalsData,
  onClickPlus = () => {},
  goalInfo = {},
  handelSaveNote = () => {},
  isGoalsModal,
  setGoalsData = () => {},
  goal,
  index,
  setshowTags = () => {},
  showTags,
  isTagsActive,
  selectedTags,
  fromAdvancedClinicalNote,
  clinicalGoalsNote,
  setClinicalGoalsNote = () => {},
  isLoggedInUserSame,
}: any) => {
  const dispatch = useAppDispatch();
  const id: any = useParams()?.id;
  const [clientId, setclientId] = useState<any>("");
  const { dynamicColor } = useAppSelector((state) => state?.common);
  const [showLights, setShowLights] = useState<boolean>(false);
  const [trafficColors, setTrafficColors] = useState<any>("");
  useEffect(() => {
    if (id) {
      let realId = window?.atob(id);
      setclientId(realId);
    }
  }, [id]);
  const [isHoveredForAdd, setIsHoveredForAdd] = React.useState(false);

  const handleMouseEnterForAdd = () => {
    setIsHoveredForAdd(true);
  };

  const handleMouseLeaveForAdd = () => {
    setIsHoveredForAdd(false);
  };
  const handleSelectTrafficLights = async (index: any, traffic_light: any) => {
    let updatedGoalsData: object[] | any;

    setGoalsData((prevGoalsData: any) => {
      updatedGoalsData = JSON.parse(JSON.stringify(prevGoalsData));
      updatedGoalsData[goalIndex].activities[index][`traffic_light`] =
        traffic_light;
      return updatedGoalsData;
    });

    const updatedCurrentGoal = {
      ...updatedGoalsData[goalIndex],
      goal_id: updatedGoalsData[goalIndex]?.id,
    };
    let res = await dispatch(updateGoalsThunk({ data: updatedCurrentGoal }));
    await dispatch(getGoalsThunk({ clientId }));
    await dispatch(getUserNotificationStats());

    // cancelAndClose()
  };

  const initialActivityData = {
    id: "",
    responsible_parties_id: [],
    associated_services_id: [],
    title: "",
    summary: "",
    start_time: "",
    end_time: "",
    service_type_id: null,
  };

  const handleAddActivity = async () => {
    try {
      const newActivity = {
        ...initialActivityData,
      };

      const updatedGoalsData = JSON.parse(JSON.stringify(goalsData));

      updatedGoalsData[goalIndex].activities.push(newActivity);

      // setGoalsData(updatedGoalsData);

      const updatedCurrentGoal = {
        ...currentGoal,
        goal_id: currentGoal.id,
      };

      // @ts-ignore
      updatedCurrentGoal.activities = updatedCurrentGoal.activities.map(
        (elem: { responsible_parties_id: string }) => {
          if (typeof elem.responsible_parties_id === "string") {
            return {
              ...elem,
              responsible_parties_id: elem.responsible_parties_id.split(","),
            };
          }
          return elem;
        }
      );

      // @ts-ignore
      updatedCurrentGoal.activities = updatedCurrentGoal.activities.map(
        (elem: { associated_services_id: string }) => {
          if (typeof elem.associated_services_id === "string") {
            return {
              ...elem,
              associated_services_id: elem.associated_services_id.split(","),
            };
          }
          return elem;
        }
      );

      updatedCurrentGoal.activities.push(newActivity);

      if (isThereAnyChangesGoal) {
        let res = await dispatch(
          updateGoalsThunk({ data: updatedCurrentGoal })
        );
        if (res?.payload?.status === "success") {
          toast.success("Activity Created Successfully !", {
            progressStyle: { backgroundColor: dynamicColor },
            icon: (
              <span
                style={{
                  marginRight: "8px",
                  borderRadius: "50%",
                  width: "7rem",
                  height: "35px",
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: dynamicColor,
                  backgroundColor: "white",
                  fontSize: "1.5rem",
                  border: `3px solid ${dynamicColor}`,
                }}
              >
                ✓
              </span>
            ),
          });
        }
        await dispatch(getGoalsThunk({ clientId }));
      }
    } catch (error) {
      // Handle any errors here
      console.error("Error:", error);
    }
    await dispatch(getGoalsThunk({ clientId }));
  };
  const handleAddUpdateNotes = () => {
    if (clinicalGoalsNote == "") {
      return;
    }
    let obj = {
      goal_id: goalInfo?.id,
      note: clinicalGoalsNote,
      note_tag: "clinical-progress",
      note_date_time: "",
      user_id: clientId,
    };
    handelSaveNote(obj);
  };

  // Debounced function (wait 300 milliseconds after the last click)
  const debouncedApiCall = _.debounce(handleAddUpdateNotes, 300);

  let tempArr = activities.map((el: { id: any }) => {
    return { id: el.id, status: false };
  });
  const [showTrafficLightsActivities, setshowTrafficLightsActivities] =
    useState(tempArr);

  useEffect(() => {
    if (activities) {
      let tempArr = activities.map((el: { id: any }) => {
        return { id: el.id, status: false };
      });
      // setshowTrafficLightsActivities(tempArr);
    }
  }, [activities]);

  // Attach the debounced function to your button click event
  useEffect(() => {
    const handleClick = () => {
      debouncedApiCall();
    };

    document?.getElementById("saveBTN")?.addEventListener("click", handleClick);

    return () => {
      document
        ?.getElementById("saveBTN")
        ?.removeEventListener("click", handleClick);
    };
  }, [debouncedApiCall]);

  const { selectedClinicalNoteInfo } = useAppSelector(
    (state) => state.clinical
  );
  const [isHovered, setIsHovered] = React.useState(false);

  useEffect(() => {
    if (selectedClinicalNoteInfo?.id) {
      setClinicalGoalsNote(selectedClinicalNoteInfo?.note);
    }
  }, [selectedClinicalNoteInfo]);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const [showTagsModal, setshowTagsModal] = useState(false);

  const handleDeleteGoal = async () => {
    const res = await dispatch(
      deleteGoalThunk({ clientId, goalId: goalInfo?.id })
    );
    if (res.payload.status === "success") {
      toast.success("Goal Deleted Successfully!", {
        progressStyle: { backgroundColor: dynamicColor },
        icon: (
          <span
            style={{
              marginRight: "8px",
              borderRadius: "50%",
              width: "7rem",
              height: "35px",
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: dynamicColor,
              backgroundColor: "white",
              fontSize: "1.5rem",
              border: `3px solid ${dynamicColor}`,
            }}
          >
            ✓
          </span>
        ),
      });
    }
    await dispatch(getGoalsThunk({ clientId }));
  };

  let textareaTitleRef: any = useRef(null);

  useEffect(() => {
    //@ts-ignore
    if (textareaTitleRef?.current) {
      //@ts-ignore
      textareaTitleRef.current.style.height = "auto";
      // //@ts-ignore
      textareaTitleRef.current.style.height =
        //   //@ts-ignore
        textareaTitleRef.current.scrollHeight + "px";
    }
  }, [textareaTitleRef]);
  // let trafficLightActivitiesArr = goal?.activities?.map(
  //   (res: any) => res?.traffic_light
  // );
  // console.log(goal,'trafficLightActivitiesArr')
  useEffect(() => {
    if (activities) {
      let trafficLightActivitiesArr = activities?.map(
        (res: any) => res?.traffic_light
      );

      if (trafficLightActivitiesArr.includes(0)) {
        setTrafficColors("#ef3a34");
      } else if (trafficLightActivitiesArr.includes(1)) {
        setTrafficColors("#fbbf14");
      } else if (trafficLightActivitiesArr.includes(2)) {
        setTrafficColors("#2ad400");
      } else {
        setTrafficColors("#ef3a34");
      }
    }
  }, [activities]);

  return (
    <div
      onClick={onClick}
      style={{
        position: "relative",
      }}
      className={`${
        forSticky
          ? "expanded_note_container_sticky"
          : isGoalsModal
          ? "goals_modal"
          : "expanded_note_container"
      } `}
    >
      {!isGoalsModal && showTagsModal && (
        <div>
          <TagsInput
            isClinicalProgress={true}
            selectedTags={selectedTags}
            allTags={[]}
            onSelectTagIdChange={() => {}}
            selectedTask={true}
          />
        </div>
      )}

      <div className="expanded_note_inner">
        <div
          className={`expanded_left_flex
        `}
        >
          <div>
            <span
              className="traffic_light_trigger_goals"
              style={{
                cursor: "default",
              }}
              // onClick={() => setShowLights(true)}
            >
              <GreenCircleSvg color={trafficColors} size={24} />
            </span>
            {showLights && (
              <TrafficLights
                className={"client_taffic_light_big"}
                type="goals"
                setShowLights={setShowLights}
                handleTrafficColors={(data: any) => {
                  setTrafficColors(data);
                  setShowLights(false);
                }}
                isSmallLight={false}
              />
            )}
          </div>
          <div className="clinical_flex">
            <ReplyPlusSvg isPlus={isOpen} onClick={onClickPlus} />

            <span className="expanded_goal">GOAL</span>
          </div>
          <div className="expanded_flex">
            {isGoalsModal ? (
              <textarea
                placeholder="Enter New Goal..."
                className="goal_text_area expanded_title expanded_title_textarea"
                onFocus={(e) => {
                  e.stopPropagation();
                }}
                //@ts-ignore
                ref={textareaTitleRef}
                onChange={(e) => {
                  setisThereAnyChangesGoal(true);
                  setGoalsData((prevData: any) =>
                    prevData.map((prevGoal: any, i: number) =>
                      i === index
                        ? {
                            ...prevGoal,
                            title: e.target.value,
                            goal_id: prevGoal.id,
                          }
                        : prevGoal
                    )
                  );
                }}
                value={goal.title}
                autoFocus={true}
                rows={goal.title !== "" ? goal.title?.split(`\n`)?.length : 1}
                onBlur={async () => {
                  if (!isThereAnyChangesGoal) {
                    return;
                  }

                  const tempGoal = { ...goal };
                  delete tempGoal.created_at;
                  delete tempGoal.updated_at;
                  delete tempGoal.deleted_at;
                  tempGoal.goal_id = tempGoal.id;
                  delete tempGoal.id;
                  delete tempGoal.tenant_id;
                  tempGoal.activities = tempGoal.activities.map((elem: any) => {
                    if (typeof elem.responsible_parties_id === "string") {
                      return {
                        ...elem,
                        responsible_parties_id:
                          elem.responsible_parties_id.split(","),
                      };
                    }
                    return elem;
                  });

                  // @ts-ignore
                  tempGoal.activities = tempGoal.activities.map((elem: any) => {
                    if (typeof elem.associated_services_id === "string") {
                      return {
                        ...elem,
                        associated_services_id:
                          elem.associated_services_id.split(","),
                      };
                    }
                    return elem;
                  });

                  let res = await dispatch(
                    updateGoalsThunk({ data: tempGoal })
                  );

                  if (res?.payload?.status === "success") {
                    toast.success("Goal updated successfully!", {
                      progressStyle: { backgroundColor: dynamicColor },
                      icon: (
                        <span
                          style={{
                            marginRight: "8px",
                            borderRadius: "50%",
                            width: "7rem",
                            height: "35px",
                            textAlign: "center",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            color: dynamicColor,
                            backgroundColor: "white",
                            fontSize: "1.5rem",
                            border: `3px solid ${dynamicColor}`,
                          }}
                        >
                          ✓
                        </span>
                      ),
                    });
                  } else {
                    toast.error("Failed To Update!");
                  }

                  await dispatch(getGoalsThunk({ clientId }));
                  setisThereAnyChangesGoal(false);
                }}
              />
            ) : (
              <h4
                className={
                  forSticky ? "expanded_title_sticky" : "expanded_title"
                }
              >
                {title}
              </h4>
            )}
          </div>
        </div>
        <div className="expanded_right_flex">
          {/* <ClientStatusCircleSvg fill="#F3F3F3" count={0} /> */}
          {isGoalsModal && (
            <div
              onMouseEnter={handleMouseEnterForAdd}
              onMouseLeave={handleMouseLeaveForAdd}
            >
              <GoalsPlusSVG
                isHovered={isHoveredForAdd}
                onClick={handleAddActivity}
                width={12}
                height={12}
              />
            </div>
          )}
          <div
            // className=""
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            {isGoalsModal && (
              <>
                <DeleteSVG
                  className="delete_daybook_icon"
                  onClick={handleDeleteGoal}
                  fill={isHovered ? "black" : "#f3f3f3"}
                />
              </>
            )}
          </div>
          <div
            onClick={() => {
              if (showTags) {
                let allTags = [...showTags];
                allTags[index] = !allTags[index];
                setshowTags(allTags);
              }
            }}
          >
            {true && (
              <div onClick={() => setshowTagsModal(!showTagsModal)}>
                <Tag
                  isTagsActive={isTagsActive || showTagsModal}
                  count={selectedTags?.length}
                />
                {/* {console.log({ goal })} */}
              </div>
            )}
          </div>
        </div>
      </div>
      <div
        className={`inner_note_scroll
    ${isGoalsModal ? "goals_modal" : ""}
      `}
      >
        {/* <InnerNote /> */}
        {activities?.map(
          (elem: any, index: number) =>
            !isGoalsModal && (
              <div style={{
                cursor:"default"
              }}>
                <InnerNote
                  forSticky={forSticky}
                  showTrafficLightsActivities={showTrafficLightsActivities}
                  setshowTrafficLightsActivities={
                    setshowTrafficLightsActivities
                  }
                  key={index}
                  isLoggedInUserSame={isLoggedInUserSame}
                  fromAdvancedClinicalNote={fromAdvancedClinicalNote}
                  title={elem.title}
                  summary={elem?.summary}
                  tags={elem?.responsible_parties}
                  services={elem?.associated_services}
                  time={elem?.created_at}
                  activity={elem}
                  trafficColorsStatus={
                    elem.traffic_light == 0
                      ? "#ef3a34"
                      : elem.traffic_light == 1
                      ? "#fbbf14"
                      : elem.traffic_light == 2
                      ? "#2ad400"
                      : ""
                  }
                  onChangeTrafficLight={(res: any) => {
                    handleSelectTrafficLights(
                      index,
                      res == "#ef3a34"
                        ? 0
                        : res == "#fbbf14"
                        ? 1
                        : res == "#2ad400"
                        ? 2
                        : 1
                    );
                  }}
                />
              </div>
            )
        )}
      </div>

      {!isGoalsModal && !fromAdvancedClinicalNote && (
        <>
          <div className="clinical_btn_container">
            <button className="clinical_progress_save_btn" id="saveBTN">
              {selectedClinicalNoteInfo?.id ? "Update" : "Save"}
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default ExpandedNote;
