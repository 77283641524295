// @ts-nocheck
import "../Styles/ActivityDialog.css";

import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
// import ReactDatePicker from "react-datepicker";
// import DeleteSVG from "../../../pages/People/ClientPage/Components/UniqueClientScreen/Components/FinanceTab/Data/DeleteSVG";
// import DeleteActivity from "./DeleteActivity";
// import { ReplyIcon, ServiceReply } from "../Data/SVGs";
// import ClientImg from "../../../../Model/Assets/png/client.png";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../Controller/redux/Hooks";
import {
  deleteActivityThunk,
  getGoalsThunk,
  setcurrentGoal,
  updateGoalsThunk,
} from "../../../../Controller/redux/features/ClinicalFeature/clinicalSlice";
import { getClientsThunk } from "../../../../Controller/redux/features/ClientFeature/clientsSlice";
import { useParams } from "react-router-dom";
import { getDateForIntervals } from "../../../../Model/utils/Helper/intervalManupulater";
import { formatDateStatusApi } from "../../../../Model/utils/Helper/formatDate";
// import ReplySvg, {
//   ReplyPlusSvg,
// } from "../../../../Model/Assets/SVG/Common/ReplySvg";
// import { SearchIcon } from "@chakra-ui/icons";
// import { NormalCapsule } from "../../ClinicalProgress/Components/Capsule";
import InnerNote from "../../ClinicalProgress/Components/InnerNote";
import { getStaffThunk } from "../../../../Controller/redux/features/StaffFeature/staffSlice";
import { getProspectsThunk } from "../../../../Controller/redux/features/ProspectsFeature/prospectsSlice";
import { getContactsThunk } from "../../../../Controller/redux/features/ContactsFeature/contactsSlice";
import { getTeamsThunk } from "../../../../Controller/redux/features/TeamsFeature/teamsSlice";
import { getBusinessThunk } from "../../../../Controller/redux/features/BusinessFeature/businessSlice";
import { getLocationUserThunk } from "../../../../Controller/redux/features/LocationFeature/locationSlice";
// import CustomDatePicker from "../../DatePicker/CustomDatePicker";
// import { TempArr } from "../../../pages/Roster/Data/TempArr";
import { getServiceTypeByFilterThunk } from "../../../../Controller/redux/features/RefDataFeature/ServiceTypeFeature/serviceTypeSlice";
import ActivityBox from "./ActivityBox";
// import {PlusSVG} from "../../../pages/Roster/Data/PlusSVG";
// import {PlusSvg} from "../../../pages/People/ClientPage/Components/UniqueClientScreen/Components/ClinicalTab/Data/SVGs";
// import NormalPlusSvg from "../../../../Model/Assets/SVG/Common/NormalPlusSvg";

const ActivityDialog = ({
  goalIndex,
  goalsData,
  setGoalsData,
  currentGoal,
  setisThereAnyChangesGoal = () => {},
}: // randy,
// setRandy,
// handleUpdateGoal,
any) => {
  const dispatch = useAppDispatch();
  const clientsData = useAppSelector((state) => state?.people?.allClients);
  ////////////////////////////////// CLIENT ID ////////////////////////////////////
  const id: any = useParams()?.id;
  const clientId = window.atob(id);
  /////////////////////////////////////////////////////////////////////////////////
  const [searchText, setSearchText] = useState<string>("");
  const initialActivityData = {
    id: "",
    responsible_parties_id: [],
    associated_services_id: [],
    title: "",
    summary: "",
    start_time: "",
    end_time: "",
    service_type_id: null,
  };

  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const datepickerRef = useRef<any>(null);
  const pickerRef = useRef<any>(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [isCustom, setIsCustom] = useState(false);
  const [dateWithPeriodPicker, setdateWithPeriodPicker] = useState<any>({
    startDate: null,
    endDate: null,
    sMonth: "",
    sYear: "",
    eMonth: "",
    eYear: "",
  });
  const [selectedInterval, setSelectedInterval] = useState<any>("");
  const currentDate = new Date();

  const dynamicColor = useAppSelector((state) => state?.common?.dynamicColor);
  const { allServiceTypeRecord } = useAppSelector((state) => state?.common);

  const [ServiceTypeArr, setServiceTypeArr] = useState<any[]>([]);

  useEffect(() => {
    if (allServiceTypeRecord?.data) {
      setServiceTypeArr(allServiceTypeRecord?.data);
    }
  }, [allServiceTypeRecord]);

  const handleAddActivity = async () => {
    try {
      const newActivity = {
        ...initialActivityData,
      };

      const updatedGoalsData = JSON.parse(JSON.stringify(goalsData));

      updatedGoalsData[goalIndex].activities.push(newActivity);

      // setGoalsData(updatedGoalsData);

      const updatedCurrentGoal = {
        ...currentGoal,
        goal_id: currentGoal.id,
      };

      // @ts-ignore
      updatedCurrentGoal.activities = updatedCurrentGoal.activities.map(
        (elem: { responsible_parties_id: string }) => {
          if (typeof elem.responsible_parties_id === "string") {
            return {
              ...elem,
              responsible_parties_id: elem.responsible_parties_id.split(","),
            };
          }
          return elem;
        }
      );

      // @ts-ignore
      updatedCurrentGoal.activities = updatedCurrentGoal.activities.map(
        (elem: { service_type_id: string }) => {
          if (typeof elem.service_type_id === "string") {
            return {
              ...elem,
              service_type_id: elem.service_type_id.split(","),
            };
          }
          return elem;
        }
      );

      updatedCurrentGoal.activities.push(newActivity);

      let res = await dispatch(updateGoalsThunk({ data: updatedCurrentGoal }));
      if (res?.payload?.status === "success") {
        toast.success("Activity Created Successfully !", {
          progressStyle: { backgroundColor: dynamicColor },
          icon: (
            <span
              style={{
                marginRight: "8px",
                borderRadius: "50%",
                width: "7rem",
                height: "35px",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: dynamicColor,
                backgroundColor: "white",
                fontSize: "1.5rem",
                border: `3px solid ${dynamicColor}`,
              }}
            >
              ✓
            </span>
          ),
        });
      }
      await dispatch(getGoalsThunk({ clientId }));
    } catch (error) {
      // Handle any errors here
      console.error("Error:", error);
    }
    await dispatch(getGoalsThunk({ clientId }));
  };

  const [showModal, setShowModal] = useState(false);

  const handleActivityDataChange = (
    activityIndex: number,
    field: string,
    value: string
  ) => {
    // setchangeInActivity(true);
    setisThereAnyChangesGoal(true);
    setGoalsData((prevGoalsData: any) => {
      const updatedGoalsData = [...prevGoalsData];
      const updatedGoal = { ...updatedGoalsData[goalIndex] };
      const updatedActivities = [...updatedGoal.activities];
      updatedActivities[activityIndex] = {
        ...updatedActivities[activityIndex],
        [field]: value,
      };
      updatedGoal.activities = updatedActivities;
      updatedGoalsData[goalIndex] = updatedGoal;
      return updatedGoalsData;
    });
  };

  const [focusPeople, setFocusPeople] = useState(false);
  const [focusResponsibleParties, setFocusResponsibleParties] = useState(false);

  const [responsibleParties, setResponsibleParties] = useState<any[]>([]);

  // @ts-ignore
  const dynamicClientId = parseInt(localStorage.getItem("dynamicClientId"));
  // @ts-ignore
  const dynamicStaffId = parseInt(localStorage.getItem("dynamicStaffId"));
  // @ts-ignore
  const dynamicProspectId = parseInt(localStorage.getItem("dynamicProspectId"));
  // @ts-ignore
  const dynamicContactId = parseInt(localStorage.getItem("dynamicContactId"));
  // @ts-ignore
  const dynamicBusinessId = parseInt(localStorage.getItem("dynamicBusinessId"));
  // @ts-ignore
  const dynamicTeamsId = parseInt(localStorage.getItem("dynamicTeamsId"));
  // @ts-ignore
  const dynamicLocationId = parseInt(localStorage.getItem("dynamicLocationId"));

  const { clientDataForDayBook } = useAppSelector((state) => state?.people);
  const { staffDataDayBook } = useAppSelector((state) => state?.staff);
  const { getProspectsData } = useAppSelector((state) => state?.prospects);
  const { getContactsData } = useAppSelector((state) => state?.contacts);
  const { allDataBusiness } = useAppSelector((state) => state?.business);
  const { allDataTeams } = useAppSelector((state) => state?.teams);
  const { allLocationUserData } = useAppSelector((state) => state?.location);

  useEffect(() => {
    dispatch(getClientsThunk({ dynamicClientId: dynamicClientId }));
    dispatch(getStaffThunk({ dynamicStaffId: dynamicStaffId }));
    dispatch(getProspectsThunk({ dynamicProspectsId: dynamicProspectId }));
    dispatch(getContactsThunk({ dynamicContactId: dynamicContactId }));
    dispatch(getTeamsThunk(dynamicTeamsId));
    dispatch(getBusinessThunk(dynamicBusinessId));
    dispatch(getLocationUserThunk(dynamicLocationId));
  }, [
    dynamicStaffId,
    dynamicClientId,
    dynamicProspectId,
    dynamicContactId,
    dynamicTeamsId,
    dynamicBusinessId,
    dynamicLocationId,
  ]);
  // let prospectData = /;
  const [contactsData, setcontactsData] = useState([]);
  const [prospectsData, setprospectsData] = useState([]);
  const [businessData, setBusinessData] = useState([]);
  const [teamsData, setTeamsData] = useState([]);
  const [locationData, setLocationData] = useState([]);
  const [searchAssociatedParties, setsearchAssociatedParties] = useState("");

  useEffect(() => {
    if (getProspectsData) {
      setprospectsData(getProspectsData?.data?.data);
    }
  }, [getProspectsData]);
  useEffect(() => {
    if (getContactsData) {
      setcontactsData(getContactsData?.data?.data);
    }
  }, [getContactsData]);

  useEffect(() => {
    if (allDataBusiness?.data?.data) {
      setBusinessData(allDataBusiness?.data?.data);
    }
  }, [allDataBusiness]);

  useEffect(() => {
    if (allDataTeams?.data?.data) {
      setTeamsData(allDataTeams?.data?.data);
    }
  }, [allDataTeams]);

  useEffect(() => {
    if (allLocationUserData?.data?.data) {
      setLocationData(allLocationUserData?.data?.data);
    }
  }, [allLocationUserData]);

  useEffect(() => {
    if (
      staffDataDayBook &&
      prospectsData &&
      contactsData &&
      clientDataForDayBook &&
      businessData &&
      teamsData &&
      locationData
    ) {
      let dataArr = [
        ...clientDataForDayBook,
        ...staffDataDayBook,
        ...prospectsData,
        ...contactsData,
        ...businessData,
        ...teamsData,
        ...locationData,
      ];
      const uniqueIds = new Set();

      const filteredDataArr = dataArr.filter((elem) => {
        if (uniqueIds.has(elem.id)) {
          return false;
        }
        uniqueIds.add(elem.id);
        return true;
      });

      setResponsibleParties(filteredDataArr);
    }
  }, [
    staffDataDayBook,
    prospectsData,
    contactsData,
    clientDataForDayBook,
    businessData,
    teamsData,
    locationData,
  ]);

  let tempArr = currentGoal.activities.map((el) => {
    return { id: el.id, status: false };
  });
  const [showTrafficLightsActivities, setshowTrafficLightsActivities] =
    useState(tempArr);

  useEffect(() => {
    if (currentGoal) {
      let tempArr = currentGoal.activities.map((el) => {
        return { id: el.id, status: false };
      });
      setshowTrafficLightsActivities(tempArr);
    }
  }, [currentGoal]);

  // console.log(showTrafficLightsActivities,"showTrafficLightsActivities")

  /////////////////////////////////////////////////////

  const { serviceTypeData } = useAppSelector((state) => state.serviceType);
  useEffect(() => {
    dispatch(
      getServiceTypeByFilterThunk({
        filterColumn: "",
        filterValue: "",
        searchVal: "",
        sortColumn: "",
        sortType: "",
        serviceTypePage: 1,
      })
    );
  }, []);
  // console.log(serviceTypeData,"serviceTypeData")

  useEffect(() => {
    dispatch(getClientsThunk({ dynamicClientId: dynamicClientId }));
    dispatch(getStaffThunk({ dynamicStaffId: dynamicStaffId }));
    dispatch(getProspectsThunk({ dynamicProspectsId: dynamicProspectId }));
    dispatch(getContactsThunk({ dynamicContactId: dynamicContactId }));
    dispatch(getTeamsThunk(dynamicTeamsId));
    dispatch(getBusinessThunk(dynamicBusinessId));
    dispatch(getLocationUserThunk(dynamicLocationId));
  }, [
    dynamicStaffId,
    dynamicClientId,
    dynamicProspectId,
    dynamicContactId,
    dynamicTeamsId,
    dynamicBusinessId,
    dynamicLocationId,
  ]);
  // let prospectData = /;
  const [clientsDataForAssignedTo, setClientsDataForAssignedTo] = useState<any>(
    []
  );

  // console.log(clientsDataForAssignedTo,"clientsDataForAssignedTo")

  useEffect(() => {
    if (getProspectsData) {
      setprospectsData(getProspectsData?.data?.data);
    }
  }, [getProspectsData]);
  useEffect(() => {
    if (getContactsData) {
      setcontactsData(getContactsData?.data?.data);
    }
  }, [getContactsData]);

  useEffect(() => {
    if (allDataBusiness?.data?.data) {
      setBusinessData(allDataBusiness?.data?.data);
    }
  }, [allDataBusiness]);

  useEffect(() => {
    if (allDataTeams?.data?.data) {
      setTeamsData(allDataTeams?.data?.data);
    }
  }, [allDataTeams]);

  useEffect(() => {
    if (allLocationUserData?.data?.data) {
      setLocationData(allLocationUserData?.data?.data);
    }
  }, [allLocationUserData]);

  useEffect(() => {
    if (
      staffDataDayBook &&
      prospectsData &&
      contactsData &&
      clientDataForDayBook &&
      businessData &&
      teamsData &&
      locationData
    ) {
      let dataArr = [
        ...clientDataForDayBook,
        ...staffDataDayBook,
        ...prospectsData,
        ...contactsData,
        ...businessData,
        ...teamsData,
        ...locationData,
      ];
      const uniqueIds = new Set();

      const filteredDataArr = dataArr.filter((elem) => {
        if (uniqueIds.has(elem.id)) {
          return false;
        }
        uniqueIds.add(elem.id);
        return true;
      });

      setClientsDataForAssignedTo(filteredDataArr);
    }
  }, [
    staffDataDayBook,
    prospectsData,
    contactsData,
    clientDataForDayBook,
    businessData,
    teamsData,
    locationData,
  ]);
  ///////////////////////////////////////////////////////////////////

  const [filteredResponsibleParties, setfilteredResponsibleParties] =
    useState<any>();

  const [associatedServices, setAssociatedServices] =
    useState<any>(clientsData);

  const [filteredassociatedServices, setfilteredassociatedServices] =
    useState<any>();

  const [showResponsiblePartiesDropdown, setshowResponsiblePartiesDropdown] =
    useState(
      currentGoal?.activities?.length > 0
        ? Array.from(currentGoal?.activities).fill(false)
        : []
    );

  const [showAssociatedServicesDropdown, setshowAssociatedServicesDropdown] =
    useState(
      currentGoal?.activities?.length > 0
        ? Array.from(currentGoal?.activities).fill(false)
        : []
    );

  const handleServicesInputChange = (
    serviceType: string,
    index: number,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    console.info({
      serviceType,
      index,
      e,
    });
    const inputValue = e.target.value;

    if (serviceType === "Responsible Parties") {
      setSearchText(inputValue);

      if (inputValue === "") {
        const updatedActivities = [...showResponsiblePartiesDropdown];
        updatedActivities[index] = false;
        setshowResponsiblePartiesDropdown(updatedActivities);
        setfilteredResponsibleParties([]);
      } else {
        const updatedActivities = [...showResponsiblePartiesDropdown];
        updatedActivities[index] = true;

        // responsiblePartiesSearch[index] = inputValue;
        setshowResponsiblePartiesDropdown(updatedActivities);

        setfilteredResponsibleParties((prevItems: any) => {
          return responsibleParties.filter((elem: any) =>
            elem?.first_name?.toLowerCase().includes(inputValue.toLowerCase())
          );
        });
      }
    } else if (serviceType === "Associated Services") {
      setsearchAssociatedParties(inputValue);
      if (inputValue === "") {
        const updatedActivities = [...showAssociatedServicesDropdown];
        updatedActivities[index] = false;
        setshowAssociatedServicesDropdown(updatedActivities);
        setfilteredassociatedServices([]);
      } else {
        const updatedActivities = [...showAssociatedServicesDropdown];
        updatedActivities[index] = true;
        setshowAssociatedServicesDropdown(updatedActivities);

        setfilteredassociatedServices((prevItems: any) => {
          return ServiceTypeArr.filter((elem: any) =>
            elem?.name?.toLowerCase().includes(inputValue.toLowerCase())
          );
        });
      }
    }
  };

  // console.warn(searchAssociatedParties,"SEARCHINGGGGGGGGGG")
  const handleSelectService = (service: any | {}, index: any, type: string) => {
    let updatedGoalsData: object[] | any;
    setGoalsData((prevGoalsData: any) => {
      updatedGoalsData = JSON.parse(JSON.stringify(prevGoalsData));
      let activity: any = updatedGoalsData[goalIndex].activities[index];

      if (type === "responsibleParties") {
        // CLOSE THE DROPDOWN WHEN SELECTED AN ITEM
        let spreadResponsiblePartiesDropdown = [
          ...showResponsiblePartiesDropdown,
        ];
        spreadResponsiblePartiesDropdown[index] = false;
        setshowResponsiblePartiesDropdown(spreadResponsiblePartiesDropdown);
        setfilteredResponsibleParties([]);

        if (typeof activity?.responsible_parties_id === "string") {
          activity.responsible_parties_id = activity.responsible_parties_id
            .split(",")
            .concat(service?.id);
        } else if (Array.isArray(activity?.responsible_parties_id)) {
          activity.responsible_parties_id.unshift(service?.id);
        } else {
          activity.responsible_parties_id = [service?.id];
        }
      } else if (type === "associatedServices") {
        // CLOSE THE DROPDOWN WHEN SELECTED AN ITEM
        let spreadAssociatedServicesDropdown = [
          ...showAssociatedServicesDropdown,
        ];
        spreadAssociatedServicesDropdown[index] = false;
        setshowAssociatedServicesDropdown(spreadAssociatedServicesDropdown);
        setfilteredassociatedServices([]);

        // activity[`service_type_id`] = service?.id;

        if (typeof activity?.associated_services_id === "string") {
          activity.associated_services_id = activity.associated_services_id
            .split(",")
            .concat(service?.id);
        } else if (Array.isArray(activity?.associated_services_id)) {
          activity.associated_services_id.unshift(service?.id);
        } else {
          activity.associated_services_id = [service?.id];
        }
      }

      updatedGoalsData[goalIndex].activities[index] = activity;

      return updatedGoalsData;

    });

    setSearchText("");
    setsearchAssociatedParties("");

  };

  const handleSelectTrafficLights = (index: any, traffic_light: string) => {
    let updatedGoalsData: object[] | any;
    setGoalsData((prevGoalsData: any) => {
      updatedGoalsData = JSON.parse(JSON.stringify(prevGoalsData));
      updatedGoalsData[goalIndex].activities[index][`traffic_light`] =
        traffic_light;
      return updatedGoalsData;
    });
  };

  useEffect(() => {
    if (selectedInterval) {
      // @ts-ignore
      setGoalsData((prevGoalsData) => {
        const updatedGoalsData = [...prevGoalsData];
        const goalToUpdate = { ...updatedGoalsData[goalIndex] };
        const updatedActivities = [...goalToUpdate.activities];
        updatedActivities[currentIndex] = {
          ...updatedActivities[currentIndex],
          end_time: selectedInterval,
        };
        goalToUpdate.activities = updatedActivities;
        updatedGoalsData[goalIndex] = goalToUpdate;
        // console.log("updatedGoalsData=====================>", updatedGoalsData);
        return updatedGoalsData;
      });
    }
  }, [selectedInterval, goalIndex]);

  useEffect(() => {
    if (
      selectedInterval && selectedInterval == "custom"
        ? dateWithPeriodPicker.startDate && dateWithPeriodPicker.startDate
        : selectedDate
    ) {
      // @ts-ignore
      setGoalsData((prevGoalsData) => {
        const updatedGoalsData = [...prevGoalsData];
        const goalToUpdate = { ...updatedGoalsData[goalIndex] };
        const updatedActivities = [...goalToUpdate.activities];
        updatedActivities[currentIndex] = {
          ...updatedActivities[currentIndex],
          start_time: formatDateStatusApi(
            selectedDate == "custom"
              ? dateWithPeriodPicker.startDate
              : selectedDate
          ),
        };
        goalToUpdate.activities = updatedActivities;
        updatedGoalsData[goalIndex] = goalToUpdate;
        // console.log("updatedGoalsData=====================>", updatedGoalsData);
        return updatedGoalsData;
      });
    }
  }, [dateWithPeriodPicker, selectedDate, selectedInterval, goalIndex]);

  const activityDialogRef = useRef<any>(null);
  useEffect(() => {
    dispatch(setcurrentGoal(currentGoal));
  }, [currentGoal]);

  useEffect(() => {
    const isOutsideActivityDialog = (target: any) => {
      return (
        activityDialogRef.current &&
        !activityDialogRef.current.contains(target) &&
        !target.classList.contains("filter_dropdown") &&
        !target.classList.contains("responsible_parties_input") &&
        !target.classList.contains("filter_option") &&
        !target.classList.contains("activity_title") &&
        !target.classList.contains("activity_summary") &&
        !target.classList.contains("time-picker-input") &&
        !target.classList.contains("selected") &&
        !target.classList.contains("picker-item") &&
        !target.className.includes("goal_text_area") &&
        target.className.includes("add_goals")
      );
    };

    const handleClickOutside = async (event: any) => {
      if (isOutsideActivityDialog(event.target)) {
        const tempGoalCopy = { ...currentGoal };
        tempGoalCopy.goal_id = tempGoalCopy.id;
        delete tempGoalCopy.id;

        tempGoalCopy.activities = tempGoalCopy.activities.map((elem: any) => {
          if (typeof elem.responsible_parties_id === "string") {
            return {
              ...elem,
              responsible_parties_id: elem.responsible_parties_id.split(","),
              traffic_light: 1,
            };
          }
          return elem;
        });

        tempGoalCopy.activities = tempGoalCopy.activities.map((elem: any) => {
          if (typeof elem.associated_services_id === "string") {
            return {
              ...elem,
              associated_services_id: elem.associated_services_id.split(","),
              traffic_light: 1,
            };
          }
          return elem;
        });

        let res = await dispatch(updateGoalsThunk({ data: tempGoalCopy }));
        dispatch(getGoalsThunk({ clientId }));
        if (res?.payload?.status === "success") {
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [goalsData, activityDialogRef]);

  // // @ts-ignore
  // let dynamicClientId = parseInt(localStorage.getItem("dynamicClientId"));

  useEffect(() => {
    dispatch(getClientsThunk({ dynamicClientId }));
  }, []);
  // console.log(selectedDate,"selectedDat")
  // const [isInputFocused, setInputFocused] = useState(false);

  const [currentIndex, setCurrentIndex] = useState<any>(null);


  const [nextIntervalDate, setNextIntervalDate] = useState<any>(null);
  const [maxHeight, setMaxHeight] = useState(100);

  // console.log("selectedDate=================================", selectedDate);

  useEffect(() => {
    if (selectedDate && selectedInterval) {
      const ongoingReviewDate: any = getDateForIntervals(selectedDate);

      const dateIs = ongoingReviewDate[selectedInterval.replace(/\s/g, "")];

      setNextIntervalDate(dateIs);
      setIsPickerOpen(false);
    }
  }, [selectedDate, selectedInterval]);



  useEffect(() => {
    updateDatePickerValue();
  }, [dateWithPeriodPicker]);

  const updateDatePickerValue = () => {
    if (dateWithPeriodPicker.startDate && dateWithPeriodPicker.endDate) {
      const sMonth = dateWithPeriodPicker.startDate.toString().split(" ")[1];
      const sYear = dateWithPeriodPicker.startDate.toString().split(" ")[3];
      const eMonth = dateWithPeriodPicker.endDate.toString().split(" ")[1];
      const eYear = dateWithPeriodPicker.endDate.toString().split(" ")[3];

      const formattedValue = `${sMonth} ${sYear} to ${eMonth} ${eYear}`;

      if (
        formattedValue !==
        `${dateWithPeriodPicker.sMonth} ${dateWithPeriodPicker.sYear} to ${dateWithPeriodPicker.eMonth} ${dateWithPeriodPicker.eYear}`
      ) {
        setdateWithPeriodPicker((prevState: any) => ({
          ...prevState,
          sMonth,
          sYear,
          eMonth,
          eYear,
        }));
      }
    }
  };
  const [isHovered, setIsHovered] = React.useState(false);

  const [selectedActivity, setselectedActivity] = useState(-1);




  const handleDeleteActivity = async (activityId: any, activity: any) => {
    const res = await dispatch(
      deleteActivityThunk({ goalId: currentGoal.id, activityId })
    );
    if (res.payload.status === "success") {
      toast.success("Activity Deleted Successfully!", {
        progressStyle: { backgroundColor: dynamicColor },
        icon: (
          <span
            style={{
              marginRight: "8px",
              borderRadius: "50%",
              width: "7rem",
              height: "35px",
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: dynamicColor,
              backgroundColor: "white",
              fontSize: "1.5rem",
              border: `3px solid ${dynamicColor}`,
            }}
          >
            ✓
          </span>
        ),
      });
    }
    await dispatch(getGoalsThunk({ clientId }));
  };

  useEffect(() => {
    setMaxHeight(selectedActivity > -1 ? 173 : 40);
  }, [selectedActivity]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setMaxHeight(selectedActivity > -1 ? 173 : 40);
    }, 300);

    return () => clearTimeout(timeoutId);
  }, [selectedActivity]);

  // console.log(showTrafficLightsActivities)

  return (
    <>
      <div className="" ref={activityDialogRef}>
        {currentGoal.activities.map((activity: any, index: any) => {
          let startTimeInfo = activity.start_time;
          let optionSelected = activity.end_time;
          let getDateForIntervalsList: any = getDateForIntervals(startTimeInfo);

          let nextDateIs;
          if (optionSelected) {
            nextDateIs = getDateForIntervalsList[optionSelected];
          }
          return (
            <>
              {selectedActivity === index ? (
                <>
                  <ActivityBox
                    handleServicesInputChange={handleServicesInputChange}
                    index={index}
                    showResponsiblePartiesDropdown={
                      showResponsiblePartiesDropdown
                    }
                    searchText={searchText}
                    searchAssociatedParties={searchAssociatedParties}
                    filteredResponsibleParties={filteredResponsibleParties}
                    handleSelectService={handleSelectService}
                    goalsData={goalsData}
                    clientsData={clientsData}
                    goalIndex={goalIndex}
                    handleActivityDataChange={handleActivityDataChange}
                    activity={activity}
                    showAssociatedServicesDropdown={
                      showAssociatedServicesDropdown
                    }
                    serviceTypeData={serviceTypeData}
                    handleDeleteActivity={handleDeleteActivity}
                    setFocusResponsibleParties={setFocusResponsibleParties}
                    setselectedActivity={setselectedActivity}
                  />
                </>
              ) : (
                <div
                  onClick={() =>
                    setselectedActivity((prev) => (prev === index ? -1 : index))
                  }
                >
                  <InnerNote
                    forSticky={false}
                    activity={activity}
                    setshowTrafficLightsActivities={
                      setshowTrafficLightsActivities
                    }
                    showTrafficLightsActivities={showTrafficLightsActivities}
                    key={index}
                    title={activity.title}
                    summary={activity?.summary}
                    tags={activity?.responsible_parties}
                    services={activity?.associated_services}
                    time={activity?.updated_at}
                    trafficColorsStatus={
                      activity.traffic_light == 0
                        ? "#ef3a34"
                        : activity.traffic_light == 1
                        ? "#fbbf14"
                        : activity.traffic_light == 2
                        ? "#2ad400"
                        : ""
                    }
                    onChangeTrafficLight={(res: any) => {
                      handleSelectTrafficLights(
                        index,
                        res == "#ef3a34"
                          ? 0
                          : res == "#fbbf14"
                          ? 1
                          : res == "#2ad400"
                          ? 2
                          : 1
                      );
                    }}
                    maxHeight={maxHeight}
                  />
                </div>
              )}
            </>
          );
        })}
      </div>
    </>
  );
};

export default ActivityDialog;
