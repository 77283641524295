import "../Styles/TrafficLights.css";

import CrossSvg from "../../../../Model/Assets/SVG/Common/CrossSvg";

const TrafficLights = ({
  className,
  setShowLights,
  handleTrafficColors,
  isSmall,
  showTrafficLightsActivities,
  setshowTrafficLightsActivities = () => {},
  cancelAndClose = () => {},
  isFromClinicalProgressModal = false,
  isThreads,
  setshowCircularTrafficLights = () => {}
}: any) => {
  return (
    <div
      className={`${className} ${
        // type === "daybook"
        //   ? `daybook_clinical_note_taffic_light`
        //   : `traffic_light_container`
        isSmall ? `traffic_light_container` : `traffic_light_container_goals`
      } `}
      onClick={(e) => {
        e.stopPropagation();
      }}
      style={{ right: isFromClinicalProgressModal ? -2 : "unset" }}
    >
      <div
        onClick={() => {
          const updatedActivities = showTrafficLightsActivities?.map(
            (elem: { id: any; status: any }) => {
              return { ...elem, status: false };
            }
          );
          setshowTrafficLightsActivities(updatedActivities);
          setshowCircularTrafficLights(false)

          setShowLights(false);
          if (isThreads) {
            setShowLights(-1);
          }
        }}
        className={` ${
          isSmall ? `traffic_cross_svg_goals` : `traffic_cross_svg`
        }`}
      >
        <CrossSvg width="8" height="7" />
      </div>
      <div className={`traffic_light_dot_container `}>
        {[...Array(3)].map((_, index) => (
          <span
            onClick={() =>{
              setshowCircularTrafficLights(false)
              index === 0
                ? handleTrafficColors("#ef3a34")
                : index === 1
                ? handleTrafficColors("#fbbf14")
                : index === 2
                ? handleTrafficColors("#2ad400")
                : ""}
            }
            key={index}
            className={`${
              isSmall ? `traffic_light_dots_goals` : `traffic_light_dots`
            } `}
          >
            &nbsp;
          </span>
        ))}
      </div>
    </div>
  );
};

export default TrafficLights;
