import { toast } from "react-toastify";
import { useAppSelector } from "../../../Controller/redux/Hooks";

const SuccessToast = () => {
  const { dynamicColor } = useAppSelector((state) => state?.common);

  return (
    <div>
      <span
        style={{
          marginRight: "8px",
          borderRadius: "50%",
          width: "2rem",
          height: "35px",
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: dynamicColor,
          backgroundColor: "white",
          fontSize: "1.5rem",
          border: `3px solid ${dynamicColor}`,
        }}
      >
        ✓
      </span>
    </div>
  );
};

export const SuccessToastComponent = ({title = "Comms Sent Successfully! "}: any) => {
  return toast.success(title, {
    icon: <SuccessToast />,
  });
};

export default SuccessToast;


