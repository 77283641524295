// @ts-nocheck

import "./Daybook.css";
import "../Daybook/Styles/CollapsedDaybookItem.css";
import { Fragment, useEffect, useRef, useState } from "react";
import DaybookHeader from "./Components/DaybookHeader";
import { useParams } from "react-router";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../Controller/redux/Hooks";
import { getAllTagsThunk } from "../../../Controller/redux/features/CommonFeature/commonSlice";
import { createDocumentCommsThunk } from "../../../Controller/redux/features/CommonFeature/documentSlice";
import {
  createDaybookItem,
  deleteDaybookItem,
  getDaybookItems,
  reselectedServiceType,
  resetConfirmDeleteTask,
  resetDaybookTags,
  resetUploadedFilesFromStore,
  setConfirmDeleteTask,
  setDaybookTags,
  setselectedDaybookTags,
  setUploadedFilesToStore,
  updateDaybookItem,
} from "../../../Controller/redux/features/DaybookFeature/daybookSlice";
import TagsInput from "../../components/Tags/TagBuilder";
import PlusIcon from "./assets/header-icons/plus-icon.svg";
import PlusIcon2 from "./assets/svg/plus-icon-2.svg";
import ReactDatePicker from "react-datepicker";
import TimePicker from "../../components/Time/Time";
import fileIcon from "./assets/svg/file-icon.svg";
import { getClientsThunk } from "../../../Controller/redux/features/ClientFeature/clientsSlice";
import { getStaffThunk } from "../../../Controller/redux/features/StaffFeature/staffSlice";
import { getContactsThunk } from "../../../Controller/redux/features/ContactsFeature/contactsSlice";
import { getProspectsThunk } from "../../../Controller/redux/features/ProspectsFeature/prospectsSlice";
import { getBusinessThunk } from "../../../Controller/redux/features/BusinessFeature/businessSlice";
import { getTeamsThunk } from "../../../Controller/redux/features/TeamsFeature/teamsSlice";
import { toast } from "react-toastify";
import { EditorState, Modifier, convertToRaw } from "draft-js";
import { api } from "../../../Controller/services/api";
import CommonTaskItem from "../../components/CommonTaskItem/Components/CommonTaskItemClinical/CommonTaskItem";
import PlusSVG from "../People/ClientPage/Components/UniqueClientScreen/Components/FinanceTab/Data/PlusSVG";
import {
  convertFormatDate,
  getCurrentDates,
} from "../../../Model/utils/Helper/formatDate";
import CircularNoteTrigger from "../../components/CircularNoteTrigger/CircularNoteTrigger";
import TrafficLights from "../../components/CommonModal/Components/TrafficLights";
import { getLocationUserThunk } from "../../../Controller/redux/features/LocationFeature/locationSlice";
import { Modal, ModalOverlay } from "@chakra-ui/react";
import ClientAssessmentForm from "../People/ClientPage/Components/UniqueClientScreen/Components/DocumentsTab/Components/ClientAssessmentForm";
import { dumpArray } from "./Data/TimeIntervalsArr";
import CopySVG from "./Data/CopySVG";
import SortSVG from "./Data/SortSVG";
import CreateComms from "../Comms/Component/CreateCommsSection/Index";
import { CrossMdSvg } from "../../../Model/Assets/SVG/Common/CrossSvg";
import WeekdaySelector from "../../components/CustomCalender/WeekdaySelector";
import YearlyComponent from "../../components/CustomCalender/YearlyComponent";
import MonthlyComponent from "../../components/CustomCalender/MonthlyComponent";
import CustomDatePicker from "../../components/DatePicker/CustomDatePicker";
import ClientCommsForm from "../People/ClientPage/Components/UniqueClientScreen/Components/DocumentsTab/Components/ClientCommsForm";
import UploadSVG from "./Data/UploadSVG";
import GlobalPDFSvg from "../../../Model/Data/PDFSvg";
import GlobalDOCXSvg from "../../../Model/Data/DOCXSvg";
import EXCELSvg from "../../../Model/Data/EXCELSvg";
import ConfirmationModal from "../../components/ConfirmationModal/ConfirmationModal";
import CountSVG from "./Data/CountSVG";
import { SuccessToastComponent } from "../../components/SuccessToast/SuccessToast";
import { addOneHour } from "../../../Model/utils/Helper/formatTime";
import { getUserNotificationStats } from "../../../Controller/redux/features/NotificationsFeature/NotificaionsSlice";
import { useLocation } from "react-router-dom";
import { ThreadsBox } from "../../components/CommonModal/Components/ThreadsBox";
import {
  getServiceTypeByFilterThunk,
  resetServiceTypeData,
} from "../../../Controller/redux/features/RefDataFeature/ServiceTypeFeature/serviceTypeSlice";
import { updateTaggedUsersThunk } from "../../../Controller/redux/features/CommsFeature/commsSlice";
import { convertTimeFormat } from "../../../Model/utils/Helper/convertTimeDaybook";

const weekday = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
const monthNames = [
  "JAN",
  "FEB",
  "MAR",
  "APR",
  "MAY",
  "JUN",
  "JUL",
  "AUG",
  "SEP",
  "OCT",
  "NOV",
  "DEC",
];
const yesterday = new Date();
const today = new Date();
const tomorrow = new Date();
const dayAfterTomorrow = new Date();

yesterday.setDate(dayAfterTomorrow.getDate() - 1);
tomorrow.setDate(dayAfterTomorrow.getDate() + 1);
dayAfterTomorrow.setDate(dayAfterTomorrow.getDate() + 2);

const formatDate = (date: any, interval: any) => {
  date = new Date(date);
  const month = date.toLocaleString("default", { month: "long" });
  const Year = date.toLocaleString("default", { year: "numeric" });

  const day = date.getDate();

  switch (interval) {
    case "quarterly":
      const quarter = Math.floor((date.getMonth() + 3) / 3);
      return `Starting ${day} ${month}, Quarterly`;
    case "monthly":
      let elem = `Starting ${day} ${month}, Monthly`;
      return elem;
    case "yearly":
      return `Starting ${day} ${month}, Yearly`;
    case "half yearly":
      const half = date.getMonth() < 6 ? "first" : "second";
      return `Starting ${day} ${month}, Half Yearly`;
    case "weekly":
      return `Starting ${day} ${month}, Weekly`;
    case "daily":
      return `Starting ${day} ${month}, Daily`;
    case "hourly":
      return `Starting ${day} ${month}, Hourly`;
    case "fortnightly":
      return `Starting ${day} ${month}, Fortnightly`;
    case "None":
      return `${day} ${month}`;
    case "custom":
      return `Starting Custom`;
    default:
      return `${day} ${month} ${Year}`;
  }
};

const prefixDate = (date: number) => {
  if (date < 10) return `0${date}`;
  else return date;
};

export const selectDateCategories = [
  {
    id: 0,
    title: "New Event",
    value: ``,
  },
  {
    id: 1,
    title: "Yesterday",
    value: `${yesterday.getFullYear()}-${prefixDate(
      yesterday.getMonth() + 1
    )}-${prefixDate(yesterday.getDate())}`,
  },
  {
    id: 2,
    title: "Today",
    value: `${today.getFullYear()}-${prefixDate(
      today.getMonth() + 1
    )}-${prefixDate(today.getDate())}`,
  },
  {
    id: 3,
    title: "Tomorrow",
    value: `${tomorrow.getFullYear()}-${prefixDate(
      tomorrow.getMonth() + 1
    )}-${prefixDate(tomorrow.getDate())}`,
  },
  {
    id: 4,
    title: `${dayAfterTomorrow.getDate()} ${
      monthNames[dayAfterTomorrow.getMonth()]
    }`,
    value: `${dayAfterTomorrow.getFullYear()}-${prefixDate(
      dayAfterTomorrow.getMonth() + 1
    )}-${prefixDate(dayAfterTomorrow.getDate())}`,
  },
  { id: 5, title: "Choose Date", value: "" },
];

const DayBook = ({ type }: any) => {
  /////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////
  const [serviceType, setServiceType] = useState(null);
  const customDate = useLocation()?.state?.customDate;
  const [isFirstTaskItem, setIsFirst] = useState(false);
  const [showAttachmentAddingModal, setShowAttachmentAddingModal] =
    useState(false);
  const [isPeriodOpen, setIsPeriodOpen] = useState(false);
  const [showFreq, setShowFreq] = useState(false);
  const [selectedDate, setselectedDate] = useState<any>(new Date());
  const [selectedInterval, setSelectedInterval] = useState<string | any>(
    "None"
  );
  const [filesWhenClickedFromCommsBox, setFilesWhenClickedFromCommsBox] =
    useState<any>([]);

  const [chooseDate, setChooseDate] = useState(new Date().toString());
  const [futureDates, setfutureDate] = useState(new Date());
  const [taskIndex, setTaskIndex] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showChooseDate, setShowChooseDate] = useState<any>(false);
  const [distDate, setDistDate] = useState<any>(new Date());
  const [showCustomModal, setShowCustomModal] = useState(false);
  const [showComsForm, setShowCommsForm] = useState(false);
  const [endDate, setEndDate] = useState<Date>(futureDates);
  const [startDate, setStartDate] = useState<Date>(futureDates);
  const [selectedMessage, setSelectedMessage] = useState("");
  const [selectedIterator, setSelectedIterator] = useState("first");
  const [selectedWeekday, setSelectedWeekday] = useState("day");
  const [manualRadio, setManualRadio] = useState("Each");
  const [clientId, setclientId] = useState("");
  const [showLights, setShowLights] = useState<boolean>(false);
  const [trafficColors, setTrafficColors] = useState<any>("");
  const [isTagClicked, setTagClicked] = useState(false);
  const [tagStatus, setTagStatus] = useState("collapsed");

  const [daybookTaskId, setDaybookTaskId] = useState("");
  const [selectTypeSave, setSelectTypeSave] = useState<string>("Save");
  const [selectTypeDelete, setSelectTypeDelete] = useState<string>("Delete");
  const [selectedRepetitive, setSelectedRepetitive] = useState<any>(1);
  const [selectedSortDate, setSelectedSortDate] = useState<any>(null);

  const [isAddingFromCross, setisAddingFromCross] = useState(false);
  const [isAddingFromCrossUpdate, setisAddingFromCrossUpdate] = useState(false);
  const [isSingleton, setisSingleton] = useState(false);

  const [clientsDataForAssignedTo, setClientsDataForAssignedTo] = useState<any>(
    []
  );

  const [clientsDataForTaggedUser, setClientsDataForTaggedUser] = useState<any>(
    []
  );

  const [selectedSortTab, setSelectedSortTab] = useState(
    selectDateCategories[customDate ? 5 : 2]
  );

  useEffect(() => {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    const customDateObj = new Date(customDate);
  
    if (
      today.getFullYear() === customDateObj.getFullYear() &&
      today.getMonth() === customDateObj.getMonth() &&
      today.getDate() === customDateObj.getDate()
    ) {
      setSelectedSortTab(selectDateCategories[2]);
    } else if (
      today.getFullYear() === customDateObj.getFullYear() &&
      today.getMonth() === customDateObj.getMonth() &&
      today.getDate() + 1 === customDateObj.getDate()
    ) {
      setSelectedSortTab(selectDateCategories[3]);
    } else if (
      today.getFullYear() === customDateObj.getFullYear() &&
      today.getMonth() === customDateObj.getMonth() &&
      today.getDate() + 2 === customDateObj.getDate()
    ) {
      setSelectedSortTab(selectDateCategories[4]);
    } else if (
      today.getFullYear() === customDateObj.getFullYear() &&
      today.getMonth() === customDateObj.getMonth() &&
      today.getDate() + 3 === customDateObj.getDate()
    ) {
      setSelectedSortTab(selectDateCategories[5]);
      setSelectedSortDateCustom(customDateObj)
    } else if (customDateObj.getTime() === yesterday.getTime()) {
      setSelectedSortTab(selectDateCategories[1]);
    }
  }, [customDate]);
  

  // console.log(selectedSortTab,'selectedSortTab')

  const [selectedSortDateCustom, setSelectedSortDateCustom] = useState<any>(
    customDate ? customDate : null
  );

  const getDaybookCall = () => {
    // alert(1)
    let date = new Date();
    dispatch(
      // @ts-ignore
      getDaybookItems({
        sortType: "asc",
        userId:
          pathname.includes("clients") ||
          pathname.includes("staff") ||
          pathname.includes("prospects") ||
          pathname.includes("business") ||
          pathname.includes("teams") ||
          pathname.includes("contacts") ||
          pathname.includes("locations")
            ? clientId
            : userId,

        date:
          selectedSortTab.id !== 5
            ? selectedSortTab?.value
            : // @ts-ignore

              `${date?.getFullYear()}-${prefixDate(
                // @ts-ignore
                date?.getMonth() + 1
                // @ts-ignore
              )}-${date?.getDate()}`,
        tenantId: providerId,
      })
    );
  };

  const [contactsData, setcontactsData] = useState([]);
  const [prospectsData, setprospectsData] = useState([]);
  const [businessData, setBusinessData] = useState([]);
  const [teamsData, setTeamsData] = useState([]);
  const [locationData, setLocationData] = useState([]);

  const [selectedDuration, setSelectedDuration] = useState<string>("");

  const [dayBookIndex, setdayBookIndex] = useState(-1);
  const [isDeleteing, setisDeleting] = useState(false);
  const [deleteProps, setDeleteProps] = useState({
    save_type: "",
    date: "",
  });
  const [isThereMessage, setisThereMessage] = useState<any>(null);
  const [paramsId, setparamsId] = useState("");
  const [dayBookTaskIndex, setdayBookTaskIndex] = useState(-1);
  const [dayBookList, setDayBookList] = useState<any>([]);
  const [selectedDayBookItem, setSelectedDayBookItem] = useState<any>(null);
  const [refreshAttempt, setRefreshAttempt] = useState(0);
  const [editorState, setEditorState] = useState(EditorState?.createEmpty());
  const [startTime, setStartTime] = useState(
    selectedDayBookItem ? selectedDayBookItem?.task?.start_time : ""
  );
  const [localStateTrafficLight, setlocalStateTrafficLight] = useState("");

  const [endTime, setEndTime] = useState(
    selectedDayBookItem ? selectedDayBookItem?.task?.end_time : ""
  );
  const [finalStartTime, setFinalStartTime] = useState("");
  const [finalEndTime, setFinalEndTime] = useState("");
  const [isModify, setIsModify] = useState(false);
  const [createCommsData, setCreateCommsData] = useState<any>({
    link_id: "",
    summary: "",
    message: selectedMessage,
    comm_type: "DAYBOOK",
    comm_document_files: [],
  });

  const [showDateTimePickerDaybook, setshowDateTimePickerDaybook] =
    useState(false);

  const [isChangeToFrequencyToShowModal, setIsChangeToFrequencyToShowModal] =
    useState(false);

  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const [currentIndex, setcurrentIndex] = useState(-1);
  const [additionalState, setAdditionalState] = useState<any>({
    showTags: true,
    showTagsArr: Array(dayBookList?.length).fill(false),
    selectedTags: [],
  });

  const [trafficLightColors, setTrafficLightColors] = useState<any>({
    0: "#ef3a34",
    1: "#fbbf14",
    2: "#2ad400",
  });

  const [
    showFrequencyChangeConfirmationModal,
    setshowFrequencyChangeConfirmationModal,
  ] = useState(false);

  const [hitStartTimeToSetEndTime, sethitStartTimeToSetEndTime] = useState({
    isHit: false,
    selectedHours: "",
    selectedMinutes: "",
    selectedFormat: "",
  });

  /////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////

  useEffect(() => {
    if (selectedDayBookItem) {
      setServiceType(selectedDayBookItem?.task?.service_types);
    }
  }, [selectedDayBookItem]);


  const toggleRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
    setManualRadio(event.target.value);
  };

  useEffect(() => {
    if (selectedInterval === "Yearly") {
      setManualRadio("Each");
    }
  }, [selectedInterval]);

  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.common);
  // @ts-ignore
  const dynamicClientId = parseInt(localStorage.getItem("dynamicClientId"));
  // @ts-ignore
  const dynamicStaffId = parseInt(localStorage.getItem("dynamicStaffId"));
  // @ts-ignore
  const dynamicProspectId = parseInt(localStorage.getItem("dynamicProspectId"));
  // @ts-ignore
  const dynamicContactId = parseInt(localStorage.getItem("dynamicContactId"));
  // @ts-ignore
  const dynamicBusinessId = parseInt(localStorage.getItem("dynamicBusinessId"));
  // @ts-ignore
  const dynamicTeamsId = parseInt(localStorage.getItem("dynamicTeamsId"));
  // @ts-ignore
  const dynamicLocationId = parseInt(localStorage.getItem("dynamicLocationId"));

  const pathname = useLocation().pathname;
  const location = useLocation();

  const salesPath = location?.state?.prevPath === "/sales";

  // @ts-ignore
  const idFromParams: any = useParams()?.id;
  const providerId: any = window.atob(useParams()?.pro_id || "");

  useEffect(() => {
    if (idFromParams) {
      let realId = window.atob(idFromParams);
      setparamsId(realId);
    }
  }, [idFromParams]);

  const { error, daybookTags } = useAppSelector((state) => state.daybook);

  let errorMessage = error?.payload?.data?.response;

  useEffect(() => {
    if (
      pathname.includes("clients") ||
      pathname.includes("staff") ||
      pathname.includes("prospects") ||
      pathname.includes("business") ||
      pathname.includes("teams") ||
      pathname.includes("contacts") ||
      pathname.includes("locations")
    ) {
      setclientId(paramsId);
    }
  }, [pathname, idFromParams, paramsId]);

  const isClientsOrStaffScreen =
    pathname.includes("clients") ||
    pathname.includes("staff") ||
    pathname.includes("prospects") ||
    pathname.includes("business") ||
    pathname.includes("teams") ||
    pathname.includes("contacts") ||
    pathname.includes("locations");

  let id = user?.id;
  const [userId, setuserId] = useState("");

  useEffect(() => {
    let loggedInUserId = localStorage.getItem("loggedInUserId");
    if (loggedInUserId) {
      setuserId(loggedInUserId);
    }
  }, []);

  const { dayBookList: dayBookListRedux } = useAppSelector(
    (state) => state?.daybook
  );


  const allTags = useAppSelector((state) => state?.common?.allTags);

  const isDeleteingConfirmation = (props: string, saveType: string) => {
    setisDeleting(true);
    setDeleteProps({
      date: props,
      save_type: saveType,
    });
  };

  // console.log('selectedTags', selectedTags);

  const { clientDataForDayBook } = useAppSelector((state) => state?.people);
  const { staffDataDayBook } = useAppSelector((state) => state?.staff);
  const { getProspectsData } = useAppSelector((state) => state?.prospects);
  const { getContactsData } = useAppSelector((state) => state?.contacts);
  const { allDataBusiness } = useAppSelector((state) => state?.business);
  const { allDataTeams } = useAppSelector((state) => state?.teams);
  const { allLocationUserData } = useAppSelector((state) => state?.location);

  // useEffect(() => {
  //   if(dayBookList){
  //     setSelectedDayBookItem(dayBookList[dayBookIndex].task[dayBookTaskIndex])
  //   }
  // },[dayBookList])
  // // console.log(hitStartTimeToSetEndTime,"hitStartTimeToSetEndTime")

  const dynamicColor = useAppSelector((state) => state?.common?.dynamicColor);

  // @ts-ignore
  let currentLoggedInUserDetails = JSON.parse(localStorage.getItem("user"));
  let loggedInUserId = currentLoggedInUserDetails?.data?.id;
  let mentionData = staffDataDayBook?.filter(
    (elem: any) => elem.id !== loggedInUserId
  );
  let mentionDataArr: any = [];
  if (Array.isArray(mentionData)) {
    for (let item of mentionData) {
      let tempObj = {
        text: `${item.first_name} ${item.last_name}`,
        value: item.first_name,
        url: item.id,
      };
      mentionDataArr.push(tempObj);
    }
  }

  // Getting Yesterday , today , Tomorrow , Day After Tomorrow
  const currentDate = getCurrentDates();

  useEffect(() => {
    dispatch(getAllTagsThunk());
  }, []);

  const handleSelectedTagIdsChange = (newSelectedTagIds: any) => {
    const newSelectedTags = [...newSelectedTagIds];

    setSelectedTags(newSelectedTags);

    if (selectedDayBookItem?.task?.id) {
      setIsModify(true);
    }
  };



  const handleDurationChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    if (selectedDayBookItem?.task?.id) {
      setIsChangeToFrequencyToShowModal(true);
    }
    setSelectedDuration(event.target.value);
  };

  useEffect(() => {
    dispatch(getClientsThunk({ dynamicClientId: dynamicClientId }));
    dispatch(getStaffThunk({ dynamicStaffId: dynamicStaffId }));
    dispatch(getProspectsThunk({ dynamicProspectsId: dynamicProspectId }));
    dispatch(getContactsThunk({ dynamicContactId: dynamicContactId }));
    dispatch(getTeamsThunk(dynamicTeamsId));
    dispatch(getBusinessThunk(dynamicBusinessId));
    dispatch(getLocationUserThunk(dynamicLocationId));
  }, [
    dynamicStaffId,
    dynamicClientId,
    dynamicProspectId,
    dynamicContactId,
    dynamicTeamsId,
    dynamicBusinessId,
    dynamicLocationId,
  ]);
  // let prospectData = /;

  useEffect(() => {
    if (getProspectsData) {
      setprospectsData(getProspectsData?.data?.data);
    }
  }, [getProspectsData]);
  useEffect(() => {
    if (getContactsData) {
      setcontactsData(getContactsData?.data?.data);
    }
  }, [getContactsData]);

  useEffect(() => {
    if (allDataBusiness?.data?.data) {
      setBusinessData(allDataBusiness?.data?.data);
    }
  }, [allDataBusiness]);

  useEffect(() => {
    if (allDataTeams?.data?.data) {
      setTeamsData(allDataTeams?.data?.data);
    }
  }, [allDataTeams]);

  useEffect(() => {
    if (allLocationUserData?.data?.data) {
      setLocationData(allLocationUserData?.data?.data);
    }
  }, [allLocationUserData]);

  useEffect(() => {
    if (
      staffDataDayBook &&
      prospectsData &&
      contactsData &&
      clientDataForDayBook &&
      businessData &&
      teamsData &&
      locationData
    ) {
      let dataArr = [
        ...clientDataForDayBook,
        ...staffDataDayBook,
        ...prospectsData,
        ...contactsData,
        ...businessData,
        ...teamsData,
        ...locationData,
      ];
      const uniqueIds = new Set();

      const filteredDataArr = dataArr.filter((elem) => {
        if (uniqueIds.has(elem.id)) {
          return false;
        }
        uniqueIds.add(elem.id);
        return true;
      });

      setClientsDataForAssignedTo(filteredDataArr);
    }
  }, [
    staffDataDayBook,
    prospectsData,
    contactsData,
    clientDataForDayBook,
    businessData,
    teamsData,
    locationData,
  ]);

  useEffect(() => {
    if (
      clientDataForDayBook &&
      staffDataDayBook &&
      contactsData &&
      prospectsData &&
      businessData &&
      teamsData &&
      locationData
    ) {
      setClientsDataForTaggedUser([
        ...clientDataForDayBook,
        ...staffDataDayBook,
        ...prospectsData,
        ...contactsData,
        ...businessData,
        ...teamsData,
        ...locationData,
      ]);
    }
  }, [
    clientDataForDayBook,
    staffDataDayBook,
    prospectsData,
    contactsData,
    businessData,
    teamsData,
    locationData,
  ]);

  // const [chooseDate, setChooseDate] = useState("");
  // const [selectedSortDateCustom, setSelectedSortDateCustom] =
  //   useState<any>(null);

  useEffect(() => {
    setChooseDate(
      selectedSortDateCustom
        ? convertFormatDate(selectedSortDateCustom)
        : new Date().toString()
    );
    setfutureDate(
      selectedSortDateCustom ? selectedSortDateCustom : new Date().toString()
    );
  }, [selectedSortDateCustom]);

  useEffect(() => {
    if (selectedInterval === "None" || selectedInterval === "Once") {
      setisSingleton(true);
    } else {
      setisSingleton(false);
    }
  }, [selectedInterval]);
  const [sortType, setSortType] = useState("asc");
  const handleSortDaybook = () => {
    // alert('helloooo')
    const newSortType = sortType === "asc" ? "desc" : "asc";
    setSortType(newSortType);

    dispatch(
      // @ts-ignore
      getDaybookItems({
        sortType: newSortType,
        userId:
          pathname.includes("clients") ||
          pathname.includes("staff") ||
          pathname.includes("prospects") ||
          pathname.includes("business") ||
          pathname.includes("teams") ||
          pathname.includes("contacts") ||
          pathname.includes("locations")
            ? clientId
            : userId,

        date:
          selectedSortTab.id !== 5
            ? selectedSortTab?.value
            : // @ts-ignore
              `${date.getFullYear()}-${prefixDate(
                // @ts-ignore
                date.getMonth() + 1
                // @ts-ignore
              )}-${date.getDate()}`,
        tenantId: providerId,
      })
    );
  };

  // // console.log(taskIndex, "taskIndex");
  const handeCloseSelectedDaybookItem = () => {
    if (selectedDayBookItem?.task?.id) {
      const conditionUpdate =
        updatePopUpRef.current &&
        // @ts-ignore
        !updatePopUpRef.current?.contains(event.target);

      const conditionDelete =
        deletePopupRef.current &&
        // @ts-ignore
        !deletePopupRef.current?.contains(event.target);
      if (conditionUpdate) {
        // setIsModify(false);
        // setSelectedDayBookItem(null);
        setSelectedInterval("");
        setSelectedRepetitive("");
      }
      if (conditionDelete) {
        dispatch(resetConfirmDeleteTask());
        // setSelectedDayBookItem(null);
        setSelectedInterval("");
        setSelectedRepetitive("");
      }
      if (!isModify) {
        setSelectedDayBookItem(null);
      }
      if (isModify) {
        // setSelectedDayBookItem(null);
        if (selectedDayBookItem?.task?.title === "") {
          toast.warn("Can't save without a title.");
        } else {
          setisAddingFromCrossUpdate(true);
        }
      }
    } else {
      if (selectedDayBookItem?.task?.title === "") {
        dispatch(
          // @ts-ignore
          getDaybookItems({
            userId:
              pathname.includes("clients") ||
              pathname.includes("staff") ||
              pathname.includes("prospects") ||
              pathname.includes("business") ||
              pathname.includes("teams") ||
              pathname.includes("contacts") ||
              pathname.includes("locations")
                ? clientId
                : userId,
            date: selectedSortTab?.id !== 5 ? selectedSortTab?.value : "",
            tenantId: providerId,
          })
        );
      } else {
        setisAddingFromCross(true);
      }
      setSelectedDayBookItem(null);
    }
    setTagStatus("collapsed");
  };

  // const isDeleteDaybookTaskActive = useAppSelector(
  //   (state) => state?.daybook?.hasDeleteTask
  // );

  const daybookIdToBeDeleted = useAppSelector(
    (state) => state?.daybook?.idOfTaskToDelete
  );

  var tempDayBookList = JSON.parse(JSON.stringify(dayBookList));

  useEffect(() => {
    setFinalStartTime(
      tempDayBookList[dayBookIndex]?.task[dayBookTaskIndex]?.start_time
    );
  }, [tempDayBookList]);

  useEffect(() => {
    if (!finalEndTime || finalEndTime.includes("--:--")) {
      setFinalEndTime(
        tempDayBookList[dayBookIndex]?.task[dayBookTaskIndex]?.end_time
      );
    }
  }, [tempDayBookList]);


  // useEffect(() => {
  //   if(selectedDayBookItem?.task){
  //     dispatch(reselectedServiceType())

  //   }
  // },[selectedDayBookItem])

  useEffect(() => {
    if (selectedSortDateCustom) {
      setStartDate(selectedSortDateCustom);
    }
  }, [selectedSortDateCustom]);

  useEffect(() => {
    if (selectedSortDateCustom) {
      setEndDate(selectedSortDateCustom);
    }
  }, [selectedSortDateCustom]);

  useEffect(() => {
    if (selectedSortTab) {
      setStartDate(
        selectedSortTab?.id === 5 ? customDate : new Date(selectedSortTab.value)
      );
    }
  }, [selectedSortTab]);
  // console.log(selectedSortTab.value, "vallllllllllllll");
  useEffect(() => {
    if (selectedSortDateCustom) {
      setEndDate(new Date(selectedSortTab?.value));
    }
  }, [selectedSortTab]);

  useEffect(() => {
    setDayBookList(dayBookList);
  }, [dayBookList]);

  //////////////////////////////////////////////////////////////////////
  let selectedDaybookItemId = selectedDayBookItem?.task?.id;

  useEffect(() => {
    if (selectedDaybookItemId) {
      setuploadedFiles(selectedDayBookItem?.task?.files);
    }
  }, [selectedDaybookItemId]);

  useEffect(() => {
    if (daybookIdToBeDeleted) {
      setDaybookTaskId(daybookIdToBeDeleted);
      setSelectedInterval(selectedDayBookItem?.task?.period_picker);
    }
  }, [daybookIdToBeDeleted]);

  useEffect(() => {
    setSelectedSortDate(new Date());
  }, []);

  useEffect(() => {
    if (
      selectedSortTab.id !== 5 ||
      (selectedSortTab.id === 5 && selectedSortDateCustom)
    ) {
      let date = "";
      if (selectedSortTab.id === 5 && selectedSortDateCustom) {
        // @ts-ignore
        date = new Date(selectedSortDateCustom);
      }
      if (
        pathname.includes("clients") ||
        pathname.includes("staff") ||
        pathname.includes("prospects") ||
        pathname.includes("business") ||
        pathname.includes("teams") ||
        pathname.includes("contacts") ||
        pathname.includes("locations")
      ) {
        clientId &&
          dispatch(
            // @ts-ignore
            getDaybookItems({
              userId: clientId,
              date:
                selectedSortTab.id !== 5
                  ? selectedSortTab?.value
                  : // @ts-ignore
                    `${date.getFullYear()}-${prefixDate(
                      // @ts-ignore
                      date.getMonth() + 1
                      // @ts-ignore
                    )}-${date.getDate()}`,
              tenantId: providerId,
            })
          );
      } else if (userId) {
        dispatch(
          // @ts-ignore
          getDaybookItems({
            userId: userId,
            date:
              selectedSortTab.id !== 5
                ? selectedSortTab?.value
                : // @ts-ignore
                  `${date.getFullYear()}-${prefixDate(
                    // @ts-ignore
                    date.getMonth() + 1
                    // @ts-ignore
                  )}-${date.getDate()}`,
          })
        );
      }
    }
  }, [
    selectedSortTab,
    refreshAttempt,
    clientId,
    userId,
    selectedSortDateCustom,
    trafficColors,
  ]);

  let elem = dayBookList[dayBookIndex]?.task[dayBookTaskIndex];

  //   let dataArr = [];
  // for(let item in dayBookListRedux){
  //   let dataArr = [];
  // for(let item in dayBookListRedux){

  //     let tempObj = {
  //         timing: item,
  //         tasksArr: dayBookListRedux[item]

  //     }
  //     dataArr.push(tempObj)
  // }
  // // @ts-ignore
  // // console.log(dataArr.map((elem) => // console.log(elem.timing, elem.tasksArr.map((item) => item.id))),'dataArray')

  // // console.log(selectedInterval,"selectedInterval")

  useEffect(() => {
    if (dayBookListRedux) {
      const parsedDayBookList =
        Object.keys(dayBookListRedux).length === 0
          ? [{ time: "Today", task: [] }]
          : Object.keys(dayBookListRedux).map((key) => {
              return {
                time: key === "all" ? "Today" : key,
                task: dayBookListRedux[key],
              };
            });
      setDayBookList(parsedDayBookList);
    }
  }, [dayBookListRedux]);

  // const tempDayBookList = JSON.parse(JSON.stringify(dayBookList));
  // @ts-ignore
   function prefixDate(date) {
    return date < 10 ? "0" + date : date;
  }

  const [isT1Open, setisT1Open] = useState(-1);
  const [isT2Open, setisT2Open] = useState(-1);

  // @ts-ignore
  function formatDateForAPI(date) {
    const year = date?.getFullYear();
    const month = prefixDate(date?.getMonth() + 1);
    const day = prefixDate(date?.getDate());
    return `${year}-${month}-${day}`;
  }

  const {selectedServiceType} = useAppSelector((state) => state?.daybook)

  const handleAddTask = (daybookItem: any) => {
    setselectedItemsArr([])

    if (selectedDayBookItem?.task) {
      toast.warn("Can't open another task while current one is open");
      return;
    }
    dispatch(reselectedServiceType());
    setlocalStateTrafficLight(1);
    setShowModal(false);
    if (daybookItem?.time !== "Today") {
      setStartTime(daybookItem?.time);
      setEndTime(addOneHour(daybookItem?.time));
      setFinalEndTime(addOneHour(daybookItem?.time));
    } else {
      setStartTime("");
      setEndTime("");
      setFinalEndTime("");
    }
    setuploadedFiles([]);

    const initialStartDate =
      selectedSortTab?.id === 5
        ? selectedSortDateCustom
        : new Date(selectedSortTab?.value);
    setStartDate(initialStartDate);

    setSelectedDuration("");
    if (selectedTags?.length > 0) setSelectedTags([]);

    setSelectedRepetitive("");
    setSelectedInterval("None");
    // if (selectedDayBookItem) {
    //   toast.warn("Can't create another task until active one is saved.");
    //   return;
    // }

    const tempDayBookList = JSON.parse(JSON.stringify(dayBookList));

    let itemIndex = tempDayBookList.findIndex(
      (item: any) => item.time === daybookItem.time
    );

    setselectedDate(initialStartDate);

    const newTask = {
      title: "",
      summary: "",
      communications: "",
      tag: [],
      tagged_user: [],
      document_files: keysArray || [],
      date: initialStartDate
        ? formatDateForAPI(initialStartDate)
        : selectedSortTab?.id === 5
        ? formatDateForAPI(selectedSortDateCustom)
        : selectedSortTab?.value,
      start_time:
        tempDayBookList[itemIndex]?.time === "Today"
          ? ""
          : tempDayBookList[itemIndex]?.time,
      end_time:
        tempDayBookList[itemIndex]?.time === "Today"
          ? ""
          : tempDayBookList[itemIndex]?.time.replace(" ", ""),
      assigned_to: isClientsOrStaffScreen
        ? clientsDataForAssignedTo.find((el) => el.id == clientId)
        : clientsDataForAssignedTo.find((el) => el.id == userId),
      service_type_id: "",
    };

    if (tempDayBookList[itemIndex]?.task?.length) {
      tempDayBookList[itemIndex].task.unshift(newTask);
    } else if (tempDayBookList?.length > 0) {
      tempDayBookList[itemIndex].task = [newTask];
    }

    setDayBookList(tempDayBookList);

    setSelectedDayBookItem({
      time: tempDayBookList[itemIndex].time || "",
      task: tempDayBookList[itemIndex].task[0] || null,
      taskIndex: 0,
    });
    // setStartTime('');
    // setEndTime('');
    // setTrafficColors('#fbbf14');
    // setShowLights(false);
  };
  // // console.log(dayBookList,"dayBookList")

  useEffect(() => {
    if (salesPath) {
      setsalesPathLive(true);
    }
  }, [salesPath]);

  const {loading} = useAppSelector((state) => state?.daybook)

  const [salesPathLive, setsalesPathLive] = useState(false);
  const [taskAdded, setTaskAdded] = useState(false);
  const [keysArray, setKeysArray] = useState<any>([]);
  const [uploadedFiles, setuploadedFiles] = useState<any>(
    selectedDayBookItem && selectedDayBookItem?.task?.files
      ? selectedDayBookItem?.task?.files
      : []
  );

  const keysArrayNewUploads = uploadedFiles?.filter(
    (elem) => elem?.key !== undefined
  );

  const newDocumentFilesArr = keysArrayNewUploads?.map((elem) => ({
    actual_file_name: elem?.file ? elem?.file.name : "",
    file_name: elem?.key,
  }));

  useEffect(() => {
    if (uploadedFiles) {
      const extractedKeys = uploadedFiles.map((elem: any) => elem?.key);
      const extractedNames = uploadedFiles.map((elem: any) => elem?.file?.name);

      const newArray = extractedKeys.map((key: any, index: number) => ({
        file_name: key,
        actual_file_name: extractedNames[index],
      }));

      setKeysArray(newArray);
      if (daybookTaskId) {
        setSelectedDayBookItem((prevDetails: any) => ({
          ...prevDetails,
          task: {
            ...prevDetails.task,
            document_files:
              newArray.filter((file) => Object.keys(file).length !== 0) || [],
          },
        }));
      }
    }
  }, [uploadedFiles]);

  useEffect(() => {
    if (salesPathLive && !taskAdded) {
      const daybookItem = {
        time: "Today",
        title: "",
        summary: "",
        communications: "",
        tag: [],
        tagged_user: [],
        date: "",
        start_time: "",
        end_time: "",
        assigned_to: "",
        period_picker: "",
        repetitive_duration: 1,
      };
      const tempDayBookList = JSON.parse(JSON.stringify(dayBookList));

      // @ts-ignore
      const itemIndex = tempDayBookList.findIndex(
        (item: any) => item.time === daybookItem.time
      );

      const newTask = {
        title: "",
        summary: "",
        communications: "",
        tag: [],
        tagged_user: [],
        date: "",
        start_time: "",
        end_time: "",
        assigned_to: "",
        period_picker: "",
        repetitive_duration: 1,
      };

      if (itemIndex !== -1) {
        if (tempDayBookList[itemIndex]?.task?.length) {
          tempDayBookList[itemIndex].task.unshift(newTask);
        } else {
          tempDayBookList[itemIndex].task = [newTask];
        }
      } else {
        tempDayBookList.push({
          ...daybookItem,
          task: [newTask],
        });
      }

      // @ts-ignore
      setSelectedDayBookItem({
        time: daybookItem.time,
        task: newTask,
        taskIndex: 0,
      });
      setTaskAdded(true);
    }
  }, [salesPath, dayBookList, taskAdded]);

  const handleAddTaskOnLoad = () => {
    if (salesPath) {
      setsalesPathLive(true);
    }
  };

  // prevent function from going into loop..........
  useEffect(() => {
    handleAddTaskOnLoad();
  }, []);

  const handleStartDateDaybook = (date) => {
    setStartDate(date);
  };

  // console.log('uploadedFiles', uploadedFiles);
  //  get file from s3 bucket
  const handleGetFile = async (name: string | any) => {
    try {
      const response = await api.get(`files/get_file?file=${name}`);
      const fileUrl = response?.data?.data;
      window.open(fileUrl, "_blank");
    } catch (error) {
      console.error("Error fetching file:", error);
    }
  };
  useEffect(() => {
    setCreateCommsData({
      link_id: selectedDayBookItem?.task?.id,
      summary: "",
      message: selectedMessage,
      comm_type: "DAYBOOK",
      comm_document_files: filesWhenClickedFromCommsBox,
    });
  }, [
    selectedDayBookItem?.task?.id,
    selectedMessage,
    keysArray,
    filesWhenClickedFromCommsBox,
  ]);

  let isInUserType =
    pathname.includes("clients") ||
    pathname.includes("staff") ||
    pathname.includes("prospects") ||
    pathname.includes("business") ||
    pathname.includes("teams") ||
    pathname.includes("contacts") ||
    pathname.includes("locations");

  // console.log('isInuserty', isInUserType);

  let isDuplicatePending = false;

  const handleDuplicateTask = async (
    dayBookTask: any,
    dayBookTaskIndex: any
  ) => {
    const duplicatedTask = {
      ...dayBookTask,
      title: selectedDayBookItem?.task?.title + " - copy",
    };

    // console.log('startDate', startDate);
    // Duplicate the task in the task list
    const updatedDayBookList = [...dayBookList];
    const updatedDayBook = { ...updatedDayBookList[dayBookTaskIndex] };
    const updatedTasks = [...updatedDayBook?.task];
    updatedTasks.splice(dayBookTaskIndex + 1, 0, duplicatedTask);
    updatedDayBook.task = updatedTasks;
    updatedDayBookList[dayBookTaskIndex] = updatedDayBook;

    setDayBookList(updatedDayBookList);

    setIsModify(true);
    if (selectedDayBookItem?.task?.title == "") {
      setSelectedDayBookItem(null);
      setSelectedInterval("");
      setSelectedRepetitive("");

      return;
    }
    let requiredParams = {
      ...selectedDayBookItem?.task,
      title: selectedDayBookItem?.task?.title + " - copy",
      tag: selectedTags || [],
      tagged_user:
        typeof selectedDayBookItem?.task?.tagged_user === "string"
          ? dayBookTask.tagged_user
              .split(",")
              .map((item: string) => parseInt(item.id))
          : dayBookTask.tagged_user.map((el) => el.id) || [],
      assigned_to: isClientsOrStaffScreen
        ? clientId
        : (selectedDayBookItem?.task?.assigned_to).map((el) => el?.id) ||
          userId,
      start_time:
        startTime === "--:--AM" || startTime === "--:--PM"
          ? ""
          : startTime === "Today"
          ? ""
          : startTime?.replace(" ", ""),

      end_time:
        endTime === "--:--AM" || endTime === "--:--PM"
          ? ""
          : endTime?.replace(" ", ""),
      communications: [{ summary: "", message: selectedMessage }],
      document_files: keysArray || [],
      date: startDate
        ? formatDateForAPI(startDate)
        : selectedDayBookItem.task.date
        ? selectedDayBookItem.task.date
        : selectedSortTab.id == 5
        ? `${new Date(chooseDate)?.getFullYear()}-${prefixDate(
            new Date(chooseDate)?.getMonth() + 1
          )}-${new Date(chooseDate)?.getDate()}`
        : selectedSortTab.value,
      end_date: endDate
        ? formatDateForAPI(endDate)
        : selectedDayBookItem?.task?.date
        ? selectedDayBookItem?.task?.date
        : selectedSortTab.id == 5
        ? `${new Date(chooseDate)?.getFullYear()}-${prefixDate(
            new Date(chooseDate)?.getMonth() + 1
          )}-${new Date(chooseDate)?.getDate()}`
        : selectedSortTab?.value,
      traffic_light:
        trafficColors == "#ef3a34"
          ? 0
          : trafficColors == "#fbbf14"
          ? 1
          : trafficColors == "#2ad400"
          ? 2
          : 1,
      period_picker: selectedInterval ? selectedInterval : "None",
      repetitive_duration: Number(selectedRepetitive),
      note_details: {},
    };

    if (endTime !== "" && startTime == "") {
      toast.warning("Can't have end time without having start time!");
      return;
    }
    if (isDuplicatePending) return;
    try {
      isDuplicatePending = true;
      const response = await dispatch(createDaybookItem(requiredParams));
      if (response?.meta?.requestStatus === "fulfilled") {
        isDuplicatePending = false;
        <SuccessToastComponent title={"Task Added Successfully!"} />;
        dispatch(
          // @ts-ignore
          getDaybookItems({
            userId:
              pathname.includes("clients") ||
              pathname.includes("staff") ||
              pathname.includes("prospects") ||
              pathname.includes("business") ||
              pathname.includes("teams") ||
              pathname.includes("contacts") ||
              pathname.includes("locations")
                ? clientId
                : userId,
            date:
              selectedSortTab.id !== 5
                ? selectedSortTab?.value
                : // @ts-ignore
                  `${date.getFullYear()}-${prefixDate(
                    // @ts-ignore
                    date.getMonth() + 1
                    // @ts-ignore
                  )}-${date.getDate()}`,
            tenantId: providerId,
          })
        );
      }
      if (response?.meta?.requestStatus === "rejected") {
        toast.error("Repetative task should have an interval selected.");
      }
    } catch (error) {}
    // setSelectedDayBookItem(null);
    dispatch(getUserNotificationStats());
  };

  const [selectedItemsArr, setselectedItemsArr] = useState<any>([]);

  useEffect(() => {
    if (selectedDayBookItem && Array.isArray(selectedDayBookItem?.task?.tagged_person)) {

      setselectedItemsArr(selectedDayBookItem?.task?.tagged_person);
    }
  }, [selectedDayBookItem]);

  // console.log('selectedDayBookItem', selectedDayBookItem);
  const { allUserTypeData } = useAppSelector((state) => state.comms);

  const handleSelectTaggedPeople = async (item) => {
    const selectedItem = allUserTypeData.find(
      (elem) =>
        elem?.first_name?.toLowerCase() === item?.first_name?.toLowerCase() ||
        elem?.last_name?.toLowerCase() === item?.last_name?.toLowerCase()
    );
    if (selectedItem && Array.isArray(selectedItemsArr)) {

      const tempArr = [selectedItem, ...selectedItemsArr];
      // console.log('tempArr', tempArr);
      setselectedItemsArr(tempArr);
      // setisSearching(false);

      const selectedItemsId = tempArr.map((elem) => elem?.id);

      const selectedTaggedUsers = {
        link_uid: selectedDayBookItem?.task?.id,
        comm_type: "DAYBOOK",
        tagged_users: selectedItemsId,
      };

     let res = await dispatch(updateTaggedUsersThunk(selectedTaggedUsers));
     if(res?.error?.message){
      toast.warn(res?.payload)
            }
    }
  };

  const sendComms = async () => {
    if(!(selectedDayBookItem?.task?.id)){
          toast.warn("Please save to send this comm.")
         return
    }
    try {
      const res = await dispatch(createDocumentCommsThunk(createCommsData));
      if (res?.payload?.status === "success") {
        toast.success("Message Sent Successfully!", {
          progressStyle: { backgroundColor: dynamicColor },
          icon: (
            <span
              style={{
                marginRight: "8px",
                borderRadius: "50%",
                width: "7rem",
                height: "35px",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: dynamicColor,
                backgroundColor: "white",
                fontSize: "1.5rem",
                border: `3px solid ${dynamicColor}`,
              }}
            >
              ✓
            </span>
          ),
        });
        // setSelectedDayBookItem(null);
      }
      setisThereMessage("");
      setRefreshAttempt(refreshAttempt + 1);
      setSelectedMessage("");
    } catch (err) {
      console.error(err);
      setisThereMessage("");
      setRefreshAttempt(refreshAttempt + 1);
      setSelectedMessage("");
    }
    // setSelectedDayBookItem(null);
   
  };

  useEffect(() => {
    setshowDateTimePickerDaybook(false);
  }, [selectedDayBookItem]);

  const handleTimeChange = (
    type: "task-start-time" | "task-end-time",

    dayBookIndex: any,
    dayBookTaskIndex: any,
    value: any
  ) => {
    let tempDayBookList = JSON.parse(JSON.stringify(dayBookList));
    if (type === "task-start-time") {
      tempDayBookList[dayBookIndex].task[dayBookTaskIndex].start_time = value;
      setStartTime(value);
    } else if (type === "task-end-time") {
      tempDayBookList[dayBookIndex].task[dayBookTaskIndex].end_time = value;
      setEndTime(value);
    }
    //  console.log(' tempDayBookList[dayBookIndex].task[dayBookTaskIndex].end_time',  tempDayBookList[dayBookIndex].task[dayBookTaskIndex].end_time);
    setDayBookList(tempDayBookList);
  };

  const changeTags = (
    type: any,
    dayBookIndex: any,
    dayBookTaskIndex: any,
    value: any
  ) => {
    setIsModify(true)
    let tempDayBookList = JSON.parse(JSON.stringify(dayBookList));
    setSelectedTags(value);

    tempDayBookList[dayBookIndex].task[dayBookTaskIndex].tags = value;
    setDayBookList(tempDayBookList);
  };

  // console.log('isModify', isModify);

  const handleTaskItemChange = (
    type:
      | "task-title"
      | "task-summary"
      | "task-comms"
      | "task-tagged-people"
      | "task-date"
      | "task-start-time"
      | "task-end-time"
      | "task-assigned-to"
      | "task-editor"
      | "task-service-type",
    dayBookIndex: any,
    dayBookTaskIndex: any,
    value: any
  ) => {
    setIsModify(true);
    dispatch(resetDaybookTags());
    setSelectedDayBookItem(null);
    setSelectedMessage("");
    let tempDayBookList = JSON.parse(JSON.stringify(dayBookList));

    if (
      !tempDayBookList[dayBookIndex] ||
      !tempDayBookList[dayBookIndex].task ||
      !tempDayBookList[dayBookIndex].task[dayBookTaskIndex]
    ) {
      console.error("Task or task index is not defined");
      // return;
    }

    if (type === "task-title") {
      tempDayBookList[dayBookIndex].task[dayBookTaskIndex].title = value;
    } else if (type === "task-summary") {
      tempDayBookList[dayBookIndex].task[dayBookTaskIndex].summary = value;
    } else if (type === "task-comms") {
      tempDayBookList[dayBookIndex].task[dayBookTaskIndex].communications =
        value;
    } else if (type === "task-tagged-people") {
      tempDayBookList[dayBookIndex].task[dayBookTaskIndex].tagged_user = value;
    } else if (type === "task-assigned-to") {
      tempDayBookList[dayBookIndex].task[dayBookTaskIndex].assigned_to =
        value[0];
    } else if (type === "task-date") {
      setselectedDate(value);
      const date = new Date(value);
      tempDayBookList[dayBookIndex].task[
        dayBookTaskIndex
      ].date = `${date.getFullYear()}-${prefixDate(
        date.getMonth() + 1
      )}-${prefixDate(date.getDate())}`;
    } else if (type === "task-start-time") {
      tempDayBookList[dayBookIndex].task[dayBookTaskIndex].start_time = value;
    } else if (type === "task-end-time") {
      tempDayBookList[dayBookIndex].task[dayBookTaskIndex].end_time = value;
    } else if (type === "task-service-type") {
      tempDayBookList[dayBookIndex].task[dayBookTaskIndex].service_type_id =
        value;
    } else if (type === "task-editor") {
      setEditorState(value);
      const contentState = value.getCurrentContent();
      const rawContentState = convertToRaw(contentState);
      const newObject = {
        entityMap: rawContentState.entityMap,
        blocks: rawContentState.blocks.map((block) => ({
          key: block.key,
          text: block.text,
          type: block.type,
          depth: block.depth,
          inlineStyleRanges: block.inlineStyleRanges,
          entityRanges: block.entityRanges,
        })),
      };
      setisThereMessage(newObject);
      const contentString = JSON.stringify(newObject);
      tempDayBookList[dayBookIndex].task[dayBookTaskIndex].communications =
        contentString;
    }

    setDayBookList(tempDayBookList);

    if (tempDayBookList[dayBookIndex]?.task[dayBookTaskIndex]?.id) {
      setIsModify(true);
    }

    setSelectedDayBookItem({
      ...selectedDayBookItem,
      time: tempDayBookList[dayBookIndex].time,
      task: tempDayBookList[dayBookIndex].task[dayBookTaskIndex],
      taskIndex: dayBookTaskIndex,
    });

    setSelectedTags([]);
  };

  const [selectedTags, setSelectedTags] = useState<any>(
    dayBookList[dayBookIndex]?.task[dayBookTaskIndex]
      ? dayBookList[dayBookIndex]?.task[dayBookTaskIndex]?.tags
      : []
  );
  let selectedDayBookItemTask = selectedDayBookItem?.task;
  useEffect(() => {
    if (selectedDayBookItemTask?.tags) {
      setSelectedTags(
        selectedDayBookItemTask?.tags?.map((res: any) => res?.id)
      );
    }
  }, [selectedDayBookItemTask?.tags]);

  // // console.log(selectedTags,"selectedTags")

  let isNewDaybookPending = false;


  const addDayBookItem = async () => {
    if (endDate < startDate) {
      var endDateChosen = startDate
        ? formatDateForAPI(startDate)
        : selectedDayBookItem.task.date
        ? selectedDayBookItem.task.date
        : selectedSortTab.id == 5
        ? `${new Date(chooseDate).getFullYear()}-${prefixDate(
            new Date(chooseDate).getMonth() + 1
          )}-${new Date(chooseDate).getDate()}`
        : selectedSortTab.value;
    } else {
      var endDateChosen = formatDateForAPI(endDate);
    }
    if (selectedDayBookItem?.task?.title == "") {
      // setSelectedDayBookItem(null);
      setSelectedInterval("");
      setSelectedRepetitive("");

      // return;
    }

    if (startTime && endTime) {
      let startTimeSplite = startTime.split(":");
      let endTimeSplite = endTime.split(":");
      let parseIntStartHours = parseInt(startTimeSplite[0]);
      let parseIntEndHours = parseInt(endTimeSplite[0]);
      let startMinutes = startTimeSplite[1].substring(0, 2);
      let endMinutes = endTimeSplite[1].substring(0, 2);

      if (startTime.includes("AM") && endTime.includes("AM")) {
        if (parseIntStartHours > parseIntEndHours) {
        } else if (parseIntStartHours == parseIntEndHours) {
          if (parseInt(startMinutes) >= parseInt(endMinutes)) {
            // toast.warning("Please select the future end time.");
          }
        }
      } else if (startTime.includes("AM") && endTime.includes("PM")) {
      } else if (startTime.includes("PM") && endTime.includes("AM")) {
        // toast.warning("Please select the future end time.");
        // return;
      } else if (startTime.includes("PM") && endTime.includes("PM")) {
        if (parseIntStartHours > parseIntEndHours) {
          // toast.warning("Please select the future end time.");
        } else if (parseIntStartHours == parseIntEndHours) {
          if (parseInt(startMinutes) >= parseInt(endMinutes)) {
            // toast.warning("Please select the future end time.");
          }
        }
      }
    }
    if (selectedDayBookItem?.task?.title == "") {
      toast.warn("Can't create an event without a title");
      return;
    }

    if((selectedMessage == "") && selectedItemsArr.length >= 1 ){
      toast.info("Please add a comm to be able to save tagged people!")
      return
    }

    let requiredParmas = {
      ...selectedDayBookItem.task,
      service_type_id: serviceType?.id || selectedServiceType?.id,
      tag:
        tempDayBookList[dayBookIndex]?.task[dayBookTaskIndex]?.tags?.map(
          (elem) => elem?.id
        ) ||
        selectedTags.map((el) => el?.id) ||
        [],
      tagged_user: selectedDayBookItem.task.tagged_user || userId,
      assigned_to: ( selectedDayBookItem?.task?.assigned_to?.id) || isClientsOrStaffScreen
        ? clientId
        : selectedDayBookItem?.task?.assigned_to?.id || userId,

      key_person: selectedDayBookItem?.task?.key_person?.map((elem) => elem.id),
      tagged_user: selectedDayBookItem?.task?.tagged_user?.map(
        (elem) => elem.id
      ),
      tagged_person: selectedItemsArr?.map((elem) => elem?.id),
      start_time:
        startTime === "--:--AM" || startTime === "--:--PM"
          ? ""
          : startTime === "Today"
          ? ""
          : startTime === "To:ayay"
          ? ""
          : startTime.replace(" ", ""),
      end_time:
        endTime === "--:--AM" || endTime === "--:--PM"
          ? ""
          : endTime.replace(" ", ""),
      communications: [
        {
          summary: "",
          message: selectedMessage,
          comm_document_files: filesWhenClickedFromCommsBox || [],
        },
      ],
      // date: startDate
      //   ? formatDateForAPI(startDate)
      //   : selectedDayBookItem.task.date
      date: startDate
        ? formatDateForAPI(startDate)
        : selectedDayBookItem.task.date
        ? selectedDayBookItem.task.date
        : selectedSortTab.id == 5
        ? `${new Date(chooseDate).getFullYear()}-${prefixDate(
            new Date(chooseDate).getMonth() + 1
          )}-${new Date(chooseDate).getDate()}`
        : selectedSortTab.value,
      traffic_light: localStateTrafficLight,
      period_picker: selectedInterval ? selectedInterval : "None",
      repetitive_duration: selectedRepetitive ? selectedRepetitive : 1,
      document_files: keysArray || [],
      end_date: startDate
        ? formatDateForAPI(startDate)
        : selectedDayBookItem?.task.date
        ? selectedDayBookItem?.task.date
        : selectedSortTab.id == 5
        ? `${new Date(chooseDate).getFullYear()}-${prefixDate(
            new Date(chooseDate).getMonth() + 1
          )}-${new Date(chooseDate).getDate()}`
        : selectedSortTab.value,
      note_details: {},

      // document_files: keysArray || [],
    };

    if (endTime !== "" && startTime == "") {
      // toast.warning("Can't have end time without having start time!");
      // return;
    }
    // if (isNewDaybookPending) return;
    try {
      isNewDaybookPending = true;
      const response = await dispatch(createDaybookItem(requiredParmas));
   if(selectedMessage){
    if (response?.meta?.requestStatus === "fulfilled") {
      isNewDaybookPending = false;
      toast.success("Comm and Task added successfully!", {
        // style: {},
        progressStyle: { backgroundColor: dynamicColor },
        icon: (
          <span
            style={{
              marginRight: "8px",
              borderRadius: "50%",
              width: "7rem",
              height: "35px",
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: dynamicColor,
              backgroundColor: "white",
              fontSize: "1.5rem",
              border: `3px solid ${dynamicColor}`,
            }}
          >
            ✓
          </span>
        ),
      });
      setRefreshAttempt(refreshAttempt + 1);
      setSelectedDayBookItem(null);
      setStartTime("");
      setEndTime("");
      setSelectedRepetitive("");
      setSelectedInterval("");
    }
   }else{
    if (response?.meta?.requestStatus === "fulfilled") {
      isNewDaybookPending = false;
      toast.success("Task added successfully!", {
        // style: {},
        progressStyle: { backgroundColor: dynamicColor },
        icon: (
          <span
            style={{
              marginRight: "8px",
              borderRadius: "50%",
              width: "7rem",
              height: "35px",
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: dynamicColor,
              backgroundColor: "white",
              fontSize: "1.5rem",
              border: `3px solid ${dynamicColor}`,
            }}
          >
            ✓
          </span>
        ),
      });
      setRefreshAttempt(refreshAttempt + 1);
      setSelectedDayBookItem(null);
      setStartTime("");
      setEndTime("");
      setSelectedRepetitive("");
      setSelectedInterval("");
    }
   }
    
      if (response?.meta?.requestStatus === "rejected") {
        toast.error(response?.payload);
        dispatch(
          // @ts-ignore
          getDaybookItems({
            userId:
              pathname.includes("clients") ||
              pathname.includes("staff") ||
              pathname.includes("prospects") ||
              pathname.includes("business") ||
              pathname.includes("teams") ||
              pathname.includes("contacts") ||
              pathname.includes("locations")
                ? clientId
                : userId,
            date:
              selectedSortTab.id !== 5
                ? selectedSortTab?.value
                : // @ts-ignore
                  `${date.getFullYear()}-${prefixDate(
                    // @ts-ignore
                    date.getMonth() + 1
                    // @ts-ignore
                  )}-${date.getDate()}`,
            tenantId: providerId,
          })
        );
      }
    } catch (error) {
      setSelectedDayBookItem(null);
      throw error;
    }
    dispatch(getUserNotificationStats());
setIsModify(false)

setselectedItemsArr([])
    setSelectedDayBookItem(null);
  };

  function ensureDaybookTagsArray(tags) {
    if (!Array.isArray(tags)) {
      return Object.values(tags).map(Number);
    } else {
      return tags.map(Number);
    }
  }

  const updateDayBookItem = async () => {
    const startTime = finalStartTime;
    const endTime = tempDayBookList[dayBookIndex]?.task[dayBookTaskIndex]?.end_time;


    setIsModify(false)
    setisModifyAndCross(false);

    if (selectedDayBookItem?.task?.title == "") {
      setSelectedDayBookItem(null);
      return;
    }

    if (
      selectedDayBookItem?.task?.title !== "" &&
      endTime !== "" &&
      startTime == ""
    ) {
      toast.warning("Can't have end time without having start time");
      return;
    }

    if (startTime && endTime) {
      let startTimeSplite = startTime.split(":");
      let endTimeSplite = endTime.split(":");
      let parseIntStartHours = parseInt(startTimeSplite[0]);
      let parseIntEndHours = parseInt(endTimeSplite[0]);
      let startMinutes = startTimeSplite[1].substring(0, 2);
      let endMinutes = endTimeSplite[1].substring(0, 2);

      if (startTime.includes("AM") && endTime.includes("AM")) {
        if (parseIntStartHours > parseIntEndHours) {
        } else if (parseIntStartHours == parseIntEndHours) {
          if (parseInt(startMinutes) >= parseInt(endMinutes)) {
          }
        }
      } else if (startTime.includes("AM") && endTime.includes("PM")) {
      } else if (startTime.includes("PM") && endTime.includes("AM")) {
        return;
      } else if (startTime.includes("PM") && endTime.includes("PM")) {
        if (parseIntStartHours > parseIntEndHours) {
          // toast.warning("Please select the future end time.");
          // return;
        } else if (parseIntStartHours == parseIntEndHours) {
          if (parseInt(startMinutes) >= parseInt(endMinutes)) {
            // toast.warning("Please select the future end time.");
            // return;
          }
        }
      }
    }

    if (!isModify && keysArrayNewUploads.length === 0) {
      setSelectedDayBookItem(null);
      setSelectedInterval("");
      setSelectedRepetitive("");
      setSelectTypeSave("");
      // return;
    }
    // console.log('selectedTags', selectedTags);

    // alert(startTime)
    let requiredParmas = {
      ...selectedDayBookItem?.task,
      service_type_id: serviceType?.id,
      tag: tempDayBookList[dayBookIndex]?.task[dayBookTaskIndex]?.tags?.map(
        (elem) => elem?.id
      ),
      document_files: newDocumentFilesArr || [],
      tagged_user:
        typeof selectedDayBookItem?.task.tagged_user === "string"
          ? selectedDayBookItem?.task.tagged_user
              .split(",")
              .map((item) => parseInt(item))
          : selectedDayBookItem?.task.tagged_user || [],
      assigned_to: selectedDayBookItem?.task?.assigned_to?.id ||( isClientsOrStaffScreen
      ? clientId
      : selectedDayBookItem?.task?.assigned_to?.id || userId),
      key_person: selectedDayBookItem?.task?.key_person?.map((elem) => elem.id),
      tagged_user: selectedDayBookItem?.task?.tagged_user?.map(
        (elem) => elem.id
      ),
      tagged_person: selectedDayBookItem?.task?.tagged_person?.map(
        (elem) => elem.id
      ),
      daybook_id: selectedDayBookItem?.task.id,
      start_time:
        startTime === "--:--AM" || startTime === "--:--PM"
          ? ""
          : startTime === "Today"
          ? ""
          : convertTimeFormat(startTime),
      end_time:
        endTime === "--:--AM" || endTime === "--:--PM"
          ? ""
          : endTime?.replace(" ", ""),
      save_type: "Current",
      date: startDate
        ? formatDateForAPI(startDate)
        : selectedDayBookItem?.task.date
        ? selectedDayBookItem?.task.date
        : selectedSortTab.id == 5
        ? `${new Date(chooseDate).getFullYear()}-${prefixDate(
            new Date(chooseDate).getMonth() + 1
          )}-${new Date(chooseDate).getDate()}`
        : selectedSortTab.value,
      traffic_light: localStateTrafficLight,
      period_picker: selectedInterval,
      repetitive_duration: selectedRepetitive,
      note_details: {},
      end_date: startDate
        ? formatDateForAPI(startDate)
        : selectedDayBookItem?.task.date
        ? selectedDayBookItem?.task.date
        : selectedSortTab.id == 5
        ? `${new Date(chooseDate).getFullYear()}-${prefixDate(
            new Date(chooseDate).getMonth() + 1
          )}-${new Date(chooseDate).getDate()}`
        : selectedSortTab.value,
    };

    try {
      const response = await api.post("/daybooks/update_daybook", {
        ...requiredParmas,
      });

      if (response?.meta?.requestStatus === "fulfilled") {
        toast.success("Task updated successfully!", {
          progressStyle: { backgroundColor: dynamicColor },
          icon: (
            <span
              style={{
                marginRight: "8px",
                borderRadius: "50%",
                width: "7rem",
                height: "35px",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: dynamicColor,
                backgroundColor: "white",
                fontSize: "1.5rem",
                border: `3px solid ${dynamicColor}`,
              }}
            >
              ✓
            </span>
          ),
        });
        setStartTime("");
        setEndTime("");
        setRefreshAttempt(refreshAttempt + 1);
        setIsModify(false);
        setSelectTypeSave("");
        dispatch(resetDaybookTags());
      } else {
        // toast.error(
        //   response?.payload?.data?.data?.response || "Couldn't update the task."
        // );
      }
    } catch (error) {
      setIsModify(false);
      setSelectedDayBookItem(null);
      setSelectedInterval("");
      setSelectedRepetitive("");
    }

    dispatch(getUserNotificationStats());

    setSelectedDayBookItem(null);
    setSelectedInterval("");
    setSelectedRepetitive("");
    setSelectTypeSave("");

    dispatch(
      getDaybookItems({
        userId:
          pathname.includes("clients") ||
          pathname.includes("staff") ||
          pathname.includes("prospects") ||
          pathname.includes("business") ||
          pathname.includes("teams") ||
          pathname.includes("contacts") ||
          pathname.includes("locations")
            ? clientId
            : userId,
        date:
          selectedSortTab?.id !== 5
            ? selectedSortTab?.value
            : // @ts-ignore
              `${date.getFullYear()}-${prefixDate(
                // @ts-ignore
                date.getMonth() + 1
                // @ts-ignore
              )}-${date.getDate()}`,
        tenantId: providerId,
      })
    );
  };

  const deletePopupRef = useRef(null);
  const updatePopUpRef = useRef(null);

  const handleDeleteDaybook = async (props: string, dateType: string) => {
    setisModifyAndCross(false);
    if (props === "Delete") {
      return;
    }
    let condition = props === "Current" ? "send_date" : "";
    if (condition) {
      try {
        const response = await dispatch(
          deleteDaybookItem({
            id: selectedDayBookItem?.task?.id,
            save_type: props,
            date: "12" || "",
          })
        );
        if (response?.meta?.requestStatus === "fulfilled") {
          toast.success("Task Deleted Successfully!", {
            progressStyle: { backgroundColor: dynamicColor },
            icon: (
              <span
                style={{
                  marginRight: "8px",
                  borderRadius: "50%",
                  width: "7rem",
                  height: "35px",
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: dynamicColor,
                  backgroundColor: "white",
                  fontSize: "1.5rem",
                  border: `3px solid ${dynamicColor}`,
                }}
              >
                ✓
              </span>
            ),
          });
          setStartTime("");
          setEndTime("");
          setRefreshAttempt(refreshAttempt + 1);
          setIsModify(false);
          setSelectedDayBookItem(null);
          setSelectTypeDelete("");
          setSelectedInterval("");
        }
        if (response?.meta?.requestStatus === "rejected") {
          toast.error(response?.payload);
        }
      } catch (error) {
        setIsModify(false);
        setSelectedDayBookItem(null);

        throw error;
      }
    } else {
      try {
        const response = await dispatch(
          deleteDaybookItem({
            id: selectedDayBookItem?.task?.id,
            save_type: props,
          })
        );
        if (response?.meta?.requestStatus === "fulfilled") {
          toast.success("Task Deleted Successfully!", {
            progressStyle: { backgroundColor: dynamicColor },
            icon: (
              <span
                style={{
                  marginRight: "8px",
                  borderRadius: "50%",
                  width: "7rem",
                  height: "35px",
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: dynamicColor,
                  backgroundColor: "white",
                  fontSize: "1.5rem",
                  border: `3px solid ${dynamicColor}`,
                }}
              >
                ✓
              </span>
            ),
          });
          setStartTime("");
          setEndTime("");
          setRefreshAttempt(refreshAttempt + 1);
          setIsModify(false);
          setSelectedDayBookItem(null);
          setSelectTypeDelete("");
          setSelectedInterval("");
        }
        if (response?.meta?.requestStatus === "rejected") {
          toast.error(response?.payload);
        }
      } catch (error) {
        setIsModify(false);
        setSelectedDayBookItem(null);

        throw error;
      }
    }

    dispatch(resetConfirmDeleteTask());
    setSelectTypeDelete("");
    // setSelectedDayBookItem(null);
  };

  // console.log('selectedTags', selectedTags);

  const [isModifyAndCross, setisModifyAndCross] = useState(false);
  const selectedDayBookItemRef = useRef();

  useEffect(() => {
    function handleClickOutside(event: any) {
      const isOutsideClick =
        selectedDayBookItemRef.current &&
        // @ts-ignore
        !selectedDayBookItemRef.current.contains(event.target);

      if (isOutsideClick) {
        setIsPickerOpen(false);
        setShowFreq(false);
        setShowChooseDate(false);
        setIsPeriodOpen(false);
        // setShowModal(false);
        // setShowCustomModal(false);

        const closestClass = (className: string) =>
          event.target.closest(`.${className}`);

        const isClickableArea =
          !closestClass("public-DraftStyleDefault-block") &&
          !closestClass("description-heading-input") &&
          !closestClass("task-item-wrapper-popup") &&
          !closestClass("task-item-third-column") &&
          !closestClass("description-sub-heading-input") &&
          !closestClass("time-picker-input") &&
          !closestClass("tagbook-select-placeholder") &&
          !closestClass("description-description-tagged-people-wrapper") &&
          !closestClass("daybook-all-tags") &&
          !closestClass("react-datepicker__input-container") &&
          !closestClass("daybook__input__search__field") &&
          !closestClass("daybook__tag__item") &&
          !closestClass("picker-item") &&
          !closestClass("picker-item") &&
          !closestClass("tagbook-select-wrapper");

        let isClickableAreaDelete = !closestClass("delete_daybook_icon");
        if (!isClickableAreaDelete) {
          // handleDeleteDaybook();
          dispatch(
            setConfirmDeleteTask({
              flag: true,
              id: selectedDayBookItem?.task?.id,
            })
          );

          return;
        }

        if (isClickableArea) {
          if (selectedDayBookItem?.task?.id) {
            // if (isThereTextInMessage) {
            //   sendComms();
            // }

            // updateDayBookItem();
            const conditionUpdate =
              updatePopUpRef.current &&
              // @ts-ignore
              !updatePopUpRef.current?.contains(event.target);

            const conditionDelete =
              deletePopupRef.current &&
              // @ts-ignore
              !deletePopupRef.current?.contains(event.target);
            if (conditionUpdate) {
              // setIsModify(false);
              // setSelectedDayBookItem(null);
              setSelectedInterval("");
              setSelectedRepetitive("");
            }
            if (conditionDelete) {
              dispatch(resetConfirmDeleteTask());
              // setSelectedDayBookItem(null);
              setSelectedInterval("");
              setSelectedRepetitive("");
            }
            if (!isModify) {
            }
            if (isModify) {
            }
          } else {
          }
        }
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [
    selectedDayBookItemRef,
    selectedDayBookItem,
    selectedDayBookItem?.task?.tagged_user,
    selectedTags,
    selectedDayBookItem?.task?.start_time,
    selectedDayBookItem?.task?.end_time,
    selectedDayBookItem?.time,
    isThereMessage,
    startTime,
    endTime,
    trafficColors,
    selectedInterval,
    selectedRepetitive,
  ]);

  useEffect(() => {
    dispatch(
      getServiceTypeByFilterThunk({
        filterColumn: "",
        filterValue: "",
        searchVal: "",
        sortColumn: "",
        sortType: "asc",
        serviceTypePage: 1,
      })
    );
  }, []);

  const { serviceTypeData } = useAppSelector((state) => state.serviceType);

  // console.log('serviceTypeData', serviceTypeData);
  // console.log('selectedDayBookItem?.task?.assigned_to', selectedDayBookItem?.task?.assigned_to);
  // const handleIntervalChange = (interval: string | any) => {
  //   setSelectedInterval(interval);
  // };
  // const handleRepetitiveChange = (interval: string | any) => {
  //   setSelectedRepetitive(+interval);
  // };

  const options = ["None", "Daily", "Weekly", "Monthly", "Yearly", "Custom"];

  //   useOutsideAlerter();

  const [staffUsers, setstaffUsers] = useState([]);
  const fetchData = async () => {
    if (dynamicStaffId) {
      const res = await api.get(`/users?type=${dynamicStaffId}`);
      setstaffUsers(res?.data?.data?.data);
    }
  };

  useEffect(() => {
    fetchData();
  }, [dynamicStaffId]);

  // // console.log(keysArray,'keysArray')

  const datePickerSectionRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        datePickerSectionRef.current &&
        // @ts-ignore
        !datePickerSectionRef.current?.contains(event.target)
      ) {
        setIsPickerOpen(false);
      }
      // // console.log("clicked function");
    };

    // Add the event listener when the component mounts
    document.addEventListener("click", handleClickOutside);

    // Remove the event listener when the component unmounts to avoid memory leaks
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [datePickerSectionRef]);

  useEffect(() => {
    if (
      selectedInterval !== "" &&
      (selectedInterval === "None" || selectedRepetitive !== "")
    ) {
      setIsPickerOpen(false);
    }
  }, [selectedInterval, selectedRepetitive]);

  useEffect(() => {
    if (parseInt(selectedDayBookItem?.task?.repetitive_duration) > 1) {
      setSelectedInterval(selectedDayBookItem?.task?.period_picker);
      setSelectedDuration("After");
      setSelectedRepetitive(selectedDayBookItem?.task?.repetitive_duration);
    }
  }, [selectedDayBookItem]);

  useEffect(() => {
    if (keysArrayNewUploads && selectedDayBookItem) {
      // setIsModify(true);
    }
  }, [newDocumentFilesArr]);

  const onChangeTrafficLight = async (data) => {
    if (loggedInUserId !== selectedDayBookItem?.task?.assigned_to?.id) {
      toast.warn("Only assigned to person can change the status of task.");
      return;
    }
    setlocalStateTrafficLight(
      data === "#EF3A34" ? 0 : data === "#FBBF14" ? 2 : 1
    );
    let requiredParmas = {
      ...selectedDayBookItem?.task,
      tag:
        typeof selectedDayBookItem?.task?.tag === "string"
          ? selectedDayBookItem?.task?.tag?.split(",")
          : tempDayBookList[dayBookIndex]?.task[dayBookTaskIndex]?.tags?.map(
              (elem) => elem?.id
            ),
      assigned_to: selectedDayBookItem?.task?.assigned_to?.id,
      key_person: selectedDayBookItem?.task?.key_person?.map((elem) => elem.id),
      tagged_user: selectedDayBookItem?.task?.tagged_user?.map(
        (elem) => elem.id
      ),
      tagged_person: selectedDayBookItem?.task?.tagged_person?.map(
        (elem) => elem.id
      ),
      start_time: selectedDayBookItem?.task?.start_time?.replace(" ", ""),
      end_time: selectedDayBookItem?.task?.end_time?.replace(" ", ""),

      daybook_id: selectedDayBookItem?.task?.id,
      traffic_light:
        data === "#EF3A34"
          ? 0
          : data === "#FBBF14"
          ? 1
          : data === "#2AD400"
          ? 2
          : 1,
      save_type: "Current",
      note_details: {},
    };

    if (selectedDayBookItem?.task?.id) {
      const response = await dispatch(updateDaybookItem(requiredParmas));

      getDaybookCall();
    } else {
      setlocalStateTrafficLight(
        data === "#EF3A34"
          ? 0
          : data === "#FBBF14"
          ? 1
          : data === "#2AD400"
          ? 2
          : 1
      );
    }

    // console.log(' selectedDayBookItem?.task?.traffic_light',  selectedDayBookItem?.task?.traffic_light);
  };

  const [tempDetails,setTempDetails]= useState<any>(null)

  return (
    <>
      <Modal onClose={() => {}} isOpen={showComsForm} isCentered>
        <ModalOverlay zIndex={10} />
        <ClientCommsForm
          setShowCommsForm={setShowCommsForm}
          isFromDayBook={true}
          commsMessage={
            selectedDayBookItem
              ? dayBookList[dayBookIndex]?.task[dayBookTaskIndex]
              : dayBookList[taskIndex?.dayBookIndex]?.task[
                  taskIndex?.dayBookTaskIndex
                ]
          }
          sortType={sortType}
          idDaybook={isClientsOrStaffScreen ? clientId : userId}
        />
      </Modal>


      {type === "clients" ||
      type === "staff" ||
      type == "prospects" ||
      type == "business" ||
      type == "teams" ||
      type === "locations" ||
      type == "contacts" ? (
        ""
      ) : (
        <DaybookHeader />
      )}
      <div
        className={`daybook__wrapper ${
          type === "clients"
            ? "clients__daybook__active"
            : type === "staff"
            ? "staff__daybook___active"
            : type == "prospects"
            ? "prospects_daybook_active"
            : type == "business"
            ? "business_daybook_active"
            : type == "teams"
            ? "teams_daybook_active"
            : type == "contacts"
            ? "contacts_daybook_active"
            : ""
        }`}
        style={{
          height: 24,
          marginBottom: 15,
          marginTop: 22,
        }}
      >
        <div className="daybook__sidebar__row-1" key={"first_section"}></div>
        <div className="daybook__content__row-1"></div>
      </div>
      <div className={"chooseDate_Picker"}>
        {showChooseDate && (
          <div
            className={
              isInUserType ? "date__picker__userType" : "date__picker__normal"
            }
          >
            <ReactDatePicker
              className={
                isInUserType ? "date__picker__userType" : "date__picker"
              }
              selected={selectedSortDateCustom}
              value={selectedSortDateCustom}
              onChange={(date: any) => {
                setSelectedDayBookItem(null);
                setSelectedSortDateCustom(date);
                setShowChooseDate(false);
              }}
              open={showChooseDate}
              onClickOutside={() => {
                setShowChooseDate(false);
              }}
            />
          </div>
        )}
      </div>

      <div className="daybook__wrapper">
        <div className="daybook__sidebar__row-2 sort_section_rapper_daybook">
          <span>Sort:</span>

          <SortSVG
            fill="#06a5ff"
            onClick={handleSortDaybook}
            Styles={{
              transform: sortType == "asc" ? "none" : "rotateX(180deg)",
            }}
          />
        </div>
        <div className="daybook__content__row-2  daybook__content__row-1">
          {selectDateCategories.map(({ title, id, value }: any, index) => {
            // // console.log('Date check in map: ',title, id , value)
            return (
              <div
                key={index}
                style={{
                  width: id === 5 ? 100 : "fit-content",
                  textAlign: "center",
                }}
                className={` sort__tab ${
                  (selectedSortTab?.id === id && "sort__tab__selected") || ""
                }`}
                onClick={(e) => {
                  setTempDetails({
                    title, id, value 
                  })
                
                  if(isModify && id !== 5){
                    setisAddingFromCross(true)
                   
                    return
                  }
                 
                  if (id == 0) {
                    handleAddTask(dayBookList[0]);
                    return;
                  }

                  setSelectedSortTab({ title, id, value });
                  setSelectedDayBookItem(null)
                  if (id !== 5) {
                    setSelectedSortDateCustom(null);
                  } else {
                    setShowChooseDate(true);
                  }
                }}
              >
                {selectedSortDateCustom && id === 5
                  ? `${prefixDate(selectedSortDateCustom?.getDate())} ${
                      monthNames[selectedSortDateCustom?.getMonth()]
                    } ${selectedSortDateCustom?.getFullYear()}`
                  : title}
              </div>
            );
          })}
        </div>
      </div>
      <div
        className={`daybook__wrapper daybook__wrapper-fixed-height ${
          pathname.includes("my-daybook") && "main_screen_height"
        }`}
        style={{ paddingTop: isFirstTaskItem ? "3%" : "" }}
        // style={{ paddingTop: isFirstTaskItem ? "3%" : "" }}
      >
        <div className="daybook__sidebar__row-3" />
        <div className="daybook__content__row-3">
          <div>
            {dayBookList?.map((dayBook: any, dayBookIndex: any) =>
              dayBook?.task?.length > 0 ? (
                dayBook?.task?.map(
                  (dayBookTask: any, dayBookTaskIndex: any) => (
                    <div style={{ display: "flex" }} key={dayBookTaskIndex}>
                      <CommonTaskItem
                         color={dayBookTask?.comms_traffic_light}
                        selectedServiceType={serviceType}
                        getDaybookCall={getDaybookCall}
                        onChangeTrafficLight={onChangeTrafficLight}
                        startDate={startDate}
                        localStateTrafficLight={localStateTrafficLight}
                        currTask={dayBookTask}
                        selectedSortTab={selectedSortTab}
                        currentDate={currentDate}
                        onClickTask={() => {
                          // if(isModify){
                          //   setisAddingFromCross(true)
                          // }
                          setSelectedDayBookItem(dayBookTask)
                        }}
                        selectedSortDateCustom={selectedSortDateCustom}
                        selectedTags={selectedTags}
                        selectedDayBookItem={selectedDayBookItem}
                        setdayBookTaskIndex={setdayBookTaskIndex}
                        setdayBookIndex={setdayBookIndex}
                        uploadedFiles={
                          dayBookList[dayBookIndex].task[dayBookTaskIndex].files
                        }
                        // setSelectedDaybookTask={setSelectedDaybookTask}
                        chooseDate={chooseDate}
                        setTaskIndex={setTaskIndex}
                        setShowCommsForm={setShowCommsForm}
                        setState={(info: any) => {
                          setuploadedFiles([]);
                          setSelectedTags([]);
                          sethitStartTimeToSetEndTime({
                            isHit: false,
                            selectedHours: "",
                            selectedMinutes: "",
                            selectedFormat: "",
                          });
                          setSelectedMessage("");
                          dispatch(resetUploadedFilesFromStore());
                          setisThereMessage(null);
                          dispatch(
                            setUploadedFilesToStore(
                              dayBookList[dayBookIndex]?.task[dayBookTaskIndex]
                                .files
                            )
                          );
                          setShowLights(false);

                          if (!selectedDayBookItem) {
                            setSelectedDayBookItem({
                              time: dayBookList[dayBookIndex]?.time,
                              task: dayBookList[dayBookIndex]?.task[
                                dayBookTaskIndex
                              ],
                              comms: [...dayBookTask.communications],
                              taskIndex: dayBookTaskIndex,
                            });

                            dispatch(
                              setDaybookTags(
                                dayBookList[dayBookIndex]?.task[
                                  dayBookTaskIndex
                                ]?.tags
                              )
                            );
                            setStartTime(dayBookTask?.start_time);
                            setEndTime(dayBookTask?.end_time);
                            setEditorState(EditorState?.createEmpty());
                            // setIsModify(false) ;
                            let colorIs = dayBookTask?.traffic_light;
                            setTrafficColors(
                              () => trafficLightColors[`${colorIs}`]
                            );
                          }
                          setSelectedInterval(dayBookTask?.period_picker);
                        }}
                        setSelectedDayBookItem={() =>
                          setSelectedDayBookItem({
                            time: dayBookList[dayBookIndex]?.time,
                            task: dayBookList[dayBookIndex]?.task[
                              dayBookTaskIndex
                            ],
                            comms: [...dayBookTask.communications],
                            taskIndex: dayBookTaskIndex,
                          })
                        }
                        dayBookList={dayBookList}
                        dayBookIndex={dayBookIndex}
                        dayBookTaskIndex={dayBookTaskIndex}
                        handleAddTask={handleAddTask}
                        dayBook={dayBook}
                        PlusIcon={PlusIcon}
                        PlusIcon2={PlusIcon2}
                        tagColorYellow={
                          dayBookTask.traffic_light == 0
                            ? "#ef3a34"
                            : dayBookTask.traffic_light == 1
                            ? "#fbbf14"
                            : dayBookTask.traffic_light == 2
                            ? "#2ad400"
                            : ""
                        }
                        dayBookTask={dayBookTask}
                        weekday={weekday}
                        monthNames={monthNames}
                        fileIcon={fileIcon}
                        setTrafficColors={setTrafficColors}
                        setIsModify={setIsModify}
                        trafficColors={trafficColors}
                        isModify={isModify}
                        userId={userId}
                        isSelected={
                          selectedDayBookItem?.task?.id === dayBookTask?.id
                        }
                      />
                      <div
                        style={{
                          marginLeft: "12px",
                        }}
                      >
                        {selectedDayBookItem &&
                          selectedDayBookItem?.task?.id == dayBookTask?.id && (
                            <div
                              style={
                                {
                                  // position: isInUserType ? "absolute": "unset",
                                  // top: isInUserType ?  "120px" : "unset"
                                }
                              }
                            >
                              <ThreadsBox
                              loading={loading}
                                handleDelete={
                                 () =>  isDeleteingConfirmation( "Current",selectedDayBookItem?.task?.date)
                                }
                                setFilesWhenClickedFromCommsBox={
                                  setFilesWhenClickedFromCommsBox
                                }
                                setuploadedFiles={setuploadedFiles}
                                sendComms={sendComms}
                                onChangeTrafficLightFromProps={
                                  onChangeTrafficLight
                                }
                                currentElem={dayBookTask}
                                localStateTrafficLight={localStateTrafficLight}
                                isInUserType={isInUserType}
                                color={dayBookTask?.comms_traffic_light}
                                count={dayBookTask?.comm_count}
                                getDaybookCall={getDaybookCall}
                                selectedCurrentElement={dayBookTask}
                                setSelectedMessage={setSelectedMessage}
                                serviceTypes={serviceTypeData}
                                selectedServiceType={serviceType}
                                startTime={startTime}
                                endTime={endTime}
                                setStartDateDaybook={setStartDate}
                                setEndDateDaybook={setEndDate}
                                startDateDaybook={startDate}
                                endDateDaybook={endDate}
                                setshowDateTimePickerDaybook={
                                  setshowDateTimePickerDaybook
                                }
                                showDateTimePickerDaybook={
                                  showDateTimePickerDaybook
                                }
                                // setStartDate={setStartDate}
                                isDocument={false}
                                periodPicker={false}
                                handleSaveClick={
                                  selectedDayBookItem?.task?.id
                                    ? updateDayBookItem
                                    : addDayBookItem
                                }
                                // type={":"}
                                assignedToPeopleOnOptionSelect={(item) => {
                                  const taggedPeopleIds =
                                    typeof selectedDayBookItem?.task
                                      ?.assigned_to === "string"
                                      ? selectedDayBookItem?.task?.assigned_to.split(
                                          ","
                                        )
                                      : selectedDayBookItem?.task?.assigned_to;

                                  let addTaggedPeople = (data: any) => {
                                    handleTaskItemChange(
                                      "task-assigned-to",
                                      dayBookIndex,
                                      dayBookTaskIndex,
                                      data
                                    );
                                  };
                                  addTaggedPeople(
                                    taggedPeopleIds?.length
                                      ? [item, ...taggedPeopleIds]
                                      : [item]
                                  );
                                }}
                                taggedPeopleOnOptionSelectDaybook={(item) => {
                                  const taggedPeopleIds =
                                    typeof selectedDayBookItem?.task
                                      ?.tagged_user === "string"
                                      ? selectedDayBookItem?.task?.tagged_user.split(
                                          ","
                                        )
                                      : selectedDayBookItem?.task?.tagged_user;

                                  let addTaggedPeople = (data: any) => {
                                    handleTaskItemChange(
                                      "task-tagged-people",
                                      dayBookIndex,
                                      dayBookTaskIndex,
                                      data
                                    );
                                  };
                                  addTaggedPeople(
                                    taggedPeopleIds?.length
                                      ? [item, ...taggedPeopleIds]
                                      : [item]
                                  );
                                }}
                                taggedPeopleDaybook={
                                  typeof selectedDayBookItem?.task
                                    ?.tagged_user === "string"
                                    ? selectedDayBookItem?.task?.tagged_user
                                        .split(",")
                                        .map((item: string) => parseInt(item))
                                    : selectedDayBookItem?.task?.tagged_user
                                }
                                assignedTo={
                                  typeof selectedDayBookItem?.task
                                    ?.assigned_to === "string"
                                    ? selectedDayBookItem?.task?.assigned_to
                                        .split(",")
                                        .map((item: string) => item)
                                    : [selectedDayBookItem?.task?.assigned_to]
                                }
                                showLastUpdateAndSave={true}
                                selectedElement={selectedDayBookItem?.task}
                                handleTitleChange={(e) =>
                                  handleTaskItemChange(
                                    "task-title",
                                    dayBookIndex,
                                    dayBookTaskIndex,
                                    e?.target?.value
                                  )
                                }
                                changeTags={(vals) =>
                                  changeTags(
                                    "task-tags",
                                    dayBookIndex,
                                    dayBookTaskIndex,
                                    vals
                                  )
                                }
                                handleStartTimeChange={(time) =>
                                  handleTimeChange(
                                    "task-start-time",
                                    dayBookIndex,
                                    dayBookTaskIndex,
                                    time
                                  )
                                }
                                handleEndTimeChange={(time) =>
                                  handleTimeChange(
                                    "task-end-time",
                                    dayBookIndex,
                                    dayBookTaskIndex,
                                    time
                                  )
                                }
                                setServiceType={setServiceType}
                                isDocument={false}
                                isNotComms={true}
                                handleSummaryChange={(e) =>
                                  handleTaskItemChange(
                                    "task-summary",
                                    dayBookIndex,
                                    dayBookTaskIndex,
                                    e.target.value
                                  )
                                }
                                title={selectedDayBookItem?.task?.title}
                                summary={selectedDayBookItem?.task?.summary}
                                incomingArrayTabs={[]}
                                taggedPeople={selectedItemsArr}
                                // taggedPeopleDropdownOptions={filteredResults}
                              
                                // onChangeTaggedPeople={handleInputChangeAllData}
                                // taggedPeopleInputValue={inputValue}
                                // taggedPeopleDropdownOptions={filteredResults}
                                commsDetail={
                                  Array.isArray(dayBookTask?.communications)
                                    ? dayBookTask?.communications
                                    : []
                                }
                                attachments={dayBookTask?.files}
                                isDaybook
                                isCreateState={!selectedDayBookItem?.task?.id}
                                handleCloseModal={() => {
                                  if(isModify){
                                    setisModifyAndCross(true)
                                  }
                                  setSelectedDayBookItem(null);
                                  getDaybookCall();
                                }}
                                keyPeople={dayBookTask?.key_person}
                                // onChangeTaggedPeople={handleInputChangeAllData}
                                // taggedPeopleInputValue={inputValue}
                                // taggedPeopleDropdownOptions={[]}
                                taggedPeopleOnOptionSelect={
                                  handleSelectTaggedPeople
                                }
                                hideCommsSendButton={
                                  !selectedDayBookItem?.task?.id
                                }
                              />
                            </div>
                          )}
                      </div>
                    </div>
                  )
                )
              ) : (
                <div className="task-item-empty" key={dayBookIndex}>
                  <div className="task-item-time">
                    <PlusSVG
                      onClick={(e: any) => {
                        e?.stopPropagation();
                        handleAddTask(dayBook);
                      }}
                    />
                    <span>{dayBook.time}</span>
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      </div>

      <CircularNoteTrigger
        type="stickyHub"
        title="Comms"
        extraType="globalCommsBox"
      />

      {isModifyAndCross && (
        <>
          <ConfirmationModal type="clientScreen" 
          
          handleMoveWithSave={
            setisModifyAndCross(false)
          }
          handleMoveWithoutSaving={() =>{
            getDaybookCall()
            setisModifyAndCross(false)
            setSelectedDayBookItem(false)}}
          />
        </>
      )}

      {showFrequencyChangeConfirmationModal && (
        <>
          <ConfirmationModal
            type="clientScreen"
            title={
              "Do you really want to make changes to frequency of this task? This change will impact other tasks of this series as well."
            }
            handleMoveWithSave={() =>
              setshowFrequencyChangeConfirmationModal(false)
            }
            handleMoveWithoutSaving={() =>
              setshowFrequencyChangeConfirmationModal(false)
            }
          />
        </>
      )}

      {/* delete confirmation modal for series and current */}
      {isDeleteing && (
        <ConfirmationModal
          type="clientScreen"
          yes={"Yes"}
          no={"No"}
          title={
            deleteProps.date === "Current"
              ? "Do you want to delete this task?"
              : "Do you want to delete this series of tasks?"
          }
          handleMoveWithSave={() => {
            handleDeleteDaybook(deleteProps.date, deleteProps.save_type);
            setisDeleting(false);
          }}
          handleMoveWithoutSaving={() => {
            getDaybookItems({
              userId:
                pathname.includes("clients") ||
                pathname.includes("staff") ||
                pathname.includes("prospects") ||
                pathname.includes("business") ||
                pathname.includes("teams") ||
                pathname.includes("contacts") ||
                pathname.includes("locations")
                  ? clientId
                  : userId,
              date:
                selectedSortTab?.id !== 5
                  ? selectedSortTab?.value
                  : // @ts-ignore
                    `${date.getFullYear()}-${prefixDate(
                      // @ts-ignore
                      date.getMonth() + 1
                      // @ts-ignore
                    )}-${date.getDate()}`,
              tenantId: providerId,
            });
            // setSelectedDayBookItem(null);
            setisDeleting(false);
          }}
        />
      )}

    
      {/* when a new task is created */}
      {isAddingFromCross && (
        <>
          <div>
            <ConfirmationModal
              type="clientScreen"
              yes={"Continue Editing"}
              title={"Do you want continue and not save or continue editing?"}
              handleMoveWithSave={async () => {
                // if (isThereMessage) {
                  // sendComms();
                // }
            // let res =   await updateDayBookItem();
            // if(res){
              // setSelectedSortTab(tempDetails);

            // }
                setisAddingFromCross(false);
// setIsModify(false)

              }}
              handleMoveWithoutSaving={() => {
                setSelectedSortTab(tempDetails)
                setisAddingFromCross(false);
                dispatch(
                  // @ts-ignore
                  getDaybookItems({
                    userId:
                      pathname.includes("clients") ||
                      pathname.includes("staff") ||
                      pathname.includes("prospects") ||
                      pathname.includes("business") ||
                      pathname.includes("teams") ||
                      pathname.includes("contacts") ||
                      pathname.includes("locations")
                        ? clientId
                        : userId,
                    date:
                      selectedSortTab.id !== 5
                        ? selectedSortTab?.value
                        : // @ts-ignore
                          `${date.getFullYear()}-${prefixDate(
                            // @ts-ignore
                            date.getMonth() + 1
                            // @ts-ignore
                          )}-${date.getDate()}`,
                    tenantId: providerId,
                  })
                );
                setSelectedDayBookItem(null);
setIsModify(false)

              }}
            />
          </div>
        </>
      )}

      {/* when an aleady present task is changed  */}
      {isModifyAndCross  && (
        <>
          <ConfirmationModal
            type="clientScreen"
            title={"Would you like to make changes and update this task?"}
            handleMoveWithSave={() => {
              if (isThereMessage) {
                sendComms();
              }
              updateDayBookItem(
                selectedInterval !== "None" || selectedInterval !== "Once"
                  ? "Current"
                  : "Current"
              );
              setisAddingFromCrossUpdate(false);
            }}
            handleMoveWithoutSaving={() => {
              setisAddingFromCrossUpdate(false);
              setSelectedDayBookItem(null);
              setisAddingFromCross(false);
              dispatch(
                // @ts-ignore
                getDaybookItems({
                  userId:
                    pathname.includes("clients") ||
                    pathname.includes("staff") ||
                    pathname.includes("prospects") ||
                    pathname.includes("business") ||
                    pathname.includes("teams") ||
                    pathname.includes("contacts") ||
                    pathname.includes("locations")
                      ? clientId
                      : userId,
                  date:
                    selectedSortTab.id !== 5
                      ? selectedSortTab?.value
                      : // @ts-ignore
                        `${date.getFullYear()}-${prefixDate(
                          // @ts-ignore
                          date.getMonth() + 1
                          // @ts-ignore
                        )}-${date.getDate()}`,
                  tenantId: providerId,
                })
              );
            }}
          />
        </>
      )}
    </>
  );
};

export default DayBook;

const AssignedToInitialState: any = {
  dropdownOpen: false,
  inputValue: "",
  selectedOptions: [],
  filteredTaggedPeople: [],
  taggedPeople: [],
};

export const AssignedToSelect = ({
  taggedPeopleList = [],
  taggedPeopleIDs = [],
  addTaggedPeople = () => null,
  type = "",
}: any) => {
  const pathname = useLocation().pathname;

  useEffect(() => {
    if (taggedPeopleIDs) {
      const findUserFormId: any[] = taggedPeopleList.filter(
        (res: any) => res?.id == taggedPeopleIDs
      );
      setState((prevData: any) => ({
        ...prevData,
        taggedPeople: findUserFormId.length !== 0 ? findUserFormId[0] : {},
      }));
    }
  }, [taggedPeopleIDs]);

  const [
    { dropdownOpen, inputValue, filteredTaggedPeople, taggedPeople },
    setState,
  ] = useState<any>(AssignedToInitialState);

  const [currentUser, setCurrentUser] = useState<any>(null);
  const state = useAppSelector((state) => state);
  const { user } = useAppSelector((state) => state.auth);
  useEffect(() => {
    const isClientPath = pathname.includes("clients");

    if (isClientPath) {
      setCurrentUser(state?.people?.uniqueClient?.data?.data[0]);
    } else if (pathname.includes("prospects")) {
      setCurrentUser(state?.prospects?.getUniqueProspect?.data?.data[0]);
    } else if (pathname.includes("business")) {
      setCurrentUser(state?.business?.getUniqueBusiness?.data?.data[0]);
    } else if (pathname.includes("contacts")) {
      setCurrentUser(state?.contacts?.getUniqueContact?.d0ata?.data[0]);
    } else if (pathname.includes("teams")) {
      setCurrentUser(state?.teams?.getUniqueTeams?.data?.data[0]);
    } else if (pathname.includes("staff")) {
      setCurrentUser(state?.staff.getUniqueStaff?.data?.data[0]);
    } else {
      setCurrentUser(user);
    }
  }, [pathname, state]);

  const { domainDetail } = useAppSelector((state) => state?.common);
  const [dynamicColor, setDynamicColor] = useState("");
  useEffect(() => {
    if (domainDetail) {
      setDynamicColor(domainDetail?.sticky_background_color);
    }
  }, [domainDetail]);

  return (
    <div className="tagbook-select-wrapper-sup">
      <div
        className="tagbook-select-wrapper"
        style={{
          gridTemplateColumns:
            type == "stickynote" ? "75px 50px 1fr" : "75px 100px 1fr",
        }}
      >
        <div className="tagbook-select-placeholder">Assigned To: </div>
        {pathname.includes("clients") ||
        pathname.includes("staff") ||
        pathname.includes("prospects") ||
        pathname.includes("business") ||
        pathname.includes("teams") ||
        pathname.includes("contacts") ||
        pathname.includes("locations") ? (
          ""
        ) : (
          <input
            type="text"
            placeholder="Search..."
            style={{
              display:
                pathname.includes("clients") ||
                pathname.includes("staff") ||
                pathname.includes("prospects") ||
                pathname.includes("business") ||
                pathname.includes("teams") ||
                pathname.includes("contacts") ||
                pathname.includes("locations")
                  ? "none"
                  : "block",
            }}
            value={inputValue || ""}
            onChange={({ target }) => {
              setState((prevData: any) => ({
                ...prevData,
                inputValue: target?.value,
                filteredTaggedPeople: target?.value
                  ? taggedPeopleList.filter((item: any) =>
                      // @ts-ignore
                      item?.first_name
                        ? item?.first_name
                            .toUpperCase()
                            .match(target.value.toUpperCase()) || ""
                        : item?.name
                        ? item?.name
                            .toUpperCase()
                            .match(target.value.toUpperCase())
                        : ""
                    )
                  : [],
              }));
            }}
            onFocus={() =>
              setState((prevData: any) => ({
                ...prevData,
                dropdownOpen: true,
              }))
            }
            onBlur={() =>
              setTimeout(() => {
                setState((prevData: any) => ({
                  ...prevData,
                  dropdownOpen: false,
                }));
              }, 500)
            }
          />
        )}
        <div
          className={`tagbook-select-selected-options-wrapper daybook_extranl_css ${
            type == "stickynote" && "stickynote_extend_class"
          } `}
        >
          {taggedPeople && (
            <div
              className={`tagbook-select-selected-option tagbook_select_selected_option_daybook  `}
            >
              {taggedPeople?.profile_image_s3 ? (
                <img
                  src={
                    taggedPeople?.profile_image_s3
                      ? taggedPeople?.profile_image_s3
                      : ""
                  }
                  alt=""
                  style={{
                    borderRadius: "50%",
                    width: "18px",
                    height: "18px",
                    objectFit: "cover",
                  }}
                />
              ) : (
                <span
                  style={{
                    borderRadius: "50%",
                    backgroundColor: dynamicColor,
                    color: "white",
                    width: "18px",
                    textAlign: "center",
                    letterSpacing: ".3px",
                    height: "18px",
                    fontSize: "7px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {`${
                    taggedPeople.first_name?.charAt(0)
                      ? taggedPeople.first_name?.charAt(0).toUpperCase()
                      : ""
                  }  ${
                    taggedPeople.last_name?.charAt(0)
                      ? taggedPeople.last_name?.charAt(0).toUpperCase()
                      : ""
                  }`}
                </span>
              )}
              <p>
                {taggedPeople?.first_name} {taggedPeople?.last_name || ""}
              </p>
            </div>
          )}
          {/* </>
          )} */}
          <div></div>
        </div>
      </div>{" "}
      {dropdownOpen &&
        !pathname.includes("clients") &&
        !pathname.includes("staff") &&
        !pathname.includes("prospects") &&
        !pathname.includes("business") &&
        !pathname.includes("teams") &&
        !pathname.includes("contacts") && (
          <div className="tagbook-select-dropdown">
            {[...(inputValue ? filteredTaggedPeople : taggedPeopleList)].map(
              (item: any, index: number) => (
                <div
                  key={index}
                  className="tagbook-select-dropdown-option"
                  onClick={() => {
                    if (taggedPeopleIDs?.length) {
                      addTaggedPeople(item.id);
                    } else {
                      addTaggedPeople(item.id);
                    }
                    setState((prevData: any) => ({
                      ...prevData,
                      inputValue: "",
                    }));
                  }}
                >
                  {item?.first_name || item?.name} {"   "}{" "}
                  {item?.last_name || ""}
                </div>
              )
            )}
          </div>
        )}
    </div>
  );
};

const TagBookSelectInitialState: any = {
  dropdownOpen: false,
  inputValue: "",
  selectedOptions: [],
  filteredTaggedPeople: [],
  taggedPeople: [],
};

export const TagBookSelect = ({
  taggedPeopleList = [],
  taggedPeopleIDs = [],
  addTaggedPeople = () => null,
  type = "",
}: any) => {
  useEffect(() => {
    if (taggedPeopleIDs?.length) {
      setState((prevData: any) => ({
        ...prevData,
        taggedPeople: taggedPeopleList.filter((item: any) => {
          return taggedPeopleIDs.indexOf(item.id) !== -1;
        }),
      }));
    }
  }, [taggedPeopleIDs]);

  const [
    { dropdownOpen, inputValue, filteredTaggedPeople, taggedPeople },
    setState,
  ] = useState(TagBookSelectInitialState);

  const { domainDetail } = useAppSelector((state) => state?.common);
  const [dynamicColor, setDynamicColor] = useState("");
  useEffect(() => {
    if (domainDetail) {
      setDynamicColor(domainDetail?.sticky_background_color);
    }
  }, [domainDetail]);

  return (
    <div className="tagbook-select-wrapper-sup">
      <div
        className="tagbook_select_wrapper"
        style={{
          // 95px 100px 1fr
          gridTemplateColumns:
            type == "stickynote" ? "95px 50px 1fr" : "95px 100px 1fr",
        }}
      >
        <div className="tagbook_select_placeholder">Tagged People:</div>
        <input
          type="text"
          placeholder="Search..."
          value={inputValue || ""}
          onChange={({ target }) => {
            setState((prevData: any) => ({
              ...prevData,
              inputValue: target.value,
              filteredTaggedPeople: target.value
                ? taggedPeopleList.filter((item: any) =>
                    // @ts-ignore
                    item?.first_name
                      ? item?.first_name
                          .toUpperCase()
                          .match(target.value.toUpperCase())
                      : item?.name
                          .toUpperCase()
                          .match(target.value.toUpperCase())
                  )
                : [],
            }));
          }}
          onFocus={() =>
            setState((prevData: any) => ({ ...prevData, dropdownOpen: true }))
          }
          onBlur={({ target }) =>
            setTimeout(() => {
              setState((prevData: any) => ({
                ...prevData,
                dropdownOpen: false,
                inputValue: "",
              }));
            }, 500)
          }
        />
        <div
          className={`tagbook-select-selected-options-wrapper  ${
            type == "stickynote" && "stickynote_extend_class_tagbook"
          }`}
        >
          {taggedPeople.map((item: any, index: number) => (
            <div className="tagbook-select-selected-option" key={index}>
              {item?.profile_image_s3 ? (
                <img
                  src={item?.profile_image_s3}
                  alt=""
                  style={{
                    borderRadius: "50%",
                    width: "18px",
                    height: "18px",
                    objectFit: "cover",
                    color: "white",
                  }}
                />
              ) : (
                <span
                  style={{
                    borderRadius: "50%",
                    width: "18px",
                    height: "18px",
                    backgroundColor: dynamicColor,
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                    fontSize: "7px",
                    letterSpacing: "0.3px",
                    color: "white",
                  }}
                >{`${
                  item.first_name?.charAt(0)
                    ? item.first_name?.charAt(0).toUpperCase()
                    : ""
                }  ${
                  item.last_name?.charAt(0)
                    ? item.last_name?.charAt(0).toUpperCase()
                    : ""
                }`}</span>
              )}
              <p>
                {item?.first_name || item?.name} {item?.last_name}
              </p>
            </div>
          ))}
        </div>
      </div>

      {dropdownOpen && (
        <div className="tagbook-select-dropdown">
          {[...(inputValue ? filteredTaggedPeople : taggedPeopleList)].map(
            (item: any, index: number) => (
              <div
                key={index}
                className="tagbook-select-dropdown-option"
                onClick={() => {
                  if (taggedPeopleIDs?.length) {
                    addTaggedPeople([item.id, ...taggedPeopleIDs]);
                  } else {
                    addTaggedPeople([item.id]);
                  }
                }}
              >
                {item?.first_name || item?.name} {item?.last_name || ""}
              </div>
            )
          )}
        </div>
      )}
    </div>
  );
};

export const DateStartEndTime = ({
  // dateValue,
  // onDateChange,
  onStartTimeChange,
  onEndTimeChange,
  startTimeValue,
  endTimeValue,
}: any) => {
  return (
    <div className="date-start-end-time-wrapper">
      <div className="date-start-end-time-row-1"></div>
      <div className="date-start-end-time-row-2">
        <TimePicker setTime={onStartTimeChange} time={startTimeValue} />
        <TimePicker setTime={onEndTimeChange} time={endTimeValue} />
      </div>
    </div>
  );
};
