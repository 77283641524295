import { Fragment, useEffect, useRef, useState } from "react";
import UpperBox from "../../../components/UpperBox/UpperBox";
import ReactDatePicker from "react-datepicker";
import SortIcon from "../../../pages/Daybook/assets/svg/sort-icon.svg";
import "../MenuAssignment/Styles/MenuAssignment.css";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../Controller/redux/Hooks";
import { api } from "../../../../Controller/services/api";
import { toast } from "react-toastify";
import PlusIcon from "../../../pages/Daybook/assets/header-icons/plus-icon.svg";
import { monthNames } from "../Data/Data";
import {
  deleteMenuAssignmentThunk,
  getMenuAssignmentThunk,
  resetConfirmDeleteMenuAssignment,
  setConfirmDeleteMenuAssignment,
} from "../../../../Controller/redux/features/MealsFeatures/MenuAssignmentFeature/menuAssignmentSlice";
import { Editor } from "react-draft-wysiwyg";
import {
  Radio,
  RadioGroup,
  StackDivider,
  Switch,
  Stack,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import {
  ContentState,
  EditorState,
  convertFromHTML,
  convertFromRaw,
  convertToRaw,
} from "draft-js";
import convertToHTML from "draftjs-to-html";
import BoldSVG from "../../People/ClientPage/Components/UniqueClientScreen/Components/FinanceTab/Data/BoldSVG";
import ItalicIconSVG from "../../People/ClientPage/Components/UniqueClientScreen/Components/FinanceTab/Data/ItalicIconSVG";
import UnderscorSVG from "../../People/ClientPage/Components/UniqueClientScreen/Components/FinanceTab/Data/UnderscorSVG";
import PlusSVG from "../../People/ClientPage/Components/UniqueClientScreen/Components/FinanceTab/Data/PlusSVG";
import CircularNoteTrigger from "../../../components/CircularNoteTrigger/CircularNoteTrigger";
import DeleteSVG from "../../People/ClientPage/Components/UniqueClientScreen/Components/FinanceTab/Data/DeleteSVG";
import UnsavedChangesPopup from "../../../components/UnsavedChangesPopup/UnsavedChangesPopup";
import { getStaffThunk } from "../../../../Controller/redux/features/StaffFeature/staffSlice";
import CustomDatePicker from "../../../components/DatePicker/CustomDatePicker";


const MenuAssignment = () => {
  const yesterday = new Date();
  const today = new Date();
  const tomorrow = new Date();
  const dayAfterTomorrow = new Date();

  yesterday.setDate(dayAfterTomorrow.getDate() - 1);
  tomorrow.setDate(dayAfterTomorrow.getDate() + 1);
  dayAfterTomorrow.setDate(dayAfterTomorrow.getDate() + 2);

  const [selectedSortTab, setSelectedSortTab] = useState<any>({});

  const [selectedSortDate, setSelectedSortDate] = useState(
    new Date(selectedSortTab?.value || new Date())
  );

  const [selectedSortDateCustom, setSelectedSortDateCustom] =
    useState<any>(null);

  const [currentDate, setCurrentDate] = useState(new Date());
  const [datePickeerSelectedDate, setDatePickeerSelectedDate] =
    useState(currentDate);
  const [currentDateIndexIs, setCurrentDateIndexIs] = useState<any>(null);

  const [daysArray, setDaysArray] = useState<any[]>([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [UseEffectCalling, setUseEffectCalling] = useState(0);

  useEffect(() => {
    if (currentDate) {
      function getDatesForWeek(date: any) {
        const currentDate = new Date(date);
        const currentDayOfWeek = currentDate.getDay(); // Get the current day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
        const daysInWeek = 7;
        const days = [];

        const monday = new Date(currentDate);
        monday.setDate(currentDate.getDate() - currentDayOfWeek + 1);

        // If the current date is Sunday, adjust the calculation to get the previous week's Monday
        if (currentDayOfWeek === 0) {
          monday.setDate(monday.getDate() - daysInWeek);
        }

        // Generate an array of dates for the current week
        for (let i = 0; i <= daysInWeek; i++) {
          const day = new Date(monday);
          day.setDate(monday.getDate() + i);
          days.push({
            id: i + 1,
            title:
              i == 7
                ? `${date?.getDate()} ${
                    monthNames[date?.getMonth()]
                  } ${date?.getFullYear()} `
                : day.toLocaleDateString(undefined, { weekday: "short" }),
            value: day.toISOString().slice(0, 10),
          });
        }

        return days;
      }
      function getWeekDataForDateSecons(date: Date) {
        // // console.log("inside second====================>");

        const todayDate = new Date(date);
        const currentDay = todayDate.getDay();
        const dayOrder = [1, 2, 3, 4, 5, 6, 7, 8]; // Mon, Tue, Wed, Thu, Fri, Sat, Sun, Choose Date
        let daysSequenceArr = [
          "Mon",
          "Tue",
          "Wed",
          "Thu",
          "Fri",
          "Sat",
          "Sun",
          "Choose Date",
        ];
        const monday = new Date(todayDate);
        monday.setDate(
          todayDate.getDate() -
            currentDay +
            (currentDay === 1 ? 1 : currentDay === 0 ? -6 : 1)
        );

        const weekData = [];

        for (let i = 0; i < 8; i++) {
          const day = new Date(monday);
          day.setDate(monday.getDate() + dayOrder[i]);

          const options = { weekday: "short" };
          const dayObject = {
            id: i + 1,
            title: daysSequenceArr[i], // Set the locale to "en-US"
            value: day.toISOString().slice(0, 10),
          };

          weekData.push(dayObject);
        }
        return weekData;
      }
      function getWeekDataForDate(date: Date) {
        const currentDate = new Date(date);
        const currentDayOfWeek = currentDate.getDay();
        const daysInWeek = 7;
        const days = [];

        const monday = new Date(currentDate);
        monday.setDate(currentDate.getDate() - currentDayOfWeek + 1);

        if (currentDayOfWeek === 0) {
          monday.setDate(monday.getDate() - daysInWeek);
        }

        for (let i = 0; i <= daysInWeek; i++) {
          const day = new Date(monday);
          day.setDate(monday.getDate() + i);
          days.push({
            id: i + 1,
            title:
              i == 7
                ? `${date?.getDate()} ${
                    monthNames[date?.getMonth()]
                  } ${date?.getFullYear()} `
                : day?.toLocaleDateString(undefined, { weekday: "short" }),
            value: day?.toISOString().slice(0, 10),
          });
        }

        return days;
      }

      var weekData: any = getWeekDataForDate(currentDate);
      // if (UseEffectCalling == 0) {
      //   weekData = getDatesForWeek(currentDate);
      // } else {
      //   weekData = getWeekDataForDateSecons(currentDate);
      // }
      // setUseEffectCalling(UseEffectCalling + 1);

      // const weekData = getDatesForWeek(currentDate);
      // // console.log("weekDat=======================", weekData);

      setDaysArray(weekData);
      let todayDate = currentDate;
      let indexIS =
        todayDate.getDay() == 0
          ? 6
          : todayDate.getDay() == 1
          ? 0
          : todayDate.getDay() - 1;
      // if (selectedSortDateCustom == null) {
      setSelectedSortTab(weekData[indexIS]);
      setCurrentDateIndexIs(indexIS);
      // }
    }
  }, [currentDate]);

  useEffect(() => {
    if (selectedSortTab) {
      let currentDateTimeStamp;
      // let todayTimeStamp = today.getTime();
      if (selectedSortTab.id == 8) {
        currentDateTimeStamp = selectedSortDateCustom?.getTime();
      } else {
        currentDateTimeStamp = new Date(selectedSortTab?.value).getTime();
      }
      setSelectedSortDate(new Date(selectedSortTab.value));
    }
  }, [selectedSortTab]);

  const dynamicColor = useAppSelector((state) => state?.common?.dynamicColor);

  const menuItems = useAppSelector(
    (state) => state?.menuAssignment?.menuAssignmentData
  );

  // console.warn("menuItems==========================>", menuItems);

  const dispatch = useAppDispatch();

  let todaysDate = currentDate.toISOString().split("T")[0];

  // const onDateDateOnSelect = async (date: string) => {
  //   dispatch(getMenuAssignmentThunk({ id: clientId, date: date }));
  // };

  const [selectedMenuItem, setSelectedMenuItem] = useState<{
    menu_type?: string;
    itemIndex?: number;
    meal_id?: number;
    item?: any;
  } | null>(null);

  // ALTAFS CODE
  const [taskItem, settaskItem] = useState<{} | any>({
    title: "",
    summary: "",
    delivery: "",
    tags: [],
    notes: "",
    menuId: "",
  });

  const [selectedMenuAssignment, setSelectedMenuAssignment] =
    useState<any>(null);

  const handleAddItem = (menuType: string) => {
    const newTaskItem = {
      title: "",
      summary: "",
      delivery: "",
      tags: [],
      notes: "",
      has_delivery: 0,
      menuId: "",
      type: "new",
    };
    setEditorState(EditorState.createEmpty());
    settaskItem(newTaskItem);

    const updatedTasks = JSON.parse(JSON.stringify(menuAssigmentDataArr));

    let itemIndex = -1;
    menuAssigmentDataArr.find((item: any, index: any) => {
      if (item.name === menuType) {
        itemIndex = index;
      }
    });

    updatedTasks[itemIndex].data.data.unshift(newTaskItem);
    setMenuAssigmentDataArr(updatedTasks);
    setTimeout(() => {
      setSelectedMenuAssignment({
        menu_type: menuType,
        itemIndex: 0,
      });
    }, 1000);
  };

  let paramsId = useParams()?.id;
  const [clientId, setClientId] = useState("");

  useEffect(() => {
    if (paramsId) {
      let decryptedId = window.atob(paramsId);
      setClientId(decryptedId);
    }
  }, [paramsId]);

  // INITAL STATE WITH NO DATA IN THERE
  const [radioButtonValue, setRadioButtonValue] = useState("Medium");

  const [createMenuAssignmentData, setCreateMenuAssignmentData] = useState({
    assignment_date: todaysDate,
    user_id: clientId,
    menu_id: taskItem?.menuId,
    has_delivery: 1,
    meal_size: radioButtonValue,
    notes: "",
  });

  const [deliveryValue, setDeliveryValue] = useState(1);

  const taskRef = useRef<any>(null);
  const deleteMenuRef = useRef<any>(null);
  const deletePopupRef = useRef<any>(null);

  const { idOfMenuAssignToDelete } = useAppSelector(
    (state) => state?.menuAssignment
  );
  const { hasDeleteMenuAssignment } = useAppSelector(
    (state) => state?.menuAssignment
  );
  const handleDeleteMenu = async () => {
    let dateIS =
      selectedSortDateCustom == null
        ? daysArray[currentDateIndexIs].value
        : `${selectedSortDateCustom.getFullYear()}-${
            selectedSortDateCustom.getMonth() + 1
          }-${selectedSortDateCustom.getDate()}`;
    try {
      const response = await dispatch(
        deleteMenuAssignmentThunk(idOfMenuAssignToDelete)
      );
      if (response?.meta?.requestStatus === "fulfilled") {
        toast.success("Menu Assignment Deleted Successfully", {
          style: {},
          progressStyle: { backgroundColor: dynamicColor },
          icon: (
            <span
              style={{
                marginRight: "8px",
                borderRadius: "50%",
                width: "7rem",
                height: "35px",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: dynamicColor,
                backgroundColor: "white",
                fontSize: "1.5rem",
                border: `3px solid ${dynamicColor}`,
              }}
            >
              ✓
            </span>
          ),
        });
      }
    } catch (error) {
      throw error;
    }
    dispatch(getMenuAssignmentThunk({ id: clientId, date: dateIS }));
    dispatch(resetConfirmDeleteMenuAssignment());
    setSelectedMenuAssignment(null);
    // setSelectedDayBookItem(null);
  };


  // console.log(selectedMenuAssignment?.menu?.notes,'selectedMenuAssignment')

  var [commsMessageToSendToBackend, setcommsMessageToSendToBackend] =
    useState("");

  useEffect(() => {
    if(selectedMenuAssignment){
      setcommsMessageToSendToBackend(selectedMenuAssignment?.menu?.notes)
    }
  },[ selectedMenuAssignment])



  useEffect(() => {
    const handleClickOutside = (event: any) => {
      const isPopupClick =
        deletePopupRef.current &&
        !deletePopupRef.current.contains(event.target);

      const isOutsideClick =
        taskRef.current && !taskRef.current.contains(event.target);
      const onDeleteMenuClick =
        deleteMenuRef.current && !deleteMenuRef.current.contains(event.target);

      if (isOutsideClick) {
        const closestClass = (className: string) =>
          event.target.closest(`.${className}`);

        const isClickableArea =
          !closestClass("task__item__wrapper selected-menu") &&
          !closestClass("left__three__dots___wrapper") &&
          !closestClass("left__three__dots") &&
          !closestClass("writable__content__wrapper") &&
          !closestClass("input__field__menu__assignment") &&
          !closestClass("menu_assign_select_dropdown") &&
          !closestClass("menu_assign_select_dropdown_option") &&
          !closestClass("rdw-suggestion-dropdown");

        let isClickableAreaDelete = !closestClass("delete_menu_assign_icon");

        if (!isClickableAreaDelete) {
          dispatch(
            setConfirmDeleteMenuAssignment({
              flag: true,
              id: selectedMenuAssignment?.menu?.id,
            })
          );
        }
        // // console.log()
        if (isClickableAreaDelete && isClickableArea && isPopupClick) {
          if (selectedMenuAssignment?.menu?.id) {
            handleUpdateAssignment();
          } else {
            // let classNameList = [
            //   "input__field__menu__assignment",
            //   "tags__wrapper__menu__assignemnt",
            //   "tag__element__menu__assignment",
            //   "inner_html_state",
            // ];
            // if (!classNameList.includes(event.target.className)) {
            // }
            handleAddAssignment();
          }
        }
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [
    taskRef,
    selectedMenuAssignment,
    selectedMenuAssignment?.menu,
    taskItem.notes,
    radioButtonValue,
    deliveryValue,
    commsMessageToSendToBackend,
  ]);

  const handleAddAssignment = async () => {
    let dateIS =
      selectedSortDateCustom == null
        ? daysArray[currentDateIndexIs].value
        : `${selectedSortDateCustom.getFullYear()}-${
            selectedSortDateCustom.getMonth() + 1
          }-${selectedSortDateCustom.getDate()}`;

    // // console.log("dateIS===========================>", dateIS);

    let requiredParams = {
      ...createMenuAssignmentData,
      menu_id: selectedMenuAssignment?.menu_id,
      meal_size: radioButtonValue,
      has_delivery: deliveryValue,
      notes: commsMessageToSendToBackend,
      assignment_date: dateIS,
    };
    // // console.log(requiredParams);
    if (selectedMenuAssignment?.menu_id) {
      try {
        const response = await api.post(
          "/menu_assignments/create_menu_assignment",
          requiredParams
        );
        if (response.data.status === "success") {
          toast.success("Menu Assignment Created Successfully!", {
            style: {},
            progressStyle: { backgroundColor: dynamicColor },
            icon: (
              <span
                style={{
                  marginRight: "8px",
                  borderRadius: "50%",
                  width: "7rem",
                  height: "35px",
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: dynamicColor,
                  backgroundColor: "white",
                  fontSize: "1.5rem",
                  border: `3px solid ${dynamicColor}`,
                }}
              >
                ✓
              </span>
            ),
          });
        }

        dispatch(getMenuAssignmentThunk({ id: clientId, date: dateIS }));
        // getMenuAssignments();
      } catch (error: any) {
        if (error?.response?.data?.response) {
          toast.error(error?.response?.data?.response + "!");
        }
      }
    } else {
      toast.warn("Please select the menu item!");
    }
    setSelectedMenuAssignment(null);
    setcommsMessageToSendToBackend("");
    // setEditorState(EditorState.createEmpty());
  };

  const handleUpdateAssignment = async () => {
    let dateIS =
      selectedSortDateCustom == null
        ? daysArray[currentDateIndexIs].value
        : `${selectedSortDateCustom.getFullYear()}-${
            selectedSortDateCustom.getMonth() + 1
          }-${selectedSortDateCustom.getDate()}`;

    let requiredParams = {
      ...createMenuAssignmentData,
      menu_id: selectedMenuAssignment.menu_id,
      menu_assignment_id: selectedMenuAssignment?.menu?.id,
      has_delivery: deliveryValue,
      meal_size: radioButtonValue,
      notes: commsMessageToSendToBackend,
      assignment_date: dateIS,
    };
    try {
      const response = await api.post(
        "/menu_assignments/update_menu_assignment",
        requiredParams
      );
      if (response.data.status === "success") {
        toast.success("Menu Assignment updated successfully!", {
          progressStyle: { backgroundColor: dynamicColor },
          icon: (
            <span
              style={{
                marginRight: "8px",
                borderRadius: "50%",
                width: "7rem",
                height: "35px",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: dynamicColor,
                backgroundColor: "white",
                fontSize: "1.5rem",
                border: `3px solid ${dynamicColor}`,
              }}
            >
              ✓
            </span>
          ),
        });
      }

      // // console.log("Task created successfully:", response.data);
    } catch (error: any) {
      if (error?.response?.data?.response) {
        toast.error(error?.response?.data?.response + "!");
      }
    }
    // getMenuAssignments();
    dispatch(getMenuAssignmentThunk({ id: clientId, date: dateIS }));
    setSelectedMenuAssignment(null);
    setcommsMessageToSendToBackend("");
  };

  useEffect(() => {
    setCreateMenuAssignmentData({
      assignment_date: todaysDate,
      user_id: clientId,
      menu_id: taskItem?.menuId,
      has_delivery: taskItem.has_delivery,
      meal_size: radioButtonValue,
      notes: taskItem.notes,
    });
  }, [
    clientId,
    radioButtonValue,
    taskItem?.menuId,
    taskItem?.has_delivery,
    taskItem.notes,
  ]);

  const [menuAssigmentDataArr, setMenuAssigmentDataArr] = useState<any>([]);

  useEffect(() => {
    if (menuItems) {
      function convertNestedJSONToArrayOfObjects(data: any) {
        const result: any = [];
        const menuTypes = [
          "All Meals",
          "AllMeals",
          "Breakfast",
          "MorningTea",
          "Lunch",
          "AfternoonTea",
          "Dinner",
          "Supper",
        ];
        // // console.log("mealType ====================?", data);

        for (const mealType in menuTypes) {
          // // console.log("mealType ====================?", menuTypes[mealType]);
          if (data[menuTypes[mealType]]) {
            let duplicateMenuRecord: any[] = data[menuTypes[mealType]]?.menu;
            let duplicateMenuAssignmentrecord: any[] = [
              ...data[menuTypes[mealType]]?.data,
            ];
            let fiterDessertRecord = duplicateMenuRecord.filter(
              (res: any) => res.type == "dessert"
            );
            let fiterMainRecord = duplicateMenuRecord.filter(
              (res: any) => res.type == "main"
            );
            let getDessertMenuRecord = [
              ...data[menuTypes[mealType]]?.data,
            ].filter((res: any) => res.type == "dessert");
            let getMainMenuRecord = [...data[menuTypes[mealType]]?.data].filter(
              (res: any) => res.type == "main"
            );
            // // console.log(
            //   "getDessertMenuRecord===================>",
            //   getDessertMenuRecord,
            //   "fiterDessertRecord",
            //   fiterDessertRecord,
            //   mealType
            // );
            if (
              getDessertMenuRecord.length == 0 &&
              fiterDessertRecord.length !== 0
            ) {
              duplicateMenuAssignmentrecord.push({
                title: "",
                summary: "",
                delivery: "",
                tags: [],
                notes: "",
                has_delivery: 0,
                menuId: "",
                default: true,
                type: "dessert",
              });
            }

            if (getMainMenuRecord.length == 0 && fiterMainRecord.length !== 0) {
              duplicateMenuAssignmentrecord.unshift({
                title: "",
                summary: "",
                delivery: "",
                tags: [],
                notes: "",
                has_delivery: 0,
                menuId: "",
                default: true,
                type: "main",
              });
            }
            const dessertMenu: any[] = [];

            const updatedList = (duplicateMenuAssignmentrecord || []).filter(
              (menu: any) => {
                if (menu.type == "dessert") {
                  dessertMenu.push(menu);
                  return false; // Exclude dessert meals from the list
                }
                return true; // Include non-dessert meals in the list
              }
            );
            // // console.log(
            //   "updatedList ===================>",
            //   updatedList,
            //   dessertMenu
            // );

            result.push({
              name: menuTypes[mealType],
              title:
                menuTypes[mealType] === "AllMeal"
                  ? "All Meals"
                  : menuTypes[mealType] == "MorningTea"
                  ? "Morning Tea"
                  : menuTypes[mealType] == "AfternoonTea"
                  ? "Afternoon Tea"
                  : menuTypes[mealType],
              data: {
                data: updatedList.concat(dessertMenu),
                menu: data[menuTypes[mealType]]?.menu,
              },
            });
          }
        }
        return result;
      }

      const arrayOfObjects = convertNestedJSONToArrayOfObjects(menuItems);

      setMenuAssigmentDataArr(arrayOfObjects);
    }
  }, [menuItems]);

  useEffect(() => {
    if (clientId) {
      let dateIS =
        selectedSortDateCustom == null
          ? daysArray[currentDateIndexIs].value
          : `${selectedSortDateCustom.getFullYear()}-${
              selectedSortDateCustom.getMonth() + 1
            }-${selectedSortDateCustom.getDate()}`;

      dispatch(getMenuAssignmentThunk({ id: clientId, date: dateIS }));
    }
  }, [clientId, selectedSortDateCustom, currentDateIndexIs]);

  const onSelectMenuItem = (
    menuInfo: any,
    index: number,
    taskItemindex: number
  ) => {
    let tempMenuAssignmentList = JSON.parse(
      JSON.stringify(menuAssigmentDataArr)
    );

    let itemIndex = -1;
    menuAssigmentDataArr.find((item: any, arraIndex: any) => {
      if (item.name === selectedMenuAssignment?.menu_type) {
        itemIndex = arraIndex;
      }
    });
    // let index =
    if (itemIndex !== -1) {
      let replicateSelectedMenuAssignment = {
        ...tempMenuAssignmentList[itemIndex].data.data[taskItemindex],
      };

      // // console.log(
      //   "replicateSelectedMenuAssignment=================",
      //   replicateSelectedMenuAssignment
      // );
      tempMenuAssignmentList[itemIndex].data.data[taskItemindex].meal_name =
        menuInfo.meal_name;
      tempMenuAssignmentList[itemIndex].data.data[taskItemindex].tags =
        menuInfo.tags;
      tempMenuAssignmentList[itemIndex].data.data[taskItemindex].menu_id =
        menuInfo.menu_id;
      // "has_delivery": 1,
      // "meal_size": "Large"
      setMenuAssigmentDataArr(tempMenuAssignmentList);
      setSelectedMenuAssignment({
        ...selectedMenuAssignment,
        menu_id: menuInfo.menu_id,
      });
    }
  };

  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const handleEditorStateChange = (newEditorState: any) => {
    setEditorState(newEditorState);
    const contentState = newEditorState.getCurrentContent();
    const rawContentState = convertToRaw(contentState);
    const newObject = {
      entityMap: rawContentState.entityMap,
      blocks: rawContentState.blocks.map((block) => ({
        key: block.key,
        text: block.text,
        type: block.type,
        depth: block.depth,
        inlineStyleRanges: block.inlineStyleRanges,
        entityRanges: block.entityRanges,
      })),
    };

    const contentString = JSON.stringify(newObject);
    setcommsMessageToSendToBackend(contentString);
  };
  //  @ts-ignore
  const dynamicStaffId = parseInt(localStorage.getItem("dynamicStaffId"));
  //  @ts-ignore
  const loggedInUserId = parseInt(localStorage.getItem("loggedInUserId"));

  const { getAllStaffData } = useAppSelector((state) => state?.staff);
  useEffect(() => {
    dispatch(getStaffThunk({ dynamicStaffId }));
  }, [dynamicStaffId]);

  const [mentionUsersList, setMentionUsersList] = useState<any[]>([]);

  useEffect(() => {
    if (getAllStaffData?.data) {
      // // console.log("staff ==========================>", getAllStaffData);
      let replicateAllStaffRecords = JSON.parse(
        JSON.stringify(getAllStaffData?.data?.data)
      );

      let convertIntomentionArray = replicateAllStaffRecords
        .map((res: any) => ({
          text: `${res?.first_name || ""} ${res?.last_name || ""}`,
          value: `${res?.first_name || ""} ${res?.last_name || ""}`,
          url: res?.id,
        }))
        .filter((res: any) => res?.url !== loggedInUserId);

      setMentionUsersList(convertIntomentionArray);
    }
  }, [getAllStaffData]);

  return (
    <div className="meal__assignment__container__wrapper">
      <div
        className="daybook__wrapper"
        style={{
          height: 24,
          marginBottom: 15,
        }}
      >
        <div className="menu_sidebar_row_1"></div>
        <div className="menu_content_row_1 menu">
          <h1>Select Date:</h1>
          {daysArray.map(({ title, id, value }: any, index) => (
            <div
              key={index}
              style={{
                width: id === 8 ? 88 : "fit-content",
                textAlign: "center",
                padding: "0 20px",
              }}
              className={`relative sort__tab menu-builder  ${
                (selectedSortTab?.id === id && "sort__tab__selected") || ""
              }`}
              onClick={(e) => {
                if (id !== 8) {
                  setSelectedSortTab({ title, id, value });
                  setCurrentDateIndexIs(index);
                  setSelectedSortDateCustom(null);
                  setDatePickeerSelectedDate(new Date(value));
                }
              }}
            >
              {id === 8
                ? `${selectedSortDate?.getDate()} ${
                    monthNames[selectedSortDate?.getMonth()]
                  } ${selectedSortDate?.getFullYear()}`
                : title}
              {id === 8 && (
                <div className="date-picker-wrapper">
                  <ReactDatePicker
                    showYearDropdown
                    showMonthDropdown
                    selected={datePickeerSelectedDate}
                    value={selectedSortDateCustom}
                    onChange={(date: Date) => {
                      setSelectedSortTab({ title, id, value });
                      let dateIS = new Date(date);
                      setDatePickeerSelectedDate(dateIS);
                      setSelectedSortDateCustom(dateIS);
                      setCurrentDate(dateIS);
                    }}
                  />
                  {/* <CustomDatePicker
                    newDate={selectedSortDateCustom}
                    handleChange={(date: Date) => {
                      setSelectedSortTab({ title, id, value });

                      let dateIS = new Date(date);
                      setSelectedSortDateCustom(dateIS);
                      setTimeout(() => {
                        setCurrentDate(dateIS);
                      }, 1000);
                    }}
                  /> */}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      <div className="daybook__wrapper">
        <div className="daybook__sidebar__row-2">
          <span></span>
          <img src={SortIcon} className="svg-icon-tablehead" alt="" />
        </div>
        <div className="daybook__content__row-2__menu__assignment">
          <div className="editor_btn_group">
            {/* <BoldSVG />
              <ItalicIconSVG />
              <UnderscorSVG /> */}
          </div>
          {selectedMenuAssignment?.menu?.menu_id && (
            <div ref={deleteMenuRef} className="menu_assign_delete">
              <DeleteSVG className="delete_menu_assign_icon" />
            </div>
          )}
        </div>
      </div>

      <div className="all__menu__containers___wrapper">
        {menuAssigmentDataArr.map((item: any, index: number) => {
          return (
            <div className="menu__assignment__wrapper" key={index}>
              <div className="left__persistent__sidebar__fixed" />
              <div
                className="left_sidebar_menu_assignment_new"
                style={{
                  borderTopLeftRadius: index == 0 ? "10px" : "0",
                  borderTopRightRadius: index == 0 ? "10px" : "0",
                }}
              >
                <div className="all__meals">
                  {/* <PlusSVG
                    onClick={() => {
                      handleAddItem(item?.name);
                    }}
                  /> */}
                  {/* <img
                    src={PlusIcon}
                    className="add__icon"
                    onClick={() => handleAddItem(item?.name)}
                  /> */}
                  <span>{item?.title}</span>
                </div>
              </div>
              <div className="right__content__wrapper">
                {item?.data?.data?.length !== 0
                  ? item?.data?.data?.map(
                      (taskIteminfo: any, taskItemindex: number) => {
                        if (
                          selectedMenuAssignment?.menu_type == item?.name &&
                          selectedMenuAssignment?.itemIndex == taskItemindex
                        ) {
                          let duplicateMenuRecords: any[] = JSON.parse(
                            JSON.stringify(item?.data?.menu)
                          );

                          let sortedArray: any[] =
                            duplicateMenuRecords &&
                            duplicateMenuRecords
                              .sort((a: any, b: any) =>
                                a?.meal_name?.localeCompare(b?.meal_name)
                              )
                              .filter((mealInfo: any) =>
                                mealInfo?.meal_name
                                  ?.toLowerCase()
                                  ?.includes(
                                    taskItem?.title?.toLowerCase() || ""
                                  )
                              );
                          return (
                            <div key={taskItemindex}>
                              <div
                                className="task__item__wrapper selected-menu"
                                ref={taskRef}
                                style={{
                                  height: "150px",
                                }}
                              >
                                <div className="left__three__dots___wrapper">
                                  {[...Array(3)].map(
                                    (item: any, dotIndex: number) => {
                                      return (
                                        <div
                                          className="left__three__dots"
                                          key={dotIndex}
                                        />
                                      );
                                    }
                                  )}
                                </div>
                                <div className="writable__content__wrapper">
                                  <input
                                    type="text"
                                    className="input__field__menu__assignment"
                                    placeholder="Search Here..."
                                    value={taskItem.title}
                                    onChange={(e) => {
                                      settaskItem((prevState: any) => ({
                                        ...prevState,
                                        title: e.target.value,
                                      }));
                                    }}
                                    onFocus={() =>
                                      // setState((prevData: any) => ({ ...prevData, dropdownOpen: true }))
                                      setDropdownOpen(true)
                                    }
                                    onBlur={() => {
                                      setTimeout(() => {
                                        setDropdownOpen(false);
                                      }, 500);
                                    }}
                                  />
                                  {dropdownOpen && (
                                    <div className="menu_assign_select_dropdown">
                                      {sortedArray &&
                                        sortedArray?.map(
                                          (
                                            menuInfo: any,
                                            menuIndex: number
                                          ) => {
                                            if (
                                              menuInfo?.type ==
                                              taskIteminfo?.type
                                            ) {
                                              return (
                                                <div
                                                  key={menuIndex}
                                                  className="menu_assign_select_dropdown_option"
                                                  onClick={() => {
                                                    setSelectedMenuItem({
                                                      ...selectedMenuItem,
                                                      meal_id: menuInfo?.id,
                                                    });
                                                    settaskItem(
                                                      (prevState: any) => ({
                                                        ...prevState,
                                                        title:
                                                          menuInfo?.meal_name,
                                                        menuId:
                                                          menuInfo.menu_id,
                                                      })
                                                    );
                                                    onSelectMenuItem(
                                                      menuInfo,
                                                      index,
                                                      taskItemindex
                                                    );
                                                  }}
                                                >
                                                  {menuInfo.meal_name}
                                                </div>
                                              );
                                            } else if (
                                              taskIteminfo.type == "new"
                                            ) {
                                              return (
                                                <div
                                                  key={menuIndex}
                                                  className="menu_assign_select_dropdown_option"
                                                  onClick={() => {
                                                    setSelectedMenuItem({
                                                      ...selectedMenuItem,
                                                      meal_id: menuInfo?.id,
                                                    });
                                                    settaskItem(
                                                      (prevState: any) => ({
                                                        ...prevState,
                                                        title:
                                                          menuInfo?.meal_name,
                                                        menuId:
                                                          menuInfo.menu_id,
                                                      })
                                                    );
                                                    onSelectMenuItem(
                                                      menuInfo,
                                                      index,
                                                      taskItemindex
                                                    );
                                                  }}
                                                >
                                                  {menuInfo.meal_name}
                                                </div>
                                              );
                                            }
                                          }
                                        )}
                                    </div>
                                  )}
                                  <div className="switch__wrapper">
                                    <label>Delivery</label>
                                    <Switch
                                      colorScheme="teal"
                                      size="md"
                                      position={"absolute"}
                                      right={"-20px"}
                                      isChecked={
                                        deliveryValue == 0 ? false : true
                                      }
                                      zIndex={99999}
                                      onChange={(e) => {
                                        setDeliveryValue(
                                          e.target.checked ? 1 : 0
                                        );
                                      }}
                                    />
                                  </div>
                                  <div className="radio__wrapper">
                                    <RadioGroup
                                      value={radioButtonValue}
                                      onChange={(mealSize) => {
                                        setCreateMenuAssignmentData({
                                          ...createMenuAssignmentData,
                                          meal_size: mealSize,
                                        });
                                        setRadioButtonValue(mealSize);
                                      }}
                                    >
                                      <Stack spacing={3} direction="row">
                                        {[
                                          "Extra Small",
                                          "Small",
                                          "Medium",
                                          "Large",
                                        ].map((mealSize, index: number) => (
                                          <Radio
                                            key={index}
                                            value={mealSize}
                                            fontSize={"8px"}
                                            className="radio__button"
                                            width={"51px"}
                                            style={{
                                              borderColor: "#06a5ff",
                                              color: "white",
                                            }}
                                          >
                                            <label
                                              className="label__radio"
                                              style={{ width: "120px" }}
                                            >
                                              {mealSize}
                                            </label>
                                          </Radio>
                                        ))}
                                      </Stack>
                                    </RadioGroup>
                                  </div>
                                  <Editor
                                    wrapperClassName="editor__wrapper__menu__assignment"
                                    toolbarClassName="toolbar__wrapper__menu__assignment"
                                    editorClassName="editor__menu__assignment"
                                    placeholder="Comments..."
                                    toolbar={{
                                      options: ["inline"],
                                      inline: {
                                        options: [
                                          "bold",
                                          "italic",
                                          "underline",
                                        ],
                                      },
                                    }}
                                    mention={{
                                      separator: " ",
                                      trigger: "@",
                                      suggestions: mentionUsersList,
                                    }}
                                    editorState={editorState}
                                    onEditorStateChange={
                                      handleEditorStateChange
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          );
                        } else {
                          const dataObjectMessage = taskIteminfo?.notes;
                          let parsedDataObject: any;
                          try {
                            parsedDataObject = JSON.parse(dataObjectMessage);
                          } catch (error) {
                            parsedDataObject = null;
                          }
                          const htmlContent = convertToHTML(parsedDataObject);

                          return (
                            <div
                              key={taskItemindex}
                              onClick={() => {
                                if (!selectedMenuAssignment) {
                                  setEditorState(EditorState.createEmpty());
                                  setSelectedMenuAssignment({
                                    menu_type: item?.name,
                                    itemIndex: taskItemindex,
                                    menu: taskIteminfo,
                                    menu_id: taskIteminfo?.menu_id,
                                  });
                                  setDeliveryValue(
                                    () => taskIteminfo?.has_delivery
                                  );
                                  setRadioButtonValue(
                                    taskIteminfo?.meal_size || "Medium"
                                  );
                                  settaskItem({
                                    ...taskItem,
                                    title: taskIteminfo?.meal_name,
                                    notes: taskIteminfo?.notes,
                                  });
                                  if (taskIteminfo?.notes) {
                                    const rawValue = taskIteminfo?.notes;
                                    const string =
                                      EditorState.createWithContent(
                                        convertFromRaw(JSON.parse(rawValue))
                                      );
                                    setEditorState(string);
                                  }
                                }
                              }}
                            >
                              <div
                                className={`task__item__wrapper  ${
                                  taskIteminfo.default && "default_menu"
                                } ${
                                  taskIteminfo.type == "dessert" &&
                                  "default_dessert_menu"
                                }  `}
                                // ref={taskRef}
                                style={{
                                  cursor: "pointer",
                                }}
                              >
                                <div className="left__three__dots___wrapper">
                                  {[...Array(3)].map((item: any, dotIndex) => {
                                    return (
                                      <div
                                        className="left__three__dots"
                                        key={dotIndex}
                                      />
                                    );
                                  })}
                                </div>
                                <div className="writable__content__wrapper">
                                  <h1 className="input__field__menu__assignment">
                                    {taskIteminfo?.meal_name}
                                  </h1>
                                  {taskIteminfo?.tags?.length !== 0 && (
                                    <div className="tags__wrapper__menu__assignemnt">
                                      {taskIteminfo?.tags.map(
                                        (tag: string, tagIndex: number) => {
                                          return (
                                            <span
                                              key={tagIndex}
                                              className="tag__element__menu__assignment"
                                            >
                                              {tag}
                                            </span>
                                          );
                                        }
                                      )}
                                    </div>
                                  )}
                                  <div className="inner_html_state">
                                    <div
                                      style={{
                                        fontSize: "12px",
                                        marginTop: "5px",
                                        marginLeft: "15px",
                                        marginBottom: "10px",
                                      }}
                                      // className="inner_html_state"
                                      // contentEditable={true}
                                      id="dangerouslySetInnerHTML"
                                      dangerouslySetInnerHTML={{
                                        __html: htmlContent,
                                      }}
                                    ></div>
                                    {/* {
                                       EditorState.createWithContent(
                                        convertFromRaw(JSON.parse(taskIteminfo?.notes))
                                      )
                                    } */}
                                    {/* {taskIteminfo?.notes} */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        }
                      }
                    )
                  : null}
              </div>
            </div>
          );
        })}
      </div>
      <div ref={deletePopupRef}>
        {hasDeleteMenuAssignment && (
          <UnsavedChangesPopup
            type="menuAssignment"
            onConfirmClick={handleDeleteMenu}
          />
        )}
      </div>
      {/* <Rectangle type="mealbuilder" /> */}
      <CircularNoteTrigger type="mealBuilder" title="Comms" />
    </div>
  );
};

export default MenuAssignment;
