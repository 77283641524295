const ExpandRight = ({ onClick }: any) => {
  return (
    <div onClick={onClick} className="cursor_pointer">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M9 6L15 12L9 18" stroke="#333333" strokeWidth="2" />
      </svg>
    </div>
  );
};

export default ExpandRight;
