export const FilterSVG = () => {
  return (
    <>
      <svg
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="6" cy="6" r="6" fill="#06A5FF" />
        <g clipPath="url(#clip0_106_7243)">
          <path
            d="M9.24302 6.65057L6.19494 9.92476C6.10107 10.0268 5.89724 10.0268 5.80337 9.92476L2.75528 6.65057C2.64666 6.5337 2.7593 6.37524 2.95107 6.37524H9.04589C9.23766 6.37524 9.3503 6.5337 9.24168 6.65057H9.24302Z"
            fill="white"
          />
          <path
            d="M9.26264 4.4043H2.73736C2.60627 4.4043 2.5 4.48278 2.5 4.57959V5.68684C2.5 5.78365 2.60627 5.86214 2.73736 5.86214H9.26264C9.39373 5.86214 9.5 5.78365 9.5 5.68684V4.57959C9.5 4.48278 9.39373 4.4043 9.26264 4.4043Z"
            fill="white"
          />
          <path
            d="M9.26264 3H2.73736C2.60627 3 2.5 3.07848 2.5 3.1753V3.71703C2.5 3.81385 2.60627 3.89233 2.73736 3.89233H9.26264C9.39373 3.89233 9.5 3.81385 9.5 3.71703V3.1753C9.5 3.07848 9.39373 3 9.26264 3Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_106_7243">
            <rect
              width="7"
              height="7"
              fill="white"
              transform="translate(2.5 3)"
            />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};
