
const PurplePlusSVG = () => {
  return (
    <div>
      <svg
        style={{
          cursor: "pointer",
        }}
        width={"12"}
        height={"12"}
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width={"12"} height={"12"} rx="6" fill={"#9150de"} />

        <svg
          width={"12"}
          height={"12"}
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 6L12 18"
            stroke="#fff"
            strokeWidth="2"
            strokeLinecap="round"
          />
          <path
            d="M18 12L6 12"
            stroke="#fff"
            strokeWidth="2"
            strokeLinecap="round"
          />
        </svg>
      </svg>
    </div>
  );
};

export default PurplePlusSVG;
