import { useState, useEffect, useRef } from "react";
import "./MultiSelect.css";
import { api } from "../../../Controller/services/api";
import { useAppSelector } from "../../../Controller/redux/Hooks";
import {toast} from "react-toastify";

interface Option {
  id: number;
  name: string;
}

interface CustomMultiSelectProps {
  type?: "ongoingApprover" | "primaryApprover" | any;
  sendArraysToParent?: any;
  initialOptions?: any;
  providers?: any;
}

const CustomMultiSelect = ({
  type,
  sendArraysToParent,
  initialOptions,
  providers,
}: CustomMultiSelectProps) => {
  const [users, setUsers] = useState<any[] | null>(null);
  const [selectedOptions, setSelectedOptions] = useState<Option[] | any>([]);
  const [searchValue, setSearchValue] = useState("");
  const [filteredOptions, setFilteredOptions] = useState<any>([]);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef<any>(null);

  // const staffId = useAppSelector(
  //   (state) => state.staff.getUserTypeIdForStaff?.id
  // );

  // console.log(providers,"providers")
  // @ts-ignore
  const staffId = parseInt(localStorage.getItem("dynamicStaffId"));
  // // console.log(staffId,"staffId")

  const fetchData = async () => {
    const res = await api.get(`/users?type=${staffId}&status=1,2,7`);
    setUsers(res?.data?.data?.data);
  };
  // // console.log(users,"users")

  useEffect(() => {
    fetchData();
  }, []);

  // console.log(providers,'providers')

  useEffect(() => {
    if (providers) {
      let filteredResults = providers?.filter((elem: any) =>
        elem.tenant_name.toUpperCase().includes(searchValue.toUpperCase())
      );
      setFilteredOptions(filteredResults);
    } else {
      if (users) {
        setFilteredOptions(
          users?.filter(
            (user: any) =>
              Array.isArray(selectedOptions) &&
              !selectedOptions?.find(
                (selected: any) => selected?.id === user?.id
              ) &&
              user?.first_name
                ?.toLowerCase()
                ?.includes(searchValue?.toLowerCase())
          )
        );
      }
    }
  }, [searchValue, users, selectedOptions, providers]);

  // console.log(filteredOptions,"filteredOptions")

  useEffect(() => {
    // Initialize the selected options when the initialOptions prop changes
    setSelectedOptions(initialOptions);
  }, [initialOptions]);

  const handleSelectChange = (newOption: any, type: string) => {
    if (providers) {
      setDropdownOpen(false);
     toast.success("Provider added successfully!", {
          progressStyle: { backgroundColor: dynamicColor },
          icon: (
            <span
              style={{
                marginRight: "8px",
                borderRadius: "50%",
                width: "7rem",
                height: "35px",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: dynamicColor,
                backgroundColor: "white",
                fontSize: "1.5rem",
                border: `3px solid ${dynamicColor}`,
              }}
            >
              ✓
            </span>
          ),
        });
      

    }
    setSearchValue("");

    const updatedSelectedOptions = [...selectedOptions];

    switch (type) {
      case "ongoingApprover":
        updatedSelectedOptions.unshift(newOption);
        setSelectedOptions(updatedSelectedOptions);
        sendArraysToParent("ongoingApprover", updatedSelectedOptions);
        break;

      case "primaryApprover":
        updatedSelectedOptions.unshift(newOption);
        setSelectedOptions(updatedSelectedOptions);
        sendArraysToParent("primaryApprover", updatedSelectedOptions);
        break;

      default:
        console.warn(`Unhandled type: ${type}`);
        break;
    }
  };

  const handleSearchChange = (newValue: any) => {
    setSearchValue(newValue);
    if (newValue) {
      const filteredResults = providers?.filter((option: any) =>
        option?.tenant_name?.toLowerCase().includes(newValue.toLowerCase())
      );
      setFilteredOptions(filteredResults);
      setDropdownOpen(true);
    } else {
      setFilteredOptions(providers);
      setDropdownOpen(false);
    }
    setDropdownOpen(true);
  };

  const dynamicColor = useAppSelector((state) => state?.common?.dynamicColor);

  const handleTagDelete = (optionValue: number, type: any) => {
    const updatedOngoingOptions = selectedOptions.filter(
      (option: { id: number }) => option?.id !== optionValue
    );

    setSelectedOptions(updatedOngoingOptions);
    sendArraysToParent(type, updatedOngoingOptions);
    if(providers){
      toast.success("Provider removed successfully!", {
        progressStyle: { backgroundColor: dynamicColor },
        icon: (
          <span
            style={{
              marginRight: "8px",
              borderRadius: "50%",
              width: "7rem",
              height: "35px",
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: dynamicColor,
              backgroundColor: "white",
              fontSize: "1.5rem",
              border: `3px solid ${dynamicColor}`,
            }}
          >
            ✓
          </span>
        ),
      });
    }
  
    // switch (type) {
    //   case "ongoingApprover":
    //     setSelectedOptions(updatedOngoingOptions);
    //     sendArraysToParent("ongoingApprover", updatedOngoingOptions);
    //     break;

    //   case "primaryApprover":
    //     const updatedPrimaryOptions = selectedOptions.filter(
    //       (option) => option.id !== optionValue
    //     );
    //     setSelectedOptions(updatedPrimaryOptions);
    //     sendArraysToParent("primaryApprover", updatedPrimaryOptions);
    //     break;

    //   default:
    //     console.warn(`Unhandled type: ${type}`);
    //     break;
    // }
  };

  const handleDropdownToggle = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleOutsideClick = (event: any) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  if (users === null) {
    return <div style={{ color: "gray", fontSize: "9px" }}>Loading...</div>;
  }

  return (
    <div className="custom-multi-select-container">
      <input
        className={`search-input ${
          type === "ongoingApprover" ? "ongoing" : "primary"
        }  ${providers && "providers-input"}`}
        value={searchValue}
        onChange={(e) => handleSearchChange(e.target.value)}
        placeholder="Type to search..."
      />
      <div
        className={`  
    ${providers ? "tag-container-provider" : "tag-container"}
    ${type === "ongoingApprover" ? "-ongoing-approver" : ""}
  `}
        onClick={handleDropdownToggle}
      >
        {Array.isArray(selectedOptions) &&
          selectedOptions?.map((option: any) => (
            <div
              key={option?.value}
              className={`tag${
                type === "ongoingApprover" ? "-ongoing-approver " : ""
              }`}
            >
              {option?.first_name || ""} {option?.last_name || ""}{" "}
              {option?.tenant_name || ""}
              {true && (
                <>
                  <span
                    className="tag-close"
                    onClick={() => handleTagDelete(option?.id, type)}
                  >
                    &times;
                  </span>
                </>
              )}
            </div>
          ))}
      </div>
      {isDropdownOpen && (
        <div
          ref={dropdownRef}
          className={`options-dropdown${
            type === "ongoingApprover" ? "-ongoing-approver" : ""
          } ${providers && "options-dropdown-providers"}`}
        >
          {filteredOptions?.map(
            (option: any) =>
              Array.isArray(selectedOptions) &&
              !selectedOptions.some(
                (selected: any) => selected.id === option.id
              ) && (
                <div
                  key={option.id}
                  className="option-item"
                  onClick={() => handleSelectChange(option, type)}
                >
                  {option.first_name || ""} {option.last_name || ""}
                  {option.tenant_name || ""}
                </div>
              )
          )}
        </div>
      )}
    </div>
  );
};

export default CustomMultiSelect;
