import "./MobileAppointments.css";
import { useEffect, useState } from "react";
import { MobileStickyHeader } from "./MobileCard";
import { selectDateCategories } from "../../../pages/Daybook/Daybook";
import { useAppDispatch, useAppSelector } from "../../../../Controller/redux/Hooks";
import StickyItems from "./StickyItems";
import {getDaybookItems} from "../../../../Controller/redux/features/DaybookFeature/daybookSlice";


function MobileAppointments() {
    const dispatch = useAppDispatch();
    const [userId, setuserId] = useState("");

  useEffect(() => {
    let loggedInUserId = localStorage.getItem("loggedInUserId");
    if (loggedInUserId) {
      setuserId(loggedInUserId);
    }
  }, []);
  const { dayBookList: dayBookListRedux } = useAppSelector(
    (state) => state?.daybook
  );
  const [dayBookList, setDayBookList] = useState<any>([]);

   
  useEffect(() => {
    if (dayBookListRedux) {
      const parsedDayBookList =
        Object.keys(dayBookListRedux).length === 0
          ? [{ time: "All Day", task: [] }]
          : Object.keys(dayBookListRedux).map((key) => {
              return {
                time: key === "all" ? "All Day" : key,
                task: dayBookListRedux[key],
              };
            });

      setDayBookList(parsedDayBookList);
    }
  }, [dayBookListRedux]);

  const [activeItemId, setActiveItemId] = useState(-1);
  const [activeTab, setActiveTab] = useState("home");
  const tempDayBookList = JSON.parse(JSON.stringify(dayBookList));
  const [selectedSortTab, setSelectedSortTab] = useState(
    selectDateCategories[1]
  );
  const [selectedSortDateCustom, setSelectedSortDateCustom] =
    useState<any>(null);
  const [showChooseDate, setShowChooseDate] = useState(false);
  const monthNames = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];
  const [selectedSortDate, setSelectedSortDate] = useState<any>(null);
  useEffect(() => {
    setSelectedSortDate(new Date());
  }, []);
  useEffect(() => {
    if (
      selectedSortTab.id !== 5 ||
      (selectedSortTab.id === 5 && selectedSortDateCustom)
    ) {
      let date = "";
      if (selectedSortTab.id === 5 && selectedSortDateCustom) {
        // @ts-ignore
        date = new Date(selectedSortDateCustom);
      }

      if (userId) {
        dispatch(
          // @ts-ignore
          getDaybookItems({
            userId: userId,
            date:
              selectedSortTab.id !== 5
                ? selectedSortTab?.value
                : // @ts-ignore
                  `${date.getFullYear()}-${prefixDate(
                    // @ts-ignore
                    date.getMonth() + 1
                    // @ts-ignore
                  )}-${date.getDate()}`,
          })
        );
      }
    }
  }, [selectedSortTab, userId]);
  const DaySelectorSection = () => {
    const [activeDay, setActiveDay] = useState("Today");
    return (
      <div className="day_tabs_container">
        {selectDateCategories.map(({ title, id, value }: any, index) => (
          <div
            key={index}
            className={`time_picker_tabs ${
              (selectedSortTab?.id === id && "active") || ""
            }`}
            onClick={(e) => {
              setSelectedSortTab({ title, id, value });
              if (id !== 5) {
                setSelectedSortDateCustom(null);
              } else {
                setShowChooseDate(true);
              }
            }}
          >
            {selectedSortDateCustom && id === 5
              ? `${selectedSortDateCustom?.getDate()} ${
                  monthNames[selectedSortDateCustom?.getMonth()]
                } ${selectedSortDateCustom?.getFullYear()}`
              : title}
          </div>
        ))}
      </div>
    );
  };
  return (
    <>
      <MobileStickyHeader title="Find Your  Appointments" />
      <DaySelectorSection />
      <div className="appointments_main_container">
        <div className="appointments_inner_container">
          {dayBookList.map((item: any) => {
            return (
              Array.isArray(item?.task) &&
              item.task.map((daybook: any, index: number) => {
                return (
                  <StickyItems
                    key={daybook.id}
                    item={daybook}
                    setActiveItemId={setActiveItemId}
                    isActive={daybook.id === activeItemId}
                    index={index}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    isFromDayBook={true}
                    isFromClient={false}
                  />
                );
              })
            );
          })}
        </div>
      </div>
    </>
  );
}

export default MobileAppointments;
