export const CalendarSVG = () => {
    return (
      <>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="33"
          height="33"
          viewBox="0 0 33 33"
          fill="none"
        >
          <g clipPath="url(#clip0_671_1147)">
            <path
              d="M30.0505 3.26489H26.9968V1.75532C26.9968 0.77234 26.2334 0 25.2618 0C24.2902 0 23.5268 0.77234 23.5268 1.75532V3.26489H21.1325V1.75532C21.1325 0.77234 20.3691 0 19.3975 0C18.4259 0 17.6625 0.77234 17.6625 1.75532V3.26489H15.2681V1.75532C15.2681 0.77234 14.5047 0 13.5331 0C12.5615 0 11.7981 0.77234 11.7981 1.75532V3.26489H9.40379V1.75532C9.40379 0.77234 8.64038 0 7.66877 0C6.69716 0 5.93375 0.77234 5.93375 1.75532V3.26489H2.88013C1.28391 3.26489 0 4.56383 0 6.17872V30.0862C0 31.7011 1.28391 33 2.88013 33H30.1199C31.7161 33 33 31.7011 33 30.0862V6.17872C32.9306 4.56383 31.6467 3.26489 30.0505 3.26489ZM30.0505 29.3489C30.0505 29.7351 29.7382 30.0862 29.3218 30.0862H3.53943C3.15773 30.0862 2.81073 29.7702 2.81073 29.3489V10.4266C2.81073 10.0404 3.12303 9.68936 3.53943 9.68936H29.3218C29.7035 9.68936 30.0505 10.0053 30.0505 10.4266V29.3489Z"
              fill="#C7C7C7"
            />
            <path
              d="M10.7918 21.5203H7.70351C7.18301 21.5203 6.7666 21.9415 6.7666 22.4681V25.1713C6.7666 25.6979 7.18301 26.1192 7.70351 26.1192H10.7918C11.3123 26.1192 11.7287 25.6979 11.7287 25.1713V22.4681C11.7287 21.9415 11.3123 21.5203 10.7918 21.5203Z"
              fill="#C7C7C7"
            />
            <path
              d="M17.9745 21.5203H14.8861C14.3656 21.5203 13.9492 21.9415 13.9492 22.4681V25.1713C13.9492 25.6979 14.3656 26.1192 14.8861 26.1192H17.9745C18.495 26.1192 18.9114 25.6979 18.9114 25.1713V22.4681C18.9114 21.9415 18.495 21.5203 17.9745 21.5203Z"
              fill="#C7C7C7"
            />
            <path
              d="M25.1922 21.5203H22.1039C21.5834 21.5203 21.167 21.9415 21.167 22.4681V25.1713C21.167 25.6979 21.5834 26.1192 22.1039 26.1192H25.1922C25.7127 26.1192 26.1291 25.6979 26.1291 25.1713V22.4681C26.1291 21.9415 25.7127 21.5203 25.1922 21.5203Z"
              fill="#C7C7C7"
            />
            <path
              d="M10.7918 14.0426H7.70351C7.18301 14.0426 6.7666 14.4639 6.7666 14.9905V17.6937C6.7666 18.2203 7.18301 18.6415 7.70351 18.6415H10.7918C11.3123 18.6415 11.7287 18.2203 11.7287 17.6937V14.9905C11.7287 14.4639 11.3123 14.0426 10.7918 14.0426Z"
              fill="#C7C7C7"
            />
            <path
              d="M17.9745 14.0426H14.8861C14.3656 14.0426 13.9492 14.4639 13.9492 14.9905V17.6937C13.9492 18.2203 14.3656 18.6415 14.8861 18.6415H17.9745C18.495 18.6415 18.9114 18.2203 18.9114 17.6937V14.9905C18.9114 14.4639 18.495 14.0426 17.9745 14.0426Z"
              fill="#C7C7C7"
            />
            <path
              d="M25.1922 14.0426H22.1039C21.5834 14.0426 21.167 14.4639 21.167 14.9905V17.6937C21.167 18.2203 21.5834 18.6415 22.1039 18.6415H25.1922C25.7127 18.6415 26.1291 18.2203 26.1291 17.6937V14.9905C26.1291 14.4639 25.7127 14.0426 25.1922 14.0426Z"
              fill="#C7C7C7"
            />
          </g>
          <defs>
            <clipPath id="clip0_671_1147">
              <rect width="33" height="33" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </>
    );
}