import { useEffect, useState } from "react";
import { GreenCircleSvg } from "../../../pages/People/ClientPage/Components/UniqueClientScreen/Components/ClinicalTab/Data/SVGs";
import "./MobileCard.css";
import TrafficLights from "../../CommonModal/Components/TrafficLights";
import { ClientStatusCircleXlSvg } from "../../../../Model/Assets/SVG/WidgetVillage/ClientStatusCircleSvg";

import message from "./assets/icons/message_bubble.png";
import user from "./assets/icons/user.png";
import expenses from "./assets/icons/expenses.png";
import activity from "./assets/icons/activity.png";
import slider from "./assets/icons/sliders.png";
import info from "./assets/icons/info.png";
import calendar from "./assets/icons/calendar.png";
import cart from "./assets/icons/cart.png";
import notes from "./assets/icons/notes.png";
import ClinicalProgress from "../../ClinicalProgress/ClinicalProgress";
import { useNavigate } from "react-router-dom";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../Controller/redux/Hooks";
import {
  getUniqueClientById,
} from "../../../../Controller/redux/features/ClientFeature/clientsSlice";

export const BottomTabs = ({
  activeTab,
  setActiveTab,
  item,
  isHomeScreen,
  isActive,
}: any) => {
  useEffect(() => {
    const handleScroll = () => {
      let elem = document.querySelector(".mobile_sticky_footer");
      if (!elem) return;
      let currentSticyPosition = elem?.getBoundingClientRect().top;
      if (window.scrollY > currentSticyPosition) {
      }
    };
  }, [isActive]);
  const navigate = useNavigate();
  return (
    <div
      className="mobile_sticky_footer"
      style={{ position: isActive && "sticky", bottom: 0 }}
    >
      <span
        className={`tab_icon_container ${activeTab === "home" ? "active" : ""}`}
        onClick={(e) => {
          e.stopPropagation();
          setActiveTab("home");
          navigate("/");
        }}
      >
        {item?.profile_image_s3 ? (
          <img
            src={
              item?.profile_image_s3
                ? item?.profile_image_s3
                : "https://avatar.iran.liara.run/public/boy?username=Ash"
            }
            alt="profile"
            className={
              isHomeScreen
                ? "profile_image_container homescreen"
                : "profile_image_container"
            }
          />
        ) : (
          <div
            className={
              isHomeScreen
                ? "profile_image_container homescreen"
                : "profile_image_container"
            }
          >
            {`${
              item?.first_name?.charAt(0) ? item?.first_name?.charAt(0) : ""
            }  ${item?.last_name?.charAt(0) ? item?.last_name?.charAt(0) : ""}`}
          </div>
        )}
      </span>
      <span
        className={`tab_icon_container ${
          activeTab === "calendar" ? "active" : ""
        }`}
        onClick={(e) => {
          e.stopPropagation();
          setActiveTab("calendar");
        }}
      >
        <img src={calendar} height={35} width={35} />
      </span>
      {isHomeScreen && (
        <span
          className={`tab_icon_container ${
            activeTab === "message" ? "active" : ""
          }`}
          onClick={(e) => {
            e.stopPropagation();
            setActiveTab("message");
          }}
        >
          <img src={message} height={35} width={35} />
        </span>
      )}
      <span
        className={`tab_icon_container ${activeTab === "cart" ? "active" : ""}`}
        onClick={(e) => {
          e.stopPropagation();
          setActiveTab("cart");
        }}
      >
        <img src={cart} height={30} width={30} />
      </span>
      {isHomeScreen && (
        <span
          className={`tab_icon_container ${
            activeTab === "user" ? "active" : ""
          }`}
          onClick={(e) => {
            e.stopPropagation();
            setActiveTab("user");
          }}
        >
          <img src={user} height={30} width={30} />
        </span>
      )}
    </div>
  );
};

const NameTag = ({ userData, item, isFromDaybook }: any) => {
  let client_full_name =
    item?.first_name?.charAt(0).toUpperCase() +
    item?.first_name.slice(1).toLowerCase() +
    " " +
    item?.last_name?.charAt(0).toUpperCase() +
    item?.last_name.slice(1).toLowerCase();

  let user_full_name =
    userData?.first_name?.charAt(0).toUpperCase() +
    userData?.first_name.slice(1).toLowerCase() +
    " " +
    userData?.last_name?.charAt(0).toUpperCase() +
    userData?.last_name.slice(1).toLowerCase();
  return (
    <>
      {!isFromDaybook && (
        <div className="nametag_container">
          {`${client_full_name}`}
          {item?.profile_image_s3 ? (
            <img
              src={
                item?.profile_image_s3
                  ? item?.profile_image_s3
                  : "https://avatar.iran.liara.run/public/boy?username=Ash"
              }
              alt="profile"
              className="profile_image_container"
              style={{ width: "25px", height: "25px", marginLeft: "4px" }}
            />
          ) : (
            <div
              className="profile_image_container"
              style={{
                width: "25px",
                height: "25px",
                marginLeft: "4px",
                fontSize: "9px",
              }}
            >
              {`${
                item?.first_name?.charAt(0) ? item?.first_name?.charAt(0) : ""
              }  ${
                item?.last_name?.charAt(0) ? item?.last_name?.charAt(0) : ""
              }`}
            </div>
          )}
        </div>
      )}
      {isFromDaybook && (
        <div className="nametag_container">
          {`${user_full_name}`}
          {userData?.profile_image_s3 ? (
            <img
              src={
                item?.profile_image_s3
                  ? item?.profile_image_s3
                  : "https://avatar.iran.liara.run/public/boy?username=Ash"
              }
              alt="profile"
              className="profile_image_container"
              style={{ width: "25px", height: "25px", marginLeft: "4px" }}
            />
          ) : (
            <div
              className="profile_image_container"
              style={{ width: "25px", height: "25px", marginLeft: "4px" }}
            >
              {`${
                userData?.first_name?.charAt(0)
                  ? userData?.first_name?.charAt(0)
                  : ""
              }  ${
                userData?.last_name?.charAt(0)
                  ? userData?.last_name?.charAt(0)
                  : ""
              }`}
            </div>
          )}
        </div>
      )}
    </>
  );
};

const CustomTag = ({ title, backgroundColor, isIcon, item, color }: any) => {
  return (
    <div
      className="custom_tag_container"
      style={{ backgroundColor: backgroundColor, color: color }}
    >
      {title}
      {isIcon &&
        (item?.profile_image_s3 ? (
          <img
            src={
              item?.profile_image_s3
                ? item?.profile_image_s3
                : "https://avatar.iran.liara.run/public/boy?username=Ash"
            }
            alt="profile"
            className="profile_image_container"
            style={{ width: "20px", height: "20px", marginLeft: "4px" }}
          />
        ) : (
          <div
            className="profile_image_container"
            style={{ width: "20px", height: "20px", marginLeft: "4px" }}
          >
            {`${
              item?.first_name?.charAt(0) ? item?.first_name?.charAt(0) : ""
            }  ${item?.last_name?.charAt(0) ? item?.last_name?.charAt(0) : ""}`}
          </div>
        ))}
    </div>
  );
};

const CalendarTabViewHeader = ({ item, isActive, daybookItem }: any) => {
  return (
    <>
      {item?.profile_image_s3 ? (
        <img
          src={
            item?.profile_image_s3
              ? item?.profile_image_s3
              : "https://avatar.iran.liara.run/public/boy?username=Ash"
          }
          alt="profile"
          className="profile_image_container"
          style={{ width: "25px", height: "25px", marginLeft: "4px" }}
        />
      ) : (
        <div
          className="profile_image_container"
          style={{ width: "25px", height: "25px", marginLeft: "4px" }}
        >
          {`${item?.first_name?.charAt(0) ? item.first_name?.charAt(0) : ""}  ${
            item?.last_name?.charAt(0) ? item.last_name?.charAt(0) : ""
          }`}
        </div>
      )}
      <div className="calendar_tab_header_inner_container">
        <div className="calendar_tab_header_inner_container_left_section">
          <div className="plus_icon_container">+</div>
          <div className="service_text_container">SERVICE</div>

          <div className="headerTag_container">
            <CustomTag
              title={"Personal care"}
              backgroundColor={"#d1d1d1"}
              isIcon={true}
              item={item}
            />
            {!isActive &&
              `${daybookItem.start_time ? daybookItem.start_time : ""}${
                daybookItem.start_time && daybookItem.end_time ? " to " : ""
              }${daybookItem.end_time ? daybookItem.end_time : ""}`}
          </div>
        </div>
        <div className="status_container">
          <ClientStatusCircleXlSvg
            count={daybookItem?.communications?.length}
            fill="#06A5FF"
          />
        </div>
      </div>
    </>
  );
};

const CalendarTabViewContent = ({
  isActive,
  item,
  activeTab,
  setActiveTab,
}: any) => {
  return (
    <div className="calendar_tab_content_container_outer">
      <div className="calendar_tab_content_container">
        <div>
          <CustomTag
            title={"Personal care"}
            backgroundColor={"beige"}
            isIcon={true}
            item={item}
          />
        </div>
        <div className="circular_tags">s3</div>
        <div className="circular_tags">G34</div>
      </div>
      <div className="start_end_tags">
        <div className="start_end_tags_inner">
          <CustomTag
            title={"START"}
            backgroundColor={"#06A5FF"}
            isIcon={false}
            color={"white"}
          />
          <CustomTag
            title={"END"}
            backgroundColor={"#06A5FF"}
            isIcon={false}
            color={"white"}
          />
        </div>
        <div className="start_end_tags_inner">
          <CustomTag
            title={item.start ? item.start_time : "__.__"}
            backgroundColor={"#2AD400"}
            isIcon={false}
            color={"white"}
          />
          <CustomTag
            title={item.start ? item.end_time : "__:__"}
            backgroundColor={"#BDBDBD"}
            isIcon={false}
            color={"white"}
          />
        </div>
      </div>
      <CardBottomTab
        isActive={isActive}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
    </div>
  );
};

const CardBottomTab = ({ isActive, activeTab, setActiveTab }: any) => {
  return (
    <div
      className="card_tab_bar_container"
      style={{ position: isActive && "sticky", bottom: 0 }}
    >
      <span
        className={`card_tab_bar_icon_container ${
          activeTab === "obs" ? "active" : ""
        }`}
        onClick={(e) => {
          e.stopPropagation();
          setActiveTab("obs");
        }}
      >
        Obs
        <img src={activity} height={21} width={21} />
      </span>
      <span
        className={`card_tab_bar_icon_container ${
          activeTab === "reports" ? "active" : ""
        }`}
        onClick={(e) => {
          e.stopPropagation();
          setActiveTab("reports");
        }}
      >
        Reports
        <img src={slider} height={21} width={21} />
      </span>
      <span
        className={`card_tab_bar_icon_container ${
          activeTab === "medications" ? "active" : ""
        }`}
        onClick={(e) => {
          e.stopPropagation();
          setActiveTab("medications");
        }}
      >
        Medications
        <img src={calendar} height={21} width={21} />
      </span>
      <span
        className={`card_tab_bar_icon_container ${
          activeTab === "notes" ? "active" : ""
        }`}
        onClick={(e) => {
          e.stopPropagation();
          setActiveTab("notes");
        }}
      >
        Notes
        <img src={notes} height={21} width={21} />
      </span>
      <span
        className={`card_tab_bar_icon_container ${
          activeTab === "info" ? "active" : ""
        }`}
        onClick={(e) => {
          e.stopPropagation();
          setActiveTab("info");
        }}
      >
        Info
        <img src={info} height={21} width={21} />
      </span>
      <span
        className={`card_tab_bar_icon_container ${
          activeTab === "expenses" ? "active" : ""
        }`}
        onClick={(e) => {
          e.stopPropagation();
          setActiveTab("expenses");
        }}
      >
        Expenses
        <img src={expenses} height={25} width={25} />
      </span>
    </div>
  );
};

const ObsTabContent = ({ isActive, activeTab, setActiveTab, item }: any) => {
  return (
    <>
      <div className="obs_tab_container_outer">
        <div className="obs_tab_container">
          <div className="calendar_tab_content_container">
            <div>
              <CustomTag
                title={"Personal care"}
                backgroundColor={"beige"}
                isIcon={true}
                item={item}
              />
            </div>
            <div className="circular_tags">s3</div>
            <div className="circular_tags">G34</div>
          </div>
          <div className="obs_info_container">
            Heart Rate:
            <img
              src={"https://avatar.iran.liara.run/public/boy?username=Ash"}
              height={21}
              width={21}
            />
          </div>
          <div className="obs_info_container">
            Blood Pressure:
            <img
              src={"https://avatar.iran.liara.run/public/boy?username=Ash"}
              height={21}
              width={21}
            />
          </div>
        </div>
        <CardBottomTab
          isActive={isActive}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
      </div>
    </>
  );
};

const NotesTabContent = ({ isActive, item, activeTab, setActiveTab }: any) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        width: "100%",
        height: "460px",
      }}
    >
      <div style={{ display: "flex", height: "250px" }}>
        <ClinicalProgress />
      </div>
      <CardBottomTab
        isActive={isActive}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
    </div>
  );
};

const CommonContent = ({ isActive, activeTab, setActiveTab }: any) => {
  return (
    <div className="common_content_container">
      <CardBottomTab
        isActive={isActive}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
    </div>
  );
};

const StickyItems = ({
  item,
  isActive,
  index,
  setActiveItemId,
  activeTab,
  setActiveTab,
  isFromDayBook,
  isFromClient,
}: any) => {
  const dispatch = useAppDispatch();

  const dynamicClientId = localStorage.getItem("dynamicClientId");
  const styleClass =
    index % 2 !== 0
      ? "sticky_item_container_dark"
      : "sticky_item_container_light";

  const [showLights, setShowLights] = useState<boolean>(false);
  const [trafficColors, setTrafficColors] = useState<any>("");
  const [showBigBar, setShowBigBar] = useState<boolean>(true);
  const userData = useAppSelector((state) => state.common.user);
  const { uniqueClient } = useAppSelector((state) => state?.people);

  useEffect(() => {
    const handleScroll = () => {
      let smallTabs = document.querySelector(".card_tab_bar_container");
      if (!smallTabs) return;
      // console.log("elem in small tab bar: ", smallTabs);
      let currentSticyPosition = smallTabs?.getBoundingClientRect().top;
      if (window.scrollY > currentSticyPosition) {
      }
    };
  }, [isActive]);

  return (
    <div
      className={styleClass}
      onClick={
        isActive
          ? () => {
              setActiveItemId(-1);
            }
          : () => {
              setActiveItemId(isFromDayBook ? item.id : index);
              if (isFromDayBook && item?.tagged_user) {
                const taggedUsers = item?.tagged_user?.split(",");
                const clientId = parseInt(taggedUsers[taggedUsers?.length - 1]);
                dispatch(
                  getUniqueClientById({
                    typeId: dynamicClientId,
                    clientId,
                  })
                );
              }
            }
      }
      style={{
        height: isActive ? "400px" : "120px",
        borderBottomLeftRadius: isActive ? "12px" : "0",
        borderBottomRightRadius: isActive ? "12px" : "0",
      }}
    >
      <div className="sticky_item_header">
        <div className="dot_container">
          <GreenCircleSvg
            onClick={() => {
              setShowLights(!showLights);
            }}
            color={trafficColors}
            size={24}
          />
          {showLights && (
            <TrafficLights
              className={"client_taffic_light"}
              type="goals"
              setShowLights={setShowLights}
              handleTrafficColors={(data: any) => {
                setTrafficColors(data);
                setShowLights(false);
              }}
              isSmallLight={false}
            />
          )}
          {activeTab === "home" && !isFromDayBook ? (
            <NameTag
              user={userData}
              item={item}
              isFromDayBook={isFromDayBook}
            />
          ) : (
            <CalendarTabViewHeader
              item={userData}
              isActive={isActive}
              daybookItem={item}
            />
          )}
        </div>
      </div>
      {isActive && activeTab === "home" && isFromClient && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "100%",
            height: "100%",
          }}
        >
          <div className="sticky_title_container">
            <h3 className="sticky_item_title_bold">
              {item?.first_name} {item.last_name}
            </h3>
            <h3 className="sticky_item_title">Email Id: {item.email}</h3>
            <h3 className="sticky_item_title">Contact number :{item.phone}</h3>
            <div className="sticky_content">{item.content}</div>
          </div>
        </div>
      )}
      {isActive && activeTab === "home" && isFromDayBook && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "100%",
            height: "100%",
          }}
        >
          <div className="sticky_title_container">
            <div className="nameTag_container">
              <CustomTag
                title={userData?.first_name + " " + userData?.last_name}
                backgroundColor={"#d1d1d1"}
                isIcon={true}
                item={userData}
              />
            </div>

            <textarea
              className="tagged_user_address"
              value={
                "Location: " +
                (item?.tagged_user
                  ? uniqueClient?.data?.data?.[0]?.user_addresses?.[0]
                      ?.address || ""
                  : "")
              }
              disabled
            />

            <div className="task_summary_container">
              <div className="note_label">Notes</div>
              <textarea
                className="task_summary"
                value={item?.summary || ""}
                disabled
              />
            </div>
          </div>
          <CardBottomTab activeTab={activeTab} setActiveTab={setActiveTab} />
        </div>
      )}

      {isActive && activeTab === "medications" && (
        <>
          <CalendarTabViewContent
            item={userData}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            isActive={isActive}
          />
        </>
      )}
      {isActive && activeTab === "obs" && (
        <>
          <ObsTabContent
            item={userData}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            isActive={isActive}
          />
        </>
      )}
      {isActive && activeTab === "notes" && (
        <>
          <NotesTabContent
            isActive={isActive}
            item={item}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        </>
      )}
      {isActive && activeTab === "reports" && (
        <>
          <CommonContent
            isActive={isActive}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        </>
      )}
      {isActive && activeTab === "info" && (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              width: "100%",
              height: "100%",
            }}
          >
            <div className="sticky_title_container">
              <h3 className="sticky_item_title_bold">
                {item?.first_name} {item.last_name}
              </h3>
              <h3 className="sticky_item_title">Task: {item.title}</h3>
              <h3 className="sticky_item_title">Summary :{item.summary}</h3>
              <div className="sticky_content">{item.content}</div>
            </div>
            <CardBottomTab activeTab={activeTab} setActiveTab={setActiveTab} />
          </div>
          {/* <CommonContent activeTab={activeTab} setActiveTab={setActiveTab} /> */}
        </>
      )}
      {isActive && activeTab === "expenses" && (
        <>
          {
            <CommonContent
              isActive={isActive}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
          }
        </>
      )}
      {isActive && !isFromDayBook && (
        <BottomTabs
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          item={item}
          setShowBigBar={setShowBigBar}
          isHomeScreen={false}
          isActive={isActive}
        />
      )}
    </div>
  );
};

export default StickyItems;
