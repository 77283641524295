export const CompletionSvg = () => {
  return (
    <>
      <svg
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.91815 12C9.18665 12 11.8363 9.31371 11.8363 6C11.8363 2.68629 9.18665 0 5.91815 0C2.64964 0 0 2.68629 0 6C0 9.31371 2.64964 12 5.91815 12Z"
          fill="#828282"
        />
        <path
          d="M5.91797 12C9.18641 12 11.8361 9.31373 11.8361 6.00019C11.8361 2.68664 9.18677 0 5.91797 0V12Z"
          fill="#F0B61C"
        />
        <path
          d="M5.91941 10.6673C8.46158 10.6673 10.5224 8.57798 10.5224 6.00065C10.5224 3.42332 8.46158 1.33398 5.91941 1.33398C3.37724 1.33398 1.31641 3.42332 1.31641 6.00065C1.31641 8.57798 3.37724 10.6673 5.91941 10.6673Z"
          fill="white"
        />
      </svg>
    </>
  );
};
export const LineSvg = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        style={{
          position: "relative",
          top: "0.4rem",
        }}
        width="386"
        height="2"
        viewBox="0 0 386 2"
        fill="none"
      >
        <path
          d="M1 1L385 0.999973"
          stroke="#9296A2"
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke-dasharray="4"
        />
      </svg>
    </>
  );
};

export const PDFSvg = ({ onClick = () => {} }: { onClick?: any }) => {
  return (
    <>
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        onClick={onClick}
        cursor={"pointer"}
      >
        <g clipPath="url(#clip0_90_6218)">
          <path
            d="M18 5.58691V16.8044C18 17.4653 17.4342 18 16.7374 18H1.26256C0.565812 18 0 17.4638 0 16.8044V1.19564C0 0.536243 0.565812 0 1.26256 0H12.1657"
            fill="#E13D35"
          />
          <path d="M12.1641 0V5.58691H17.9968L12.1641 0Z" fill="#AF1010" />
          <path
            d="M5.33594 10.3958V7.60547H6.23999C6.58291 7.60547 6.8058 7.6195 6.91024 7.64756C7.07078 7.68965 7.20327 7.78006 7.31238 7.92036C7.41993 8.06065 7.47449 8.24148 7.47449 8.46439C7.47449 8.63587 7.44332 8.77928 7.38097 8.89619C7.31862 9.01311 7.23912 9.10508 7.14404 9.17055C7.04896 9.23758 6.95076 9.28123 6.85256 9.30305C6.71851 9.32955 6.52368 9.34358 6.26805 9.34358H5.90019V10.3958H5.3375H5.33594ZM5.89863 8.0778V8.86969H6.20726C6.42859 8.86969 6.57823 8.85566 6.65305 8.82605C6.72787 8.79643 6.7871 8.75122 6.82918 8.68887C6.87127 8.62651 6.89309 8.55481 6.89309 8.47219C6.89309 8.37086 6.86347 8.28668 6.80424 8.22121C6.74501 8.15574 6.66864 8.11365 6.57823 8.09806C6.51121 8.08559 6.3756 8.07936 6.17297 8.07936H5.90019L5.89863 8.0778Z"
            fill="white"
          />
          <path
            d="M7.93359 7.60547H8.96234C9.19459 7.60547 9.37073 7.62262 9.49386 7.65847C9.65753 7.70679 9.79781 7.79253 9.91472 7.91568C10.0316 8.03883 10.1205 8.19004 10.1813 8.36774C10.242 8.54545 10.2732 8.76525 10.2732 9.02714C10.2732 9.25629 10.2452 9.45426 10.1875 9.62106C10.1173 9.82371 10.0176 9.98894 9.88822 10.1137C9.79002 10.2087 9.65909 10.2836 9.49231 10.3366C9.36761 10.3755 9.20238 10.3958 8.99352 10.3958H7.93359V7.60547ZM8.49629 8.0778V9.92503H8.91714C9.07457 9.92503 9.18836 9.91568 9.2585 9.89853C9.35046 9.87515 9.42528 9.83774 9.48607 9.78318C9.54686 9.72862 9.59518 9.6382 9.63415 9.5135C9.67312 9.38879 9.69182 9.21887 9.69182 9.0022C9.69182 8.78552 9.67312 8.62028 9.63415 8.50492C9.59674 8.38957 9.54218 8.29916 9.4736 8.23524C9.40502 8.16977 9.31773 8.12612 9.2133 8.1043C9.13536 8.08715 8.98105 8.0778 8.75036 8.0778H8.49785H8.49629Z"
            fill="white"
          />
          <path
            d="M10.7539 10.3958V7.60547H12.6664V8.0778H11.3182V8.73875H12.4825V9.21108H11.3182V10.3958H10.7555H10.7539Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_90_6218">
            <rect width="18" height="18" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};

export const SheetSvg = ({ onClick = () => {} }: { onClick?: any }) => {
  return (
    <>
      <svg
        width="19"
        height="18"
        viewBox="0 0 19 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        onClick={onClick}
        cursor={"pointer"}
      >
        <g id="Layer_1" clipPath="url(#clip0_90_6202)">
          <g id="Group">
            <path
              id="Vector"
              d="M18.3359 5.58691V16.8044C18.3359 17.4653 17.7701 18 17.0734 18H1.59849C0.90175 18 0.335938 17.4638 0.335938 16.8044V1.19564C0.335938 0.536243 0.90175 0 1.59849 0H12.5017"
              fill="#2AD400"
            />
            <path
              id="Vector_2"
              d="M12.5 0V5.58691H18.3327L12.5 0Z"
              fill="#24A500"
            />
          </g>
          <path
            id="Vector_3"
            d="M4.79688 7.69727V13.9592H13.8779V7.69727H4.79688ZM13.0986 9.52423H9.72708V8.47669H13.0986V9.52423ZM9.72708 10.3037H13.0986V11.3512H9.72708V10.3037ZM8.94772 11.3512H5.57623V10.3037H8.94772V11.3512ZM8.94772 8.47669V9.52423H5.57623V8.47669H8.94772ZM5.57623 12.1306H8.94772V13.1782H5.57623V12.1306ZM9.72708 13.1782V12.1306H13.0986V13.1782H9.72708Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_90_6202">
            <rect
              width="18"
              height="18"
              fill="white"
              transform="translate(0.335938)"
            />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};

export const WordSvg = ({ onClick = () => {} }: { onClick?: any }) => {
  return (
    <>
      <svg
        width="19"
        height="18"
        viewBox="0 0 19 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        onClick={onClick}
        cursor={"pointer"}
      >
        <g id="Layer_1" clipPath="url(#clip0_90_6208)">
          <g id="Group">
            <path
              id="Vector"
              d="M18.6641 5.58691V16.8044C18.6641 17.4653 18.0982 18 17.4015 18H1.92662C1.22987 18 0.664062 17.4638 0.664062 16.8044V1.19564C0.664062 0.536243 1.22987 0 1.92662 0H12.8298"
              fill="#06A5FF"
            />
            <path
              id="Vector_2"
              d="M12.8281 0V5.58691H18.6608L12.8281 0Z"
              fill="#0088C4"
            />
          </g>
          <g id="Group_2">
            <path
              id="Vector_3"
              d="M13.8134 7.69727H5.51172V8.47669H13.8134V7.69727Z"
              fill="white"
            />
            <path
              id="Vector_4"
              d="M13.8134 9.52344H5.51172V10.3029H13.8134V9.52344Z"
              fill="white"
            />
            <path
              id="Vector_5"
              d="M13.8134 11.3516H5.51172V12.131H13.8134V11.3516Z"
              fill="white"
            />
            <path
              id="Vector_6"
              d="M10.2627 13.1777H5.51172V13.9572H10.2627V13.1777Z"
              fill="white"
            />
          </g>
        </g>
        <defs>
          <clipPath id="clip0_90_6208">
            <rect
              width="18"
              height="18"
              fill="white"
              transform="translate(0.664062)"
            />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};

export const UploadDocSvg = ({
  onClick = () => {},
}: {
  onClick?: () => void;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="22"
    viewBox="0 0 12 22"
    fill="none"
    onClick={onClick}
    cursor="pointer"
  >
    <path
      d="M11 1L0.999999 11L11 21"
      stroke="#F3F3F3"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const FileInputSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    style={{
      position: "absolute",
      left: "-28px",
      bottom: "0px",
    }}
    // onClick={(event: any) => {
    //   handleFileSelection(event);
    // }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.42188 8.33281L5.5432 10.3977L10.4825 5.58979L10.4825 15.2055C10.4825 16.0119 11.1541 16.6656 11.9825 16.6656C12.811 16.6656 13.4825 16.0119 13.4825 15.2055L13.4825 5.58979L18.4219 10.3977L20.5432 8.3328L11.9825 -3.73345e-06L3.42188 8.33281Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 18.1562V19.6163C3 20.4227 3.67157 21.0764 4.5 21.0764L19.5 21.0764C20.3284 21.0764 21 20.4227 21 19.6163V18.1562H24V19.6163C24 22.0355 21.9853 23.9966 19.5 23.9966L4.5 23.9966C2.01472 23.9966 0 22.0355 0 19.6163V18.1562H3Z"
      fill="black"
    />
  </svg>
);

export const CrossSvg = ({
  onClick = () => {},
  style = {},
}: {
  onClick?: () => void;
  style?: any;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="cross-icon"
    onClick={onClick}
    style={style}
    width="2"
    height="2"
    viewBox="0 0 10 10"
    fill="none"
  >
    <path d="M9.00017 1L1.00017 9" stroke="#212121" />
    <path d="M9.00017 9L1.00017 0.999999" stroke="#212121" />
  </svg>
);

export const MessageCounterSvg = ({
  onClick = () => {},
  getUnreadMessages = 0,
  dataObject = {},
}: {
  onClick?: () => void;
  getUnreadMessages?: number;
  dataObject?: any;
}) => (
  <svg
    onClick={onClick}
    style={{ cursor: "pointer" }}
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
  >
    <path
      d="M6 0C9.3138 0 12 2.6862 12 6C12 9.3138 9.3138 12 6 12C5.0424 12.0013 4.0985 11.7725 3.2478 11.3328C2.97312 11.1438 2.6433 11.1219 2.35089 11.2821C1.79004 11.5894 0.888309 12.0621 0.560239 11.976C0.431624 11.9423 0.314294 11.875 0.220271 11.7809C0.126247 11.6869 0.0589109 11.5696 0.0251613 11.441C-0.00858834 11.3123 -0.00753788 11.1771 0.028205 11.049L0.669005 8.7552C0.228216 7.90376 -0.00124128 6.95877 5.05017e-06 6C5.05017e-06 2.6862 2.6862 0 6 0ZM4.05 6.6H6.7488C6.86282 6.60003 6.97257 6.64334 7.05588 6.72118C7.13919 6.79902 7.18985 6.90558 7.19762 7.01933C7.20539 7.13307 7.16969 7.24553 7.09774 7.33398C7.02579 7.42242 6.92295 7.48026 6.81 7.4958L6.7488 7.5H4.05C3.93599 7.49996 3.82624 7.45665 3.74293 7.37881C3.65962 7.30098 3.60896 7.19442 3.60119 7.08067C3.59342 6.96692 3.62911 6.85446 3.70106 6.76602C3.77301 6.67757 3.87585 6.61974 3.9888 6.6042L4.05 6.6ZM4.05 4.5H7.953C8.06697 4.50018 8.17661 4.5436 8.25981 4.62149C8.343 4.69937 8.39355 4.80592 8.40124 4.91962C8.40893 5.03333 8.3732 5.14571 8.30125 5.23409C8.2293 5.32247 8.1265 5.38026 8.0136 5.3958L7.953 5.4H4.05C3.93599 5.39996 3.82624 5.35665 3.74293 5.27881C3.65962 5.20098 3.60896 5.09442 3.60119 4.98067C3.59342 4.86692 3.62911 4.75446 3.70106 4.66602C3.77301 4.57757 3.87585 4.51974 3.9888 4.5042L4.05 4.5Z"
      fill={getUnreadMessages > 0 ? "rgb(239, 58, 52)" : "#06A5FF"}
    />
    <text
      x="6"
      y="6"
      fill="#FFF"
      textAnchor="middle"
      fontFamily="Arial"
      fontSize="10px"
      fontStyle="normal"
      fontWeight="400"
      letterSpacing="0.25px"
      alignmentBaseline="central"
    >
      {dataObject ? dataObject?.communications?.length : getUnreadMessages}
    </text>
  </svg>
);