import React, { useEffect, useRef, useState } from "react";
import Table from "../../../components/CommonTable/Table";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../Controller/redux/Hooks";
import { useNavigate, useParams } from "react-router-dom";
import {
  getProviderClientFilterThunk,
  getProviderClients,
  resetClientProviderData,
} from "../../../../Controller/redux/features/ProviderFeature/providerClientsSlice";
import {
  getProviderClientDobThunk,
  getProviderClientEmailThunk,
  getProviderClientFundingThunk,
  getProviderClientNameThunk,
  resetClientPage,
  setClientPage,
} from "../../../../Controller/redux/features/ClientFeature/clientsSlice";

const ProviderClient = () => {
  const tableRef = useRef();
  const clientInnerRef = useRef();
  const dropdownRefProspects = useRef<any>();
  const svgParentRefProspects = useRef<any>();
  const dropdownRefClients = useRef<any>();
  const svgParentRefClients = useRef<any>();
  const [isDropdownOpenClients, setIsDropdownOpenClients] = useState(false);
  const { domainDetail } = useAppSelector((state) => state?.common);
  const navigate = useNavigate();

  const columns = [
    { header: "Name", accessor: "first_name" },
    { header: "Contact", accessor: "email" },
    { header: "DOB", accessor: "date_of_birth" },
    { header: "Funding Type", accessor: "funding_type" },
    { header: "Residence", accessor: "residence_name" },
    { header: "Status", accessor: "status" },
  ];

  const handleClickRow = (e: any, id: any) => {
    if (!isDropdownOpenClients) {
      navigate(
        `/providers/${window.btoa(providerId)}/clients/${window.btoa(id)}`
      );
    }
  };

  const handleClickOutsideProspects = (event: any) => {
    if (
      dropdownRefProspects.current &&
      !dropdownRefProspects.current.contains(event.target) &&
      svgParentRefProspects.current &&
      !svgParentRefProspects.current.contains(event.target)
    ) {
      setIsDropdownOpenClients(false);
    }
  };

  const id: any = useParams()?.id;
  const [providerId, setProviderId] = useState<any>("");
  useEffect(() => {
    if (id) {
      let realId = window?.atob(id);
      setProviderId(realId);
    }
  }, [id]);

  // @ts-ignore
  const dynamicClientId = parseInt(localStorage.getItem("dynamicClientId"));

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (providerId && dynamicClientId) {
      // dispatch(
      //   getProviderClients({
      //     dynamicProviderId: providerId,
      //     dynamicUserTypeId: dynamicClientId,
      //   })
      // );
      dispatch(
        getProviderClientNameThunk({
          dynamicProviderId: providerId,
          dynamicUserTypeId: dynamicClientId,
        })
      );
      dispatch(
        getProviderClientEmailThunk({
          dynamicProviderId: providerId,
          dynamicUserTypeId: dynamicClientId,
        })
      );
      dispatch(
        getProviderClientDobThunk({
          dynamicProviderId: providerId,
          dynamicUserTypeId: dynamicClientId,
        })
      );
      dispatch(
        getProviderClientFundingThunk({
          dynamicProviderId: providerId,
          dynamicUserTypeId: dynamicClientId,
        })
      );
    }
  }, [providerId, dynamicClientId]);

  const clientStatusDetailsArr = useAppSelector(
    (state) => state.common.statusDetailsData?.data
  );

  const { clientsRecords, loading, lastPageProviderClient } = useAppSelector(
    (state) => state?.providerClient
  );
  const [providerClientsRecords, setProviderClientsRecords] = useState([]);

  useEffect(() => {
    if (clientsRecords) {
      setProviderClientsRecords(clientsRecords);
    }
  }, [clientsRecords]);

  useEffect(() => {
    const handleClickOutsideClients = (event: any) => {
      if (
        dropdownRefClients.current &&
        !dropdownRefClients.current.contains(event.target) &&
        svgParentRefClients.current &&
        !svgParentRefClients.current.contains(event.target)
      ) {
        setIsDropdownOpenClients(false);
      }
    };
    document.addEventListener("click", handleClickOutsideClients);
    return () => {
      document.removeEventListener("click", handleClickOutsideClients);
    };
  }, []);

  const { ClientFilterItems, ClientPage } = useAppSelector(
    (state) => state.people
  );

  const { filterIconColor } = useAppSelector((state) => state?.common);
  const { selectedFilterItems, sortbyClients, searchValueClients } =
    useAppSelector((state) => state?.people);
  useEffect(() => {
    dispatch(resetClientProviderData());
    dispatch(resetClientPage());
  }, [
    ClientFilterItems?.searchVal,
    ClientFilterItems?.filterValue,
    ClientFilterItems?.sortType,
    ClientFilterItems?.status,
  ]);
  const onScroll = () => {
    if (clientInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = clientInnerRef.current;
      if (
        scrollTop + clientHeight + +5 >= scrollHeight &&
        ClientPage < lastPageProviderClient
      ) {
        dispatch(setClientPage(ClientPage));
      }
    }
  };

  useEffect(() => {
    dispatch(
      getProviderClientFilterThunk({
        dynamicProviderId: providerId,
        dynamicUserTypeId: dynamicClientId,
        filterColumn: ClientFilterItems?.filterColumn
          ? ClientFilterItems?.filterColumn
          : "",
        filterValue: ClientFilterItems?.filterValue
          ? ClientFilterItems?.filterValue
          : "",
        searchVal: ClientFilterItems?.searchVal
          ? ClientFilterItems?.searchVal
          : "",
        sortColumn: ClientFilterItems?.sortColumn
          ? ClientFilterItems?.sortColumn
          : "",
        sortType: ClientFilterItems?.sortType
          ? ClientFilterItems?.sortType
          : "",
        status: ClientFilterItems.status ? ClientFilterItems.status : "",
        ClientPage,
      })
    );
  }, [
    providerId,
    dynamicClientId,
    ClientPage,
    ClientFilterItems?.searchVal,
    ClientFilterItems?.filterValue,
    ClientFilterItems?.sortType,
    ClientFilterItems?.status,
  ]);

  return (
    <div className="provider_prospect_wrapper">
      <Table
        isLoading={loading}
        tableRef={tableRef}
        tableBodyRef={clientInnerRef}
        onScroll={onScroll}
        dynamicHeaderColor={domainDetail?.listingtbl_color}
        statusType="providerClientScreen"
        thData={columns}
        itemsArr={clientStatusDetailsArr}
        classNameTr={"all-client-table-head"}
        type="providerClients"
        dropdownRef={dropdownRefClients}
        isDropdownOpen={isDropdownOpenClients}
        onClickSvg={() => setIsDropdownOpenClients(true)}
        svgParentRef={svgParentRefClients}
        allClients={providerClientsRecords}
        onClickRow={handleClickRow}
        classNameRow={"all-client-table-body"}
        fill={
          selectedFilterItems?.length > 0 ||
          searchValueClients?.length > 0 ||
          sortbyClients?.length > 0
            ? filterIconColor
            : "#D8D3D3"
        }
      />
    </div>
  );
};

export default ProviderClient;
