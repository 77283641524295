import React from "react";
import "../../../../../Styles/ClientAssessmentNote.css";
import ReactDatePicker from "react-datepicker";
import { GreenCircleSvg } from "../../ClinicalTab/Data/SVGs";
import { useContext, useEffect, useState } from "react";
import { CompletionSvg, PDFSvg, SheetSvg, WordSvg } from "../Data/SVGs";
import { Modal, ModalOverlay } from "@chakra-ui/react";
import BudgetModal from "../../FinanceTab/BudgetModal";
import StatementModal from "../../FinanceTab/Components/StatementModal";
import TrafficLights from "../../../../../../../../components/CommonModal/Components/TrafficLights";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../Controller/redux/Hooks";
import {
  formatDate,
  formatDateFromAPIforCustom,
  formatDateWithoutTime,
  formatPrimaryReview1,
} from "../../../../../../../../../Model/utils/Helper/formatDate";
import { getDateForIntervals } from "../../../../../../../../../Model/utils/Helper/intervalManupulater";
import CustomDatePicker from "../../../../../../../../components/DatePicker/CustomDatePicker";
import { api } from "../../../../../../../../../Controller/services/api";
import {
  getDocumentsForClinicalThunk,
  updateDocumentThunk,
} from "../../../../../../../../../Controller/redux/features/CommonFeature/documentSlice";
import { toast } from "react-toastify";
import {TriangleSection} from "../../../../../../../../components/CommonModal/Components/TriangleCircleSection";
// import {TriangleSection, TriangleSectionSm} from "../../../../../../../../components/CommonModal/Components/ThreadsBox";

const ClientAssessmentNote = ({
  type,
  onClick,
  fundingType,
  lastUpdate,
  startingBalance,
  incomingFunds,
  expenses,
  closingBalance,
  fundingTotal,
  expensesTotal,
  balanceTotal,
  clinicalNote,
  clinicalNoteTitle = "",
  dueDate,
  percentage = 0,
  last_updated,
  ongoing_review,
  files = [],
  dataObject,
  clientId,
  providerId,
  elem,
  res,
}: any) => {
  // USER OBJECT FROM CONTEXT
  // let userFullName = `${user?.first_name} ${user.last_name}`;

  const dispatch = useAppDispatch();

  const dynamicColor = useAppSelector((state) => state?.common?.dynamicColor);

  const [showLights, setShowLights] = useState<boolean>(false);
  const [trafficColors, setTrafficColors] = useState<any>(
    dataObject?.completion_status === 0
      ? "#ef3a34"
      : dataObject?.completion_status === 1
      ? "#fbbf14"
      : "#2AD400"
  );
  const [dates, setDates] = useState({
    dueDate: "",
    reviewDate: "",
  });
  const { domainDetail } = useAppSelector((state) => state.common.domainDetail);

  // console.warn(dataObject,"dataObject");
  ///////////////     INTERVALS DATE ///////////////
  let { primaryDate, ongoingDate } = useAppSelector((state) => state?.people);

  let ongoingReviewDate = getDateForIntervals(ongoingDate?.date);

  const currentDate = new Date().toISOString().slice(0, 10);
  const [openForm, setopenForm] = useState(false);

  const handleCloseMealFormModal = () => {
    // setopenForm(false);
  };
  const handleOverlayClickOnBudgetModel = async () => {
    // setopenForm(false);
  };

  const onClickOnBugetSection = (e: any) => {
    if (e && e.target && typeof e?.target?.className === "string") {
      const classNames = e.target.className;

      if (
        !classNames.includes(
          "react__datepicker___note--review react-datepicker-ignore-onclickoutside"
        ) &&
        !classNames.includes(
          "react__datepicker___note--due react-datepicker-ignore-onclickoutside"
        ) &&
        !classNames.includes("react-datepicker__day react-datepicker__day")
      ) {
        setopenForm(true);
      }
    }
  };

  // /////////////////////////////////////////////////////////////// Statement  /////////////////////////////////////////////////////

  const [openFormStatement, setopenFormStatement] = useState(false);

  const handleCloseStatementFormModal = () => {
    setopenFormStatement(false);
  };

  const docs = useAppSelector((state) => state.document.getDocuments);
  let result_n = docs?.find(
    (el: any) => el?.title === "Clinical Care Plan And Assessment"
  );
  if (clinicalNote && result_n) {
    ongoingDate = {};
    primaryDate = result_n?.primary_review;
    ongoingDate.date = result_n?.ongoing_review;
    ongoingDate.interval = result_n?.period_picker;
    ongoingReviewDate = getDateForIntervals(ongoingDate?.date);
  }

  //  get file from s3 bucket
  const handleGetFile = async (name: string | any) => {
    try {
      const response = await api.get(`files/get_file?file=${name}`);
      const fileUrl = response?.data?.data;
      window.open(fileUrl, "_blank");
    } catch (error) {
      console.error("Error fetching file:", error);
    }
  };

  let selectedDate = dataObject?.ongoing_review
    ? new Date(dataObject?.ongoing_review)
    : null;
  let selectedInterval = dataObject?.period_picker
    ? dataObject?.period_picker
    : "weekly";

  const [dateWithPeriodPicker, setdateWithPeriodPicker] = useState<any>({
    startDate: null,
    endDate: null,
    sMonth: "",
    sYear: "",
    eMonth: "",
    eYear: "",
  });

  useEffect(() => {
    setTrafficColors(
      dataObject?.completion_status === 0
        ? "#ef3a34"
        : dataObject?.completion_status === 1
        ? "#fbbf14"
        : "#2AD400"
    );
  }, [dataObject]);

  return (
    <>
      {type == "statement" ? (
        <div className="small_documents_note_finanse">
          <div className="small_finanse_note_wrapper">
            <div className="small_id_note_title-title">Statement</div>
            <div className="hash_code_icon_wrapper_finanse">
              {/* <span>#9574854</span> */}
              <GreenCircleSvg />
            </div>
          </div>
          <div className="small_finanse_type_attachment_wraper">
            <div className="category_label_finanse">{fundingType}</div>
            <div className="attachment_wrapper">
              <PDFSvg />
              <SheetSvg />
              <WordSvg />
            </div>
          </div>
          <div className="dates_wrapper_finanse">
            <div className="to__date">
              Dates:
              <span
                className="date__fixed"
                style={{
                  color: "#9296a2",
                  width: "100px",
                  fontSize: "10px",
                }}
              >
                01-07-2023
              </span>
            </div>
            <div className="review__date">
              to:
              <CustomDatePicker
                newDate={dates.reviewDate}
                handleChange={(date: any) => {
                  setDates((prevData: any) => ({
                    ...prevData,
                    reviewDate: date,
                  }));
                }}
              />
            </div>
          </div>
          <div className={`signed_wrapper_finanse `}>
            <div className="signed_text_finanse">Starting Balance </div>
            <div className="completion_icon_wrapper_finanse">
              <div className="percentage__completed">$ {startingBalance}</div>
            </div>
          </div>

          <div className={`signed_wrapper_finanse `}>
            <div className="signed_text_finanse">Incoming Funds</div>
            <div className="completion_icon_wrapper_finanse">
              <div className="percentage__completed">$ {incomingFunds}</div>
            </div>
          </div>

          <div className={`signed_wrapper_finanse `}>
            <div className="signed_text_finanse">Expenses</div>
            <div className="completion_icon_wrapper_finanse">
              <div className="percentage__completed">$ {expenses}</div>
            </div>
          </div>

          <div className={`signed_wrapper_finanse `}>
            <div className="signed_text_finanse">Closing Balance</div>
            <div className="completion_icon_wrapper_finanse">
              <div className="percentage__completed">$ {closingBalance}</div>
            </div>
          </div>

          <div className={`signed_wrapper_finanse  `}>
            <div className="signed_text_finanse">
              Last Update <span>{lastUpdate}</span>
            </div>
          </div>
        </div>
      ) : type == "budget" ? (
        <div
          className="small_documents_note_finanse"
          onClick={(e) => {
            onClickOnBugetSection(e);
          }}
        >
          <div className="small_finanse_note_wrapper">
            <div className="small_id_note_title-title">Budget</div>
            <div className="hash_code_icon_wrapper_finanse">
              {/* <span>#9574854</span> */}
              <GreenCircleSvg />
            </div>
          </div>
          <div className="small_finanse_type_attachment_wraper">
            <div className="category_label_finanse">{fundingType}</div>
            <div className="attachment_wrapper">
              <PDFSvg />
              <SheetSvg />
              <WordSvg />
            </div>
          </div>
          <div className="dates_wrapper_finanse">
            <div className="to__date">
              Dates:
              <span
                style={{
                  color: "#9296a2",
                  width: "100px",
                  fontSize: "10px",
                }}
              >
                01-07-2023
              </span>
            </div>
            <div className="review__date">
              to:
              <span
                style={{
                  color: "#9296a2",
                  width: "100px",
                  fontSize: "10px",
                }}
              >
                30-06-2024
              </span>
            </div>
          </div>

          <div className={`signed_wrapper_finanse `}>
            <div className="signed_text_finanse">Funding Total </div>
            <div className="completion_icon_wrapper_finanse">
              <div className="percentage__completed">$ {fundingTotal}</div>
            </div>
          </div>

          <div className={`signed_wrapper_finanse `}>
            <div className="signed_text_finanse">Expenses Total </div>
            <div className="completion_icon_wrapper_finanse">
              <div className="percentage__completed">$ {expensesTotal}</div>
            </div>
          </div>

          <div className={`signed_wrapper_finanse `}>
            <div className="signed_text_finanse">Balance Total </div>
            <div className="completion_icon_wrapper_finanse">
              <div className="percentage__completed">$ {balanceTotal}</div>
            </div>
          </div>

          <div className={`signed_wrapper_finanse  `}>
            <div
              className="signed_text_finanse"
              style={{
                marginTop: "20px",
              }}
            >
              Last Update <span>{lastUpdate}</span>{" "}
            </div>
          </div>
        </div>
      ) : (
        <div className="small__documents__note">
          <div onClick={onClick} className="small__documents__note-title">
            {clinicalNoteTitle || "Clinical Care Plan and Assessment"}
          </div>
          <div className="hash__code__icon__wrapper_small">
            {/* <span>#9574854</span> */}
            <span>
              <TriangleSection
              completionStatus={res?.completion_status}
              isDocument={true}

            onChangeTrafficLight={
              (data: any) => {
                const updateTrafficLightPayload = {
                  completion_status:
                    data === "#ef3a34" ? 0 : data === "#fbbf14" ? 1 : 2,
                  custom_form_id: dataObject?.custom_form_id,
                  document_id: dataObject?.id,
                  title: clinicalNoteTitle,
                  user_id: dataObject?.user_entity_id,
                  primary_approver_id: [
                    ...dataObject?.primary_approvers?.map((o: any) => o?.id),
                  ],
                  ongoing_approver_id: [
                    ...dataObject?.ongoing_approvers?.map((o: any) => o?.id),
                  ],
                  note_details: dataObject?.note_details || {},
                };
                setShowLights(false);
                dispatch(updateDocumentThunk(updateTrafficLightPayload)).then(
                  (res: any) => {
                    dispatch(
                      getDocumentsForClinicalThunk({
                        clientId,
                        providerId,
                      })
                    );
                    if (res?.payload?.status === "success") {
                      setTrafficColors(data);
                      toast.success(`Traffic Light updated successfully!`, {
                        // style: {},
                        progressStyle: { backgroundColor: dynamicColor },
                        icon: (
                          <span
                            style={{
                              marginRight: "8px",
                              borderRadius: "50%",
                              width: "7rem",
                              height: "35px",
                              textAlign: "center",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              color: dynamicColor,
                              backgroundColor: "white",
                              fontSize: "1.5rem",
                              border: `3px solid ${dynamicColor}`,
                            }}
                          >
                            ✓
                          </span>
                        ),
                      });
                    } else {
                      toast.error(res?.payload);
                    }
                  }
                );
              }
            }
              />
            </span>

            {showLights && (
              <TrafficLights
                className="client_traffic_light"
                isSmall={false}
                setShowLights={setShowLights}
              />
            )}
          </div>
          {/* <div className="category__label">Category</div> */}
          <div className="dates__wrapper">
            <div className="due__date">
              Due:
              <span className="client_assessment_date">
                {/* { ((primaryDate === "" || primaryDate === null) &&
                  "dd-mm-yyyy") ||
                  DateFormat(primaryDate)} */}
                {res?.ongoing_review == null
                  ? formatPrimaryReview1(res?.primary_review)
                  : formatPrimaryReview1(res?.ongoing_review)}
              </span>
            </div>
            <div
              className="review__date"
              style={{
                marginRight: "-30px",
              }}
            >
              Review:
              <span className="client_assessment_date">
                {res?.period_picker || ""}
              </span>
            </div>
          </div>

          {[...Array(2)].map((elem, index) => {
            return (
              <>
                <div
                  key={index}
                  className={`signed__wrapper 
              
              ${index === 1 ? "add__document__wrapper" : ""}
              `}
                >
                  <div className="signed__text">
                    {index === 0 ? "Signed" : "Attachments:"}
                  </div>
                  <div className="completion__icon__wrapper">
                    {index === 0 ? (
                      <>
                        <div className="percentage__completed">
                          {res?.hard_copy_completed_date}{" "}
                        </div>

                        <CompletionSvg />
                      </>
                    ) : (
                      <>
                        {files &&
                          files.length > 0 &&
                          files
                            ?.slice(0, 3)
                            ?.map((elem: any, index: number) => {
                              if (elem?.file_name?.includes("pdf")) {
                                return (
                                  <PDFSvg
                                    key={index}
                                    onClick={() => {
                                      handleGetFile(elem.file_name);
                                    }}
                                  />
                                );
                              } else if (elem?.file_name?.includes("docx")) {
                                return (
                                  <WordSvg
                                    key={index}
                                    onClick={() => {
                                      handleGetFile(elem.file_name);
                                    }}
                                  />
                                );
                              } else {
                                return (
                                  <SheetSvg
                                    key={index}
                                    onClick={() => {
                                      handleGetFile(elem.file_name);
                                    }}
                                  />
                                );
                              }
                            })}
                      </>
                    )}
                  </div>
                </div>
              </>
            );
          })}

          <div className="last__date">
            Last Update :
            <span className="last__update__date">
              {" "}
              {formatDate(last_updated)}
            </span>
          </div>
        </div>
      )}

      {openForm && (
        <Modal
          isOpen={true}
          onClose={handleCloseMealFormModal}
          closeOnOverlayClick={true}
          isCentered
          size={"7xl"}
        >
          <ModalOverlay onClick={handleOverlayClickOnBudgetModel} zIndex={10} />
          <div>
            <BudgetModal setopenForm={setopenForm} />
          </div>
        </Modal>
      )}

      {openFormStatement && (
        <Modal
          isOpen={true}
          onClose={handleCloseStatementFormModal}
          closeOnOverlayClick={true}
          isCentered
          size={"md"}
        >
          <ModalOverlay onClick={handleCloseStatementFormModal} zIndex={10} />
          <div>
            <StatementModal />
          </div>
        </Modal>
      )}
    </>
  );
};
export default ClientAssessmentNote;
