import React from "react";

const NotificationIconSVG = ({
  fill = "#2ad400",
  count = 0,
  onClick,
  onMouseEnter,
  onMouseLeave,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="23"
      viewBox="0 0 26 23"
      fill="none"
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      cursor="pointer"
    >
      <path
        d="M9.5359 1.99999C11.0755 -0.666672 14.9245 -0.666666 16.4641 2L25.1244 17C26.664 19.6667 24.7395 23 21.6603 23H4.33974C1.26054 23 -0.663956 19.6667 0.875645 17L9.5359 1.99999Z"
        fill={fill}
      />
      <text
        x="50%"
        y="20%"
        font-size="10"
        fill="white"
        text-anchor="middle"
        dominant-baseline="central"
        fontWeight={500}
        letterSpacing={0.25}
      >
        {count > 99 ? "+" : ""}
      </text>
      <text
        x="50%"
        y="60%"
        font-size="12"
        fill="white"
        text-anchor="middle"
        dominant-baseline="central"
        fontWeight={500}
        letterSpacing={0.25}
      >
        {count > 99 ? 99 : count}
      </text>
    </svg>
  );
};

export default NotificationIconSVG;
