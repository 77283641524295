import { useState, useEffect } from "react";

const useDimensions = (ref: any) => {
  const [width, setWidth] = useState(ref?.current?.clientWidth);
  const [height, setHeight] = useState(ref?.current?.clientHeight);

  useEffect(() => {
    const getDimensions = () => {
      if (ref?.current) {
        const { width, height } = ref.current?.getBoundingClientRect();
        setWidth(width);
        setHeight(height);
      }
    };

    getDimensions();

    window.addEventListener("resize", getDimensions);

    return () => {
      window.removeEventListener("resize", getDimensions);
    };
  }, []);

  return { width, height };
};

export default useDimensions;
