import {Progress} from "@chakra-ui/react";

export const ProgressCompletedSection = ({ label, progressValue }: any) => {
    return (
      <div className="progress_section">
        <div className="label_segment">
          <div className="items_container_label">{label}</div>
        </div>
        <Progress
          width={"75%"}
          borderRadius={"3px"}
          color={"#2AD400"}
          size="sm"
          value={progressValue}
        />
      </div>
    );
  };
  