import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../../services/api";

const initialState: any = {
  contactName: [],
  contactEmail: [],
  contactPhone: [],
  ContactPage: 1,
  ContactFilterItems: {
    status: "1",
    filterColumn: "",
    filterValue: "",
    searchVal: "",
    sortColumn: "",
    sortType: "",
    ContactPage: 1,
  },
  getContactsData: [],
  contacts: [],
  lastPage: 10000,
  getUniqueContact: [],
  updateContact: null,
  getUserTypeIdForContact: null,
  loading: false,
  error: null,
  getAllContactsData: [],
  selectedFilterOptionsContact: [],
  selectedSortOptionsContact: [],
  contactRoles: [],
  // STATES OF SELECTED FILTER GOES HERE
  searchValContacts: "",
  selectedDropdownItemContact: "",
  selectedItemIndexFilterContact: "",
  selectedItemIndexSortContact: "",
  sortByContacts: "",
  selectedFilterItemsContact: [],
};

export const getContactsThunk = createAsyncThunk(
  "contacts/fetchContacts",
  async (dynamicId: number | any) => {
    try {
      const response = await api.get(
        `/users?paginate=true&status=1,&type=${dynamicId.dynamicContactId}&sort_column=id&sort_type=desc&page=1&limit=10000`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getContactsByFilterThunk = createAsyncThunk(
  "contacts/fetchContactsByFilter",
  async ({
    dynamicId,
    filterColumn,
    filterValue,
    searchVal,
    sortColumn,
    sortType,
    status,
    ContactPage,
  }: {
    dynamicId: object | any;
    filterColumn: string;
    filterValue: string;
    searchVal: string;
    sortColumn: string;
    sortType: string | any;
    status: string | any;
    ContactPage: string | any;
  }) => {
    let dynamicContactId = dynamicId;
    try {
      const encodedFilterVal = encodeURIComponent(filterValue);
      const encodedSearchVal = encodeURIComponent(searchVal);

      const response = await api.get(
        `/users?paginate=true&page=${ContactPage}&sort_column=${sortColumn}&sort_type=${sortType}&status=${status}&filter_column=${filterColumn}&type=${dynamicContactId}&limit=50&filter_value=${encodedFilterVal}&search=${encodedSearchVal}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getContactsSelectAllThunk = createAsyncThunk(
  "contacts/fetchContactsSelectAll",
  async ({ dynamicId, status }: any) => {
    try {
      const response = await api.get(
        `/users?paginate=true&status=${status}&type=${dynamicId}&sort_column=id&sort_type=desc&page=1&limit=200`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
export const updateContactThunk = createAsyncThunk(
  "contacts/updateContact",
  async (contactData: any, { rejectWithValue }) => {
    try {
      const response = await api.post("/users/update_user", contactData);
      return response.data;
    } catch (error: string | any) {
      return rejectWithValue(
        error.message || "Failed to upload client details"
      );
    }
  }
);

export const getContactByIdThunk = createAsyncThunk(
  "contacts/fetchContactById",
  async ({ typeId, clientId }: any) => {
    try {
      const response = await api.get(
        `/users?paginate=true&type=${typeId}&sort_column=id&sort_type=desc&id=${clientId}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getProviderContactByIdThunk = createAsyncThunk(
 "providerContacts/fetchProviderContactsById",
 async ({ typeId, clientId, tenant_id }: any) => {
    try {
      const response = await api.get(
        `/providers/get_provider_users?paginate=true&type=${typeId}&sort_column=id&sort_type=desc&id=${clientId}&tenant_id=${tenant_id}`
      );
      // console.log('response form provider contact: ', response?.data?.data)
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
)

export const getUserRolesContactsThunk = createAsyncThunk(
  "contacts/fetchUserRoles",
  async ({ dynamicContactId }: any) => {
    try {
      const response = await api.get(
        `/users/get_user_roles?type=${dynamicContactId}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
export const getContactNameThunk = createAsyncThunk(
  "contact/fetchName",
  async (dynamicContactId: number | any) => {
    try {
      const { data } = await api.get(
        `/users?unique_column=name&type=${dynamicContactId}`
      );
      return data?.data?.data;
    } catch (error) {
      throw error;
    }
  }
);
export const getProviderContactNameThunk = createAsyncThunk(
  "providerContact/fetchName",
  async ({ dynamicUserTypeId, dynamicProviderId }: any) => {
    try {
      const { data } = await api.get(
        `/providers/get_provider_users?unique_column=name&type=${dynamicUserTypeId}&tenant_id=${dynamicProviderId}`
      );
      return data?.data?.data;
    } catch (error) {
      throw error;
    }
  }
);
export const getContactEmailThunk = createAsyncThunk(
  "contact/fetchEmail",
  async (dynamicContactId: number | any) => {
    try {
      const { data } = await api.get(
        `/users?unique_column=email&type=${dynamicContactId}`
      );
      return data?.data?.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getProviderContactEmailThunk = createAsyncThunk(
  "providerContact/fetchEmail",
  async ({ dynamicUserTypeId, dynamicProviderId }: any) => {
    try {
      const { data } = await api.get(
        `/providers/get_provider_users?unique_column=email&type=${dynamicUserTypeId}&tenant_id=${dynamicProviderId}`
      );
      return data?.data?.data;
    } catch (error) {
      throw error;
    }
  }
);
export const getContactPhoneThunk = createAsyncThunk(
  "contact/fetchPhone",
  async (dynamicContactId: number | any) => {
    try {
      const { data } = await api.get(
        `/users?unique_column=phone&type=${dynamicContactId}`
      );
      return data?.data?.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getProviderContactPhoneThunk = createAsyncThunk(
  "providerContact/fetchPhone",
  async ({ dynamicUserTypeId, dynamicProviderId }: any) => {
    try {
      const { data } = await api.get(
        `/providers/get_provider_users?unique_column=email&type=${dynamicUserTypeId}&tenant_id=${dynamicProviderId}`
      );
      return data?.data?.data;
    } catch (error) {
      throw error;
    }
  }
);
const contactsSlice: any = createSlice({
  name: "contacts",
  initialState,
  reducers: {
    // Pagination Start
    setContactPage: (state) => {
      state.ContactPage += 1;
    },
    resetContactPage: (state) => {
      state.ContactPage = 1;
    },
    resetContactData: (state) => {
      state.getContactsData = [];
      state.contacts = [];
    },
    setContactFilterItems: (state, action) => {
      state.ContactFilterItems = action.payload;
    },
    resetContactFilterItems: (state) => {
      state.ContactFilterItems = {
        status: "1",
        filterColumn: "",
        filterValue: "",
        searchVal: "",
        sortColumn: "",
        sortType: "",
        ContactPage: 1,
      };
    },
    // Pagination End
    setUserTypeIdForContact: (state, action) => {
      state.getUserTypeIdForContact = action.payload;
    },
    setsearchValueContacts: (state, action) => {
      state.searchValContacts = action.payload;
    },
    resetsearchValueContacts: (state, action) => {
      state.searchValContacts = "";
    },
    setFilterOptionsContact: (state, action) => {
      state.selectedFilterOptionsContact = action.payload;
    },
    resetFilterOptionsContact: (state) => {
      state.selectedFilterOptionsContact = [];
    },
    setSortOptionsContact: (state, action) => {
      state.selectedSortOptionsContact = action.payload;
    },
    resetSortOptionsContact: (state) => {
      state.selectedSortOptionsContact = [];
    },
    // Filter Dropdown
    setSelectedDropdownItemContact: (state, action) => {
      state.selectedDropdownItemContact = action.payload;
    },
    resetSelectedDropdownItemContact: (state) => {
      state.selectedDropdownItemContact = "";
    },
    setSelectedItemIndexFilterContact: (state, action) => {
      state.selectedItemIndexFilterContact = action.payload;
    },
    resetSelectedItemIndexFilterContact: (state) => {
      state.selectedItemIndexFilterContact = "";
    },
    setSelectedItemIndexSortContact: (state, action) => {
      state.selectedItemIndexSortContact = action.payload;
    },
    resetSelectedItemIndexSortContact: (state) => {
      state.selectedItemIndexSortContact = "";
    },
    setSortByContacts: (state, action) => {
      state.sortByContacts = action.payload;
    },
    resetSortByContacts: (state) => {
      state.sortByContacts = "";
    },
    setSelectedFilterItemsContact: (state, action) => {
      state.selectedFilterItemsContact = action.payload;
    },
    resetSelectedFilterItemsContact: (state) => {
      state.selectedFilterItemsContact = "";
    },
    clearCurrentContactData: (state) => {
      state.getUniqueContact = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getContactsThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getContactsThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.getContactsData = action.payload;
        state.getAllContactsData = action.payload;
      })
      .addCase(getContactsThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getUserRolesContactsThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUserRolesContactsThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.contactRoles = action.payload;
      })
      .addCase(getUserRolesContactsThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      // getUserRolesContactsThunk
      .addCase(getContactsByFilterThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getContactsByFilterThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.getContactsData = action.payload;
        state.lastPage = action.payload.data.last_page;
        state.contacts = [ ...action.payload.data.data];
      })
      .addCase(getContactsByFilterThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getContactsSelectAllThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getContactsSelectAllThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.getContactsData = action.payload;
        state.contacts = [ ...action.payload.data.data];
      })
      .addCase(getContactsSelectAllThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getContactByIdThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getContactByIdThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.getUniqueContact = action.payload;
      })
      .addCase(getContactByIdThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      // .addCase(updateContactThunk.pending, (state) => {
      //   state.loading = true;
      //   state.error = null;
      // })
    // .addCase(updateContactThunk.fulfilled, (state, action) => {
    //   state.loading = false;
    //   state.updateContact = action.payload;
    // })
    // .addCase(updateContactThunk.rejected, (state, action) => {
    //     state.loading = false;
    //     state.error = action.payload
    //       ? action.payload
    //       : "Failed to upload client details";
    //   })
      .addCase(getContactNameThunk.pending, (state) => {
        state.loading === true;
      })
      .addCase(getContactNameThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.contactName = action.payload;
      })
      .addCase(getContactNameThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(getProviderContactNameThunk.pending, (state) => {
        state.loading === true;
      })
      .addCase(getProviderContactNameThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.contactName = action.payload;
      })
      .addCase(getProviderContactNameThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getContactEmailThunk.pending, (state) => {
        state.loading === true;
      })
      .addCase(getContactEmailThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.contactEmail = action.payload;
      })
      .addCase(getContactEmailThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getProviderContactEmailThunk.pending, (state) => {
        state.loading === true;
      })
      .addCase(getProviderContactEmailThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.contactEmail = action.payload;
      })
      .addCase(getProviderContactEmailThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getContactPhoneThunk.pending, (state) => {
        state.loading === true;
      })
      .addCase(getContactPhoneThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.contactPhone = action.payload;
      })
      .addCase(getContactPhoneThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getProviderContactPhoneThunk.pending, (state) => {
        state.loading === true;
      })
      .addCase(getProviderContactPhoneThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.contactPhone = action.payload;
      })
      .addCase(getProviderContactPhoneThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      //Provider Unique Contact
      .addCase(getProviderContactByIdThunk.pending,(state)=>{
        state.loading=true
      })
      .addCase(getProviderContactByIdThunk.fulfilled,(state,action)=>{
        state.loading = false;
     state.getUniqueContact = action.payload;
      })
      .addCase(getProviderContactByIdThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
  },
});

export const {
  setUserTypeIdForContact,
  setsearchValueContacts,
  resetsearchValueContacts,
  setFilterOptionsContact,
  resetFilterOptionsContact,
  setSortOptionsContact,
  resetSortOptionsContact,
  setContactRole,
  setSelectedDropdownItemContact, //Filter
  resetSelectedDropdownItemContact,
  setSelectedItemIndexFilterContact,
  resetSelectedItemIndexFilterContact,
  setSelectedItemIndexSortContact,
  resetSelectedItemIndexSortContact,
  setSortByContacts,
  resetSortByContacts,
  setSelectedFilterItemsContact,
  resetSelectedFilterItemsContact,
  clearCurrentContactData,
  // Page
  setContactPage,
  resetContactPage,
  resetContactData,
  setContactFilterItems,
  resetContactFilterItems,
} = contactsSlice.actions;
export default contactsSlice.reducer;
