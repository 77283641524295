import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { api } from "../../../services/api";

const initialState: any = {
  loading: false,
  error: null,
  currentEditReportsHub: {},
  reportsHubList: [],
  getUserTypeIdForReportsHub: null,
  currentReportsHub: {},
  softwareAccessList: [],
  allowedReportsHubIds: "",
  getUserRoleReportsHub: [],
  getHubReports: [],
};

export const getReportsHub = createAsyncThunk(
  "reportsHubs/get_reportsHubs",
  async ({ type }: any, { rejectWithValue }) => {
    try {
      const { data } = await api.get(
        `/hub_reports?paginate=true&limit=1000000&type=${type}`
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data?.response);
    }
  }
);

export const getReportById = createAsyncThunk(
  "reportsHubs/get_reportsHub",
  async ({ type, id, tenant_id }: any, { rejectWithValue }) => {
    try {
      const { data } = await api.get(
        `/hub_reports?paginate=true&type=${type}&id=${id}`
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data?.response);
    }
  }
);

export const createReportsHub = createAsyncThunk(
  "reportsHubs/create_reportsHub",
  async (reportsHubData: any, { rejectWithValue }) => {
    try {
      const { data } = await api.post(
        `/hub_reports/create_report`,
        reportsHubData
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data?.response);
    }
  }
);

export const updateReportsHub = createAsyncThunk(
  "reportsHubs/update_reportsHub",
  async (reportsHubData: any, { rejectWithValue }) => {
    try {
      const { data } = await api.post(
        `/hub_reports/update_report`,
        reportsHubData
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data?.response);
    }
  }
);

export const getReportSoftwareAccess = createAsyncThunk(
  "reportsHubs/view_software_access",
  async ({ type, id, parentId }: any, { rejectWithValue }) => {
    try {
      const { data } = await api.get(
        `/hub_reports/view_report_access?type=${type}&tenant_id=${id}&api_tenant_parent_id=${parentId}`
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data?.response);
    }
  }
);

export const addReportSoftwareAccess = createAsyncThunk(
  "reportsHubs/reportsHubs_software_access",
  async ({ id, reports }: any, { rejectWithValue }) => {
    try {
      const { data } = await api.post(`/hub_reports/report_access`, {
        tenant_id: id,
        reports,
      });
      return data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data?.response);
    }
  }
);

export const getUserRolesReportsHub = createAsyncThunk(
  "reportsHubs/fetchUserRoles",
  async ({ id }: any, { rejectWithValue }) => {
    try {
      const response = await api.get(`/users/get_user_roles?type=${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.response);
    }
  }
);

export const getDashboardReports = createAsyncThunk(
  "reportsHubs/reportsHub_dashboard",
  async () => {
    try {
      const response = await api.get(`/hub_reports/get_dashboard_reports`);
      return response.data.data;
    } catch (error) {
      throw error;
    }
  }
);

const reportsHubSlice = createSlice({
  name: "reportsHubs",
  initialState,
  reducers: {
    resetCurrentEditReportsHub: (state) => {
      state.currentEditReportsHub = null;
    },
    setCurrentEditReportsHub: (state, action) => {
      state.currentEditReportsHub = action.payload;
    },
    setUserTypeIdForReportsHub: (state, action) => {
      state.getUserTypeIdForReportsHub = action.payload;
    },
    setAllowedReportsHubIds: (state, action) => {
      state.allowedReportsHubIds = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getReportsHub.pending, (state) => {
        state.loading === true;
      })
      .addCase(getReportsHub.fulfilled, (state, action) => {
        state.loading = false;
        state.reportsHubList = action.payload?.data?.data;
      })
      .addCase(getReportsHub.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(createReportsHub.pending, (state) => {
        state.loading === true;
      })
      .addCase(createReportsHub.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(createReportsHub.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updateReportsHub.pending, (state) => {
        state.loading === true;
      })
      .addCase(updateReportsHub.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(updateReportsHub.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getReportById.pending, (state) => {
        state.loading === true;
      })
      .addCase(getReportById.fulfilled, (state, action) => {
        state.loading = false;
        state.currentReportsHub = action.payload?.data?.data[0];
      })
      .addCase(getReportById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getReportSoftwareAccess.pending, (state) => {
        state.loading === true;
      })
      .addCase(getReportSoftwareAccess.fulfilled, (state, action) => {
        state.loading = false;
        const data = action.payload?.data;
        state.softwareAccessList = data;
        state.allowedReportsHubIds = data
          .flatMap((category) =>
            category.reports
              .filter((software) => software.has_access)
              .map((software) => software.id)
          )
          .join(",");
      })
      .addCase(getReportSoftwareAccess.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(addReportSoftwareAccess.pending, (state) => {
        state.loading === true;
      })
      .addCase(addReportSoftwareAccess.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(addReportSoftwareAccess.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getUserRolesReportsHub.pending, (state) => {
        state.loading === true;
      })
      .addCase(getUserRolesReportsHub.fulfilled, (state, action) => {
        state.loading = false;
        state.getUserRoleReportsHub = action.payload;
      })
      .addCase(getUserRolesReportsHub.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getDashboardReports.fulfilled, (state, action) => {
        state.getHubReports = action.payload;
        state.loading = false;
      });
  },
});

export const {
  resetCurrentEditReportsHub,
  setCurrentEditReportsHub,
  setUserTypeIdForReportsHub,
  setAllowedReportsHubIds,
} = reportsHubSlice.actions;

export default reportsHubSlice.reducer;
