import React from "react";

interface GreenColorSVGProps {
  width?: string;
  height?: string;
  onClick?: any;
  color?: string;
  className?: any;
}

const GreenColorSVG = ({
  width,
  height,
  onClick,
  color,
  className,
}: GreenColorSVGProps) => {
  return (
    <div className={className} onClick={onClick}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6 12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 0 6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12Z"
          fill={color}
        />
      </svg>
    </div>
  );
};

GreenColorSVG.defaultProps = {
  width: "12",
  height: "12",
  color: "#2AD400",
};

export default GreenColorSVG;
