import { useEffect, useRef, useState } from "react";
import "../Styles/TimePickerIntervals.css";
import { setTime } from "../../../../Controller/redux/features/CommonFeature/commonSlice";
import { useAppDispatch } from "../../../../Controller/redux/Hooks";
import { extractTimeComponents } from "../../../../Model/utils/Helper/extractTime";
import React from "react";
import {setselectedServiceType} from "../../../../Controller/redux/features/DaybookFeature/daybookSlice";

export const TimePickerIntervals = ({
  dateTime,
  setDateTime = () => {},
  ends,
  handleSetTime = () => {},
  time,
  showDateTimePickerModal
}: any) => {
  const dispatch = useAppDispatch();

  const [selectedHour, setSelectedHour] = useState("");
  const [selectedMinute, setSelectedMinute] = useState("");
  const [selectedFormatValue, setSelectedFormatValue] = useState("");
  const [showTimePickerModal, setShowTimePickerModal] = useState(false);
  const [hourInput, setHourInput] = useState("");
  const [minuteInput, setMinuteInput] = useState("");


  const minuteRef = useRef<any>();
  const hourRef = useRef();
  const formatRef = useRef();

  useEffect(() => {
    if (time) {
      let { hour, minute, format } = extractTimeComponents(time);
  
      hour = hour.toString().padStart(2, "0");
      minute = minute.toString().padStart(2, "0");
  
      setSelectedHour(hour);
      setSelectedMinute(minute);
      setSelectedFormatValue(format);
    }
  }, [time]);
  
  useEffect(() => {
    if (hourInput && !minuteInput) {
      // minuteRef.current.focus();
    }
  }, [hourInput, minuteInput]);

  useEffect(() => {
    if (selectedHour && selectedMinute && selectedFormatValue) {
      const formattedHour = selectedHour.toString().padStart(2, "0");
      const formattedMinute = selectedMinute.toString().padStart(2, "0");
      const formattedTime = `${formattedHour}:${formattedMinute} ${selectedFormatValue.toUpperCase()}`;

      dispatch(
        setTime(
          ends ? { endTime: formattedTime } : { startTime: formattedTime }
        )
      );
      handleSetTime(formattedTime);
    }
  }, [selectedHour, selectedMinute, selectedFormatValue]);

  const getIntervals = () => {
    const intervals = [];

    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 15) {
        const formattedTime = new Date().setHours(hour, minute);
        const displayTime = new Intl.DateTimeFormat("en-US", {
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        }).format(formattedTime);

        const isSelected = hour === selectedHour && minute === selectedMinute;
        if (
          hourInput === "" ||
          hourInput === hour % 12 ||
          hourInput === (hour % 12 === 0 ? 12 : hour % 12)
        ) {
          intervals.push(
            <div
              key={`${hour}-${minute}`}
              className={`interval ${isSelected ? "selected" : ""}`}
              onClick={() => {
                const time = new Date();
                time.setHours(hour);
                time.setMinutes(minute);
                const formattedHour = (time.getHours() % 12 || 12).toString().padStart(2, "0");
                const formattedMinute = time.getMinutes().toString().padStart(2, "0");
                const formattedFormat = time.getHours() < 12 ? "am" : "pm";
                const formattedTime = `${formattedHour}:${formattedMinute} ${formattedFormat}`;
                
                setSelectedHour(formattedHour);
                setSelectedMinute(formattedMinute);
                setSelectedFormatValue(formattedFormat);
                setShowTimePickerModal(false);
                
                handleSetTime(formattedTime);
              }}
            >
              {displayTime}
            </div>
          );
        }
      }
    }
    return intervals;
  };

  const intervals = getIntervals();

  const setCurrentTime = () => {
    const now = new Date();
    setSelectedHour(now.getHours() % 12 || 12);
    setSelectedMinute(now.getMinutes());
    setSelectedFormatValue(now.getHours() < 12 ? "am" : "pm");
  };

  const handleHourChange = (e) => {
    const value = e.target.value;
    const parsedValue = parseInt(value);

    if (parsedValue < 1 || parsedValue > 12 || value === "") {
      setSelectedHour("");
      setHourInput("");
    } else {
      setSelectedHour(parsedValue);
      setHourInput(parsedValue);

    }
  };

  const handleMinuteChange = (e) => {
    const value = e.target.value;
    if (value.length <= 2 && /^[0-5]?[0-9]?$/.test(value)) {
      setSelectedMinute(value);
      setMinuteInput(value);
    }
    if (value.length === 2) {
      // formatRef.current.focus();
    }
  };

  const handleFormatChange = (e) => {
    let value = e.target.value.toLowerCase();
    if (value === "a") {
      value = "am";
    } else if (value === "p") {
      value = "pm";
    }
    if (/^(am|pm)?$/.test(value)) {
      setSelectedFormatValue(value);
    }
  };

  return (
    <div className="threads_modal_time_picker">
      <b>
        <input
          type="number"
          ref={hourRef}
          value={selectedHour}
          onFocus={() => {
            if (!selectedHour) {
              setCurrentTime();
            }
            setShowTimePickerModal(true);
          }}
          onChange={handleHourChange}
          placeholder="--"
          className="TimePicker_Input time_picker_input_hour"
        />
        <label className="time_picker_label">:</label>
        <input
          type="number"
          ref={minuteRef}
          value={selectedMinute}
          onFocus={() => {
            setShowTimePickerModal(true);
          }}
          onChange={handleMinuteChange}
          placeholder="--"
          className="TimePicker_Input time_picker_input_minute"
        />
        <input
          type="text"
          ref={formatRef}
          value={selectedFormatValue?.toUpperCase()}
          onChange={handleFormatChange}
          onKeyDown={(e) => {
            if (e.key === "Backspace") {
              setSelectedFormatValue("");
            }
          }}
          placeholder="--"
          className="TimePicker_Input time_picker_input"
        />
      </b>

      {showTimePickerModal && <IntervalDropdown intervals={intervals} />}
    </div>
  );
};

export const IntervalDropdown = ({ intervals }: any) => {
  return (
    <>
      <div className="time_picker_intervals_1">
        <div className="intervals">{intervals}</div>
      </div>
    </>
  );
};

export const ServicesDropdown = ({
  services,
  onClick,
  setShowServiceDropdown,
}: any) => {
  const dispatch = useAppDispatch();
  return (
    <>
      <div className="time_picker_intervals_1 services_dropdown">
        <div className="intervals">
          {services &&
            services.map((service) => (
              <div key={service.name}>
                <div
                  onClick={() => {
                    dispatch(setselectedServiceType(service))
                    setShowServiceDropdown(false);
                    onClick(service);
                  }}
                  className="service_interval"
                >
                  {service.name}
                </div>
              </div>
            ))}
        </div>
      </div>
    </>
  );
};
