import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../../services/api";
import axios from "axios";

const initialState: any = {
  getAuditData: {},
  audits: [],
  getAuditUsernameData: [],
  getAuditActivityTypeData: [],
  getAuditTableNameData: [],
  selectedFilterOptionsAudit: [],
  selectedSortOptionsAudit: [],
  auditDataProviders: [],
  isAuditFilterDropdownOpen: false,
  AuditPageFilter: 1,
  AuditRowId: "",
  AuditFilterItems: {
    filterColumn: "",
    filterValue: "",
    searchVal: "",
    sortColumn: "",
    sortType: "",
    auditPageFilter: 1,
  },
  searchValuesAudit: "",
  selectedDropdownItemAudit: "",
  selectedItemIndexFilterAudit: "",
  selectedItemIndexSortAudit: "",
  sortByAudits: "",
  selectedFilterItemsAudit: [],
  trackUserClick: "",
  loading: false,
};

export const getAuditThunk = createAsyncThunk(
  "audit/fetchAudit",
  async (auditPage: number) => {
    try {
      const { data } = await api.get(
        `audit_logs?paginate=true&page=${auditPage}&limit=50`
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
);
export const getAuditUsernameThunk = createAsyncThunk(
  "audit/fetchUsername",
  async () => {
    try {
      const { data } = await api.get(`/audit_logs?unique_column=username`);
      return data;
    } catch (error) {
      throw error;
    }
  }
);
export const getAuditActivityTypeThunk = createAsyncThunk(
  "audit/fetchActivityType",
  async () => {
    try {
      const { data } = await api.get(`/audit_logs?unique_column=activity_type`);
      return data;
    } catch (error) {
      throw error;
    }
  }
);
export const getAuditTableNameThunk = createAsyncThunk(
  "audit/fetchTableName",
  async () => {
    try {
      const { data } = await api.get(`/audit_logs?unique_column=table_name`);
      return data;
    } catch (error) {
      throw error;
    }
  }
);

export const getAuditByFilterThunk = createAsyncThunk(
  "audit/fetchAuditByFilter",
  async ({
    filterColumn,
    filterValue,
    searchVal,
    sortColumn,
    sortType,
    auditPageFilter,
    isProviderAudit,
    token,
  }: {
    filterColumn: string | any;
    filterValue: string | any;
    searchVal: string | any;
    sortColumn: string | any;
    sortType: string | any;
    auditPageFilter: number;
    isProviderAudit: boolean;
    token?: string | number;
  }) => {
    const encodedFilterValue = encodeURIComponent(filterValue);

    try {
      let response;
      if (!isProviderAudit) {
        response = await api.get(
          `/audit_logs?paginate=true&page=${auditPageFilter}&limit=50&search=${searchVal}&sort_column=${sortColumn}&sort_type=${sortType}&filter_column=${filterColumn}&filter_value=${encodedFilterValue}`
        );
      }

      return response?.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getAuditByProviderFilterThunk = createAsyncThunk(
  "audit/fetchAuditByProviderFilter",
  async ({
    filterColumn,
    filterValue,
    searchVal,
    sortColumn,
    sortType,
    auditPageFilter,
    isProviderAudit,
    tenant_id,
  }: {
    filterColumn: string | any;
    filterValue: string | any;
    searchVal: string | any;
    sortColumn: string | any;
    sortType: string | any;
    auditPageFilter: number;
    isProviderAudit: boolean;
    tenant_id?: string | number;
  }) => {
    const encodedFilterValue = encodeURIComponent(filterValue);

    try {
      let response;
      response = await api.get(
        `/ezihealth/audit_logs?paginate=true&page=${auditPageFilter}&limit=10000&search=${searchVal}&sort_column=${sortColumn}&sort_type=${sortType}&filter_column=${filterColumn}&filter_value=${encodedFilterValue}&tenant_id=${tenant_id}`
      );

      return response?.data;
    } catch (error) {
      throw error;
    }
  }
);

export const trackUserClickThunk = createAsyncThunk(
  "audit/trackUserClick",
  async ({ desc, screen }: { desc: any; screen: any }) => {
    try {
      const userClickData = {
        table_name: "",
        desc,
        screen,
        log_id: "",
        activity_type: "Click",
      };
      const response = await api.post("/audit_logs/create", userClickData);

      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

const auditSlice = createSlice({
  name: "audit",
  initialState,
  reducers: {
    setAuditPageFilter: (state) => {
      state.AuditPageFilter += 1;
    },
    resetAuditPageFilter: (state) => {
      state.AuditPageFilter = 1;
    },
    resetAuditData: (state) => {
      state.getAuditData = {};
      state.audits = [];
    },

    setAuditRowId: (state, action) => {
      state.AuditRowId = action.payload;
    },
    setAuditFilterItems: (state, action) => {
      state.AuditFilterItems = action.payload;
    },
    resetAuditFilterItems: (state) => {
      state.AuditFilterItems = {
        filterColumn: "",
        filterValue: "",
        searchVal: "",
        sortColumn: "",
        sortType: "",
        auditPageFilter: 1,
      };
    },
    setFilterOptionsAudit: (state, action) => {
      state.selectedFilterOptionsAudit = action.payload;
    },
    resetFilterOptionsAudit: (state) => {
      state.selectedFilterOptionsAudit = [];
    },
    setSortOptionsAudit: (state, action) => {
      state.selectedSortOptionsAudit = action.payload;
    },
    resetSortOptionsAudit: (state) => {
      state.selectedSortOptionsAudit = [];
    },
    setSearchValueAudit: (state, action) => {
      state.searchValuesAudit = action.payload;
    },
    resetSearchValueAudit: (state) => {
      state.searchValuesAudit = "";
    },
    setIsAuditFilterDropdownOpen: (state) => {
      state.isAuditFilterDropdownOpen = true;
    },
    resetIsAuditFilterDropdownOpen: (state) => {
      state.isAuditFilterDropdownOpen = false;
    },
    // Filter Dropdown
    setSelectedDropdownItemAudit: (state, action) => {
      state.selectedDropdownItemAudit = action.payload;
    },
    resetSelectedDropdownItemAudit: (state) => {
      state.selectedDropdownItemAudit = "";
    },
    setSelectedItemIndexFilterAudit: (state, action) => {
      state.selectedItemIndexFilterAudit = action.payload;
    },
    resetSelectedItemIndexFilterAudit: (state) => {
      state.selectedItemIndexFilterAudit = "";
    },
    setSelectedItemIndexSortAudit: (state, action) => {
      state.selectedItemIndexSortAudit = action.payload;
    },
    resetSelectedItemIndexSortAudit: (state) => {
      state.selectedItemIndexSortAudit = "";
    },
    setSortByAudits: (state, action) => {
      state.sortByAudits = action.payload;
    },
    resetSortByAudits: (state) => {
      state.sortByAudits = "";
    },
    setSelectedFilterItemsAudit: (state, action) => {
      state.selectedFilterItemsAudit = action.payload;
    },
    resetSelectedFilterItemsAudit: (state) => {
      state.selectedFilterItemsAudit = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAuditThunk.pending, (state) => {
        state.loading === true;
      })
      .addCase(getAuditThunk.fulfilled, (state, action) => {
        state.loading = false;

        if (JSON.parse(JSON.stringify(state.getAuditData)).data) {
          const data = [
            ...JSON.parse(JSON.stringify(state.getAuditData)).data.data,
            ...action.payload.data.data,
          ];
          action.payload.data.data = data;
        }

        state.getAuditData = action.payload;
      })
      .addCase(getAuditThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getAuditByFilterThunk.pending, (state) => {
        state.loading === true;
      })
      .addCase(getAuditByProviderFilterThunk.pending, (state) => {
        state.loading = true;
      })
      // getAuditByProviderFilterThunk
      .addCase(getAuditByProviderFilterThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.auditDataProviders = [
          ...state.auditDataProviders,
          ...action.payload.data,
        ];
        if (JSON.parse(JSON.stringify(state.auditDataProviders)).data) {
          const data = [
            ...JSON.parse(JSON.stringify(state.getAuditData)).data,
            ...action.payload.data,
          ];
          action.payload.data = data;
        }
        state.getAuditData = action.payload;
      })
      .addCase(getAuditByFilterThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.audits = [...state.audits, ...action.payload.data.data];
        if (JSON.parse(JSON.stringify(state.getAuditData)).data) {
          const data = [
            ...JSON.parse(JSON.stringify(state.getAuditData)).data.data,
            ...action.payload.data.data,
          ];
          action.payload.data.data = data;
        }
        state.getAuditData = action.payload;
      })
      .addCase(getAuditByFilterThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getAuditUsernameThunk.pending, (state) => {
        state.loading === true;
      })
      .addCase(getAuditUsernameThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.getAuditUsernameData = action.payload;
      })
      .addCase(getAuditUsernameThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getAuditActivityTypeThunk.pending, (state) => {
        state.loading === true;
      })
      .addCase(getAuditActivityTypeThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.getAuditActivityTypeData = action.payload;
      })
      .addCase(getAuditActivityTypeThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getAuditTableNameThunk.pending, (state) => {
        state.loading === true;
      })
      .addCase(getAuditTableNameThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.getAuditTableNameData = action.payload;
      })
      .addCase(getAuditTableNameThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(trackUserClickThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(trackUserClickThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.trackUserClick = action.payload;
      })
      .addCase(trackUserClickThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const {
  setAuditPageFilter,
  resetAuditPageFilter,
  resetAuditData,
  setAuditRowId,
  setFilterOptionsAudit,
  resetFilterOptionsAudit,
  setSortOptionsAudit,
  resetSortOptionsAudit,
  setSearchValueAudit,
  resetSearchValueAudit,
  setAuditFilterItems,
  resetAuditFilterItems,
  setSelectedDropdownItemAudit, //Filter
  resetSelectedDropdownItemAudit,
  setSelectedItemIndexFilterAudit,
  resetSelectedItemIndexFilterAudit,
  setSelectedItemIndexSortAudit,
  resetSelectedItemIndexSortAudit,
  setSortByAudits,
  resetSortByAudits,
  setSelectedFilterItemsAudit,
  resetSelectedFilterItemsAudit,
} = auditSlice.actions;
export default auditSlice.reducer;
