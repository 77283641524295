import StaffDetailBox from './StaffDetailBox';

const ThirdStageBoxComponent = ({
  rosterItemIndex,
  minute,
  setClickedIndex,
  setMatchingItemIndex,
  forwards,
  rosterData,
  setRosterData,
  matchingItem,
  // matchingItemIndex
}: any) => (
  <div
    style={{ marginLeft: `${(minute / 60) * 100}px` }}
    className="third_stage_box"
    onDoubleClick={(e) => {
      e.stopPropagation();
      setClickedIndex(rosterItemIndex);
      setMatchingItemIndex(matchingItem.type);
      let tempArr = [...rosterData];
      let rosterCurrentItem = tempArr[rosterItemIndex];
      let currentAppointment = rosterCurrentItem.appointment.findIndex(
        (elem: any) => elem.type === matchingItem.type
      );

      if (!forwards) {
        tempArr[rosterItemIndex].appointment[currentAppointment].current_stage = 2;
      } else {
        tempArr[rosterItemIndex].appointment[currentAppointment].current_stage = 2;
      }
      setRosterData(tempArr);
    }}
  >
    <StaffDetailBox />
  </div>
);

export default ThirdStageBoxComponent;
