const mainTypesToButtons = {
  contacts: ["comms", "associations", "documents", "details", "daybook"],
  prospects: [
    "comms",
    "associations",
    "documents",
    "clinical",
    "details",
    "finance",
    "daybook",
  ],
  staffScreen: [
    "comms",
    "associations",
    "documents",
    "clinical",
    "details",
    "daybook",
    "finance",
    "meals",
    "availability",
  ],
  clinical: ["comms", "associations", "documents", "clinical", "details"],
  business: ["comms", "associations", "documents", "daybook", "details"],
  locations: ["comms", "associations", "documents", "daybook", "details"],
  teams: ["comms", "associations", "documents", "daybook", "details"],
  clients: [
    "comms",
    "associations",
    "documents",
    "clinical",
    "details",
    "meals",
    "finance",
    "daybook",
  ],
  providers: [
    "details",
    "prospects",
    "clients",
    "business",
    "locations",
    "teams",
    "contacts",
    "staff",
    "audit",
    "associations",
    "documents",
    "daybook",
    "comms",
    "documents",
    "finance",
    "vendor access",
    "report access",
  ],
  billingRule: ["details"],
  payRule: ["details"],
  reports: ["details", "comms"],
  reportComms: ["comms"],
};
export default mainTypesToButtons;
