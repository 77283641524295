export const HomeSVG = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="48"
        height="25"
        viewBox="0 0 48 25"
        fill="none"
      >
        <rect width="48" height="24.4706" rx="3" fill="#E9E9E9" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.4911 5.45354L23.4894 5.45181L24.2381 4.70312L32.4736 12.9387L31.725 13.6874L30.5896 12.5521V18.5317H30.5891V19.5866H17.8834V18.5317V18.5278V12.5586L16.7526 13.6894L16.0039 12.9407L23.4911 5.45354ZM24.2398 6.20223L29.5308 11.4933V18.5278H26.3549V14.2955H25.2961V18.5278H23.1794V14.2955H22.1206V18.5278H18.9422V11.4998L24.2398 6.20223ZM26.3558 13.2337H22.1206V14.2925H26.3558V13.2337Z"
          fill="#4F4F4F"
        />
      </svg>
    </>
  );
};

export const SearchSVG = ({ width = "24", height = "24" }: any) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
      >
        <mask
          id="path-1-outside-1_2320_10209"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width={width}
          height={height}
          fill="black"
        >
          <rect fill="white" width="24" height="24" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.1075 10.0538C18.1075 14.5017 14.5017 18.1075 10.0538 18.1075C5.60579 18.1075 2 14.5017 2 10.0538C2 5.60579 5.60579 2 10.0538 2C14.5017 2 18.1075 5.60579 18.1075 10.0538ZM16.0926 16.7995C14.4904 18.2347 12.374 19.1075 10.0538 19.1075C5.0535 19.1075 1 15.054 1 10.0538C1 5.0535 5.0535 1 10.0538 1C15.054 1 19.1075 5.0535 19.1075 10.0538C19.1075 12.3739 18.2348 14.4903 16.7997 16.0924L23.1027 22.3954L22.3956 23.1025L16.0926 16.7995Z"
          />
        </mask>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.1075 10.0538C18.1075 14.5017 14.5017 18.1075 10.0538 18.1075C5.60579 18.1075 2 14.5017 2 10.0538C2 5.60579 5.60579 2 10.0538 2C14.5017 2 18.1075 5.60579 18.1075 10.0538ZM16.0926 16.7995C14.4904 18.2347 12.374 19.1075 10.0538 19.1075C5.0535 19.1075 1 15.054 1 10.0538C1 5.0535 5.0535 1 10.0538 1C15.054 1 19.1075 5.0535 19.1075 10.0538C19.1075 12.3739 18.2348 14.4903 16.7997 16.0924L23.1027 22.3954L22.3956 23.1025L16.0926 16.7995Z"
          fill="#C7C7C7"
        />
        <path
          d="M16.0926 16.7995L16.4461 16.4459L16.1115 16.1113L15.7589 16.4271L16.0926 16.7995ZM16.7997 16.0924L16.4272 15.7588L16.1115 16.1113L16.4461 16.4459L16.7997 16.0924ZM23.1027 22.3954L23.4562 22.7489L23.8098 22.3954L23.4562 22.0418L23.1027 22.3954ZM22.3956 23.1025L22.042 23.4561L22.3956 23.8096L22.7491 23.4561L22.3956 23.1025ZM10.0538 18.6075C14.7779 18.6075 18.6075 14.7779 18.6075 10.0538H17.6075C17.6075 14.2256 14.2256 17.6075 10.0538 17.6075V18.6075ZM1.5 10.0538C1.5 14.7779 5.32965 18.6075 10.0538 18.6075V17.6075C5.88193 17.6075 2.5 14.2256 2.5 10.0538H1.5ZM10.0538 1.5C5.32965 1.5 1.5 5.32965 1.5 10.0538H2.5C2.5 5.88193 5.88193 2.5 10.0538 2.5V1.5ZM18.6075 10.0538C18.6075 5.32965 14.7779 1.5 10.0538 1.5V2.5C14.2256 2.5 17.6075 5.88193 17.6075 10.0538H18.6075ZM10.0538 19.6075C12.5018 19.6075 14.7359 18.6861 16.4262 17.1719L15.7589 16.4271C14.2449 17.7833 12.2462 18.6075 10.0538 18.6075V19.6075ZM0.5 10.0538C0.5 15.3301 4.77736 19.6075 10.0538 19.6075V18.6075C5.32965 18.6075 1.5 14.7779 1.5 10.0538H0.5ZM10.0538 0.5C4.77736 0.5 0.5 4.77736 0.5 10.0538H1.5C1.5 5.32965 5.32965 1.5 10.0538 1.5V0.5ZM19.6075 10.0538C19.6075 4.77736 15.3301 0.5 10.0538 0.5V1.5C14.7779 1.5 18.6075 5.32965 18.6075 10.0538H19.6075ZM17.1721 16.426C18.6861 14.7357 19.6075 12.5017 19.6075 10.0538H18.6075C18.6075 12.2461 17.7834 14.2448 16.4272 15.7588L17.1721 16.426ZM16.4461 16.4459L22.7491 22.7489L23.4562 22.0418L17.1532 15.7388L16.4461 16.4459ZM22.7491 22.0418L22.042 22.7489L22.7491 23.4561L23.4562 22.7489L22.7491 22.0418ZM22.7491 22.7489L16.4461 16.4459L15.739 17.153L22.042 23.4561L22.7491 22.7489Z"
          fill="#C7C7C7"
          mask="url(#path-1-outside-1_2320_10209)"
        />
      </svg>
    </>
  );
};
