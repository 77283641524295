import "./ClientStatus.css";

import React, { useRef, useState } from "react";

import clientImg from "../../../Model/Assets/png/client.png";
import { ClientStatusCircleXxlSvg } from "../../../Model/Assets/SVG/WidgetVillage/ClientStatusCircleSvg";
import CalenderSvg from "../../../Model/Assets/SVG/WidgetVillage/CalenderSvg";
import PlusIcon from "../../../Model/Assets/SVG/WidgetVillage/PlusIcon";
import { statusMapClient } from "../../../Model/utils/Helper/statusMap";
import {
  formatDateForClientStatus,
  formatTimeClientStatus,
} from "../../../Model/utils/Helper/formatDate";
import { useAppSelector } from "../../../Controller/redux/Hooks";

const ClientStatusVillage = ({
  isResidence = false,
  residence,
  status,
  title,
  date,
  time,
  imageURL,
  backgroundWithText,
  isUserAvailable,
}: any) => {
  const clientStatusParentRef = useRef<any>();

  const [isShow, setIsShow] = useState<boolean>(false);
  const [isActive, setIsActive] = useState<boolean>(false);
  const { dynamicColor } = useAppSelector((state) => state?.common);

  return (
    <div className={`${!isUserAvailable && "client_empty_user"}`}>
      <div className="client_status_flex">
        {isUserAvailable ? (
          isResidence &&
          residence && (
            <span className="client_widget_status_residence">
              {isActive && residence ? residence : ""}
              {!isActive && residence ? residence.slice(0, 11) : ""}
            </span>
          )
        ) : null}

        {/* {!isUserAvailable && (
          <div style={{ height: "87px", maxHeight: "87px" }}>&nbsp;</div>
        )} */}
        {isUserAvailable && (
          <span
            style={{
              backgroundColor:
                statusMapClient[status]?.backgroundColor ||
                statusMapClient.default.backgroundColor,
            }}
            className="client_widget_status"
          >
            {statusMapClient[status]?.label || statusMapClient.default.label}
          </span>
        )}
      </div>
      {isUserAvailable && (
        <div
          ref={clientStatusParentRef}
          className={`client_widget_container  ${
            isShow || isActive ? "client_widget_container_active" : ""
          }`}
        >
          <div
            onClick={() => setIsActive((prevState) => !prevState)}
            className="client_widget_inner_container"
          >
            <div className="client_widget_img_container">
              <div
                style={{
                  border: `2px solid ${
                    statusMapClient[status]?.backgroundColor ||
                    statusMapClient.default.backgroundColor
                  }`,
                  backgroundColor: dynamicColor,
                }}
                className="client_widget_img"
              >
                {imageURL ? (
                  <img src={imageURL} alt="client image" />
                ) : (
                  <span> {backgroundWithText} </span>
                )}
              </div>
              <div className="client_widget_circle">
                <ClientStatusCircleXxlSvg />
              </div>
            </div>
          </div>

          {(isActive || isShow) && (
            <div
              className={`client_widget_detail_container 
          client_widget_detail_container_active  `}
            >
              <div className="client_widget_title_container">
                <span className="client_widget_title">{title.slice(0, 9)}</span>
                <span className="client_widget_plus">
                  <PlusIcon />
                </span>
              </div>
              <div className="client_widget_time_wrapper">
                <div className="client_widget_time_container">
                  <span className="client_widget_at">@</span>
                  <span className="client_widget_time">
                    {formatTimeClientStatus(time)}
                  </span>
                </div>
                <div className="client_widget_date_container">
                  <CalenderSvg />
                  <span className="client_widget_time">
                    {formatDateForClientStatus(date)}
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

// ClientStatus.defaultProps = {
//   status: 1,
//   title: "Adnan",
//   date: "24 / May / 2023",
//   time: "2023-10-14T05:04:05.000000Z",
// };
export default React.memo(ClientStatusVillage);
