import React, { useContext, useEffect, useRef, useState } from "react";
import { dummyArrProspects } from "../../../../pages/People/ClientPage/Components/UniqueClientScreen/Data/ClientScreenContentArr";
import {
  formatDate,
  formatDateForAPI,
  getCurrentDate,
} from "../../../../../Model/utils/Helper/formatDate";
import { translateYIndices } from "../../../../pages/People/ProspectusPage/Data/IndicesData";
import ReactDatePicker from "react-datepicker";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../Controller/redux/Hooks";
import {
  getProspectsByIdThunk,
  getProspectsThunk,
  updateProspectsThunk,
} from "../../../../../Controller/redux/features/ProspectsFeature/prospectsSlice";
import { toast } from "react-toastify";
import { setunsavedChanges } from "../../../../../Controller/redux/features/AuthFeature/authSlice";
import CustomDatePicker from "../../../DatePicker/CustomDatePicker";

const ProspectDetailsSticky = ({ currentProspects }: any) => {
  // @ts-ignore
  const dynamicProspectId = parseInt(localStorage.getItem("dynamicProspectId"));
  const dispatch = useAppDispatch();
  const [userRoles, setUserRoles] = useState([]);
  const getUserRoles = useAppSelector(
    (state) => state?.prospects?.prospectRoles
  );

  const { domainDetail } = useAppSelector((state) => state?.common);
  const [dynamicColor, setDynamicColor] = useState("");
  useEffect(() => {
    if (domainDetail) {
      setDynamicColor(domainDetail?.sticky_background_color);
    }
  }, [domainDetail]);

  useEffect(() => {
    if (getUserRoles?.data && getUserRoles?.data?.length > 0) {
      setUserRoles(getUserRoles?.data);
    }
  }, [getUserRoles]);
  const [details, setDetails] = useState<any>({
    givenName: "",
    middleName: "",
    familyName: "",
    knownAs: "",
    address: "",
    email: "",
    phone: "",
    mobile: "",
    dateOfBirth: "",
    null1: "",
    null2: "",
    null3: "",
    fundingType: "",
    packageLevel: "",
    efa: "",
    acatAssessment: "",
    addInitialEnquiry: "",
    role: "",
  });

  const [selectedDate, setSelectedDate] = useState<Date | null | any>("");
  const [selectedEFA, setselectedEFA] = useState<any>("");
  const [selectedIED, setSelectedIED] = useState<any>("");
  const [selectedACATDate, setSelectedACATDate] = useState<Date | null | any>(
    ""
  );
  useEffect(() => {
    const fetchedData: any = currentProspects;
    if (fetchedData) {
      setDetails({
        givenName: `${fetchedData.first_name}` || "",
        middleName: fetchedData.middle_name || "",
        familyName: fetchedData.last_name || "",
        knownAs: fetchedData.pref_name || "",
        address: fetchedData?.user_addresses[0]?.address || "",
        email: fetchedData?.email === null ? "" : fetchedData?.email,
        phone: fetchedData?.phone === null ? "" : fetchedData?.phone,
        mobile: fetchedData?.mobile === null ? "" : fetchedData?.mobile,

        dateOfBirth:
          fetchedData?.date_of_birth !== null
            ? formatDate(fetchedData.date_of_birth)
            : "",
        null1: "",
        null2: "",
        null3: "",

        fundingType:
          fetchedData?.funding_type !== null ? fetchedData?.funding_type : "",

        packageLevel:
          fetchedData?.package_level !== null ? fetchedData?.package_level : "",
        efa:
          fetchedData?.funding_available !== null
            ? fetchedData?.funding_available
            : "",

        acatAssessment: fetchedData?.acat_assessment,
        addInitialEnquiry: fetchedData?.initial_enquiry,
        role: currentProspects ? currentProspects?.roles[0]?.id : "",
      });

      const currentClientDOB = fetchedData?.date_of_birth;
      if (currentClientDOB !== null) {
        setSelectedDate(new Date(currentClientDOB));
      } else {
        setSelectedDate(null);
      }

      const currentEFA = fetchedData?.funding_available;
      if (currentEFA !== null) {
        setselectedEFA(new Date(currentEFA));
      } else {
        setselectedEFA(null);
      }
      const currentIED = fetchedData?.initial_enquiry;
      if (currentIED !== null) {
        setSelectedIED(new Date(currentIED));
      } else {
        setSelectedIED(null);
      }
    } else {
      setDetails({
        givenName: "",
        middleName: "",
        familyName: "",
        knownAs: "",
        address: "",
        email: "",
        phone: "",
        mobile: "",
        dateOfBirth: "",

        fundingType: "",
        packageLevel: "",
        efa: "",
        acatAssessment: "",
        addInitialEnquiry: "",
      });
    }
  }, [currentProspects]);

  const pickerRef = useRef<any>(null);
  const datepickerRef = useRef<any>(null);

  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  const handleDateChange = (date: Date | any) => {
    dispatch(setunsavedChanges(true));
    if (!hasUnsavedChanges) {
      setHasUnsavedChanges(true);
    }
    setSelectedDate(date);
  };

  const handleChange = (
    index: number,
    e: React.ChangeEvent<HTMLInputElement> | any
  ) => {
    const { value } = e.target;
    const fieldName = Object.keys(details)[index];
    setDetails((prevState: any) => ({
      ...prevState,
      [fieldName]: value,
    }));

    dispatch(setunsavedChanges(true));
    if (!hasUnsavedChanges) {
      setHasUnsavedChanges(true);
    }
  };

  const handleDateChangeForEFA = (date: Date | any) => {
    dispatch(setunsavedChanges(true));
    if (!hasUnsavedChanges) {
      setHasUnsavedChanges(true);
    }
    setselectedEFA(date);
  };

  const [openACATDropdown, setopenACATDropdown] = useState(false);

  const handleOpenDropdown = () => {
    setopenACATDropdown(true);
  };
  const [updateProspects, setUpdateProspects] = useState<any>({
    first_name: "",
    middle_name: "",
    last_name: "",
    pref_name: "",
    email: "",
    mobile: "",
    dob: "",
    funding_type: "",
    package_level: "",
    funding_available: "",
    acat_assessment: "",
    initial_enquiry: "",
    role: 4,
    user_id: currentProspects?.id,
    type: -1,
    phone: "",
    address: "",
  });

  const initialDate = currentProspects?.date_of_birth;
  useEffect(() => {
    if (initialDate) {
      setSelectedDate(new Date(initialDate));
    }
  }, [initialDate]);

  const [selectedInterval, setselectedInterval] = useState("");
  const capitalizedInterval =
    selectedInterval.charAt(0).toUpperCase() + selectedInterval.slice(1);
  const result = `${capitalizedInterval}, ${formatDate(selectedACATDate)}`;

  useEffect(() => {
    setUpdateProspects({
      first_name: details.givenName,
      middle_name: details.middleName,
      last_name: details.familyName,
      pref_name: details.knownAs,
      dob: selectedDate ? formatDateForAPI(selectedDate) : initialDate,
      mobile: details?.mobile,
      package_level: details.packageLevel,
      funding_available: formatDateForAPI(selectedEFA),
      acat_assessment: result,
      initial_enquiry: formatDateForAPI(selectedIED),
      email: details.email,
      funding_type: details.fundingType,
      role: details.role,
      user_id: currentProspects?.id,
      type: dynamicProspectId,
      phone: details.phone,
      address: details.address,
    });
  }, [details, selectedDate, selectedEFA, result, selectedIED]);

  const handleIntervalChange = (interval: string | any) => {
    setselectedInterval(interval);
  };

  const handleACATDateChange = (date: Date | any) => {
    dispatch(setunsavedChanges(true));
    if (!hasUnsavedChanges) {
      setHasUnsavedChanges(true);
    }
    setSelectedACATDate(date);
  };

  const handleDateChangeForIED = (date: Date | any) => {
    dispatch(setunsavedChanges(true));
    if (!hasUnsavedChanges) {
      setHasUnsavedChanges(true);
    }
    setSelectedIED(date);
  };

  const [possibleAddresses, setPossibleAddresses] = useState<any>([]);
  function initService(query: any) {
    const displaySuggestions = function (predictions: any, status: any) {
      // @ts-ignore
      if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
        // console.log("Location service down!");
        return;
      }
      if (!predictions) {
        setPossibleAddresses([]);
        return;
      }
      setPossibleAddresses(predictions);
    };
    // @ts-ignore
    const service = new window.google.maps.places.AutocompleteService();
    // console.log(query);
    service.getQueryPredictions({ input: query }, displaySuggestions);
  }

  const handleChangeForAddress = (index: number, e: any) => {
    const { value } = e.target;
    setDetails({ ...details, address: value });
    initService(value);
    if (value.length === 0) {
      setDetails({ ...details, address: "" });
      setPossibleAddresses([]);
    }
  };

  const [selectedAddress, setSelectedAddress] = useState<any>("");

  const selectAddress = (e: any) => {
    if (!hasUnsavedChanges) {
      setHasUnsavedChanges(true);
    }
    setSelectedAddress(e.target.innerHTML);
    setDetails({ ...details, address: e.target.innerHTML });
    dispatch(setunsavedChanges(true));
    setPossibleAddresses([]);
  };

  const handleSaveChanges = async () => {
    setHasUnsavedChanges(false);
    dispatch(setunsavedChanges(false));

    try {
      const res = await dispatch(updateProspectsThunk(updateProspects));
      dispatch(
        getProspectsByIdThunk({
          typeId: dynamicProspectId,
          clientId: currentProspects?.id,
        })
      );
      if (res?.payload.status === "success") {
        toast.success("Prospect updated successfully!", {
          style: {},
          progressStyle: { backgroundColor: dynamicColor },
          icon: (
            <span
              style={{
                marginRight: "8px",
                borderRadius: "50%",
                width: "7rem",
                height: "35px",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: dynamicColor,
                backgroundColor: "white",
                fontSize: "1.5rem",
                border: `3px solid ${dynamicColor}`,
              }}
            >
              ✓
            </span>
          ),
        });
      } else {
        toast.error("An Error Occurred!");
      }
    } catch (error) {
      console.error(error);
      toast.error("An Error Occurred!");
    }
    dispatch(getProspectsThunk({ dynamicProspectsId: dynamicProspectId }));
  };

  return (
    <div className="staff_details_section_wrapper_sticky_note prospect_sticky_mobile_wrapper">
      <div className=" client-screen-personal-details-heading">
        <span className=" client-screen-sub-heading-title">
          Personal Details
        </span>
        <span className="client-screen-line"></span>
      </div>
      <div className="main-box-body">
        {dummyArrProspects.map((elem: any, index: any) => {
          const fieldName = Object.keys(details)[index];

          return (
            <div
              className={`content-wrapper ${
                index === 8 && "prospect_date_filter"
              }`}
              key={index}
              style={{
                transform: translateYIndices.includes(index)
                  ? "translateY(130%)"
                  : "none",
                visibility:
                  index === 9 || index === 10 || index === 11
                    ? "hidden"
                    : "visible",
              }}
            >
              <div className="wrapper-title">{elem?.UpperTitle}</div>

              {index === 8 ? (
                // <ReactDatePicker
                //   onChange={handleDateChange}
                //   selected={selectedDate}
                //   startDate={selectedDate}
                //   placeholderText="Select Date"
                //   className="date-picker-details-tab-of-client-screen"
                //   showYearDropdown
                //   showMonthDropdown
                //   dropdownMode="select"
                //   maxDate={getCurrentDate()}
                //   dateFormat="EEE, dd MMMM yyyy"
                // />
                <CustomDatePicker handleChange={handleDateChange} newDate={selectedDate} maxDate={getCurrentDate()}/>
              ) : index === 12 ? (
                <select
                  className="wrapper-info wrapper-info-select-dropdown"
                  onChange={(e) => handleChange(index, e)}
                  value={details[fieldName]}
                >
                  <option value="">Please Select...</option>

                  <option value="Aged Care">Aged Care</option>
                  <option value="Disability">Disability</option>
                  <option value="Veterans">Veterans</option>
                  <option value="Private">Private</option>
                  <option value="Insurance">Insurance</option>
                  <option value="Other">Other</option>
                </select>
              ) : index === 14 ? (
                <>
                  {/* <ReactDatePicker
                    onChange={handleDateChangeForEFA}
                    selected={selectedEFA}
                    startDate={selectedEFA}
                    placeholderText="Select Date"
                    className="date-picker-details-tab-of-client-screen date_picker_prospects_efa"
                    showYearDropdown
                    showMonthDropdown
                    dropdownMode="select"
                    minDate={getCurrentDate()}
                    dateFormat="EEE, dd MMMM yyyy"
                  /> */}
                  <CustomDatePicker handleChange={handleDateChangeForEFA} newDate={selectedEFA} minDate={getCurrentDate()}/>
                </>
              ) : index === 15 ? (
                <>
                  <div id="date-picker-prospects">
                    {openACATDropdown && (
                      <div
                        id="acat_wrapper"
                        style={{
                          marginTop: "17px",
                        }}
                        ref={pickerRef}
                      >
                        <div id="acat_option">
                          {["Booked", "Completed"]?.map((option: any) => (
                            <div
                              key={option}
                              className={`each_acat_option   
                          ${
                            selectedInterval.toLowerCase() ===
                            option.toLowerCase()
                              ? "selected_option"
                              : ""
                          } 

                      `}
                              onClick={() =>
                                handleIntervalChange(option.toLowerCase())
                              }
                            >
                              {option}
                            </div>
                          ))}
                        </div>
                      </div>
                    )}

                    <ReactDatePicker
                      placeholderText="ACAT Assessment"
                      selected={selectedACATDate}
                      ref={datepickerRef}
                      onChange={handleACATDateChange}
                      onFocus={handleOpenDropdown}
                      className="date-picker-acat"
                      showYearDropdown
                      showMonthDropdown
                      dropdownMode="select"
                      shouldCloseOnSelect={false}
                      open={openACATDropdown}
                      value={
                        selectedACATDate
                          ? `${result}`
                          : currentProspects
                          ? currentProspects?.acat_assessment
                          : "ACAT Assessment"
                      }
                    />
                  </div>
                </>
              ) : index === 16 ? (
                <>
                  {/* <ReactDatePicker
                    onChange={handleDateChangeForIED}
                    selected={selectedIED}
                    startDate={selectedIED}
                    placeholderText="Select Date"
                    className="date-picker-details-tab-of-client-screen date_picker_prospects_efa"
                    showYearDropdown
                    showMonthDropdown
                    dropdownMode="select"
                    maxDate={getCurrentDate()}
                    dateFormat="EEE, dd MMMM yyyy"
                  /> */}
                  <CustomDatePicker newDate={selectedIED} handleChange={handleDateChangeForIED} maxDate={getCurrentDate()}/>
                </>
              ) : index === 13 ? (
                <select
                  className="wrapper-info wrapper-info-select-dropdown"
                  onChange={(e) => handleChange(index, e)}
                  value={details[fieldName]}
                >
                  <option value="">Please Select...</option>
                  {[
                    "NDIS - Self Managed",
                    "NDIS - Plan Managed",
                    "NDIS - NDIA Managed",
                    "DSOA",
                    "HCP Level 1",
                    "HCP Level 2",
                    "HCP Level 3",
                    "HCP Level 4",
                    "HACC",
                    "CHSP",
                    "DVA",
                    "DVA Nursing",
                    "TAC",
                    "Brokerage",
                    "Private Fee for Service",
                  ].map((elem, index) => (
                    <option key={index} value={elem}>
                      {elem}
                    </option>
                  ))}
                </select>
              ) : index === 17 ? (
                <>
                  <select
                    className="wrapper-info wrapper-info-select-dropdown"
                    onChange={(e) => handleChange(index, e)}
                    value={details[fieldName]}
                    style={{
                      border: "none",
                      outline: "none",
                      height: "fit-content",
                      backgroundColor: "white",
                      // width: "256px",
                    }}
                  >
                    <option>Please Select...</option>

                    {userRoles?.map((elem: any, index: number | any) => (
                      <>
                        <option key={index} value={elem?.id}>
                          {elem?.desc}
                        </option>
                      </>
                    ))}
                  </select>
                </>
              ) : index === 4 ? (
                <>
                  <div>
                    <input
                      type="text"
                      onChange={(e) => handleChangeForAddress(index, e)}
                      className="wrapper-info"
                      placeholder="Enter an address"
                      value={details[fieldName]}
                    />
                    <div className="address_listing">
                      <ul>
                        {possibleAddresses.length > 0
                          ? possibleAddresses.map((address: any, ind: any) => {
                              return (
                                <li
                                  onClick={selectAddress}
                                  className="address_listing_item"
                                >
                                  {address.description}
                                </li>
                              );
                            })
                          : null}
                      </ul>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <input
                    className="wrapper-info"
                    onChange={(e) => handleChange(index, e)}
                    value={details[fieldName]}
                    placeholder={elem?.placeHolder}
                    style={{
                      border: "none",
                      outline: "none",
                      height: "fit-content",
                    }}
                  />
                </>
              )}

              <div className="wrapper-line"></div>
            </div>
          );
        })}
      </div>
      <div className="prospects-second-div">
        <div
          className="client-screen-personal-details-heading"
          style={{
            transform: "translateY(-190px)",
          }}
        >
          <span className="client-screen-sub-heading-title">
            Funding Details
          </span>
          <span className="client-screen-line"></span>
        </div>
        <div className="content-wrapper">
          {hasUnsavedChanges && (
            <button
              className="client_sticky_save_btn"
              style={{
                background: dynamicColor,
                marginBottom: "5px",
              }}
              onClick={() => {
                handleSaveChanges();
              }}
            >
              save
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProspectDetailsSticky;
