import React, { useEffect, useRef, useState } from "react";
import Table from "../../../components/CommonTable/Table";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../Controller/redux/Hooks";
import {useNavigate, useParams } from "react-router-dom";
import {
  getProviderContacts,
  getProviderContactsByFilterThunk,
  resetContactProviderData,
} from "../../../../Controller/redux/features/ProviderFeature/providerContactsSlice";
import {
  getProviderContactEmailThunk,
  getProviderContactNameThunk,
  getProviderContactPhoneThunk,
  resetContactPage,
  setContactPage,
} from "../../../../Controller/redux/features/ContactsFeature/contactsSlice";

const ProviderContacts = () => {
  const tableRef = useRef();
  const contactInnerRef = useRef();
  //  @ts-ignore
  const dynamicContactId = parseInt(localStorage.getItem("dynamicContactId"));

  const dropdownRefContacts = useRef<any>();
  const svgParentRefContacts = useRef<any>();
  const [isDropdownOpenContacts, setIsDropdownOpenContacts] = useState(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const handleClickRow = (e: any, id: any) => {
     navigate(
      `/providers/${window.btoa(providerId)}/contacts/${window.btoa(id)}`
    );
  };
  const columns = [
    { header: "Name", accessor: "first_name" },
    { header: "Email", accessor: "email" },
    { header: "Mobile", accessor: "mobile" },
    { header: "Associated Business", accessor: "associated_business" },
    { header: "Status", accessor: "status" },
  ];
  const { domainDetail } = useAppSelector((state) => state?.common);
  const contactsStatusDetailsArr = useAppSelector(
    (state) => state.common.statusDetailsData?.data
  );

  const id: any = useParams()?.id;
  const [providerId, setProviderId] = useState<any>("");
  useEffect(() => {
    if (id) {
      let realId = window?.atob(id);
      setProviderId(realId);
    }
  }, [id]);
  useEffect(() => {
    if (providerId && dynamicContactId) {
      // dispatch(
      //   getProviderContacts({
      //     dynamicProviderId: providerId,
      //     dynamicUserTypeId: dynamicContactId,
      //   })
      // );
      dispatch(
        getProviderContactNameThunk({
          dynamicProviderId: providerId,
          dynamicUserTypeId: dynamicContactId,
        })
      );
      dispatch(
        getProviderContactEmailThunk({
          dynamicProviderId: providerId,
          dynamicUserTypeId: dynamicContactId,
        })
      );
      dispatch(
        getProviderContactPhoneThunk({
          dynamicProviderId: providerId,
          dynamicUserTypeId: dynamicContactId,
        })
      );
    }
  }, [providerId, dynamicContactId]);

  const { contactsRecords, loading, lastPage } = useAppSelector(
    (state) => state?.providerContacts
  );

  const [providerContactsRecords, setProviderContactsRecords] = useState([]);

  useEffect(() => {
    if (contactsRecords) {
      setProviderContactsRecords(contactsRecords);
    }
  }, [contactsRecords]);

  useEffect(() => {
    const handleClickOutsideContacts = (event: any) => {
      if (
        dropdownRefContacts.current &&
        !dropdownRefContacts.current.contains(event.target) &&
        svgParentRefContacts.current &&
        !svgParentRefContacts.current.contains(event.target)
      ) {
        setIsDropdownOpenContacts(false);
      }
    };
    document.addEventListener("click", handleClickOutsideContacts);
    return () => {
      document.removeEventListener("click", handleClickOutsideContacts);
    };
  }, []);

  const { ContactFilterItems, ContactPage } = useAppSelector(
    (state) => state?.contacts
  );
  const {
    selectedFilterOptionsContact,
    selectedSortOptionsContact,
    searchValContacts,
  } = useAppSelector((state) => state?.contacts);
  const { filterIconColor } = useAppSelector((state) => state?.common);
  useEffect(() => {
    dispatch(resetContactProviderData());
    dispatch(resetContactPage());
  }, [
    ContactFilterItems?.searchVal,
    ContactFilterItems?.filterValue,
    ContactFilterItems?.sortType,
    ContactFilterItems?.status,
    dynamicContactId,
  ]);
  useEffect(() => {
    dispatch(
      getProviderContactsByFilterThunk({
        dynamicProviderId: providerId,
        dynamicUserTypeId: dynamicContactId,
        filterColumn: ContactFilterItems?.filterColumn
          ? ContactFilterItems?.filterColumn
          : "",
        filterValue: ContactFilterItems?.filterValue
          ? ContactFilterItems?.filterValue
          : "",
        searchVal: ContactFilterItems?.searchVal
          ? ContactFilterItems?.searchVal
          : "",
        sortColumn: ContactFilterItems?.sortColumn
          ? ContactFilterItems?.sortColumn
          : "",
        sortType: ContactFilterItems?.sortType
          ? ContactFilterItems?.sortType
          : "",
        status: ContactFilterItems.status ? ContactFilterItems.status : "",
        ContactPage,
      })
    );
  }, [
    ContactPage,
    ContactFilterItems?.searchVal,
    ContactFilterItems?.filterValue,
    ContactFilterItems?.sortType,
    ContactFilterItems?.status,
    providerId,
    dynamicContactId,
  ]);
  const onScroll = () => {
    if (contactInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = contactInnerRef.current;

      if (
        scrollTop + clientHeight + +5 >= scrollHeight &&
        ContactPage < lastPage
      ) {
        dispatch(setContactPage(ContactPage));
      }
    }
  };
  return (
    <div className="provider_prospect_wrapper">
      <Table
        isLoading={loading}
        tableRef={tableRef}
        tableBodyRef={contactInnerRef}
        onScroll={onScroll}
        dynamicHeaderColor={domainDetail?.listingtbl_color}
        statusType="providerContactsScreen"
        thData={columns}
        classNameTr={`all-contacts-table-head `}
        type="providerContacts"
        dropdownRef={dropdownRefContacts}
        isDropdownOpen={isDropdownOpenContacts}
        itemsArr={contactsStatusDetailsArr}
        onClickSvg={() => {
          setIsDropdownOpenContacts(true);
        }}
        svgParentRef={svgParentRefContacts}
        allClients={providerContactsRecords}
        onClickRow={handleClickRow}
        classNameRow={`all-contacts-table-head `}
        fill={
          selectedFilterOptionsContact.length > 0 ||
          searchValContacts ||
          selectedSortOptionsContact.length > 0
            ? filterIconColor
            : "#D8D3D3"
        }
      />
    </div>
  );
};

export default ProviderContacts;
