import {useEffect, useState} from "react";
import {useAppSelector} from "../../../../Controller/redux/Hooks";
import {Modal, ModalOverlay} from "@chakra-ui/react";
import AttachmentsModal from "./AttachmentsModal";
import EXCELSvg from "../../../../Model/Data/EXCELSvg";
import GlobalDOCXSvg from "../../../../Model/Data/DOCXSvg";
import GlobalPDFSvg from "../../../../Model/Data/PDFSvg";
import {AddAttachmentSVG} from "../Data/SVGs";

export const AttachmentsContainer = ({
    attachments,
    currentElem,
    clientId,
    showAttachmentModal,
    setFilesWhenClickedFromCommsBox = () => {},
    setShowAttachmentModal,
    getDaybookCall = () => {},
    providerId,
    triggerGetCommsAPICall = () => {},
    setState = () => {},
    isDaybook,
    isDocumentCreateState
  }: any) => {
    const { user } = useAppSelector((state) => state.common);
  
    const handleGetFile = async (name: string | any) => {
      try {
        const response = await api.get(`files/get_file?file=${name}`);
        const fileUrl = response?.data?.data;
        window.open(fileUrl, "_blank");
      } catch (error) {
        console.error("Error fetching file:", error);
      }
    };
    const [localStateAttachments, setLocalStateAttachments] = useState<any>([]);
  
    useEffect(() => {
      if (attachments) {
        setLocalStateAttachments(attachments);
      }
    }, [attachments]);
  
    const recieveArrayFromChild = (args: any[] | "empty", index?: number) => {
      if (args === "empty") {
        setLocalStateAttachments(attachments ? attachments : []);
      } else if (index !== undefined) {
        setLocalStateAttachments(prev => prev.filter((_, i) => i !== index));
      } else {
        setLocalStateAttachments(prev => [...prev, ...args]);
      }
    };
    
  
  
    return (
      <>
        {showAttachmentModal && (
          <>
            <Modal
              isOpen={true}
              onClose={() => setShowAttachmentModal(false)}
              closeOnOverlayClick={true}
              isCentered
            >
              <ModalOverlay
                onClick={() => setShowAttachmentModal(false)}
                zIndex={10}
              />
  
              <AttachmentsModal
              // setFilesWhenClickedFromCommsBox={setFilesWhenClickedFromCommsBox}
              setState={setState}
              isDocumentCreateState={isDocumentCreateState}
                attachments={attachments}
                recieveArrayFromChild={recieveArrayFromChild}
                loggedInUserDetailsFromDaybook={user}
                setshowAttachmentModal={setShowAttachmentModal}
                currentElem={currentElem}
                clientId={clientId}
                getDaybookCall={getDaybookCall}
                providerId={providerId}
                triggerGetCommsAPICall={triggerGetCommsAPICall}
                isDaybook={isDaybook}
              />
            </Modal>
          </>
        )}
        <div className="searchable_items_container">
          <div className="label_segment">
            <div className="items_container_label">Attachments</div>
            <span onClick={() => setShowAttachmentModal(!showAttachmentModal)}>
              <AddAttachmentSVG />
            </span>
          </div>
  
          <div className="searchable_items_selected_items_wrapper wrapper_attachments">
            {localStateAttachments?.map((item: any) => {
              return (
                <div className="single_item">
                  <div className="file-item">
                    {item?.file_name?.includes("pdf") ||
                    item?.file?.name?.includes("pdf") ? (
                      <GlobalPDFSvg
                        onClick={() => handleGetFile(item?.file_name || item?.key)}
                      />
                    ) : item?.file_name?.includes("docx") ||
                      item?.file?.name?.includes("docx") ? (
                      <GlobalDOCXSvg
                        onClick={() => handleGetFile(item?.file_name || item?.key)}
                      />
                    ) : (
                      <EXCELSvg onClick={() => handleGetFile(item?.file_name || item?.key)} />
                    )}
                  </div>
                  <div className=" title_single_item">
                    {item?.actual_file_name
                      ? item?.actual_file_name?.substring(0, 5) + "..."
                      : item?.file_name ? item?.file_name?.substring(0, 5) + "..."
                    
                    : item?.file?.name ? item?.file?.name?.substring(0, 5) + "..." : ""}
                    }
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </>
    );
  };