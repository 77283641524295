import _ from "lodash";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { api } from "../../../../Controller/services/api";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../Controller/redux/Hooks";
import {
  getExpenseTypeByFilterThunk,
  resetExpenseTypeData,
} from "../../../../Controller/redux/features/RefDataFeature/ExpenseTypeFeature/expensetypeSlice";
import { setShowStickyNote } from "../../../../Controller/redux/features/CommonFeature/commonSlice";

const ExpenseForm = () => {
  const [IsLoading, setIsLoading] = useState(false);
  const dynamicColor = useAppSelector((state) => state?.common?.dynamicColor);
  const dispatch = useAppDispatch();
  const { expenseTypePage } = useAppSelector((state) => state.expenssType);

  const [commonBody, setcommonBody] = useState<any>({
    name: "",
    associated_funding_type: "",
    associated_package_type: "",
    category: "",
    rate: "",
    rate_type: "",
  });

  const handleAddForm = () => {
    setIsLoading(true);
    if (commonBody.name == "") {
      toast.error("Please enter value on required fileds");
      setIsLoading(false);
      return;
    }
    const url = "/expense_types/create_expense_type";

    api
      .post(url, commonBody)
      .then((response: any) => {
        if (response.data.status === "success") {
          setIsLoading(false);
          toast.success(`Expense added successfully!`, {
            style: {},
            progressStyle: { backgroundColor: dynamicColor },
            icon: (
              <span
                style={{
                  marginRight: "8px",
                  borderRadius: "50%",
                  width: "7rem",
                  height: "35px",
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: dynamicColor,
                  backgroundColor: "white",
                  fontSize: "1.5rem",
                  border: `3px solid ${dynamicColor}`,
                }}
              >
                ✓
              </span>
            ),
          });
          const stickyNoteElement = document.querySelector(
            ".new_sticky_note_wrapper"
          );
          dispatch(resetExpenseTypeData());
          dispatch(
            getExpenseTypeByFilterThunk({
              filterColumn: "",
              filterValue: "",
              searchVal: "",
              sortColumn: "",
              sortType: "",
              //   status: "",
              expenseTypePage,
            })
          );

          if (stickyNoteElement) {
            stickyNoteElement.classList.add("fade_out");
            setTimeout(() => {
              dispatch(setShowStickyNote(false));
            }, 300);
          }
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        toast.error(error.message);
        setIsLoading(false);
      });
  };

  const debouncedApiCall = _.debounce(handleAddForm, 300);

  useEffect(() => {
    const handleClick = () => {
      debouncedApiCall();
    };

    document?.getElementById("saveBTN")?.addEventListener("click", handleClick);

    return () => {
      document
        ?.getElementById("saveBTN")
        ?.removeEventListener("click", handleClick);
    };
  }, [debouncedApiCall]);

  return (
    <div className="payload_area">
      <div className="main_area">
        <div className="side_bar"></div>
        <div className="side_bar" />

        <div className="middle-block">
          <p className="note-title">New Expense</p>
          <div className="middle-section-content middle-section-others">
            <div className="field">
              <span className="field-title">
                {`Name`}
                <span className="asterixs_color"> * </span>
              </span>
              <input
                style={{
                  backgroundColor: "white",
                }}
                className="input-fields-of-sticky-note"
                placeholder={`Enter Name`}
                onChange={(e) =>
                  setcommonBody((prevState: any) => ({
                    ...prevState,
                    name: e.target.value,
                  }))
                }
                value={commonBody?.name}
              />

              <span className="field-border field-border-add-form"></span>
            </div>
            <div className="field">
              <span className="field-title">{`Category`}</span>
              <input
                style={{
                  backgroundColor: "white",
                }}
                className="input-fields-of-sticky-note"
                placeholder={`Enter Category`}
                onChange={(e) =>
                  setcommonBody((prevState: any) => ({
                    ...prevState,
                    category: e.target.value,
                  }))
                }
                value={commonBody?.category}
              />

              <span className="field-border field-border-add-form"></span>
            </div>{" "}
            <div className="field">
              <span className="field-title">{`Associated Funding Type`}</span>
              <select
                onChange={(e) =>
                  setcommonBody((prevState: any) => ({
                    ...prevState,
                    associated_funding_type: e.target.value,
                  }))
                }
                value={commonBody?.associated_funding_type}
                className="wrapper-info wrapper-info-select-dropdown"
                style={{
                  border: "none",
                  outline: "none",
                  height: "fit-content",
                  backgroundColor: "white",
                  // width: "256px",
                }}
              >
                <option value="">Please Select...</option>
                {["HCP", "NDIS", "Private"]?.map((elem: any, index: any) => (
                  <option key={index} value={elem}>
                    {elem}
                  </option>
                ))}
              </select>

              <span className="field-border field-border-add-form"></span>
            </div>
            <div className="field">
              <span className="field-title">{`Associated Package Type`}</span>
              <select
                onChange={(e) =>
                  setcommonBody((prevState: any) => ({
                    ...prevState,
                    associated_package_type: e.target.value,
                  }))
                }
                value={commonBody?.associated_package_type}
                className="wrapper-info wrapper-info-select-dropdown"
                style={{
                  border: "none",
                  outline: "none",
                  height: "fit-content",
                  backgroundColor: "white",
                  // width: "256px",
                }}
              >
                <option value="">Please Select...</option>
                {["HCP", "NDIS", "Private"]?.map((elem: any, index: any) => (
                  <option key={index} value={elem}>
                    {elem}
                  </option>
                ))}
              </select>

              <span className="field-border field-border-add-form"></span>
            </div>
            <div className="service_type_rate_flex">
              <input
                className="wrapper-info tax_rate_input"
                onChange={(e) =>
                  setcommonBody((prevState: any) => ({
                    ...prevState,
                    rate: e.target.value,
                  }))
                }
                value={commonBody?.rate}
                name="tax_rate"
                placeholder={"Please Enter Rate "}
                style={{
                  border: "none",
                  outline: "none",
                  height: "fit-content",
                }}
              />

              <select
                className="wrapper-info wrapper-info-select-dropdown"
                onChange={(e) =>
                  setcommonBody((prevState: any) => ({
                    ...prevState,
                    rate_type: e.target.value,
                  }))
                }
                value={commonBody?.rate_type}
                style={{
                  border: "none",
                  outline: "none",
                  height: "fit-content",
                  backgroundColor: "white",
                  marginTop: "7px",
                }}
              >
                <option value="">Please Select Rate Type...</option>
                {["Fixed", "Percent"]?.map((elem: any, index: any) => (
                  <option key={index} value={elem}>
                    {elem}
                  </option>
                ))}
              </select>
            </div>
            <span className="field-border field-border-add-form span_under_rate"></span>
          </div>

          <div className="bottomBlock save_btn_expense">
            <button
              className="save-btn"
              id="saveBTN"
              // onClick={() => {
              //   handleAddForm();
              //   setDisibleSaveBTN(true);
              // }}
            >
              {IsLoading ? "Loading..." : "Save"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExpenseForm;
