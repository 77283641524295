import "./CircularNoteTrigger.css";
import React from "react";
import { ClientStatusCircleXxlSvg } from "../../../Model/Assets/SVG/WidgetVillage/ClientStatusCircleSvg";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../Controller/redux/Hooks";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Fragment, useEffect, useState } from "react";
import StickyNoteHub from "../StickyNote/StickyNoteHub/StickyNoteHub";
import {
  resetButtonTypeSticky,
  resetProfileTypeSticky,
  setButtonTypeSticky,
  setShowStickyNote,
} from "../../../Controller/redux/features/CommonFeature/commonSlice";
import { logoutThunk } from "../../../Controller/redux/features/AuthFeature/authSlice";
import {
  resetLocationFilterItems,
  resetSearchValueLocation,
  resetSelectedDropdownItemLocation,
  resetSelectedFilterItemsLocation,
  resetSelectedFilterOptionsLocation,
  resetSelectedItemIndexFilterLocation,
  resetSelectedItemIndexSortLocation,
  resetSortByLocationR,
  resetTypeAssociation,
} from "../../../Controller/redux/features/LocationFeature/locationSlice";
import {
  getUnreadMessagesCommsThunk,
  resetAllCommsData,
  resetAllCommsPage,
  resetCommsDetailData,
  resetDetailCommsPage,
} from "../../../Controller/redux/features/CommsFeature/commsSlice";
import {
  resetProspectFilterItems,
  resetSelectedDropdownItemProspect,
  resetSelectedFilterItemsProspect,
  resetSelectedItemIndexFilterProspect,
  resetSelectedItemIndexSortProspect,
  resetSelectedProspects,
  resetSortByProspect,
  resetsearchvalProspects,
  resetselectedSortValuesProspects,
} from "../../../Controller/redux/features/ProspectsFeature/prospectsSlice";
import {
  resetProvidersFilterItems,
  resetSelectedDropdownItemProviders,
  resetSelectedFilterItemsProviders,
  resetSelectedItemIndexFilterProviders,
  resetSelectedItemIndexSortProviders,
  resetinputSearchValProviders,
  resetselectedFilterOptionsProviders,
  resetsortByProviderR,
} from "../../../Controller/redux/features/ProviderFeature/providerSlice";
import {
  resetBusinessFilterItems,
  resetSearchValueBusiness,
  resetSelectedDropdownItemBusiness,
  resetSelectedFilterItemsBusiness,
  resetSelectedFilterOptionsBusiness,
  resetSelectedItemIndexFilterBusiness,
  resetSelectedItemIndexSortBusiness,
  resetSortByBusinessR,
} from "../../../Controller/redux/features/BusinessFeature/businessSlice";
import {
  resetSearchValueTeams,
  resetSelectedDropdownItemTeams,
  resetSelectedFilterItemsTeams,
  resetSelectedItemIndexFilterTeams,
  resetSelectedItemIndexSortTeams,
  resetSortByTeamsR,
  resetTeamsFilterItems,
} from "../../../Controller/redux/features/TeamsFeature/teamsSlice";
import {
  resetContactFilterItems,
  resetSelectedDropdownItemContact,
  resetSelectedFilterItemsContact,
  resetSelectedItemIndexFilterContact,
  resetSelectedItemIndexSortContact,
  resetSortByContacts,
  resetsearchValueContacts,
} from "../../../Controller/redux/features/ContactsFeature/contactsSlice";
import {
  resetFilterOptions,
  resetSearchValueStaff,
  resetSortOptions,
  resetStaffFilterItems,
  resetisStaffFilterDropdownOpen,
} from "../../../Controller/redux/features/StaffFeature/staffSlice";
import {
  resetClientFilterItems,
  resetsearchValueClients,
  resetselectedDropdownItemClients,
  resetselectedFilterItemsClients,
  resetselectedItemIndexFilterClients,
  resetselectedItemIndexSortClients,
  resetsortbyClients,
} from "../../../Controller/redux/features/ClientFeature/clientsSlice";
import {
  resetsearchValueMeals,
  resetselectedDropdownItemMeals,
  resetselectedFilterItemsMeals,
  resetselectedItemIndexFilterMeals,
  resetselectedItemIndexSortMeals,
  resetsortbyMeals,
} from "../../../Controller/redux/features/MealsFeatures/MealFeature/mealbuilderSlice";
import {
  resetExpenseTypeFilterItems,
  resetSearchValueExpenseType,
  resetSelectedDropdownItemExpenseType,
  resetSelectedFilterItemsExpenseType,
  resetSelectedItemIndexFilterExpenseType,
  resetSelectedItemIndexSortExpenseType,
  resetSortByExpenseTypeR,
} from "../../../Controller/redux/features/RefDataFeature/ExpenseTypeFeature/expensetypeSlice";
import {
  resetSearchValueServiceType,
  resetSelectedDropdownItemServiceType,
  resetSelectedFilterItemsServiceType,
  resetSelectedItemIndexFilterServiceType,
  resetSelectedItemIndexSortServiceType,
  resetServiceTypeFilterItems,
  resetSortByServiceTypeR,
} from "../../../Controller/redux/features/RefDataFeature/ServiceTypeFeature/serviceTypeSlice";
import {
  resetBillingRuleFilterItems,
  resetSearchValueBillingRule,
  resetSelectedDropdownItemBillingRule,
  resetSelectedFilterItemsBillingRule,
  resetSelectedItemIndexFilterBillingRule,
  resetSelectedItemIndexSortBillingRule,
  resetSortByBillingRuleR,
} from "../../../Controller/redux/features/RefDataFeature/BillingRuleFeature/billingruleSlice";
import {
  resetPayRuleFilterItems,
  resetSearchValuePayRule,
  resetSelectedDropdownItemPayRule,
  resetSelectedFilterItemsPayRule,
  resetSelectedItemIndexFilterPayRule,
  resetSelectedItemIndexSortPayRule,
  resetSortByPayRuleR,
} from "../../../Controller/redux/features/RefDataFeature/PayRuleFeature/payruleSlice";
import CommentWithNumberSVG from "../../../Model/Assets/SVG/Common/CommentWithNumberSVG";
import {current} from "@reduxjs/toolkit";
import {resetcurrentEditCustomForm} from "../../../Controller/redux/features/CustomFromFeature/customFormSlice";
import { resetCurrentEditVendor } from "../../../Controller/redux/features/VendorFeature/VendorSlice";
import {resetCurrentEditReportsHub} from "../../../Controller/redux/features/ReportsHubFeature/ReportsHubSlice";

const CircularNoteTrigger = ({
  type,
  mainType,
  title,
  isFinanceScreen,
  isOutside,
  setIsSubTaskOpen = () => {},
  setcurrentEditCustomForm = () => {},
  isCommsActive,
  extraType
}: any) => {

  const { dynamicColor } = useAppSelector((state) => state?.common);
  const { currentEditCustomForm } = useAppSelector((state) => state.customForm);

  const navigate = useNavigate();
  const location = useLocation()?.pathname;

  const id: any = useParams()?.id;

  const [clientId, setClientId] = useState("");
  const [isStickyNoteOpen, setIsStickyNoteOpen] = useState<boolean>(false);

  const { showStickyNote } = useAppSelector((state) => state?.common);
  // const [data, setData] = useState<any>(null);
  const { getUnreadMessages } = useAppSelector((state) => state?.comms);
  let data = getUnreadMessages[0];

  useEffect(() => {
    if (!showStickyNote) {
      dispatch(resetTypeAssociation());
      setcurrentEditCustomForm(false);
      dispatch(resetcurrentEditCustomForm());
      dispatch(resetCurrentEditVendor());
      dispatch(resetCurrentEditReportsHub());

    }
  }, [showStickyNote]);

  const { user } = useAppSelector((state) => state?.common);

  const userId = user?.id;

  useEffect(() => {
    dispatch(getUnreadMessagesCommsThunk(userId));
  }, [userId]);

  const { getCommsUnreadMessages } = useAppSelector((state) => state?.comms);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (id) {
      let realId = window.atob(id);
      setClientId(realId);
    }
  }, [id]);
  useEffect(() => {
    dispatch(setShowStickyNote(false));
  }, [navigate]);
  useEffect(() => {
    dispatch(resetAllCommsPage());
    dispatch(resetAllCommsData());
    dispatch(resetCommsDetailData());
    dispatch(resetDetailCommsPage());
  }, [navigate]);
  const openMealBuilderForm = useAppSelector(
    (state) => state.mealBuilder.openMealBuilderForm
  );
  const [isMealFormOpen, setIsMealFormOpen] = useState(false);
  const [viewWholeUserDetails, setViewWholeUserDetails] = useState(false);

  useEffect(() => {
    if (openMealBuilderForm) {
      setIsMealFormOpen(true);
    }
  }, [openMealBuilderForm]);

  const handleLogout = async (e: any) => {
    e.stopPropagation();
    dispatch(setShowStickyNote(false));
    await dispatch(logoutThunk());
    localStorage.clear();
  };
  useEffect(() => {
    if (showStickyNote) {
      setViewWholeUserDetails(false);
    }
  }, [showStickyNote]);
  const {
    prospects_label,
    clients_label,
    business_label,
    locations_label,
    teams_label,
    contacts_label,
    staff_label,
  } = useAppSelector((state) => state?.common?.domainDetail);

  if (
    type === prospects_label ||
    type === clients_label ||
    type === business_label ||
    type === locations_label ||
    type === teams_label ||
    type === contacts_label ||
    type === staff_label
  ) {
    dispatch(resetButtonTypeSticky());
  } else {
    dispatch(resetProfileTypeSticky());
  }
  const activeTab = localStorage.getItem("activeTab");

  useEffect(() => {
    if (activeTab === "Associations") {
      dispatch(setButtonTypeSticky("Asso"));
    } else if (activeTab === "Clinical") {
      dispatch(setButtonTypeSticky("Obs"));
    } else if (activeTab === "Comms") {
      dispatch(setButtonTypeSticky("Comms"));
    } else if (activeTab === "Daybook") {
      // can be changed later to be daybook only to show same sticky note...
      dispatch(setButtonTypeSticky("Comms"));
    } else {
      dispatch(setButtonTypeSticky("Asso"));
    }
  }, [activeTab]);
  const isOtherPathname =
    location?.includes("daybook") ||
    location?.includes("comms") ||
    location?.includes("villages") ||
    location?.includes("rac") ||
    location?.includes("sil") ||
    location?.includes("sales") ||
    location?.includes("meals") ||
    location?.includes("audit") ||
    location === "/";
  useEffect(() => {
    if (isOtherPathname) {
      dispatch(resetButtonTypeSticky());
      dispatch(resetcurrentEditCustomForm());
    }
  }, [location, isOtherPathname]);

  useEffect(() => {
    if (showStickyNote && type == "expense") {
      dispatch(setButtonTypeSticky("Expense"));
    }
  }, [type, showStickyNote]);

  useEffect(() => {
    if (showStickyNote && type == "funding") {
      dispatch(setButtonTypeSticky("Funding"));
    }
  }, [type, showStickyNote]);
  useEffect(() => {
    if (showStickyNote && type == "billing") {
      dispatch(setButtonTypeSticky("Billing"));
    }
  }, [type, showStickyNote]);
  useEffect(() => {
    if (showStickyNote && type == "payrule") {
      dispatch(setButtonTypeSticky("PayRule"));
    }
  }, [type, showStickyNote]);

  useEffect(() => {
    if (showStickyNote && type == "Vendors") {
      dispatch(setButtonTypeSticky("Vendors"));
    }
  }, [type, showStickyNote]);

  useEffect(() => {
    if (showStickyNote && type == "Reports") {
      dispatch(setButtonTypeSticky("Reports"));
    }
  }, [type, showStickyNote]);

  useEffect(() => {
    dispatch(resetSearchValueLocation());
    dispatch(resetSelectedFilterOptionsLocation());
    dispatch(resetSelectedDropdownItemLocation());
    dispatch(resetSelectedItemIndexFilterLocation());
    dispatch(resetSelectedItemIndexSortLocation());
    dispatch(resetSortByLocationR());
    dispatch(resetSelectedFilterItemsLocation());
    dispatch(resetLocationFilterItems());
    dispatch(resetsearchvalProspects());
    dispatch(resetSelectedDropdownItemProspect());
    dispatch(resetSelectedItemIndexFilterProspect());
    dispatch(resetSelectedItemIndexSortProspect());
    dispatch(resetSortByProspect());
    dispatch(resetSelectedFilterItemsProspect());
    dispatch(resetProspectFilterItems());
    dispatch(resetSelectedProspects(""));
    dispatch(resetselectedSortValuesProspects(""));
    dispatch(resetsearchValueClients(""));
    dispatch(resetselectedDropdownItemClients());
    dispatch(resetselectedItemIndexFilterClients());
    dispatch(resetselectedItemIndexSortClients());
    dispatch(resetsortbyClients());
    dispatch(resetselectedFilterItemsClients());
    dispatch(resetClientFilterItems());
    dispatch(resetinputSearchValProviders(""));
    dispatch(resetselectedFilterOptionsProviders(""));
    dispatch(resetSelectedDropdownItemProviders());
    dispatch(resetSelectedItemIndexFilterProviders());
    dispatch(resetSelectedItemIndexSortProviders());
    dispatch(resetsortByProviderR());
    dispatch(resetSelectedFilterItemsProviders());
    dispatch(resetProvidersFilterItems());
    dispatch(resetSearchValueBusiness());
    dispatch(resetSelectedFilterOptionsBusiness());
    dispatch(resetSelectedDropdownItemBusiness());
    dispatch(resetSelectedItemIndexFilterBusiness());
    dispatch(resetSelectedItemIndexSortBusiness());
    dispatch(resetSortByBusinessR());
    dispatch(resetSelectedFilterItemsBusiness());
    dispatch(resetBusinessFilterItems());
    dispatch(resetSearchValueTeams());
    dispatch(resetSelectedDropdownItemTeams());
    dispatch(resetSelectedItemIndexFilterTeams());
    dispatch(resetSelectedItemIndexSortTeams());
    dispatch(resetSortByTeamsR());
    dispatch(resetSelectedFilterItemsTeams());
    dispatch(resetTeamsFilterItems());
    // Teams End
    // Contacts Start
    dispatch(resetsearchValueContacts());
    dispatch(resetSelectedDropdownItemContact());
    dispatch(resetSelectedItemIndexFilterContact());
    dispatch(resetSelectedItemIndexSortContact());
    dispatch(resetSortByContacts());
    dispatch(resetSelectedFilterItemsContact());
    dispatch(resetContactFilterItems());
    // Contacts End
    // Staff Start
    dispatch(resetSearchValueStaff());
    dispatch(resetFilterOptions());
    dispatch(resetSortOptions());
    dispatch(resetisStaffFilterDropdownOpen());
    dispatch(resetStaffFilterItems());
    // Staff End
    // Meals Start
    dispatch(resetsearchValueMeals(""));
    dispatch(resetselectedDropdownItemMeals());
    dispatch(resetselectedItemIndexFilterMeals());
    dispatch(resetselectedItemIndexSortMeals());
    dispatch(resetsortbyMeals());
    dispatch(resetselectedFilterItemsMeals());
    // Meals End
    // Expense Type Start
    dispatch(resetSearchValueExpenseType());
    dispatch(resetSelectedDropdownItemExpenseType());
    dispatch(resetSelectedItemIndexFilterExpenseType());
    dispatch(resetSelectedItemIndexSortExpenseType());
    dispatch(resetSortByExpenseTypeR());
    dispatch(resetSelectedFilterItemsExpenseType());
    dispatch(resetExpenseTypeFilterItems());
    // Expense Type End
    // Service Type Start
    dispatch(resetSearchValueServiceType());
    dispatch(resetSelectedDropdownItemServiceType());
    dispatch(resetSelectedItemIndexFilterServiceType());
    dispatch(resetSelectedItemIndexSortServiceType());
    dispatch(resetSortByServiceTypeR());
    dispatch(resetSelectedFilterItemsServiceType());
    dispatch(resetServiceTypeFilterItems());
    // Service Type End
    // Billing Type Start
    dispatch(resetSearchValueBillingRule());
    dispatch(resetSelectedDropdownItemBillingRule());
    dispatch(resetSelectedItemIndexFilterBillingRule());
    dispatch(resetSelectedItemIndexSortBillingRule());
    dispatch(resetSortByBillingRuleR());
    dispatch(resetSelectedFilterItemsBillingRule());
    dispatch(resetBillingRuleFilterItems());
    // Billing Type End
    // PayRule Type Start
    dispatch(resetSearchValuePayRule());
    dispatch(resetSelectedDropdownItemPayRule());
    dispatch(resetSelectedItemIndexFilterPayRule());
    dispatch(resetSelectedItemIndexSortPayRule());
    dispatch(resetSortByPayRuleR());
    dispatch(resetSelectedFilterItemsPayRule());
    dispatch(resetPayRuleFilterItems());
    // PayRule Type End
  }, [navigate]);

  return (
    <Fragment>
      <>
        <div
          onClick={(e) => {
            // setIsStickyNoteOpen(!isStickyNoteOpen);
            dispatch(setShowStickyNote(!showStickyNote));
          }}
          className="circular_note_wrapper circular_bottom_flex"
        >
          {!showStickyNote && (
            <div
              className={` ${
                viewWholeUserDetails ? "sticky-fade-in" : "fade_out"
              } circular_note_container_extended `}
              onMouseLeave={() => {
                setViewWholeUserDetails(false);
              }}
            >
              <button
                onClick={() =>
                  navigate(`/people/staff/${window.btoa(user?.id)}`)
                }
                className="circular_btn"
              >
                Profile
              </button>
              <button onClick={handleLogout} className="circular_btn">
                Logout
              </button>
              <div className="circular_note_container">
                <div
                  style={{
                    backgroundColor: dynamicColor,
                    border: `2px solid ${dynamicColor}`,
                  }}
                  className="circular_note_img"
                >
                  {user?.profile_image_s3 ? (
                    <img
                      src={user?.profile_image_s3 || ""}
                      alt="profile"
                      loading="lazy"
                    />
                  ) : (
                    <div className="initials__name">
                      {`${user?.first_name[0] || ""}  ${
                        user?.last_name[0] || ""
                      }`}
                    </div>
                  )}
                </div>
                <div className="circular_note_svg">
                  <ClientStatusCircleXxlSvg
                    count={getCommsUnreadMessages ?? 0}
                  />
                </div>
              </div>
            </div>
          )}
          {!showStickyNote && (
            <div
              className={` ${!viewWholeUserDetails ? "" : ""} `}
              // className={" circular_note_container"}
              onMouseEnter={() => {
                setViewWholeUserDetails(true);
              }}
            >
              <div
                style={{
                  backgroundColor: dynamicColor,
                  border: `2px solid ${dynamicColor}`,
                }}
                className="circular_note_img"
              >
                {user?.profile_image_s3 ? (
                  <img src={user?.profile_image_s3} alt="profile" />
                ) : (
                  <div className="initials__name">
                    {`${user?.first_name[0] || ""}  ${
                      user?.last_name[0] || ""
                    }`}
                  </div>
                )}
              </div>
              <div className="circular_note_svg">
                <CommentWithNumberSVG
                  number={data?.data?.unread_messages || 0}
                  fill={data?.data?.traffic_light || "Green"}
                />
                {/* <ClientStatusCircleXxlSvg count={getCommsUnreadMessages ?? 0} /> */}
              </div>
            </div>
          )}
        </div>
        {(showStickyNote || currentEditCustomForm ) && (
          <div> 
           <StickyNoteHub
            setIsStickyNoteOpen={setIsStickyNoteOpen}
            isStickyNoteOpen={isStickyNoteOpen}
            extraType={extraType}
            setcurrentEditCustomForm={setcurrentEditCustomForm}
            type={type}
            mainType={mainType}
            isOutside={isOutside}
            title={title}
            isFinanceScreen={isFinanceScreen}
            setIsSubTaskOpen={setIsSubTaskOpen}
            isCommsActive={isCommsActive}
          />
          </div>
         
        )}
      </>
    </Fragment>
  );
};

export default React.memo(CircularNoteTrigger);
