import "../../../../View/pages/Daybook/Daybook.css";
import React, { useEffect, useRef, useState } from "react";
import ClientDetailsSticky from "../StickyNoteHub/Components/ClientDetailsSticky";
import CommonTable from "../../CommonTable/InputTable";
import OtherForm from "./OtherForm";
import {
  AssignedToSelect,
  DateStartEndTime,
  TagBookSelect,
} from "../../../pages/Daybook/Daybook";
import TrafficLights from "../../CommonModal/Components/TrafficLights";
import GreenColorSVG from "../../../pages/Comms/Data/GreenColorSVG";
import DaybookSticky from "../StickyNoteHub/Components/DaybookSticky";
import ProspectDetailsSticky from "../StickyNoteHub/Components/ProspectDetailsSticky";
import ContactDetailsSticky from "../StickyNoteHub/Components/ContactDetailsSticky";
import StaffDetailsSticky from "../StickyNoteHub/Components/StaffDetailsSticky";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../Controller/redux/Hooks";
import Table from "../../CommonTable/Table";
import { resetProfileTypeSticky } from "../../../../Controller/redux/features/CommonFeature/commonSlice";
import CustomDatePicker from "../../DatePicker/CustomDatePicker";

const DaybookForm = ({
  mobileScreenStickyNoteCurrentSlide,
  currentSelectedBTN,
  // trafficColors,
  // setShowLights,
  // showLights,
  // setTrafficColors,
  dayBookDetails,
  extraType,
  setDayBookDetails,
  startTime,
  endTime,
  setStartTime,
  setEndTime,
  editorState,
  handleEditorStateChange,
  clientsDataForAssignedTo,
  clientsDataForTaggedUser,
  onSavetrigger,
  setDaybookDate,
  daybookDate,
  setcurrentSelectedBTN,
  clientDataForDayBook,
  handleClickClientRow,
  staffDataDayBook,
  prospectsData,
  contactsData,
  clientDetails,
  //
  currentStaff,
  currentContact,
  currentProspects,
  clientStickyColumnArr,
  // dropdownRefClients,
  isDropdownOpenClients,
  isSmall,
}: // svgParentRefClients,
any) => {
  const dispatch = useAppDispatch();
  const dropdownRefClients = useRef<any>();
  const svgParentRefClients = useRef<any>();
  const { domainDetail } = useAppSelector((state) => state?.common);
  const { filterIconColor } = useAppSelector((state) => state?.common);
  const {
    selectedFilterOptionsContact,
    selectedSortOptionsContact,
    searchValContacts,
  } = useAppSelector((state) => state?.contacts);
  const { selectedFilterItems, sortbyClients, searchValueClients } =
    useAppSelector((state) => state?.people);
  const { selectedProspects, selectedSortValuesProspects, searchValProspects } =
    useAppSelector((state) => state?.prospects);
  const { selectedFilterOptions, selectedSortOptions, searchValueStaff } =
    useAppSelector((state) => state?.staff);

    
  const [showLights, setShowLights] = useState<boolean>(false);
  const [trafficColors, setTrafficColors] = useState<any>("");

  useEffect(() => {
    // console.log("useeffect OF DAYBOOK FORM:: , isSmall: ", isSmall);
    dispatch(resetProfileTypeSticky());
    // setSelectedSortDateCustom(null)
  }, []);

 console.log('extraType', extraType);
  return (
    <>
      {mobileScreenStickyNoteCurrentSlide == "Daybook" && (
        <>
          {currentSelectedBTN == "New" && (
            <div className={extraType == "globalCommsBox" ? "daybook_wrapper_globalCommsBox": "day_book_wrapper_sticky"}>
              <div className="day_book_wrapper_sticky_left_section">
                <div className="side_bar_section">
                  {[...Array(3)].map((_, mapIndex) => (
                    <div className="left-dots" key={mapIndex} />
                  ))}
                </div>
                <GreenColorSVG
                  color={trafficColors ? trafficColors : "#2AD400"}
                  onClick={() => setShowLights(!showLights)}
                  className="sticky_daybook_green_dot"
                />
              </div>
              {showLights && (
                <TrafficLights
                  className="daybook_sticky_clinical_note_taffic_light"
                  isSmall={isSmall}
                  setShowLights={setShowLights}
                  handleTrafficColors={(data: any) => {
                    setTrafficColors(data);
                    setShowLights(false);
                  }}
                />
              )}
              <div className="form_wrapper_daybook_sticky_notes">
                <div className="title_header_wrapper">
                  <h1>New Task</h1>
                </div>
                <div className=" date_picker_satrt_end_time_wrapper">
                  {/* <ReactDatePicker
                    // @ts-ignore
                    // value={dayBookTask?.date}
                    placeholderText="Select Date"
                    selected={dayBookDetails?.date}
                    showYearDropdown
                    showMonthDropdown
                    dateFormat={"dd MMM yyyy"}
                    className="date_Picker"
                    onChange={(date: any) => {
                      setDayBookDetails({
                        ...dayBookDetails,
                        date: date,
                      });
                    }}
                  /> */}
                  <CustomDatePicker
                    handleChange={(date: any) => {
                      setDayBookDetails({
                        ...dayBookDetails,
                        date: date,
                      });
                    }}
                    newDate={dayBookDetails?.date}
                  />
                  <DateStartEndTime
                    startTimeValue={startTime}
                    endTimeValue={endTime}
                    onStartTimeChange={(time: any) => {
                      setStartTime(time);
                    }}
                    onEndTimeChange={(time: any) => {
                      setEndTime(time);
                    }}
                  />
                  {/* <div className="start_end_time_wrapper">
                    </div> */}
                </div>
                <div className="description_wrapper_sticky_notes">
                  <input
                    className="description_heading_input_sticky"
                    type="text"
                    // value={dayBookTask.title}
                    value={dayBookDetails?.title}
                    placeholder="Title"
                    onChange={({ target }) => {
                      setDayBookDetails({
                        ...dayBookDetails,
                        title: target?.value,
                      });
                    }}
                  />
                  <div className="description_sub_heading_input_wrapper">
                    <span className="description_sub_heading_input_wrapper_placeholder">
                      Summary:
                    </span>
                    <input
                      className="description_sub_heading_input"
                      type="text"
                      value={dayBookDetails?.summary}
                      onChange={({ target }) => {
                        setDayBookDetails({
                          ...dayBookDetails,
                          summary: target?.value,
                        });
                      }}
                    />
                  </div>
                  {/* <div className="description_description_daybook_wrapper">
                    <div className="description_description_daybook_wrapper_placeholder">
                      Comms:
                    </div>
                    <Editor
                      // ref={editorRef}
                      // wrapperClassName="wrapper-class"
                      editorClassName={`editor-class-daybook daybook_height_fixing `}
                      editorState={editorState}
                      onEditorStateChange={handleEditorStateChange}
                      editorStyle={{ fontSize: 12 }}
                      toolbar={{
                        options: ["inline"],
                        inline: {
                          options: ["bold", "italic", "underline"],
                        },
                      }}
                      // placeholder="Send Comms:"
                    />
                  </div> */}
                  <div
                    className="description-description-tagged-people-wrapper"
                    style={{
                      marginBottom: 8,
                    }}
                  >
                    <AssignedToSelect
                      taggedPeopleIDs={dayBookDetails?.assigned_to}
                      taggedPeopleList={clientsDataForAssignedTo}
                      addTaggedPeople={(data: any) => {
                        setDayBookDetails({
                          ...dayBookDetails,
                          assigned_to: data,
                        });
                      }}
                      type={"stickynote"}
                    />
                  </div>
                  <div className="description-description-tagged-people-wrapper">
                    <TagBookSelect
                      taggedPeopleIDs={dayBookDetails?.tagged_user}
                      taggedPeopleList={clientsDataForTaggedUser}
                      addTaggedPeople={(data: any) => {
                        setDayBookDetails({
                          ...dayBookDetails,
                          tagged_user: data,
                        });
                      }}
                      type={"stickynote"}
                    />
                  </div>
                  <div className="save_btn_wrapper">
                    <button
                      className="save_btn_sticky_daybook"
                      onClick={() => {
                        onSavetrigger();
                      }}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          {currentSelectedBTN == "All" && (
            <div className="day_book_wrapper_sticky">
              <DaybookSticky
                customDate={daybookDate}
                onChangeTab={() => setDaybookDate(null)}
              />
            </div>
          )}
        </>
      )}

      {mobileScreenStickyNoteCurrentSlide == "Client" && (
        <>
          {currentSelectedBTN == "New" && (
            <OtherForm
              type={"clients"}
              // setShowStickyNote={() => {
              //   setcurrentSelectedBTN("All");
              // }}
            />
          )}
          {currentSelectedBTN == "All" && (
            <div className="day_book_wrapper_sticky">
              {/* <CommonTable
                        type="stickyClients"
                         allClients={clientDataForDayBook}
                        onClickRow={handleClickClientRow}
                      /> */}
              <div className="sticky_clients_table_wrapper">
                <Table
                  dynamicHeaderColor={domainDetail?.listingtbl_color}
                  thData={clientStickyColumnArr}
                  statusType="clientScreen"
                  classNameTr="sticky_clients_table_head"
                  classNameTable="sticky_clients_table"
                  type="clients"
                  dropdownRef={dropdownRefClients}
                  isDropdownOpen={isDropdownOpenClients}
                  onClickSvg={() => {
                    // setIsDropdownOpenClients(true);
                  }}
                  svgParentRef={svgParentRefClients}
                  allClients={clientDataForDayBook}
                  onClickRow={(e: any, id: any) => {
                    handleClickClientRow(e, id, "Client");
                  }}
                  classNameRow="sticky_clients_body_tr"
                  classNameBody={"sticky_clients_model_body"}
                  fill={
                    selectedFilterItems?.length > 0 ||
                    searchValueClients?.length > 0 ||
                    sortbyClients?.length > 0
                      ? filterIconColor
                      : "#D8D3D3"
                  }
                />
              </div>
            </div>
          )}
        </>
      )}

      {mobileScreenStickyNoteCurrentSlide == "Staff" && (
        <>
          {currentSelectedBTN == "New" && (
            <OtherForm
              type={"staff"}
              // setShowStickyNote={(res: any) => {
              //   setcurrentSelectedBTN("All");
              // }}
            />
          )}
          {currentSelectedBTN == "All" && (
            <div className="day_book_wrapper_sticky">
              {/* <CommonTable
                        type="stickyStaffs"
                        allClients={staffDataDayBook}
                      /> */}
              <div className="sticky_clients_table_wrapper">
                <Table
                  dynamicHeaderColor={domainDetail?.listingtbl_color}
                  thData={clientStickyColumnArr}
                  statusType="staffScreen"
                  classNameTr="sticky_clients_table_head"
                  classNameTable="sticky_clients_table"
                  type="staff"
                  dropdownRef={dropdownRefClients}
                  isDropdownOpen={isDropdownOpenClients}
                  onClickSvg={() => {
                    // setIsDropdownOpenClients(true);
                  }}
                  svgParentRef={svgParentRefClients}
                  allClients={staffDataDayBook}
                  onClickRow={(e: any, id: any) => {
                    handleClickClientRow(e, id, "Staff");
                  }}
                  classNameRow="sticky_clients_body_tr"
                  classNameBody={"sticky_clients_model_body"}
                  fill={
                    selectedFilterOptions.length > 0 ||
                    selectedSortOptions.length > 0 ||
                    searchValueStaff.length > 0
                      ? filterIconColor
                      : "#D8D3D3"
                  }
                />
              </div>
            </div>
          )}
        </>
      )}

      {mobileScreenStickyNoteCurrentSlide == "Prospect" && (
        <>
          {currentSelectedBTN == "New" && (
            <OtherForm
              type={"prospects"}
              // setShowStickyNote={(res: any) => {
              //   setcurrentSelectedBTN("All");
              // }}
            />
          )}
          {currentSelectedBTN == "All" && (
            <div className="day_book_wrapper_sticky">
              {/* <CommonTable
                        type="stickyProspects"
                        allClients={prospectsData}
                      /> */}
              <div className="sticky_clients_table_wrapper">
                <Table
                  dynamicHeaderColor={domainDetail?.listingtbl_color}
                  thData={clientStickyColumnArr}
                  statusType="prospectsScreen"
                  classNameTr="sticky_clients_table_head"
                  classNameTable="sticky_clients_table"
                  type="prospects"
                  dropdownRef={dropdownRefClients}
                  isDropdownOpen={isDropdownOpenClients}
                  onClickSvg={() => {
                    // setIsDropdownOpenClients(true);
                  }}
                  svgParentRef={svgParentRefClients}
                  allClients={prospectsData}
                  onClickRow={(e: any, id: any) => {
                    handleClickClientRow(e, id, "Prospect");
                  }}
                  classNameRow="sticky_clients_body_tr"
                  classNameBody={"sticky_clients_model_body"}
                  fill={
                    selectedProspects?.length > 0 ||
                    searchValProspects?.length > 0 ||
                    selectedSortValuesProspects?.length > 0
                      ? filterIconColor
                      : "#D8D3D3"
                  }
                />
              </div>
            </div>
          )}
        </>
      )}

      {mobileScreenStickyNoteCurrentSlide == "Contact" && (
        <>
          {currentSelectedBTN == "New" && (
            <OtherForm
              type={"contacts"}
              // setShowStickyNote={(res: any) => {
              //   setcurrentSelectedBTN("All");
              // }}
            />
          )}
          {currentSelectedBTN == "All" && (
            <div className="day_book_wrapper_sticky">
              {/* <CommonTable
                        type="stickyContacts"
                        allClients={contactsData}
                      /> */}
              <div className="sticky_clients_table_wrapper">
                <Table
                  dynamicHeaderColor={domainDetail?.listingtbl_color}
                  thData={clientStickyColumnArr}
                  statusType="contactsScreen"
                  classNameTr="sticky_clients_table_head"
                  classNameTable="sticky_clients_table"
                  type="contacts"
                  dropdownRef={dropdownRefClients}
                  isDropdownOpen={isDropdownOpenClients}
                  onClickSvg={() => {
                    // setIsDropdownOpenClients(true);
                  }}
                  svgParentRef={svgParentRefClients}
                  allClients={contactsData}
                  onClickRow={(e: any, id: any) => {
                    handleClickClientRow(e, id, "Contact");
                  }}
                  classNameRow="sticky_clients_body_tr"
                  classNameBody={"sticky_clients_model_body"}
                  fill={
                    selectedFilterOptionsContact.length > 0 ||
                    searchValContacts ||
                    selectedSortOptionsContact.length > 0
                      ? filterIconColor
                      : "#D8D3D3"
                  }
                />
              </div>
            </div>
          )}
        </>
      )}

      {mobileScreenStickyNoteCurrentSlide == "clientDetails" && (
        <>
          {currentSelectedBTN == "Details" && (
            <div className="day_book_wrapper_sticky">
              {clientDetails && (
                <ClientDetailsSticky currentClient={clientDetails} />
              )}
            </div>
          )}
        </>
      )}

      {mobileScreenStickyNoteCurrentSlide == "staffDetails" && (
        <>
          {currentSelectedBTN == "Details" && (
            <div className="day_book_wrapper_sticky">
              {currentStaff && (
                <StaffDetailsSticky currentStaff={currentStaff} />
              )}
            </div>
          )}
        </>
      )}

      {mobileScreenStickyNoteCurrentSlide == "contactDetails" && (
        <>
          {currentSelectedBTN == "Details" && (
            <div className="day_book_wrapper_sticky">
              {currentContact && (
                <ContactDetailsSticky currentContact={currentContact} />
              )}
            </div>
          )}
        </>
      )}

      {mobileScreenStickyNoteCurrentSlide == "prospectDetails" && (
        <>
          {currentSelectedBTN == "Details" && (
            <div className="day_book_wrapper_sticky">
              {currentProspects && (
                <ProspectDetailsSticky currentProspects={currentProspects} />
              )}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default DaybookForm;
