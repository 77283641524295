import React from "react";

const DeleteSvg = ({ onClick, width = "16", height = "22" }: any) => {
  return (
    <div>
      {" "}
      <svg
        onClick={onClick}
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 8 10"
        fill="none"
      >
        <path
          d="M0.571429 8.88889C0.571429 9.5 1.08571 10 1.71429 10H6.28571C6.91429 10 7.42857 9.5 7.42857 8.88889V2.22222H0.571429V8.88889ZM8 0.555556H6L5.42857 0H2.57143L2 0.555556H0V1.66667H8V0.555556Z"
          fill="black"
        />
      </svg>
    </div>
  );
};

export default DeleteSvg;
