import './MobileCommsScreen.css'
import { Fragment, useEffect, useRef, useState } from "react";
import { MobileStickyHeader } from "./MobileCard";
import CapsuleWithNumber from "../../../pages/Comms/Component/CapsuleAndNumber";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../Controller/redux/Hooks";
import {
  getCommsDetailThunk,
  getCommsForUserThunk,
  getCommsFromUserTypeThunk,
  getUnreadMessagesCommsThunk,
  setAllCommsPage,
  setCommsIdAndType,
} from "../../../../Controller/redux/features/CommsFeature/commsSlice";
import ClinicalNote from "../../ClinicalProgress/Components/ClinicalNote";
import { Spinner } from "@chakra-ui/react";
interface CommsProps {
  type?: string;
  isUserType?: boolean;
}
import convertToHTML from "draftjs-to-html";
import {getAllClinicalNotes} from '../../../../Controller/redux/features/ClinicalFeature/clinicalSlice';

const InboxCard = ({ isUserType }: CommsProps) => {
  // const allCommsPageRef = useRef<any>(null);
  const [commType, setCommType] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [uniqueAllCommsData, setuniqueAllCommsData] = useState<any>([]);
  const [commsDetail, setCommsDetail] = useState<any>([]);
  const [selectedItem, setselectedItem] = useState(0);
  const [isSubTaskOpen, setIsSubTaskOpen] = useState<number>(-1);
  const dispatch = useAppDispatch();
  const [selectedItemsArr, setselectedItemsArr] = useState<any>([]);
  // @ts-ignore
  const dynamicClientId = parseInt(localStorage.getItem("dynamicClientId"));
  // @ts-ignore
  const dynamicProspectId = parseInt(localStorage.getItem("dynamicProspectId"));
  const [clientId, setClientId] = useState("");
  const { loading, allCommsPage }: any = useAppSelector(
    (state) => state?.comms
  );

  // @ts-ignore
  const user = JSON.parse(localStorage.getItem("user"));
  useEffect(() => {
    if (user) {
      setClientId(user?.data?.id);
    }
  }, [user]);
  let isUserTypeData = !isUserType
    ? [
        { title: "Inbox", type: "yellow" },
        { title: "Sent Items", type: "green" },
        { title: "Clients", type: "green" },
        { title: "Prospects", type: "green" },
      ]
    : [
        { title: " Inbox", type: "yellow" },
        { title: "Notes", type: "green" },
      ];

  const { getUnreadMessages, getClientUnreadMessages, getCommsUnreadMessages } =
    useAppSelector((state) => state?.comms);

  let sentItems = {
    key: "Sent Items",
    data: {
      unread_messages: 0,
      traffic_light: "Green",
    },
  };
  const [speadGetUnreadMessages, setspeadGetUnreadMessages] =
    useState<any>(null);
  useEffect(() => {
    if (getClientUnreadMessages || getUnreadMessages) {
      let speadGetUnreadMessages = isUserType
        ? [...getClientUnreadMessages].slice(
            0,
            [...getClientUnreadMessages].slice(
              0,
              getClientUnreadMessages.length
            ).length
          )
        : [...getUnreadMessages].slice(1, getUnreadMessages.length);
      speadGetUnreadMessages.splice(1, 0, sentItems);

      setspeadGetUnreadMessages(speadGetUnreadMessages);
    }
  }, [getClientUnreadMessages, getUnreadMessages]);

  return (
    <div style={{ width: "100%" }}>
      {isUserTypeData.map((elem: any, index: any) => {
        return (
          // <div className="mobile_all_inbox_list" key={index}>
          <>
            {index === 0}
            <CapsuleWithNumber
              isPlusIcon={true}
              isFromMobile={true}
              type={getCommsUnreadMessages === 0 ? "green" : "red"}
              number={
                // @ts-ignore
                speadGetUnreadMessages?.find(
                  (obj: { key: any }) =>
                    obj?.key?.toLowerCase() == elem?.title?.toLowerCase()
                )?.data?.unread_messages || 0
              }
              fill={
                speadGetUnreadMessages?.find(
                  (obj: { key: any }) =>
                    obj?.key?.toLowerCase() === elem?.title?.toLowerCase()
                )?.data?.traffic_light || "Green"
              }
              title={elem.title}
              isSelected={index === selectedItem}
              onClick={() => {
                setselectedItem(index);
                // setCommsDetail([]);
                setIsSubTaskOpen(0);
                setselectedItemsArr([]);
                setIsOpen(!isOpen);
                let type = elem.title;
                setCommType(type);
                // console.log("commType: type ", type);
                if (index === 0) {
                  dispatch(
                    getCommsForUserThunk({
                      clientId,
                      page: allCommsPage,
                      endpoint: "get_comms",
                      sortType: "",
                      search: "",
                      unread: "",
                    })
                  );
                } else if (index === 1) {
                  dispatch(
                    getCommsForUserThunk({
                      clientId,
                      page: allCommsPage,
                      endpoint: "get_comms_of_sent_items",
                      unread: "",
                      sortType: "",
                      search: "",
                    })
                  );
                } else if (index === 2) {
                  dispatch(
                    getCommsFromUserTypeThunk({
                      userType: dynamicProspectId,
                      search: "",
                      sortType: "",
                    })
                  );
                } else {
                  dispatch(
                    getCommsFromUserTypeThunk({
                      userType: dynamicClientId,
                      search: "",
                      sortType: "",
                    })
                  );
                }
              }}
            />

            {elem.title === commType && isOpen && <Card />}
          </>
          // </div>
        );
      })}
    </div>
  );
};

const Card = ({ commType }: any) => {
  const dispatch = useAppDispatch();
  const { getAllComms, loading, detailCommsPage, getCommsDetail }: any =
    useAppSelector((state) => state?.comms);
  const [isSubTaskOpen, setIsSubTaskOpen] = useState<number>(-1);
  const [uniqueAllCommsData, setuniqueAllCommsData] = useState<any>([]);
  // const { user } = useAppSelector((state) => state?.common);
  // const userId = user?.id;
  const [commsDetail, setCommsDetail] = useState<any>([]);
  const [isCommsDetailIndex, setisCommsDetailIndex] = useState(-1);
  const [clientId, setClientId] = useState("");

  const [trafficColors, setTrafficColors] = useState({});
  const uniqueCommsDetailData = getCommsDetail?.filter(
    (item: any, index: number, array: any) => {
      return array?.findIndex((obj: any) => obj.id === item.id) === index;
    }
  );

  // @ts-ignore
  const user = JSON.parse(localStorage.getItem("user"));
  useEffect(() => {
    if (user) {
      setClientId(user?.data?.id);
    }
  }, [user]);

  const userId = user?.data?.id;
  useEffect(() => {
    const index = isSubTaskOpen >= 0 ? isSubTaskOpen : -1;
    if (uniqueAllCommsData?.length > 0) {
      setIsSubTaskOpen(index);
      dispatch(
        getCommsDetailThunk({
          clientId: uniqueAllCommsData[index]?.link_uid,
          type: uniqueAllCommsData[index]?.comm_type,
          userId: clientId,
          page: detailCommsPage,
        })
      );
      dispatch(
        setCommsIdAndType({
          link_id: uniqueAllCommsData[index]?.link_uid,
          comm_type: uniqueAllCommsData[index]?.comm_type,
        })
      );
    }
    dispatch(getUnreadMessagesCommsThunk(userId));
  }, [getAllComms, isSubTaskOpen, clientId, detailCommsPage]);
  // console.log("use effect: ", commsDetail);
  const [commsDetailCurrentElement, setcommsDetailCurrentElement] =
    useState(null);
  useEffect(() => {
    if (getAllComms) {
      setuniqueAllCommsData(getAllComms);
    }
  }, [commType, getAllComms]);
  useEffect(() => {
    if (uniqueCommsDetailData?.length > 0) {
      setCommsDetail(uniqueCommsDetailData);
    }
  }, [getCommsDetail]);
  // Parse Converter to HTML Start///////
  function parseAndConvertToHTML(data: any) {
    function isJson(str: any) {
      try {
        JSON.parse(str);
        return true;
      } catch (e) {
        return false;
      }
    }
    if (!isJson(data)) {
      return "";
    }
    let jsonData = JSON?.parse(data);
    function removeHref(entityMap: any) {
      Object?.keys(entityMap).forEach((key) => {
        if (entityMap[key].type === "MENTION" && entityMap[key].data.url) {
          delete entityMap[key]?.data?.url;
        }
      });
      return entityMap;
    }

    jsonData.entityMap = removeHref(jsonData?.entityMap);
    function convertToHTML(blocks: any, entityMap: any) {
      let htmlContent = "";
      blocks?.forEach((block: any) => {
        let text = block?.text;
        block?.entityRanges?.forEach((range: any) => {
          const entity = entityMap[range?.key];
          if (entity && entity?.type === "MENTION") {
            const mentionText = text?.substring(
              range?.offset,
              range?.offset + range?.length
            );
            text = text?.replace(mentionText, `<a>${mentionText}</a>`);
          }
        });
        htmlContent += `<p>${text}</p>`;
      });
      return htmlContent;
    }
    return convertToHTML(jsonData?.blocks, jsonData?.entityMap);
  }

  let currentElem = uniqueAllCommsData.find(
    (_: any, index: number) => index === isSubTaskOpen
  );

  return (
    <div className="message_card_container">
      <div className="mobile_comms_pagination_area">
        {uniqueAllCommsData?.map((res: any, index: number) => {
          return (
            <>
              <Fragment key={index}>
                {isSubTaskOpen === index ? (
                  <>
                    <ClinicalNote
                      isFromMobile={true}
                      isComms={true}
                      clientId={userId}
                      isSubTaskOpen={isSubTaskOpen}
                      index={index}
                      uniqueAllCommsData={uniqueAllCommsData}
                      elem={res}
                      isFromCol1={true}
                      isExpanded={isSubTaskOpen === index}
                      noteTitle={"Message"}
                      clinicalGoalsNote={(() => {
                        switch (res.comm_type) {
                          case "COMM":
                            return res?.comm_title || "";
                          case "DAYBOOK":
                            return res.daybook_title || "";
                          case "DOCUMENT":
                            return res.document_title || "";
                          case "MEAL":
                            return res.meal_title || "";
                          default:
                            return "";
                        }
                      })()}
                    />
                  </>
                ) : (
                  <ClinicalNote
                    isComms={true}
                    index={index}
                    clientId={userId}
                    isFromCol1={true}
                    isSubTaskOpen={isSubTaskOpen}
                    setIsSubTaskOpen={setIsSubTaskOpen}
                    elem={res}
                    uniqueAllCommsData={uniqueAllCommsData}
                    noteTitle={"Message"}
                    clinicalGoalsNote={(() => {
                      switch (res.comm_type) {
                        case "COMM":
                          return res?.comm_title || "";
                        case "DAYBOOK":
                          return res.daybook_title || "";
                        case "DOCUMENT":
                          return res.document_title || "";
                        case "MEAL":
                          return res.meal_title || "";
                        default:
                          return "";
                      }
                    })()}
                  />
                )}
                {isSubTaskOpen === index && (
                  <div className="comms_record_all_wrapper_mobile">
                    {commsDetail?.map((elem: any, index: any) => {
                      // console.log(elem);
                      let dataObjectMessage = elem?.message;
                      let parsedRes;
                      try {
                        parsedRes = JSON.parse(dataObjectMessage);
                      } catch (error) {
                        console.error("Error parsing JSON:", error);
                        return null;
                      }

                      const htmlContent = convertToHTML(parsedRes);
                      // @ts-ignore
                      const color = trafficColors[index];
                      let isFirst = commsDetail[0];
                      return (
                        <div>
                          {isCommsDetailIndex === index ? (
                            <ClinicalNote
                              index={index}
                              noteTitle={"Reply"}
                              isComms={true}
                              elem={elem}
                              clientId={clientId}
                              setcommsDetailCurrentElement={
                                setcommsDetailCurrentElement
                              }
                              isExpanded={true}
                              isFirst={isFirst}
                              htmlContent={htmlContent}
                              setIsSubTaskOpen={setisCommsDetailIndex}
                              isCol2={true}
                              currentElem={currentElem}
                              color={color}
                            />
                          ) : (
                            <ClinicalNote
                              index={index}
                              elem={elem}
                              clientId={clientId}
                              isCol2={true}
                              noteTitle={"Reply"}
                              isFirst={isFirst}
                              htmlContent={htmlContent}
                              setIsSubTaskOpen={setisCommsDetailIndex}
                              currentElem={currentElem}
                              color={color}
                              isComms={true}
                            />
                          )}
                        </div>
                      );
                    })}
                  </div>
                )}
              </Fragment>
            </>
          );
        })}
      </div>
    </div>
  );
};


function MobileCommsScreen() {
  return (
  <>
  <MobileStickyHeader title="Your  Comms"/>
   <div className="mobile_comms">
    <InboxCard />
   </div>
  </>
  );
}

export default MobileCommsScreen;
