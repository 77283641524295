import React, { useEffect, useState, useRef } from "react";
import "./CustomDatePicker.css";
import ReactDatePicker from "react-datepicker";
import { formatDate } from "../../../Model/utils/Helper/formatDate";

const CustomDatePicker = ({
  newDate,
  minDate,
  format,
  maxDate,
  handleChange,
  type,
  newonFocus,
  newvalue,
  style,
  setisChange = () => {},
  setHasUnsavedChangesPopup = () => {},
  currentClient,setchanges = () => {}
}: any) => {

  const [openSwitch, setOpenswitch] = useState(false);
  const yearInputRef = useRef<HTMLInputElement>(null);
  const monthInputRef = useRef<HTMLInputElement>(null);
  const dateInputRef = useRef<HTMLInputElement>(null);

  const toggleSwitch = () => {
    setOpenswitch(!openSwitch);
  };

  const datepickerRef = useRef<HTMLDivElement>(null);
  const [change, setChange] = useState(false);

  useEffect(() => {
    const handleOutsideClick = (event: any) => {
      if (
        datepickerRef.current &&
        !datepickerRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
        setOpenswitch(false);
      }
    };
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  // console.log('newDate', newDate);
  const month31 = [1, 3, 5, 7, 8, 10, 12];
  const [isOpen, setIsOpen] = useState(type === "input" ? false : true);
  const [selectedDate, setSelectedDate] = useState<number | null>(
    newDate ? newDate.getDate() : null
  );
  const [selectedMonth, setSelectedMonth] = useState<number>(
    newDate ? newDate.getMonth() + 1 : new Date().getMonth() + 1 
  );

  const [selectedYear, setSelectedYear] = useState<number | null>(
    newDate ? newDate.getFullYear() : new Date().getFullYear()
  );

  useEffect(() => {
    if (newDate) {
      setSelectedDate(newDate.getDate());
      setSelectedMonth(newDate.getMonth() + 1);
      setSelectedYear(newDate.getFullYear());
    }
  }, [newDate]);

  const [focusedInput, setFocusedInput] = useState<string | null>(null);

  const handleEnterKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter") {
      setIsOpen(false);
      toggleSwitch();
    }
    if (event.key === "ArrowLeft") {
      const activeElement = document.activeElement as HTMLInputElement;
      if (activeElement === dateInputRef.current) {
        yearInputRef.current?.focus();
      } else if (activeElement === monthInputRef.current) {
        dateInputRef.current?.focus();
      } else if (activeElement === yearInputRef.current) {
        monthInputRef.current?.focus();
      }
    }
    if (event.key === "ArrowRight") {
      const activeElement = document.activeElement as HTMLInputElement;
      if (activeElement === dateInputRef.current) {
        monthInputRef.current?.focus();
      } else if (activeElement === monthInputRef.current) {
        yearInputRef.current?.focus();
      } else if (activeElement === yearInputRef.current) {
        dateInputRef.current?.focus();
      }
    }
  };
  useEffect(() => {
    if (minDate) {
      const date = new Date(minDate);
      setSelectedDate(date.getDate());
      setSelectedMonth(date.getMonth() + 1);
      setSelectedYear(date.getFullYear());
    }
  }, [minDate]);

  useEffect(() => {
    if (selectedDate && selectedMonth && selectedYear) {
      if (handleChange) {
        const newSelectedDate = new Date(selectedYear, selectedMonth - 1, selectedDate);
        handleChange(newSelectedDate);
      }
    }
  }, [selectedDate, selectedMonth, selectedYear]);

  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setchanges(true)

    // setHasUnsavedChangesPopup(true);
    const value = parseInt(event.target.value);
    if (!isNaN(value) && value >= 1 && value <= 31) {
      setSelectedDate(value);
    } else {
      setSelectedDate(null);
    }
  };

  const handleMonthChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // setHasUnsavedChangesPopup(true);
    setchanges(true)

    const value = parseInt(event.target.value);
    if (!isNaN(value) && value >= 1 && value <= 12) {
      setSelectedMonth(value);
    } else {
      setSelectedMonth(null);
    }
  };

  const handleYearChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // setHasUnsavedChangesPopup(true);
    setchanges(true)

    const value = parseInt(event.target.value);
    if (!isNaN(value) && value >= 1900 && value <= 2099) {
      setSelectedYear(value);
    } else {
      setSelectedYear(null);
    }
  };

  return (
    <>
      {openSwitch || type === "input" ? (
        <div className="" ref={datepickerRef}>
          <div
            className="selector_of_date"
            onClick={() => {
              setIsOpen(true);
            }}
            ref={datepickerRef}
          >
            <input
              type="number"
              name=""
              id=""
              className="num_field"
              placeholder="dd"
              value={selectedDate !== null ? selectedDate : ""}
              onChange={handleDateChange}
              onClick={() => setIsOpen(true)}
              onKeyDown={handleEnterKeyPress}
              onFocus={() => setFocusedInput("date")}
              ref={dateInputRef}
            />
            <input
              type="number"
              name=""
              id=""
              className="num_field"
              placeholder="mm"
              value={selectedMonth !== null ? String(selectedMonth).padStart(2, "0") : ""}
              onChange={handleMonthChange}
              onClick={() => setIsOpen(true)}
              onKeyDown={handleEnterKeyPress}
              onFocus={() => setFocusedInput("month")}
              ref={monthInputRef}
            />
            <input
              placeholder="yyyy"
              type="number"
              className="num_field year_field"
              value={selectedYear !== null ? String(selectedYear).padStart(4, "0") : ""}
              onChange={handleYearChange}
              onClick={() => setIsOpen(true)}
              onKeyDown={handleEnterKeyPress}
              onFocus={() => setFocusedInput("year")}
              ref={yearInputRef}
              maxLength={4}
            />

            {isOpen && (
              <div>
                <ReactDatePicker
                  selected={newDate}
                  value={ newDate?.toString()}
                  dateFormat={format ? format : "dd/MM/yyyy"}
                  minDate={minDate}
                  maxDate={maxDate}
                  onChange={(date: any) => {
                    setchanges(true)
                    handleChange(date);
                    setisChange(true);
                    setHasUnsavedChangesPopup(true);
                    setSelectedDate(date.getDate());
                    setSelectedMonth(date.getMonth() + 1);
                    setSelectedYear(date.getFullYear());
                    setIsOpen(false);
                    setOpenswitch(false);
                  }}
                  open={isOpen}
                  onFocus={newonFocus ? newonFocus : ""}
                />
              </div>
            )}
          </div>
        </div>
      ) : (
        <input
          type="text"
          onChange={() => {}}
          onClick={() => {
            setIsOpen(true);
            toggleSwitch();
            setChange(true);
          }}
          placeholder="Select Date"
          style={
            style
              ? {
                  color: "black",
                  textDecoration: "underline",
                  fontSize: "16px",
                  cursor: "pointer",
                }
              : {}
          }
          value={newDate ? formatDate(newDate) : "Select Date"}
        />
      )}
    </>
  );
};

export default CustomDatePicker;
