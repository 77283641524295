import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../../services/api";
import { getAllTags, getUserTypes } from "../../../services/staffApi";

const initialState: any = {
  getProfileTypeSticky: "",
  getButtonTypeSticky: "Asso",
  domainDetail: [],
  getUserTypes: [],
  getEntityTypes: [],
  isAudit: "",
  dynamicColor: "",
  saveBtnColor: "",
  statusDetailsData: [],
  deleteBtnColor: "",
  warningColor: "",
  successColor: "",
  failColor: "",
  filterIconColor: "",
  hasDaybook: "",
  hasVillages: "",
  dateFormat: "",
  hasRoster:"",
  allTags: [],
  hasHub: "",
  hasSil: "",
  hasClinicalFromURL: false,
  refetchCurrentUser: null,
  isModal: false,
  profilePicture: null,
  uploadedProfilePic: false,
  isImageLoading: false,
  showStickyNote: false,
  changeStickyForClinicalWhenMobileView: false,
  fromGetDomainDetails: [],
  user: JSON.parse(localStorage.getItem("user") || "{}")?.data,

  //Service Type Record
  allServiceTypeRecord: [],
  getCommDetailFull: [],

  // isDocumentIsOpen
  isDocumentIsOpen: false,
  getVendors: [],
  time: {
    startTime: "",
    endTime: ""
  }
};

export const selectUserTypes = (state: any) => state.common.getUserTypes;

export const getUserTypesThunk = createAsyncThunk(
  "common/fetchUserTypes",
  async () => {
    try {
      const response = await getUserTypes();
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
export const getEntityTypesThunk = createAsyncThunk(
  "common/fetchEntityTypes",
  async () => {
    try {
      const response = await api.get(`/entities/get_entity_types`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
export const getProviderDetailThunk = createAsyncThunk(
  "common/fetchProviderDetails",
  async () => {
    try {
      const response = await api.get(`/get_provider_details`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

// }

export const getCommDetailFullThunk = createAsyncThunk(
  "common/getCommDetailFullThunk",
  async ({ linkUUID, type, clientId }: any) => {
    try {
      const response = await api.get(
        `/communications/get_comm_details?page=1&limit=100000&link_uid=${linkUUID}&comm_type=${type}&user_id=${clientId}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getStatusDetailsThunk = createAsyncThunk(
  "common/fetchStatusDetails",
  async (statusNums: string | any) => {
    try {
      const response = await api.get(
        `/users/get_status_details?status=${statusNums}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);


export const getDashboardVendorsThunk = createAsyncThunk(
  "common/getDashboardVendorsThunk",
  async () => {
    try {
      const response = await api.get(
        `/vendors/get_dashboard_vendors`
      );
      return response.data.data;
    } catch (error) {
      throw error;
    }
  }
);


// /users/get_status_details?status=1,0,2

export const getDomainDetailsThunk = createAsyncThunk(
  "common/domainDetailsThunk",
  async () => {
    try {
      const response = await api.get(`/get_domain_details`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getAllTagsThunk = createAsyncThunk(
  "common/fetchAllTags",
  async () => {
    try {
      const response = await getAllTags();
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const uploadProfilePictureThunk = createAsyncThunk(
  "common/uploadProfilePicture",
  async ({ data }: any) => {
    try {
      const response = await api.post("/users/upload_picture", data);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
export const uploadProfilePictureEntityThunk = createAsyncThunk(
  "common/uploadProfilePictureEntity",
  async ({ data }: any) => {
    try {
      const response = await api.post("/entities/upload_picture", data);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const uploadFileThunk = createAsyncThunk(
  "common/uploadFile",
  async ({ data }: any) => {
    try {
      const response = await api.post(`/files/upload_files`, data);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
export const getFileThunk = createAsyncThunk(
  "common/getFile",
  async ({ path }: any) => {
    try {
      const response = await api.get(`files/get_file?file=${path}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getProfileDetailsThunk = createAsyncThunk(
  "common/getProfileDetailsThunk",
  async () => {
    try {
      const response = await api.get("user_profile");
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
export const updateUserPasswordThunk = createAsyncThunk(
  "common/updatePassword",
  async (passwordData: any, { rejectWithValue }) => {
    try {
      const response = await api.post("/change_password", passwordData);
      return response.data;
    } catch (error: string | any) {
      return rejectWithValue(error || "Failed to upload passwords");
    }
  }
);
export const updateTrafficLightThunk = createAsyncThunk(
  "common/updateTrafficLightThunk",
  async (trafficLightData: any, { rejectWithValue }) => {
    try {
      const response = await api.post(
        "/communications/update_traffic_light",
        trafficLightData
      );
      return response.data;
    } catch (error: string | any) {
      return rejectWithValue(error || "Failed to update traffic lig");
    }
  }
);
export const getServiceTypeThunk = createAsyncThunk(
  "service_types/fatchServiceType",
  async () => {
    try {
      const response = await api.get(
        "/service_types?paginate=true&page=1&limit=10000"
      );
      return response?.data?.data;
    } catch (error: string | any) {
      throw error;
    }
  }
);

export const linkFileThunk = createAsyncThunk(
  "common/link_file",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await api.post("/files/link_file", data);
      return response.data;
    } catch (error: string | any) {
      return rejectWithValue("Failed to link file");
    }
  }
);

const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    setTime: (state, action) => {
      state.time = { ...state.time, ...action.payload };
    },
    resetTime: (state) => {
      state.time = initialState.time;
    },
    setProfileTypeSticky: (state, action) => {
      state.getProfileTypeSticky = action.payload;
    },
    resetProfileTypeSticky: (state) => {
      state.getProfileTypeSticky = "";
    },
    setButtonTypeSticky: (state, action) => {
      state.getButtonTypeSticky = action.payload;
    },
    resetButtonTypeSticky: (state) => {
      state.getButtonTypeSticky = "";
    },
    setIsMealsFlag: (state, action) => {
      state.isMeals = action.payload;
    },
    setIsAuditFlag: (state, action) => {
      state.isAudit = action.payload;
    },
    setDynamicColor: (state, action) => {
      state.dynamicColor = action.payload;
    },
    setSaveBtnColor: (state, action) => {
      state.saveBtnColor = action.payload;
    },
    setDeleteBtnColor: (state, action) => {
      state.deleteBtnColor = action.payload;
    },
    setWarningColor: (state, action) => {
      state.warningColor = action.payload;
    },
    setSuccessColor: (state, action) => {
      state.successColor = action.payload;
    },
    setFailColor: (state, action) => {
      state.failColor = action.payload;
    },
    setFilterIconColor: (state, action) => {
      state.filterIconColor = action.payload;
    },
    setHasDaybook: (state, action) => {
      state.hasDaybook = action.payload;
    },
    setHasVillages: (state, action) => {
      state.hasVillages = action.payload;
    },
    setdateFormat: (state, action) => {
      state.dateFormat = action.payload;
    },
    sethasHub: (state, action) => {
      state.hasHub = action.payload;
    },
    setHasClinicalFromURL: (state) => {
      state.hasClinicalFromURL = true;
    },
    resetHasClinicalFromURL: (state) => {
      state.hasClinicalFromURL = false;
    },
    setisModal: (state, action: any) => {
      state.isModal = action.payload;
    },
    sethasRoster: (state, action) => {
      state.hasRoster = action.payload;
    },
    resethasRoster: (state, action) => {
      state.hasRoster = action.payload;
    },
    resetisModal: (state) => {
      state.isModal = false;
    },
    sethasSil: (state, action) => {
      state.hasSil = action.payload;
    },
    resethasSil: (state) => {
      state.hasSil = null;
    },
    setShowStickyNote: (state, action) => {
      state.showStickyNote = action.payload;
    },

    resetShowStickyNote: (state) => {
      state.showStickyNote = false;
    },
    setchangeStickyForClinicalWhenMobileView: (state, action) => {
      state.changeStickyForClinicalWhenMobileView = true;
    },
    resetchangeStickyForClinicalWhenMobileView: (state, action) => {
      state.changeStickyForClinicalWhenMobileView = false;
    },
    resetStatusDetails: (state) => {
      state.statusDetailsData = null;
    },
    setIsDocumentIsOpenOnCommsSticky: (state, action) => {
      state.isDocumentIsOpen = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserTypesThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUserTypesThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.getUserTypes = action.payload;
      })
      .addCase(getUserTypesThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(getFileThunk.pending, (state) => {
        state.isImageLoading = true;
      })
      .addCase(getFileThunk.fulfilled, (state, action) => {
        state.isImageLoading = false;
        state.profilePicture = action.payload;
      })
      .addCase(getFileThunk.rejected, (state, action) => {
        state.isImageLoading = false;
        state.error = action.error.message;
      })
      .addCase(getProfileDetailsThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProfileDetailsThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload.data;
        state.refetchCurrentUser = action.payload;
        localStorage.setItem("user", JSON.stringify(action.payload));
      })
      .addCase(getProfileDetailsThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getEntityTypesThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getEntityTypesThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.getEntityTypes = action.payload;
      })
      .addCase(getAllTagsThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllTagsThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.allTags = action.payload.data;
      })
      .addCase(getAllTagsThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(uploadProfilePictureThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(uploadProfilePictureThunk.fulfilled, (state, action) => {
        state.loading = false;
        // state.uploadedProfilePic = true;
      })
      .addCase(uploadProfilePictureThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(uploadProfilePictureEntityThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(uploadProfilePictureEntityThunk.fulfilled, (state, action) => {
        state.loading = false;
        // state.uploadedProfilePic = true;
      })
      .addCase(uploadProfilePictureEntityThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getProviderDetailThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCommDetailFullThunk.rejected, (state, action) => {
        state.loading = false;
        state.getCommDetailFull = action.payload;
      })
      .addCase(getProviderDetailThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.domainDetail = action.payload.data;
      })
      .addCase(getProviderDetailThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(getDomainDetailsThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDomainDetailsThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.fromGetDomainDetails = action.payload.data;
      })
      .addCase(getDomainDetailsThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getStatusDetailsThunk.fulfilled, (state, action) => {
        state.statusDetailsData = action.payload;
      })
      .addCase(getServiceTypeThunk.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getServiceTypeThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getServiceTypeThunk.fulfilled, (state, action) => {
        state.allServiceTypeRecord = action.payload;
        state.loading = false;
      })
      .addCase(getDashboardVendorsThunk.fulfilled, (state, action) => {
        state.getVendors = action.payload;
        state.loading = false;
      })
      .addCase(linkFileThunk.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(linkFileThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(linkFileThunk.fulfilled, (state, action) => {
        state.loading = false;
      });

    // getStatusDetailsThunk
  },
});

export const {
  setIsMealsFlag,
  setDynamicColor,
  setSaveBtnColor,
  setDeleteBtnColor,
  setWarningColor,
  setSuccessColor,
  setFailColor,
  setIsAuditFlag,
  setFilterIconColor,
  setHasDaybook,
  setHasVillages,
  setdateFormat,
  sethasHub,
  setHasClinicalFromURL,
  resetHasClinicalFromURL,
  setisModal,
  resetisModal,
  sethasSil,
  resethasSil,
  sethasRoster,
  resethasRoster,
  setShowStickyNote,
  setchangeStickyForClinicalWhenMobileView,
  resetchangeStickyForClinicalWhenMobileView,
  resetShowStickyNote,
  resetStatusDetails,
  setProfileTypeSticky,
  resetProfileTypeSticky,
  setButtonTypeSticky,
  resetButtonTypeSticky,
  setIsDocumentIsOpenOnCommsSticky,
  setTime,
  resetTime
} = commonSlice.actions;

export default commonSlice.reducer;
