import React from "react";
import { PolygonSVG } from "../CommonModal/Data/SVGs";
import CrossSVGTrafficLights from "./Data/CrossSVGTrafficLights";

const TriangularTrafficLights = ({onChangeTrafficLight}: any) => {
  return (
    <div
      style={{
        position: "absolute",
        background: "#E6E6E6",
        width: "31px",
        height: "98px",
        borderRadius: "13px",
        right: "-5px",
        display: "flex",
        flexDirection: "column",
        zIndex: 9,
        top:"0px"
      }}
    >
      <CrossSVGTrafficLights onChangeTrafficLight={onChangeTrafficLight}/>
      <span
        style={{
          zIndex: 9999,
        }}
      ></span>
    </div>
  );
};

export default TriangularTrafficLights;
