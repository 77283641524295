export function getDateForIntervals(inputDate: any) {
  const currentDate = new Date(inputDate);

  const weekly = new Date(currentDate);
  weekly.setDate(currentDate.getDate() + 7);

  const fortnightly = new Date(currentDate);
  fortnightly.setDate(currentDate.getDate() + 14);

  const monthly = new Date(currentDate);
  monthly.setMonth(currentDate.getMonth() + 1);

  const quarterly = new Date(currentDate);
  quarterly.setMonth(currentDate.getMonth() + 3);

  const halfYearly = new Date(currentDate);
  halfYearly.setMonth(currentDate.getMonth() + 6);

  const yearly = new Date(currentDate);
  yearly.setFullYear(currentDate.getFullYear() + 1);

  function formatDate(date: any) {
    const day = date.getDate();
    const month = date.getMonth() + 1; // Add 1 to get the correct month (0-based index)
    const year = date.getFullYear();
    return `${day.toString().padStart(2, "0")}-${month
      .toString()
      .padStart(2, "0")}-${year}`;
  }
  //   return `${year}-${month.toString().padStart(2, "0")}-${day
  //     .toString()
  //     .padStart(2, "0")}`;
  // }

  const formattedWeekly = formatDate(weekly);
  const formattedFortnightly = formatDate(fortnightly);
  const formattedMonthly = formatDate(monthly);
  const formattedQuarterly = formatDate(quarterly);
  const formattedHalfYearly = formatDate(halfYearly);
  const formattedYearly = formatDate(yearly);

  return {
    weekly: formattedWeekly,
    fortnightly: formattedFortnightly,
    monthly: formattedMonthly,
    quarterly: formattedQuarterly,
    halfYearly: formattedHalfYearly,
    yearly: formattedYearly,
  };
}
