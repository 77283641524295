import React, { useEffect, useState } from "react";
import CommentWithNumberSVG from "../../../Model/Assets/SVG/Common/CommentWithNumberSVG";
import PlusSVG from "../../pages/People/ClientPage/Components/UniqueClientScreen/Components/FinanceTab/Data/PlusSVG";
import "./Styles/CommonUpperBox.css";
import { getFileThunk } from "../../../Controller/redux/features/CommonFeature/commonSlice";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../Controller/redux/Hooks";
import { useLocation, useParams } from "react-router-dom";
import { getInitials } from "../../../Model/utils/Helper/getInitials";
import {GreenCircleSvg} from "../../pages/People/ClientPage/Components/UniqueClientScreen/Components/ClinicalTab/Data/SVGs";
import GreenColorSVG from "../../pages/Comms/Data/GreenColorSVG";

interface CommonUpperBoxProps {
  title?: string;
  onClickPlus?: any;
  onClickBasket?: any;
  OnClickYellow?: any;
  onClickUserImage?: any;
  imageSection?: boolean;
  imageURL?: string;
  child: any;
  text?: string;
  backgroundWithText?: boolean | any;
  type?: any;
  currentUser?: any;
  isMealBuilder?: any;
  isProvider?: any;
  status? : any;
}

const CommonUpperBox = ({
  title,
  onClickPlus,
  imageURL,
  onClickUserImage,
  child,
  backgroundWithText,
  text,
  type,
  currentUser,
  isMealBuilder,
  isProvider,
  status
}: CommonUpperBoxProps) => {
  const dispatch = useAppDispatch();
  const [image, setimage] = useState<any>("");

  const profilePictureRefetched = useAppSelector(
    (state) => state?.common?.user
  );
  let profilePictureCurrentUser = profilePictureRefetched?.profile_image_s3;

  const pathName = useLocation().pathname;
  const id = useParams()?.id;
  const [clientId, setclientId] = useState("");

  const { dynamicColor } = useAppSelector((state) => state?.common);

  useEffect(() => {
    if (id) {
      let realId = window.atob(id);
      setclientId(realId);
    }
  }, [id]);
  
  useEffect(() => {
    if (pathName.includes("my-daybook") || pathName.includes("comms")) {
      setimage(profilePictureCurrentUser);
    }
  }, [profilePictureRefetched]);

  useEffect(() => {
    const abortController = new AbortController();

    if (imageURL) {
      const path = imageURL;

      if (path && !path.includes("https")) {
        dispatch(getFileThunk({ path }), { signal: abortController.signal })
          .then((res: any) => {
            if (!abortController.signal.aborted) {
              setimage(res?.payload?.data);
            }
          })
          .catch((error: any) => {});
      } else if (path.includes("https")) {
        setimage(path);
      } else {
        setimage("");
      }
    }

    return () => {
      abortController.abort();
    };
  }, [imageURL]);

  return (
    <div className="common_header_wrapper">
      <div className={`common_header ${title === "Roster" ? "isRoster" : ""}`}>
        <div className="left_side_section_wrapper">
          {imageURL ? (
            <div className="image_with_comment_svg_wrapper">
              <img
                src={imageURL ? image : null}
                onClick={onClickUserImage}
                className={"common_header__img"}
                style={{
                  visibility: image ? "visible" : "hidden",
                }}
              />

              <CommentWithNumberSVG number={0} />
            </div>
          ) : type === "listing" ? null : type === "screen" && !image ? (
            <div
              className="initials__daybook"
              style={{
                visibility: imageURL ? "hidden" : "visible",
              }}
              onClick={onClickUserImage}
            >
              {isMealBuilder === "MB" ? (
                "MB"
              ) : isMealBuilder === "MR" ? (
                "MR"
              ) : isProvider ? (
                <div
                  className="initials__daybook"
                  style={{
                    visibility: imageURL ? "hidden" : "visible",
                  }}
                  onClick={onClickUserImage}
                >
                  {currentUser?.tenant_name.split(" ").length >= 2 &&
                  currentUser?.tenant_name !== null
                    ? `${currentUser?.tenant_name.split("")[0].charAt(0) || ""}
              
              ${currentUser?.tenant_name.split(" ")[1].charAt(0) || ""}
            `
                    : ` ${currentUser?.tenant_name.split("")[0].charAt(0) || ""}
            ${currentUser?.tenant_name.split("")[0].charAt(1) || ""}
            `}
                </div>
              ) : (
                <>
                  {currentUser?.last_name === null ? (
                    <div>{getInitials(currentUser?.first_name)}</div>
                  ) : (
                    <div className="meal_builder">
                      {currentUser?.first_name?.charAt(0)}
                      {currentUser?.last_name?.charAt(0)}
                    </div>
                  )}
                </>
              )}
            </div>
          ) : (
            <img
              src={image ? image : null}
              onClick={onClickUserImage}
              className={"common_header__img"}
            />
          )}
          {backgroundWithText && !image && (
            <span
              style={{
                backgroundColor: dynamicColor,
              }}
              className="common_upper_box_title"
            >
              {!image ? text : ""}
            </span>
          )}

          <h1
            className="common_header_name text-ellipsis "
            title={title ? title : ""}
          >
            {title ? title : ""}
          </h1>
        </div>
        <div className="common_header_options_svgs">
          <PlusSVG onClick={onClickPlus} />
        </div>
      </div>
      {child}
    </div>
  );
};

CommonUpperBox.defaultProps = {
  title: "",
  onClickPlus: () => {},
  onClickBasket: () => {},
  OnClickYellow: () => {},
  onClickUserImage: () => {},
  imageSection: false,
  imageURL: "",
  child: <></>,
  backgroundWithText: false,
  text: "",
};

export default CommonUpperBox;
