const SelectedFilterDropdown = ({
  options,
  tableObject,
  selectedItemIndex,
  handleClickColumn,
  clickType
}: any) => {
  return (
    <div>
      <div className="dropdown-2-box-sub">
        <div className="sub-dropdown-2">
          {options?.map((elem: any, index: any) => (
            <div
              key={index}
              className={`dropdown-2-item ${
                tableObject?.selectedItemIndex === index
                  ? "dropdown-2-item-selected"
                  : ""
              }`}
              onClick={() => handleClickColumn(index, clickType)}
            >
              <p>{elem}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SelectedFilterDropdown;
