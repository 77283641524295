import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../../services/api";

const initialState: any = {
  loading: false,
  error: null,
  customFormList: {},
  hasDeleteFrom: false,
  idOfFormToDelete: null,
  selectedActiveForm: null,
  FormTypePage: 1,
  currentEditCustomForm: null,
  // Tabs
  selectedActiveFormTabsArr: null,
  formTypeFilterItems: {
    // status: "1",
    filterColumn: "",
    filterValue: "",
    searchVal: "",
    sortColumn: "",
    sortType: "",
    formTypePage: 1,
  },
  lastPage: 100000,

  ExpessTypeData: [],
  // Filter Data Start
  getFormName: [],
  // Filter Data End
  //  // STATES OF SELECTED FILTER GOES HERE
  searchValForm: "",
  selectedDropdownItemForm: "",
  selectedItemIndexFilterForm: "",
  selectedItemIndexSortForm: "",
  sortByFormR: "",
  selectedFilterItemsForm: [],
  uniqueFormDetails: null,
  // End
  getFormType: [],
  customFormVersions: [],
  selectedTabStore: null,
  customFormAnswerVersions: [],
  getCustomFormList: [],
};

export const createCustomForm = createAsyncThunk(
  "customForm/createForm",
  async (tabInfo: any) => {
    try {
      const response = await api.post(
        `/custom_form/create_custom_form`,
        tabInfo
      );
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
);

export const cloneCustomFormThunk = createAsyncThunk(
  "customForm/cloneCustomForm",
  async (tabInfo: any, { rejectWithValue }) => {
    try {
      const response = await api.post(
        `/custom_form/clone_custom_form`,
        tabInfo
      );
      return response?.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data?.response);
    }
  }
);

export const updateCustomFormByIdThunk = createAsyncThunk(
  "customForm/updateForm",
  async (tabInfo: any, { rejectWithValue }) => {
    try {
      const response = await api.post(
        `/custom_form/update_custom_form`,
        tabInfo
      );
      return response?.data;
    } catch (error: any) {
      return rejectWithValue(error?.response);
    }
  }
);

export const getCustomFormsThunk = createAsyncThunk(
  "customForm/fetchForm",
  async (userId: number | string) => {
    try {
      const response = await api.get(
        `/custom_form/get_custom_forms?paginate=true&page=1&limit=1000&user_id=${userId}`
      );
      return response?.data?.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getCustomFormsByClinicalStatusThunk = createAsyncThunk(
  "customForm/fatchFormByClinical",
  async (clientId: any) => {
    try {
      const response = await api.get(
        `/custom_form/get_custom_forms?paginate=true&page=1&limit=10000&filter_column=form_type&filter_value=Clinical&user_id=${clientId}`
      );
      return response?.data?.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getCustomFormVersionsThunk = createAsyncThunk(
  "customForm/customFormVersion",
  async (customFormId: any) => {
    try {
      const response = await api.get(
        `/custom_form/get_custom_form_versions?custom_form_id=${customFormId}`
      );
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getCustomFormAnswersVersionsThunk = createAsyncThunk(
  "customForm/customFormAnswersVersions",
  async ({ customFormId, userId }: any) => {
    try {
      const response = await api.get(
        `/custom_form/getanswers_versions?custom_form_id=${customFormId}&user_id=${userId}`
      );
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
);

// custom_form/getcustomform_list

export const getCustomFormListThunk = createAsyncThunk(
  "form-type/getcustomformlist",
  async () => {
    try {
      const { data } = await api.get(`/custom_form/getcustomform_list`);
      return data;
    } catch (error) {
      throw error;
    }
  }
);

export const getFormNameThunk = createAsyncThunk(
  "form-type/fetchFormName",
  async (userId: any) => {
    try {
      const { data } = await api.get(
        `/custom_form/get_custom_forms?unique_column=form_name&user_id=${userId}`
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
);
export const getFormTypeThunk = createAsyncThunk(
  "form-type/fetchFormType",
  async (userId: any) => {
    try {
      const { data } = await api.get(
        `/custom_form/get_custom_forms?unique_column=form_type&user_id=${userId}`
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
);
export const linkCustomFormTabHeaderThunk = createAsyncThunk(
  "form-type/linkCustomFormTabHeaderThunk",
  async (params: any) => {
    try {
      const response = await api.post(`/custom_form/link_tab`, params);
      return response;
    } catch (error) {
      throw error;
    }
  }
);
// linkCustomFormTabHeaderThunk
export const getCustomFormsIdByThunk = createAsyncThunk(
  "customForm/fatchFormDetails",
  async ({ id, userId, documentId }: any) => {
    try {
      const response = await api.get(
        `/custom_form/get_custom_forms?paginate=true&page=1&limit=10000&id=${id}&user_id=${userId}&document_id=${documentId}`
      );
      return response?.data?.data?.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getCustomFormsIdPaginateTrueByThunk = createAsyncThunk(
  "customForm/fetchFormDetailsWithPaginateFalse",
  async ({ id, userId, documentId }: any) => {
    try {
      const response = await api.get(
        `/custom_form?custom_form_id=${id}&user_id=${userId}&document_id=${documentId}`
      );
      return response?.data?.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getCustomFormFromAnswerVersionNumberThunk = createAsyncThunk(
  "customForm/getCustomFormFromAnswerVersionNumber",
  async ({
    userId,
    documentId,
    customFormId,
    versionNumber,
  }: {
    userId: any;
    documentId: any;
    customFormId: any;
    versionNumber: any;
  }) => {
    try {
      const response = await api.get(
        `/custom_form?user_id=${userId}&document_id=${documentId}&custom_form_id=${customFormId}&answer_version=${versionNumber}`
      );
      return response?.data?.data;
    } catch (error) {
      throw error;
    }
  }
);

export const removeCustomFormByIdThunk = createAsyncThunk(
  "customForm/deleteForm",
  async (parmas: any) => {
    try {
      const response = await api.post(
        `/custom_form/delete_custom_form`,
        parmas
      );
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getCustomFormsTabsByIdThunk = createAsyncThunk(
  "customForm/fatchFormTabs",
  async ({ user_id, custom_form_id, document_id }: any) => {
    try {
      if (user_id) {
        const response = await api.get(
          `/custom_form?user_id=${user_id}&custom_form_id=${custom_form_id}&document_id=${document_id}`
        );
        return response?.data?.data;
      }
    } catch (error) {
      throw error;
    }
  }
);

export const publishCustomFormThunk = createAsyncThunk(
  "customForm/publish_custom_form",
  async (params: any) => {
    try {
      const response = await api.post(
        `/custom_form/publish_custom_form`,
        params
      );
      return response?.data;
    } catch (error: any) {
      throw error?.response?.data?.response;
    }
  }
);

export const getFormTypeByFilterThunk = createAsyncThunk(
  "customForm/fetchFormTypeByFilter",
  async ({
    filterColumn,
    filterValue,
    searchVal,
    sortColumn,
    sortType,
    // status,
    formTypePage,
    userId,
  }: {
    filterColumn: string | any;
    filterValue: string | any;
    searchVal: string | any;
    sortColumn: string | any;
    sortType: string | any;
    // status: string | any;
    formTypePage: string | any;
    userId: any;
  }) => {
    const encodedFilterValue = encodeURIComponent(filterValue);

    try {
      const response = await api.get(
        `/custom_form/get_custom_forms?paginate=true&page=1&limit=1000000&search=${searchVal}&sort_column=${sortColumn}&sort_type=${sortType}&filter_column=${filterColumn}&filter_value=${encodedFilterValue}&user_id=${userId}`
      );
      return response?.data?.data;
    } catch (error) {
      throw error;
    }
  }
);

export const updateTabsSequence = createAsyncThunk(
  "customForm/updateQuestionsSequence",
  async (parmas: any) => {
    try {
      const response = await api.post(
        `/custom_form/update_custom_header_sequence`,
        parmas
      );
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
);

export const updateExcludedProviders = createAsyncThunk(
  "customForm/updatedExcludedProviders",
  async (params: any, { rejectWithValue }) => {
    try {
      const response = await api.post(
        `/custom_form/update_custom_form_included_providers`,
        params
      );
      return response?.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data?.response);
    }
  }
);
const customForm = createSlice({
  name: "customForm",
  initialState,
  reducers: {
    setcurrentEditCustomForm: (state, action) => {
      state.currentEditCustomForm = action.payload;
    },
    resetcurrentEditCustomForm: (state) => {
      state.currentEditCustomForm = null;
    },
    setConfirmDeleteForm: (state, action) => {
      const { flag, id } = action.payload;
      state.hasDeleteFrom = flag;
      state.idOfFormToDelete = id;
    },
    resetConfirmDeleteForm: (state) => {
      state.hasDeleteFrom = false;
      state.idOfFormToDelete = null;
    },
    setCurrentActiveFormInfo: (state, action) => {
      state.selectedActiveForm = action.payload;
    },
    resetCurrentActiveFormInfo: (state) => {
      state.selectedActiveForm = null;
    },
    resetActiveFormTabsArr: (state) => {
      state.selectedActiveFormTabsArr = null;
    },
    setFormTypePage: (state) => {
      state.formTypePage += 1;
    },
    resetFormTypePage: (state) => {
      state.formTypePage = 1;
    },
    resetFormTypeData: (state) => {
      state.ExpessTypeData = [];
    },
    setFormTypeFilterItems: (state, action) => {
      state.FormTypeFilterItems = action.payload;
    },
    resetFormTypeFilterItems: (state) => {
      state.FormTypeFilterItems = {
        // status: "1",
        filterColumn: "",
        filterValue: "",
        searchVal: "",
        sortColumn: "",
        sortType: "",
        formTypePage: 1,
      };
    },
    // Pagination End
    // Filter Dropdown Start
    setSelectedDropdownItemForm: (state, action) => {
      state.selectedDropdownItemForm = action.payload;
    },
    resetSelectedDropdownItemForm: (state) => {
      state.selectedDropdownItemForm = "";
    },
    setSelectedItemIndexFilterForm: (state, action) => {
      state.selectedItemIndexFilterForm = action.payload;
    },
    resetSelectedItemIndexFilterForm: (state) => {
      state.selectedItemIndexFilterForm = "";
    },
    setSelectedItemIndexSortForm: (state, action) => {
      state.selectedItemIndexSortForm = action.payload;
    },
    resetSelectedItemIndexSortForm: (state) => {
      state.selectedItemIndexSortForm = "";
    },
    setSortByFormR: (state, action) => {
      state.sortByFormR = action.payload;
    },
    resetSortByFormR: (state) => {
      state.sortByFormR = "";
    },
    setSelectedFilterItemsForm: (state, action) => {
      state.selectedFilterItemsForm = action.payload;
    },
    resetSelectedFilterItemsForm: (state) => {
      state.selectedFilterItemsForm = "";
    },
    setSearchValueForm: (state, action) => {
      state.searchValForm = action.payload;
    },
    resetSearchValueForm: (state) => {
      state.searchValForm = "";
    },
    // Filter End
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFormTypeByFilterThunk.pending, (state) => {
        state.loading = true;
      })

      .addCase(getFormTypeByFilterThunk.fulfilled, (state, action) => {
        state.loading = false;
        (state.customFormList = action.payload),
          (state.lastPage = action.payload.data.last_page);
      })
      .addCase(getFormTypeByFilterThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getCustomFormsThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCustomFormsThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.customFormList = action.payload;
      })
      .addCase(getCustomFormsThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getCustomFormsIdByThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCustomFormsIdByThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.selectedActiveForm =
          action?.payload.length !== 0 ? action?.payload[0] : {};
      })
      .addCase(getCustomFormsIdByThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // getCustomFormsIdPaginateTrueByThunk
      .addCase(getCustomFormsTabsByIdThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCustomFormsTabsByIdThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.selectedActiveFormTabsArr = action?.payload;
        state.selectedTabStore = action.payload[0]?.id;
      })

      .addCase(
        getCustomFormFromAnswerVersionNumberThunk.fulfilled,
        (state, action) => {
          state.loading = false;
          state.selectedActiveFormTabsArr = action?.payload;
          state.selectedTabStore = action.payload[0]?.id;
        }
      )
      .addCase(getCustomFormsTabsByIdThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getCustomFormsByClinicalStatusThunk.pending, (state) => {
        state.loading = true;
      })

      .addCase(
        getCustomFormsByClinicalStatusThunk.fulfilled,
        (state, action) => {
          state.loading = false;
          state.clinicalCustomFormRecords = action?.payload;
        }
      )
      .addCase(
        getCustomFormsByClinicalStatusThunk.rejected,
        (state, action) => {
          state.loading = false;
          state.error = action.error.message;
        }
      )

      // getCustomFormListThunk
      .addCase(getCustomFormListThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCustomFormListThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.getCustomFormList = action?.payload;
      })
      .addCase(getCustomFormListThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getCustomFormVersionsThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCustomFormVersionsThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.customFormVersions = action?.payload?.data;
      })
      .addCase(getCustomFormVersionsThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updateCustomFormByIdThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // updateCustomFormByIdThunk

      .addCase(getCustomFormAnswersVersionsThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCustomFormAnswersVersionsThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.customFormAnswerVersions = action?.payload?.data;
      })
      .addCase(getCustomFormAnswersVersionsThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getFormNameThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(cloneCustomFormThunk.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(getFormNameThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.getFormName = action.payload.data.data;
      })
      .addCase(getFormNameThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getFormTypeThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getFormTypeThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.getFormType = action.payload.data.data;
      })
      .addCase(getFormTypeThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(
        getCustomFormsIdPaginateTrueByThunk.fulfilled,
        (state, action) => {
          state.loading = false;
          state.selectedActiveFormTabsArr = action?.payload;
          state.selectedTabStore = action.payload[0]?.id;
        }
      );
  },
});

export const {
  resetConfirmDeleteForm,
  setConfirmDeleteForm,
  resetCurrentActiveFormInfo,
  setCurrentActiveFormInfo,
  resetActiveFormTabsArr,
  setFormTypePage,
  resetFormTypeData,
  resetFormTypeFilterItems,
  resetFormTypePage,
  resetSearchValueForm,
  resetSelectedDropdownItemForm,
  resetSelectedFilterItemsForm,
  resetSelectedItemIndexFilterForm,
  resetSelectedItemIndexSortForm,
  resetSortByFormR,
  setFormTypeFilterItems,
  setSearchValueForm,
  setSelectedDropdownItemForm,
  setSelectedFilterItemsForm,
  setSelectedItemIndexFilterForm,
  setSelectedItemIndexSortForm,
  setSortByFormR,
  setcurrentEditCustomForm,
  resetcurrentEditCustomForm,
} = customForm.actions;
export default customForm.reducer;
