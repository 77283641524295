export const optionsArr = [
  {
    title: "Text",
    value: "text-area",
  },
  {
    title: "Multi Choice",
    value: "radio",
  },
  {
    title: "Checkbox",
    value: "checkbox",
  },
  {
    title: "Drop-down",
    value: "dropdown",
  },
  {
    title: "Date",
    value: "date",
  },
  {
    title: "Time",
    value: "time",
  },
  {
    title: "Note",
    value: "note",
  },
  {
    title: "File",
    value: "file",
  },
  {
    title: "Response",
    value: "response",
  },
  {
    title: "Score",
    value: "score",
  },
  {
    title: "Signature",
    value: "signature",
  },
  {
    title: "Risk Factor",
    value: "risk",
  },
];
