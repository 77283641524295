import "../Styles/ClinicalComponents.css";
import React from "react";

const Tag = ({ count = 0, isTagsActive }: any) => {
  return (
    <div
      className={`tag_container ${isTagsActive ? "goals_tag_active" : ""}`}
      style={{
        backgroundColor: !isTagsActive
          ? count > 0
            ? "#b2ff9f"
            : "rgba(213, 213, 213, 0.8)"
          : "#06a5ff",
        color: !isTagsActive && count > 0 ? "#1a8400" : "white",
      }}
    >
      <span
        className="tag_num"
        style={{
          color: !isTagsActive && count === 0 ? "white" : "#a1a1a1",
        }}
      >
        {count}
      </span>
      <span className={`tag_title ${isTagsActive ? "goals_tag_title" : ""}`}>
        Tag
      </span>
    </div>
  );
};

export default Tag;
