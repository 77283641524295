import React, { Fragment, useEffect, useRef, useState } from "react";
import CommonUpperBox from "../../../components/UpperBox/CommonHeader";
import Table from "../../../components/CommonTable/Table";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../Controller/redux/Hooks";
import {
  getBillingRule,
  getBillingRuleAssociatedFundingThunk,
  getBillingRuleByFilterThunk,
  getBillingRuleNameThunk,
  getBillingRuleRateThunk,
  resetBillingRuleData,
  resetBillingRulePage,
  setBillingRulePage,
} from "../../../../Controller/redux/features/RefDataFeature/BillingRuleFeature/billingruleSlice";
import CircularNoteTrigger from "../../../components/CircularNoteTrigger/CircularNoteTrigger";
import { useNavigate } from "react-router-dom";
import CommonQuickMenu from "../../../components/CommonQuickMenu/CommonQuickMenu";
import { getStatusDetailsThunk } from "../../../../Controller/redux/features/CommonFeature/commonSlice";
const Billing = () => {
  const { BillingRuleData, loading } = useAppSelector(
    (state) => state.billingRule
  );
  const { domainDetail, filterIconColor } = useAppSelector(
    (state) => state?.common
  );

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getStatusDetailsThunk("1,0"));
  }, []);

  useEffect(() => {
    // dispatch(getBillingRule());
    dispatch(getBillingRuleNameThunk());
    dispatch(getBillingRuleAssociatedFundingThunk());
    dispatch(getBillingRuleRateThunk());
  }, []);

  const {
    lastPage,
    billingRulePage,
    billingRuleData,
    // loading,
    selectedFilterItemsBillingRule,
    sortByBillingRuleR,
    searchValBillingRule,
    billingRuleFilterItems,
  } = useAppSelector((state) => state.billingRule);
  useEffect(() => {
    dispatch(resetBillingRuleData());
    dispatch(resetBillingRulePage());
  }, [
    billingRuleFilterItems?.searchVal,
    billingRuleFilterItems?.filterValue,
    billingRuleFilterItems?.sortType,
  ]);
  useEffect(() => {
    dispatch(
      getBillingRuleByFilterThunk({
        filterColumn: billingRuleFilterItems?.filterColumn
          ? billingRuleFilterItems?.filterColumn
          : "",
        filterValue: billingRuleFilterItems?.filterValue
          ? billingRuleFilterItems?.filterValue
          : "",
        searchVal: billingRuleFilterItems?.searchVal
          ? billingRuleFilterItems?.searchVal
          : "",
        sortColumn: billingRuleFilterItems?.sortColumn
          ? billingRuleFilterItems?.sortColumn
          : "",
        sortType: billingRuleFilterItems?.sortType
          ? billingRuleFilterItems?.sortType
          : "",
        billingRulePage,
      })
    );
  }, [
    billingRulePage,
    billingRuleFilterItems?.searchVal,
    billingRuleFilterItems?.filterValue,
    billingRuleFilterItems?.sortType,
  ]);
  const uniqueBillingRule = BillingRuleData?.filter(
    (item: any, index: number, array: any) => {
      return array?.findIndex((obj: any) => obj.id === item.id) === index;
    }
  );

  // const [BillingRuleRecords, setBillingRuleRecords] = useState([]);

  // useEffect(() => {
  //   if (BillingRuleData?.data) {
  //     setBillingRuleRecords(BillingRuleData?.data);
  //   }
  // }, [BillingRuleData]);

  const columns = [
    { header: "Name", accessor: "set_name" },
    {
      header: "Associated Funding Type",
      accessor: "associated_funding_type",
    },
    { header: "Tax Rate", accessor: "tax_rate" },
    { header: "Action", accessor: "status" },
  ];
  const handleClickRow = (e: any, id: any) => {
    if (!isDropdownOpenBillingRule) {
      navigate(`/ref-data/billing-set/${window.btoa(id)}`);
    }
  };

  const tableRef = useRef();
  const billingRuleInnerRef = useRef();

  const clientStatusDetailsArr = useAppSelector(
    (state) => state.common.statusDetailsData?.data
  );
  const dropdownRefBillingRule = useRef<any>();
  const svgParentRefBillingRule = useRef<any>();
  const [isDropdownOpenBillingRule, setIsDropdownOpenBillingRule] =
    useState(false);

  useEffect(() => {
    const handleClickOutsideClients = (event: any) => {
      if (
        dropdownRefBillingRule.current &&
        !dropdownRefBillingRule.current.contains(event.target) &&
        svgParentRefBillingRule.current &&
        !svgParentRefBillingRule.current.contains(event.target)
      ) {
        setIsDropdownOpenBillingRule(false);
      }
    };
    document.addEventListener("click", handleClickOutsideClients);
    return () => {
      document.removeEventListener("click", handleClickOutsideClients);
    };
  }, []);

  const onScroll = () => {
    if (billingRuleInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } =
        billingRuleInnerRef.current;

      if (
        scrollTop + clientHeight + +5 >= scrollHeight &&
        billingRulePage < lastPage
      ) {
        dispatch(setBillingRulePage(billingRulePage));
      }
    }
  };
  const [stageTwoToGoalsModal, setstageTwoToGoalsModal] = useState<any>(false);
  return (
    <Fragment>
      <CommonUpperBox
        title="Billing Set"
        type={"listing"}
        backgroundWithText={true}
        text={"B R"}
        onClickPlus={() => {
          setstageTwoToGoalsModal(true);
        }}
      />
      <Table
        isLoading={loading}
        tableRef={tableRef}
        tableBodyRef={billingRuleInnerRef}
        onScroll={onScroll}
        dynamicHeaderColor={domainDetail?.listingtbl_color}
        statusType="billingRuleScreen"
        thData={columns}
        itemsArr={clientStatusDetailsArr}
        classNameTr={"all-expenss-type-table-head"}
        type="billingRule"
        dropdownRef={dropdownRefBillingRule}
        isDropdownOpen={isDropdownOpenBillingRule}
        onClickSvg={() => setIsDropdownOpenBillingRule(true)}
        svgParentRef={svgParentRefBillingRule}
        allClients={uniqueBillingRule}
        onClickRow={handleClickRow}
        classNameRow={"all-expenss-type-table-body"}
        fill={
          selectedFilterItemsBillingRule.length > 0 ||
          searchValBillingRule.length > 0 ||
          sortByBillingRuleR.length > 0
            ? filterIconColor
            : "#D8D3D3"
        }
      />
      <CircularNoteTrigger type="billing" title="Add Billing" />
      {stageTwoToGoalsModal && (
        <CommonQuickMenu
          setshowGoalsModal={(value: any) => {}}
          mainType={"listing"}
          setstageTwoToGoalsModal={setstageTwoToGoalsModal}
        />
      )}
    </Fragment>
  );
};

export default Billing;
