import "./home.css";

import { Fragment, useEffect } from "react";
import {
  getAssociationRelationshipsThunk,
  getAssociationTypesThunk,
} from "../../../Controller/redux/features/CommonFeature/associationSlice";
import {
  getAllTagsThunk,
  getDashboardVendorsThunk,
  getEntityTypesThunk,
  getProfileDetailsThunk,
  getUserTypesThunk,
} from "../../../Controller/redux/features/CommonFeature/commonSlice";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../Controller/redux/Hooks";
import {
  vendorsDataArrBox2,
  vendorsDataArrBox3,
  vendorsDataArrBox4,
} from "./VendorsDataArr";

import UpperBox from "../../components/UpperBox/UpperBox";
import { setUserTypeIdForClient } from "../../../Controller/redux/features/ClientFeature/clientsSlice";
import { setUserTypeIdForContact } from "../../../Controller/redux/features/ContactsFeature/contactsSlice";
import { setUserTypeIdForProspects } from "../../../Controller/redux/features/ProspectsFeature/prospectsSlice";
import { setUserTypeIdForStaff } from "../../../Controller/redux/features/StaffFeature/staffSlice";
import { setUserTypeIdForBusiness } from "../../../Controller/redux/features/BusinessFeature/businessSlice";
import { setUserTypeIdForTeams } from "../../../Controller/redux/features/TeamsFeature/teamsSlice";
import { getDocumentsTagsThunk } from "../../../Controller/redux/features/CommonFeature/documentSlice";
import { getUniqueTagsMealsThunk } from "../../../Controller/redux/features/MealsFeatures/MealFeature/mealbuilderSlice";
import CircularNoteTrigger from "../../components/CircularNoteTrigger/CircularNoteTrigger";
import GreenColorSVG from "../Comms/Data/GreenColorSVG";
import PlusIcon from "../../../Model/Assets/SVG/WidgetVillage/PlusIcon";
import { setUserTypeIdForLocation } from "../../../Controller/redux/features/LocationFeature/locationSlice";
import { toast } from "react-toastify";
import { setUserTypeIdForVendors } from "../../../Controller/redux/features/VendorFeature/VendorSlice";
import React from "react";
import CommentWithNumberSVG from "../../../Model/Assets/SVG/Common/CommentWithNumberSVG";
import { setUserTypeIdForReportsHub } from "../../../Controller/redux/features/ReportsHubFeature/ReportsHubSlice";

export function Home() {
  const dispatch = useAppDispatch();

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  const userTypesFromRedux = useAppSelector(
    (state) => state.common.getUserTypes.data
  );
  const entityTypesFromRedux = useAppSelector(
    (state) => state?.common?.getEntityTypes?.data
  );
  const { dynamicColor } = useAppSelector((state) => state?.common);
  const { domainDetail } = useAppSelector((state) => state?.common);

  useEffect(() => {
    document.documentElement.style.setProperty("--dynamicColor", dynamicColor);
  }, []);

  const mapAndDispatch = (elem: any) => {
    for (const item of elem) {
      const lowerDesc = item.desc.toLowerCase();
      if (lowerDesc.includes("staff")) {
        dispatch(setUserTypeIdForStaff(item));
        localStorage.setItem("dynamicStaffId", JSON.stringify(item.id));
      } else if (lowerDesc.includes("contact")) {
        dispatch(setUserTypeIdForContact(item));
        localStorage.setItem("dynamicContactId", JSON.stringify(item.id));
      } else if (lowerDesc.includes("company admin")) {
      } else if (lowerDesc.includes("prospect")) {
        localStorage.setItem("dynamicProspectId", JSON.stringify(item.id));
        dispatch(setUserTypeIdForProspects(item));
      } else if (lowerDesc.includes("client")) {
        localStorage.setItem("dynamicClientId", JSON.stringify(item.id));
        dispatch(setUserTypeIdForClient(item));
      } else if (lowerDesc.includes("location")) {
        localStorage.setItem("dynamicLocationId", JSON.stringify(item.id));
        dispatch(setUserTypeIdForLocation(item));
      } else if (lowerDesc.includes("business")) {
        dispatch(setUserTypeIdForBusiness(item));
        localStorage.setItem("dynamicBusinessId", JSON.stringify(item.id));
      } else if (lowerDesc.includes("team")) {
        dispatch(setUserTypeIdForTeams(item));
        localStorage.setItem("dynamicTeamsId", JSON.stringify(item.id));
      } else if (lowerDesc.includes("vendor")) {
        dispatch(setUserTypeIdForVendors(item));
        localStorage.setItem("dynamicVendorsId", JSON.stringify(item.id));
      } else if (lowerDesc.includes("report")) {
        dispatch(setUserTypeIdForReportsHub(item));
        localStorage.setItem("dynamicReportsHubId", JSON.stringify(item.id));
      }
    }
  };

  const mapAndDispatchEntity = (elem: any) => {};
  useEffect(() => {
    dispatch(getAssociationTypesThunk());
    dispatch(getAssociationRelationshipsThunk());
    dispatch(getUserTypesThunk());
    dispatch(getAllTagsThunk());
    dispatch(getEntityTypesThunk());
    dispatch(getDocumentsTagsThunk());
    dispatch(getUniqueTagsMealsThunk());
    dispatch(getProfileDetailsThunk());
  }, []);

  useEffect(() => {
    if (entityTypesFromRedux) {
      mapAndDispatchEntity(entityTypesFromRedux);
    }
  }, [entityTypesFromRedux]);

  useEffect(() => {
    if (userTypesFromRedux) {
      mapAndDispatch(userTypesFromRedux);
    }
  }, [userTypesFromRedux]);

  const { getVendors } = useAppSelector((state) => state.common);
  // console.log(getVendors,'getVendors')

  useEffect(() => {
    dispatch(getDashboardVendorsThunk());
    if (getVendors?.length == 0) {
      // toast.warn("No Categories Found :(  Please contact your admin.");
    }
  }, []);
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <Fragment>
      <div className="main-content-section">
        <div className="container-fluid">
          <div style={{ display: "flex" }}>
            <div style={{ width: "100%" }}>
              <UpperBox type="hub" name={domainDetail?.hub_label} />

              <div className="main-box-hub">
                <div className="main-box-header-home">
                  {getVendors &&
                    getVendors.map((elem) => {
                      return (
                        <>
                          <div className="vendor-box">
                            <div className="vendor-box-heading">
                              <div className="vender_box_container">
                                <span className="vendor-box-heading-title">
                                  {elem.category}
                                </span>
                              </div>
                            </div>

                            <div className="vendor-box-content">
                              {elem?.vendor_softwares &&
                                elem?.vendor_softwares?.map((vendor, index) => {
                                  return (
                                    <div key={index}>
                                      <a
                                        href={
                                          vendor?.link?.includes("https://")
                                            ? vendor?.link
                                            : "https://" + vendor?.link
                                        }
                                        target="_blank"
                                        className="vendor-box-wrapper"
                                      >
                                        <div className="vendor-box-item">
                                          <img
                                            src={
                                              vendor?.profile_image_s3 ||
                                              require("../../../Model/Assets/images/logo-placeholder-image.png")
                                            }
                                            alt="logo"
                                          />
                                        </div>
                                        {vendor.vendor_name}
                                      </a>
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                        </>
                      );
                    })}
                </div>

                <div className="main-box-body ">
                  <div className="upper-segment-clinical">
                    <div
                      className="content-wrapper"
                      style={{ display: "block", zIndex: "-1" }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="icon-bottom-section"></div>
        </div>
        {/* <iframe> src="https://docs.google.com/document/d/15Ag7ve7qpFZvH767W1PTwu8Kp6qC-Fe4_TcD9zstBe8/edit?pli=1#heading=h.3xr6b8ogghk7" </iframe> */}

        <CircularNoteTrigger type="stickyHub" title="Comms" 

        
          extraType="globalCommsBox"
        
        
        />
      </div>
    </Fragment>
  );
}
