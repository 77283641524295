import React, { useContext, useEffect, useState } from "react";
import { dummyArrStaff } from "../../../../pages/People/ClientPage/Components/UniqueClientScreen/Data/ClientScreenContentArr";
import {
  formatDate,
  formatDateForAPI,
  getCurrentDate,
} from "../../../../../Model/utils/Helper/formatDate";
import ReactDatePicker from "react-datepicker";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../Controller/redux/Hooks";
import DropdownIcon from "../../../../../Model/Assets/png/drop.png";
import { getProfileDetailsThunk } from "../../../../../Controller/redux/features/CommonFeature/commonSlice";
import {
  getStaffByIdThunk,
  getStaffThunk,
  updateStaffThunk,
} from "../../../../../Controller/redux/features/StaffFeature/staffSlice";
import { toast } from "react-toastify";
import { setunsavedChanges } from "../../../../../Controller/redux/features/AuthFeature/authSlice";
import CustomDatePicker from "../../../DatePicker/CustomDatePicker";

const StaffDetailsSticky = ({ currentStaff }: any) => {
  const [details, setDetails] = useState<any>({
    givenName: "",
    middleName: "",
    familyName: "",
    knownAs: "",
    address: "",
    dob: "",
    email: "",
    mobile: "",
    role: "",
    fundingType: "",
  });
  const dispatch = useAppDispatch();
  // @ts-ignore
  let dynamicStaffId = parseInt(localStorage.getItem("dynamicStaffId"));

  useEffect(() => {
    const fetchedData: any = currentStaff;
    if (fetchedData) {
      setDetails({
        givenName: `${fetchedData.first_name}` || "",
        middleName: fetchedData.middle_name || "",
        familyName: fetchedData.last_name || "",
        knownAs: fetchedData.pref_name || "",
        address: fetchedData?.user_addresses[0]?.address || "",
        dob: formatDate(fetchedData?.dob) || "",
        email: fetchedData?.email,
        mobile: fetchedData?.mobile,
        role: fetchedData ? fetchedData?.roles[0]?.id : "",
        fundingType: fetchedData?.funding_type || "",
      });
    } else {
      setDetails({
        givenName: "",
        middleName: "",
        familyName: "",
        knownAs: "",
        address: "",
        dateOfBirth: "",
        email: "",
        mobile: "",
        role: "",
        fundingType: "",
      });
    }
  }, [currentStaff]);

  const dynamicColor = useAppSelector((state) => state?.common?.dynamicColor);
  const [selectedDate, setSelectedDate] = useState<Date | null | any>();

  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  const handleDateChange = (date: Date | any) => {
    dispatch(setunsavedChanges(true));
    if (!hasUnsavedChanges) {
      setHasUnsavedChanges(true);
    }
    setSelectedDate(date);
  };

  // CHANGE TO INPUT FIELDS
  const handleChange = (
    index: number,
    e: React.ChangeEvent<HTMLInputElement> | any
  ) => {
    const { value } = e.target;

    const staffRole = getUserRoles?.data?.find(
      (val: any) => val?.id === +value
    );
    const fieldName = Object.keys(details)[index];

    setDetails((prevState: any) => ({
      ...prevState,
      [fieldName]: value,
    }));

    dispatch(setunsavedChanges(true));
    if (!hasUnsavedChanges) {
      setHasUnsavedChanges(true);
    }
  };

  const getUserRoles = useAppSelector((state) => state?.staff?.staffUserRoles);
  const [userRoles, setUserRoles] = useState([]);

  // FETCH USER ROLES
  useEffect(() => {
    if (getUserRoles?.data && getUserRoles?.data?.length > 0) {
      setUserRoles(getUserRoles?.data);
    }
  }, [getUserRoles]);

  const [selectedAddress, setSelectedAddress] = useState<any>("");
  const [possibleAddresses, setPossibleAddresses] = useState<any>([]);

  function initService(query: any) {
    const displaySuggestions = function (predictions: any, status: any) {
      // @ts-ignore
      if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
        // console.log("Location service down!");
        return;
      }
      if (!predictions) {
        setPossibleAddresses([]);
        return;
      }
      setPossibleAddresses(predictions);
    };
    // @ts-ignore

    // @ts-ignore
    const service = new window.google.maps.places.AutocompleteService();
    // console.log(query);
    service.getQueryPredictions({ input: query }, displaySuggestions);
  }

  const handleChangeForAddress = (index: number, e: any) => {
    const { value } = e.target;
    setDetails({ ...details, address: value });
    initService(value);
    if (value.length === 0) {
      setDetails({ ...details, address: "" });
      setPossibleAddresses([]);
    }
    // setDetails((prevState: any) => ({
    //   ...prevState,
    //   [fieldName]: value,
    // }));

    // setunsavedChanges(true);
    // if (!hasUnsavedChanges) {
    //   setHasUnsavedChanges(true);
    // }
  };

  const selectAddress = (e: any) => {
    if (!hasUnsavedChanges) {
      setHasUnsavedChanges(true);
    }
    setSelectedAddress(e.target.innerHTML);
    setDetails({ ...details, address: e.target.innerHTML });
    dispatch(setunsavedChanges(true));
    setPossibleAddresses([]);
  };
  const initialDate = currentStaff?.date_of_birth;
  useEffect(() => {
    if (initialDate) {
      setSelectedDate(new Date(initialDate));
    }
  }, [initialDate]);
  const { user } = useAppSelector((state) => state?.common);
  const IsUserAdmin = user?.roles[0]?.desc.toLowerCase();
  const hasProvider = IsUserAdmin === "administrator";
  const [staffRole, setStaffRole] = useState<any>("");
  useEffect(() => {
    if (currentStaff?.roles?.length > 0) {
      setStaffRole(currentStaff?.roles[0]?.desc);
    }
  }, [currentStaff]);

  const [updateStaff, setUpdateStaff] = useState<any>({
    first_name: "",
    middle_name: "",
    address: "",
    last_name: "",
    pref_name: "",
    email: "",
    mobile: "",
    phone: "",
    dob: "",
    funding_type: "",
    package_level: "",
    funding_available: "",
    acat_assessment: "",
    initial_enquiry: "",
    role: 4,
    user_id: "",
    type: "",
  });

  useEffect(() => {
    setUpdateStaff({
      first_name: details.givenName,
      middle_name: details.middleName,
      last_name: details.familyName,
      pref_name: details.knownAs,
      address: details.address,
      dob: selectedDate ? formatDateForAPI(selectedDate) : initialDate,
      mobile: details.mobile,
      package_level: "",
      funding_available: "",
      acat_assessment: "",
      initial_enquiry: "",
      email: details.email,
      phone: "",
      funding_type: details.fundingType,
      role: details?.role,
      user_id: currentStaff?.id,
      type: dynamicStaffId,
    });
  }, [details, selectedDate, dynamicStaffId]);

  const handleSaveChanges = async () => {
    setHasUnsavedChanges(false);
    dispatch(setunsavedChanges(false));

    let requiredParams = {
      ...updateStaff,
      user_id: currentStaff?.id,
    };

    try {
      const res = await dispatch(updateStaffThunk(requiredParams));
      dispatch(
        getStaffByIdThunk({
          typeId: dynamicStaffId,
          clientId: currentStaff?.id,
        })
      );
      if (res.payload.status === "success") {
        toast.success("Contact updated successfully!", {
          style: {},
          progressStyle: { backgroundColor: dynamicColor },
          icon: (
            <span
              style={{
                marginRight: "8px",
                borderRadius: "50%",
                width: "7rem",
                height: "35px",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: dynamicColor,
                backgroundColor: "white",
                fontSize: "1.5rem",
                border: `3px solid ${dynamicColor}`,
              }}
            >
              ✓
            </span>
          ),
        });
        dispatch(getProfileDetailsThunk());
      } else {
        toast.error("An Error Occurred");
      }
    } catch (error) {
      console.error(error);
      toast.error("An Error Occurred");
    }
    dispatch(getStaffThunk({ dynamicStaffId: dynamicStaffId }));
  };

  return (
    <div className="staff_details_section_wrapper_sticky_note">
      <div className=" client-screen-personal-details-heading">
        <span className=" client-screen-sub-heading-title">
          Personal Details
        </span>
        <span className=" client-screen-line"></span>
      </div>
      <div className="main-box-body">
        {dummyArrStaff.slice(0, 8).map((elem: any, index: any) => {
          const fieldName = Object.keys(details)[index];
          return (
            <div
              className={`content-wrapper ${
                index === 5 && "staff_date_filter"
              }`}
              key={index}
            >
              <div className="wrapper-title">{elem.UpperTitle}</div>

              {index === 5 ? (
                // <ReactDatePicker
                //   onChange={handleDateChange}
                //   selected={selectedDate}
                //   startDate={selectedDate}
                //   placeholderText="Select Date"
                //   className="date-picker-details-tab-of-client-screen"
                //   showYearDropdown
                //   showMonthDropdown
                //   dropdownMode="select"
                //   maxDate={getCurrentDate()}
                //   dateFormat="EEE, dd MMMM yyyy"
                // />
                <CustomDatePicker newDate={selectedDate} handleChange={handleDateChange} maxDate={getCurrentDate()}/>
              ) : index === 9 ? (
                <select
                  className="wrapper-info wrapper-info-select-dropdown"
                  onChange={(e) => handleChange(index, e)}
                  value={details[fieldName]}
                  style={{
                    border: "none",
                    outline: "none",
                    height: "fit-content",
                    backgroundColor: "white",
                    // width: "256px",
                  }}
                >
                  <option value="">Please Select...</option>

                  <option value="Aged Care">Aged Care</option>
                  <option value="Disability">Disability</option>
                  <option value="Veterans">Veterans</option>
                  <option value="Private">Private</option>
                  <option value="Insurance">Insurance</option>
                  <option value="Other">Other</option>
                </select>
              ) : index === 8 ? (
                <select
                  className="wrapper-info wrapper-info-select-dropdown"
                  onChange={(e) => handleChange(index, e)}
                  value={details[fieldName]}
                  style={{
                    border: "none",
                    outline: "none",
                    height: "fit-content",
                    backgroundColor: "white",
                    // width: "256px",
                  }}
                >
                  {userRoles?.map((elem: any, index: number | any) => (
                    <option key={index} value={elem?.id}>
                      {elem?.desc}
                    </option>
                  ))}
                </select>
              ) : index === 4 ? (
                /*furkan location*/
                <>
                  <div>
                    <input
                      type="text"
                      onChange={(e) => handleChangeForAddress(index, e)}
                      className="wrapper-info"
                      placeholder="Enter an address"
                      value={details[fieldName]}
                    />
                    <div className="address_listing">
                      <ul>
                        {possibleAddresses.length > 0
                          ? possibleAddresses.map((address: any, ind: any) => {
                              return (
                                <li
                                  onClick={selectAddress}
                                  className="address_listing_item"
                                >
                                  {address.description}
                                </li>
                              );
                            })
                          : null}
                      </ul>
                    </div>
                  </div>
                </>
              ) : (
                <input
                  className="wrapper-info"
                  onChange={(e) => handleChange(index, e)}
                  value={details[fieldName]}
                  placeholder={elem?.placeHolder}
                  style={{
                    border: "none",
                    outline: "none",
                    height: "fit-content",
                  }}
                />
              )}

              {elem.index === 5 && (
                <div className="dropdown-icon">
                  <img src={DropdownIcon} alt="dropdown-icon" />
                </div>
              )}
              <div className="wrapper-line"></div>
            </div>
          );
        })}
      </div>

      <div className=" client-screen-personal-details-heading">
        <span className="client-screen-sub-heading-title">Profile Details</span>
        <span className=" client-screen-line"></span>
      </div>
      <div className="main-box-body">
        {dummyArrStaff.slice(8, 9).map((elem: any, index: any) => {
          const indexTemp = index + 8;
          const fieldName = Object.keys(details)[indexTemp];
          return (
            <div className="content-wrapper" key={indexTemp}>
              <div className="wrapper-title">{elem.UpperTitle}</div>

              {indexTemp === 5 ? (
                // <ReactDatePicker
                //   onChange={handleDateChange}
                //   selected={selectedDate}
                //   startDate={selectedDate}
                //   placeholderText="Select Date"
                //   className="date-picker-details-tab-of-client-screen"
                //   showYearDropdown
                //   showMonthDropdown
                //   dropdownMode="select"
                //   maxDate={getCurrentDate()}
                //   dateFormat="EEE, dd MMMM yyyy"
                // />
                <CustomDatePicker handleChange={handleDateChange} maxDate={getCurrentDate()} newDate={selectedDate}/>
              ) : indexTemp === 9 ? (
                <select
                  className="wrapper-info wrapper-info-select-dropdown"
                  onChange={(e) => handleChange(indexTemp, e)}
                  value={details[fieldName]}
                  style={{
                    border: "none",
                    outline: "none",
                    height: "fit-content",
                    backgroundColor: "white",
                    // width: "256px",
                  }}
                >
                  <option value="">Please Select...</option>

                  <option value="Aged Care">Aged Care</option>
                  <option value="Disability">Disability</option>
                  <option value="Veterans">Veterans</option>
                  <option value="Private">Private</option>
                  <option value="Insurance">Insurance</option>
                  <option value="Other">Other</option>
                </select>
              ) : indexTemp === 8 ? (
                hasProvider ? (
                  <select
                    className="wrapper-info wrapper-info-select-dropdown"
                    onChange={(e) => handleChange(indexTemp, e)}
                    value={details[fieldName]}
                    style={{
                      border: "none",
                      outline: "none",
                      height: "fit-content",
                      backgroundColor: "white",
                      // width: "256px",
                    }}
                  >
                    <option>Please Select...</option>
                    {userRoles?.map((elem: any, indexTemp: number | any) => (
                      <option key={indexTemp} value={elem?.id}>
                        {elem.desc}
                      </option>
                    ))}
                  </select>
                ) : (
                  <input
                    readOnly
                    className="wrapper-info"
                    // onChange={(e) => handleChange(indexTemp, e)}
                    value={staffRole}
                    style={{
                      border: "none",
                      outline: "none",
                      height: "fit-content",
                    }}
                  />
                )
              ) : (
                <input
                  className="wrapper-info"
                  onChange={(e) => handleChange(indexTemp, e)}
                  value={details[fieldName]}
                  style={{
                    border: "none",
                    outline: "none",
                    height: "fit-content",
                  }}
                />
              )}

              {elem.index === 5 && (
                <div className="dropdown-icon">
                  <img src={DropdownIcon} alt="dropdown-icon" />
                </div>
              )}
              <div className="wrapper-line"></div>
            </div>
          );
        })}
        <div className="content-wrapper">
          {hasUnsavedChanges && (
            <button
              className="client_sticky_save_btn"
              style={{
                background: dynamicColor,
              }}
              onClick={() => {
                handleSaveChanges();
              }}
            >
              save
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default StaffDetailsSticky;
