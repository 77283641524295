import React, { useEffect, useRef, useState } from "react";
import Chart from "chart.js/auto";
import { useAppSelector } from "../../../Controller/redux/Hooks";

const CommonChart = ({
  values,
  pointBackgroundColor,
  borderWidth,
  labels,
  pointRadius,
  pointBorderColor,
  chartWidth,
  chartLabel,
  chartType,
  dataColors,
  chartSize,
  displayX,
  displayY,
  data_2,
  isFromVillage,
  chartHeight,
  isFromSales,
  datasets,
  barThickness,
}: any) => {
  const chartRef = useRef<any>(null);
  const chartInstanceRef = useRef<any>(null);
  const dynamicColor = useAppSelector((state) => state?.common?.dynamicColor);

  // CHART STYLE OBJECT
  const chartStyles = {
    // width: !isFromVillage ? '152px' : chartWidth,
    // height: !isFromVillage ? '152px' : chartType === "doughnut" ? "88px" : "auto",
    width: chartWidth,
    height: chartHeight,
    marginTop: "5px",
  };

  const doughnutConfig = {
    type: "doughnut",
    data: {
      labels: ["Open", "In Progress", "Waitlist"],
      datasets: [
        {
          data: values,
          borderColor: ["#6AE24C", "#FFFF5B", "#d99c23"],
          backgroundColor: ["#6AE24C", "#FFFF5B", "#d99c23"],
          borderWidth: 1,
        },
      ],
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      animation: {
        duration: 0,
      },
      plugins: {
        legend: {
          display: false,
        },
      },
    },
  };

  useEffect(() => {
    const shouldRenderChart = true;

    if (shouldRenderChart) {
      const data = {
        labels: labels,

        datasets: isFromSales
          ? [...datasets]
          : [
              {
                label: chartLabel,
                data: values,
                borderColor: dynamicColor,
                borderWidth: borderWidth,
                fill: false,
                pointBackgroundColor: pointBackgroundColor,
                pointBorderColor: pointBorderColor,
                pointRadius: pointRadius,
                backgroundColor: dataColors,
              },
            ],
      };

      if (data_2) {
        data.datasets.push({
          label: "Diastolic",
          data: data_2,
          borderColor: "#f5f5f5",
          borderWidth: borderWidth,
          fill: false,
          pointBackgroundColor: pointBackgroundColor,
          pointBorderColor: pointBorderColor,
          pointRadius: pointRadius,
          backgroundColor: dataColors,
        });
      }

      // if (isFromSales) {
      //   data.datasets = [];
      //   data.datasets = datasets;
      // }
      // if (data_3) {
      //   data.datasets.push({
      //     label: "Diastolic",
      //     data: data_3,
      //     borderColor: "#f5f5f5",
      //     borderWidth: borderWidth,
      //     fill: false,
      //     pointBackgroundColor: pointBackgroundColor,
      //     pointBorderColor: pointBorderColor,
      //     pointRadius: pointRadius,
      //     backgroundColor: dataColors,
      //   });
      // }

      const config =
        chartType === "doughnut"
          ? doughnutConfig
          : {
              type: chartType,
              data: data,
              options: {
                responsive: true,
                animation: {
                  duration: 0,
                },
                // barThickness: 80,
                scales: {
                  x: {
                    beginAtZero: true,
                    display: displayX,
                    categorySpacing: 10,
                    barPercentage: 0.1,
                    stacked: true,
                    grid: {
                      tickWidth: 2,
                      drawOnChartArea: false,
                    },
                  },
                  y: {
                    beginAtZero: true,
                    stacked: true,

                    min: 0,
                    display: displayY,
                    grid: {
                      drawOnChartArea: false,
                    },
                  },
                },
                plugins: {
                  legend: {
                    display: false,
                  },
                  tooltip: {
                    enabled:
                      chartSize === "medium" ||
                      chartSize === "large" ||
                      isFromVillage,
                    callbacks: {
                      label: function (context: any) {
                        if (context.datasetIndex === 0) {
                          if (isFromSales) {
                            return (
                              context.dataset.label + " " + context.parsed.y
                            );
                          }
                          let check =
                            chartLabel === "BP Standing" ||
                            chartLabel === "BP Sitting"
                              ? "Systolic"
                              : chartLabel;
                          return check + " " + context.parsed.y;
                        } else if (context.datasetIndex === 1) {
                          if (isFromSales) {
                            return (
                              context.dataset.label + " " + context.parsed.y
                            );
                          }
                          let check =
                            chartLabel === "Temperature" ? " " : "Diastolic";
                          return check + " " + context.parsed.y;
                        } else {
                          if (isFromSales) {
                            return (
                              context.dataset.label + " " + context.parsed.y
                            );
                          }
                        }
                      },
                    },
                  },
                },
              },
            };
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
      }

      const ctx = chartRef.current?.getContext("2d");
      chartInstanceRef.current = new Chart(ctx, config);
    }
  }, [
    values,
    labels,
    chartLabel,
    borderWidth,
    pointBackgroundColor,
    pointBorderColor,
    pointRadius,
    dataColors,
    displayX,
    displayY,
    data_2,
    chartType,
    dynamicColor,
    chartSize,
    isFromVillage,
  ]);

  return (
    <div style={chartStyles}>
      <canvas ref={chartRef}></canvas>
    </div>
  );
};

CommonChart.defaultProps = {
  displayX: false,
  displayY: false,
  chartLabel: "Health",
};

export default React.memo(CommonChart);
