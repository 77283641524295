import React, { useEffect, useRef, useState } from "react";
import Table from "../../../components/CommonTable/Table";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../Controller/redux/Hooks";
import { useNavigate,useParams } from "react-router-dom";
import {
  getProviderLocations,
  getProviderLocationsByFilterThunk,
  resetLocationProviderData,
} from "../../../../Controller/redux/features/ProviderFeature/providerLocationSlice";
import {
  getProviderLocationEmailThunk,
  getProviderLocationPhoneThunk,
  getProviderLocationStatusThunk,
  getProviderLocationUserNameThunk,
  resetLocationPage,
  setLocationPage,
} from "../../../../Controller/redux/features/LocationFeature/locationSlice";

const ProviderLocations = () => {
  // @ts-ignore
  const dynamicLocationId = parseInt(localStorage.getItem("dynamicLocationId"));

  const tableRef = useRef();
  const locationInnerRef = useRef();
  const dispatch = useAppDispatch();
  const dropdownRefBusiness = useRef<any>();
  const svgParentRefBusiness = useRef<any>();
  const [isDropdownOpenBusiness, setIsDropdownOpenBusiness] = useState(false);
  const columns = [
    { header: "Name", accessor: "name" },
    { header: "Email", accessor: "email" },
    { header: "Phone", accessor: "phone" },
    { header: "Status", accessor: "status" },
  ];
  const { domainDetail } = useAppSelector((state) => state?.common);
  const navigate = useNavigate();
  const locationStatusDetailsArr = useAppSelector(
    (state) => state.common.statusDetailsData?.data
  );

  const handleClickRow = (e: any, id: any) => {
    navigate(
      `/providers/${window.btoa(providerId)}/locations/${window.btoa(id)}`
    );
  };

  const id: any = useParams()?.id;
  const [providerId, setProviderId] = useState<any>("");
  useEffect(() => {
    if (id) {
      let realId = window?.atob(id);
      setProviderId(realId);
    }
  }, [id]);
  useEffect(() => {
    if (providerId && dynamicLocationId) {
      // dispatch(
      //   getProviderLocations({
      //     dynamicProviderId: providerId,
      //     dynamicUserTypeId: dynamicLocationId,
      //   })
      // );
      dispatch(
        getProviderLocationUserNameThunk({
          dynamicProviderId: providerId,
          dynamicUserTypeId: dynamicLocationId,
        })
      );
      dispatch(
        getProviderLocationEmailThunk({
          dynamicProviderId: providerId,
          dynamicUserTypeId: dynamicLocationId,
        })
      );
      dispatch(
        getProviderLocationPhoneThunk({
          dynamicProviderId: providerId,
          dynamicUserTypeId: dynamicLocationId,
        })
      );
      dispatch(
        getProviderLocationStatusThunk({
          dynamicProviderId: providerId,
          dynamicUserTypeId: dynamicLocationId,
        })
      );
    }
  }, [providerId, dynamicLocationId]);

  const { locationsRecords, loading, lastPage } = useAppSelector(
    (state) => state?.providerLocations
  );

  const [providerLocationsRecords, setProviderLocationsRecords] = useState([]);

  useEffect(() => {
    if (locationsRecords) {
      setProviderLocationsRecords(locationsRecords);
    }
  }, [locationsRecords]);

  const handleClickOutsideBusiness = (event: any) => {
    if (
      dropdownRefBusiness.current &&
      !dropdownRefBusiness.current.contains(event.target) &&
      svgParentRefBusiness.current &&
      !svgParentRefBusiness.current.contains(event.target)
    ) {
      setIsDropdownOpenBusiness(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutsideBusiness);
    return () => {
      document.removeEventListener("click", handleClickOutsideBusiness);
    };
  }, []);

  const { selectedFilterItemsLocation, sortByLocationR, searchValLocation } =
    useAppSelector((state) => state?.location);
  const { filterIconColor } = useAppSelector((state) => state?.common);
  const { LocationPage, LocationFilterItems } = useAppSelector(
    (state) => state?.location
  );
  useEffect(() => {
    dispatch(resetLocationProviderData());
    dispatch(resetLocationPage());
  }, [
    LocationFilterItems?.searchVal,
    LocationFilterItems?.filterValue,
    LocationFilterItems?.sortType,
    LocationFilterItems?.status,
    dynamicLocationId,
  ]);
  useEffect(() => {
    dispatch(
      getProviderLocationsByFilterThunk({
        dynamicProviderId: providerId,
        dynamicUserTypeId: dynamicLocationId,
        filterColumn: LocationFilterItems?.filterColumn
          ? LocationFilterItems?.filterColumn
          : "",
        filterValue: LocationFilterItems?.filterValue
          ? LocationFilterItems?.filterValue
          : "",
        searchVal: LocationFilterItems?.searchVal
          ? LocationFilterItems?.searchVal
          : "",
        sortColumn: LocationFilterItems?.sortColumn
          ? LocationFilterItems?.sortColumn
          : "",
        sortType: LocationFilterItems?.sortType
          ? LocationFilterItems?.sortType
          : "",
        status: LocationFilterItems.status ? LocationFilterItems.status : "",
        LocationPage,
      })
    );
  }, [
    LocationPage,
    dynamicLocationId,
    providerId,
    LocationFilterItems?.searchVal,
    LocationFilterItems?.filterValue,
    LocationFilterItems?.sortType,
    LocationFilterItems?.status,
  ]);
  const onScroll = () => {
    if (locationInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } =
        locationInnerRef.current;

      if (
        scrollTop + clientHeight + +5 >= scrollHeight &&
        LocationPage < lastPage
      ) {
        dispatch(setLocationPage(LocationPage));
      }
    }
  };
  return (
    <div className="provider_prospect_wrapper">
      <Table
        isLoading={loading}
        tableRef={tableRef}
        tableBodyRef={locationInnerRef}
        onScroll={onScroll}
        dynamicHeaderColor={domainDetail?.listingtbl_color}
        statusType="providerLocationScreen"
        thData={columns}
        classNameTr="all-business-table-head"
        type="providerLocations"
        dropdownRef={dropdownRefBusiness}
        isDropdownOpen={isDropdownOpenBusiness}
        onClickSvg={() => setIsDropdownOpenBusiness(true)}
        svgParentRef={svgParentRefBusiness}
        allClients={providerLocationsRecords}
        itemsArr={locationStatusDetailsArr}
        onClickRow={handleClickRow}
        classNameRow="all-business-table-head"
        fill={
          selectedFilterItemsLocation.length > 0 ||
          searchValLocation.length > 0 ||
          sortByLocationR.length > 0
            ? filterIconColor
            : "#D8D3D3"
        }
      />
    </div>
  );
};

export default ProviderLocations;
