import React from "react";
import "../Styles/StickyNoteHub.css";
import { useEffect, useRef, useState } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../Controller/redux/Hooks";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { EditorState, convertToRaw } from "draft-js";
import { toast } from "react-toastify";
import {
  createDaybookItem,
  getDaybookItems,
} from "../../../../Controller/redux/features/DaybookFeature/daybookSlice";
import { formatDateForAPI } from "../../../../Model/utils/Helper/formatDate";
import {
  clearClinicalObs,
  getClinicalGraphDataThunk,
  getClinicalObsThunk,
  updateClinicalObsThunk,
} from "../../../../Controller/redux/features/ClinicalFeature/clinicalSlice";
import {
  getClientsThunk,
  getUniqueClientById,
} from "../../../../Controller/redux/features/ClientFeature/clientsSlice";
import {
  getStaffByIdThunk,
  getStaffThunk,
} from "../../../../Controller/redux/features/StaffFeature/staffSlice";
import {
  getProspectsByIdThunk,
  getProspectsThunk,
} from "../../../../Controller/redux/features/ProspectsFeature/prospectsSlice";
import {
  getContactByIdThunk,
  getContactsThunk,
} from "../../../../Controller/redux/features/ContactsFeature/contactsSlice";
import useWindowSize from "../../../../Model/utils/useWindowSize";
import {
  createCommsThunk,
  getCommsForUserThunk,
  resetAllCommsData,
  resetAllCommsPage,
  resetCommsDetailData,
  resetDetailCommsPage,
} from "../../../../Controller/redux/features/CommsFeature/commsSlice";
import { getCurrentTime } from "../../../../Model/utils/Helper/formatTime";
import {
  addAssociationThunk,
  getAssociationNamesThunk,
  getAssociations,
  getRelationshipsWithTypesThunk,
  resetRelationshipReverseData,
  setAssociationTypes,
} from "../../../../Controller/redux/features/CommonFeature/associationSlice";
import { getProspectsAssociationNamesThunk } from "../../../../Controller/redux/features/ProspectsFeature/associationProspectsSlice";

import StickyHeader from "./Components/StickyHeader";

import { getTeamsThunk } from "../../../../Controller/redux/features/TeamsFeature/teamsSlice";
import { getBusinessThunk } from "../../../../Controller/redux/features/BusinessFeature/businessSlice";

import MainFormSticky from "../Components/MainFormSticky";
import {
  resetButtonTypeSticky,
  resetchangeStickyForClinicalWhenMobileView,
  setButtonTypeSticky,
  setShowStickyNote,
  setchangeStickyForClinicalWhenMobileView,
} from "../../../../Controller/redux/features/CommonFeature/commonSlice";
import ClinicalTabSticky from "../Components/ClinicalTabSticky/ClinicalTabSticky";
import { setTypeAssociation } from "../../../../Controller/redux/features/LocationFeature/locationSlice";
import { resetcurrentEditCustomForm } from "../../../../Controller/redux/features/CustomFromFeature/customFormSlice";
import { setshowUnsavedModal } from "../../../../Controller/redux/features/AuthFeature/authSlice";
import UnsaveInline from "../../ConfirmationModal/UnsaveInline";

const StickyNoteHub = ({
  title,
  type,
  titleHub,
  isFinanceScreen,
  isOutside,
  isCommsActive,
  setIsSubTaskOpenComms = () => {},
  extraType,
}: // setcurrentEditCustomForm = () => {},
any) => {

  // console.log('isCommsActive', isCommsActive);
  // console.log('extraType', extraType);
  const popupRef = useRef<any>(null);
  const markerJsChildRef = useRef<any>(null);
  // const loginUserId = useAppSelector((state) => state?.common?.user?.id);

  const pathname = useLocation()?.pathname;
let isLocation = pathname.includes("locations");

  const [paramsId, setparamsId] = useState("");

  // @ts-ignore
  const idFromParams: any = useParams()?.id;
  useEffect(() => {
    if (idFromParams) {
      let realId = window.atob(idFromParams);
      setparamsId(realId);
    }
  }, [idFromParams]);

  useEffect(() => {
    if (
      pathname.includes("clients") ||
      pathname.includes("staff") ||
      pathname.includes("prospects") ||
      pathname.includes("business") ||
      pathname.includes("teams") ||
      pathname.includes("contacts") ||
      pathname.includes("locations")
    ) {
      setClientId(paramsId);
    }
  }, [pathname, idFromParams, paramsId]);

  // const { dynamicColor } = useAppSelector((state) => state?.common);
  const backenderror = useAppSelector(
    (state) => state?.associations?.error?.data?.data
  );

  const [clientId, setClientId] = useState<any>("");
  const { width } = useWindowSize();

  const [isMobileScreen, setIsMobileScreen] = useState<boolean>(false);
  const { getProfileTypeSticky, getButtonTypeSticky } = useAppSelector(
    (state) => state?.common
  );

  // console.log(getButtonTypeSticky, "getButtonTypeSticky");
  // New Changes Start  //
  const { profile_name } = useAppSelector((state) => state.common.domainDetail);
  const [selectedName, setselectedName] = useState("");
  const [selectedType, setselectedType] = useState("");
  const [relationship, setRelationship] = useState("");
  const [reverseRelationship, setreverseRelationship] = useState("");

  const [isThereAnyChanges, setisThereAnyChanges] = useState(false);

  console.log("isThereAnyChanges____", isThereAnyChanges);
  const [connectionTwoId, setconnectionTwoId] = useState<any>(null);
  const [connectionTwoTable, setconnectionTwoTable] = useState("");
  const [activeTab, setActiveTab] = useState<string>("");
  const [activeTabInner, setActiveTabInner] = useState<string>("");
  const [activeTabProfile, setActiveTabProfile] =
    useState<string>(profile_name);
  const getNames = useAppSelector(
    (state) => state.associations.getAssociationNames
  );
  const { allCommsPage } = useAppSelector((state) => state?.comms);
  const getTypes = useAppSelector(
    (state) => state.associations.getAssociationTypes
  );

  const { showStickyNote, isDocumentIsOpen } = useAppSelector(
    (state) => state?.common
  );
  const handleNameChange = (event: any, props: any) => {
    if (props === "associations") {
      setselectedName(event.target.value);
      const getIdByName = getNames.find(
        (elem: any) =>
          elem.name.toLowerCase() === event.target.value.toLowerCase()
      );
      setconnectionTwoId(getIdByName?.id);
    } else if (props === "prospectsAssociations") {
    } else if (props === "staffAssociation") {
    }
  };

  // @ts-ignore
  const dynamicClientId = parseInt(localStorage.getItem("dynamicClientId"));
  // @ts-ignore
  const dynamicStaffId = parseInt(localStorage.getItem("dynamicStaffId"));
  // @ts-ignore
  const dynamicProspectId = parseInt(localStorage.getItem("dynamicProspectId"));
  // @ts-ignore

  const dynamicContactId = parseInt(localStorage.getItem("dynamicContactId"));
  // @ts-ignore

  const dynamicBusinessId = parseInt(localStorage.getItem("dynamicBusinessId"));
  // @ts-ignore
  const dynamicTeamsId = parseInt(localStorage.getItem("dynamicTeamsId"));
  // @ts-ignore
  const dynamicLocationId = parseInt(localStorage.getItem("dynamicLocationId"));

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const [getRelationshipsDataWithTypes, setgetRelationshipsDataWithTypes] =
    useState({
      parent: "",
      child: "",
    });

  useEffect(() => {
    setgetRelationshipsDataWithTypes((prevDetails: any) => ({
      ...prevDetails,
      parent: pathname.includes("prospects")
        ? dynamicProspectId
        : pathname.includes("clients")
        ? dynamicClientId
        : pathname.includes("staff")
        ? dynamicStaffId
        : pathname.includes("contacts")
        ? dynamicContactId
        : pathname.includes("business")
        ? dynamicBusinessId
        : pathname.includes("teams")
        ? dynamicTeamsId
        : "",
    }));
    dispatch(
      setAssociationTypes({
        parent: getRelationshipsDataWithTypes.parent,
        child: "",
      })
    );
  }, [
    dynamicProspectId,
    dynamicClientId,
    dynamicStaffId,
    dynamicContactId,
    dynamicBusinessId,
    dynamicTeamsId,
    clientId,
  ]);
  const handleTypeSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    dispatch(resetRelationshipReverseData());
    setselectedType(e.target.value);
    let childTypeSelected = e.target.value.toLowerCase().includes("prospect")
      ? dynamicProspectId
      : e.target.value.toLowerCase().includes("client")
      ? dynamicClientId
      : e.target.value.toLowerCase().includes("staff")
      ? dynamicStaffId
      : e.target.value.toLowerCase().includes("contact")
      ? dynamicContactId
      : e.target.value.toLowerCase().includes("business")
      ? dynamicBusinessId
      : e.target.value.toLowerCase().includes("team")
      ? dynamicTeamsId
      : e.target.value.toLowerCase().includes("location")
      ? dynamicLocationId
      : "";
    dispatch(
      setAssociationTypes({
        parent: getRelationshipsDataWithTypes.parent,
        child: childTypeSelected,
      })
    );
    let parentTypeSelected = getRelationshipsDataWithTypes.parent;
    dispatch(
      getRelationshipsWithTypesThunk({
        parent: parentTypeSelected,
        child: childTypeSelected,
      })
    );
    setselectedName("");
    const getTypeObject = getTypes.find(
      (type: any) => type.desc === e.target.value
    );
    dispatch(setTypeAssociation(getTypeObject?.desc));
    const id = getTypeObject?.id;
    const model = getTypeObject?.model_name;
    setconnectionTwoTable(model);
    dispatch(getAssociationNamesThunk({ model, id }));
    dispatch(getProspectsAssociationNamesThunk({ model, id }));
  };
  let pathanme = useLocation().pathname;
  const formData = new FormData();
  const reverseRelationships = useAppSelector(
    (state) => state.associations.getReverseRelationshipsWithTypes
  );
  if (relationship !== null && typeof relationship !== "undefined") {
    formData.append("relationship_name", relationship);
  }
  formData.append("connection_one", clientId);
  formData.append("connection_two", connectionTwoId);
  formData.append("connection_one_table", "User");
  formData.append("connection_two_table", connectionTwoTable);
  formData.append(
    "reverse_relationship_name",
    reverseRelationships?.length === 1
      ? reverseRelationships[0]
      : reverseRelationship
  );

  const handleSaveAssociation = async () => {
    if (selectedName !== "" && selectedType !== "") {
      const res = await dispatch(addAssociationThunk(formData));
      if (res.payload.status === "success") {
        toast.success("Association Created Successfully!", {
          style: {},
          progressStyle: { backgroundColor: dynamicColor },
          icon: (
            <span
              style={{
                marginRight: "8px",
                borderRadius: "50%",
                width: "7rem",
                height: "35px",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: dynamicColor,
                backgroundColor: "white",
                fontSize: "1.5rem",
                border: `3px solid ${dynamicColor}`,
              }}
            >
              ✓
            </span>
          ),
        });
        setRelationship("");
        setselectedName("");
        setselectedType("");
        setreverseRelationship("");
        // TO UPDATE THE DATA IN COMMON TABLE FOR ASSOCIATIONS.
        dispatch(
          getAssociations({
            clientId,
            searchVal: "",
            type: "",
            pageNumber: 1,
          })
        );
        const stickyNoteElement = document.querySelector(
          ".new_sticky_note_wrapper"
        );
        if (stickyNoteElement) {
          stickyNoteElement.classList.add("fade_out");
          setTimeout(() => {
            // dispatch(setShowStickyNote(false));
          }, 300);
        }
      } else {
        let errorArray = Object.entries(backenderror);
        let err = "";
        for (let [key, value] of errorArray) {
          err += value;
        }
        toast.error(`${err}`);
      }
    } else {
      toast.error("All Fields Required!");
    }
  };
  // New Changes End //
  useEffect(() => {
    if (width <= 600) {
      setIsMobileScreen(true);
    } else {
      setIsMobileScreen(false);
    }
  }, [width]);

  const id = useParams()?.id;

  useEffect(() => {
    if (id) {
      const realId = window.atob(id);
      setClientId(realId);
    }
  }, [id]);
  const ClinicalTabArr = [
    {
      title: "Temperature",
    },
    {
      title: "Weight",
    },
    {
      title: "Heart Rate",
    },
    {
      title: "Respirations",
    },
    {
      title: "BP Standing",
    },
    {
      title: "BP Sitting",
    },
  ];
  const [hasUnsavePopup, setHasUnsavePopup] = useState(false);
  const [isSubTaskOpen, setIsSubTaskOpen] = useState<number>(-1);
  const [showLights, setShowLights] = useState<boolean>(false);
  const [trafficColors, setTrafficColors] = useState<any>("");

  console.log("hasUnsavePopup", hasUnsavePopup);
  const receiveMarkerJsChildRef = (ref: any) => {
    markerJsChildRef.current = ref;
  };

  useEffect(() => {
    // @ts-ignore
    const handleOutsideClick = (event) => {
      if (
        !event.target.closest(".new_sticky_note_wrapper") &&
        getButtonTypeSticky !== "Support"
      ) {
        if (!isDocumentIsOpen) {
          if (!isThereAnyChanges) {
            dispatch(setShowStickyNote(false));
          } else {
            setHasUnsavePopup(true);
          }
          dispatch(resetcurrentEditCustomForm());
        }
        dispatch(clearClinicalObs());
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [
    showStickyNote,
    getButtonTypeSticky,
    isDocumentIsOpen,
    isThereAnyChanges,
  ]);

  // useEffect(() => {
  //   const stickyNoteElement = document.querySelector(
  //     ".new_sticky_note_wrapper"
  //   );
  //   if (stickyNoteElement) {
  //     if (showStickyNote) {
  //       stickyNoteElement.classList.remove("fade_out");
  //       stickyNoteElement.classList.add("fade_in");
  //       dispatch(setShowStickyNote(true));
  //     } else {
  //       stickyNoteElement.classList.remove("fade_in");
  //       stickyNoteElement.classList.add("fade_out");
  //       dispatch(setShowStickyNote(false));
  //     }
  //   }
  // }, [showStickyNote]);
  // domainDetail
  const { domainDetail } = useAppSelector((state) => state?.common);
  const [dynamicColor, setDynamicColor] = useState("");
  useEffect(() => {
    if (domainDetail) {
      setDynamicColor(domainDetail?.sticky_background_color);
    }
  }, [domainDetail]);

  const dispatch = useAppDispatch();
  // const navigate = useNavigate();

  // // const handleLogout = async () => {
  // //   localStorage.clear();
  // //   await dispatch(logoutThunk());
  // //   navigate("/login");
  // // };

  const [clinicalDate, setClinicalDate] = useState<Date | any>(new Date());
  const clinicalObsArray = [
    { name: "Temperature", value1: "", value2: "" },
    { name: "Weight", value1: "", value2: "" },
    { name: "Heart Rate", value1: "", value2: "" },
    { name: "Respirations", value1: "", value2: "" },
    { name: "Blood Pressure Standing", value1: "", value2: "" },
    { name: "Blood Pressure Sitting", value1: "", value2: "" },
  ];
  const [clinicalObs, setclinicalObs] = useState<any>(clinicalObsArray);
  const initialValue = {
    user_id: clientId,
    date: clinicalDate ? formatDateForAPI(clinicalDate) : "",
    clinical_observations: [...clinicalObs],
    time: "",
  };

  const clientStickyColumnArr = [
    { header: "Name", accessor: "first_name" },
    { header: "Contact", accessor: "email" },
    { header: "Status", accessor: "status" },
  ];

  const [clinicalData, setClinicalData] = useState<any>(initialValue);
  const [clinicalVal1, setClinicalVal1] = useState<any>("");
  const [clinicalVal2, setClinicalVal2] = useState<any>("");
  const [clinicalVal3, setClinicalVal3] = useState<any>("");
  const [clinicalName, setClinicalName] = useState<any>("Temperature");
  const [time, setTime] = useState<any>(getCurrentTime());

  useEffect(() => {
    setClinicalData({
      user_id: clientId,
      date: clinicalDate ? formatDateForAPI(clinicalDate) : "",
      clinical_observations: [...clinicalObs].filter((row: any) => row.value1),
      time: time,
    });
  }, [clientId, clinicalDate, clinicalObs, time]);

  const handleOpenSubTask = (e: any, id: any) => {
    e?.stopPropagation();
    setIsSubTaskOpen(id);
  };

  useEffect(() => {
    const tempObj = {
      value1: clinicalVal1,
      value2:
        clinicalName === "Blood Pressure Standing"
          ? clinicalVal2
          : clinicalName === "Blood Pressure Sitting"
          ? clinicalVal3
          : "",
      name: clinicalName,
    };
    const existingIndex = clinicalObs?.findIndex(
      (elem: any) => elem.name.toLowerCase() === clinicalName.toLowerCase()
    );
    if (existingIndex !== -1) {
      const updatedClinicalObs = [...clinicalObs];
      updatedClinicalObs[existingIndex] = tempObj;
      setclinicalObs(updatedClinicalObs);
    } else {
      setclinicalObs([...clinicalObs, tempObj]);
    }
  }, [clinicalName, clinicalVal1, clinicalVal2, clinicalVal3]);

  const handleChangeValue = (e: any, index: any, name: string) => {
    setClinicalVal1(e.target.value);
    setClinicalName(name);
  };
  const handleChangeValueTemp = (e: any, index: any, name: string) => {
    if (name === "Blood Pressure Standing") {
      setClinicalVal2(e.target.value);
    } else if (name === "Blood Pressure Sitting") {
      setClinicalVal3(e.target.value);
    }
    setClinicalName(name);
  };

  const onSaveClinicalObservations = async () => {
    clearClinicalObs();
    if (clinicalDate.length !== 0) {
      try {
        const res = await dispatch(updateClinicalObsThunk(clinicalData));
        if (res.payload.status === "success") {
          toast.success("Clinical Observations updated successfully!", {
            progressStyle: { backgroundColor: dynamicColor },
            icon: (
              <span
                style={{
                  marginRight: "8px",
                  borderRadius: "50%",
                  width: "7rem",
                  height: "35px",
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: dynamicColor,
                  backgroundColor: "white",
                  fontSize: "1.5rem",
                  border: `3px solid ${dynamicColor}`,
                }}
              >
                ✓
              </span>
            ),
          });
          for (const item of ClinicalTabArr) {
            dispatch(
              getClinicalGraphDataThunk({
                clientId: clientId,
                observation:
                  item?.title === "BP Standing"
                    ? "Blood Pressure Standing"
                    : item?.title === "BP Sitting"
                    ? "Blood Pressure Sitting"
                    : item?.title,
                days: 30,
              })
            );
          }
        } else {
          toast.error("An Error Occurred!");
        }
        // setIsStickyNoteOpen(false);
        dispatch(setShowStickyNote(false));
      } catch (error: any) {
        toast.error("An Error Occurred!");
        dispatch(setShowStickyNote(false));
        // setIsStickyNoteOpen(false);
      }
    } else {
      if (clinicalDate.length === 0) {
        toast.error("Date is Required!");
      }
    }
  };

  const { user } = useAppSelector((state) => state?.common);

  let fullName = ` ${user?.first_name ? user?.first_name : ""} ${
    user?.last_name?.charAt(0) ? user?.last_name?.charAt(0) : ""
  }`;
  let email = user?.email ? user?.email : "";

  // //////////////////////////////////////// Daybook Sticky //////////////////////////////////////

  const [dayBookDetails, setDayBookDetails] = useState<any>({
    title: "",
    summary: "",
    assigned_to: "",
    tagged_user: [],
    date: "",
  });
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");

  const [userId, setUserId] = useState("");

  useEffect(() => {
    let loggedInUserId = localStorage.getItem("loggedInUserId");
    if (loggedInUserId) {
      setUserId(loggedInUserId);
      setDayBookDetails({
        ...dayBookDetails,
        assigned_to: loggedInUserId,
      });
    }
  }, []);

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [isThereMessage, setisThereMessage] = useState<any>(null);
  const [commsMessageToSendToBackend, setcommsMessageToSendToBackend] =
    useState("");

  const handleEditorStateChange = (newEditorState: any) => {
    setEditorState(newEditorState);
    const contentState = newEditorState.getCurrentContent();
    const rawContentState = convertToRaw(contentState);
    const newObject = {
      entityMap: rawContentState.entityMap,
      blocks: rawContentState.blocks.map((block) => ({
        key: block.key,
        text: block.text,
        type: block.type,
        depth: block.depth,
        inlineStyleRanges: block.inlineStyleRanges,
        entityRanges: block.entityRanges,
      })),
    };
    setisThereMessage(newObject);
    const contentString = JSON.stringify(newObject);
    setcommsMessageToSendToBackend(contentString);
  };
  const { clientDataForDayBook } = useAppSelector((state) => state?.people);
  const { staffDataDayBook } = useAppSelector((state) => state?.staff);
  const { getClinicalObs } = useAppSelector((state) => state?.clinical);
  const { getProspectsData } = useAppSelector((state) => state?.prospects);
  const { getContactsData } = useAppSelector((state) => state?.contacts);
  const { allDataBusiness } = useAppSelector((state) => state?.business);
  const { allDataTeams } = useAppSelector((state) => state?.teams);

  const [clientsDataForAssignedTo, setClientsDataForAssignedTo] = useState<any>(
    []
  );

  const [clientsDataForTaggedUser, setClientsDataForTaggedUser] = useState<any>(
    []
  );

  useEffect(() => {
    dispatch(getClientsThunk({ dynamicClientId: dynamicClientId }));
    dispatch(getStaffThunk({ dynamicStaffId: dynamicStaffId }));
    dispatch(getProspectsThunk({ dynamicProspectsId: dynamicProspectId }));
    dispatch(getContactsThunk({ dynamicContactId: dynamicContactId }));
    dispatch(getTeamsThunk(dynamicTeamsId));
    dispatch(getBusinessThunk(dynamicBusinessId));
  }, [
    dynamicStaffId,
    dynamicClientId,
    dynamicProspectId,
    dynamicContactId,
    dynamicTeamsId,
    dynamicBusinessId,
  ]);

  const [contactsData, setcontactsData] = useState([]);
  const [prospectsData, setprospectsData] = useState([]);
  const [businessData, setBusinessData] = useState([]);
  const [teamsData, setTeamsData] = useState([]);

  // // console.log(prospectsData,"prospectsData")
  useEffect(() => {
    if (getProspectsData) {
      setprospectsData(getProspectsData?.data?.data);
    }
  }, [getProspectsData]);
  // // console.log(prospectsData,"prospectsData")
  useEffect(() => {
    if (getContactsData) {
      setcontactsData(getContactsData?.data?.data);
    }
  }, [getContactsData]);

  useEffect(() => {
    if (allDataBusiness?.data?.data) {
      setBusinessData(allDataBusiness?.data?.data);
    }
  }, [allDataBusiness]);

  useEffect(() => {
    if (allDataTeams?.data?.data) {
      setTeamsData(allDataTeams?.data?.data);
    }
  }, [allDataTeams]);

  useEffect(() => {
    if (
      staffDataDayBook &&
      prospectsData &&
      contactsData &&
      clientDataForDayBook &&
      businessData &&
      teamsData
    ) {
      setClientsDataForAssignedTo([
        ...clientDataForDayBook,
        ...staffDataDayBook,
        ...prospectsData,
        ...contactsData,
        ...businessData,
        ...teamsData,
      ]);
    }
  }, [
    staffDataDayBook,
    prospectsData,
    contactsData,
    clientDataForDayBook,
    businessData,
    teamsData,
  ]);

  useEffect(() => {
    if (
      clientDataForDayBook &&
      staffDataDayBook &&
      contactsData &&
      prospectsData &&
      businessData &&
      teamsData
    ) {
      setClientsDataForTaggedUser([
        ...clientDataForDayBook,
        ...staffDataDayBook,
        ...prospectsData,
        ...contactsData,
        ...businessData,
        ...teamsData,
      ]);
    }
  }, [
    clientDataForDayBook,
    staffDataDayBook,
    prospectsData,
    contactsData,
    businessData,
    teamsData,
  ]);

  useEffect(() => {
    if (getClinicalObs?.data?.length > 0) {
      const clinicalObs = clinicalObsArray.map((elem: any) => {
        const row = getClinicalObs?.data?.find(
          (obj: any) => obj.name === elem.name
        );

        if (row) {
          return { name: elem.name, value1: row.value1, value2: row.value2 };
        }
        return elem;
      });

      setclinicalObs(clinicalObs);
    } else {
      setclinicalObs(clinicalObsArray);
    }
  }, [getClinicalObs, clientId]);

  useEffect(() => {
    if (clientId && clinicalDate && time) {
      dispatch(
        getClinicalObsThunk({
          dynamicId: clientId,
          date: formatDateForAPI(clinicalDate),
          time: time,
        })
      );
    }
  }, [clientId, clinicalDate, time]);

  useEffect(() => {
    dispatch(
      getClinicalObsThunk({
        dynamicId: clientId,
        date: formatDateForAPI(clinicalDate),
        time: time,
      })
    );
  }, [time]);

  const onSavetrigger = async () => {
    if (dayBookDetails.title) {
      let formattedDate = "";

      if (dayBookDetails.date) {
        const currentDate = new Date(dayBookDetails.date);
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, "0");
        const day = String(currentDate.getDate()).padStart(2, "0");
        formattedDate = `${year}-${month}-${day}`;
      }

      let requiredParmas = {
        ...dayBookDetails,
        communications: [
          {
            summary: "",
            message: commsMessageToSendToBackend,
          },
        ],
        date: formattedDate,
        start_time: startTime,
        end_time: endTime,
      };
      try {
        const response = await dispatch(createDaybookItem(requiredParmas));

        if (response?.meta?.requestStatus === "fulfilled") {
          toast.success("Task added successfully!", {
            style: {},
            progressStyle: { backgroundColor: dynamicColor },
            icon: (
              <span
                style={{
                  marginRight: "8px",
                  borderRadius: "50%",
                  width: "7rem",
                  height: "35px",
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: dynamicColor,
                  backgroundColor: "white",
                  fontSize: "1.5rem",
                  border: `3px solid ${dynamicColor}`,
                }}
              >
                ✓
              </span>
            ),
          });
          dispatch(getDaybookItems({ userId: userId, date: formattedDate }));

          setDayBookDetails({
            title: "",
            summary: "",
            assigned_to: "",
            tagged_user: [],
            date: "",
          });
          setEditorState(EditorState?.createEmpty());
          setStartTime("");
          setEndTime("");
          dispatch(setShowStickyNote(false));
          // setIsStickyNoteOpen(false);
        }
      } catch (error) {
        throw error;
      }
    } else {
      toast.warning("Task Title Is Mandatory!");
    }
  };
  let error = useAppSelector((state) => state.comms.error);
  const onSaveComms = async () => {
    if (dayBookDetails.title && commsMessageToSendToBackend !== "") {
      let requiredParams = {
        title: dayBookDetails?.title,
        summary: dayBookDetails?.summary,
        communications: [
          {
            summary: dayBookDetails?.summary,
            message: commsMessageToSendToBackend,
          },
        ],
      };
      try {
        const response = await dispatch(createCommsThunk(requiredParams));
        if (response?.payload?.status === "success") {
          toast.success("Comms added successfully!", {
            style: {},
            progressStyle: { backgroundColor: dynamicColor },
            icon: (
              <span
                style={{
                  marginRight: "8px",
                  borderRadius: "50%",
                  width: "7rem",
                  height: "35px",
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: dynamicColor,
                  backgroundColor: "white",
                  fontSize: "1.5rem",
                  border: `3px solid ${dynamicColor}`,
                }}
              >
                ✓
              </span>
            ),
          });

          setDayBookDetails({
            title: "",
            summary: "",
            assigned_to: "",
            tagged_user: [],
            date: "",
          });
          setEditorState(EditorState?.createEmpty());
          // setIsStickyNoteOpen(false);
          dispatch(setShowStickyNote(false));
          dispatch(resetAllCommsData());
          dispatch(resetAllCommsPage());
          dispatch(resetCommsDetailData());
          dispatch(resetDetailCommsPage());
          dispatch(
            getCommsForUserThunk({ clientId: clientId, page: allCommsPage })
          );
        } else {
          toast.error("Comms cant be created without a mentioned user");
        }
      } catch (error) {
        // console.log(error);
      }
    } else {
      if (dayBookDetails?.title === "") {
        toast.warning("Title Is Mandatory!");
      } else if (commsMessageToSendToBackend === "") {
        toast.warning("Comms Is Mandatory!");
      } else {
        toast.error("Comms cant be created without a mentioned user");
      }
    }
  };
  const [currentSelectedBTN, setcurrentSelectedBTN] = useState("All");
  const [daybookDate, setDaybookDate] = useState<any>(null);

  const [isOpenQuickMenu, setIsOpenQuickMenu] = useState(false);

  const [
    mobileScreenStickyNoteCurrentSlide,
    setMobileScreenStickyNoteCurrentSlide,
  ] = useState("Daybook");

  const [customTitle, setCustomTitle] = useState("");

  const [tabsArray, setTabsArray] = useState(["All", "New"]);

  useEffect(() => {
    if (type == "stickyDaybook") {
      setCustomTitle(title);
    }
  }, [type]);

  const [clientDetails, setClientDetails] = useState<any>({});
  const { uniqueClient } = useAppSelector((state) => state.people);

  useEffect(() => {
    if (uniqueClient?.data?.data.length > 0) {
      let info = uniqueClient?.data?.data[0];
      setClientDetails(info);
      setCustomTitle(`Client - ${info?.first_name} ${info?.last_name}`);
    } else {
      setClientDetails(null);
    }
  }, [uniqueClient]);

  const getCurrentStaff = useAppSelector(
    (state: any) => state.staff.getUniqueStaff
  );

  const [currentStaff, setCurrentStaff] = useState<any>("");

  useEffect(() => {
    if (getCurrentStaff?.data?.data.length > 0) {
      let info = getCurrentStaff?.data?.data[0];
      setCurrentStaff(info);
      setCustomTitle(`Staff - ${info?.first_name} ${info?.last_name}`);
    } else {
      setCurrentStaff(null);
    }
  }, [getCurrentStaff]);

  const getCurrentContact = useAppSelector(
    (state: any) => state.contacts.getUniqueContact
  );

  const [currentContact, setcurrentContact] = useState<any>(null);

  useEffect(() => {
    if (getCurrentContact?.data?.data.length > 0) {
      let info = getCurrentContact?.data?.data[0];
      setcurrentContact(info);
      setCustomTitle(`Contact - ${info?.first_name} ${info?.last_name}`);
    } else {
      setcurrentContact(null);
    }
  }, [getCurrentContact, clientId]);

  const getCurrentProspects = useAppSelector(
    (state: any) => state?.prospects?.getUniqueProspect
  );
  const [currentProspects, setcurrentProspects] = useState<any>(null);

  useEffect(() => {
    if (getCurrentProspects?.data?.data.length > 0) {
      let info = getCurrentProspects?.data?.data[0];
      setCustomTitle(`Prospect - ${info?.first_name} ${info?.last_name}`);
      setcurrentProspects(info);
    } else {
      setcurrentProspects(null);
    }
  }, [getCurrentProspects, clientId]);

  const handleClickClientRow = (e: any, id: any, type = "Client") => {
    e.stopPropagation();
    setMobileScreenStickyNoteCurrentSlide(`${type.toLocaleLowerCase()}Details`);
    setTabsArray(["Details", "Associations", "Clinical"]);
    setcurrentSelectedBTN("Details");
    if (type == "Client") {
      dispatch(getUniqueClientById({ typeId: dynamicClientId, clientId: id }));
    } else if (type == "Staff") {
      dispatch(getStaffByIdThunk({ typeId: dynamicStaffId, clientId: id }));
    } else if (type == "Contact") {
      dispatch(getContactByIdThunk({ typeId: dynamicContactId, clientId: id }));
    } else if (type == "Prospect") {
      dispatch(getProspectsByIdThunk({ dynamicProspectId, clientId: id }));
    }
  };

  const [isDropdownOpenClients, setIsDropdownOpenClients] = useState(false);

  useEffect(() => {
    if (currentSelectedBTN === "Clinical" && isMobileScreen) {
      dispatch(setchangeStickyForClinicalWhenMobileView(true));
    } else {
      dispatch(resetchangeStickyForClinicalWhenMobileView(false));
    }
  }, [currentSelectedBTN, width]);
  const handleTabChange = (tab: string) => {
    setActiveTab(tab);
  };
  const handleProfileTabChange = (tab: string) => {
    setActiveTabProfile(tab);
  };
  const handleTabChangeInner = (tab: string) => {
    setActiveTabInner(tab);
  };
  useEffect(() => {
    if (isMobileScreen) {
      dispatch(resetButtonTypeSticky());
      dispatch(resetcurrentEditCustomForm());
    }
  }, [isMobileScreen]);

  useEffect(() => {
    if (type == "form") {
      dispatch(setButtonTypeSticky(type));
    }
  }, [type]);

  return (
    <div>
      <div className="new_sticky_note_wrapper" ref={popupRef}>
        <ClinicalTabSticky
          isMobileScreen={isMobileScreen}
          type={type}
          isFinanceScreen={true}
          title={title}
          handleProfileTabChange={handleProfileTabChange}
          handleTabChangeInner={handleTabChangeInner}
          setTime={setTime}
          time={time}
          clinicalDate={clinicalDate}
          setClinicalDate={setClinicalDate}
          midleSection={
            <div>
              {type === "stickyClinical" && (
                <StickyHeader
                  activeTabInner={activeTabInner}
                  type={type}
                  isMobileScreen={isMobileScreen}
                  isOpenQuickMenu={isOpenQuickMenu}
                  setIsOpenQuickMenu={setIsOpenQuickMenu}
                  customTitle={customTitle}
                  title={title}
                  setMobileScreenStickyNoteCurrentSlide={
                    setMobileScreenStickyNoteCurrentSlide
                  }
                  setCustomTitle={setCustomTitle}
                  setTabsArray={setTabsArray}
                  setcurrentSelectedBTN={setcurrentSelectedBTN}
                  tabsArray={tabsArray}
                  currentSelectedBTN={currentSelectedBTN}
                  daybookDate={daybookDate}
                  setDaybookDate={setDaybookDate}
                  setTime={setTime}
                  time={time}
                  clinicalDate={clinicalDate}
                  setClinicalDate={setClinicalDate}
                  isFormClinical={true}
                  handleTabChange={handleTabChange}
                />
              )}

              <MainFormSticky
                extraType={extraType}
                isCommsActive={isCommsActive}
                sendMarkerRef={receiveMarkerJsChildRef}
                setIsSubTaskOpen={setIsSubTaskOpen}
                setIsSubTaskOpenComms={setIsSubTaskOpenComms}
                // activeTab={getButtonTypeSticky}
                activeTabProfile={activeTabProfile}
                mobileScreenStickyNoteCurrentSlide={
                  mobileScreenStickyNoteCurrentSlide
                }
                setisThereAnyChangesComms={setisThereAnyChanges}
                isThereAnyChanges={isThereAnyChanges}
                isOutside={isOutside}
                currentTitle={title}
                currentSelectedBTN={currentSelectedBTN}
                trafficColors={trafficColors}
                setShowLights={setShowLights}
                showLights={showLights}
                setTrafficColors={setTrafficColors}
                dayBookDetails={dayBookDetails}
                setDayBookDetails={setDayBookDetails}
                startTime={startTime}
                endTime={endTime}
                setStartTime={setStartTime}
                setEndTime={setEndTime}
                editorState={editorState}
                handleEditorStateChange={handleEditorStateChange}
                clientsDataForAssignedTo={clientsDataForAssignedTo}
                clientsDataForTaggedUser={clientsDataForTaggedUser}
                onSavetrigger={onSavetrigger}
                setDaybookDate={setDaybookDate}
                daybookDate={daybookDate}
                setcurrentSelectedBTN={setcurrentSelectedBTN}
                clientDataForDayBook={clientDataForDayBook}
                handleClickClientRow={handleClickClientRow}
                staffDataDayBook={staffDataDayBook}
                prospectsData={prospectsData}
                contactsData={contactsData}
                clientDetails={clientDetails}
                currentStaff={currentStaff}
                currentContact={currentContact}
                currentProspects={currentProspects}
                clientStickyColumnArr={clientStickyColumnArr}
                // dropdownRefClients={dropdownRefClients}
                isDropdownOpenClients={isDropdownOpenClients}
                // svgParentRefClients={svgParentRefClients}
                titleHub={titleHub}
                onSaveComms={onSaveComms}
                clinicalObs={clinicalObs}
                isFinanceScreen={isFinanceScreen}
                isSubTaskOpen={isSubTaskOpen}
                type={
                  isFinanceScreen
                    ? "stickyHub"
                    : type == "form"
                    ? "form"
                    : getButtonTypeSticky
                    ? getButtonTypeSticky
                    : type
                }
                typeProfile={getProfileTypeSticky}
                handleChangeValueTemp={handleChangeValueTemp}
                handleChangeValue={handleChangeValue}
                handleOpenSubTask={handleOpenSubTask}
                selectedName={selectedName}
                selectedType={selectedType}
                formData={formData}
                relationship={relationship}
                setRelationship={setRelationship}
                handleSaveAssociation={handleSaveAssociation}
                handleTypeSelect={handleTypeSelect}
                handleNameChange={handleNameChange}
                setreverseRelationship={setreverseRelationship}
                reverseRelationship={reverseRelationship}
                // setShowStickyNote={setIsStickyNoteOpen}
              />
              {getButtonTypeSticky === "Obs" && (
                <div className="save_btn_wrapper save_btn_clinical new_sticky_clinicla_save_btn">
                  <button
                    className="save_btn_sticky_daybook"
                    onClick={() => {
                      onSaveClinicalObservations();
                    }}
                  >
                    Save
                  </button>
                </div>
              )}
            </div>
          }
        />
      </div>
      {hasUnsavePopup && (
        <div
          style={{
            position: "absolute",
            width: "100%",

            top: "93%",
          }}
        >
          <UnsaveInline
            oncancel={() => {
              setHasUnsavePopup(false);
            }}
            onsave={() => {
              setHasUnsavePopup(false);
              dispatch(setShowStickyNote(false));
            }}
          />
        </div>
      )}
    </div>
  );
};

StickyNoteHub.defaultProps = {
  title: "",
  titleHub: "New Comms",
};

export default StickyNoteHub;
