import React from "react";

const QuestionMarkSVG = ({ onClick = () => {} }: any) => {
  return (
    <svg
      onClick={onClick}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      cursor={"pointer"}
    >
      <path
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="#F3F3F3"
      />
      <path
        d="M13.822 14.528H11.87C11.87 13.696 11.982 13.04 12.222 12.576C12.382 12.224 12.798 11.792 13.454 11.28C13.662 11.12 13.822 11.008 13.918 10.928C14.014 10.848 14.11 10.752 14.222 10.624C14.318 10.512 14.414 10.384 14.51 10.224C14.638 10.016 14.718 9.792 14.718 9.536C14.718 8.576 14.158 8.08 13.038 8.08C12.398 8.08 11.918 8.24 11.614 8.56C11.31 8.88 11.15 9.392 11.118 10.128H9.086C9.086 8.88 9.422 7.952 10.11 7.312C10.782 6.672 11.774 6.352 13.07 6.352C14.238 6.352 15.166 6.624 15.854 7.152C16.526 7.68 16.878 8.416 16.878 9.376C16.878 10.416 16.318 11.344 15.214 12.176C14.606 12.624 14.206 13.008 14.046 13.296C13.886 13.552 13.822 13.952 13.822 14.528ZM14.014 18H11.678V15.712H14.014V18Z"
        fill="white"
      />
    </svg>
  );
};

export default QuestionMarkSVG;
