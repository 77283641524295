import React, { useEffect } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../Controller/redux/Hooks";
import {
  setAllowedVendorIds,
} from "../../../Controller/redux/features/VendorFeature/VendorSlice";

function VendorCard({
  vendor_name,
  profile_image_s3,
  has_access,
  id,
}) {

  const dispatch = useAppDispatch();
  const { allowedVendorIds } = useAppSelector((state) => state.vendors);

  const handleAllowedVendors = (event) => {
    const ids = allowedVendorIds
      .split(",")
      .map(Number)
      .filter((n) => !isNaN(n));
    const index = ids.indexOf(id);
    if (index === -1) {
      ids.push(id);
    } else {
      ids.splice(index, 1);
    }
    dispatch(setAllowedVendorIds(ids.join(",")));
  };


  return (
    <div className="vendor_card">
      <img
        src={
          profile_image_s3 ||
          require("../../../Model/Assets/images/logo-placeholder-image.png")
        }
        alt={`${vendor_name} logo`}
        className="vendor_logo"
      />
      <div className="toggle_switch">
        <input
          type="checkbox"
          id={`${id}_toggle`}
          checked={allowedVendorIds?.includes(id)}
          onChange={handleAllowedVendors}
        />
        <label htmlFor={`${id}_toggle`} />
      </div>
      <div>{vendor_name}</div>
    </div>
  );
}

export default VendorCard;
