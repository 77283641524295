import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { api } from "../../../services/api";

const initialState: any = {
  hasDeleteTask: false,
  idOfTaskToDelete: null,
  uploadedFiles: [],
  loading:false,
  error:null,
  daybookTags: [],
  daybookMainTrafficLight: 2,
  selectedServiceType: "",
  selectedDaybookTags : []
};

export const getDocuments = createAsyncThunk(
  "clients/documents/fetchDocuments",
  async ({ clientId = "", providerId = "" }: any) => {
    try {
      const response = await api.get(
        `/documents?paginate=true&sort_column=id&page=1&limit=200&sort_type=desc&user_id=${clientId}&tenant_id=${providerId}`
      );
      return response.data.data.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getDaybookItems = createAsyncThunk(
  "daybook/daybookList",
  async ({ userId, date, sortType, tenantId = "" }: any) => {
    try {
      let sortDaybookType = sortType ? sortType : "asc";
      const response = await api.get(
        `/daybooks?sort_type=${sortDaybookType}&user_id=${userId}&date=${date}${
          tenantId && `&tenant_id=${tenantId}`
        }`
      );
      return response.data.data;
    } catch (error) {
      throw error;
    }
  }
);

export const createDaybookItem = createAsyncThunk(
  "daybook/create",
  async (daybookDetails: any, { rejectWithValue }) => {
    try {
      const response = await api.post(`/daybooks/create_daybook`, {
        ...daybookDetails,
      });
      // if (response.status !== 200) {
      //   throw new Error("Failed to create daybook.");
      // }
      return response.data.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data?.response);
    }
  }
);

export const updateDaybookItem = createAsyncThunk(
  "daybook/update",
  async ({ ...daybookDetails } : any, { rejectWithValue }) => {
    try {
      const response = await api.post(`/daybooks/update_daybook`, {
        ...daybookDetails,
      });
      return response.data.data;
  } catch (error: any) {
      return rejectWithValue(error.response);
    }
  }
);

export const deleteDaybookItem = createAsyncThunk(
  "daybook/delete",
  async ({ id, save_type, date }: any) => {
    try {
      const response = await api.post(`/daybooks/delete_daybook`, {
        daybook_id: id,
        save_type,
        date
      });
      if (response.status !== 200) {
        throw new Error("Failed to delete daybook.");
      }
      return response.data.data;
    } catch (error) {
      throw error;
    }
  }
);

const documentSlice = createSlice({
  name: "document",
  initialState,
  reducers: {
    setselectedServiceType: (state, action) => {
      state.selectedServiceType = action.payload
    },
    reselectedServiceType : (state) => {
      state.selectedServiceType = null
    },
    setConfirmDeleteTask: (state, action) => {
      const { flag, id } = action.payload;
      state.hasDeleteTask = flag;
      state.idOfTaskToDelete = id;
    },
    resetConfirmDeleteTask: (state) => {
      state.hasDeleteTask = false;
      state.idOfTaskToDelete = null;
    },
    clearDaybookList: (state) => {
      state.dayBookList = null;
    },
    setUploadedFilesToStore: (state, action) => {
      let uploadedFiles = action?.payload;
      const extractedKeys = uploadedFiles?.map((elem: any) => elem?.key);
      const extractedNames = uploadedFiles?.map(
        (elem: any) => elem?.file?.name
      );

      const newArray = extractedKeys?.map((key: any, index: number) => ({
        file_name: key,
        actual_file_name: extractedNames[index],
      }));
      state.uploadedFiles= newArray
  
    },
    resetUploadedFilesFromStore: (state) => {
      state.uploadedFiles = null
    },
    setselectedDaybookTags: (state, action) => {
      state.selectedDaybookTags = action.payload
    },
    resetselectedDaybookTags: (state,action) => {
      state.setselectedDaybookTags = []
    },
    setDaybookTags: (state, action) => {
      state.daybookTags = action.payload;
    },
    resetDaybookTags: (state) => {
      state.daybookTags = [];
    },
    setDaybookMainTrafficLight: (state, action) => {
      state.daybookMainTrafficLight = action.payload;
    },
    resetDaybookMainTrafficLight: (state) => {
      state.daybookMainTrafficLight = 2;
    }
  },
  extraReducers: (builder) => {
    builder
    // updateDaybookItem
    .addCase(updateDaybookItem.rejected, (state, action) => {
      state.error = action;
    })
    .addCase(createDaybookItem.rejected, (state, action) => {
      state.error = action;
    })
      .addCase(getDaybookItems.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteDaybookItem.pending, (state) => {
        state.loading = true;
      })

      // deleteDaybookItem
      .addCase(getDaybookItems.fulfilled, (state, action) => {
        state.loading = false;
        state.dayBookList = action.payload;
      })
      .addCase(getDaybookItems.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const {
  resetConfirmDeleteTask,
  setConfirmDeleteTask,
  clearDaybookList,
  setUploadedFilesToStore,
  resetUploadedFilesFromStore,
  setDaybookTags,
  resetDaybookTags,
  resetDaybookMainTrafficLight,
  setDaybookMainTrafficLight,
  setselectedServiceType,
  reselectedServiceType,
  setselectedDaybookTags,
  resetselectedDaybookTags
} = documentSlice.actions;

export default documentSlice.reducer;
