const CalenderSvg = () => {
  return (
    <div>
      <svg
        width="9"
        height="9"
        viewBox="0 0 9 9"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.22472 0C2.44812 0 2.62921 0.191878 2.62921 0.428571V0.857143H5.46067V0.428571C5.46067 0.191878 5.64177 0 5.86517 0C6.08857 0 6.26966 0.191878 6.26966 0.428571V0.867723C7.29168 0.975236 8.08989 1.88885 8.08989 3V6.85714C8.08989 8.04061 7.1844 9 6.06742 9H2.02247C0.905492 9 0 8.04061 0 6.85714V3C0 1.88885 0.798205 0.975236 1.82022 0.867723V0.428571C1.82022 0.191878 2.00132 0 2.22472 0ZM0.878038 2.57143H7.21185C7.04526 2.07206 6.59577 1.71429 6.06742 1.71429H2.02247C1.49411 1.71429 1.04462 2.07206 0.878038 2.57143ZM7.2809 3.42857H0.808989V6.85714C0.808989 7.56722 1.35228 8.14286 2.02247 8.14286H6.06742C6.7376 8.14286 7.2809 7.56722 7.2809 6.85714V3.42857ZM1.61798 4.5C1.61798 4.26331 1.79908 4.07143 2.02247 4.07143H6.06742C6.29081 4.07143 6.47191 4.26331 6.47191 4.5C6.47191 4.73669 6.29081 4.92857 6.06742 4.92857H2.02247C1.79908 4.92857 1.61798 4.73669 1.61798 4.5ZM1.61798 5.78571C1.61798 5.54902 1.79908 5.35714 2.02247 5.35714H6.06742C6.29081 5.35714 6.47191 5.54902 6.47191 5.78571C6.47191 6.02241 6.29081 6.21429 6.06742 6.21429H2.02247C1.79908 6.21429 1.61798 6.02241 1.61798 5.78571ZM1.61798 7.07143C1.61798 6.83473 1.79908 6.64286 2.02247 6.64286H6.06742C6.29081 6.64286 6.47191 6.83473 6.47191 7.07143C6.47191 7.30812 6.29081 7.5 6.06742 7.5H2.02247C1.79908 7.5 1.61798 7.30812 1.61798 7.07143Z"
          fill="#212121"
        />
      </svg>
    </div>
  );
};

export default CalenderSvg;
