import { Modal } from "@chakra-ui/react";
import { Fragment, useEffect, useState } from "react";
import "../../../../../Styles/ClientsCommsForm.css";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../Controller/redux/Hooks";
import ClientImg from "../../../../../../../../../Model/Assets/png/client.png";
import {
  createDocumentCommsThunk,
  getCommsOfDocumentThunk,
} from "../../../../../../../../../Controller/redux/features/CommonFeature/documentSlice";
import { useParams } from "react-router-dom";
import { getInitials } from "../../../../../../../../../Model/utils/Helper/getInitials";
import Tags from "../../../../../../../../components/Tags/Tags";
import { getStaffThunk } from "../../../../../../../../../Controller/redux/features/StaffFeature/staffSlice";
import convertToHTML from "draftjs-to-html";
import ClinicalNote from "../../../../../../../../components/ClinicalProgress/Components/ClinicalNote";
import CreateComms from "../../../../../../../Comms/Component/CreateCommsSection/Index";
import {
  getDaybookItems,
} from "../../../../../../../../../Controller/redux/features/DaybookFeature/daybookSlice";
import { toast } from "react-toastify";

const PrevArrow = ({ setStage }: any) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="22"
        viewBox="0 0 12 22"
        fill="none"
        onClick={() => setStage(1)}
        className="go-to-screen-one-click"
      >
        <path
          d="M11 1L0.999999 11L11 21"
          stroke="#F3F3F3"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};
const PenIcon = ({ data }: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
  >
    <path
      d="M6 0C9.3138 0 12 2.6862 12 6C12 9.3138 9.3138 12 6 12C5.0424 12.0013 4.0985 11.7725 3.2478 11.3328C2.97312 11.1438 2.6433 11.1219 2.35089 11.2821C1.79004 11.5894 0.888309 12.0621 0.560239 11.976C0.431624 11.9423 0.314294 11.875 0.220271 11.7809C0.126247 11.6869 0.0589109 11.5696 0.0251613 11.441C-0.00858834 11.3123 -0.00753788 11.1771 0.028205 11.049L0.669005 8.7552C0.228216 7.90376 -0.00124128 6.95877 5.05017e-06 6C5.05017e-06 2.6862 2.6862 0 6 0ZM4.05 6.6H6.7488C6.86282 6.60003 6.97257 6.64334 7.05588 6.72118C7.13919 6.79902 7.18985 6.90558 7.19762 7.01933C7.20539 7.13307 7.16969 7.24553 7.09774 7.33398C7.02579 7.42242 6.92295 7.48026 6.81 7.4958L6.7488 7.5H4.05C3.93599 7.49996 3.82624 7.45665 3.74293 7.37881C3.65962 7.30098 3.60896 7.19442 3.60119 7.08067C3.59342 6.96692 3.62911 6.85446 3.70106 6.76602C3.77301 6.67757 3.87585 6.61974 3.9888 6.6042L4.05 6.6ZM4.05 4.5H7.953C8.06697 4.50018 8.17661 4.5436 8.25981 4.62149C8.343 4.69937 8.39355 4.80592 8.40124 4.91962C8.40893 5.03333 8.3732 5.14571 8.30125 5.23409C8.2293 5.32247 8.1265 5.38026 8.0136 5.3958L7.953 5.4H4.05C3.93599 5.39996 3.82624 5.35665 3.74293 5.27881C3.65962 5.20098 3.60896 5.09442 3.60119 4.98067C3.59342 4.86692 3.62911 4.75446 3.70106 4.66602C3.77301 4.57757 3.87585 4.51974 3.9888 4.5042L4.05 4.5Z"
      fill={data > 0 ? "rgb(239, 58, 52)" : "#06A5FF"}
    />
    <text
      x="6"
      y="6"
      fill="#FFF"
      textAnchor="middle"
      fontFamily="Arial"
      fontSize="10px"
      fontStyle="normal"
      fontWeight="400"
      letterSpacing="0.25px"
      alignmentBaseline="central"
    >
      {data}
    </text>
  </svg>
);

const CommsIconDaybook = ({ data }: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
  >
    <path
      d="M6 0C9.3138 0 12 2.6862 12 6C12 9.3138 9.3138 12 6 12C5.0424 12.0013 4.0985 11.7725 3.2478 11.3328C2.97312 11.1438 2.6433 11.1219 2.35089 11.2821C1.79004 11.5894 0.888309 12.0621 0.560239 11.976C0.431624 11.9423 0.314294 11.875 0.220271 11.7809C0.126247 11.6869 0.0589109 11.5696 0.0251613 11.441C-0.00858834 11.3123 -0.00753788 11.1771 0.028205 11.049L0.669005 8.7552C0.228216 7.90376 -0.00124128 6.95877 5.05017e-06 6C5.05017e-06 2.6862 2.6862 0 6 0ZM4.05 6.6H6.7488C6.86282 6.60003 6.97257 6.64334 7.05588 6.72118C7.13919 6.79902 7.18985 6.90558 7.19762 7.01933C7.20539 7.13307 7.16969 7.24553 7.09774 7.33398C7.02579 7.42242 6.92295 7.48026 6.81 7.4958L6.7488 7.5H4.05C3.93599 7.49996 3.82624 7.45665 3.74293 7.37881C3.65962 7.30098 3.60896 7.19442 3.60119 7.08067C3.59342 6.96692 3.62911 6.85446 3.70106 6.76602C3.77301 6.67757 3.87585 6.61974 3.9888 6.6042L4.05 6.6ZM4.05 4.5H7.953C8.06697 4.50018 8.17661 4.5436 8.25981 4.62149C8.343 4.69937 8.39355 4.80592 8.40124 4.91962C8.40893 5.03333 8.3732 5.14571 8.30125 5.23409C8.2293 5.32247 8.1265 5.38026 8.0136 5.3958L7.953 5.4H4.05C3.93599 5.39996 3.82624 5.35665 3.74293 5.27881C3.65962 5.20098 3.60896 5.09442 3.60119 4.98067C3.59342 4.86692 3.62911 4.75446 3.70106 4.66602C3.77301 4.57757 3.87585 4.51974 3.9888 4.5042L4.05 4.5Z"
      fill={"#06A5FF"}
    />
    <text
    style={{
      fontSize: "7px"
    }}
      x="6"
      y="6"
      fill="#FFF"
      textAnchor="middle"
      fontFamily="Arial"
      fontSize="10px"
      fontStyle="normal"
      fontWeight="400"
      letterSpacing="0.25px"
      alignmentBaseline="central"
    >
      {data}
    </text>
  </svg>
);

export const SeperatorLineIcon = ({ type }: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{
      position: "absolute",
      top: type === "comment" ? "2rem" : "7.5rem",
    }}
    width="386"
    height="2"
    viewBox="0 0 386 2"
    fill="none"
  >
    <path
      d="M1 1L385 0.999973"
      stroke="#9296A2"
      strokeLinecap="round"
      strokeLinejoin="round"
      stroke-dasharray="4"
    />
  </svg>
);

const ClientCommsForm = ({
  dataObject,
  setUploadDocument,
  setShowCommsForm,
  clientData,
  profilePicture,
  setshowTag,
  showTag,
  documentTags,
  SelectedTagList,
  onSelectTag,
  onUnSelectTag,
  isFromDayBook,
  commsMessage,
  sortType,
  idDaybook
}: any) => {
  const dispatch = useAppDispatch();
  const [createCommsData, setCreateCommsData] = useState<any>({
    link_id: dataObject?.document_uid,
    summary: "",
    message: "",
    comm_type: "DOCUMENT",
    comm_tagged_users: [],
    comm_tag: [],
    comm_document_files: [],
  });
  const [selectedMessage, setSelectedMessage] = useState<any>("");
  const [isThereMessage, setisThereMessage] = useState<boolean>(false);
  const [commList, setCommList] = useState<any>(commsMessage);

  const dynamicColor = useAppSelector((state) => state?.common?.dynamicColor);

  const [commsData, setCommsData] = useState<any>({
    link_id: "",
    summary: "",
    message: selectedMessage,
    comm_type: "DAYBOOK",
  });

  useEffect(() => {
    setCommsData({
      link_id: commList?.id,
      summary: "",
      message: selectedMessage,
      comm_type: "DAYBOOK",
    });
  }, [commList?.id, selectedMessage]);

  let renderer = commsMessage?.communications;

  useEffect(() => {
    if (renderer) {
      setCommList(commsMessage);
    }
  }, [renderer]);

  useEffect(() => {
    setCreateCommsData({
      link_id: dataObject?.document_uid,
      summary: "",
      message: "",
      comm_type: "DOCUMENT",
      comm_tagged_users: [],
      comm_tag: [],
      comm_document_files: [],
    });
  }, [createCommsData.id]);

  useEffect(() => {
    dispatch(getCommsOfDocumentThunk({ clientId, docId }));
  }, []);

  const selectedDataObject = useAppSelector(
    (state) => state?.document?.selectedDataObject
  );

  const docId = selectedDataObject?.id;
  /////////////////////////////////////
  const idURL: any = useParams()?.id;
  const [clientId, setclientId] = useState("");

  useEffect(() => {
    if (idURL) {
      let realId = window.atob(idURL);
      setclientId(realId);
    }
  }, [idURL]);

  //  @ts-ignore
  const dynamicStaffId = parseInt(localStorage.getItem("dynamicStaffId"));

  function prefixDate(date: any) {
    return date < 10 ? "0" + date : date;
  }

  useEffect(() => {
    dispatch(getStaffThunk({ dynamicStaffId }));
  }, [dynamicStaffId]);

  const { getUnreadMessages } = useAppSelector((state) => state.document);

  const sendComms = async () => {
    try {
      const res = await dispatch(createDocumentCommsThunk(commsData));
      if (res?.payload?.status === "success") {
        toast.success("Message Sent Successfully!", {
          progressStyle: { backgroundColor: dynamicColor },
          icon: (
            <span
              style={{
                marginRight: "8px",
                borderRadius: "50%",
                width: "7rem",
                height: "35px",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: dynamicColor,
                backgroundColor: "white",
                fontSize: "1.5rem",
                border: `3px solid ${dynamicColor}`,
              }}
            >
              ✓
            </span>
          ),
        });
      }
      setisThereMessage(false);
      setSelectedMessage("");
      let selectedDate = new Date(commsMessage?.virtual_date);

      dispatch(
        getDaybookItems({
          sortType,
          userId: idDaybook,
          date: `${selectedDate.getFullYear()}-${prefixDate(
            selectedDate.getMonth() + 1
          )}-${prefixDate(selectedDate.getDate())}`,
        })
      ).then(() => {
        setCommList(commsMessage);
      });
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    setCommList(commsMessage);
  }, [commsMessage]);

  return (
    <Fragment>
      <Modal isOpen={true} onClose={() => {}} size="md">
        <div className="client-assessment-form client-comms-form">
          {showTag && (
            <Tags
              type={"mealbuilder"}
              tagArray={documentTags}
              selectedArrayListOfId={SelectedTagList}
              onSelectunAndSelectTag={onSelectTag}
              onUnSelectTag={onUnSelectTag}
            />
          )}
          <div className="header-left-segment">
            <div className="top-icons-wrapper">
              {isFromDayBook ? (
                <div onClick={() => setShowCommsForm(false)}>
                  <PrevArrow setStage={() => {}} />
                </div>
              ) : (
                <PrevArrow setStage={setUploadDocument} />
              )}

              {true ? (
                <CommsIconDaybook
                  data={
                    commList
                      ? commList?.communications?.length
                      : getUnreadMessages
                  }
                />
              ) : (
                <PenIcon
                  data={
                    commList
                      ? dataObject?.communications?.length
                      : getUnreadMessages
                  }
                />
              )}
            </div>
          </div>
          <div
            className="profile profile-comms"
            style={{
              display: isFromDayBook ? "none" : "",
            }}
          >
            {clientData?.profile_image ? (
              <img
                src={profilePicture ? profilePicture : ClientImg}
                alt="client-image"
                id="client-image"
                style={{
                  width: "60px",
                  height: "60px",
                  borderRadius: "50%",
                  objectFit: "cover",
                }}
              />
            ) : (
              <div className="alternate-div-for-image">
                {clientData?.last_name === null ? (
                  <>
                    <div className="red-text">
                      {getInitials(clientData?.first_name)}
                    </div>
                  </>
                ) : (
                  <>
                    <div className="red-text">
                      {clientData?.first_name?.charAt(0).toUpperCase()}
                    </div>
                    <div className="red-text">
                      {clientData?.last_name?.charAt(0).toUpperCase()}
                    </div>
                  </>
                )}
              </div>
            )}

            <div className="profile-name profile-name-comms">
              <textarea
                placeholder="Comms-New Name Form..."
                className="input-details input-details-document-comms"
                value={dataObject ? dataObject.title : ""}
              />
            </div>
          </div>

          {!isFromDayBook && (
            <>
              <div className="widget-dropdown tag_wrapper_document_form">
                <div
                  className=""
                  style={{
                    backgroundColor: !showTag
                      ? SelectedTagList?.length > 0
                        ? "#b2ff9f"
                        : "rgba(213, 213, 213, 0.8)"
                      : "#06a5ff",
                    color:
                      !showTag && SelectedTagList?.length > 0
                        ? "#1a8400"
                        : "white",
                  }}
                  onClick={() => setshowTag(!showTag)}
                >
                  <span
                    style={{
                      color:
                        !showTag && SelectedTagList?.length === 0
                          ? "#a1a1a1"
                          : "white",
                    }}
                  >
                    {SelectedTagList?.length}
                  </span>
                  Tag
                </div>
              </div>
            </>
          )}

          <div
            className="temp"
            style={{ display: isFromDayBook ? "none" : "" }}
          >
            <CreateComms isDocument={true} />
          </div>
          <div
            className={` ${isFromDayBook ? "all_comms_daybook" : "all-comms"} `}
          >
            {commList?.communications?.length > 0 &&
              commList?.communications?.map((elem: any, index: any) => {
                let dataObjectMessage = elem?.message;
                let parsedRes;
                try {
                  parsedRes = JSON.parse(dataObjectMessage);
                } catch (error) {
                  console.error("Error parsing JSON:", error);
                  return null;
                }
                const htmlContent = convertToHTML(parsedRes);

                return (
                  <Fragment key={index}>
                    <ClinicalNote
                      isFromDayBook={isFromDayBook}
                      isFromCommsFormDocuments={true}
                      index={index}
                      noteTitle={
                        index === commList?.communications?.length - 1
                          ? "Message"
                          : "Reply"
                      }
                      elem={elem}
                      htmlContent={htmlContent}
                    />
                  </Fragment>
                );
              })}
            {isFromDayBook && (
              <div className="temp">
                <CreateComms
                  isFromDayBook={true}
                  setSelectedMessage={setSelectedMessage}
                  setIsThereAnyMessageOnEditor={setisThereMessage}
                  selectedMessage={selectedMessage}
                  isCol2={true}
                  isEmpty={commList?.communications?.length === 0}
                />
                <button
                  className="reply_save_btn"
                  style={{
                    backgroundColor: dynamicColor,
                  }}
                  onClick={() => {
                    if (isThereMessage) {
                      sendComms();
                    }
                  }}
                >
                  Send
                </button>
              </div>
            )}
          </div>
        </div>
      </Modal>
    </Fragment>
  );
};

export default ClientCommsForm;
