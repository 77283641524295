import React from "react";
import { useEffect, useRef, useState } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../Controller/redux/Hooks";
import {
  getCommsDetailThunk,
  updateCommsTagsThunk,
} from "../../../../Controller/redux/features/CommsFeature/commsSlice";
import { CrossTagSVG, SearchIconSVG } from "../../CommonModal/Data/SVGs";
import { setUpdateDocumentDataToStore } from "../../../../Controller/redux/features/CommonFeature/documentSlice";
import {
  setDaybookTags,
  setselectedDaybookTags,
} from "../../../../Controller/redux/features/DaybookFeature/daybookSlice";

export const TagBox = ({
  // blockPointerEvents,
  currentElem,
  isFromCol1,
  clientId,
  changeTags = () => {},
  triggerGetCommsAPICall = () => {},
  documentTagSelect,
  documentTagUnselect
}: any) => {
  
  const dispatch = useAppDispatch();

  const inputRef = useRef<HTMLInputElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const [showDropdown, setshowDropdown] = useState(false);
  const [filteredResults, setfilteredResults] = useState([]);
  const [selectedTagIds, setSelectedTagIds] = useState<number[]>([]);
  const [selectedTags, setSelectedTags] = useState<number[]>([]);

  const { getCommDetailFull } = useAppSelector((state) => state.comms);
  const allTags = useAppSelector((state) => state?.common?.allTags);
  const updatedDoc = useAppSelector(
    (state) => state?.document?.updateDocumentData
  );
  // const daybookTags = useAppSelector((state) => state?.daybook?.daybookTags);

  // const [userId, setuserId] = useState("");

  // useEffect(() => {
  //   let loggedInUserId = localStorage.getItem("loggedInUserId");
  //   if (loggedInUserId) {
  //     // setuserId(loggedInUserId);
  //   }
  // }, []);

  // const { selectedDaybookTags} = useAppSelector((state) => state?.daybook)

  // console.log('currentElem?.tags', currentElem?.tags);
  useEffect(() => {
    if (currentElem) {
      setSelectedTags(currentElem?.tags);
      setSelectedTagIds(
        typeof currentElem?.tag === "string"
          ? currentElem?.tag?.split(",")
          : currentElem?.tags?.map((i: any) => i?.id) || []
      );
    }
  }, [currentElem]);
  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setshowDropdown(false);
    }
  };

  useEffect(() => {
    if (showDropdown) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showDropdown]);

  useEffect(() => {
    if (showDropdown) {
      inputRef?.current?.focus();
    }
  }, [showDropdown]);

  useEffect(() => {
    if (allTags) {
      setfilteredResults(allTags);
    }
  }, [allTags]);

  const handleSelectedTagIdsChange = (newSelectedTagIds: any) => {
    const newSelectedTags = [...newSelectedTagIds];
    setSelectedTagIds(newSelectedTags);

    if (currentElem?.assigned_to !== null) {
      dispatch(setDaybookTags(newSelectedTags));
      dispatch(setselectedDaybookTags(newSelectedTags));
    }

    if (currentElem && Object.keys(currentElem)?.includes("document_uid")) {
      dispatch(
        setUpdateDocumentDataToStore({
          ...updatedDoc,
          tagArray: newSelectedTags,
        })
      );
    }
    let selectedTagsObject = {
      tags: newSelectedTags,
      link_uid: currentElem?.link_uid,
      comm_type: currentElem?.comm_type,
    };

    if(currentElem?.assigned_to){
      selectedTagsObject = {
        tags: newSelectedTags,
        link_uid: currentElem?.id,
        comm_type: "DAYBOOK",
      }
    }

    if (selectedTagsObject.comm_type && selectedTagsObject.link_uid) {
      try {
        dispatch(updateCommsTagsThunk(selectedTagsObject));
      } catch (err) {
        console.error(err, "error");
      }
      dispatch(
        getCommsDetailThunk({
          clientId: currentElem?.link_uid,
          type: currentElem?.comm_type,
          userId: clientId,
          page: 1,
        })
      );
      triggerGetCommsAPICall();
    }
  };

  // console.log('selectedTags', selectedTags);

  function removeTag(tag: any) {
    documentTagUnselect &&  documentTagUnselect(tag?.id)
    setSelectedTags(selectedTags.filter((t: any) => t.id != tag.id));
    changeTags(selectedTags.filter((t: any) => t.id != tag.id));
    const selectedTag = allTags.find((t: any) => t.id == tag.id);
    if (selectedTag) {
      handleSelectedTagIdsChange(
        selectedTagIds.filter((id) => id != selectedTag.id)
      );
      setSelectedTagIds(selectedTagIds.filter((id) => id != selectedTag.id));
    }
  }

  function handleAddTag(tag: any, item = null) {
    if (
      tag?.trim() === "" ||
      selectedTags?.find((t: any) => t?.tag_name === tag)
    ) {
      return;
    }

    documentTagSelect &&  documentTagSelect(item?.id)
    const updatedTags = [
      ...(selectedTags ? selectedTags : []),
      allTags?.find((t: any) => t?.tag_name == tag),
    ];
    setSelectedTags([...new Set(updatedTags)]);
    changeTags([...new Set(updatedTags)]);
    // dispatch(setDaybookTags([...new Set(updatedTags)]))
    setfilteredResults((prevTags: any) => {
      if (!prevTags.includes(tag)) {
        const newTags = [...prevTags, tag];
        const selectedTag = allTags.find((t: any) => t?.tag_name === tag);
        if (selectedTag) {
          setSelectedTagIds((prevIds) => {
            const newIds = [...(prevIds ? prevIds : []), selectedTag.id];
            handleSelectedTagIdsChange(new Set(newIds));
            return Array.from(new Set(newIds));
          });
        }
        return newTags;
      }
      return prevTags;
    });

    // setInputValue("");
    // setFilteredSuggestions([]);
  }

  return (
    <>
      <div

      className={`
        ${isFromCol1  && "blockPointerEvents" }
        tag_box` }onClick={() => setshowDropdown(true)}>
        <div className="tag_number">{selectedTags?.length || "0"}</div>
        <span className="tag_label">Tag</span>
        {!!showDropdown && !isFromCol1 && (
          <>
            <div ref={dropdownRef} className=" search_items_container_tags ">
              <div className="top_section_search_items_container">
                {!isFromCol1 && (
                  <>
                    <div className="searchable_input_wrapper ">
                      <SearchIconSVG />
                      <input
                        ref={inputRef}
                        value={inputRef?.current?.value}
                        onChange={(e) => {
                          setfilteredResults((prevItems: any) => {
                            return allTags.filter((elem: any) =>
                              elem?.tag_name
                                ?.toLowerCase()
                                .includes(e.target.value.toLowerCase())
                            );
                          });
                        }}
                        type="text"
                        className="searchable_input searchable_input_inner"
                      />
                    </div>
                  </>
                )}
              </div>
              <div className="middle_section_search_items_container">
                {filteredResults
                  ? filteredResults?.map((item: any, itemIndex: any) => {
                      const nameExists = !getCommDetailFull?.tags?.some(
                        (elem: any) =>
                          elem?.tag_name?.toLowerCase() ===
                          item?.tag_name?.toLowerCase()
                      );

                      if (nameExists) {
                        return (
                          <li
                            onClick={() => {
                              if(!isFromCol1){
                              handleAddTag(item?.tag_name , item);

                              }

                              // setshowDropdown(false);
                            }}
                            key={itemIndex}
                            className={` ${isFromCol1 ? "blockPointerEvents" : ""} search_item`}
                          >
                            {item?.tag_name}
                          </li>
                        );
                      } else {
                        return null;
                      }
                    })
                  : null}
              </div>

              <div className="selected_tags_section">
                {selectedTags?.map((tag: any, index: any) => {
                  return (
                    <>
                      {tag?.tag_name ? (
                        <span className="tag_items_wrapper">
                          {tag?.tag_name}
                          {!isFromCol1 && (
                            <>
                              <span onClick={() => removeTag(tag)}>
                                <CrossTagSVG />
                              </span>
                            </>
                          )}
                        </span>
                      ) : null}
                    </>
                  );
                })}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};
