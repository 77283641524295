import { useEffect, useRef, useState } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../Controller/redux/Hooks";
import { setUpdateDocumentDataToStore } from "../../../../Controller/redux/features/CommonFeature/documentSlice";
import { DatePickerDropdown } from "../Data/SVGs";
import ReactDatePicker from "react-datepicker";
import {
  formatDate,
  getCurrentDate,
} from "../../../../Model/utils/Helper/formatDate";
import DateTimePicker from "../../CommonDateTimePicker/DateTimePicker";

export const DateTimePickerWithLabel = ({
  dateTime,
  showDateTimePickerModal,
  setShowDateTimePickerModal = () => {},
  setDateTime,
  label,
  periodPicker,
  isDocument,
  handleChange = () => {},
  selectedDate,
  hasTimepicker,
  setStartDateDaybook,
  setStartTime,
  setEndTime,
  startTime,
  endTime,
  handleStartTimeChange,
  selectedCurrentElement,
  handleEndTimeChange,
  ends,
  setEndDateDaybook,
  handleIntervalChange,
  selectedEndDate,
  setCurrentInterval = () => {},
  currentInterval = "None",
}: any) => {
  const dispatch = useAppDispatch();
  const dropdownRef = useRef<any>(null);
  const [currentDate, setcurrentDate] = useState<string | any>("");

  // useEffect(() => {
  //   if (getCurrentDate) {
  //     setcurrentDate(getCurrentDate());
  //   }
  // }, [currentDate]);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setShowDateTimePickerModal(false);
    }
  };

  useEffect(() => {
    if (showDateTimePickerModal) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showDateTimePickerModal]);

  // const { time } = useAppSelector((state) => state.common);
  const updatedDoc = useAppSelector(
    (state) => state?.document?.updateDocumentData
  );
  const selectedIntervalRedux = useAppSelector(
    (state) => state?.document?.selectedInterval
  );

  const intervals = [
    "None",
    "Daily",
    "Weekly",
    "Monthly",
    "Quarterly",
    "Half Yearly",
    "Yearly",
  ];

  useEffect(() => {
    if (currentInterval) {
      setCurrentInterval(currentInterval);
    } else {
      setCurrentInterval(selectedIntervalRedux);
    }
  }, [currentInterval, selectedIntervalRedux]);

  useEffect(() => {
    if (typeof selectedDate === "string") {
      setcurrentDate(new Date(selectedDate));
    } else {
      setcurrentDate(selectedDate);
    }
  }, [selectedDate]);

  return (
    <div
      className="date_time_picker_with_label_wrapper"
      ref={dropdownRef}
      onClick={(e) => {
        // alert('yeiadk')
        e.stopPropagation();
        setShowDateTimePickerModal(true);
      }}
    >
      {label && (
        <>
          <h1 className="items_container_label">{label}</h1>
        </>
      )}
      <div className="dateTime_picker_segment">
        {!isDocument && (
          <>
            <div className="selected_typography">
            {`${
  dateTime?.startDate
    ? formatDate(dateTime.startDate)
    : selectedDate
    ? `${formatDate(selectedDate)} ${
        selectedCurrentElement?.start_time?.toUpperCase() || ""
      } ${
        selectedCurrentElement?.end_time
          ? "-" + selectedCurrentElement.end_time.toUpperCase()
          : ""
      }`
    : currentInterval
    ? currentInterval
    : "None"
}`.trim() || "None"}

            </div>
          </>
        )}

        <span>
          <DatePickerDropdown />
        </span>

        {isDocument && (
          <>
            <ReactDatePicker
              placeholderText="dd-mm-yyyy"
              dateFormat={"dd MMM yyyy"}
              // minDate={getCurrentDate()}
              onChange={(date) => {
                if (isDocument) {
                  handleChange(date);
                } else {
                  // setDateTime((prev: string | any) => ({
                  //   ...prev,
                  //   startDate: date,
                  // }))
                  
                }
              }}
              selected={
                typeof selectedDate === "string"
                  ? new Date(selectedDate)
                  : selectedDate || getCurrentDate()
              }

              className="date_input_docs "
            />
          </>
        )}

        {periodPicker && showDateTimePickerModal && (
          <>
            <div className="time_picker_intervals_1">
              <div className="intervals">
                {intervals?.map((item) => (
                  <div
                    className={`interval`}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleIntervalChange(item);
                      setShowDateTimePickerModal(false);
                      dispatch(
                        setUpdateDocumentDataToStore({
                          ...updatedDoc,
                          period_picker: item,
                        })
                      );
                      setCurrentInterval(item);
                      // dispatch(setSelectedInterval(item));
                    }}
                  >
                    {item}
                  </div>
                ))}
              </div>
            </div>
          </>
        )}

        {showDateTimePickerModal && !isDocument && !periodPicker && (
          <DateTimePicker
            // isDocument={isDocument}
            handleChange={handleChange}
            // showDateTimePickerModal={showDateTimePickerModal}
            setDateTime={setDateTime}
            dateTime={dateTime}
            selectedDate={selectedDate}
            selectedEndDate={selectedEndDate}
            hasTimepicker={hasTimepicker}
            // selectedDate={selectedDate}
            setStartDateDaybook={setStartDateDaybook}
            setEndDateDaybook={setEndDateDaybook}
            setStartTime={setStartTime}
            setEndTime={setEndTime}
            startTime={startTime}
            endTime={endTime}
            ends={ends}
            handleStartTimeChange={handleStartTimeChange}
            handleEndTimeChange={handleEndTimeChange}
            selectedCurrentElement={selectedCurrentElement}
          />
        )}
      </div>
    </div>
  );
};
