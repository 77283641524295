import React, { useState } from "react";
import TimePicker from "../../Time/Time";
import { getCurrentTime } from "../../../../Model/utils/Helper/formatTime";
import { toast } from "react-toastify";
import { api } from "../../../../Controller/services/api";
import { setShowStickyNote } from "../../../../Controller/redux/features/CommonFeature/commonSlice";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../Controller/redux/Hooks";

import {
  getPayRule,
  getPayRuleByFilterThunk,
  resetPayRuleData,
} from "../../../../Controller/redux/features/RefDataFeature/PayRuleFeature/payruleSlice";

const PayRuleForm = () => {
  const dispatch = useAppDispatch();
  const [IsLoading, setIsLoading] = useState(false);
  const initialValue = {
    name: "",
    associated_award: "",
    rate: "",
    associated_pay_code: "",
    associated_days_of_the_week: "",
    start_time: getCurrentTime(),
    end_time: getCurrentTime(),
    valid_dates: "",
    tax_rate: "",
    linked_rules: "",
    associated_user_type: "",
  };
  const { payRulePage } = useAppSelector((state) => state.payRule);

  const [billingFormData, setBillingFormData] = useState(initialValue);
  const { dynamicColor } = useAppSelector((state) => state?.common);
  let isPending = false;

  const handleSubmitBilling = async (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    const url = "/pay_rules/create_pay_rule";
    if (billingFormData?.name === "") {
      toast.error("Please Enter Value on Required Fileds");
      setIsLoading(false);
    }
    if (billingFormData?.name !== "") {
      if (isPending) return;
      if (IsLoading) return;
      try {
        isPending = true;
        const response = await api.post(url, billingFormData);
        if (response.data.status === "success") {
          setIsLoading(false);
          isPending = false;

          toast.success(`Pay Rule added successfully! `, {
            style: {},
            progressStyle: { backgroundColor: dynamicColor },
            icon: (
              <span
                style={{
                  marginRight: "8px",
                  borderRadius: "50%",
                  width: "7rem",
                  height: "35px",
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: dynamicColor,
                  backgroundColor: "white",
                  fontSize: "1.5rem",
                  border: `3px solid ${dynamicColor}`,
                }}
              >
                ✓
              </span>
            ),
          });
          dispatch(resetPayRuleData());
          dispatch(
            getPayRuleByFilterThunk({
              filterColumn: "",
              filterValue: "",
              searchVal: "",
              sortColumn: "",
              sortType: "",
              //   status: "",
              payRulePage,
            })
          );
        }
        dispatch(getPayRule());

        const stickyNoteElement = document.querySelector(
          ".new_sticky_note_wrapper"
        );
        if (stickyNoteElement) {
          stickyNoteElement.classList.add("fade_out");
          setTimeout(() => {
            dispatch(setShowStickyNote(false));
          }, 300);
        }
      } catch (error: any) {
        toast.error(error.message);
        setIsLoading(false);
      }
    }
  };
  return (
    <div className="payload_area">
      <div className="main_area">
        <div className="side_bar"></div>
        <div className="side_bar" />
        <div className="middle-block">
          <p className="note-title">{`New Pay Rule`}</p>
          <div className="middle-section-content middle_section_scrollable">
            <div className="field">
              <span className="field-title">
                Name <span className="asterixs_color"> * </span>
              </span>
              <input
                style={{
                  backgroundColor: "white",
                }}
                className="input-fields-of-sticky-note"
                placeholder="Enter Name"
                onChange={(e) =>
                  setBillingFormData((prevState) => ({
                    ...prevState,
                    name: e.target.value,
                  }))
                }
                value={billingFormData.name}
              />

              <span className="field-border field-border-add-form"></span>
            </div>
            <div className="field">
              <span className="field-title">
                Associated Ward
                {/* {<span className="asterixs_color"> * </span>} */}
              </span>
              <input
                style={{
                  backgroundColor: "white",
                }}
                className="input-fields-of-sticky-note"
                placeholder="Enter Associated Ward"
                onChange={(e) =>
                  setBillingFormData((prevState) => ({
                    ...prevState,
                    associated_award: e.target.value,
                  }))
                }
                value={billingFormData.associated_award}
              />
              <span className="field-border field-border-add-form"></span>
            </div>
            <div className="field">
              <span className="field-title">Rate</span>
              <input
                style={{
                  backgroundColor: "white",
                }}
                className="input-fields-of-sticky-note"
                placeholder="Enter Rate"
                onChange={(e) =>
                  setBillingFormData((prevState) => ({
                    ...prevState,
                    rate: e.target.value,
                  }))
                }
                value={billingFormData.rate}
              />

              <span className="field-border field-border-add-form"></span>
            </div>
            <div className="field">
              <span className="field-title">Associated Pay Code</span>
              <input
                style={{
                  backgroundColor: "white",
                }}
                className="input-fields-of-sticky-note"
                placeholder="Enter Associated Pay Code"
                onChange={(e) =>
                  setBillingFormData((prevState) => ({
                    ...prevState,
                    associated_pay_code: e.target.value,
                  }))
                }
                value={billingFormData.associated_pay_code}
              />

              <span className="field-border field-border-add-form"></span>
            </div>
            <div className="field">
              <span className="field-title">Associated Days of the Week</span>
              <input
                style={{
                  backgroundColor: "white",
                }}
                className="input-fields-of-sticky-note"
                placeholder="Enter Associated Days of the Week"
                onChange={(e) =>
                  setBillingFormData((prevState) => ({
                    ...prevState,
                    associated_days_of_the_week: e.target.value,
                  }))
                }
                value={billingFormData.associated_days_of_the_week}
              />

              <span className="field-border field-border-add-form"></span>
            </div>
            <div className="field">
              <span className="field-title">Start Time</span>
              <div id="billing_start_time">
                <TimePicker
                  setTime={(e: any) =>
                    setBillingFormData((prevState) => ({
                      ...prevState,
                      start_time: e,
                    }))
                  }
                  time={billingFormData.start_time}
                />
                {/* <input type="time" onChange={(e)=>{setTime(convertTime(e.target.value))}}/> */}

              </div>
              {/* <input
                style={{
                  backgroundColor: "white",
                }}
                className="input-fields-of-sticky-note"
                placeholder="Enter Start Time"
                onChange={(e) =>
                  setBillingFormData((prevState) => ({
                    ...prevState,
                    start_time: e.target.value,
                  }))
                }
                value={billingFormData.start_time}
              /> */}
              <span className="field-border field-border-add-form"></span>
            </div>
            <div className="field">
              <span className="field-title">End Time</span>
              <div id="billing_start_time">
                <TimePicker
                  setTime={(e: any) =>
                    setBillingFormData((prevState) => ({
                      ...prevState,
                      end_time: e,
                    }))
                  }
                  time={billingFormData.end_time}
                />
              </div>
  
              <span className="field-border field-border-add-form"></span>
            </div>
            <div className="field">
              <span className="field-title">Valid Dates</span>
              <input
                style={{
                  backgroundColor: "white",
                }}
                className="input-fields-of-sticky-note"
                placeholder="Enter Valid Dates"
                onChange={(e) =>
                  setBillingFormData((prevState) => ({
                    ...prevState,
                    valid_dates: e.target.value,
                  }))
                }
                value={billingFormData.valid_dates}
              />
              <span className="field-border field-border-add-form"></span>
            </div>
            <div className="field">
              <span className="field-title">Tax Rate</span>
              <input
                style={{
                  backgroundColor: "white",
                }}
                className="input-fields-of-sticky-note"
                placeholder="Enter Tax Rate"
                onChange={(e) =>
                  setBillingFormData((prevState) => ({
                    ...prevState,
                    tax_rate: e.target.value,
                  }))
                }
                value={billingFormData.tax_rate}
              />
              <span className="field-border field-border-add-form"></span>
            </div>
            <div className="field">
              <span className="field-title">Linked Rules</span>
              <input
                style={{
                  backgroundColor: "white",
                }}
                className="input-fields-of-sticky-note"
                placeholder="Enter Linked Rules"
                onChange={(e) =>
                  setBillingFormData((prevState) => ({
                    ...prevState,
                    linked_rules: e.target.value,
                  }))
                }
                value={billingFormData.linked_rules}
              />
              <span className="field-border field-border-add-form"></span>
            </div>
            <div className="field">
              <span className="field-title">Associated User Type</span>
              <input
                style={{
                  backgroundColor: "white",
                }}
                className="input-fields-of-sticky-note"
                placeholder="Enter Associated User Type"
                onChange={(e) =>
                  setBillingFormData((prevState) => ({
                    ...prevState,
                    associated_user_type: e.target.value,
                  }))
                }
                value={billingFormData.associated_user_type}
              />
              <span className="field-border field-border-add-form"></span>
            </div>
          </div>
        </div>
        <div className="bottomBlock">
          <button className="save-btn" onClick={handleSubmitBilling}>
            {IsLoading ? "Loading..." : "Save"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PayRuleForm;
