import "./CommonQuickMenu.css";
import { useEffect, useRef, useState } from "react";
import { SearchSVG } from "./Data/SVGs";
import Home from "../../../Model/Assets/png/HomeQuickMenu.png";
import { boxItems, circleItems, circleItemsStickynotes } from "./Data/DataArr";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../Controller/redux/Hooks";
import {
  setClinicalAddNote,
  setopenClientAssessmentForm,
} from "../../../Controller/redux/features/ClientFeature/clientsSlice";
import { setopenFormByClickProspects } from "../../../Controller/redux/features/ProspectsFeature/documentProspectsSlice";
import { setOpenFormByClickBusiness } from "../../../Controller/redux/features/BusinessFeature/documentBusinessSlice";
import { setOpenFormByClickTeams } from "../../../Controller/redux/features/TeamsFeature/documentTeamsSlice";
import { setopenFormByClickContacts } from "../../../Controller/redux/features/ContactsFeature/documentContactSlice";
import { setOpenFormByClickStaff } from "../../../Controller/redux/features/StaffFeature/documentStaffSlice";
import { setopenMealBuilderForm } from "../../../Controller/redux/features/MealsFeatures/MealFeature/mealbuilderSlice";
import { useNavigate, useParams } from "react-router-dom";
import { setOpenFormByClickLocation } from "../../../Controller/redux/features/LocationFeature/locationSlice";
import { Modal, ModalOverlay } from "@chakra-ui/react";
import ClientAssessmentForm from "../../pages/People/ClientPage/Components/UniqueClientScreen/Components/DocumentsTab/Components/ClientAssessmentForm";
import {
  createDocumentThunk,
  getDocuments,
  getDocumentsForClinicalThunk,
  getUniquePrimaryApproversThunk,
} from "../../../Controller/redux/features/CommonFeature/documentSlice";
import { toast } from "react-toastify";
import { setCurrentActiveFormInfo } from "../../../Controller/redux/features/CustomFromFeature/customFormSlice";
import { CrossMdSvg } from "../../../Model/Assets/SVG/Common/CrossSvg";
import SuccessToastMessage from "../SuccessToast/SuccessToastMessage";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";

const CommonQuickMenu = ({
  isFromMobileCard,
  setshowGoalsModal,
  showGoalsModal,
  setstageTwoToGoalsModal,
  type,
  mainType,
  filterItems,
  onClickCircle,
  showModal,
  setShowModal,
  inDocument,
  screenType,
}: any) => {
  const quickMenuRef = useRef<any>();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  let data = localStorage.getItem("flagHere");
  const [isThereAnyChanges, setIsThereAnyChanges] = useState(false);

  // @ts-ignore
  let parsedData = JSON.parse(data);
  // // console.log(parsedData,'parsedData')

  const [showDocumentForm, setshowDocumentForm] = useState<any>({
    data: "",
    state: false,
  });
  // let firstDoc = localStorage.getItem("GET_CALL_FIRST_DOCUMENT_TRUE")

  const [customFormLinkedDocument, setcustomFormLinkedDocument] = useState<any>(
    showDocumentForm?.data?.form_name + "-"
  );

  useEffect(() => {
    if (showDocumentForm?.data) {
      setcustomFormLinkedDocument(showDocumentForm?.data?.form_name + "-");
    }
  }, [showDocumentForm]);

  function handleShowDocumentLinkedToCustomForm(item: any) {
    dispatch(setCurrentActiveFormInfo(item));
    setshowDocumentForm({
      data: item,
      state: true,
    });
  }

  const [uploadedFiles, setUploadedFiles] = useState<any>([]);

  const handleOpenModals = (e: any, index: any) => {
    e.stopPropagation();

    if (index === 0) {
      setshowGoalsModal(true);
    }
    if (index === 1) {
      switch (type) {
        case "clients":
          dispatch(setopenClientAssessmentForm(true));
          break;
        case "prospects":
          dispatch(setopenFormByClickProspects());
          break;
        case "business":
          dispatch(setOpenFormByClickBusiness());
          break;
        case "teams":
          dispatch(setOpenFormByClickTeams());
          break;
        case "contacts":
          dispatch(setopenFormByClickContacts());
          break;
        case "staff":
          dispatch(setOpenFormByClickStaff());
          break;
        case "locations":
          dispatch(setOpenFormByClickLocation());
          break;
        default:
          break;
      }
    }

    if (index === 2) {
      navigate("/meals/meals-builder");
      dispatch(setopenMealBuilderForm(true));
    }
    if (index === 3) {
      dispatch(setClinicalAddNote(true));
    }
    setstageTwoToGoalsModal(false);
  };

  const handleClickOutside = (event: any) => {
    if (
      quickMenuRef?.current &&
      // @ts-ignore
      !quickMenuRef?.current?.contains(event.target) &&
      // @ts-ignore
      !documentFormRef?.curent?.contains(event.target)
    ) {
      // setstageTwoToGoalsModal(false);
      // setShowModal(false);
    }
  };

  ////////////////////////////////////////////////////////////////////////
  const isMeals = useAppSelector((state) => state?.common?.isMeals);

  const circleItems1 = [
    { icon: "+", label: "Advanced Care Note" },
    { icon: "+", label: "New Document" },
    { icon: "+", label: "Add New Meal" },
    // { icon: "+", label: "Add New Note" },
  ];

  const circleItems2 = [
    { icon: "+", label: "Advanced Care Note" },
    { icon: "+", label: "New Document" },
    // { icon: "+", label: "Add New Meal" },
    // { icon: "+", label: "Add New Note" },
  ];

  let circleItemsAll = isMeals ? circleItems1 : circleItems2;
  ///////////////////////////////////////////////////////////////

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (parsedData) {
      setshowGoalsModal(true);
    }
  }, [parsedData]);

  const documentFormRef = useRef<any>();
  const { uniqueClient } = useAppSelector((state) => state.people);
  const { id, pro_id }: any = useParams();

  const [clientId, setclientId] = useState<any>("");
  const [providerId, setProviderId] = useState("");
  const [profilePicture, setProfilePicture] = useState<any>("");

  const dynamicColor = useAppSelector((state) => state?.common?.dynamicColor);
  //  const { getDocuments} = useAppSelector((state) => state.)
  let isPending = false;

  const [showConfirmationModal, setshowConfirmationModal] = useState(false);

  function prefixDate(date) {
    return date < 10 ? "0" + date : date;
  }
  const handleCloseModal = async () => {
    if (
      documentDetails.title ||
      documentDetails.summary ||
      showDocumentForm.data.form_name
    ) {
      if (isPending) return;
      try {
        isPending = true;

        if (isThereAnyChanges) {
          setshowConfirmationModal(true);
        }
        if (!customFormLinkedDocument) {
          toast.warn("Title is mandatory!");
          return;
        }

        let docItem = { ...documentDetails };

        if (docItem?.primary_review == null || docItem?.primary_review == "") {
          let date = new Date();

          docItem.primary_review = `${date.getFullYear()}-${prefixDate(
            date.getMonth() + 1
          )}-${prefixDate(date.getDate())}`;
        }

        if (
          documentDetails?.communications[0]?.message == "" &&
          documentDetails?.tagged_person?.length >= 1
        ) {
          toast.info("Please add a comm to be able to save tagged people!");
          return;
        }
        if (documentDetails?.primary_approver_id?.length == 0) {
          toast.warn("Primary approver is mandatory to create a document!");
          return;
        }
       
        let res = await dispatch(
          createDocumentThunk({
            ...documentDetails,

            primary_review: docItem?.primary_review,
          })
        );

        console.log("res", res);
        if (res?.payload?.status == "success") {
          toast.success("Document created successfully!", {
            progressStyle: { backgroundColor: dynamicColor },
            icon: <SuccessToastMessage dynamicColor={dynamicColor} />,
          });
        }
        dispatch(getDocuments({ clientId, providerId }));
        localStorage.setItem("SCAM", "true");
        dispatch(getDocumentsForClinicalThunk({ clientId, providerId }));
        dispatch(setopenClientAssessmentForm(false));
        dispatch(getUniquePrimaryApproversThunk(clientId));
        // setopenForm(false);
      } catch {
        // setopenForm(false);

        dispatch(getDocuments({ clientId, providerId }));

        // dispatch(setopenClientAssessmentForm(false));
      } finally {
        // setopenForm(false);

        dispatch(getDocuments({ clientId, providerId }));
        // dispatch(setopenClientAssessmentForm(false));
      }
    } else {
      // setopenForm(false);

      dispatch(getDocuments({ clientId, providerId }));
      // dispatch(setopenClientAssessmentForm(false));
    }

    setstageTwoToGoalsModal(false);
    setshowDocumentForm((prev: any) => ({
      ...prev,
      state: false,
    }));
  };

  useEffect(() => {
    if (id) {
      let realId = window?.atob(id);
      setclientId(realId);
      if (screenType) {
        setProviderId(window?.atob(pro_id));
      }
    }
  }, [id, pro_id, screenType]);
  const [currentClient, setCurrentClient] = useState<any>(null);
  const [documentDetails, setdocumentDetails] = useState<any>();

  useEffect(() => {
    if (uniqueClient?.data?.data.length > 0) {
      setCurrentClient(uniqueClient?.data?.data[0]);
    } else {
      setCurrentClient(null);
    }
  }, [uniqueClient, clientId]);

  const handleGetDetails = (elem: any) => {
    setdocumentDetails({
      ...elem,
      tenant_id: providerId,
      custom_form_id: showDocumentForm?.data?.id,
    });
  };
  useEffect(() => {
    if (documentDetails?.title == "") {
      setdocumentDetails({
        ...documentDetails,
        title: showDocumentForm?.data?.form_name,
      });
    }
  }, [documentDetails]);

  return (
    <>
      {showDocumentForm.state && (
        <div ref={documentFormRef}>
          <Modal isOpen={true} onClose={() => {}} isCentered>
            <ModalOverlay onClick={() => {}} zIndex={920} />

            <ClientAssessmentForm
              type="new-form"
              isCustomFormCreation={showDocumentForm.data}
              // dataObject={ allDocs[0]}
              setcustomFormLinkedDocument={setcustomFormLinkedDocument}
              customFormLinkedDocument={customFormLinkedDocument}
              setdocumentDetails={setdocumentDetails}
              documentDetails={documentDetails}
              setIsThereAnyChanges={setIsThereAnyChanges}
              isThereAnyChanges={isThereAnyChanges}
              // onClick={() => {
              //   setstageTwoToGoalsModal(false);
              // }}
              setstageTwoToGoalsModal={setstageTwoToGoalsModal}
              setshowDocumentForm={setshowDocumentForm}
              openFormClinical={true}
              openForm={showDocumentForm.state}
              currentData={currentClient}
              profilePicture={profilePicture}
              handleGetDetails={handleGetDetails}
              uploadedFiles={uploadedFiles}
              setUploadedFiles={setUploadedFiles}
              handleSaveClick={handleCloseModal}
              isDocumentCreateState={true}
            />
          </Modal>
        </div>
      )}

      <div
        ref={quickMenuRef}
        style={{
          display: !!showDocumentForm.state ? "none" : "",
        }}
        className={
          isFromMobileCard
            ? "mobile_card_view"
            : `quick_menu_wrapper ${showGoalsModal ? "goals_modal_open" : ""}${
                type == "stickynote" && "sticky_note_class"
              }`
        }
      >
        <div className={isFromMobileCard ? "" : "segment segment_1"}>
          <div className="quick_menu_title">QUICK MENU</div>
        </div>
        <div
          className="segment segment_2"
          style={{
            position: "relative",
          }}
        >
          <div className="box_item box_item_1" onClick={() => navigate("/")}>
            <img src={Home} alt="" />
            <span>Home</span>
          </div>
          {/* {boxItems.map((elem, index) => (
          <div className="box_item" key={index}>
            {elem.icon}
            <span>{elem.label}</span>
          </div>
        ))} */}
        </div>
        <div
          className="segment segment_3"
          style={{
            marginTop: inDocument ? "7px" : "-10px",
          }}
        >
          {type == "stickynote"
            ? circleItemsStickynotes.map((item: any, index: number) => {
                return (
                  <div
                    className={`circle_item`}
                    style={{
                      display:
                        filterItems && (index === 0 || index === 3)
                          ? "none"
                          : "",
                    }}
                    onClick={(e) => {
                      onClickCircle(item);
                      setstageTwoToGoalsModal(false);
                    }}
                    key={item.icon}
                  >
                    {item.icon}
                    <span>{item.label} </span>
                  </div>
                );
              })
            : circleItemsAll.map((item, index) =>
                (mainType === "listing" && index === 2) ||
                mainType !== "listing" ? (
                  <div
                    className={`circle_item ${
                      index === 1 || index === 2 ? "circle_item_next" : ""
                    }`}
                    style={{
                      position: "relative",
                      display:
                        filterItems && (index === 0 || index === 3)
                          ? "none"
                          : "",
                    }}
                    onClick={(e) => handleOpenModals(e, index)}
                    key={item.icon}
                  >
                    {item.icon}
                    <span>{item.label}</span>
                  </div>
                ) : null
              )}

          {inDocument?.map((item: any) => {
            return (
              <>
                <div
                  className={`circle_item`}
                  style={{
                    position: "relative",
                  }}
                  onClick={(e) => {
                    handleShowDocumentLinkedToCustomForm(item);
                  }}
                  key={item?.icon}
                >
                  +<span className="form_name">{item?.form_name} </span>
                </div>
              </>
            );
          })}
        </div>
        <CrossMdSvg
          className="cross_button"
          onClick={() => {
            setstageTwoToGoalsModal(false);
          }}
        />
      </div>
    </>
  );
};

export default CommonQuickMenu;
