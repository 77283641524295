import React, { useEffect, useRef, useState } from "react";
import {
  clearCurrentContactData,
  getContactByIdThunk,
  getProviderContactByIdThunk,
  getUserRolesContactsThunk,
  // setUserTypeIdForContact,
  updateContactThunk,
} from "../../../../../../Controller/redux/features/ContactsFeature/contactsSlice";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../Controller/redux/Hooks";

import CommonHeader from "../../../../../components/CommonHeader/CommonHeader";
import DropdownIcon from "../../../../../../Model/Assets/png/drop.png";
// import UnderWork from "../../../../../components/UnderWork/UnderWork";
// import UpperBox from "../../../../../components/UpperBox/UpperBox";
import CommonUpperBox from "../../../../../components/UpperBox/CommonHeader";
import { dummyArrContacts } from "../../../ClientPage/Components/UniqueClientScreen/Data/ClientScreenContentArr";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import CommonTable from "../../../../../components/CommonTable/InputTable";
// import Rectangle from "../../../ClientPage/Components/StickyNote/Rectangle";
import {
  resetIsAssessmentFormOpenContacts,
  resetopenFormByClickContacts,
  setIsAssessmentFormOpenContacts,
} from "../../../../../../Controller/redux/features/ContactsFeature/documentContactSlice";
import { Modal, ModalOverlay } from "@chakra-ui/modal";
import ClientAssessmentForm from "../../../ClientPage/Components/UniqueClientScreen/Components/DocumentsTab/Components/ClientAssessmentForm";
import { getDocuments } from "../../../../../../Controller/redux/features/DaybookFeature/daybookSlice";
import { api } from "../../../../../../Controller/services/api";
import ConfirmationModal from "../../../../../components/ConfirmationModal/ConfirmationModal";
import UnsavedChangesPopup from "../../../../../components/UnsavedChangesPopup/UnsavedChangesPopup";
import {
  getStatusDetailsThunk,
  getUserTypesThunk,
} from "../../../../../../Controller/redux/features/CommonFeature/commonSlice";
import {
  getAssociationRelationshipsThunk,
  getAssociationTypesThunk,
  getAssociations,
  resetSelectedDropdownItemAssociations,
  resetSelectedFilterItemsAssociations,
  resetSelectedItemIndexFilterAssociations,
} from "../../../../../../Controller/redux/features/CommonFeature/associationSlice";
// import { setUserTypeIdForStaff } from "../../../../../../Controller/redux/features/StaffFeature/staffSlice";
// import { setUserTypeIdForProspects } from "../../../../../../Controller/redux/features/ProspectsFeature/prospectsSlice";
// import { setUserTypeIdForClient } from "../../../../../../Controller/redux/features/ClientFeature/clientsSlice";
import { Comms } from "../../../../Comms/Comms";
import Daybook from "../../../../Daybook/Daybook";
import {
  createDocumentThunk,
  getDocumentsByFilter,
  getUniquePrimaryApproversThunk,
  resetSelectedDropdownItemDocuments,
  resetSelectedFilterItemsDocuments,
  resetSelectedItemIndexFilterDocuments,
  setIsFormOpenTrue,
  setselectedDataObject,
  updateDocumentThunk,
} from "../../../../../../Controller/redux/features/CommonFeature/documentSlice";
import CircularNoteTrigger from "../../../../../components/CircularNoteTrigger/CircularNoteTrigger";
import ProfilePictureModal from "../../../../../components/ProfilePictureModal/ProfilePictureModal";
import CommonQuickMenu from "../../../../../components/CommonQuickMenu/CommonQuickMenu";
import { Spinner } from "@chakra-ui/react";

import UnderWork from "../../../../../components/UnderWork/UnderWork";
import Table from "../../../../../components/CommonTable/Table";
import { documentsColumns } from "../../../../../components/CommonTable/Data/AssociationHeaderDataArr";
import { setisDropdownOpen } from "../../../../../../Controller/redux/features/ProspectsFeature/prospectsSlice";
import {
  setshowUnsavedModal,
  setunsavedChanges,
} from "../../../../../../Controller/redux/features/AuthFeature/authSlice";
import { setWarningMessage } from "../../../../../../Controller/redux/features/ClientFeature/documentClientSlice";
import { getProviderBusinessByIdThunk } from "../../../../../../Controller/redux/features/BusinessFeature/businessSlice";

const ContactScreen = ({ screenType }: any) => {
  const dispatch = useAppDispatch();
  const getCurrentContact = useAppSelector(
    (state: any) => state.contacts.getUniqueContact
  );

  const [currentContact, setcurrentContact] = useState<any>(null);

  const dynamicColor = useAppSelector((state) => state?.common?.dynamicColor);

  const getUserRoles = useAppSelector((state) => state?.contacts?.contactRoles);

  const { id, pro_id }: any = useParams();
  const [clientId, setClientId] = useState("");
  const [providerId, setProviderId] = useState("");

  useEffect(() => {
    if (id) {
      let realId = window.atob(id);
      setClientId(realId);
      if (screenType) {
        setProviderId(window?.atob(pro_id));
      }
    }
  }, [id]);

  // @ts-ignore
  let dynamicContactId = parseInt(localStorage.getItem("dynamicContactId"));

  const [userRoles, setUserRoles] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (clientId) {
      dispatch(clearCurrentContactData());
      if (screenType) {
        if (providerId && clientId && dynamicContactId) {
          dispatch(
            getProviderContactByIdThunk({
              typeId: dynamicContactId,
              clientId,
              tenant_id: providerId,
            })
          )
            .then(() => {
              setLoading(false);
            })
            .catch((error: any) => {
              // console.log(error);
              setLoading(false);
            });
        }
      } else {
        dispatch(getContactByIdThunk({ typeId: dynamicContactId, clientId }))
          .then(() => {
            setLoading(false);
          })
          .catch((error: any) => {
            // console.log(error);
            setLoading(false);
          });
      }
      dispatch(getUserRolesContactsThunk({ dynamicContactId }));
    }
  }, [clientId, dynamicContactId, providerId]);

  useEffect(() => {
    if (getCurrentContact?.data?.data.length > 0) {
      setcurrentContact(getCurrentContact?.data?.data[0]);
    } else {
      setcurrentContact(null);
    }
  }, [getCurrentContact, clientId]);

  const isFormOpen = useAppSelector(
    (state) => state?.documentContact?.openFormByClick
  );
  const { hasDeleteDocument, warningPopup } = useAppSelector(
    (state) => state?.document
  );
  const { hasDeleteAssociation, warningPopupAssociation } = useAppSelector(
    (state) => state?.associations
  );
  ////////////////////////////////////////////////////////////////////// FETCH USER ROLES ////////////////////////////////////////////////////////////////////

  useEffect(() => {
    if (getUserRoles?.data && getUserRoles?.data?.length > 0) {
      setUserRoles(getUserRoles?.data);
    }
  }, [getUserRoles]);

  useEffect(() => {
    if (clientId) {
      dispatch(getDocuments({ clientId, providerId }));
      dispatch(getUniquePrimaryApproversThunk(clientId));
    }
  }, [clientId]);

  const { routeString, showUnsavedModal } = useAppSelector(
    (state) => state.auth
  );

  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const navigate = useNavigate();

  const handleMoveWithoutSaving = () => {
    dispatch(setshowUnsavedModal(false));
    dispatch(setunsavedChanges(false));
    navigate(routeString);
  };

  const handleMoveWithSave = async () => {
    dispatch(setshowUnsavedModal(false));
    dispatch(setunsavedChanges(false));
    navigate(routeString);
    try {
      let parms = updatecontact;
      if (screenType) {
        parms.tenant_id = providerId;
      }
      const res = await dispatch(updateContactThunk(parms));

      if (res.payload.status === "success") {
        toast.success("Contact updated successfully!", {
          progressStyle: { backgroundColor: dynamicColor },
          icon: (
            <span
              style={{
                marginRight: "8px",
                borderRadius: "50%",
                width: "7rem",
                height: "35px",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: dynamicColor,
                backgroundColor: "white",
                fontSize: "1.5rem",
                border: `3px solid ${dynamicColor}`,
              }}
            >
              ✓
            </span>
          ),
        });
      } else {
        toast.error("An Error Occurred!");
      }
    } catch (error) {
      console.error(error);
      toast.error("An Error Occurred!");
    }
    if (screenType) {
      dispatch(
        getProviderContactByIdThunk({
          typeId: dynamicContactId,
          clientId,
          tenant_id: providerId,
        })
      )
        .then(() => {
          setLoading(false);
        })
        .catch((error: any) => {
          // console.log(error);
          setLoading(false);
        });
    } else {
      dispatch(getContactByIdThunk({ typeId: dynamicContactId, clientId }))
        .then(() => {
          setLoading(false);
        })
        .catch((error: any) => {
          // console.log(error);
          setLoading(false);
        });
    }
  };

  const [uploadedFiles, setUploadedFiles] = useState<
    { file: File; key: string; file_name?: string; actual_file_name?: string }[]
  >([]);
  const [documentDetails, setdocumentDetails] = useState<any>();

  const handleGetDetails = (elem: any) => {
    setdocumentDetails({ ...elem, tenant_id: providerId });
  };

  let isPending = false;

  const handleOverlayClick = async (isFormSave: any) => {
    let duplicateDetailsInfo = { ...documentDetails, user_id: "" };
    if (documentDetails?.title == "") {
      const hasValueInOtherFields = Object.keys(duplicateDetailsInfo);
      let nonEmptyFieldCount = 0;

      let ishereAnyFieldsHaveVaule = false;
      hasValueInOtherFields.map((res) => {
        // if (documentDetails[res]) {
        if (!ishereAnyFieldsHaveVaule) {
          if (typeof duplicateDetailsInfo[res] === "string") {
            if (!!duplicateDetailsInfo[res]) {
              ishereAnyFieldsHaveVaule = true;
              !isFormSave
                ? dispatch(setWarningMessage(true))
                : toast.warning("Title is mandatory");
              return;
            }
          } else if (typeof duplicateDetailsInfo[res] === "object") {
            if (!!duplicateDetailsInfo[res]?.length) {
              if (!!duplicateDetailsInfo[res]) {
                ishereAnyFieldsHaveVaule = true;
                // toast.warning("Title is mandatory");
                !isFormSave
                  ? dispatch(setWarningMessage(true))
                  : toast.warning("Title is mandatory");
                return;
              }
            }
          }
        }
      });

      if (ishereAnyFieldsHaveVaule) {
        return;
      }
    }

    if (documentDetails?.title || documentDetails?.summary) {
      if (isPending) return;
      try {
        isPending = true;

        dispatch(createDocumentThunk(documentDetails))
          .then(() => {
            isPending = false;

            toast.success("Document Created Successfully!", {
              style: {},
              progressStyle: { backgroundColor: dynamicColor },
              icon: (
                <span
                  style={{
                    marginRight: "8px",
                    borderRadius: "50%",
                    width: "7rem",
                    height: "35px",
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: dynamicColor,
                    backgroundColor: "white",
                    fontSize: "1.5rem",
                    border: `3px solid ${dynamicColor}`,
                  }}
                >
                  ✓
                </span>
              ),
            });
            dispatch(getUniquePrimaryApproversThunk(clientId));
          })
          .catch(() => {
            isPending = false;

            toast.error("Failed To Create Document. Please Try Again!");
          });
        dispatch(getDocuments({ clientId, providerId }));
        dispatch(resetIsAssessmentFormOpenContacts());
        dispatch(resetopenFormByClickContacts());
      } catch {
        dispatch(getDocuments({ clientId, providerId }));
        dispatch(resetIsAssessmentFormOpenContacts());
        dispatch(resetopenFormByClickContacts());
      } finally {
        dispatch(getDocuments({ clientId, providerId }));
        dispatch(resetIsAssessmentFormOpenContacts());
        dispatch(resetopenFormByClickContacts());
      }
    } else {
      dispatch(resetIsAssessmentFormOpenContacts());
      dispatch(resetopenFormByClickContacts());

      dispatch(getDocuments({ clientId, providerId }));
    }
  };

  const handleCloseModal = async () => {
    if (documentDetails.title || documentDetails.summary) {
      if (isPending) return;
      try {
        isPending = true;

        dispatch(createDocumentThunk(documentDetails))
          .then(() => {
            isPending = false;

            toast.success("Document Created Successfully!", {
              style: {},
              progressStyle: { backgroundColor: dynamicColor },
              icon: (
                <span
                  style={{
                    marginRight: "8px",
                    borderRadius: "50%",
                    width: "7rem",
                    height: "35px",
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: dynamicColor,
                    backgroundColor: "white",
                    fontSize: "1.5rem",
                    border: `3px solid ${dynamicColor}`,
                  }}
                >
                  ✓
                </span>
              ),
            });
            dispatch(getUniquePrimaryApproversThunk(clientId));
          })
          .catch(() => {
            isPending = false;

            toast.error("Failed To Create Document. Please Try Again!");
          });
        dispatch(getDocuments({ clientId, providerId }));
        dispatch(resetIsAssessmentFormOpenContacts());
        dispatch(resetopenFormByClickContacts());
      } catch {
        dispatch(getDocuments({ clientId, providerId }));
        dispatch(resetIsAssessmentFormOpenContacts());
        // dispatch(resetIsAssessmentFormOpenContacts());
      } finally {
        dispatch(resetIsAssessmentFormOpenContacts());
        dispatch(resetopenFormByClickContacts());

        dispatch(getDocuments({ clientId, providerId }));
        dispatch(resetIsAssessmentFormOpenContacts());
      }
    } else {
      dispatch(resetIsAssessmentFormOpenContacts());
      dispatch(resetopenFormByClickContacts());

      dispatch(getDocuments({ clientId, providerId }));
      dispatch(resetIsAssessmentFormOpenContacts());
    }
  };
  const [buttonTab, setbuttonTab] = useState({
    Details: true,
    Associations: false,
    // Clinical: false,
    Comms: false,
    Documents: false,
    // Finance: false,
    Daybook: false,
  });

  const [details, setDetails] = useState<any>({
    givenName: "",
    middleName: "",
    familyName: "",
    knownAs: "",
    address: "",
    email: "",
    mobile: "",
    role: "",
  });

  useEffect(() => {
    const fetchedData: any = currentContact;
    if (fetchedData) {
      setDetails({
        givenName: currentContact?.first_name,
        middleName: currentContact?.middle_name,
        familyName: currentContact?.last_name,
        knownAs: currentContact?.pref_name
          ? currentContact?.pref_name
          : currentContact?.first_name,
        address: currentContact?.user_addresses[0]?.address || "",
        email: currentContact?.email,
        mobile: currentContact?.mobile !== null ? currentContact?.mobile : "",
        role: currentContact ? currentContact?.roles[0]?.id : "",
      });
    } else {
      setDetails({
        givenName: "",
        familyName: "",
        knownAs: "",
        address: "",
        email: "",
        mobile: "",
        role: "",
      });
    }
  }, [currentContact]);

  const [updatecontact, setUpdateContact] = useState<any>({
    first_name: "",
    middle_name: "",
    last_name: "",
    pref_name: "",
    email: "",
    mobile: "",
    // phone: "",
    dob: "",
    funding_type: "",
    package_level: "",
    funding_available: "",
    acat_assessment: "",
    initial_enquiry: "",
    role: 4,
    user_id: clientId,
    type: 9,
    address: "",
  });

  useEffect(() => {
    setUpdateContact({
      first_name: details.givenName,
      middle_name: details.middleName,
      last_name: details.familyName,
      pref_name: details.knownAs,
      address: details.address,
      dob: "",
      mobile: details.mobile,
      package_level: "",
      funding_available: "",
      acat_assessment: "",
      initial_enquiry: "",
      email: details.email,
      funding_type: "",
      role: details?.role,
      user_id: clientId,
      type: dynamicContactId,
      phone: currentContact?.phone,
    });
  }, [clientId, details]);

  // // console.log(currentContact, "currentContact");

  // // console.log(updateClient,"updateClientHere")

  // UPDATE CONTACT FUNCTION
  const handleSaveChanges = async () => {
    setHasUnsavedChanges(false);
    dispatch(setunsavedChanges(false));
    try {
      let parms = updatecontact;
      if (screenType) {
        parms.tenant_id = providerId;
      }
      const res = await dispatch(updateContactThunk(parms));

      if (res.payload.status === "success") {
        toast.success("Contact updated successfully!", {
          style: {},
          progressStyle: { backgroundColor: dynamicColor },
          icon: (
            <span
              style={{
                marginRight: "8px",
                borderRadius: "50%",
                width: "7rem",
                height: "35px",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: dynamicColor,
                backgroundColor: "white",
                fontSize: "1.5rem",
                border: `3px solid ${dynamicColor}`,
              }}
            >
              ✓
            </span>
          ),
        });
      } else {
        toast.error("An Error Occurred!");
      }
    } catch (error) {
      console.error(error);
      toast.error("An Error Occurred!");
    }
    if (screenType) {
      dispatch(
        getProviderContactByIdThunk({
          typeId: dynamicContactId,
          clientId,
          tenant_id: providerId,
        })
      )
        .then(() => {
          setLoading(false);
        })
        .catch((error: any) => {
          // console.log(error);
          setLoading(false);
        });
    } else {
      dispatch(getContactByIdThunk({ typeId: dynamicContactId, clientId }))
        .then(() => {
          setLoading(false);
        })
        .catch((error: any) => {
          // console.log(error);
          setLoading(false);
        });
    }
  };

  // RETAIN THE STATE
  useEffect(() => {
    if (localStorage.getItem("activeTab") === "Documents") {
      handleClick("Documents");
    } else if (localStorage.getItem("activeTab") === "Associations") {
      handleClick("Associations");
    } else if (localStorage.getItem("activeTab") === "Meals") {
      handleClick("Meals");
    } else if (localStorage.getItem("activeTab") === "Comms") {
      handleClick("Comms");
    } else if (localStorage.getItem("activeTab") === "Clinical") {
      handleClick("Clinical");
    } else if (localStorage.getItem("activeTab") === "Finance") {
      handleClick("Finance");
    } else if (localStorage.getItem("activeTab") === "Daybook") {
      handleClick("Daybook");
    }
  }, []);
  // NAVIGATING AMONG BUTTONS
  const handleClick = (button: string) => {
    setstageTwoToGoalsModal(false);
    setbuttonTab((prevState) => ({
      ...prevState,
      Details: button === "Details",
      Associations: button === "Associations",
      // Clinical: button === "Clinical",
      Comms: button === "Comms",
      Documents: button === "Documents",
      // Finance: button === "Finance",
      Daybook: button === "Daybook",
    }));

    const defaultTabState = {
      Details: false,
      Associations: false,
      Clinical: false,
      Comms: false,
      Documents: false,
      Finance: false,
      Daybook: false,
      Meals: false,
    };
    dispatch(resetopenFormByClickContacts());
    dispatch(resetIsAssessmentFormOpenContacts());
    dispatch(resetSelectedDropdownItemAssociations());
    dispatch(resetSelectedItemIndexFilterAssociations());
    dispatch(resetSelectedFilterItemsAssociations());
    dispatch(resetSelectedDropdownItemDocuments());
    dispatch(resetSelectedItemIndexFilterDocuments());
    dispatch(resetSelectedFilterItemsDocuments());
    if (button === "Documents") {
      dispatch(setIsAssessmentFormOpenContacts());
    }

    if (!hasDeleteDocument) {
      const newTabState = { ...defaultTabState, [button]: true };
      localStorage.setItem("activeTab", button);
      setbuttonTab(newTabState);
    } else {
      setbuttonTab({ ...defaultTabState, Documents: true });
      localStorage.setItem("activeTab", "Documents");
      // dispatch(setWarningPopup(true));
    }
  };

  // CHANGE TO INPUT FIELDS
  const handleChange = (
    index: number,
    e: React.ChangeEvent<HTMLInputElement> | any
  ) => {
    const { value } = e.target;
    const contactRole = getUserRoles?.data?.find(
      (val: any) => val?.id === +value
    );

    const fieldName = Object.keys(details)[index];

    setDetails((prevState: any) => ({
      ...prevState,
      [fieldName]: value,
    }));
    dispatch(setunsavedChanges(true));

    if (!hasUnsavedChanges) {
      setHasUnsavedChanges(true);
    }
  };

  useEffect(() => {
    dispatch(getAssociationTypesThunk());
    dispatch(getAssociationRelationshipsThunk());
    dispatch(getUserTypesThunk());
  }, []);
  useEffect(() => {
    if (clientId) {
      dispatch(
        getAssociations({ clientId, searchVal: "", type: "", pageNumber: 1 })
      );
    }
  }, [clientId]);

  const [showProfile, setShowProfile] = useState<boolean>(false);
  const [showGoalsModal, setshowGoalsModal] = useState(false);
  const [stageTwoToGoalsModal, setstageTwoToGoalsModal] = useState(false);

  const [selectedAddress, setSelectedAddress] = useState<any>("");
  const [possibleAddresses, setPossibleAddresses] = useState<any>([]);

  function initService(query: any) {
    const displaySuggestions = function (predictions: any, status: any) {
      // @ts-ignore
      if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
        // console.log("Location service down!");
        return;
      }
      if (!predictions) {
        setPossibleAddresses([]);
        return;
      }
      setPossibleAddresses(predictions);
    };
    // @ts-ignore
    const service = new window.google.maps.places.AutocompleteService();
    // console.log(query);
    service.getQueryPredictions({ input: query }, displaySuggestions);
  }

  const handleChangeForAddress = (index: number, e: any) => {
    dispatch(setunsavedChanges(true));
    if (!hasUnsavedChanges) {
      setHasUnsavedChanges(true);
    }
    const { value } = e.target;
    setDetails({ ...details, address: value });
    initService(value);
    if (value.length === 0) {
      setDetails({ ...details, address: "" });
      setPossibleAddresses([]);
    }
    // setDetails((prevState: any) => ({
    //   ...prevState,
    //   [fieldName]: value,
    // }));

    // setunsavedChanges(true);
    // if (!hasUnsavedChanges) {
    //   setHasUnsavedChanges(true);
    // }
  };

  const selectAddress = (e: any) => {
    if (!hasUnsavedChanges) {
      setHasUnsavedChanges(true);
    }
    setSelectedAddress(e.target.innerHTML);
    setDetails({ ...details, address: e.target.innerHTML });
    dispatch(setunsavedChanges(true));
    setPossibleAddresses([]);
  };

  useEffect(() => {
    if (isFormOpen) {
      setbuttonTab({
        Details: false,
        Associations: false,
        Comms: false,
        Documents: true,
        Daybook: false,
      });
    }
  }, [isFormOpen]);
  /// ASSOCIATION TABLE START //
  const dropdownRefAssociation = useRef<any>();
  const svgParentRefAssociation = useRef<any>();
  const [isDropdownOpenAssociation, setIsDropdownOpenAssociation] =
    useState(false);
  const {
    selectedFilterItemsAssociations,
    sortByAssociationsR,
    searchValAssociations,
  } = useAppSelector((state) => state?.associations);

  const { filterIconColor } = useAppSelector((state) => state?.common);
  const handleClickOutsideAssociation = (event: any) => {
    if (
      dropdownRefAssociation.current &&
      !dropdownRefAssociation.current.contains(event.target) &&
      svgParentRefAssociation.current &&
      !svgParentRefAssociation.current.contains(event.target)
    ) {
      setIsDropdownOpenAssociation(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutsideAssociation);
    return () => {
      document.removeEventListener("click", handleClickOutsideAssociation);
    };
  }, []);
  const associationColumns = [
    { header: "Name", accessor: "user_name" },
    { header: "Category", accessor: "category" },
    { header: "Relationship", accessor: "relationship_name" },
    { header: "Contact Number", accessor: "number" },
    { header: "Associated Business/Site", accessor: "funding_type" },
    { header: "Action", accessor: "actionAssociation" },
  ];
  const { getAssociationByUserId } = useAppSelector(
    (state) => state?.associations
  );
  /// ASSOCIATION TABLE END //
  // DOCUMENTS TABLE START //
  const dropdownRefDocuments = useRef<any>();
  const svgParentRefDocuments = useRef<any>();
  const [isDropdownOpenDocuments, setIsDropdownOpenDocuments] = useState(false);
  const { selectedFilterItemsDocuments, sortByDocumentsR, searchValDoc } =
    useAppSelector((state) => state?.document);
  const handleClickOutsideDocuments = (event: any) => {
    if (
      dropdownRefDocuments.current &&
      !dropdownRefDocuments.current.contains(event.target) &&
      svgParentRefDocuments.current &&
      !svgParentRefDocuments.current.contains(event.target)
    ) {
      setIsDropdownOpenDocuments(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutsideDocuments);
    return () => {
      document.removeEventListener("click", handleClickOutsideDocuments);
    };
  }, []);

  const getDocumentsData = useAppSelector(
    (state) => state?.document?.getDocuments
  );
  const updatedDoc = useAppSelector(
    (state) => state?.document?.updateDocumentData
  );
  const selectedOps = useAppSelector(
    (state) => state?.document?.selectedOptions
  );
  const [isFormOpenDoc, setIsFormOpenDoc] = useState(false);
  const [selectedObject, setSelectedObject] = useState<any>(null);
  const [showStateOfDocument, setShowStateOfDocument] = useState<any>("");

  const [primaryApprover, setPrimaryApprover] = useState([]);
  const [ongoingApprover, setOngoingApprover] = useState([]);

  const handleClickDocRow = (e: any, id: any, title: any) => {
    e.stopPropagation();
    if (isDropdownOpenDocuments) {
      setIsDropdownOpenDocuments(false);
    } else {
      const selectedDocument = getDocumentsData?.find((elem: any) => {
        return elem?.id === id;
      });
      setIsFormOpenDoc(true);
      dispatch(setIsFormOpenTrue());
      setSelectedObject(selectedDocument);
      setPrimaryApprover(selectedDocument?.primary_approvers);
      setOngoingApprover(selectedDocument?.ongoing_approvers);
      dispatch(setselectedDataObject(selectedDocument));
      setUploadedFiles(selectedDocument?.files);
      setShowStateOfDocument(title);
    }
  };
  const handleOverlayClickUpdate = async () => {
    if (!selectedObject?.isThereAnyChanges) {
      setIsFormOpenDoc(false);
      return;
    }

    try {
      // dispatch(getDocuments( {  clientId, providerId }));

      /* */
      dispatch(
        updateDocumentThunk({
          title: updatedDoc?.title,
          summary: updatedDoc.summary,
          primary_review: updatedDoc?.primary_review,
          ongoing_review: updatedDoc.ongoing_review,
          period_picker: updatedDoc.period_picker,
          start: updatedDoc.start,
          end: updatedDoc.end,
          primary_approver_id: primaryApprover.map((res: any) => res?.id),
          ongoing_approver_id: ongoingApprover.map((res: any) => res?.id),
          hard_copy_completed_date: updatedDoc.hard_copy_completed_date,
          document_files: updatedDoc.document_files,
          user_id: updatedDoc.user_id,
          document_id: updatedDoc?.document_id,
          tag: updatedDoc?.tagArray,
          tenant_id: providerId,
          note_details: updatedDoc?.note_details || {},

        })
      )
        .then(() => {
          toast.success("Document updated successfully!", {
            // style: {},
            progressStyle: { backgroundColor: dynamicColor },
            icon: (
              <span
                style={{
                  marginRight: "8px",
                  borderRadius: "50%",
                  width: "7rem",
                  height: "35px",
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: dynamicColor,
                  backgroundColor: "white",
                  fontSize: "1.5rem",
                  border: `3px solid ${dynamicColor}`,
                }}
              >
                ✓
              </span>
            ),
          });
          dispatch(getUniquePrimaryApproversThunk(clientId));
          dispatch(getDocuments({ clientId, providerId }));
          dispatch(
            getDocumentsByFilter({
              clientId,
              filterValue: selectedOps?.join(","),
              searchVal: "",
              sortType: "",
              filterColumn: "",
              sortColumn: "",
              providerId,
            })
          );
        })
        .catch(() =>
          toast.error("Failed To Update Document. Please Try Again!")
        );
    } catch (err) {}

    setchangesToForm("");
    setIsFormOpenDoc(false);
    dispatch(getUniquePrimaryApproversThunk(clientId));
  };
  const [changesToForm, setchangesToForm] = useState("");

  const handleChangesToForm = (val: any) => {
    setchangesToForm(val);
  };
  // DOCUMENTS TABLE END //
  const { domainDetail } = useAppSelector((state) => state?.common);
  let businessType = domainDetail?.businessmodel_type;

  const [userTypes, setUserTypes] = useState<any>([]);
  useEffect(() => {
    if (domainDetail && domainDetail?.contact_type) {
      let spliteTheTypeString = domainDetail?.contact_type.split(",");
      setUserTypes(spliteTheTypeString);
    }
  }, [domainDetail]);

  const contactsStatusDetailsNums = useAppSelector(
    (state) => state.common.domainDetail.contact_status
  );
  useEffect(() => {
    dispatch(getStatusDetailsThunk(contactsStatusDetailsNums));
  }, [contactsStatusDetailsNums]);

  const openWarningSection = useAppSelector(
    (state) => state?.documentClient?.openWarningSection
  );

  const needToSkipIndexForB2B = [1, 2, 3];

  return (
    <div>
      {loading && <Spinner position={"absolute"} top={"50%"} left={"50%"} />}
      <div
        style={{
          position: "relative",
        }}
      >
        {stageTwoToGoalsModal && (
          <CommonQuickMenu
            setshowGoalsModal={setshowGoalsModal}
            showGoalsModal={showGoalsModal}
            setstageTwoToGoalsModal={setstageTwoToGoalsModal}
            type="contacts"
            filterItems={true}
          />
        )}
        <CommonUpperBox
          imageSection
          imageURL={currentContact?.profile_image}
          title={`${currentContact?.first_name || ""} ${
            currentContact?.last_name || ""
          }`}
          type={"screen"}
          currentUser={currentContact}
          onClickUserImage={() => setShowProfile(true)}
          onClickPlus={() => {
            // if (buttonTab.Documents) {
            setstageTwoToGoalsModal(true);
            // }
          }}
        />
      </div>

      {showProfile && (
        <ProfilePictureModal
          showProfile={showProfile}
          className="profile_picture_external"
          setShowProfile={setShowProfile}
          currentClient={currentContact}
        />
      )}

      {buttonTab.Details && (
        <>
          {/* <Header />
          <SideNavbar /> */}

          <CommonHeader
            currentContact={currentContact}
            mainType="contacts"
            type="details"
            handleClick={handleClick}
          />

          <div className="main-box">
            {showUnsavedModal && <UnsavedChangesPopup />}

            <div className="px-[10px]">
              <div className=" client-screen-personal-details-heading">
                <span className=" client-screen-sub-heading-title">
                  Personal Details
                </span>
                <span className=" client-screen-line"></span>
              </div>
              <div className="main-box-body">
                {dummyArrContacts.map((elem: any, index: any) => {
                  const fieldName = Object.keys(details)[index];
                  if (
                    needToSkipIndexForB2B.includes(index) &&
                    businessType == "B2B"
                  ) {
                    return null;
                  }

                  let title = elem.UpperTitle;
                  if (businessType == "B2B") {
                    index == 0
                      ? (title = "Contact Name:")
                      : index == 7
                      ? (title = "Contact Type:")
                      : "";
                  }

                  return (
                    <div className="content-wrapper" key={index}>
                      <div className="wrapper-title">{title}</div>

                      {index === 7 ? (
                        <select
                          className="wrapper-info wrapper-info-select-dropdown"
                          onChange={(e) => handleChange(index, e)}
                          value={details[fieldName]}
                          style={{
                            border: "none",
                            outline: "none",
                            height: "fit-content",
                            backgroundColor: "white",
                            // width: "256px",
                          }}
                        >
                          <option>Please Select...</option>

                          {userRoles?.map((elem: any, index: number | any) => {
                            let isExist: any = userTypes.includes(
                              `${elem?.desc}`
                            );
                            if (isExist) {
                              return (
                                <option key={index} value={elem?.id}>
                                  {elem?.desc}
                                </option>
                              );
                            }
                          })}
                        </select>
                      ) : index === 4 ? (
                        /*furkan location*/
                        <>
                          <div>
                            <input
                              type="text"
                              onChange={(e) => handleChangeForAddress(index, e)}
                              className="wrapper-info"
                              placeholder="Enter an address"
                              value={details[fieldName]}
                            />
                            <div className="address_listing">
                              <ul>
                                {possibleAddresses.length > 0
                                  ? possibleAddresses.map(
                                      (address: any, ind: any) => {
                                        return (
                                          <li
                                            onClick={selectAddress}
                                            className="address_listing_item"
                                          >
                                            {address.description}
                                          </li>
                                        );
                                      }
                                    )
                                  : null}
                              </ul>
                            </div>
                          </div>
                        </>
                      ) : (
                        <input
                          className="wrapper-info"
                          onChange={(e) => handleChange(index, e)}
                          value={details[fieldName]}
                          placeholder={elem?.placeHolder}
                          style={{
                            border: "none",
                            outline: "none",
                            height: "fit-content",
                          }}
                        />
                      )}

                      {elem.index === 5 && (
                        <div className="dropdown-icon">
                          <img src={DropdownIcon} alt="dropdown-icon" />
                        </div>
                      )}
                      <div className="wrapper-line"></div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          {hasUnsavedChanges && (
            <>
              <div
                className="save"
                style={{
                  position: "absolute",
                  marginRight: "200px",
                  bottom: "10px",
                }}
              >
                <p>Would you like to save your changes?</p>
                <div className="btnDivision">
                  <button
                    style={{
                      backgroundColor: dynamicColor,
                    }}
                    onClick={handleSaveChanges}
                  >
                    Save
                  </button>
                  <button
                    style={{
                      fontFamily: "Helvetica",
                      fontStyle: "normal",
                      fontWeight: "400",
                      fontSize: "16px",
                      lineHeight: "18px",
                      backgroundColor: "#F9F9F9",
                      border: "none",
                      color: "#9296A2",
                    }}
                    onClick={() => {
                      dispatch(
                        getContactByIdThunk({
                          typeId: dynamicContactId,
                          clientId,
                        })
                      );

                      setHasUnsavedChanges(false);
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </>
          )}
          {/* <Rectangle type="stickyHub" /> */}
          <CircularNoteTrigger type="stickyHub" />
        </>
      )}

      {buttonTab.Documents && (
        <>
          <CommonHeader
            currentContact={currentContact}
            mainType="contacts"
            type="documents"
            handleClick={handleClick}
          />
          <Table
            dynamicHeaderColor={domainDetail?.doctbl_color}
            thData={documentsColumns}
            classNameTr="client-documents-tab-table-head"
            classNameTable="documents-table-additional-styles"
            type="documents"
            dropdownRef={dropdownRefDocuments}
            setIsDropdownOpen={setIsDropdownOpenDocuments}
            isDropdownOpen={isDropdownOpenDocuments}
            onClickSvg={() => setIsDropdownOpenDocuments(true)}
            svgParentRef={svgParentRefDocuments}
            allClients={getDocumentsData}
            // onClickRow={handleClickDocRow.bind(this, "onRow")}
            onClickRow={handleClickDocRow}
            classNameRow="client-documents-tab-table-body"
            fill={
              selectedFilterItemsDocuments.length > 0 ||
              searchValDoc.length > 0 ||
              sortByDocumentsR.length > 0
                ? filterIconColor
                : "#D8D3D3"
            }
          />
          {/* <CommonTable type="documents" /> */}
          {/* <Rectangle type="stickyHub" /> */}
          <CircularNoteTrigger type="stickyHub" />

          {warningPopup && (
            <>
              <ConfirmationModal type="" />
            </>
          )}
          {hasDeleteDocument && (
            <>
              <UnsavedChangesPopup type="documents" />
            </>
          )}

          {isFormOpen && openWarningSection && (
            <ConfirmationModal
              type="documents"
              handleMoveWithoutSaving={() => {
                dispatch(setWarningMessage(false));
              }}
              handleMoveWithSave={() => {
                dispatch(setWarningMessage(false));
                dispatch(resetopenFormByClickContacts());
                // dispatch(setopenClientAssessmentForm(false));
              }}
            />
          )}

          {isFormOpen && (
            <div className="client-assessment-form-div">
              {isFormOpen && (
                <Modal
                  isOpen={true}
                  onClose={() => {}}
                  closeOnOverlayClick={true}
                  isCentered
                >
                  <ModalOverlay onClick={() => {}} zIndex={10} />

                  <ClientAssessmentForm
                    type="new-form"
                    // openForm={openForm}
                    // setopenForm={setopenForm}
                    // title={typeCheckForAssessmentform}
                    handleOverlayClickContacts={handleOverlayClick}
                    handleOverlayClickUpdateContacts={handleOverlayClickUpdate}
                    handleGetDetails={handleGetDetails}
                    uploadedFiles={uploadedFiles}
                    setUploadedFiles={setUploadedFiles}
                    // changesMadeToTheForm={changesMadeToTheForm}
                    // setChangesMadeToTheForm={setChangesMadeToTheForm}
                    handleSaveClick={handleOverlayClick}
                  />
                </Modal>
              )}
            </div>
          )}
          {isFormOpenDoc && (
            <Modal
              isOpen={true}
              onClose={() => {}}
              closeOnOverlayClick={true}
              isCentered
            >
              <ModalOverlay onClick={() => {}} zIndex={10} />
              <ClientAssessmentForm
                type="new-form"
                handleChangesToForm={handleChangesToForm}
                dataObject={selectedObject}
                setDataObject={setSelectedObject}
                handleGetDetails={() => {}}
                showStateOfDocument={showStateOfDocument}
                uploadedFiles={uploadedFiles}
                setUploadedFiles={setUploadedFiles}
                primaryApprover={primaryApprover}
                setPrimaryApprover={setPrimaryApprover}
                ongoingApprover={ongoingApprover}
                setOngoingApprover={setOngoingApprover}
                handleOverlayClickUpdateContacts={handleOverlayClickUpdate}
                handleSaveClick={handleOverlayClickUpdate}
                setIsFormOpenDocContacts={setIsFormOpenDoc}
              />
            </Modal>
          )}
          {/* <Rectangle type="stickyHub" /> */}
          <CircularNoteTrigger type="stickyHub" />
        </>
      )}

      {buttonTab.Associations && (
        <>
          <CommonHeader
            mainType="contacts"
            type="associations"
            handleClick={handleClick}
            currentContact={currentContact}
          />
          {/* <CommonTable type="associations" /> */}
          <Table
            dynamicHeaderColor={domainDetail?.assoctbl_color}
            type="asociations"
            classNameTable="common-table-container-associations"
            thData={associationColumns}
            classNameTr="client-associations-tab-table-head"
            dropdownRef={dropdownRefAssociation}
            onClickSvg={() => setIsDropdownOpenAssociation(true)}
            isDropdownOpen={isDropdownOpenAssociation}
            svgParentRef={svgParentRefAssociation}
            allClients={getAssociationByUserId}
            classNameRow="client-associations-tab-table-body"
            fill={
              selectedFilterItemsAssociations.length > 0 ||
              searchValAssociations.length > 0 ||
              sortByAssociationsR.length > 0
                ? filterIconColor
                : "#D8D3D3"
            }
          />
          {/* <Rectangle type="associations" /> */}
          <CircularNoteTrigger type="associations" title="Association" />
          {warningPopupAssociation && (
            <>
              <ConfirmationModal type="" />
            </>
          )}
          {hasDeleteAssociation && (
            <>
              <UnsavedChangesPopup type="associations" />
            </>
          )}
          {/* <UnderWork /> */}
        </>
      )}

      {buttonTab.Comms && (
        <>
          <CommonHeader
            mainType="contacts"
            type="comms"
            handleClick={handleClick}
            currentContact={currentContact}
          />
          {/* <div className="comms_client_section_wrapper">
            <Comms type="contacts" />
          </div> */}
          <div style={{ marginTop: "10px" }}>
            <Comms isUserType={true} />
          </div>
        </>
      )}

      {buttonTab.Daybook && (
        <>
          <CommonHeader
            mainType="contacts"
            currentContact={currentContact}
            type="daybook"
            handleClick={handleClick}
          />
          <Daybook type="contacts" />
        </>
      )}
    </div>
  );
};

export default ContactScreen;
