import { api } from './api'



export function getstaffdata()
{
    return api.get('/users?type=3&sort_column=id&sort_type=desc');
}

export function getUserTypes()
{
    return api.get('/users/get_user_types');
}


export function getAllTags()
{
    return api.get('/tags/get_tags');
}






export function inviteUser(invitevalue: any)
{
    return api.post('users/invite_user', invitevalue);
}

export function getUserDetails(id:any)
{
    return api.get(`/users?type=1&id=${id}`);

    // return api.get('/users?type=2&sort_column=id&sort_type=desc&id=${params.id}');
}