const UploadSVG = () => {
  return (
    <div>
      <svg

        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="14"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.42188 8.33281L5.5432 10.3977L10.4825 5.58979L10.4825 15.2055C10.4825 16.0119 11.1541 16.6656 11.9825 16.6656C12.811 16.6656 13.4825 16.0119 13.4825 15.2055L13.4825 5.58979L18.4219 10.3977L20.5432 8.3328L11.9825 -3.73345e-06L3.42188 8.33281Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3 18.1562V19.6163C3 20.4227 3.67157 21.0764 4.5 21.0764L19.5 21.0764C20.3284 21.0764 21 20.4227 21 19.6163V18.1562H24V19.6163C24 22.0355 21.9853 23.9966 19.5 23.9966L4.5 23.9966C2.01472 23.9966 0 22.0355 0 19.6163V18.1562H3Z"
          fill="black"
        />
      </svg>
    </div>
  );
};

export default UploadSVG;
