export const btnArrNoMeals = [
  "Details",
  "Associations",
  "Comms",
  "Documents",
  "Daybook",
];

export const btnArrContacts = [
  "Details",
  "Associations",
  "Comms",
  "Documents",
  "Daybook",
];

export const btnArrProvidersSecondTabSection = [
  "Details",
  "Associations",
  "Comms",
  "Documents",
  "Daybook",
  "Finance",
];

export const btnArrBusiness = [
  "Details",
  "Associations",
  "Comms",
  "Documents",
  "Daybook",
];
export const btnArrProviders = [
  "Prospects",
  "Clients",
  "Business",
  "Locations",
  "Teams",
  "Contacts",
  "Staff",
  "Audit",
  "Vendor Access",
  "Report Access",
];

export const btnArrStaff = [
  "Details",
  "Associations",
  "Comms",
  "Documents",
  "Daybook",
  "Meals",
];

export const btnArrBillingRule = ["Details"];

export const btnArrPayRule = ["Details"];

export const btnArrVendors = ["Details"];

export const btnArrReportsHub = ["Details", "Comms"];

export const btnArrReportComms = ["Comms"];
