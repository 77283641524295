import React from "react";
import { CommsRedIcon, YellowIcon } from "./Data/SVGs";
import "../ReportingWidget/ReportingWidget.css";
import ClientImage from "../../../Model/Assets/png/clientImg.png";
import CommonChart from "../CommonCharts/CommonChart";
import { useAppSelector } from "../../../Controller/redux/Hooks";

interface ReportingWidgetProps {
  title?: string;
  clientName?: string;
  clientType?: string;
  clientLastVisit?: string;
  chartLabel?: string;
  labels?: string[] | any;
  values?: string[] | any;
  chartType?: string;
  chartWidth: string;
  isFromVillage?: boolean;
  pieChartBackgroundColors?: string[] | any;
  borderWidth: string | number;
  chartSize: string | any;
  pointRadius?: number | string;
  onClick?: React.MouseEventHandler | any;
  data?: object[] | any;
  data_2?: object[] | any;
  statusData?: object[] | any;
  lables?: object[] | any;
  heading?: string;
  displayX?: boolean;
  displayY?: boolean;
  name?: string;
  providerName?: string;
  chartHeight?: string;
  lastUpdate?: string;
  isClinicalScreen?: boolean;
}

const CommonReportingWidget = ({
  lastUpdate = "",
  name,
  title,
  chartType,
  // values,
  chartLabel,
  pieChartBackgroundColors,
  chartWidth,
  chartHeight,
  borderWidth,
  chartSize,
  pointRadius,
  onClick,
  data,
  data_2,
  statusData,
  isFromVillage = false,
  displayX = false,
  displayY = false,
  providerName,
  heading = "Update",
  labels = [
    "Blood Pressure",
    "SpO2",
    "Blood Glucose",
    "Age",
    "Weight",
    "Height",
  ],
  isClinicalScreen
}: ReportingWidgetProps) => {
  const { user } = useAppSelector((state) => state?.common);

  return (
    <div
      onClick={onClick}
      className={`
    ${
      chartSize === "medium"
        ? "reporting__widget__wrapper"
        : "reporting__widget__wrapper-small"
    }
    `}
    >
      <div className="upper__segment__widget" style={{
        visibility: isClinicalScreen ? "hidden" : "visible"
      }}>
        <YellowIcon />

        {chartSize === "medium" ? (
          <>
            <img
              src={ClientImage}
              alt="client-img"
              className="note__sub__title-1-client__image"
            />

            <div className="client__name">
              {/* {`${user?.first_name?.charAt(0)}. ${user?.last_name}`}
               */}
              {name}
            </div>
          </>
        ) : chartSize === "small" ? (
          <CommsRedIcon commsLength={"6"} />
        ) : (
          ""
        )}
      </div>
      <div
        className={
          chartSize === "small"
            ? "middle__segment__widget-small"
            : isFromVillage
            ? "villages__widget__flag"
            : "middle__segment__widget"
        }
      >
        <div
          className={`
          ${chartSize === "medium" ? "report__title" : "report__title-small"}
       `}
        >
          {title || "Report Title"}
          {/* {heading} */}
        </div>

        <div className="widget_graph_container">
          {chartSize === "small" && (
            <span className="last__update">{`Last Update ${lastUpdate}`}</span>
          )}

          <CommonChart
            values={data}
            data_2={data_2}
            displayX={displayX}
            displayY={displayY}
            pointBackgroundColor={"#B7A9FF"}
            borderWidth={borderWidth}
            pointBorderColor={"rgba(0, 0, 0, 0)"}
            pointRadius={pointRadius}
            chartLabel={chartLabel || "Health"}
            chartType={chartType || "line"}
            labels={labels}
            isFromVillage={isFromVillage}
            // labels={statusData?.map((elem: any) => elem?.data)}
            chartLineColor={"#000"}
            chartWidth={chartWidth}
            chartSize={chartSize}
            dataColors={pieChartBackgroundColors || ["red", "green", "blue"]}
            chartHeight={chartHeight}
          />
          <div
            className={`
      ${
        chartSize === "medium"
          ? "lower__segment__widget"
          : "lower__segment__widget-small"
      }
      `}
          >
            <div className="reporting_chart_status">
              {statusData?.map((elem: any, index: any) => (
                <div className="reporting_chart_status_inner" key={index}>
                  <span
                    style={{
                      backgroundColor: elem?.color,
                    }}
                    className="reporting_chart_status_color"
                  >
                    &nbsp;
                  </span>
                  <span>{elem?.data}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {/* {chartSize === "medium" && (
        <div className="widget_heading">{heading}</div>
      )} */}
      <div
        className={`
      ${
        chartSize === "medium"
          ? "lower__segment__widget"
          : "lower__segment__widget-small"
      }
      `}
      >
        {[
          {
            key: "Name",
            detail: providerName,
          },
        ].map((detail, index) => {
          return (
            <div key={index} className="client__personal__details">
              <div className="client__key">{detail?.key}</div>
              <div className="client__detail">{detail?.detail}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default React.memo(CommonReportingWidget);
