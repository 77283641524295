import { statusMap } from "../../../../Model/utils/Helper/statusMap";

import WidgetItems from "../../../pages/People/ClientPage/Components/WidgetItems";

interface StatusPropsTypes {
  currentData: any;
  position: any;
  setOpenStatusWidget: (arg: any) => any;
  OpenStatusWidget: any;
  widgetRef: any;
  setAllClientsDataToTrackClickOfStatusClick: (arg: any) => any;
  allClientsDataToTrackClickOfStatusClick: any;
  setOpenWidgetContacts?: (arg: any) => any;
  setOpenWidgetProspects?: (arg: any) => any;
  setShowStatusWidget?: (arg: any) => any;
  setOpenWidgetBusiness?: (arg: any) => any;
  setOpenWidgetTeams?: (arg: any) => any;
  itemsArr: any;
  screenType:
    | "clinical"
    | "prospects"
    | "teams"
    | "business"
    | "locations"
    | "contacts"
    | "staffScreen"
    | "providers"
    | "clients";
}

const Status: React.FC<StatusPropsTypes | any> = ({
  currentData,
  position,
  setOpenStatusWidget,
  OpenStatusWidget,
  widgetRef,
  setAllClientsDataToTrackClickOfStatusClick,
  allClientsDataToTrackClickOfStatusClick,
  setOpenWidgetBusiness,
  setOpenWidgetContacts,
  setOpenWidgetProspects,
  setShowStatusWidget,
  setOpenWidgetTeams,
  screenType,
  itemsArr,
}) => {

  console.log('currentData', currentData);
  return (
    <div
      className="status-btn status-btn-active status-btn-active-clients "
      style={{
        position: "absolute",
        right: screenType === "billingRule" ? "45px" :   "20px",
        top: "25%",
        border: `1 px solid ${
          statusMap[currentData?.status]?.color || statusMap.default.color
        }`,
        cursor: "pointer",
        color: statusMap[currentData?.status]?.color || statusMap.default.color,

        backgroundColor:
          statusMap[currentData?.status]?.backgroundColor ||
          statusMap.default.backgroundColor,
      }}
    >
      <span
        className="last-item "
        style={{
          fontWeight: "700",
          cursor: "pointer",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color:
            screenType === "staffScreen"
              ? statusMap[currentData?.status]?.color ||
                statusMap?.default?.color
              : "",
          backgroundColor:
            screenType === "staffScreen"
              ? statusMap[currentData?.status]?.backgroundColor ||
                statusMap?.default?.backgroundColor
              : "",
        }}
        onClick={() => setOpenStatusWidget(true)}
      >
        {statusMap[currentData?.status]?.label || statusMap.default.label}
        
{
  console.log('currentData', currentData)
}
        {OpenStatusWidget && (
          <div
            className="status-widget-div-main"
            ref={widgetRef}
            style={{
              position: "absolute",
              top: "2.5rem",
              right: "-1.25rem",
              zIndex: 1,
            }}
          >
            <WidgetItems
              setOpenStatusWidget={setOpenStatusWidget}
              type={
                screenType === "contacts"
                  ? "contactsScreen"
                  : screenType === "prospects"
                  ? "prospectsScreen"
                  : screenType === "staffScreen"
                  ? "staffScreen"
                  : screenType === "business"
                  ? "businessScreen"
                  : screenType === "teams"
                  ? "teamsScreen"
                  : screenType === "locations"
                  ? "businessScreen"
                  : screenType === "providers"
                  ? "providersScreen"
                  : "contactsScreen"
              }
              setAllClientsDataToTrackClickOfStatusClick={
                setAllClientsDataToTrackClickOfStatusClick
              }
              allClientsDataToTrackClickOfStatusClick={
                allClientsDataToTrackClickOfStatusClick
              }
              position={position}
              widgetRef={widgetRef}
              setOpenWidgetContacts={
                screenType === "contacts" ? setOpenWidgetContacts : null
              }
              itemsArr={itemsArr}
              setOpenWidgetProspects={
                screenType === "prospects" || screenType === "staffScreen"
                  ? setOpenWidgetProspects
                  : null
              }
              setOpenWidgetBusiness={
                screenType === "business" ? setOpenWidgetBusiness : null
              }
              setOpenWidgetTeams={
                screenType === "teams" ? setOpenWidgetTeams : null
              }
            />
          </div>
        )}
      </span>
    </div>
  );
};

export default Status;
