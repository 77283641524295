//@ts-ignore
import React from "react";

import "../../Styles/ClientScreen.css";
import "../../Styles/ClientAssessmentForm.css";
import { Fragment, useEffect, useRef, useState } from "react";
import { Modal, ModalOverlay, Spinner } from "@chakra-ui/react";
import { dummyArr } from "./Data/ClientScreenContentArr";
import {
  convertDateFormat,
  formatDate,
  formatDateForAPI,
  formatDateFromAPI,
  getCurrentDate,
} from "../../../../../../Model/utils/Helper/formatDate";
import {
  getAssociationRelationshipsThunk,
  getAssociationTypesThunk,
  getAssociations,
  resetSelectedDropdownItemAssociations,
  resetSelectedFilterItemsAssociations,
  resetSelectedItemIndexFilterAssociations,
  resetselectedItemIndexAssociations,
  resetselectedNames,
} from "../../../../../../Controller/redux/features/CommonFeature/associationSlice";
import {
  createDocumentThunk,
  deleteDocumentThunk,
  getDocuments,
  getDocumentsByFilter,
  getDocumentsForClinicalThunk,
  getUniquePrimaryApproversThunk,
  resetConfirmDeleteDocument,
  resetIsDocumentsDropdownOpen,
  resetSelectedDropdownItemDocuments,
  resetSelectedFilterItemsDocuments,
  resetSelectedInterval,
  resetSelectedItemIndexFilterDocuments,
  resetinputSearchVal,
  setIsFormOpenTrue,
  setWarningPopup,
  setselectedDataObject,
  setselectedDocument,
  updateDocumentThunk,
} from "../../../../../../Controller/redux/features/CommonFeature/documentSlice";
import {
  clearCurrentClientData,
  getUniqueClientById,
  getUniqueProviderClientById,
  getUserRolesClientsThunk,
  resetsearchValueClients,
  resetselectedDropdownItemClients,
  resetselectedFilterItemsClients,
  resetselectedItemIndexFilterClients,
  resetselectedItemIndexSortClients,
  resetsortbyClients,
  setClinicalAddNote,
  setStatusDetailObject,
  setopenClientAssessmentForm,
} from "../../../../../../Controller/redux/features/ClientFeature/clientsSlice";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../Controller/redux/Hooks";
import { useNavigate, useParams } from "react-router-dom";
import ClientAssessmentForm from "./Components/DocumentsTab/Components/ClientAssessmentForm";
import CommonHeader from "../../../../../components/CommonHeader/CommonHeader";
import ConfirmationModal from "../../../../../components/ConfirmationModal/ConfirmationModal";
import DropdownIcon from "../../../../../../Model/Assets/png/drop.png";
import UnsavedChangesPopup from "../../../../../components/UnsavedChangesPopup/UnsavedChangesPopup";
import CommonUpperBox from "../../../../../components/UpperBox/CommonHeader";
import { api } from "../../../../../../Controller/services/api";
import {
  getFileThunk,
  getStatusDetailsThunk,
  getUserTypesThunk,
} from "../../../../../../Controller/redux/features/CommonFeature/commonSlice";
import { itemsArr } from "./Data/WidgetDataArr";
import { toast } from "react-toastify";
import { updateClientThunk } from "../../../../../../Controller/redux/features/ClientFeature/detailsSlice";
import Daybook from "../../../../Daybook/Daybook";
import MenuAssignment from "../../../../Meals/MenuAssignment/MenuAssignment";
import FinanceModal from "./Components/FinanceTab/FinanceModal";
import ClinicalNotes from "./Components/ClinicalTab/Components/ClinicalList";
import ClientAssessmentNote from "./Components/DocumentsTab/Components/ClientAssessmentNote";
import ClientDetails from "./Components/DetailsTab/Components/ClientDetails";
import { Comms } from "../../../../Comms/Comms";
import "../../Styles/WidgetNew.css";
import { setOpenFinanceModelForm } from "../../../../../../Controller/redux/features/FinanceFeature/FinanceSlice";
import FinanceInvoiceModal from "./Components/FinanceTab/Components/FinanceModal";
import {
  createClinicalNoteThunk,
  getAllClinicalNotes,
  getClinicalGraphDataThunk,
  getGoalsThunk,
  resetClinicalRecords,
  setClinicalDetailsInfo,
  updateClinicalNoteThunk,
  updateGoalsThunk,
} from "../../../../../../Controller/redux/features/ClinicalFeature/clinicalSlice";
import CircularNoteTrigger from "../../../../../components/CircularNoteTrigger/CircularNoteTrigger";
import ProfilePictureModal from "../../../../../components/ProfilePictureModal/ProfilePictureModal";
import CommonQuickMenu from "../../../../../components/CommonQuickMenu/CommonQuickMenu";
import VerticalModal from "../../../../../components/CommonModal/VerticalModal";
import CommonReportingWidget from "../../../../../components/ReportingWidget/ReportingWidget";
import AuditModal from "../../../../Audit/AuditModal";
import UnderWork from "../../../../../components/UnderWork/UnderWork";
import Table from "../../../../../components/CommonTable/Table";
import { documentsColumns } from "../../../../../components/CommonTable/Data/AssociationHeaderDataArr";
import {
  financeColumns,
  financeData,
} from "../../../../../components/CommonTable/Data/TableData";
import {
  setshowUnsavedModal,
  setunsavedChanges,
} from "../../../../../../Controller/redux/features/AuthFeature/authSlice";
import {
  getLocationsTypesBuildingThunk,
  getLocationsTypesResidenceThunk,
  getLocationsTypesRoomThunk,
  getLocationsTypesSilThunk,
  getLocationsTypesTableThunk,
  getLocationsTypesWardThunk,
  setLocationTypeBuilding,
} from "../../../../../../Controller/redux/features/LocationFeature/locationSlice";
import { setWarningMessage } from "../../../../../../Controller/redux/features/ClientFeature/documentClientSlice";
import {
  convertDateTimeFormat,
  formatDateAndTime,
} from "../../../../../../Model/utils/Helper/formatTime";
import {
  getCustomFormsByClinicalStatusThunk,
  getCustomFormsTabsByIdThunk,
  getCustomFormsThunk,
  resetActiveFormTabsArr,
  setCurrentActiveFormInfo,
} from "../../../../../../Controller/redux/features/CustomFromFeature/customFormSlice";
import SuccessToastMessage from "../../../../../components/SuccessToast/SuccessToastMessage";

const ClientScreen = ({ screenType }: any) => {
  // @ts-ignore
  const userDetails = JSON.parse(localStorage.getItem("user"));
  const userId = userDetails?.data?.id;

  useEffect(() => {
    if (userId) {
      dispatch(getCustomFormsThunk(userId));
    }
  }, [userId]);

  const [isFormOpenFlagFromRedux, setisFormOpenFlagFromRedux] = useState(false);
  const { id, pro_id }: any = useParams();
  const [clientId, setclientId] = useState<any>("");
  const [providerId, setProviderId] = useState("");
  const [profilePicture, setProfilePicture] = useState<any>("");
  const [hasUnsavedChangesPopup, setHasUnsavedChangesPopup] =
    useState<boolean>(false);

  useEffect(() => {
    if (id) {
      let realId = window?.atob(id);
      setclientId(realId);
      if (screenType) {
        setProviderId(window?.atob(pro_id));
      }
    }
  }, [id, pro_id, screenType]);

  const flagForForm = useAppSelector((state) => state.document.isFormOpenTrue);
  const isMeals = useAppSelector((state) => state?.common?.isMeals);
  const dynamicColor = useAppSelector((state) => state?.common?.dynamicColor);
  const [loading, setLoading] = useState(true);
  const [documentDetails, setdocumentDetails] = useState<any>();
  const { uniqueClient, isClinicalAddNote } = useAppSelector(
    (state) => state.people
  );
  const [currentClient, setCurrentClient] = useState<any>(null);

  useEffect(() => {
    if (uniqueClient?.data?.data.length > 0) {
      setCurrentClient(uniqueClient?.data?.data[0]);
    } else {
      setCurrentClient(null);
    }
  }, [uniqueClient, clientId]);

  const [uploadedFiles, setUploadedFiles] = useState<
    { file: File; key: string; file_name?: string; actual_file_name?: string }[]
  >([]);
  const [modalFinance, setModalFinance] = useState<boolean>(false);

  useEffect(() => {
    dispatch(resetselectedDropdownItemClients());
    dispatch(resetselectedItemIndexFilterClients());
    dispatch(resetselectedItemIndexSortClients());
    dispatch(resetsortbyClients());
    dispatch(resetsearchValueClients(""));
    dispatch(resetselectedFilterItemsClients());
  }, [window.location.pathname, window.location.reload]);

  const isDeleteDocumentActive = useAppSelector(
    (state) => state?.document?.hasDeleteDocument
  );
  const isDeleteAssociationActive = useAppSelector(
    (state) => state?.associations?.hasDeleteAssociation
  );

  const hasWarningPopup = useAppSelector(
    (state) => state?.document?.warningPopup
  );

  useEffect(() => {
    if (clientId) {
      dispatch(clearCurrentClientData());
      if (screenType) {
        if (providerId && clientId && dynamicClientId) {
          dispatch(
            getUniqueProviderClientById({
              typeId: dynamicClientId,
              clientId,
              tenant_id: providerId,
            })
          )
            .then(() => {
              setLoading(false);
            })
            .catch((error: any) => {
              // console.log(error);
              setLoading(false);
            });
        }
      } else {
        dispatch(getUniqueClientById({ typeId: dynamicClientId, clientId }))
          .then(() => {
            setLoading(false);
          })
          .catch((error: any) => {
            // console.log(error);
            setLoading(false);
          });
      }
      if (buttonTab.Documents) {
        dispatch(getUniquePrimaryApproversThunk(clientId));
      }
      dispatch(getUserRolesClientsThunk({ dynamicClientId }));
    }
  }, [clientId, screenType]);

  useEffect(() => {
    setisFormOpenFlagFromRedux(flagForForm);
  }, [flagForForm]);

  const [selectedACATDate, setSelectedACATDate] = useState<Date | null | any>(
    ""
  );

  const [changesMadeToTheForm, setChangesMadeToTheForm] = useState(false);

  let data = localStorage.getItem("flagHere");
  // @ts-ignore
  let parsedData = JSON.parse(data);

  useEffect(() => {
    if (parsedData) {
      setshowGoalsModal(true);
    }
  }, [parsedData]);

  // //////////////////// Profile Picture///////////
  useEffect(() => {
    if (currentClient?.profile_image) {
      const path = currentClient?.profile_image;
      if (path) {
        dispatch(getFileThunk({ path })).then((res: any) =>
          setProfilePicture(res?.payload?.data)
        );
      } else {
        setProfilePicture(null);
      }
    }
  }, [currentClient?.profile_image, clientId]);

  const currentClientDataB2C = [
    {
      Name: "",
      UpperTitle: `Known As`,
      LowerTitle: ` ${
        currentClient?.pref_name || currentClient?.first_name || ""
      } `,
    },
    {
      UpperTitle: "Email",
      LowerTitle: currentClient?.email || "",
    },
    {
      UpperTitle: "Mobile",
      LowerTitle: currentClient?.mobile
        ? currentClient?.mobile
        : currentClient?.phone,
    },
    {
      UpperTitle: "DOB",
      LowerTitle: currentClient?.date_of_birth
        ? formatDate(formatDateFromAPI(currentClient.date_of_birth))
        : "",
    },
    {
      UpperTitle: "Client Type",
      LowerTitle: currentClient?.roles[0]?.desc
        ? currentClient?.roles[0]?.desc
        : "",
    },
    {
      UpperTitle: "Funding Type",
      LowerTitle: currentClient?.funding_type || "",
    },
  ];
  const currentClientDataB2B = [
    {
      Name: "",
      UpperTitle: `Known As`,
      LowerTitle: ` ${
        currentClient?.pref_name || currentClient?.first_name || ""
      } `,
    },
    {
      UpperTitle: "Email",
      LowerTitle: currentClient?.email || "",
    },
    {
      UpperTitle: "Mobile",
      LowerTitle: currentClient?.mobile
        ? currentClient?.mobile
        : currentClient?.phone,
    },

    {
      UpperTitle: "Client Type",
      LowerTitle: currentClient?.roles[0]?.desc
        ? currentClient?.roles[0]?.desc
        : "",
    },
  ];

  const [currentClientData, setcurrentClientData] = useState<any>([]);
  useEffect(() => {
    if (businessType === "B2B") {
      setcurrentClientData(currentClientDataB2B);
    } else {
      setcurrentClientData(currentClientDataB2C);
    }
  }, [businessType, currentClient]);

  const handleIntervalChange = (interval: string | any) => {
    setHasUnsavedChangesPopup(true);
    // console.log(interval,"intervalll")
    setHasUnsavedChanges(true);
    setselectedInterval(interval);
  };

  // INITIALIZE WITH EMPTY STRING TO MAKE DESIRED DATE FORMAT
  const [selectedDate, setSelectedDate] = useState<Date | null | any>("");
  const [selectedInterval, setselectedInterval] = useState("Booked");
  const [openACATDropdown, setopenACATDropdown] = useState(false);

  const capitalizedInterval =
    selectedInterval.charAt(0).toUpperCase() + selectedInterval.slice(1);
  const result = `${capitalizedInterval}, ${formatDate(selectedACATDate)}`;

  const [isThereAnyChanges, setIsThereAnyChanges] = useState(false);

  const handleDateChange = (date: Date | any) => {
    setSelectedDate(date);
    if (date) {
      setIsThereAnyChanges(true);
    }
  };

  ////////////////// OPEN VERTICAL MODAL FOR CLINICAL /////////////////////////////
  const [isClickingCross, setisClickingCross] = useState(false);
  const [openVerticaModal, setopenVerticaModal] = useState(false);
  const [selectedIndex, setselectedIndex] = useState(-1);
  const [ActiveModaleData, setActiveModaleData] = useState<any>(null);
  const handleOpenVerticalModal = (e: any, index: number) => {
    // // console.log(index,"eeeee")
    if (e && e.target && typeof e.target.className === "string") {
      const classNames = e.target.className;

      if (
        !classNames.includes(
          "react__datepicker___note--review react-datepicker-ignore-onclickoutside"
        ) &&
        !classNames.includes(
          "react__datepicker___note--due react-datepicker-ignore-onclickoutside"
        ) &&
        !classNames.includes("react-datepicker__day react-datepicker__day")
      ) {
        setopenVerticaModal(true);
        setselectedIndex(index);
      }
    }
  };

  // // console.log(openVerticaModal,"openVerticaModal")

  useEffect(() => {
    dispatch(setunsavedChanges(false));
    setHasUnsavedChanges(false);
  }, []);

  useEffect(() => {
    dispatch(getLocationsTypesResidenceThunk());
    dispatch(getLocationsTypesBuildingThunk());
    // dispatch(getLocationsTypesWardThunk());
    // dispatch(getLocationsTypesRoomThunk());
    dispatch(getLocationsTypesTableThunk());
    dispatch(getLocationsTypesSilThunk());
  }, [clientId]);

  /////////////////////////////////////////////////////////////////////////////////////

  // @ts-ignore
  let dynamicClientId = parseInt(localStorage.getItem("dynamicClientId"));
  const clinicalGoalsModal = useRef<any>(null);
  const [selectedEFA, setselectedEFA] = useState<any>("");
  const handleDateChangeForEFA = (date: Date | any) => {
    dispatch(setunsavedChanges(true));
    if (!hasUnsavedChanges) {
      setHasUnsavedChanges(true);
    }
    setselectedEFA(date);
  };

  const loadingDocuments = useAppSelector((state) => state?.document?.loading);
  const handleSaveChanges = async () => {
    setHasUnsavedChangesPopup(false);
    dispatch(setunsavedChanges(false));

    try {
      if (selectedInterval === "" && selectedACATDate !== "") {
        toast.error("ACAT Assessment Interval Can't Be Blank!");
      } else {
        // let { building_id, room_id, ward_id } = updateClient;

        // if ((building_id || ward_id) && !room_id) {
        //   toast.info("Please choose a room before saving the form.");
        //   return;
        // }
        let parms = updateClient;
        if (screenType) {
          parms.tenant_id = providerId;
        }
        const res = await dispatch(updateClientThunk({ ...updateClient }));

        if (res.payload.status === "success") {
          toast.success("Profile updated successfully!", {
            progressStyle: { backgroundColor: dynamicColor },
            icon: (
              <span
                style={{
                  marginRight: "8px",
                  borderRadius: "50%",
                  width: "7rem",
                  height: "35px",
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: dynamicColor,
                  backgroundColor: "white",
                  fontSize: "1.5rem",
                  border: `3px solid ${dynamicColor}`,
                }}
              >
                ✓
              </span>
            ),
          });
        } else {
          toast.error("An Error Occurred!");
        }
        // navigate(routeString);
      }
    } catch (error) {
      console.error(error);
      toast.error("An Error Occurred!");
    }
    if (screenType) {
      dispatch(
        getUniqueProviderClientById({
          typeId: dynamicClientId,
          clientId,
          tenant_id: providerId,
        })
      );
    } else {
      dispatch(getUniqueClientById({ dynamicClientId, clientId }));
    }
  };

  const handleACATDateChange = (date: Date | any) => {
    setHasUnsavedChangesPopup(true);
    // dispatch(setunsavedChanges(true));
    if (!hasUnsavedChanges) {
      // setHasUnsavedChanges(true);
    }
    setSelectedACATDate(date);
    // setHasUnsavedChanges(true);
  };
  const dataObject = currentClient;

  const [details, setDetails] = useState<any>({
    givenName: "",
    middleName: "",
    familyName: "",
    knownAs: "",
    address: "",
    email: "",
    phone: "",
    mobile: "",
    dateOfBirth: "",
    fundingType: "",
    fundingAvailable: "",
    packageLevel: "",
    packageNumber: "",
    acatAssessment: "",
    role: "",
    residence: "",
    building: "",
    ward: "",
    room: "",
    table: "",
  });

  // // console.log(details,'details')

  useEffect(() => {
    const fetchedData = currentClient;

    if (fetchedData) {
      // Set the current client's data
      // setcurrentClient(fetchedData);

      setDetails({
        givenName: fetchedData?.first_name,
        middleName: fetchedData?.middle_name,
        familyName: fetchedData?.last_name,
        knownAs: fetchedData?.pref_name,
        address: fetchedData?.user_addresses[0]?.address || "",
        email: fetchedData?.email,
        phone: fetchedData?.phone,
        mobile: fetchedData?.mobile,
        dateOfBirth:
          fetchedData?.date_of_birth !== null
            ? formatDate(fetchedData?.date_of_birth)
            : "",
        fundingType:
          fetchedData?.funding_type !== null ? fetchedData?.funding_type : "",
        packageLevel:
          fetchedData?.package_level !== null ? fetchedData?.package_level : "",
        packageNumber:
          fetchedData?.package_number !== null
            ? fetchedData?.package_number
            : "",
        acatAssessment: fetchedData?.acat_assessment,
        role: fetchedData ? fetchedData?.roles[0]?.id : "",
        fundingAvailable: formatDateForAPI(fetchedData?.funding_available),
        residence: fetchedData?.residence_id,
        building: fetchedData?.building_id,
        ward: fetchedData?.ward_id,
        room: fetchedData?.room_id,
        table: fetchedData?.table_id,
      });

      const currentClientDOB = fetchedData?.date_of_birth;
      if (currentClientDOB !== null) {
        const rearrangedDateString = formatDateFromAPI(currentClientDOB);
        setSelectedDate(new Date(rearrangedDateString));
      } else {
        setSelectedDate(null);
      }
    } else {
      // setcurrentClient(null);
      setDetails({
        givenName: "",
        middleName: "",
        familyName: "",
        knownAs: "",
        address: "",
        dateOfBirth: "",
        email: "",
        phone: "",
        fundingType: "",
        packageLevel: "",
        packageNumber: "",
        acatAssessment: "",
        role: "",
        fundingAvailable: "",
        residence: "",
        building: "",
        ward: "",
        room: "",
        table: "",
      });

      if (businessType === "B2B") {
        setDetails({
          businessName: fetchedData?.first_name,
          email: fetchedData?.email,
          address: fetchedData?.address,
          phone: fetchedData?.mobile,
          role: fetchedData?.address,
        });
      }
      setSelectedDate(null);
    }
    if (businessType == "B2B") {
      setDetails({
        businessName: fetchedData?.first_name,
        email: fetchedData?.email,
        address:
          (fetchedData?.user_addresses &&
            fetchedData?.user_addresses.length !== 0 &&
            fetchedData?.user_addresses[0]?.address) ||
          "",
        phone: fetchedData?.phone,
        role: fetchedData?.roles[0]?.id,
      });
    }
  }, [currentClient, clientId]);

  useEffect(() => {
    setHasUnsavedChanges(false);
  }, []);

  ///////////////////////////////////////////////////////////////////////
  const pickerRef = useRef<any>(null);
  const datepickerRef = useRef<any>(null);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const widgetRef = useRef<any>(null);

  const [updateClient, setupdateClient] = useState<any>({
    first_name: "",
    middle_name: "",
    address: "",
    last_name: "",
    pref_name: "",
    email: "",
    mobile: "",
    phone: "",
    dob: "",
    funding_type: "",
    package_level: "",
    package_number: "",

    funding_available: "",
    acat_assessment: "",
    initial_enquiry: "",
    role: "",
    user_id: clientId,
    type: -1,
    residence_id: "",
    building_id: "",
    ward_id: "",
    room_id: "",
    table_id: "",
  });

  // console.log(updateClient,"updateClient")

  const initialDate =
    currentClient?.date_of_birth !== null ? currentClient?.date_of_birth : "";

  useEffect(() => {
    if (initialDate) {
      const rearrangedDateString = formatDateFromAPI(initialDate);
      setSelectedDate(new Date(rearrangedDateString));
    }
  }, [initialDate]);
  const [packageLevel, setpackageLevel] = useState("");

  useEffect(() => {
    if (currentClient?.package_level) {
      setpackageLevel(currentClient?.package_level);
    } else if (currentClient?.package_level === null) {
      setpackageLevel("");
    }
  }, [currentClient]);

  // // console.log(details,"details")
  useEffect(() => {
    if (businessType == "B2B") {
      // @ts-ignore
      setupdateClient({
        first_name: details?.businessName, //this
        last_name: "",
        email: details?.email, //this
        phone: details?.phone,
        address: details?.address,
        role: details?.role, //this
        user_id: clientId, //this
        type: dynamicClientId, //this
      });
    } else {
      setupdateClient({
        first_name: details.givenName,
        middle_name: details.middleName,
        last_name: details.familyName,
        address: details.address,
        pref_name: details.knownAs,
        email: details.email,
        mobile: details.mobile,
        phone: details.phone,
        dob: selectedDate ? formatDateForAPI(selectedDate) : initialDate,
        funding_type: details.fundingType,
        package_level: packageLevel,
        package_number: details.packageNumber,
        funding_available: selectedEFA ? formatDateForAPI(selectedEFA) : "",
        acat_assessment: result ? result : details?.acatAssessment,
        initial_enquiry: "",
        role: details?.role,
        user_id: clientId,
        type: dynamicClientId,
        residence_id: details.residence,
        building_id: details.building,
        ward_id: details.ward,
        room_id: details.room,
        table_id: details.table,
      });
    }
  }, [
    clientId,
    details,
    selectedDate,
    selectedInterval,
    selectedACATDate,
    result,
    packageLevel,
    selectedEFA,
  ]);

  const handleOpenDropdown = () => {
    setopenACATDropdown(true);
  };

  const initialEFA = currentClient?.funding_available;
  useEffect(() => {
    if (initialEFA) {
      setselectedEFA(new Date(initialEFA));
    }
  }, [initialEFA]);

  const { routeString, showUnsavedModal } = useAppSelector(
    (state) => state.auth
  );

  const openClientAssessmentForm = useAppSelector(
    (state) => state.people.openClientAssessmentForm
  );

  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  useEffect(() => {
    setHasUnsavedChanges(false);
  }, []);

  const [showStatusWidget, setShowStatusWidget] = useState(false);
  const [openForm, setopenForm] = useState(false);
  const [typeCheckForAssessmentform, settypeCheckForAssessmentform] =
    useState("");
  const [selectedClientDetails, setSelectedClientDetails] = useState<
    Object | any
  >({});
  const clientDetails = useAppSelector((state) => state?.people?.uniqueClient);
  const [typeToOpenForm, setTypeToOpenForm] = useState("");

  useEffect(() => {
    if (showUnsavedModal) {
      setHasUnsavedChanges(false);
    }
  }, [showUnsavedModal]);
  const { domainDetail } = useAppSelector((state) => state?.common);

  var businessType = domainDetail?.businessmodel_type;

  useEffect(() => {
    if (selectedClientDetails && selectedClientDetails?.building_id) {
      const fetchLocationByBuidingId = async (id: string) => {
        const res = await api.get(
          `/users/get_locations_from_type?location_type=Ward&parent_id=${id}&parent_type=Building`
        );

        setlocationWardData(res?.data?.data);
      };

      fetchLocationByBuidingId(selectedClientDetails?.building_id);
    }

    if (selectedClientDetails && selectedClientDetails?.ward_id) {
      const fetchRoomByWardId = async (id: string) => {
        const res = await api.get(
          `/users/get_locations_from_type?location_type=Room&parent_id=${id}&parent_type=Ward`
        );
        setlocationRoomData(res?.data?.data);
      };

      fetchRoomByWardId(selectedClientDetails?.ward_id);
    }
  }, [selectedClientDetails]);

  // MAKE FORM CLOSE ON CLICK OUTSIE
  const [statusData, setstatusData] = useState<any>();
  useEffect(() => {
    const statusData = itemsArr.find(
      (item) => item.status == currentClient?.status
    );
    setstatusData(statusData);
    dispatch(setStatusDetailObject(statusData));
  }, [currentClient, clientId, dispatch]);

  const handleGetDetails = (elem: any) => {
    setdocumentDetails({
      ...elem,
      tenant_id: providerId,
      hard_copy_completed_time: time?.startTime,
    });
  };

  let isPending = false;

  const handleCloseModal = async () => {
    if (documentDetails.title || documentDetails.summary) {
      if (isPending) return;
      try {
        isPending = true;

        dispatch(createDocumentThunk(documentDetails))
          .then(() => {
            isPending = false;

            toast.success("Document Created Successfully!", {
              progressStyle: { backgroundColor: dynamicColor },
              icon: (
                <span
                  style={{
                    marginRight: "8px",
                    borderRadius: "50%",
                    width: "7rem",
                    height: "35px",
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: dynamicColor,
                    backgroundColor: "white",
                    fontSize: "1.5rem",
                    border: `3px solid ${dynamicColor}`,
                  }}
                >
                  ✓
                </span>
              ),
            });
            dispatch(getUniquePrimaryApproversThunk(clientId));
          })
          .catch(() => {
            isPending = false;

            toast.error("Failed To Create Document. Please Try Again!");
          });
        dispatch(getDocuments({ clientId, providerId }));
        setopenForm(false);
        dispatch(setopenClientAssessmentForm(false));
        dispatch(getUniquePrimaryApproversThunk(clientId));
      } catch {
        // setopenForm(false);

        dispatch(getDocuments({ clientId, providerId }));
        // dispatch(setopenClientAssessmentForm(false));
      } finally {
        // setopenForm(false);

        dispatch(getDocuments({ clientId, providerId }));
        // dispatch(setopenClientAssessmentForm(false));
      }
    } else {
      // setopenForm(false);

      dispatch(getDocuments({ clientId, providerId }));
      // dispatch(setopenClientAssessmentForm(false));
    }
  };
  function prefixDate(date) {
    return date < 10 ? "0" + date : date;
  }

  const handleOverlayClick = async (isFormSave: any) => {
    let docItem = { ...documentDetails };

    if (docItem?.primary_review == null || docItem?.primary_review == "") {
      let date = new Date();
      docItem.primary_review = `${date.getFullYear()}-${prefixDate(
        date.getMonth() + 1
      )}-${prefixDate(date.getDate())}`;
    }

    if (
      documentDetails?.communications[0]?.message == "" &&
      documentDetails?.tagged_person?.length >= 1
    ) {
      toast.info("Please add a comm to be able to save tagged people!");
      return;
    }
    if (!documentDetails.title) {
      toast.warn("Title is mandatory!");
      return;
    }
    if (currentGoal) {
      const tempGoalCopy = { ...currentGoal };
      tempGoalCopy.goal_id = tempGoalCopy.id;
      delete tempGoalCopy.id;

      tempGoalCopy.activities = tempGoalCopy.activities.map((elem: any) => {
        if (typeof elem.responsible_parties_id === "string") {
          return {
            ...elem,
            responsible_parties_id: elem.responsible_parties_id.split(","),
          };
        }
        return elem;
      });

      tempGoalCopy.activities = tempGoalCopy.activities.map((elem: any) => {
        if (typeof elem.associated_services_id === "string") {
          return {
            ...elem,
            associated_services_id: elem.associated_services_id.split(","),
          };
        }
        return elem;
      });

      dispatch(getGoalsThunk({ clientId }));
      setopenVerticaModal(false);
    }

    if (isCCPA && targetDoc) {
      setIsCCPA(false);

      setDataForModal({
        user_id: `${targetDoc?.user_entity_id}`,
        document_files:
          documentDetails?.document_files || targetDoc?.files || [],
        start: null,
        end: null,
        document_id: targetDoc?.id,
        title: targetDoc?.title,
        primary_review:
          documentDetails?.primary_review || targetDoc?.primary_review || "",
        ongoing_review:
          documentDetails?.ongoing_review || targetDoc?.ongoing_review || "",
        period_picker:
          documentDetails?.period_picker || targetDoc?.period_picker || "",
        primary_approver_id:
          documentDetails?.primary_approver_id?.length > 0
            ? documentDetails?.primary_approver_id?.map((res: any) => res)
            : targetDoc?.primary_approver_id
            ? targetDoc?.primary_approver_id?.split(",").map((res: any) => res)
            : [],
        ongoing_approver_id:
          documentDetails?.ongoing_approver_id?.length > 0
            ? documentDetails?.ongoing_approver_id?.map((res: any) => res)
            : targetDoc?.ongoing_approver_id
            ? targetDoc?.ongoing_approver_id?.split(",").map((res: any) => res)
            : [],
        hard_copy_completed_date:
          documentDetails?.hard_copy_completed_date ||
          targetDoc?.hard_copy_completed_date ||
          "",

        tag:
          documentDetails?.tag?.length > 0
            ? documentDetails?.tag
            : targetDoc?.tag?.length > 0
            ? targetDoc?.tag?.split(",")
            : [],
      });
      dispatch(
        updateDocumentThunk({
          user_id: `${targetDoc.user_entity_id}`,
          document_files:
            documentDetails.document_files || targetDoc.files || [],
          start: null,
          end: null,
          document_id: targetDoc?.id,
          title: targetDoc?.title,
          primary_review:
            documentDetails.primary_review || targetDoc?.primary_review || "",
          ongoing_review:
            documentDetails.ongoing_review || targetDoc?.ongoing_review || "",
          period_picker:
            documentDetails.period_picker || targetDoc.period_picker || "",
          primary_approver_id:
            documentDetails?.primary_approver_id.length > 0
              ? documentDetails?.primary_approver_id.map((res: any) => res)
              : targetDoc?.primary_approver_id
              ? targetDoc?.primary_approver_id.split(",").map((res: any) => res)
              : [],
          ongoing_approver_id:
            documentDetails?.ongoing_approver_id.length > 0
              ? documentDetails?.ongoing_approver_id.map((res: any) => res)
              : targetDoc?.ongoing_approver_id
              ? targetDoc?.ongoing_approver_id.split(",").map((res: any) => res)
              : [],
          hard_copy_completed_date:
            documentDetails.hard_copy_completed_date ||
            targetDoc.hard_copy_completed_date ||
            "",
          tag:
            documentDetails.tag?.length > 0
              ? documentDetails.tag
              : targetDoc.tag?.length > 0
              ? targetDoc.tag?.split(",")
              : [],
          note_details: documentDetails?.note_details || {},
        })
      );
    }

    let duplicateDetailsInfo = { ...documentDetails, user_id: "" };
    if (documentDetails?.title == "") {
      const hasValueInOtherFields = Object.keys(duplicateDetailsInfo);

      let ishereAnyFieldsHaveVaule = false;
      hasValueInOtherFields.map((res) => {
        // if (documentDetails[res]) {
        if (!ishereAnyFieldsHaveVaule) {
          if (typeof duplicateDetailsInfo[res] === "string") {
            if (!!duplicateDetailsInfo[res]) {
              ishereAnyFieldsHaveVaule = true;
              !isFormSave
                ? dispatch(setWarningMessage(true))
                : toast.warning("Title is mandatory");
              return;
            }
          } else if (typeof duplicateDetailsInfo[res] === "object") {
            if (!!duplicateDetailsInfo[res]?.length) {
              if (!!duplicateDetailsInfo[res]) {
                ishereAnyFieldsHaveVaule = true;
                // toast.warning("Title is mandatory");
                !isFormSave
                  ? dispatch(setWarningMessage(true))
                  : toast.warning("Title is mandatory");
                return;
              }
            }
          }
        }
      });

      if (ishereAnyFieldsHaveVaule) {
        return;
      }
    }

    if (documentDetails?.primary_approver_id.length == 0) {
      toast.warn("Primary approver is mandatory to create a document!");
      return;
    }

    if (documentDetails?.title) {
      if (isPending) return;
      try {
        isPending = true;

        let res = await dispatch(
          createDocumentThunk({
            ...documentDetails,

            primary_review: docItem?.primary_review,
          })
        );
        if (res?.payload?.status == "success") {
          toast.success("Document created successfully!", {
            progressStyle: { backgroundColor: dynamicColor },
            icon: <SuccessToastMessage dynamicColor={dynamicColor} />,
          });
        }

        dispatch(getDocuments({ clientId, providerId }));

        setopenForm(false);
        dispatch(setopenClientAssessmentForm(false));
      } catch {
        setopenForm(false);

        dispatch(getDocuments({ clientId, providerId }));
        dispatch(setopenClientAssessmentForm(false));
      } finally {
        setopenForm(false);
        setopenVerticaModal(false);

        dispatch(getDocuments({ clientId, providerId }));

        dispatch(setopenClientAssessmentForm(false));
      }

      setopenVerticaModal(false);
    } else {
      toast.warn("Title is mandatory.");
      setopenForm(false);
      dispatch(setopenClientAssessmentForm(false));

      dispatch(getDocuments({ clientId, providerId }));
      setopenVerticaModal(false);
    }
    setIsThereAnyChanges(false);
  };
  const currentGoal = useAppSelector((state) => state?.clinical?.currentGoal);

  // CREATE DOCUMENT ON CLICK OFF...
  const docs = useAppSelector((state) => state?.document?.getDocuments);
  let targetDoc = docs?.find(
    (el: any) => el?.title === "Clinical Care Plan And Assessment"
  );
  const [isCCPA, setIsCCPA] = useState(false);

  const [dataForModal, setDataForModal] = useState({});

  const [buttonTab, setbuttonTab] = useState({
    Details: true,
    Associations: false,
    Clinical: false,
    Comms: false,
    Documents: false,
    Finance: false,
    Daybook: false,
    Meals: false,
  });

  useEffect(() => {
    if (buttonTab.Associations) {
      dispatch(getAssociationTypesThunk());
      dispatch(getAssociationRelationshipsThunk());
      dispatch(getUserTypesThunk());
    }
  }, [clientId, currentClient, buttonTab]);

  // const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleMoveWithoutSaving = () => {
    dispatch(setshowUnsavedModal(false));
    dispatch(setunsavedChanges(false));
    navigate(routeString);
  };

  useEffect(() => {
    const handleClickOutsideOfAcat = (event: any) => {
      if (
        pickerRef.current &&
        !pickerRef.current.contains(event.target) &&
        !event.target.classList.contains("react-datepicker") &&
        !event.target.closest(".react-datepicker") &&
        !event.target.classList.contains("acat-items-client") &&
        !event.target.closest(".acat-items-client") &&
        !event.target.classList.contains("select-container-acat-clients") &&
        !event.target.closest(".select-container-acat-clients")
      ) {
        setopenACATDropdown(false);
      }
    };
    document.addEventListener("click", handleClickOutsideOfAcat);
    return () => {
      document.removeEventListener("click", handleClickOutsideOfAcat);
    };
  }, []);

  const handleMoveWithSave = async () => {
    dispatch(setshowUnsavedModal(false));
    dispatch(setunsavedChanges(false));
    if (selectedInterval === "" && selectedACATDate !== "") {
      toast.error("ACAT Assessment Interval Can't Be Blank!");
    } else {
      const res = await dispatch(updateClientThunk(updateClient));
      getUniqueClientById({ typeId: dynamicClientId, clientId });
      if (res.payload.status === "success") {
        toast.success("Profile updated successfully!", {
          progressStyle: { backgroundColor: dynamicColor },
          icon: (
            <span
              style={{
                marginRight: "8px",
                borderRadius: "50%",
                width: "7rem",
                height: "35px",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: dynamicColor,
                backgroundColor: "white",
                fontSize: "1.5rem",
                border: `3px solid ${dynamicColor}`,
              }}
            >
              ✓
            </span>
          ),
        });
      } else {
        toast.error("An Error Occurred!");
      }
      navigate(routeString);
    }
  };

  // DISPATCH ACTION TO CALL API IN PEOPLESLICE TO FETCH DETAILS OF INDIVIDUAL CLIENT & ASSOCIATION SLICE TO CALL ASSOCIATION
  useEffect(() => {
    dispatch(setunsavedChanges(false));
    dispatch(clearCurrentClientData());
    getUniqueClientById({ typeId: dynamicClientId, clientId });
  }, [clientId]);

  useEffect(() => {
    if (clientId) {
      dispatch(
        getAssociations({ clientId, searchVal: "", type: " ", pageNumber: 1 })
      );
    }
  }, [clientId]);

  // GET DETAILS OF INDIVIDUAL CLIENT BY ID
  useEffect(() => {
    setSelectedClientDetails(clientDetails?.data?.data[0]);
  }, [clientDetails]);

  const [locationWardData, setlocationWardData] = useState([]);
  const [locationRoomData, setlocationRoomData] = useState([]);

  // CHANGE TO INPUT FIELDS
  const handleChange = async (
    index: number,
    e: React.ChangeEvent<HTMLInputElement> | any
  ) => {
    const fieldName = Object.keys(details)[index];
    // console.log(e.target.value, index, fieldName);
    const { value } = e.target;
    setHasUnsavedChangesPopup(true);

    // dispatch(setunsavedChanges(true));
    if (!hasUnsavedChanges) {
      // setHasUnsavedChanges(true);
    }

    if (index === 16) {
      const res = await api.get(
        `/users/get_locations_from_type?location_type=Ward&parent_id=${value}&parent_type=Building`
      );
      setlocationWardData(res?.data?.data);
      setlocationRoomData([]);
      setDetails((prevState: any) => ({
        ...prevState,
        building: value,
      }));
      return;
    }

    if (index === 17) {
      const res = await api.get(
        `/users/get_locations_from_type?location_type=Room&parent_id=${value}&parent_type=Ward`
      );
      setlocationRoomData(res?.data?.data);
      setDetails((prevState: any) => ({
        ...prevState,
        ward: value,
      }));
      return;
    }

    if (index === 18) {
      setDetails((prevState: any) => ({
        ...prevState,
        room: value,
      }));
      return;
    }

    setDetails((prevState: any) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  // RETAIN THE STATE
  useEffect(() => {
    if (localStorage.getItem("activeTab") === "Documents") {
      handleClick("Documents");
    } else if (localStorage.getItem("activeTab") === "Associations") {
      handleClick("Associations");
    } else if (localStorage.getItem("activeTab") === "Meals") {
      handleClick("Meals");
    } else if (localStorage.getItem("activeTab") === "Comms") {
      handleClick("Comms");
    } else if (localStorage.getItem("activeTab") === "Clinical") {
      handleClick("Clinical");
    } else if (localStorage.getItem("activeTab") === "Finance") {
      handleClick("Finance");
    } else if (localStorage.getItem("activeTab") === "Daybook") {
      handleClick("Daybook");
    }
  }, [localStorage.getItem("activeTab")]);

  const hasClinicalFromTab = useAppSelector(
    (state) => state?.common?.hasClinicalFromURL
  );

  useEffect(() => {
    if (hasClinicalFromTab) {
      setbuttonTab({
        Details: false,
        Associations: false,
        Clinical: true,
        Comms: false,
        Documents: false,
        Finance: false,
        Daybook: false,
        Meals: false,
      });
    }
  }, []);

  useEffect(() => {
    if (isClinicalAddNote) {
      setbuttonTab({
        Details: false,
        Associations: false,
        Clinical: true,
        Comms: false,
        Documents: false,
        Finance: false,
        Daybook: false,
        Meals: false,
      });
    }
  }, [isClinicalAddNote]);

  const [isSelectTab, setIsSelectTab] = useState("");

  useEffect(() => {
    if (clientId && buttonTab.Clinical) {
      dispatch(getDocumentsForClinicalThunk({ clientId, providerId }));
    }
  }, [clientId, buttonTab]);

  const { getDocumentsWhenInClinicalTab } = useAppSelector(
    (state) => state.document
  );

  const handleClick = (button: string) => {
    setstageTwoToGoalsModal(false);
    if (hasClinicalFromTab) {
      setbuttonTab({
        Details: false,
        Associations: false,
        Clinical: true,
        Comms: false,
        Documents: false,
        Finance: false,
        Daybook: false,
        Meals: false,
      });
    }
    dispatch(getDocuments({ clientId, providerId }));

    dispatch(resetselectedItemIndexAssociations(""));
    dispatch(resetselectedNames(""));
    dispatch(resetIsDocumentsDropdownOpen());
    dispatch(resetinputSearchVal(""));
    dispatch(resetSelectedDropdownItemAssociations());
    dispatch(resetSelectedItemIndexFilterAssociations());
    dispatch(resetSelectedFilterItemsAssociations());
    dispatch(resetSelectedDropdownItemDocuments());
    dispatch(resetSelectedItemIndexFilterDocuments());
    dispatch(resetSelectedFilterItemsDocuments());

    const defaultTabState = {
      Details: false,
      Associations: false,
      Clinical: false,
      Comms: false,
      Documents: false,
      Finance: false,
      Daybook: false,
      Meals: false,
    };

    if (!isDeleteDocumentActive) {
      const newTabState = { ...defaultTabState, [button]: true };
      localStorage.setItem("activeTab", button);
      setbuttonTab(newTabState);
    } else {
      setIsSelectTab(button);
      setbuttonTab({ ...defaultTabState, Documents: true });
      localStorage.setItem("activeTab", "Documents");
      dispatch(setWarningPopup(true));
    }
  };

  // HANLDE FORM OPEN FROM UPPER BOX VIA REDUX
  useEffect(() => {
    if (openClientAssessmentForm) {
      setopenForm(true);
      setTypeToOpenForm("from-upper-box-add-button");
      setbuttonTab({
        Details: false,
        Associations: false,
        Clinical: false,
        Comms: false,
        Documents: true,
        Finance: false,
        Daybook: false,
        Meals: false,
      });
    }
  }, [openClientAssessmentForm]);

  /////////////////////////////////////////////////////// Comms Section start heereree ////////////////////////////////////////////////////////////

  const handleCloseMealFormModal = () => {
    setopenForm(false);
  };
  const handleOverlayClickOnMealModel = async () => {
    setopenForm(false);
  };

  /////////////////////////////////////////////////////// Finance Section Start Hereeeee //////////////////////////////////////////////////////////
  const [openFinanceModal, setOpenFinanceModal] = useState(false);

  const clientFinanceModel = useAppSelector(
    (state) => state?.finance?.openClientFinanceForm
  );

  useEffect(() => {
    if (clientFinanceModel) {
      setOpenFinanceModal(true);
    } else {
      setOpenFinanceModal(false);
    }
  }, [clientFinanceModel]);

  const handleCloseFinanceFormModal = () => {
    setOpenFinanceModal(false);
  };

  const handleOverlayClickOnFinanceModel = () => {
    dispatch(setOpenFinanceModelForm(false));
    setOpenFinanceModal(false);
  };

  const highlightOption = (event: any) => {
    event.target.style.backgroundColor = "#06a5ff";
    event.target.style.color = "white";
  };

  const unhighlightOption = (event: any) => {
    event.target.style.backgroundColor = "white";
    event.target.style.color = "black";
  };

  const documentsArr = useAppSelector((state) => state.document.getDocuments);

  const [documentArrRecords, setDocumentArrRecords] = useState([]);

  useEffect(() => {
    if (documentsArr && buttonTab.Documents) {
      setDocumentArrRecords(documentsArr);
    }
  }, [documentsArr, buttonTab.Documents]);

  // useEffect(() => {
  //   if (clientId ) {
  //     // dispatch(getDocuments({ clientId, providerId }));
  //   }
  // }, [clientId]);

  const [showProfile, setShowProfile] = useState<boolean>(false);
  const [showGoalsModal, setshowGoalsModal] = useState(false);
  const [stageTwoToGoalsModal, setstageTwoToGoalsModal] = useState(false);

  const [openModal, setopenModal] = useState(false);
  const [selectedWigdetItem, setselectedWidgetItem] = useState<any>(null);

  // OPENS LARGE CLINICAL MODAL
  const handleOpenClinicalModal = (index: number, selectedWidget: any) => {
    setopenModal(true);
    setselectedWidgetItem(selectedWidget);
  };

  const [selectedAddress, setSelectedAddress] = useState<any>("");
  const [possibleAddresses, setPossibleAddresses] = useState<any>([]);

  useEffect(() => {
    if (clientId || providerId) {
      // alert("lknl")
      dispatch(getDocuments({ clientId, providerId }));
    }
  }, [clientId, providerId]);

  function initService(query: any) {
    const displaySuggestions = function (predictions: any, status: any) {
      // @ts-ignore
      if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
        // console.log("Location service down!");
        return;
      }
      if (!predictions) {
        setPossibleAddresses([]);
        return;
      }
      setPossibleAddresses(predictions);
    };
    // @ts-ignore

    const service = new window.google.maps.places.AutocompleteService();
    service.getQueryPredictions({ input: query }, displaySuggestions);
  }

  const handleChangeForAddress = (index: number, e: any) => {
    setHasUnsavedChangesPopup(true);

    // dispatch(setunsavedChanges(true));
    if (!hasUnsavedChanges) {
      // setHasUnsavedChanges(true);
    }
    const { value } = e.target;
    setDetails({ ...details, address: value });
    initService(value);
    if (value.length === 0) {
      setDetails({ ...details, address: "" });
      setPossibleAddresses([]);
    }
  };

  const selectAddress = (e: any) => {
    if (!hasUnsavedChanges) {
      setHasUnsavedChanges(true);
    }
    setSelectedAddress(e.target.innerHTML);
    setDetails({ ...details, address: e.target.innerHTML });
    dispatch(setunsavedChanges(true));
    setPossibleAddresses([]);
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////// GRAPHS ///////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  /////////////////////////////////////// GRAPH DATA RETRIVAL FROM API //////////////////////////////
  const { graphData } = useAppSelector((state) => state?.clinical);

  const ClinicalTabArr = [
    {
      title: "Temperature",
      data_1: (
        graphData["Temperature"]?.map((elem: any) => elem.value1) || []
      ).reverse(),
      data_2: (
        graphData["Temperature"]?.map((elem: any) => elem.value2) || []
      ).reverse(),
      color: "#97D94A",
      dates_X: (
        graphData["Temperature"]?.map(
          (elem: any) => `${elem.time} - ${convertDateFormat(elem.date)}`
        ) || []
      ).reverse(),
      date: (graphData["Temperature"]?.map(
        (elem: any) => `${convertDateFormat(elem.date)}`
      ) || [])[0],
    },
    {
      title: "Weight",
      data_1: (
        graphData["Weight"]?.map((elem: any) => elem.value1) || []
      ).reverse(),
      color: "#F1B60D",
      dates_X: (
        graphData["Weight"]?.map(
          (elem: any) => `${elem.time} - ${convertDateFormat(elem.date)}`
        ) || []
      ).reverse(),
      date: (graphData["Weight"]?.map(
        (elem: any) => `${convertDateFormat(elem.date)}`
      ) || [])[0],
    },
    {
      title: "Heart Rate",
      data_1: (
        graphData["Heart Rate"]?.map((elem: any) => elem.value1) || []
      ).reverse(),
      color: "#F1B60D",
      dates_X: (
        graphData["Heart Rate"]?.map(
          (elem: any) => `${elem.time} - ${convertDateFormat(elem.date)}`
        ) || []
      ).reverse(),
      date: (graphData["Heart Rate"]?.map(
        (elem: any) => `${convertDateFormat(elem.date)}`
      ) || [])[0],
    },
    {
      title: "Respirations",
      data_1: (
        graphData["Respirations"]?.map((elem: any) => elem.value1) || []
      ).reverse(),
      color: "#E13D35",
      dates_X: (
        graphData["Respirations"]?.map(
          (elem: any) => `${elem.time} - ${convertDateFormat(elem.date)}`
        ) || []
      ).reverse(),
      date: (graphData["Respirations"]?.map(
        (elem: any) => `${convertDateFormat(elem.date)}`
      ) || [])[0],
    },
    {
      title: "BP Standing",
      data_1: (
        graphData["Blood Pressure Standing"]?.map((elem: any) => elem.value1) ||
        []
      ).reverse(),
      data_2: (
        graphData["Blood Pressure Standing"]?.map((elem: any) => elem.value2) ||
        []
      ).reverse(),
      color: "#97D94A",
      dates_X: (
        graphData["Blood Pressure Standing"]?.map(
          (elem: any) => `${elem.time} - ${convertDateFormat(elem.date)}`
        ) || []
      ).reverse(),
      date: (graphData["Blood Pressure Standing"]?.map(
        (elem: any) => `${convertDateFormat(elem.date)}`
      ) || [])[0],
    },
    {
      title: "BP Sitting",
      data_1: (
        graphData["Blood Pressure Sitting"]?.map((elem: any) => elem.value1) ||
        []
      ).reverse(),
      data_2: (
        graphData["Blood Pressure Sitting"]?.map((elem: any) => elem.value2) ||
        []
      ).reverse(),
      color: "#97D94A",
      dates_X: (
        graphData["Blood Pressure Sitting"]?.map(
          (elem: any) => `${elem.time} - ${convertDateFormat(elem.date)}`
        ) || []
      ).reverse(),
      date: (graphData["Blood Pressure Sitting"]?.map(
        (elem: any) => `${convertDateFormat(elem.date)}`
      ) || [])[0],
    },
  ];

  const ClientAssessmentData = [
    {
      type: "budget",
      fundingType: "NDIS - Plan Managed",
      startDate: "01-07-2023",
      endDate: "30-06-2024",
      lastUpdate: "04-07-2023",
      fundingTotal: "53500",
      expensesTotal: "54000",
      balanceTotal: "2757",
    },
    {
      type: "statement",
      fundingType: " NDIS - Plan Managed",
      startDate: "01-09-2023",
      endDate: "30-09-2023",
      lastUpdate: "07-10-2023",
      startingBalance: "78043",
      incomingFunds: "9576",
      expenses: "9576",
      closingBalance: "68434",
    },
  ];

  const renderClinicalWidgets = () => {
    return (
      <>
        <div className="upper_box_clinical_widget_container">
          {ClinicalTabArr.map((elem: any, index: number) => (
            <CommonReportingWidget
              isClinicalScreen={true}
              key={index}
              title={
                elem.title.length > 11
                  ? elem.title.substring(0, 14) + "..."
                  : elem.title
              }
              data_2={elem.data_2}
              data={elem.data_1}
              onClick={() => handleOpenClinicalModal(index, elem)}
              chartType={"line"}
              chartLabel={"Health"}
              pointRadius={3}
              chartSize={"small"}
              chartWidth="75%"
              pieChartBackgroundColors={[
                "#5737EE",
                "#9150DE",
                "#B7A9FF",
                "#7D6AD8",
              ]}
              borderWidth={1}
              lastUpdate={elem?.date}
            />
          ))}
        </div>
      </>
    );
  };

  const fetchAllGraphs = () => {
    for (const item of ClinicalTabArr) {
      dispatch(
        getClinicalGraphDataThunk({
          clientId: clientId,
          observation:
            item?.title === "BP Standing"
              ? "Blood Pressure Standing"
              : item?.title === "BP Sitting"
              ? "Blood Pressure Sitting"
              : item?.title,
          days: 30,
        })
      );
    }
  };

  useEffect(() => {
    if (clientId) {
      fetchAllGraphs();
      // dispatch(getDocuments({clientId, providerId}));
    }
  }, [clientId]);

  /// ASSOCIATION TABLE START //
  const dropdownRefAssociation = useRef<any>();
  const svgParentRefAssociation = useRef<any>();
  const [isDropdownOpenAssociation, setIsDropdownOpenAssociation] =
    useState(false);

  const {
    selectedFilterItemsAssociations,
    sortByAssociationsR,
    searchValAssociations,
  } = useAppSelector((state) => state?.associations);

  const { filterIconColor } = useAppSelector((state) => state?.common);
  const handleClickOutsideAssociation = (event: any) => {
    if (
      dropdownRefAssociation.current &&
      !dropdownRefAssociation.current.contains(event.target) &&
      svgParentRefAssociation.current &&
      !svgParentRefAssociation.current.contains(event.target)
    ) {
      setIsDropdownOpenAssociation(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutsideAssociation);
    return () => {
      document.removeEventListener("click", handleClickOutsideAssociation);
    };
  }, []);
  const associationColumns = [
    { header: "Name", accessor: "user_name" },
    { header: "Category", accessor: "category" },
    { header: "Relationship", accessor: "relationship_name" },
    { header: "Contact Number", accessor: "number" },
    { header: "Associated Business/Site", accessor: "funding_type" },
    { header: "Action", accessor: "actionAssociation" },
  ];
  const { getAssociationByUserId } = useAppSelector(
    (state) => state?.associations
  );

  /// ASSOCIATION TABLE END //
  // DOCUMENTS TABLE START //
  const dropdownRefDocuments = useRef<any>();
  const svgParentRefDocuments = useRef<any>();
  const [isDropdownOpenDocuments, setIsDropdownOpenDocuments] = useState(false);
  const { selectedFilterItemsDocuments, sortByDocumentsR, searchValDoc } =
    useAppSelector((state) => state?.document);
  const handleClickOutsideDocuments = (event: any) => {
    if (
      dropdownRefDocuments.current &&
      !dropdownRefDocuments.current.contains(event.target) &&
      svgParentRefDocuments.current &&
      !svgParentRefDocuments.current.contains(event.target)
    ) {
      setIsDropdownOpenDocuments(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutsideDocuments);
    return () => {
      document.removeEventListener("click", handleClickOutsideDocuments);
    };
  }, []);

  const getDocumentsData = useAppSelector(
    (state) => state?.document?.getDocuments
  );

  const updatedDoc = useAppSelector(
    (state) => state?.document?.updateDocumentData
  );

  const [primaryApprover, setPrimaryApprover] = useState([]);
  const [ongoingApprover, setOngoingApprover] = useState([]);

  // const selectedOps = useAppSelector(
  //   (state) => state?.document?.selectedOptions
  // );
  const [isFormOpenDoc, setIsFormOpenDoc] = useState(false);
  const [selectedObject, setSelectedObject] = useState<any>(null);
  const [showStateOfDocument, setShowStateOfDocument] = useState<any>("");

  const { error } = useAppSelector((state) => state?.document);

  // console.log('error', error);
  // console.log(selectedObject,'selectedObject')
  const { selectedActiveForm } = useAppSelector((state) => state.customForm);

  useEffect(() => {
    if (selectedActiveForm) {
      setSelectedObject(selectedActiveForm);
    }
  }, [selectedActiveForm]);

  const handleClickDocRow = (e: any, id: any, title: any) => {
    e.stopPropagation();
    dispatch(resetSelectedInterval());
    if (isDropdownOpenDocuments) {
      setIsDropdownOpenDocuments(false);
    } else {
      const selectedDocument = getDocumentsData?.find((elem: any) => {
        return elem?.id === id;
      });
      setIsFormOpenDoc(true);
      dispatch(setIsFormOpenTrue());
      setSelectedObject(selectedDocument);
      dispatch(setselectedDocument(selectedDocument));

      setPrimaryApprover(selectedDocument?.primary_approvers);
      setOngoingApprover(selectedDocument?.ongoing_approvers);
      dispatch(setselectedDataObject(selectedDocument));
      setUploadedFiles(selectedDocument?.files);
      setShowStateOfDocument(title);
    }
  };
  let refetchAttempt = localStorage.getItem("refetchAttempt");
  //  const[isThereAnyChange, setIsThereAnyChange] = useState(false);

  useEffect(() => {
    if (refetchAttempt == "true") {
      const selectedDocument = getDocumentsData?.find((elem: any) => {
        return elem?.id === id;
      });
      setIsFormOpenDoc(true);
      dispatch(setIsFormOpenTrue());
      setSelectedObject(selectedDocument);
      dispatch(setselectedDocument(selectedDocument));

      setPrimaryApprover(selectedDocument?.primary_approvers);
      setOngoingApprover(selectedDocument?.ongoing_approvers);
      setUploadedFiles(selectedDocument?.files);
    }
    localStorage.removeItem("refetchAttempt");
  }, [refetchAttempt]);

  const handleOverlayClickUpdate = async () => {
    if (!selectedObject?.isThereAnyChanges) {
      setIsFormOpenDoc(false);
      setopenVerticaModal(false);
      if (!buttonTab.Clinical) {
        return;
      }
    }

    if (primaryApprover.length == 0) {
      toast.warn("Can't update without adding a primary approver!");
      return;
    }

    try {
      let res = await dispatch(
        updateDocumentThunk({
          custom_form_id: updatedDoc?.custom_form_id,
          title: updatedDoc?.title,
          summary: updatedDoc.summary,
          primary_review: updatedDoc?.primary_review,
          ongoing_review: updatedDoc?.ongoing_review,
          period_picker: updatedDoc.period_picker,
          start: updatedDoc.start,
          end: updatedDoc.end,
          primary_approver_id: primaryApprover.map((res: any) => res?.id),
          ongoing_approver_id: ongoingApprover.map((res: any) => res?.id),
          hard_copy_completed_date: updatedDoc.hard_copy_completed_date,
          // hard_copy_completed_time: hard_copy_time.startTime,

          document_files: updatedDoc.document_files,
          user_id: updatedDoc.user_id,
          document_id: updatedDoc?.document_id,
          tag: updatedDoc?.tagArray,
          tenant_id: providerId,
          note_details: updatedDoc?.note_details || {},
        })
      );
      // console.log('res?.error?.payload', res?.error);
      if (res?.error?.message == "Rejected") {
        toast.error(res?.payload);
        setchangesToForm("");
        setIsFormOpenDoc(false);
        return;
      }

      toast.success("Document updated successfully!", {
        progressStyle: { backgroundColor: dynamicColor },
        icon: (
          <span
            style={{
              marginRight: "8px",
              borderRadius: "50%",
              width: "7rem",
              height: "35px",
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: dynamicColor,
              backgroundColor: "white",
              fontSize: "1.5rem",
              border: `3px solid ${dynamicColor}`,
            }}
          >
            ✓
          </span>
        ),
      });
      dispatch(getCustomFormsByClinicalStatusThunk(clientId));
      dispatch(getCustomFormsByClinicalStatusThunk(clientId));
      setopenVerticaModal(false);
      dispatch(getDocuments({ clientId, providerId }));
      dispatch(getDocumentsForClinicalThunk({ clientId, providerId }));

      // dispatch(
      //   getDocumentsByFilter({
      //     clientId,
      //     filterValue: selectedOps?.join(","),
      //     searchVal: "",
      //     sortType: "",
      //     filterColumn: "",
      //     sortColumn: "",
      //   })
      // );

      dispatch(getUniquePrimaryApproversThunk(clientId));
    } catch (err) {
      toast.error(error);
    }

    setchangesToForm("");
    setIsFormOpenDoc(false);
    dispatch(getUniquePrimaryApproversThunk(clientId));
    setIsThereAnyChanges(false);
  };

  const [changesToForm, setchangesToForm] = useState("");

  const handleChangesToForm = (val: any) => {
    setchangesToForm(val);
  };
  // DOCUMENTS TABLE END //

  const documentIdToBeDeleted = useAppSelector(
    (state) => state?.document?.idOfDocumentToDelete
  );

  const handleMoveWithSaveDeleteDocument = async () => {
    const response: any = await dispatch(
      deleteDocumentThunk({ documentId: documentIdToBeDeleted, clientId })
    );
    if (response?.payload?.status === "success") {
      dispatch(resetConfirmDeleteDocument());
      toast.success("Document Deleted Successfully!", {
        style: {},
        progressStyle: { backgroundColor: dynamicColor },
        icon: (
          <span
            style={{
              marginRight: "8px",
              borderRadius: "50%",
              width: "7rem",
              height: "35px",
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: dynamicColor,
              backgroundColor: "white",
              fontSize: "1.5rem",
              border: `3px solid ${dynamicColor}`,
            }}
          >
            ✓
          </span>
        ),
      });
      dispatch(getUniquePrimaryApproversThunk(clientId));
    } else {
      toast.error("Failed To Delete Document!");
    }
  };

  useEffect(() => {
    if (buttonTab.Documents) {
      dispatch(getUniquePrimaryApproversThunk(clientId));
    }
  }, [buttonTab]);

  const { time } = useAppSelector((state) => state.common);
  const clientStatusDetailsNums = useAppSelector(
    (state) => state.common.domainDetail.client_status
  );
  const clientStatusDetailsArr = useAppSelector(
    (state) => state.common.statusDetailsData?.data
  );

  useEffect(() => {
    dispatch(getStatusDetailsThunk(clientStatusDetailsNums));
  }, [clientStatusDetailsNums]);

  const openWarningSection = useAppSelector(
    (state) => state?.documentClient?.openWarningSection
  );

  const { clicalDetails, ClinicalPage, noteInfoProgress } = useAppSelector(
    (state) => state.clinical
  );

  useEffect(() => {}, [clientId]);

  const { selectedClinicalNoteInfo } = useAppSelector(
    (state) => state.clinical
  );

  const addOrUpdateNote = async () => {
    const { date, time } = formatDateAndTime(new Date());
    if (selectedClinicalNoteInfo?.id) {
      try {
        const response = await dispatch(
          updateClinicalNoteThunk({
            ...clicalDetails,
            user_id: clientId,
            activity_note_id: selectedClinicalNoteInfo?.id,
            note_date_time: `${date} ${time.split(" ")[0]}`,
            note: noteInfoProgress,
            note_tag: "Progress",
          })
        );
        if (response?.payload?.status === "success") {
          toast.success("Note updated successfully", {
            progressStyle: { backgroundColor: dynamicColor },
            icon: (
              <span
                style={{
                  marginRight: "8px",
                  borderRadius: "50%",
                  width: "7rem",
                  height: "35px",
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: dynamicColor,
                  backgroundColor: "white",
                  fontSize: "1.5rem",
                  border: `3px solid ${dynamicColor}`,
                }}
              >
                ✓
              </span>
            ),
          });
        }
        dispatch(resetClinicalRecords());
        dispatch(getAllClinicalNotes({ user_id: clientId }));
        dispatch(setClinicalAddNote(false));
        dispatch(setClinicalDetailsInfo({}));
      } catch (error) {}
    } else {
      try {
        const response = await dispatch(
          createClinicalNoteThunk({
            ...clicalDetails,
            user_id: clientId,
            note_date_time: `${date} ${time.split(" ")[0]}`,
            note: noteInfoProgress,
            note_tag: "Progress",
          })
        );
        if (response?.payload?.status === "success") {
          toast.success("Note Created Successfully", {
            // style: {},
            progressStyle: { backgroundColor: dynamicColor },
            icon: (
              <span
                style={{
                  marginRight: "8px",
                  borderRadius: "50%",
                  width: "7rem",
                  height: "35px",
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: dynamicColor,
                  backgroundColor: "white",
                  fontSize: "1.5rem",
                  border: `3px solid ${dynamicColor}`,
                }}
              >
                ✓
              </span>
            ),
          });
        }
        dispatch(resetClinicalRecords());
        dispatch(getAllClinicalNotes({ user_id: clientId }));
        dispatch(setClinicalAddNote(false));
      } catch (error: any) {}
    }

    setshowGoalsModal(false);
  };
  const handleOverlayClickOnClilcalNote = async () => {
    if (clicalDetails && clicalDetails?.note == "") {
      dispatch(setClinicalAddNote(false));
      dispatch(resetClinicalRecords());
      return;
    }

    const { date, time } = formatDateAndTime(new Date());

    if (selectedClinicalNoteInfo?.id) {
      // Update State Start Here
      try {
        const response = await dispatch(
          updateClinicalNoteThunk({
            ...clicalDetails,
            user_id: clientId,
            activity_note_id: selectedClinicalNoteInfo?.id,
            note_date_time: `${date} ${time.split(" ")[0]}`,
          })
        );
        if (response?.payload?.status === "success") {
          toast.success("Note updated successfully", {
            progressStyle: { backgroundColor: dynamicColor },
            icon: (
              <span
                style={{
                  marginRight: "8px",
                  borderRadius: "50%",
                  width: "7rem",
                  height: "35px",
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: dynamicColor,
                  backgroundColor: "white",
                  fontSize: "1.5rem",
                  border: `3px solid ${dynamicColor}`,
                }}
              >
                ✓
              </span>
            ),
          });
        }
        dispatch(resetClinicalRecords());
        dispatch(getAllClinicalNotes({ user_id: clientId }));
        dispatch(setClinicalAddNote(false));
        dispatch(setClinicalDetailsInfo({}));
      } catch (error) {}
    } else {
      try {
        const response = await dispatch(
          createClinicalNoteThunk({
            ...clicalDetails,
            user_id: clientId,
            note_date_time: `${date} ${time.split(" ")[0]}`,
          })
        );
        if (response?.payload?.status === "success") {
          toast.success("Note Created Successfully", {
            // style: {},
            progressStyle: { backgroundColor: dynamicColor },
            icon: (
              <span
                style={{
                  marginRight: "8px",
                  borderRadius: "50%",
                  width: "7rem",
                  height: "35px",
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: dynamicColor,
                  backgroundColor: "white",
                  fontSize: "1.5rem",
                  border: `3px solid ${dynamicColor}`,
                }}
              >
                ✓
              </span>
            ),
          });
        }
        dispatch(resetClinicalRecords());
        dispatch(getAllClinicalNotes({ user_id: clientId }));
        dispatch(setClinicalAddNote(false));
      } catch (error: any) {}
    }
  };

  useEffect(() => {
    if (showGoalsModal) {
      setstageTwoToGoalsModal(false);
    }
  }, [showGoalsModal]);

  useEffect(() => {
    if (buttonTab?.Clinical && clientId) {
      dispatch(getCustomFormsByClinicalStatusThunk(clientId));
    }
  }, [buttonTab, clientId]);

  const { clinicalCustomFormRecords, customFormList } = useAppSelector(
    (state) => state?.customForm
  );

  console.log("customFormList", customFormList);

  // const [CinicalStatusFormsArr, setCinicalStatusFormsArr] = useState<any[]>([]);

  // console.log(selectedObject, "selectedObject");

  // console.log(getDocumentsData,"getDocumentsData")

  // useEffect(() => {
  //   if (clientId && providerId) {
  //   dispatch(getDocuments({clientId, providerId}));

  //   }
  // }, [clientId, providerId]);

  let CinicalStatusFormsArr = getDocumentsWhenInClinicalTab?.filter(
    (el: any) => el.form_type == "Clinical"
  );

  ////////// CUSTOM FORM OF DOCUMENTS  /////////////
  let customFormsWithTypeDocument = customFormList?.data?.filter(
    (elem: any) => elem.form_type === "Document" && elem?.is_repeating == 1
  );
  /////////////////////////////////////////////////
  ////////// CUSTOM FORM OF CLINICAL  /////////////
  let customFormsWithTypeClinical = customFormList?.data?.filter(
    (elem: any) => elem.form_type === "Clinical" && elem?.is_repeating == 1
  );
  /////////////////////////////////////////////////

  console.log("customFormsWithTypeDocument", customFormsWithTypeDocument);

  return (
    <div>
      {loading && <Spinner position={"absolute"} top={"50%"} left={"50%"} />}
      <div
        style={{
          position: "relative",
        }}
      >
        <div
          style={{
            position: "relative",
            top: "-4px",
          }}
        >
          {" "}
          <CommonUpperBox
            imageSection={currentClient ? true : false}
            imageURL={currentClient?.profile_image}
            title={`${currentClient?.first_name || ""} ${
              currentClient?.last_name || ""
            }`}
            onClickUserImage={() => setShowProfile(true)}
            onClickPlus={() => {
              buttonTab.Finance
                ? dispatch(setOpenFinanceModelForm(true))
                : setstageTwoToGoalsModal(true);
            }}
            type={"screen"}
            currentUser={currentClient}
            child={buttonTab.Clinical && renderClinicalWidgets()}
          />
        </div>

        {stageTwoToGoalsModal && (
          <CommonQuickMenu
            screenType={screenType}
            setshowGoalsModal={(value: any) => {
              if (value) {
                setbuttonTab({
                  Details: false,
                  Associations: false,
                  Clinical: true,
                  Comms: false,
                  Documents: false,
                  Finance: false,
                  Daybook: false,
                  Meals: false,
                });
              }
              setshowGoalsModal(value);
              dispatch(setClinicalDetailsInfo({}));
              // setstageTwoToGoalsModal(false);
            }}
            showGoalsModal={showGoalsModal}
            setstageTwoToGoalsModal={setstageTwoToGoalsModal}
            type={buttonTab.Documents ? "clients" : "clients"}
            inDocument={
              buttonTab.Documents
                ? customFormsWithTypeDocument
                : buttonTab.Clinical
                ? customFormsWithTypeClinical
                : []
            }
          />
        )}
      </div>

      {showProfile && (
        <ProfilePictureModal
          showProfile={showProfile}
          className="profile_picture_external"
          setShowProfile={setShowProfile}
          currentClient={currentClient}
        />
      )}

      {showGoalsModal && (
        <Modal
          isOpen={showGoalsModal}
          onClose={() => {
            setshowGoalsModal(false);
            localStorage.removeItem("flagHere");
          }}
          closeOnOverlayClick={false}
          isCentered
        >
          <ModalOverlay
            zIndex={10}
            onClick={() => {
              // setshowGoalsModal(false);
              // localStorage.removeItem("flagHere");
              // dispatch(setClinicalDetailsInfo({}));
            }}
          />
          <div className="vertical_modal_clinical" ref={clinicalGoalsModal}>
            <VerticalModal
              onClickClinicalProgress={() => {
                // setshowGoalsModal(false);
                addOrUpdateNote();
              }}
              setshowGoalsModal={setshowGoalsModal}
              type={"clinicalModalFromAdd"}
            />
          </div>
        </Modal>
      )}

      {openModal && (
        <>
          <Modal
            isOpen={openModal}
            onClose={() => {
              setopenModal(false);
            }}
            closeOnOverlayClick={true}
            isCentered
          >
            <ModalOverlay
              onClick={() => {
                setopenModal(false);
              }}
              zIndex={10}
            />

            <AuditModal
              type="clinical"
              data={selectedWigdetItem.data_1}
              data_2={selectedWigdetItem.data_2}
              selectedWidgdet={selectedWigdetItem}
              setopenModal={setopenModal}
            />
          </Modal>
        </>
      )}

      {/* DETAILS TAB ACTIVE */}
      {buttonTab.Details && (
        <Fragment>
          <ClientDetails
            currentClient={currentClient}
            handleChangeForAddress={handleChangeForAddress}
            locationRoomData={locationRoomData}
            selectAddress={selectAddress}
            possibleAddresses={possibleAddresses}
            DropdownIcon={DropdownIcon}
            handleChange={handleChange}
            handleClick={handleClick}
            locationWardData={locationWardData}
            details={details}
            result={result}
            selectedDate={selectedDate}
            setShowStatusWidget={setShowStatusWidget}
            showStatusWidget={showStatusWidget}
            currentClientData={currentClientData}
            widgetRef={widgetRef}
            pickerRef={pickerRef}
            dummyArr={dummyArr}
            handleDateChange={handleDateChange}
            getCurrentDate={getCurrentDate}
            selectedACATDate={selectedACATDate}
            openACATDropdown={openACATDropdown}
            handleOpenDropdown={handleOpenDropdown}
            handleIntervalChange={handleIntervalChange}
            highlightOption={highlightOption}
            unhighlightOption={unhighlightOption}
            datepickerRef={datepickerRef}
            handleACATDateChange={handleACATDateChange}
            isMeals={isMeals}
            selectedInterval={selectedInterval}
            setpackageLevel={setpackageLevel}
            packageLevel={packageLevel}
            setunsavedChanges={(arg: any) => {
              dispatch(setunsavedChanges(arg));
            }}
            setHasUnsavedChanges={setHasUnsavedChanges}
            hasUnsavedChanges={hasUnsavedChanges}
            handleDateChangeForEFA={handleDateChangeForEFA}
            selectedEFA={selectedEFA}
            itemsArr={clientStatusDetailsArr}
            setHasUnsavedChangesPopup={setHasUnsavedChangesPopup}
            setIsThereAnyChange={setIsThereAnyChanges}
            currentClient={currentClient}
          />

          {!!hasUnsavedChangesPopup && (
            <>
              <div
                className="save"
                style={{
                  position: "absolute",
                  marginRight: "200px",
                  bottom: "10px",
                  zIndex: 9,
                }}
              >
                <p>Would you like to save your changes?</p>
                <div className="btnDivision">
                  <button
                    onClick={handleSaveChanges}
                    style={{
                      backgroundColor: dynamicColor,
                    }}
                  >
                    Save
                  </button>
                  <button
                    onClick={() => {
                      dispatch(
                        getUniqueClientById({
                          typeId: dynamicClientId,
                          clientId,
                        })
                      );

                      setHasUnsavedChangesPopup(false);
                      dispatch(clearCurrentClientData());
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </>
          )}

          {false && (
            <>
              <ConfirmationModal
                type="clientScreen"
                handleMoveWithoutSaving={handleMoveWithoutSaving}
                handleMoveWithSave={handleMoveWithSave}
              />
            </>
          )}
          <CircularNoteTrigger type="stickyHub" />
        </Fragment>
      )}

      {/* CLINICAL TAB ACTIVE */}
      {buttonTab.Clinical && (
        <Fragment>
          <CommonHeader
            mainType="clients"
            type="clinical"
            handleClick={handleClick}
            currentClient={currentClient}
            itemsArr={clientStatusDetailsArr}
          />

          {openVerticaModal && (
            <Modal
              isOpen={true}
              onClose={handleCloseModal}
              closeOnOverlayClick={true}
              isCentered
            >
              <ModalOverlay zIndex={10} />
              <ClientAssessmentForm
                type="new-form"
                handleCloseModal={handleCloseModal}
                setopenForm={setopenVerticaModal}
                handleOverlayClickUpdate={handleOverlayClickUpdate}
                handleChangesToForm={handleChangesToForm}
                dataObject={selectedObject}
                setDataObject={setSelectedObject}
                handleGetDetails={() => {}}
                showStateOfDocument={showStateOfDocument}
                uploadedFiles={uploadedFiles}
                setUploadedFiles={setUploadedFiles}
                primaryApprover={primaryApprover}
                setPrimaryApprover={setPrimaryApprover}
                ongoingApprover={ongoingApprover}
                setOngoingApprover={setOngoingApprover}
                handleSaveClick={handleOverlayClickUpdate}
                setIsFormOpenDoc={setIsFormOpenDoc}
                clinicalType={"clinicalType"}
                clinicalNoteTitle={ActiveModaleData?.title}
                openFormClinical={true}
                providerId={providerId}
              />
            </Modal>
          )}
          {isClinicalAddNote && (
            <Modal
              isOpen={true}
              onClose={() => dispatch(setClinicalAddNote(false))}
              closeOnOverlayClick={true}
              isCentered
            >
              <ModalOverlay zIndex={10} />
              <AuditModal
                type="clinicalNote"
                className="clinicalModal"
                onClickCross={handleOverlayClickOnClilcalNote}
              />
            </Modal>
          )}
          <div className="flex__box___clinical__tab">
            <ClinicalNotes
              handleOverlayClickOnClilcalNote={handleOverlayClickOnClilcalNote}
              setshowGoalsModal={setshowGoalsModal}
              showGoalsModal={showGoalsModal}
            />
            <div className="small__document__notes-clinical">
              {CinicalStatusFormsArr?.map((res: any, index: number) => {
                return (
                  <div key={index}>
                    {loading && (
                      <Spinner position={"absolute"} left={"50%"} top={"50%"} />
                    )}
                    <ClientAssessmentNote
                      onClick={(e: any) => {
                        dispatch(
                          getCustomFormsTabsByIdThunk({
                            custom_form_id: res?.custom_form_id,
                            user_id: clientId,
                          })
                        );
                        setActiveModaleData(res);

                        setIsCCPA(true);
                        handleOpenVerticalModal(e, index);
                        dispatch(resetActiveFormTabsArr());
                        dispatch(setCurrentActiveFormInfo(res));
                        setSelectedObject(res);
                        setPrimaryApprover(res?.primary_approvers);
                        setOngoingApprover(res?.ongoing_approvers);

                        dispatch(setselectedDataObject(res));
                        setUploadedFiles(res?.files);
                      }}
                      percentage={
                        domainDetail.required_percentage == "1"
                          ? res?.percentage_completed_required
                          : res?.percentage_completed
                      }
                      hardCopyCompletedDate={res?.hard_copy_completed_date}
                      clinicalNote={true}
                      res={res}
                      dueDate={res?.primary_review}
                      dataObject={res}
                      clinicalNoteTitle={`${res?.title}`}
                      ongoing_review={`
                        
                        ${formatDate(res?.ongoing_review)}`}
                      last_updated={
                        res?.updated_at
                          ? formatDateForAPI(new Date(res?.updated_at))
                          : ""
                      }
                      files={res?.files || []}
                      clientId={clientId}
                      providerId={providerId}
                    />
                  </div>
                );
              })}
            </div>
          </div>
          <CircularNoteTrigger title="Clinical" type="stickyClinical" />
        </Fragment>
      )}

      {/* DOCUMENTS TAB ACTIVE */}
      {buttonTab.Documents && (
        <>
          <Fragment>
            <div className={openForm ? "" : ""}>
              <CommonHeader
                mainType="clients"
                type="documents"
                handleClick={handleClick}
                currentClient={currentClient}
                itemsArr={clientStatusDetailsArr}
              />

              <Table
                dynamicHeaderColor={domainDetail?.doctbl_color}
                thData={documentsColumns}
                classNameTr="client-documents-tab-table-head"
                classNameTable="documents-table-additional-styles"
                type="documents"
                isLoading={loadingDocuments}
                dropdownRef={dropdownRefDocuments}
                isDropdownOpen={isDropdownOpenDocuments}
                onClickSvg={() => setIsDropdownOpenDocuments(true)}
                svgParentRef={svgParentRefDocuments}
                allClients={getDocumentsData}
                providerId={providerId}
                // onClickRow={handleClickDocRow.bind(this, "onRow")}
                onClickRow={handleClickDocRow}
                classNameRow="client-documents-tab-table-body"
                fill={
                  selectedFilterItemsDocuments.length > 0 ||
                  searchValDoc.length > 0 ||
                  sortByDocumentsR.length > 0
                    ? filterIconColor
                    : "#D8D3D3"
                }
              />
              {false && (
                <ConfirmationModal
                  type="documents"
                  handleMoveWithoutSaving={() => {
                    dispatch(setWarningPopup(false));
                    dispatch(resetConfirmDeleteDocument());
                    if (isSelectTab) {
                      const defaultTabState = {
                        Details: false,
                        Associations: false,
                        Clinical: false,
                        Comms: false,
                        Documents: false,
                        Finance: false,
                        Daybook: false,
                        Meals: false,
                      };
                      const newTabState = {
                        ...defaultTabState,
                        [isSelectTab]: true,
                      };
                      localStorage.setItem("activeTab", isSelectTab);
                      setbuttonTab(newTabState);
                      setIsSelectTab("");
                    } else {
                      navigate(routeString);
                    }
                  }}
                  handleMoveWithSave={() => {
                    handleMoveWithSaveDeleteDocument();
                    if (isSelectTab) {
                      const defaultTabState = {
                        Details: false,
                        Associations: false,
                        Clinical: false,
                        Comms: false,
                        Documents: false,
                        Finance: false,
                        Daybook: false,
                        Meals: false,
                      };
                      const newTabState = {
                        ...defaultTabState,
                        [isSelectTab]: true,
                      };
                      localStorage.setItem("activeTab", isSelectTab);
                      setbuttonTab(newTabState);
                      setIsSelectTab("");
                    } else {
                      navigate(routeString);
                    }
                    dispatch(setWarningPopup(false));
                  }}
                />
              )}
              {isDeleteDocumentActive && (
                <UnsavedChangesPopup type="documents" />
              )}
              <CircularNoteTrigger
                type="stickyHub"
                title="New Comms"
                //  extraType="globalCommsBox"
              />
              {/* <Rectangle type="stickyHub" /> */}

              {false && (
                <ConfirmationModal
                  type="documents"
                  handleMoveWithoutSaving={() => {
                    dispatch(setWarningMessage(false));
                  }}
                  handleMoveWithSave={() => {
                    setopenForm(false);
                    dispatch(setWarningMessage(false));
                    dispatch(setopenClientAssessmentForm(false));
                  }}
                />
              )}
            </div>
          </Fragment>
          {openForm ? (
            <div className="client-assessment-form-div">
              {openForm && (
                <Modal
                  isOpen={true}
                  onClose={() => {}}
                  closeOnOverlayClick={true}
                  isCentered
                >
                  <ModalOverlay onClick={() => {}} zIndex={10} />

                  <ClientAssessmentForm
                    type="new-form"
                    openForm={openForm}
                    currentData={currentClient}
                    profilePicture={profilePicture}
                    setIsThereAnyChanges={setIsThereAnyChanges}
                    isThereAnyChanges={isThereAnyChanges}
                    setopenForm={setopenForm}
                    title={typeCheckForAssessmentform}
                    handleGetDetails={handleGetDetails}
                    uploadedFiles={uploadedFiles}
                    setUploadedFiles={setUploadedFiles}
                    changesMadeToTheForm={changesMadeToTheForm}
                    setChangesMadeToTheForm={setChangesMadeToTheForm}
                    handleOverlayClick={handleOverlayClick}
                    handleSaveClick={handleOverlayClick}
                    isDocumentCreateState={true}
                  />
                </Modal>
              )}
            </div>
          ) : (
            openForm &&
            typeToOpenForm === "by-note-click" && (
              <div className="client-assessment-form-div">
                <ClientAssessmentForm
                  type="form-from-note-click"
                  openForm={openForm}
                  title={typeCheckForAssessmentform}
                  handleGetDetails={handleGetDetails}
                />
              </div>
            )
          )}

          {isFormOpenDoc && (
            <Modal
              isOpen={true}
              onClose={() => {}}
              closeOnOverlayClick={true}
              isCentered
            >
              <ModalOverlay onClick={() => {}} zIndex={10} />
              <ClientAssessmentForm
                type="new-form"
                handleOverlayClickUpdate={handleOverlayClickUpdate}
                handleChangesToForm={handleChangesToForm}
                dataObject={selectedObject}
                setDataObject={setSelectedObject}
                handleGetDetails={() => {}}
                showStateOfDocument={showStateOfDocument}
                uploadedFiles={uploadedFiles}
                setUploadedFiles={setUploadedFiles}
                primaryApprover={primaryApprover}
                setPrimaryApprover={setPrimaryApprover}
                ongoingApprover={ongoingApprover}
                setOngoingApprover={setOngoingApprover}
                handleSaveClick={handleOverlayClickUpdate}
                setIsFormOpenDoc={setIsFormOpenDoc}
                setIsThereAnyChanges={setIsThereAnyChanges}
                isThereAnyChanges={isThereAnyChanges}
              />
            </Modal>
          )}
        </>
      )}

      {/* COMMS TAB ACTIVE */}
      {buttonTab.Comms && (
        <Fragment>
          <Fragment>
            <CommonHeader
              mainType="clients"
              type="comms"
              handleClick={handleClick}
              currentClient={currentClient}
              itemsArr={clientStatusDetailsArr}
            />
            {/* <UnderWork /> */}
            <div style={{ marginTop: "10px" }}>
              <Comms isUserType={true} />
            </div>

            {/* <CircularNoteTrigger type="stickyHub" title="Comms" /> */}
          </Fragment>
          {/* <CircularNoteTrigger type="associations" title="Association" /> */}
        </Fragment>
      )}

      {/* ASSOCIATIONS TAB ACTIVE */}
      {buttonTab.Associations && (
        <>
          <CommonHeader
            mainType="clients"
            type="associations"
            handleClick={handleClick}
            currentClient={currentClient}
            itemsArr={clientStatusDetailsArr}
          />
          <Table
            dynamicHeaderColor={domainDetail?.assoctbl_color}
            type="asociations"
            classNameTable="common-table-container-associations"
            thData={associationColumns}
            classNameTr="client-associations-tab-table-head"
            dropdownRef={dropdownRefAssociation}
            onClickSvg={() => setIsDropdownOpenAssociation(true)}
            isDropdownOpen={isDropdownOpenAssociation}
            svgParentRef={svgParentRefAssociation}
            allClients={getAssociationByUserId}
            classNameRow="client-associations-tab-table-body"
            fill={
              selectedFilterItemsAssociations.length > 0 ||
              searchValAssociations.length > 0 ||
              sortByAssociationsR.length > 0
                ? filterIconColor
                : "#D8D3D3"
            }
          />
          <CircularNoteTrigger type="associations" title="Association" />
          {isDeleteAssociationActive && (
            <>
              <UnsavedChangesPopup type="associations" />
            </>
          )}
        </>
      )}

      {/* MEALS TAB ACTIVE */}
      {buttonTab.Meals && (
        <Fragment>
          <Fragment>
            <CommonHeader
              mainType="clients"
              type="meals"
              handleClick={handleClick}
              currentClient={currentClient}
              itemsArr={clientStatusDetailsArr}
            />
            <MenuAssignment />
            {/* <Rectangle type="stickyHub" /> */}
            <CircularNoteTrigger type="stickyHub" title="Comms" />
          </Fragment>
        </Fragment>
      )}

      {/* DAYBOOK */}
      {buttonTab.Daybook && (
        <Fragment>
          <Fragment>
            <CommonHeader
              mainType="clients"
              type="daybook"
              handleClick={handleClick}
              currentClient={currentClient}
              itemsArr={clientStatusDetailsArr}
            />
            <Daybook type="clients" />
          </Fragment>
        </Fragment>
      )}

      {/* FINANCE */}
      {buttonTab.Finance && (
        <Fragment>
          <Fragment>
            <CommonHeader
              mainType="clients"
              currentClient={currentClient}
              type="finance"
              handleClick={handleClick}
              itemsArr={clientStatusDetailsArr}
            />
            <div className="widget_finance_container">
              {/* DRY RUN @ADNAN */}
              <div className="finance_component_wrapper">
                {/* {FinanseBudgestStatementArr.map((res: any, index: number) => {
                  return <BudgetStatement type={res.type} recordInfo={res} />;
                })} */}

                {ClientAssessmentData?.map((elem: any, index: any) => (
                  <ClientAssessmentNote
                    key={index}
                    fundingType={elem?.fundingType}
                    type={elem?.type}
                    startDate={elem?.startDate}
                    endDate={elem?.endDate}
                    lastUpdate={elem?.lastUpdate}
                    fundingTotal={elem?.fundingTotal}
                    expensesTotal={elem?.expensesTotal}
                    balanceTotal={elem?.balanceTotal}
                    startingBalance={elem?.startingBalance}
                    incomingFunds={elem?.incomingFunds}
                    expenses={elem?.expenses}
                    closingBalance={elem?.closingBalance}
                    clientId={clientId}
                    elem={elem}
                    providerId={providerId}
                  />
                ))}
              </div>
              {openForm && (
                <Modal
                  isOpen={true}
                  onClose={handleCloseMealFormModal}
                  closeOnOverlayClick={true}
                  isCentered
                  size={"full"}
                >
                  <ModalOverlay
                    onClick={handleOverlayClickOnMealModel}
                    zIndex={10}
                  />
                </Modal>
              )}

              {openFinanceModal && (
                <Modal
                  isOpen={true}
                  onClose={handleCloseFinanceFormModal}
                  closeOnOverlayClick={true}
                  isCentered
                  size={"7xl"}
                >
                  <ModalOverlay
                    onClick={handleOverlayClickOnFinanceModel}
                    zIndex={10}
                  />
                  <div>
                    <FinanceInvoiceModal />
                  </div>
                </Modal>
              )}
            </div>
            {modalFinance && (
              <Modal
                isOpen={true}
                onClose={() => setModalFinance(false)}
                closeOnOverlayClick={true}
                isCentered
              >
                <ModalOverlay
                  onClick={() => setModalFinance(false)}
                  zIndex={10}
                />
                <FinanceModal />
              </Modal>
            )}

            {/* <CommonTable type="finance" /> */}
            <div className="finance_table_wrapper">
              <Table
                dynamicHeaderColor={domainDetail?.fintbl_color}
                thData={financeColumns}
                allClients={financeData}
                classNameTable="common-table-container finance_table"
                classNameTr="finance_table_head"
                classNameBody="finance_model_body"
                classNameRow="normal-table-row normal-class-table-row-clients-table"
                onClickRow={() => {}}
                fill={
                  selectedFilterItemsDocuments.length > 0 ||
                  searchValDoc.length > 0 ||
                  sortByDocumentsR.length > 0
                    ? filterIconColor
                    : "#D8D3D3"
                }
              />
            </div>

            {/* <Rectangle type="stickyHub" /> */}
            <CircularNoteTrigger type="stickyHub" extraType="globalCommsBox" />
          </Fragment>
        </Fragment>
      )}
    </div>
  );
};

export default ClientScreen;
