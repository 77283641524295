import React, { useEffect, useRef, useState } from "react";
import "./BudgetModal.css";
import PlusSVG from "./Data/PlusSVG";
import SearchSVG from "./Data/SearchSVG";
import BoldSVG from "./Data/BoldSVG";
import ItalicIconSVG from "./Data/ItalicIconSVG";
import UnderscorSVG from "./Data/UnderscorSVG";
import GallaryIconSVG from "./Data/GallaryIconSVG";
// import { PDFSVG } from "../PDFSVG";
import DOCSSVG from "../../Data/DOCSSVG";
// import SHEETSVG from "../SHEETSVG";
import CommonTable from "../../../../../../../components/CommonTable/InputTable";
import DeleteSVG from "./Data/DeleteSVG";
import SHEETSVG from "../../Data/SHEETSVG";
import { PDFSVG } from "../../Data/PDFSVG";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../Controller/redux/Hooks";
import ReactDatePicker from "react-datepicker";
import { formatDateFromAPIforCustom } from "../../../../../../../../Model/utils/Helper/formatDate";
import { onChangeFunding } from "../../../../../../../../Controller/redux/features/FinanceFeature/FinanceSlice";
import Table from "../../../../../../../components/CommonTable/Table";
import { fundingColumns } from "./Data/TableData";
import { CrossMdSvg } from "../../../../../../../../Model/Assets/SVG/Common/CrossSvg";

const BudgetModal = ({ setopenForm }: any) => {
  const [selectedBTN, setSelectedBTN] = useState("Funding");
  const btnGroupArr = ["Funding", "Services", "Fees", "Expenses", "Totals"];
  const dispatch = useAppDispatch();
  const budgetFinanceServicesRecord = useAppSelector(
    (state) => state?.finance?.budgetFinanceServicesRecords
  );

  const budgetFinanceFeesRecord = useAppSelector(
    (state) => state?.finance?.budgetFinanceFeesRecords
  );

  const budgetFinanceTotalsRecords = useAppSelector(
    (state) => state?.finance?.budgetFinanceTotalsRecords
  );

  const budgetFinanceExpensesRecords = useAppSelector(
    (state) => state?.finance?.budgetFinanceExpensesRecords
  );

  const budgetFinanceFundingRecords = useAppSelector(
    (state) => state?.finance?.budgetFinanceFundingRecords
  );

  const [budgetFinanceServices, setBudgetFinanceServices] = useState<any>([]);
  const [budgetFinanceFeess, setBudgetFinanceFees] = useState<any>([]);
  const [budgetFinanceToatls, setBudgetFinanceTatals] = useState<any>([]);
  const [budgetFinanceExpenses, setBudgetFinanceExpenses] = useState<any>([]);
  const [budgetFinanceFunding, setBudgetFinanceFunding] = useState<any>([]);

  useEffect(() => {
    if (budgetFinanceServicesRecord) {
      setBudgetFinanceServices(budgetFinanceServicesRecord);
    }
  }, [budgetFinanceServicesRecord]);

  useEffect(() => {
    if (budgetFinanceFeesRecord) {
      setBudgetFinanceFees(budgetFinanceFeesRecord);
    }
  }, [budgetFinanceFeesRecord]);

  useEffect(() => {
    if (budgetFinanceTotalsRecords) {
      setBudgetFinanceTatals(budgetFinanceTotalsRecords);
    }
  }, [budgetFinanceTotalsRecords]);

  useEffect(() => {
    if (budgetFinanceExpensesRecords) {
      setBudgetFinanceExpenses(budgetFinanceExpensesRecords);
    }
  }, [budgetFinanceExpensesRecords]);

  useEffect(() => {
    if (budgetFinanceFundingRecords) {
      setBudgetFinanceFunding(budgetFinanceFundingRecords);
    }
  }, [budgetFinanceFundingRecords]);

  const [dateWithPeriodPicker, setdateWithPeriodPicker] = useState<any>({
    startDate: null,
    endDate: null,
    sMonth: "",
    sYear: "",
    eMonth: "",
    eYear: "",
  });

  const datepickerRef = useRef<any>(null);
  const currentDate = new Date();

  const [isPickerOpen, setIsPickerOpen] = useState(false);

  const handlePickerClick = () => {
    setIsPickerOpen(true);
  };

  const handleDateChangePeriod = (dates: any) => {
    const [start, end] = dates;
    setdateWithPeriodPicker({
      startDate: start,
      endDate: end,
    });
    if (end) {
      setIsPickerOpen(false);
    }
  };

  useEffect(() => {
    updateDatePickerValue();
  }, [dateWithPeriodPicker]);

  const updateDatePickerValue = () => {
    if (dateWithPeriodPicker.startDate && dateWithPeriodPicker.endDate) {
      const sMonth = dateWithPeriodPicker.startDate.toString().split(" ")[1];
      const sYear = dateWithPeriodPicker.startDate.toString().split(" ")[3];
      const eMonth = dateWithPeriodPicker.endDate.toString().split(" ")[1];
      const eYear = dateWithPeriodPicker.endDate.toString().split(" ")[3];

      const formattedValue = `${sMonth} ${sYear} to ${eMonth} ${eYear}`;

      if (
        formattedValue !==
        `${dateWithPeriodPicker.sMonth} ${dateWithPeriodPicker.sYear} to ${dateWithPeriodPicker.eMonth} ${dateWithPeriodPicker.eYear}`
      ) {
        setdateWithPeriodPicker((prevState: any) => ({
          ...prevState,
          sMonth,
          sYear,
          eMonth,
          eYear,
        }));
      }
    }
  };

  const [unitName, setUnitName] = useState("");

  const fundingType = useAppSelector((state) => state?.finance?.fundingType);

  useEffect(() => {
    if (fundingType) {
      setUnitName(fundingType);
    }
  }, [fundingType]);

  return (
    <div className="budget_finance_form">
      <CrossMdSvg
        className="cross_button"
        onClick={() => {
          setopenForm(false);
        }}
      />
      <div className="title_wrapper">
        <h1>Budget</h1>
      </div>
      <div className="budget_content_row_1">
        {btnGroupArr.map((res: string, index: number) => {
          return (
            <div
              key={res}
              className={`sort_tab_btn  ${
                res == selectedBTN && "sort_tab_btn_selected"
              } `}
              style={{
                width: "fit-content",
                textAlign: "center",
              }}
              onClick={() => {
                setSelectedBTN(res);
              }}
            >
              {res}
            </div>
          );
        })}
        {/* <PlusSVG key={"budget_content_row_1_plus_icon"} marginLeft="10px" /> */}
      </div>
      {selectedBTN == "Funding" && (
        <div className="filed_wrapper">
          <div className="form_field_section_funding">
            <span>Funding Type:</span>
            <select
              placeholder="select Unit"
              value={unitName}
              onChange={(e) => {
                setUnitName(e?.target?.value);
                dispatch(onChangeFunding(e?.target?.value));
              }}
            >
              <option value="NDIS">NDIS</option>
              <option value="HCP">HCP</option>
              <option value="Private">Private</option>
            </select>
          </div>
          <div className="form_field_section_funding">
            <span>Package Level:</span>
            <select
              placeholder="select Unit"
              value={unitName}
              onChange={(e) => {
                setUnitName(e?.target?.value);
                dispatch(onChangeFunding(e?.target?.value));
              }}
            >
              <option value="NDIS - Plan Managed">NDIS - Plan Managed</option>
              <option value="NDIS - NDIA Managed">NDIS - NDIA Managed</option>
              <option value="DSOA">DSOA</option>
              <option value="HCP Level 1">HCP Level 1</option>
              <option value="HCP Level 2">HCP Level 2</option>
              <option value="HCP Level 3">HCP Level 3</option>
              <option value="HCP Level 4">HCP Level 4</option>
              <option value="HACC">HACC</option>
              <option value="CHSP">CHSP</option>
              <option value="DVA">DVA</option>
              <option value="DVA Nursing">DVA Nursing</option>
              <option value="TAC">TAC</option>
              <option value="Brokerage">Brokerage</option>
              <option value="Private Fee for Service">
                Private Fee for Service
              </option>
            </select>
          </div>
          <div className="form_field_section_funding">
            <span>Category</span>
            <select
              placeholder="select Unit"
              value={unitName}
              onChange={(e) => {
                setUnitName(e?.target?.value);
                dispatch(onChangeFunding(e?.target?.value));
              }}
            >
              <option value=""></option>
              <option value=""></option>
              <option value=""></option>
            </select>
          </div>
          <div className="form_field_section_funding">
            <span>Sub Category</span>
            <select
              placeholder="select Unit"
              value={unitName}
              onChange={(e) => {
                setUnitName(e?.target?.value);
                dispatch(onChangeFunding(e?.target?.value));
              }}
            >
              <option value=""></option>
              <option value=""></option>
              <option value=""></option>
            </select>
          </div>
          <div className="form_field_section_funding form_field_date_range">
            <span>Date Range:</span>
            {/* <input type="text" placeholder="Enter here" /> */}

            {/* {isPickerOpen && ( */}
            <div>
              <ReactDatePicker
                placeholderText="Date range"
                startDate={dateWithPeriodPicker.startDate}
                endDate={dateWithPeriodPicker.endDate}
                selectsRange
                onFocus={handlePickerClick}
                value={
                  dateWithPeriodPicker.startDate && dateWithPeriodPicker.endDate
                    ? `${dateWithPeriodPicker.sMonth} ${dateWithPeriodPicker.sYear} to ${dateWithPeriodPicker.eMonth} ${dateWithPeriodPicker.eYear}`
                    : "Review Period"
                }
                onChange={handleDateChangePeriod}
                showYearDropdown
                showMonthDropdown
                shouldCloseOnSelect={false}
                className={``}
                dropdownMode="select"
                open={isPickerOpen}
                ref={datepickerRef}
                minDate={currentDate}
                onBlur={() => {
                  setIsPickerOpen(false);
                }}
              />
            </div>
          </div>
          <div className="form_field_section_funding blue">
            <span>Opening Amount:</span>
            <input type="text" placeholder="000" />
          </div>

          <div className="form_field_section_funding blue">
            <span>Budget:</span>
            <input type="text" placeholder="000" />
          </div>
        </div>
      )}
      <div className="filter_content_row_2" style={{visibility: "hidden"}}>
        <div className="serach_sections">
          <SearchSVG key={"search_icon"} />
          <input className="finanse_" type="text" placeholder="Search" />
          <PlusSVG key={"serach_sections_plus_icon"} />
          <div className="menu_option_section">
            <p>file </p>
            <p>edit </p>
            <p>function </p>
          </div>
          <div className="btn_group_section_content">
            <BoldSVG key={"bold_svg"} />
            <ItalicIconSVG key={"italic_svg"} />
            <UnderscorSVG key={"underscor_svg"} />
            {/* <GallaryIconSVG key={"gallary_svg"} /> */}
          </div>
        </div>
        <div className="section_right_side_wrapper">
          <div className="all_btn_group_wrapper">
            <div className="btn_with_icon adjustment">
              <p>Adjustment</p>
              <PlusSVG marginLeft="10px" key={"plus_svg_on_btn_adjustment"} />
            </div>
            <div className="btn_with_icon accepted ">
              <p>Accepted</p>
              <PlusSVG marginLeft="10px" key={"plus_svg_accepted"} />
            </div>
            <div className="btn_with_icon task">
              <p>Task</p>
              <PlusSVG marginLeft="10px" key={"plus_svg_task"} />
            </div>
            <div className="btn_with_icon issue">
              <p>Issue</p>
              <PlusSVG marginLeft="10px" key={"plus_svg_issue"} />
            </div>
          </div>
          <div className="attachment_and_remove_btn_section">
            <p>export</p>
            <PDFSVG key={"pdf_svg"} />
            <DOCSSVG key={"docs_svg"} />
            <SHEETSVG key={"sheet_svg"} />
            <DeleteSVG style={{ marginLeft: "15px" }} key={"delete_svg"} />
          </div>
        </div>
      </div>

      <CommonTable
        type={`budgetModel${selectedBTN}`}
        allClients={
          selectedBTN == "Services"
            ? budgetFinanceServices[unitName]
            : selectedBTN == "Fees"
            ? budgetFinanceFeess[unitName]
            : selectedBTN == "Totals"
            ? budgetFinanceToatls[unitName]
            : selectedBTN == "Expenses"
            ? budgetFinanceExpenses[unitName]
            : selectedBTN == "Funding"
            ? budgetFinanceFunding[unitName]
            : []
        }
      />
      {!(selectedBTN === "Totals") && <button className="save_btn">Save</button>}
    </div>
  );
};

export default BudgetModal;
