import React, { useContext, useEffect, useRef, useState } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../Controller/redux/Hooks";
import { Modal, ModalOverlay } from "@chakra-ui/modal";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import DropdownIcon from "../../../../../Model/Assets/png/drop.png";
import {
  dummyArrBusiness,
  dummyArrLocations,
} from "../../ClientPage/Components/UniqueClientScreen/Data/ClientScreenContentArr";

import Daybook from "../../../Daybook/Daybook";
import UpperBox from "../../../../components/UpperBox/UpperBox";
import CommonUpperBox from "../../../../components/UpperBox/CommonHeader";
import { Comms } from "../../../Comms/Comms";
import CommonHeader from "../../../../components/CommonHeader/CommonHeader";
import CommonTable from "../../../../components/CommonTable/InputTable";
import ClientAssessmentForm from "../../ClientPage/Components/UniqueClientScreen/Components/DocumentsTab/Components/ClientAssessmentForm";
import ConfirmationModal from "../../../../components/ConfirmationModal/ConfirmationModal";
import UnsavedChangesPopup from "../../../../components/UnsavedChangesPopup/UnsavedChangesPopup";

import {
  getStatusDetailsThunk,
  getUserTypesThunk,
} from "../../../../../Controller/redux/features/CommonFeature/commonSlice";
import {
  getAssociationRelationshipsThunk,
  getAssociationTypesThunk,
  getAssociations,
  getAssociationsForEntity,
  resetSelectedDropdownItemAssociations,
  resetSelectedFilterItemsAssociations,
  resetSelectedItemIndexFilterAssociations,
  resetselectedItemIndexAssociations,
  resetselectedNames,
} from "../../../../../Controller/redux/features/CommonFeature/associationSlice";
import {
  clearCurrentBusinessData,
  getBusinessByIdThunk,
  getEntityRoleThunk,
  updateBusinessThunk,
} from "../../../../../Controller/redux/features/BusinessFeature/businessSlice";
import {
  resetOpenFormByClickBusiness,
  setOpenFormByClickBusiness,
} from "../../../../../Controller/redux/features/BusinessFeature/documentBusinessSlice";
import {
  createDocumentThunk,
  getDocuments,
  getDocumentsByFilter,
  getDocumentsByFilterForEntity,
  getDocumentsEntity,
  getUniquePrimaryApproversForEntityThunk,
  getUniquePrimaryApproversThunk,
  resetIsDocumentsDropdownOpen,
  resetSelectedDropdownItemDocuments,
  resetSelectedFilterItemsDocuments,
  resetSelectedItemIndexFilterDocuments,
  resetinputSearchVal,
  setIsFormOpenTrue,
  setWarningPopup,
  setselectedDataObject,
  updateDocumentThunk,
} from "../../../../../Controller/redux/features/CommonFeature/documentSlice";
import CircularNoteTrigger from "../../../../components/CircularNoteTrigger/CircularNoteTrigger";
import ProfilePictureModal from "../../../../components/ProfilePictureModal/ProfilePictureModal";
import CommonQuickMenu from "../../../../components/CommonQuickMenu/CommonQuickMenu";
import { Spinner } from "@chakra-ui/react";
import UnderWork from "../../../../components/UnderWork/UnderWork";
import Table from "../../../../components/CommonTable/Table";
import { documentsColumns } from "../../../../components/CommonTable/Data/AssociationHeaderDataArr";
import {
  clearCurrentLocationData,
  getLocationsUserByIdThunk,
  getProviderLocationByIdThunk,
  getUserRolesLocationThunk,
  resetOpenFormByClickLocation,
  updateLocationUserThunk,
} from "../../../../../Controller/redux/features/LocationFeature/locationSlice";
import {
  setshowUnsavedModal,
  setunsavedChanges,
} from "../../../../../Controller/redux/features/AuthFeature/authSlice";
import { LocationTypeData } from "./Data/LocationTypeData";
import { setWarningMessage } from "../../../../../Controller/redux/features/ClientFeature/documentClientSlice";

const LocationsScreen = ({ screenType }: any) => {
  const dispatch = useAppDispatch();
  const [locationId, setLocationId] = useState("");

  // @ts-ignore
  const dynamicLocationId = parseInt(localStorage.getItem("dynamicLocationId"));

  const { locationRoles } = useAppSelector((state) => state?.location);
  const { getUniqueLocationUser } = useAppSelector((state) => state?.location);
  const [currentLocation, setCurrentLocation] = useState<any>(null);
  useEffect(() => {
    if (getUniqueLocationUser?.data?.data.length > 0) {
      setCurrentLocation(getUniqueLocationUser?.data?.data[0]);
    } else {
      setCurrentLocation(null);
    }
  }, [getUniqueLocationUser, locationId]);

  const currentLocationRoles = locationRoles?.data;
  const { id, pro_id }: any = useParams();
  const navigate = useNavigate();
  const [providerId, setProviderId] = useState("");
  useEffect(() => {
    if (id) {
      const realId = window.atob(id);
      setLocationId(realId);
    }
    if (screenType) {
      setProviderId(window?.atob(pro_id));
    }
  }, [id, pro_id, screenType]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(getUserRolesLocationThunk(dynamicLocationId));
  }, [dynamicLocationId]);

  useEffect(() => {
    if (locationId) {
      dispatch(clearCurrentLocationData());
      if (screenType) {
        if (providerId && clientId && dynamicLocationId) {
          dispatch(
            getProviderLocationByIdThunk({
              typeId: dynamicLocationId,
              clientId,
              tenant_id: providerId,
            })
          )
            .then(() => {
              setLoading(false);
            })
            .catch((error: any) => {
              // console.log(error);
              setLoading(false);
            });
        }
      } else {
        dispatch(
          getLocationsUserByIdThunk({
            dynamicId: dynamicLocationId,
            locationId,
          })
        )
          .then(() => {
            setLoading(false);
          })
          .catch((error: any) => {
            // console.log(error);
            setLoading(false);
          });
      }
      // dispatch(getUniquePrimaryApproversThunk(locationId));
    }
  }, [locationId, providerId, dynamicLocationId]);

  const {
    routeString,

    showUnsavedModal,
  } = useAppSelector((state) => state.auth);

  //Commenting below piece of code to avoid the flash of details on provider location screen

  // useEffect(() => {
  //   if (clientId) {
  //     dispatch(clearCurrentLocationData());
  //     dispatch(
  //       getLocationsUserByIdThunk({
  //         dynamicId: dynamicLocationId,
  //         locationId,
  //       })
  //     )
  //       .then(() => {
  //         setLoading(false);
  //       })
  //       .catch((error: any) => {
  //         // console.log(error);
  //         setLoading(false);
  //       });
  //   }
  // }, [locationId]);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const dynamicColor = useAppSelector((state) => state?.common?.dynamicColor);
  const { hasDeleteDocument, warningPopup } = useAppSelector(
    (state) => state?.document
  );
  const { hasDeleteAssociation, warningPopupAssociation } = useAppSelector(
    (state) => state?.associations
  );

  const { openFormLocation } = useAppSelector((state) => state?.location);
  const handleMoveWithoutSaving = () => {
    dispatch(setshowUnsavedModal(false));
    dispatch(setunsavedChanges(false));
    navigate(routeString);
  };
  useEffect(() => {
    if (showUnsavedModal) {
      setHasUnsavedChanges(false);
    }
  }, [showUnsavedModal]);

  const [uploadedFiles, setUploadedFiles] = useState<
    {
      file: File;
      key: string;
      file_name?: string;
      actual_file_name?: string;
    }[]
  >([]);
  const [documentDetails, setDocumentDetails] = useState<any>({
    title: "",
    summary: "",
    primary_review: "",
    ongoing_review: "",
    period_picker: "",
    start: "",
    end: "",
    primary_approver_id: [],
    ongoing_approver_id: [],
    hard_copy_completed_date: "",
    communications: [],
    user_id: locationId,
    document_files: [],
    tag: "",
  });
  const [UpdateDetails, setUpdateDetails] = useState<any>({
    title: "",
    summary: "",
    primary_review: "",
    ongoing_review: "",
    period_picker: "",
    start: "",
    end: "",
    primary_approver_id: [],
    ongoing_approver_id: [],
    hard_copy_completed_date: "",
    communications: [],
    user_id: locationId,
    document_id: "",
  });

  const handleGetDetails = (elem: any) => {
    setDocumentDetails({
      title: elem.title,
      summary: elem.summary,
      primary_review: elem.primary_review,
      ongoing_review: elem.ongoing_review,
      period_picker: elem.period_picker,
      start: elem.start,
      end: elem.end,
      primary_approver_id: elem.primary_approver_id,
      ongoing_approver_id: elem.ongoing_approver_id,
      hard_copy_completed_date: elem.hard_copy_completed_date,
      communications: elem.communications,
      user_id: locationId,
      document_files: elem.document_files,
      tag: elem.tag,
      tenant_id: providerId,
    });

    setUpdateDetails({
      title: elem.title,
      summary: elem.summary,
      primary_review: elem.primary_review,
      ongoing_review: elem.ongoing_review,
      period_picker: elem.period_picker,
      start: elem.start,
      end: elem.end,
      primary_approver_id: elem.primary_approver_id,
      ongoing_approver_id: elem.ongoing_approver_id,
      hard_copy_completed_date: elem.hard_copy_completed_date,
      communications: elem.communications,
      user_id: locationId,
      document_id: elem.id,
      document_files: elem.document_files,
      tag: elem.tag,
      tenant_id: providerId,
    });
  };

  let isPending = false;

  const handleOverlayClick = async (isFormSave: any) => {
    let duplicateDetailsInfo = { ...documentDetails, user_id: "" };
    if (documentDetails?.title == "") {
      const hasValueInOtherFields = Object.keys(duplicateDetailsInfo);
      let nonEmptyFieldCount = 0;

      let ishereAnyFieldsHaveVaule = false;
      hasValueInOtherFields.map((res) => {
        // if (documentDetails[res]) {
        if (!ishereAnyFieldsHaveVaule) {
          if (typeof duplicateDetailsInfo[res] === "string") {
            if (!!duplicateDetailsInfo[res]) {
              ishereAnyFieldsHaveVaule = true;
              !isFormSave
                ? dispatch(setWarningMessage(true))
                : toast.warning("Title is mandatory");
              return;
            }
          } else if (typeof duplicateDetailsInfo[res] === "object") {
            if (!!duplicateDetailsInfo[res]?.length) {
              if (!!duplicateDetailsInfo[res]) {
                ishereAnyFieldsHaveVaule = true;
                // toast.warning("Title is mandatory");
                !isFormSave
                  ? dispatch(setWarningMessage(true))
                  : toast.warning("Title is mandatory");
                return;
              }
            }
          }
        }
      });

      if (ishereAnyFieldsHaveVaule) {
        return;
      }
    }

    if (documentDetails?.title || documentDetails?.summary) {
      if (isPending) return;
      try {
        isPending = true;

        dispatch(createDocumentThunk(documentDetails))
          .then(() => {
            isPending = false;

            toast.success("Document Created Successfully!", {
              style: {},
              progressStyle: { backgroundColor: dynamicColor },
              icon: (
                <span
                  style={{
                    marginRight: "8px",
                    borderRadius: "50%",
                    width: "7rem",
                    height: "35px",
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: dynamicColor,
                    backgroundColor: "white",
                    fontSize: "1.5rem",
                    border: `3px solid ${dynamicColor}`,
                  }}
                >
                  ✓
                </span>
              ),
            });
            dispatch(getUniquePrimaryApproversThunk(clientId));
          })
          .catch(() => {
            isPending = false;

            toast.error("Failed To Create Document. Please Try Again!");
          });
        dispatch(getDocuments({ clientId: locationId, providerId }));
        // dispatch(resetIsAssessmentFormOpenContacts());
        dispatch(resetOpenFormByClickLocation());
      } catch {
        dispatch(getDocuments({ clientId: locationId, providerId }));
        // dispatch(resetIsAssessmentFormOpenContacts());
        dispatch(resetOpenFormByClickLocation());
      } finally {
        dispatch(getDocuments({ clientId: locationId, providerId }));
        // dispatch(resetIsAssessmentFormOpenContacts());
        dispatch(resetOpenFormByClickLocation());
      }
    } else {
      // dispatch(resetIsAssessmentFormOpenContacts());
      dispatch(resetOpenFormByClickLocation());
      dispatch(getDocuments({ clientId: locationId, providerId }));
    }
  };

  const handleCloseModal = async () => {
    if (documentDetails.title || documentDetails.summary) {
      if (isPending) return;
      try {
        isPending = true;

        dispatch(createDocumentThunk(documentDetails))
          .then(() => {
            isPending = false;

            toast.success("Document Created Successfully!", {
              style: {},
              progressStyle: { backgroundColor: dynamicColor },
              icon: (
                <span
                  style={{
                    marginRight: "8px",
                    borderRadius: "50%",
                    width: "7rem",
                    height: "35px",
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: dynamicColor,
                    backgroundColor: "white",
                    fontSize: "1.5rem",
                    border: `3px solid ${dynamicColor}`,
                  }}
                >
                  ✓
                </span>
              ),
            });
            dispatch(getUniquePrimaryApproversThunk(clientId));
          })
          .catch(() => {
            isPending = false;

            toast.error("Failed To Create Document. Please Try Again!");
          });
        dispatch(resetOpenFormByClickLocation());
        dispatch(getDocuments({ clientId: locationId, providerId }));
      } catch {
        dispatch(getDocuments({ clientId: locationId, providerId }));
      } finally {
        dispatch(resetOpenFormByClickLocation());
        dispatch(getDocuments({ clientId: locationId, providerId }));
      }
    } else {
      dispatch(resetOpenFormByClickLocation());
      dispatch(getDocuments({ clientId: locationId, providerId }));
    }
  };
  const [buttonTab, setbuttonTab] = useState({
    Details: true,
    Associations: false,
    Comms: false,
    Documents: false,
    Daybook: false,
  });

  const [details, setDetails] = useState<any>({
    locationName: "",
    email: "",
    address: "",
    phone: "",
    role: "",
  });

  useEffect(() => {
    const fetchedData: any = currentLocation;
    if (fetchedData) {
      setDetails({
        locationName: currentLocation?.first_name,
        email: currentLocation?.email,
        address: currentLocation?.user_addresses[0]?.address || "",
        phone: currentLocation?.phone,
        role: currentLocation?.roles[0]?.id,
      });
    } else {
      setDetails({
        locationName: "",
        email: "",
        address: "",
        phone: "",
        role: "",
      });
    }
  }, [currentLocation]);

  const [updateLocation, setUpdateLocation] = useState<any>({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    role: "",
    user_id: "",
    type: "",
  });

  useEffect(() => {
    setUpdateLocation({
      first_name: details?.locationName, //this
      last_name: "",
      email: details?.email, //this
      phone: details?.phone,
      address: details?.address,
      role: details?.role, //this
      user_id: +locationId, //this
      type: dynamicLocationId, //this
    });
  }, [locationId, details]);
  useEffect(() => {
    if (locationId) {
      dispatch(getDocuments({ clientId: locationId, providerId }));
    }
  }, [locationId]);
  // UPDATE BUSINESS FUNCTION
  const handleSaveChanges = async () => {
    setHasUnsavedChanges(false);
    dispatch(setunsavedChanges(false));
    try {
      let parms = updateLocation;
      if (screenType) {
        parms.tenant_id = providerId;
      }
      const res = await dispatch(updateLocationUserThunk(parms));

      dispatch(
        getLocationsUserByIdThunk({
          dynamicId: dynamicLocationId,
          locationId,
        })
      );
      if (res.payload.status === "success") {
        toast.success("Location updated successfully!", {
          style: {},
          progressStyle: { backgroundColor: dynamicColor },
          icon: (
            <span
              style={{
                marginRight: "8px",
                borderRadius: "50%",
                width: "7rem",
                height: "35px",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: dynamicColor,
                backgroundColor: "white",
                fontSize: "1.5rem",
                border: `3px solid ${dynamicColor}`,
              }}
            >
              ✓
            </span>
          ),
        });
      } else {
        toast.error(res?.payload);
      }
    } catch (error: any) {
      toast.error("An Error Occurred!");
    }
    if (screenType) {
      dispatch(
        getProviderLocationByIdThunk({
          typeId: dynamicLocationId,
          clientId,
          tenant_id: providerId,
        })
      )
        .then(() => {
          setLoading(false);
        })
        .catch((error: any) => {
          // console.log(error);
          setLoading(false);
        });
    } else {
      dispatch(
        getLocationsUserByIdThunk({
          dynamicId: dynamicLocationId,
          locationId,
        })
      )
        .then(() => {
          setLoading(false);
        })
        .catch((error: any) => {
          // console.log(error);
          setLoading(false);
        });
    }
  };

  // RETAIN THE STATE
  useEffect(() => {
    if (localStorage.getItem("activeTab") === "Documents") {
      handleClickBusiness("Documents");
    } else if (localStorage.getItem("activeTab") === "Associations") {
      handleClickBusiness("Associations");
    } else if (localStorage.getItem("activeTab") === "Comms") {
      handleClickBusiness("Comms");
    } else if (localStorage.getItem("activeTab") === "Daybook") {
      handleClickBusiness("Daybook");
    }
  }, []);

  // NAVIGATING AMONG BUTTONS
  const handleClickBusiness = (button: string) => {
    setstageTwoToGoalsModal(false);
    dispatch(resetselectedItemIndexAssociations(""));
    dispatch(resetselectedNames(""));
    dispatch(resetIsDocumentsDropdownOpen());
    dispatch(resetinputSearchVal(""));
    dispatch(resetOpenFormByClickLocation());
    dispatch(resetSelectedDropdownItemAssociations());
    dispatch(resetSelectedItemIndexFilterAssociations());
    dispatch(resetSelectedFilterItemsAssociations());
    dispatch(resetSelectedDropdownItemDocuments());
    dispatch(resetSelectedItemIndexFilterDocuments());
    dispatch(resetSelectedFilterItemsDocuments());

    const defaultTabState = {
      Details: true,
      Associations: false,
      Comms: false,
      Documents: false,
      Daybook: false,
    };
    if (!hasDeleteDocument) {
      const newTabState = { ...defaultTabState, [button]: true };
      localStorage.setItem("activeTab", button);
      setbuttonTab(newTabState);
    } else {
      setbuttonTab({ ...defaultTabState, Documents: true });
      localStorage.setItem("activeTab", "Documents");
      dispatch(setWarningPopup(true));
    }
    setbuttonTab((prevState) => ({
      ...prevState,
      Details: button === "Details",
      Associations: button === "Associations",
      Comms: button === "Comms",
      Documents: button === "Documents",
      Daybook: button === "Daybook",
    }));
  };

  // CHANGE TO INPUT FIELDS
  const handleChange = (
    index: number,
    e: React.ChangeEvent<HTMLInputElement> | any
  ) => {
    const { value } = e.target;

    const fieldName = Object.keys(details)[index];

    setDetails((prevState: any) => ({
      ...prevState,
      [fieldName]: value,
    }));
    dispatch(setunsavedChanges(true));
    if (!hasUnsavedChanges) {
      setHasUnsavedChanges(true);
    }
  };

  useEffect(() => {
    if (buttonTab.Associations) {
      dispatch(getAssociationTypesThunk());
      dispatch(getAssociationRelationshipsThunk());
      dispatch(getUserTypesThunk());
    }
  }, [buttonTab]);

  const handleMoveWithSave = async () => {
    dispatch(setshowUnsavedModal(false));
    dispatch(setunsavedChanges(false));
    try {
      let parms = updateLocation;
      if (screenType) {
        parms.tenant_id = providerId;
      }
      const res = await dispatch(updateLocationUserThunk(parms));
      dispatch(
        getLocationsUserByIdThunk({
          dynamicId: dynamicLocationId,
          locationId,
        })
      );
      if (res.payload.status === "success") {
        toast.success("Location updated successfully!", {
          style: {},
          progressStyle: { backgroundColor: dynamicColor },
          icon: (
            <span
              style={{
                marginRight: "8px",
                borderRadius: "50%",
                width: "7rem",
                height: "35px",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: dynamicColor,
                backgroundColor: "white",
                fontSize: "1.5rem",
                border: `3px solid ${dynamicColor}`,
              }}
            >
              ✓
            </span>
          ),
        });
      } else {
        toast.error("An Error Occurred!");
      }
    } catch (error) {
      console.error(error);
      toast.error("An Error Occurred!");
    }
    if (screenType) {
      dispatch(
        getProviderLocationByIdThunk({
          typeId: dynamicLocationId,
          clientId,
          tenant_id: providerId,
        })
      )
        .then(() => {
          setLoading(false);
        })
        .catch((error: any) => {
          // console.log(error);
          setLoading(false);
        });
    } else {
      dispatch(
        getLocationsUserByIdThunk({
          dynamicId: dynamicLocationId,
          locationId,
        })
      )
        .then(() => {
          setLoading(false);
        })
        .catch((error: any) => {
          // console.log(error);
          setLoading(false);
        });
    }
    navigate(routeString);
  };
  useEffect(() => {
    if (locationId) {
      dispatch(
        getAssociations({
          clientId: locationId,
          searchVal: "",
          type: "",
          pageNumber: 1,
        })
      );
    }
  }, [locationId]);

  // // console.log(businessId, "Business Id");
  // const [currentBusiness, setCurrentBusiness] = useState([]);
  // const [userRoles, setUserRoles] = useState([]);

  const [showProfile, setShowProfile] = useState<boolean>(false);
  const [showGoalsModal, setshowGoalsModal] = useState(false);
  const [stageTwoToGoalsModal, setstageTwoToGoalsModal] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState<any>("");
  const [possibleAddresses, setPossibleAddresses] = useState<any>([]);

  function initService(query: any) {
    const displaySuggestions = function (predictions: any, status: any) {
      // @ts-ignore
      if (status !== window?.google?.maps.places.PlacesServiceStatus.OK) {
        return;
      }
      if (!predictions) {
        setPossibleAddresses([]);
        return;
      }
      setPossibleAddresses(predictions);
    };

    if (window.google && window.google.maps) {
      // @ts-ignore
      const service = new window.google.maps.places.AutocompleteService();
      service?.getQueryPredictions({ input: query }, displaySuggestions);
    }
  }

  const handleChangeForAddress = (index: number, e: any) => {
    if (!hasUnsavedChanges) {
      setHasUnsavedChanges(true);
    }
    const { value } = e.target;
    setDetails({ ...details, address: value });
    initService(value);
    if (value.length === 0) {
      setDetails({ ...details, address: "" });
      setPossibleAddresses([]);
    }
    dispatch(setunsavedChanges(true));
  };

  const selectAddress = (e: any) => {
    if (!hasUnsavedChanges) {
      setHasUnsavedChanges(true);
    }
    setSelectedAddress(e.target.innerHTML);
    setDetails({ ...details, address: e.target.innerHTML });
    dispatch(setunsavedChanges(true));
    setPossibleAddresses([]);
  };

  useEffect(() => {
    if (openFormLocation) {
      setbuttonTab({
        Associations: false,
        Comms: false,
        Daybook: false,
        Documents: true,
        Details: false,
      });
    }
  }, [openFormLocation]);
  /// ASSOCIATION TABLE START //
  const dropdownRefAssociation = useRef<any>();
  const svgParentRefAssociation = useRef<any>();
  const [isDropdownOpenAssociation, setIsDropdownOpenAssociation] =
    useState(false);
  const {
    selectedFilterItemsAssociations,
    sortByAssociationsR,
    searchValAssociations,
  } = useAppSelector((state) => state?.associations);

  const { filterIconColor } = useAppSelector((state) => state?.common);
  const handleClickOutsideAssociation = (event: any) => {
    if (
      dropdownRefAssociation.current &&
      !dropdownRefAssociation.current.contains(event.target) &&
      svgParentRefAssociation.current &&
      !svgParentRefAssociation.current.contains(event.target)
    ) {
      setIsDropdownOpenAssociation(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutsideAssociation);
    return () => {
      document.removeEventListener("click", handleClickOutsideAssociation);
    };
  }, []);
  const associationColumns = [
    { header: "Name", accessor: "user_name" },
    { header: "Category", accessor: "category" },
    { header: "Relationship", accessor: "relationship_name" },
    { header: "Contact Number", accessor: "number" },
    { header: "Associated Business/Site", accessor: "funding_type" },
    { header: "Action", accessor: "actionAssociation" },
  ];
  const { getAssociationByUserId } = useAppSelector(
    (state) => state?.associations
  );
  /// ASSOCIATION TABLE END //
  // DOCUMENTS TABLE START //
  const dropdownRefDocuments = useRef<any>();
  const svgParentRefDocuments = useRef<any>();
  const [isDropdownOpenDocuments, setIsDropdownOpenDocuments] = useState(false);
  const { selectedFilterItemsDocuments, sortByDocumentsR, searchValDoc } =
    useAppSelector((state) => state?.document);
  const handleClickOutsideDocuments = (event: any) => {
    if (
      dropdownRefDocuments.current &&
      !dropdownRefDocuments.current.contains(event.target) &&
      svgParentRefDocuments.current &&
      !svgParentRefDocuments.current.contains(event.target)
    ) {
      setIsDropdownOpenDocuments(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutsideDocuments);
    return () => {
      document.removeEventListener("click", handleClickOutsideDocuments);
    };
  }, []);

  let clientId = locationId;
  const getDocumentsArray = useAppSelector(
    (state) => state?.document?.getDocuments
  );
  const updatedDoc = useAppSelector(
    (state) => state?.document?.updateDocumentData
  );
  const selectedOps = useAppSelector(
    (state) => state?.document?.selectedOptions
  );

  const [primaryApprover, setPrimaryApprover] = useState([]);
  const [ongoingApprover, setOngoingApprover] = useState([]);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [selectedObject, setSelectedObject] = useState<any>(null);
  const [showStateOfDocument, setShowStateOfDocument] = useState<any>("");
  const handleClickDocRow = (e: any, id: any, title: any) => {
    e.stopPropagation();
    if (isDropdownOpenDocuments) {
      setIsDropdownOpenDocuments(false);
    } else {
      const selectedDocument = getDocumentsArray?.find((elem: any) => {
        return elem?.id === id;
      });
      setIsFormOpen(true);
      dispatch(setIsFormOpenTrue());
      setSelectedObject(selectedDocument);
      setPrimaryApprover(selectedDocument?.primary_approvers);
      setOngoingApprover(selectedDocument?.ongoing_approvers);
      dispatch(setselectedDataObject(selectedDocument));
      setUploadedFiles(selectedDocument?.files);
      setShowStateOfDocument(title);
    }
  };
  const handleOverlayClickUpdate = async () => {
    if (!selectedObject?.isThereAnyChanges) {
      setIsFormOpen(false);
      return;
    }
    try {
      // dispatch(getDocuments(clientId));
      dispatch(
        updateDocumentThunk({
          title: updatedDoc?.title,
          summary: updatedDoc.summary,
          primary_review: updatedDoc?.primary_review,
          ongoing_review: updatedDoc.ongoing_review,
          period_picker: updatedDoc.period_picker,
          start: updatedDoc.start,
          end: updatedDoc.end,
          primary_approver_id: primaryApprover?.map((res: any) => res?.id),
          ongoing_approver_id: ongoingApprover?.map((res: any) => res?.id),
          hard_copy_completed_date: updatedDoc.hard_copy_completed_date,
          document_files: updatedDoc.document_files,
          user_id: updatedDoc.user_id,
          document_id: updatedDoc?.document_id,
          tag: updatedDoc?.tagArray,
          tenant_id: providerId,
          note_details: updatedDoc?.note_details || {},

        })
      )
        .then(() => { 
          toast.success("Document updated successfully!", { 
            style: {},
            progressStyle: { backgroundColor: dynamicColor },
            icon: (
              <span
                style={{
                  marginRight: "8px",
                  borderRadius: "50%",
                  width: "7rem",
                  height: "35px",
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: dynamicColor,
                  backgroundColor: "white",
                  fontSize: "1.5rem",
                  border: `3px solid ${dynamicColor}`,
                }}
              >
                ✓
              </span>
            ),
          });

          dispatch(getDocuments(clientId));
          dispatch(
            getDocumentsByFilter({
              clientId: locationId,
              filterValue: selectedOps?.join(","),
              searchVal: "",
              sortType: "",
              filterColumn: "",
              sortColumn: "",
              providerId,
            })
          );
          dispatch(getUniquePrimaryApproversThunk(clientId));
        })
        .catch(() =>
          toast.error("Failed To Update Document. Please Try Again!")
        );
    } catch (err) {}
    setIsFormOpen(false);
    dispatch(getUniquePrimaryApproversThunk(clientId));
  };
  const [changesToForm, setchangesToForm] = useState("");

  const handleChangesToForm = (val: any) => {
    setchangesToForm(val);
  };
  const { domainDetail } = useAppSelector((state) => state?.common);
  // DOCUMENTS TABLE END //

  const [userType, setUserType] = useState<any>([]);
  useEffect(() => {
    if (domainDetail && domainDetail?.location_type) {
      let spliteTheTypeString = domainDetail?.location_type.split(",");
      setUserType(spliteTheTypeString);
    }
  }, [domainDetail]);

  const locationStatusDetailsNums = useAppSelector(
    (state) => state.common.domainDetail.location_status
  );
  useEffect(() => {
    dispatch(getStatusDetailsThunk(locationStatusDetailsNums));
  }, [locationStatusDetailsNums]);

  const openWarningSection = useAppSelector(
    (state) => state?.documentClient?.openWarningSection
  );

  return (
    <div>
      {loading && <Spinner position={"absolute"} top={"50%"} left={"50%"} />}

      {/* <UpperBox type="businessScreen" currentBusiness={currentBusiness} /> */}
      <div
        style={{
          position: "relative",
        }}
      >
        {stageTwoToGoalsModal && (
          <CommonQuickMenu
            setshowGoalsModal={setshowGoalsModal}
            showGoalsModal={showGoalsModal}
            setstageTwoToGoalsModal={setstageTwoToGoalsModal}
            type="locations"
            filterItems={true}
          />
        )}
        <CommonUpperBox
          imageSection
          type={"screen"}
          currentUser={currentLocation}
          imageURL={currentLocation?.profile_image}
          title={`${currentLocation?.first_name || ""}`}
          onClickUserImage={() => setShowProfile(true)}
          onClickPlus={() => {
            // if (buttonTab.Documents) {
            setstageTwoToGoalsModal(true);
            // }
          }}
        />
      </div>

      {showProfile && (
        <ProfilePictureModal
          showProfile={showProfile}
          className="profile_picture_external"
          setShowProfile={setShowProfile}
          currentClient={currentLocation}
        />
      )}

      {buttonTab.Details && (
        <>
          <CommonHeader
            mainType="locations"
            type="details"
            handleClick={handleClickBusiness}
            currentLocation={currentLocation}
          />
          <>
            <div className="px-[10px]">
              <div className=" client-screen-personal-details-heading">
                <span className=" client-screen-sub-heading-title">
                  Personal Details
                </span>
                <span className=" client-screen-line"></span>
              </div>
              <div className="main-box-body">
                {dummyArrLocations?.map((elem: any, index: any) => {
                  const fieldName = Object.keys(details)[index];

                  return (
                    <div className="content-wrapper" key={index}>
                      <div className="wrapper-title">{elem.UpperTitle}</div>

                      {index === 4 ? (
                        <select
                          className="wrapper-info wrapper-info-select-dropdown"
                          onChange={(e) => handleChange(index, e)}
                          value={details[fieldName]}
                          style={{
                            border: "none",
                            outline: "none",
                            height: "fit-content",
                            backgroundColor: "white",
                            // width: "256px",
                          }}
                        >
                          <option>Please Select...</option>
                          {currentLocationRoles?.map(
                            (elem: any, index: number | any) => {
                              let isExist: any = userType.includes(
                                `${elem?.desc}`
                              );
                              if (isExist) {
                                return (
                                  <option key={index} value={elem?.id}>
                                    {elem.desc}
                                  </option>
                                );
                              }
                            }
                          )}
                        </select>
                      ) : index === 2 ? (
                        /*furkan location*/
                        <>
                          <div>
                            <input
                              type="text"
                              onChange={(e) => handleChangeForAddress(index, e)}
                              className="wrapper-info"
                              placeholder="Enter an address"
                              value={details[fieldName]}
                            />
                            <div className="address_listing">
                              <ul>
                                {possibleAddresses.length > 0
                                  ? possibleAddresses.map(
                                      (address: any, ind: any) => {
                                        return (
                                          <li
                                            onClick={selectAddress}
                                            className="address_listing_item"
                                          >
                                            {address.description}
                                          </li>
                                        );
                                      }
                                    )
                                  : null} 
                              </ul>
                            </div>
                          </div>
                        </>
                      ) : (
                        <input
                          className="wrapper-info"
                          onChange={(e) => handleChange(index, e)}
                          value={details[fieldName]}
                          placeholder={elem?.placeHolder}
                          style={{
                            border: "none",
                            outline: "none",
                            height: "fit-content",
                          }}
                        />
                      )}
                      {elem.index === 5 && (
                        <div className="dropdown-icon">
                          <img src={DropdownIcon} alt="dropdown-icon" />
                        </div>
                      )}
                      <div className="wrapper-line"></div>
                    </div>
                  );
                })}
              </div>
            </div>
            {hasUnsavedChanges && (
              <>
                <div
                  className="save"
                  style={{
                    position: "absolute",
                    marginRight: "200px",
                    bottom: "10px",
                  }}
                >
                  <p>Would you like to save your changes?</p>
                  <div className="btnDivision">
                    <button
                      onClick={handleSaveChanges}
                      style={{ backgroundColor: dynamicColor }}
                    >
                      Save
                    </button>
                    <button
                      style={{
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "18px",
                        backgroundColor: "#F9F9F9",
                        border: "none",
                        color: "#9296A2",
                      }}
                      onClick={() => {
                        dispatch(
                          getLocationsUserByIdThunk({
                            dynamicId: dynamicLocationId,
                            locationId,
                          })
                        );
                        setHasUnsavedChanges(false);
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </>
            )}
            {showUnsavedModal && (
              <>
                <ConfirmationModal
                  type="clientScreen"
                  handleMoveWithoutSaving={handleMoveWithoutSaving}
                  handleMoveWithSave={handleMoveWithSave}
                />
              </>
            )}
          </>
          {/* <Rectangle type="stickyHub" /> */}
          <CircularNoteTrigger type="stickyHub" />
        </>
      )}

      {buttonTab.Documents && (
        <>
          <CommonHeader
            mainType="locations"
            type="documents"
            handleClick={handleClickBusiness}
            currentLocation={currentLocation}
          />
          {/* <CommonTable type="documents" /> */}
          <Table
            dynamicHeaderColor={domainDetail?.doctbl_color}
            thData={documentsColumns}
            classNameTr="client-documents-tab-table-head"
            classNameTable="documents-table-additional-styles"
            type="documents"
            dropdownRef={dropdownRefDocuments}
            isDropdownOpen={isDropdownOpenDocuments}
            onClickSvg={() => setIsDropdownOpenDocuments(true)}
            svgParentRef={svgParentRefDocuments}
            allClients={getDocumentsArray}
            // onClickRow={handleClickDocRow.bind(this, "onRow")}
            onClickRow={handleClickDocRow}
            classNameRow="client-documents-tab-table-body"
            fill={
              selectedFilterItemsDocuments.length > 0 ||
              searchValDoc.length > 0 ||
              sortByDocumentsR.length > 0
                ? filterIconColor
                : "#D8D3D3"
            }
          />
          {warningPopup && (
            <>
              <ConfirmationModal type="" />
            </>
          )}
          {hasDeleteDocument && (
            <>
              <UnsavedChangesPopup type="documents" />
            </>
          )}

          {openFormLocation && openWarningSection && (
            <ConfirmationModal
              type="documents"
              handleMoveWithoutSaving={() => {
                dispatch(setWarningMessage(false));
              }}
              handleMoveWithSave={() => {
                dispatch(setWarningMessage(false));
                dispatch(resetOpenFormByClickLocation());
                // dispatch(setopenClientAssessmentForm(false));
              }}
            />
          )}

          {openFormLocation && (
            <div className="client-assessment-form-div">
              {openFormLocation && (
                <Modal
                  isOpen={true}
                  onClose={() => {}}
                  closeOnOverlayClick={true}
                  isCentered
                >
                  <ModalOverlay onClick={() => {}} zIndex={10} />

                  <ClientAssessmentForm
                    type="new-form"
                    // openForm={openForm}
                    // setopenForm={setopenForm}
                    // title={typeCheckForAssessmentform}
                    handleGetDetails={handleGetDetails}
                    uploadedFiles={uploadedFiles}
                    setUploadedFiles={setUploadedFiles}
                    // changesMadeToTheForm={changesMadeToTheForm}
                    // setChangesMadeToTheForm={setChangesMadeToTheForm}
                    handleSaveClick={handleOverlayClick}
                  />
                </Modal>
              )}
            </div>
          )}
          {isFormOpen && (
            <Modal
              isOpen={true}
              onClose={() => {}}
              closeOnOverlayClick={true}
              isCentered
            >
              <ModalOverlay onClick={() => {}} zIndex={10} />
              <ClientAssessmentForm
                type="new-form"
                handleChangesToForm={handleChangesToForm}
                dataObject={selectedObject}
                setDataObject={setSelectedObject}
                handleGetDetails={() => {}}
                showStateOfDocument={showStateOfDocument}
                uploadedFiles={uploadedFiles}
                setUploadedFiles={setUploadedFiles}
                primaryApprover={primaryApprover}
                setPrimaryApprover={setPrimaryApprover}
                ongoingApprover={ongoingApprover}
                setOngoingApprover={setOngoingApprover}
                handleSaveClick={handleOverlayClickUpdate}
                setIsFormOpenLocation={setIsFormOpen}
              />
            </Modal>
          )}
          <CircularNoteTrigger type="stickyHub" />
        </>
      )}

      {buttonTab.Associations && (
        <>
          <CommonHeader
            mainType="locations"
            type="associations"
            handleClick={handleClickBusiness}
            currentLocation={currentLocation}
          />
          <CircularNoteTrigger type="associations" title="Association" />
          {/* <CommonTable type="associations" /> */}
          <Table
            dynamicHeaderColor={domainDetail?.assoctbl_color}
            type="asociations"
            classNameTable="common-table-container-associations"
            thData={associationColumns}
            classNameTr="client-associations-tab-table-head"
            dropdownRef={dropdownRefAssociation}
            onClickSvg={() => setIsDropdownOpenAssociation(true)}
            isDropdownOpen={isDropdownOpenAssociation}
            svgParentRef={svgParentRefAssociation}
            allClients={getAssociationByUserId}
            classNameRow="client-associations-tab-table-body"
            fill={
              selectedFilterItemsAssociations.length > 0 ||
              searchValAssociations.length > 0 ||
              sortByAssociationsR.length > 0
                ? filterIconColor
                : "#D8D3D3"
            }
          />
          {warningPopupAssociation && (
            <>
              <ConfirmationModal type="" />
            </>
          )}
          {hasDeleteAssociation && (
            <>
              <UnsavedChangesPopup type="associations" />
            </>
          )}
        </>
      )}

      {buttonTab.Comms && (
        <>
          <CommonHeader
            mainType="locations"
            type="comms"
            handleClick={handleClickBusiness}
            currentLocation={currentLocation}
          />
          {/* <UnderWork /> */}
          <div style={{ marginTop: "10px" }}>
            <Comms isUserType={true} />
          </div>
          {/* <div className="comms_client_section_wrapper">
            <Comms type="business" />
          </div>
          <CircularNoteTrigger type="stickyHub" /> */}
        </>
      )}

      {buttonTab.Daybook && (
        <>
          <CommonHeader
            mainType="locations"
            currentLocation={currentLocation}
            type="daybook"
            handleClick={handleClickBusiness}
          />
          <div style={{ marginTop: "10px" }}>
            <Daybook type="locations" />
          </div>
        </>
      )}
    </div>
  );
};

export default LocationsScreen;
