import React from "react";
import { useEffect, useState } from "react";
import { ReplyPlusSvg } from "../../../../Model/Assets/SVG/Common/ReplySvg";
import { shuffleColorsClinical } from "../../../../Model/utils/Helper/shuffleColors";
import { GreenCircleSvg } from "../../../pages/People/ClientPage/Components/UniqueClientScreen/Components/ClinicalTab/Data/SVGs";
import TrafficLights from "../../CommonModal/Components/TrafficLights";
import {TagBox} from "../../Tags/CommonTagsComponent/TagBox";


const CollapsedNote = ({
  onClick = () => {},
  index,
  title,
  forSticky,
  isOpen,
  onClickPlus = () => {},
  isGoalsModal,
  goal,
  selectedTags,
  fromAdvancedClinicalNote
}: any) => {
  
  const [showLights, setShowLights] = useState<boolean>(false);
  const [trafficColors, setTrafficColors] = useState<any>("");
  useEffect(() => {
    if (goal) {
      let trafficLightActivitiesArr = goal?.activities?.map(
        (res: any) => res?.traffic_light
      );

      if (trafficLightActivitiesArr.includes(0)) {
        setTrafficColors("#ef3a34");
      } else if (trafficLightActivitiesArr.includes(1)) {
        setTrafficColors("#fbbf14");
      } else if (trafficLightActivitiesArr.includes(2)) {
        setTrafficColors("#2ad400");
      } else {
        setTrafficColors("#ef3a34");
      }
    }
  }, [goal]);

  return (
    <div
      style={{
        backgroundColor: shuffleColorsClinical(index),
      }}
      onClick={onClick}
      className={`${
        forSticky
          ? "collapsed_note_container_sticky"
          : isGoalsModal
          ? "collapsed_note_container_goals_modal"
          : "collapsed_note_container"
      } `}
    >
      <div className="expanded_note_inner">
        <div
          className={`expanded_left_flex ${
            forSticky && "expanded_left_flex_sticky"
          }
        `}
        >
          <div>
            <span
              style={{
                display: !isGoalsModal ? "" : "none",
              }}
              className="traffic_light_trigger_goals"
              onClick={() =>{

                if(!fromAdvancedClinicalNote){
                  setShowLights(true)
                }

              } }
            >

              
              <GreenCircleSvg color={trafficColors} size={24} />
            </span>
            {showLights && (
              <TrafficLights
                className={"client_taffic_light_big"}
                type="goals"
                setShowLights={setShowLights}
                handleTrafficColors={(data: any) => {
                  setTrafficColors(data);
                  setShowLights(false);
                }}
                isSmallLight={false}
              />
            )}
          </div>

          <div className="clinical_flex">
            <ReplyPlusSvg isPlus={isOpen} onClick={onClickPlus} />
            <span className="expanded_goal">GOAL</span>
          </div>
          <div className="expanded_flex">
            {forSticky ? (
              <h4 className="expanded_title_sticky">{title}</h4>
            ) : (
              <h4 className="expanded_title">{title}</h4>
            )}
          </div>
        </div>
        <div className="expanded_right_flex">
          {true && (
            <div onClick={onClickPlus}>
              <TagBox count={selectedTags?.length} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CollapsedNote;
