import { createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  openFormBusiness: false,
};

const documentBusinessSlice = createSlice({
  name: "documentBusinessSlice",
  initialState,
  reducers: {
    setOpenFormByClickBusiness: (state) => {
      state.openFormBusiness = true;
    },
    resetOpenFormByClickBusiness: (state) => {
      state.openFormBusiness = false;
    },
  },
});

export const { setOpenFormByClickBusiness, resetOpenFormByClickBusiness } =
  documentBusinessSlice.actions;
export default documentBusinessSlice.reducer;
