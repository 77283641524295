import React from "react";
import DaybookForm from "./DaybookForm";
import CommsForm from "./CommsForm";
import ClinicalForm from "./ClinicalForm";
import AssociationForm from "./AssociationForm";
import BusinessAndTeamForm from "./BusinessAndTeamForm";
import ProvidersForm from "./ProvidersForm";
import OtherForm from "./OtherForm";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../Controller/redux/Hooks";
import ClinicalProgress from "../../ClinicalProgress/ClinicalProgress";
import ExpenseForm from "./ExpenseForm";
import FundingForm from "./FundingForm";
import PayRuleForm from "./PayRuleForm";
import BillingForm from "./BillingForm";
import SupportForm from "./SupportForm";
import { Fragment, useEffect, useRef, useState } from "react";
import {
  createClinicalNoteThunk,
  getAllClinicalNotes,
  resetClinicalRecords,
  setClinicalPage,
  updateClinicalNoteThunk,
} from "../../../../Controller/redux/features/ClinicalFeature/clinicalSlice";
import _ from "lodash";
import ClinicalNote from "../../ClinicalProgress/Components/ClinicalNote";
import { formatDateAndTime } from "../../../../Model/utils/Helper/formatTime";
import { toast } from "react-toastify";
import { useLocation, useParams } from "react-router-dom";
import PlusSVG from "../../../pages/People/ClientPage/Components/UniqueClientScreen/Components/FinanceTab/Data/PlusSVG";
import CustomForm from "./CustomFormForm";
import VendorStickyForm from "../../Vendors/VendorStickyForm";
import ReportsHubStickyForm from "../../ReportsHub/ReportsHubStickyForm";

const MainFormSticky = ({
  setIsSubTaskOpenComms,
  setisThereAnyChangesComms,
  // isThereAnyChanges,
  isCommsActive,
  activeTab,
  extraType,
  isOutside,
  activeTabProfile,
  type,
  formData,
  mobileScreenStickyNoteCurrentSlide,
  currentSelectedBTN,
  trafficColors,
  setShowLights,
  showLights,
  setreverseRelationship,
  setTrafficColors,
  dayBookDetails,
  setDayBookDetails,
  startTime,
  reverseRelationship,
  endTime,
  setStartTime,
  setEndTime,
  editorState,
  handleEditorStateChange,
  clientsDataForAssignedTo,
  clientsDataForTaggedUser,
  onSavetrigger,
  setDaybookDate,
  daybookDate,
  setcurrentSelectedBTN,
  clientDataForDayBook,
  handleClickClientRow,
  currentTitle,
  staffDataDayBook,
  prospectsData,
  contactsData,
  clientDetails,
  //
  titleHub,
  onSaveComms,
  //
  clinicalObs,
  isSubTaskOpen,
  handleChangeValueTemp,
  handleChangeValue,
  handleOpenSubTask,
  //
  selectedName,
  selectedType,
  relationship,
  setRelationship,
  handleNameChange,
  handleSaveAssociation,
  handleTypeSelect,
  // setIsStickyNoteOpen,
  //
  currentStaff,
  currentContact,
  currentProspects,
  clientStickyColumnArr,
  // dropdownRefClients,
  isDropdownOpenClients,
  typeProfile,
  sendMarkerRef,
}: // svgParentRefClients,
any) => {
  const {
    prospects_label,
    clients_label,
    business_label,
    locations_label,
    teams_label,
    contacts_label,
    staff_label,
  } = useAppSelector((state) => state?.common?.domainDetail);
  const { pathname } = useLocation();
  // const isAdmin = useAppSelector(
  //   (state) => state?.common?.user?.roles[0]?.desc
  // );
  // let isAdminActive = isAdmin === "Administrator";
  const dispatch = useAppDispatch();

  const clinicalNotesSectionRef = useRef<any>(null);
  const [CurrentActiveIndex, setCurrentActiveIndex] = useState<any>(null);
  const activeNoteRef = useRef<any>(null);
  const dynamicColor = useAppSelector((state) => state?.common?.dynamicColor);
  const [isThereAnyChanges, setisThereAnyChanges] = useState(false);

  const { user, getProfileTypeSticky, getButtonTypeSticky } = useAppSelector(
    (state) => state.common
  );
  const isAdminActive = user?.tenant?.id === user?.tenant?.parent_id;

  // Clinical Note Modification Start  here
  const [currentActiveTabClinical, setcurrentActiveTabClinical] =
    useState("Clinical Note");

  const { ClinicalNotesData, lastPage, ClinicalPage } = useAppSelector(
    (state) => state.clinical
  );

  const [ClinicalNotesRecords, setClinicalNotesRecords] = useState<any>([]);

  const handleScroll = () => {
    const { scrollTop, clientHeight, scrollHeight }: any =
      clinicalNotesSectionRef.current;

    if (
      scrollTop + clientHeight >= scrollHeight - 10 &&
      ClinicalPage < lastPage
    ) {
      dispatch(setClinicalPage());
    }
  };

  const debouncedApiCall = _.debounce(handleScroll, 300);

  useEffect(() => {
    const scrollableElement: any = clinicalNotesSectionRef.current;
    scrollableElement?.addEventListener("scroll", debouncedApiCall);
    return () =>
      scrollableElement?.removeEventListener("scroll", debouncedApiCall);
  }, [debouncedApiCall]);

  useEffect(() => {
    if (ClinicalNotesData) {
      const uniqueClientData = ClinicalNotesData?.filter(
        (item: any, index: number, array: any) => {
          return array?.findIndex((obj: any) => obj.id === item.id) === index;
        }
      );
      setClinicalNotesRecords(uniqueClientData);
    }
  }, [ClinicalNotesData]);
  const handleOnChangeNote = (e: any) => {
    let updatedClinicalArray: any = ClinicalNotesRecords.map(
      (res: any, index: number) => {
        if (index == CurrentActiveIndex) {
          return {
            ...res,
            note: e?.target?.value,
          };
        }
        return res;
      }
    );
    setisThereAnyChanges(true);
    setClinicalNotesRecords(updatedClinicalArray);
  };
  const onChangeNoteType = (e: any) => {
    let updatedClinicalArray: any = ClinicalNotesRecords.map(
      (res: any, index: number) => {
        if (index == CurrentActiveIndex) {
          return {
            ...res,
            note_tag: e?.target?.value,
          };
        }
        return res;
      }
    );
    setisThereAnyChanges(true);
    setClinicalNotesRecords(updatedClinicalArray);
  };

  const handleAddNote = () => {
    let cloneClinicalNotesRecords = [...ClinicalNotesRecords];
    cloneClinicalNotesRecords.unshift({
      id: null,
      goal_id: null,
      note_type: "Progress",
      note: "",
      note_tag: "Default",
      staff_user: null,
    });
    setClinicalNotesRecords(cloneClinicalNotesRecords);
    setCurrentActiveIndex(0);
    setisThereAnyChanges(false);
  };
  const id: any = useParams()?.id;
  const [clientId, setclientId] = useState<any>("");
  useEffect(() => {
    if (id) {
      let realId = window?.atob(id);
      setclientId(realId);
    }
  }, [id]);

  const handleAddUpdateNote = async (event: any) => {
    let storeCurrentNoteInfo = ClinicalNotesRecords[CurrentActiveIndex];
    const { date, time } = formatDateAndTime(new Date());

    if (!storeCurrentNoteInfo?.note || !storeCurrentNoteInfo?.note_tag) {
      return;
    }

    if (storeCurrentNoteInfo?.id) {
      setCurrentActiveIndex(null);
      if (!isThereAnyChanges) {
        return;
      }
      try {
        const response = await dispatch(
          updateClinicalNoteThunk({
            ...storeCurrentNoteInfo,
            user_id: clientId,
            activity_note_id: storeCurrentNoteInfo?.id,
            note_date_time: `${date} ${time.split(" ")[0]}`,
          })
        );
        if (response?.payload?.status === "success") {
          toast.success("Note updated successfully", {
            progressStyle: { backgroundColor: dynamicColor },
            icon: (
              <span
                style={{
                  marginRight: "8px",
                  borderRadius: "50%",
                  width: "7rem",
                  height: "35px",
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: dynamicColor,
                  backgroundColor: "white",
                  fontSize: "1.5rem",
                  border: `3px solid ${dynamicColor}`,
                }}
              >
                ✓
              </span>
            ),
          });
        }
        // dispatch(getAllClinicalNotes({ user_id: clientId }));
      } catch (error) {}
    } else {
      //  Add State
      try {
        const response = await dispatch(
          createClinicalNoteThunk({
            ...storeCurrentNoteInfo,
            user_id: clientId,
            note_date_time: `${date} ${time.split(" ")[0]}`,
          })
        );
        if (response?.payload?.status === "success") {
          toast.success("Note Created Successfully", {
            progressStyle: { backgroundColor: dynamicColor },
            icon: (
              <span
                style={{
                  marginRight: "8px",
                  borderRadius: "50%",
                  width: "7rem",
                  height: "35px",
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: dynamicColor,
                  backgroundColor: "white",
                  fontSize: "1.5rem",
                  border: `3px solid ${dynamicColor}`,
                }}
              >
                ✓
              </span>
            ),
          });
        }
        dispatch(resetClinicalRecords());
        dispatch(getAllClinicalNotes({ user_id: clientId }));
        setCurrentActiveIndex(null);
      } catch (error: any) {
        setCurrentActiveIndex(null);
      }
    }
  };

  const debouncedApiCallNote = _.debounce(handleAddUpdateNote, 300);

  useEffect(() => {
    document
      ?.getElementById("saveBTN")
      ?.addEventListener("click", debouncedApiCallNote);
    return () => {
      document
        ?.getElementById("saveBTN")
        ?.removeEventListener("click", debouncedApiCallNote);
    };
  }, [clinicalNotesSectionRef, ClinicalNotesRecords, CurrentActiveIndex]);

  const renderForm = () => {
    switch (type) {
      case "Daybook":
      case "stickyDaybook":
        return (
          <DaybookForm
          extraType={extraType}
            mobileScreenStickyNoteCurrentSlide={
              mobileScreenStickyNoteCurrentSlide
            }
            currentSelectedBTN={currentSelectedBTN}
            trafficColors={trafficColors}
            setShowLights={setShowLights}
            showLights={showLights}
            setTrafficColors={setTrafficColors}
            dayBookDetails={dayBookDetails}
            setDayBookDetails={setDayBookDetails}
            startTime={startTime}
            endTime={endTime}
            setStartTime={setStartTime}
            setEndTime={setEndTime}
            editorState={editorState}
            handleEditorStateChange={handleEditorStateChange}
            clientsDataForAssignedTo={clientsDataForAssignedTo}
            clientsDataForTaggedUser={clientsDataForTaggedUser}
            onSavetrigger={onSavetrigger}
            setDaybookDate={setDaybookDate}
            daybookDate={daybookDate}
            setcurrentSelectedBTN={setcurrentSelectedBTN}
            clientDataForDayBook={clientDataForDayBook}
            handleClickClientRow={handleClickClientRow}
            staffDataDayBook={staffDataDayBook}
            prospectsData={prospectsData}
            contactsData={contactsData}
            clientDetails={clientDetails}
            currentStaff={currentStaff}
            currentContact={currentContact}
            currentProspects={currentProspects}
            clientStickyColumnArr={clientStickyColumnArr}
            isDropdownOpenClients={isDropdownOpenClients}
            isSmall={true}
          />
        );
      case "Comms":
      case "stickyHub":
        return (
          <CommsForm
            type={type}
            extraType={extraType}
            setisThereAnyChanges={setisThereAnyChangesComms}
            trafficColors={trafficColors}
            setShowLights={setShowLights}
            showLights={showLights}
            setTrafficColors={setTrafficColors}
            titleHub={titleHub}
            dayBookDetails={dayBookDetails}
            setDayBookDetails={setDayBookDetails}
            editorState={editorState}
            handleEditorStateChange={handleEditorStateChange}
            onSaveComms={onSaveComms}
            setIsSubTaskOpenComms={setIsSubTaskOpenComms}
          />
        );

      case "Obs":
      case "stickyClinical":
        return (
          <ClinicalForm
            // activeTab={activeTab}
            clinicalObs={clinicalObs}
            isSubTaskOpen={isSubTaskOpen}
            type={type}
            handleChangeValueTemp={handleChangeValueTemp}
            handleChangeValue={handleChangeValue}
            handleOpenSubTask={handleOpenSubTask}
          />
        );
      case "Notes":
      case "Note":
        return (
          <div className="clinical_note_sticky_section_wrapper">
            <div
              className="sticky_profil_btns"
              style={{
                display: "none",
              }}
            >
              {["Clinical Note", "Standard Note"]?.map(
                (elem: string, index: number) => {
                  return (
                    <button
                      onClick={() => {
                        // setActiveTabInner(elem);
                        // handleTabChangeInner(elem);
                        // dispatch(setButtonTypeSticky(elem));
                        setcurrentActiveTabClinical(elem);
                      }}
                      className={`${
                        currentActiveTabClinical === elem
                          ? "sticky_note_btn_active"
                          : ""
                      } sticky_note_btn`}
                    >
                      {elem}
                    </button>
                  );
                }
              )}
              {currentActiveTabClinical == "Standard Note" && (
                <PlusSVG
                  width="18"
                  height="18"
                  onClick={() => handleAddNote()}
                />
              )}{" "}
            </div>
            {currentActiveTabClinical == "Clinical Note" && (
              <ClinicalProgress forSticky={true} />
            )}
            {currentActiveTabClinical == "Standard Note" && (
              <div
                className="sticky_clinical_note_section_wrapper"
                //@ts-ignore
                ref={clinicalNotesSectionRef}
              >
                {ClinicalNotesRecords.map((elem: any, index: number) => {
                  if (CurrentActiveIndex == index) {
                    return (
                      <div
                        className="active_note_wrapper"
                        //@ts-ignore
                        ref={activeNoteRef}
                        key={index}
                      >
                        <ClinicalNote
                          editiable={false}
                          clinicalGoalsNote={elem?.note}
                          handleOnClick={() => {
                            setCurrentActiveIndex(index);
                          }}
                          dateTime={elem?.note_date_time}
                          noteType={
                            elem?.note_type == "Advance Care"
                              ? "Clinical Progress"
                              : elem?.note_tag
                          }
                          userName={
                            elem?.staff_user
                              ? `${elem?.staff_user?.first_name} ${elem?.staff_user?.last_name}`
                              : null
                          }
                          color={elem?.goal_id !== null ? "#FFE8F8" : "#faf9ac"}
                          setshowGoalsModal={() => {}}
                          NoteInfo={elem}
                          onClickEnable={false}
                          dropdown={true}
                          dropdownValue={elem?.note_tag}
                          onChangeTextNote={handleOnChangeNote}
                          onChangeNoteType={onChangeNoteType}
                          saveBtn={isThereAnyChanges && elem?.note !== ""}
                        />
                      </div>
                    );
                  }
                  return (
                    <Fragment key={index}>
                      <ClinicalNote
                        clinicalGoalsNote={elem?.note}
                        handleOnClick={() => {
                          if (!CurrentActiveIndex) {
                            // console.log("index is ", index);
                            setCurrentActiveIndex(index);
                            setisThereAnyChanges(false);
                          }
                        }}
                        dateTime={elem?.note_date_time}
                        noteType={
                          elem?.note_type == "Advance Care"
                            ? "Clinical Progress"
                            : elem?.note_tag
                        }
                        userName={`${elem?.staff_user?.first_name} ${elem?.staff_user?.last_name}`}
                        color={elem?.goal_id !== null ? "#FFE8F8" : "#faf9ac"}
                        setshowGoalsModal={() => {}}
                        NoteInfo={elem}
                        onClickEnable={true}
                        onClickPlus={(e: any) => {
                          if (!CurrentActiveIndex) {
                            handleAddNote();
                          }
                        }}
                      />
                    </Fragment>
                  );
                })}
              </div>
            )}
          </div>
        );

      case "Asso":
      case "associations":
      case "Associations":
        return (
          <AssociationForm
          extraType={extraType}
            selectedName={selectedName}
            selectedType={selectedType}
            formData={formData}
            relationship={relationship}
            setRelationship={setRelationship}
            handleSaveAssociation={handleSaveAssociation}
            handleTypeSelect={handleTypeSelect}
            handleNameChange={handleNameChange}
            setreverseRelationship={setreverseRelationship}
            reverseRelationship={reverseRelationship}
          />
        );
      case "providers":
        return <ProvidersForm type={typeProfile} currentTitle={currentTitle} />;
      case "form":
        return <CustomForm />;
      case "Expense":
        return <ExpenseForm />;
      case "Funding":
        return <FundingForm />;
      case "Billing":
        return <BillingForm />;
      case "PayRule":
        return <PayRuleForm />;
      case "Vendors":
        return <VendorStickyForm />;
      case "Reports": 
       return <ReportsHubStickyForm />;
      default:
        return <></>;
    }
  };

  const renderForm2 = () => {
    switch (typeProfile) {
      case business_label:
      case teams_label:
      case locations_label:
        return (
          <BusinessAndTeamForm
            activeTabProfile={activeTabProfile}
            type={typeProfile}
            currentTitle={currentTitle}
            comp={
              <CommsForm
                type={type}
                extraType={extraType}
                trafficColors={trafficColors}
                setShowLights={setShowLights}
                showLights={showLights}
                setTrafficColors={setTrafficColors}
                titleHub={titleHub}
                dayBookDetails={dayBookDetails}
                setDayBookDetails={setDayBookDetails}
                editorState={editorState}
                handleEditorStateChange={handleEditorStateChange}
                onSaveComms={onSaveComms}
              />
            }
          />
        );
      case prospects_label:
      case contacts_label:
      case staff_label:
        return (
          <OtherForm
            activeTabProfile={activeTabProfile}
            type={typeProfile}
            currentTitle={currentTitle}
            comp={
              <CommsForm
                type={type}
                extraType={extraType}
                trafficColors={trafficColors}
                setShowLights={setShowLights}
                showLights={showLights}
                setTrafficColors={setTrafficColors}
                titleHub={titleHub}
                dayBookDetails={dayBookDetails}
                setDayBookDetails={setDayBookDetails}
                editorState={editorState}
                handleEditorStateChange={handleEditorStateChange}
                onSaveComms={onSaveComms}
              />
            }
          />
        );
      case clients_label:
        return isAdminActive && !pathname.includes("provider") ? (
          <ProvidersForm
            type={typeProfile}
            currentTitle={currentTitle}
            activeTabProfile={activeTabProfile}
            comp={
              <CommsForm
                type={type}
                extraType={extraType}
                trafficColors={trafficColors}
                setShowLights={setShowLights}
                showLights={showLights}
                setTrafficColors={setTrafficColors}
                titleHub={titleHub}
                dayBookDetails={dayBookDetails}
                setDayBookDetails={setDayBookDetails}
                editorState={editorState}
                handleEditorStateChange={handleEditorStateChange}
                onSaveComms={onSaveComms}
              />
            }
          />
        ) : (
          <OtherForm
            activeTabProfile={activeTabProfile}
        
            type={typeProfile}
            currentTitle={currentTitle}
            comp={
              <CommsForm
                type={type}
                extraType={extraType}
                trafficColors={trafficColors}
                setShowLights={setShowLights}
                showLights={showLights}
                setTrafficColors={setTrafficColors}
                titleHub={titleHub}
                dayBookDetails={dayBookDetails}
                setDayBookDetails={setDayBookDetails}
                editorState={editorState}
                handleEditorStateChange={handleEditorStateChange}
                onSaveComms={onSaveComms}
              />
            }
          />
        );

      default:
        return <></>;
    }
  };
  const renderForm3 = () => {
    switch (getProfileTypeSticky || getButtonTypeSticky) {
      case "Support":
        return (
          <>
            <SupportForm sendMarkerRef={(data: any) => sendMarkerRef(data)} />
          </>
        );

      default:
        return <></>;
    }
  };
  return (
    <div
      className={`sticky_note_home_container ${
        type === "stickyClinical" || type === "Notes"
          ? "sticky_note_form_clinic"
          :type == "stickyHub" ? "sticky_note_form_in_gbc" : "sticky_note_form_in"
      }  `}
    >
      {renderForm()}
      {renderForm2()}
      {renderForm3()}
    </div>
  );
};

export default MainFormSticky;
