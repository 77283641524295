import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../Controller/redux/Hooks";
import {
  getBillingRuleDetailsById,
  updateBillingDetailsThunk,
  updateBillingRuleSet,
} from "../../../../../../Controller/redux/features/RefDataFeature/BillingRuleFeature/billingruleSlice";
import { Spinner } from "@chakra-ui/react";
import CommonUpperBox from "../../../../../components/UpperBox/CommonHeader";
import { getInitials } from "../../../../../../Model/utils/Helper/getInitials";
import CommonHeader from "../../../../../components/CommonHeader/CommonHeader";
// import TimePicker from "../../../../../components/Time/Time";
import ExpandDown from "../../../../../../Model/Assets/SVG/Common/ExpandDown";
import ExpandRight from "../../../../../../Model/Assets/SVG/Common/ExpandRight";
import PlusSVG from "../../../../People/ClientPage/Components/UniqueClientScreen/Components/FinanceTab/Data/PlusSVG";
// import { PlusSvg } from "../../../../People/ClientPage/Components/UniqueClientScreen/Components/ClinicalTab/Data/SVGs";
import { toast } from "react-toastify";
import ReactDatePicker from "react-datepicker";
import { formatDateForAPI } from "../../../../../../Model/utils/Helper/formatDate";
import MultiSelectDropdown from "../MultiSelectDropdown";
import _ from "lodash";
import CircularNoteTrigger from "../../../../../components/CircularNoteTrigger/CircularNoteTrigger";
import { setunsavedChanges } from "../../../../../../Controller/redux/features/AuthFeature/authSlice";
import DeleteSvg from "../../../../../../Model/Assets/SVG/Common/DeleteSvg";
import { api } from "../../../../../../Controller/services/api";
import { itemArrBusiness } from "../../../../People/ClientPage/Components/UniqueClientScreen/Data/WidgetDataArr";
import { convertTime } from "../../../../../../Model/utils/Helper/formatTime";
import TimePicker from "../../../../../components/Time/Time";
import CustomDatePicker from "../../../../../components/DatePicker/CustomDatePicker";
import CommonQuickMenu from "../../../../../components/CommonQuickMenu/CommonQuickMenu";
const BillingRuleScreen = () => {
  const id: any = useParams()?.id;
  const [clientId, setclientId] = useState<any>("");
  const [showNew, setShowNew] = useState<boolean>(false);
  const { user } = useAppSelector((state) => state?.common);

  useEffect(() => {
    if (id) {
      let realId = window?.atob(id);
      setclientId(realId);
    }
  }, [id]);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (clientId) {
      dispatch(getBillingRuleDetailsById(clientId));
    }
  }, [clientId]);

  // // console.log(clientId, "clientId");

  const { UniqueBillingRuleDetails, loading } = useAppSelector(
    (state) => state.billingRule
  );
  const isAdmin = user?.tenant_id === UniqueBillingRuleDetails?.tenant_id;
  const [details, setDetails] = useState<any>({
    billing_rule_set_id: "",
    set_name: "",
    associated_funding_type: "",
    associated_package_type: "",
    tax_rate: "",
    billing_rules: [
      {
        id: "",
        name: "",
        day_restrictions: "",
        time_range_start_after: "",
        time_range_start_before: "",
        time_range_finish_after: "",
        time_range_finish_before: "",
        any_part_of_appointment_is_after: "",
        any_part_of_appointment_is_before: "",
        valid_from: "",
        valid_to: "",
        rate: "",
        rate_type: "",
        start_and: 1,
        start_or: 0,
        finish_and: 0,
        finish_or: 1,
        public_holidays: 0,
      },
    ],
  });

  // const [billingRules, setBillingRules] = useState({
  //   id: "",
  //   name: "",
  //   start_and: 1,
  //   start_or: 0,
  //   finish_and: 0,
  //   finish_or: 1,
  //   day_restrictions: "",
  //   time_range_start_after: "",
  //   time_range_start_before: "",
  //   time_range_finish_after: "",
  //   time_range_finish_before: "",
  //   any_part_of_appointment_is_after: "",
  //   any_part_of_appointment_is_before: "",
  //   valid_from: "",
  //   valid_to: "",
  //   rate: "",
  //   rate_type: "",
  //   public_holidays: "",
  // });
  const [billingRules, setBillingRules] = useState<any>([]);

  // const [showDetails, setShowDetails] = useState<boolean>(false);

  useEffect(() => {
    if (UniqueBillingRuleDetails?.id) {
      setDetails({
        billing_rule_set_id: UniqueBillingRuleDetails.id,
        set_name: UniqueBillingRuleDetails.set_name,
        associated_funding_type:
          UniqueBillingRuleDetails.associated_funding_type,
        associated_package_type:
          UniqueBillingRuleDetails.associated_package_type,
        tax_rate: UniqueBillingRuleDetails.tax_rate,
        billing_rules: UniqueBillingRuleDetails.billing_rules,
      });
      setBillingRules(UniqueBillingRuleDetails.billing_rules);
    }
  }, [UniqueBillingRuleDetails]);

  // const [buttonTab, setbuttonTab] = useState({
  //   Details: true,
  // });

  const handleAddNewRule = () => {
    const newRule = {
      id: "",
      name: "",
      day_restrictions: "",
      time_range_start_after: "",
      time_range_start_before: "",
      time_range_finish_after: "",
      time_range_finish_before: "",
      any_part_of_appointment_is_after: "",
      any_part_of_appointment_is_before: "",
      valid_from: "",
      valid_to: "",
      rate: "",
      rate_type: "",
      start_and: 1,
      start_or: 0,
      finish_and: 0,
      finish_or: 1,
      public_holidays: "",
    };
    setBillingRules([...billingRules, newRule]);
  };

  const handleRuleChange = (index: number, key: string, value: any) => {
    setBillingRules((currentRules: any) =>
      currentRules.map((rule: any, i: number) => {
        if (i === index && rule[key] !== value) {
          setHasUnsavedChanges(true);
          return { ...rule, [key]: value };
        } else {
          return rule;
        }
      })
    );
  };

  const handleSave = async (index: number) => {
    setHasUnsavedChanges(false);
    const ruleToUpdate = billingRules[index];
    const formattedRule = {
      ...details,
      billing_rules: [
        {
          ...ruleToUpdate,
          valid_from: ruleToUpdate.valid_from
            ? formatDateForAPI(ruleToUpdate.valid_from)
            : "",
          valid_to: ruleToUpdate.valid_to
            ? formatDateForAPI(ruleToUpdate.valid_to)
            : "",
        },
      ],
    };

    try {
      const res = await dispatch(updateBillingDetailsThunk(formattedRule));
      if (res.payload.status === "success") {
        toast.success(`Billing Rule updated successfully!`, {
          progressStyle: { backgroundColor: dynamicColor },
          icon: (
            <span
              style={{
                marginRight: "8px",
                borderRadius: "50%",
                width: "7rem",
                height: "35px",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: dynamicColor,
                backgroundColor: "white",
                fontSize: "1.5rem",
                border: `3px solid ${dynamicColor}`,
              }}
            >
              ✓
            </span>
          ),
        });
      } else {
        toast.error("An Error Occurred!");
      }
    } catch (error) {
      console.error(error);
      toast.error("An Error Occurred!");
    }

    dispatch(getBillingRuleDetailsById(clientId));
  };

  const dynamicColor = useAppSelector((state) => state?.common?.dynamicColor);

  const [openIndexes, setOpenIndexes] = useState<any>({});

  const [selectedIndex, setselectedIndex] = useState<any>(-1);

  const toggleItem = (index: number) => {
    setOpenIndexes((prev: any) => ({ ...prev, [index]: !prev[index] }));
    setselectedIndex(index);
  };
  // // console.log(selectedIndex,'selectedIndex')
  const [IsThereAnyChanges, setIsThereAnyChanges] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  const onChangeDetails = (e: any) => {
    setHasUnsavedChanges(true);

    const { name, value } = e?.target;
    setDetails({ ...details, [name]: value });
    // !IsThereAnyChanges && setHasUnsavedChanges(true);
  };



  const handleDelete = async (id: number) => {
    await api.post("/billing_rule_sets/delete_billing_rule", {
      billing_rule_id: id,
    });
    dispatch(getBillingRuleDetailsById(clientId));
    toast.success("Billing Rule Deleted Successfully", {
      progressStyle: { backgroundColor: dynamicColor },
      icon: (
        <span
          style={{
            marginRight: "8px",
            borderRadius: "50%",
            width: "7rem",
            height: "35px",
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: dynamicColor,
            backgroundColor: "white",
            fontSize: "1.5rem",
            border: `3px solid ${dynamicColor}`,
          }}
        >
          ✓
        </span>
      ),
    });
  };

  const timePickerRef = useRef();

  const [istimepickeropen, setistimepickeropen] = useState(false);

  const handleClickOutside = (event: any) => {
    // @ts-ignore
    if (
      timePickerRef.current &&
      // @ts-ignore
      !timePickerRef.current.contains(event.target)
    ) {
      setistimepickeropen(false);
    }
  };

  useEffect(() => {
    window.addEventListener("click", handleClickOutside);
    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);
  const handleUpdateBillingRule = async () => {
    if (!IsThereAnyChanges) {
      return;
    }
    setIsThereAnyChanges(false);
    try {
      // // console.log(details);
      const response = await dispatch(
        updateBillingRuleSet({
          ...details,
        })
      );
      if (response?.payload?.status == "success") {
        toast.success(`Bulling Rule updated successfully!`, {
          style: {},
          progressStyle: { backgroundColor: dynamicColor },
          icon: (
            <span
              style={{
                marginRight: "8px",
                borderRadius: "50%",
                width: "7rem",
                height: "35px",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: dynamicColor,
                backgroundColor: "white",
                fontSize: "1.5rem",
                border: `3px solid ${dynamicColor}`,
              }}
            >
              ✓
            </span>
          ),
        });
        dispatch(getBillingRuleDetailsById(clientId));
      }
    } catch (error: any) {}
  };

  const debouncedApiCall = _.debounce(handleUpdateBillingRule, 300);

  useEffect(() => {
    const handleClick = () => {
      debouncedApiCall();
    };

    document?.getElementById("saveBTN")?.addEventListener("click", handleClick);

    return () => {
      document
        ?.getElementById("saveBTN")
        ?.removeEventListener("click", handleClick);
    };
  }, [debouncedApiCall]);

  const [stageTwoToGoalsModal, setstageTwoToGoalsModal] = useState<any>(false);

  return (
    <div>
      {loading && <Spinner position={"absolute"} top={"50%"} left={"50%"} />}
      <CommonUpperBox
        imageSection={UniqueBillingRuleDetails ? true : false}
        title={`${UniqueBillingRuleDetails?.set_name || ""}`}
        onClickUserImage={() => {}}
        onClickPlus={() => {
          setstageTwoToGoalsModal(true);
        }}
        type={"listing"}
        currentUser={UniqueBillingRuleDetails}
        backgroundWithText={true}
        text={getInitials(UniqueBillingRuleDetails?.set_name || "")}
      />
      <div style={{ position: "relative" }}>
        {UniqueBillingRuleDetails?.id && (
          <CommonHeader
            mainType="billingRule"
            type="details"
            handleClick={() => {}}
            currentBilling={UniqueBillingRuleDetails}
            refData={true}
          />
        )}
        <div
          style={{
            position: "absolute",
            right: "15px",
            top: "58px",
          }}
        >
          <PlusSVG height="24" width="24" onClick={handleAddNewRule} />
        </div>
      </div>

      <div className="billing_set_details_section_wrapper">
        <div className="main-box-body relative">
          <div
            className={`content-wrapper `}
            // key={index}
          >
            <div className="wrapper-title">Name: </div>
            <input
              className="wrapper-info"
              onChange={onChangeDetails}
              name="set_name"
              value={details?.set_name}
              readOnly={!isAdmin}
              placeholder={"Please Enter Name "}
              style={{
                border: "none",
                outline: "none",
                height: "fit-content",
              }}
            />
            <div className="wrapper-line"></div>
          </div>
          <div
            className={`content-wrapper `}
            // key={index}
          >
            <div className="wrapper-title">Associated Funding Type: </div>
            {isAdmin && (
              <select
                value={details?.associated_funding_type}
                onChange={onChangeDetails}
                name="associated_funding_type"
                className="wrapper-info wrapper-info-select-dropdown"
                style={{
                  border: "none",
                  outline: "none",
                  height: "fit-content",
                  backgroundColor: "white",
                  // width: "256px",
                }}
              >
                <option value="">Please Select...</option>
                {["HCP", "NDIS", "Private"]?.map((elem: any, index: any) => (
                  <option key={index} value={elem}>
                    {elem}
                  </option>
                ))}
              </select>
            )}
            {!isAdmin && (
              <input
                className="wrapper-info"
                readOnly={true}
                value={details?.associated_funding_type}
                style={{
                  border: "none",
                  outline: "none",
                  height: "fit-content",
                }}
              />
            )}
            <div className="wrapper-line"></div>
          </div>
          <div
            className={`content-wrapper `}
            // key={index}
          >
            <div className="wrapper-title">Associated Package Type: </div>
            {isAdmin && (
              <select
                value={details?.associated_package_type}
                onChange={onChangeDetails}
                name="associated_package_type"
                className="wrapper-info wrapper-info-select-dropdown"
                style={{
                  border: "none",
                  outline: "none",
                  height: "fit-content",
                  backgroundColor: "white",
                  // width: "256px",
                }}
              >
                <option value="">Please Select...</option>
                {["HCP", "NDIS", "Private"]?.map((elem: any, index: any) => (
                  <option key={index} value={elem}>
                    {elem}
                  </option>
                ))}
              </select>
            )}
            {!isAdmin && (
              <input
                className="wrapper-info"
                readOnly={true}
                value={details?.associated_package_type}
                style={{
                  border: "none",
                  outline: "none",
                  height: "fit-content",
                }}
              />
            )}
            <div className="wrapper-line"></div>
          </div>
          <div
            className={`content-wrapper `}
            // key={index}
          >
            <div className="wrapper-title">Tax Rate: </div>
            {isAdmin && (
              <select
                value={details?.tax_rate}
                onChange={onChangeDetails}
                name="tax_rate"
                className="wrapper-info wrapper-info-select-dropdown"
                style={{
                  border: "none",
                  outline: "none",
                  height: "fit-content",
                  backgroundColor: "white",
                  // width: "256px",
                }}
              >
                <option value="">Please Select...</option>
                {["GST Free", "GST Exclusive", "GST Inclusive"]?.map(
                  (elem: any, index: any) => (
                    <option key={index} value={elem}>
                      {elem}
                    </option>
                  )
                )}
              </select>
            )}
            {!isAdmin && (
              <input
                className="wrapper-info"
                readOnly={true}
                value={details?.tax_rate}
                style={{
                  border: "none",
                  outline: "none",
                  height: "fit-content",
                }}
              />
            )}
            <div className="wrapper-line"></div>
          </div>
        </div>

        {IsThereAnyChanges && (
          <>
            <div
              className="save"
              style={{
                position: "absolute",
                marginRight: "200px",
                bottom: "10px",
                zIndex: 9,
              }}
            >
              <p>Would you like to save your changes?</p>
              <div className="btnDivision">
                <button
                  id="saveBTN"
                  style={{
                    backgroundColor: dynamicColor,
                  }}
                >
                  Save
                </button>
                <button onClick={() => setIsThereAnyChanges(false)}>
                  Cancel
                </button>
              </div>
            </div>
          </>
        )}
      </div>

      <div className="client-screen-personal-details-heading">
        <span className="client-screen-sub-heading-title">Billing Rules</span>
        <span className="client-screen-line"></span>
      </div>

      <div className="billing_rule_wrapper">
        {billingRules?.map((rule: any, index: number) => {
          return (
            <>
              <div key={index} style={{ position: "relative" }}>
                <div className="service_type_accordion">
                  <span className="service_title">{rule?.name}</span>
                  <div>
                    {openIndexes[index] ? (
                      <div
                        style={{
                          display: "flex",
                          gap: "15px",
                          marginLeft: "-100px",
                          cursor: "pointer",
                        }}
                      >
                        <ExpandDown onClick={() => toggleItem(index)} />
                        <DeleteSvg onClick={() => handleDelete(rule?.id)} />
                      </div>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          gap: "15px",
                          marginLeft: "-100px",
                          cursor: "pointer",
                        }}
                      >
                        <ExpandRight onClick={() => toggleItem(index)} />
                        <DeleteSvg onClick={() => handleDelete(rule?.id)} />
                      </div>
                    )}
                  </div>
                </div>

                {openIndexes[index] && (
                  <div
                    className="main-box-body relative"
                    style={{ padding: "10px 25px" }}
                  >
                    <div
                      className={`content-wrapper `}
                      // key={index}
                    >
                      <div className="wrapper-title">Name: </div>
                      <input
                        className="wrapper-info"
                        value={rule?.name}
                        readOnly={!isAdmin}
                        onChange={(e: any) =>
                          handleRuleChange(index, "name", e.target.value)
                        }
                        name="name"
                        // value={billingRules?.name}
                        placeholder={"Please Enter Name "}
                        style={{
                          border: "none",
                          outline: "none",
                          height: "fit-content",
                        }}
                      />
                      <div className="wrapper-line"></div>
                    </div>
                    <div
                      className={`content-wrapper `}
                      // key={index}
                    >
                      <div className="wrapper-title">Day Restrictions: </div>
                      <MultiSelectDropdown
                        options={(option: any) => {
                          return handleRuleChange(
                            index,
                            "day_restrictions",
                            option?.join(",")
                          );
                        }}
                        isAdmin={isAdmin}
                        value={rule?.day_restrictions}
                      />
                    </div>
                    {/* here */}
                    <div
                      className={`content-wrapper `}
                      // key={index}
                    >
                      <div className="wrapper-title">Valid From: </div>
                      <div id="service_type_time">
                        {isAdmin && (
                          // <ReactDatePicker
                          //   selected={
                          //     rule?.valid_from
                          //       ? new Date(rule?.valid_from)
                          //       : new Date()
                          //   }
                          //   onChange={(e: any) =>
                          //     handleRuleChange(index, "valid_from", e)
                          //   }
                          //   placeholderText="Select Date"
                          //   showYearDropdown
                          //   showMonthDropdown
                          //   dropdownMode="select"
                          //   dateFormat="EEE, dd MMMM yyyy"
                          // />
                          <CustomDatePicker
                            newDate={
                              rule?.valid_from
                                ? new Date(rule?.valid_from)
                                : new Date()
                            }
                            handleChange={(e: any) =>
                              handleRuleChange(index, "valid_from", e)
                            }
                          />
                        )}
                        {!isAdmin && (
                          <>
                            <input
                              className="wrapper-info"
                              readOnly={true}
                              value={rule?.valid_from}
                              style={{
                                border: "none",
                                outline: "none",
                                height: "fit-content",
                              }}
                            />
                          </>
                        )}
                      </div>
                      <div className="wrapper-line"></div>
                    </div>
                    <div
                      className={`content-wrapper `}
                      // key={index}
                    >
                      <div className="wrapper-title">Valid To: </div>
                      <div id="service_type_time">
                        {isAdmin && (
                          // <ReactDatePicker
                          //   selected={
                          //     rule?.valid_to
                          //       ? new Date(rule?.valid_to)
                          //       : new Date()
                          //   }
                          //   onChange={(e: any) =>
                          //     handleRuleChange(index, "valid_to", e)
                          //   }
                          //   placeholderText="Select Date"
                          //   showYearDropdown
                          //   showMonthDropdown
                          //   dateFormat="EEE, dd MMMM yyyy"
                          // />
                          <CustomDatePicker
                            newDate={
                              rule?.valid_to
                                ? new Date(rule?.valid_to)
                                : new Date()
                            }
                            handleChange={(e: any) =>
                              handleRuleChange(index, "valid_to", e)
                            }
                          />
                        )}
                        {!isAdmin && (
                          <>
                            <input
                              className="wrapper-info"
                              readOnly={true}
                              value={rule?.valid_to}
                              style={{
                                border: "none",
                                outline: "none",
                                height: "fit-content",
                              }}
                            />
                          </>
                        )}
                      </div>
                      <div className="wrapper-line"></div>
                    </div>

                    <div
                      className={`content-wrapper `}
                      // key={index}
                    >
                      <div className="wrapper-title ">Time Range Start: </div>
                      <div className="service_time_flex">
                        <div className="service_field">
                          <span className="field-title">After</span>
                          <div id="service_type_time">
                            {isAdmin && (
                              <>
                                <input
                                  type="time"
                                  // value={rule?.time_range_start_after?rule?.time_range_start_after:""}
                                  onChange={(e: any) =>
                                    handleRuleChange(
                                      index,
                                      "time_range_start_after",
                                      convertTime(e.target.value)
                                    )
                                  }
                                />
                              </>
                            )}
                            {!isAdmin && (
                              <input
                                className="wrapper-info"
                                readOnly={true}
                                value={rule?.time_range_start_after}
                                style={{
                                  border: "none",
                                  outline: "none",
                                  height: "fit-content",
                                }}
                              />
                            )}
                          </div>
                          <span className="field-border field-border-add-form"></span>
                        </div>
                        <div className="service_field">
                          <span className="field-title">Before </span>
                          <div id="service_type_time">
                            {isAdmin && (
                              <>
                                <input
                                  type="time"
                                  // value={rule?.time_range_start_before?rule?.time_range_start_before:""}
                                  onChange={(e: any) =>
                                    handleRuleChange(
                                      index,
                                      "time_range_start_before",
                                      convertTime(e.target.value)
                                    )
                                  }
                                />

                                {/* <TimePicker onChange={handleRuleChange(
                                      index,
                                      "time_range_start_before",
                                      convertTime(e.target.value)
                                  )}  setTime={()=>{}} time={""}/>*/}
                              </>
                            )}
                            {!isAdmin && (
                              <input
                                className="wrapper-info"
                                readOnly={true}
                                value={rule?.time_range_start_before}
                                style={{
                                  border: "none",
                                  outline: "none",
                                  height: "fit-content",
                                }}
                              />
                            )}
                          </div>

                          <span className="field-border field-border-add-form"></span>
                        </div>
                      </div>

                      <div className="wrapper-line"></div>
                      {isAdmin && (
                        <select
                          name="start_and"
                          id="start_and"
                          style={{
                            position: "absolute",
                            width: "60px",
                            left: "22%",
                            transform: "translateY(45%)",
                          }}
                          onChange={(e: any) =>
                            handleRuleChange(index, "start_and", e.target.value)
                          }
                          value={rule.start_and}
                        >
                          <option value="1">And</option>
                          <option value="0">Or</option>
                        </select>
                      )}
                      {!isAdmin && (
                        <input
                          className="wrapper-info"
                          readOnly={true}
                          value={"And"}
                          style={{
                            border: "none",
                            outline: "none",
                            height: "fit-content",
                          }}
                        />
                      )}
                    </div>

                    <div className={`content-wrapper `}>
                      <div className="wrapper-title ">Time Range Finish: </div>
                      <div className="service_time_flex">
                        <div className="service_field">
                          <span className="field-title">After </span>
                          <div id="service_type_time">
                            {isAdmin && (
                              <>
                                <input
                                  type="time"
                                  // value={rule?.time_range_finish_after?rule?.time_range_finish_after:""}
                                  onChange={(e: any) =>
                                    handleRuleChange(
                                      index,
                                      "time_range_finish_after",
                                      convertTime(e.target.value)
                                    )
                                  }
                                />
                              </>
                            )}
                            {!isAdmin && (
                              <input
                                className="wrapper-info"
                                readOnly={true}
                                value={rule?.time_range_finish_after}
                                style={{
                                  border: "none",
                                  outline: "none",
                                  height: "fit-content",
                                }}
                              />
                            )}
                          </div>

                          <span className="field-border field-border-add-form"></span>
                        </div>
                        <div className="service_field">
                          <span className="field-title">Before </span>
                          <div id="service_type_time">
                            {isAdmin && (
                              <>
                                <input
                                  type="time"
                                  // value={rule?.time_range_finish_before?rule?.time_range_finish_before:""}
                                  onChange={(e: any) =>
                                    handleRuleChange(
                                      index,
                                      "time_range_finish_before",
                                      convertTime(e.target.value)
                                    )
                                  }
                                />
                              </>
                            )}
                            {!isAdmin && (
                              <input
                                className="wrapper-info"
                                readOnly={true}
                                value={rule?.time_range_finish_before}
                                style={{
                                  border: "none",
                                  outline: "none",
                                  height: "fit-content",
                                }}
                              />
                            )}
                          </div>

                          <span className="field-border field-border-add-form"></span>
                        </div>
                      </div>

                      <div className="wrapper-line"></div>
                      {isAdmin && (
                        <select
                          name=""
                          id=""
                          style={{
                            position: "absolute",
                            width: "60px",
                            left: "48%",
                            transform: "translateY(45%)",
                          }}
                          onChange={(e: any) =>
                            handleRuleChange(
                              index,
                              "finish_and",
                              e.target.value
                            )
                          }
                          value={rule.finish_and}
                        >
                          <option value="1">And</option>
                          <option value="0">Or</option>
                        </select>
                      )}
                      {!isAdmin && (
                        <input
                          className="wrapper-info"
                          readOnly={true}
                          value={"And"}
                          style={{
                            border: "none",
                            outline: "none",
                            height: "fit-content",
                          }}
                        />
                      )}
                    </div>
                    <div
                      className={`content-wrapper `}
                      // key={index}
                    >
                      <div className="wrapper-title ">
                        Any Part of Appointment is:
                      </div>
                      <div className="service_time_flex">
                        <div className="service_field">
                          <span className="field-title">After </span>
                          <div id="service_type_appointment">
                            {isAdmin && (
                              <>
                                <input
                                  type="time"
                                  onChange={(e: any) =>
                                    handleRuleChange(
                                      index,
                                      "any_part_of_appointment_is_after",
                                      convertTime(e.target.value)
                                    )
                                  }
                                />
                              </>
                            )}
                            {!isAdmin && (
                              <input
                                className="wrapper-info"
                                readOnly={true}
                                value={rule?.any_part_of_appointment_is_after}
                                style={{
                                  border: "none",
                                  outline: "none",
                                  height: "fit-content",
                                }}
                              />
                            )}
                          </div>

                          <span className="field-border field-border-add-form"></span>
                        </div>
                        <div className="service_field">
                          <span className="field-title">Before </span>
                          <div id="service_type_time">
                            {isAdmin && (
                              <>
                                <input
                                  type="time"
                                  // value={rule?.any_part_of_appointment_is_before?rule?.any_part_of_appointment_is_before:""}
                                  onChange={(e: any) => {
                                    handleRuleChange(
                                      index,
                                      "any_part_of_appointment_is_before",
                                      convertTime(e.target.value)
                                    );
                                  }}
                                />
                              </>
                            )}
                            {!isAdmin && (
                              <input
                                className="wrapper-info"
                                readOnly={true}
                                value={rule?.any_part_of_appointment_is_before}
                                style={{
                                  border: "none",
                                  outline: "none",
                                  height: "fit-content",
                                }}
                              />
                            )}
                          </div>

                          <span className="field-border field-border-add-form"></span>
                        </div>
                      </div>

                      <div className="wrapper-line"></div>
                    </div>
                    <div
                      className={`content-wrapper `}
                      // key={index}
                    >
                      <div className="wrapper-title">Rate: </div>
                      <div className="service_type_rate_flex">
                        <input
                          className="wrapper-info"
                          value={rule?.rate}
                          readOnly={!isAdmin}
                          onChange={(e: any) =>
                            handleRuleChange(index, "rate", e.target.value)
                          }
                          name="tax_rate"
                          placeholder={"Enter Rate "}
                          style={{
                            border: "none",
                            outline: "none",
                            height: "fit-content",
                          }}
                        />

                        {isAdmin && (
                          <select
                            className="wrapper-info wrapper-info-select-dropdown"
                            value={rule?.rate_type}
                            onChange={(e: any) =>
                              handleRuleChange(
                                index,
                                "rate_type",
                                e.target.value
                              )
                            }
                            style={{
                              border: "none",
                              outline: "none",
                              height: "fit-content",
                              backgroundColor: "white",
                              // width: "256px",
                            }}
                          >
                            <option value="">Select Rate Type...</option>
                            {["Hourly", "Fixed"]?.map(
                              (elem: any, index: any) => (
                                <option key={index} value={elem}>
                                  {elem}
                                </option>
                              )
                            )}
                          </select>
                        )}
                        {!isAdmin && (
                          <input
                            className="wrapper-info"
                            readOnly={true}
                            value={rule?.rate_type}
                            style={{
                              border: "none",
                              outline: "none",
                              height: "fit-content",
                            }}
                          />
                        )}
                      </div>
                      <div className="wrapper-line"></div>
                    </div>
                    <div
                      className={`content-wrapper `}
                      // key={index}
                    >
                      <div className="wrapper-title">Public Holidays: </div>
                      {isAdmin && (
                        <select
                          className="wrapper-info wrapper-info-select-dropdown"
                          value={rule?.public_holidays}
                          onChange={(e: any) =>
                            handleRuleChange(
                              index,
                              "public_holidays",
                              e.target.value
                            )
                          }
                          style={{
                            border: "none",
                            outline: "none",
                            height: "fit-content",
                            backgroundColor: "white",
                            // width: "256px",
                          }}
                        >
                          <option value="">Please Select...</option>
                          {["Yes", "No"]?.map((elem: any, index: any) => (
                            <option key={index} value={elem === "Yes" ? 1 : 0}>
                              {elem}
                            </option>
                          ))}
                        </select>
                      )}
                      {!isAdmin && (
                        <input
                          className="wrapper-info"
                          readOnly={true}
                          value={rule?.public_holidays}
                          style={{
                            border: "none",
                            outline: "none",
                            height: "fit-content",
                          }}
                        />
                      )}
                      <div className="wrapper-line"></div>
                    </div>
                    {/* <button
                    onClick={() => handleSave(index)}
                    // Change Later
                    style={{
                      position: "absolute",
                      bottom: "10px",
                      right: "23px",
                      borderRadius: "4px",
                      color: "#fff",
                      marginLeft: "auto",
                      padding: "6px 30px",
                      backgroundColor: dynamicColor,
                    }}
                  >
                    Save
                  </button> */}
                  </div>
                )}
              </div>
            </>
          );
        })}
      </div>
      <CircularNoteTrigger
        type="stickyHub"
        title="Comms"
        isFinanceScreen={true}
      />
      {hasUnsavedChanges && (
        <>
          <div
            className="save"
            style={{
              position: "absolute",
              marginRight: "200px",
              bottom: "10px",
            }}
          >
            <p>Would you like to save your changes?</p>
            <div className="btnDivision">
              <button
                style={{
                  backgroundColor: dynamicColor,
                }}
                onClick={() => handleSave(selectedIndex)}
              >
                Save
              </button>
              <button
                style={{
                  fontFamily: "Helvetica",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "16px",
                  lineHeight: "18px",
                  backgroundColor: dynamicColor,
                  border: "none",
                  color: "#9296A2",
                }}
                onClick={() => setHasUnsavedChanges(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </>
      )}
      {stageTwoToGoalsModal && (
        <CommonQuickMenu
          setshowGoalsModal={(value: any) => {}}
          mainType={"listing"}
          setstageTwoToGoalsModal={setstageTwoToGoalsModal}
        />
      )}
    </div>
  );
};

export default BillingRuleScreen;
