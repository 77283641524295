export const statusMap: any = {
  1: { color: "#97D94A", backgroundColor: "#F8FFEF", label: "Active" },
  2: { color: "#FFBC10", backgroundColor: "#FEFFE5", label: "On Leave" },
  3: { color: "#FFBC10", backgroundColor: "#FEFFE5", label: "Hospital" },
  4: { color: "#FFBC10", backgroundColor: "#FEFFE5", label: "Waitlist" },
  5: { color: "#FFBC10", backgroundColor: "#FEFFE5", label: "Respite" },
  0: { color: "#EB5757", backgroundColor: "#FFF0F0", label: "Inactive" },
  6: { color: "#EB5757", backgroundColor: "#FFF0F0", label: "Deceased" },
  7: { color: "#FFBC10", backgroundColor: "#FEFFE5", label: "Probation" },
  8: { color: "#3498db", backgroundColor: "#f0f8ff", label: "Deposit" },
  9: { color: "#3498db", backgroundColor: "#f0f8ff", label: "Moved In" },
  10: { color: "#3498db", backgroundColor: "#f0f8ff", label: "For Future" },
  11: { color: "#FFBC10", backgroundColor: "#FEFFE5", label: "Make Client" },
  12: { color: "#97D94A", backgroundColor: "#F8FFEF", label: "Open" },
  13: { color: "#97D94A", backgroundColor: "#F8FFEF", label: "In Progress" },
  14: { color: "#EB5757", backgroundColor: "#FFF0F0", label: "Closed" },
  15: { color: "#EB5757", backgroundColor: "#FFF0F0", label: "Lost" },

  // 11: { color: "#97D94A", backgroundColor: "#f0f8ff", label: "Make Client" },
  default: { color: "#000", backgroundColor: "#f8ffef", label: "" },
};

//  color: "#FFBC10",
// bgCol: "#FEFFE5",

export const statusMapClient: any = {
  1: { backgroundColor: "#6AE24C", label: "Active" },
  2: { backgroundColor: "#FFFF5B", label: "On Leave" },
  3: { backgroundColor: "#FFFF5B", label: "Hospital" },
  4: { backgroundColor: "#FFFF5B", label: "Waitlist" },
  5: { backgroundColor: "#FFFF5B", label: "Respite" },
  0: { backgroundColor: "#f56969", label: "Inactive" },
  6: { backgroundColor: "#f56969", label: "Deceased" },
  7: { backgroundColor: "#FFFF5B", label: "Probation" },
  8: { backgroundColor: "#f0f8ff", label: "Deposit" },
  9: { backgroundColor: "#f0f8ff", label: "Moved In" },
  10: { backgroundColor: "#f0f8ff", label: "For Future" },
  11: { color: "#FFBC10", backgroundColor: "#FEFFE5", label: "Make Client" },
  12: { color: "#97D94A", backgroundColor: "#F8FFEF", label: "Open" },
  13: { color: "#97D94A", backgroundColor: "#F8FFEF", label: "In Progress" },
  14: { color: "#EB5757", backgroundColor: "#FFF0F0", label: "Closed" },
  15: { color: "#EB5757", backgroundColor: "#FFF0F0", label: "Lost" },

  // 11: { color: "#97D94A", backgroundColor: "#f0f8ff", label: "Make Client" },
  default: { color: "#000", backgroundColor: "#f8ffef", label: "" },
};

// #f75959;

// export const itemsArrProspectsHomeCare = [
//   {
//     status: 12,
//     type: "Open",
//     color: "#97D94A",
//     bgCol: "#F8FFEF",
//   },
//   {
//     status: 13,
//     type: "In Progress",
//     color: "#FFBC10",
//     bgCol: "#FEFFE5",
//   },
//   {
//     status: 14,
//     type: "Closed",
//     color: "#EB5757",
//     bgCol: "#FFF0F0",
//   },
//   {
//     status: 15,
//     type: "Lost",
//     color: "#EB5757",
//     bgCol: "#FFF0F0",
//   },
//   {
//     status: 4,
//     type: "Wait List",
//     color: "#3498db",
//     bgCol: "#f0f8ff",
//   },

// ];
