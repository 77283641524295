import React, { useCallback, useRef, useState } from "react";
import debounce from "lodash/debounce";
import DropdownOptions from "../DropdownOptions";
import { Data, filterIcon } from "../TableDropDown2";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../Controller/redux/Hooks";
import {
  resetSearchValuePayRule,
  resetSelectedFilterItemsPayRule,
  resetSelectedItemIndexFilterPayRule,
  resetSelectedItemIndexSortPayRule,
  resetSortByPayRuleR,
  setPayRuleFilterItems,
  setSearchValuePayRule,
  setSelectedDropdownItemPayRule,
  setSelectedFilterItemsPayRule,
  setSelectedItemIndexFilterPayRule,
  setSelectedItemIndexSortPayRule,
  setSortByPayRuleR,
} from "../../../../../Controller/redux/features/RefDataFeature/PayRuleFeature/payruleSlice";
import { setselectedTypeOfManipulation } from "../../../../../Controller/redux/features/CommonFeature/documentSlice";
import { setisDropdownOpen } from "../../../../../Controller/redux/features/ProspectsFeature/prospectsSlice";
import { Checkbox } from "@chakra-ui/react";
import {
  resetselectedItemIndexAssociations,
  setselectedItemIndexAssociationsSort,
} from "../../../../../Controller/redux/features/CommonFeature/associationSlice";

const PayRuleFilter = () => {
  const dispatch = useAppDispatch();
  const inputRefBilling = useRef<HTMLInputElement | any>(null);
  const {
    sortByPayRuleR,
    payRulePage,
    getPayRuleName,
    getPayRuleAssociatedUser,
    getPayRuleRate,
    selectedDropdownItemPayRule,
    selectedItemIndexFilterPayRule,
    selectedItemIndexSortPayRule,
  } = useAppSelector((state) => state?.payRule);
  const filterIconCo̥lor = useAppSelector(
    (state) => state?.common?.filterIconColor
  );
  const expenseName = getPayRuleName
    ?.map((data: any) => data?.name)
    ?.filter((elem: any) => elem !== null);

  const expenseAssociated = getPayRuleAssociatedUser
    ?.map((data: any) => data?.associated_user_type)
    ?.filter((elem: any) => elem !== null);

  const expenseRate = getPayRuleRate
    ?.map((data: any) => data?.rate)
    ?.filter((elem: any) => elem !== null);

  const [sortByBilling, setSortByBilling] = useState(sortByPayRuleR);
  const [expenseTableDataObject, setBillingTableDataObject] = useState<any>({
    currentActive: selectedDropdownItemPayRule,
    selectedItemIndex: selectedItemIndexFilterPayRule,
    selectedSortIndex: selectedItemIndexSortPayRule,
  });
  const [inputValueTextPayRule, setinputValueTextPayRule] = useState("");

  //////////////////// START //////////////

  const selectedItemIndexAssociationsSort = useAppSelector(
    (state) => state?.associations?.selectedItemIndexAssociationsSort
  );
  const selectedItemIndexAssociations = useAppSelector(
    (state) => state?.associations?.selectedItemIndexAssociations
  );

  const [selectedSortIndex, setselectedSortIndex] = useState<any>(
    selectedItemIndexAssociationsSort
  );
  const [selectedItemIndex, setSelectedItemIndex] = useState(
    selectedItemIndexAssociations
  );
  const handleFoucsInSearchOfAssociations = () => {
    setSelectedItemIndex(null);
    setselectedSortIndex(null);
  };

  const debounceInputChangeBusiness = useCallback(
    debounce((newSearchText: string) => {
      dispatch(
        setPayRuleFilterItems({
          filterColumn: "",
          filterValue: "",
          searchVal: newSearchText,
          sortColumn: "",
          sortType: "",
          //   status: newSearchText === "" || newSearchText === null ? "1" : "",
          payRulePage,
        })
      );

      dispatch(setSearchValuePayRule(newSearchText));
    }, 300),
    []
  );

  const handleInputChangeBusiness = (e: string | any) => {
    const newInputValue = e.target.value;
    setinputValueTextPayRule(newInputValue);

    debounceInputChangeBusiness(newInputValue);
  };
  const handleClearSearchBusiness = () => {
    setinputValueTextPayRule("");
    dispatch(resetSearchValuePayRule());
    dispatch(
      setPayRuleFilterItems({
        filterColumn: "",
        filterValue: "",
        searchVal: "",
        sortColumn: "",
        sortType: "",
        // status: "1",
        payRulePage,
      })
    );
  };

  const selectedTypeOFManipulation = useAppSelector(
    (state) => state?.document?.selectedTypeOfManipulation
  );
  const seleectedColumnForSort = useAppSelector(
    (state) => state?.document?.selectedColumnForSort
  );
  const initialState: any = {
    selectedFilterDropDownOne: selectedTypeOFManipulation,
    selectedSortDropDownOne: selectedTypeOFManipulation,
  };
  const [{ selectedFilterDropDownOne, selectedSortDropDownOne }, setState] =
    useState(initialState);
  const handleItemClickDropdownOneDocuments = (
    id: any,
    icon: any,
    title: any,
    props: any
  ) => {
    if (props === "payRule") {
      if (title.includes("filter")) {
        dispatch(resetSearchValuePayRule());
        dispatch(resetSelectedItemIndexSortPayRule());
        dispatch(resetSortByPayRuleR());
        setBillingTableDataObject((prevState: any) => ({
          ...prevState,
          selectedSortIndex: null,
        }));

        dispatch(
          setPayRuleFilterItems({
            filterColumn: "",
            filterValue: "",
            searchVal: "",
            sortColumn: "",
            sortType: "",
            // status: "1",
            payRulePage,
          })
        );
      } else if (title.includes("sort")) {
        dispatch(resetSearchValuePayRule());
        dispatch(resetSelectedItemIndexFilterPayRule());
        dispatch(resetSelectedFilterItemsPayRule());
        setBillingTableDataObject((prevState: any) => ({
          ...prevState,
          selectedItemIndex: null,
        }));

        dispatch(
          setPayRuleFilterItems({
            filterColumn: "",
            filterValue: "",
            searchVal: "",
            sortColumn: "",
            sortType: "",
            // status: "1",
            payRulePage,
          })
        );
      } else {
        dispatch(resetSelectedItemIndexFilterPayRule());
        dispatch(resetSelectedItemIndexSortPayRule());
        dispatch(resetSortByPayRuleR());
        dispatch(resetSelectedFilterItemsPayRule());

        dispatch(
          setPayRuleFilterItems({
            filterColumn: "",
            filterValue: "",
            searchVal: "",
            sortColumn: "",
            sortType: "",
            status: "",
            payRulePage,
          })
        );
      }
      setBillingTableDataObject((prevState: any) => ({
        ...prevState,
        currentActive: title,
      }));
      dispatch(setSelectedDropdownItemPayRule(title));
    }
    setState((prevData: any) => ({
      ...prevData,
      selectedFilterDropDownOne:
        id === 1 ? { icon, title, id, ...props } : null,
      selectedSortDropDownOne: id === 3 ? { icon, title, id, ...props } : null,
      selectedSearchItem: id === 2,
      selectedFilterDropDownTwo: null,
      selectedSortDropDownTwo: null,
      selectedItem: { icon, title, id, ...props },
    }));
    dispatch(setselectedTypeOfManipulation({ icon, title, id, ...props }));
  };
  const [selectedTitles, setSelectedTitles] = useState<string[] | any[]>([]);
  const handleClickAssociationsColumn = (index: number | any, props: any) => {
    if (props === "payRule") {
      setBillingTableDataObject((prevState: any) => ({
        ...prevState,
        selectedItemIndex: index,
      }));
      dispatch(setSelectedItemIndexFilterPayRule(index));
      setSelectedTitles([]);
    } else {
      setSelectedItemIndex(index);
      setselectedSortIndex(null);
      dispatch(setisDropdownOpen(true));
      setSelectedTitles([]);
    }
  };
  const handleCheckboxChangeBilling = (elem: any, type: any, index: any) => {
    let targetType;
    switch (true) {
      case type.includes("name"):
        targetType = "name";
        break;
      case type.includes("associated_user_type"):
        targetType = "associated_user_type";
        break;
      case type.includes("rate"):
        targetType = "rate";
        break;
    }
    const updatedSelectedTitles = selectedTitles?.includes(elem)
      ? selectedTitles.filter((t) => t !== elem)
      : [...selectedTitles, elem];

    setSelectedTitles(updatedSelectedTitles);
    dispatch(setSelectedFilterItemsPayRule(updatedSelectedTitles));
    handleChangeOfDropdownBilling(updatedSelectedTitles, targetType);
  };
  const handleChangeOfDropdownBilling = (names: any, type: any) => {
    let filterColumn;

    switch (type) {
      case "name":
        filterColumn = "name";
        break;
      case "associated_user_type":
        filterColumn = "associated_user_type";
        break;
      case "rate":
        filterColumn = "rate";
        break;
      // default:
      //   const statusMap: any = {
      //     Inactive: 0,
      //     Active: 1,
      //   };
      //   const numericalValues = names.map((name: any) => statusMap[name]);
      //   filterColumn = "status";
      //   names = numericalValues;
    }

    let filterValue;

    filterValue = names.join(",");

    if (names.length !== 0) {
      dispatch(
        setPayRuleFilterItems({
          filterColumn,
          filterValue,
          searchVal: "",
          sortColumn: "",
          sortType: "",
          // status: "",

          payRulePage,
        })
      );
    } else {
      dispatch(
        setPayRuleFilterItems({
          filterColumn,
          filterValue,
          searchVal: "",
          sortColumn: "",
          sortType: "",
          // status: "1",
          payRulePage,
        })
      );
    }
  };
  const handleOpenSortOptionsOnBasisOfIndexOfColumn = (
    index: number | any,
    props: any
  ) => {
    if (props === "payRule") {
      setBillingTableDataObject((prevState: any) => ({
        ...prevState,
        selectedSortIndex: index,
      }));
      dispatch(setSelectedItemIndexSortPayRule(index));
    } else {
      setselectedSortIndex(index);
      dispatch(setselectedItemIndexAssociationsSort(index));
      dispatch(resetselectedItemIndexAssociations(""));
      setSelectedItemIndex(null);
    }
  };
  const handleSortChangeBilling = (val: string, selectedSortIndex: number) => {
    const capturedVal = val === "newest on top" ? "asc" : "desc";
    setSortByBilling(val);
    dispatch(setSortByPayRuleR(val));

    let sortColumn = "";
    switch (expenseTableDataObject.selectedSortIndex) {
      case 0:
        sortColumn = "name";
        break;
      case 1:
        sortColumn = "associated_user_type";
        break;
      default:
        sortColumn = "rate";
        break;
    }

    const actionPayload = {
      filterColumn: "",
      filterValue: "",
      searchVal: "",
      sortColumn,
      sortType: capturedVal,
      // status: "1",

      payRulePage,
    };
    dispatch(setPayRuleFilterItems(actionPayload));
  };
  /////////////////////////// END///////////////////////

  return (
    <div className="dropdown-wrapper-main">
      <div className="associations-table-dropdown">
        <div className="dropdown-2-box">
          <div className="dropdown-2">
            <DropdownOptions
              selectedItem={expenseTableDataObject?.currentActive}
              data={Data}
              ref={inputRefBilling}
              onFocus={handleFoucsInSearchOfAssociations}
              inputTextValue={inputValueTextPayRule}
              onChange={handleInputChangeBusiness}
              clearSearch={handleClearSearchBusiness}
              onClick={handleItemClickDropdownOneDocuments}
              selectedType={"payRule"}
            />
          </div>

          {/* ///////////////////////////////////////////////////// FILTER OPTIONS ////////////////////////////////////////////////////////// */}
          {!!selectedFilterDropDownOne &&
          selectedFilterDropDownOne?.id === 1 ? (
            <div className="dropdown-2-box-sub">
              <div className="sub-dropdown-2">
                {["Name", "User Type", "Rate"]?.map((elem, index) => (
                  <div
                    key={index}
                    className={`dropdown-2-item ${
                      expenseTableDataObject.selectedItemIndex === index
                        ? "dropdown-2-item-selected"
                        : ""
                    }`}
                    onClick={() =>
                      handleClickAssociationsColumn(index, "payRule")
                    }
                  >
                    <p>{elem}</p>
                  </div>
                ))}
              </div>
            </div>
          ) : null}

          {/* ////////////////////////////////////////////////    FILTERING    ////////////////////////////////////////////////////////////////////// */}
          {expenseTableDataObject.selectedItemIndex === 0 &&
            selectedFilterDropDownOne?.id === 1 && (
              <div className="dropdown-2-box-sub-2">
                <div className="sub-dropdown-2">
                  <Checkbox
                    isChecked={selectedTitles?.length === expenseName?.length}
                    className="dropdown-2-item dropdown-2-item-no-hover-effect w-full"
                    onChange={() => {
                      if (selectedTitles?.length === expenseName?.length) {
                        setSelectedTitles([]);
                      } else {
                        setSelectedTitles(expenseName);

                        dispatch(
                          setPayRuleFilterItems({
                            filterColumn: "",
                            filterValue: "",
                            searchVal: "",
                            sortColumn: "",
                            sortType: "",
                            // status: "",
                            payRulePage,
                          })
                        );
                      }
                    }}
                  >
                    (Select All)
                  </Checkbox>

                  {expenseName?.map((elem: any, index: any) => (
                    <div
                      className="dropdown-2-item dropdown-2-item-no-hover-effect"
                      // note here key forces re render
                      key={elem?.id}
                    >
                      <Checkbox
                        isChecked={selectedTitles.includes(elem)}
                        onChange={() =>
                          handleCheckboxChangeBilling(elem, "name", index)
                        }
                      >
                        {elem}
                      </Checkbox>
                    </div>
                  ))}
                </div>
              </div>
            )}
          {expenseTableDataObject.selectedItemIndex === 1 &&
            selectedFilterDropDownOne?.id === 1 && (
              <div className="dropdown-2-box-sub-2">
                <div className="sub-dropdown-2">
                  <Checkbox
                    isChecked={
                      selectedTitles.length === expenseAssociated.length
                    }
                    className="dropdown-2-item dropdown-2-item-no-hover-effect w-full"
                    onChange={() => {
                      if (selectedTitles.length === expenseAssociated.length) {
                        setSelectedTitles([]);
                      } else {
                        setSelectedTitles(expenseAssociated);

                        dispatch(
                          setPayRuleFilterItems({
                            filterColumn: "",
                            filterValue: "",
                            searchVal: "",
                            sortColumn: "",
                            sortType: "",
                            // status: "",
                            payRulePage,
                          })
                        );
                      }
                    }}
                  >
                    (Select All)
                  </Checkbox>

                  {expenseAssociated?.map((elem: any, index: any) => (
                    <div
                      className="dropdown-2-item dropdown-2-item-no-hover-effect"
                      key={index}
                    >
                      {elem !== null && (
                        <Checkbox
                          onChange={() =>
                            handleCheckboxChangeBilling(
                              elem,
                              "associated_user_type",
                              index
                            )
                          }
                          isChecked={selectedTitles.includes(elem)}
                        >
                          {elem}
                        </Checkbox>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            )}
          {expenseTableDataObject.selectedItemIndex === 2 &&
            selectedFilterDropDownOne?.id === 1 && (
              <div className="dropdown-2-box-sub-2">
                <div className="sub-dropdown-2">
                  <Checkbox
                    isChecked={selectedTitles.length === expenseRate.length}
                    className="dropdown-2-item dropdown-2-item-no-hover-effect w-full"
                    onChange={() => {
                      if (selectedTitles.length === expenseRate.length) {
                        setSelectedTitles([]);
                      } else {
                        setSelectedTitles(expenseRate);

                        dispatch(
                          setPayRuleFilterItems({
                            filterColumn: "",
                            filterValue: "",
                            searchVal: "",
                            sortColumn: "",
                            sortType: "",
                            // status: "",
                            payRulePage,
                          })
                        );
                      }
                    }}
                  >
                    (Select All)
                  </Checkbox>

                  {expenseRate?.map((elem: any, index: any) => (
                    <div
                      className="dropdown-2-item dropdown-2-item-no-hover-effect"
                      key={index}
                    >
                      {elem !== null && (
                        <Checkbox
                          isChecked={selectedTitles?.includes(elem)}
                          onChange={() =>
                            handleCheckboxChangeBilling(elem, "rate", index)
                          }
                        >
                          {elem}
                        </Checkbox>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            )}
          {/*  /////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
          {/*  selectedSortDropDownOne?.id === 3 */}
          {/* SORT OPTIONS */}

          {!!selectedSortDropDownOne &&
          expenseTableDataObject.currentActive === "sort" ? (
            <div className="dropdown-2-box-sub">
              <div className="sub-dropdown-2">
                {["Name", "User Type", "Rate"]?.map(
                  (elem: any, index: number) => (
                    <div
                      key={index}
                      className={`dropdown-2-item ${
                        expenseTableDataObject.selectedSortIndex === index
                          ? "dropdown-2-item-selected"
                          : ""
                      }`}
                      onClick={() =>
                        handleOpenSortOptionsOnBasisOfIndexOfColumn(
                          index,
                          "payRule"
                        )
                      }
                    >
                      <p>{elem}</p>
                    </div>
                  )
                )}
              </div>
            </div>
          ) : null}

          {/* SORT OPTIONS ITEMS */}

          {expenseTableDataObject.selectedSortIndex === 0 &&
            selectedSortDropDownOne?.id === 3 && (
              <div className="dropdown-2-box-sub-2">
                <div className="sub-dropdown-2">
                  {["newest on top", "oldest on top"].map((title, index) => {
                    return (
                      <div
                        className={`dropdown-2-item capitalize hover:text-white  ${
                          title === sortByBilling &&
                          "dropdown-2-item-selected text-white"
                        }`}
                        onClick={() =>
                          handleSortChangeBilling(title, selectedSortIndex)
                        }
                      >
                        <span
                          className="font-normal"
                          style={{
                            textDecoration: "none",
                            textTransform: "none",
                          }}
                        >
                          {index === 0 ? "A to Z" : "Z to A"}
                        </span>
                        <div className="relative left-[2px] flex">
                          {index === 0 ? (
                            <svg
                              width="20px"
                              height="20px"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M12 4V20M12 20L8 16M12 20L16 16"
                                stroke={filterIconCo̥lor}
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          ) : (
                            <svg
                              width="20px"
                              height="20px"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M12 4V20M12 4L8 8M12 4L16 8"
                                stroke={filterIconCo̥lor}
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}

          {expenseTableDataObject.selectedSortIndex === 1 &&
            selectedSortDropDownOne?.id === 3 && (
              <div className="dropdown-2-box-sub-2">
                <div className="sub-dropdown-2">
                  {["newest on top", "oldest on top"].map((title, index) => {
                    return (
                      <div
                        className={`dropdown-2-item capitalize hover:text-white  ${
                          title === sortByBilling &&
                          "dropdown-2-item-selected text-white"
                        }`}
                        onClick={() =>
                          handleSortChangeBilling(
                            title,

                            selectedSortIndex
                          )
                        }
                      >
                        <span
                          className="font-normal"
                          style={{
                            textDecoration: "none",
                            textTransform: "none",
                          }}
                        >
                          {index === 0 ? "A to Z" : "Z to A"}
                        </span>
                        <div className="relative left-[2px] flex">
                          {index === 0 ? (
                            <svg
                              width="20px"
                              height="20px"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M12 4V20M12 20L8 16M12 20L16 16"
                                stroke={filterIconCo̥lor}
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          ) : (
                            <svg
                              width="20px"
                              height="20px"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M12 4V20M12 4L8 8M12 4L16 8"
                                stroke={filterIconCo̥lor}
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}

          {expenseTableDataObject.selectedSortIndex === 2 &&
            selectedSortDropDownOne?.id === 3 && (
              <div className="dropdown-2-box-sub-2">
                <div className="sub-dropdown-2">
                  {["newest on top", "oldest on top"].map((title, index) => {
                    return (
                      <div
                        className={`dropdown-2-item capitalize hover:text-white  ${
                          title === sortByBilling &&
                          "dropdown-2-item-selected text-white"
                        }`}
                        onClick={() =>
                          handleSortChangeBilling(title, selectedSortIndex)
                        }
                      >
                        <span
                          className="font-normal"
                          style={{
                            textDecoration: "none",
                            textTransform: "none",
                          }}
                        >
                          {index === 0 ? "A to Z" : "Z to A"}
                        </span>
                        <div className="relative left-[2px] flex">
                          {index === 0 ? (
                            <svg
                              width="20px"
                              height="20px"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M12 4V20M12 20L8 16M12 20L16 16"
                                stroke={filterIconCo̥lor}
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          ) : (
                            <svg
                              width="20px"
                              height="20px"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M12 4V20M12 4L8 8M12 4L16 8"
                                stroke={filterIconCo̥lor}
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
        </div>
      </div>
    </div>
  );
};

export default PayRuleFilter;
