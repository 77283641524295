import React from "react";
import { Checkbox } from "@chakra-ui/react";
import {
  getDocuments,
  getDocumentsEntity,
} from "../../../../Controller/redux/features/CommonFeature/documentSlice";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";

const SelectedFilterOption = ({
  selectedOps,
  allOps,
  setselectedOps,
  dispatch,
  clientId,
  pathname,
  handleChangeCheckboxOption,
  currElem,
  type,
  handelChangeSelectAll,
}: any) => {
  const { id, pro_id }: any = useParams();
  const [providerId, setProviderId] = useState("");
  useEffect(() => {
    if (pro_id) {
      setProviderId(window?.atob(pro_id));
    }
  }, [pro_id]);

  return (
    <div className="dropdown-2-box-sub-2">
      <div className="sub-dropdown-2 font-normal">
        <div className="dropdown-2-item dropdown-2-item-no-hover-effect">
          <Checkbox
            isChecked={selectedOps?.length === allOps?.length}
            onChange={() => {
              if (selectedOps?.length === allOps?.length) {
                setselectedOps([]);
                handelChangeSelectAll([], currElem);
              } else {
                setselectedOps(allOps);
                if (type == "clients" || type == "notifications") {
                  handelChangeSelectAll &&
                    handelChangeSelectAll(allOps, currElem);
                }
                if (pathname) {
                  dispatch(getDocumentsEntity({ clientId, providerId }));
                } else {
                  dispatch(getDocuments({ clientId, providerId }));
                }
              }
            }}
          >
            (Select All)
          </Checkbox>
        </div>
        {allOps?.map((elem: any, key: number) => (
          <div
            key={key}
            className="dropdown-2-item dropdown-2-item-no-hover-effect"
          >
            <Checkbox
              isChecked={selectedOps?.includes(elem)}
              onChange={() => handleChangeCheckboxOption(elem, currElem)}
            >
              {elem}
            </Checkbox>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SelectedFilterOption;
