import React, { FormEvent, useEffect, useState, Fragment, useRef } from "react";
import img from "../../../Model/Assets/images/logo.png";
import img1 from "../../../Model/Assets/images/image-1.png";
import { api } from "../../../Controller/services/api";
import { useAppSelector } from "../../../Controller/redux/Hooks";

export function LogoLeft() {
  // const [data, setData] = useState<any>([]);
  const logo = useAppSelector((state) => state?.common?.fromGetDomainDetails?.logo);


  return (
    <Fragment>
      <div className="login-section-left">
        <div className="container-fluid">
          <div
            style={{
              display: "flex",
            }}
          >
            <div className="col-lg-12">
              <a href="">
                <img
                  src={logo}
                  alt="logo"
                  className="imf-fluid"
                  height={"10px"}
                  width={"250px"}
                  style={{
                    marginTop: "10px",
                    marginLeft: "10px",
                  }}
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
