import React from "react";
import { useEffect, useState } from "react";
import UploadSVG from "../../../pages/Daybook/Data/UploadSVG";
import { AttachmentModalCrossIcon, LinkSVG } from "../Data/SVGs";
import "../Styles/AttachmentsModal.css";
import {
  AllDocSvg,
  DocxSvg,
  PdfDocSvg,
} from "../../../../Model/Assets/SVG/Common/AllDocsSvg";
import { toast } from "react-toastify";
import { api } from "../../../../Controller/services/api";
import {
  getCommsDetailThunk,
  resetCommsDetailData,
  resetDetailCommsPage,
  updateCommsThunk,
} from "../../../../Controller/redux/features/CommsFeature/commsSlice";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../Controller/redux/Hooks";
import { linkFileThunk } from "../../../../Controller/redux/features/CommonFeature/commonSlice";
import { getDocuments } from "../../../../Controller/redux/features/CommonFeature/documentSlice";

const AttachmentsModal = ({
  setshowAttachmentModal = () => {},
  hideSaveButton,
  attachments,
  extraType,
  loggedInUserDetailsFromDaybook,
  currentElem,
  clientId,
  getDaybookCall = () => {},
  providerId,
  recieveArrayFromChild = () => {},
  triggerGetCommsAPICall = () => {},
  setState = () => {},
  setHideModal = () => {},
  isDaybook,
  isDocumentCreateState,
}: any) => {
  // console.log('setState', setState);
  const { commsIdAndType } = useAppSelector((state) => state?.comms);

  const dispatch = useAppDispatch();
  const [selectedTab, setSelectedTab] = useState("Upload Document");
  const [selectedFiles, setselectedFiles] = useState<any>([]);
  const [uploadedFiles, setUploadedFiles] = useState<any>();
  const [keysArray, setKeysArray] = useState<any>([]);

  const { dynamicColor, user } = useAppSelector((state) => state?.common);
  const userId = user?.id;

  useEffect(() => {
    setUploadedFiles(attachments ? attachments : []);
  }, [attachments]);
  // currElem removed here add later for docs

  // console.log('uploadedFiles', uploadedFiles);

  useEffect(() => {
    const extractedKeys = uploadedFiles?.map((elem: any) => elem?.key);
    const extractedNames = uploadedFiles?.map((elem: any) => elem?.file?.name);

    const newArray = extractedKeys?.map((key: any, index: number) => ({
      file_name: key,
      actual_file_name: extractedNames[index],
    }));
    // if(setFilesWhenClickedFromCommsBox){

    // }
    setKeysArray(newArray);
  }, [uploadedFiles]);

  let spreadObj = {
    ...commsIdAndType,
    link_id: currentElem?.linkUUID,
    comm_type: currentElem?.commType,
  };

  const handleDragOver = (e: any) => {
    e.preventDefault();
  };

  const handleGetFile = async (name: string | any) => {
    try {
      const response = await api.get(`files/get_file?file=${name}`);
      const fileUrl = response?.data?.data;
      window.open(fileUrl, "_blank");
    } catch (error) {
      console.error("Error fetching file:", error);
    }
  };

  const handleDelete = async (name: string | any) => {
    try {
      await api.post("/files/delete_file", { file: name });
      setUploadedFiles((prevState: [object] | any[]) => {
        return prevState.filter(
          (e: { [x: string]: any; file: { name: string | any } }) =>
            e?.key !== name
        );
      });
      let filteredData = uploadedFiles?.filter((res: any) =>
        res.file ? res?.key !== name : res?.file_name !== name
      );
      setUploadedFiles(filteredData);
      dispatch(
        getCommsDetailThunk({
          clientId: currentElem?.link_uid,
          type: currentElem?.comm_type,
          userId: clientId,
          page: 1,
        })
      );
    } catch (error) {
      console.error("Error Deleting File:", error);
      // toast.error("Failed To Delete File!");
    }
  };
  const handleFileSelection = async (event: any) => {
    const files = event.target.files;
    let newFiles = Array.from(files);
    let filesToUpload = [...selectedFiles, ...newFiles];

    const allowedTypes = [
      "application/pdf",
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.template",
    ];

    const invalidFiles = filesToUpload.filter(
      (file: any) => !allowedTypes.includes(file.type)
    );

    const totalFilesAfterUpload = filesToUpload.length + selectedFiles.length;

    if (totalFilesAfterUpload > 10) {
      toast.error("Maximum file limit exceeded. Please select up to 10 files!");
      return;
    }

    if (invalidFiles.length > 0) {
      toast.error(
        "Invalid file type(s). Only PDF, Docs, and Excel files are allowed!"
      );
      return;
    }

    if (filesToUpload.length + selectedFiles.length > 10) {
      toast.error("Maximum file limit exceeded. Please select up to 10 files!");
      return;
    }

    const maxSize = 20 * 1024 * 1024;

    if (filesToUpload.length > 10) {
      toast.error("Maximum file limit exceeded. Please select up to 10 files!");
      return;
    }

    for (let i = 0; i < filesToUpload.length; i++) {
      const file = filesToUpload[i];

      if (file.size > maxSize) {
        event.target.value = null;
        toast.error("File Size Can't Be More Than 20MB!");
        return;
      }
    }
    const uploadFiles = async (files: any) => {
      const allowedTypes = [
        "application/pdf",
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.template",
      ];

      const validFiles = files.filter((file: any) =>
        allowedTypes.includes(file.type)
      );
      const maxSize = 20 * 1024 * 1024;

      const fileToBase64 = (file: File): Promise<string> => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () => {
            const base64String = reader.result?.toString().split(",")[1];
            resolve(base64String || "");
          };
          reader.onerror = (error) => reject(error);
          reader.readAsDataURL(file);
        });
      };

      const filesData: any = [];

      for (let i = 0; i < validFiles.length; i++) {
        const file = validFiles[i];
        if (file.size > maxSize) {
          toast.error("File Size Can't Be More Than 20MB!");
          return;
        }

        try {
          const base64String = await fileToBase64(file);
          filesData.push({
            type: loggedInUserDetailsFromDaybook ? "daybooks" : "documents",
            extension: file.name.split(".").pop(),
            base64: base64String,
          });
        } catch (error) {
          console.error(`Error converting ${file.name} to base64:`, error);
        }
      }

      if (files.length + selectedFiles.length > 10) {
        toast.error(
          "Maximum file limit exceeded. Please select up to 10 files!"
        );
        return;
      }

      if (filesData.length > 10) {
        toast.error(
          "Maximum file limit exceeded. Please select up to 10 files!"
        );
        return;
      }
      try {
        // setLoading(true);

        const response = await api.post("/files/upload_files", {
          files: filesData,
        });

        // setLoading(false);
        const responseArray = response.data.data;

        // Combine selected files with their corresponding keys
        const updatedFiles = validFiles.map((file: File, index: number) => {
          return { file, key: responseArray[index] };
        });

        setUploadedFiles((prevUploadedFiles: any) => [
          ...prevUploadedFiles,
          ...updatedFiles,
        ]);
        setState((prev: any) => [...prev, ...updatedFiles]);
        recieveArrayFromChild(updatedFiles);

        if (setHideModal) {
          setHideModal(false);
        }
      } catch (error) {
      } finally {
        // setLoading(false);
      }
    };

    await uploadFiles(filesToUpload);
  };
  const handleDrop = async (e: any) => {
    e.preventDefault();
    // setDragging(false);

    let newFiles = Array.from(e.dataTransfer.files);
    let files = [...selectedFiles, ...newFiles];

    const allowedTypes = [
      "application/pdf",
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.template",
    ];

    const validFiles = files.filter((file: any) =>
      allowedTypes.includes(file.type)
    );
    const invalidFiles = files.filter(
      (file) => !allowedTypes.includes(file.type)
    );

    if (invalidFiles.length > 0) {
      toast.error(
        "Invalid file type(s). Only PDF, Docs, and Excel files are allowed!"
      );
      return;
    }

    const maxSize = 20 * 1024 * 1024;

    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      if (file.size > maxSize) {
        e.target.value = null;
        toast.error("File Size Can't Be More Than 20MB!");
        return;
      }
    }

    if (files.length + selectedFiles.length > 10) {
      toast.error("Maximum file limit exceeded. Please select up to 10 files!");
      return;
    }

    try {
      // setLoading(true);

      const fileToBase64 = (file: any) => {
        return new Promise<string>((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () => {
            const base64String = reader.result?.toString().split(",")[1];
            resolve(base64String || "");
          };
          reader.onerror = (error) => reject(error);
          reader.readAsDataURL(file);
        });
      };

      const filesData: any = [];

      for (let i = 0; i < files.length; i++) {
        const file: any = files[i];
        try {
          const base64String = await fileToBase64(file);
          filesData.push({
            type: loggedInUserDetailsFromDaybook ? "daybooks" : "documents",
            extension: file.name.split(".").pop(),
            base64: base64String,
          });
        } catch (error) {
          console.error(`Error converting ${file.name} to base64:`, error);
        }
      }

      // setLoading(false);

      try {
        // setLoading(true);

        const response = await api.post("/files/upload_files", {
          files: filesData,
        });

        // setLoading(false);
        const responseArray = response.data.data;

        const updatedFiles = validFiles.map((file: File, index: number) => {
          return { file, key: responseArray[index] };
        });

        // Update uploadedFiles state with the selected files and their keys
        setUploadedFiles((prevUploadedFiles: any) => [
          ...prevUploadedFiles,
          ...updatedFiles,
        ]);

        setState((prev: any) => [...prev, ...updatedFiles]);

        recieveArrayFromChild(updatedFiles);

        // alert(123)

        // toast.success("Upload Successfully!", {
        //   progressStyle: { backgroundColor: dynamicColor },
        //   icon: <span style={successToastStyle}>✓</span>,
        // });
      } catch (error) {}
      // toast.success("Upload Successfully!", {
      //   progressStyle: { backgroundColor: dynamicColor },
      //   icon: <span style={successToastStyle}>✓</span>,
      // });
      // setLoading(false);
      setselectedFiles(files);
    } catch (error) {
      console.error("Error uploading files:", error);
    } finally {
      // setLoading(false);
    }

    if (setHideModal) {
      setHideModal(false);
    }
  };


  // console.log('uploadedFiles', uploadedFiles);
  // console.log('uplaodedFiles', uploadedFiles);
  const handleSaveAttachments = async () => {
    if (setHideModal) {
      setHideModal(false);
      // return;
    }

    if (isDaybook || isDocumentCreateState) {
      setshowAttachmentModal(false);
      return;
    }

    const filteredKeysArray = keysArray?.filter(
      (k: any) => k?.file_name || k?.actual_file_name
    );

    const linkPayload = {
      document_files: filteredKeysArray,
      doc_type: currentElem?.document_uid
        ? "DOCUMENT"
        : currentElem?.comm_type || "DAYBOOK",
      link_uid:
        currentElem?.document_uid || currentElem?.id || currentElem?.link_uid,
    };

    dispatch(linkFileThunk(linkPayload)).then((res: any) => {
      if (res?.error) {
        // toast.error(res?.payload);
        return;
      }
      toast.success("Attachments Successfully Linked!", {
        progressStyle: { backgroundColor: dynamicColor },
        icon: (
          // TODO: Remove this inline CSS
          <span
            style={{
              marginRight: "8px",
              borderRadius: "50%",
              width: "7rem",
              height: "35px",
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: dynamicColor,
              backgroundColor: "white",
              fontSize: "1.5rem",
              border: `3px solid ${dynamicColor}`,
            }}
          >
            ✓
          </span>
        ),
      });
      triggerGetCommsAPICall();
      getDaybookCall();
      dispatch(getDocuments({ clientId, providerId }));
      setshowAttachmentModal(false);
      setKeysArray([]);
    });

    const payload = {
      ...spreadObj,
      comm_document_files: keysArray,
      summary: "",
      message: "",
      comm_tagged_users: [],
      comm_tag: [],
    };

    const response = await dispatch(updateCommsThunk(payload));
    if (response?.payload?.status === "success") {
      dispatch(resetCommsDetailData());
      dispatch(resetDetailCommsPage());
    }
    dispatch(
      getCommsDetailThunk({
        clientId: spreadObj?.link_id,
        type: spreadObj?.comm_type,
        userId,
        page: 1,
      })
    );
  };

  const handleDeleteOnUpdate = async (id: string | any) => {
    try {
      await api.post("/files/delete_document_file", { id: id });
      let filteredData = uploadedFiles?.filter((res: any) => res.id !== id);
      setUploadedFiles(filteredData);
    } catch (error) {
      // toast.error("Failed To Delete File!");
    }
  };

  return (
    <div className="attachment_modal">
      <div className="title_section">
        New Attachment
        <span
          className="cross_icon_capsule_top_section"
          onClick={() => {
            if (setHideModal) {
              setHideModal(false);
            }
            recieveArrayFromChild("empty");
            setshowAttachmentModal(false);
          }}
        >
          <AttachmentModalCrossIcon />
        </span>
      </div>

      <div className="link_section">
        {[
          // {
          //   link: <UploadSVG invert={selectedTab === "Upload Document"} />,
          //   title: "Upload Document",
          // },
          // {
          //   link: <LinkSVG invert={selectedTab === "Link Internal Document"} />,
          //   title: "Link Internal Document",
          // },
        ].map((button) => {
          return (
            <button
              className={`tab_section_button 
          ${selectedTab === button.title ? "selected_tab" : ""}
          `}
              onClick={() => setSelectedTab(button.title)}
              key={button.title}
            >
              <span className="link_icon">{button.link}</span>

              {button.title}
            </button>
          );
        })}
      </div>

      <div
        className="drag_drop_box"
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        // onDragLeave={() => {}}
      >
        <div className="top_section">Drag and drop file</div>
        <div className="or_section">or</div>
        <label className="browse_button" htmlFor="fileInput">
          Browse
        </label>
        <input
          type="file"
          id="fileInput"
          className="hidden"
          accept=".xlsx,.xls,.csv,.docx,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf"
          multiple
          max="10"
          onChange={(event: any) => handleFileSelection(event)}
        />
      </div>

      <div className="attachments_listing">
        {uploadedFiles &&
          uploadedFiles?.map((elem: any, index: any) => {
            return (
              <div className="attachment_capsule_wrapper">
                <div className="attachment_capsule">
                  <div className="left_section_attachments_modal">
                    {elem?.file_name?.includes("pdf") ||
                    elem?.file?.name?.includes("pdf") ? (
                      <PdfDocSvg
                        width="12"
                        height="12"
                        onClick={() => handleGetFile(elem?.file_name)}
                      />
                    ) : elem?.file_name?.includes("docx") ||
                      elem?.file?.name?.includes("docx") ? (
                      <DocxSvg
                        width="12"
                        height="12"
                        onClick={() => handleGetFile(elem?.file_name)}
                      />
                    ) : (
                      <AllDocSvg
                        width="12"
                        height="12"
                        onClick={() => handleGetFile(elem?.file_name)}
                      />
                    )}
                    <div className="attachment_name">
                      {elem?.actual_file_name
                        ? elem?.actual_file_name
                        : elem?.file?.name
                        ? elem?.file?.name
                        : elem?.file_name}
                    </div>
                  </div>
                  <span
                    className="cross_icon_capsule"
                    onClick={() => {
                      if (elem?.id) {
                        setState((prev) =>
                          prev.filter((el) => el.key !== elem?.key)
                        );

                        handleDeleteOnUpdate(elem.id);
                      } else {
                        setState((prev) =>
                          prev.filter((el) => el.key !== elem?.key)
                        );
                        handleDelete(elem?.key || elem?.file_name);
                      }
                    }}
                  >
                    {!elem?.file_size && (
                      <span
                        onClick={() => recieveArrayFromChild("clear", index)}
                      >
                        <AttachmentModalCrossIcon />
                      </span>
                    )}
                  </span>
                </div>
                <span className="file_size">{elem?.file_size}</span>
              </div>
            );
          })}
      </div>
      {!extraType && !hideSaveButton && (
        <>
          <button className="save_btn" onClick={() => handleSaveAttachments()}>
            Save
          </button>
        </>
      )}
    </div>
  );
};

export default AttachmentsModal;
