import React, { Fragment } from "react";

const ClearSearchIcon = ({ clearSearch }: any) => {
  return (
    <svg
      onClick={clearSearch}
      width="16"
      height="18"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="absolute right-[0rem] bottom-[0.15rem]"
    >
      <path
        d="M18 6L6 18M6 6L18 18"
        stroke="#FFF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const DropdownOptions = ({
  selectedItem,
  data,
  ref,
  inputTextValue,
  onFocus,
  onChange,
  onClick,
  clearSearch,
  selectedType
}: any) => {
  return (
    <Fragment>
      {data?.map(({ icon, title, id, ...props }: any, index: any) => (
        <div
          key={index}
          className={`dropdown-2-item custom-strokeWidth ${
            selectedItem === title && "dropdown-2-item-selected"
          }`}
          onClick={() => onClick(id, icon, title, selectedType)}
        >
          <div className={`dropdown-2-item-img`}>{icon}</div>
          {id === 2 ? (
            <div className="search-input-field-documents-dropdown">
              <input
                ref={ref}
                value={inputTextValue}
                placeholder="Search"
                onFocus={onFocus}
                onChange={onChange}
                type="text"
              />
              <ClearSearchIcon clearSearch={clearSearch} />
            </div>
          ) : (
            <p>{title}</p>
          )}
        </div>
      ))}
    </Fragment>
  );
};

export default DropdownOptions;
