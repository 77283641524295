import _ from "lodash";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../Controller/redux/Hooks";
import { setShowStickyNote } from "../../../../Controller/redux/features/CommonFeature/commonSlice";
import MultiSelectDropdown from "../../../pages/Ref-Data/BillingRule/Components/MultiSelectDropdown";
import { Switch } from "@chakra-ui/react";
import {
  createCustomForm,
  getCustomFormsThunk,
  resetcurrentEditCustomForm,
  updateCustomFormByIdThunk,
} from "../../../../Controller/redux/features/CustomFromFeature/customFormSlice";

const CustomForm = () => {
  const [IsLoading, setIsLoading] = useState(false);
  const dynamicColor = useAppSelector((state) => state?.common?.dynamicColor);
  const dispatch = useAppDispatch();
  const { currentEditCustomForm } = useAppSelector((state) => state.customForm);

  // const { expenseTypePage } = useAppSelector((state) => state.expenssType);

  const [commonBody, setcommonBody] = useState<any>({
    form_name: "",
    form_type: "",
    user_types: "",
    has_goals: 0,
    is_required: 0,
    is_repeating: 0,
    admin_title: ""
  });

  useEffect(() => {
    if (currentEditCustomForm) {
      setcommonBody({
        form_name: currentEditCustomForm?.form_name,
        form_type: currentEditCustomForm?.form_type,
        user_types: currentEditCustomForm?.user_types,
        has_goals: currentEditCustomForm?.has_goals,
        is_required: currentEditCustomForm?.is_required,
        is_repeating: currentEditCustomForm?.is_repeating,
        admin_title: currentEditCustomForm?.admin_title,

        
      });
    }
  }, [currentEditCustomForm]);

  //@ts-ignore
  const dynamicBusinessId = parseInt(localStorage.getItem("dynamicBusinessId"));
  // @ts-ignore
  const dynamicClientId = parseInt(localStorage.getItem("dynamicClientId"));
  const dynamicProspectsId = parseInt(
    // @ts-ignore
    localStorage.getItem("dynamicProspectId")
  );
  // @ts-ignore
  const dynamicTeamsId = parseInt(localStorage.getItem("dynamicTeamsId"));
  // @ts-ignore
  const dynamicLocationId = parseInt(localStorage.getItem("dynamicLocationId"));
  // @ts-ignore
  const dynamicStaffId = parseInt(localStorage.getItem("dynamicStaffId"));
  // @ts-ignore
  const dynamicContactId = parseInt(localStorage.getItem("dynamicContactId"));

  const [dynamicUsersStaffIds, setdynamicUsersStaffIds] = useState<any>({});
  // console.log(dynamicUsersStaffIds, "dynamicUsersStaffIds");
  // @ts-ignore
  const userDetails = JSON.parse(localStorage.getItem("user"));
  const userId = userDetails.data.id;

  useEffect(() => {
    if (
      dynamicClientId &&
      dynamicProspectsId &&
      dynamicTeamsId &&
      dynamicLocationId &&
      dynamicStaffId &&
      dynamicContactId &&
      dynamicBusinessId
    ) {
      // console.log("all things foun herrerererr");
      //  "Prospects",
      // "Clients",
      // "Business",
      // "Locations",
      // "Teams",
      // "Contacts",
      // "Staff",
      const userTypeObj = {
        Prospects: dynamicProspectsId,
        Clients: dynamicClientId,
        Business: dynamicBusinessId,
        Locations: dynamicLocationId,
        Teams: dynamicTeamsId,
        Contacts: dynamicContactId,
        Staff: dynamicStaffId,
      };
      setdynamicUsersStaffIds(userTypeObj);
      // const userTypeArr = [
      //   {
      //     type: "Prospects",
      //     id: dynamicProspectsId,
      //   },
      //   {
      //     type: "Clients",
      //     id: dynamicClientId,
      //   },
      //   {
      //     type: "Business",
      //     id: dynamicBusinessId,
      //   },
      //   {
      //     type: "Locations",
      //     id: dynamicLocationId,
      //   },
      //   {
      //     type: "Teams",
      //     id: dynamicTeamsId,
      //   },
      //   {
      //     type: "Contacts",
      //     id: dynamicContactId,
      //   },
      //   {
      //     type: "Staff",
      //     id: dynamicStaffId,
      //   },
      // ];
    }
  }, [
    dynamicBusinessId,
    dynamicClientId,
    dynamicProspectsId,
    dynamicTeamsId,
    dynamicLocationId,
    dynamicStaffId,
    dynamicContactId,
  ]);

  const [SelectedUserTypesIdArr, setSelectedUserTypesIdArr] = useState<any[]>(
    []
  );


  const { error } = useAppSelector((state) => state.customForm)

  useEffect(() => {
    if (commonBody?.user_types) {
      let userTypesNameArr: any[] = commonBody?.user_types?.split(",");

      let idsArr = userTypesNameArr?.map((res) => {
        return dynamicUsersStaffIds[res];
      });
      if (!currentEditCustomForm) {
        setSelectedUserTypesIdArr(idsArr);
      } else {
        setSelectedUserTypesIdArr(
          commonBody?.user_types?.split(",")?.map((el: string) => Number(el))
        );
      }
    } else {
      setSelectedUserTypesIdArr([]);
    }
  }, [commonBody?.user_types, currentEditCustomForm]);
  const handleAddForm = async () => {
    setIsLoading(true);
    if (commonBody.name == "") {
      toast.error("Please enter value on required fileds");
      setIsLoading(false);
      return;
    }
    try {
      let response;
      if (!currentEditCustomForm) {
        response = await dispatch(
          createCustomForm({
            ...commonBody,
            user_types: SelectedUserTypesIdArr,
            included_providers: [0],
          })
        );
      } else{
        response = await dispatch(
          updateCustomFormByIdThunk({
            ...commonBody,
            user_types: SelectedUserTypesIdArr,
            included_providers: [0],
            custom_form_id: currentEditCustomForm?.id
          })
        );
      }

      if (response?.payload?.status == "success") {
        toast.success(
          currentEditCustomForm
            ? "Form Updated Successfully!"
            : "Form Created Successfully!",
          {
            progressStyle: { backgroundColor: dynamicColor },
            icon: (
              <span
                style={{
                  marginRight: "8px",
                  borderRadius: "50%",
                  width: "7rem",
                  height: "35px",
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: dynamicColor,
                  backgroundColor: "white",
                  fontSize: "1.5rem",
                  border: `3px solid ${dynamicColor}`,
                }}
              >
                ✓
              </span>
            ),
          }
        );
        const stickyNoteElement = document.querySelector(
          ".new_sticky_note_wrapper"
        );
        dispatch(getCustomFormsThunk(userId));
        if (stickyNoteElement) {
          stickyNoteElement.classList.add("fade_out");
          setTimeout(() => {
            dispatch(setShowStickyNote(false));
          }, 300);
        }
      }else{
        toast.error(error)
      }
    } catch (error: any) {
      toast.error(error.message);
      toast.error(error)

    } finally {
      setIsLoading(false);
    }

    dispatch(resetcurrentEditCustomForm());
  };

  const debouncedApiCall = _.debounce(handleAddForm, 300);

  useEffect(() => {
    const handleClick = () => {
      debouncedApiCall();
    };

    document?.getElementById("saveBTN")?.addEventListener("click", handleClick);

    return () => {
      document
        ?.getElementById("saveBTN")
        ?.removeEventListener("click", handleClick);
    };
  }, [debouncedApiCall]);

  return (
    <div className="payload_area">
      <div className="main_area">
        <div className="side_bar"></div>
        <div className="side_bar" />

        <div className="middle-block">
          <p className="note-title">New Form</p>
          <div className="middle-section-content middle-section-others">
            <div className="field">
              <span className="field-title">
                {`Name`}
                <span className="asterixs_color"> * </span>
              </span>
              <input
                style={{
                  backgroundColor: "white",
                }}
                className="input-fields-of-sticky-note"
                placeholder={`Enter Name`}
                onChange={(e) =>
                  setcommonBody((prevState: any) => ({
                    ...prevState,
                    form_name: e.target.value,
                  }))
                }
                value={commonBody?.form_name}
              />

              <span className="field-border field-border-add-form"></span>
            </div>
            <div className="field">
              <span className="field-title">
                {`Admin Title`}
                <span className="asterixs_color"> * </span>
              </span>
              <input
                style={{
                  backgroundColor: "white",
                }}
                className="input-fields-of-sticky-note"
                placeholder={`Enter Name`}
                onChange={(e) =>
                  setcommonBody((prevState: any) => ({
                    ...prevState,
                    admin_title: e.target.value,
                  }))
                }
                value={commonBody?.admin_title}
              />

              <span className="field-border field-border-add-form"></span>
            </div>
            {
              !currentEditCustomForm && (
                <div className="field">
                <span className="field-title">{`Form Type`}</span>
                <select
                  onChange={(e) =>
                    setcommonBody((prevState: any) => ({
                      ...prevState,
                      form_type: e.target.value,
                    }))
                  }
                  value={commonBody?.form_type}
                  className="wrapper-info wrapper-info-select-dropdown"
                  style={{
                    border: "none",
                    outline: "none",
                    height: "fit-content",
                    backgroundColor: "white",
                    // width: "256px",
                  }}
                >
                  <option value="">Please Select...</option>
                  {["Clinical", "Document"]?.map((elem: any, index: any) => (
                    <option key={index} value={elem}>
                      {elem}
                    </option>
                  ))}
                </select>
                <span className="field-border field-border-add-form"></span>
              </div>
            )}

            {commonBody?.form_type == "Document" && !currentEditCustomForm && (
              <div className="field custom_form_filed_multi_select_wrapper">
                <span className="field-title">{`User Type`}</span>
                <MultiSelectDropdown
                  options={(option: any) => {
                    setcommonBody({
                      ...commonBody,
                      user_types:
                        Array.isArray(option) && option?.length !== 0
                          ? option?.join(",")
                          : "",
                    });
                  }}
                  value={commonBody?.user_types || ""}
                  optionArr={[
                    "Prospects",
                    "Clients",
                    "Business",
                    "Locations",
                    "Teams",
                    "Contacts",
                    "Staff",
                  ]}
                  placeholder="Select User Type"
                />
              </div>
            )}
            <div className="switch_custom_form_sticky_wrapper">
              <label>Goal Planner</label>
              <Switch
                colorScheme="teal"
                size="md"
                // position={"absolute"}
                // right={"-20px"}
                isChecked={commonBody.has_goals == 0 ? false : true}
                zIndex={9}
                onChange={(e) => {
                  setcommonBody({
                    ...commonBody,
                    has_goals: e.target.checked ? 1 : 0,
                  });
                }}
              />
            </div>
            {/* <div className="switch_custom_form_sticky_wrapper">
              <label>Required</label>
              <Switch
                colorScheme="teal"
                size="md"
                // position={"absolute"}
                // right={"-20px"}
                isChecked={commonBody.is_required == 0 ? false : true}
                zIndex={9}
                onChange={(e) => {
                  setcommonBody({
                    ...commonBody,
                    is_required: e.target.checked ? 1 : 0,
                  });
                }}
              />
            </div> */}
            {commonBody.form_type == "Document" && !currentEditCustomForm && (
              <div className="switch_custom_form_sticky_wrapper">
                <label>Repeating</label>
                <Switch
                  colorScheme="teal"
                  isChecked={commonBody.is_repeating == 0 ? false : true}
                  zIndex={9}
                  onChange={(e) => {
                    setcommonBody({
                      ...commonBody,
                      is_repeating: e.target.checked ? 1 : 0,
                    });
                  }}
                />
              </div>
            )}
          </div>

          <div className="bottomBlock save_btn_custom_form_sticky">
            <button
              className="save-btn"
              id="saveBTN"
              style={{
                position: currentEditCustomForm ?  "absolute" : "unset",
                bottom:currentEditCustomForm ? "-190px" : 'unset',
                right:currentEditCustomForm ? "-50px" : "unset"
              }}
              // onClick={() => {
              //   handleAddForm();
              // }}
            >
              {IsLoading ? "Loading..." : "Save"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomForm;
