import { useEffect, useRef, useState } from "react";
import CommonUpperBox from "../../../components/UpperBox/CommonHeader";
import Table from "../../../components/CommonTable/Table";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../Controller/redux/Hooks";
import { useNavigate } from "react-router-dom";
import CircularNoteTrigger from "../../../components/CircularNoteTrigger/CircularNoteTrigger";
import CommonQuickMenu from "../../../components/CommonQuickMenu/CommonQuickMenu";
import {
  getLocationEmailThunk,
  getLocationPhoneThunk,
  getLocationStatusThunk,
  getLocationUserByFilterThunk,
  getLocationUserNameThunk,
  getLocationUserThunk,
  resetLocationData,
  resetLocationPage,
  setLocationPage,
} from "../../../../Controller/redux/features/LocationFeature/locationSlice";
import {
  getStatusDetailsThunk,
  resetStatusDetails,
  setProfileTypeSticky,
} from "../../../../Controller/redux/features/CommonFeature/commonSlice";

const LocationsPage = () => {
  // @ts-ignore
  const dynamicLocationId = parseInt(localStorage.getItem("dynamicLocationId"));

  const tableRef = useRef();
  const locationInnerRef = useRef();
  const [stageTwoToGoalsModal, setstageTwoToGoalsModal] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    allLocationUserData,
    LocationPage,
    LocationFilterItems,
    location,
    lastPage,
    loading,
  } = useAppSelector((state) => state?.location);

  const { locations_label } = useAppSelector(
    (state) => state?.common?.domainDetail
  );
  useEffect(() => {
    dispatch(resetLocationData());
    dispatch(resetLocationPage());
  }, [
    LocationFilterItems?.searchVal,
    LocationFilterItems?.filterValue,
    LocationFilterItems?.sortType,
    LocationFilterItems?.status,
    dynamicLocationId,
  ]);

  useEffect(() => {
    dispatch(
      getLocationUserByFilterThunk({
        filterColumn: LocationFilterItems?.filterColumn
          ? LocationFilterItems?.filterColumn
          : "",
        filterValue: LocationFilterItems?.filterValue
          ? LocationFilterItems?.filterValue
          : "",
        searchVal: LocationFilterItems?.searchVal
          ? LocationFilterItems?.searchVal
          : "",
        sortColumn: LocationFilterItems?.sortColumn
          ? LocationFilterItems?.sortColumn
          : "",
        sortType: LocationFilterItems?.sortType
          ? LocationFilterItems?.sortType
          : "",
        LocationPage,
        dynamicId: dynamicLocationId,
        status: LocationFilterItems.status ? LocationFilterItems.status : "",
      })
    );
  }, [
    LocationPage,
    LocationFilterItems?.searchVal,
    LocationFilterItems?.filterValue,
    LocationFilterItems?.sortType,
    LocationFilterItems?.status,
    dynamicLocationId,
  ]);
  const onScroll = () => {
    if (locationInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } =
        locationInnerRef.current;

      if (
        scrollTop + clientHeight + +5 >= scrollHeight &&
        LocationPage < lastPage
      ) {
        dispatch(setLocationPage(LocationPage));
      }
    }
  };
  const uniqueLocationData = location?.filter(
    (item: any, index: number, array: any) => {
      return array?.findIndex((obj: any) => obj.id === item.id) === index;
    }
  );
  const dropdownRefBusiness = useRef<any>();
  const svgParentRefBusiness = useRef<any>();
  const [isDropdownOpenBusiness, setIsDropdownOpenBusiness] = useState(false);
  const { selectedFilterItemsLocation, sortByLocationR, searchValLocation } =
    useAppSelector((state) => state?.location);
  const { filterIconColor } = useAppSelector((state) => state?.common);
  const handleClickRow = (e: any, id: any) => {
    if (!isDropdownOpenBusiness) {
      navigate(`/people/locations/${window.btoa(id)}`);
    }
  };

  const handleClickOutsideBusiness = (event: any) => {
    if (
      dropdownRefBusiness.current &&
      !dropdownRefBusiness.current.contains(event.target) &&
      svgParentRefBusiness.current &&
      !svgParentRefBusiness.current.contains(event.target)
    ) {
      setIsDropdownOpenBusiness(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutsideBusiness);
    return () => {
      document.removeEventListener("click", handleClickOutsideBusiness);
    };
  }, []);

  ///////////////////////////////////////////////////////////////////////////////////////
  const locationStatusDetailsNums = useAppSelector(
    (state) => state.common.domainDetail.location_status
  );
  const locationStatusDetailsArr = useAppSelector(
    (state) => state.common.statusDetailsData?.data
  );
  // // console.log(clientStatusDetailsArr,"clientStatusDetailsArr")
  useEffect(() => {
    dispatch(resetStatusDetails());
    dispatch(getStatusDetailsThunk(locationStatusDetailsNums));
  }, [locationStatusDetailsNums]);
  ///////////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    dispatch(getLocationUserNameThunk(dynamicLocationId));
    dispatch(getLocationEmailThunk(dynamicLocationId));
    dispatch(getLocationPhoneThunk(dynamicLocationId));
    dispatch(getLocationStatusThunk(dynamicLocationId));
  }, [dynamicLocationId]);

  useEffect(() => {
    localStorage.removeItem("activeTab");
  }, []);

  // useEffect(() => {
  //   dispatch(getLocationUserThunk(dynamicLocationId));
  // }, [dynamicLocationId]);

  const columns = [
    { header: "Name", accessor: "name" },
    { header: "Email", accessor: "email" },
    { header: "Phone", accessor: "phone" },
    { header: "Status", accessor: "status" },
  ];
  const { domainDetail } = useAppSelector((state) => state?.common);
  useEffect(() => {
    dispatch(setProfileTypeSticky(locations_label));
  }, [locations_label]);
  const { profile_name } = useAppSelector((state) => state.common.domainDetail);
  return (
    <section>
    <div
        style={{
          position: "relative",
        }}
      >
        {stageTwoToGoalsModal && (
          <CommonQuickMenu
            setshowGoalsModal={(value: any) => {}}
            mainType={"listing"}
            setstageTwoToGoalsModal={setstageTwoToGoalsModal}
          />
        )}
        <CommonUpperBox
          onClickPlus={() => {
            setstageTwoToGoalsModal(true);
          }}
          type={"listing"}
          title={locations_label}
        />
      </div>
      <Table
        isLoading={loading}
        tableRef={tableRef}
        tableBodyRef={locationInnerRef}
        onScroll={onScroll}
        dynamicHeaderColor={domainDetail?.listingtbl_color}
        statusType="locationScreen"
        thData={columns}
        classNameTr="all-business-table-head"
        type="locations"
        dropdownRef={dropdownRefBusiness}
        isDropdownOpen={isDropdownOpenBusiness}
        onClickSvg={() => setIsDropdownOpenBusiness(true)}
        svgParentRef={svgParentRefBusiness}
        allClients={uniqueLocationData}
        itemsArr={locationStatusDetailsArr}
        onClickRow={handleClickRow}
        classNameRow="all-business-table-head"
        fill={
          selectedFilterItemsLocation.length > 0 ||
          searchValLocation.length > 0 ||
          sortByLocationR.length > 0
            ? filterIconColor
            : "#D8D3D3"
        }
      />
      <CircularNoteTrigger
        type={locations_label}
        //  title={locations_label}
        title={profile_name}
      />
  
    </section>
  );
};

export default LocationsPage;
