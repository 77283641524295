import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../../../services/api";

const initialState: any = {
  fundingTypePage: 1,
  fundingTypeFilterItems: {
    // status: "1",
    filterColumn: "",
    filterValue: "",
    searchVal: "",
    sortColumn: "",
    sortType: "",
    fundingTypePage: 1,
  },
  lastPage: 100000,

  loading: false,
  error: "",
  FundingTypeData: [],
  // Filter Data Start
  getFundingTypeName: [],
  getFundingTypeCategory: [],
  getFundingTypeSubCategory: [],
  // Filter Data End
  //  // STATES OF SELECTED FILTER GOES HERE
  searchValFundingType: "",
  selectedDropdownItemFundingType: "",
  selectedItemIndexFilterFundingType: "",
  selectedItemIndexSortFundingType: "",
  sortByFundingTypeR: "",
  selectedFilterItemsFundingType: [],
  // End
};

export const getFundingType = createAsyncThunk(
  "funding-type/fetchFundingType",
  async () => {
    try {
      const { data } = await api.get(
        `funding_types?paginate=true&page=1&limit=10000`
      );
      return data?.data;
    } catch (error) {
      throw error;
    }
  }
);
export const getFundingTypeByFilterThunk = createAsyncThunk(
  " funding-type/fetchFundingTypeByFilter",
  async ({
    filterColumn,
    filterValue,
    searchVal,
    sortColumn,
    sortType,
    // status,
    fundingTypePage,
  }: {
    filterColumn: string | any;
    filterValue: string | any;
    searchVal: string | any;
    sortColumn: string | any;
    sortType: string | any;
    // status: string | any;
    fundingTypePage: string | any;
  }) => {
    const encodedFilterValue = encodeURIComponent(filterValue);

    try {
      const response = await api.get(
        `/funding_types?paginate=true&page=${fundingTypePage}&limit=50&search=${searchVal}&sort_column=${sortColumn}&sort_type=${sortType}&filter_column=${filterColumn}&filter_value=${encodedFilterValue}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
export const getFundingTypeNameThunk = createAsyncThunk(
  "funding-type/fetchFundingTypeName",
  async () => {
    try {
      const { data } = await api.get(`funding_types?unique_column=name`);
      return data?.data;
    } catch (error) {
      throw error;
    }
  }
);
export const getFundingTypeCategoryThunk = createAsyncThunk(
  "funding-type/fetchFundingTypeCategory",
  async () => {
    try {
      const { data } = await api.get(
        `funding_types?unique_column=funding_category`
      );
      return data?.data;
    } catch (error) {
      throw error;
    }
  }
);
export const getFundingTypeSubCategoryThunk = createAsyncThunk(
  "funding-type/fetchFundingTypeSubCategory",
  async () => {
    try {
      const { data } = await api.get(
        `funding_types?unique_column=funding_sub_category`
      );
      return data?.data;
    } catch (error) {
      throw error;
    }
  }
);

const FundingTypeSlice = createSlice({
  name: "fundingType",
  initialState,
  reducers: {
    // Pagination Start
    setFundingTypePage: (state) => {
      state.fundingTypePage += 1;
    },
    resetFundingTypePage: (state) => {
      state.fundingTypePage = 1;
    },
    resetFundingTypeData: (state) => {
      state.FundingTypeData = [];
    },
    setFundingTypeFilterItems: (state, action) => {
      state.fundingTypeFilterItems = action.payload;
    },
    resetFundingTypeFilterItems: (state) => {
      state.fundingTypeFilterItems = {
        // status: "1",
        filterColumn: "",
        filterValue: "",
        searchVal: "",
        sortColumn: "",
        sortType: "",
        fundingTypePage: 1,
      };
    },
    // Pagination End
    // Filter Dropdown Start
    setSelectedDropdownItemFundingType: (state, action) => {
      state.selectedDropdownItemFundingType = action.payload;
    },
    resetSelectedDropdownItemFundingType: (state) => {
      state.selectedDropdownItemFundingType = "";
    },
    setSelectedItemIndexFilterFundingType: (state, action) => {
      state.selectedItemIndexFilterFundingType = action.payload;
    },
    resetSelectedItemIndexFilterFundingType: (state) => {
      state.selectedItemIndexFilterFundingType = "";
    },
    setSelectedItemIndexSortFundingType: (state, action) => {
      state.selectedItemIndexSortFundingType = action.payload;
    },
    resetSelectedItemIndexSortFundingType: (state) => {
      state.selectedItemIndexSortFundingType = "";
    },
    setSortByFundingTypeR: (state, action) => {
      state.sortByFundingTypeR = action.payload;
    },
    resetSortByFundingTypeR: (state) => {
      state.sortByFundingTypeR = "";
    },
    setSelectedFilterItemsFundingType: (state, action) => {
      state.selectedFilterItemsFundingType = action.payload;
    },
    resetSelectedFilterItemsFundingType: (state) => {
      state.selectedFilterItemsFundingType = "";
    },
    setSearchValueFundingType: (state, action) => {
      state.searchValFundingType = action.payload;
    },
    resetSearchValueFundingType: (state) => {
      state.searchValFundingType = "";
    }, // FilteFunding
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFundingType.pending, (state) => {
        state.loading = true;
      })
      .addCase(getFundingType.fulfilled, (state, action) => {
        state.loading = false;
        state.FundingTypeData = action.payload;
      })
      .addCase(getFundingType.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getFundingTypeByFilterThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getFundingTypeByFilterThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.FundingTypeData = [
          ...state.FundingTypeData,
          ...action.payload.data.data,
        ];
        state.lastPage = action.payload.data.last_page;
      })
      .addCase(getFundingTypeByFilterThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getFundingTypeNameThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getFundingTypeNameThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.getFundingTypeName = action.payload.data;
      })
      .addCase(getFundingTypeNameThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getFundingTypeCategoryThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getFundingTypeCategoryThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.getFundingTypeCategory = action.payload.data;
      })
      .addCase(getFundingTypeCategoryThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getFundingTypeSubCategoryThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getFundingTypeSubCategoryThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.getFundingTypeSubCategory = action.payload.data;
      })
      .addCase(getFundingTypeSubCategoryThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const {
  // Filter Start
  setSelectedDropdownItemFundingType,
  resetSelectedDropdownItemFundingType,
  setSelectedItemIndexFilterFundingType,
  resetSelectedItemIndexFilterFundingType,
  setSelectedItemIndexSortFundingType,
  resetSelectedItemIndexSortFundingType,
  setSortByFundingTypeR,
  resetSortByFundingTypeR,
  setSelectedFilterItemsFundingType,
  resetSelectedFilterItemsFundingType,
  setSearchValueFundingType,
  resetSearchValueFundingType,
  // Filter End
  
  // Pagination Start
  setFundingTypePage,
  resetFundingTypePage,
  resetFundingTypeData,
  setFundingTypeFilterItems,
  resetFundingTypeFilterItems,
  // Pagination End
} = FundingTypeSlice.actions;

export default FundingTypeSlice.reducer;
