import "./TableDropDown2.css";

import React from "react";
import { SetStateAction, useEffect, useRef, useState } from "react";
import {
  getAssociationsByFilter,
  getAssociationsEntityByFilter,
  resetSelectedFilterItemsAssociations,
  resetSelectedItemIndexFilterAssociations,
  resetSelectedItemIndexSortAssociations,
  resetSortByAssociationsR,
  resetinputSearchValAssociations,
  resetselectedItemIndexAssociations,
  resetselectedItemIndexAssociationsSort,
  setSelectedDropdownItemAssociations,
  setSelectedFilterItemsAssociations,
  setSelectedItemIndexFilterAssociations,
  setSelectedItemIndexSortAssociations,
  setSortByAssociationsR,
  setinputSearchValAssociations,
  setselectedItemIndexAssociations,
  setselectedItemIndexAssociationsSort,
  setselectedNames,
} from "../../../../Controller/redux/features/CommonFeature/associationSlice";
import {
  resetAuditFilterItems,
  resetFilterOptionsAudit,
  resetSearchValueAudit,
  resetSelectedFilterItemsAudit,
  resetSelectedItemIndexFilterAudit,
  resetSelectedItemIndexSortAudit,
  resetSortByAudits,
  resetSortOptionsAudit,
  setAuditFilterItems,
  setFilterOptionsAudit,
  setSearchValueAudit,
  setSelectedDropdownItemAudit,
  setSelectedFilterItemsAudit,
  setSelectedItemIndexFilterAudit,
  setSelectedItemIndexSortAudit,
  setSortByAudits,
  setSortOptionsAudit,
} from "../../../../Controller/redux/features/AuditFeature/auditSlice";
import FormFilter from "./AllFilters/FormTypeFilter";
import {
  getClientsByFilterThunk,
  getClientsSelectAllThunk,
  getClientsThunk,
  resetsearchValueClients,
  resetselectedFilterItemsClients,
  resetselectedItemIndexFilterClients,
  resetselectedItemIndexSortClients,
  resetsortbyClients,
  setClientFilterItems,
  setsearchValueClients,
  setselectedDropdownItemClients,
  setselectedFilterItemsClients,
  setselectedItemIndexFilterClients,
  setselectedItemIndexSortClients,
  setsortbyClients,
} from "../../../../Controller/redux/features/ClientFeature/clientsSlice";
import {
  getContactsByFilterThunk,
  getContactsSelectAllThunk,
  getContactsThunk,
  resetSelectedFilterItemsContact,
  resetSelectedItemIndexFilterContact,
  resetSelectedItemIndexSortContact,
  resetSortByContacts,
  resetsearchValueContacts,
  setContactFilterItems,
  setFilterOptionsContact,
  setSelectedDropdownItemContact,
  setSelectedItemIndexFilterContact,
  setSelectedItemIndexSortContact,
  setSortByContacts,
  setsearchValueContacts,
} from "../../../../Controller/redux/features/ContactsFeature/contactsSlice";
import {
  getDocuments,
  getDocumentsByFilter,
  getDocumentsByFilterForEntity,
  getDocumentsEntity,
  resetSelectedFilterItemsDocuments,
  resetSelectedItemIndexFilterDocuments,
  resetSelectedItemIndexSortDocuments,
  resetSortByDocumentsR,
  resetinputSearchVal,
  resetselectedColumn,
  resetselectedcolumnforsort,
  setSelectedDropdownItemDocuments,
  setSelectedFilterItemsDocuments,
  setSelectedItemIndexFilterDocuments,
  setSelectedItemIndexSortDocuments,
  setSortByDocumentsR,
  setinputSearchVal,
  setselectedApprovers,
  setselectedOptions,
  setselectedTypeOfManipulation,
  setsortType,
} from "../../../../Controller/redux/features/CommonFeature/documentSlice";
import {
  getProspectsByFilterThunk,
  getProspectsSelectAllThunk,
  getProspectsThunk,
  resetProspectData,
  resetProspectFilterItems,
  resetSelectedFilterItemsProspect,
  resetSelectedItemIndexFilterProspect,
  resetSelectedItemIndexSortProspect,
  resetSelectedProspects,
  resetSortByProspect,
  resetsearchvalProspects,
  resetselectedSortValuesProspects,
  setProspectFilterItems,
  setSelectedDropdownItemProspect,
  setSelectedItemIndexFilterProspect,
  setSelectedItemIndexSortProspect,
  setSortByProspect,
  setisDropdownOpen,
  setsearchvalProspects,
  setselectedProspects,
  setselectedSortValuesProspects,
} from "../../../../Controller/redux/features/ProspectsFeature/prospectsSlice";
import {
  getStaffByFilterThunk,
  getStaffRolesThunk,
  getStaffSelectAllThunk,
  getStaffThunk,
  resetFilterOptions,
  resetSearchValueStaff,
  resetSortOptions,
  setFilterOptions,
  setSearchValueStaff,
  setSortOptions,
  setStaffFilterItems,
} from "../../../../Controller/redux/features/StaffFeature/staffSlice";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../Controller/redux/Hooks";

import { Checkbox } from "@chakra-ui/react";
import { tableHeadArr } from "../Data/AssociationHeaderDataArr";
import { useLocation, useParams } from "react-router-dom";
import FilterDropdown from "./FilterDropdown";
import DropdownOptions from "./DropdownOptions";
import {
  getBusinessByFilterThunk,
  getBusinessThunk,
  resetFilterOptionsBusiness,
  resetSearchValueBusiness,
  resetSelectedFilterItemsBusiness,
  resetSelectedItemIndexFilterBusiness,
  resetSelectedItemIndexSortBusiness,
  resetSortByBusinessR,
  resetSortOptionsBusiness,
  setBusinessFilterItems,
  setSearchValueBusiness,
  setSelectedDropdownItemBusiness,
  setSelectedFilterItemsBusiness,
  setSelectedItemIndexFilterBusiness,
  setSelectedItemIndexSortBusiness,
  setSortByBusinessR,
} from "../../../../Controller/redux/features/BusinessFeature/businessSlice";
import {
  getTeamsByFilterThunk,
  getTeamsThunk,
  resetFilterOptionsTeams,
  resetSearchValueTeams,
  resetSelectedFilterItemsTeams,
  resetSelectedItemIndexFilterTeams,
  resetSelectedItemIndexSortTeams,
  resetSortByTeamsR,
  resetSortOptionsTeams,
  setSearchValueTeams,
  setSelectedDropdownItemTeams,
  setSelectedFilterItemsTeams,
  setSelectedItemIndexFilterTeams,
  setSelectedItemIndexSortTeams,
  setSortByTeamsR,
  setSortOptionsTeams,
  setTeamsFilterItems,
} from "../../../../Controller/redux/features/TeamsFeature/teamsSlice";
import SortDropdown from "./SortDropdown";
import {
  getMealBuilder,
  getMealsByFilterThunk,
  getUniqueTagsMealsThunk,
  resetsearchValueMeals,
  resetselectedFilterItemsMeals,
  resetselectedItemIndexFilterMeals,
  resetselectedItemIndexSortMeals,
  resetsortbyMeals,
  setsearchValueMeals,
  setselectedFilterItemsMeals,
  setselectedItemIndexFilterMeals,
  setselectedItemIndexSortMeals,
  setsortbyMeals,
} from "../../../../Controller/redux/features/MealsFeatures/MealFeature/mealbuilderSlice";
import SelectedFilterDropdown from "./SelectedFilterDropdown";
import SelectedFilterOption from "./SelectedFilterOption";
import SelectedSortDropdown from "./SelectedSortDropdown";
import SelectedSortOptions from "./SelectedSortOptions";
import Dropdown from "./Dropdown";
import {
  getLocationUserByFilterThunk,
  getLocationUserThunk,
  resetSearchValueLocation,
  resetSelectedFilterItemsLocation,
  resetSelectedItemIndexFilterLocation,
  resetSelectedItemIndexSortLocation,
  resetSortByLocationR,
  setLocationFilterItems,
  setSearchValueLocation,
  setSelectedDropdownItemLocation,
  setSelectedFilterItemsLocation,
  setSelectedItemIndexFilterLocation,
  setSelectedItemIndexSortLocation,
  setSortByLocationR,
} from "../../../../Controller/redux/features/LocationFeature/locationSlice";
import {
  getProvidersByFilterThunk,
  getProvidersThunk,
  resetSelectedFilterItemsProviders,
  resetSelectedItemIndexFilterProviders,
  resetSelectedItemIndexSortProviders,
  resetinputSearchValProviders,
  resetsortByProviderR,
  setProvidersFilterItems,
  setSelectedDropdownItemProviders,
  setSelectedFilterItemsProviders,
  setSelectedItemIndexFilterProviders,
  setSelectedItemIndexSortProviders,
  setinputSearchValProviders,
  setselectedFilterOptionsProviders,
  setsortByProviderR,
} from "../../../../Controller/redux/features/ProviderFeature/providerSlice";
import ExpenseTypeFilter from "./AllFilters/ExpenseTypeFilter";
import FundingTypeFilter from "./AllFilters/FundingTypeFilter";
import BillingRuleFilter from "./AllFilters/BillingRuleFilter";
import PayRuleFilter from "./AllFilters/PayRuleFilter";
import { itemsArrProspectsAll } from "../../../pages/People/ClientPage/Components/UniqueClientScreen/Data/WidgetDataArr";
import NotificationsDropdown from "./../../../pages/Notifications/NotificationsDropdown";

export const searchIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="11"
    height="11"
    viewBox="0 0 11 11"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.15354 4.07677C7.15354 5.77602 5.77602 7.15354 4.07677 7.15354C2.37752 7.15354 1 5.77602 1 4.07677C1 2.37752 2.37752 1 4.07677 1C5.77602 1 7.15354 2.37752 7.15354 4.07677ZM6.58437 7.29134C5.89279 7.83159 5.02237 8.15354 4.07677 8.15354C1.82523 8.15354 0 6.32831 0 4.07677C0 1.82523 1.82523 0 4.07677 0C6.32831 0 8.15354 1.82523 8.15354 4.07677C8.15354 5.0223 7.83164 5.89265 7.29146 6.58421L10.147 9.43977L9.43991 10.1469L6.58437 7.29134Z"
      fill="#4F4F4F"
    />
  </svg>
);

export const sortIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="11"
    height="8"
    viewBox="0 0 11 8"
    fill="none"
  >
    <path d="M1.00146 1H10.0015" stroke="#4F4F4F" strokeLinecap="square"></path>
    <path
      d="M1.00146 4.42871H7.30147"
      stroke="#4F4F4F"
      strokeLinecap="square"
    ></path>
    <path d="M1.00146 7H4.60146" stroke="#4F4F4F" strokeLinecap="square"></path>
  </svg>
);

export const filterIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
  >
    <mask id="path-1-inside-1_313_10343" fill="white">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 1C10 0.447715 9.55228 0 9 0H1C0.447715 0 0 0.447715 0 1V1.46079C0 1.726 0.105357 1.98036 0.292894 2.16789L2.83211 4.70711C3.01965 4.89465 3.125 5.149 3.125 5.41422V9.30629C3.125 9.64757 3.45935 9.88855 3.78312 9.78063L6.53312 8.86397C6.73729 8.79591 6.875 8.60484 6.875 8.38962V5.41421C6.875 5.14899 6.98036 4.89464 7.1679 4.7071L9.70711 2.16789C9.89464 1.98036 10 1.726 10 1.46079V1Z"
      ></path>
    </mask>
    <path
      d="M3.78312 9.78063L3.46689 8.83195L3.46689 8.83195L3.78312 9.78063ZM6.53312 8.86397L6.84935 9.81265H6.84935L6.53312 8.86397ZM9.70711 2.16789L10.4142 2.875L9.70711 2.16789ZM2.83211 4.70711L3.53922 4L2.83211 4.70711ZM1 1H9V-1H1V1ZM1 1.46079V1H-1V1.46079H1ZM3.53922 4L1 1.46079L-0.414213 2.875L2.125 5.41422L3.53922 4ZM2.125 5.41422V9.30629H4.125V5.41422H2.125ZM2.125 9.30629C2.125 10.3301 3.12805 11.0531 4.09935 10.7293L3.46689 8.83195C3.79066 8.72403 4.125 8.96501 4.125 9.30629H2.125ZM4.09934 10.7293L6.84935 9.81265L6.21689 7.91528L3.46689 8.83195L4.09934 10.7293ZM6.84935 9.81265C7.46186 9.60848 7.875 9.03527 7.875 8.38962H5.875C5.875 8.17441 6.01272 7.98334 6.21689 7.91528L6.84935 9.81265ZM7.875 8.38962V5.41421H5.875V8.38962H7.875ZM9 1.46079L6.46079 4L7.875 5.41421L10.4142 2.875L9 1.46079ZM9 1V1.46079H11V1H9ZM10.4142 2.875C10.7893 2.49993 11 1.99122 11 1.46079H9L10.4142 2.875ZM7.875 5.41421L6.46079 4C6.08572 4.37507 5.875 4.88378 5.875 5.41421H7.875ZM2.125 5.41422H4.125C4.125 4.88378 3.91429 4.37508 3.53922 4L2.125 5.41422ZM-1 1.46079C-1 1.99122 -0.789285 2.49993 -0.414213 2.875L1 1.46079L1 1.46079H-1ZM9 1V1H11C11 -0.10457 10.1046 -1 9 -1V1ZM1 -1C-0.10457 -1 -1 -0.104569 -1 1H1V1V-1Z"
      fill="#4F4F4F"
      mask="url(#path-1-inside-1_313_10343)"
    ></path>
  </svg>
);

export const Data: any[] = [
  {
    id: 1,
    icon: filterIcon,
    title: "filter",
    children: [
      {
        id: 1,
        title: "Document",
        subChildren: ["A", "B", "C", "c", "A", "B", "C", "c"],
      },
      {
        id: 2,
        title: "Due Date",
        subChildren: ["A", "B", "C"],
      },
      {
        id: 3,
        title: "Primary Approver",
        subChildren: ["A", "B", "C"],
      },
    ],
  },
  {
    id: 2,
    icon: searchIcon,
    title: "search",
  },
  {
    id: 3,
    icon: sortIcon,
    title: "sort",
    children: [
      {
        id: 1,
        title: "Document",
      },
      {
        id: 2,
        title: "Due Date",
      },
      {
        id: 3,
        title: "Primary Approver",
      },
    ],
  },
];

interface initialStateType {
  inputValue: string;
  selectedFilterDropDownOne: any;
  selectedFilterDropDownTwo: any;
  selectedSearchItem: boolean;
  selectedSortDropDownOne: any;
  selectedSortDropDownTwo: any;
  selectedSortBy: string;
  selectedItem: any;
}

const TableDropdown2 = ({ type }: string | any) => {
  const { id, pro_id }: any = useParams();
  const [clientId, setclientId] = useState<string | any>("");
  const [providerId, setProviderId] = useState("");

  useEffect(() => {
    if (id) {
      setclientId(window?.atob(id));
    }
    if (pro_id) {
      setProviderId(window?.atob(pro_id));
    }
  }, [id, pro_id]);
  //////////////////////
  const { user } = useAppSelector((state) => state?.common);
  const isAdminActive = user?.tenant?.id === user?.tenant?.parent_id;
  // const isUserAdmin = user?.roles[0]?.desc?.toLowerCase();
  // const isAdminActive = isUserAdmin === "administrator";

  /////////////////////
  const pathname = useLocation()?.pathname;
  const pathnameBusinessAndTeams =
    pathname?.includes("business") || pathname?.includes("teams");
  const inputValueFromRedux = useAppSelector(
    (state) => state?.document?.searchValDoc
  );
  const selectedTypeOFManipulation = useAppSelector(
    (state) => state?.document?.selectedTypeOfManipulation
  );
  const selectedColumn = useAppSelector(
    (state) => state?.document?.selectedColumn
  );
  const selectedColumnForDocumentSort = useAppSelector(
    (state) => state?.document?.selectedColumnForSort
  );
  const selectedOptionsFromRedux = useAppSelector(
    (state) => state?.document?.selectedOptions
  );
  const getDocumentsArr = useAppSelector(
    (state) => state?.document?.getAllDocuments
  );
  const getProvidersArr = useAppSelector(
    (state) => state.providers.allProviders
  );
  const {
    providersPage,
    getProvidersUniqueTenantName,
    getProvidersUniqueTenantType,
  } = useAppSelector((state) => state.providers);
  const searchValContacts = useAppSelector(
    (state) => state.contacts.searchValContacts
  );
  const getAssociationsArr = useAppSelector(
    (state) => state?.associations?.getAllAssociations
  );
  const seleectedColumnForSort = useAppSelector(
    (state) => state?.document?.selectedColumnForSort
  );
  const getTypeOfSort = useAppSelector(
    (state) => state?.document?.selectedSortType
  );
  // const uniquePrimaryApprovers = useAppSelector(
  //   (state) => state?.document?.uniquePrimaryApprovers
  // );
  const filterIconCo̥lor = useAppSelector(
    (state) => state?.common?.filterIconColor
  );
  const selectedApproversFromRedux = useAppSelector(
    (state) => state?.document?.selectedApprovers
  );
  const searchValAssociations = useAppSelector(
    (state) => state?.associations?.searchValAssociations
  );
  const searchValProspects = useAppSelector(
    (state) => state?.prospects?.searchValProspects
  );

  const selectedItemIndexAssociations = useAppSelector(
    (state) => state?.associations?.selectedItemIndexAssociations
  );

  const [selectedApproversDocs, setSelectedApproversDocs] = useState<any>([]);
  // const [uniquePrimaryApproversArr, setUniquePrimaryApproversArr] = useState<string[]>([]);

  const handleClearSearchAssociations = () => {
    // setSelectedItemIndex(null);
    // setselectedSortIndex(null);
    setInputValueTextAssociations("");
    dispatch(resetinputSearchValAssociations(""));
    if (pathnameBusinessAndTeams) {
      dispatch(
        getAssociationsEntityByFilter({
          clientId,
          filterVal: "",
          columnType: "",
          searchVal: "",
          sortType: "",
        })
      );
    } else {
      dispatch(
        getAssociationsByFilter({
          clientId,
          filterVal: "",
          columnType: "",
          uniqueCol: "",
          searchVal: "",
          sortType: "",
        })
      );
    }
  };

  const allMealBuilder = useAppSelector(
    (state) => state?.mealBuilder?.persistentMealsData?.data?.data
  );

  const uniqueTagsMeals = useAppSelector(
    (state) => state?.mealBuilder?.uniqueTags
  );

  const [uniqueTagsOfMeals, setuniqueTagsOfMeals] = useState<any>([]);

  const { domainDetail } = useAppSelector((state) => state?.common);

  var businessType = domainDetail?.businessmodel_type;
  useEffect(() => {
    if (uniqueTagsMeals) {
      let uniqueTags = [
        ...new Set(
          uniqueTagsMeals[0]?.tags?.map(
            (elem: { tag_name: string }) => elem?.tag_name
          )
        ),
      ];
      setuniqueTagsOfMeals(uniqueTags);
    }
  }, [uniqueTagsMeals]);

  const selectedColumnOfDocuments = selectedColumn?.title?.includes("Due Date")
    ? "primary_review"
    : selectedColumn?.title?.includes("Document")
    ? "title"
    : "primary_approver";

  const selectedColumnOfDocumentsForDocumentSort =
    selectedColumnForDocumentSort?.title?.includes("Due Date")
      ? "primary_review"
      : selectedColumnForDocumentSort?.title?.includes("Document")
      ? "title"
      : "primary_approver";

  const initialState: initialStateType = {
    inputValue: inputValueFromRedux,
    selectedFilterDropDownOne: selectedTypeOFManipulation,
    selectedFilterDropDownTwo: selectedColumn,
    selectedSearchItem: false,
    selectedSortDropDownOne: selectedTypeOFManipulation,
    selectedSortDropDownTwo: seleectedColumnForSort,
    selectedSortBy: getTypeOfSort,
    selectedItem: null,
  };

  const dispatch = useAppDispatch();

  const inputRef = useRef<HTMLInputElement | any>(null);
  const inputRefAssociations = useRef<HTMLInputElement | any>(null);
  const inputRefClients = useRef<HTMLInputElement | any>(null);
  const inputRefProspects = useRef<HTMLInputElement | any>(null);
  const inputRefContacts = useRef<HTMLInputElement | any>(null);
  const inputRefStaff = useRef<HTMLInputElement | any>(null);
  const inputRefAudit = useRef<HTMLInputElement | any>(null);
  const inputRefBusiness = useRef<HTMLInputElement | any>(null);
  const inputRefLocation = useRef<HTMLInputElement | any>(null);
  const inputRefTeams = useRef<HTMLInputElement | any>(null);
  const inputRefMeals = useRef<HTMLInputElement | any>(null);
  const inputRefNotifications = useRef<HTMLInputElement | any>(null);

  const [
    {
      selectedFilterDropDownOne,
      selectedFilterDropDownTwo,
      selectedSortDropDownOne,
      selectedSortDropDownTwo,
      selectedSortBy,
      selectedItem,
    },
    setState,
  ] = useState(initialState);

  const [selectedTitles, setSelectedTitles] = useState<string[] | any[]>([]);
  const { ContactPage } = useAppSelector((state) => state?.contacts);
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////// AUDIT DROPDOWN ///////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  const {
    selectedDropdownItemAudit,
    selectedItemIndexFilterAudit,
    selectedItemIndexSortAudit,
    sortByAudits,
    AuditPageFilter,
  } = useAppSelector((state) => state?.audit);
  const [AuditTableDataObject, setAuditTableDataObject] = useState<any>({
    currentActive: selectedDropdownItemAudit,
    selectedItemIndex: selectedItemIndexFilterAudit,
    selectedSortIndex: selectedItemIndexSortAudit,
  });

  const handleChangeOfDropdownAudit = (names: any, type: any) => {
    let filterColumn;

    switch (type) {
      case "username":
        filterColumn = "username";
        break;
      case "activity_type":
        filterColumn = "activity_type";
        break;
      case "table_name":
        filterColumn = "table_name";
        break;
      default:
        "screen";
    }

    let filterValue;

    filterValue = names.join(",");

    const actionPayload = {
      filterColumn,
      filterValue,
      searchVal: "",
      sortColumn: "",
      sortType: "",
      auditPageFilter: AuditPageFilter,
    };
    // dispatch(getAuditByFilterThunk(actionPayload)); /c
    dispatch(setAuditFilterItems(actionPayload));
  };
  const handleCheckboxChangeAudit = (elem: any, type: any, index: any) => {
    let targetType;
    switch (true) {
      case type.includes("username"):
        targetType = "username";
        break;
      case type.includes("activity_type"):
        targetType = "activity_type";
        break;
      case type.includes("table_name"):
        targetType = "table_name";
        break;
      default:
        targetType = "screen";
    }

    const updatedSelectedTitles = selectedTitles?.includes(elem)
      ? selectedTitles?.filter((t) => t !== elem)
      : [...selectedTitles, elem];

    setSelectedTitles(updatedSelectedTitles);

    dispatch(setFilterOptionsAudit(updatedSelectedTitles));
    dispatch(setSelectedFilterItemsAudit(updatedSelectedTitles));
    handleChangeOfDropdownAudit(updatedSelectedTitles, targetType);
  };
  const searchValueAudit = useAppSelector(
    (state) => state?.audit?.searchValueAudit
  );

  // SET SEARCH ACTIVE WHEN RESULTS ALREADY EXIST FROM REDUX
  useEffect(() => {
    if (searchValueAudit !== "") {
      setState((prevData: any) => ({ ...prevData, selectedItem: Data[1] }));
      // inputRefStaff?.current?.focus();
      setState((prevData: any) => ({
        ...prevData,
        selectedFilterDropDownTwo: null,
      }));
    }
  }, [searchValueAudit]);
  const [inputValueTextAudit, setinputValueTextAudit] =
    useState(searchValueAudit);

  const handleInputChangeAudit = (e: string | any) => {
    const newInputValue = e.target.value;
    setinputValueTextAudit(newInputValue);

    const actionPayload = {
      filterColumn: "",
      filterValue: "",
      searchVal: newInputValue,
      sortColumn: "",
      sortType: "",
      auditPageFilter: AuditPageFilter,
    };
    dispatch(setSearchValueAudit(newInputValue));
    // dispatch(getAuditByFilterThunk(actionPayload));
    dispatch(setAuditFilterItems(actionPayload));
  };

  const handleClearSearchAudit = () => {
    setselectedItemIndexAssociations(null);
    setSelectedItemIndex(null);
    setselectedSortIndex(null);
    setinputValueTextAudit("");
    dispatch(resetSearchValueAudit());
    // dispatch(getAuditThunk(AuditPage));
    dispatch(resetAuditFilterItems());
  };

  const [sortByAudit, setSortByAudit] = useState(sortByAudits);

  const handleSortChangeAudit = (val: string, selectedSortIndex: number) => {
    const capturedVal = val === "newest on top" ? "asc" : "desc";
    setSortByAudit(val);
    dispatch(setSortByAudits(val));

    let sortColumn = "";
    switch (AuditTableDataObject.selectedSortIndex) {
      case 0:
        sortColumn = "created_at";
        break;
      case 1:
        sortColumn = "username";
        break;
      case 2:
        sortColumn = "desc";
        break;
      case 3:
        sortColumn = "activity_type";
        break;
      case 4:
        sortColumn = "table_name";
        break;
      default:
        sortColumn = "screen";
        break;
    }

    const filterData = {
      filterColumn: "",
      filterValue: "",
      searchVal: "",
      sortColumn,
      sortType: capturedVal,
      auditPageFilter: AuditPageFilter,
    };
    const filterDataPagination = {
      val,
      filterColumn: "",
      filterValue: "",
      searchVal: "",
      sortColumn,
      sortType: capturedVal,
      auditPageFilter: AuditPageFilter,
    };
    // dispatch(getAuditByFilterThunk(filterData));
    dispatch(setAuditFilterItems(filterDataPagination));
  };
  // Audit Get Username
  // useEffect(() => {
  // dispatch(getAuditUsernameThunk());
  // dispatch(getAuditActivityTypeThunk());
  // dispatch(getAuditTableNameThunk());
  // }, []);
  const {
    getAuditUsernameData,
    getAuditActivityTypeData,
    getAuditTableNameData,
  } = useAppSelector((state) => state?.audit);

  const AuditUsername = getAuditUsernameData?.data?.data
    ?.map((data: any) => data.username)
    ?.filter((username: any) => username !== null);

  // Audit Get Activity Type
  // const { } = useAppSelector((state) => state?.audit);
  const AuditActivityType = getAuditActivityTypeData?.data?.data
    ?.map((data: any) => data?.activity_type)
    ?.filter((activity: any) => activity !== null);
  // Audit Get Table Name

  // const { } = useAppSelector((state) => state?.audit);
  const AuditTableName = getAuditTableNameData?.data?.data
    ?.map((data: any) => data?.table_name)
    ?.filter((table: any) => table !== null);

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////// BUSINESS TABLE //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const { sortByBusinessR, BusinessPage } = useAppSelector(
    (state) => state?.business
  );
  // @ts-ignore
  const dynamicBusinessId = parseInt(localStorage.getItem("dynamicBusinessId"));
  // const dynamicBusinessId = useAppSelector(
  //   (state) => state?.business?.getUserTypeIdForBusiness?.id
  const handleChangeOfDropdownBusiness = (names: any, type: any) => {
    let filterColumn;

    switch (type) {
      case "name":
        filterColumn = "name";
        break;
      case "email":
        filterColumn = "email";
        break;
      case "phone":
        filterColumn = "phone";
        break;
      default:
        const statusMap: any = {
          Inactive: 0,
          Active: 1,
        };
        const numericalValues = names.map((name: any) => statusMap[name]);
        filterColumn = "status";
        names = numericalValues;
    }

    let filterValue;

    filterValue = names.join(",");

    if (names.length !== 0) {
      // dispatch(
      //   getBusinessByFilterThunk({
      //     filterColumn,
      //     filterValue,
      //     searchVal: "",
      //     sortColumn: "",
      //     sortType: "",
      //     status: "",
      //     dynamicId: dynamicBusinessId,
      //     BusinessPage,
      //   })
      // );
      dispatch(
        setBusinessFilterItems({
          filterColumn,
          filterValue,
          searchVal: "",
          sortColumn: "",
          sortType: "",
          status: "",
          dynamicId: dynamicBusinessId,
          BusinessPage,
        })
      );
    } else {
      // dispatch(
      //   getBusinessByFilterThunk({
      //     filterColumn,
      //     filterValue,
      //     searchVal: "",
      //     sortColumn: "",
      //     sortType: "",
      //     status: "1",
      //     dynamicId: dynamicBusinessId,
      //     BusinessPage,
      //   })
      // );
      dispatch(
        setBusinessFilterItems({
          filterColumn,
          filterValue,
          searchVal: "",
          sortColumn: "",
          sortType: "",
          status: "1",
          dynamicId: dynamicBusinessId,
          BusinessPage,
        })
      );
    }
  };
  const handleCheckboxChangeBusiness = (elem: any, type: any, index: any) => {
    let targetType;
    switch (true) {
      case type.includes("name"):
        targetType = "name";
        break;
      case type.includes("email"):
        targetType = "email";
        break;
      case type.includes("phone"):
        targetType = "phone";
        break;
      default:
        targetType = "status";
    }
    // @ts-ignore
    // @ts-ignore
    const updatedSelectedTitles = selectedTitles?.includes(elem)
      ? selectedTitles?.filter((t) => t !== elem)
      : [...selectedTitles, elem];

    setSelectedTitles(updatedSelectedTitles);
    dispatch(setSelectedFilterItemsBusiness(updatedSelectedTitles));
    handleChangeOfDropdownBusiness(updatedSelectedTitles, targetType);
  };

  const [inputValueTextBusiness, setinputValueTextBusiness] = useState("");

  const handleInputChangeBusiness = (e: string | any) => {
    const newInputValue = e.target.value;
    setinputValueTextBusiness(newInputValue);
    if (newInputValue === "" || newInputValue === null) {
      dispatch(
        setBusinessFilterItems({
          filterColumn: "",
          filterValue: "",
          searchVal: newInputValue,
          sortColumn: "",
          sortType: "",
          status: "1",
          dynamicId: dynamicBusinessId,
          BusinessPage,
        })
      );
      // dispatch(
      //   getBusinessByFilterThunk({
      //     filterColumn: "",
      //     filterValue: "",
      //     searchVal: newInputValue,
      //     sortColumn: "",
      //     sortType: "",
      //     status: "1",
      //     dynamicId: dynamicBusinessId,
      //     BusinessPage,
      //   })
      // );
    } else {
      // dispatch(
      //   getBusinessByFilterThunk({
      //     filterColumn: "",
      //     filterValue: "",
      //     searchVal: newInputValue,
      //     sortColumn: "",
      //     sortType: "",
      //     status: "",
      //     dynamicId: dynamicBusinessId,
      //     BusinessPage,
      //   })
      // );
      dispatch(
        setBusinessFilterItems({
          filterColumn: "",
          filterValue: "",
          searchVal: newInputValue,
          sortColumn: "",
          sortType: "",
          status: "",
          dynamicId: dynamicBusinessId,
          BusinessPage,
        })
      );
    }

    dispatch(setSearchValueBusiness(newInputValue));
  };

  const handleClearSearchBusiness = () => {
    setinputValueTextBusiness("");
    dispatch(resetSearchValueBusiness());
    // dispatch(getBusinessThunk(dynamicBusinessId));
    dispatch(
      setBusinessFilterItems({
        filterColumn: "",
        filterValue: "",
        searchVal: "",
        sortColumn: "",
        sortType: "",
        status: "",
        dynamicId: dynamicBusinessId,
        BusinessPage,
      })
    );
  };

  const [sortByBusiness, setSortByBusiness] = useState(sortByBusinessR);
  const handleSortChangeBusiness = (val: string, selectedSortIndex: number) => {
    const capturedVal = val === "newest on top" ? "asc" : "desc";
    setSortByBusiness(val);
    dispatch(setSortByBusinessR(val));

    let sortColumn = "";
    switch (businessTableDataObject.selectedSortIndex) {
      case 0:
        sortColumn = "name";
        break;
      case 1:
        sortColumn = "email";
        break;
      default:
        sortColumn = "phone";
        break;
    }

    const actionPayload = {
      filterColumn: "",
      filterValue: "",
      searchVal: "",
      sortColumn,
      sortType: capturedVal,
      status: "1",
      dynamicId: dynamicBusinessId,
      BusinessPage,
    };
    dispatch(setBusinessFilterItems(actionPayload));
    // dispatch(getBusinessByFilterThunk(actionPayload));
  };

  const {
    getBusinessName,
    getBusinessEmail,
    getBusinessPhone,
    getBusinessStatus,
  } = useAppSelector((state) => state?.business);

  const BusinessName = getBusinessName?.data?.data?.map(
    (data: any) => `${data?.first_name || ""} ${data?.last_name || ""}`
  );

  const BusinessEmail = getBusinessEmail?.data?.data?.map(
    (data: any) => data?.email
  );
  const BusinessPhone = getBusinessPhone?.data?.data
    ?.map((data: any) => data?.phone)
    ?.filter((elem: any) => elem !== null);

  const BusinessStatus = getBusinessStatus?.data?.data?.map(
    (data: any) => data?.status
  );
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////// LOCATION TABLE //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const { sortByLocationR, LocationPage } = useAppSelector(
    (state) => state?.location
  );
  // @ts-ignore
  const dynamicLocationId = parseInt(localStorage.getItem("dynamicLocationId"));

  const handleChangeOfDropdownLocation = (names: any, type: any) => {
    let filterColumn;

    switch (type) {
      case "name":
        filterColumn = "name";
        break;
      case "email":
        filterColumn = "email";
        break;
      case "phone":
        filterColumn = "phone";
        break;
      default:
        const statusMap: any = {
          Inactive: 0,
          Active: 1,
        };
        const numericalValues = names.map((name: any) => statusMap[name]);
        filterColumn = "status";
        names = numericalValues;
    }

    let filterValue;

    filterValue = names.join(",");

    if (names.length !== 0) {
      // dispatch(
      //   getLocationUserByFilterThunk({
      //     filterColumn,
      //     filterValue,
      //     searchVal: "",
      //     sortColumn: "",
      //     sortType: "",
      //     status: "",
      //     dynamicId: dynamicLocationId,
      //     LocationPage,
      //   })
      // );
      dispatch(
        setLocationFilterItems({
          filterColumn,
          filterValue,
          searchVal: "",
          sortColumn: "",
          sortType: "",
          status: "",
          dynamicId: dynamicLocationId,
          LocationPage,
        })
      );
    } else {
      // dispatch(
      //   getLocationUserByFilterThunk({
      //     filterColumn,
      //     filterValue,
      //     searchVal: "",
      //     sortColumn: "",
      //     sortType: "",
      //     status: "1",
      //     dynamicId: dynamicLocationId,
      //     LocationPage
      //   })
      // );
      dispatch(
        setLocationFilterItems({
          filterColumn,
          filterValue,
          searchVal: "",
          sortColumn: "",
          sortType: "",
          status: "1",
          dynamicId: dynamicLocationId,
          LocationPage,
        })
      );
    }
  };
  const handleCheckboxChangeLocation = (elem: any, type: any, index: any) => {
    let targetType;
    switch (true) {
      case type.includes("name"):
        targetType = "name";
        break;
      case type.includes("email"):
        targetType = "email";
        break;
      case type.includes("phone"):
        targetType = "phone";
        break;
      default:
        targetType = "status";
    }
    // @ts-ignore
    const updatedSelectedTitles = selectedTitles?.includes(elem)
      ? selectedTitles?.filter((t) => t !== elem)
      : [...selectedTitles, elem];

    setSelectedTitles(updatedSelectedTitles);
    dispatch(setSelectedFilterItemsLocation(updatedSelectedTitles));
    handleChangeOfDropdownLocation(updatedSelectedTitles, targetType);
  };

  const [inputValueTextLocation, setinputValueTextLocation] = useState("");

  const handleInputChangeLocation = (e: string | any) => {
    const newInputValue = e.target.value;
    setinputValueTextLocation(newInputValue);
    if (newInputValue === "" || newInputValue === null) {
      // dispatch(
      //   getLocationUserByFilterThunk({
      //     filterColumn: "",
      //     filterValue: "",
      //     searchVal: newInputValue,
      //     sortColumn: "",
      //     sortType: "",
      //     status: "1",
      //     dynamicId: dynamicLocationId,
      //     LocationPage
      //   })
      // );
      dispatch(
        setLocationFilterItems({
          filterColumn: "",
          filterValue: "",
          searchVal: newInputValue,
          sortColumn: "",
          sortType: "",
          status: "1",
          dynamicId: dynamicLocationId,
          LocationPage,
        })
      );
    } else {
      // dispatch(
      //   getLocationUserByFilterThunk({
      //     filterColumn: "",
      //     filterValue: "",
      //     searchVal: newInputValue,
      //     sortColumn: "",
      //     sortType: "",
      //     status: "",
      //     dynamicId: dynamicLocationId,
      //     LocationPage
      //   })
      // );
      dispatch(
        setLocationFilterItems({
          filterColumn: "",
          filterValue: "",
          searchVal: newInputValue,
          sortColumn: "",
          sortType: "",
          status: "",
          dynamicId: dynamicLocationId,
          LocationPage,
        })
      );
    }
    dispatch(setSearchValueLocation(newInputValue));
  };

  const handleClearSearchLocation = () => {
    setinputValueTextLocation("");
    dispatch(resetSearchValueLocation());
    // dispatch(getLocationUserThunk(dynamicLocationId));
    dispatch(
      setLocationFilterItems({
        filterColumn: "",
        filterValue: "",
        searchVal: "",
        sortColumn: "",
        sortType: "",
        status: "1",
        dynamicId: dynamicLocationId,
        LocationPage,
      })
    );
  };

  const [sortByLocation, setSortByLocation] = useState(sortByLocationR);
  const handleSortChangeLocation = (val: string, selectedSortIndex: number) => {
    const capturedVal = val === "newest on top" ? "asc" : "desc";
    setSortByLocation(val);
    dispatch(setSortByLocationR(val));

    let sortColumn = "";
    switch (locationTableDataObject.selectedSortIndex) {
      case 0:
        sortColumn = "name";
        break;
      case 1:
        sortColumn = "email";
        break;
      default:
        sortColumn = "phone";
        break;
    }

    const actionPayload = {
      filterColumn: "",
      filterValue: "",
      searchVal: "",
      sortColumn,
      sortType: capturedVal,
      status: "1",
      dynamicId: dynamicLocationId,
      LocationPage,
    };
    // dispatch(getLocationUserByFilterThunk(actionPayload));
    dispatch(setLocationFilterItems(actionPayload));
  };

  const {
    getLocationName,
    getLocationEmail,
    getLocationPhone,
    getLocationStatus,
  } = useAppSelector((state) => state?.location);

  const LocationName = getLocationName?.data?.data?.map(
    (data: any) => data?.first_name
  );

  const LocationEmail = getLocationEmail?.data?.data?.map(
    (data: any) => data?.email
  );
  const LocationPhone = getLocationPhone?.data?.data
    ?.map((data: any) => data?.phone)
    ?.filter((elem: any) => elem !== null);
  const LocationStatus = getLocationStatus?.data?.data?.map(
    (data: any) => data?.status
  );
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////// TEAMS TABLE //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  //  const {
  //    selectedFilterOptionsAudit,
  //    selectedSortOptionsAudit,
  //    searchValuesAudit,
  //    AuditPage,
  //    AuditPageFilter,
  //  } = useAppSelector((state) => state?.audit); /c
  // @ts-ignore
  const dynamicTeamsId = parseInt(localStorage.getItem("dynamicTeamsId"));
  // const dynamicTeamsId = useAppSelector(
  //   (state) => state?.teams?.getUserTypeIdForTeams?.id
  // );
  const handleChangeOfDropdownTeams = (names: any, type: any) => {
    let filterColumn;

    switch (type) {
      case "name":
        filterColumn = "name";
        break;
      case "email":
        filterColumn = "email";
        break;
      case "phone":
        filterColumn = "phone";
        break;
      case "business_name":
        filterColumn = "business_name";
        break;
      default:
        const statusMap: any = {
          Inactive: 0,
          Active: 1,
        };
        const numericalValues = names.map((name: any) => statusMap[name]);
        filterColumn = "status";
        names = numericalValues;
    }

    let filterValue;

    filterValue = names.join(",");

    if (names.length !== 0) {
      // dispatch(
      //   getTeamsByFilterThunk({
      //     filterColumn,
      //     filterValue,
      //     searchVal: "",
      //     sortColumn: "",
      //     sortType: "",
      //     status: "",
      //     dynamicId: dynamicTeamsId,
      //     TeamsPage
      //   })
      // );
      dispatch(
        setTeamsFilterItems({
          filterColumn,
          filterValue,
          searchVal: "",
          sortColumn: "",
          sortType: "",
          status: "",
          dynamicId: dynamicTeamsId,
          TeamsPage,
        })
      );
    } else {
      // dispatch(
      //   getTeamsByFilterThunk({
      //     filterColumn,
      //     filterValue,
      //     searchVal: "",
      //     sortColumn: "",
      //     sortType: "",
      //     status: "1",
      //     dynamicId: dynamicTeamsId,
      //     TeamsPage
      //   })
      // );
      dispatch(
        setTeamsFilterItems({
          filterColumn,
          filterValue,
          searchVal: "",
          sortColumn: "",
          sortType: "",
          status: "1",
          dynamicId: dynamicTeamsId,
          TeamsPage,
        })
      );
    }

    const actionPayload = {
      filterColumn,
      filterValue,
      searchVal: "",
      sortColumn: "",
      sortType: "",
      status: "",
      dynamicId: dynamicTeamsId,
    };
  };
  const handleCheckboxChangeTeams = (elem: any, type: any, index: any) => {
    let targetType;
    switch (true) {
      case type.includes("name"):
        targetType = "name";
        break;
      case type.includes("email"):
        targetType = "email";
        break;
      case type.includes("phone"):
        targetType = "phone";
        break;
      case type.includes("businessAssociate"):
        targetType = "business_name";
        break;
      default:
        targetType = "status";
    }
    const updatedSelectedTitles = selectedTitles?.includes(elem)
      ? selectedTitles?.filter((t) => t !== elem)
      : [...selectedTitles, elem];

    setSelectedTitles(updatedSelectedTitles);

    dispatch(setSelectedFilterItemsTeams(updatedSelectedTitles));
    handleChangeOfDropdownTeams(updatedSelectedTitles, targetType);
  };
  //  const searchValueAudit = useAppSelector(
  //    (state) => state?.audit?.searchValueAudit
  //  ); /c

  // SET SEARCH ACTIVE WHEN RESULTS ALREADY EXIST FROM REDUX
  //  useEffect(() => {
  //    if (searchValueAudit !== "") {
  //      setState((prevData: any) => ({ ...prevData, selectedItem: Data[1] }));
  //      // inputRefStaff?.current?.focus();
  //      setState((prevData: any) => ({
  //        ...prevData,
  //        selectedFilterDropDownTwo: null,
  //      }));
  //    }
  //  }, [searchValueAudit]); /c
  //  const [inputValueTextBusiness, setinputValueTextBusiness] =
  //    useState(searchValueAudit); /c
  const [inputValueTextTeams, setinputValueTextTeams] = useState("");

  const handleInputChangeTeams = (e: string | any) => {
    const newInputValue = e.target.value;
    setinputValueTextTeams(newInputValue);

    if (newInputValue === "" || newInputValue === null) {
      // dispatch(
      //   getTeamsByFilterThunk({
      //     filterColumn: "",
      //     filterValue: "",
      //     searchVal: newInputValue,
      //     sortColumn: "",
      //     sortType: "",
      //     status: "1",
      //     dynamicId: dynamicTeamsId,
      //     TeamsPage
      //   })
      // );
      dispatch(
        setTeamsFilterItems({
          filterColumn: "",
          filterValue: "",
          searchVal: newInputValue,
          sortColumn: "",
          sortType: "",
          status: "1",
          dynamicId: dynamicTeamsId,
          TeamsPage,
        })
      );
    } else {
      // dispatch(
      //   getTeamsByFilterThunk({
      //     filterColumn: "",
      //     filterValue: "",
      //     searchVal: newInputValue,
      //     sortColumn: "",
      //     sortType: "",
      //     status: "",
      //     dynamicId: dynamicTeamsId,
      //     TeamsPage
      //   })
      // );
      dispatch(
        setTeamsFilterItems({
          filterColumn: "",
          filterValue: "",
          searchVal: newInputValue,
          sortColumn: "",
          sortType: "",
          status: "",
          dynamicId: dynamicTeamsId,
          TeamsPage,
        })
      );
    }

    dispatch(setSearchValueTeams(newInputValue));

    //  dispatch(setAuditFilterItems(actionPayload)); /c
  };

  const handleClearSearchTeams = () => {
    setselectedItemIndexAssociations(null);
    setSelectedItemIndex(null);
    setselectedSortIndex(null);
    setinputValueTextTeams("");
    dispatch(resetSearchValueTeams());
    // dispatch(getTeamsThunk(dynamicTeamsId));
    dispatch(
      setTeamsFilterItems({
        filterColumn: "",
        filterValue: "",
        searchVal: "",
        sortColumn: "",
        sortType: "",
        status: "",
        dynamicId: dynamicTeamsId,
        TeamsPage,
      })
    );
  };
  const { sortByTeamsR, TeamsPage } = useAppSelector((state) => state?.teams);
  const [sortByTeams, setSortByTeams] = useState(sortByTeamsR);
  const handleSortChangeTeams = (val: string, selectedSortIndex: number) => {
    const capturedVal = val === "newest on top" ? "asc" : "desc";
    setSortByTeams(val);
    dispatch(setSortByTeamsR(val));

    let sortColumn = "";
    switch (teamsTableDataObject.selectedSortIndex) {
      case 0:
        sortColumn = "name";
        break;
      case 1:
        sortColumn = "email";
        break;
      case 2:
        sortColumn = "phone";
        break;
      case 3:
        sortColumn = "associate_business";
        break;
      default:
        sortColumn = "status";
        break;
    }

    const actionPayload = {
      filterColumn: "",
      filterValue: "",
      searchVal: "",
      sortColumn,
      sortType: capturedVal,
      status: "1",
      dynamicId: dynamicTeamsId,
      TeamsPage,
    };
    dispatch(setTeamsFilterItems(actionPayload));
    // dispatch(getTeamsByFilterThunk(actionPayload));
  };

  // useEffect(() => {
  //   dispatch(getTeamsNameThunk(dynamicTeamsId));
  //   dispatch(getTeamsEmailThunk(dynamicTeamsId));
  //   dispatch(getTeamsPhoneThunk(dynamicTeamsId));
  //   dispatch(getTeamsAssociateThunk(dynamicTeamsId));
  // }, [dynamicTeamsId]);

  const { getTeamsName, getTeamsEmail, getTeamsPhone, getTeamsAssociate } =
    useAppSelector((state) => state?.teams);

  const TeamsName = getTeamsName?.data?.data?.map(
    (data: any) => data?.first_name
  );

  const TeamsEmail = getTeamsEmail?.data?.data
    ?.map((data: any) => data?.email)
    .filter((elem: any) => elem !== null);
  const TeamsPhone = getTeamsPhone?.data?.data
    ?.map((data: any) => data?.phone)
    .filter((elem: any) => elem !== null);

  const [teamsAssociateState, setTeamsAssociateState] = useState<any>([]);
  const [teamsAssociateData, setTeamsAssociateData] = useState<any>([]);
  const [teamsBusinessName, setTeamsBusinessName] = useState<any>([]);

  useEffect(() => {
    if (getTeamsAssociate?.data?.data) {
      setTeamsAssociateState(getTeamsAssociate?.data?.data);
    }
  }, [getTeamsAssociate]);

  useEffect(() => {
    if (teamsAssociateState) {
      let data = teamsAssociateState?.map((elem: any) => {
        return elem?.business ? elem?.business : [];
      });
      setTeamsAssociateData(data);
    }
  }, [teamsAssociateState]);

  useEffect(() => {
    if (teamsAssociateData.flat(1).length) {
      setTeamsBusinessName(
        teamsAssociateData.flat(1)?.map((elem: any) => elem?.name)
      );
    }
  }, [teamsAssociateData]);

  // const TeamsAssociateData = teamsAssociateState?.map(
  //   (elem: any) => elem?.business
  // );
  // // console.log(TeamsAssociateData, "qw");

  // const teamsBusinessName = TeamsAssociateData?.map((data: any) => data?.name);

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////// DOCUMENTS TABLE //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const currentSearch = useAppSelector(
    (state) => state?.people?.searchValueClients
  );

  const getAllDocuments = useAppSelector(
    (state) => state?.document?.getAllDocuments
  );

  const uniquePrimaryApprovers = useAppSelector(
    (state) => state?.document?.uniquePrimaryApprovers
  );

  const [uniquePrimaryApproversArr, setUniquePrimaryApproversArr] =
    useState<any>([]);

  // @ts-ignore
  useEffect(() => {
    if (uniquePrimaryApprovers[0]?.primary_approvers?.length > 0) {
      let primaryApprovers = uniquePrimaryApprovers[0]?.primary_approvers?.map(
        (elem: any) => `${elem.first_name} ${elem.last_name}`
      );
      setUniquePrimaryApproversArr(primaryApprovers);
    }
  }, [uniquePrimaryApprovers, clientId]);

  // // console.log(getAllDocuments[0]?.primary_approvers?.map(
  //   (elem: any) => `${elem.first_name} ${elem.last_name}`
  // ),"uniquePrimaryApproversArr")

  // SET SEARCH ACRIVE WHEN RESULTS ALREADY EXIST FROM REDUX
  useEffect(() => {
    if (currentSearch !== "") {
      setState((prevData) => ({
        ...prevData,
        selectedItem: Data[1],
        selectedFilterDropDownTwo: null,
      }));
      // inputRefClients?.current?.focus();
    }
  }, [currentSearch]);

  const { prospect_status } = domainDetail;
  const [inputValueText, setInputValueText] = useState(inputValueFromRedux);
  const [inputValueTextProviders, setinputValueTextProviders] = useState("");

  const [inputValueTextAssociations, setInputValueTextAssociations] = useState(
    searchValAssociations
  );
  const [inputValueTextClients, setInputValueTextClients] =
    useState(currentSearch);
  const [inputValueTextProspects, setInputValueTextProspects] =
    useState(searchValProspects);
  const [inputValueTextContacts, setinputValueTextContacts] =
    useState(searchValContacts);

  const handleClearInputFieldProviders = () => {
    setinputValueTextProviders("");
    dispatch(resetinputSearchValProviders(""));
    dispatch(
      setProvidersFilterItems({
        status: "1",
        title: "",
        searchVal: "",
        sortType: "",
        filterColumn: "",
        sortColumn: "",
        providersPage,
      })
    );
  };
  const {
    selectedDropdownItemProviders,
    selectedItemIndexFilterProviders,
    selectedItemIndexSortProviders,
    sortByProviderR,
  } = useAppSelector((state) => state?.providers);
  const { sortByAssociationsR } = useAppSelector(
    (state) => state?.associations
  );
  const { sortByDocumentsR } = useAppSelector((state) => state?.document);
  const [sortByAssociaiton, setsortByAssociaiton] =
    useState(sortByAssociationsR);

  const [sortByDocument, setSortByDocument] = useState(sortByDocumentsR);
  const [sortByProvider, setSortByProvider] = useState(sortByProviderR);

  const handleSortChange = (val: any, selectedSortIndex: any) => {
    const capturedVal = val === "newest on top" ? "asc" : "desc";
    setSortByDocument(val);
    dispatch(setSortByDocumentsR(val));

    let sortColumn = "";
    switch (documentTableDataObject.selectedSortIndex) {
      case 0:
        sortColumn = "title";
        break;
      case 1:
        sortColumn = "primary_review";
        break;
      default:
        sortColumn = "primary_approver";
        break;
    }
    if (pathnameBusinessAndTeams) {
      dispatch(
        getDocumentsByFilterForEntity({
          clientId,
          title: "",
          searchVal: "",
          sortType: capturedVal,
          filterColumn: "",
          sortColumn,
          providerId,
        })
      );
    } else if (clientId) {
      dispatch(
        getDocumentsByFilter({
          clientId,
          filterValue: "",
          searchVal: "",
          sortType: capturedVal,
          filterColumn: "",
          sortColumn,
          providerId,
        })
      );
    }
  };

  const handleSortChangeProvider = (val: any, selectedSortIndex: any) => {
    const capturedVal = val === "newest on top" ? "asc" : "desc";
    setSortByProvider(val);
    dispatch(setsortByProviderR(val));

    let sortColumn = "";
    switch (providerTableDataObject.selectedSortIndex) {
      case 0:
        sortColumn = "tenant_name";
        break;
      case 1:
        sortColumn = "tenant_type";
        break;

      default:
        sortColumn = "";
        break;
    }
    if (pathnameBusinessAndTeams) {
      // dispatch(
      //   getProvidersByFilterThunk({
      //     clientId,
      //     title: "",
      //     searchVal: "",
      //     sortType: capturedVal,
      //     filterColumn: "",
      //     sortColumn,
      //   })
      // );
      dispatch(
        setProvidersFilterItems({
          status: 1,
          title: "",
          searchVal: "",
          sortType: capturedVal,
          filterColumn: "",
          sortColumn,
          providersPage,
        })
      );
    } else {
      // dispatch(
      //   getProvidersByFilterThunk({
      //     clientId,
      //     filterValue: "",
      //     searchVal: "",
      //     sortType: capturedVal,
      //     filterColumn: "",
      //     sortColumn,
      //   })
      // );
      dispatch(
        setProvidersFilterItems({
          status: 1,
          title: "",
          searchVal: "",
          sortType: capturedVal,
          filterColumn: "",
          sortColumn,
          providersPage,
        })
      );
    }
  };

  // SORT ASSOCIATIONS HANDLER ON BASIS OF INDEX
  const handleSortChangeAssociations = (
    val: any,
    setState: any,
    selectedSortIndex: any
  ) => {
    let capturedVal = val === "newest on top" ? "asc" : "desc";
    setsortByAssociaiton(val);
    dispatch(setSortByAssociationsR(val));

    let sortColumn = "";
    switch (associationTableDataObject.selectedSortIndex) {
      case 0:
        sortColumn = "name";
        break;
      case 1:
        sortColumn = "contact_category";
        break;
      case 2:
        sortColumn = "relationship_name";
        break;

      default:
        sortColumn = "contact_number";
        break;
    }
    if (pathnameBusinessAndTeams) {
      dispatch(
        getAssociationsEntityByFilter({
          clientId,
          filterVal: "",
          columnType: sortColumn,
          searchVal: "",
          sortType: capturedVal,
        })
      );
    } else {
      dispatch(
        getAssociationsByFilter({
          clientId,
          filterVal: "",
          columnType: sortColumn,
          uniqueCol: "",
          searchVal: "",
          sortType: capturedVal,
        })
      );
    }
  };

  // SORTING ACTION HERE FOR DOCUMENTS
  useEffect(() => {
    if (selectedSortBy !== getTypeOfSort) {
      if (selectedSortBy?.includes("newest on top")) {
        dispatch(setsortType("newest on top"));
        if (pathnameBusinessAndTeams) {
          dispatch(
            getDocumentsByFilterForEntity({
              clientId,
              title: "",
              searchVal: "",
              sortType: "asc",
              filterColumn: "",
              sortColumn: selectedColumnOfDocumentsForDocumentSort,
              providerId,
            })
          );
        } else if (clientId) {
          dispatch(
            getDocumentsByFilter({
              clientId,
              filterValue: "",
              searchVal: "",
              sortType: "asc",
              filterColumn: "",
              sortColumn: selectedColumnOfDocumentsForDocumentSort,
              providerId,
            })
          );
        }
      } else if (selectedSortBy?.includes("oldest on top")) {
        dispatch(setsortType("oldest on top"));
        if (pathnameBusinessAndTeams) {
          dispatch(
            getDocumentsByFilterForEntity({
              clientId,
              title: "",
              searchVal: "",
              sortType: "desc",
              filterColumn: "",
              sortColumn: selectedColumnOfDocumentsForDocumentSort,
              providerId,
            })
          );
        } else if (clientId) {
          dispatch(
            getDocumentsByFilter({
              clientId,
              filterValue: "",
              searchVal: "",
              sortType: "desc",
              filterColumn: "",
              sortColumn: selectedColumnOfDocumentsForDocumentSort,
              providerId,
            })
          );
        }
      }
    }
  }, [
    selectedSortBy,
    getTypeOfSort,
    selectedColumnOfDocumentsForDocumentSort,
    pathnameBusinessAndTeams,
  ]);

  // CLIENTS
  const selectedTitleClients = useAppSelector(
    (state) => state?.people?.selectedDropdownItemClients
  );
  const selectedItemIndexFilter = useAppSelector(
    (state) => state?.people?.selectedItemIndexFilter
  );
  const selectedItemIndexSort = useAppSelector(
    (state) => state?.people?.selectedItemIndexSort
  );

  // MEALS
  const {
    selectedDropdownItemMeals,
    selectedItemIndexFilterMeals,
    selectedItemIndexSortMeals,
    sortByMeals,
  } = useAppSelector((state) => state?.mealBuilder);

  // Prospect
  const {
    selectedDropdownItemProspect,
    selectedItemIndexFilterProspect,
    selectedItemIndexSortProspect,
    sortByProspect,
  } = useAppSelector((state) => state?.prospects);

  //Provider Prospect
  const {
    selectedDropdownItemProviderProspect,
    selectedItemIndexFilterProviderProspect,
    selectedItemIndexSortProviderProspect,
    sortByProviderProspect,
  } = useAppSelector((state) => state?.providerProspect);

  // Contacts
  const {
    selectedDropdownItemContact,
    selectedItemIndexFilterContact,
    selectedItemIndexSortContact,
    sortByContacts,
  } = useAppSelector((state) => state?.contacts);

  const [clientsTableDataObject, setclientsTableDataObject] = useState<any>({
    currentActive: selectedTitleClients,
    selectedItemIndex: selectedItemIndexFilter,
    selectedSortIndex: selectedItemIndexSort,
  });
  const [mealsTableDataObject, setmealsTableDataObject] = useState<any>({
    currentActive: selectedDropdownItemMeals,
    selectedItemIndex: selectedItemIndexFilterMeals,
    selectedSortIndex: selectedItemIndexSortMeals,
  });

  const [prospectsTableDataObject, setProspectsTableDataObject] = useState<any>(
    {
      currentActive: selectedDropdownItemProspect,
      selectedItemIndex: selectedItemIndexFilterProspect,
      selectedSortIndex: selectedItemIndexSortProspect,
    }
  );

  // Provider Prospect
  const [
    providerProspectsTableDataObject,
    setProviderProspectsTableDataObject,
  ] = useState<any>({
    currentActive: selectedDropdownItemProviderProspect,
    selectedItemIndex: selectedItemIndexFilterProviderProspect,
    selectedSortIndex: selectedItemIndexSortProviderProspect,
  });

  const [contactsTableDataObject, setContactsTableDataObject] = useState<any>({
    currentActive: selectedDropdownItemContact,
    selectedItemIndex: selectedItemIndexFilterContact,
    selectedSortIndex: selectedItemIndexSortContact,
  });
  // Business
  const {
    selectedDropdownItemBusiness,
    selectedItemIndexFilterBusiness,
    selectedItemIndexSortBusiness,
  } = useAppSelector((state) => state?.business);

  const [businessTableDataObject, setBusinessTableDataObject] = useState<any>({
    currentActive: selectedDropdownItemBusiness,
    selectedItemIndex: selectedItemIndexFilterBusiness,
    selectedSortIndex: selectedItemIndexSortBusiness,
  });
  //location
  const {
    selectedDropdownItemLocation,
    selectedItemIndexFilterLocation,
    selectedItemIndexSortLocation,
  } = useAppSelector((state) => state?.location);
  const [locationTableDataObject, setLocationTableDataObject] = useState<any>({
    currentActive: selectedDropdownItemLocation,
    selectedItemIndex: selectedItemIndexFilterLocation,
    selectedSortIndex: selectedItemIndexSortLocation,
  });
  // Teams
  const {
    selectedDropdownItemTeams,
    selectedItemIndexFilterTeams,
    selectedItemIndexSortTeams,
  } = useAppSelector((state) => state?.teams);

  const [teamsTableDataObject, setTeamsTableDataObject] = useState<any>({
    currentActive: selectedDropdownItemTeams,
    selectedItemIndex: selectedItemIndexFilterTeams,
    selectedSortIndex: selectedItemIndexSortTeams,
  });
  // Association
  const {
    selectedDropdownItemAssociations,
    selectedItemIndexFilterAssociations,
    selectedItemIndexSortAssociations,
  } = useAppSelector((state) => state?.associations);
  const [associationTableDataObject, setAssociationTableDataObject] =
    useState<any>({
      currentActive: selectedDropdownItemAssociations,
      selectedItemIndex: selectedItemIndexFilterAssociations,
      selectedSortIndex: selectedItemIndexSortAssociations,
    });
  // Document
  const {
    selectedDropdownItemDocuments,
    selectedItemIndexFilterDocuments,
    selectedItemIndexSortDocuments,
  } = useAppSelector((state) => state?.document);

  const [documentTableDataObject, setDocumentTableDataObject] = useState<any>({
    currentActive: selectedDropdownItemDocuments,
    selectedItemIndex: selectedItemIndexFilterDocuments,
    selectedSortIndex: selectedItemIndexSortDocuments,
  });

  const [providerTableDataObject, setProviderTableDataObject] = useState<any>({
    currentActive: selectedDropdownItemProviders,
    selectedItemIndex: selectedItemIndexFilterProviders,
    selectedSortIndex: selectedItemIndexSortProviders,
  });

  // FIRST DROPDOWN COMMON HANDLER
  const handleItemClickDropdownOneDocuments = (
    id: any,
    icon: any,
    title: any,
    props: any
  ) => {
    ////////////////////////////////////////////// Audit TABLE DROPDOWN ////////////////
    if (props === "audit") {
      if (title.includes("filter")) {
        dispatch(resetSearchValueAudit());
        dispatch(resetSelectedItemIndexSortAudit());
        dispatch(resetSortByAudits());
        setAuditTableDataObject((prevState: any) => ({
          ...prevState,
          selectedSortIndex: null,
        }));
        dispatch(
          setAuditFilterItems({
            filterColumn: "",
            filterValue: "",
            searchVal: "",
            sortColumn: "",
            sortType: "",
            auditPageFilter: AuditPageFilter,
          })
        );
      } else if (title.includes("sort")) {
        dispatch(resetSearchValueAudit());
        dispatch(resetSelectedItemIndexFilterAudit());
        dispatch(resetSelectedFilterItemsAudit());
        setAuditTableDataObject((prevState: any) => ({
          ...prevState,
          selectedItemIndex: null,
        }));
        dispatch(
          setAuditFilterItems({
            filterColumn: "",
            filterValue: "",
            searchVal: "",
            sortColumn: "",
            sortType: "",
            auditPageFilter: AuditPageFilter,
          })
        );
      } else {
        dispatch(resetSelectedItemIndexFilterAudit());
        dispatch(resetSelectedItemIndexSortAudit());
        dispatch(resetSortByAudits());
        dispatch(resetSelectedFilterItemsAudit());
        dispatch(
          setAuditFilterItems({
            filterColumn: "",
            filterValue: "",
            searchVal: "",
            sortColumn: "",
            sortType: "",
            auditPageFilter: AuditPageFilter,
          })
        );
      }
      setAuditTableDataObject((prevState: any) => ({
        ...prevState,
        currentActive: title,
      }));
      dispatch(setSelectedDropdownItemAudit(title));
    }
    if (props === "business") {
      if (title.includes("filter")) {
        dispatch(resetSearchValueBusiness());
        dispatch(resetSelectedItemIndexSortBusiness());
        dispatch(resetSortByBusinessR());
        setBusinessTableDataObject((prevState: any) => ({
          ...prevState,
          selectedSortIndex: null,
        }));
        // dispatch(getBusinessThunk(dynamicBusinessId));
        // dispatch(
        //   setBusinessFilterItems({
        //     filterColumn: "",
        //     filterValue: "",
        //     searchVal: "",
        //     sortColumn: "",
        //     sortType: "",
        //     status: "",
        //     dynamicId: dynamicBusinessId,
        //     BusinessPage,
        //   })
        // );
      } else if (title.includes("sort")) {
        dispatch(resetSearchValueBusiness());
        dispatch(resetSelectedItemIndexFilterBusiness());
        dispatch(resetSelectedFilterItemsBusiness());
        setBusinessTableDataObject((prevState: any) => ({
          ...prevState,
          selectedItemIndex: null,
        }));
        // dispatch(getBusinessThunk(dynamicBusinessId));
        // dispatch(
        //   setBusinessFilterItems({
        //     filterColumn: "",
        //     filterValue: "",
        //     searchVal: "",
        //     sortColumn: "",
        //     sortType: "",
        //     status: "",
        //     dynamicId: dynamicBusinessId,
        //     BusinessPage,
        //   })
        // );
      } else {
        dispatch(resetSelectedItemIndexFilterBusiness());
        dispatch(resetSelectedItemIndexSortBusiness());
        dispatch(resetSortByBusinessR());
        dispatch(resetSelectedFilterItemsBusiness());
        // dispatch(getBusinessThunk(dynamicBusinessId));
        // dispatch(
        //   setBusinessFilterItems({
        //     filterColumn: "",
        //     filterValue: "",
        //     searchVal: "",
        //     sortColumn: "",
        //     sortType: "",
        //     status: "",
        //     dynamicId: dynamicBusinessId,
        //     BusinessPage,
        //   })
        // );
      }
      setBusinessTableDataObject((prevState: any) => ({
        ...prevState,
        currentActive: title,
      }));
      dispatch(setSelectedDropdownItemBusiness(title));
    }
    if (props === "locations") {
      if (title.includes("filter")) {
        dispatch(resetSearchValueLocation());
        dispatch(resetSelectedItemIndexSortLocation());
        dispatch(resetSortByLocationR());
        setLocationTableDataObject((prevState: any) => ({
          ...prevState,
          selectedSortIndex: null,
        }));
        // dispatch(getLocationUserThunk(dynamicLocationId));
        // dispatch(
        //   setLocationFilterItems({
        //     filterColumn: "",
        //     filterValue: "",
        //     searchVal: "",
        //     sortColumn: "",
        //     sortType: "",
        //     status: "1",
        //     dynamicId: dynamicLocationId,
        //     LocationPage,
        //   })
        // );
      } else if (title.includes("sort")) {
        dispatch(resetSearchValueLocation());
        dispatch(resetSelectedItemIndexFilterLocation());
        dispatch(resetSelectedFilterItemsLocation());
        setLocationTableDataObject((prevState: any) => ({
          ...prevState,
          selectedItemIndex: null,
        }));
        // dispatch(getLocationUserThunk(dynamicLocationId));
        // dispatch(
        //   setLocationFilterItems({
        //     filterColumn: "",
        //     filterValue: "",
        //     searchVal: "",
        //     sortColumn: "",
        //     sortType: "",
        //     status: "1",
        //     dynamicId: dynamicLocationId,
        //     LocationPage,
        //   })
        // );
      } else {
        dispatch(resetSelectedItemIndexFilterLocation());
        dispatch(resetSelectedItemIndexSortLocation());
        dispatch(resetSortByLocationR());
        dispatch(resetSelectedFilterItemsLocation());
        // dispatch(getLocationUserThunk(dynamicLocationId));
        // dispatch(
        //   setLocationFilterItems({
        //     filterColumn: "",
        //     filterValue: "",
        //     searchVal: "",
        //     sortColumn: "",
        //     sortType: "",
        //     status: "1",
        //     dynamicId: dynamicLocationId,
        //     LocationPage,
        //   })
        // );
      }
      setLocationTableDataObject((prevState: any) => ({
        ...prevState,
        currentActive: title,
      }));
      dispatch(setSelectedDropdownItemLocation(title));
    }
    if (props === "teams") {
      if (title.includes("filter")) {
        dispatch(resetSearchValueTeams());
        dispatch(resetSelectedItemIndexSortTeams());
        dispatch(resetSortByTeamsR());
        setTeamsTableDataObject((prevState: any) => ({
          ...prevState,
          selectedSortIndex: null,
        }));
        // dispatch(getTeamsThunk(dynamicTeamsId));
        // dispatch(
        //   setTeamsFilterItems({
        //     filterColumn: "",
        //     filterValue: "",
        //     searchVal: "",
        //     sortColumn: "",
        //     sortType: "",
        //     status: "",
        //     dynamicId: dynamicTeamsId,
        //     TeamsPage,
        //   })
        // );
      } else if (title.includes("sort")) {
        dispatch(resetSearchValueTeams());
        dispatch(resetSelectedItemIndexFilterTeams());
        dispatch(resetSelectedFilterItemsTeams());
        setTeamsTableDataObject((prevState: any) => ({
          ...prevState,
          selectedItemIndex: null,
        }));
        // dispatch(getTeamsThunk(dynamicTeamsId));
        // dispatch(
        //   setTeamsFilterItems({
        //     filterColumn: "",
        //     filterValue: "",
        //     searchVal: "",
        //     sortColumn: "",
        //     sortType: "",
        //     status: "",
        //     dynamicId: dynamicTeamsId,
        //     TeamsPage,
        //   })
        // );
      } else {
        dispatch(resetSelectedItemIndexFilterTeams());
        dispatch(resetSelectedItemIndexSortTeams());
        dispatch(resetSortByTeamsR());
        dispatch(resetSelectedFilterItemsTeams());
        // dispatch(getTeamsThunk(dynamicTeamsId));
        // dispatch(
        //   setTeamsFilterItems({
        //     filterColumn: "",
        //     filterValue: "",
        //     searchVal: "",
        //     sortColumn: "",
        //     sortType: "",
        //     status: "",
        //     dynamicId: dynamicTeamsId,
        //     TeamsPage,
        //   })
        // );
      }
      setTeamsTableDataObject((prevState: any) => ({
        ...prevState,
        currentActive: title,
      }));
      dispatch(setSelectedDropdownItemTeams(title));
    }

    if (props === "clients") {
      if (title.includes("filter")) {
        dispatch(resetsearchValueClients(""));
        dispatch(resetselectedItemIndexSortClients());
        dispatch(resetsortbyClients());
        setclientsTableDataObject((prevState: any) => ({
          ...prevState,
          selecredSortIndex: null,
        }));
        // dispatch(getClientsThunk({ dynamicClientId }));
        // dispatch(
        //   setClientFilterItems({
        //     filterColumn: "",
        //     filterValue: "",
        //     searchVal: "",
        //     sortColumn: "",
        //     sortType: "",
        //     status: "",
        //     dynamicId: dynamicClientId,
        //     ClientPage,
        //   })
        // );
      } else if (title.includes("sort")) {
        dispatch(resetsearchValueClients(""));
        dispatch(resetselectedItemIndexFilterClients());
        dispatch(resetselectedFilterItemsClients());
        setclientsTableDataObject((prevState: any) => ({
          ...prevState,
          selectedItemIndex: null,
        }));
        // dispatch(getClientsThunk({ dynamicClientId }));
        // dispatch(
        //   setClientFilterItems({
        //     filterColumn: "",
        //     filterValue: "",
        //     searchVal: "",
        //     sortColumn: "",
        //     sortType: "",
        //     status: "",
        //     dynamicId: dynamicClientId,
        //     ClientPage,
        //   })
        // );
      } else {
        dispatch(resetselectedItemIndexFilterClients());
        dispatch(resetselectedItemIndexSortClients());
        dispatch(resetsortbyClients());
        dispatch(resetselectedFilterItemsClients());
        // dispatch(getClientsThunk({ dynamicClientId }));
        // dispatch(
        //   setClientFilterItems({
        //     filterColumn: "",
        //     filterValue: "",
        //     searchVal: "",
        //     sortColumn: "",
        //     sortType: "",
        //     status: "",
        //     dynamicId: dynamicClientId,
        //     ClientPage,
        //   })
        // );
      }
      setclientsTableDataObject((prevState: any) => ({
        ...prevState,
        currentActive: title,
      }));

      dispatch(setselectedDropdownItemClients(title));
    }
    if (props === "meals") {
      if (title.includes("filter")) {
        dispatch(resetsearchValueMeals(""));
        dispatch(resetselectedItemIndexSortMeals());
        dispatch(resetsortbyMeals());
        setmealsTableDataObject((prevState: any) => ({
          ...prevState,
          selecredSortIndex: null,
        }));
        // dispatch(getMealBuilder());
      } else if (title.includes("sort")) {
        dispatch(resetsearchValueMeals(""));
        dispatch(resetselectedItemIndexFilterMeals());
        dispatch(resetselectedFilterItemsMeals());
        setmealsTableDataObject((prevState: any) => ({
          ...prevState,
          selectedItemIndex: null,
        }));
        // dispatch(getMealBuilder());
      } else {
        dispatch(resetselectedItemIndexFilterMeals());
        dispatch(resetselectedItemIndexSortMeals());
        dispatch(resetsortbyMeals());
        dispatch(resetselectedFilterItemsMeals());
        // dispatch(getMealBuilder());
      }
      setmealsTableDataObject((prevState: any) => ({
        ...prevState,
        currentActive: title,
      }));

      dispatch(setselectedDropdownItemClients(title));
    }
    if (props === "providers") {
      if (title.includes("filter")) {
        dispatch(resetinputSearchValProviders(""));
        dispatch(resetSelectedItemIndexSortProviders());
        dispatch(resetsortByProviderR());
        setProviderTableDataObject((prevState: any) => ({
          ...prevState,
          selectedSortIndex: null,
        }));
        // dispatch(getProvidersThunk());
        // dispatch(
        //   setProvidersFilterItems({
        //     status: 1,
        //     filterColumn: "",
        //     filterValue: "",
        //     searchVal: "",
        //     sortType: "",
        //     sortColumn: "",
        //     providersPage,
        //   })
        // );
      } else if (title.includes("sort")) {
        dispatch(resetinputSearchValProviders(""));
        dispatch(resetSelectedItemIndexFilterProviders());
        dispatch(resetSelectedFilterItemsProviders());
        setProviderTableDataObject((prevState: any) => ({
          ...prevState,
          selectedItemIndex: null,
        }));
        // dispatch(getProvidersThunk());
        // dispatch(
        //   setProvidersFilterItems({
        //     status: 1,
        //     filterColumn: "",
        //     filterValue: "",
        //     searchVal: "",
        //     sortType: "",
        //     sortColumn: "",
        //     providersPage,
        //   })
        // );
      } else {
        dispatch(resetSelectedItemIndexFilterProviders());
        dispatch(resetSelectedItemIndexSortProviders());
        dispatch(resetsortByProviderR());
        dispatch(resetSelectedFilterItemsProviders());
        // dispatch(getProvidersThunk());
        // dispatch(
        //   setProvidersFilterItems({
        //     status: 1,
        //     filterColumn: "",
        //     filterValue: "",
        //     searchVal: "",
        //     sortType: "",
        //     sortColumn: "",
        //     providersPage,
        //   })
        // );
      }
      setProviderTableDataObject((prevState: any) => ({
        ...prevState,
        currentActive: title,
      }));

      dispatch(setSelectedDropdownItemProviders(title));
    }
    if (props === "prospect") {
      if (title.includes("filter")) {
        // dispatch(resetsearchvalProspects());
        // dispatch(resetSelectedItemIndexSortProspect());
        // dispatch(resetSortByProspect());
        // setProspectsTableDataObject((prevState: any) => ({
        //   ...prevState,
        //   selectedSortIndex: null,
        // }));
        // dispatch(
        //   setProspectFilterItems({
        //     filterColumn: "",
        //     filterValue: "",
        //     searchVal: "",
        //     sortColumn: "",
        //     sortType: "",
        //     prospectPageFilter: ProspectPageFilter,
        //   })
        // );
        // dispatch(getProspectsThunk({ dynamicProspectsId }));
      } else if (title.includes("sort")) {
        dispatch(resetsearchvalProspects());
        dispatch(resetSelectedItemIndexFilterProspect());
        dispatch(resetSelectedFilterItemsProspect());
        setProspectsTableDataObject((prevState: any) => ({
          ...prevState,
          selectedItemIndex: null,
        }));
        // dispatch(
        //   setProspectFilterItems({
        //     filterColumn: "",
        //     filterValue: "",
        //     searchVal: "",
        //     sortColumn: "",
        //     sortType: "",
        //     prospectPageFilter: ProspectPageFilter,
        //   })
        // );
        // dispatch(getProspectsThunk({ dynamicProspectsId }));
      } else {
        // dispatch(resetSelectedItemIndexFilterProspect());
        // dispatch(resetSelectedItemIndexSortProspect());
        // dispatch(resetSortByProspect());
        // dispatch(resetSelectedFilterItemsProspect());
        // dispatch(getProspectsThunk({ dynamicProspectsId }));
      }
      setProspectsTableDataObject((prevState: any) => ({
        ...prevState,
        currentActive: title,
      }));
      // dispatch(
      //   setProspectFilterItems({
      //     filterColumn: "",
      //     filterValue: "",
      //     searchVal: "",
      //     sortColumn: "",
      //     sortType: "",
      //     prospectPageFilter: ProspectPageFilter,
      //   })
      // );
      // dispatch(setSelectedDropdownItemProspect(title));
    }

    if (props === "contact") {
      if (title.includes("filter")) {
        dispatch(resetsearchValueContacts());
        dispatch(resetSelectedItemIndexSortContact());
        dispatch(resetSortByContacts());
        setContactsTableDataObject((prevState: any) => ({
          ...prevState,
          selectedSortIndex: null,
        }));
        // dispatch(getContactsThunk({ dynamicContactId }));
        // dispatch(
        //   setContactFilterItems({
        //     dynamicId: dynamicContactId,
        //     filterColumn: "",
        //     filterValue: "",
        //     searchVal: "",
        //     sortColumn: "",
        //     sortType: "",
        //     status: "1",
        //     ContactPage,
        //   })
        // );
      } else if (title.includes("sort")) {
        dispatch(resetsearchValueContacts());
        dispatch(resetSelectedItemIndexFilterContact());
        dispatch(resetSelectedFilterItemsContact());
        setContactsTableDataObject((prevState: any) => ({
          ...prevState,
          selectedItemIndex: null,
        }));
        // dispatch(getContactsThunk({ dynamicContactId }));
        // dispatch(
        //   setContactFilterItems({
        //     dynamicId: dynamicContactId,
        //     filterColumn: "",
        //     filterValue: "",
        //     searchVal: "",
        //     sortColumn: "",
        //     sortType: "",
        //     status: "1",
        //     ContactPage,
        //   })
        // );
      } else {
        dispatch(resetSelectedItemIndexFilterContact());
        dispatch(resetSelectedItemIndexSortContact());
        dispatch(resetSortByContacts());
        dispatch(resetSelectedFilterItemsContact());
        // dispatch(getContactsThunk({ dynamicContactId }));
        // dispatch(
        //   setContactFilterItems({
        //     dynamicId: dynamicContactId,
        //     filterColumn: "",
        //     filterValue: "",
        //     searchVal: "",
        //     sortColumn: "",
        //     sortType: "",
        //     status: "1",
        //     ContactPage,
        //   })
        // );
      }
      setContactsTableDataObject((prevState: any) => ({
        ...prevState,
        currentActive: title,
      }));
      dispatch(setSelectedDropdownItemContact(title));
    }
    if (props === "association") {
      if (title.includes("filter")) {
        dispatch(resetinputSearchValAssociations(""));
        dispatch(resetSelectedItemIndexSortAssociations());
        dispatch(resetSortByAssociationsR());
        setAssociationTableDataObject((prevState: any) => ({
          ...prevState,
          selectedSortIndex: null,
        }));
        if (pathnameBusinessAndTeams) {
          dispatch(
            getAssociationsEntityByFilter({
              clientId,
              filterVal: "",
              columnType: "",
              searchVal: "",
              sortType: "",
            })
          );
        } else {
          dispatch(
            getAssociationsByFilter({
              clientId,
              filterVal: "",
              columnType: "",
              uniqueCol: "",
              searchVal: "",
              sortType: "",
            })
          );
        }
      } else if (title.includes("sort")) {
        dispatch(resetinputSearchValAssociations(""));
        dispatch(resetSelectedItemIndexFilterAssociations());
        dispatch(resetSelectedFilterItemsAssociations());
        setAssociationTableDataObject((prevState: any) => ({
          ...prevState,
          selectedItemIndex: null,
        }));
        if (pathnameBusinessAndTeams) {
          dispatch(
            getAssociationsEntityByFilter({
              clientId,
              filterVal: "",
              columnType: "",
              searchVal: "",
              sortType: "",
            })
          );
        } else {
          dispatch(
            getAssociationsByFilter({
              clientId,
              filterVal: "",
              columnType: "",
              uniqueCol: "",
              searchVal: "",
              sortType: "",
            })
          );
        }
      } else {
        dispatch(resetSelectedItemIndexFilterAssociations());
        dispatch(resetSelectedItemIndexSortAssociations());
        dispatch(resetSortByAssociationsR());
        dispatch(resetSelectedFilterItemsAssociations());
        if (pathnameBusinessAndTeams) {
          dispatch(
            getAssociationsEntityByFilter({
              clientId,
              filterVal: "",
              columnType: "",
              searchVal: "",
              sortType: "",
            })
          );
        } else {
          dispatch(
            getAssociationsByFilter({
              clientId,
              filterVal: "",
              columnType: "",
              uniqueCol: "",
              searchVal: "",
              sortType: "",
            })
          );
        }
      }
      setAssociationTableDataObject((prevState: any) => ({
        ...prevState,
        currentActive: title,
      }));
      dispatch(setSelectedDropdownItemAssociations(title));
    }
    if (props === "document") {
      if (title.includes("filter")) {
        dispatch(resetinputSearchVal(""));
        dispatch(resetSelectedItemIndexSortDocuments());
        dispatch(resetSortByDocumentsR());
        setDocumentTableDataObject((prevState: any) => ({
          ...prevState,
          selectedSortIndex: null,
        }));
        if (pathnameBusinessAndTeams) {
          dispatch(getDocumentsEntity({ clientId, providerId }));
        } else {
          dispatch(getDocuments({ clientId, providerId }));
        }
      } else if (title.includes("sort")) {
        dispatch(resetinputSearchVal(""));
        dispatch(resetSelectedItemIndexFilterDocuments());
        dispatch(resetSelectedFilterItemsDocuments());
        setDocumentTableDataObject((prevState: any) => ({
          ...prevState,
          selectedItemIndex: null,
        }));
        if (pathnameBusinessAndTeams) {
          dispatch(getDocumentsEntity({ clientId, providerId }));
        } else {
          dispatch(getDocuments({ clientId, providerId }));
        }
      } else {
        dispatch(resetSelectedItemIndexFilterDocuments());
        dispatch(resetSelectedItemIndexSortDocuments());
        dispatch(resetSortByDocumentsR());
        dispatch(resetSelectedFilterItemsDocuments());
        if (pathnameBusinessAndTeams) {
          dispatch(getDocumentsEntity({ clientId, providerId }));
        } else {
          dispatch(getDocuments({ clientId, providerId }));
        }
      }
      setDocumentTableDataObject((prevState: any) => ({
        ...prevState,
        currentActive: title,
      }));
      dispatch(setSelectedDropdownItemDocuments(title));
    }
    // RESET STATES WHEN SWITCHING BETWEEN
    if (title.includes("filter")) {
      dispatch(resetSortOptions());
      dispatch(resetSortOptionsAudit());
      dispatch(resetSortOptionsBusiness());
      dispatch(resetSortOptionsTeams());
      dispatch(resetSearchValueStaff());
      dispatch(resetSearchValueAudit());
      dispatch(resetSearchValueBusiness());
      dispatch(resetSearchValueTeams());
      dispatch(resetsearchValueContacts());
      dispatch(resetselectedSortValuesProspects(""));
      dispatch(resetsearchvalProspects());
    } else if (title.includes("sort")) {
      dispatch(resetFilterOptions());
      dispatch(resetFilterOptionsAudit());
      dispatch(resetFilterOptionsBusiness());
      dispatch(resetFilterOptionsTeams());
      dispatch(resetSelectedProspects(""));
      dispatch(resetSearchValueStaff());
      dispatch(resetSearchValueAudit());
      dispatch(resetSearchValueBusiness());
      dispatch(resetSearchValueAudit());
      dispatch(resetsearchvalProspects());
    } else {
      dispatch(resetFilterOptions());
      dispatch(resetFilterOptionsAudit());
      dispatch(resetFilterOptionsBusiness());
      dispatch(resetFilterOptionsTeams());
      dispatch(resetSelectedProspects(""));
      dispatch(resetSortOptions());
      dispatch(resetselectedSortValuesProspects(""));
    }

    setState((prevData: any) => ({
      ...prevData,
      selectedFilterDropDownOne:
        id === 1 ? { icon, title, id, ...props } : null,
      selectedSortDropDownOne: id === 3 ? { icon, title, id, ...props } : null,
      selectedSearchItem: id === 2,
      selectedFilterDropDownTwo: null,
      selectedSortDropDownTwo: null,
      selectedItem: { icon, title, id, ...props },
    }));
    // dispatch(setselectedTypeOfManipulation({ icon, title, id, ...props }));
  };

  // SELECT ALL FUNCTIONALITY
  const handleSelectAll = (type: string) => {
    if (type === "title") {
      const allTitles = filteredDocumentsArr.map((elem: any) => elem.title);
      if (selectedTitles.length === allTitles.length) {
        setSelectedTitles([]);
      } else {
        setSelectedTitles(allTitles);
      }
      dispatch(getDocuments({ clientId, providerId }));
    }
  };

  // INPUT CHANGE FOR DOCUMENTS
  const handleInputChange = (e: any) => {
    const newInputValue = e.target.value;
    setInputValueText(newInputValue);
    dispatch(setinputSearchVal(newInputValue));
    // dispatch(resetIsDocumentsDropdownOpen());
    dispatch(resetselectedColumn());
    dispatch(resetselectedcolumnforsort());

    if (pathnameBusinessAndTeams) {
      dispatch(
        getDocumentsByFilterForEntity({
          clientId,
          title: "",
          searchVal: newInputValue,
          sortType: "",
          filterColumn: "",
          sortColumn: "",
          providerId,
        })
      );
    } else if (clientId) {
      dispatch(
        getDocumentsByFilter({
          clientId,
          filterValue: "",
          searchVal: newInputValue,
          sortType: "",
          filterColumn: "",
          sortColumn: "",
          providerId,
        })
      );
    }
  };

  useEffect(() => {
    if (searchValAssociations !== "") {
      // setState((prevData: any) => ({ ...prevData, selectedItem: Data[1] }));
      // inputRefAssociations?.current?.focus();
      setState((prevData: any) => ({
        ...prevData,
        selectedFilterDropDownTwo: null,
      }));
    }
  }, [searchValAssociations]);
  // INPUT CHANGE FOR ASSOCIATIONS

  const handleInputChangeAssociations = (e: any) => {
    const newInputValue = e.target.value;
    setInputValueTextAssociations(newInputValue);

    if (pathnameBusinessAndTeams) {
      dispatch(
        getAssociationsEntityByFilter({
          clientId,
          filterVal: "",
          columnType: "",
          searchVal: newInputValue,
          sortType: "",
        })
      );
    } else {
      dispatch(
        getAssociationsByFilter({
          clientId,
          filterVal: "",
          columnType: "",
          uniqueCol: "",
          searchVal: newInputValue,
          sortType: "",
        })
      );
    }
    dispatch(setinputSearchValAssociations(newInputValue));
  };

  const handleInputChangeProviders = (e: any) => {
    const newInputValue = e.target.value;
    setinputValueTextProviders(newInputValue);
    if (newInputValue === "" || newInputValue === null) {
      dispatch(
        setProvidersFilterItems({
          status: "1",
          filterColumn: "",
          filterValue: "",
          searchVal: newInputValue,
          sortType: "",
          sortColumn: "",
          providersPage,
        })
      );
    } else {
      dispatch(
        setProvidersFilterItems({
          status: "1",
          filterColumn: "",
          filterValue: "",
          searchVal: newInputValue,
          sortType: "",
          sortColumn: "",
          providersPage,
        })
      );
    }
    dispatch(setinputSearchValProviders(newInputValue));
  };
  const uniqueTitles = new Set();
  const filteredDocumentsArr = getDocumentsArr?.filter((obj: any) => {
    const lowerCaseTitle = obj.title.toLowerCase();
    if (uniqueTitles.has(lowerCaseTitle)) {
      return false;
    }
    uniqueTitles.add(lowerCaseTitle);
    return true;
  });

  let documentsName: any[] = filteredDocumentsArr?.map(
    (res: any) => res?.title
  );
  // let providerNames = [
  //   ...new Set(
  //     getProvidersArr?.data?.data?.map((elem: any) => elem.tenant_name)
  //   ),
  // ];

  let providerNames = getProvidersUniqueTenantName
    ?.map((elem: any) => elem.tenant_name)
    ?.filter((elem: any) => elem !== null);
  let providerType = getProvidersUniqueTenantType
    ?.map((elem: any) => elem.tenant_type)
    ?.filter((elem: any) => elem !== null);

  // let providerType = [
  //   ...new Set(
  //     getProvidersArr?.data?.data
  //       ?.map((elem: any) => elem.tenant_type)
  //       .filter((elem: any) => elem !== null)
  //   ),
  // ];

  // // console.log(getProvidersArr?.data?.data, "check___")

  const uniqueReviewDates = [
    ...new Set(
      getDocumentsArr?.map(
        (elem: { primary_review: any }) => elem.primary_review
      )
    ),
  ].filter(Boolean);

  useEffect(() => {
    if (selectedOptionsFromRedux && selectedOptionsFromRedux?.length > 0) {
      setSelectedTitles(selectedOptionsFromRedux);
    }
  }, [selectedOptionsFromRedux]);

  useEffect(() => {
    dispatch(setselectedApprovers(selectedApproversDocs));
  }, [selectedApproversDocs]);

  useEffect(() => {
    dispatch(setselectedOptions(selectedTitles));
  }, [selectedTitles]);

  useEffect(() => {
    if (inputValueText !== "") {
      // setState((prevData: any) => ({ ...prevData, selectedItem: Data[1] }));
      // inputRef?.current?.focus();
      setState((prevData: any) => ({
        ...prevData,
        selectedFilterDropDownTwo: null,
      }));
    }
  }, [inputValueText]);

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////   ASSOCIATIONS TABLE //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const selectedItemIndexAssociationsSort = useAppSelector(
    (state) => state?.associations?.selectedItemIndexAssociationsSort
  );

  ////////////////////////////////////////////  INDEX IF SORT / FILTER ////////////////////////////////////////////////////
  const [selectedSortIndex, setselectedSortIndex] = useState<any>(
    selectedItemIndexAssociationsSort
  );
  const [selectedItemIndex, setSelectedItemIndex] = useState(
    selectedItemIndexAssociations
  );

  /////////////////////////////////////////////// FINDS WHICH COLUMN IS SELECTED  /////////////////////////////
  const handleClickAssociationsColumn = (index: number | any, props: any) => {
    if (props === "clients") {
      setclientsTableDataObject((prevState: any) => ({
        ...prevState,
        selectedItemIndex: index,
      }));
      dispatch(setselectedItemIndexFilterClients(index));
      setSelectedTitles([]);
    } else if (props === "audit") {
      setAuditTableDataObject((prevState: any) => ({
        ...prevState,
        selectedItemIndex: index,
      }));
      dispatch(setSelectedItemIndexFilterAudit(index));
      setSelectedTitles([]);
    } else if (props === "prospect") {
      setProspectsTableDataObject((prevState: any) => ({
        ...prevState,
        selectedItemIndex: index,
      }));
      dispatch(setSelectedItemIndexFilterProspect(index));
      setSelectedTitles([]);
    } else if (props === "contact") {
      setContactsTableDataObject((prevState: any) => ({
        ...prevState,
        selectedItemIndex: index,
      }));
      dispatch(setSelectedItemIndexFilterContact(index));
      setSelectedTitles([]);
    } else if (props === "business") {
      setBusinessTableDataObject((prevState: any) => ({
        ...prevState,
        selectedItemIndex: index,
      }));
      dispatch(setSelectedItemIndexFilterBusiness(index));
      setSelectedTitles([]);
    } else if (props === "teams") {
      setTeamsTableDataObject((prevState: any) => ({
        ...prevState,
        selectedItemIndex: index,
      }));
      dispatch(setSelectedItemIndexFilterTeams(index));
      setSelectedTitles([]);
    } else if (props === "locations") {
      setLocationTableDataObject((prevState: any) => ({
        ...prevState,
        selectedItemIndex: index,
      }));
      dispatch(setSelectedItemIndexFilterLocation(index));
      setSelectedTitles([]);
    } else if (props === "association") {
      setAssociationTableDataObject((prevState: any) => ({
        ...prevState,
        selectedItemIndex: index,
      }));
      dispatch(setSelectedItemIndexFilterAssociations(index));
      setSelectedTitles([]);
    } else if (props === "document") {
      setDocumentTableDataObject((prevState: any) => ({
        ...prevState,
        selectedItemIndex: index,
      }));
      dispatch(setSelectedItemIndexFilterDocuments(index));
      setSelectedTitles([]);
    } else if (props === "providers") {
      setProviderTableDataObject((prevState: any) => ({
        ...prevState,
        selectedItemIndex: index,
      }));
      dispatch(setSelectedItemIndexFilterProviders(index));
      setSelectedTitles([]);
    } else if (props === "meals") {
      // // console.log(selectedTitles,"selectedTtiels")
      setSelectedTitles([]);
      dispatch(getMealBuilder());

      setmealsTableDataObject((prevState: any) => ({
        ...prevState,
        selectedItemIndex: index,
      }));
      dispatch(setselectedItemIndexFilterMeals(index));
    } else {
      setSelectedItemIndex(index);
      setselectedSortIndex(null);
      dispatch(resetselectedItemIndexAssociationsSort(""));
      dispatch(setselectedItemIndexAssociations(index));
      dispatch(setisDropdownOpen(true));
      setSelectedTitles([]);
    }
  };

  /////////////////////////////////////////////////////////// DISPATCH ACTION HERE ///////////////////////////////////////////////////
  const handleChangeOfDropdownAssociations = (
    names: string[],
    type: string | any
  ) => {
    if (type?.includes("name")) {
      if (pathnameBusinessAndTeams) {
        dispatch(
          getAssociationsEntityByFilter({
            clientId,
            filterVal: names.join(","),
            columnType: "name",
            searchVal: "",
            sortType: "",
          })
        );
      } else {
        dispatch(
          getAssociationsByFilter({
            clientId,
            filterVal: names.join(","),
            columnType: "name",
            uniqueCol: "name",
            searchVal: "",
            sortType: "",
          })
        );
      }
    } else if (type?.includes("relationships")) {
      if (pathnameBusinessAndTeams) {
        dispatch(
          getAssociationsEntityByFilter({
            clientId,
            filterVal: names.join(","),
            columnType: "relationship_name",
            searchVal: "",
            sortType: "",
          })
        );
      } else {
        dispatch(
          getAssociationsByFilter({
            clientId,
            filterVal: names.join(","),
            columnType: "relationship_name",
            uniqueCol: "relationship_name",
            searchVal: "",
            sortType: "",
          })
        );
      }
    } else if (type?.includes("contact category")) {
      if (pathnameBusinessAndTeams) {
        dispatch(
          getAssociationsEntityByFilter({
            clientId,
            filterVal: names.join(","),
            columnType: "contact_category",
            searchVal: "",
            sortType: "",
          })
        );
      } else {
        dispatch(
          getAssociationsByFilter({
            clientId,
            filterVal: names.join(","),
            columnType: "contact_category",
            uniqueCol: "contact_category",
            searchVal: "",
            sortType: "",
          })
        );
      }
    } else {
      if (pathnameBusinessAndTeams) {
        dispatch(
          getAssociationsEntityByFilter({
            clientId,
            filterVal: names.join(","),
            columnType: "contact_number",
            searchVal: "",
            sortType: "",
          })
        );
      } else {
        dispatch(
          getAssociationsByFilter({
            clientId,
            filterVal: names.join(","),
            columnType: "contact_number",
            uniqueCol: "contact_number",
            searchVal: "",
            sortType: "",
          })
        );
      }
    }
  };

  ///////////////////////////////////////////////////////// SINGLE CHECKBOX CHANGE ///////////////////////////////////////////////////
  const handleCheckboxChangeAssociations = (
    elem: string,
    type: string,
    index: number
  ) => {
    let targetType;
    switch (true) {
      case type.includes("name"):
        targetType = "name";
        break;
      case type.includes("relationships"):
        targetType = "relationships";
        break;
      case type.includes("contact category"):
        targetType = "contact category";
        break;
      default:
        targetType = "contact number";
    }
    const updatedSelectedTitles = selectedTitles?.includes(elem)
      ? selectedTitles.filter((t) => t !== elem)
      : [...selectedTitles, elem];
    setSelectedTitles(updatedSelectedTitles);
    dispatch(setSelectedFilterItemsAssociations(updatedSelectedTitles));
    handleChangeOfDropdownAssociations(updatedSelectedTitles, targetType);
  };

  useEffect(() => {
    document.documentElement.style.setProperty(
      "--theme-color",
      filterIconCo̥lor
    );
  }, [filterIconCo̥lor]);

  ////////////////////////////////////////////////////////   UNIQUE VALUES FOR DROPDOWN ////////////////////////////////////////////
  const uniqueUserContactNames = [
    ...new Set(
      getAssociationsArr?.data?.map(
        (elem: { user_contact_name: any; entity_contact_name: any }) =>
          elem.user_contact_name || elem.entity_contact_name
      )
    ),
  ];

  const uniqueRelationshipName = [
    ...new Set(
      getAssociationsArr?.data?.map(
        (elem: { relationship_name: any }) => elem.relationship_name
      )
    ),
  ];
  const uniqueUserContactCategory = [
    ...new Set(
      getAssociationsArr?.data?.map(
        (elem: { user_contact_category: any; entity_contact_category: any }) =>
          elem.user_contact_category || elem.entity_contact_category
      )
    ),
  ];
  const uniqueContactNumbers = [
    ...new Set(
      getAssociationsArr?.data?.map(
        (elem: { user_contact_number: any; entity_contact_number: any }) =>
          elem.entity_contact_number || elem.user_contact_number
      )
    ),
  ];
  let newArr: SetStateAction<any[]> = [];
  for (let i = 0; i <= uniqueContactNumbers.length; i++) {
    if (
      uniqueContactNumbers[i] !== null &&
      uniqueContactNumbers[i] !== undefined
    ) {
      newArr.push(uniqueContactNumbers[i]);
    }
  }
  // const uniquteAssociatedBuissness = [
  //   ...new Set(
  //     getAssociationsArr?.data?.map(
  //       (elem: { associated_buissness: any }) => elem.associated_buissness
  //     )
  //   ),
  // ];
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const handleOpenSortOptionsOnBasisOfIndexOfColumn = (
    index: number | any,
    props: any
  ) => {
    if (props === "clients") {
      setclientsTableDataObject((prevState: any) => ({
        ...prevState,
        selectedSortIndex: index,
      }));
      dispatch(setselectedItemIndexSortClients(index));
    } else if (props === "audit") {
      setAuditTableDataObject((prevState: any) => ({
        ...prevState,
        selectedSortIndex: index,
      }));
      dispatch(setSelectedItemIndexSortAudit(index));
    } else if (props === "prospect") {
      setProspectsTableDataObject((prevState: any) => ({
        ...prevState,
        selectedSortIndex: index,
      }));
      dispatch(setSelectedItemIndexSortProspect(index));
    } else if (props === "contact") {
      setContactsTableDataObject((prevState: any) => ({
        ...prevState,
        selectedSortIndex: index,
      }));
      dispatch(setSelectedItemIndexSortContact(index));
    } else if (props === "business") {
      setBusinessTableDataObject((prevState: any) => ({
        ...prevState,
        selectedSortIndex: index,
      }));
      dispatch(setSelectedItemIndexSortBusiness(index));
    } else if (props === "locations") {
      setLocationTableDataObject((prevState: any) => ({
        ...prevState,
        selectedSortIndex: index,
      }));
      dispatch(setSelectedItemIndexSortLocation(index));
    } else if (props === "teams") {
      setTeamsTableDataObject((prevState: any) => ({
        ...prevState,
        selectedSortIndex: index,
      }));
      dispatch(setSelectedItemIndexSortTeams(index));
    } else if (props === "association") {
      setAssociationTableDataObject((prevState: any) => ({
        ...prevState,
        selectedSortIndex: index,
      }));
      dispatch(setSelectedItemIndexSortAssociations(index));
    } else if (props === "providers") {
      setProviderTableDataObject((prevState: any) => ({
        ...prevState,
        selectedSortIndex: index,
      }));
      dispatch(setSelectedItemIndexSortProviders(index));
    } else if (props === "document") {
      setDocumentTableDataObject((prevState: any) => ({
        ...prevState,
        selectedSortIndex: index,
      }));
      dispatch(setSelectedItemIndexSortDocuments(index));
    } else if (props === "meals") {
      setmealsTableDataObject((prevState: any) => ({
        ...prevState,
        selectedSortIndex: index,
      }));
      dispatch(setselectedItemIndexSortMeals(index));
    } else {
      setselectedSortIndex(index);
      dispatch(setselectedItemIndexAssociationsSort(index));
      dispatch(resetselectedItemIndexAssociations(""));
      setSelectedItemIndex(null);
    }
  };

  const handleFoucsInSearchOfAssociations = () => {
    setSelectedItemIndex(null);
    setselectedSortIndex(null);
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////// CLIENTS TABLE //////////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  // const dynamicClientId = useAppSelector(
  //   (state) => state?.people?.setUserTypeIdForClient?.id
  // );

  // @ts-ignore
  const dynamicClientId: any = parseInt(
    localStorage.getItem("dynamicClientId") || ""
  );
  const dynamicProspectsId: any = parseInt(
    localStorage.getItem("dynamicProspectId") || ""
  );

  // @ts-ignore
  // const dynamicClientId: any= parseInt(
  //   localStorage?.getItem("dynamicClientId")
  // );

  // const dynamicProspectsId = useAppSelector(
  //   (state) => state?.prospects?.getUserTypeIdForProspects?.id
  // );

  // @ts-ignore
  // const dynamicProspectsId: any = parseInt(
  //   localStorage?.getItem("dynamicProspectId")
  // );

  const allClientData = useAppSelector((state) => state?.people?.client);

  // // console.log(allClientData, "allClientData");
  const {
    ClientPage,
    clientName,
    clientEmail,
    clientDob,
    clientFunding,
    clientResidence,
  } = useAppSelector((state) => state?.people);

  //////////////////////////  UNIQUE RESULTS ////////////////////////////////////////////

  //////////////////////////////////  FULL NAMES OF CLIENTS //////////////////////
  function createFullNamesArray(allClientData: any) {
    const fullNamesClients = allClientData?.map((elem: any) => {
      const { first_name, last_name } = elem;
      return `${first_name || ""} ${last_name || ""}`;
    });
    return fullNamesClients || [];
  }
  const fullNamesClients = createFullNamesArray(clientName);
  const uniqueNamesOfClients = [...new Set(fullNamesClients)];
  // // console.log("clientName =====================>", clientName);
  // @ts-ignore
  let cleanedArray = uniqueNamesOfClients.map((item: any) =>
    item?.replace(" null", "")
  );
  // // console.log(cleanedArray, "cleanedArray");
  /////////////////////////////////// CONATCTS OF CLIENTS ///////////////////////////////

  const uniqueEmailsOfClients = [
    ...new Set(clientEmail?.map((elem: { email: string }) => elem?.email)),
  ];

  ///////////////////////////// DOB OF CLIENTS ////////////////////////////////////////////

  const uniqueDOBOfClients = [
    ...new Set(
      (clientDob || [])
        .map((elem: any) => elem?.date_of_birth)
        .filter((elem: any) => elem !== null)
    ),
  ];

  ///////////////////////////// FUNDING TYPE OF CLIENTS ////////////////////////////////////////////

  const uniqueFundingTypeOfClients = [
    ...new Set(
      (clientFunding || [])
        .map((elem: any) => elem?.funding_type)
        .filter((elem: any) => elem !== null)
    ),
  ];

  const uniqueResidenceOfClients = [
    ...new Set(
      (clientResidence || [])
        .map((elem: any) => elem?.residence_name)
        .filter((elem: any) => elem !== null)
    ),
  ];

  ///////////////////////////// STATUS OF CLIENTS ////////////////////////////////////////////

  const statusMapping: any = [
    "Inactive",
    "Active",
    "On Leave",
    "Hospital",
    "Waitlist",
    "Respite",
    "Deceased",
  ];
  const statusMappingForBusinessAndTeams: any = ["Inactive", "Active"];

  const tableHeadArrClients = [
    "Name",
    "Contact",
    "DOB",
    "Funding Type",
    "Residence",
    "Status",
  ];

  const tableHeadArrMeals = ["Meal Name", "Tags", "Prep Time", "Cooking Time"];
  ////////////////// CHECKBOX CHANGE OF FILTERS FOR CLIENTS /////////////

  const handleCheckboxChangeDocuments = (elem: string, type: any) => {
    let targetType;

    switch (true) {
      case type.includes("documents"):
        targetType = "title";
        break;
      case type.includes("primaryReview"):
        targetType = "primary_review";
        break;
      case type.includes("primary_approver"):
        targetType = "primary_approver";
        break;
      default:
        targetType = "";
    }

    const updatedSelectedTitles = selectedTitles?.includes(elem)
      ? selectedTitles.filter((t) => t !== elem)
      : [...selectedTitles, elem];

    setSelectedTitles(updatedSelectedTitles);
    dispatch(setSelectedFilterItemsDocuments(updatedSelectedTitles));
    handleChangeOfDropdownDocuments(updatedSelectedTitles, targetType);
  };

  const handleCheckboxChangeProviders = (elem: string, type: any) => {
    let targetType;

    switch (true) {
      case type.includes("tenant_name"):
        targetType = "tenant_name";
        break;
      case type.includes("provider_type"):
        targetType = "tenant_type";
        break;
      default:
        targetType = "";
    }

    const updatedSelectedTitles = selectedTitles?.includes(elem)
      ? selectedTitles.filter((t) => t !== elem)
      : [...selectedTitles, elem];

    setSelectedTitles(updatedSelectedTitles);
    dispatch(setSelectedFilterItemsProviders(updatedSelectedTitles));
    dispatch(setselectedFilterOptionsProviders(updatedSelectedTitles));
    handleChangeOfDropdownProviders(updatedSelectedTitles, targetType);
  };

  const handleChangeOfDropdownProviders = (names: any, type: any) => {
    let filterColumn;
    // console.log(type, "type");
    switch (type) {
      case "tenant_name":
        filterColumn = "tenant_name";
        break;
      case "tenant_type":
        filterColumn = "tenant_type";
        break;
      default:
        filterColumn = "";
        break;
    }

    let filterValue;

    filterValue = names.join(",");
    const filterParams = {
      filterColumn,
      filterValue,
      searchVal: "",
      sortColumn: "",
      sortType: "",
      status: "",
      providersPage,
    };

    // dispatch(getProvidersByFilterThunk(filterParams));
    dispatch(setProvidersFilterItems(filterParams));
  };
  const handleChangeOfDropdownDocuments = (names: any, type: any) => {
    let filterColumn;

    switch (type) {
      case "title":
        filterColumn = "title";
        break;
      case "primary_review":
        filterColumn = "primary_review";
        break;
      case "primary_approver":
        filterColumn = "primary_approver";
        break;

      default:
        filterColumn = "";
        break;
    }

    let filterValue;

    filterValue = names.join(",");

    const filterParams = {
      clientId,
      filterColumn,
      filterValue,
      searchVal: "",
      sortColumn: "",
      sortType: "",
      providerId,
    };

    if (pathnameBusinessAndTeams) {
      dispatch(
        getDocumentsByFilterForEntity({
          clientId,
          title: filterValue,
          searchVal: "",
          sortType: "",
          filterColumn,
          sortColumn: "",
          providerId,
        })
      );
    } else if (clientId) {
      // @ts-ignore
      dispatch(getDocumentsByFilter(filterParams));
    }
  };
  const handleCheckboxChangeClients = (elem: any, type: any, index: any) => {
    // console.log("elem======================>", elem);
    let targetType;
    switch (true) {
      case type.includes("name"):
        targetType = "name";
        break;
      case type.includes("email"):
        targetType = "email";
        break;
      case type.includes("dob"):
        targetType = "dob";
        break;
      case type.includes("fundingType"):
        targetType = "fundingType";
        break;
      case type.includes("residence"):
        targetType = "residence_name";
        break;
      default:
        targetType = "status";
    }

    const updatedSelectedTitles = selectedTitles?.includes(elem)
      ? selectedTitles.filter((t) => t !== elem)
      : [...selectedTitles, elem];

    setSelectedTitles(updatedSelectedTitles);
    dispatch(setselectedFilterItemsClients(updatedSelectedTitles));
    handleChangeOfDropdownClients(updatedSelectedTitles, targetType);
  };

  const handleSortChangeClients = (
    val: string,
    selectedSortIndex: number | any
  ) => {
    const capturedVal = val === "newest on top" ? "asc" : "desc";
    setsortByClients(val);

    dispatch(setsortbyClients(val));

    let sortColumn = "";
    switch (clientsTableDataObject?.selectedSortIndex) {
      case 0:
        sortColumn = "name";
        break;
      case 1:
        sortColumn = "email";
        break;
      case 2:
        sortColumn = "date_of_birth";
        break;
      case 3:
        sortColumn = "funding_type";
        break;
      case 4:
        sortColumn = "residence_name";
        break;
      default:
        sortColumn = "";
        break;
    }

    const filterData = {
      dynamicId: dynamicClientId,
      filterColumn: "",
      filterValue: "",
      searchVal: "",
      sortColumn,
      sortType: capturedVal,
      status: "1,2,3,4,5",
      ClientPage,
    };
    dispatch(setClientFilterItems(filterData));
    // dispatch(getClientsByFilterThunk(filterData));
  };
  const handleChangeOfDropdownClients = (names: any, type: any) => {
    let filterColumn;

    switch (type) {
      case "name":
        filterColumn = "name";
        break;
      case "email":
        filterColumn = "email";
        break;
      case "dob":
        filterColumn = "date_of_birth";
        break;
      case "fundingType":
        filterColumn = "funding_type";
        break;
      case "residence":
        filterColumn = "residence_name";
        break;
      default:
        const statusMap: any = {
          Inactive: 0,
          Active: 1,
          "On Leave": 2,
          Hospital: 3,
          Waitlist: 4,
          Respite: 5,
          Deceased: 6,
        };
        const numericalValues = names.map((name: any) => statusMap[name]);
        filterColumn = "status";
        names = numericalValues;
    }

    let filterValue;

    filterValue = names.join(",");

    // // console.log("filterValue ===============", filterValue);

    if (names.length !== 0) {
      // dispatch(
      //   getClientsByFilterThunk({
      //     dynamicId: dynamicClientId,
      //     filterColumn,
      //     filterValue,
      //     searchVal: "",
      //     sortColumn: "",
      //     sortType: "",
      //     status: "",
      //     ClientPage,
      //   })
      // );
      dispatch(
        setClientFilterItems({
          dynamicId: dynamicClientId,
          filterColumn,
          filterValue: names.join(","),
          searchVal: "",
          sortColumn: "",
          sortType: "",
          status: "",
          ClientPage,
        })
      );
    } else {
      // dispatch(
      //   getClientsByFilterThunk({
      //     dynamicId: dynamicClientId,
      //     filterColumn,
      //     filterValue,
      //     searchVal: "",
      //     sortColumn: "",
      //     sortType: "",
      //     status: "1,2,3,4,5",
      //     ClientPage,
      //   })
      // );
      dispatch(
        setClientFilterItems({
          dynamicId: dynamicClientId,
          filterColumn,
          filterValue: names.join(","),
          searchVal: "",
          sortColumn: "",
          sortType: "",
          status: "1,2,3,4,5",
          ClientPage,
        })
      );
    }

    const actionPayload = {
      dynamicId: dynamicClientId,
      filterColumn,
      filterValue,
      searchVal: "",
      sortColumn: "",
      sortType: "",
      status: "",
    };

    // Dispatch the action with the appropriate payload
  };

  const selectedSortByOption = useAppSelector(
    (state) => state?.people?.sortbyClients
  );

  const [clientSearchDDopen, setclientSearchDDopen] = useState(false);
  const [sortByClients, setsortByClients] = useState(selectedSortByOption);

  const handleClearSearchClients = () => {
    setselectedItemIndexAssociations(null);
    setSelectedItemIndex(null);
    setselectedSortIndex(null);
    setInputValueTextClients("");
    dispatch(resetsearchValueClients(""));

    // dispatch(
    //   getClientsByFilterThunk({
    //     dynamicId: dynamicClientId,
    //     filterColumn: "",
    //     filterValue: "",
    //     searchVal: "",
    //     sortColumn: "",
    //     sortType: "",
    //     status: "",
    //     ClientPage
    //   })
    // );
    dispatch(
      setClientFilterItems({
        dynamicId: dynamicClientId,
        filterColumn: "",
        filterValue: "",
        searchVal: "",
        sortColumn: "",
        sortType: "",
        status: "",
        ClientPage,
      })
    );
  };

  const handleClearSearchMeals = () => {
    setselectedItemIndexAssociations(null);
    setSelectedItemIndex(null);
    setselectedSortIndex(null);
    setInputValueTextMeals("");
    dispatch(resetsearchValueMeals(""));
  };
  const handleClearSearchContacts = () => {
    // setselectedItemIndexAssociations(null);
    // setselectedSortIndex(null);
    setinputValueTextContacts("");
    dispatch(resetsearchValueContacts());
    // dispatch(
    //   getContactsByFilterThunk({
    //     dynamicId: dynamicContactId,
    //     filterColumn: "",
    //     filterValue: "",
    //     searchVal: "",
    //     sortColumn: "",
    //     sortType: "",
    //     status: "",
    //     ContactPage,
    //   })
    // );
    dispatch(
      setContactFilterItems({
        dynamicId: dynamicContactId,
        filterColumn: "",
        filterValue: "",
        searchVal: "",
        sortColumn: "",
        sortType: "",
        status: "1",
        ContactPage,
      })
    );
  };
  const [searchDropdownContacts, setsearchDropdownContacts] = useState(false);

  const handleInputChangeClients = (e: any) => {
    setclientSearchDDopen(true);
    const newInputValue = e.target.value;
    setInputValueTextClients(newInputValue);
    dispatch(setsearchValueClients(newInputValue));
    if (newInputValue === "" || newInputValue === null) {
      // dispatch(
      //   getClientsByFilterThunk({
      //     dynamicId: dynamicClientId,
      //     filterColumn: "",
      //     filterValue: "",
      //     searchVal: newInputValue,
      //     sortColumn: "",
      //     sortType: "",
      //     status: "1,2,3,4,5",
      //     ClientPage,
      //   })
      // );
      dispatch(
        setClientFilterItems({
          dynamicId: dynamicClientId,
          filterColumn: "",
          filterValue: "",
          searchVal: newInputValue,
          sortColumn: "",
          sortType: "",
          status: "1,2,3,4,5",
          ClientPage,
        })
      );
    } else {
      // dispatch(
      //   getClientsByFilterThunk({
      //     dynamicId: dynamicClientId,
      //     filterColumn: "",
      //     filterValue: "",
      //     searchVal: newInputValue,
      //     sortColumn: "",
      //     sortType: "",
      //     status: "",
      //     ClientPage
      //   })
      // );
      dispatch(
        setClientFilterItems({
          dynamicId: dynamicClientId,
          filterColumn: "",
          filterValue: "",
          searchVal: newInputValue,
          sortColumn: "",
          sortType: "",
          status: "",
          ClientPage,
        })
      );
    }
  };

  const [mealsSearchDDOpen, setmealsSearchDDOpen] = useState(false);
  const [inputValueTextMeals, setInputValueTextMeals] = useState("");

  const handleInputChangeMeals = (e: any) => {
    setmealsSearchDDOpen(true);
    const newInputValue = e.target.value;
    setInputValueTextMeals(newInputValue);
    dispatch(setsearchValueMeals(newInputValue));
    dispatch(
      getMealsByFilterThunk({
        filterColumn: "",
        filterValue: "",
        searchVal: newInputValue,
        sortColumn: "",
        sortType: "",
      })
    );
  };
  const handleSortChangeMeals = (
    val: string,
    selectedSortIndex: number | any
  ) => {
    const capturedVal = val === "newest on top" ? "asc" : "desc";
    setsortByClients(val);

    dispatch(setsortbyMeals(val));

    let sortColumn = "";
    switch (mealsTableDataObject?.selectedSortIndex) {
      case 0:
        sortColumn = "meal_name";
        break;
      case 1:
        sortColumn = "tags";
        break;
      case 2:
        sortColumn = "prep_time";
        break;
      case 3:
        sortColumn = "cooking_time";
        break;
      default:
        sortColumn = "";
        break;
    }

    const filterData = {
      filterColumn: "",
      filterValue: "",
      searchVal: "",
      sortColumn,
      sortType: capturedVal,
    };

    dispatch(getMealsByFilterThunk(filterData));
  };

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////// PROSPECTS DROPDOWN /////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const getProspectsData = useAppSelector(
    (state) => state?.prospects?.getAllProspectsData?.data?.data
  );

  const {
    ProspectPageFilter,
    ProspectFilterItems,
    getProspectName,
    getProspectEmail,
    getProspectPhone,
    getProspectStatus,
    getProspectReffer,
  } = useAppSelector((state) => state?.prospects);

  const fullNamesProspects = createFullNamesArray(getProspectsData);
  const uniqueNamesOfProspects = getProspectName
    ?.map((data: any) => `${data?.first_name || ""} ${data?.last_name || ""}`)
    ?.filter((elem: any) => elem !== null);

  const uniqueEmailsOfProspects = [
    ...new Set(getProspectEmail?.map((elem: { email: string }) => elem?.email)),
  ]?.filter((elem: any) => elem !== null);
  // const uniqueEmailsOfProspects = [
  //   ...new Set(getProspectsData?.map((elem: { email: string }) => elem?.email)),
  // ];
  const numbersOfProspects = [
    ...new Set(
      getProspectPhone?.map((elem: { mobile: string }) => elem?.mobile)
    ),
  ]?.filter((elem: any) => elem !== null);

  // const numbersOfProspects = [
  //   ...new Set(
  //     getProspectsData?.map((elem: { mobile: string }) => elem?.mobile)
  //   ),
  // ];
  const uniqueNumbersOfProspects = [...new Set(numbersOfProspects)];

  const uniqueReferrersOfProspects = [
    ...new Set(
      getProspectReffer?.map(
        (elem: { referrer_name: string }) => elem?.referrer_name
      )
    ),
  ]?.filter((elem: any) => elem !== null);

  const handleClearSearchProspects = () => {
    setInputValueTextProspects("");
    dispatch(resetsearchvalProspects());
    dispatch(resetProspectFilterItems());
    dispatch(
      setProspectFilterItems({
        dynamicId: dynamicProspectsId,
        filterColumn: "",
        filterValue: "",
        searchVal: "",
        sortColumn: "",
        sortType: "",
        status: "1,2,3,4,5,8,9,10",
      })
    );
  };

  const filteredUniqueNumbers = uniqueNumbersOfProspects?.filter(
    (phone) => phone !== null
  );

  const handleInputChangeProspects = (e: any) => {
    const newInputValue = e.target.value;
    setInputValueTextProspects(newInputValue);
    dispatch(setsearchvalProspects(newInputValue));
    // const actionPayload = {
    //   filterColumn: "",
    //   filterValue: "",
    //   searchVal: newInputValue,
    //   sortColumn: "",
    //   sortType: "",
    //   prospectPageFilter: ProspectPageFilter,
    // };
    // dispatch(setProspectFilterItems(actionPayload));

    if (newInputValue === "" || newInputValue === null) {
      dispatch(
        setProspectFilterItems({
          dynamicId: dynamicProspectsId,
          filterColumn: "",
          filterValue: "",
          searchVal: newInputValue,
          sortColumn: "",
          sortType: "",
          status: "1",
          prospectPageFilter: ProspectPageFilter,
        })
      );
    } else {
      dispatch(
        setProspectFilterItems({
          dynamicId: dynamicProspectsId,
          filterColumn: "",
          filterValue: "",
          searchVal: newInputValue,
          sortColumn: "",
          sortType: "",
          status: "",
          prospectPageFilter: ProspectPageFilter,
        })
      );
    }
  };

  const [selectedSearchProspects, setselectedSearchProspects] =
    useState<any>("");

  useEffect(() => {
    if (searchValProspects !== null) {
      setselectedSearchProspects("search");
      setState((prevData: any) => ({
        ...prevData,
        selectedFilterDropDownTwo: null,
      }));
    }
  }, [searchValProspects]);
  let splitProspectStatus = prospect_status?.split(",");

  let resRecords = itemsArrProspectsAll?.filter((elem: any) => {
    let isExists = false;
    for (let i = 0; i < splitProspectStatus?.length; i++) {
      if (elem.status == splitProspectStatus[i]) {
        isExists = true;
        break;
      }
    }
    return isExists;
  });

  let filteredStatusesProspects = resRecords.map((elem) => elem.type);
  let statusMappingProspects = filteredStatusesProspects.filter(
    (elem) => elem !== "Make Client"
  );

  // const statusMappingProspects: any = [
  //   "Inactive",
  //   "Active",
  //   "Waitlist",
  //   "Deceased",
  //   "Deposit",
  //   "Moved In",
  //   "For Future",
  // ];

  const handleChangeOfDropdownProspects = (
    names: string[] | any,
    type: string
  ) => {
    let filterColumn;

    switch (type) {
      case "name":
        filterColumn = "name";
        break;
      case "email":
        filterColumn = "email";
        break;
      case "mobile":
        filterColumn = "mobile";
        break;
      case "refferer":
        filterColumn = "refferer";
        break;
      default:
        const statusMap: any = itemsArrProspectsAll.reduce(
          (map: any, item: any) => {
            map[item.type] = item.status;
            return map;
          },
          {}
        );
        const numericalValues = names.map((name: any) => statusMap[name]);
        filterColumn = "status";
        names = numericalValues;
    }
    let filterValue;

    filterValue = names.join(",");

    // const actionPayload = {
    //   filterColumn,
    //   filterValue,
    //   searchVal: "",
    //   sortColumn: "",
    //   sortType: "",
    //   auditPageFilter: ProspectPageFilter,
    // };
    // // dispatch(getAuditByFilterThunk(actionPayload)); /c
    // dispatch(setProspectFilterItems(actionPayload));

    if (names.length !== 0) {
      dispatch(
        setProspectFilterItems({
          dynamicId: dynamicProspectsId,
          filterColumn,
          filterValue: names.join(","),
          searchVal: "",
          sortColumn: "",
          sortType: "",
          status: "",
        })
      );
    } else {
      // dispatch(getProspectsThunk({ dynamicId: dynamicProspectsId }));
      dispatch(
        setProspectFilterItems({
          dynamicId: dynamicProspectsId,
          filterColumn,
          filterValue: names.join(","),
          searchVal: "",
          sortColumn: "",
          sortType: "",
          status: "1,2,3,4,5,8,9,10",
        })
      );
    }
  };

  const handleCheckboxChangeProspects = (
    elem: string,
    type: string,
    index: number
  ) => {
    let targetType;

    switch (true) {
      case type.includes("name"):
        targetType = "name";
        break;
      case type.includes("email"):
        targetType = "email";
        break;
      case type.includes("mobile"):
        targetType = "mobile";
        break;
      case type.includes("referrer"):
        targetType = "referrer";
        break;
      default:
        targetType = "status";
    }

    const updatedSelectedTitles = selectedTitles?.includes(elem)
      ? selectedTitles.filter((t) => t !== elem)
      : [...selectedTitles, elem];

    setSelectedTitles(updatedSelectedTitles);
    dispatch(setselectedProspects(updatedSelectedTitles));
    handleChangeOfDropdownProspects(updatedSelectedTitles, targetType);
  };
  const [sortByProspects, setSortByProspects] = useState(sortByProspect);

  const handleSortChangeProspects = (
    val: string,
    selectedSortIndex: number | any
  ) => {
    const capturedVal = val === "newest on top" ? "asc" : "desc";
    setsortByClients(val);
    setSortByProspects(val);
    dispatch(setSortByProspect(val));
    dispatch(setselectedSortValuesProspects(val));
    let sortColumn = "";
    switch (prospectsTableDataObject?.selectedSortIndex) {
      case 0:
        sortColumn = "name";
        break;
      case 1:
        sortColumn = "email";
        break;
      case 2:
        sortColumn = "mobile";
        break;
      default:
        sortColumn = "refferer";
        break;
    }

    const filterData = {
      dynamicId: dynamicProspectsId,
      filterColumn: "",
      filterValue: "",
      searchVal: "",
      sortColumn,
      sortType: capturedVal,
      status: "1,2,3,4,5,8,9,10",
    };
    // dispatch(getAuditByFilterThunk(filterData));
    dispatch(setProspectFilterItems(filterData));
  };

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////// CONTACTS DROPDOWN //////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const handleInputChangeContacts = (e: any) => {
    setsearchDropdownContacts(true);
    const newInputValue = e.target.value;
    setinputValueTextContacts(newInputValue);
    dispatch(setsearchValueContacts(newInputValue));
    if (newInputValue === "" || newInputValue === null) {
      // dispatch(
      //   getContactsByFilterThunk({
      //     dynamicId: dynamicContactId,
      //     filterColumn: "",
      //     filterValue: "",
      //     searchVal: newInputValue,
      //     sortColumn: "",
      //     sortType: "",
      //     status: "1",
      //     ContactPage
      //   })
      // );

      dispatch(
        setContactFilterItems({
          dynamicId: dynamicContactId,
          filterColumn: "",
          filterValue: "",
          searchVal: newInputValue,
          sortColumn: "",
          sortType: "",
          status: "1",
          ContactPage,
        })
      );
    } else {
      // dispatch(
      //   getContactsByFilterThunk({
      //     dynamicId: dynamicContactId,
      //     filterColumn: "",
      //     filterValue: "",
      //     searchVal: newInputValue,
      //     sortColumn: "",
      //     sortType: "",
      //     status: "",
      //     ContactPage
      //   })
      // );
      dispatch(
        setContactFilterItems({
          dynamicId: dynamicContactId,
          filterColumn: "",
          filterValue: "",
          searchVal: newInputValue,
          sortColumn: "",
          sortType: "",
          status: "1",
          ContactPage,
        })
      );
    }
  };

  const getAllContactsDataArr = useAppSelector(
    (state) => state?.contacts?.contacts
  );
  const { contactName, contactEmail, contactPhone } = useAppSelector(
    (state) => state?.contacts
  );

  // const dynamicContactId = useAppSelector(
  //   (state) => state.contacts.getUserTypeIdForContact?.id
  // );

  // @ts-ignore
  const dynamicContactId = parseInt(localStorage.getItem("dynamicContactId"));

  useEffect(() => {
    if (searchValContacts !== "") {
      setState((prevData: any) => ({ ...prevData, selectedItem: Data[1] }));
      // inputRefContacts?.current?.focus();
      // setState((prevData: any) => ({
      //   ...prevData,
      //   selectedFilterDropDownTwo: null,
      // }));
    }
  }, [searchValContacts]);

  ////////////////  UNIQUE FULL NAME CONTACTS //////////////////
  // const uniqueNamesOfContacts = contactName?.map(
  //   (elem: any) => elem?.first_name
  // );
  const uniqueNamesOfContacts = contactName
    ?.map((elem: any) => elem?.first_name + " " + (elem?.last_name || ""))
    ?.filter((name: any) => name !== null);

  ///////////////////   UNIQUE EMAIL CONTACTS //////////////////
  const uniqueEmailsOfContacts = contactEmail
    ?.map((elem: any) => elem?.email)
    ?.filter((email: any) => email !== null);

  //////////////////   UNIQUE PHONE GCONTACTS //////////////////

  const uniqueNumbersOfContacts = contactPhone
    ?.map((elem: any) => elem?.mobile)
    ?.filter((mobile: any) => mobile !== null);

  ///////////////////  UNIQUE ASSOCIATED BUISSNESS CONTACTS ///////////////
  const uniqueBusinesssOfContacts = [
    ...new Set(
      getAllContactsDataArr?.map((elem: { phone: string }) => elem?.phone)
    ),
  ];

  /////////////////////// UNIQUE STATUS CONTACTS /////////////////
  const statusMappingContacts: any = ["Inactive", "Active"];
  const [sortByContact, setSortByContact] = useState(sortByContacts);
  const handleSortChangeContacts = (
    val: string,
    selectedSortIndex: number | any
  ) => {
    const capturedVal = val === "newest on top" ? "asc" : "desc";
    // setsortByClients(val);
    setSortByContact(val);
    dispatch(setSortByContacts(val));

    let sortColumn = "";
    switch (contactsTableDataObject.selectedSortIndex) {
      case 0:
        sortColumn = "name";
        break;
      case 1:
        sortColumn = "email";
        break;
      case 2:
        sortColumn = "mobile";
        break;
      default:
        sortColumn = "associatedBusiness";
        break;
    }

    const filterData = {
      dynamicId: dynamicContactId,
      filterColumn: "",
      filterValue: "",
      searchVal: "",
      sortColumn,
      sortType: capturedVal,
      status: "1",
      ContactPage,
    };

    // dispatch(getContactsByFilterThunk(filterData));
    dispatch(setContactFilterItems(filterData));
  };

  ////////////////// CHECKBOX CHANGE OF FILTERS FOR CONTACTS /////////////
  const handleCheckboxChangeContacts = (elem: any, type: any, index: any) => {
    let targetType;
    switch (true) {
      case type.includes("name"):
        targetType = "name";
        break;
      case type.includes("email"):
        targetType = "email";
        break;
      case type.includes("phone"):
        targetType = "mobile";
        break;
      case type.includes("associatedbusiness"):
        targetType = "associatedbusiness";
        break;
      default:
        targetType = "status";
    }

    const updatedSelectedTitles = selectedTitles?.includes(elem)
      ? selectedTitles.filter((t) => t !== elem)
      : [...selectedTitles, elem];

    setSelectedTitles(updatedSelectedTitles);
    dispatch(setFilterOptionsContact(updatedSelectedTitles));
    dispatch(setselectedNames(updatedSelectedTitles));
    handleChangeOfDropdownContacts(updatedSelectedTitles, targetType);
  };

  const handleChangeOfDropdownContacts = (names: any, type: any) => {
    let filterColumn;

    switch (type) {
      case "name":
        filterColumn = "name";
        break;
      case "email":
        filterColumn = "email";
        break;
      case "mobile":
        filterColumn = "mobile";
        break;
      case "associatedBusiness":
        filterColumn = "associatedBusiness";
        break;
      default:
        const statusMap: any = {
          Inactive: 0,
          Active: 1,
        };
        const numericalValues = names.map((name: any) => statusMap[name]);
        filterColumn = "status";
        names = numericalValues;
    }

    let filterValue;

    if (filterColumn === "status" && names.length === 0) {
      filterValue = "1,3,4,5";
    } else {
      filterValue = names.join(",");
    }
    if (names.length !== 0) {
      // dispatch(
      //   getContactsByFilterThunk({
      //     dynamicId: dynamicContactId,
      //     filterColumn,
      //     filterValue,
      //     searchVal: "",
      //     sortColumn: "",
      //     sortType: "",
      //     status: "",
      //     ContactPage
      //   })
      // );
      dispatch(
        setContactFilterItems({
          dynamicId: dynamicContactId,
          filterColumn,
          filterValue,
          searchVal: "",
          sortColumn: "",
          sortType: "",
          status: "",
          ContactPage,
        })
      );
    } else {
      // dispatch(
      //   getContactsByFilterThunk({
      //     dynamicId: dynamicContactId,
      //     filterColumn,
      //     filterValue,
      //     searchVal: "",
      //     sortColumn: "",
      //     sortType: "",
      //     status: "1",
      //     ContactPage
      //   })
      // );
      dispatch(
        setContactFilterItems({
          dynamicId: dynamicContactId,
          filterColumn,
          filterValue,
          searchVal: "",
          sortColumn: "",
          sortType: "",
          status: "1",
          ContactPage,
        })
      );
    }
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////// STAFF DROPDOWN //////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  const { staffPage, staffName, staffEmail, staffPhone } = useAppSelector(
    (state) => state?.staff
  );
  const getAllDataStaff = useAppSelector((state) => state?.staff?.staff);

  // const dynamicStaffId = useAppSelector(
  //   (state) => state?.staff?.getUserTypeIdForStaff?.id
  // );

  // @ts-ignore
  const dynamicStaffId = parseInt(localStorage.getItem("dynamicStaffId"));

  // useEffect(() => {
  //   dispatch(getStaffRolesThunk({ dynamicStaffId }));
  // }, []);

  ///////////////////////////////////////  UNIQUE FULL NAMES ///////////////////////////

  // const uniqueNamesOfStaff = staffName?.map(
  //   (elem: any) => elem.first_name + " " + elem.last_name
  // );
  const uniqueNamesOfStaff = staffName
    ?.map((elem: any) => elem.first_name + " " + elem.last_name)
    ?.filter((name: any) => name !== null);
  ////////////////////////////////////// UNQIUE EMAILS //////////////////////////////////
  const uniqueEmailsOfStaff = staffEmail
    ?.map((elem: any) => elem?.email)
    ?.filter((email: any) => email !== null);

  /////////////////////////////////////////// UNIQUE ROLES ////////////////////////////
  const staffRoles = useAppSelector((state) => state?.staff?.staffRoles);
  const uniqueRolesStaff = staffRoles?.data?.data[0];

  const [roleDescriptions, setRoleDescriptions] = useState([]);

  useEffect(() => {
    if (uniqueRolesStaff) {
      const descriptions = uniqueRolesStaff?.roles?.map(
        (elem: any) => elem?.desc
      );
      setRoleDescriptions(descriptions);
    }
  }, [uniqueRolesStaff]);
  /////////////////////////////////////// UNQIUE MOBILE ////////////////////////////////////
  // const uniqueMobileOfStaff = staffPhone?.map(
  //   (elem: any) => elem?.mobile
  // );
  const uniqueMobileOfStaff = staffPhone
    ?.map((elem: any) => elem?.mobile)
    ?.filter((mobile: any) => mobile !== null);

  // const uniqueMobileOfStaff = [
  //   ...new Set(
  //     getAllDataStaff?.map((elem: { mobile: string }) => elem?.mobile || )
  //   ),
  // ].filter(Boolean);

  //////////////////////////////////////// UNIQUE STATUS //////////////////////////////////

  const statusMappingStatus: string[] = [
    "Inactive",
    "Active",
    "On Leave",
    "Probation",
  ];

  ///////////////////////////////// CHECKBOX CHANGE STAFF //////////////////////////////////////////////////

  const handleCheckboxChangeStaff = (elem: any, type: any, index: any) => {
    let targetType;
    switch (true) {
      case type.includes("name"):
        targetType = "name";
        break;
      case type.includes("email"):
        targetType = "email";
        break;
      case type.includes("mobile"):
        targetType = "mobile";
        break;
      case type.includes("role"):
        targetType = "role";
        break;
      default:
        targetType = "status";
    }

    const updatedSelectedTitles = selectedTitles?.includes(elem)
      ? selectedTitles.filter((t) => t !== elem)
      : [...selectedTitles, elem];

    setSelectedTitles(updatedSelectedTitles);
    dispatch(setFilterOptions(updatedSelectedTitles));
    // dispatch(setselectedNames(updatedSelectedTitles));
    handleChangeOfDropdownStaff(updatedSelectedTitles, targetType);
  };

  const handleChangeOfDropdownStaff = (names: any, type: any) => {
    let filterColumn;

    switch (type) {
      case "name":
        filterColumn = "name";
        break;
      case "email":
        filterColumn = "email";
        break;
      case "mobile":
        filterColumn = "mobile";
        break;
      case "role":
        filterColumn = "role";
        break;
      default:
        const statusMap: any = {
          Inactive: 0,
          Active: 1,
          "On Leave": 2,
          Probation: 7,
        };
        const numericalValues = names.map((name: any) => statusMap[name]);
        filterColumn = "status";
        names = numericalValues;
    }

    let filterValue;

    filterValue = names.join(",");

    if (names.length !== 0) {
      // dispatch(
      //   getStaffByFilterThunk({
      //     dynamicId: dynamicStaffId,
      //     filterColumn,
      //     filterValue,
      //     searchVal: "",
      //     sortColumn: "",
      //     sortType: "",
      //     status: "",
      //     staffPage,
      //   })
      // );
      dispatch(
        setStaffFilterItems({
          dynamicId: dynamicStaffId,
          filterColumn,
          filterValue,
          searchVal: "",
          sortColumn: "",
          sortType: "",
          status: "",
          staffPage,
        })
      );
    } else {
      // dispatch(
      //   getStaffByFilterThunk({
      //     dynamicId: dynamicStaffId,
      //     filterColumn,
      //     filterValue,
      //     searchVal: "",
      //     sortColumn: "",
      //     sortType: "",
      //     status: "1,2,7",
      //     staffPage
      //   })
      // );
      dispatch(
        setStaffFilterItems({
          dynamicId: dynamicStaffId,
          filterColumn,
          filterValue,
          searchVal: "",
          sortColumn: "",
          sortType: "",
          status: "1,2,7",
          staffPage,
        })
      );
    }
  };

  const searchValueStaff = useAppSelector(
    (state) => state?.staff?.searchValueStaff
  );

  // SET SEARCH ACTIVE WHEN RESULTS ALREADY EXIST FROM REDUX
  useEffect(() => {
    if (searchValueStaff !== "") {
      setState((prevData: any) => ({ ...prevData, selectedItem: Data[1] }));
      // inputRefStaff?.current?.focus();
      setState((prevData: any) => ({
        ...prevData,
        selectedFilterDropDownTwo: null,
      }));
    }
  }, [searchValueStaff]);
  const [inputValueTextStaff, setinputValueTextStaff] =
    useState(searchValueStaff);

  const handleInputChangeStaff = (e: string | any) => {
    const newInputValue = e.target.value;
    setinputValueTextStaff(newInputValue);
    dispatch(setSearchValueStaff(newInputValue));
    if (newInputValue === "" || newInputValue === null) {
      // dispatch(
      //   getStaffByFilterThunk({
      //     dynamicId: dynamicStaffId,
      //     filterColumn: "",
      //     filterValue: "",
      //     searchVal: newInputValue,
      //     sortColumn: "",
      //     sortType: "",
      //     status: "1,2,7",
      //     staffPage
      //   })
      // );
      dispatch(
        setStaffFilterItems({
          dynamicId: dynamicStaffId,
          filterColumn: "",
          filterValue: "",
          searchVal: newInputValue,
          sortColumn: "",
          sortType: "",
          status: "1,2,7",
          staffPage,
        })
      );
    } else {
      // dispatch(
      //   getStaffByFilterThunk({
      //     dynamicId: dynamicStaffId,
      //     filterColumn: "",
      //     filterValue: "",
      //     searchVal: newInputValue,
      //     sortColumn: "",
      //     sortType: "",
      //     status: "",
      //     staffPage
      //   })
      // );
      dispatch(
        setStaffFilterItems({
          dynamicId: dynamicStaffId,
          filterColumn: "",
          filterValue: "",
          searchVal: newInputValue,
          sortColumn: "",
          sortType: "",
          status: "",
          staffPage,
        })
      );
    }
  };

  const handleClearSearchStaff = () => {
    setinputValueTextStaff("");
    dispatch(resetSearchValueStaff());
    // dispatch(getStaffThunk({ dynamicStaffId }));
    dispatch(
      setStaffFilterItems({
        dynamicId: dynamicStaffId,
        filterColumn: "",
        filterValue: "",
        searchVal: "",
        sortColumn: "",
        sortType: "",
        status: "",
        staffPage,
      })
    );
  };

  const [sortByStaff, setsortByStaff] = useState("");

  const handleSortChangeStaff = (val: string, selectedSortIndex: number) => {
    const capturedVal = val === "newest on top" ? "asc" : "desc";
    setsortByStaff(val);
    dispatch(setSortOptions(val));

    let sortColumn = "";
    switch (selectedSortIndex) {
      case 0:
        sortColumn = "name";
        break;
      case 1:
        sortColumn = "email";
        break;
      case 2:
        sortColumn = "mobile";
        break;
      case 3:
        sortColumn = "role";
        break;
      default:
        sortColumn = "status";
        break;
    }

    const filterData = {
      dynamicId: dynamicStaffId,
      filterColumn: "",
      filterValue: "",
      searchVal: "",
      sortColumn,
      sortType: capturedVal,
      status: "1,2,7",
      staffPage,
    };

    // dispatch(getStaffByFilterThunk(filterData));
    dispatch(setStaffFilterItems(filterData));
  };

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////// Meal Builder TABLE ////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const handleCheckboxChangeMeals = (elem: any, type: any, index: any) => {
    let targetType;
    switch (true) {
      case type.includes("name"):
        setSelectedTitles([]);
        targetType = "meal_name";
        break;
      case type.includes("tags"):
        setSelectedTitles([]);
        targetType = "tags";
        break;
      case type.includes("prepTime"):
        setSelectedTitles([]);
        targetType = "prep_time";
        break;
      case type.includes("cookingTime"):
        setSelectedTitles([]);
        targetType = "cooking_time";
        break;
      default:
        targetType = "";
    }

    const updatedSelectedTitles = selectedTitles?.includes(elem)
      ? selectedTitles.filter((t) => t !== elem)
      : [...selectedTitles, elem];

    setSelectedTitles(updatedSelectedTitles);
    dispatch(setselectedFilterItemsMeals(updatedSelectedTitles));
    handleChangeOfDropdownMeals(updatedSelectedTitles, targetType);
  };

  const handleChangeOfDropdownMeals = (names: any, type: any) => {
    let filterColumn;

    switch (type) {
      case "meal_name":
        filterColumn = "meal_name";
        break;
      case "tags":
        filterColumn = "tags";
        break;
      case "prep_time":
        filterColumn = "prep_time";
        break;
      case "cooking_time":
        filterColumn = "cooking_time";
        break;
      default:
        filterColumn = "";
    }

    let filterValue;

    filterValue = names.join(",");

    const actionPayload = {
      filterColumn,
      filterValue,
      searchVal: "",
      sortColumn: "",
      sortType: "",
    };

    dispatch(getMealsByFilterThunk(actionPayload));
  };

  const uniqueNamesOfMeals = [
    ...new Set(
      allMealBuilder?.map((elem: { meal_name: string }) => elem?.meal_name)
    ),
  ];

  const uniquePrepTimesOfMeals = [
    ...new Set(
      allMealBuilder
        ?.map((elem: any) => {
          const prepTime = parseFloat(elem?.prep_time);
          const prepTimeUnit = elem?.prep_time_unit;
          if (!isNaN(prepTime) && prepTime >= 1) {
            return `${prepTime} ${prepTimeUnit}`;
          } else {
            return `null`;
          }
        })
        .filter((elem: any) => !elem.includes(null))
    ),
  ];

  const uniqueCookingTimesOfMeals = [
    ...new Set(
      allMealBuilder
        ?.map((elem: any) => {
          const cookingTime = parseFloat(elem?.cooking_time);
          const cookingTimeUnit = elem?.cooking_time_unit;

          if (!isNaN(cookingTime) && cookingTime >= 1) {
            return `${cookingTime} ${cookingTimeUnit}`;
          } else {
            // return `${cookingTime} ${cookingTimeUnit}`;
            return `null`;
          }
        })
        .filter((elem: any) => !elem.includes(null))
    ),
  ];

  //////////////////////////  UNIQUE RESULTS ////////////////////////////////////////////

  //////////////////////////////////  MEALS NAMES OF   //////////////////////

  // const fullNamesClients = allClientData;
  // const uniqueNamesOfClients = [...new Set(fullNamesClients)];

  function createMealsNameArray(Meals: any) {
    const fullNamesClients = Meals?.map((elem: any) => {
      const { meal_name } = elem;
      return meal_name;
    });
    return fullNamesClients || [];
  }
  const MealsNameArray = createMealsNameArray(allMealBuilder?.data?.data || []);

  // const uniqueMealsNameOfMealsBuilder = [...new Set(MealsNameArray)];

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////// Menu Builder TABLE ////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const renderSelectedSortOptions = (index: number) =>
    documentTableDataObject.selectedSortIndex === index &&
    selectedSortDropDownOne?.id === 3 && (
      <SelectedSortOptions
        options={["newest on top", "oldest on top"]}
        sortBy={sortByDocument}
        sortChange={handleSortChange}
        selectedSortIndex={selectedSortIndex}
        iconColor={filterIconCo̥lor}
      />
    );

  const renderSelectedSortOptionsProviders = (index: number) =>
    providerTableDataObject.selectedSortIndex === index &&
    selectedSortDropDownOne?.id === 3 && (
      <SelectedSortOptions
        options={["newest on top", "oldest on top"]}
        sortBy={sortByProvider}
        sortChange={handleSortChangeProvider}
        selectedSortIndex={selectedSortIndex}
        iconColor={filterIconCo̥lor}
      />
    );

  const renderSelectedSortOptionsAssociations = (index: number) =>
    associationTableDataObject.selectedSortIndex === index &&
    selectedSortDropDownOne?.id === 3 && (
      <SelectedSortOptions
        options={["newest on top", "oldest on top"]}
        sortBy={sortByAssociaiton}
        sortChange={handleSortChangeAssociations}
        selectedSortIndex={selectedSortIndex}
        iconColor={filterIconCo̥lor}
      />
    );

  const renderSelectedSortOptionsClients = (index: number) =>
    clientsTableDataObject.selectedSortIndex === index &&
    selectedSortDropDownOne?.id === 3 && (
      <SelectedSortOptions
        options={["newest on top", "oldest on top"]}
        sortBy={sortByClients}
        sortChange={handleSortChangeClients}
        selectedSortIndex={clientsTableDataObject.selecredSortIndex}
        iconColor={filterIconCo̥lor}
      />
    );

  const inputRefProviders = useRef<any>();

  return (
    <>
      {/* DOCUMENT DROPDOWN */}
      {type === "documents" && (
        <>
          <Dropdown
            selectedItem={documentTableDataObject.currentActive}
            data={Data}
            ref={inputRef}
            onFocus={handleFoucsInSearchOfAssociations}
            inputTextValue={inputValueText}
            onChange={handleInputChange}
            clearSearch={handleClearInputFieldProviders}
            onClick={handleItemClickDropdownOneDocuments}
            selectedType={"document"}
            selectedFilterDropDownOne={selectedFilterDropDownOne}
            tableObject={documentTableDataObject}
            selectedItemIndex={selectedItemIndex}
            options={["Document", "Due Date", "Primary Approver"]}
            clickType={"document"}
            handleClickColumn={handleClickAssociationsColumn}
            selectedOps={selectedTitles}
            allOps={
              documentTableDataObject.selectedItemIndex === 0
                ? documentsName
                : documentTableDataObject.selectedItemIndex === 1
                ? uniqueReviewDates
                : uniquePrimaryApproversArr
            }
            setselectedOps={setSelectedTitles}
            dispatch={dispatch}
            clientId={clientId}
            pathname={pathnameBusinessAndTeams}
            currElem={
              documentTableDataObject.selectedItemIndex === 0
                ? "documents"
                : documentTableDataObject.selectedItemIndex === 1
                ? "primaryReview"
                : "primary_approvers"
            }
            handleChangeCheckboxOption={handleCheckboxChangeDocuments}
            selectedSortDropDownOne={selectedSortDropDownOne}
            currentActive={documentTableDataObject.currentActive}
            handleOpenSortOptionsOnBasisOfIndexOfColumn={
              handleOpenSortOptionsOnBasisOfIndexOfColumn
            }
            props={"document"}
            renderSelectedSortOptions={renderSelectedSortOptions}
            tableHeadArr={["Document", "Due Date", "Primary Approver"]}
          />
        </>
      )}
      {/* PROVIDER DROPDOWN */}
      {type === "providers" && (
        <>
          <Dropdown
            selectedItem={providerTableDataObject.currentActive}
            data={Data}
            type={"providers"}
            ref={inputRefProviders}
            onFocus={handleFoucsInSearchOfAssociations}
            inputTextValue={inputValueTextProviders}
            onChange={handleInputChangeProviders}
            clearSearch={handleClearInputFieldProviders}
            onClick={handleItemClickDropdownOneDocuments}
            selectedType={"providers"}
            clickType={"providers"}
            selectedFilterDropDownOne={selectedFilterDropDownOne}
            tableObject={providerTableDataObject}
            selectedItemIndex={selectedItemIndex}
            options={["Tenant Name", "Provider Type"]}
            handleClickColumn={handleClickAssociationsColumn}
            selectedOps={selectedTitles}
            allOps={
              providerTableDataObject.selectedItemIndex === 0
                ? providerNames
                : providerTableDataObject.selectedItemIndex === 1
                ? providerType
                : null
            }
            setselectedOps={setSelectedTitles}
            dispatch={dispatch}
            clientId={clientId}
            pathname={pathnameBusinessAndTeams}
            currElem={
              providerTableDataObject.selectedItemIndex === 0
                ? "tenant_name"
                : providerTableDataObject.selectedItemIndex === 1
                ? "provider_type"
                : null
            }
            handleChangeCheckboxOption={handleCheckboxChangeProviders}
            selectedSortDropDownOne={selectedSortDropDownOne}
            currentActive={providerTableDataObject.currentActive}
            handleOpenSortOptionsOnBasisOfIndexOfColumn={
              handleOpenSortOptionsOnBasisOfIndexOfColumn
            }
            props={"providers"}
            renderSelectedSortOptions={renderSelectedSortOptionsProviders}
            tableHeadArr={["Tenant Name", "Provider Type"]}
          />
        </>
      )}
      {/* ASSOCIATIONS DROPDOWN */}
      {type === "asociations" && (
        <>
          <div className="dropdown-wrapper-main">
            <div className="associations-table-dropdown">
              <div className="dropdown-2-box">
                <div className="dropdown-2">
                  <DropdownOptions
                    selectedItem={associationTableDataObject?.currentActive}
                    data={Data}
                    ref={inputRefAssociations}
                    onFocus={handleFoucsInSearchOfAssociations}
                    inputTextValue={inputValueTextAssociations}
                    onChange={handleInputChangeAssociations}
                    clearSearch={handleClearSearchAssociations}
                    onClick={handleItemClickDropdownOneDocuments}
                    selectedType={"association"}
                  />
                </div>

                {/* ///////////////////////////////////////////////////// FILTER OPTIONS ////////////////////////////////////////////////////////// */}
                {!!selectedFilterDropDownOne &&
                selectedFilterDropDownOne?.id === 1 ? (
                  <>
                    <SelectedFilterDropdown
                      options={tableHeadArr}
                      tableObject={associationTableDataObject}
                      selectedItemIndex={selectedItemIndex}
                      clickType="association"
                      handleClickColumn={handleClickAssociationsColumn}
                    />
                  </>
                ) : null}

                {/* ////////////////////////////////////////////////    FILTERING ASSOCIATONS    ////////////////////////////////////////////////////////////////////// */}

                {selectedFilterDropDownOne?.id === 1 ? (
                  <SelectedFilterOption
                    selectedOps={selectedTitles}
                    allOps={
                      associationTableDataObject.selectedItemIndex === 0
                        ? uniqueUserContactNames
                        : associationTableDataObject.selectedItemIndex === 1
                        ? uniqueUserContactCategory
                        : associationTableDataObject.selectedItemIndex === 2
                        ? uniqueRelationshipName
                        : newArr
                    }
                    setselectedOps={setSelectedTitles}
                    dispatch={dispatch}
                    clientId={clientId}
                    pathname={pathnameBusinessAndTeams}
                    currElem={
                      associationTableDataObject.selectedItemIndex === 0
                        ? "name"
                        : associationTableDataObject.selectedItemIndex === 2
                        ? "relationships"
                        : associationTableDataObject.selectedItemIndex === 1
                        ? "contact category"
                        : "contact number"
                    }
                    handleChangeCheckboxOption={
                      handleCheckboxChangeAssociations
                    }
                  />
                ) : null}

                {/*  /////////////////////////////////////////////////////////////////////////////////////////////////////////// */}

                {/* SORT OPTIONS */}
                {!!selectedSortDropDownOne &&
                associationTableDataObject.currentActive === "sort" ? (
                  <>
                    <SelectedSortDropdown
                      options={tableHeadArr}
                      tableObject={associationTableDataObject}
                      selectedItemIndex={selectedSortIndex}
                      props={"association"}
                      handeChangeSortOptions={
                        handleOpenSortOptionsOnBasisOfIndexOfColumn
                      }
                    />
                    {/* SORT OPTIONS ITEMS */}
                    {renderSelectedSortOptionsAssociations(0)}
                    {renderSelectedSortOptionsAssociations(1)}
                    {renderSelectedSortOptionsAssociations(2)}
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </>
      )}
      {/* CLIENTS DROPDOWN */}
      {(type === "clients" || type === "providerClients") && (
        <div className="dropdown-wrapper-main">
          <div className="associations-table-dropdown">
            <div className="dropdown-2-box">
              <div className="dropdown-2">
                <DropdownOptions
                  selectedItem={clientsTableDataObject?.currentActive}
                  data={Data}
                  ref={inputRefClients}
                  onFocus={handleFoucsInSearchOfAssociations}
                  inputTextValue={inputValueTextClients}
                  onChange={handleInputChangeClients}
                  clearSearch={handleClearSearchClients}
                  onClick={handleItemClickDropdownOneDocuments}
                  selectedType={"clients"}
                />
              </div>

              {/* ///////////////////////////////////////////////////// FILTER OPTIONS ////////////////////////////////////////////////////////// */}
              {!!selectedFilterDropDownOne &&
              selectedFilterDropDownOne?.id === 1 ? (
                <>
                  <SelectedFilterDropdown
                    options={tableHeadArrClients}
                    tableObject={clientsTableDataObject}
                    selectedItemIndex={
                      clientsTableDataObject?.selectedItemIndex
                    }
                    clickType="clients"
                    handleClickColumn={handleClickAssociationsColumn}
                  />
                </>
              ) : null}

              {/* ////////////////////////////////////////////////    FILTERING ASSOCIATONS    ////////////////////////////////////////////////////////////////////// */}
              {selectedFilterDropDownOne?.id === 1 && (
                <>
                  <SelectedFilterOption
                    selectedOps={selectedTitles}
                    allOps={
                      clientsTableDataObject.selectedItemIndex === 0
                        ? businessType === "B2B"
                          ? cleanedArray
                          : uniqueNamesOfClients
                        : clientsTableDataObject.selectedItemIndex === 1
                        ? uniqueEmailsOfClients
                        : clientsTableDataObject.selectedItemIndex === 2
                        ? uniqueDOBOfClients
                        : clientsTableDataObject.selectedItemIndex === 3
                        ? uniqueFundingTypeOfClients
                        : clientsTableDataObject.selectedItemIndex === 4
                        ? uniqueResidenceOfClients
                        : statusMapping
                    }
                    setselectedOps={setSelectedTitles}
                    dispatch={dispatch}
                    clientId={clientId}
                    pathname={pathnameBusinessAndTeams}
                    currElem={
                      clientsTableDataObject.selectedItemIndex === 0
                        ? "name"
                        : clientsTableDataObject.selectedItemIndex === 1
                        ? "email"
                        : clientsTableDataObject.selectedItemIndex === 2
                        ? "dob"
                        : clientsTableDataObject.selectedItemIndex === 3
                        ? "fundingType"
                        : clientsTableDataObject.selectedItemIndex === 4
                        ? "residence"
                        : clientsTableDataObject.selectedItemIndex === 5
                        ? "status"
                        : ""
                    }
                    type={type}
                    handleChangeCheckboxOption={handleCheckboxChangeClients}
                    handelChangeSelectAll={(
                      records: any,
                      filterColumn: any
                    ) => {
                      handleChangeOfDropdownClients(records, filterColumn);
                    }}
                  />
                </>
              )}

              {/*  /////////////////////////////////////////////////////////////////////////////////////////////////////////// */}

              {/* SORT OPTIONS */}
              {!!selectedSortDropDownOne &&
              clientsTableDataObject.currentActive === "sort" ? (
                <>
                  <SelectedSortDropdown
                    options={tableHeadArrClients}
                    tableObject={clientsTableDataObject}
                    selectedItemIndex={clientsTableDataObject.selectedSortIndex}
                    props={"clients"}
                    handeChangeSortOptions={
                      handleOpenSortOptionsOnBasisOfIndexOfColumn
                    }
                  />
                  {/* SORT OPTIONS ITEMS */}
                  {renderSelectedSortOptionsClients(0)}
                  {renderSelectedSortOptionsClients(1)}
                  {renderSelectedSortOptionsClients(2)}
                  {renderSelectedSortOptionsClients(3)}
                  {renderSelectedSortOptionsClients(4)}
                </>
              ) : null}
            </div>
          </div>
        </div>
      )}
      {/* NOTIFICATIONS DROPDOWN */}
      {type === "notifications" && (
        <NotificationsDropdown
          selectedSortDropDownOne={selectedSortDropDownOne}
          selectedFilterDropDownOne={selectedFilterDropDownOne}
          clientId={clientId}
          setState={setState}
        />
      )}
      {/* MEALS DROPDOWN */}
      {type === "mealsBuilder" && (
        <div className="dropdown-wrapper-main">
          <div className="associations-table-dropdown">
            <div className="dropdown-2-box">
              <div className="dropdown-2">
                {/* meals */}
                <DropdownOptions
                  selectedItem={mealsTableDataObject?.currentActive}
                  data={Data}
                  ref={inputRefMeals}
                  onFocus={handleFoucsInSearchOfAssociations}
                  inputTextValue={inputValueTextMeals}
                  onChange={handleInputChangeMeals}
                  clearSearch={handleClearSearchMeals}
                  onClick={handleItemClickDropdownOneDocuments}
                  selectedType={"meals"}
                />
              </div>

              {/* ///////////////////////////////////////////////////// FILTER OPTIONS ////////////////////////////////////////////////////////// */}
              {!!selectedFilterDropDownOne &&
              selectedFilterDropDownOne?.id === 1 ? (
                <>
                  <FilterDropdown
                    tableHeadArr={tableHeadArrMeals}
                    selectedItemIndex={mealsTableDataObject?.selectedItemIndex}
                    handleSelectColumn={handleClickAssociationsColumn}
                    selectedColumnType={"meals"}
                  />
                </>
              ) : null}

              {/* ////////////////////////////////////////////////    FILTERING ASSOCIATONS    ////////////////////////////////////////////////////////////////////// */}
              {/* FILTER OPTIONS ITEMS ON BASIS OF NAME FOR MEALS */}
              {mealsTableDataObject.selectedItemIndex === 0 &&
                selectedFilterDropDownOne?.id === 1 && (
                  <div className="dropdown-2-box-sub-2">
                    <div className="sub-dropdown-2">
                      <Checkbox
                        isChecked={
                          selectedTitles?.length === uniqueNamesOfMeals.length
                        }
                        className="dropdown-2-item dropdown-2-item-no-hover-effect w-full"
                        onChange={() => {
                          if (
                            selectedTitles.length === uniqueNamesOfMeals.length
                          ) {
                            setSelectedTitles([]);
                          } else {
                            setSelectedTitles(uniqueNamesOfMeals);
                            dispatch(getMealBuilder());
                          }
                        }}
                      >
                        (Select All)
                      </Checkbox>

                      {uniqueNamesOfMeals?.map((elem: any, index: any) => (
                        <div
                          className="dropdown-2-item dropdown-2-item-no-hover-effect"
                          // note here key forces re render
                          key={elem?.id}
                        >
                          <Checkbox
                            isChecked={selectedTitles.includes(elem)}
                            onChange={() =>
                              handleCheckboxChangeMeals(elem, "name", index)
                            }
                          >
                            {elem}
                          </Checkbox>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              {mealsTableDataObject.selectedItemIndex === 1 &&
                selectedFilterDropDownOne?.id === 1 && (
                  <div className="dropdown-2-box-sub-2">
                    <div className="sub-dropdown-2">
                      <Checkbox
                        isChecked={
                          selectedTitles.length === uniqueTagsOfMeals.length
                        }
                        className="dropdown-2-item dropdown-2-item-no-hover-effect w-full"
                        onChange={() => {
                          if (
                            selectedTitles.length === uniqueTagsOfMeals.length
                          ) {
                            setSelectedTitles([]);
                          } else {
                            setSelectedTitles(uniqueTagsOfMeals);
                            dispatch(getMealBuilder());
                          }
                        }}
                      >
                        (Select All)
                      </Checkbox>

                      {uniqueTagsOfMeals?.map((elem: any, index: any) => (
                        <div
                          className="dropdown-2-item dropdown-2-item-no-hover-effect"
                          key={index}
                        >
                          {elem !== null && (
                            <Checkbox
                              onChange={() =>
                                handleCheckboxChangeMeals(elem, "tags", index)
                              }
                              isChecked={selectedTitles.includes(elem)}
                            >
                              {elem}
                            </Checkbox>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              {mealsTableDataObject.selectedItemIndex === 2 &&
                selectedFilterDropDownOne?.id === 1 && (
                  <div className="dropdown-2-box-sub-2">
                    <div className="sub-dropdown-2">
                      <Checkbox
                        isChecked={
                          selectedTitles.length ===
                          uniquePrepTimesOfMeals.length
                        }
                        className="dropdown-2-item dropdown-2-item-no-hover-effect w-full"
                        onChange={() => {
                          if (
                            selectedTitles.length ===
                            uniquePrepTimesOfMeals.length
                          ) {
                            setSelectedTitles([]);
                          } else {
                            setSelectedTitles(uniquePrepTimesOfMeals);
                            dispatch(getMealBuilder());
                          }
                        }}
                      >
                        (Select All)
                      </Checkbox>
                      {uniquePrepTimesOfMeals.map((elem: any, index: any) => (
                        <div
                          className="dropdown-2-item dropdown-2-item-no-hover-effect"
                          key={index}
                        >
                          {elem !== null && (
                            <Checkbox
                              isChecked={selectedTitles?.includes(elem)}
                              onChange={() =>
                                handleCheckboxChangeMeals(
                                  elem,
                                  "prepTime",
                                  index
                                )
                              }
                            >
                              {elem}
                            </Checkbox>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              {mealsTableDataObject.selectedItemIndex === 3 && (
                <div className="dropdown-2-box-sub-2">
                  <div className="sub-dropdown-2">
                    <Checkbox
                      isChecked={
                        selectedTitles.length ===
                        uniqueCookingTimesOfMeals.length
                      }
                      className="dropdown-2-item dropdown-2-item-no-hover-effect w-full"
                      onChange={() => {
                        if (
                          selectedTitles.length ===
                          uniqueCookingTimesOfMeals.length
                        ) {
                          setSelectedTitles([]);
                        } else {
                          setSelectedTitles(uniqueCookingTimesOfMeals);
                          dispatch(getMealBuilder());
                        }
                      }}
                    >
                      (Select All)
                    </Checkbox>
                    {uniqueCookingTimesOfMeals?.map((elem: any, index: any) => (
                      <div
                        className="dropdown-2-item dropdown-2-item-no-hover-effect"
                        key={index}
                      >
                        {elem !== null && (
                          <Checkbox
                            isChecked={selectedTitles?.includes(elem)}
                            onChange={() =>
                              handleCheckboxChangeMeals(
                                elem,
                                "cookingTime",
                                index
                              )
                            }
                          >
                            {elem}
                          </Checkbox>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {/*  /////////////////////////////////////////////////////////////////////////////////////////////////////////// */}

              {/* SORT OPTIONS */}
              {!!selectedSortDropDownOne &&
              mealsTableDataObject.currentActive === "sort" ? (
                <div className="dropdown-2-box-sub">
                  <div className="sub-dropdown-2">
                    {tableHeadArrMeals.map((elem: any, index: number) => (
                      <div
                        key={index}
                        className={`dropdown-2-item ${
                          mealsTableDataObject.selectedSortIndex === index
                            ? "dropdown-2-item-selected"
                            : ""
                        }`}
                        onClick={() =>
                          handleOpenSortOptionsOnBasisOfIndexOfColumn(
                            index,
                            "meals"
                          )
                        }
                      >
                        <p>{elem}</p>
                      </div>
                    ))}
                  </div>
                </div>
              ) : null}

              {/* SORT COLUMNS OF ASSOCIATIONS ON BASIS OF INDEX HERE */}
              {/* SORT OPTIONS ITEMS */}

              {mealsTableDataObject.selectedSortIndex === 0 &&
                selectedSortDropDownOne?.id === 3 && (
                  <div className="dropdown-2-box-sub-2">
                    <div className="sub-dropdown-2">
                      {["newest on top", "oldest on top"].map(
                        (title, index) => {
                          return (
                            <div
                              className={`dropdown-2-item capitalize hover:text-white  ${
                                title === sortByClients &&
                                "dropdown-2-item-selected text-white"
                              }`}
                              onClick={() =>
                                handleSortChangeMeals(title, selectedSortIndex)
                              }
                            >
                              <span
                                className="font-normal"
                                style={{
                                  textDecoration: "none",
                                  textTransform: "none",
                                }}
                              >
                                {index === 0 ? "A to Z" : "Z to A"}
                              </span>
                              <div className="relative left-[2px] flex">
                                {index === 0 ? (
                                  <svg
                                    width="20px"
                                    height="20px"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M12 4V20M12 20L8 16M12 20L16 16"
                                      stroke={filterIconCo̥lor}
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                ) : (
                                  <svg
                                    width="20px"
                                    height="20px"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M12 4V20M12 4L8 8M12 4L16 8"
                                      stroke={filterIconCo̥lor}
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                )}
                              </div>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                )}

              {mealsTableDataObject.selectedSortIndex === 1 &&
                selectedSortDropDownOne?.id === 3 && (
                  <div className="dropdown-2-box-sub-2">
                    <div className="sub-dropdown-2">
                      {["newest on top", "oldest on top"].map(
                        (title, index) => {
                          return (
                            <div
                              className={`dropdown-2-item capitalize hover:text-white  ${
                                title === sortByClients &&
                                "dropdown-2-item-selected text-white"
                              }`}
                              onClick={() =>
                                handleSortChangeMeals(
                                  title,

                                  selectedSortIndex
                                )
                              }
                            >
                              <span
                                className="font-normal"
                                style={{
                                  textDecoration: "none",
                                  textTransform: "none",
                                }}
                              >
                                {index === 0 ? "A to Z" : "Z to A"}
                              </span>
                              <div className="relative left-[2px] flex">
                                {index === 0 ? (
                                  <svg
                                    width="20px"
                                    height="20px"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M12 4V20M12 20L8 16M12 20L16 16"
                                      stroke={filterIconCo̥lor}
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                ) : (
                                  <svg
                                    width="20px"
                                    height="20px"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M12 4V20M12 4L8 8M12 4L16 8"
                                      stroke={filterIconCo̥lor}
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                )}
                              </div>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                )}

              {mealsTableDataObject.selectedSortIndex === 2 &&
                selectedSortDropDownOne?.id === 3 && (
                  <div className="dropdown-2-box-sub-2">
                    <div className="sub-dropdown-2">
                      {["newest on top", "oldest on top"].map(
                        (title, index) => {
                          return (
                            <div
                              className={`dropdown-2-item capitalize hover:text-white  ${
                                title === sortByClients &&
                                "dropdown-2-item-selected text-white"
                              }`}
                              onClick={() =>
                                handleSortChangeMeals(title, selectedSortIndex)
                              }
                            >
                              <span
                                className="font-normal"
                                style={{
                                  textDecoration: "none",
                                  textTransform: "none",
                                }}
                              >
                                {index === 0 ? "A to Z" : "Z to A"}
                              </span>
                              <div className="relative left-[2px] flex">
                                {index === 0 ? (
                                  <svg
                                    width="20px"
                                    height="20px"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M12 4V20M12 20L8 16M12 20L16 16"
                                      stroke={filterIconCo̥lor}
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                ) : (
                                  <svg
                                    width="20px"
                                    height="20px"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M12 4V20M12 4L8 8M12 4L16 8"
                                      stroke={filterIconCo̥lor}
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                )}
                              </div>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                )}

              {mealsTableDataObject.selectedSortIndex === 3 &&
                selectedSortDropDownOne?.id === 3 && (
                  <div className="dropdown-2-box-sub-2">
                    <div className="sub-dropdown-2">
                      {["newest on top", "oldest on top"].map(
                        (title, index) => {
                          return (
                            <div
                              className={`dropdown-2-item capitalize hover:text-white  ${
                                title === sortByClients &&
                                "dropdown-2-item-selected text-white"
                              }`}
                              onClick={() =>
                                handleSortChangeMeals(title, selectedSortIndex)
                              }
                            >
                              <span
                                className="font-normal"
                                style={{
                                  textDecoration: "none",
                                  textTransform: "none",
                                }}
                              >
                                {index === 0 ? "A to Z" : "Z to A"}
                              </span>
                              <div className="relative left-[2px] flex">
                                {index === 0 ? (
                                  <svg
                                    width="20px"
                                    height="20px"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M12 4V20M12 20L8 16M12 20L16 16"
                                      stroke={filterIconCo̥lor}
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                ) : (
                                  <svg
                                    width="20px"
                                    height="20px"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M12 4V20M12 4L8 8M12 4L16 8"
                                      stroke={filterIconCo̥lor}
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                )}
                              </div>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                )}
            </div>
          </div>
        </div>
      )}
      {/* PROSPECTS DROPDOWN */}
      {type === "prospects" && (
        // <div className="dropdown-wrapper-main-clients-table">
        <div className="dropdown-wrapper-main">
          <div className="associations-table-dropdown">
            <div className="dropdown-2-box">
              <div className="dropdown-2">
                <DropdownOptions
                  selectedItem={prospectsTableDataObject?.currentActive}
                  data={Data}
                  ref={inputRefProspects}
                  onFocus={handleFoucsInSearchOfAssociations}
                  inputTextValue={inputValueTextProspects}
                  onChange={handleInputChangeProspects}
                  clearSearch={handleClearSearchProspects}
                  onClick={handleItemClickDropdownOneDocuments}
                  selectedType={"prospect"}
                />
              </div>

              {/* ///////////////////////////////////////////////////// FILTER OPTIONS ////////////////////////////////////////////////////////// */}
              {!!selectedFilterDropDownOne &&
              selectedFilterDropDownOne?.id === 1 ? (
                <>
                  <FilterDropdown
                    tableHeadArr={
                      businessType === "B2B" || isAdminActive
                        ? ["Name", "Email", "Mobile", , "Status"]
                        : ["Name", "Email", "Mobile", "Referrer", "Status"]
                    }
                    selectedItemIndex={
                      prospectsTableDataObject.selectedItemIndex
                    }
                    handleSelectColumn={handleClickAssociationsColumn}
                    selectedColumnType={"prospect"}
                  />
                </>
              ) : null}

              {/* ////////////////////////////////////////////////    FILTERING PROSPECTS    ////////////////////////////////////////////////////////////////////// */}
              {prospectsTableDataObject.selectedItemIndex === 0 &&
                selectedFilterDropDownOne?.id === 1 && (
                  <div className="dropdown-2-box-sub-2">
                    <div className="sub-dropdown-2">
                      <Checkbox
                        isChecked={
                          selectedTitles?.length ===
                          uniqueNamesOfProspects.length
                        }
                        className="dropdown-2-item dropdown-2-item-no-hover-effect w-full"
                        onChange={() => {
                          if (
                            selectedTitles.length ===
                            uniqueNamesOfProspects.length
                          ) {
                            setSelectedTitles([]);
                          } else {
                            setSelectedTitles(uniqueNamesOfProspects);
                            // dispatch(getProspectsThunk({ dynamicProspectsId }));
                            dispatch(
                              setProspectFilterItems({
                                filterColumn: "name",
                                filterValue: uniqueNamesOfProspects.join(","),
                                searchVal: "",
                                sortColumn: "",
                                sortType: "",
                                prospectPageFilter: ProspectPageFilter,
                              })
                            );
                          }
                        }}
                      >
                        (Select All)
                      </Checkbox>

                      {uniqueNamesOfProspects?.map((elem: any, index: any) => (
                        <div
                          className="dropdown-2-item dropdown-2-item-no-hover-effect"
                          key={elem?.id}
                        >
                          <Checkbox
                            isChecked={selectedTitles.includes(elem)}
                            onChange={() =>
                              handleCheckboxChangeProspects(elem, "name", index)
                            }
                          >
                            {elem}
                          </Checkbox>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              {prospectsTableDataObject.selectedItemIndex === 1 &&
                selectedFilterDropDownOne?.id === 1 && (
                  <div className="dropdown-2-box-sub-2">
                    <div className="sub-dropdown-2">
                      <Checkbox
                        isChecked={
                          selectedTitles.length ===
                          uniqueEmailsOfProspects.length
                        }
                        className="dropdown-2-item dropdown-2-item-no-hover-effect w-full"
                        onChange={() => {
                          if (
                            selectedTitles.length ===
                            uniqueEmailsOfProspects.length
                          ) {
                            setSelectedTitles([]);
                          } else {
                            setSelectedTitles(uniqueEmailsOfProspects);
                            // dispatch(getProspectsThunk({ dynamicProspectsId }));
                            dispatch(
                              setProspectFilterItems({
                                filterColumn: "email",
                                filterValue: uniqueEmailsOfProspects.join(","),
                                searchVal: "",
                                sortColumn: "",
                                sortType: "",
                                prospectPageFilter: ProspectPageFilter,
                              })
                            );
                          }
                        }}
                      >
                        (Select All)
                      </Checkbox>

                      {uniqueEmailsOfProspects?.map((elem: any, index: any) => (
                        <div
                          className="dropdown-2-item dropdown-2-item-no-hover-effect"
                          key={index}
                        >
                          {elem !== null && (
                            <Checkbox
                              onChange={() =>
                                handleCheckboxChangeProspects(
                                  elem,
                                  "email",
                                  index
                                )
                              }
                              isChecked={selectedTitles.includes(elem)}
                            >
                              {elem}
                            </Checkbox>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              {prospectsTableDataObject.selectedItemIndex === 2 &&
                selectedFilterDropDownOne?.id === 1 && (
                  <div className="dropdown-2-box-sub-2">
                    <div className="sub-dropdown-2">
                      <Checkbox
                        isChecked={
                          selectedTitles.length === filteredUniqueNumbers.length
                        }
                        className="dropdown-2-item dropdown-2-item-no-hover-effect w-full"
                        onChange={() => {
                          if (
                            selectedTitles.length ===
                            filteredUniqueNumbers.length
                          ) {
                            setSelectedTitles([]);
                          } else {
                            setSelectedTitles(filteredUniqueNumbers);
                            dispatch(
                              setProspectFilterItems({
                                filterColumn: "mobile",
                                filterValue: filteredUniqueNumbers.join(","),
                                searchVal: "",
                                sortColumn: "",
                                sortType: "",
                                prospectPageFilter: ProspectPageFilter,
                              })
                            );
                            // dispatch(getProspectsThunk({ dynamicProspectsId }));
                          }
                        }}
                      >
                        (Select All)
                      </Checkbox>
                      {/* associations array of relationships */}
                      {filteredUniqueNumbers.map((elem: any, index: any) => (
                        <div
                          className="dropdown-2-item dropdown-2-item-no-hover-effect"
                          key={index}
                        >
                          {elem !== null && (
                            <Checkbox
                              isChecked={selectedTitles?.includes(elem)}
                              onChange={() =>
                                handleCheckboxChangeProspects(
                                  elem,
                                  "mobile",
                                  index
                                )
                              }
                            >
                              {elem}
                            </Checkbox>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              {prospectsTableDataObject.selectedItemIndex === 3 &&
                selectedFilterDropDownOne?.id === 1 && (
                  <div className="dropdown-2-box-sub-2">
                    <div className="sub-dropdown-2">
                      <Checkbox
                        isChecked={
                          selectedTitles.length ===
                          uniqueReferrersOfProspects.length
                        }
                        className="dropdown-2-item dropdown-2-item-no-hover-effect w-full"
                        onChange={() => {
                          if (
                            selectedTitles.length ===
                            uniqueReferrersOfProspects.length
                          ) {
                            setSelectedTitles([]);
                          } else {
                            setSelectedTitles(uniqueReferrersOfProspects);
                            // dispatch(getProspectsThunk({ dynamicProspectsId }));
                            dispatch(
                              setProspectFilterItems({
                                filterColumn: "referrer",
                                filterValue:
                                  uniqueReferrersOfProspects.join(","),
                                searchVal: "",
                                sortColumn: "",
                                sortType: "",
                                prospectPageFilter: ProspectPageFilter,
                              })
                            );
                          }
                        }}
                      >
                        (Select All)
                      </Checkbox>
                      {uniqueReferrersOfProspects?.map(
                        (elem: any, index: any) => (
                          <div
                            className="dropdown-2-item dropdown-2-item-no-hover-effect"
                            key={index}
                          >
                            {elem !== null && (
                              <Checkbox
                                isChecked={selectedTitles?.includes(elem)}
                                onChange={() =>
                                  handleCheckboxChangeProspects(
                                    elem,
                                    "referrer",
                                    index
                                  )
                                }
                              >
                                {elem}
                              </Checkbox>
                            )}
                          </div>
                        )
                      )}
                    </div>
                  </div>
                )}

              {prospectsTableDataObject.selectedItemIndex === 4 &&
                selectedFilterDropDownOne?.id === 1 && (
                  <div className="dropdown-2-box-sub-2">
                    <div className="sub-dropdown-2">
                      <Checkbox
                        isChecked={
                          selectedTitles.length ===
                          statusMappingProspects.length
                        }
                        className="dropdown-2-item dropdown-2-item-no-hover-effect w-full"
                        onChange={() => {
                          if (
                            selectedTitles.length ===
                            statusMappingProspects.length
                          ) {
                            setSelectedTitles([]);
                            dispatch(
                              getProspectsSelectAllThunk({
                                dynamicId: dynamicProspectsId,
                                status: prospect_status,
                              })
                            );
                          } else {
                            setSelectedTitles(statusMappingProspects);
                            // dispatch(
                            //   getProspectsSelectAllThunk({
                            //     dynamicId: dynamicProspectsId,
                            //     status: "0,1,4,6,8,9,10",
                            //   })
                            // );
                            dispatch(
                              setProspectFilterItems({
                                filterColumn: "status",
                                filterValue: prospect_status,
                                searchVal: "",
                                sortColumn: "",
                                sortType: "",
                                prospectPageFilter: 1,
                              })
                            );
                          }
                        }}
                      >
                        (Select All)
                      </Checkbox>
                      {statusMappingProspects?.map((elem: any, index: any) => (
                        <div
                          className="dropdown-2-item dropdown-2-item-no-hover-effect"
                          key={index}
                        >
                          {elem !== null && (
                            <Checkbox
                              isChecked={selectedTitles?.includes(elem)}
                              onChange={() =>
                                handleCheckboxChangeProspects(
                                  elem,
                                  "status",
                                  index
                                )
                              }
                            >
                              {elem}
                            </Checkbox>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                )}

              {/*  /////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
              {/*   selectedSortDropDownOne?.id === 3 */}
              {/* SORT OPTIONS */}
              {!!selectedSortDropDownOne &&
              prospectsTableDataObject.currentActive === "sort" ? (
                <div className="dropdown-2-box-sub">
                  <div className="sub-dropdown-2">
                    {(businessType === "B2B" || isAdminActive
                      ? ["Name", "Email", "Mobile"]
                      : ["Name", "Email", "Mobile", "Referrer"]
                    ).map((elem: any, index: number) => (
                      <div
                        key={index}
                        className={`dropdown-2-item ${
                          prospectsTableDataObject.selectedSortIndex === index
                            ? "dropdown-2-item-selected"
                            : ""
                        }`}
                        onClick={() =>
                          handleOpenSortOptionsOnBasisOfIndexOfColumn(
                            index,
                            "prospect"
                          )
                        }
                      >
                        <p>{elem}</p>
                      </div>
                    ))}
                  </div>
                </div>
              ) : null}

              {/* SORT COLUMNS OF ASSOCIATIONS ON BASIS OF INDEX HERE */}
              {/* SORT OPTIONS ITEMS */}

              {prospectsTableDataObject.selectedSortIndex === 0 &&
                selectedSortDropDownOne?.id === 3 && (
                  <div className="dropdown-2-box-sub-2">
                    <div className="sub-dropdown-2">
                      {["newest on top", "oldest on top"].map(
                        (title, index) => {
                          return (
                            <div
                              className={`dropdown-2-item capitalize hover:text-white  ${
                                title === sortByProspects &&
                                "dropdown-2-item-selected text-white"
                              }`}
                              onClick={() =>
                                handleSortChangeProspects(
                                  title,
                                  selectedSortIndex
                                )
                              }
                            >
                              <span
                                className="font-normal"
                                style={{
                                  textDecoration: "none",
                                  textTransform: "none",
                                }}
                              >
                                {index === 0 ? "A to Z" : "Z to A"}
                              </span>
                              <div className="relative left-[2px] flex">
                                {index === 0 ? (
                                  <svg
                                    width="20px"
                                    height="20px"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M12 4V20M12 20L8 16M12 20L16 16"
                                      stroke={filterIconCo̥lor}
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                ) : (
                                  <svg
                                    width="20px"
                                    height="20px"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M12 4V20M12 4L8 8M12 4L16 8"
                                      stroke={filterIconCo̥lor}
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                )}
                              </div>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                )}

              {prospectsTableDataObject.selectedSortIndex === 1 &&
                selectedSortDropDownOne?.id === 3 && (
                  <div className="dropdown-2-box-sub-2">
                    <div className="sub-dropdown-2">
                      {["newest on top", "oldest on top"].map(
                        (title, index) => {
                          return (
                            <div
                              className={`dropdown-2-item capitalize hover:text-white  ${
                                title === sortByProspects &&
                                "dropdown-2-item-selected text-white"
                              }`}
                              onClick={() =>
                                handleSortChangeProspects(
                                  title,

                                  selectedSortIndex
                                )
                              }
                            >
                              <span
                                className="font-normal"
                                style={{
                                  textDecoration: "none",
                                  textTransform: "none",
                                }}
                              >
                                {index === 0 ? "A to Z" : "Z to A"}
                              </span>
                              <div className="relative left-[2px] flex">
                                {index === 0 ? (
                                  <svg
                                    width="20px"
                                    height="20px"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M12 4V20M12 20L8 16M12 20L16 16"
                                      stroke={filterIconCo̥lor}
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                ) : (
                                  <svg
                                    width="20px"
                                    height="20px"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M12 4V20M12 4L8 8M12 4L16 8"
                                      stroke={filterIconCo̥lor}
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                )}
                              </div>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                )}

              {prospectsTableDataObject.selectedSortIndex === 2 &&
                selectedSortDropDownOne?.id === 3 && (
                  <div className="dropdown-2-box-sub-2">
                    <div className="sub-dropdown-2">
                      {["newest on top", "oldest on top"].map(
                        (title, index) => {
                          return (
                            <div
                              className={`dropdown-2-item capitalize hover:text-white  ${
                                title === sortByProspects &&
                                "dropdown-2-item-selected text-white"
                              }`}
                              onClick={() =>
                                handleSortChangeProspects(
                                  title,
                                  selectedSortIndex
                                )
                              }
                            >
                              <span
                                className="font-normal"
                                style={{
                                  textDecoration: "none",
                                  textTransform: "none",
                                }}
                              >
                                {index === 0 ? "A to Z" : "Z to A"}
                              </span>
                              <div className="relative left-[2px] flex">
                                {index === 0 ? (
                                  <svg
                                    width="20px"
                                    height="20px"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M12 4V20M12 20L8 16M12 20L16 16"
                                      stroke={filterIconCo̥lor}
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                ) : (
                                  <svg
                                    width="20px"
                                    height="20px"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M12 4V20M12 4L8 8M12 4L16 8"
                                      stroke={filterIconCo̥lor}
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                )}
                              </div>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                )}

              {prospectsTableDataObject.selectedSortIndex === 3 &&
                selectedSortDropDownOne?.id === 3 && (
                  <div className="dropdown-2-box-sub-2">
                    <div className="sub-dropdown-2">
                      {["newest on top", "oldest on top"].map(
                        (title, index) => {
                          return (
                            <div
                              className={`dropdown-2-item capitalize hover:text-white  ${
                                title === sortByProspects &&
                                "dropdown-2-item-selected text-white"
                              }`}
                              onClick={() =>
                                handleSortChangeProspects(
                                  title,
                                  selectedSortIndex
                                )
                              }
                            >
                              <span
                                className="font-normal"
                                style={{
                                  textDecoration: "none",
                                  textTransform: "none",
                                }}
                              >
                                {index === 0 ? "A to Z" : "Z to A"}
                              </span>
                              <div className="relative left-[2px] flex">
                                {index === 0 ? (
                                  <svg
                                    width="20px"
                                    height="20px"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M12 4V20M12 20L8 16M12 20L16 16"
                                      stroke={filterIconCo̥lor}
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                ) : (
                                  <svg
                                    width="20px"
                                    height="20px"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M12 4V20M12 4L8 8M12 4L16 8"
                                      stroke={filterIconCo̥lor}
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                )}
                              </div>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                )}
              {prospectsTableDataObject.selectedSortIndex === 4 &&
                selectedSortDropDownOne?.id === 3 && (
                  <div className="dropdown-2-box-sub-2">
                    <div className="sub-dropdown-2">
                      {["newest on top", "oldest on top"].map(
                        (title, index) => {
                          return (
                            <div
                              className={`dropdown-2-item capitalize hover:text-white  ${
                                title === sortByProspects &&
                                "dropdown-2-item-selected text-white"
                              }`}
                              onClick={() =>
                                handleSortChangeProspects(
                                  title,
                                  selectedSortIndex
                                )
                              }
                            >
                              <span
                                className="font-normal"
                                style={{
                                  textDecoration: "none",
                                  textTransform: "none",
                                }}
                              >
                                {index === 0 ? "A to Z" : "Z to A"}
                              </span>
                              <div className="relative left-[2px] flex">
                                {index === 0 ? (
                                  <svg
                                    width="20px"
                                    height="20px"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M12 4V20M12 20L8 16M12 20L16 16"
                                      stroke={filterIconCo̥lor}
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                ) : (
                                  <svg
                                    width="20px"
                                    height="20px"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M12 4V20M12 4L8 8M12 4L16 8"
                                      stroke={filterIconCo̥lor}
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                )}
                              </div>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                )}
            </div>
          </div>
        </div>
        // </div>
      )}{" "}
      {type === "providerProspect" && (
        // <div className="dropdown-wrapper-main-clients-table">
        <div className="dropdown-wrapper-main">
          <div className="associations-table-dropdown">
            <div className="dropdown-2-box">
              <div className="dropdown-2">
                <DropdownOptions
                  selectedItem={prospectsTableDataObject?.currentActive}
                  data={Data}
                  ref={inputRefProspects}
                  onFocus={handleFoucsInSearchOfAssociations}
                  inputTextValue={inputValueTextProspects}
                  onChange={handleInputChangeProspects}
                  clearSearch={handleClearSearchProspects}
                  onClick={handleItemClickDropdownOneDocuments}
                  selectedType={"prospect"}
                />
              </div>

              {/* ///////////////////////////////////////////////////// FILTER OPTIONS ////////////////////////////////////////////////////////// */}
              {!!selectedFilterDropDownOne &&
              selectedFilterDropDownOne?.id === 1 ? (
                <>
                  <FilterDropdown
                    tableHeadArr={[
                      "Name",
                      "Email",
                      "Mobile",
                      "Referrer",
                      "Status",
                    ]}
                    selectedItemIndex={
                      prospectsTableDataObject.selectedItemIndex
                    }
                    handleSelectColumn={handleClickAssociationsColumn}
                    selectedColumnType={"prospect"}
                  />
                </>
              ) : null}

              {/* ////////////////////////////////////////////////    FILTERING PROSPECTS    ////////////////////////////////////////////////////////////////////// */}
              {prospectsTableDataObject.selectedItemIndex === 0 &&
                selectedFilterDropDownOne?.id === 1 && (
                  <div className="dropdown-2-box-sub-2">
                    <div className="sub-dropdown-2">
                      <Checkbox
                        isChecked={
                          selectedTitles?.length ===
                          uniqueNamesOfProspects.length
                        }
                        className="dropdown-2-item dropdown-2-item-no-hover-effect w-full"
                        onChange={() => {
                          if (
                            selectedTitles.length ===
                            uniqueNamesOfProspects.length
                          ) {
                            setSelectedTitles([]);
                          } else {
                            setSelectedTitles(uniqueNamesOfProspects);
                            // dispatch(getProspectsThunk({ dynamicProspectsId }));
                            dispatch(
                              setProspectFilterItems({
                                filterColumn: "name",
                                filterValue: uniqueNamesOfProspects.join(","),
                                searchVal: "",
                                sortColumn: "",
                                sortType: "",
                                prospectPageFilter: ProspectPageFilter,
                              })
                            );
                          }
                        }}
                      >
                        (Select All)
                      </Checkbox>

                      {uniqueNamesOfProspects?.map((elem: any, index: any) => (
                        <div
                          className="dropdown-2-item dropdown-2-item-no-hover-effect"
                          key={elem?.id}
                        >
                          <Checkbox
                            isChecked={selectedTitles.includes(elem)}
                            onChange={() =>
                              handleCheckboxChangeProspects(elem, "name", index)
                            }
                          >
                            {elem}
                          </Checkbox>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              {prospectsTableDataObject.selectedItemIndex === 1 &&
                selectedFilterDropDownOne?.id === 1 && (
                  <div className="dropdown-2-box-sub-2">
                    <div className="sub-dropdown-2">
                      <Checkbox
                        isChecked={
                          selectedTitles.length ===
                          uniqueEmailsOfProspects.length
                        }
                        className="dropdown-2-item dropdown-2-item-no-hover-effect w-full"
                        onChange={() => {
                          if (
                            selectedTitles.length ===
                            uniqueEmailsOfProspects.length
                          ) {
                            setSelectedTitles([]);
                          } else {
                            setSelectedTitles(uniqueEmailsOfProspects);
                            // dispatch(getProspectsThunk({ dynamicProspectsId }));
                            dispatch(
                              setProspectFilterItems({
                                filterColumn: "email",
                                filterValue: uniqueEmailsOfProspects.join(","),
                                searchVal: "",
                                sortColumn: "",
                                sortType: "",
                                prospectPageFilter: ProspectPageFilter,
                              })
                            );
                          }
                        }}
                      >
                        (Select All)
                      </Checkbox>

                      {uniqueEmailsOfProspects?.map((elem: any, index: any) => (
                        <div
                          className="dropdown-2-item dropdown-2-item-no-hover-effect"
                          key={index}
                        >
                          {elem !== null && (
                            <Checkbox
                              onChange={() =>
                                handleCheckboxChangeProspects(
                                  elem,
                                  "email",
                                  index
                                )
                              }
                              isChecked={selectedTitles.includes(elem)}
                            >
                              {elem}
                            </Checkbox>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              {prospectsTableDataObject.selectedItemIndex === 2 &&
                selectedFilterDropDownOne?.id === 1 && (
                  <div className="dropdown-2-box-sub-2">
                    <div className="sub-dropdown-2">
                      <Checkbox
                        isChecked={
                          selectedTitles.length === filteredUniqueNumbers.length
                        }
                        className="dropdown-2-item dropdown-2-item-no-hover-effect w-full"
                        onChange={() => {
                          if (
                            selectedTitles.length ===
                            filteredUniqueNumbers.length
                          ) {
                            setSelectedTitles([]);
                          } else {
                            setSelectedTitles(filteredUniqueNumbers);
                            dispatch(
                              setProspectFilterItems({
                                filterColumn: "mobile",
                                filterValue: filteredUniqueNumbers.join(","),
                                searchVal: "",
                                sortColumn: "",
                                sortType: "",
                                prospectPageFilter: ProspectPageFilter,
                              })
                            );
                            // dispatch(getProspectsThunk({ dynamicProspectsId }));
                          }
                        }}
                      >
                        (Select All)
                      </Checkbox>
                      {/* associations array of relationships */}
                      {filteredUniqueNumbers.map((elem: any, index: any) => (
                        <div
                          className="dropdown-2-item dropdown-2-item-no-hover-effect"
                          key={index}
                        >
                          {elem !== null && (
                            <Checkbox
                              isChecked={selectedTitles?.includes(elem)}
                              onChange={() =>
                                handleCheckboxChangeProspects(
                                  elem,
                                  "mobile",
                                  index
                                )
                              }
                            >
                              {elem}
                            </Checkbox>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              {prospectsTableDataObject.selectedItemIndex === 3 &&
                selectedFilterDropDownOne?.id === 1 && (
                  <div className="dropdown-2-box-sub-2">
                    <div className="sub-dropdown-2">
                      <Checkbox
                        isChecked={
                          selectedTitles.length ===
                          uniqueReferrersOfProspects.length
                        }
                        className="dropdown-2-item dropdown-2-item-no-hover-effect w-full"
                        onChange={() => {
                          if (
                            selectedTitles.length ===
                            uniqueReferrersOfProspects.length
                          ) {
                            setSelectedTitles([]);
                          } else {
                            setSelectedTitles(uniqueReferrersOfProspects);
                            // dispatch(getProspectsThunk({ dynamicProspectsId }));
                            dispatch(
                              setProspectFilterItems({
                                filterColumn: "referrer",
                                filterValue:
                                  uniqueReferrersOfProspects.join(","),
                                searchVal: "",
                                sortColumn: "",
                                sortType: "",
                                prospectPageFilter: ProspectPageFilter,
                              })
                            );
                          }
                        }}
                      >
                        (Select All)
                      </Checkbox>
                      {uniqueReferrersOfProspects?.map(
                        (elem: any, index: any) => (
                          <div
                            className="dropdown-2-item dropdown-2-item-no-hover-effect"
                            key={index}
                          >
                            {elem !== null && (
                              <Checkbox
                                isChecked={selectedTitles?.includes(elem)}
                                onChange={() =>
                                  handleCheckboxChangeProspects(
                                    elem,
                                    "referrer",
                                    index
                                  )
                                }
                              >
                                {elem}
                              </Checkbox>
                            )}
                          </div>
                        )
                      )}
                    </div>
                  </div>
                )}

              {prospectsTableDataObject.selectedItemIndex === 4 &&
                selectedFilterDropDownOne?.id === 1 && (
                  <div className="dropdown-2-box-sub-2">
                    <div className="sub-dropdown-2">
                      <Checkbox
                        isChecked={
                          selectedTitles.length ===
                          statusMappingProspects.length
                        }
                        className="dropdown-2-item dropdown-2-item-no-hover-effect w-full"
                        onChange={() => {
                          if (
                            selectedTitles.length ===
                            statusMappingProspects.length
                          ) {
                            setSelectedTitles([]);
                            dispatch(
                              getProspectsSelectAllThunk({
                                dynamicId: dynamicProspectsId,
                                filterValue: statusMappingProspects.join(","),
                              })
                            );
                          } else {
                            setSelectedTitles(statusMappingProspects);
                            // dispatch(
                            //   getProspectsSelectAllThunk({
                            //     dynamicId: dynamicProspectsId,
                            //     status: "0,1,4,6,8,9,10",
                            //   })
                            // );
                            dispatch(
                              setProspectFilterItems({
                                filterColumn: "status",
                                filterValue: statusMappingProspects.join(","),
                                searchVal: "",
                                sortColumn: "",
                                sortType: "",
                                prospectPageFilter: ProspectPageFilter,
                              })
                            );
                          }
                        }}
                      >
                        (Select All)
                      </Checkbox>
                      {statusMappingProspects?.map((elem: any, index: any) => (
                        <div
                          className="dropdown-2-item dropdown-2-item-no-hover-effect"
                          key={index}
                        >
                          {elem !== null && (
                            <Checkbox
                              isChecked={selectedTitles?.includes(elem)}
                              onChange={() =>
                                handleCheckboxChangeProspects(
                                  elem,
                                  "status",
                                  index
                                )
                              }
                            >
                              {elem}
                            </Checkbox>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                )}

              {/*  /////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
              {/*   selectedSortDropDownOne?.id === 3 */}
              {/* SORT OPTIONS */}
              {!!selectedSortDropDownOne &&
              prospectsTableDataObject.currentActive === "sort" ? (
                <div className="dropdown-2-box-sub">
                  <div className="sub-dropdown-2">
                    {["Name", "Email", "Mobile", "Referrer"].map(
                      (elem: any, index: number) => (
                        <div
                          key={index}
                          className={`dropdown-2-item ${
                            prospectsTableDataObject.selectedSortIndex === index
                              ? "dropdown-2-item-selected"
                              : ""
                          }`}
                          onClick={() =>
                            handleOpenSortOptionsOnBasisOfIndexOfColumn(
                              index,
                              "prospect"
                            )
                          }
                        >
                          <p>{elem}</p>
                        </div>
                      )
                    )}
                  </div>
                </div>
              ) : null}

              {/* SORT COLUMNS OF ASSOCIATIONS ON BASIS OF INDEX HERE */}
              {/* SORT OPTIONS ITEMS */}

              {prospectsTableDataObject.selectedSortIndex === 0 &&
                selectedSortDropDownOne?.id === 3 && (
                  <div className="dropdown-2-box-sub-2">
                    <div className="sub-dropdown-2">
                      {["newest on top", "oldest on top"].map(
                        (title, index) => {
                          return (
                            <div
                              className={`dropdown-2-item capitalize hover:text-white  ${
                                title === sortByProspects &&
                                "dropdown-2-item-selected text-white"
                              }`}
                              onClick={() =>
                                handleSortChangeProspects(
                                  title,
                                  selectedSortIndex
                                )
                              }
                            >
                              <span
                                className="font-normal"
                                style={{
                                  textDecoration: "none",
                                  textTransform: "none",
                                }}
                              >
                                {index === 0 ? "A to Z" : "Z to A"}
                              </span>
                              <div className="relative left-[2px] flex">
                                {index === 0 ? (
                                  <svg
                                    width="20px"
                                    height="20px"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M12 4V20M12 20L8 16M12 20L16 16"
                                      stroke={filterIconCo̥lor}
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                ) : (
                                  <svg
                                    width="20px"
                                    height="20px"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M12 4V20M12 4L8 8M12 4L16 8"
                                      stroke={filterIconCo̥lor}
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                )}
                              </div>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                )}

              {prospectsTableDataObject.selectedSortIndex === 1 &&
                selectedSortDropDownOne?.id === 3 && (
                  <div className="dropdown-2-box-sub-2">
                    <div className="sub-dropdown-2">
                      {["newest on top", "oldest on top"].map(
                        (title, index) => {
                          return (
                            <div
                              className={`dropdown-2-item capitalize hover:text-white  ${
                                title === sortByProspects &&
                                "dropdown-2-item-selected text-white"
                              }`}
                              onClick={() =>
                                handleSortChangeProspects(
                                  title,

                                  selectedSortIndex
                                )
                              }
                            >
                              <span
                                className="font-normal"
                                style={{
                                  textDecoration: "none",
                                  textTransform: "none",
                                }}
                              >
                                {index === 0 ? "A to Z" : "Z to A"}
                              </span>
                              <div className="relative left-[2px] flex">
                                {index === 0 ? (
                                  <svg
                                    width="20px"
                                    height="20px"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M12 4V20M12 20L8 16M12 20L16 16"
                                      stroke={filterIconCo̥lor}
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                ) : (
                                  <svg
                                    width="20px"
                                    height="20px"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M12 4V20M12 4L8 8M12 4L16 8"
                                      stroke={filterIconCo̥lor}
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                )}
                              </div>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                )}

              {prospectsTableDataObject.selectedSortIndex === 2 &&
                selectedSortDropDownOne?.id === 3 && (
                  <div className="dropdown-2-box-sub-2">
                    <div className="sub-dropdown-2">
                      {["newest on top", "oldest on top"].map(
                        (title, index) => {
                          return (
                            <div
                              className={`dropdown-2-item capitalize hover:text-white  ${
                                title === sortByProspects &&
                                "dropdown-2-item-selected text-white"
                              }`}
                              onClick={() =>
                                handleSortChangeProspects(
                                  title,
                                  selectedSortIndex
                                )
                              }
                            >
                              <span
                                className="font-normal"
                                style={{
                                  textDecoration: "none",
                                  textTransform: "none",
                                }}
                              >
                                {index === 0 ? "A to Z" : "Z to A"}
                              </span>
                              <div className="relative left-[2px] flex">
                                {index === 0 ? (
                                  <svg
                                    width="20px"
                                    height="20px"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M12 4V20M12 20L8 16M12 20L16 16"
                                      stroke={filterIconCo̥lor}
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                ) : (
                                  <svg
                                    width="20px"
                                    height="20px"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M12 4V20M12 4L8 8M12 4L16 8"
                                      stroke={filterIconCo̥lor}
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                )}
                              </div>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                )}

              {prospectsTableDataObject.selectedSortIndex === 3 &&
                selectedSortDropDownOne?.id === 3 && (
                  <div className="dropdown-2-box-sub-2">
                    <div className="sub-dropdown-2">
                      {["newest on top", "oldest on top"].map(
                        (title, index) => {
                          return (
                            <div
                              className={`dropdown-2-item capitalize hover:text-white  ${
                                title === sortByProspects &&
                                "dropdown-2-item-selected text-white"
                              }`}
                              onClick={() =>
                                handleSortChangeProspects(
                                  title,
                                  selectedSortIndex
                                )
                              }
                            >
                              <span
                                className="font-normal"
                                style={{
                                  textDecoration: "none",
                                  textTransform: "none",
                                }}
                              >
                                {index === 0 ? "A to Z" : "Z to A"}
                              </span>
                              <div className="relative left-[2px] flex">
                                {index === 0 ? (
                                  <svg
                                    width="20px"
                                    height="20px"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M12 4V20M12 20L8 16M12 20L16 16"
                                      stroke={filterIconCo̥lor}
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                ) : (
                                  <svg
                                    width="20px"
                                    height="20px"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M12 4V20M12 4L8 8M12 4L16 8"
                                      stroke={filterIconCo̥lor}
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                )}
                              </div>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                )}
              {prospectsTableDataObject.selectedSortIndex === 4 &&
                selectedSortDropDownOne?.id === 3 && (
                  <div className="dropdown-2-box-sub-2">
                    <div className="sub-dropdown-2">
                      {["newest on top", "oldest on top"].map(
                        (title, index) => {
                          return (
                            <div
                              className={`dropdown-2-item capitalize hover:text-white  ${
                                title === sortByProspects &&
                                "dropdown-2-item-selected text-white"
                              }`}
                              onClick={() =>
                                handleSortChangeProspects(
                                  title,
                                  selectedSortIndex
                                )
                              }
                            >
                              <span
                                className="font-normal"
                                style={{
                                  textDecoration: "none",
                                  textTransform: "none",
                                }}
                              >
                                {index === 0 ? "A to Z" : "Z to A"}
                              </span>
                              <div className="relative left-[2px] flex">
                                {index === 0 ? (
                                  <svg
                                    width="20px"
                                    height="20px"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M12 4V20M12 20L8 16M12 20L16 16"
                                      stroke={filterIconCo̥lor}
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                ) : (
                                  <svg
                                    width="20px"
                                    height="20px"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M12 4V20M12 4L8 8M12 4L16 8"
                                      stroke={filterIconCo̥lor}
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                )}
                              </div>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                )}
            </div>
          </div>
        </div>
        // </div>
      )}
      {/* CONTACTS DROPDOWN */}
      {(type === "contacts" || type === "providerContacts") && (
        <div className="dropdown-wrapper-main">
          <div className="associations-table-dropdown">
            <div className="dropdown-2-box">
              <div className="dropdown-2">
                <DropdownOptions
                  selectedItem={contactsTableDataObject?.currentActive}
                  data={Data}
                  ref={inputRefContacts}
                  inputTextValue={inputValueTextContacts}
                  onChange={handleInputChangeContacts}
                  clearSearch={handleClearSearchContacts}
                  onClick={handleItemClickDropdownOneDocuments}
                  selectedType={"contact"}
                />
              </div>

              {/* ///////////////////////////////////////////////////// FILTER OPTIONS ////////////////////////////////////////////////////////// */}
              {!!selectedFilterDropDownOne &&
              selectedFilterDropDownOne?.id === 1 ? (
                <FilterDropdown
                  tableHeadArr={["Name", "Email", "Mobile", "Status"]}
                  selectedItemIndex={contactsTableDataObject.selectedItemIndex}
                  handleSelectColumn={handleClickAssociationsColumn}
                  selectedColumnType={"contact"}
                />
              ) : null}

              {/* ////////////////////////////////////////////////    FILTERING CONTACTS    ////////////////////////////////////////////////////////////////////// */}
              {contactsTableDataObject.selectedItemIndex === 0 &&
                selectedFilterDropDownOne?.id === 1 && (
                  <div className="dropdown-2-box-sub-2">
                    <div className="sub-dropdown-2">
                      <Checkbox
                        isChecked={
                          selectedTitles?.length ===
                          uniqueNamesOfContacts.length
                        }
                        className="dropdown-2-item dropdown-2-item-no-hover-effect w-full"
                        onChange={() => {
                          if (
                            selectedTitles.length ===
                            uniqueNamesOfContacts.length
                          ) {
                            setSelectedTitles([]);
                          } else {
                            setSelectedTitles(uniqueNamesOfContacts);
                            // dispatch(getContactsThunk({ dynamicContactId }));
                            dispatch(
                              setContactFilterItems({
                                dynamicId: dynamicContactId,
                                filterColumn: "",
                                filterValue: "",
                                searchVal: "",
                                sortColumn: "",
                                sortType: "",
                                status: "1",
                                ContactPage,
                              })
                            );
                          }
                        }}
                      >
                        (Select All)
                      </Checkbox>

                      {uniqueNamesOfContacts?.map((elem: any, index: any) => (
                        <div
                          className="dropdown-2-item dropdown-2-item-no-hover-effect"
                          // note here key forces re render
                          key={elem?.id}
                        >
                          <Checkbox
                            isChecked={selectedTitles.includes(elem)}
                            onChange={() =>
                              handleCheckboxChangeContacts(elem, "name", index)
                            }
                          >
                            {elem}
                          </Checkbox>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              {contactsTableDataObject.selectedItemIndex === 1 &&
                selectedFilterDropDownOne?.id === 1 && (
                  <div className="dropdown-2-box-sub-2">
                    <div className="sub-dropdown-2">
                      <Checkbox
                        isChecked={
                          selectedTitles.length ===
                          uniqueEmailsOfContacts.length
                        }
                        className="dropdown-2-item dropdown-2-item-no-hover-effect w-full"
                        onChange={() => {
                          if (
                            selectedTitles.length ===
                            uniqueEmailsOfContacts.length
                          ) {
                            setSelectedTitles([]);
                          } else {
                            setSelectedTitles(uniqueEmailsOfContacts);
                            // dispatch(getContactsThunk({ dynamicContactId }));
                            dispatch(
                              setContactFilterItems({
                                dynamicId: dynamicContactId,
                                filterColumn: "",
                                filterValue: "",
                                searchVal: "",
                                sortColumn: "",
                                sortType: "",
                                status: "1",
                                ContactPage,
                              })
                            );
                          }
                        }}
                      >
                        (Select All)
                      </Checkbox>

                      {uniqueEmailsOfContacts?.map((elem: any, index: any) => (
                        <div
                          className="dropdown-2-item dropdown-2-item-no-hover-effect"
                          key={index}
                        >
                          {elem !== null && (
                            <Checkbox
                              onChange={() =>
                                handleCheckboxChangeContacts(
                                  elem,
                                  "email",
                                  index
                                )
                              }
                              isChecked={selectedTitles.includes(elem)}
                            >
                              {elem}
                            </Checkbox>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              {contactsTableDataObject.selectedItemIndex === 2 &&
                selectedFilterDropDownOne?.id === 1 && (
                  <div className="dropdown-2-box-sub-2">
                    <div className="sub-dropdown-2">
                      <Checkbox
                        isChecked={
                          selectedTitles.length ===
                          uniqueNumbersOfContacts.length
                        }
                        className="dropdown-2-item dropdown-2-item-no-hover-effect w-full"
                        onChange={() => {
                          if (
                            selectedTitles.length ===
                            uniqueNumbersOfContacts.length
                          ) {
                            setSelectedTitles([]);
                          } else {
                            setSelectedTitles(uniqueNumbersOfContacts);
                            // dispatch(getContactsThunk({ dynamicContactId }));
                            dispatch(
                              setContactFilterItems({
                                dynamicId: dynamicContactId,
                                filterColumn: "",
                                filterValue: "",
                                searchVal: "",
                                sortColumn: "",
                                sortType: "",
                                status: "1",
                                ContactPage,
                              })
                            );
                          }
                        }}
                      >
                        (Select All)
                      </Checkbox>

                      {uniqueNumbersOfContacts?.map((elem: any, index: any) => (
                        <div
                          className="dropdown-2-item dropdown-2-item-no-hover-effect"
                          key={index}
                        >
                          {elem !== null && (
                            <Checkbox
                              isChecked={selectedTitles?.includes(elem)}
                              onChange={() =>
                                handleCheckboxChangeContacts(
                                  elem,
                                  "phone",
                                  index
                                )
                              }
                            >
                              {elem}
                            </Checkbox>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              {/* {selectedItemIndex === 3 && (
                  <div className="dropdown-2-box-sub-2">
                    <div className="sub-dropdown-2">
                      <Checkbox
                        isChecked={
                          selectedTitles.length ===
                          uniqueFundingTypeOfClients.length
                        }
                        className="dropdown-2-item dropdown-2-item-no-hover-effect w-full"
                        onChange={() => {
                          if (
                            selectedTitles.length ===
                            uniqueFundingTypeOfClients.length
                          ) {
                            setSelectedTitles([]);
                          } else {
                            setSelectedTitles(uniqueFundingTypeOfClients);
                            dispatch(getClientsThunk({ dynamicClientId }));
                          }
                        }}
                      >
                        (Select All)
                      </Checkbox>
                      {uniqueFundingTypeOfClients?.map(
                        (elem: any, index: any) => (
                          <div
                            className="dropdown-2-item dropdown-2-item-no-hover-effect"
                            key={index}
                          >
                            {elem !== null && (
                              <Checkbox
                                isChecked={selectedTitles?.includes(elem)}
                                onChange={() =>
                                  handleCheckboxChangeContacts(
                                    elem,
                                    "associatedbusiness",
                                    index
                                  )
                                }
                              >
                                {elem}
                              </Checkbox>
                            )}
                          </div>
                        )
                      )}
                    </div>
                  </div>
                )} */}

              {contactsTableDataObject.selectedItemIndex === 3 &&
                selectedFilterDropDownOne?.id === 1 && (
                  <div className="dropdown-2-box-sub-2">
                    <div className="sub-dropdown-2">
                      <Checkbox
                        isChecked={
                          selectedTitles.length === statusMappingContacts.length
                        }
                        className="dropdown-2-item dropdown-2-item-no-hover-effect w-full"
                        onChange={() => {
                          if (
                            selectedTitles.length ===
                            statusMappingContacts.length
                          ) {
                            setSelectedTitles([]);
                            dispatch(
                              getContactsSelectAllThunk({
                                dynamicId: dynamicContactId,
                                status: "1",
                              })
                            );
                          } else {
                            setSelectedTitles(statusMappingContacts);
                            dispatch(
                              getContactsSelectAllThunk({
                                dynamicId: dynamicContactId,
                                status: "0,1",
                              })
                            );
                          }
                        }}
                      >
                        (Select All)
                      </Checkbox>
                      {statusMappingContacts?.map((elem: any, index: any) => (
                        <div
                          className="dropdown-2-item dropdown-2-item-no-hover-effect"
                          key={index}
                        >
                          {elem !== null && (
                            <Checkbox
                              isChecked={selectedTitles?.includes(elem)}
                              onChange={() =>
                                handleCheckboxChangeContacts(
                                  elem,
                                  "status",
                                  index
                                )
                              }
                            >
                              {elem}
                            </Checkbox>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                )}

              {/*  /////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
              {/*    selectedSortDropDownOne?.id === 3  */}
              {/* SORT OPTIONS */}
              {!!selectedSortDropDownOne &&
              contactsTableDataObject.currentActive === "sort" ? (
                <div className="dropdown-2-box-sub">
                  <div className="sub-dropdown-2">
                    {["Name", "Email", "Mobile", "Status"]?.map(
                      (elem: any, index: number) => (
                        <div
                          key={index}
                          className={`dropdown-2-item ${
                            contactsTableDataObject.selectedSortIndex === index
                              ? "dropdown-2-item-selected"
                              : ""
                          }`}
                          onClick={() =>
                            handleOpenSortOptionsOnBasisOfIndexOfColumn(
                              index,
                              "contact"
                            )
                          }
                        >
                          <p>{elem}</p>
                        </div>
                      )
                    )}
                  </div>
                </div>
              ) : null}

              {/* SORT OPTIONS ITEMS */}

              {contactsTableDataObject.selectedSortIndex === 0 &&
                selectedSortDropDownOne?.id === 3 && (
                  <div className="dropdown-2-box-sub-2">
                    <div className="sub-dropdown-2">
                      {["newest on top", "oldest on top"].map(
                        (title, index) => {
                          return (
                            <div
                              className={`dropdown-2-item capitalize hover:text-white  ${
                                title === sortByContact &&
                                "dropdown-2-item-selected text-white"
                              }`}
                              onClick={() =>
                                handleSortChangeContacts(
                                  title,
                                  selectedSortIndex
                                )
                              }
                            >
                              <span
                                className="font-normal"
                                style={{
                                  textDecoration: "none",
                                  textTransform: "none",
                                }}
                              >
                                {index === 0 ? "A to Z" : "Z to A"}
                              </span>
                              <div className="relative left-[2px] flex">
                                {index === 0 ? (
                                  <svg
                                    width="20px"
                                    height="20px"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M12 4V20M12 20L8 16M12 20L16 16"
                                      stroke={filterIconCo̥lor}
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                ) : (
                                  <svg
                                    width="20px"
                                    height="20px"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M12 4V20M12 4L8 8M12 4L16 8"
                                      stroke={filterIconCo̥lor}
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                )}
                              </div>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                )}

              {contactsTableDataObject.selectedSortIndex === 1 &&
                selectedSortDropDownOne?.id === 3 && (
                  <div className="dropdown-2-box-sub-2">
                    <div className="sub-dropdown-2">
                      {["newest on top", "oldest on top"].map(
                        (title, index) => {
                          return (
                            <div
                              className={`dropdown-2-item capitalize hover:text-white  ${
                                title === sortByContact &&
                                "dropdown-2-item-selected text-white"
                              }`}
                              onClick={() =>
                                handleSortChangeContacts(
                                  title,

                                  selectedSortIndex
                                )
                              }
                            >
                              <span
                                className="font-normal"
                                style={{
                                  textDecoration: "none",
                                  textTransform: "none",
                                }}
                              >
                                {index === 0 ? "A to Z" : "Z to A"}
                              </span>
                              <div className="relative left-[2px] flex">
                                {index === 0 ? (
                                  <svg
                                    width="20px"
                                    height="20px"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M12 4V20M12 20L8 16M12 20L16 16"
                                      stroke={filterIconCo̥lor}
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                ) : (
                                  <svg
                                    width="20px"
                                    height="20px"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M12 4V20M12 4L8 8M12 4L16 8"
                                      stroke={filterIconCo̥lor}
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                )}
                              </div>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                )}

              {contactsTableDataObject.selectedSortIndex === 2 &&
                selectedSortDropDownOne?.id === 3 && (
                  <div className="dropdown-2-box-sub-2">
                    <div className="sub-dropdown-2">
                      {["newest on top", "oldest on top"].map(
                        (title, index) => {
                          return (
                            <div
                              className={`dropdown-2-item capitalize hover:text-white  ${
                                title === sortByContact &&
                                "dropdown-2-item-selected text-white"
                              }`}
                              onClick={() =>
                                handleSortChangeContacts(
                                  title,
                                  selectedSortIndex
                                )
                              }
                            >
                              <span
                                className="font-normal"
                                style={{
                                  textDecoration: "none",
                                  textTransform: "none",
                                }}
                              >
                                {index === 0 ? "A to Z" : "Z to A"}
                              </span>
                              <div className="relative left-[2px] flex">
                                {index === 0 ? (
                                  <svg
                                    width="20px"
                                    height="20px"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M12 4V20M12 20L8 16M12 20L16 16"
                                      stroke={filterIconCo̥lor}
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                ) : (
                                  <svg
                                    width="20px"
                                    height="20px"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M12 4V20M12 4L8 8M12 4L16 8"
                                      stroke={filterIconCo̥lor}
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                )}
                              </div>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                )}

              {contactsTableDataObject.selectedSortIndex === 3 &&
                selectedSortDropDownOne?.id === 3 && (
                  <div className="dropdown-2-box-sub-2">
                    <div className="sub-dropdown-2">
                      {["newest on top", "oldest on top"].map(
                        (title, index) => {
                          return (
                            <div
                              className={`dropdown-2-item capitalize hover:text-white  ${
                                title === sortByContact &&
                                "dropdown-2-item-selected text-white"
                              }`}
                              onClick={() =>
                                handleSortChangeContacts(
                                  title,
                                  selectedSortIndex
                                )
                              }
                            >
                              <span
                                className="font-normal"
                                style={{
                                  textDecoration: "none",
                                  textTransform: "none",
                                }}
                              >
                                {index === 0 ? "A to Z" : "Z to A"}
                              </span>
                              <div className="relative left-[2px] flex">
                                {index === 0 ? (
                                  <svg
                                    width="20px"
                                    height="20px"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M12 4V20M12 20L8 16M12 20L16 16"
                                      stroke={filterIconCo̥lor}
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                ) : (
                                  <svg
                                    width="20px"
                                    height="20px"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M12 4V20M12 4L8 8M12 4L16 8"
                                      stroke={filterIconCo̥lor}
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                )}
                              </div>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                )}
              {contactsTableDataObject.selectedSortIndex === 4 &&
                selectedSortDropDownOne?.id === 3 && (
                  <div className="dropdown-2-box-sub-2">
                    <div className="sub-dropdown-2">
                      {["newest on top", "oldest on top"].map(
                        (title, index) => {
                          return (
                            <div
                              className={`dropdown-2-item capitalize hover:text-white  ${
                                title === sortByContact &&
                                "dropdown-2-item-selected text-white"
                              }`}
                              onClick={() =>
                                handleSortChangeContacts(
                                  title,
                                  selectedSortIndex
                                )
                              }
                            >
                              <span
                                className="font-normal"
                                style={{
                                  textDecoration: "none",
                                  textTransform: "none",
                                }}
                              >
                                {index === 0 ? "A to Z" : "Z to A"}
                              </span>
                              <div className="relative left-[2px] flex">
                                {index === 0 ? (
                                  <svg
                                    width="20px"
                                    height="20px"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M12 4V20M12 20L8 16M12 20L16 16"
                                      stroke={filterIconCo̥lor}
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                ) : (
                                  <svg
                                    width="20px"
                                    height="20px"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M12 4V20M12 4L8 8M12 4L16 8"
                                      stroke={filterIconCo̥lor}
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                )}
                              </div>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                )}
            </div>
          </div>
        </div>
      )}
      {/* STAFF DROPDOWN */}
      {(type === "staff" || type === "providerStaff") && (
        <div className="dropdown-wrapper-main">
          <div className="associations-table-dropdown">
            <div className="dropdown-2-box">
              <div className="dropdown-2">
                <DropdownOptions
                  selectedItem={selectedItem?.title}
                  data={Data}
                  ref={inputRefStaff}
                  inputTextValue={inputValueTextStaff}
                  onChange={handleInputChangeStaff}
                  clearSearch={handleClearSearchStaff}
                  onClick={handleItemClickDropdownOneDocuments}
                  selectedType={"staff"}
                />
              </div>

              {/* ///////////////////////////////////////////////////// FILTER OPTIONS ////////////////////////////////////////////////////////// */}
              {!!selectedFilterDropDownOne &&
              selectedFilterDropDownOne?.id === 1 ? (
                <FilterDropdown
                  tableHeadArr={["Name", "Email", "Mobile", "Role", "Status"]}
                  selectedItemIndex={selectedItemIndex}
                  handleSelectColumn={handleClickAssociationsColumn}
                  selectedColumnType={""}
                />
              ) : null}

              {/* ///////////////////////////////////////////////// ///    FILTERING STAFF    ////////////////////////////////////////////////////////////////////// */}
              {selectedItemIndex === 0 &&
                selectedFilterDropDownOne?.id === 1 && (
                  <div className="dropdown-2-box-sub-2">
                    <div className="sub-dropdown-2">
                      <Checkbox
                        isChecked={
                          selectedTitles.length === uniqueNamesOfStaff.length
                        }
                        className="dropdown-2-item dropdown-2-item-no-hover-effect w-full"
                        onChange={() => {
                          if (
                            selectedTitles?.length === uniqueNamesOfStaff.length
                          ) {
                            setSelectedTitles([]);
                          } else {
                            setSelectedTitles(uniqueNamesOfStaff);
                            // dispatch(getStaffThunk({ dynamicStaffId }));
                            dispatch(
                              setStaffFilterItems({
                                dynamicId: dynamicStaffId,
                                filterColumn: "",
                                filterValue: "",
                                searchVal: "",
                                sortColumn: "",
                                sortType: "",
                                status: "",
                                staffPage,
                              })
                            );
                          }
                        }}
                      >
                        (Select All)
                      </Checkbox>

                      {uniqueNamesOfStaff?.map((elem: any, index: any) => (
                        <div
                          className="dropdown-2-item dropdown-2-item-no-hover-effect"
                          key={elem?.id}
                        >
                          <Checkbox
                            isChecked={selectedTitles.includes(elem)}
                            onChange={() =>
                              handleCheckboxChangeStaff(elem, "name", index)
                            }
                          >
                            {elem}
                          </Checkbox>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              {selectedItemIndex === 1 &&
                selectedFilterDropDownOne?.id === 1 && (
                  <div className="dropdown-2-box-sub-2">
                    <div className="sub-dropdown-2">
                      <Checkbox
                        isChecked={
                          selectedTitles.length === uniqueEmailsOfStaff.length
                        }
                        className="dropdown-2-item dropdown-2-item-no-hover-effect w-full"
                        onChange={() => {
                          if (
                            selectedTitles.length === uniqueEmailsOfStaff.length
                          ) {
                            setSelectedTitles([]);
                          } else {
                            setSelectedTitles(uniqueEmailsOfStaff);
                            // dispatch(getStaffThunk({ dynamicStaffId }));
                            dispatch(
                              setStaffFilterItems({
                                dynamicId: dynamicStaffId,
                                filterColumn: "",
                                filterValue: "",
                                searchVal: "",
                                sortColumn: "",
                                sortType: "",
                                status: "",
                                staffPage,
                              })
                            );
                          }
                        }}
                      >
                        (Select All)
                      </Checkbox>

                      {uniqueEmailsOfStaff?.map((elem: any, index: any) => (
                        <div
                          className="dropdown-2-item dropdown-2-item-no-hover-effect"
                          key={index}
                        >
                          {elem !== null && (
                            <Checkbox
                              onChange={() =>
                                handleCheckboxChangeStaff(elem, "email", index)
                              }
                              isChecked={selectedTitles.includes(elem)}
                            >
                              {elem}
                            </Checkbox>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              {selectedItemIndex === 2 &&
                selectedFilterDropDownOne?.id === 1 && (
                  <div className="dropdown-2-box-sub-2">
                    <div className="sub-dropdown-2">
                      <Checkbox
                        isChecked={
                          selectedTitles.length === uniqueMobileOfStaff.length
                        }
                        className="dropdown-2-item dropdown-2-item-no-hover-effect w-full"
                        onChange={() => {
                          if (
                            selectedTitles.length === uniqueMobileOfStaff.length
                          ) {
                            setSelectedTitles([]);
                          } else {
                            setSelectedTitles(uniqueMobileOfStaff);
                            // dispatch(getStaffThunk({ dynamicStaffId }));
                            dispatch(
                              setStaffFilterItems({
                                dynamicId: dynamicStaffId,
                                filterColumn: "",
                                filterValue: "",
                                searchVal: "",
                                sortColumn: "",
                                sortType: "",
                                status: "",
                                staffPage,
                              })
                            );
                          }
                        }}
                      >
                        (Select All)
                      </Checkbox>

                      {uniqueMobileOfStaff.map((elem: any, index: any) => (
                        <div
                          className="dropdown-2-item dropdown-2-item-no-hover-effect"
                          key={index}
                        >
                          {elem !== null && (
                            <Checkbox
                              isChecked={selectedTitles?.includes(elem)}
                              onChange={() =>
                                handleCheckboxChangeStaff(elem, "mobile", index)
                              }
                            >
                              {elem}
                            </Checkbox>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              {selectedItemIndex === 3 &&
                selectedFilterDropDownOne?.id === 1 && (
                  <div className="dropdown-2-box-sub-2">
                    <div className="sub-dropdown-2">
                      <Checkbox
                        isChecked={
                          selectedTitles.length === roleDescriptions.length
                        }
                        className="dropdown-2-item dropdown-2-item-no-hover-effect w-full"
                        onChange={() => {
                          if (
                            selectedTitles.length === roleDescriptions.length
                          ) {
                            setSelectedTitles([]);
                          } else {
                            setSelectedTitles(roleDescriptions);
                            // dispatch(getStaffThunk({ dynamicStaffId }));
                          }
                        }}
                      >
                        (Select All)
                      </Checkbox>
                      {roleDescriptions?.map((elem: any, index: any) => (
                        <div
                          className="dropdown-2-item dropdown-2-item-no-hover-effect"
                          key={index}
                        >
                          {elem !== null && (
                            <Checkbox
                              isChecked={selectedTitles?.includes(elem)}
                              onChange={() =>
                                handleCheckboxChangeStaff(elem, "role", index)
                              }
                            >
                              {elem}
                            </Checkbox>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                )}

              {selectedItemIndex === 4 &&
                selectedFilterDropDownOne?.id === 1 && (
                  <div className="dropdown-2-box-sub-2">
                    <div className="sub-dropdown-2">
                      <Checkbox
                        isChecked={
                          selectedTitles.length === statusMappingStatus.length
                        }
                        className="dropdown-2-item dropdown-2-item-no-hover-effect w-full"
                        onChange={() => {
                          if (
                            selectedTitles.length === statusMappingStatus.length
                          ) {
                            setSelectedTitles([]);
                            dispatch(
                              getStaffSelectAllThunk({
                                dynamicId: dynamicStaffId,
                                status: "1,2,7",
                              })
                            );
                          } else {
                            setSelectedTitles(statusMappingStatus);
                            dispatch(
                              getStaffSelectAllThunk({
                                dynamicId: dynamicStaffId,
                                status: "0,1,2,7",
                              })
                            );
                          }
                        }}
                      >
                        (Select All)
                      </Checkbox>
                      {statusMappingStatus?.map((elem: any, index: any) => (
                        <div
                          className="dropdown-2-item dropdown-2-item-no-hover-effect"
                          key={index}
                        >
                          {elem !== null && (
                            <Checkbox
                              isChecked={selectedTitles?.includes(elem)}
                              onChange={() =>
                                handleCheckboxChangeStaff(elem, "status", index)
                              }
                            >
                              {elem}
                            </Checkbox>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                )}

              {/*  /////////////////////////////////////////////////////////////////////////////////////////////////////////// */}

              {/* SORT OPTIONS */}
              {!!selectedSortDropDownOne &&
              selectedSortDropDownOne?.id === 3 ? (
                <>
                  <SortDropdown
                    tableHeadArr={["Name", "Email", "Mobile", "Role", "Status"]}
                    selectedSortIndex={selectedSortIndex}
                    onClick={handleOpenSortOptionsOnBasisOfIndexOfColumn}
                  />
                </>
              ) : null}

              {selectedSortIndex === 0 && selectedSortDropDownOne?.id === 3 && (
                <div className="dropdown-2-box-sub-2">
                  <div className="sub-dropdown-2">
                    {["newest on top", "oldest on top"].map((title, index) => {
                      return (
                        <div
                          className={`dropdown-2-item capitalize hover:text-white  ${
                            title === sortByStaff &&
                            "dropdown-2-item-selected text-white"
                          }`}
                          onClick={() =>
                            handleSortChangeStaff(title, selectedSortIndex)
                          }
                        >
                          <span
                            className="font-normal"
                            style={{
                              textDecoration: "none",
                              textTransform: "none",
                            }}
                          >
                            {index === 0 ? "A to Z" : "Z to A"}
                          </span>
                          <div className="relative left-[2px] flex">
                            {index === 0 ? (
                              <svg
                                width="20px"
                                height="20px"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M12 4V20M12 20L8 16M12 20L16 16"
                                  stroke={filterIconCo̥lor}
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            ) : (
                              <svg
                                width="20px"
                                height="20px"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M12 4V20M12 4L8 8M12 4L16 8"
                                  stroke={filterIconCo̥lor}
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}

              {selectedSortIndex === 1 && selectedSortDropDownOne?.id === 3 && (
                <div className="dropdown-2-box-sub-2">
                  <div className="sub-dropdown-2">
                    {["newest on top", "oldest on top"].map((title, index) => {
                      return (
                        <div
                          className={`dropdown-2-item capitalize hover:text-white  ${
                            title === sortByClients &&
                            "dropdown-2-item-selected text-white"
                          }`}
                          onClick={() =>
                            handleSortChangeStaff(
                              title,

                              selectedSortIndex
                            )
                          }
                        >
                          <span
                            className="font-normal"
                            style={{
                              textDecoration: "none",
                              textTransform: "none",
                            }}
                          >
                            {index === 0 ? "A to Z" : "Z to A"}
                          </span>
                          <div className="relative left-[2px] flex">
                            {index === 0 ? (
                              <svg
                                width="20px"
                                height="20px"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M12 4V20M12 20L8 16M12 20L16 16"
                                  stroke={filterIconCo̥lor}
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            ) : (
                              <svg
                                width="20px"
                                height="20px"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M12 4V20M12 4L8 8M12 4L16 8"
                                  stroke={filterIconCo̥lor}
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}

              {selectedSortIndex === 2 && selectedSortDropDownOne?.id === 3 && (
                <div className="dropdown-2-box-sub-2">
                  <div className="sub-dropdown-2">
                    {["newest on top", "oldest on top"].map((title, index) => {
                      return (
                        <div
                          className={`dropdown-2-item capitalize hover:text-white  ${
                            title === sortByClients &&
                            "dropdown-2-item-selected text-white"
                          }`}
                          onClick={() =>
                            handleSortChangeStaff(title, selectedSortIndex)
                          }
                        >
                          <span
                            className="font-normal"
                            style={{
                              textDecoration: "none",
                              textTransform: "none",
                            }}
                          >
                            {index === 0 ? "A to Z" : "Z to A"}
                          </span>
                          <div className="relative left-[2px] flex">
                            {index === 0 ? (
                              <svg
                                width="20px"
                                height="20px"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M12 4V20M12 20L8 16M12 20L16 16"
                                  stroke={filterIconCo̥lor}
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            ) : (
                              <svg
                                width="20px"
                                height="20px"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M12 4V20M12 4L8 8M12 4L16 8"
                                  stroke={filterIconCo̥lor}
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}

              {selectedSortIndex === 3 && selectedSortDropDownOne?.id === 3 && (
                <div className="dropdown-2-box-sub-2">
                  <div className="sub-dropdown-2">
                    {["newest on top", "oldest on top"].map((title, index) => {
                      return (
                        <div
                          className={`dropdown-2-item capitalize hover:text-white  ${
                            title === sortByClients &&
                            "dropdown-2-item-selected text-white"
                          }`}
                          onClick={() =>
                            handleSortChangeStaff(title, selectedSortIndex)
                          }
                        >
                          <span
                            className="font-normal"
                            style={{
                              textDecoration: "none",
                              textTransform: "none",
                            }}
                          >
                            {index === 0 ? "A to Z" : "Z to A"}
                          </span>
                          <div className="relative left-[2px] flex">
                            {index === 0 ? (
                              <svg
                                width="20px"
                                height="20px"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M12 4V20M12 20L8 16M12 20L16 16"
                                  stroke={filterIconCo̥lor}
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            ) : (
                              <svg
                                width="20px"
                                height="20px"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M12 4V20M12 4L8 8M12 4L16 8"
                                  stroke={filterIconCo̥lor}
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
              {selectedSortIndex === 4 && selectedSortDropDownOne?.id === 3 && (
                <div className="dropdown-2-box-sub-2">
                  <div className="sub-dropdown-2">
                    {["newest on top", "oldest on top"].map((title, index) => {
                      return (
                        <div
                          className={`dropdown-2-item capitalize hover:text-white  ${
                            title === sortByClients &&
                            "dropdown-2-item-selected text-white"
                          }`}
                          onClick={() =>
                            handleSortChangeStaff(title, selectedSortIndex)
                          }
                        >
                          <span
                            className="font-normal"
                            style={{
                              textDecoration: "none",
                              textTransform: "none",
                            }}
                          >
                            {index === 0 ? "A to Z" : "Z to A"}
                          </span>
                          <div className="relative left-[2px] flex">
                            {index === 0 ? (
                              <svg
                                width="20px"
                                height="20px"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M12 4V20M12 20L8 16M12 20L16 16"
                                  stroke={filterIconCo̥lor}
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            ) : (
                              <svg
                                width="20px"
                                height="20px"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M12 4V20M12 4L8 8M12 4L16 8"
                                  stroke={filterIconCo̥lor}
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {/* AUDIT DROPDOWN */}
      {type === "audit" && (
        <div className="dropdown-wrapper-main">
          <div className="associations-table-dropdown">
            <div className="dropdown-2-box">
              <div className="dropdown-2">
                {Data.map(({ icon, title, id, ...props }, index) => (
                  <div
                    key={index}
                    className={`dropdown-2-item custom-strokeWidth ${
                      AuditTableDataObject.currentActive === title &&
                      "dropdown-2-item-selected"
                    }`}
                    onClick={() =>
                      handleItemClickDropdownOneDocuments(
                        id,
                        icon,
                        title,
                        "audit"
                      )
                    }
                  >
                    <div className="dropdown-2-item-img">{icon}</div>
                    {id === 2 ? (
                      <div className="search-input-field-documents-dropdown">
                        <input
                          ref={inputRefAudit}
                          value={inputValueTextAudit}
                          placeholder="Search"
                          onFocus={handleFoucsInSearchOfAssociations}
                          onChange={(e) => {
                            handleInputChangeAudit(e);
                          }}
                          type="text"
                        />
                        <svg
                          onClick={handleClearSearchAudit}
                          width="16"
                          height="18"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className=" absolute right-[0rem] bottom-[0.15rem]"
                        >
                          <path
                            d="M18 6L6 18M6 6L18 18"
                            stroke="#FFF"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    ) : (
                      <p>{title}</p>
                    )}
                  </div>
                ))}
              </div>

              {/* ///////////////////////////////////////////////////// FILTER OPTIONS ////////////////////////////////////////////////////////// */}
              {!!selectedFilterDropDownOne &&
              selectedFilterDropDownOne?.id === 1 ? (
                <div className="dropdown-2-box-sub">
                  <div className="sub-dropdown-2">
                    {["Username", "Activity Type", "Table Name"]?.map(
                      (elem, index) => (
                        <div
                          key={index}
                          className={`dropdown-2-item ${
                            AuditTableDataObject.selectedItemIndex === index
                              ? "dropdown-2-item-selected"
                              : ""
                          }`}
                          onClick={() =>
                            handleClickAssociationsColumn(index, "audit")
                          }
                        >
                          <p>{elem}</p>
                        </div>
                      )
                    )}
                  </div>
                </div>
              ) : null}

              {/* ////////////////////////////////////////////////    FILTERING AUDIT    ////////////////////////////////////////////////////////////////////// */}
              {AuditTableDataObject.selectedItemIndex === 0 &&
                selectedFilterDropDownOne?.id === 1 && (
                  <div className="dropdown-2-box-sub-2">
                    <div className="sub-dropdown-2">
                      <Checkbox
                        isChecked={
                          selectedTitles?.length === AuditUsername.length
                        }
                        className="dropdown-2-item dropdown-2-item-no-hover-effect w-full"
                        onChange={() => {
                          if (selectedTitles?.length === AuditUsername.length) {
                            setSelectedTitles([]);
                          } else {
                            setSelectedTitles(AuditUsername);
                            // dispatch(getAuditThunk(1));
                            dispatch(
                              setAuditFilterItems({
                                filterColumn: "username",
                                filterValue: AuditUsername.join(","),
                                searchVal: "",
                                sortColumn: "",
                                sortType: "",
                                auditPageFilter: AuditPageFilter,
                              })
                            );
                          }
                        }}
                      >
                        (Select All)
                      </Checkbox>

                      {AuditUsername?.map((elem: any, index: any) => (
                        <div
                          className="dropdown-2-item dropdown-2-item-no-hover-effect"
                          // note here key forces re render
                          key={elem?.id}
                        >
                          <Checkbox
                            isChecked={selectedTitles.includes(elem)}
                            onChange={() =>
                              handleCheckboxChangeAudit(elem, "username", index)
                            }
                          >
                            {elem}
                          </Checkbox>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              {AuditTableDataObject.selectedItemIndex === 1 &&
                selectedFilterDropDownOne?.id === 1 && (
                  <div className="dropdown-2-box-sub-2">
                    <div className="sub-dropdown-2">
                      <Checkbox
                        isChecked={
                          selectedTitles.length === AuditActivityType.length
                        }
                        className="dropdown-2-item dropdown-2-item-no-hover-effect w-full"
                        onChange={() => {
                          if (
                            selectedTitles.length === AuditActivityType.length
                          ) {
                            setSelectedTitles([]);
                          } else {
                            setSelectedTitles(AuditActivityType);
                            // dispatch(getAuditThunk(1));
                            dispatch(
                              setAuditFilterItems({
                                filterColumn: "activity_type",
                                filterValue: AuditActivityType.join(","),
                                searchVal: "",
                                sortColumn: "",
                                sortType: "",
                                auditPageFilter: AuditPageFilter,
                              })
                            );
                          }
                        }}
                      >
                        (Select All)
                      </Checkbox>

                      {AuditActivityType?.map((elem: any, index: any) => (
                        <div
                          className="dropdown-2-item dropdown-2-item-no-hover-effect"
                          key={index}
                        >
                          {elem !== null && (
                            <Checkbox
                              onChange={() =>
                                handleCheckboxChangeAudit(
                                  elem,
                                  "activity_type",
                                  index
                                )
                              }
                              isChecked={selectedTitles.includes(elem)}
                            >
                              {elem}
                            </Checkbox>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              {AuditTableDataObject.selectedItemIndex === 2 &&
                selectedFilterDropDownOne?.id === 1 && (
                  <div className="dropdown-2-box-sub-2">
                    <div className="sub-dropdown-2">
                      <Checkbox
                        isChecked={
                          selectedTitles.length === AuditTableName.length
                        }
                        className="dropdown-2-item dropdown-2-item-no-hover-effect w-full"
                        onChange={() => {
                          if (selectedTitles.length === AuditTableName.length) {
                            setSelectedTitles([]);
                          } else {
                            setSelectedTitles(AuditTableName);
                            // dispatch(getAuditThunk(1));
                            dispatch(
                              setAuditFilterItems({
                                filterColumn: "table_name",
                                filterValue: AuditTableName.join(","),
                                searchVal: "",
                                sortColumn: "",
                                sortType: "",
                                auditPageFilter: AuditPageFilter,
                              })
                            );
                          }
                        }}
                      >
                        (Select All)
                      </Checkbox>

                      {AuditTableName.map((elem: any, index: any) => (
                        <div
                          className="dropdown-2-item dropdown-2-item-no-hover-effect"
                          key={index}
                        >
                          {elem !== null && (
                            <Checkbox
                              isChecked={selectedTitles?.includes(elem)}
                              onChange={() =>
                                handleCheckboxChangeAudit(
                                  elem,
                                  "table_name",
                                  index
                                )
                              }
                            >
                              {elem}
                            </Checkbox>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              {/*  /////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
              {/* selectedSortDropDownOne?.id === 3 */}
              {/* SORT OPTIONS */}
              {/* AuditTableDataObject.currentActive === "sort" || */}
              {!!selectedSortDropDownOne &&
              AuditTableDataObject.currentActive === "sort" ? (
                <div className="dropdown-2-box-sub">
                  <div className="sub-dropdown-2">
                    {[
                      "Created At",
                      "Username",
                      "Description",
                      "Activity Type",
                      "Table Name",
                      "IP Address",
                    ]?.map((elem: any, index: number) => (
                      <div
                        key={index}
                        className={`dropdown-2-item ${
                          AuditTableDataObject.selectedSortIndex === index
                            ? "dropdown-2-item-selected"
                            : ""
                        }`}
                        onClick={() =>
                          handleOpenSortOptionsOnBasisOfIndexOfColumn(
                            index,
                            "audit"
                          )
                        }
                      >
                        <p>{elem}</p>
                      </div>
                    ))}
                  </div>
                </div>
              ) : null}

              {/* SORT OPTIONS ITEMS */}

              {AuditTableDataObject.selectedSortIndex === 0 &&
                selectedSortDropDownOne?.id === 3 && (
                  <div className="dropdown-2-box-sub-2">
                    <div className="sub-dropdown-2">
                      {["newest on top", "oldest on top"].map(
                        (title, index) => {
                          return (
                            <div
                              className={`dropdown-2-item capitalize hover:text-white  ${
                                title === sortByAudit &&
                                "dropdown-2-item-selected text-white"
                              }`}
                              onClick={() =>
                                handleSortChangeAudit(title, selectedSortIndex)
                              }
                            >
                              <span
                                className="font-normal"
                                style={{
                                  textDecoration: "none",
                                  textTransform: "none",
                                }}
                              >
                                {index === 0 ? "A to Z" : "Z to A"}
                              </span>
                              <div className="relative left-[2px] flex">
                                {index === 0 ? (
                                  <svg
                                    width="20px"
                                    height="20px"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M12 4V20M12 20L8 16M12 20L16 16"
                                      stroke={filterIconCo̥lor}
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                ) : (
                                  <svg
                                    width="20px"
                                    height="20px"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M12 4V20M12 4L8 8M12 4L16 8"
                                      stroke={filterIconCo̥lor}
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                )}
                              </div>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                )}

              {AuditTableDataObject.selectedSortIndex === 1 &&
                selectedSortDropDownOne?.id === 3 && (
                  <div className="dropdown-2-box-sub-2">
                    <div className="sub-dropdown-2">
                      {["newest on top", "oldest on top"].map(
                        (title, index) => {
                          return (
                            <div
                              className={`dropdown-2-item capitalize hover:text-white  ${
                                title === sortByAudit &&
                                "dropdown-2-item-selected text-white"
                              }`}
                              onClick={() =>
                                handleSortChangeAudit(
                                  title,

                                  selectedSortIndex
                                )
                              }
                            >
                              <span
                                className="font-normal"
                                style={{
                                  textDecoration: "none",
                                  textTransform: "none",
                                }}
                              >
                                {index === 0 ? "A to Z" : "Z to A"}
                              </span>
                              <div className="relative left-[2px] flex">
                                {index === 0 ? (
                                  <svg
                                    width="20px"
                                    height="20px"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M12 4V20M12 20L8 16M12 20L16 16"
                                      stroke={filterIconCo̥lor}
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                ) : (
                                  <svg
                                    width="20px"
                                    height="20px"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M12 4V20M12 4L8 8M12 4L16 8"
                                      stroke={filterIconCo̥lor}
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                )}
                              </div>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                )}

              {AuditTableDataObject.selectedSortIndex === 2 &&
                selectedSortDropDownOne?.id === 3 && (
                  <div className="dropdown-2-box-sub-2">
                    <div className="sub-dropdown-2">
                      {["newest on top", "oldest on top"].map(
                        (title, index) => {
                          return (
                            <div
                              className={`dropdown-2-item capitalize hover:text-white  ${
                                title === sortByAudit &&
                                "dropdown-2-item-selected text-white"
                              }`}
                              onClick={() =>
                                handleSortChangeAudit(title, selectedSortIndex)
                              }
                            >
                              <span
                                className="font-normal"
                                style={{
                                  textDecoration: "none",
                                  textTransform: "none",
                                }}
                              >
                                {index === 0 ? "A to Z" : "Z to A"}
                              </span>
                              <div className="relative left-[2px] flex">
                                {index === 0 ? (
                                  <svg
                                    width="20px"
                                    height="20px"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M12 4V20M12 20L8 16M12 20L16 16"
                                      stroke={filterIconCo̥lor}
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                ) : (
                                  <svg
                                    width="20px"
                                    height="20px"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M12 4V20M12 4L8 8M12 4L16 8"
                                      stroke={filterIconCo̥lor}
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                )}
                              </div>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                )}

              {AuditTableDataObject.selectedSortIndex === 3 &&
                selectedSortDropDownOne?.id === 3 && (
                  <div className="dropdown-2-box-sub-2">
                    <div className="sub-dropdown-2">
                      {["newest on top", "oldest on top"].map(
                        (title, index) => {
                          return (
                            <div
                              className={`dropdown-2-item capitalize hover:text-white  ${
                                title === sortByAudit &&
                                "dropdown-2-item-selected text-white"
                              }`}
                              onClick={() =>
                                handleSortChangeAudit(title, selectedSortIndex)
                              }
                            >
                              <span
                                className="font-normal"
                                style={{
                                  textDecoration: "none",
                                  textTransform: "none",
                                }}
                              >
                                {index === 0 ? "A to Z" : "Z to A"}
                              </span>
                              <div className="relative left-[2px] flex">
                                {index === 0 ? (
                                  <svg
                                    width="20px"
                                    height="20px"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M12 4V20M12 20L8 16M12 20L16 16"
                                      stroke={filterIconCo̥lor}
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                ) : (
                                  <svg
                                    width="20px"
                                    height="20px"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M12 4V20M12 4L8 8M12 4L16 8"
                                      stroke={filterIconCo̥lor}
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                )}
                              </div>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                )}
              {AuditTableDataObject.selectedSortIndex === 4 &&
                selectedSortDropDownOne?.id === 3 && (
                  <div className="dropdown-2-box-sub-2">
                    <div className="sub-dropdown-2">
                      {["newest on top", "oldest on top"].map(
                        (title, index) => {
                          return (
                            <div
                              className={`dropdown-2-item capitalize hover:text-white  ${
                                title === sortByAudit &&
                                "dropdown-2-item-selected text-white"
                              }`}
                              onClick={() =>
                                handleSortChangeAudit(title, selectedSortIndex)
                              }
                            >
                              <span
                                className="font-normal"
                                style={{
                                  textDecoration: "none",
                                  textTransform: "none",
                                }}
                              >
                                {index === 0 ? "A to Z" : "Z to A"}
                              </span>
                              <div className="relative left-[2px] flex">
                                {index === 0 ? (
                                  <svg
                                    width="20px"
                                    height="20px"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M12 4V20M12 20L8 16M12 20L16 16"
                                      stroke={filterIconCo̥lor}
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                ) : (
                                  <svg
                                    width="20px"
                                    height="20px"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M12 4V20M12 4L8 8M12 4L16 8"
                                      stroke={filterIconCo̥lor}
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                )}
                              </div>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                )}
              {AuditTableDataObject.selectedSortIndex === 5 &&
                selectedSortDropDownOne?.id === 3 && (
                  <div className="dropdown-2-box-sub-2">
                    <div className="sub-dropdown-2">
                      {["newest on top", "oldest on top"].map(
                        (title, index) => {
                          return (
                            <div
                              className={`dropdown-2-item capitalize hover:text-white  ${
                                title === sortByAudit &&
                                "dropdown-2-item-selected text-white"
                              }`}
                              onClick={() =>
                                handleSortChangeAudit(title, selectedSortIndex)
                              }
                            >
                              <span
                                className="font-normal"
                                style={{
                                  textDecoration: "none",
                                  textTransform: "none",
                                }}
                              >
                                {index === 0 ? "A to Z" : "Z to A"}
                              </span>
                              <div className="relative left-[2px] flex">
                                {index === 0 ? (
                                  <svg
                                    width="20px"
                                    height="20px"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M12 4V20M12 20L8 16M12 20L16 16"
                                      stroke={filterIconCo̥lor}
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                ) : (
                                  <svg
                                    width="20px"
                                    height="20px"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M12 4V20M12 4L8 8M12 4L16 8"
                                      stroke={filterIconCo̥lor}
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                )}
                              </div>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                )}
              {AuditTableDataObject.selectedSortIndex === 6 &&
                selectedSortDropDownOne?.id === 3 && (
                  <div className="dropdown-2-box-sub-2">
                    <div className="sub-dropdown-2">
                      {["newest on top", "oldest on top"].map(
                        (title, index) => {
                          return (
                            <div
                              className={`dropdown-2-item capitalize hover:text-white  ${
                                title === sortByAudit &&
                                "dropdown-2-item-selected text-white"
                              }`}
                              onClick={() =>
                                handleSortChangeAudit(title, selectedSortIndex)
                              }
                            >
                              <span
                                className="font-normal"
                                style={{
                                  textDecoration: "none",
                                  textTransform: "none",
                                }}
                              >
                                {index === 0 ? "A to Z" : "Z to A"}
                              </span>
                              <div className="relative left-[2px] flex">
                                {index === 0 ? (
                                  <svg
                                    width="20px"
                                    height="20px"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M12 4V20M12 20L8 16M12 20L16 16"
                                      stroke={filterIconCo̥lor}
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                ) : (
                                  <svg
                                    width="20px"
                                    height="20px"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M12 4V20M12 4L8 8M12 4L16 8"
                                      stroke={filterIconCo̥lor}
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                )}
                              </div>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                )}
              {AuditTableDataObject.selectedSortIndex === 7 &&
                selectedSortDropDownOne?.id === 3 && (
                  <div className="dropdown-2-box-sub-2">
                    <div className="sub-dropdown-2">
                      {["newest on top", "oldest on top"].map(
                        (title, index) => {
                          return (
                            <div
                              className={`dropdown-2-item capitalize hover:text-white  ${
                                title === sortByAudit &&
                                "dropdown-2-item-selected text-white"
                              }`}
                              onClick={() =>
                                handleSortChangeAudit(title, selectedSortIndex)
                              }
                            >
                              <span
                                className="font-normal"
                                style={{
                                  textDecoration: "none",
                                  textTransform: "none",
                                }}
                              >
                                {index === 0 ? "A to Z" : "Z to A"}
                              </span>
                              <div className="relative left-[2px] flex">
                                {index === 0 ? (
                                  <svg
                                    width="20px"
                                    height="20px"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M12 4V20M12 20L8 16M12 20L16 16"
                                      stroke={filterIconCo̥lor}
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                ) : (
                                  <svg
                                    width="20px"
                                    height="20px"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M12 4V20M12 4L8 8M12 4L16 8"
                                      stroke={filterIconCo̥lor}
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                )}
                              </div>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                )}
            </div>
          </div>
        </div>
      )}
      {/* BUSINESS DROPDOWN */}
      {(type === "business" || type === "providerBusiness") && (
        <div className="dropdown-wrapper-main">
          <div className="associations-table-dropdown">
            <div className="dropdown-2-box">
              <div className="dropdown-2">
                <DropdownOptions
                  selectedItem={businessTableDataObject?.currentActive}
                  data={Data}
                  ref={inputRefBusiness}
                  onFocus={handleFoucsInSearchOfAssociations}
                  inputTextValue={inputValueTextBusiness}
                  onChange={handleInputChangeBusiness}
                  clearSearch={handleClearSearchBusiness}
                  onClick={handleItemClickDropdownOneDocuments}
                  selectedType={"business"}
                />
              </div>

              {/* ///////////////////////////////////////////////////// FILTER OPTIONS ////////////////////////////////////////////////////////// */}
              {!!selectedFilterDropDownOne &&
              selectedFilterDropDownOne?.id === 1 ? (
                <div className="dropdown-2-box-sub">
                  <div className="sub-dropdown-2">
                    {["Name", "Email", "Phone", "Status"]?.map(
                      (elem, index) => (
                        <div
                          key={index}
                          className={`dropdown-2-item ${
                            businessTableDataObject.selectedItemIndex === index
                              ? "dropdown-2-item-selected"
                              : ""
                          }`}
                          onClick={() =>
                            handleClickAssociationsColumn(index, "business")
                          }
                        >
                          <p>{elem}</p>
                        </div>
                      )
                    )}
                  </div>
                </div>
              ) : null}

              {/* ////////////////////////////////////////////////    FILTERING Business    ////////////////////////////////////////////////////////////////////// */}
              {businessTableDataObject.selectedItemIndex === 0 &&
                selectedFilterDropDownOne?.id === 1 && (
                  <div className="dropdown-2-box-sub-2">
                    <div className="sub-dropdown-2">
                      <Checkbox
                        isChecked={
                          selectedTitles?.length === BusinessName?.length
                        }
                        className="dropdown-2-item dropdown-2-item-no-hover-effect w-full"
                        onChange={() => {
                          if (selectedTitles?.length === BusinessName?.length) {
                            setSelectedTitles([]);
                          } else {
                            setSelectedTitles(BusinessName);
                            // dispatch(getBusinessThunk(dynamicBusinessId));
                            dispatch(
                              setBusinessFilterItems({
                                filterColumn: "",
                                filterValue: "",
                                searchVal: "",
                                sortColumn: "",
                                sortType: "",
                                status: "",
                                dynamicId: dynamicBusinessId,
                                BusinessPage,
                              })
                            );
                          }
                        }}
                      >
                        (Select All)
                      </Checkbox>

                      {BusinessName?.map((elem: any, index: any) => (
                        <div
                          className="dropdown-2-item dropdown-2-item-no-hover-effect"
                          // note here key forces re render
                          key={elem?.id}
                        >
                          <Checkbox
                            isChecked={selectedTitles.includes(elem)}
                            onChange={() =>
                              handleCheckboxChangeBusiness(elem, "name", index)
                            }
                          >
                            {elem}
                          </Checkbox>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              {businessTableDataObject.selectedItemIndex === 1 &&
                selectedFilterDropDownOne?.id === 1 && (
                  <div className="dropdown-2-box-sub-2">
                    <div className="sub-dropdown-2">
                      <Checkbox
                        isChecked={
                          selectedTitles.length === BusinessEmail.length
                        }
                        className="dropdown-2-item dropdown-2-item-no-hover-effect w-full"
                        onChange={() => {
                          if (selectedTitles.length === BusinessEmail.length) {
                            setSelectedTitles([]);
                          } else {
                            setSelectedTitles(BusinessEmail);
                            // dispatch(getBusinessThunk(dynamicBusinessId));
                            dispatch(
                              setBusinessFilterItems({
                                filterColumn: "",
                                filterValue: "",
                                searchVal: "",
                                sortColumn: "",
                                sortType: "",
                                status: "",
                                dynamicId: dynamicBusinessId,
                                BusinessPage,
                              })
                            );
                          }
                        }}
                      >
                        (Select All)
                      </Checkbox>

                      {BusinessEmail?.map((elem: any, index: any) => (
                        <div
                          className="dropdown-2-item dropdown-2-item-no-hover-effect"
                          key={index}
                        >
                          {elem !== null && (
                            <Checkbox
                              onChange={() =>
                                handleCheckboxChangeBusiness(
                                  elem,
                                  "email",
                                  index
                                )
                              }
                              isChecked={selectedTitles.includes(elem)}
                            >
                              {elem}
                            </Checkbox>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              {businessTableDataObject.selectedItemIndex === 2 &&
                selectedFilterDropDownOne?.id === 1 && (
                  <div className="dropdown-2-box-sub-2">
                    <div className="sub-dropdown-2">
                      <Checkbox
                        isChecked={
                          selectedTitles.length === BusinessPhone.length
                        }
                        className="dropdown-2-item dropdown-2-item-no-hover-effect w-full"
                        onChange={() => {
                          if (selectedTitles.length === BusinessPhone.length) {
                            setSelectedTitles([]);
                          } else {
                            setSelectedTitles(BusinessPhone);
                            // dispatch(getBusinessThunk(dynamicBusinessId));
                            dispatch(
                              setBusinessFilterItems({
                                filterColumn: "",
                                filterValue: "",
                                searchVal: "",
                                sortColumn: "",
                                sortType: "",
                                status: "",
                                dynamicId: dynamicBusinessId,
                                BusinessPage,
                              })
                            );
                          }
                        }}
                      >
                        (Select All)
                      </Checkbox>

                      {BusinessPhone?.map((elem: any, index: any) => (
                        <div
                          className="dropdown-2-item dropdown-2-item-no-hover-effect"
                          key={index}
                        >
                          {elem !== null && (
                            <Checkbox
                              isChecked={selectedTitles?.includes(elem)}
                              onChange={() =>
                                handleCheckboxChangeBusiness(
                                  elem,
                                  "phone",
                                  index
                                )
                              }
                            >
                              {elem}
                            </Checkbox>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              {businessTableDataObject.selectedItemIndex === 3 &&
                selectedFilterDropDownOne?.id === 1 && (
                  <div className="dropdown-2-box-sub-2">
                    <div className="sub-dropdown-2">
                      <Checkbox
                        isChecked={
                          selectedTitles.length ===
                          statusMappingForBusinessAndTeams.length
                        }
                        className="dropdown-2-item dropdown-2-item-no-hover-effect w-full"
                        onChange={() => {
                          if (
                            selectedTitles.length ===
                            statusMappingForBusinessAndTeams.length
                          ) {
                            setSelectedTitles([]);

                            // dispatch(getBusinessThunk(dynamicBusinessId));
                            dispatch(
                              setBusinessFilterItems({
                                filterColumn: "",
                                filterValue: "",
                                searchVal: "",
                                sortColumn: "",
                                sortType: "",
                                status: "",
                                dynamicId: dynamicBusinessId,
                                BusinessPage,
                              })
                            );
                          } else {
                            setSelectedTitles(statusMappingForBusinessAndTeams);
                            // dispatch(getBusinessThunk(dynamicBusinessId));
                            // dispatch(
                            //   getBusinessByFilterThunk({
                            //     filterColumn: "",
                            //     filterValue: "",
                            //     searchVal: "",
                            //     sortColumn: "",
                            //     sortType: "",
                            //     status: "0,1",
                            //     dynamicId: dynamicBusinessId,
                            //     BusinessPage
                            //   })
                            // );
                            dispatch(
                              setBusinessFilterItems({
                                filterColumn: "",
                                filterValue: "",
                                searchVal: "",
                                sortColumn: "",
                                sortType: "",
                                status: "0,1",
                                dynamicId: dynamicBusinessId,
                                BusinessPage,
                              })
                            );
                          }
                        }}
                      >
                        (Select All)
                      </Checkbox>
                      {statusMappingForBusinessAndTeams?.map(
                        (elem: any, index: any) => (
                          <div
                            className="dropdown-2-item dropdown-2-item-no-hover-effect"
                            key={index}
                          >
                            {elem !== null && (
                              <Checkbox
                                isChecked={selectedTitles?.includes(elem)}
                                onChange={() =>
                                  handleCheckboxChangeBusiness(
                                    elem,
                                    "status",
                                    index
                                  )
                                }
                              >
                                {elem}
                              </Checkbox>
                            )}
                          </div>
                        )
                      )}
                    </div>
                  </div>
                )}

              {/*  /////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
              {/*  selectedSortDropDownOne?.id === 3 */}
              {/* SORT OPTIONS */}
              {!!selectedSortDropDownOne &&
              businessTableDataObject.currentActive === "sort" ? (
                <div className="dropdown-2-box-sub">
                  <div className="sub-dropdown-2">
                    {["Name", "Email", "Phone", "Status"]?.map(
                      (elem: any, index: number) => (
                        <div
                          key={index}
                          className={`dropdown-2-item ${
                            businessTableDataObject.selectedSortIndex === index
                              ? "dropdown-2-item-selected"
                              : ""
                          }`}
                          onClick={() =>
                            handleOpenSortOptionsOnBasisOfIndexOfColumn(
                              index,
                              "business"
                            )
                          }
                        >
                          <p>{elem}</p>
                        </div>
                      )
                    )}
                  </div>
                </div>
              ) : null}

              {/* SORT OPTIONS ITEMS */}

              {businessTableDataObject.selectedSortIndex === 0 &&
                selectedSortDropDownOne?.id === 3 && (
                  <div className="dropdown-2-box-sub-2">
                    <div className="sub-dropdown-2">
                      {["newest on top", "oldest on top"].map(
                        (title, index) => {
                          return (
                            <div
                              className={`dropdown-2-item capitalize hover:text-white  ${
                                title === sortByBusiness &&
                                "dropdown-2-item-selected text-white"
                              }`}
                              onClick={() =>
                                handleSortChangeBusiness(
                                  title,
                                  selectedSortIndex
                                )
                              }
                            >
                              <span
                                className="font-normal"
                                style={{
                                  textDecoration: "none",
                                  textTransform: "none",
                                }}
                              >
                                {index === 0 ? "A to Z" : "Z to A"}
                              </span>
                              <div className="relative left-[2px] flex">
                                {index === 0 ? (
                                  <svg
                                    width="20px"
                                    height="20px"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M12 4V20M12 20L8 16M12 20L16 16"
                                      stroke={filterIconCo̥lor}
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                ) : (
                                  <svg
                                    width="20px"
                                    height="20px"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M12 4V20M12 4L8 8M12 4L16 8"
                                      stroke={filterIconCo̥lor}
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                )}
                              </div>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                )}

              {businessTableDataObject.selectedSortIndex === 1 &&
                selectedSortDropDownOne?.id === 3 && (
                  <div className="dropdown-2-box-sub-2">
                    <div className="sub-dropdown-2">
                      {["newest on top", "oldest on top"].map(
                        (title, index) => {
                          return (
                            <div
                              className={`dropdown-2-item capitalize hover:text-white  ${
                                title === sortByBusiness &&
                                "dropdown-2-item-selected text-white"
                              }`}
                              onClick={() =>
                                handleSortChangeBusiness(
                                  title,

                                  selectedSortIndex
                                )
                              }
                            >
                              <span
                                className="font-normal"
                                style={{
                                  textDecoration: "none",
                                  textTransform: "none",
                                }}
                              >
                                {index === 0 ? "A to Z" : "Z to A"}
                              </span>
                              <div className="relative left-[2px] flex">
                                {index === 0 ? (
                                  <svg
                                    width="20px"
                                    height="20px"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M12 4V20M12 20L8 16M12 20L16 16"
                                      stroke={filterIconCo̥lor}
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                ) : (
                                  <svg
                                    width="20px"
                                    height="20px"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M12 4V20M12 4L8 8M12 4L16 8"
                                      stroke={filterIconCo̥lor}
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                )}
                              </div>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                )}

              {businessTableDataObject.selectedSortIndex === 2 &&
                selectedSortDropDownOne?.id === 3 && (
                  <div className="dropdown-2-box-sub-2">
                    <div className="sub-dropdown-2">
                      {["newest on top", "oldest on top"].map(
                        (title, index) => {
                          return (
                            <div
                              className={`dropdown-2-item capitalize hover:text-white  ${
                                title === sortByBusiness &&
                                "dropdown-2-item-selected text-white"
                              }`}
                              onClick={() =>
                                handleSortChangeBusiness(
                                  title,
                                  selectedSortIndex
                                )
                              }
                            >
                              <span
                                className="font-normal"
                                style={{
                                  textDecoration: "none",
                                  textTransform: "none",
                                }}
                              >
                                {index === 0 ? "A to Z" : "Z to A"}
                              </span>
                              <div className="relative left-[2px] flex">
                                {index === 0 ? (
                                  <svg
                                    width="20px"
                                    height="20px"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M12 4V20M12 20L8 16M12 20L16 16"
                                      stroke={filterIconCo̥lor}
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                ) : (
                                  <svg
                                    width="20px"
                                    height="20px"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M12 4V20M12 4L8 8M12 4L16 8"
                                      stroke={filterIconCo̥lor}
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                )}
                              </div>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                )}

              {businessTableDataObject.selectedSortIndex === 3 &&
                selectedSortDropDownOne?.id === 3 && (
                  <div className="dropdown-2-box-sub-2">
                    <div className="sub-dropdown-2">
                      {["newest on top", "oldest on top"].map(
                        (title, index) => {
                          return (
                            <div
                              className={`dropdown-2-item capitalize hover:text-white  ${
                                title === sortByBusiness &&
                                "dropdown-2-item-selected text-white"
                              }`}
                              onClick={() =>
                                handleSortChangeBusiness(
                                  title,
                                  selectedSortIndex
                                )
                              }
                            >
                              <span
                                className="font-normal"
                                style={{
                                  textDecoration: "none",
                                  textTransform: "none",
                                }}
                              >
                                {index === 0 ? "A to Z" : "Z to A"}
                              </span>
                              <div className="relative left-[2px] flex">
                                {index === 0 ? (
                                  <svg
                                    width="20px"
                                    height="20px"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M12 4V20M12 20L8 16M12 20L16 16"
                                      stroke={filterIconCo̥lor}
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                ) : (
                                  <svg
                                    width="20px"
                                    height="20px"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M12 4V20M12 4L8 8M12 4L16 8"
                                      stroke={filterIconCo̥lor}
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                )}
                              </div>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                )}
            </div>
          </div>
        </div>
      )}
      {/* BUSINESS DROPDOWN */}
      {(type === "locations" || type === "providerLocations") && (
        <div className="dropdown-wrapper-main">
          <div className="associations-table-dropdown">
            <div className="dropdown-2-box">
              <div className="dropdown-2">
                <DropdownOptions
                  selectedItem={locationTableDataObject?.currentActive}
                  data={Data}
                  ref={inputRefLocation}
                  onFocus={handleFoucsInSearchOfAssociations}
                  inputTextValue={inputValueTextLocation}
                  onChange={handleInputChangeLocation}
                  clearSearch={handleClearSearchLocation}
                  onClick={handleItemClickDropdownOneDocuments}
                  selectedType={"locations"}
                />
              </div>

              {/* ///////////////////////////////////////////////////// FILTER OPTIONS ////////////////////////////////////////////////////////// */}
              {!!selectedFilterDropDownOne &&
              selectedFilterDropDownOne?.id === 1 ? (
                <div className="dropdown-2-box-sub">
                  <div className="sub-dropdown-2">
                    {["Name", "Email", "Phone", "Status"]?.map(
                      (elem, index) => (
                        <div
                          key={index}
                          className={`dropdown-2-item ${
                            locationTableDataObject.selectedItemIndex === index
                              ? "dropdown-2-item-selected"
                              : ""
                          }`}
                          onClick={() =>
                            handleClickAssociationsColumn(index, "locations")
                          }
                        >
                          <p>{elem}</p>
                        </div>
                      )
                    )}
                  </div>
                </div>
              ) : null}

              {/* ////////////////////////////////////////////////    FILTERING Business    ////////////////////////////////////////////////////////////////////// */}
              {locationTableDataObject.selectedItemIndex === 0 &&
                selectedFilterDropDownOne?.id === 1 && (
                  <div className="dropdown-2-box-sub-2">
                    <div className="sub-dropdown-2">
                      <Checkbox
                        isChecked={
                          selectedTitles?.length === LocationName?.length
                        }
                        className="dropdown-2-item dropdown-2-item-no-hover-effect w-full"
                        onChange={() => {
                          if (selectedTitles?.length === LocationName?.length) {
                            setSelectedTitles([]);
                          } else {
                            setSelectedTitles(LocationName);
                            // dispatch(getLocationUserThunk(dynamicLocationId));
                            dispatch(
                              setLocationFilterItems({
                                filterColumn: "",
                                filterValue: "",
                                searchVal: "",
                                sortColumn: "",
                                sortType: "",
                                status: "1",
                                dynamicId: dynamicLocationId,
                                LocationPage,
                              })
                            );
                          }
                        }}
                      >
                        (Select All)
                      </Checkbox>

                      {LocationName?.map((elem: any, index: any) => (
                        <div
                          className="dropdown-2-item dropdown-2-item-no-hover-effect"
                          // note here key forces re render
                          key={elem?.id}
                        >
                          <Checkbox
                            isChecked={selectedTitles.includes(elem)}
                            onChange={() =>
                              handleCheckboxChangeLocation(elem, "name", index)
                            }
                          >
                            {elem}
                          </Checkbox>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              {locationTableDataObject.selectedItemIndex === 1 &&
                selectedFilterDropDownOne?.id === 1 && (
                  <div className="dropdown-2-box-sub-2">
                    <div className="sub-dropdown-2">
                      <Checkbox
                        isChecked={
                          selectedTitles.length === LocationEmail.length
                        }
                        className="dropdown-2-item dropdown-2-item-no-hover-effect w-full"
                        onChange={() => {
                          if (selectedTitles.length === LocationEmail.length) {
                            setSelectedTitles([]);
                          } else {
                            setSelectedTitles(LocationEmail);
                            // dispatch(getLocationUserThunk(dynamicLocationId));
                            dispatch(
                              setLocationFilterItems({
                                filterColumn: "",
                                filterValue: "",
                                searchVal: "",
                                sortColumn: "",
                                sortType: "",
                                status: "1",
                                dynamicId: dynamicLocationId,
                                LocationPage,
                              })
                            );
                          }
                        }}
                      >
                        (Select All)
                      </Checkbox>

                      {LocationEmail?.map((elem: any, index: any) => (
                        <div
                          className="dropdown-2-item dropdown-2-item-no-hover-effect"
                          key={index}
                        >
                          {elem !== null && (
                            <Checkbox
                              onChange={() =>
                                handleCheckboxChangeLocation(
                                  elem,
                                  "email",
                                  index
                                )
                              }
                              isChecked={selectedTitles.includes(elem)}
                            >
                              {elem}
                            </Checkbox>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              {locationTableDataObject.selectedItemIndex === 2 &&
                selectedFilterDropDownOne?.id === 1 && (
                  <div className="dropdown-2-box-sub-2">
                    <div className="sub-dropdown-2">
                      <Checkbox
                        isChecked={
                          selectedTitles.length === LocationPhone.length
                        }
                        className="dropdown-2-item dropdown-2-item-no-hover-effect w-full"
                        onChange={() => {
                          if (selectedTitles.length === LocationPhone.length) {
                            setSelectedTitles([]);
                          } else {
                            setSelectedTitles(LocationPhone);
                            // dispatch(getLocationUserThunk(dynamicLocationId));
                            dispatch(
                              setLocationFilterItems({
                                filterColumn: "",
                                filterValue: "",
                                searchVal: "",
                                sortColumn: "",
                                sortType: "",
                                status: "1",
                                dynamicId: dynamicLocationId,
                                LocationPage,
                              })
                            );
                          }
                        }}
                      >
                        (Select All)
                      </Checkbox>

                      {LocationPhone?.map((elem: any, index: any) => (
                        <div
                          className="dropdown-2-item dropdown-2-item-no-hover-effect"
                          key={index}
                        >
                          {elem !== null && (
                            <Checkbox
                              isChecked={selectedTitles?.includes(elem)}
                              onChange={() =>
                                handleCheckboxChangeLocation(
                                  elem,
                                  "phone",
                                  index
                                )
                              }
                            >
                              {elem}
                            </Checkbox>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              {locationTableDataObject.selectedItemIndex === 3 &&
                selectedFilterDropDownOne?.id === 1 && (
                  <div className="dropdown-2-box-sub-2">
                    <div className="sub-dropdown-2">
                      <Checkbox
                        isChecked={
                          selectedTitles.length ===
                          statusMappingForBusinessAndTeams.length
                        }
                        className="dropdown-2-item dropdown-2-item-no-hover-effect w-full"
                        onChange={() => {
                          if (
                            selectedTitles.length ===
                            statusMappingForBusinessAndTeams.length
                          ) {
                            setSelectedTitles([]);
                            // dispatch(getLocationUserThunk(dynamicLocationId));
                            dispatch(
                              setLocationFilterItems({
                                filterColumn: "",
                                filterValue: "",
                                searchVal: "",
                                sortColumn: "",
                                sortType: "",
                                status: "1",
                                dynamicId: dynamicLocationId,
                                LocationPage,
                              })
                            );
                          } else {
                            setSelectedTitles(statusMappingForBusinessAndTeams);
                            // dispatch(getBusinessThunk(dynamicBusinessId));
                            // dispatch(
                            //   getLocationUserByFilterThunk({
                            //     filterColumn: "",
                            //     filterValue: "",
                            //     searchVal: "",
                            //     sortColumn: "",
                            //     sortType: "",
                            //     status: "0,1",
                            //     dynamicId: dynamicLocationId,
                            //     LocationPage
                            //   })
                            // );
                            dispatch(
                              setLocationFilterItems({
                                filterColumn: "",
                                filterValue: "",
                                searchVal: "",
                                sortColumn: "",
                                sortType: "",
                                status: "0,1",
                                dynamicId: dynamicLocationId,
                                LocationPage,
                              })
                            );
                          }
                        }}
                      >
                        (Select All)
                      </Checkbox>
                      {statusMappingForBusinessAndTeams?.map(
                        (elem: any, index: any) => (
                          <div
                            className="dropdown-2-item dropdown-2-item-no-hover-effect"
                            key={index}
                          >
                            {elem !== null && (
                              <Checkbox
                                isChecked={selectedTitles?.includes(elem)}
                                onChange={() =>
                                  handleCheckboxChangeLocation(
                                    elem,
                                    "status",
                                    index
                                  )
                                }
                              >
                                {elem}
                              </Checkbox>
                            )}
                          </div>
                        )
                      )}
                    </div>
                  </div>
                )}

              {/*  /////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
              {/*  selectedSortDropDownOne?.id === 3 */}
              {/* SORT OPTIONS */}
              {!!selectedSortDropDownOne &&
              locationTableDataObject.currentActive === "sort" ? (
                <div className="dropdown-2-box-sub">
                  <div className="sub-dropdown-2">
                    {["Name", "Email", "Phone", "Status"]?.map(
                      (elem: any, index: number) => (
                        <div
                          key={index}
                          className={`dropdown-2-item ${
                            locationTableDataObject.selectedSortIndex === index
                              ? "dropdown-2-item-selected"
                              : ""
                          }`}
                          onClick={() =>
                            handleOpenSortOptionsOnBasisOfIndexOfColumn(
                              index,
                              "locations"
                            )
                          }
                        >
                          <p>{elem}</p>
                        </div>
                      )
                    )}
                  </div>
                </div>
              ) : null}

              {/* SORT OPTIONS ITEMS */}

              {locationTableDataObject.selectedSortIndex === 0 &&
                selectedSortDropDownOne?.id === 3 && (
                  <div className="dropdown-2-box-sub-2">
                    <div className="sub-dropdown-2">
                      {["newest on top", "oldest on top"].map(
                        (title, index) => {
                          return (
                            <div
                              className={`dropdown-2-item capitalize hover:text-white  ${
                                title === sortByLocation &&
                                "dropdown-2-item-selected text-white"
                              }`}
                              onClick={() =>
                                handleSortChangeLocation(
                                  title,
                                  selectedSortIndex
                                )
                              }
                            >
                              <span
                                className="font-normal"
                                style={{
                                  textDecoration: "none",
                                  textTransform: "none",
                                }}
                              >
                                {index === 0 ? "A to Z" : "Z to A"}
                              </span>
                              <div className="relative left-[2px] flex">
                                {index === 0 ? (
                                  <svg
                                    width="20px"
                                    height="20px"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M12 4V20M12 20L8 16M12 20L16 16"
                                      stroke={filterIconCo̥lor}
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                ) : (
                                  <svg
                                    width="20px"
                                    height="20px"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M12 4V20M12 4L8 8M12 4L16 8"
                                      stroke={filterIconCo̥lor}
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                )}
                              </div>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                )}

              {locationTableDataObject.selectedSortIndex === 1 &&
                selectedSortDropDownOne?.id === 3 && (
                  <div className="dropdown-2-box-sub-2">
                    <div className="sub-dropdown-2">
                      {["newest on top", "oldest on top"].map(
                        (title, index) => {
                          return (
                            <div
                              className={`dropdown-2-item capitalize hover:text-white  ${
                                title === sortByLocation &&
                                "dropdown-2-item-selected text-white"
                              }`}
                              onClick={() =>
                                handleSortChangeLocation(
                                  title,
                                  selectedSortIndex
                                )
                              }
                            >
                              <span
                                className="font-normal"
                                style={{
                                  textDecoration: "none",
                                  textTransform: "none",
                                }}
                              >
                                {index === 0 ? "A to Z" : "Z to A"}
                              </span>
                              <div className="relative left-[2px] flex">
                                {index === 0 ? (
                                  <svg
                                    width="20px"
                                    height="20px"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M12 4V20M12 20L8 16M12 20L16 16"
                                      stroke={filterIconCo̥lor}
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                ) : (
                                  <svg
                                    width="20px"
                                    height="20px"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M12 4V20M12 4L8 8M12 4L16 8"
                                      stroke={filterIconCo̥lor}
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                )}
                              </div>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                )}

              {locationTableDataObject.selectedSortIndex === 2 &&
                selectedSortDropDownOne?.id === 3 && (
                  <div className="dropdown-2-box-sub-2">
                    <div className="sub-dropdown-2">
                      {["newest on top", "oldest on top"].map(
                        (title, index) => {
                          return (
                            <div
                              className={`dropdown-2-item capitalize hover:text-white  ${
                                title === sortByLocation &&
                                "dropdown-2-item-selected text-white"
                              }`}
                              onClick={() =>
                                handleSortChangeLocation(
                                  title,
                                  selectedSortIndex
                                )
                              }
                            >
                              <span
                                className="font-normal"
                                style={{
                                  textDecoration: "none",
                                  textTransform: "none",
                                }}
                              >
                                {index === 0 ? "A to Z" : "Z to A"}
                              </span>
                              <div className="relative left-[2px] flex">
                                {index === 0 ? (
                                  <svg
                                    width="20px"
                                    height="20px"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M12 4V20M12 20L8 16M12 20L16 16"
                                      stroke={filterIconCo̥lor}
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                ) : (
                                  <svg
                                    width="20px"
                                    height="20px"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M12 4V20M12 4L8 8M12 4L16 8"
                                      stroke={filterIconCo̥lor}
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                )}
                              </div>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                )}

              {locationTableDataObject.selectedSortIndex === 3 &&
                selectedSortDropDownOne?.id === 3 && (
                  <div className="dropdown-2-box-sub-2">
                    <div className="sub-dropdown-2">
                      {["newest on top", "oldest on top"].map(
                        (title, index) => {
                          return (
                            <div
                              className={`dropdown-2-item capitalize hover:text-white  ${
                                title === sortByLocation &&
                                "dropdown-2-item-selected text-white"
                              }`}
                              onClick={() =>
                                handleSortChangeLocation(
                                  title,
                                  selectedSortIndex
                                )
                              }
                            >
                              <span
                                className="font-normal"
                                style={{
                                  textDecoration: "none",
                                  textTransform: "none",
                                }}
                              >
                                {index === 0 ? "A to Z" : "Z to A"}
                              </span>
                              <div className="relative left-[2px] flex">
                                {index === 0 ? (
                                  <svg
                                    width="20px"
                                    height="20px"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M12 4V20M12 20L8 16M12 20L16 16"
                                      stroke={filterIconCo̥lor}
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                ) : (
                                  <svg
                                    width="20px"
                                    height="20px"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M12 4V20M12 4L8 8M12 4L16 8"
                                      stroke={filterIconCo̥lor}
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                )}
                              </div>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                )}
            </div>
          </div>
        </div>
      )}
      {/* TEAMS DROPDOWN */}
      {(type === "teams" || type === "providerTeams") && (
        <div className="dropdown-wrapper-main">
          <div className="associations-table-dropdown">
            <div className="dropdown-2-box">
              <div className="dropdown-2">
                <DropdownOptions
                  selectedItem={teamsTableDataObject.currentActive}
                  data={Data}
                  ref={inputRefTeams}
                  onFocus={handleFoucsInSearchOfAssociations}
                  inputTextValue={inputValueTextTeams}
                  onChange={handleInputChangeTeams}
                  clearSearch={handleClearSearchTeams}
                  onClick={handleItemClickDropdownOneDocuments}
                  selectedType={"teams"}
                />
              </div>

              {/* ///////////////////////////////////////////////////// FILTER OPTIONS ////////////////////////////////////////////////////////// */}
              {!!selectedFilterDropDownOne &&
              selectedFilterDropDownOne?.id === 1 ? (
                <div className="dropdown-2-box-sub">
                  <div className="sub-dropdown-2">
                    {[
                      "Name",
                      "Email",
                      "Phone",
                      "Associate Business",
                      "Status",
                    ]?.map((elem, index) => (
                      <div
                        key={index}
                        className={`dropdown-2-item ${
                          teamsTableDataObject.selectedItemIndex === index
                            ? "dropdown-2-item-selected"
                            : ""
                        }`}
                        onClick={() =>
                          handleClickAssociationsColumn(index, "teams")
                        }
                      >
                        <p>{elem}</p>
                      </div>
                    ))}
                  </div>
                </div>
              ) : null}

              {/* ////////////////////////////////////////////////    FILTERING AUDIT    ////////////////////////////////////////////////////////////////////// */}
              {teamsTableDataObject.selectedItemIndex === 0 &&
                selectedFilterDropDownOne?.id === 1 && (
                  <div className="dropdown-2-box-sub-2">
                    <div className="sub-dropdown-2">
                      <Checkbox
                        isChecked={selectedTitles?.length === TeamsName?.length}
                        className="dropdown-2-item dropdown-2-item-no-hover-effect w-full"
                        onChange={() => {
                          if (selectedTitles?.length === TeamsName.length) {
                            setSelectedTitles([]);
                          } else {
                            setSelectedTitles(TeamsName);
                            // dispatch(getTeamsThunk(dynamicTeamsId));
                            dispatch(
                              setTeamsFilterItems({
                                filterColumn: "",
                                filterValue: "",
                                searchVal: "",
                                sortColumn: "",
                                sortType: "",
                                status: "1",
                                dynamicId: dynamicTeamsId,
                                TeamsPage,
                              })
                            );
                          }
                        }}
                      >
                        (Select All)
                      </Checkbox>

                      {TeamsName?.map((elem: any, index: any) => (
                        <div
                          className="dropdown-2-item dropdown-2-item-no-hover-effect"
                          // note here key forces re render
                          key={elem?.id}
                        >
                          <Checkbox
                            isChecked={selectedTitles.includes(elem)}
                            onChange={() =>
                              handleCheckboxChangeTeams(elem, "name", index)
                            }
                          >
                            {elem}
                          </Checkbox>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              {teamsTableDataObject.selectedItemIndex === 1 &&
                selectedFilterDropDownOne?.id === 1 && (
                  <div className="dropdown-2-box-sub-2">
                    <div className="sub-dropdown-2">
                      <Checkbox
                        isChecked={selectedTitles.length === TeamsEmail.length}
                        className="dropdown-2-item dropdown-2-item-no-hover-effect w-full"
                        onChange={() => {
                          if (selectedTitles.length === TeamsEmail.length) {
                            setSelectedTitles([]);
                          } else {
                            setSelectedTitles(TeamsEmail);
                            // dispatch(getTeamsThunk(dynamicTeamsId));
                            dispatch(
                              setTeamsFilterItems({
                                filterColumn: "",
                                filterValue: "",
                                searchVal: "",
                                sortColumn: "",
                                sortType: "",
                                status: "1",
                                dynamicId: dynamicTeamsId,
                                TeamsPage,
                              })
                            );
                          }
                        }}
                      >
                        (Select All)
                      </Checkbox>

                      {TeamsEmail?.map((elem: any, index: any) => (
                        <div
                          className="dropdown-2-item dropdown-2-item-no-hover-effect"
                          key={index}
                        >
                          {elem !== null && (
                            <Checkbox
                              onChange={() =>
                                handleCheckboxChangeTeams(elem, "email", index)
                              }
                              isChecked={selectedTitles.includes(elem)}
                            >
                              {elem}
                            </Checkbox>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              {teamsTableDataObject.selectedItemIndex === 2 &&
                selectedFilterDropDownOne?.id === 1 && (
                  <div className="dropdown-2-box-sub-2">
                    <div className="sub-dropdown-2">
                      <Checkbox
                        isChecked={selectedTitles.length === TeamsPhone.length}
                        className="dropdown-2-item dropdown-2-item-no-hover-effect w-full"
                        onChange={() => {
                          if (selectedTitles.length === TeamsPhone.length) {
                            setSelectedTitles([]);
                          } else {
                            setSelectedTitles(TeamsPhone);
                            // dispatch(getTeamsThunk(dynamicTeamsId));
                            dispatch(
                              setTeamsFilterItems({
                                filterColumn: "",
                                filterValue: "",
                                searchVal: "",
                                sortColumn: "",
                                sortType: "",
                                status: "1",
                                dynamicId: dynamicTeamsId,
                                TeamsPage,
                              })
                            );
                          }
                        }}
                      >
                        (Select All)
                      </Checkbox>

                      {TeamsPhone.map((elem: any, index: any) => (
                        <div
                          className="dropdown-2-item dropdown-2-item-no-hover-effect"
                          key={index}
                        >
                          {elem !== null && (
                            <Checkbox
                              isChecked={selectedTitles?.includes(elem)}
                              onChange={() =>
                                handleCheckboxChangeTeams(elem, "phone", index)
                              }
                            >
                              {elem}
                            </Checkbox>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              {teamsTableDataObject.selectedItemIndex === 3 &&
                selectedFilterDropDownOne?.id === 1 && (
                  <div className="dropdown-2-box-sub-2">
                    <div className="sub-dropdown-2">
                      <Checkbox
                        isChecked={
                          selectedTitles.length === teamsBusinessName.length
                        }
                        className="dropdown-2-item dropdown-2-item-no-hover-effect w-full"
                        onChange={() => {
                          if (
                            selectedTitles.length === teamsBusinessName.length
                          ) {
                            setSelectedTitles([]);
                          } else {
                            setSelectedTitles(teamsBusinessName);
                            // dispatch(getTeamsThunk(dynamicTeamsId));
                            dispatch(
                              setTeamsFilterItems({
                                filterColumn: "",
                                filterValue: "",
                                searchVal: "",
                                sortColumn: "",
                                sortType: "",
                                status: "1",
                                dynamicId: dynamicTeamsId,
                                TeamsPage,
                              })
                            );
                          }
                        }}
                      >
                        (Select All)
                      </Checkbox>

                      {teamsBusinessName.map((elem: any, index: any) => (
                        <div
                          className="dropdown-2-item dropdown-2-item-no-hover-effect"
                          key={index}
                        >
                          {elem !== null && (
                            <Checkbox
                              isChecked={selectedTitles?.includes(elem)}
                              onChange={() =>
                                handleCheckboxChangeTeams(
                                  elem,
                                  "businessAssociate",
                                  index
                                )
                              }
                            >
                              {elem}
                            </Checkbox>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              {teamsTableDataObject.selectedItemIndex === 4 &&
                selectedFilterDropDownOne?.id === 1 && (
                  <div className="dropdown-2-box-sub-2">
                    <div className="sub-dropdown-2">
                      <Checkbox
                        isChecked={
                          selectedTitles.length ===
                          statusMappingForBusinessAndTeams.length
                        }
                        className="dropdown-2-item dropdown-2-item-no-hover-effect w-full"
                        onChange={() => {
                          if (
                            selectedTitles.length ===
                            statusMappingForBusinessAndTeams.length
                          ) {
                            setSelectedTitles([]);
                            // dispatch(getTeamsThunk(dynamicTeamsId));
                            dispatch(
                              setTeamsFilterItems({
                                filterColumn: "",
                                filterValue: "",
                                searchVal: "",
                                sortColumn: "",
                                sortType: "",
                                status: "1",
                                dynamicId: dynamicTeamsId,
                                TeamsPage,
                              })
                            );
                          } else {
                            setSelectedTitles(statusMappingForBusinessAndTeams);
                            // dispatch(
                            //   getTeamsByFilterThunk({
                            //     filterColumn: "",
                            //     filterValue: "",
                            //     searchVal: "",
                            //     sortColumn: "",
                            //     sortType: "",
                            //     status: "0,1",
                            //     dynamicId: dynamicTeamsId,
                            //     TeamsPage
                            //   })
                            // );
                            dispatch(
                              setTeamsFilterItems({
                                filterColumn: "",
                                filterValue: "",
                                searchVal: "",
                                sortColumn: "",
                                sortType: "",
                                status: "0,1",
                                dynamicId: dynamicTeamsId,
                                TeamsPage,
                              })
                            );
                          }
                        }}
                      >
                        (Select All)
                      </Checkbox>
                      {statusMappingForBusinessAndTeams?.map(
                        (elem: any, index: any) => (
                          <div
                            className="dropdown-2-item dropdown-2-item-no-hover-effect"
                            key={index}
                          >
                            {elem !== null && (
                              <Checkbox
                                isChecked={selectedTitles?.includes(elem)}
                                onChange={() =>
                                  handleCheckboxChangeTeams(
                                    elem,
                                    "status",
                                    index
                                  )
                                }
                              >
                                {elem}
                              </Checkbox>
                            )}
                          </div>
                        )
                      )}
                    </div>
                  </div>
                )}

              {/*  /////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
              {/*   selectedSortDropDownOne?.id === 3 */}
              {/* SORT OPTIONS */}
              {!!selectedSortDropDownOne &&
              teamsTableDataObject.currentActive === "sort" ? (
                <div className="dropdown-2-box-sub">
                  <div className="sub-dropdown-2">
                    {[
                      "Name",
                      "Email",
                      "Phone",
                      "Associate Business",
                      "Status",
                    ]?.map((elem: any, index: number) => (
                      <div
                        key={index}
                        className={`dropdown-2-item ${
                          teamsTableDataObject.selectedSortIndex === index
                            ? "dropdown-2-item-selected"
                            : ""
                        }`}
                        onClick={() =>
                          handleOpenSortOptionsOnBasisOfIndexOfColumn(
                            index,
                            "teams"
                          )
                        }
                      >
                        <p>{elem}</p>
                      </div>
                    ))}
                  </div>
                </div>
              ) : null}

              {/* SORT OPTIONS ITEMS */}

              {teamsTableDataObject.selectedSortIndex === 0 &&
                selectedSortDropDownOne?.id === 3 && (
                  <div className="dropdown-2-box-sub-2">
                    <div className="sub-dropdown-2">
                      {["newest on top", "oldest on top"].map(
                        (title, index) => {
                          return (
                            <div
                              className={`dropdown-2-item capitalize hover:text-white  ${
                                title === sortByTeams &&
                                "dropdown-2-item-selected text-white"
                              }`}
                              onClick={() =>
                                handleSortChangeTeams(title, selectedSortIndex)
                              }
                            >
                              <span
                                className="font-normal"
                                style={{
                                  textDecoration: "none",
                                  textTransform: "none",
                                }}
                              >
                                {index === 0 ? "A to Z" : "Z to A"}
                              </span>
                              <div className="relative left-[2px] flex">
                                {index === 0 ? (
                                  <svg
                                    width="20px"
                                    height="20px"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M12 4V20M12 20L8 16M12 20L16 16"
                                      stroke={filterIconCo̥lor}
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                ) : (
                                  <svg
                                    width="20px"
                                    height="20px"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M12 4V20M12 4L8 8M12 4L16 8"
                                      stroke={filterIconCo̥lor}
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                )}
                              </div>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                )}

              {teamsTableDataObject.selectedSortIndex === 1 &&
                selectedSortDropDownOne?.id === 3 && (
                  <div className="dropdown-2-box-sub-2">
                    <div className="sub-dropdown-2">
                      {["newest on top", "oldest on top"].map(
                        (title, index) => {
                          return (
                            <div
                              className={`dropdown-2-item capitalize hover:text-white  ${
                                title === sortByTeams &&
                                "dropdown-2-item-selected text-white"
                              }`}
                              onClick={() =>
                                handleSortChangeTeams(
                                  title,

                                  selectedSortIndex
                                )
                              }
                            >
                              <span
                                className="font-normal"
                                style={{
                                  textDecoration: "none",
                                  textTransform: "none",
                                }}
                              >
                                {index === 0 ? "A to Z" : "Z to A"}
                              </span>
                              <div className="relative left-[2px] flex">
                                {index === 0 ? (
                                  <svg
                                    width="20px"
                                    height="20px"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M12 4V20M12 20L8 16M12 20L16 16"
                                      stroke={filterIconCo̥lor}
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                ) : (
                                  <svg
                                    width="20px"
                                    height="20px"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M12 4V20M12 4L8 8M12 4L16 8"
                                      stroke={filterIconCo̥lor}
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                )}
                              </div>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                )}

              {teamsTableDataObject.selectedSortIndex === 2 &&
                selectedSortDropDownOne?.id === 3 && (
                  <div className="dropdown-2-box-sub-2">
                    <div className="sub-dropdown-2">
                      {["newest on top", "oldest on top"].map(
                        (title, index) => {
                          return (
                            <div
                              className={`dropdown-2-item capitalize hover:text-white  ${
                                title === sortByTeams &&
                                "dropdown-2-item-selected text-white"
                              }`}
                              onClick={() =>
                                handleSortChangeTeams(title, selectedSortIndex)
                              }
                            >
                              <span
                                className="font-normal"
                                style={{
                                  textDecoration: "none",
                                  textTransform: "none",
                                }}
                              >
                                {index === 0 ? "A to Z" : "Z to A"}
                              </span>
                              <div className="relative left-[2px] flex">
                                {index === 0 ? (
                                  <svg
                                    width="20px"
                                    height="20px"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M12 4V20M12 20L8 16M12 20L16 16"
                                      stroke={filterIconCo̥lor}
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                ) : (
                                  <svg
                                    width="20px"
                                    height="20px"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M12 4V20M12 4L8 8M12 4L16 8"
                                      stroke={filterIconCo̥lor}
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                )}
                              </div>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                )}

              {teamsTableDataObject.selectedSortIndex === 3 &&
                selectedSortDropDownOne?.id === 3 && (
                  <div className="dropdown-2-box-sub-2">
                    <div className="sub-dropdown-2">
                      {["newest on top", "oldest on top"].map(
                        (title, index) => {
                          return (
                            <div
                              className={`dropdown-2-item capitalize hover:text-white  ${
                                title === sortByTeams &&
                                "dropdown-2-item-selected text-white"
                              }`}
                              onClick={() =>
                                handleSortChangeTeams(title, selectedSortIndex)
                              }
                            >
                              <span
                                className="font-normal"
                                style={{
                                  textDecoration: "none",
                                  textTransform: "none",
                                }}
                              >
                                {index === 0 ? "A to Z" : "Z to A"}
                              </span>
                              <div className="relative left-[2px] flex">
                                {index === 0 ? (
                                  <svg
                                    width="20px"
                                    height="20px"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M12 4V20M12 20L8 16M12 20L16 16"
                                      stroke={filterIconCo̥lor}
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                ) : (
                                  <svg
                                    width="20px"
                                    height="20px"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M12 4V20M12 4L8 8M12 4L16 8"
                                      stroke={filterIconCo̥lor}
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                )}
                              </div>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                )}
              {teamsTableDataObject.selectedSortIndex === 4 &&
                selectedSortDropDownOne?.id === 3 && (
                  <div className="dropdown-2-box-sub-2">
                    <div className="sub-dropdown-2">
                      {["newest on top", "oldest on top"].map(
                        (title, index) => {
                          return (
                            <div
                              className={`dropdown-2-item capitalize hover:text-white  ${
                                title === sortByTeams &&
                                "dropdown-2-item-selected text-white"
                              }`}
                              onClick={() =>
                                handleSortChangeTeams(title, selectedSortIndex)
                              }
                            >
                              <span
                                className="font-normal"
                                style={{
                                  textDecoration: "none",
                                  textTransform: "none",
                                }}
                              >
                                {index === 0 ? "A to Z" : "Z to A"}
                              </span>
                              <div className="relative left-[2px] flex">
                                {index === 0 ? (
                                  <svg
                                    width="20px"
                                    height="20px"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M12 4V20M12 20L8 16M12 20L16 16"
                                      stroke={filterIconCo̥lor}
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                ) : (
                                  <svg
                                    width="20px"
                                    height="20px"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M12 4V20M12 4L8 8M12 4L16 8"
                                      stroke={filterIconCo̥lor}
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                )}
                              </div>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                )}
            </div>
          </div>
        </div>
      )}
      {type === "expenseType" && <ExpenseTypeFilter />}
      {type === "fundingType" && <FundingTypeFilter />}
      {type === "billingRule" && <BillingRuleFilter />}
      {type === "payRule" && <PayRuleFilter />}
      {type === "form" && <FormFilter />}
    </>
  );
};
export default TableDropdown2;
