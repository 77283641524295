import { Fragment, useEffect, useRef, useState } from "react";

import {
  getAuditActivityTypeThunk,
  getAuditByFilterThunk,
  getAuditByProviderFilterThunk,
  getAuditTableNameThunk,
  getAuditUsernameThunk,
  resetAuditData,
  resetAuditPageFilter,
  setAuditPageFilter,
  setAuditRowId,
} from "../../../Controller/redux/features/AuditFeature/auditSlice";

import CircularNoteTrigger from "../../components/CircularNoteTrigger/CircularNoteTrigger";
import CommonUpperBox from "../../components/UpperBox/CommonHeader";
import CommonQuickMenu from "../../components/CommonQuickMenu/CommonQuickMenu";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../Controller/redux/Hooks";
import Table from "../../components/CommonTable/Table";
import { Modal, ModalOverlay, Spinner } from "@chakra-ui/react";
import AuditModal from "./AuditModal";

const Audit = ({ isFromProvidersAudit, currentProvider }: any) => {
  const dispatch = useAppDispatch();
  const AuditInnerRef = useRef();
  const svgParentRefAudit = useRef<any>();
  const tableRef = useRef();
  const dropdownRefAudit = useRef<any>();
  const {
    getAuditData,
    AuditPageFilter,
    AuditFilterItems,
    auditDataProviders,
    audits,
    loading,
  } = useAppSelector((state) => state?.audit);

  // console.log(currentProvider,'currentProvider')

  const [isAuditModal, setIsAuditModal] = useState<boolean>(false);
  const [isopenDropdownAudit, setisopenDropdownAudit] = useState(false);

  useEffect(() => {
    dispatch(resetAuditData());
    dispatch(resetAuditPageFilter());
  }, [
    AuditFilterItems?.searchVal,
    AuditFilterItems?.filterValue,
    AuditFilterItems?.sortType,
  ]);

  useEffect(() => {
    dispatch(getAuditUsernameThunk());
    dispatch(getAuditActivityTypeThunk());
    dispatch(getAuditTableNameThunk());
  }, []);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    if (!isFromProvidersAudit) {
      dispatch(
        getAuditByFilterThunk({
          filterColumn: AuditFilterItems?.filterColumn
            ? AuditFilterItems?.filterColumn
            : "",
          filterValue: AuditFilterItems?.filterValue
            ? AuditFilterItems?.filterValue
            : "",
          searchVal: AuditFilterItems?.searchVal
            ? AuditFilterItems?.searchVal
            : "",
          sortColumn: AuditFilterItems?.sortColumn
            ? AuditFilterItems?.sortColumn
            : "",
          sortType: AuditFilterItems?.sortType
            ? AuditFilterItems?.sortType
            : "",
          auditPageFilter: AuditPageFilter,
          isProviderAudit: false,
        })
      ).then((res: any) => setIsLoading(false));
    } else if (currentProvider?.id) {
      dispatch(
        getAuditByProviderFilterThunk({
          filterColumn: AuditFilterItems?.filterColumn
            ? AuditFilterItems?.filterColumn
            : "",
          filterValue: AuditFilterItems?.filterValue
            ? AuditFilterItems?.filterValue
            : "",
          searchVal: AuditFilterItems?.searchVal
            ? AuditFilterItems?.searchVal
            : "",
          sortColumn: AuditFilterItems?.sortColumn
            ? AuditFilterItems?.sortColumn
            : "",
          sortType: AuditFilterItems?.sortType
            ? AuditFilterItems?.sortType
            : "",
          auditPageFilter: AuditPageFilter,
          isProviderAudit: true,
          tenant_id: currentProvider?.id,
        })
      ).then((res: any) => setIsLoading(false));
    }
  }, [
    AuditPageFilter,
    AuditFilterItems?.searchVal,
    AuditFilterItems?.filterValue,
    AuditFilterItems?.sortType,
    isFromProvidersAudit,
    currentProvider,
  ]);

  // console.log(isLoading,'isLOADING__')

  const onScroll = () => {
    if (AuditInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = AuditInnerRef.current;

      if (scrollTop + clientHeight + +5 >= scrollHeight) {
        dispatch(setAuditPageFilter(AuditPageFilter));
      }
    }
  };

  const [stageTwoToGoalsModal, setstageTwoToGoalsModal] = useState(false);
  const { selectedFilterItemsAudit, sortByAudits, searchValuesAudit } =
    useAppSelector((state) => state?.audit);

  const { filterIconColor } = useAppSelector((state) => state?.common);
  const columns1 = [
    { header: "Created At", accessor: "created_at" },
    { header: "Username", accessor: "username" },
    { header: "Description", accessor: "desc" },
    { header: "Activity Type", accessor: "activity_type" },
    { header: "Table Name", accessor: "table_name" },
    { header: "IP Address", accessor: "ip_address" },
  ];
  const columns2 = [
    { header: "Created At", accessor: "created_at" },
    { header: "Run Date", accessor: "run_date" },
    { header: "Description", accessor: "change_description" },
    { header: "UUID", accessor: "UUID" },

    {
      header: "System Vendor",
      accessor: "system_vendor",
      last_col_providers: true,
    },
  ];

  useEffect(() => {
    const handleClickOutsideAudit = (event: any) => {
      if (
        dropdownRefAudit.current &&
        !dropdownRefAudit.current.contains(event.target) &&
        svgParentRefAudit.current &&
        !svgParentRefAudit.current.contains(event.target)
      ) {
        setisopenDropdownAudit(false);
      }
    };
    document.addEventListener("click", handleClickOutsideAudit);
    return () => {
      document.removeEventListener("click", handleClickOutsideAudit);
    };
  }, []);

  const handleClickRow = (e: any, id: any) => {
    setIsAuditModal(true);
    dispatch(setAuditRowId(id));
  };
  const { domainDetail } = useAppSelector((state) => state?.common);
  const uniqueAuditData = audits?.filter(
    (item: any, index: number, array: any) => {
      return array.findIndex((obj: any) => obj.id === item.id) === index;
    }
  );
  const uniqueAuditDataProviders = auditDataProviders?.filter(
    (item: any, index: number, array: any) => {
      return array.findIndex((obj: any) => obj.id === item.id) === index;
    }
  );

  // console.log(auditDataProviders,'auditDataProviders')
  return (
    <Fragment>
      {!isFromProvidersAudit && (
        <>
          <CommonUpperBox
            title="Audit"
            type={"listing"}
            onClickPlus={() => {
              setstageTwoToGoalsModal(true);
            }}
          />
        </>
      )}

      {isFromProvidersAudit ? (
        <Table
          isLoading={isLoading}
          tableRef={tableRef}
          tableBodyRef={AuditInnerRef}
          dynamicHeaderColor={domainDetail?.listingtbl_color}
          itemsArr={[]}
          statusType="prospectsScreen"
          thData={columns2}
          classNameTr={` all-prospects-table-head `}
           type="audit"
          dropdownRef={dropdownRefAudit}
          isDropdownOpen={isopenDropdownAudit}
          onClickSvg={() => setisopenDropdownAudit(true)}
          svgParentRef={svgParentRefAudit}
          allClients={uniqueAuditDataProviders}
          onClickRow={handleClickRow}
          classNameRow={`all-prospects-table-body`}
          fill={"#D8D3D3"}
        />
      ) : (
        <Table
          isLoading={isLoading}
          dynamicHeaderColor={domainDetail?.listingtbl_color}
          thData={columns1}
          classNameTr={"all-audit-table-head"}
          type="audit"
          dropdownRef={dropdownRefAudit}
          isDropdownOpen={isopenDropdownAudit}
          onClickSvg={() => setisopenDropdownAudit(true)}
          svgParentRef={svgParentRefAudit}
          allClients={
            isFromProvidersAudit ? uniqueAuditDataProviders : uniqueAuditData
          }
          onScroll={onScroll}
          tableRef={tableRef}
          tableBodyRef={AuditInnerRef}
          onClickRow={handleClickRow}
          classNameRow="all-audit-table-body"
          fill={
            selectedFilterItemsAudit.length > 0 ||
            searchValuesAudit.length > 0 ||
            sortByAudits.length > 0
              ? filterIconColor
              : "#D8D3D3"
          }
        />
      )}
      <CircularNoteTrigger
        type="stickyHub"
        title="Comms"
        isOutside
        extraType="globalCommsBox"
      />
      {stageTwoToGoalsModal && (
        <CommonQuickMenu
          setshowGoalsModal={(value: any) => {}}
          mainType={"listing"}
          setstageTwoToGoalsModal={setstageTwoToGoalsModal}
        />
      )}

      {isAuditModal && (
        <Modal
          isOpen={true}
          onClose={() => {}}
          closeOnOverlayClick={true}
          isCentered
        >
          <ModalOverlay onClick={() => {}} zIndex={10} />

          <AuditModal setIsAuditModal={setIsAuditModal} />
        </Modal>
      )}
    </Fragment>
  );
};

export default Audit;
