import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../../services/api";

const initialState: any = {
  updateClient: null,
  loading: false,
  error: null,
};

export const updateClientThunk = createAsyncThunk(
  "clients/update",
  async (clientData: any, { rejectWithValue }) => {
    try {
      const response = await api.post("/users/update_user", clientData);
      return response.data;
    } catch (error: string | any) {
      return rejectWithValue(
        error.message || "Failed to upload client details"
      );
    }
  }
);

const detailsTypeSlice = createSlice({
  name: "details",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(updateClientThunk.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(updateClientThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.updateClient = action.payload;
    });

    builder.addCase(updateClientThunk.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload
        ? action.payload
        : "Failed to upload client details";
    });
  },
});

export default detailsTypeSlice.reducer;
