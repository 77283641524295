import React, {
  useEffect,
  useRef,
  useState,
} from "react";
import CommonUpperBox from "../../../../../components/UpperBox/CommonHeader";
import CommonHeader from "../../../../../components/CommonHeader/CommonHeader";
import DropdownIcon from "../../../../../../Model/Assets/png/drop.png";
import { dummyArrTeams } from "../../../ClientPage/Components/UniqueClientScreen/Data/ClientScreenContentArr";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../Controller/redux/Hooks";
import { useNavigate, useParams } from "react-router-dom";
import {
  clearCurrentTeamsData,
  getProviderTeamsByIdThunk,
  getTeamsByIdThunk,
  updateTeamsThunk,
} from "../../../../../../Controller/redux/features/TeamsFeature/teamsSlice";
import { toast } from "react-toastify";
import { resetOpenFormByClickTeams } from "../../../../../../Controller/redux/features/TeamsFeature/documentTeamsSlice";
import ConfirmationModal from "../../../../../components/ConfirmationModal/ConfirmationModal";
import UnsavedChangesPopup from "../../../../../components/UnsavedChangesPopup/UnsavedChangesPopup";
import { Modal, ModalOverlay, Spinner } from "@chakra-ui/react";
import ClientAssessmentForm from "../../../ClientPage/Components/UniqueClientScreen/Components/DocumentsTab/Components/ClientAssessmentForm";
import {
  createDocumentThunk,
  getDocumentsByFilterForEntity,
  getDocumentsEntity,
  getUniquePrimaryApproversForEntityThunk,
  getUniquePrimaryApproversThunk,
  resetIsDocumentsDropdownOpen,
  resetSelectedDropdownItemDocuments,
  resetSelectedFilterItemsDocuments,
  resetSelectedItemIndexFilterDocuments,
  resetinputSearchVal,
  setIsFormOpenTrue,
  setWarningPopup,
  setselectedDataObject,
  updateDocumentThunk,
} from "../../../../../../Controller/redux/features/CommonFeature/documentSlice";
import {
  getAssociationRelationshipsThunk,
  getAssociationTypesThunk,
  getAssociationsForEntity,
  resetSelectedDropdownItemAssociations,
  resetSelectedFilterItemsAssociations,
  resetSelectedItemIndexFilterAssociations,
  resetselectedItemIndexAssociations,
  resetselectedNames,
} from "../../../../../../Controller/redux/features/CommonFeature/associationSlice";
import { Comms } from "../../../../Comms/Comms";
import {
  getStatusDetailsThunk,
  getUserTypesThunk,
} from "../../../../../../Controller/redux/features/CommonFeature/commonSlice";
import Daybook from "../../../../Daybook/Daybook";
import CircularNoteTrigger from "../../../../../components/CircularNoteTrigger/CircularNoteTrigger";
import ProfilePictureModal from "../../../../../components/ProfilePictureModal/ProfilePictureModal";
import CommonQuickMenu from "../../../../../components/CommonQuickMenu/CommonQuickMenu";
import {
  getBusinessNameThunk,
  getEntityRoleThunk,
} from "../../../../../../Controller/redux/features/BusinessFeature/businessSlice";
import {
  getStaffNamesThunk,
} from "../../../../../../Controller/redux/features/StaffFeature/staffSlice";
import Table from "../../../../../components/CommonTable/Table";
import { documentsColumns } from "../../../../../components/CommonTable/Data/AssociationHeaderDataArr";
import {
  setshowUnsavedModal,
  setunsavedChanges,
} from "../../../../../../Controller/redux/features/AuthFeature/authSlice";
import { setWarningMessage } from "../../../../../../Controller/redux/features/ClientFeature/documentClientSlice";

const TeamsScreen = ({ screenType }: any) => {
  const dispatch = useAppDispatch();

  const { routeString, showUnsavedModal } = useAppSelector(
    (state) => state.auth
  );

  // const dynamicTeamsId = useAppSelector(
  //   (state) => state?.teams?.getUserTypeIdForTeams?.id
  // );
  // @ts-ignore
  const dynamicTeamsId = parseInt(localStorage.getItem("dynamicTeamsId"));
  const navigate = useNavigate();
  const { getUserTypeIdForTeams, getUniqueTeams } = useAppSelector(
    (state) => state?.teams
  );
  // @ts-ignore
  const dynamicBusinessId = parseInt(localStorage.getItem("dynamicBusinessId"));
  // @ts-ignore
  const dynamicStaffId = parseInt(localStorage.getItem("dynamicStaffId"));

  useEffect(() => {
    dispatch(getBusinessNameThunk(dynamicBusinessId));
    dispatch(getStaffNamesThunk(dynamicStaffId));
  }, [dynamicBusinessId, dynamicStaffId]);
  const { getBusinessName } = useAppSelector((state) => state?.business);
  const { getStaffNames } = useAppSelector((state) => state?.staff);

  const StaffName = getStaffNames?.data?.data?.map((data: any) => ({
    id: data?.id,
    name: data?.first_name + " " + data?.last_name,
  }));
  const BusinessName = getBusinessName?.data?.data?.map((data: any) => ({
    id: data?.id,
    name: data?.first_name,
  }));

  const dynamicColor = useAppSelector((state) => state?.common?.dynamicColor);
  // const currentTeams = ;
  const [currentTeams, setcurrentTeams] = useState<any>(null);

  const { id, pro_id }: any = useParams();
  const [teamsId, setTeamsId] = useState("");
  let clientId = teamsId;
  const [providerId, setProviderId] = useState("");

  useEffect(() => {
    if (getUniqueTeams?.data?.data.length > 0) {
      setcurrentTeams(getUniqueTeams?.data?.data[0]);
    } else {
      setcurrentTeams(null);
    }
  }, [teamsId, getUniqueTeams]);

  useEffect(() => {
    if (id) {
      const realId = window.atob(id);
      setTeamsId(realId);
      if (screenType) {
        setProviderId(window?.atob(pro_id));
      }
    }
  }, [id, pro_id]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (teamsId) {
      dispatch(clearCurrentTeamsData());

      dispatch(getUniquePrimaryApproversForEntityThunk(teamsId));
    }
  }, [teamsId, providerId, dynamicTeamsId]);
  const { hasDeleteDocument, warningPopup } = useAppSelector(
    (state) => state?.document
  );
  const { hasDeleteAssociation, warningPopupAssociation } = useAppSelector(
    (state) => state?.associations
  );
  const { openFormTeams } = useAppSelector((state) => state?.documentTeams);
  const [userRoles, setUserRoles] = useState([]);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [buttonTab, setbuttonTab] = useState({
    Details: true,
    Associations: false,
    Comms: false,
    Documents: false,
    Daybook: false,
  });
  const [details, setDetails] = useState<any>({
    TeamName: "",
    business_id: "",
    address: "",
    team_leader_id: "",
    phone: "",
    email: "",
  });
  // // console.log(StaffName, "staffname");
  // // console.log(currentTeams, "currentTeams");

  useEffect(() => {
    const fetchedData: any = currentTeams;
    if (fetchedData) {
      setDetails({
        TeamName: currentTeams?.first_name,
        business_id: currentTeams ? currentTeams?.business_id : "",
        address: currentTeams?.address ? currentTeams?.address : "",
        team_leader_id: currentTeams ? currentTeams?.team_leader_id : "",
        phone: currentTeams?.phone,
        email: currentTeams?.email,
      });
    } else {
      setDetails({
        TeamName: "",
        business_id: "",
        address: "",
        team_leader_id: "",
        phone: "",
        email: "",
      });
    }
  }, [currentTeams]);
  const [updateTeams, setUpdateTeams] = useState<any>({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    business_id: "",
    team_leader_id: "",
    role: "",
    user_id: "",
    type: "",
  });

  useEffect(() => {
    setUpdateTeams({
      first_name: details?.TeamName, //this
      last_name: "",
      email: details?.email, //this
      phone: details?.phone,
      business_id: details?.business_id,
      team_leader_id: details?.team_leader_id,
      address: details?.address,
      role: details?.role, //this
      user_id: +teamsId, //this
      type: dynamicTeamsId, //this
    });
  }, [teamsId, details]);
  useEffect(() => {
    if (showUnsavedModal) {
      setHasUnsavedChanges(false);
    }
  }, [showUnsavedModal]);
  const handleMoveWithoutSaving = () => {
    dispatch(setshowUnsavedModal(false));
    dispatch(setunsavedChanges(false));
    navigate(routeString);
  };

  useEffect(() => {
    // if (screenType) {
    if (providerId && clientId && dynamicBusinessId && screenType) {
      // setTimeout(() => {
      dispatch(
        getProviderTeamsByIdThunk({
          typeId: dynamicTeamsId,
          clientId,
          tenant_id: providerId,
        })
      )
        .then(() => {
          setLoading(false);
        })
        .catch((error: any) => {
          // console.log(error);
          setLoading(false);
        });
      // }, 1000);
    } else {
      dispatch(getTeamsByIdThunk({ dynamicId: dynamicTeamsId, teamsId }))
        .then(() => {
          setLoading(false);
        })
        .catch((error: any) => {
          // console.log(error);
          setLoading(false);
        });
    }
    // }

    // dispatch(
    //   getTeamsByIdThunk({
    //     dynamicId: dynamicTeamsId,
    //     teamsId,
    //   })
    // );
  }, [screenType, teamsId, clientId, providerId]);

  // UPDATE BUSINESS FUNCTION
  const handleSaveChanges = async () => {
    setHasUnsavedChanges(false);
    dispatch(setunsavedChanges(false));
    try {
      let parms = updateTeams;
      if (screenType) {
        parms.tenant_id = providerId;
      }
      const res = await dispatch(updateTeamsThunk(parms));
      dispatch(
        getTeamsByIdThunk({
          dynamicId: dynamicTeamsId,
          teamsId,
        })
      );
      if (res.payload.status === "success") {
        toast.success("Teams updated successfully!", {
          progressStyle: { backgroundColor: dynamicColor },
          icon: (
            <span
              style={{
                marginRight: "8px",
                borderRadius: "50%",
                width: "7rem",
                height: "35px",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: dynamicColor,
                backgroundColor: "white",
                fontSize: "1.5rem",
                border: `3px solid ${dynamicColor}`,
              }}
            >
              ✓
            </span>
          ),
        });
      } else {
        toast.error("An Error Occurred!");
      }
    } catch (error) {
      console.error(error);
      toast.error("An Error Occurred!");
    }
    if (screenType) {
      dispatch(
        getProviderTeamsByIdThunk({
          typeId: dynamicTeamsId,
          clientId,
          tenant_id: providerId,
        })
      )
        .then(() => {
          setLoading(false);
        })
        .catch((error: any) => {
          // console.log(error);
          setLoading(false);
        });
    } else {
      dispatch(
        getTeamsByIdThunk({
          dynamicId: dynamicBusinessId,
          teamsId,
        })
      )
        .then(() => {
          setLoading(false);
        })
        .catch((error: any) => {
          // console.log(error);
          setLoading(false);
        });
    }
  };
  const handleMoveWithSave = async () => {
    dispatch(setshowUnsavedModal(false));
    dispatch(setunsavedChanges(false));
    try {
      let parms = updateTeams;
      if (screenType) {
        parms.tenant_id = providerId;
      }
      // console.log("teams: ", parms);
      const res = await dispatch(updateTeamsThunk(parms));
      dispatch(
        getTeamsByIdThunk({
          dynamicId: dynamicTeamsId,
          teamsId,
        })
      );
      if (res.payload.status === "success") {
        toast.success("Teams updated successfully!", {
          style: {},
          progressStyle: { backgroundColor: dynamicColor },
          icon: (
            <span
              style={{
                marginRight: "8px",
                borderRadius: "50%",
                width: "7rem",
                height: "35px",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: dynamicColor,
                backgroundColor: "white",
                fontSize: "1.5rem",
                border: `3px solid ${dynamicColor}`,
              }}
            >
              ✓
            </span>
          ),
        });
      } else {
        toast.error("An Error Occurred!");
      }
    } catch (error) {
      console.error(error);
      toast.error("An Error Occurred!");
    }
    navigate(routeString);
  };
  // RETAIN THE STATE
  useEffect(() => {
    if (localStorage.getItem("activeTab") === "Documents") {
      handleClickTeams("Documents");
    } else if (localStorage.getItem("activeTab") === "Associations") {
      handleClickTeams("Associations");
    } else if (localStorage.getItem("activeTab") === "Comms") {
      handleClickTeams("Comms");
    } else if (localStorage.getItem("activeTab") === "Daybook") {
      handleClickTeams("Daybook");
    }
  }, []);

  const handleClickTeams = (button: string) => {
    setstageTwoToGoalsModal(false);
    dispatch(resetselectedItemIndexAssociations(""));
    dispatch(resetselectedNames(""));
    dispatch(resetIsDocumentsDropdownOpen());
    dispatch(resetinputSearchVal(""));
    dispatch(resetSelectedDropdownItemAssociations());
    dispatch(resetSelectedItemIndexFilterAssociations());
    dispatch(resetSelectedFilterItemsAssociations());
    dispatch(resetSelectedDropdownItemDocuments());
    dispatch(resetSelectedItemIndexFilterDocuments());
    dispatch(resetSelectedFilterItemsDocuments());
    dispatch(resetOpenFormByClickTeams());
    const defaultTabState = {
      Details: true,
      Associations: false,
      Comms: false,
      Documents: false,
      Daybook: false,
    };
    if (!hasDeleteDocument) {
      const newTabState = { ...defaultTabState, [button]: true };
      localStorage.setItem("activeTab", button);
      setbuttonTab(newTabState);
    } else {
      setbuttonTab({ ...defaultTabState, Documents: true });
      localStorage.setItem("activeTab", "Documents");
      dispatch(setWarningPopup(true));
    }
    setbuttonTab((prevState) => ({
      ...prevState,
      Details: button === "Details",
      Associations: button === "Associations",
      Comms: button === "Comms",
      Documents: button === "Documents",
      Daybook: button === "Daybook",
    }));
  };

  useEffect(() => {
    if (teamsId) {
      dispatch(getDocumentsEntity({ clientId: teamsId, providerId }));
    }
  }, [teamsId]);

  useEffect(() => {
    if (buttonTab.Associations) {
      dispatch(getAssociationTypesThunk());
      dispatch(getAssociationRelationshipsThunk());
      dispatch(getUserTypesThunk());
    }
  }, [buttonTab]);
  useEffect(() => {
    if (teamsId) {
      dispatch(
        getAssociationsForEntity({
          clientId: teamsId,
          searchVal: "",
          type: "",
          pageNumber: 1,
        })
      );
    }
  }, [teamsId]);
  // CHANGE TO INPUT FIELDS
  const handleChange = (
    index: number,
    e: React.ChangeEvent<HTMLInputElement> | any
  ) => {
    const { value } = e.target;
    const fieldName = Object.keys(details)[index];
    setDetails((prevState: any) => ({
      ...prevState,
      [fieldName]: value,
    }));
    dispatch(setunsavedChanges(true));

    if (!hasUnsavedChanges) {
      setHasUnsavedChanges(true);
    }
  };
  const [uploadedFiles, setUploadedFiles] = useState<
    {
      file: File;
      key: string;
      file_name?: string;
      actual_file_name?: string;
    }[]
  >([]);
  const [documentDetails, setDocumentDetails] = useState<any>({
    title: "",
    summary: "",
    primary_review: "",
    ongoing_review: "",
    period_picker: "",
    start: "",
    end: "",
    primary_approver_id: [],
    ongoing_approver_id: [],
    hard_copy_completed_date: "",
    communications: [],
    user_id: teamsId,
    document_files: [],
    tag: "",
  });

  const handleGetDetails = (elem: any) => {
    setDocumentDetails({
      title: elem.title,
      summary: elem.summary,
      primary_review: elem.primary_review,
      ongoing_review: elem.ongoing_review,
      period_picker: elem.period_picker,
      start: elem.start,
      end: elem.end,
      primary_approver_id: elem.primary_approver_id,
      ongoing_approver_id: elem.ongoing_approver_id,
      hard_copy_completed_date: elem.hard_copy_completed_date,
      communications: elem.communications,
      user_id: teamsId,
      document_files: elem.document_files,
      tag: elem.tag,
      tenant_id: providerId,
    });
  };
  let isPending = false;
  const handleOverlayClick = async (isFormSave: any) => {
    let duplicateDetailsInfo = { ...documentDetails, user_id: "" };
    if (documentDetails?.title == "") {
      const hasValueInOtherFields = Object.keys(duplicateDetailsInfo);
      let nonEmptyFieldCount = 0;

      let ishereAnyFieldsHaveVaule = false;
      hasValueInOtherFields.map((res) => {
        // if (documentDetails[res]) {
        if (!ishereAnyFieldsHaveVaule) {
          if (typeof duplicateDetailsInfo[res] === "string") {
            if (!!duplicateDetailsInfo[res]) {
              ishereAnyFieldsHaveVaule = true;
              !isFormSave
                ? dispatch(setWarningMessage(true))
                : toast.warning("Title is mandatory");
              return;
            }
          } else if (typeof duplicateDetailsInfo[res] === "object") {
            if (!!duplicateDetailsInfo[res]?.length) {
              if (!!duplicateDetailsInfo[res]) {
                ishereAnyFieldsHaveVaule = true;
                // toast.warning("Title is mandatory");
                !isFormSave
                  ? dispatch(setWarningMessage(true))
                  : toast.warning("Title is mandatory");
                return;
              }
            }
          }
        }
      });

      if (ishereAnyFieldsHaveVaule) {
        return;
      }
    }

    if (documentDetails?.title || documentDetails?.summary) {
      if (isPending) return;
      try {
        isPending = true;

        dispatch(createDocumentThunk(documentDetails))
          .then(() => {
            isPending = false;

            toast.success("Document Created Successfully!", {
              style: {},
              progressStyle: { backgroundColor: dynamicColor },
              icon: (
                <span
                  style={{
                    marginRight: "8px",
                    borderRadius: "50%",
                    width: "7rem",
                    height: "35px",
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: dynamicColor,
                    backgroundColor: "white",
                    fontSize: "1.5rem",
                    border: `3px solid ${dynamicColor}`,
                  }}
                >
                  ✓
                </span>
              ),
            });
            dispatch(getUniquePrimaryApproversThunk(clientId));
          })
          .catch(() => {
            isPending = false;

            toast.error("Failed To Create Document. Please Try Again!");
          });

        dispatch(getDocumentsEntity({ clientId: teamsId, providerId }));
        dispatch(resetOpenFormByClickTeams());
      } catch {
        dispatch(getDocumentsEntity({ clientId: teamsId, providerId }));
        dispatch(resetOpenFormByClickTeams());
      } finally {
        dispatch(getDocumentsEntity({ clientId: teamsId, providerId }));
        dispatch(resetOpenFormByClickTeams());
      }
    } else {
      dispatch(resetOpenFormByClickTeams());
      dispatch(getDocumentsEntity({ clientId: teamsId, providerId }));
    }
  };

  const handleCloseModal = async () => {
    if (documentDetails.title || documentDetails.summary) {
      if (isPending) return;
      try {
        isPending = true;

        dispatch(createDocumentThunk(documentDetails))
          .then(() => {
            isPending = false;

            toast.success("Document Created Successfully!", {
              progressStyle: { backgroundColor: dynamicColor },
              icon: (
                <span
                  style={{
                    marginRight: "8px",
                    borderRadius: "50%",
                    width: "7rem",
                    height: "35px",
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: dynamicColor,
                    backgroundColor: "white",
                    fontSize: "1.5rem",
                    border: `3px solid ${dynamicColor}`,
                  }}
                >
                  ✓
                </span>
              ),
            });
            dispatch(getUniquePrimaryApproversThunk(clientId));
          })
          .catch(() => {
            isPending = false;

            toast.error("Failed To Create Document. Please Try Again!");
          });
        dispatch(getDocumentsEntity({ clientId: teamsId, providerId }));
        dispatch(resetOpenFormByClickTeams());
      } catch {
        dispatch(getDocumentsEntity({ clientId: teamsId, providerId }));
      } finally {
        dispatch(resetOpenFormByClickTeams());
        dispatch(getDocumentsEntity({ clientId: teamsId, providerId }));
      }
    } else {
      dispatch(resetOpenFormByClickTeams());
      dispatch(getDocumentsEntity({ clientId: teamsId, providerId }));
    }
  };
  useEffect(() => {
    dispatch(getEntityRoleThunk(dynamicTeamsId));
  }, [dynamicTeamsId]);

  // const currentTeamsRoles = entityRoles?.data;

  const [showProfile, setShowProfile] = useState<boolean>(false);
  const [showGoalsModal, setshowGoalsModal] = useState(false);
  const [stageTwoToGoalsModal, setstageTwoToGoalsModal] = useState(false);

  useEffect(() => {
    if (openFormTeams) {
      setbuttonTab({
        Details: false,
        Associations: false,
        Comms: false,
        Documents: true,
        Daybook: false,
      });
    }
  }, [openFormTeams]);
  /// ASSOCIATION TABLE START //
  const dropdownRefAssociation = useRef<any>();
  const svgParentRefAssociation = useRef<any>();
  const [isDropdownOpenAssociation, setIsDropdownOpenAssociation] =
    useState(false);
  const {
    selectedFilterItemsAssociations,
    sortByAssociationsR,
    searchValAssociations,
  } = useAppSelector((state) => state?.associations);

  const { filterIconColor } = useAppSelector((state) => state?.common);
  const handleClickOutsideAssociation = (event: any) => {
    if (
      dropdownRefAssociation.current &&
      !dropdownRefAssociation.current.contains(event.target) &&
      svgParentRefAssociation.current &&
      !svgParentRefAssociation.current.contains(event.target)
    ) {
      setIsDropdownOpenAssociation(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutsideAssociation);
    return () => {
      document.removeEventListener("click", handleClickOutsideAssociation);
    };
  }, []);
  const associationColumns = [
    { header: "Name", accessor: "user_name" },
    { header: "Category", accessor: "category" },
    { header: "Relationship", accessor: "relationship_name" },
    { header: "Contact Number", accessor: "number" },
    { header: "Associated Business/Site", accessor: "funding_type" },
    { header: "Action", accessor: "actionAssociation" },
  ];
  const { getAssociationByUserId } = useAppSelector(
    (state) => state?.associations
  );

  // DOCUMENTS TABLE START //
  const dropdownRefDocuments = useRef<any>();
  const svgParentRefDocuments = useRef<any>();
  const [isDropdownOpenDocuments, setIsDropdownOpenDocuments] = useState(false);
  const { selectedFilterItemsDocuments, sortByDocumentsR, searchValDoc } =
    useAppSelector((state) => state?.document);
  const handleClickOutsideDocuments = (event: any) => {
    if (
      dropdownRefDocuments.current &&
      !dropdownRefDocuments.current.contains(event.target) &&
      svgParentRefDocuments.current &&
      !svgParentRefDocuments.current.contains(event.target)
    ) {
      setIsDropdownOpenDocuments(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutsideDocuments);
    return () => {
      document.removeEventListener("click", handleClickOutsideDocuments);
    };
  }, []);

  const [primaryApprover, setPrimaryApprover] = useState([]);
  const [ongoingApprover, setOngoingApprover] = useState([]);

  const { getDocuments } = useAppSelector((state) => state?.document);
  const updatedDoc = useAppSelector(
    (state) => state?.document?.updateDocumentData
  );
  const selectedOps = useAppSelector(
    (state) => state?.document?.selectedOptions
  );
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [selectedObject, setSelectedObject] = useState<any>(null);
  const [showStateOfDocument, setShowStateOfDocument] = useState<any>("");
  const handleClickDocRow = (e: any, id: any, title: any) => {
    e.stopPropagation();
    if (isDropdownOpenDocuments) {
      setIsDropdownOpenDocuments(false);
    } else {
      const selectedDocument = getDocuments?.find((elem: any) => {
        return elem?.id === id;
      });
      setIsFormOpen(true);
      dispatch(setIsFormOpenTrue());
      setSelectedObject(selectedDocument);
      setPrimaryApprover(selectedDocument?.primary_approvers);
      setOngoingApprover(selectedDocument?.ongoing_approvers);
      dispatch(setselectedDataObject(selectedDocument));
      setUploadedFiles(selectedDocument?.files);
      setShowStateOfDocument(title);
    }
  };
  const handleOverlayClickUpdate = async () => {
    if (!selectedObject?.isThereAnyChanges) {
      setIsFormOpen(false);
      return;
    }

    try {
      dispatch(
        updateDocumentThunk({
          title: updatedDoc?.title,
          summary: updatedDoc.summary,
          primary_review: updatedDoc?.primary_review,
          ongoing_review: updatedDoc.ongoing_review,
          period_picker: updatedDoc.period_picker,
          start: updatedDoc.start,
          end: updatedDoc.end,
          primary_approver_id: primaryApprover.map((res: any) => res?.id),
          ongoing_approver_id: ongoingApprover.map((res: any) => res?.id),
          hard_copy_completed_date: updatedDoc.hard_copy_completed_date,
          document_files: updatedDoc.document_files,
          user_id: updatedDoc.user_id,
          document_id: updatedDoc?.document_id,
          tag: updatedDoc?.tagArray,
          tenant_id: providerId,
          note_details: updatedDoc?.note_details || {},

        })
      )
        .then(() => {
          toast.success("Document updated successfully!", {
            // style: {},
            progressStyle: { backgroundColor: dynamicColor },
            icon: (
              <span
                style={{
                  marginRight: "8px",
                  borderRadius: "50%",
                  width: "7rem",
                  height: "35px",
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: dynamicColor,
                  backgroundColor: "white",
                  fontSize: "1.5rem",
                  border: `3px solid ${dynamicColor}`,
                }}
              >
                ✓
              </span>
            ),
          });
          dispatch(getUniquePrimaryApproversThunk(clientId));
          dispatch(getDocumentsEntity({ clientId, providerId }));
          dispatch(
            getDocumentsByFilterForEntity({
              clientId: teamsId,
              title: selectedOps?.join(","),
              searchVal: "",
              sortType: "",
              filterColumn: "",
              sortColumn: "",
              providerId,
            })
          );
        })
        .catch((error: any) => {
          toast.error("Failed To Update Document. Please Try Again!");
        });
    } catch (err) {}

    setIsFormOpen(false);
    // dispatch(getUniquePrimaryApproversThunk(clientId));
    dispatch(getUniquePrimaryApproversForEntityThunk(clientId));
  };
  const [changesToForm, setchangesToForm] = useState("");

  const handleChangesToForm = (val: any) => {
    setchangesToForm(val);
  };
  // DOCUMENTS TABLE END //
  const { domainDetail } = useAppSelector((state) => state?.common);

  const teamsStatusDetailsNums = useAppSelector(
    (state) => state.common.domainDetail.team_status
  );
  useEffect(() => {
    dispatch(getStatusDetailsThunk(teamsStatusDetailsNums));
  }, [teamsStatusDetailsNums]);

  const openWarningSection = useAppSelector(
    (state) => state?.documentClient?.openWarningSection
  );

  return (
    <div>
      {loading && <Spinner position={"absolute"} top={"50%"} left={"50%"} />}
      <div
        style={{
          position: "relative",
        }}
      >
        {stageTwoToGoalsModal && (
          <CommonQuickMenu
            setshowGoalsModal={setshowGoalsModal}
            showGoalsModal={showGoalsModal}
            setstageTwoToGoalsModal={setstageTwoToGoalsModal}
            type="teams"
            filterItems={true}
          />
        )}
        <CommonUpperBox
          imageSection
          type={"screen"}
          currentUser={currentTeams}
          imageURL={currentTeams?.profile_image}
          title={`${currentTeams?.first_name || ""} ${
            currentTeams?.last_name || ""
          }`}
          onClickUserImage={() => setShowProfile(true)}
          onClickPlus={() => {
            // if (buttonTab.Documents) {
            setstageTwoToGoalsModal(true);
            // }
          }}
        />
      </div>

      {showProfile && (
        <ProfilePictureModal
          showProfile={showProfile}
          className="profile_picture_external"
          setShowProfile={setShowProfile}
          currentClient={currentTeams}
        />
      )}

      {buttonTab.Details && (
        <>
          <CommonHeader
            mainType="teams"
            type="details"
            handleClick={handleClickTeams}
            currentTeams={currentTeams}
          />
          <>
            <div style={{ paddingRight: "10px", paddingLeft: "10px" }}>
              <div className=" client-screen-personal-details-heading">
                <span className=" client-screen-sub-heading-title">
                  Personal Details
                </span>
                <span className=" client-screen-line"></span>
              </div>
              <div className="main-box-body">
                {dummyArrTeams.map((elem: any, index: any) => {
                  const fieldName = Object.keys(details)[index];
                  return index === 2 ? null : (
                    <div className="content-wrapper" key={index}>
                      <div className="wrapper-title">{elem.UpperTitle}</div>
                      {index === 1 ? (
                        <select
                          className="wrapper-info wrapper-info-select-dropdown"
                          onChange={(e) => handleChange(index, e)}
                          value={details[fieldName]}
                          style={{
                            border: "none",
                            outline: "none",
                            height: "fit-content",
                            backgroundColor: "white",
                          }}
                        >
                          <option>Please Select...</option>
                          {BusinessName?.map(
                            (elem: any, index: number | any) => (
                              <option key={index} value={elem?.id}>
                                {elem?.name}
                              </option>
                            )
                          )}
                        </select>
                      ) : index === 3 ? (
                        <select
                          className="wrapper-info wrapper-info-select-dropdown"
                          onChange={(e) => handleChange(index, e)}
                          value={details[fieldName]}
                          style={{
                            border: "none",
                            outline: "none",
                            height: "fit-content",
                            backgroundColor: "white",
                          }}
                        >
                          <option>Please Select... </option>
                          {StaffName?.map((elem: any, index: number | any) => (
                            <option key={index} value={elem?.id}>
                              {elem?.name}
                            </option>
                          ))}
                        </select>
                      ) : (
                        <input
                          className="wrapper-info"
                          onChange={(e) => handleChange(index, e)}
                          value={details[fieldName]}
                          placeholder={elem?.placeHolder}
                          style={{
                            border: "none",
                            outline: "none",
                            height: "fit-content",
                          }}
                        />
                      )}

                      {elem.index === 5 && (
                        <div className="dropdown-icon">
                          <img src={DropdownIcon} alt="dropdown-icon" />
                        </div>
                      )}
                      <div className="wrapper-line"></div>
                    </div>
                  );
                })}
              </div>
            </div>
            {hasUnsavedChanges && (
              <>
                <div
                  className="save"
                  style={{
                    position: "absolute",
                    marginRight: "200px",
                    bottom: "10px",
                  }}
                >
                  <p>Would you like to save your changes?</p>
                  <div className="btnDivision">
                    <button
                      onClick={handleSaveChanges}
                      style={{ backgroundColor: dynamicColor }}
                    >
                      Save
                    </button>
                    <button
                      style={{
                        fontFamily: "Helvetica",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "18px",
                        backgroundColor: "#F9F9F9",
                        border: "none",
                        color: "#9296A2",
                      }}
                      onClick={() => {
                        dispatch(
                          getTeamsByIdThunk({
                            dynamicId: dynamicTeamsId,
                            teamsId,
                          })
                        );

                        setHasUnsavedChanges(false);
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </>
            )}
            {showUnsavedModal && (
              <>
                <ConfirmationModal
                  type="clientScreen"
                  handleMoveWithoutSaving={handleMoveWithoutSaving}
                  handleMoveWithSave={handleMoveWithSave}
                />
              </>
            )}
          </>
          <CircularNoteTrigger type="stickyHub" />
        </>
      )}
      {buttonTab.Documents && (
        <>
          <CommonHeader
            mainType="teams"
            type="documents"
            handleClick={handleClickTeams}
            currentTeams={currentTeams}
          />
          {/* <CommonTable type="documents" /> */}
          <Table
            dynamicHeaderColor={domainDetail?.doctbl_color}
            thData={documentsColumns}
            classNameTr="client-documents-tab-table-head"
            classNameTable="documents-table-additional-styles"
            type="documents"
            dropdownRef={dropdownRefDocuments}
            isDropdownOpen={isDropdownOpenDocuments}
            onClickSvg={() => setIsDropdownOpenDocuments(true)}
            svgParentRef={svgParentRefDocuments}
            allClients={getDocuments}
            // onClickRow={handleClickDocRow.bind(this, "onRow")}
            onClickRow={handleClickDocRow}
            classNameRow="client-documents-tab-table-body"
            fill={
              selectedFilterItemsDocuments.length > 0 ||
              searchValDoc.length > 0 ||
              sortByDocumentsR.length > 0
                ? filterIconColor
                : "#D8D3D3"
            }
          />
          {warningPopup && (
            <>
              <ConfirmationModal type="" />
            </>
          )}
          {hasDeleteDocument && (
            <>
              <UnsavedChangesPopup type="documents" />
            </>
          )}{" "}
          {openFormTeams && openWarningSection && (
            <ConfirmationModal
              type="documents"
              handleMoveWithoutSaving={() => {
                dispatch(setWarningMessage(false));
              }}
              handleMoveWithSave={() => {
                dispatch(setWarningMessage(false));
                dispatch(resetOpenFormByClickTeams());
                // dispatch(setopenClientAssessmentForm(false));
              }}
            />
          )}
          {openFormTeams && (
            <div className="client-assessment-form-div">
              {openFormTeams && (
                <Modal
                  isOpen={true}
                  onClose={() => {}}
                  closeOnOverlayClick={true}
                  isCentered
                >
                  <ModalOverlay onClick={() => {}} zIndex={10} />

                  <ClientAssessmentForm
                    type="new-form"
                    handleGetDetails={handleGetDetails}
                    uploadedFiles={uploadedFiles}
                    setUploadedFiles={setUploadedFiles}
                    handleOverlayClickTeams={handleOverlayClick}
                    handleSaveClick={handleOverlayClick}
                  />
                </Modal>
              )}
            </div>
          )}
          {isFormOpen && (
            <Modal
              isOpen={true}
              onClose={() => {}}
              closeOnOverlayClick={true}
              isCentered
            >
              <ModalOverlay onClick={() => {}} zIndex={10} />
              <ClientAssessmentForm
                type="new-form"
                handleChangesToForm={handleChangesToForm}
                dataObject={selectedObject}
                setDataObject={setSelectedObject}
                handleOverlayClickTeamsOnUpdate={handleOverlayClickUpdate}
                setIsFormOpenTeams={setIsFormOpen}
                handleGetDetails={() => {}}
                showStateOfDocument={showStateOfDocument}
                uploadedFiles={uploadedFiles}
                setUploadedFiles={setUploadedFiles}
                primaryApprover={primaryApprover}
                setPrimaryApprover={setPrimaryApprover}
                ongoingApprover={ongoingApprover}
                setOngoingApprover={setOngoingApprover}
                handleSaveClick={handleOverlayClickUpdate}
              />
            </Modal>
          )}
          <CircularNoteTrigger type="stickyHub" />
        </>
      )}
      {buttonTab.Associations && (
        <>
          <CommonHeader
            mainType="teams"
            type="associations"
            handleClick={handleClickTeams}
            currentTeams={currentTeams}
          />
          <CircularNoteTrigger type="associations" title="Association" />
          {/* <CommonTable type="associations" /> */}
          <Table
            dynamicHeaderColor={domainDetail?.assoctbl_color}
            type="asociations"
            classNameTable="common-table-container-associations"
            thData={associationColumns}
            classNameTr="client-associations-tab-table-head"
            dropdownRef={dropdownRefAssociation}
            onClickSvg={() => setIsDropdownOpenAssociation(true)}
            isDropdownOpen={isDropdownOpenAssociation}
            svgParentRef={svgParentRefAssociation}
            allClients={getAssociationByUserId}
            classNameRow="client-associations-tab-table-body"
            fill={
              selectedFilterItemsAssociations.length > 0 ||
              searchValAssociations.length > 0 ||
              sortByAssociationsR.length > 0
                ? filterIconColor
                : "#D8D3D3"
            }
          />
          {warningPopupAssociation && (
            <>
              <ConfirmationModal type="" />
            </>
          )}
          {hasDeleteAssociation && (
            <>
              <UnsavedChangesPopup type="associations" />
            </>
          )}
        </>
      )}
      {buttonTab.Comms && (
        <>
          <CommonHeader
            mainType="teams"
            type="comms"
            handleClick={handleClickTeams}
            currentTeams={currentTeams}
          />
          <div style={{ marginTop: "10px" }}>
            <Comms isUserType={true} />
          </div>
        </>
      )}
      {buttonTab.Daybook && (
        <>
          <CommonHeader
            mainType="teams"
            type="daybook"
            handleClick={handleClickTeams}
            currentTeams={currentTeams}
          />
          <Daybook type="teams" />
        </>
      )}
    </div>
  );
};

export default TeamsScreen;
