import React from "react";
import { Route, Routes } from "react-router-dom";
import Audit from "../pages/Audit/Audit";
import ClientPage from "../pages/People/ClientPage/ClientPage";
import ClientScreen from "../pages/People/ClientPage/Components/UniqueClientScreen/ClientScreen";
import ContactPage from "../pages/People/ContactPage/ContactPage";
import ContactScreen from "../pages/People/ContactPage/Components/UniqueContactScreen/ContactScreen";
import Daybook from "../pages/Daybook/Daybook";
import { ForgotPassword } from "../../View/pages/Account/ForgotPassword";
import { Home } from "../pages/Home/index";
import { Login } from "../../View/pages/Account/Login";
import MealBuilder from "../pages/Meals/MealBuilder/MealBuilder";
import MenuBuilder from "../pages/Meals/MenuBuilder/MenuBuilder";
import MenuAssignment from "../pages/Meals/MenuAssignment/MenuAssignment";
import { PrivateRoute } from "./PrivateRoute";
import ProspectsScreen from "../pages/People/ProspectusPage/Components/UniqueProspectsScreen/ProspectsScreen";
import Prospectus from "../pages/People/ProspectusPage/Prospectus";
import { PublicRoute } from "./PublicRoute";
import { ResetPassword } from "../../View/pages/Account/ResetPassword";
import { SignUp } from "../../View/pages/Account/SignUp";
import StaffScreen from "../pages/People/Staff/Components/UniqueStaffScreen/StaffScreen";
import UpdateClientDetails from "../pages/People/ClientPage/Components/UniqueClientScreen/UpdateClientDetails";
import Villages from "../pages/Villages/Villages";
import BusinessPage from "../pages/People/Business/BusinessPage";
import TeamsPage from "../pages/People/Teams/TeamsPage";
import TeamsScreen from "../pages/People/Teams/Components/UniqueTeamsScreen/TeamsScreen";
import BusinessScreen from "../pages/People/Business/Components/UniqueBusinessScreen/BusinessScreen";
import StaffDetailsPage from "../pages/People/Staff/StaffPage";
import { Comms } from "../pages/Comms/Comms";
import Sales from "../pages/Sales/Sales";
import Reports from "../pages/Meals/Reports/Reports";
import Providers from "../pages/Providers/Providers";
import LocationsPage from "../pages/People/Locations/LocationsPage";
import LocationsScreen from "../pages/People/Locations/Components/LocationsScreen";
import ProvidersScreen from "../pages/Providers/Compoonents/ProvidersScreen";
import Sil from "../pages/Villages/Sil";
import UpdateProfileDetails from "../pages/Account/User/UpdateProfileDetails";
import Rac from "../pages/Villages/Rac";
import ExpenseType from "../pages/Ref-Data/ExpenseType/ExpenseType";
import ServiceType from "../pages/Ref-Data/ServiceType/ServiceType";
import Billing from "../pages/Ref-Data/BillingRule/Billing";
import PayRule from "../pages/Ref-Data/PayRule/PayRule";
import BillingRuleScreen from "../pages/Ref-Data/BillingRule/Components/UniqueBillingRuleScreen/BillingRuleScreen";
import PayRuleScreen from "../pages/Ref-Data/PayRule/Components/UniquePatRuleScreen/PayRuleScreen";
import ServiceTypeScreen from "../pages/Ref-Data/ServiceType/ServiceTypeScreen";
import ExpenseTypeScreen from "../pages/Ref-Data/ExpenseType/Components/UniqueExpenseTypeScreen/ExpenseTypeScreen";
import Roster from "../pages/Roster/Roster";
import ProviderProspectScreen from "../pages/Providers/Compoonents/ProviderProspectScreen";
import ProviderClientScreen from "../pages/Providers/Compoonents/ProviderClientScreen";
import ProviderBusinessScreen from "../pages/Providers/Compoonents/ProviderBusinessScreen";
import Form from "../pages/Forms/Form";
import ProviderTeamsScreen from "../pages/Providers/Compoonents/ProviderTeamsScreen";
import ProviderContactsScreen from "../pages/Providers/Compoonents/ProviderContactsScreen";
import ProviderStaffScreen from "../pages/Providers/Compoonents/ProviderStaffScreen";
import ProviderLocationScreen from "../pages/Providers/Compoonents/ProviderLocationScreen";
import MobileCard from "../components/StickyNote/MobileScreenSticky/MobileCard";
import MobileHomeScreen from "../components/StickyNote/MobileScreenSticky/MobileHomeScreen";
import useWindowSize from "../../Model/utils/useWindowSize";
import {useEffect, useState} from "react";
import MobileAppointments from "../components/StickyNote/MobileScreenSticky/MobileAppointments";
import MobileCommsScreen from "../components/StickyNote/MobileScreenSticky/MobileCommsScreen";
// import Iframe from "../components/Layout/Content/Iframe";
import Notifications from "../pages/Notifications/Notifications";
import VendorScreen from "../pages/Vendors/VendorScreen";
import VendorDetails from "../pages/Vendors/VendorDetails";
import Iframe from "../components/Layout/Content/Iframe";
import ReportsHub from "../pages/Reports/ReportsHub";
import ReportsScreen from "../pages/Reports/ReportsListScreen";
import ReportsHubDetails from "../pages/Reports/ReportsHubDetails";

export const RouteList = () => {
  const { width } = useWindowSize();

  const [isMobileScreen, setIsMobileScreen] = useState(false);

  useEffect(() => {
    if (width <= 600) {
      setIsMobileScreen(true);
    } else {
      setIsMobileScreen(false);
    }
  }, [width]);

  return (
    <Routes>
      <Route
        path="/"
        element={
          <PrivateRoute redirectTo="/login">
            {isMobileScreen ? <MobileHomeScreen /> : <Home />}
          </PrivateRoute>
        }
      />
      <Route
        path="reports-hub"
        element={
          <PrivateRoute redirectTo="/login">
            <ReportsHub />
          </PrivateRoute>
        }
      />
      <Route
        path="reports-hub/:id"
        element={
          <PrivateRoute redirectTo="/login">
            <Iframe />
          </PrivateRoute>
        }
      />
      <Route
        path="my-daybook"
        element={
          <PrivateRoute redirectTo="/login">
            {isMobileScreen ? <MobileAppointments /> : <Daybook />}
          </PrivateRoute>
        }
      />
      <Route
        path="comms"
        element={
          <PrivateRoute redirectTo="/login">
            {isMobileScreen ? <MobileCommsScreen /> : <Comms type="comms" />}
          </PrivateRoute>
        }
      />
      <Route
        path="villages"
        element={
          <PrivateRoute redirectTo="/login">
            <Villages />
          </PrivateRoute>
        }
      />
      <Route
        path="sil"
        element={
          <PrivateRoute redirectTo="/login">
            <Sil />
          </PrivateRoute>
        }
      />
      <Route
        path="rac"
        element={
          <PrivateRoute redirectTo="/login">
            <Rac />
          </PrivateRoute>
        }
      />
      <Route
        path="sales"
        element={
          <PrivateRoute redirectTo="/login">
            <Sales />
          </PrivateRoute>
        }
      />
      <Route
        path="/roster"
        element={
          <PrivateRoute redirectTo="/login">
            <Roster />
          </PrivateRoute>
        }
      />
      <Route
        path="vendors"
        element={
          <PrivateRoute redirectTo="/login">
            <VendorScreen />
          </PrivateRoute>
        }
      />
      <Route
        path="/vendors/:id"
        element={
          <PrivateRoute redirectTo="/login">
            <VendorDetails />
          </PrivateRoute>
        }
      />
      <Route
        path="reports"
        element={
          <PrivateRoute redirectTo="/login">
            <ReportsScreen />
          </PrivateRoute>
        }
      />
      <Route
        path="/reports/:id"
        element={
          <PrivateRoute redirectTo="/login">
            <ReportsHubDetails />
          </PrivateRoute>
        }
      />
      <Route
        path="/meals/meals-builder"
        element={
          <PrivateRoute redirectTo="/login">
            <MealBuilder />
          </PrivateRoute>
        }
      />
      <Route
        path="/meals/menu-assignment"
        element={
          <PrivateRoute redirectTo="/login">
            <MenuAssignment />
          </PrivateRoute>
        }
      />
      {/* Card UI for Mobile Sticky */}
      {/* <Route
      path="/card-design"
      element={
        <PrivateRoute redirectTo="/login">
          <MobileCard />
        </PrivateRoute>
      }
    /> */}
      {/* <Route
      path="/"
      element={
        <PrivateRoute redirectTo="/login">
          <MobileHomeScreen />
        </PrivateRoute>
      }
    /> */}
      <Route
        path="/meals/menu-builder"
        element={
          <PrivateRoute redirectTo="/login">
            <MenuBuilder />
          </PrivateRoute>
        }
      />{" "}
      <Route
        path="/meals/reports"
        element={
          <PrivateRoute redirectTo="/login">
            <Reports />
          </PrivateRoute>
        }
      />
      <Route
        path="/people/staff"
        element={
          <PrivateRoute redirectTo="/login">
            <StaffDetailsPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/people/prospects"
        element={
          <PrivateRoute redirectTo="/login">
            <Prospectus />
          </PrivateRoute>
        }
      />
      <Route
        path="/people/contacts"
        element={
          <PrivateRoute redirectTo="/login">
            <ContactPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/people/business"
        element={
          <PrivateRoute redirectTo="/login">
            <BusinessPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/people/teams"
        element={
          <PrivateRoute redirectTo="/login">
            <TeamsPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/people/locations"
        element={
          <PrivateRoute redirectTo="/login">
            <LocationsPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/people/locations/:id"
        element={
          <PrivateRoute redirectTo="/login">
            <LocationsScreen />
          </PrivateRoute>
        }
      />
      <Route
        path="/people/contacts/:id"
        element={
          <PrivateRoute redirectTo="/login">
            <ContactScreen />
          </PrivateRoute>
        }
      />
      <Route
        path="/people/business/:id"
        element={
          <PrivateRoute redirectTo="/login">
            <BusinessScreen />
          </PrivateRoute>
        }
      />
      <Route
        path="/people/teams/:id"
        element={
          <PrivateRoute redirectTo="/login">
            <TeamsScreen />
          </PrivateRoute>
        }
      />
      <Route
        path="/people/prospects/:id"
        element={
          <PrivateRoute redirectTo="/login">
            <ProspectsScreen />
          </PrivateRoute>
        }
      />
      <Route
        path="/people/staff/:id"
        element={
          <PrivateRoute redirectTo="/login">
            <StaffScreen />
          </PrivateRoute>
        }
      />
      <Route
        path="/people/clients"
        element={
          <PrivateRoute redirectTo="/login">
            {isMobileScreen ? <MobileCard /> : <ClientPage />}
          </PrivateRoute>
        }
      />
      <Route
        path="/people/clients/update/:id"
        element={
          <PrivateRoute redirectTo="/login">
            <UpdateClientDetails />
          </PrivateRoute>
        }
      />
      <Route
        path="/people/clients/:id"
        element={
          <PrivateRoute redirectTo="/login">
            <ClientScreen />
          </PrivateRoute>
        }
      />
      <Route
        path="/update-profile-details"
        element={
          <PrivateRoute redirectTo="/login">
            <UpdateProfileDetails />
          </PrivateRoute>
        }
      />
      <Route
        path="/forgot-password"
        element={
          <PublicRoute>
            <ForgotPassword />
          </PublicRoute>
        }
      />
      <Route
        path="/reset-password/:password_token"
        element={
          <PublicRoute>
            <ResetPassword />
          </PublicRoute>
        }
      />
      <Route
        path="/login"
        element={
          <PublicRoute>
            <Login />
          </PublicRoute>
        }
      />
      <Route
        path="/sign-up/:signup_token"
        element={
          <PublicRoute>
            <SignUp />
          </PublicRoute>
        }
      />
      {/* <Route path="/sign-up" element={<SignUp />} /> */}
      <Route
        path="/my-daybook"
        element={
          <PrivateRoute>
            <Daybook />
          </PrivateRoute>
        }
      />
      <Route
        path="/audit"
        element={
          <PrivateRoute>
            <Audit />
          </PrivateRoute>
        }
      />
      <Route
        path="/providers"
        element={
          <PrivateRoute>
            <Providers />
          </PrivateRoute>
        }
      />
      <Route
        path="/providers/:id"
        element={
          <PrivateRoute>
            <ProvidersScreen />
          </PrivateRoute>
        }
      />
      <Route
        path="/notifications"
        element={
          <PrivateRoute>
            <Notifications />
          </PrivateRoute>
        }
      />
      <Route
        path="/providers/:id/staff"
        element={
          <PrivateRoute>
            <ProvidersScreen />
          </PrivateRoute>
        }
      />
      <Route
        path="/providers/:pro_id/prospects/:id"
        element={
          <PrivateRoute>
            <ProviderProspectScreen />
          </PrivateRoute>
        }
      />
      <Route
        path="/providers/:pro_id/business/:id"
        element={
          <PrivateRoute>
            <ProviderBusinessScreen />
          </PrivateRoute>
        }
      />
      <Route
        path="/providers/:pro_id/teams/:id"
        element={
          <PrivateRoute>
            <ProviderTeamsScreen />
          </PrivateRoute>
        }
      />
      <Route
        path="/providers/:pro_id/contacts/:id"
        element={
          <PrivateRoute>
            <ProviderContactsScreen />
          </PrivateRoute>
        }
      />
      <Route
        path="/providers/:pro_id/clients/:id"
        element={
          <PrivateRoute>
            <ProviderClientScreen />
          </PrivateRoute>
        }
      />
      <Route
        path="/providers/:pro_id/staff/:id"
        element={
          <PrivateRoute>
            <ProviderStaffScreen />
          </PrivateRoute>
        }
      />
      <Route
        path="/providers/:pro_id/locations/:id"
        element={
          <PrivateRoute>
            <ProviderLocationScreen />
          </PrivateRoute>
        }
      />
      {/* Ref Data Route Start Here */}
      <Route
        path="/ref-data/expense-type"
        element={
          <PrivateRoute>
            <ExpenseType />
          </PrivateRoute>
        }
      />
      <Route
        path="/ref-data/expense-type/:id"
        element={
          <PrivateRoute>
            <ExpenseTypeScreen />
          </PrivateRoute>
        }
      />{" "}
      <Route
        path="/ref-data/service-type"
        element={
          <PrivateRoute>
            <ServiceType />
          </PrivateRoute>
        }
      />
      <Route
        path="/ref-data/service-type/:id"
        element={
          <PrivateRoute>
            <ServiceTypeScreen />
          </PrivateRoute>
        }
      />
      <Route
        path="/ref-data/billing-set"
        element={
          <PrivateRoute>
            <Billing />
          </PrivateRoute>
        }
      />
      <Route
        path="/ref-data/billing-set/:id"
        element={
          <PrivateRoute>
            <BillingRuleScreen />
          </PrivateRoute>
        }
      />
      <Route
        path="/ref-data/pay-rule"
        element={
          <PrivateRoute>
            <PayRule />
          </PrivateRoute>
        }
      />
      <Route
        path="/ref-data/pay-rule/:id"
        element={
          <PrivateRoute>
            <PayRuleScreen />
          </PrivateRoute>
        }
      />
      <Route
        path="/ref-data/billing-rule/:id"
        element={
          <PrivateRoute>
            <BillingRuleScreen />
          </PrivateRoute>
        }
      />
      <Route
        path="/forms"
        element={
          <PrivateRoute>
            <Form />
          </PrivateRoute>
        }
      />
      <Route
        path="*"
        element={
          <h1
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            404 - NO ROUTES FOUND
          </h1>
        }
      />
    </Routes>
  );
};
