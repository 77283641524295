export const upperBoxArr = [
    {
        name: "Delivery Unit",
        count: "88",
        time: "9am",
        color: ""
    },
    {
        name: "Delivery Unit",
        count: "78a",
        time: "7am",
        color: ""
    },
    {
        name: "Delivery Unit",
        count: "78",
        time: "11am",
        color: "#E13D35"
    },
    {
        name: "Delivery Unit",
        count: "1",
        time: "6pm",
        color: "#97D94A"
    },
    {
        name: "Delivery Unit",
        count: "1",
        time: "5am",
        color: "#97D94A"
    },
    {
        name: "Chirstmas Delivery",
        count: "",
        time: "8am",
        color: ""
    },
];


export const upperBoxVillageArr = [
    {
        name: "Mrs.Jones",
        count: "Hospital",
        time: "9am",
        color: ""
    },
    {
        name: "Mr. Smitth",
        count: "Hospital",
        time: "7am",
        color: ""
    },
    {
        name: "Ms Citizen",
        count: "Deceased",
        time: "11am",
        color: "#E13D35"
    },
    {
        name: "Mr. Bloggs",
        count: "Holiday",
        time: "6pm",
        color: "#97D94A"
    },
    {
        name: "Mr. Jones",
        count: "Holiday",
        time: "5am",
        color: "#97D94A"
    },
    {
        name: "Mr. Jones",
        count: "",
        time: "8am",
        color: ""
    },
];
