import { SearchIcon } from "@chakra-ui/icons";
import { ReplyPlusSvg } from "../../../../Model/Assets/SVG/Common/ReplySvg";
import DeleteSVG from "../../../pages/People/ClientPage/Components/UniqueClientScreen/Components/FinanceTab/Data/DeleteSVG";
import { ReplyIcon, ServiceReply } from "../Data/SVGs";
import "../Styles/ActivityBox.css";

import MultiSelectInputBox from "./MultiSelectInputBox";
import { useState } from "react";

const ActivityBox = ({
  setselectedActivity = () => {},
  handleDeleteActivity = () => {},
  activity,
  handleActivityDataChange,
  index,
  setFocusResponsibleParties,
  goalIndex,
  goalsData,
  clientsData,
  handleServicesInputChange,
  setFocusPeople,
  searchText,
  filteredResponsibleParties,
  handleSelectService,
  showAssociatedServicesDropdown,
  serviceTypeData,
  showResponsiblePartiesDropdown,
  searchAssociatedParties,
}: any) => {
  let selectedResponsiblePeople = Array.isArray(
    goalsData[goalIndex]?.activities[index]?.responsible_parties_id
  )
    ? goalsData[goalIndex]?.activities[index]?.responsible_parties_id
    : goalsData[goalIndex]?.activities[index]?.responsible_parties_id?.split(
        ","
      );

  let selectedAssociatedServices = Array.isArray(
    goalsData[goalIndex]?.activities[index]?.associated_services_id
  )
    ? goalsData[goalIndex]?.activities[index]?.associated_services_id
    : goalsData[goalIndex]?.activities[index]?.associated_services_id?.split(
        ","
      );

  return (
    <div className="activity_box_wrapper">
      <div className="upper_section_activity">
        <ReplyPlusSvg
          onClick={() => setselectedActivity(-1)}
          color={"#9150de"}
        />
        <span className={`inner_event inner_event_activities`}>ACTIVITIES</span>

        <div className="delete_activity">
          <DeleteSVG
            onClick={() => handleDeleteActivity(activity, activity.id)}
          />
        </div>
      </div>

      <textarea
        className="activity_box_title"
        placeholder="Title"
        maxLength={255}
        value={activity?.title}
        rows={activity?.title !== "" ? activity?.title?.split(`\n`)?.length : 2}
        onChange={(e: any) => {
          handleActivityDataChange(index, "title", e.target.value);
        }}
      />
      {activity?.title?.length >= 255 && (
        <span style={{ color: "red", fontSize: "14px" }}>
          Title cannot exceed 255 characters
        </span>
      )}
      <textarea
        className="activity_box_summary"
        placeholder="Enter Summary"
        value={activity?.summary}
        rows={
          activity?.summary !== "" ? activity?.summary?.split(`\n`)?.length : 2
        }
        onChange={(e: any) => {
          handleActivityDataChange(index, "summary", e.target.value);
        }}
      />

      <MultiSelectInputBox
        icon={<ReplyIcon />}
        setFocus={setFocusPeople}
        onChange={handleServicesInputChange}
        index={index}
        type={"Responsible Parties"}
        selectType={"responsibleParties"}
        onSelect={handleSelectService}
        currentDropdown={
          showResponsiblePartiesDropdown &&
          showResponsiblePartiesDropdown[index]
        }
        options={filteredResponsibleParties}
        selectedItems={[...new Set(selectedResponsiblePeople)] || []}
        dataMatch={clientsData}
        value={searchText}
      />

      <MultiSelectInputBox
        label={"Service"}
        setFocus={setFocusResponsibleParties}
        color={"#ef3a34"}
        icon={<ServiceReply />}
        value={searchAssociatedParties}
        onChange={handleServicesInputChange}
        index={index}
        type={"Associated Services"}
        selectType={"associatedServices"}
        onSelect={handleSelectService}
        currentDropdown={
          showAssociatedServicesDropdown &&
          showAssociatedServicesDropdown[index]
        }
        options={serviceTypeData}
        selectedItems={[...new Set(selectedAssociatedServices)] || []}
        dataMatch={serviceTypeData}
      />
    </div>
  );
};

export default ActivityBox;
