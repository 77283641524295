export const boxItems = [
  { icon: "$", label: "Finance" },
  { icon: "?", label: "Upcoming" },
  { icon: ":", label: "Forms" },
];

export const circleItems = [
  { icon: "+", label: "Advanced Care Note" },
  { icon: "+", label: "New Document" },
  { icon: "+", label: "Add New Meal" },
  // { icon: "+", label: "Add New Note" },
];

export const circleItemsStickynotes = [
  { icon: "+", label: "Client" },
  { icon: "+", label: "Staff" },
  { icon: "+", label: "Prospect" },
  { icon: "+", label: "Contact" },
  { icon: "+", label: "Daybook" },
];
