import React, { Fragment, useEffect, useState } from "react";
import "./Styles/UnsavedChangesPopup.css";
import {
  deleteAssociationThunk,
  getAssociations,
  getAssociationsForEntity,
  resetConfirmDelete,
} from "../../../Controller/redux/features/CommonFeature/associationSlice";
import {
  deleteDocumentEntityThunk,
  deleteDocumentThunk,
  getDocuments,
  getDocumentsEntity,
  getUniquePrimaryApproversThunk,
  resetConfirmDeleteDocument,
} from "../../../Controller/redux/features/CommonFeature/documentSlice";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../Controller/redux/Hooks";
import { useNavigate, useParams } from "react-router-dom";

import { toast } from "react-toastify";
import {
  deleteMealThunk,
  getMealBuilder,
  resetConfirmDeleteMeal,
} from "../../../Controller/redux/features/MealsFeatures/MealFeature/mealbuilderSlice";
import {
  deleteDaybookItem,
  resetConfirmDeleteTask,
} from "../../../Controller/redux/features/DaybookFeature/daybookSlice";
import { resetConfirmDeleteMenu } from "../../../Controller/redux/features/MealsFeatures/MenuFeature/menuBuilderSlice";
import { resetConfirmDeleteMenuAssignment } from "../../../Controller/redux/features/MealsFeatures/MenuAssignmentFeature/menuAssignmentSlice";
import {
  getCustomFormsThunk,
  removeCustomFormByIdThunk,
  resetConfirmDeleteForm,
} from "../../../Controller/redux/features/CustomFromFeature/customFormSlice";

const UnsavedChangesPopup = ({ type, onConfirmClick }: any) => {
  const dispatch = useAppDispatch();

  const dynamicColor = useAppSelector((state) => state?.common?.dynamicColor);

  const pathname = window.location.pathname;
  const pathnameBusiness = pathname.includes("business");
  const pathnameTeams = pathname.includes("teams");

  const idToBeDeleted = useAppSelector(
    (state) => state?.associations?.idOfAssociationToDelete
  );
  const documentIdToBeDeleted = useAppSelector(
    (state) => state?.document?.idOfDocumentToDelete
  );
  const { id: idUrl, pro_id } = useParams();
  // const clientId = window.atob(idUrl);

  const [clientId, setClientid] = useState("");
  const [providerId, setProviderId] = useState("");

  const [associationId, setAssociationId] = useState("");
  const [documentId, setDocumentId] = useState("");

  useEffect(() => {
    if (idUrl) {
      let realId = window.atob(idUrl);
      setClientid(realId);
      if (pro_id) {
        setProviderId(window?.atob(pro_id));
      }
    }
  }, [idUrl]);

  useEffect(() => {
    if (idToBeDeleted) {
      setAssociationId(idToBeDeleted);
    }
  }, [idToBeDeleted]);

  useEffect(() => {
    if (documentIdToBeDeleted) {
      setDocumentId(documentIdToBeDeleted);
    }
  }, [documentIdToBeDeleted]);

  const handleSaveChanges = async () => {
    if (associationId) {
      const response: any = await dispatch(
        deleteAssociationThunk(associationId)
      );
      if (response?.payload?.status === "success") {
        toast.success("Association Deleted Successfully!", {
          style: {},
          progressStyle: { backgroundColor: dynamicColor },
          icon: (
            <span
              style={{
                marginRight: "8px",
                borderRadius: "50%",
                width: "7rem",
                height: "35px",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: dynamicColor,
                backgroundColor: "white",
                fontSize: "1.5rem",
                border: `3px solid ${dynamicColor}`,
              }}
            >
              ✓
            </span>
          ),
        });
      } else {
        toast.error("Failed To Delete Association!");
      }
    }
    if (pathnameBusiness || pathnameTeams) {
      dispatch(
        getAssociationsForEntity({
          clientId,
          searchVal: "",
          type: "",
          pageNumber: 1,
        })
      );
    } else {
      dispatch(
        getAssociations({ clientId, searchVal: "", type: "", pageNumber: 1 })
      );
    }

    dispatch(resetConfirmDelete());
  };

  const handleSaveChangesDocument = async () => {
    if (documentId && !(pathnameBusiness || pathnameTeams)) {
      const response: any = await dispatch(
        deleteDocumentThunk({ documentId, clientId, providerId })
      );
      if (response?.payload?.status === "success") {
        toast.success("Document Deleted Successfully!", {
          style: {},
          progressStyle: { backgroundColor: dynamicColor },
          icon: (
            <span
              style={{
                marginRight: "8px",
                borderRadius: "50%",
                width: "7rem",
                height: "35px",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: dynamicColor,
                backgroundColor: "white",
                fontSize: "1.5rem",
                border: `3px solid ${dynamicColor}`,
              }}
            >
              ✓
            </span>
          ),
        });
        dispatch(getUniquePrimaryApproversThunk(clientId));
      } else {
        toast.error("Failed To Delete Document!");
      }
      dispatch(getDocuments({ clientId, providerId }));
    }
    if (documentId && pathnameBusiness) {
      const response: any = await dispatch(
        deleteDocumentEntityThunk({
          documentId,
          clientId,
          providerId,
        })
      );
      if (response?.payload?.status === "success") {
        toast.success("Document Deleted Successfully!", {
          style: {},
          progressStyle: { backgroundColor: dynamicColor },
          icon: (
            <span
              style={{
                marginRight: "8px",
                borderRadius: "50%",
                width: "7rem",
                height: "35px",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: dynamicColor,
                backgroundColor: "white",
                fontSize: "1.5rem",
                border: `3px solid ${dynamicColor}`,
              }}
            >
              ✓
            </span>
          ),
        });
        dispatch(getUniquePrimaryApproversThunk(clientId));
      } else {
        toast.error("Failed To Delete Document!");
      }
      dispatch(getDocumentsEntity({ clientId, providerId }));
    }
    if (documentIdToBeDeleted && pathnameTeams) {
      const response: any = await dispatch(
        deleteDocumentEntityThunk({
          documentId,
          clientId,
          providerId,
        })
      );
      if (response?.payload?.status === "success") {
        toast.success("Document Deleted Successfully!", {
          style: {},
          progressStyle: { backgroundColor: dynamicColor },
          icon: (
            <span
              style={{
                marginRight: "8px",
                borderRadius: "50%",
                width: "7rem",
                height: "35px",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: dynamicColor,
                backgroundColor: "white",
                fontSize: "1.5rem",
                border: `3px solid ${dynamicColor}`,
              }}
            >
              ✓
            </span>
          ),
        });
        dispatch(getUniquePrimaryApproversThunk(clientId));
      } else {
        toast.error("Failed To Delete Document!");
      }
      dispatch(getDocumentsEntity({ clientId, providerId }));
    }

    dispatch(resetConfirmDeleteDocument());
  };

  const mealIdToBeDeleted = useAppSelector(
    (state) => state?.mealBuilder?.idOfMealToDelete
  );

  const [mealId, setMealId] = useState("");

  useEffect(() => {
    if (mealIdToBeDeleted) {
      setMealId(mealIdToBeDeleted);
    }
  }, [mealIdToBeDeleted]);

  const handleSaveChangesMeal = async () => {
    if (mealIdToBeDeleted) {
      const response: any = await dispatch(deleteMealThunk({ mealId }));
      if (response?.payload?.status === "success") {
        toast.success("Meal Deleted Successfully!", {
          progressStyle: { backgroundColor: dynamicColor },
          icon: (
            <span
              style={{
                marginRight: "8px",
                borderRadius: "50%",
                width: "7rem",
                height: "35px",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: dynamicColor,
                backgroundColor: "white",
                fontSize: "1.5rem",
                border: `3px solid ${dynamicColor}`,
              }}
            >
              ✓
            </span>
          ),
        });
      } else {
        toast.error("Failed To Delete Meal!");
      }
    }
    dispatch(getMealBuilder());
    dispatch(resetConfirmDeleteMeal());
  };

  const { idOfFormToDelete } = useAppSelector((state) => state?.customForm);

  // @ts-ignore
  const userDetails =JSON.parse(localStorage.getItem("user"));
  const userId = userDetails?.data?.id;
  const handleSaveChangesForm = async () => {
    if (idOfFormToDelete) {
      const response: any = await dispatch(
        removeCustomFormByIdThunk({ custom_form_id: idOfFormToDelete })
      );
      if (response?.payload?.status === "success") {
        toast.success("Form Deleted Successfully!", {
          style: {},
          progressStyle: { backgroundColor: dynamicColor },
          icon: (
            <span
              style={{
                marginRight: "8px",
                borderRadius: "50%",
                width: "7rem",
                height: "35px",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: dynamicColor,
                backgroundColor: "white",
                fontSize: "1.5rem",
                border: `3px solid ${dynamicColor}`,
              }}
            >
              ✓
            </span>
          ),
        });
      } else {
        toast.error("Failed To Delete Form!");
      }
    }
    dispatch(getCustomFormsThunk(userId));
    dispatch(resetConfirmDeleteForm());
  };

  return (
    <Fragment>
      {type === "associations" && (
        <div
          className="save"
          //   style={{
          //     top: 778,
          //   }}
          style={{
            position: "initial",
            marginLeft: "auto",
            marginRight: "200px",
            marginTop: 5,
          }}
        >
          <p>Are you sure you want to delete this association?</p>
          <div className="btnDivision">
            <button
              onClick={handleSaveChanges}
              style={{
                backgroundColor: dynamicColor,
              }}
            >
              Delete
            </button>
            <button
              onClick={() => dispatch(resetConfirmDelete())}
              style={{
                fontFamily: "Helvetica",
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: "16px",
                lineHeight: "18px",
                backgroundColor: "#F9F9F9",
                border: "none",
                color: "#9296A2",
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      )}

      {type === "documents" && (
        <div
          className="save"
          style={{
            marginLeft: "auto",
            marginRight: "200px",
            marginTop: 5,
            position: "absolute",
            bottom: "0px",
            right: "5%",
          }}
        >
          <p>Are you sure you want to delete this document?</p>
          <div className="btnDivision">
            <button
              onClick={handleSaveChangesDocument}
              style={{
                backgroundColor: dynamicColor,
              }}
            >
              Delete
            </button>
            <button
              className="cancel-btn"
              onClick={() => dispatch(resetConfirmDeleteDocument())}
            >
              Cancel
            </button>
          </div>
        </div>
      )}

      {type === "mealBuilder" && (
        <div
          className="save"
          style={{
            position: "initial",
            marginLeft: "auto",
            marginRight: "200px",
            marginTop: 5,
          }}
        >
          <p>Are you sure you want to delete this meal?</p>
          <div className="btnDivision">
            <button
              onClick={handleSaveChangesMeal}
              style={{
                backgroundColor: dynamicColor,
              }}
            >
              Delete
            </button>
            <button
              className="cancel-btn"
              onClick={() =>{ dispatch(resetConfirmDeleteMenu())
                dispatch(resetConfirmDeleteMeal());


                
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      )}

      {type === "customForm" && (
        <div
          className="save"
          style={{
            position: "initial",
            marginLeft: "auto",
            marginRight: "200px",
            marginTop: 5,
          }}
        >
          <p>Are you sure you want to delete this form?</p>
          <div className="btnDivision">
            <button
              onClick={handleSaveChangesForm}
              style={{
                backgroundColor: dynamicColor,
              }}
            >
              Delete
            </button>
            <button
              className="cancel-btn"
              onClick={() => dispatch(resetConfirmDeleteForm())}
            >
              Cancel
            </button>
          </div>
        </div>
      )}

      {type === "daybook" && (
        <div
          className="save"
          style={{
            position: "initial",
            marginLeft: "auto",
            marginRight: "200px",
            marginTop: 5,
          }}
        >
          <p>Are you sure you want to delete this task?</p>
          <div className="btnDivision">
            <button
              onClick={onConfirmClick}
              style={{
                backgroundColor: dynamicColor,
              }}
            >
              Delete
            </button>
            <button
              className="cancel-btn"
              onClick={() => dispatch(resetConfirmDeleteTask())}
            >
              Cancel
            </button>
          </div>
        </div>
      )}
      {type === "menuBuilder" && (
        <div
          className="save_popup"
          // style={{ position: "absolute", left: "58%", bottom: "2%" }}
        >
          <p>Are you sure you want to delete this Menu?</p>
          <div className="btnDivision">
            <button
              onClick={onConfirmClick}
              style={{
                backgroundColor: dynamicColor,
              }}
            >
              Delete
            </button>
            <button
              className="cancel-btn"
              onClick={() => dispatch(resetConfirmDeleteMenu())}
            >
              Cancel
            </button>
          </div>
        </div>
      )}
      {type === "menuAssignment" && (
        <div
          className="save_popup"
          // style={{ position: "absolute", left: "58%", bottom: "2%" }}
        >
          <p>Are you sure you want to delete this Menu Assignment?</p>
          <div className="btnDivision">
            <button
              onClick={onConfirmClick}
              style={{
                backgroundColor: dynamicColor,
              }}
            >
              Delete
            </button>
            <button
              className="cancel-btn"
              onClick={() => dispatch(resetConfirmDeleteMenuAssignment())}
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default UnsavedChangesPopup;
