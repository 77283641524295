import React, { useState } from "react";

const PlusIcon = () => {
  const [isHover, setIsHover] = useState<boolean>(false);

  return (
    <div>
      <svg
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="6" cy="6" r="6" fill="#F3F3F3" />
        <path
          d="M6.83126 5.22148H9.99609V6.79642H6.83126V10H5.16093V6.79642H1.99609V5.22148H5.16093V2H6.83126V5.22148Z"
          fill={isHover ? "#87CEFA" : "#ffffff"}
        />
      </svg>
    </div>
  );
};

export default PlusIcon;

export const PlusIconWhite = ({
  width = "12",
  height = "12",
  fill = "#f3f3f3",
  color = "#f3f3f3",
  className,
}: any) => {
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 12 12"
        fill={color}
      >
        <circle cx="6" cy="6" r="6" fill={fill} />
        <path
          d="M6.83516 5.22148H10V6.79642H6.83516V10H5.16484V6.79642H2V5.22148H5.16484V2H6.83516V5.22148Z"
          fill={color}
        />
      </svg>
    </div>
  );
};
