import { useEffect, useState } from "react";

import { api } from "../../../../Controller/services/api";
import { toast } from "react-toastify";
import {
  useAppSelector,
  useAppDispatch,
} from "../../../../Controller/redux/Hooks";
import {
  getProvidersByFilterThunk,
  getProvidersThunk,
  resetProvidersData,
  resetProvidersPage,
} from "../../../../Controller/redux/features/ProviderFeature/providerSlice";
import {
  resetShowStickyNote,
  setProfileTypeSticky,
  setShowStickyNote,
} from "../../../../Controller/redux/features/CommonFeature/commonSlice";
import _ from "lodash";
import {resetcurrentEditCustomForm} from "../../../../Controller/redux/features/CustomFromFeature/customFormSlice";

interface BusinessAndTeamFormProps {
  type: any;
  currentTitle?: string;
  activeTabProfile?: string;
  comp?: any;
  // setShowStickyNote: any;
}

const ProvidersForm: React.FC<BusinessAndTeamFormProps> = ({
  type,
  currentTitle,
  activeTabProfile,
  comp,
  // setShowStickyNote,
}) => {
  // const isAdmin = useAppSelector(
  //   (state) => state?.common?.user?.roles[0]?.desc
  // );
  // let isAdminActive = isAdmin === "Administrator";
  const { user } = useAppSelector((state) => state.common);
  const isAdminActive = user?.tenant?.id === user?.tenant?.parent_id;
  const [providersData, setProvidersData] = useState({
    provider_name: "",
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    provider_type: "",
  });
  const dispatch = useAppDispatch();
  //@ts-ignore
  const dynamicColor = useAppSelector((state) => state?.common?.dynamicColor);

  // @ts-ignore
  const dynamicBusinessId = parseInt(localStorage.getItem("dynamicBusinessId"));
  // @ts-ignore
  const [IsLoading, setIsLoading] = useState(false);
  let isPending = false;
  const handleAddProviders = async (e: any) => {
    e.preventDefault();
    const url = "/providers/create_provider";
    if (
      providersData?.provider_name === "" ||
      providersData?.first_name === "" ||
      providersData?.last_name === "" ||
      providersData?.email === "" ||
      providersData?.phone === "" ||
      providersData?.provider_type === ""
    ) {
      toast.error("All Fields Required!");
      return;
    }
    if (!providersData?.email.includes("@")) {
      toast.warn("Enter Valid Email!");
      return;
    }
    if (isPending) return;
    try {
      isPending = true;
      setIsLoading(true);

      const response = await api.post(url, providersData);
      if (response.data.status === "success") {
        isPending = false;
        setIsLoading(false);
        toast.success("Provider added successfully!", {
          style: {},
          progressStyle: { backgroundColor: dynamicColor },
          icon: (
            <span
              style={{
                marginRight: "8px",
                borderRadius: "50%",
                width: "7rem",
                height: "35px",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: dynamicColor,
                backgroundColor: "white",
                fontSize: "1.5rem",
                border: `3px solid ${dynamicColor}`,
              }}
            >
              ✓
            </span>
          ),
        });
      }
      // dispatch(getProvidersThunk());
      dispatch(resetProvidersData());
      dispatch(
        getProvidersByFilterThunk({
          status: 1,
          filterColumn: "",
          filterValue: "",
          searchVal: "",
          sortType: "",
          sortColumn: "",
          providersPage: "1",
        })
      );
      dispatch(resetProvidersPage());
      const stickyNoteElement = document.querySelector(
        ".new_sticky_note_wrapper"
      );
      if (stickyNoteElement) {
        stickyNoteElement.classList.add("fade_out");
        setTimeout(() => {
          dispatch(setShowStickyNote(false));
        }, 300);
      }
      setIsLoading(false);
    } catch (error: any) {
      toast.error(error.message);
      setIsLoading(false);
    }
    dispatch(resetShowStickyNote());
    dispatch(resetcurrentEditCustomForm());
  };

  const debouncedApiCall = _.debounce(handleAddProviders, 300);

  // Attach the debounced function to your button click event
  useEffect(() => {
    const handleClick = (e: any) => {
      debouncedApiCall(e);
    };

    document?.getElementById("saveBTN")?.addEventListener("click", handleClick);

    return () => {
      document
        ?.getElementById("saveBTN")
        ?.removeEventListener("click", handleClick);
    };
  }, [debouncedApiCall]);
  const { profile_name } = useAppSelector((state) => state.common.domainDetail);
  const {
    prospects_label,
    clients_label,
    business_label,
    locations_label,
    teams_label,
    contacts_label,
    staff_label,
  } = useAppSelector((state) => state?.common?.domainDetail);
  const { getProfileTypeSticky } = useAppSelector((state) => state?.common);
  return (
    <>
      {activeTabProfile === profile_name && (
        <div
          className="payload_area"
          style={{
            height: "402px",
          }}
        >
          <div className="main_area">
            <div className="side_bar" />
            <div className="side_bar" />
            <div className="middle-block">
              <p className="note-title">
                New {isAdminActive ? profile_name : "Providers"}
              </p>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                  transform: "translateY(-5%)",
                }}
                className="middle-section-content "
              >
                {" "}
                <div className="field">
                  <span className="field-title">
                    {profile_name} Type{" "}
                    <span className="asterixs_color"> * </span>
                  </span>
                  <select
                    className="field-type custom-select-2"
                    style={{
                      backgroundColor: "white",
                    }}
                    onChange={(e) =>
                      dispatch(setProfileTypeSticky(e?.target?.value))
                    }
                    value={getProfileTypeSticky}
                  >
                    {[
                      prospects_label,
                      clients_label,
                      business_label,
                      locations_label,
                      teams_label,
                      contacts_label,
                      staff_label,
                    ]?.map((elem: string) => {
                      return (
                        <option key={elem} value={elem}>
                          {elem}
                        </option>
                      );
                    })}
                  </select>
                  <span className="field-border field-border-add-form"></span>
                </div>
                <div className="field">
                  <span className="field-title">
                    {" "}
                    Client Name <span className="asterixs_color"> * </span>
                  </span>
                  <input
                    style={{
                      backgroundColor: "white",
                    }}
                    className="input-fields-of-sticky-note"
                    placeholder="Enter Client Name"
                    onChange={(e) =>
                      setProvidersData((prevState) => ({
                        ...prevState,
                        provider_name: e.target.value,
                      }))
                    }
                    value={providersData.provider_name}
                  />
                  <span className="field-border field-border-add-form"></span>
                </div>
                <div className="field">
                  <span className="field-title">
                    Client Type <span className="asterixs_color"> * </span>
                  </span>

                  <select
                    name="select_url"
                    id="select_url"
                    value={providersData.provider_type}
                    onChange={(e) => {
                      setProvidersData((prevState: any) => ({
                        ...prevState,
                        provider_type: e.target.value,
                      }));
                    }}
                  >
                    <option value="">Please Select...</option>
                    {[
                      "Home Care",
                      "Residential Aged Care",
                      "SIL",
                      "Village",
                    ].map((elem: string) => {
                      return (
                        <>
                          <option value={elem}>{elem}</option>
                        </>
                      );
                    })}
                  </select>

                  <span className="field-border field-border-add-form"></span>
                </div>
                <div className="field">
                  <span className="field-title">
                    Admin First Name <span className="asterixs_color"> * </span>
                  </span>
                  <input
                    style={{
                      backgroundColor: "white",
                    }}
                    className="input-fields-of-sticky-note"
                    placeholder="Enter Admin First Name"
                    onChange={(e) =>
                      setProvidersData((prevState) => ({
                        ...prevState,
                        first_name: e.target.value,
                      }))
                    }
                    value={providersData.first_name}
                  />

                  <span className="field-border field-border-add-form"></span>
                </div>
                <div className="field">
                  <span className="field-title">
                    Admin Last Name <span className="asterixs_color"> * </span>
                  </span>
                  <input
                    style={{
                      backgroundColor: "white",
                    }}
                    className="input-fields-of-sticky-note"
                    placeholder="Enter Admin Last Name"
                    onChange={(e) =>
                      setProvidersData((prevState) => ({
                        ...prevState,
                        last_name: e.target.value,
                      }))
                    }
                    value={providersData.last_name}
                  />
                  <span className="field-border field-border-add-form"></span>
                </div>
                <div className="field">
                  <span className="field-title">
                    Enter Admin Email{" "}
                    <span className="asterixs_color"> * </span>
                  </span>
                  <input
                    style={{
                      backgroundColor: "white",
                    }}
                    className="input-fields-of-sticky-note"
                    placeholder="Enter Admin Email"
                    onChange={(e) =>
                      setProvidersData((prevState) => ({
                        ...prevState,
                        email: e.target.value,
                      }))
                    }
                    value={providersData.email}
                  />
                  <span className="field-border field-border-add-form"></span>
                </div>
                <div className="field">
                  <span className="field-title">
                    Mobile <span className="asterixs_color"> * </span>
                  </span>
                  <input
                    style={{
                      backgroundColor: "white",
                    }}
                    className="input-fields-of-sticky-note"
                    placeholder="Enter Mobile"
                    onChange={(e) =>
                      setProvidersData((prevState) => ({
                        ...prevState,
                        phone: e.target.value,
                      }))
                    }
                    value={providersData.phone}
                  />

                  <span className="field-border field-border-add-form"></span>
                </div>
              </div>
            </div>
          </div>
          <div className="bottomBlock">
            <button
              className="save-btn"
              style={{
                position: "relative",
              }}
              id="saveBTN"
              disabled={IsLoading}
              // onClick={handleAddProviders}
            >
              {IsLoading ? "Loading..." : "Save"}
            </button>
          </div>
        </div>
      )}
      {activeTabProfile === "Comms" && comp}
    </>
  );
};

export default ProvidersForm;
