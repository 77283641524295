import { Fragment, useEffect, useRef, useState } from "react";

import {
  useAppDispatch,
  useAppSelector,
} from "../../../../Controller/redux/Hooks";
import {
  getTeamsAssociateThunk,
  getTeamsByFilterThunk,
  getTeamsEmailThunk,
  getTeamsNameThunk,
  getTeamsPhoneThunk,
  getTeamsThunk,
  resetTeamsData,
  resetTeamsPage,
  setTeamsPage,
  setUserTypeIdForTeams,
} from "../../../../Controller/redux/features/TeamsFeature/teamsSlice";
import {
  getEntityTypesThunk,
  getStatusDetailsThunk,
  resetStatusDetails,
  setProfileTypeSticky,
} from "../../../../Controller/redux/features/CommonFeature/commonSlice";
import CircularNoteTrigger from "../../../components/CircularNoteTrigger/CircularNoteTrigger";
import CommonUpperBox from "../../../components/UpperBox/CommonHeader";
import CommonQuickMenu from "../../../components/CommonQuickMenu/CommonQuickMenu";
import Table from "../../../components/CommonTable/Table";
import { useNavigate } from "react-router-dom";

const TeamsPage = () => {
  const tableRef = useRef();
  const teamsInnerRef = useRef();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { allDataTeams, TeamsPage, TeamsFilterItems, teams, lastPage } =
    useAppSelector((state) => state?.teams);
  // @ts-ignore
  const dynamicTeamsId = parseInt(localStorage.getItem("dynamicTeamsId"));

  const dropdownRefTeams = useRef<any>();
  const svgParentRefTeams = useRef<any>();
  const [isDropdownOpenTeams, setIsDropdownOpenTeams] = useState(false);
  const { selectedFilterItemsTeams, sortByTeamsR, searchValTeams, loading } =
    useAppSelector((state) => state?.teams);
  const { filterIconColor } = useAppSelector((state) => state?.common);

  useEffect(() => {
    dispatch(getTeamsNameThunk(dynamicTeamsId));
    dispatch(getTeamsEmailThunk(dynamicTeamsId));
    dispatch(getTeamsPhoneThunk(dynamicTeamsId));
    dispatch(getTeamsAssociateThunk(dynamicTeamsId));
  }, [dynamicTeamsId]);

  useEffect(() => {
    dispatch(getEntityTypesThunk());
    localStorage.removeItem("activeTab");
  }, []);
  useEffect(() => {
    dispatch(resetTeamsData());
    dispatch(resetTeamsPage());
  }, [
    TeamsFilterItems?.searchVal,
    TeamsFilterItems?.filterValue,
    TeamsFilterItems?.sortType,
    TeamsFilterItems?.status,
    dynamicTeamsId,
  ]);
  useEffect(() => {
    dispatch(
      getTeamsByFilterThunk({
        filterColumn: TeamsFilterItems?.filterColumn
          ? TeamsFilterItems?.filterColumn
          : "",
        filterValue: TeamsFilterItems?.filterValue
          ? TeamsFilterItems?.filterValue
          : "",
        searchVal: TeamsFilterItems?.searchVal
          ? TeamsFilterItems?.searchVal
          : "",
        sortColumn: TeamsFilterItems?.sortColumn
          ? TeamsFilterItems?.sortColumn
          : "",
        sortType: TeamsFilterItems?.sortType ? TeamsFilterItems?.sortType : "",
        TeamsPage,
        dynamicId: dynamicTeamsId,
        status: TeamsFilterItems.status ? TeamsFilterItems.status : "",
      })
    );
  }, [
    TeamsPage,
    TeamsFilterItems?.searchVal,
    TeamsFilterItems?.filterValue,
    TeamsFilterItems?.sortType,
    TeamsFilterItems?.status,
    dynamicTeamsId,
  ]);
  // useEffect(() => {
  //   dispatch(getTeamsThunk(dynamicTeamsId));
  // }, [dynamicTeamsId]);
  const [stageTwoToGoalsModal, setstageTwoToGoalsModal] = useState(false);

  const handleClickRow = (e: any, id: any) => {
    if (!isDropdownOpenTeams) {
      navigate(`/people/teams/${window.btoa(id)}`);
    }
  };

  const { teams_label } = useAppSelector(
    (state) => state?.common?.domainDetail
  );

  useEffect(() => {
    const handleClickOutsideTeams = (event: any) => {
      if (
        dropdownRefTeams.current &&
        !dropdownRefTeams.current.contains(event.target) &&
        svgParentRefTeams.current &&
        !svgParentRefTeams.current.contains(event.target)
      ) {
        setIsDropdownOpenTeams(false);
      }
    };
    document.addEventListener("click", handleClickOutsideTeams);
    return () => {
      document.removeEventListener("click", handleClickOutsideTeams);
    };
  }, []);

  const columns = [
    { header: "Name", accessor: "name" },
    { header: "Email", accessor: "email" },
    { header: "Phone", accessor: "phone" },
    { header: "Associated Business", accessor: "business" },
    { header: "Status", accessor: "status" },
  ];
  const { domainDetail } = useAppSelector((state) => state?.common);
  const onScroll = () => {
    if (teamsInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = teamsInnerRef.current;

      if (
        scrollTop + clientHeight + +5 >= scrollHeight &&
        TeamsPage < lastPage
      ) {
        dispatch(setTeamsPage(TeamsPage));
      }
    }
  };
  const uniqueTeamsData = teams?.filter(
    (item: any, index: number, array: any) => {
      return array?.findIndex((obj: any) => obj?.id === item?.id) === index;
    }
  ); ///////////////////////////////////////////////////////////////////////////////////////
  const teamsStatusDetailsNums = useAppSelector(
    (state) => state.common.domainDetail.team_status
  );
  const teamsStatusDetailsArr = useAppSelector(
    (state) => state.common.statusDetailsData?.data
  );
  // // console.log(clientStatusDetailsArr,"clientStatusDetailsArr")
  useEffect(() => {
    dispatch(resetStatusDetails());
    dispatch(getStatusDetailsThunk(teamsStatusDetailsNums));
  }, [teamsStatusDetailsNums]);
  useEffect(() => {
    dispatch(setProfileTypeSticky(teams_label));
  }, [teams_label]);
  const { profile_name } = useAppSelector((state) => state.common.domainDetail);
  ///////////////////////////////////////////////////////////////////////////////////////
  return (
    <Fragment>
   <div
        style={{
          position: "relative",
        }}
      >
        {stageTwoToGoalsModal && (
          <CommonQuickMenu
            setshowGoalsModal={(value: any) => {}}
            mainType={"listing"}
            setstageTwoToGoalsModal={setstageTwoToGoalsModal}
          />
        )}
        <CommonUpperBox
          onClickPlus={() => {
            setstageTwoToGoalsModal(true);
          }}
          type={"listing"}
          title={teams_label}
        />
      </div>
      {/* <CommonTable type="teams" allClients={allDataTeams?.data?.data} /> */}
      <Table
        isLoading={loading}
        itemsArr={teamsStatusDetailsArr}
        tableRef={tableRef}
        tableBodyRef={teamsInnerRef}
        onScroll={onScroll}
        dynamicHeaderColor={domainDetail?.listingtbl_color}
        statusType="teamsScreen"
        thData={columns}
        classNameTr="all-contacts-table-head"
        type="teams"
        dropdownRef={dropdownRefTeams}
        isDropdownOpen={isDropdownOpenTeams}
        onClickSvg={() => setIsDropdownOpenTeams(true)}
        svgParentRef={svgParentRefTeams}
        allClients={uniqueTeamsData}
        // tableRef={}
        onClickRow={handleClickRow}
        classNameRow="all-contacts-table-head"
        fill={
          selectedFilterItemsTeams.length > 0 ||
          searchValTeams.length > 0 ||
          sortByTeamsR.length > 0
            ? filterIconColor
            : "#D8D3D3"
        }
      />
      <CircularNoteTrigger
        type={teams_label}
        //  title={teams_label}
        title={profile_name}
      />
  
    </Fragment>
  );
};

export default TeamsPage;
