import { api } from "../../../services/api";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  getProviders: [],
  getUniqueProvider: null,

  searchValueProviders: "",
  selectedFilterOptionsProviders: [],
  selectedDropdownItemProviders: "",
  selectedItemIndexFilterProviders: "",
  selectedItemIndexSortProviders: "",
  sortByProviderR: "",
  selectedFilterItemsProviders: [],
  loading: false,
  getProvidersUniqueTenantName: [],
  getProvidersUniqueTenantType: [],
  lastPage: 10000,
  providersPage: 1,
  providersFilterItems: {
    status: "1",
    filterColumn: "",
    filterValue: "",
    searchVal: "",
    sortColumn: "",
    sortType: "",
    providersPage: 1,
  },
};

export const getProvidersThunk = createAsyncThunk(
  "providers/providersThunk",
  async () => {
    try {
      const { data } = await api.get(
        `/providers?paginate=true&page=1&limit=10000`
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
);
export const getProvidersUniqueTenantNameThunk = createAsyncThunk(
  "providers/providersTenantNameThunk",
  async () => {
    try {
      const { data } = await api.get(`/providers?unique_column=tenant_name`);
      return data;
    } catch (error) {
      throw error;
    }
  }
);
export const getProvidersUniqueTenantTypeThunk = createAsyncThunk(
  "providers/providersTenantTypeThunk",
  async () => {
    try {
      const { data } = await api.get(`/providers?unique_column=tenant_type`);
      return data;
    } catch (error) {
      throw error;
    }
  }
);

export const getProvidersByFilterThunk = createAsyncThunk(
  "providers/providersByFilterThunk",
  async ({
    filterColumn,
    filterValue,
    searchVal,
    sortColumn,
    sortType,
    status,
    providersPage,
  }: any) => {
    const encodedFilterValue = encodeURIComponent(filterValue);
    const encodedSearchValue = encodeURIComponent(searchVal);
    try {
      const { data } = await api.get(
        `/providers?paginate=true&page=${1}&limit=500000&sort_column=${sortColumn}&sort_type=${sortType}&filter_column=${filterColumn}&status=${status}&filter_value=${encodedFilterValue}&search=${encodedSearchValue}`
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
);

export const getUniqueProviderThunk = createAsyncThunk(
  "providers/uniqueProviderThunk",
  async ({ id }: number | any) => {
    try {
      const { data } = await api.get(
        `/providers?paginate=true&page=1&limit=10000&id=${id}`
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
);

export const updateProviderThunk = createAsyncThunk(
  "providers/updateProviderThunk",
  async (staffData: any, { rejectWithValue }) => {
    try {
      const response = await api.post("/providers/update_provider", staffData);
      return response.data;
    } catch (error: string | any) {
      return rejectWithValue(
        error.message || "Failed to upload client details"
      );
    }
  }
);
export const providerSlice = createSlice({
  name: "providers",
  initialState,
  reducers: {
    clearCurrentProviderData: (state) => {
      state.getUniqueProvider = null;
    },

    setinputSearchValProviders: (state, action) => {
      state.searchValueProviders = action.payload;
    },
    resetinputSearchValProviders: (state, action) => {
      state.searchValueProviders = "";
    },
    setselectedFilterOptionsProviders: (state, acton) => {
      state.selectedFilterOptionsProviders = acton.payload;
    },
    resetselectedFilterOptionsProviders: (state, acton) => {
      state.selectedFilterOptionsProviders = null;
    },
    setSelectedDropdownItemProviders: (state, action) => {
      state.selectedDropdownItemProviders = action.payload;
    },
    resetSelectedDropdownItemProviders: (state) => {
      state.selectedDropdownItemProviders = "";
    },
    setSelectedItemIndexFilterProviders: (state, action) => {
      state.selectedItemIndexFilterProviders = action.payload;
    },
    resetSelectedItemIndexFilterProviders: (state) => {
      state.selectedItemIndexFilterProviders = "";
    },
    setSelectedItemIndexSortProviders: (state, action) => {
      state.selectedItemIndexSortProviders = action.payload;
    },
    resetSelectedItemIndexSortProviders: (state) => {
      state.selectedItemIndexSortProviders = "";
    },
    setsortByProviderR: (state, acton) => {
      state.sortByProviderR = acton.payload;
    },
    resetsortByProviderR: (state) => {
      state.sortByProviderR = "";
    },
    setSelectedFilterItemsProviders: (state, action) => {
      state.selectedFilterItemsProviders = action.payload;
    },
    resetSelectedFilterItemsProviders: (state) => {
      state.selectedFilterItemsProviders = "";
    },
    // Filter End

    // Pagination Start
    setProvidersPage: (state) => {
      state.providersPage += 1;
    },
    resetProvidersPage: (state) => {
      state.providersPage = 1;
    },
    resetProvidersData: (state) => {
      state.getProviders = [];
    },
    setProvidersFilterItems: (state, action) => {
      state.providersFilterItems = action.payload;
    },
    resetProvidersFilterItems: (state) => {
      state.providersFilterItems = {
        status: "1",
        filterColumn: "",
        filterValue: "",
        searchVal: "",
        sortColumn: "",
        sortType: "",
        providersPage: 1,
      };
    },
    // Pagination End
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProvidersThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProvidersThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.getProviders = action.payload;
        state.allProviders = action.payload;
      })
      .addCase(getProvidersThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getUniqueProviderThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUniqueProviderThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.getUniqueProvider = action.payload;
      })
      .addCase(getProvidersByFilterThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProvidersByFilterThunk.fulfilled, (state, action) => {
        state.loading = false;
        // [(state.getProviders = action.payload)];
        // state.getProviders = [
        //   ...state.getProviders,
        //   ...action.payload.data.data,
        // ];
        state.getProviders = action.payload.data.data;

        state.lastPage = action.payload.data.last_page;
      })
      .addCase(getProvidersByFilterThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getUniqueProviderThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getProvidersUniqueTenantNameThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProvidersUniqueTenantNameThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.getProvidersUniqueTenantName = action.payload.data.data;
      })
      .addCase(getProvidersUniqueTenantNameThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getProvidersUniqueTenantTypeThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProvidersUniqueTenantTypeThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.getProvidersUniqueTenantType = action.payload.data.data;
      })
      .addCase(getProvidersUniqueTenantTypeThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const {
  clearCurrentProviderData,
  // Filter Start
  setinputSearchValProviders,
  resetinputSearchValProviders,
  setselectedFilterOptionsProviders,
  resetselectedFilterOptionsProviders,
  setSelectedDropdownItemProviders,
  resetSelectedDropdownItemProviders,
  setSelectedItemIndexFilterProviders,
  resetSelectedItemIndexFilterProviders,
  setSelectedItemIndexSortProviders,
  resetSelectedItemIndexSortProviders,
  setsortByProviderR,
  resetsortByProviderR,
  setSelectedFilterItemsProviders,
  resetSelectedFilterItemsProviders,

  setProvidersPage,
  resetProvidersPage,
  resetProvidersData,
  setProvidersFilterItems,
  resetProvidersFilterItems,
} = providerSlice.actions;

export default providerSlice.reducer;
