import React, { CSSProperties } from "react";

interface DeleteSVGProps {
  style?: CSSProperties;
  onClick: any;
  width?: string;
  height?: string;
  className?: string;
  fill?: string;
}

const DeleteSVG = ({
  style,
  onClick,
  height,
  width,
  className,
  fill,
}: DeleteSVGProps) => {
  return (
    <svg
    
      width={width}
      height={height}
      viewBox="0 0 10 12"
      style={style}
      fill={fill || "none"}
      xmlns="http://www.w3.org/2000/svg"
      className={`${className} comms_svg_icon`}
      onClick={() => {
        onClick();
      }}
    >
      <path
        d="M0.670807 10.6667C0.670807 11.4 1.27453 12 2.01242 12H7.37888C8.11677 12 8.7205 11.4 8.7205 10.6667V2.66667H0.670807V10.6667ZM9.3913 0.666667H7.04348L6.37267 0H3.01863L2.34783 0.666667H0V2H9.3913V0.666667Z"
        fill={fill || "black"}
      />
    </svg>
  );
};

DeleteSVG.defaultProps = {
  style: {},
  onClick: () => {},
  height: "12",
  width: "12",
  className: "",
};

export default DeleteSVG;
