const btnArr = [
  "Details",
  "Associations",
  "Clinical",
  "Comms",
  "Documents",
  "Finance",
  "Daybook",
];

export const personalDetails = [
  {
    UpperTitle: "Given Name:",
    LowerTitle: "",
    type: "text",
    index: 0,
  },
  {
    UpperTitle: "Middle Name(s):",
    LowerTitle: "",
    type: "text",
    index: 1,
  },

  {
    UpperTitle: "Family Name:",
    LowerTitle: "",
    type: "text",
    index: 3,
  },
  {
    UpperTitle: "Known As:",
    LowerTitle: "",
    type: "text",
    index: 4,
  },
  {
    UpperTitle: "Address:",
    LowerTitle: "",
    type: "text",
    index: 5,
  },
  {
    UpperTitle: "Email:",
    LowerTitle: "",
    type: "text",
    index: 6,
  },
  {
    UpperTitle: "Phone:",
    LowerTitle: "",
    type: "text",
    index: 7,
  },
  {
    UpperTitle: "Mobile:",
    LowerTitle: "",
    type: "text",
    index: 8,
  },
];

export const dummyArr = [
  {
    UpperTitle: "Given Name:",
    LowerTitle: "",
    placeHolder: "Enter Given Name",
  },
  {
    UpperTitle: "Middle Name(s):",
    LowerTitle: "",
    placeHolder: "Enter Middle Name",
  },

  {
    UpperTitle: "Family Name:",
    LowerTitle: "",
    placeHolder: "Enter Family Name",
  },
  {
    UpperTitle: "Known As:",
    LowerTitle: "",
    placeHolder: "Enter Known As",
  },
  {
    UpperTitle: "Address:",
    LowerTitle: "",
    placeHolder: "Enter Address",
  },
  {
    UpperTitle: "Email:",
    LowerTitle: "",
    placeHolder: "Enter Email",
  },
  {
    UpperTitle: "Phone:",
    LowerTitle: "",
    placeHolder: "Enter Phone",
  },
  {
    UpperTitle: "Mobile:",
    LowerTitle: "",
    placeHolder: "Enter Mobile",
  },
  {
    UpperTitle: "Date of Birth:",
    LowerTitle: "",
    placeHolder: "Enter Date of Birth",
  },
  {
    UpperTitle: "Funding Type:",
    LowerTitle: "",
    placeHolder: "Enter Funding Type",
  },
  {
    UpperTitle: "Package Level:",
    LowerTitle: "",
    placeHolder: "Enter Package Level",
  },
  {
    UpperTitle: "Package Number:",
    LowerTitle: "",
    placeHolder: "Enter Package Number",
  },
  {
    UpperTitle: "ACAT Assessment:",
    LowerTitle: "",
    placeHolder: "Enter ACAT Assessment",
  },
  {
    UpperTitle: "Client Type:",
    LowerTitle: "",
    placeHolder: "Enter Client Type",
  },

  {
    UpperTitle: "Expected Funding Date:",
    LowerTitle: "",
    placeHolder: "Enter Expected Funding Date",
  },
  {
    UpperTitle: "Residence:",
    LowerTitle: "",
    placeHolder: "Enter Resident",
  },
  {
    UpperTitle: "Building:",
    LowerTitle: "",
    placeHolder: "Enter Building",
  },
  {
    UpperTitle: "Ward:",
    LowerTitle: "",
    placeHolder: "Enter Ward",
  },
  {
    UpperTitle: "Room:",
    LowerTitle: "",
    placeHolder: "Enter Room",
  },
  {
    UpperTitle: "Table:",
    LowerTitle: "",
    placeHolder: "Enter Table",
  },
  
];

export const dummyArrClientSticky = [
  {
    UpperTitle: "Given Name:",
    LowerTitle: "",
    placeHolder: "Enter Given Name",
  },
  {
    UpperTitle: "Middle Name(s):",
    LowerTitle: "",
    placeHolder: "Enter Middle Name",
  },

  {
    UpperTitle: "Family Name:",
    LowerTitle: "",
    placeHolder: "Enter Family Name",
  },
  {
    UpperTitle: "Known As:",
    LowerTitle: "",
    placeHolder: "Enter Known As",
  },
  {
    UpperTitle: "Address:",
    LowerTitle: "",
    placeHolder: "Enter Address",
  },
  {
    UpperTitle: "Email:",
    LowerTitle: "",
    placeHolder: "Enter Email",
  },
  {
    UpperTitle: "Phone:",
    LowerTitle: "",
    placeHolder: "Enter Phone",
  },
  {
    UpperTitle: "Mobile:",
    LowerTitle: "",
    placeHolder: "Enter Mobile",
  },
  {
    UpperTitle: "Date of Birth:",
    LowerTitle: "",
    placeHolder: "Enter Date of Birth",
  },
  {
    UpperTitle: "Funding Type:",
    LowerTitle: "",
    placeHolder: "Enter Funding Type",
  },
  {
    UpperTitle: "Package Level:",
    LowerTitle: "",
    placeHolder: "Enter Package Level",
  },
  {
    UpperTitle: "ACAT Assessment:",
    LowerTitle: "",
    placeHolder: "Enter ACAT Assessment",
  },
  {
    UpperTitle: "Client Type:",
    LowerTitle: "",
    placeHolder: "Enter Client Type",
  },

  {
    UpperTitle: "Expected Funding Date:",
    LowerTitle: "",
    placeHolder: "Enter Expected Funding Date",
  },
];

export const dummyArrProspects = [
  {
    UpperTitle: "Given Name:",
    LowerTitle: "",
    placeHolder: "Enter Given Name",
  },
  {
    UpperTitle: "Middle Name(s):",
    LowerTitle: "",
    placeHolder: "Enter Middle Name",
  },
  {
    UpperTitle: "Family Name:",
    LowerTitle: "",
    placeHolder: "Enter Family Name",
  },
  {
    UpperTitle: "Known As:",
    LowerTitle: "",
    placeHolder: "Enter Known As",
  },
  {
    UpperTitle: "Address:",
    LowerTitle: "",
    placeHolder: "Enter Address",
  },

  {
    UpperTitle: "Email:",
    LowerTitle: "",
    placeHolder: "Enter Email",
  },
  {
    UpperTitle: "Phone:",
    LowerTitle: "",
    placeHolder: "Enter Phone ",
  },
  {
    UpperTitle: "Mobile:",
    LowerTitle: "",
    placeHolder: "Enter Mobile Number",
  },
  {
    UpperTitle: "Date of Birth:",
    LowerTitle: "",
    placeHolder: "Enter Date of Birth",
  },

  {
    UpperTitle: "WHITE SPACE::",
    LowerTitle: "",
    placeHolder: " ",
  },
  {
    UpperTitle: "WHITE SPACE::",
    LowerTitle: "",
    placeHolder: " ",
  },

  {
    UpperTitle: "WHITE SPACE:",
    LowerTitle: "",
    placeHolder: "",
  },

  {
    UpperTitle: "Funding Type:",
    LowerTitle: "",
    placeHolder: "Enter Funding Type",
  },
  {
    UpperTitle: "Package Level:",
    LowerTitle: "",
    placeHolder: "Enter Package Level",
  },
  {
    UpperTitle: "Package Number:",
    LowerTitle: "",
    placeHolder: "Enter Package Number",
  },
  {
    UpperTitle: "Expected Funding Date:",
    LowerTitle: "",
    placeHolder: "Enter Expected Funding Date",
  },
  {
    UpperTitle: "ACAT Assessment:",
    LowerTitle: "",
    placeHolder: "Enter ACAT Assessment",
  },
  {
    UpperTitle: "Initial Enquiry Date:",
    LowerTitle: "",
    placeHolder: "Enter Initial Enquiry Date",
  },
  {
    UpperTitle: "Prospect Type:",
    LowerTitle: "",
    placeHolder: "Enter Initial Enquiry Date",
  },
];

export const dummyArrStaff = [
  {
    UpperTitle: "Given Name:",
    LowerTitle: "Frank",
    placeHolder: "Enter Given Name",
  },
  {
    UpperTitle: "Middle Name(s):",
    LowerTitle: "Green",
    placeHolder: "Enter Middle Name",
  },
  {
    UpperTitle: "Family Name:",
    LowerTitle: "Green",
    placeHolder: "Enter Family Name",
  },
  {
    UpperTitle: "Known As:",
    LowerTitle: "Frenko",
    placeHolder: "Enter Known As",
  },
  {
    UpperTitle: "Address:",
    LowerTitle: "3, Gilbert Street, Elizabeth Town, VC",
    placeHolder: "Enter Address",
  },

  {
    UpperTitle: "Date of Birth:",
    LowerTitle: "1, February 1970",
    placeHolder: "Enter Date of Birth",
  },
  {
    UpperTitle: "Email:",
    LowerTitle: "1, February 1970",
    placeHolder: "Enter Email",
  },
  {
    UpperTitle: "Mobile:",
    LowerTitle: "1, February 1970",
    placeHolder: "Enter Mobile",
  },
  {
    UpperTitle: "Role:",
    LowerTitle: "",
    placeHolder: "Enter Role:",
  },
  {
    UpperTitle: "Funding Type:",
    LowerTitle: "1, February 1970",
    placeHolder: "Enter Funding Type",
  },
];

export const profileDetails = [
  {
    UpperTitle: "Date of Birth:",
    LowerTitle: "",
    type: "date-picker-one",
    index: 9,
  },
  {
    UpperTitle: "Funding Type:",
    LowerTitle: "",
    type: "dropdown",
    index: 10,
  },
  {
    UpperTitle: "Package Level:",
    LowerTitle: "",
    type: "text",
    index: 10,
  },
  {
    UpperTitle: "ACAT Assessment:",
    LowerTitle: "",
    type: "date-picker-two",
    index: 12,
  },
];

export const personalDetailsStaff = [
  {
    UpperTitle: "Given Name:",
    LowerTitle: "Frank",
  },
  {
    UpperTitle: "Middle Name(s):",
    LowerTitle: "Green",
  },
  {
    UpperTitle: "Family Name:",
    LowerTitle: "Green",
  },
  {
    UpperTitle: "Known As:",
    LowerTitle: "Frenko",
  },
  {
    UpperTitle: "Address:",
    LowerTitle: "3, Gilbert Street, Elizabeth Town, VC",
  },

  {
    UpperTitle: "Date of Birth:",
    LowerTitle: "1, February 1970",
  },
  {
    UpperTitle: "Email:",
    LowerTitle: "1, February 1970",
  },
  {
    UpperTitle: "Phone:",
    LowerTitle: "1, February 1970",
  },
  {
    UpperTitle: "Role:",
    LowerTitle: "1, February 1970",
  },
];

export const profileDetailsStaff = [
  {
    UpperTitle: "Funding Type:",
    LowerTitle: "1, February 1970",
  },
];
export const ProspectsPersonalDetails = [
  {
    UpperTitle: "Given Name:",
    LowerTitle: "Frank",
    type: "text",
  },
  {
    UpperTitle: "Middle Name(s):",
    LowerTitle: "Green",
    type: "text",
  },
  {
    UpperTitle: "Family Name:",
    LowerTitle: "Green",
    type: "text",
  },
  {
    UpperTitle: "Known As:",
    LowerTitle: "Frenko",
    type: "text",
  },
  {
    UpperTitle: "Address:",
    LowerTitle: "3, Gilbert Street, Elizabeth Town, VC",
    type: "text",
  },
  {
    UpperTitle: "Date of Birth:",
    LowerTitle: "1, February 1970",
    type: "date-picker-one",
  },
  {
    UpperTitle: "Email:",
    LowerTitle: "1, February 1970",
    type: "text",
  },
  {
    UpperTitle: "Phone:",
    LowerTitle: "1, February 1970",
    type: "text",
  },
];

export const prospectsProfileDetails = [
  {
    UpperTitle: "Funding Type:",
    LowerTitle: "1, February 1970",
    type: "dropdown",
  },
  {
    UpperTitle: "Package Level:",
    LowerTitle: "",
    type: "text",
  },
  {
    UpperTitle: "Expected Funding Date:",
    LowerTitle: "",
    type: "date-picker-one",
  },
  {
    UpperTitle: "ACAT Assessment:",
    LowerTitle: "",
    type: "date-picker-two",
  },
  {
    UpperTitle: "Initial Enquiry Date:",
    LowerTitle: "",
    type: "text",
  },
  {
    UpperTitle: "Prospect Type:",
    LowerTitle: "",
    placeHolder: "Enter Initial Enquiry Date",
  },
];

export const dummyArrProspectsTwo = [
  {
    UpperTitle: "Funding Type:",
    LowerTitle: "1, February 1970",
  },
  {
    UpperTitle: "Package Level:",
    LowerTitle: "",
  },
  {
    UpperTitle: "Expected Funding Date:",
    LowerTitle: "",
  },
  {
    UpperTitle: "ACAT Assessment:",
    LowerTitle: "",
  },
  {
    UpperTitle: "Add Initial Enquiry:",
    LowerTitle: "",
  },
];
export const dummyArrContacts = [
  {
    UpperTitle: "Given Name:",
    LowerTitle: "",
    placeHolder: "Enter Given Name",
  },
  {
    UpperTitle: "Middle Name(s):",
    LowerTitle: "",
    placeHolder: "Enter Middle Name",
  },
  {
    UpperTitle: "Family Name:",
    LowerTitle: "",
    placeHolder: "Enter Family Name",
  },
  {
    UpperTitle: "Known As:",
    LowerTitle: "",
    placeHolder: "Enter Known As",
  },
  {
    UpperTitle: "Address:",
    LowerTitle: "",
    placeHolder: "Enter Address",
  },

  {
    UpperTitle: "Email:",
    LowerTitle: "",
    placeHolder: "Enter Email",
  },
  {
    UpperTitle: "Mobile:",
    LowerTitle: "",
    placeHolder: "Enter Mobile Number",
  },
  {
    UpperTitle: "Role:",
    LowerTitle: "",
    placeHolder: "Enter Role",
  },
];
export const dummyArrBusiness = [
  {
    UpperTitle: "Business Name:",
    LowerTitle: "",
    placeHolder: "Enter Business Name",
  },
  {
    UpperTitle: "Email:",
    LowerTitle: "",
    placeHolder: "Enter Email",
  },

  {
    UpperTitle: "Address:",
    LowerTitle: "",
    placeHolder: "Enter Address",
  },

  {
    UpperTitle: "Contact Number:",
    LowerTitle: "",
    placeHolder: "Enter Contact Number",
  },

  {
    UpperTitle: "Business Type:",
    LowerTitle: "",
    placeHolder: "Enter Value",
  },
];
export const dummyArrLocations = [
  {
    UpperTitle: "Location Name:",
    LowerTitle: "",
    placeHolder: "Enter Location Name",
  },
  {
    UpperTitle: "Email:",
    LowerTitle: "",
    placeHolder: "Enter Email",
  },

  {
    UpperTitle: "Address:",
    LowerTitle: "",
    placeHolder: "Enter Address",
  },

  {
    UpperTitle: "Contact Number:",
    LowerTitle: "",
    placeHolder: "Enter Contact Number",
  },

  {
    UpperTitle: "Location Type:",
    LowerTitle: "",
    placeHolder: "Enter Value",
  },
];
export const dummyArrTeams = [
  {
    UpperTitle: "Team Name:",
    LowerTitle: "",
    placeHolder: "Enter Team Name",
  },
  {
    UpperTitle: "Site/Business:",
    LowerTitle: "",
    placeHolder: "Enter Site/Business",
  },
  {
    // UpperTitle: "Address:",
    // LowerTitle: "",
    // placeHolder: "Enter Address",
  },
  {
    UpperTitle: "Team Leader:",
    LowerTitle: "",
    placeHolder: "Enter Team Leader",
  },

  {
    UpperTitle: "Team Contact:",
    LowerTitle: "",
    placeHolder: "Enter Team Leader Contact",
  },
  {
    UpperTitle: "Email:",
    LowerTitle: "",
    placeHolder: "Enter Email",
  },
];
export const UpdateClientDetailsArr = [
  {
    UpperTitle: "Given Name:",
    LowerTitle: "Frank",
  },
  {
    UpperTitle: "Family Name:",
    LowerTitle: "Green",
  },
  {
    UpperTitle: "Known As:",
    LowerTitle: "Frenko",
  },
  {
    UpperTitle: "Address:",
    LowerTitle: "3, Gilbert Street, Elizabeth Town, VC",
  },
  {
    UpperTitle: "Post Code:",
    LowerTitle: "3322",
  },
  {
    UpperTitle: "Date of Birth:",
    LowerTitle: "1, February 1970",
  },
  {
    UpperTitle: "Phone:",
    LowerTitle: "0422 020 0360",
  },
];
export const UpdateClientDetailsArr2 = [
  {
    UpperTitle: "Update Password:",
    minChar: "Minimum 8 Characters",
    LowerTitle: "",
  },
  {
    UpperTitle: "Confirm Password:",
    LowerTitle: "",
  },
];
export const mainBoxArr = [
  {
    Name: "",
    UpperTitle: "Frank Green",
    LowerTitle: "Known As : Franko",
  },
  {
    UpperTitle: "Email",
    LowerTitle: "frank@mail.com",
  },
  {
    UpperTitle: "Mobile",
    LowerTitle: `0487 ${" "} 323 ${" "} 324`,
  },
  {
    UpperTitle: "DOB:",
    LowerTitle: "1, February 1970",
  },
  {
    UpperTitle: "Funding Type:",
    LowerTitle: "Home Care 1",
  },
];
export const documentBoxArr = [
  {
    Name: "",
    UpperTitle: "Frank Green",
    LowerTitle: "Known as : Franko",
  },
  {
    UpperTitle: "Email",
    LowerTitle: "frank@mail.com",
  },
  {
    UpperTitle: "Mobile",
    LowerTitle: "(+62) 21-1234-5678",
  },
  {
    UpperTitle: "DOB",
    LowerTitle: "1,Feb 1970 ",
  },
  {
    UpperTitle: "Funding Type",
    LowerTitle: "Home Care 1 ",
  },
];
export const villageDetailsDocumentsArr = [
  {
    name: "Assessment",
    completetionDate: "01/01/2023",
    reviewDate: "01/01/2023",
    percentage: "73%",
  },
  {
    name: "Agreement",
    completetionDate: "01/01/2023",
    reviewDate: "01/01/2023",
    percentage: "73%",
  },
  {
    name: "Care Plan",
    completetionDate: "01/01/2023",
    reviewDate: "01/01/2023",
    percentage: "73%",
  },

  {
    name: "Budget",
    completetionDate: "01/01/2023",
    reviewDate: "01/01/2023",
    percentage: "73%",
  },
];
const ClientScreenContentArr = () => {
  return <div></div>;
};

export default ClientScreenContentArr;
