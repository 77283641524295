import React, { useEffect } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../Controller/redux/Hooks";
import { setAllowedReportsHubIds } from "../../../Controller/redux/features/ReportsHubFeature/ReportsHubSlice";

function ReportsHubCard({ report_name, has_access, id, profile_image_s3 }) {
  const dispatch = useAppDispatch();
  const { allowedReportsHubIds } = useAppSelector((state) => state.reportsHub);

  const handleAllowedReportsHub = (event) => {
    const ids = allowedReportsHubIds
      .trim()
      .split(",")
      .map(Number)
      .filter((n: any) => !isNaN(n));
    const index = ids.indexOf(id);
    if (index === -1) {
      ids.push(id);
    } else {
      ids.splice(index, 1);
    }
    dispatch(setAllowedReportsHubIds(ids.join(",")));
  };

  return (
    <div className="reports_hub_card">
      <img
        src={
          profile_image_s3 ||
          require("../../../Model/Assets/images/logo-placeholder-image.png")
        }
        alt={`${report_name} logo`}
        className="vendor_logo"
      />
      <div className="toggle_switch">
        <input
          type="checkbox"
          id={`${id}_toggle`}
          checked={allowedReportsHubIds?.includes(id)}
          onChange={handleAllowedReportsHub}
        />
        <label htmlFor={`${id}_toggle`} />
      </div>
      <div>{report_name}</div>
    </div>
  );
}

export default ReportsHubCard;
