export const TempArr = [
  {
    name: "Sarah",
    appointment: [
      {
        type: "Late Work",
        start_time: "09:50 AM",
        end_time: "11:00 AM",
        current_stage: 2
      },
      {
        type: "Future Work",
        start_time: "02:50 AM",
        end_time: "05:00 AM",
        current_stage: 2
      }
    ]
  },
  {
    name: "John",
    appointment: [
      {
        type: "Meeting",
        start_time: "10:30 AM",
        end_time: "12:00 PM",
        current_stage: 2
      },
      {
        type: "Presentation",
        start_time: "01:00 AM",
        end_time: "02:00 AM",
        current_stage: 2
      }
    ]
  },
  {
    name: "Emily",
    appointment: [
      {
        type: "Consultation",
        start_time: "11:00 AM",
        end_time: "12:30 PM",
        current_stage: 2
      },
      {
        type: "Project Work",
        start_time: "02:00 AM",
        end_time: "04:00 AM",
        current_stage: 2
      }
    ]
  },
  {
    name: "Michael",
    appointment: [
      {
        type: "Training",
        start_time: "08:00 AM",
        end_time: "10:00 AM",
        current_stage: 2
      },
      {
        type: "Team Meeting",
        start_time: "11:30 AM",
        end_time: "01:00 PM",
        current_stage: 2
      }
    ]
  },
  {
    name: "Jennifer",
    appointment: [
      {
        type: "Client Call",
        start_time: "10:00 AM",
        end_time: "11:30 AM",
        current_stage: 2
      },
      {
        type: "Report Analysis",
        start_time: "01:30 PM",
        end_time: "03:30 PM",
        current_stage: 2
      }
    ]
  }
];
