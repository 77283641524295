import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../Controller/redux/Hooks";
import { getPayRuleDetailsById } from "../../../../../../Controller/redux/features/RefDataFeature/PayRuleFeature/payruleSlice";
import { Spinner } from "@chakra-ui/react";
import CommonUpperBox from "../../../../../components/UpperBox/CommonHeader";
import CommonHeader from "../../../../../components/CommonHeader/CommonHeader";
import { toast } from "react-toastify";
import {
  getExpenseTypeDetailsId,
  updateExpenseType,
} from "../../../../../../Controller/redux/features/RefDataFeature/ExpenseTypeFeature/expensetypeSlice";
import CircularNoteTrigger from "../../../../../components/CircularNoteTrigger/CircularNoteTrigger";
import CommonQuickMenu from "../../../../../components/CommonQuickMenu/CommonQuickMenu";
const ExpenseTypeScreen = () => {
  const id: any = useParams()?.id;
  const [clientId, setClientId] = useState<any>("");
  const { user } = useAppSelector((state) => state?.common);

  useEffect(() => {
    if (id) {
      let realId = window?.atob(id);
      setClientId(realId);
    }
  }, [id]);

  const dispatch = useAppDispatch();
  useEffect(() => {
    if (id) {
      dispatch(getExpenseTypeDetailsId(clientId));
    }
  }, [clientId]);

  const { uniqueExpenseTypeDetails } = useAppSelector(
    (state) => state.expenseType
  );
  let isAdmin;
  if (uniqueExpenseTypeDetails) {
    isAdmin = user?.tenant_id === uniqueExpenseTypeDetails[0]?.tenant_id;
  }

  useEffect(() => {
    if (uniqueExpenseTypeDetails) {
      setDetails(uniqueExpenseTypeDetails ? uniqueExpenseTypeDetails[0] : "");
    }
  }, [uniqueExpenseTypeDetails]);
  // console.log(uniqueExpenseTypeDetails,"uniqueExpenseTypeDetails")

  const [details, setDetails] = useState<any>({
    expense_type_id: null,
    name: "",
    associated_funding_type: "",
    associated_package_type: null,
    category: "",
    rate: "",
    rate_type: "",
  });

  const onChangeValue = (e: any) => {
    const { value, name } = e?.target;

    // @ts-ignore
    setDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));

    setIsThereAnyChanges(true);
  };

  const onChangeExpenseType = (e: any, index: number, elem: any) => {
    const { value, name } = e?.target;
    let copyElem = { ...elem, [name]: value };
    let duplicateServiceTypeRules = [...details?.service_type_rules];
    duplicateServiceTypeRules[index] = copyElem;

    setDetails({
      ...details,
      service_type_rules: duplicateServiceTypeRules,
    });

    !isThereAnyChanges && setIsThereAnyChanges(true);
  };

  const handleSaveChanges = async () => {
    try {
      const response = await dispatch(
        updateExpenseType({
          ...details,
          expense_type_id: details?.id,
        })
      );
      if (response?.payload?.status === "success") {
        toast.success(`Services updated successfully!`, {
          style: {},
          progressStyle: { backgroundColor: dynamicColor },
          icon: (
            <span
              style={{
                marginRight: "8px",
                borderRadius: "50%",
                width: "7rem",
                height: "35px",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: dynamicColor,
                backgroundColor: "white",
                fontSize: "1.5rem",
                border: `3px solid ${dynamicColor}`,
              }}
            >
              ✓
            </span>
          ),
        });
        dispatch(getExpenseTypeDetailsId(clientId));
      }
    } catch (error: any) {}
  };

  // useEffect(() => {
  //   if (clientId) {
  //     dispatch(getPayRuleDetailsById(clientId));
  //   }
  // }, [clientId]);

  const { uniquePayRule, loading } = useAppSelector((state) => state.payRule);
  const [currentPayRuleDetails, setCurrentPayRuleDetails] = useState<any>({});

  // // console.log(currentPayRuleDetails, "currentPayRuleDetails");
  // useEffect(() => {
  //   if (uniquePayRule?.id) {
  //     setTimeout(() => {
  //       setCurrentPayRuleDetails(uniquePayRule);
  //     }, 1000);
  //   }
  // }, [uniquePayRule]);

  useEffect(() => {
    setDetails(currentPayRuleDetails);
  }, [currentPayRuleDetails]);
  const [stageTwoToGoalsModal, setstageTwoToGoalsModal] = useState<any>(false);

  const dynamicColor = useAppSelector((state) => state?.common?.dynamicColor);
  const [isThereAnyChanges, setIsThereAnyChanges] = useState(false);
  return (
    <div>
      {loading && <Spinner position={"absolute"} top={"50%"} left={"50%"} />}
      <CommonUpperBox
        imageSection={currentPayRuleDetails ? true : false}
        title={`Expense Type`}
        onClickUserImage={() => {}}
        onClickPlus={() => {
          setstageTwoToGoalsModal(true);
        }}
        type={"listing"}
        currentUser={currentPayRuleDetails}
        backgroundWithText={true}
        text={`ET`}
      />
      <CommonHeader
        mainType="billingRule"
        type="details"
        handleClick={() => {}}
        currentPay={uniqueExpenseTypeDetails ? uniqueExpenseTypeDetails[0] : ""}
        refData={true}
      />
      <div>
        <div className="main-box-body relative margin-top20">
          <div className={`content-wrapper `}>
            <div className="wrapper-title">Name: </div>
            <input
              className="wrapper-info"
              name="name"
              placeholder={"Please Enter Name "}
              readOnly={!isAdmin}
              onChange={onChangeValue}
              value={details?.name}
              style={{
                border: "none",
                outline: "none",
                height: "fit-content",
              }}
            />
            <div className="wrapper-line"></div>
          </div>
          <div className={`content-wrapper `}>
            <div className="wrapper-title">Associated Funding Type: </div>
            {isAdmin && (
              <select
                className="wrapper-info wrapper-info-select-dropdown"
                onChange={onChangeValue}
                name="associated_funding_type"
                style={{
                  border: "none",
                  outline: "none",
                  height: "fit-content",
                  backgroundColor: "white",
                }}
                value={details.associated_funding_type}
              >
                {<option value="">Please Select...</option>}
                {["HCP", "NDIS", "Private"]?.map((elem: any, index: any) => (
                  <option key={index} value={elem}>
                    {elem}
                  </option>
                ))}
              </select>
            )}
            {!isAdmin && (
              <input
                className="wrapper-info"
                readOnly={true}
                value={details?.associated_funding_type}
                style={{
                  border: "none",
                  outline: "none",
                  height: "fit-content",
                }}
              />
            )}
            <div className="wrapper-line"></div>
          </div>
          <div className={`content-wrapper `}>
            <div className="wrapper-title">Associated Package Type: </div>
            {isAdmin && (
              <select
                className="wrapper-info wrapper-info-select-dropdown"
                onChange={onChangeValue}
                name="associated_package_type"
                style={{
                  border: "none",
                  outline: "none",
                  height: "fit-content",
                  backgroundColor: "white",
                }}
                value={details.associated_package_type}
              >
                {isAdmin && <option value="">Please Select...</option>}
                {isAdmin &&
                  ["HCP", "NDIS", "Private"]?.map((elem: any, index: any) => (
                    <option key={index} value={elem}>
                      {elem}
                    </option>
                  ))}
              </select>
            )}
            {!isAdmin && (
              <input
                className="wrapper-info"
                readOnly={true}
                value={details?.associated_package_type === 0 ? "No" : "Yes"}
                style={{
                  border: "none",
                  outline: "none",
                  height: "fit-content",
                }}
              />
            )}
            <div className="wrapper-line"></div>
          </div>{" "}
          <div className={`content-wrapper `}>
            <div className="wrapper-title">Category: </div>
            <input
              readOnly={!isAdmin}
              className="wrapper-info"
              name="category"
              placeholder={"Please Enter Category "}
              onChange={onChangeValue}
              value={details?.category}
              style={{
                border: "none",
                outline: "none",
                height: "fit-content",
              }}
            />
            <div className="wrapper-line"></div>
          </div>
          <div className={`content-wrapper `}>
            <div className="wrapper-title">Rate: </div>
            <div className="service_type_rate_flex">
              <input
                className="wrapper-info"
                name="rate"
                readOnly={!isAdmin}
                placeholder={"Please Enter Rate "}
                onChange={onChangeValue}
                value={details?.rate}
                style={{
                  border: "none",
                  outline: "none",
                  height: "fit-content",
                }}
              />
              {isAdmin && (
                <select
                  className="wrapper-info wrapper-info-select-dropdown"
                  style={{
                    border: "none",
                    outline: "none",
                    height: "fit-content",
                    backgroundColor: "white",
                    marginTop: "7px",
                  }}
                  onChange={onChangeValue}
                  value={details.rate_type}
                  name="rate_type"
                >
                  <option value="">Rate Type...</option>
                  {["Fixed", "Percent"]?.map((elem: any, index: any) => (
                    <option key={index} value={elem}>
                      {elem}
                    </option>
                  ))}
                </select>
              )}
              {!isAdmin && (
                <input
                  className="wrapper-info"
                  readOnly={true}
                  value={details?.rate_type}
                  style={{
                    border: "none",
                    outline: "none",
                    height: "fit-content",
                  }}
                />
              )}
            </div>
            <div className="wrapper-line"></div>
            <div className="wrapper-line"></div>
          </div>
        </div>
      </div>
      {isThereAnyChanges && (
        <>
          <div
            className="save"
            style={{
              position: "absolute",
              marginRight: "200px",
              bottom: "10px",
              zIndex: 9,
            }}
          >
            <p>Would you like to save your changes?</p>
            <div className="btnDivision">
              <button
                onClick={() => {
                  setIsThereAnyChanges(false);
                  handleSaveChanges();
                }}
                style={{
                  backgroundColor: dynamicColor,
                }}
              >
                Save
              </button>
              <button onClick={() => setIsThereAnyChanges(false)}>
                Cancel
              </button>
            </div>
          </div>
        </>
      )}
      <CircularNoteTrigger
        type="stickyHub"
        title="Comms"
        isFinanceScreen={true}
      />
      {stageTwoToGoalsModal && (
        <CommonQuickMenu
          setshowGoalsModal={(value: any) => {}}
          mainType={"listing"}
          setstageTwoToGoalsModal={setstageTwoToGoalsModal}
        />
      )}
    </div>
  );
};

export default ExpenseTypeScreen;
