import React, {
  useEffect,
  useState,
  Fragment,
  useRef,
} from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { LogoLeft } from "../../../components/LogoLeft/LogoLeft";
import { forgotPasswordThunk } from "../../../../Controller/redux/features/AuthFeature/authSlice";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../Controller/redux/Hooks";
import { Spinner } from "@chakra-ui/react";
import { toast } from "react-toastify";

function initialFormValues() {
  return {
    username: "",
  };
}

export function ForgotPassword() {
  const navigate = useNavigate();
  const { logo, success_colour } = useAppSelector(
    (state) => state?.common?.fromGetDomainDetails
  );

  const [values, setValues] = useState(initialFormValues);
  const [requestStatus, setRequestStatus] = useState("success");

  const {
    register: validation,
    handleSubmit: handlevalidation,
    formState: { errors },
  } = useForm();

  const userNameRef = useRef<HTMLInputElement | null>(null);

  const { ref: usernameRef, ...restusername } = validation("username");


  const dispatch = useAppDispatch();
  async function handleForgotpassword(e: any) {
    setRequestStatus("loading");
    try {
      const response = await dispatch(forgotPasswordThunk(e));
      if (response.payload.status === "success") {
        toast.success("Email Sent Successfully!", {
          style: {},
          progressStyle: { backgroundColor: success_colour },
          icon: (
            <span
              style={{
                marginRight: "8px",
                borderRadius: "50%",
                width: "7rem",
                height: "35px",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: success_colour,
                backgroundColor: "white",
                fontSize: "1.5rem",
                border: `3px solid ${success_colour}`,
              }}
            >
              ✓
            </span>
          ),
        });
      }
    } catch (err) {
      // console.log(err);
    }
    setRequestStatus("success");
  }

  const login = () => {
    navigate("/login");
  };

  useEffect(() => {
    return () => setRequestStatus("success");
  }, []);

  return (
    <Fragment>
      <div className="main-section main-section-display forgot-password-screen">
        <LogoLeft />
        <div className="login_section_right">
          <div>
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: "#e9e9e9",
                }}
              >
                <div className="login-content-section">
                  <div className="login-heading">
                    <div className="logo-section">
                      <a href="">
                        <img
                          src={logo}
                          alt="logo"
                          className="imf-fluid display-block-img"
                        />
                      </a>
                    </div>
                  </div>
                  <form onSubmit={handlevalidation(handleForgotpassword)}>
                    <div
                      className={`form-section ${
                        errors.username ? "changeColor" : ""
                      }`}
                    >
                      <div className="form-group error">
                        <div className="forgotpass">
                          <h3>Reset Password</h3>
                        </div>
                        <div>
                          <label>Enter Your Username</label>
                        </div>

                        <div className="input-section">
                          <input
                            {...restusername}
                            type="text"
                            id="username"
                            disabled={requestStatus === "loading"}
                            placeholder="Enter Username"
                            {...validation("username", { required: true })}
                            ref={(e) => {
                              usernameRef(e);
                              userNameRef.current = e;
                            }}
                          />
                        </div>
                        {errors.username && (
                          <span className="errorname">
                            Username is required
                          </span>
                        )}
                      </div>
                      <div className="form-group">
                        <div className="btn-section">
                          <button className="comman-btn" type="submit">
                            {requestStatus === "loading" ? (
                              <Spinner
                                thickness="4px"
                                size="md"
                                speed="0.65s"
                              />
                            ) : (
                              "SEND"
                            )}
                          </button>
                        </div>
                      </div>
                      <div className="new-account-section">
                        <p
                          className="underline-para back_to_login"
                          onClick={login}
                        >
                          Back to Login
                        </p>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
