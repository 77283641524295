import { useAppSelector } from "../../../Controller/redux/Hooks";

const UnsaveInline = ({ oncancel, onsave }: any) => {
  const dynamicColor = useAppSelector((state) => state?.common?.dynamicColor);

  return (
    <div>
      <div
        className="save_sticky_popup"
        style={{
          position: "absolute",
          zIndex: "999999999999999999999999999999999",
          left:"30%",
          fontSize:"18px"
        }}
      >
        <p>Your changes would get lost. Do you wish to continue without saving?</p>
        <div className="btnDivision">
          <button
            onClick={onsave}
            style={{
              backgroundColor: dynamicColor,
            }}
          >
            Yes
          </button>
          <button onClick={oncancel}>No</button>
        </div>
      </div>
    </div>
  );
};

export default UnsaveInline;
