import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { api } from "../../../services/api";

const initialState: any = {
  loading: false,
  error: null,
  prospectRecords: [],
  selectedDropdownItemProviderProspect: "",
  selectedItemIndexFilterProviderProspect: "",
  selectedItemIndexSortProviderProspect: "",
  sortByProviderProspect: "",
  lastPage: 10000,
};

export const getProviderProspect = createAsyncThunk(
  "provider_prospect/fetchProviderProspect",
  async ({ dynamicUserTypeId, dynamicProviderId }: any) => {
    try {
      const { data } = await api.get(
        `providers/get_provider_users?paginate=true&page=1&limit=10&sort_type=asc&type=${dynamicUserTypeId}&tenant_id=${dynamicProviderId}`
      );
      return data?.data?.data;
    } catch (error) {
      throw error;
    }
  }
);
export const getProviderProspectFilterThunk = createAsyncThunk(
  "provider_prospect/fetchProviderProspectFilter",
  async ({
    dynamicUserTypeId,
    dynamicProviderId,
    filterColumn,
    filterValue,
    searchVal,
    sortColumn,
    sortType,
    status,
    ProspectPageFilter,
  }: any) => {
    const encodeFilterVal = encodeURIComponent(filterValue);
    const encodeSearchVal = encodeURIComponent(searchVal);
    try {
      const { data } = await api.get(
        `providers/get_provider_users?paginate=true&type=${dynamicUserTypeId}&tenant_id=${dynamicProviderId}&page=${ProspectPageFilter}&status=${status}&sort_column=${sortColumn}&sort_type=${sortType}&filter_column=${filterColumn}&limit=50&filter_value=${encodeFilterVal}&search=${encodeSearchVal}`
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
);

const providerProspectSlice = createSlice({
  name: "provider_prospect",
  initialState,
  reducers: {
    resetProspectProviderData: (state) => {
      state.prospectRecords = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProviderProspect.pending, (state) => {
        state.loading === true;
      })
      .addCase(getProviderProspect.fulfilled, (state, action) => {
        state.loading = false;
        state.prospectRecords = action.payload;
      })
      .addCase(getProviderProspect.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getProviderProspectFilterThunk.pending, (state) => {
        state.loading === true;
      })
      .addCase(getProviderProspectFilterThunk.fulfilled, (state, action) => {
        state.loading = false;
        // state.prospectRecords = action.payload;
        state.lastPage = action.payload.data.last_page;

        state.prospectRecords = [
          ...state.prospectRecords,
          ...action.payload.data.data,
        ];
      })
      .addCase(getProviderProspectFilterThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { resetProspectProviderData } = providerProspectSlice.actions;

export default providerProspectSlice.reducer;
