import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { api } from "../../../services/api";

const initialState: any = {
  getAllComms: [],
  getCommsDetail: [],
  getCommDetailFull: null,
  commsIdAndType: {
    link_id: "",
    comm_type: "",
  },
  allCommsPage: 1,
  allCommsLimit: 1000,
  detailCommsPage: 1,
  getCommsUnreadMessages: 0,
  lastPageAllComms: 10000,
  lastPageDetailsComms: 1000,
  loading: false,
  commsDetails: false,
  getUnreadMessageColor: "",
  getUnreadMessages: [],
  selectedCommsFolder: "",
  getClientCommsUnreadMessages: 0,
  getClientUnreadMessageColor: "",
  getClientUnreadMessages: [],
  allUserTypeData: null,
};

export const createCommsThunk = createAsyncThunk(
  "comms/createComms",
  async (commsData: any, { rejectWithValue }) => {
    try {
      const response = await api.post(
        `/communications/create_new_communication`,
        commsData
      );
      return response.data;
    } catch (error: string | any) {
      return rejectWithValue(error || "Failed To Create Comms");
    }
  }
);
export const updateCommsThunk = createAsyncThunk(
  "comms/updateComms",
  async (commsData: any, { rejectWithValue }) => {
    try {
      const response = await api.post(
        `/communications/create_communication`,
        commsData
      );
      return response.data;
    } catch (error: string | any) {
      return rejectWithValue(error.message || "Failed To Update Comms");
    }
  }
);

export const updateTaggedUsersThunk = createAsyncThunk(
  "comms/updateTaggedUsersThunk",
  async (commsTaggedUsers: any, { rejectWithValue }) => {
    try {
      const response = await api.post(
        `/communications/update_tagged_users`,
        commsTaggedUsers
      );
      return response.data;
    } catch (error: string | any) {
      return rejectWithValue(error?.response?.data?.response || "Failed To Update Tageed People.");
    }
  }
);

export const updateKeyUsersThunk = createAsyncThunk(
  "comms/updateKeyUsersThunk",
  async (commskeyUsers: any, { rejectWithValue }) => {
    try {
      const response = await api.post(
        `/communications/update_key_users`,
        commskeyUsers
      );
      return response.data;
    } catch (error: string | any) {
      return rejectWithValue(error.message || "Failed To Update Key People.");
    }
  }
);
export const updateMessageViewedStatusOnClickThunk = createAsyncThunk(
  "comms/updateMessageViewedStatusOnClickThunk",
  async (communication_id: any, { rejectWithValue }) => {
    try {
      const response = await api.post(`/communications/mark_read`, {
        id: communication_id,
      });
      return response.data;
    } catch (error: string | any) {
      return rejectWithValue(
        error.message || "Failed To Update Message Viewed Status."
      );
    }
  }
);

export const updateCommsTagsThunk = createAsyncThunk(
  "comms/updateCommsTagsThunk",
  async (commsTags: any, { rejectWithValue }) => {
    try {
      const response = await api.post(`/communications/update_tags`, commsTags);
      return response.data;
    } catch (error: string | any) {
      return rejectWithValue(error.message || "Failed To Update Tags.");
    }
  }
);
export const getCommsForUserThunk = createAsyncThunk(
  "comms/getCommsForUser",
  async ({
    clientId,
    endpoint = "get_comms",
    page = 1,
    limit = 10000000,
    sortType,
    search,
    unread,
  }: any) => {
    try {
      const response = await api.get(
        `/communications/${endpoint}?page=${page}&limit=${limit}&user_id=${clientId}&sort_type=${sortType}&search=${search}&unread=${unread}`
      );
      return response.data;
    } catch (error: string | any) {
      throw error;
    }
  }
);

export const getCommsFromUserTypeThunk = createAsyncThunk(
  "comms/getCommsFromUserTypeThunk",
  async ({ userType, search, sortType, limit = 10000 }: any) => {
    try {
      const response = await api.get(
        `/communications/get_comms_from_user_type?page=1&limit=${limit}&user_type=${userType}&search=${search}&sort_type=${sortType}`
      );
      return response.data;
    } catch (error: string | any) {
      throw error;
    }
  }
);
export const getUnreadMessagesCommsThunk = createAsyncThunk(
  "comms/getCommsForUnreadMessages",
  async (userId: any) => {
    try {
      const response = await api.get(
        `/communications/get_unread_message_count?user_id=${userId}`
      );
      return response.data;
    } catch (error: string | any) {
      throw error;
    }
  }
);
export const getClientUnreadMessagesCommsThunk = createAsyncThunk(
  "/comms/getCommsForUnreadMessages",
  async (clientId: any) => {
    try {
      const response = await api.get(
        `/communications/get_unread_message_count?user_id=${clientId}`
      );
      return response.data;
    } catch (error: string | any) {
      throw error;
    }
  }
);
export const getCommsDetailThunk = createAsyncThunk(
  "comms/getCommsDetail",
  async ({ clientId, type, userId, page }: any) => {
    try {
      const response = await api.get(
        `/communications/get_comm_details?page=1&limit=100000&link_uid=${clientId}&comm_type=${type}&user_id=${userId}`
      );
      return response.data;
    } catch (error: string | any) {
      throw error;
    }
  }
);

export const pinCommunicationThunk = createAsyncThunk(
  "comms/pinCommunicationThunk",
  async (id: any) => {
    try {
      const response = await api.post(`/communications/pin_communication`, id);
      return response.data;
    } catch (error: string | any) {
      throw error;
    }
  }
);

// /communications/pin_communication
export const commsSlice = createSlice({
  name: "comms",
  initialState,
  reducers: {
    setCommsIdAndType: (state, action) => {
      state.commsIdAndType = action.payload;
    },
    setallUserTypeData: (state, action) => {
      state.allUserTypeData = action.payload;
    },
    resetallUserTypeData: (state) => {
      state.allUserTypeData = [];
    },
    setAllCommsPage: (state) => {
      state.allCommsPage += 1;
    },
    resetAllCommsPage: (state) => {
      state.allCommsPage = 1;
    },
    setAllCommsLimit: (state) => {
      state.allCommsLimit += 1000;
    },
    resetAllCommsLimit: (state) => {
      state.allCommsLimit = 1000;
    },
    setDetailCommsPage: (state) => {
      state.detailCommsPage += 1;
    },
    resetDetailCommsPage: (state) => {
      state.detailCommsPage = 1;
    },
    resetCommsDetailData: (state) => {
      state.getCommsDetail = [];
    },
    resetAllCommsData: (state) => {
      state.getAllComms = [];
    },
    setCommsDetails: (state) => {
      state.commsDetails = !state.commsDetails;
    },
    setselectedCommsFolder: (state, action) => {
      state.selectedCommsFolder = action.payload;
    },
    resetselectedCommsFolder: (state) => {
      state.selectedCommsFolder = null;
    },
  },
  extraReducers: (builder) => {
    builder

      // updateMessageViewedStatusOnClickThunk
      .addCase(createCommsThunk.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(updateMessageViewedStatusOnClickThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        updateMessageViewedStatusOnClickThunk.fulfilled,
        (state, action) => {
          state.loading = false;
        }
      )
      .addCase(
        updateMessageViewedStatusOnClickThunk.rejected,
        (state, action) => {
          state.error = action.payload;
          state.loading = false;
        }
      )
      .addCase(getCommsForUserThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        updateTaggedUsersThunk.rejected,
        (state, action) => {
          state.error = action;
        }
      )
      // updateTaggedUsersThunk
      .addCase(getCommsForUserThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.getAllComms = [...action.payload.data.data];
        state.lastPageAllComms = action.payload.data.last_page;
      })
      .addCase(getCommsForUserThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getCommsFromUserTypeThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.getAllComms = [...action.payload.data.data];
        state.lastPageAllComms = action.payload.data.last_page;
      })
      // getCommsFromUserTypeThunk
      .addCase(getCommsDetailThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCommsDetailThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.getCommDetailFull = action.payload;
        // state.getCommsDetail = [...action.payload.data.data];
        // state.lastPageDetailsComms = action.payload.data.last_page;
      })
      .addCase(getCommsDetailThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getUnreadMessagesCommsThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUnreadMessagesCommsThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.getCommsUnreadMessages = action.payload.count;
        state.getUnreadMessageColor = action.payload.color;
        let data = action.payload.data;
        let dataArr: any = [];
        for (let key in data) {
          let tempObj = {
            key: key,
            data: data[key],
          };
          dataArr.push(tempObj);
        }
        state.getUnreadMessages = dataArr;
      })
      .addCase(getUnreadMessagesCommsThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getClientUnreadMessagesCommsThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getClientUnreadMessagesCommsThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.getClientCommsUnreadMessages = action.payload.count;
        state.getClientUnreadMessageColor = action.payload.color;
        let data = action.payload.data;
        let dataArr: any = [];
        for (let key in data) {
          let tempObj = {
            key: key,
            data: data[key],
          };
          dataArr.push(tempObj);
        }
        state.getClientUnreadMessages = dataArr;
      })
      .addCase(getClientUnreadMessagesCommsThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const {
  setCommsIdAndType,
  setAllCommsPage,
  resetAllCommsPage,
  setDetailCommsPage,
  resetDetailCommsPage,
  resetCommsDetailData,
  resetAllCommsData,
  setCommsDetails,
  setselectedCommsFolder,
  resetselectedCommsFolder,
  setAllCommsLimit,
  resetAllCommsLimit,
  setallUserTypeData,
  resetallUserTypeData,
} = commsSlice.actions;
export default commsSlice.reducer;
