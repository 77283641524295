import mainTypesToButtons from "../Data/mainTypesToButtons";

import { statusMap } from "../../../../Model/utils/Helper/statusMap";

import "../Styles/CommonHeader.css";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../Controller/redux/Hooks";
import { trackUserClickThunk } from "../../../../Controller/redux/features/AuditFeature/auditSlice";
import { useLocation } from "react-router-dom";
import React from "react";

interface headerButtonProps {
  buttonsArray: any;
  mainType: keyof typeof mainTypesToButtons;
  type: string;
  handleClick(arg: any): any;
  screenType: any;
}

const HeaderButtons = ({
  buttonsArray,
  mainType,
  type,
  handleClick,
  screenType,
}: headerButtonProps) => {
  // console.log(buttonsArray,"buttonsArray")

  const styledButtons = mainTypesToButtons[mainType];
  // console.log(styledButtons,'style')
  let appliedStyle;
  const dispatch = useAppDispatch();
  const {
    detailstab_color,
    assoctab_color,
    clinicaltab_color,
    commstab_color,
    doctab_color,
    fintab_color,
    daybtab_color,
    mealstab_color,
    has_detailstab,
    has_assoctab,
    has_doctab,
    has_daytab,
    has_fintab,
    has_mealstab,
    has_commstab,
    has_clinicaltab,
  } = useAppSelector((state) => state?.common?.domainDetail);

  // const pathname = useLocation()?.pathname;

  const buttonStyles = {
    Details: { backgroundColor: detailstab_color },
    Associations: { backgroundColor: assoctab_color },
    Clinical: { backgroundColor: clinicaltab_color },
    Comms: { backgroundColor: commstab_color },
    Documents: { backgroundColor: doctab_color },
    Finance: { backgroundColor: fintab_color },
    Daybook: { backgroundColor: daybtab_color },
    Meals: { backgroundColor: mealstab_color },
  };
  const buttonConditions = {
    Details: has_detailstab === "1",
    Associations: has_assoctab === "1",
    Clinical: has_clinicaltab === "1",
    Comms: has_commstab === "1",
    Documents: has_doctab === "1",
    Finance: has_fintab === "1",
    Daybook: has_daytab === "1",
    Meals: has_mealstab === "1",
    Audit: true,
    Prospects: "1",
    Clients: "1",
    Business: "1",
    Locations: "1",
    Teams: "1",
    Contacts: "1",
    Staff: "1",
    details: "1",
    Availability: "1",
    "Vendor Access": "1",
    "Report Access": "1",
  };

  const renderButtons = () => {
    // @ts-ignore
    return buttonsArray?.map((button, index) => {
      const appliedStyle =
        type.toLowerCase() === button?.toLowerCase() &&
        styledButtons?.includes(button.toLowerCase())
          ? "on-hover-btn-client-screen header-button-styles"
          : "on-hover-btn-client-screen";

      return (
        <button
          style={{
            // @ts-ignore
            ...buttonStyles[button],
            // @ts-ignore
            display: buttonConditions[button] ? "" : "none",
          }}
          className={appliedStyle}
          onClick={() => handleClickWithTracking(screenType, button)}
          key={button}
        >
          {button}
        </button>
      );
    });
  };

  const handleClickWithTracking = (screenType: any, button?: any) => {
    dispatch(
      trackUserClickThunk({
        desc: `Clicked on ${
          screenType.at(0).toUpperCase() + screenType.slice(1)
        }>${button}`,
        screen: `${
          screenType.at(0).toUpperCase() + screenType.slice(1)
        }>${button}`,
      })
    );
    // if (!pathname.includes("providers")) {
    handleClick(button);
    // }
  };
  return <div>{renderButtons()}</div>;
};

export default HeaderButtons;
