import React, { useState } from "react";
import TimePicker from "../../Time/Time";
import { getCurrentTime } from "../../../../Model/utils/Helper/formatTime";
import { toast } from "react-toastify";
import { api } from "../../../../Controller/services/api";
import { setShowStickyNote } from "../../../../Controller/redux/features/CommonFeature/commonSlice";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../Controller/redux/Hooks";
import billingruleSlice, {
  getBillingRule,
  getBillingRuleByFilterThunk,
  resetBillingRuleData,
} from "../../../../Controller/redux/features/RefDataFeature/BillingRuleFeature/billingruleSlice";

const BillingForm = () => {
  const dispatch = useAppDispatch();
  const [IsLoading, setIsLoading] = useState(false);
  const initialValue = {
    set_name: "",
    associated_funding_type: "",
    associated_package_type: "",
    tax_rate: "",
  };
  const { billingRulePage } = useAppSelector((state) => state.billingRule);

  const [billingFormData, setBillingFormData] = useState(initialValue);
  const { dynamicColor } = useAppSelector((state) => state?.common);

  let isPending = false;

  const handleSubmitBilling = async (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    const url = "/billing_rule_sets/create_billing_rule_set";
    if (billingFormData?.set_name === "") {
      toast.error("Please Enter Value on Required Fileds");
      setIsLoading(false);
      return;
    }
    if (billingFormData?.set_name !== "") {
      if (isPending) return;
      if (IsLoading) return;
      try {
        isPending = true;
        const response = await api.post(url, billingFormData);
        if (response.data.status === "success") {
          setIsLoading(false);
          isPending = false;

          toast.success(`Billing added successfully! `, {
            style: {},
            progressStyle: { backgroundColor: dynamicColor },
            icon: (
              <span
                style={{
                  marginRight: "8px",
                  borderRadius: "50%",
                  width: "7rem",
                  height: "35px",
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: dynamicColor,
                  backgroundColor: "white",
                  fontSize: "1.5rem",
                  border: `3px solid ${dynamicColor}`,
                }}
              >
                ✓
              </span>
            ),
          });
        }
        // dispatch(getBillingRule());
        dispatch(resetBillingRuleData());
        dispatch(
          getBillingRuleByFilterThunk({
            filterColumn: "",
            filterValue: "",
            searchVal: "",
            sortColumn: "",
            sortType: "",
            //   status: "",
            billingRulePage,
          })
        );

        const stickyNoteElement = document.querySelector(
          ".new_sticky_note_wrapper"
        );
        if (stickyNoteElement) {
          stickyNoteElement.classList.add("fade_out");
          setTimeout(() => {
            dispatch(setShowStickyNote(false));
          }, 300);
        }
      } catch (error: any) {
        toast.error(error.message);
        setIsLoading(false);
      }
    }
  };
  return (
    <div className="payload_area">
      <div className="main_area">
        <div className="side_bar"></div>
        <div className="side_bar" />
        <div className="middle-block">
          <p className="note-title">{`New Billing Set`}</p>
          <div className="middle-section-content middle_section_scrollable">
            <div className="field">
              <span className="field-title">
                Name <span className="asterixs_color"> * </span>
              </span>
              <input
                style={{
                  backgroundColor: "white",
                }}
                className="input-fields-of-sticky-note"
                placeholder="Enter Name"
                onChange={(e) =>
                  setBillingFormData((prevState) => ({
                    ...prevState,
                    set_name: e.target.value,
                  }))
                }
                value={billingFormData.set_name}
              />

              <span className="field-border field-border-add-form"></span>
            </div>
            <div className="field">
              <span className="field-title">
                Associated Funding Type
                {/* {<span className="asterixs_color"> * </span>} */}
              </span>
              <select
                className="field-type custom-select-2"
                onChange={(e) =>
                  setBillingFormData((prevState: any) => ({
                    ...prevState,
                    associated_funding_type: e.target.value,
                  }))
                }
                value={billingFormData.associated_funding_type}
                style={{
                  backgroundColor: "white",
                }}
              >
                <option value="">Please Select...</option>
                {["Private", "NDIS", "HCP"]?.map((elem: any, index: any) => (
                  <option key={index} value={elem}>
                    {elem}
                  </option>
                ))}
              </select>

              <span className="field-border field-border-add-form"></span>
            </div>

            <div className="field">
              <span className="field-title">Associated Package Type</span>
              <select
                className="field-type custom-select-2"
                onChange={(e) =>
                  setBillingFormData((prevState: any) => ({
                    ...prevState,
                    associated_package_type: e.target.value,
                  }))
                }
                value={billingFormData.associated_package_type}
                style={{
                  backgroundColor: "white",
                }}
              >
                <option value="">Please Select...</option>
                {["Private", "NDIS", "HCP"]?.map((elem: any, index: any) => (
                  <option key={index} value={elem}>
                    {elem}
                  </option>
                ))}
              </select>

              <span className="field-border field-border-add-form"></span>
            </div>

            <div className="field">
              <span className="field-title">Tax Rate</span>
              <select
                className="field-type custom-select-2"
                onChange={(e) =>
                  setBillingFormData((prevState: any) => ({
                    ...prevState,
                    tax_rate: e.target.value,
                  }))
                }
                value={billingFormData.tax_rate}
                style={{
                  backgroundColor: "white",
                }}
              >
                <option value="">Please Select...</option>
                {["GST Free", "GST Exclusive", "GST Inclusive"]?.map(
                  (elem: any, index: any) => (
                    <option key={index} value={elem}>
                      {elem}
                    </option>
                  )
                )}
              </select>
              <span className="field-border field-border-add-form"></span>
            </div>
          </div>
        </div>
        <div className="bottomBlock">
          <button className="save-btn" onClick={handleSubmitBilling}>
            {IsLoading ? "Loading..." : "Save"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default BillingForm;
