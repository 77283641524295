// @ts-nocheck
import ReactDatePicker from "react-datepicker";
import { selectDateCategories } from "../../Daybook/Daybook";

const SelectDate = () => {
  return (
    <div
      className="roster_select_date"
      style={{
        width: "515px",
      }}
    >
      <div className="daybook__content__row-1">
        <h1>Select Date:</h1>
        {selectDateCategories.map(({ title, id, value }: any, index) => (
          <div
            key={index}
            style={{
              width: id === 5 ? 100 : "fit-content",
              textAlign: "center",
            }}
            className={` sort__tab ${(false && "sort__tab__selected") || ""}`}
          >
            {title}

            {id === 5 && (
              <div className="date-picker-wrapper">
                <ReactDatePicker
                  showYearDropdown
                  showMonthDropdown
                  className="date__picker"
                />
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SelectDate;
