import React from "react";
import { Fragment, useEffect, useRef, useState } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../Controller/redux/Hooks";
import {
  createAnswerCustomFormTab,
  createCustomFormTab,
  createQuestionCustomFormTab,
  deleteQuestionCustomFormTab,
  deleteTabCustomFormTab,
  // getCustomFormsTabs,
  updateCustomFormTab,
  updateQuestionCustomFormTab,
  updateQuestionSequence,
} from "../../../../Controller/redux/features/ClinicalFeature/clinicalSlice";
// @ts-ignore
import SignatureCanvas from "react-signature-canvas";
import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import PlusSVG from "../../../pages/People/ClientPage/Components/UniqueClientScreen/Components/FinanceTab/Data/PlusSVG";
import { toast } from "react-toastify";
import _ from "lodash";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import TimePicker from "../../Time/Time";
import convertToHTML from "draftjs-to-html";
import { Radio, RadioGroup, Spinner, Stack, Switch } from "@chakra-ui/react";
import { Editor } from "react-draft-wysiwyg";
import { useLocation, useParams } from "react-router-dom";
import DeleteSVG from "../../../pages/People/ClientPage/Components/UniqueClientScreen/Components/FinanceTab/Data/DeleteSVG";
import {
  getFileThunk,
  uploadFileThunk,
} from "../../../../Controller/redux/features/CommonFeature/commonSlice";
import MultiSelectDropdown from "../../../pages/Ref-Data/BillingRule/Components/MultiSelectDropdown";
import {
  getCustomFormFromAnswerVersionNumberThunk,
  getCustomFormListThunk,
  getCustomFormVersionsThunk,
  getCustomFormsIdByThunk,
  getCustomFormsIdPaginateTrueByThunk,
  getCustomFormsTabsByIdThunk,
  getCustomFormsThunk,
  linkCustomFormTabHeaderThunk,
  publishCustomFormThunk,
  resetActiveFormTabsArr,
  updateCustomFormByIdThunk,
  updateExcludedProviders,
  updateTabsSequence,
} from "../../../../Controller/redux/features/CustomFromFeature/customFormSlice";
import CustomDatePicker from "../../DatePicker/CustomDatePicker";
import { countries } from "../../../../Model/Data/Countries";
import { languages } from "../../../../Model/Data/Languages";
import { majorReligions } from "../../../../Model/Data/Religions";
import {
  prospectsFieldsArr,
  clientFieldsArr,
  businessFieldsArr,
  locationsFiedlsArr,
  teamsFieldsArr,
  ContactsFieldsArr,
  staffsFieldsArr,
} from "../Data/UsersFieldArr";
import { optionsArr } from "../Data/OptionsArr";
import ConfirmationModal from "../../ConfirmationModal/ConfirmationModal";
import CustomMultiSelect from "../../MultiSelectDropdown/MultiSelect";
import {
  getProvidersByFilterThunk,
  resetProvidersData,
  resetProvidersPage,
} from "../../../../Controller/redux/features/ProviderFeature/providerSlice";
import {
  getDocuments,
  getDocumentsForClinicalThunk,
} from "../../../../Controller/redux/features/CommonFeature/documentSlice";
import useDimensions from "../../../../Model/utils/useDimensions";
import CustomSlider from "./../../Slider/CustomSlider";
import { LinkSVG } from "../Data/SVGs";
import {CrossMdSvg} from "../../../../Model/Assets/SVG/Common/CrossSvg";

const CustomForm = ({
  isSideBar = false,
  hasUnsavedChanges,
  sethasunsavedChanges = () => {},
  setuploadDocument = () => {},
  setclosingCustomForm = () => {},
  dataObject,
  segmentsWrapperRef,
  answerVersionValue,
  currentCustomFormId,
  currentDocumentId,
}: any) => {
  const { selectedActiveForm, selectedActiveFormTabsArr, selectedTabStore } =
    useAppSelector((state) => state?.customForm);
  // console.log(answerVersionValue,'answerVersionValue')
  // console.log(selectedActiveForm,"selectedActiveForm")
  // console.log(selectedActiveForm, "selectedActiveForm");

  const [CurrentActiveFormDetails, setCurrentActiveFormDetails] = useState<any>(
    {}
  );

  const mainSectionRef = useRef<any>(null);
  const dispatch = useAppDispatch();
  const [isEditAddStateForTabs, setIsEditAddStateForTabs] = useState(false);
  const [selectedTab, setselectedTab] = useState<string>("");
  const [TabsArray, setTabsArray] = useState<any[]>([]);
  const [customeFormArr, setCustomeFormArr] = useState<any[]>([]);
  // const { customFormTabs } = useAppSelector((state) => state?.clinical);
  const dynamicColor = useAppSelector((state) => state?.common?.dynamicColor);
  const [tenantId, setTenantId] = useState<any>("");
  const [clientId, setClientId] = useState("");
  const id: any = useParams<any>().id;
  const [isSubmitData, setIsSubmitData] = useState<boolean>(false);
  const [deletedTab, setDeletedTab] = useState(false);

  // @ts-ignore
  const userDetails = JSON.parse(localStorage.getItem("user"));
  const userId = userDetails?.data?.id;
  const { height: contentContainerHeight } = useDimensions(
    isSideBar ? mainSectionRef : segmentsWrapperRef
  );

  useEffect(() => {
    if (id) {
      const realId = window.atob(id);
      setClientId(realId);
    } else {
      setClientId(userId);
    }
  }, [id, userId]);

  // console.log(selectedActiveForm, "selectedActiveForm");
  const [isLoading, setisLoading] = useState(false);

  useEffect(() => {
    if (clientId && CurrentActiveFormDetails) {
      if (isSideBar) {
        // setisLoading(true);
        dispatch(getCustomFormListThunk());

        dispatch(
          getCustomFormsIdPaginateTrueByThunk({
            userId: clientId,
            id: CurrentActiveFormDetails?.id,
            document_id: dataObject?.id,
          })
        )
          .then((res: any) => {
            if (res.meta.requestStatus === "fulfilled") {
              setisLoading(false);
            }
          })
          .catch((err: any) => {
            setisLoading(false);
          });
      } else {
        setisLoading(true);
        dispatch(
          getCustomFormsTabsByIdThunk({
            user_id: clientId,
            custom_form_id:
              selectedActiveForm?.custom_form_id || selectedActiveForm?.id,
            document_id: dataObject?.id,
          })
        )
          .then((res: any) => {
            if (res.meta.requestStatus === "fulfilled") {
              setisLoading(false);
            }
          })
          .catch((err: any) => {
            setisLoading(false);
          });
      }
    }
  }, [clientId, CurrentActiveFormDetails, isSideBar]);
  // console.info({
  //   isLoading,
  // });
  // // console.log(TabsArray, "TabsArray");

  // useEffect(() => {
  //   setselectedTab("")

  // },[])

  // const [currentId, setcurrentId] = useState<any>("");
  // console.log(selectedActiveForm,'selectedActiveForm')
  // useEffect(() => {
  //   if (Array.isArray(selectedActiveForm)) {
  //     let currentId = selectedActiveFormTabsArr?.find(
  //       (elem: any) => elem?.id == selectedTab
  //     )?.custom_form_id;
  //     setcurrentId(currentId);
  //   }
  // }, [selectedActiveForm]);

  // console.log(currentId, 'selectedActiveForm')
  const [openColorModal, setOpenColorModal] = useState(false);

  // const [StaticLoding, setStaticLoding] = useState(false);
  const { user } = useAppSelector((state) => state?.common);
  // console.log(clientName,'clientName')
  const [currentClient, setCurrentClient] = useState<any>(null);
  const [isAdministrator, setIsAdministrator] = useState(false);
  const [CurrentUserTenantIdIs, setCurrentUserTenantIdIs] = useState<any>(null);
  const [particularAnswer, setParticularAnswer] = useState({});
  const { uniqueClient, isClinicalAddNote } = useAppSelector(
    (state) => state.people
  );
  useEffect(() => {
    if (uniqueClient?.data?.data.length > 0) {
      setCurrentClient(uniqueClient?.data?.data[0]);
    } else {
      setCurrentClient(null);
    }
  }, [uniqueClient, clientId]);
  const convertToArray = (obj: any) => {
    const resultArray: any = [];
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        resultArray?.push(obj[key]);
      }
    }

    return resultArray;
  };

  const [CustomCalss, setCustomCalss] = useState("");

  useEffect(() => {
    if (isSubmitData) {
      setclosingCustomForm(true);
    }
  }, [isSubmitData]);

  // console.log(selectedActiveForm,'selectedActiveForm')
  useEffect(() => {
    if (selectedActiveForm?.id) {
      dispatch(getCustomFormVersionsThunk(selectedActiveForm?.id));
    }
  }, [selectedActiveForm]);

  const { customFormVersions, getCustomFormList } = useAppSelector(
    (state) => state.customForm
  );

  const { CustomFormError } = useAppSelector((state) => state.clinical);

  // console.log(error,'error')

  useEffect(() => {
    if (
      Array.isArray(selectedActiveFormTabsArr) &&
      selectedActiveFormTabsArr?.length !== 0 &&
      selectedTab
    ) {
      let arr = selectedActiveFormTabsArr?.filter(
        (elem: { id: string }, index: any) => elem.id == selectedTab
      );
      setTenantId(arr[0]?.tenant_id);
      const questionArr = arr?.map((elem: any, index: number) =>
        elem?.custom_header_questions?.map((elem: any, index: number) => {
          let parseQuestion = JSON.parse(elem?.question);
          return {
            ...parseQuestion,
            id: elem?.id,
            tenant_id: arr[0]?.tenant_id,
            custom_header_id: elem?.custom_header_id,
          };
        })
      );

      // console.log(TabsArray, "arrayyyyy");
      const answerArr = arr?.map((elem: any, index: number) =>
        elem?.custom_header_answers.length !== 0
          ? elem?.custom_header_answers?.map((elem: any, index: number) => {
              let parseAnswer = JSON.parse(elem?.answer);
              const updatedArray = parseAnswer?.map((item: any) => {
                return {
                  ...item,
                  user_id: elem?.user_id,
                  answer_id: elem?.id,
                };
              });
              return updatedArray;
            })
          : []
      );

      // // console.log(answerArr, "answerARR")

      const updatedQuestionArr = questionArr.map((question: { id: any }) => {
        const answerObject = answerArr[0].find(
          (answer: any) => answer.id == question.id
        );
        if (answerObject) {
          return { ...question, ...answerObject };
        } else {
          return { ...question };
        }
      });

      const convertedArray = convertToArray(updatedQuestionArr[0]);
      setCustomeFormArr(convertedArray);
    } else {
      setCustomeFormArr([]);
    }
  }, [selectedActiveFormTabsArr, selectedTab]);

  let risks = [
    { text: "No Risk", hex: "gray" },
    { text: "Low Risk", hex: "yellow" },
    { text: "High Risk", hex: "red" },
    { text: "Medium Risk", hex: "orange" },
    { text: "Extreme Risk", hex: "black" },
  ];
  const [IsThereAnyWarningExist, setIsThereAnyWarningExist] = useState(false);

  useEffect(() => {
    if (customeFormArr?.length > 0) {
      let filterWarningQuestions = customeFormArr?.filter(
        (res) => res?.isWarning
      );

      if (filterWarningQuestions.length > 0) {
        setIsThereAnyWarningExist(true);
        let newUpdatedArrWithResult: any[] = filterWarningQuestions?.map(
          (res) => {
            return {
              type: res?.warningType,
              isComplete: false,
            };
          }
        );
        // .filter((res) => !res?.isComplete);

        if (newUpdatedArrWithResult.length > 0) {
          if (newUpdatedArrWithResult.map((res) => res?.type).includes("red")) {
            setCustomCalss("custom_form_tab_wrapper_red");
          } else if (
            newUpdatedArrWithResult.map((res) => res?.type).includes("orange")
          ) {
            setCustomCalss("custom_form_tab_wrapper_orange");
          } else if (
            newUpdatedArrWithResult.map((res) => res?.type).includes("yellow")
          ) {
            setCustomCalss("custom_form_tab_wrapper_yellow");
          } else if (
            newUpdatedArrWithResult.map((res) => res?.type).includes("green")
          ) {
            setCustomCalss("custom_form_tab_wrapper_green");
          } else setCustomCalss("");
        } else setCustomCalss("");
      } else setCustomCalss("");
    } else {
      setCustomCalss("");
    }
  }, [customeFormArr]);

  const createQuestion = async (questionInfo: any) => {
    if (!questionInfo?.custom_header_id) {
      toast.warn("Please select the tab and try again.");
      return;
    }
    try {
      const response = await dispatch(
        createQuestionCustomFormTab({ ...questionInfo })
      );

      if (response?.payload?.status == "success") {
        toast.success("Question Created Successfully!", {
          progressStyle: { backgroundColor: dynamicColor },
          icon: (
            <span
              style={{
                marginRight: "8px",
                borderRadius: "50%",
                width: "7rem",
                height: "35px",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: dynamicColor,
                backgroundColor: "white",
                fontSize: "1.5rem",
                border: `3px solid ${dynamicColor}`,
              }}
            >
              ✓
            </span>
          ),
        });
        setSelectedQuestionInfo(null);
        // if (clientId) {
        // dispatch(getCustomFormsTabs({ clientId }));
        // setselectedTab("");
        dispatch(
          getCustomFormsTabsByIdThunk({
            user_id: clientId,
            custom_form_id: selectedActiveForm?.id,
            document_id: dataObject?.id,
          })
        );
        // }
        setCurrentActiveIndex(null);
      } else {
        toast.error(response?.payload);
      }
    } catch (error: any) {
      toast.error(CustomFormError);
    } finally {
      setIsQuestionCreatePending(false);
    }
  };

  const isAdmin = isAdministrator && CurrentUserTenantIdIs === tenantId;

  const [defaultName, setDefaultName] = useState("");

  useEffect(() => {
    if (customFormVersions.length > 0) {
      setDefaultName(customFormVersions[0]?.form_name || "");
    }
  }, [customFormVersions]);

  useEffect(() => {
    if (user) {
      let RoleDesc = user?.roles[0]?.desc;
      setIsAdministrator(
        user?.tenant?.id === user?.tenant?.parent_id ? true : false
      );
      setCurrentUserTenantIdIs(user?.tenant_id);
    }
  }, [user]);

  const [selectedVersionValue, setselectedVersionValue] = useState("");
  let flag = localStorage.getItem("FIRST_TIME_FORM");

  // console.log(selectedVersionValue,'selectedVersionValue')
  useEffect(() => {
    if (customFormVersions.length && flag == "true") {
      setselectedVersionValue(customFormVersions[0].id);
      localStorage.removeItem("FIRST_TIME_FORM");
    }
  }, [customFormVersions, flag]);

  const [tabInputValue, setTabInputValue] = useState("");
  // const [formTypeValue, setFormTypeValue] = useState("");
  const [IsOnAddState, setIsOnAddState] = useState(false);
  const [showCfListDropdown, setShowCfListDropdown] = useState(false);
  const [selectedHeader, setselectedHeader] = useState<any>(null);
  const [selectedCustomHeader, setselectedCustomHeader] = useState<any>(null);

  // console.log(selectedHeader,'selectedHeader')
  let docIdLS = localStorage.getItem("docIdLS");
  useEffect(() => {
    setTabInputValue("");
    setIsEditAddStateForTabs(false);
    setIsOnAddState(false);
    if (clientId) {
      if (isSideBar) {
        // dispatch(
        //   getCustomFormsTabsByIdThunk({
        //     user_id: clientId,
        //     custom_form_id: selectedActiveForm?.id,
        //     document_id: dataObject?.id,
        //   })
        // );
      } else {
        // dispatch(
        //   getCustomFormsTabsByIdThunk({
        //     user_id: clientId,
        //     custom_form_id:
        //       selectedActiveForm?.custom_form_id || selectedActiveForm?.id,
        //     document_id: dataObject?.id,
        //   })
        // );
      }
    }
  }, [selectedTab, isSideBar, clientId]);

  // useEffect(() => {
  //   if (!isSideBar) {
  //     dispatch(getCustomFormsTabs({ clientId }));
  //   }
  // }, [clientId]);

  // const [tabTextAreaDetails, setTabTextAreaDetails] = useState<any>({});

  // console.log(selectedActiveForm?.id,'selectedActiveForm')

  // console.log(selectedActiveForm, "selectedAactiveForm");
  const handleTabChange = (tab: any) => {
    if (isSideBar && selectedActiveForm?.custom_form_id) {
      dispatch(
        getCustomFormsTabsByIdThunk({
          user_id: clientId,
          custom_form_id: selectedActiveForm?.custom_form_id,
          document_id: dataObject?.id,
        })
      );
    } else if (selectedActiveForm.custom_form_id) {
      dispatch(
        getCustomFormFromAnswerVersionNumberThunk({
          userId: clientId,
          customFormId:
            currentCustomFormId || selectedActiveForm?.custom_form_id,
          documentId: currentDocumentId || dataObject?.id,
          versionNumber: answerVersionValue,
        })
      );
    }

    sethideImageShowPad(-1);
    // setFetchedImages(null)
    // setexpandedGoal(false);
    handleSubmitAnswer("tabs");
    setselectedTab(tab?.id);
    setCustomeFormArr([]);
    // @ts-ignore
    // setTabTextAreaDetails((prevDetails) => ({
    //   ...prevDetails,
    //   [selectedTab]: tabTextAreaDetails[selectedTab] || "",
    // }));
  };
  const { getProviders } = useAppSelector((state) => state?.providers);

  const [SelectedTabInfo, setSelectedTabInfo] = useState<any>({});

  const handleOnClickEdit = () => {
    let findIndex = TabsArray.findIndex((res) => res?.id === selectedTab);
    if (findIndex !== -1) {
      let selectedtabInfo = TabsArray[findIndex];
      setSelectedTabInfo(selectedtabInfo);
      setTabInputValue(selectedtabInfo?.custom_header_name);
    }
  };
  // To Create and Update Tab
  let isCreateTabPending = false;
  let isUpdateTabPending = false;

  const handleAddUpdate = async () => {
    if (tabInputValue == "") {
      toast.warning("Please enter tab name.");
      return;
    }
    if (isCreateTabPending) return;
    if (IsOnAddState) {
      try {
        isCreateTabPending = true;
        const response = await dispatch(
          createCustomFormTab({
            custom_header_name: tabInputValue,
            custom_form_id: CurrentActiveFormDetails?.id,
          })
        );

        if (response?.payload?.status == "success") {
          isCreateTabPending = false;
          toast.success("Tab Created Successfully!", {
            style: {},
            progressStyle: { backgroundColor: dynamicColor },
            icon: (
              <span
                style={{
                  marginRight: "8px",
                  borderRadius: "50%",
                  width: "7rem",
                  height: "35px",
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: dynamicColor,
                  backgroundColor: "white",
                  fontSize: "1.5rem",
                  border: `3px solid ${dynamicColor}`,
                }}
              >
                ✓
              </span>
            ),
          });
          // if (clientId) {
          // dispatch(getCustomFormsTabs({ clientId }));
          dispatch(
            getCustomFormsTabsByIdThunk({
              user_id: clientId,
              custom_form_id: selectedActiveForm?.id,
              document_id: dataObject?.id,
            })
          );
          // }
        } else {
          toast.error(response?.payload);
        }
      } catch (error: any) {
        toast.error(error?.message);
      }
      setIsEditAddStateForTabs(false);
      setTabInputValue("");
    } else {
      if (isUpdateTabPending) return;
      try {
        isUpdateTabPending = true;
        const response = await dispatch(
          updateCustomFormTab({
            custom_header_name: tabInputValue,
            custom_header_id: selectedTab,
          })
        );

        if (response?.payload?.status == "success") {
          isUpdateTabPending = false;
          toast.success("Tab updated successfully!", {
            progressStyle: { backgroundColor: dynamicColor },
            icon: (
              <span
                style={{
                  marginRight: "8px",
                  borderRadius: "50%",
                  width: "7rem",
                  height: "35px",
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: dynamicColor,
                  backgroundColor: "white",
                  fontSize: "1.5rem",
                  border: `3px solid ${dynamicColor}`,
                }}
              >
                ✓
              </span>
            ),
          });
          // if (clientId) {
          // dispatch(getCustomFormsTabs({ clientId }));
          dispatch(
            getCustomFormsTabsByIdThunk({
              user_id: clientId,
              custom_form_id: selectedActiveForm?.id,
              document_id: dataObject?.id,
            })
          );
          // }
        }
      } catch (error: any) {
        toast.error(error?.message);
      }
      setIsEditAddStateForTabs(false);
      setTabInputValue("");

      //   let findIndex = TabsArray.findIndex((res) => res === selectedTab);
      //   // // console.log("findIndex ==================>", findIndex);
      //   let copyTabsArr = TabsArray;
      //   copyTabsArr[findIndex] = tabInputValue;
      //   setTabsArray(copyTabsArr);
      //   setTabInputValue("");
      //   setIsEditAddStateForTabs(false);
      //   setIsOnAddState(false);
      //   setselectedTab(tabInputValue);
    }
  };

  // const debouncedApiCall = _.debounce(handleAddUpdate, 300);

  // useEffect(() => {
  //   const handleClick = () => {
  //     debouncedApiCall();
  //   };

  //   document?.getElementById("saveBTN")?.addEventListener("click", handleClick);

  //   return () => {
  //     document
  //       ?.getElementById("saveBTN")
  //       ?.removeEventListener("click", handleClick);
  //   };
  // }, [debouncedApiCall]);

  const [currentActiveIndex, setCurrentActiveIndex] = useState<any>(null);
  const [
    numberOfQuestionsHavingLogicOfAndOr,
    setnumberOfQuestionsHavingLogicOfAndOr,
  ] = useState<any>([]);

  const HanldeAddNewQuestion = () => {
    let copyCustomeFormArr = [...customeFormArr];
    let parms = {
      question: EditorState.createEmpty(),
      answerType: "text",
      questionText: "",
      isRequired: true,
      optionArr: [],
      customLogic: false,
      selectedQuestion: "",
      selectedQuestionId: "",
      expectedAnswer: "",
      optionQueAnsArr: [],
      logicType: "",
      selectedQuestion2Id: "",
      expectedAnswer2: "",
      isWarning: false,
      warningType: "",
      warningSelectedQuestionId: "",
      warningExapectedAnswer: "",
    };

    let numberOfRecords = copyCustomeFormArr?.push({ ...parms });
    setSelectedQuestionInfo(parms);
    setCustomeFormArr(copyCustomeFormArr);
    setCurrentActiveIndex(numberOfRecords - 1);
    setEditorState(EditorState.createEmpty());
  };

  const [SelectedQuestionInfo, setSelectedQuestionInfo] = useState<any>(null);

  const selectedQuestionItemRef = useRef();
  const [isQuestionCreatePending, setIsQuestionCreatePending] = useState(false);

  const [isThereAnyChanges, setIsThereAnyChanges] = useState(false);

  const handleAddQuestion = async () => {
    let parms = {
      custom_header_id: selectedTab,
      question: JSON.stringify(customeFormArr[currentActiveIndex]),
    };
    if (isQuestionCreatePending) {
      return;
    }
    setIsQuestionCreatePending(true);
    try {
      const response = await dispatch(
        createQuestionCustomFormTab({ ...parms })
      );

      if (response?.payload?.status == "success") {
        toast.success("Question Created Successfully!", {
          progressStyle: { backgroundColor: dynamicColor },
          icon: (
            <span
              style={{
                marginRight: "8px",
                borderRadius: "50%",
                width: "7rem",
                height: "35px",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: dynamicColor,
                backgroundColor: "white",
                fontSize: "1.5rem",
                border: `3px solid ${dynamicColor}`,
              }}
            >
              ✓
            </span>
          ),
        });
        setSelectedQuestionInfo(null);
        // if (clientId) {
        // dispatch(getCustomFormsTabs({ clientId }));
        dispatch(
          getCustomFormsTabsByIdThunk({
            user_id: clientId,
            custom_form_id: selectedActiveForm?.id,
            document_id: dataObject?.id,
          })
        );
        // }
        setCurrentActiveIndex(null);
      } else {
        toast.error(response?.payload);
      }
    } catch (error: any) {
      toast.error(error?.message);
    } finally {
      setIsQuestionCreatePending(false);
    }
    setIsThereAnyChanges(false);
    setSelectedQuestionInfo(null);
  };
  const [selectedProviders, setselectedProviders] = useState([]);
  const [providerId, setProviderId] = useState("");
  const { pro_id }: any = useParams();

  useEffect(() => {
    if (selectedActiveForm?.included_providers && getProviders.length > 0) {
      const includedProviderIds = selectedActiveForm.included_providers;

      let matchProvidersArr = getProviders.filter((elem: any) =>
        includedProviderIds.includes(elem?.id)
      );

      matchProvidersArr = matchProvidersArr.filter((elem: any) => elem);

      setselectedProviders(matchProvidersArr);
    } else {
      setselectedProviders([]);
    }
  }, [selectedActiveForm, getProviders]);

  // console.log(selectedProviders, "SELECTEDPROVIDERS");

  const receiveArraysFromChild = async (type: any, newOptions: any) => {
    let getApproverIdsInArr = newOptions?.map((res: any) => res?.id);
    let tempObj = {
      custom_form_id: selectedActiveForm?.id,
      tenant_id: userDetails?.tenant_id,
      included_providers: getApproverIdsInArr,
    };

    const response = await dispatch(updateExcludedProviders(tempObj));
    if (response?.payload?.status === "success") {
      dispatch(getCustomFormsThunk(userId));
      // if(true){
      //   toast.success("Provider added successfully!", {
      //     progressStyle: { backgroundColor: dynamicColor },
      //     icon: (
      //       <span
      //         style={{
      //           marginRight: "8px",
      //           borderRadius: "50%",
      //           width: "7rem",
      //           height: "35px",
      //           textAlign: "center",
      //           display: "flex",
      //           justifyContent: "center",
      //           alignItems: "center",
      //           color: dynamicColor,
      //           backgroundColor: "white",
      //           fontSize: "1.5rem",
      //           border: `3px solid ${dynamicColor}`,
      //         }}
      //       >
      //         ✓
      //       </span>
      //     ),
      //   });
      // }
      setselectedProviders(newOptions);
    } else {
      toast.error(response?.payload);
    }
  };
  let isPendingAnswer = false;

  // console.log(dataObject,"dataObject")

  const handleSubmitAnswer = async (props: string) => {
    sethideImageShowPad(-1);
    if (props === "tabs") {
      if (!isSubmitData) {
        // setuploadDocument(1)
        return;
      }
      let params = {
        custom_header_id: selectedTab,
        user_id: clientId,
        answer: JSON.stringify(customeFormArr),
        custom_header_answer_id: customeFormArr[0]?.answer_id
          ? customeFormArr[0]?.answer_id
          : "",
        document_id: dataObject?.id,
      };
      if (isPendingAnswer) return;

      try {
        isPendingAnswer = true;
        const response = await dispatch(
          createAnswerCustomFormTab({ ...params })
        );

        if (response?.payload?.status == "success") {
          isPendingAnswer = false;
          //  setuploadDocument(1)

          // setSelectedQuestionInfo(null);
          setIsSubmitData(false);
          // if (clientId) {
          // dispatch(getCustomFormsTabs({ clientId }));
          if (isSideBar) {
            dispatch(
              getCustomFormsTabsByIdThunk({
                user_id: clientId,
                custom_form_id: selectedActiveForm?.id,
                document_id: dataObject?.id,
              })
            );
          } else {
            dispatch(
              getCustomFormsTabsByIdThunk({
                user_id: clientId,
                custom_form_id: selectedActiveForm?.custom_form_id,
                document_id: dataObject?.id,
              })
            );
          }

          // }
          setCurrentActiveIndex(null);
        }
      } catch (error: any) {
        //  toast.error(error?.message);
        //  setuploadDocument(1)
      }
      setIsThereAnyChanges(false);
      setSelectedQuestionInfo(null);
    } else {
      if (!isSubmitData) {
        setSelectedQuestionInfo(null);
        setCurrentActiveIndex(null);

        setuploadDocument(1);
        return;
      }
      let params = {
        custom_header_id: selectedTab,
        user_id: clientId,
        answer: JSON.stringify(customeFormArr),
        custom_header_answer_id: customeFormArr[0]?.answer_id
          ? customeFormArr[0]?.answer_id
          : "",
        document_id: dataObject?.id,
      };
      if (isPendingAnswer) return;
      try {
        isPendingAnswer = true;
        const response = await dispatch(
          createAnswerCustomFormTab({ ...params })
        );

        if (response?.payload?.status == "success") {
          isPendingAnswer = false;
          setuploadDocument(1);

          toast.success("Answer Submitted Successfully!", {
            progressStyle: { backgroundColor: dynamicColor },
            icon: (
              <span
                style={{
                  marginRight: "8px",
                  borderRadius: "50%",
                  width: "7rem",
                  height: "35px",
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: dynamicColor,
                  backgroundColor: "white",
                  fontSize: "1.5rem",
                  border: `3px solid ${dynamicColor}`,
                }}
              >
                ✓
              </span>
            ),
          });
          // setSelectedQuestionInfo(null);
          setIsSubmitData(false);
          // if (clientId) {
          // dispatch(getCustomFormsTabs({ clientId }));
          dispatch(
            getCustomFormsTabsByIdThunk({
              user_id: clientId,
              custom_form_id: selectedActiveForm?.id,
              document_id: dataObject?.id,
            })
          );
          // }
          setCurrentActiveIndex(null);
        } else {
          toast.error(CustomFormError);
        }

        dispatch(getDocuments({ clientId, providerId }));
      } catch (error: any) {
        toast.error(error);
        setuploadDocument(1);
      }
      setIsThereAnyChanges(false);
      setSelectedQuestionInfo(null);
    }

    dispatch(getDocumentsForClinicalThunk({ clientId, providerId }));
  };

  const [isSigning, setisSigning] = useState(false);

  const signatureCanvas = useRef<any>(null);
  //   useEffect(() => {
  //   if (true && signatureCanvas.current) {
  //     const canvas = signatureCanvas?.current;
  //     const context = canvas?.getContext('2d');
  //     const image = new Image();

  //     image.onload = () => {
  //       context.drawImage(image, 0, 0);
  //     };
  //     image.src = "true";
  //   }
  // }, []);

  const [hideImageShowPad, sethideImageShowPad] = useState(-1);

  const clearSignature = () => {
    signatureCanvas.current.clear();
  };

  const saveSignature = (index: number) => {
    setIsSubmitData(true);
    setIsThereAnyChangesHadMad(true);
    const signatureData = signatureCanvas.current.toDataURL();
    onChangeQuestionAnswerValue(signatureData, index);
  };
  const [isQuestionUpdatePending, setIsQuestionUpdatePending] = useState(false);
  const handleUpdateQuestion = async () => {
    let parms = {
      custom_header_question_id: SelectedQuestionInfo?.id,
      question: JSON.stringify(customeFormArr[currentActiveIndex]),
    };
    if (isQuestionUpdatePending) {
      return;
    }
    setIsQuestionUpdatePending(true);
    try {
      const response = await dispatch(
        updateQuestionCustomFormTab({ ...parms })
      );
      if (response?.payload?.status == "success") {
        toast.success("Question updated successfully!", {
          progressStyle: { backgroundColor: dynamicColor },
          icon: (
            <span
              style={{
                marginRight: "8px",
                borderRadius: "50%",
                width: "7rem",
                height: "35px",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: dynamicColor,
                backgroundColor: "white",
                fontSize: "1.5rem",
                border: `3px solid ${dynamicColor}`,
              }}
            >
              ✓
            </span>
          ),
        });
        // if (clientId) {
        // dispatch(getCustomFormsTabs({ clientId }));
        dispatch(
          getCustomFormsTabsByIdThunk({
            user_id: clientId,
            custom_form_id: selectedActiveForm?.id,
            document_id: dataObject?.id,
          })
        );
        // }
        setCurrentActiveIndex(null);
        setSelectedQuestionInfo(null);
      } else {
        toast.error(response?.payload);
      }
    } catch (error: any) {
      toast.error(CustomFormError);
    } finally {
      setIsQuestionUpdatePending(false);
    }
    setSelectedQuestionInfo(null);
  };

  const apiCallPending = useRef(false);

  useEffect(() => {
    function handleClickOutside(event: any) {
      const isOutsideClick =
        selectedQuestionItemRef.current &&
        // @ts-ignore
        !selectedQuestionItemRef.current.contains(event.target);

      if (isOutsideClick && !apiCallPending.current) {
        if (!isThereAnyChanges) {
          // // console.log("herrerere");
          setSelectedQuestionInfo(null);
          setCurrentActiveIndex(null);
          return;
        }
        apiCallPending.current = true;

        if (SelectedQuestionInfo?.id) {
          handleUpdateQuestion();
        } else {
          handleAddQuestion();
        }
        setTimeout(() => {
          apiCallPending.current = false;
        }, 500);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      apiCallPending.current = false;
    };
  }, [customeFormArr, selectedQuestionItemRef, SelectedQuestionInfo]);

  const editorRef = useRef<any>(null);
  const [editorFocus, setEditorFocus] = useState(false);
  const [editorState, setEditorState] = useState(EditorState?.createEmpty());
  const [isThereMessage, setisThereMessage] = useState("");
  const HandleChangeEditor = (value: any, arrayIndex: number) => {
    setEditorState(value);
    const contentState = value.getCurrentContent();
    const rawContentState = convertToRaw(contentState);
    const newObject = {
      entityMap: rawContentState.entityMap,
      blocks: rawContentState.blocks.map((block) => ({
        key: block.key,
        text: block.text,
        type: block.type,
        depth: block.depth,
        inlineStyleRanges: block.inlineStyleRanges,
        entityRanges: block.entityRanges,
      })),
    };
    // setisThereMessage(newObject);
    setisThereMessage(newObject?.blocks[0]?.text);
    const contentString = JSON.stringify(newObject);
    onChnageEditor(contentString);
    // setcommsMessageToSendToBackend(contentString);
  };

  const [showsignaturePad, setshowSignaturePad] = useState(false);

  const onChangeAnswerType = (value: string) => {
    if (value === "signature") {
      setshowSignaturePad(true);
    }
    let newArray = customeFormArr?.map((obj, index) => {
      if (index === currentActiveIndex) {
        return { ...obj, answerType: value };
      }
      return obj;
    });
    setCustomeFormArr(newArray);
  };

  // console.warn(customeFormArr,'customeFormArr')
  const onChangeLogicalQuestionType = (value: string) => {
    let newArray = customeFormArr?.map((obj, index) => {
      if (index === currentActiveIndex) {
        return { ...obj, logicType: value };
      }
      return obj;
    });
    setCustomeFormArr(newArray);
  };

  var onChnageEditor = (value: any) => {
    let jsonParseValues = JSON.parse(value);
    let newArray = customeFormArr?.map((obj, index) => {
      if (index === currentActiveIndex) {
        return {
          ...obj,
          question: value,
          questionText: jsonParseValues?.blocks[0]?.text,
        };
      }
      return obj;
    });
    setSelectedQuestionInfo({
      ...SelectedQuestionInfo,
      question: value,
      questionText: jsonParseValues?.blocks[0]?.text,
    });
    setCustomeFormArr(newArray);
  };

  const handleChangeIsRequired = () => {
    let newArray = customeFormArr?.map((obj, index) => {
      if (index === currentActiveIndex) {
        setSelectedQuestionInfo({
          ...SelectedQuestionInfo,
          isRequired: !obj.isRequired,
        });
        return { ...obj, isRequired: !obj.isRequired };
      }
      return obj;
    });
    setCustomeFormArr(newArray);
  };
  let isDeleteQuestionPending = false;
  const handleDeleteQuestion = async (id: any) => {
    if (isDeleteQuestionPending) return;
    try {
      isDeleteQuestionPending = true;
      const response = await dispatch(
        deleteQuestionCustomFormTab({
          custom_header_question_id: +id,
        })
      );

      if (response?.meta?.requestStatus === "fulfilled") {
        isDeleteQuestionPending = false;
        toast.success("Question Deleted Successfully!", {
          // style: {},
          progressStyle: { backgroundColor: dynamicColor },
          icon: (
            <span
              style={{
                marginRight: "8px",
                borderRadius: "50%",
                width: "7rem",
                height: "35px",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: dynamicColor,
                backgroundColor: "white",
                fontSize: "1.5rem",
                border: `3px solid ${dynamicColor}`,
              }}
            >
              ✓
            </span>
          ),
        });
        // if (clientId) {
        // dispatch(getCustomFormsTabs({ clientId }));
        dispatch(
          getCustomFormsTabsByIdThunk({
            user_id: clientId,
            custom_form_id: selectedActiveForm?.id,
            document_id: dataObject?.id,
          })
        );
        // }
        setCurrentActiveIndex(null);
        setSelectedQuestionInfo(null);
      } else {
        toast.error(response?.payload);
      }
    } catch (error) {
      throw error;
    }
  };
  let isDeleteTabPending = false;
  const [showConfirmationModal, setshowConfirmationModal] = useState(false);

  const confirmDeletion = () => {
    setshowConfirmationModal(true);
  };
  const handleDeleteTab = async () => {
    if (isDeleteTabPending) return;
    try {
      isDeleteTabPending = true;
      const response = await dispatch(
        deleteTabCustomFormTab({
          custom_header_id: selectedTab,
          custom_form_id: selectedActiveForm?.id,
        })
      );

      if (response?.meta?.requestStatus === "fulfilled") {
        isDeleteTabPending = false;
        toast.success("Tab Deleted Successfully!", {
          // style: {},
          progressStyle: { backgroundColor: dynamicColor },
          icon: (
            <span
              style={{
                marginRight: "8px",
                borderRadius: "50%",
                width: "7rem",
                height: "35px",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: dynamicColor,
                backgroundColor: "white",
                fontSize: "1.5rem",
                border: `3px solid ${dynamicColor}`,
              }}
            >
              ✓
            </span>
          ),
        });
        dispatch(
          getCustomFormsTabsByIdThunk({
            user_id: clientId,
            custom_form_id: selectedActiveForm?.id,
            document_id: dataObject?.id,
          })
        );
        // }
        setDeletedTab(true);
        setselectedTab("");
      } else {
        toast.error(response?.payload);
      }
    } catch (error) {
      throw error;
    }
  };
  useEffect(() => {
    if (currentActiveIndex !== null && selectedQuestionItemRef.current) {
      // @ts-ignore
      selectedQuestionItemRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [currentActiveIndex]);

  const RanderQuestion = (info: any, index: number, view: boolean) => {
    const dataObjectMessage = info?.question;
    let parsedDataObject: any;
    try {
      parsedDataObject = JSON.parse(dataObjectMessage);
    } catch (error) {
      parsedDataObject = null;
    }
    const htmlContent = convertToHTML(parsedDataObject);

    let className = "";
    if (info?.isWarning && info?.customLogic) {
      if (info?.warningType == "red") {
        className = "custom_form_warning_type_red";
      } else if (info?.warningType == "orange") {
        className = "custom_form_warning_type_orange";
      } else if (info?.warningType == "yellow") {
        className = "custom_form_warning_type_yellow";
      } else {
        className = "";
      }
    }

    return (
      <div
        key={index}
        className={`question_section_wrapper custom_logic_filed_wrapper ${className}`}
        id={info?.id}
      >
        <div
          className="question_wrapper"
          dangerouslySetInnerHTML={{
            __html: htmlContent,
          }}
        ></div>
        {randerInputAccordingToType(info?.answerType, info, index, false)}
      </div>
    );
  };

  const checkTheCustomLoginAndrender = (info: any, index: number) => {
    if (info?.logicType === "equal") {
      let findIndexInfo = customeFormArr.filter(
        (res: any) => res?.id == info?.selectedQuestionId
      );
      let copyObjectFormindex = findIndexInfo[0];
      if (
        copyObjectFormindex?.selectedAnswer?.toLocaleLowerCase() ==
        info?.expectedAnswer?.toLocaleLowerCase()
      ) {
        return RanderQuestion(info, index, false);
      }
    } else if (info?.logicType === "and" || info?.logicType === "or") {
      let findIndexInfoOFQ1 = customeFormArr.filter(
        (res: any) => res?.id == info?.selectedQuestionId
      );
      let copyObjectFormIndexQ1 = findIndexInfoOFQ1[0];

      let findIndexInfoOFQ2 = customeFormArr.filter(
        (res: any) => res?.id == info?.selectedQuestion2Id
      );
      let copyObjectFormIndexQ2 = findIndexInfoOFQ2[0];

      const condition1 =
        copyObjectFormIndexQ1?.selectedAnswer === info?.expectedAnswer;
      const condition2 =
        copyObjectFormIndexQ2?.selectedAnswer === info?.expectedAnswer2;

      // this if for logic type "AND"
      if (info?.logicType === "and" && condition1 && condition2) {
        return RanderQuestion(info, index, false);
      }
      // this if for logic type "OR"
      if (info?.logicType === "or" && (condition1 || condition2)) {
        return RanderQuestion(info, index, false);
      }
    } else {
      let findIndexInfo = customeFormArr.filter(
        (res: any) => res?.id == info?.selectedQuestionId
      );
      let copyObjectFormindex = findIndexInfo[0];
      if (
        copyObjectFormindex?.selectedAnswer?.toLocaleLowerCase() ==
        info?.expectedAnswer?.toLocaleLowerCase()
      ) {
        return RanderQuestion(info, index, false);
      }
    }
  };
  const handleChangeCustomLoginc = () => {
    let newArray = customeFormArr?.map((obj, index) => {
      if (index === currentActiveIndex) {
        setSelectedQuestionInfo({
          ...SelectedQuestionInfo,
          customLogic: !obj.customLogic,
        });
        return { ...obj, customLogic: !obj.customLogic };
      }
      return obj;
    });
    setCustomeFormArr(newArray);
  };

  const handleChangeCustomFormWarning = () => {
    // console.log(" hererrerererere ");
    let newArray = customeFormArr?.map((obj, index) => {
      if (index === currentActiveIndex) {
        setSelectedQuestionInfo({
          ...SelectedQuestionInfo,
          isWarning: !obj?.isWarning,
        });
        return { ...obj, isWarning: !obj?.isWarning };
      }
      return obj;
    });
    setCustomeFormArr(newArray);
  };

  const handleChangeCustomFormWarningFields = (
    value: string | boolean,
    fieldName: string
  ) => {
    let newArray = customeFormArr?.map((obj, index) => {
      if (index === currentActiveIndex) {
        setSelectedQuestionInfo({
          ...SelectedQuestionInfo,
          [fieldName]: value,
        });
        return { ...obj, [fieldName]: value };
      }
      return obj;
    });
    setCustomeFormArr(newArray);
  };

  const onChangeSelectQuestion = (value: string) => {
    let newArray = customeFormArr?.map((obj, index) => {
      if (index === currentActiveIndex) {
        setSelectedQuestionInfo({
          ...SelectedQuestionInfo,
          selectedQuestion: !obj.value,
        });
        return { ...obj, selectedQuestionId: value };
      }
      return obj;
    });
    setCustomeFormArr(newArray);
  };

  const onChangeSelectQuestion2 = (value: string) => {
    let newArray = customeFormArr?.map((obj, index) => {
      if (index === currentActiveIndex) {
        setSelectedQuestionInfo({
          ...SelectedQuestionInfo,
          selectedQuestion2Id: !obj.value,
        });
        return { ...obj, selectedQuestion2Id: value };
      }
      return obj;
    });
    setCustomeFormArr(newArray);
  };

  const [
    APIResponseFromBackendCustomForm,
    setAPIResponseFromBackendCustomForm,
  ] = useState<any>([]);

  const handleAddOptionsResponse = (index: number, value: string) => {
    if (!value) {
      return;
    }
    let numberList = value
      .split(",")
      .map((item: any) => item.trim())
      .filter((item: any) => !isNaN(item));
    let duplicateCustomFormArray = [...customeFormArr];
    duplicateCustomFormArray[index].optionArr = numberList;
    setCustomeFormArr(duplicateCustomFormArray);
  };
  const onChangeExpectedAnswer = (value: string) => {
    let newArray = customeFormArr?.map((obj, index) => {
      if (index === currentActiveIndex) {
        setSelectedQuestionInfo({
          ...SelectedQuestionInfo,
          expectedAnswer: value,
        });
        return { ...obj, expectedAnswer: value };
      }
      return obj;
    });
    setCustomeFormArr(newArray);
  };
  const onChangeExpectedAnswer2 = (value: string) => {
    let newArray = customeFormArr?.map((obj, index) => {
      if (index === currentActiveIndex) {
        setSelectedQuestionInfo({
          ...SelectedQuestionInfo,
          expectedAnswer2: value,
        });
        return { ...obj, expectedAnswer2: value };
      }
      return obj;
    });
    setCustomeFormArr(newArray);
  };

  const [imageUrl, setImageUrl] = useState(null);

  useEffect(() => {
    const updatedCustomForm = [...customeFormArr];
    if (currentActiveIndex) {
      updatedCustomForm[currentActiveIndex].image_url = imageUrl;
    }
  }, [imageUrl]);

  const onChangeQuestionAnswerValue = (value: string, objInd: number) => {
    const updatedQuestions = [...customeFormArr];
    updatedQuestions[+objInd].selectedAnswer = value;
    setParticularAnswer(updatedQuestions[+objInd]);
    setCustomeFormArr(updatedQuestions);
    let newArray = APIResponseFromBackendCustomForm.map(
      (obj: any, index: number) => {
        if (index === objInd) {
          return { ...obj, selectedAnswer: value };
        }
        return obj;
      }
    );
    setAPIResponseFromBackendCustomForm(newArray);
  };
  let element = getCustomFormList?.data?.find(
    (el: any) => el.id == selectedHeader
  )?.headers;
  useEffect(() => {
    let element = getCustomFormList?.data?.find(
      (el: any) => el.id == selectedHeader
    )?.headers;
    if (element) {
      setselectedCustomHeader(element[0]?.id);
    }
  }, [getCustomFormList, selectedHeader]);

  useEffect(() => {
    if (id) {
      let realId = window?.atob(id);
      setClientId(realId);
      if (pro_id) {
        setProviderId(window?.atob(pro_id));
      }
    }
  }, [id, pro_id]);

  // // console.log(APIResponseFromBackendCustomForm,"APIResponseFromBackendCustomForm")
  const [CurrentActiveQuestionAnswerType, setCurrentActiveQuestionAnswerType] =
    useState("text");

  const [SelectedDate, setSelectedDate] = useState(new Date());

  const [valueOption, setValueOption] = useState("");
  const [
    valueOptionForAddColorsAndTextForRisk,
    setValueOptionAddColorsAndTextForRisk,
  ] = useState<any>({
    text: "",
    color: "",
  });

  // console.log(valueOptionForAddColorsAndTextForRisk,'valueOptionForAddColorsAndTextForRisk')
  const [CurrentEditIndex, setCurrentEditIndex] = useState<any>(null);
  const [selectedImg, setSelectedImg] = useState(null);
  const handleImageUpload = async (event: any) => {
    const file = event.target.files[0];
    if (file && file.type.includes("image/")) {
      if (file.size <= 2 * 1024 * 1024) {
        setSelectedImg(file);
        const reader = new FileReader();
        reader.onload = async () => {
          // @ts-ignore
          const base64Data = reader.result.split(",")[1];
          const extension = file.name.split(".").pop();

          {
            let tempObj = {
              type: "custom_forms",
              extension: extension,
              base64: base64Data,
            };
            const data = { files: [tempObj] };
            let response = await dispatch(uploadFileThunk({ data }));

            if (response?.payload?.status === "success") {
              setImageUrl(response.payload.data[0]);
              toast.success("Image Uploaded Successfully!", {
                style: {},
                progressStyle: { backgroundColor: dynamicColor },
                icon: (
                  <span
                    style={{
                      marginRight: "8px",
                      borderRadius: "50%",
                      width: "7rem",
                      height: "35px",
                      textAlign: "center",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: dynamicColor,
                      backgroundColor: "white",
                      fontSize: "1.5rem",
                      border: `3px solid ${dynamicColor}`,
                    }}
                  >
                    ✓
                  </span>
                ),
              });
            }
          }
        };
        reader.readAsDataURL(file);
      } else {
        toast.error("Image size exceeds the maximum allowed size of 2MB.");
        event.target.value = null;
        setSelectedImg(null);
      }
    } else {
      toast.error("Please select a valid image.");
      event.target.value = null;
      setSelectedImg(null);
    }
  };

  // const handleCheckboxChange = (
  //   isChecked: boolean,
  //   value: string,
  //   index: number
  // ) => {
  //   let selectedAnswers = Array.isArray(customeFormArr[index]?.selectedAnswer)
  //     ? customeFormArr[index]?.selectedAnswer
  //     : (customeFormArr[index]?.selectedAnswer || "")
  //         .split(",")
  //         ?.filter(Boolean);
  //   let newSelectedAnswers;

  //   if (isChecked) {
  //     newSelectedAnswers = selectedAnswers.includes(value)
  //       ? selectedAnswers
  //       : [...selectedAnswers, value];
  //   } else {
  //     newSelectedAnswers = selectedAnswers.filter(
  //       (item: any) => item !== value
  //     );
  //   }
  //   let newCustomeFormArr = [...customeFormArr];
  //   newCustomeFormArr[index].selectedAnswer = newSelectedAnswers?.join(",");
  //   setCustomeFormArr(newCustomeFormArr);
  // };

  const [fetchedImages, setFetchedImages] = useState<any>([]);

  // Function to fetch image
  const fetchImage = async (imageUrl: string, index: number) => {
    try {
      const res = await dispatch(getFileThunk({ path: imageUrl }));
      setFetchedImages((prevImages: any) => ({
        ...prevImages,
        [index]: res?.payload?.data,
      }));
    } catch (error) {
      console.error("Error fetching image:", error);
    }
  };

  const [IsThereAnyChangesHadMad, setIsThereAnyChangesHadMad] = useState(false);

  useEffect(() => {
    customeFormArr?.forEach((arrayObject, index) => {
      if (arrayObject?.image_url) {
        fetchImage(arrayObject?.image_url, index);
      } else {
        setFetchedImages([]);
      }
    });
  }, [customeFormArr, selectedTab]);

  const randerInputAccordingToType = (
    type: string,
    arrayObject: any,
    index: number,
    viewOnly = true
  ) => {
    if (index === 0) {
      // console.log(arrayObject.selectedAnswer, "yeahhhhboy");
    }
    const handleAddOptions = () => {
      if (valueOption == "") {
        return;
      }
      let duplicateCustomFormArray = [...customeFormArr];
      let duplicateOptionArray = duplicateCustomFormArray[index];
      if (CurrentEditIndex !== null) {
        duplicateOptionArray.optionArr = duplicateOptionArray.optionArr.map(
          (res: string, index: number) => {
            if (index == CurrentEditIndex) {
              return valueOption;
            }
            return res;
          }
        );
        setCurrentEditIndex(null);
      } else {
        duplicateOptionArray.optionArr?.push(valueOption);
      }
      duplicateCustomFormArray[index] = duplicateOptionArray;
      setCustomeFormArr(duplicateCustomFormArray);
      setValueOption("");
      setIsThereAnyChanges(true);
    };

    ////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////
    const handleAddColorsAndTextForRisk = () => {
      if (valueOptionForAddColorsAndTextForRisk.text == "") {
        return;
      }

      let duplicateCustomFormArray = [...customeFormArr];
      let duplicateOptionArray = duplicateCustomFormArray[index];
      if (CurrentEditIndex !== null) {
        duplicateOptionArray.optionArr = duplicateOptionArray.optionArr.map(
          (res: string, index: number) => {
            if (index == CurrentEditIndex) {
              return valueOptionForAddColorsAndTextForRisk;
            }
            return res;
          }
        );
        setCurrentEditIndex(null);
      } else {
        duplicateOptionArray.optionArr?.push(
          valueOptionForAddColorsAndTextForRisk
        );
      }
      duplicateCustomFormArray[index] = duplicateOptionArray;
      setCustomeFormArr(duplicateCustomFormArray);
      setValueOption("");
      setIsThereAnyChanges(true);
    };

    // console.log(valueOptionForAddColorsAndTextForRisk,'CURRENT__EDITINDEX')

    const checkColorAndUpdate = (data: any) => {
      if (data.color == "") {
        return;
      }

      let duplicateCustomFormArray = [...customeFormArr];
      let duplicateOptionArray = duplicateCustomFormArray[index];
      if (CurrentEditIndex !== null) {
        duplicateOptionArray.optionArr = duplicateOptionArray.optionArr.map(
          (res: string, index: number) => {
            if (index == CurrentEditIndex) {
              return data;
            }
            return res;
          }
        );
        setCurrentEditIndex(null);
      } else {
        // duplicateOptionArray.optionArr?.push(valueOptionForAddColorsAndTextForRisk);
      }
      duplicateCustomFormArray[index] = duplicateOptionArray;
      setCustomeFormArr(duplicateCustomFormArray);
      setValueOption("");
      setIsThereAnyChanges(true);
    };

    ////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////

    const handleAddOptionsForResponseAndeScoreType = () => {
      if (valueOption == "") {
        return;
      }
      let duplicateCustomFormArray = [...customeFormArr];
      let duplicateOptionArray = duplicateCustomFormArray[index];
      if (CurrentEditIndex !== null) {
        duplicateOptionArray.optionQueAnsArr =
          duplicateOptionArray.optionQueAnsArr.map(
            (res: any, index: number) => {
              if (index == CurrentEditIndex) {
                return { ...res, name: valueOption, isChecked: false };
              }
              return res;
            }
          );
        setCurrentEditIndex(null);
      } else {
        duplicateOptionArray.optionQueAnsArr?.push({
          name: valueOption,
          answer: "",
          isChecked: false,
        });
      }
      duplicateCustomFormArray[index] = duplicateOptionArray;
      setCustomeFormArr(duplicateCustomFormArray);
      setValueOption("");
      setIsThereAnyChanges(true);
    };

    const removeOptionsForResponseAndeScoreType = (radioIndex: number) => {
      let duplicateCustomFormArray = [...customeFormArr];

      let duplicateOptionArray = duplicateCustomFormArray[index];
      let filteredOptions = duplicateOptionArray?.optionQueAnsArr?.filter(
        (res: any, fIndex: number) => fIndex !== radioIndex
      );
      duplicateOptionArray.optionQueAnsArr = filteredOptions;
      duplicateCustomFormArray[index] = duplicateOptionArray;
      setCustomeFormArr(duplicateCustomFormArray);
      setValueOption("");
      setIsThereAnyChanges(true);
    };
    const handleChangeDate = (date: any) => {
      setSelectedDate(date);
      if (!viewOnly) {
        onChangeQuestionAnswerValue(date, index);
        setIsSubmitData(true);
        setIsThereAnyChangesHadMad(true);
      }
    };
    const handleChangeValueOfAnswer = (Index: number, value: any) => {
      let duplicateCustomFormArray = [...customeFormArr];
      let duplicateOptionArray = duplicateCustomFormArray[index];
      duplicateOptionArray.optionQueAnsArr =
        duplicateOptionArray.optionQueAnsArr.map((res: any, index: number) => {
          if (index == Index) {
            return { ...res, answer: value };
          }
          return res;
        });
      duplicateCustomFormArray[index] = duplicateOptionArray;
      setCustomeFormArr(duplicateCustomFormArray);
      setIsThereAnyChanges(true);
    };

    const handleChangeValueOfIsChecked = (Index: number) => {
      let duplicateCustomFormArray = [...customeFormArr];
      let duplicateOptionArray = duplicateCustomFormArray[index];
      duplicateOptionArray.optionQueAnsArr =
        duplicateOptionArray.optionQueAnsArr.map(
          (res: any, opIndex: number) => {
            if (opIndex == Index) {
              return { ...res, isChecked: !res?.isChecked };
            }
            return res;
          }
        );
      duplicateCustomFormArray[index] = duplicateOptionArray;
      setCustomeFormArr(duplicateCustomFormArray);
    };

    const removeOption = (radioIndex: number) => {
      let duplicateCustomFormArray = [...customeFormArr];

      let duplicateOptionArray = duplicateCustomFormArray[index];
      let filteredOptions = duplicateOptionArray?.optionArr?.filter(
        (res: any, fIndex: number) => fIndex !== radioIndex
      );
      duplicateOptionArray.optionArr = filteredOptions;
      duplicateCustomFormArray[index] = duplicateOptionArray;
      setCustomeFormArr(duplicateCustomFormArray);
      setValueOption("");
      setIsThereAnyChanges(true);
    };

    const renderFieldsAccordingToScore = (field: string) => {
      switch (field) {
        case "user_addresses":
          return (
            <input
              type="text"
              placeholder="Please enter adress"
              readOnly
              value={
                !viewOnly && !isSideBar
                  ? user[field]?.length > 0
                    ? user[field][0]?.address
                    : ""
                  : ""
              }
            />
          );

        case "role":
          return (
            <input
              type="text"
              placeholder="Please enter role"
              readOnly
              value={
                !viewOnly && !isSideBar
                  ? user?.roles?.length > 0
                    ? user?.roles[0]?.desc
                    : ""
                  : ""
              }
            />
          );

        default:
          return (
            <input
              type="text"
              placeholder={"Please enter " + field}
              readOnly
              value={!viewOnly && !isSideBar ? currentClient[field] : ""}
            />
          );
      }
    };
    switch (type) {
      case "text":
        return (
          <input
            type="text"
            className="expected_outout_textarea"
            placeholder="Please enter value"
            width={"100%"}
            disabled={viewOnly}
            value={arrayObject?.selectedAnswer || ""}
            onChange={(e: any) => {
              if (!viewOnly) {
                onChangeQuestionAnswerValue(e.target.value, index);
                setIsSubmitData(true);
                setIsThereAnyChangesHadMad(true);
              }
            }}
          />
        );

      case "total":
        let filterScoreTypeQuestion = customeFormArr?.filter(
          (item: any) =>
            item.answerType == "score" && item?.selectedAnswer !== ""
        );

        let totalCalculation = filterScoreTypeQuestion
          ?.map((res: any) => {
            return res?.optionQueAnsArr?.filter((opt: any) => {
              if (opt?.name === res?.selectedAnswer) {
                return opt?.answer;
              }
            })[0];
          })
          .reduce((acc: any, cur: any) => acc + cur?.answer || 0, 0);

        return (
          <input
            type="text"
            className="expected_outout_textarea"
            placeholder="Please enter value"
            width={"100%"}
            // disabled={}
            readOnly
            value={totalCalculation}
            onChange={(e: any) => {
              if (!viewOnly) {
                onChangeQuestionAnswerValue(e.target.value, index);
                setIsSubmitData(true);
                setIsThereAnyChangesHadMad(true);
              }
            }}
          />
        );
      case "text-area":
        return (
          <textarea
            className="expected_outout_textarea"
            placeholder="Please enter answer"
            disabled={viewOnly}
            rows={1}
            value={arrayObject?.selectedAnswer}
            onChange={(e: any) => {
              if (!viewOnly) {
                onChangeQuestionAnswerValue(e.target.value, index);
                setIsSubmitData(true);
                setIsThereAnyChangesHadMad(true);
              }
            }}
          />
        );
      case "date":
        return (
          // <ReactDatePicker
          //   showYearDropdown
          //   showMonthDropdown
          //   className="date__picker"
          //   selected={SelectedDate}
          //   // value={SelectedDate}
          //   dateFormat="dd/MM/yyyy"
          //   onChange={(date: any) => {
          //     setSelectedDate(date);
          //     if (!viewOnly) {
          //       onChangeQuestionAnswerValue(date, index);
          //       setIsSubmitData(true);
          //       setIsThereAnyChangesHadMad(true);
          //     }
          //   }}
          // />
          <CustomDatePicker
            newDate={SelectedDate}
            handleChange={handleChangeDate}
          />
        );
      case "time":
        return (
          <div className="time_picker_wrapper">
            <TimePicker
              setTime={(e: any) => {
                if (!viewOnly) {
                  onChangeQuestionAnswerValue(e, index);
                  setIsSubmitData(true);
                  setIsThereAnyChangesHadMad(true);
                }
              }}
            />
          </div>
        );
      case "signature":
        return (
          <div
            className="signatue_pad"
            style={{
              display: isSideBar ? "none" : "",
            }}
          >
            <SignatureCanvas
              penColor="black"
              onEnd={() => saveSignature(index)}
              onBegin={() => setisSigning(true)}
              ref={signatureCanvas}
              canvasProps={{ width: 350, height: 130 }}
              imageSrc={arrayObject.selectedAnswer}
            />
            {arrayObject.selectedAnswer && (
              <>
                <img
                  src={arrayObject.selectedAnswer}
                  onClick={() => sethideImageShowPad(index)}
                  style={{
                    position: "absolute",
                    top: "0px",
                    right: "0px",
                    border: "0.5px solid silver",
                    height: "100%",
                    width: "100%",
                    display: hideImageShowPad === index ? "none" : "",
                  }}
                  width={160}
                />
              </>
            )}

            {isSigning && (
              <>
                <button
                  className="save_btn  plus_button"
                  style={{
                    fontSize: "12px",
                    height: "30px",
                    width: "70x",
                    padding: "3px",
                    margin: "5px",
                    display: "none",
                  }}
                  onClick={() => {
                    setshowSignaturePad(false);
                    clearSignature();
                  }}
                >
                  Save
                </button>
              </>
            )}
          </div>
        );
      case "radio":
        return (
          <div className="radio_group_wrapper">
            {viewOnly && (
              <div className="create_form_wrapper">
                <input
                  type="text"
                  placeholder={`Option ${arrayObject?.optionArr?.length + 1}`}
                  className="input_style_class"
                  onChange={(e) => {
                    setValueOption(e?.target?.value);
                    if (!viewOnly) {
                      onChangeQuestionAnswerValue(e.target.value, index);
                      setIsSubmitData(true);
                    }
                  }}
                  value={valueOption}
                  // value={arrayObject?.selectedAnswer}
                />
                <PlusSVG
                  marginLeft="10px"
                  height="20"
                  width="20"
                  onClick={() => {
                    handleAddOptions();
                  }}
                />
              </div>
            )}
            <RadioGroup
              display={"flex"}
              flexDirection={"column"}
              value={arrayObject?.selectedAnswer}
              onChange={(value: any) => {
                if (!viewOnly) {
                  onChangeQuestionAnswerValue(value, index);
                  setIsSubmitData(true);
                  setIsThereAnyChangesHadMad(true);
                }
              }}
              id={arrayObject?.questionText}
            >
              <Stack spacing={3} direction={viewOnly ? "column" : "row"}>
                {arrayObject?.optionArr.map((info: any, radioIndex: number) => (
                  <div
                    className="radio_section_wrapper radio_button_custom_form"
                    key={radioIndex}
                  >
                    {viewOnly && (
                      <div className="action_option">
                        {/* <EditIcon boxSize={3} /> */}
                        <DeleteIcon
                          boxSize={4}
                          ml={2}
                          onClick={() => {
                            removeOption(radioIndex);
                          }}
                        />
                        <EditIcon
                          position={"relative"}
                          ml={2}
                          boxSize={4}
                          onClick={() => {
                            setValueOption(info.text);
                            setCurrentEditIndex(radioIndex);
                          }}
                        />
                      </div>
                    )}
                    <Radio
                      key={radioIndex}
                      onChange={(e) => {
                        if (!viewOnly) {
                          onChangeQuestionAnswerValue(e.target.value, index);
                          setIsSubmitData(true);
                        }
                      }}
                      size={"md"}
                      value={info.text}
                      fontSize={"16px"}
                      className="radio__button radio_button_custom_form"
                      style={{
                        borderColor: "#06a5ff",
                        color: "white",
                      }}
                      id={arrayObject?.questionText}
                      // disabled
                    >
                      {info}
                    </Radio>
                  </div>
                ))}
              </Stack>
            </RadioGroup>
          </div>
        );
      case "dropdown":
        return (
          <div className="radio_group_wrapper">
            {viewOnly && (
              <div className="create_form_wrapper">
                <input
                  type="text"
                  placeholder={`Option ${arrayObject?.optionArr?.length + 1}`}
                  onChange={(e) => {
                    setValueOption(e?.target?.value);
                    if (!viewOnly) {
                      onChangeQuestionAnswerValue(e.target.value, index);
                      setIsSubmitData(true);
                    }
                  }}
                  value={valueOption}
                />
                {/* <AddIcon
                  boxSize={3}
                  ml={2}
                  onClick={() => {
                    handleAddOptions();
                  }}
                /> */}
                <PlusSVG
                  marginLeft="10px"
                  height="20"
                  width="20"
                  onClick={() => {
                    handleAddOptions();
                  }}
                />
              </div>
            )}
            <ol
              style={{
                marginLeft: "15px",
              }}
            >
              {viewOnly &&
                arrayObject?.optionArr.map((info: any, radioIndex: number) => {
                  return (
                    <li>
                      {info.text}
                      <DeleteIcon
                        boxSize={3}
                        ml={2}
                        onClick={() => {
                          removeOption(radioIndex);
                        }}
                      />
                      <EditIcon
                        position={"relative"}
                        ml={2}
                        boxSize={4}
                        onClick={() => {
                          setValueOption(info);
                          setCurrentEditIndex(radioIndex);
                        }}
                      />
                    </li>
                  );
                })}
            </ol>
            {!viewOnly && (
              <>
                <select
                  name=""
                  id=""
                  value={arrayObject?.selectedAnswer}
                  onChange={(e) => {
                    setValueOption(e?.target?.value);
                    if (!viewOnly) {
                      onChangeQuestionAnswerValue(e.target.value, index);
                      setIsSubmitData(true);
                      setIsThereAnyChangesHadMad(true);
                    }
                  }}
                >
                  <option>Select...</option>
                  {arrayObject?.optionArr.map((res: any, index: number) => {
                    return (
                      <>
                        <option key={index} value={res}>
                          {res?.text}{" "}
                        </option>
                      </>
                    );
                  })}
                </select>
              </>
            )}
          </div>
        );
      case "risk":
        return (
          <div className="radio_group_wrapper">
            {viewOnly && (
              <div className="create_form_wrapper">
                {/* <input
                  type="text"
                  placeholder={`Option ${arrayObject?.optionArr?.length + 1}`}
                  onChange={(e) => {
                    setValueOptionAddColorsAndTextForRisk((prev: any) => ({
                      ...prev,
                      text: e.target.value,
                    }));
                    if (!viewOnly) {
                      onChangeQuestionAnswerValue(e.target.value, index);
                      setIsSubmitData(true);
                    }
                  }}
                  value={valueOptionForAddColorsAndTextForRisk.text}
                /> */}

                <select
                  onChange={(e) => {
                    const selectedRisk = e.target.value;
                    const selectedRiskObject = risks.find(
                      (risk) => risk.text === selectedRisk
                    );
                    if (selectedRiskObject) {
                      setValueOptionAddColorsAndTextForRisk((prev: any) => ({
                        color: selectedRiskObject.hex,
                        text: selectedRiskObject.text,
                      }));
                    }
                  }}
                  name="risk_dropdown"
                  id="risk_dropdown"
                >
                  {risks.map((risk: any) => (
                    <option key={risk.text} value={risk.text}>
                      {risk.text} -{" "}
                      {risk.hex.charAt(0).toUpperCase() + risk.hex.slice(1)}
                    </option>
                  ))}
                </select>

                <PlusSVG
                  marginLeft="10px"
                  height="20"
                  width="20"
                  onClick={() => {
                    handleAddColorsAndTextForRisk();
                  }}
                />
              </div>
            )}

            <ol
              style={{
                marginLeft: "15px",
              }}
            >
              {viewOnly &&
                arrayObject?.optionArr.map((info: any, radioIndex: number) => {
                  return (
                    <li className="list_item">
                      {info?.text}
                      <DeleteIcon
                        boxSize={3}
                        ml={2}
                        onClick={() => {
                          removeOption(radioIndex);
                        }}
                      />
                      {/*
                      <EditIcon
                        position={"relative"}
                        ml={2}
                        boxSize={4}
                        onClick={() => {
                          setValueOptionAddColorsAndTextForRisk(info);
                          setCurrentEditIndex(radioIndex);
                        }}
                      /> */}
                      <div
                        style={{
                          width: "30px",
                          height: "14px",
                          border: "1px solid blue",
                          background: arrayObject.optionArr[radioIndex].color,
                          position: "relative",
                        }}
                        onClick={() => {
                          setCurrentEditIndex(radioIndex);
                          setOpenColorModal(!openColorModal);
                        }}
                      >
                        {openColorModal && CurrentEditIndex === radioIndex && (
                          <>
                            {/* <ColorPicker
                              colors={[
                                { text: "RED", hex: "red" },
                                { text: "AMBER", hex: "yellow" },
                                { text: "GREEN", hex: "green" },
                                { text: "LIGHT_GRAY", hex: "gray" },
                                { text: "BLACK", hex: "black" },
                              ]}
                              setValueOptionAddColorsAndTextForRisk={
                                setValueOptionAddColorsAndTextForRisk
                              }
                              index={radioIndex}
                              setCurrentEditIndex={setCurrentEditIndex}
                              info={arrayObject.optionArr[radioIndex]}
                              checkColorAndUpdate={checkColorAndUpdate}
                            /> */}
                          </>
                        )}
                      </div>
                      {/* <input
                        onChange={(e) => {
                          setValueOptionAddColorsAndTextForRisk((prev: any) => ({
                            text: info.text,
                            color:e.target.value
                          }))
                          setCurrentEditIndex(radioIndex)
                          checkColorAndUpdate()
                          if (!viewOnly) {
                            setValueOption(e?.target?.value);

                            onChangeQuestionAnswerValue(e.target.value, index);
                            setIsSubmitData(true);
                          }
                        }}
                        type="color"
                        id="color_field"
                        value={info?.color}
                      /> */}
                    </li>
                  );
                })}
            </ol>

            {!viewOnly && (
              <select
                name=""
                id=""
                value={arrayObject?.selectedAnswer}
                onChange={(e) => {
                  setValueOption(e?.target?.value);
                  if (!viewOnly) {
                    onChangeQuestionAnswerValue(e.target.value, index);
                    setIsSubmitData(true);
                    setIsThereAnyChangesHadMad(true);
                  }
                }}
              >
                <option>Select...</option>
                {arrayObject?.optionArr.map((res: any, index: number) => {
                  return (
                    <>
                      <option key={index} value={`${res.text} ${res.color}`}>
                        {res?.text} {res?.color}
                      </option>
                    </>
                  );
                })}
              </select>
            )}
          </div>
        );

      case "checkbox":
        return (
          <div className="radio_group_wrapper">
            {viewOnly && (
              <div className="create_form_wrapper">
                <input
                  type="text"
                  placeholder={`Option ${
                    arrayObject?.optionQueAnsArr?.length + 1
                  }`}
                  onChange={(e) => {
                    setIsSubmitData(true);

                    setValueOption(e?.target?.value);
                    if (!viewOnly) {
                      onChangeQuestionAnswerValue(e.target.value, index);
                      // setIsSubmitData(true);
                    }
                  }}
                  value={valueOption}
                />
                <PlusSVG
                  marginLeft="0"
                  height="20"
                  width="20"
                  onClick={() => {
                    // handleAddOptions();
                    handleAddOptionsForResponseAndeScoreType();
                  }}
                />
              </div>
            )}

            <div>
              {arrayObject?.optionQueAnsArr?.map(
                (info: any, checkboxIndex: number) => {
                  return (
                    <div
                      className="extended_checkbox_wrapper"
                      key={checkboxIndex}
                    >
                      {viewOnly && (
                        <>
                          <DeleteIcon
                            boxSize={4}
                            ml={2}
                            onClick={() => {
                              removeOptionsForResponseAndeScoreType(
                                checkboxIndex
                              );
                            }}
                          />
                          <EditIcon
                            position={"relative"}
                            ml={2}
                            boxSize={4}
                            onClick={() => {
                              setValueOption(info?.name);
                              setCurrentEditIndex(checkboxIndex);
                            }}
                          />
                        </>
                      )}
                      <input
                        type="checkbox"
                        id={`checkbox_${checkboxIndex}`}
                        name={`checkbox_${checkboxIndex}`}
                        checked={info.isChecked}
                        onChange={(e) => {
                          setIsSubmitData(true);

                          if (!viewOnly) {
                            handleChangeValueOfIsChecked(checkboxIndex);
                            setIsThereAnyChangesHadMad(true);
                          }
                        }}
                      />
                      <label htmlFor={`checkbox_${checkboxIndex}`}>
                        {" "}
                        {info?.name}
                      </label>
                    </div>
                  );
                }
              )}
            </div>
          </div>
        );

      case "file":
        return (
          <input
            type="file"
            className="expected_outout_textarea"
            placeholder="Please enter value"
            width={"100%"}
            disabled={viewOnly}
            value={arrayObject?.selectedAnswer}
            onChange={(e) => {
              if (!viewOnly) {
                onChangeQuestionAnswerValue(e.target.value, index);
                setIsSubmitData(true);
                setIsThereAnyChangesHadMad(true);
              }
            }}
          />
        );
      case "note":
        return (
          <>
            {viewOnly && (
              <input
                type="file"
                className="expected_outout_textarea"
                placeholder="Please enter value"
                width={"100%"}
                // disabled={viewOnly}
                onChange={handleImageUpload}
              />
            )}

            {!viewOnly && (
              <div style={{ width: "555px" }}>
                {fetchedImages[index] ? (
                  <img
                    // src="https://images.pexels.com/photos/577289/pexels-photo-577289.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                    style={{ width: "auto" }}
                    src={fetchedImages[index] ?? ""}
                    alt="Data"
                    width={"100px"}
                    height={"100px"}
                  />
                ) : (
                  <></>
                )}
              </div>
            )}
          </>
        );
      case "response":
        return (
          <div
            className={`number_list_input_wrapper ${
              !viewOnly && "full_with_wrapper"
            }`}
          >
            {viewOnly && (
              <div className="create_form_wrapper">
                <input
                  type="text"
                  placeholder={`Option ${
                    arrayObject?.optionQueAnsArr?.length + 1
                  }`}
                  onChange={(e) => {
                    setValueOption(e?.target?.value);
                    if (!viewOnly) {
                      onChangeQuestionAnswerValue(e.target.value, index);
                      setIsSubmitData(true);
                    }
                  }}
                  value={valueOption}
                />
                {/* <AddIcon
                  boxSize={3}
                  ml={2}
                  onClick={() => {
                    handleAddOptions();
                  }}
                /> */}
                <PlusSVG
                  marginLeft="10px"
                  height="20"
                  width="20"
                  onClick={() => {
                    // handleAddOptions();
                    handleAddOptionsForResponseAndeScoreType();
                  }}
                />
              </div>
            )}
            <ol
              style={{
                marginLeft: !viewOnly ? "20px" : "0px",
              }}
              type="1"
            >
              {arrayObject?.optionQueAnsArr &&
                arrayObject?.optionQueAnsArr.map(
                  (info: any, radioIndex: number) => {
                    return (
                      <>
                        <div className="repose_type_listing_li_wrapper">
                          {viewOnly && (
                            <div>
                              <DeleteIcon
                                boxSize={3}
                                ml={2}
                                onClick={() => {
                                  // removeOption(radioIndex);
                                  removeOptionsForResponseAndeScoreType(
                                    radioIndex
                                  );
                                }}
                              />
                              <EditIcon
                                position={"relative"}
                                ml={2}
                                boxSize={4}
                                onClick={() => {
                                  setValueOption(info?.name);
                                  setCurrentEditIndex(radioIndex);
                                }}
                              />
                            </div>
                          )}
                          <li>{info?.name} </li>
                        </div>
                        {!viewOnly && (
                          <textarea
                            // type="text"
                            value={info?.answer}
                            onChange={(e) => {
                              handleChangeValueOfAnswer(
                                radioIndex,
                                e?.target?.value
                              );
                              if (!viewOnly) {
                                setIsThereAnyChangesHadMad(true);
                              }
                            }}
                            className="full_with_wrapper type_input_text_area_"
                            placeholder="Please enter answer "
                          />
                        )}
                      </>
                    );
                  }
                )}
            </ol>
            {/* {!viewOnly && (
              <input type="text" placeholder="Please enter your answer." />
            )} */}
          </div>
        );

      case "score":
        return (
          <div className="number_list_input_wrapper">
            {viewOnly && (
              <div className="create_form_wrapper">
                <input
                  type="text"
                  placeholder={`Option ${
                    arrayObject?.optionQueAnsArr?.length + 1
                  }`}
                  onChange={(e) => {
                    setValueOption(e?.target?.value);
                    if (!viewOnly) {
                      onChangeQuestionAnswerValue(e.target.value, index);
                      setIsSubmitData(true);
                    }
                  }}
                  value={valueOption}
                />
                <PlusSVG
                  marginLeft="10px"
                  height="20"
                  width="20"
                  onClick={() => {
                    handleAddOptionsForResponseAndeScoreType();
                  }}
                />
              </div>
            )}

            <RadioGroup
              // value={!viewOnly ? arrayObject?.selectedAnswer : ""}
              value={arrayObject?.selectedAnswer}
              onChange={(value: any) => {
                if (!viewOnly) {
                  onChangeQuestionAnswerValue(value, index);
                  setIsSubmitData(true);
                  setIsThereAnyChangesHadMad(true);
                }
              }}
              id={arrayObject?.questionText}
            >
              <Stack spacing={1} direction={viewOnly ? "column" : "row"}>
                {arrayObject?.optionQueAnsArr?.map(
                  (info: any, radioIndex: number) => (
                    <div className="radio_section_wrapper radio_button_custom_form">
                      {viewOnly && (
                        <div className="action_option">
                          {/* <EditIcon boxSize={3} /> */}
                          <DeleteIcon
                            boxSize={4}
                            ml={2}
                            onClick={() => {
                              removeOption(radioIndex);
                            }}
                          />
                          <EditIcon
                            position={"relative"}
                            ml={2}
                            boxSize={4}
                            onClick={() => {
                              setValueOption(info?.name);
                              setCurrentEditIndex(radioIndex);
                            }}
                          />
                        </div>
                      )}
                      <Radio
                        key={radioIndex}
                        onChange={(e) => {
                          if (!viewOnly) {
                            onChangeQuestionAnswerValue(e.target.value, index);
                            setIsSubmitData(true);
                          }
                        }}
                        size={"md"}
                        value={info?.name}
                        fontSize={"16px"}
                        className="radio__button radio_button_custom_form"
                        style={{
                          borderColor: "#06a5ff",
                          color: "white",
                        }}
                        id={arrayObject?.questionText}
                        // disabled
                      >
                        {`${
                          viewOnly
                            ? info?.name
                            : `${info?.name} - ${info?.answer}`
                        }`}
                      </Radio>
                      {viewOnly && (
                        <input
                          type="number"
                          className=" type_input_text_area_"
                          placeholder="00"
                          value={info?.answer}
                          // disabled={!viewOnly}
                          readOnly={!viewOnly}
                          onChange={(e) => {
                            handleChangeValueOfAnswer(
                              radioIndex,
                              e?.target?.value ? Number(e?.target?.value) : 0
                            );
                          }}
                        />
                      )}
                    </div>
                  )
                )}
              </Stack>
            </RadioGroup>
          </div>
        );

      case "Prospects-field":
        return renderFieldsAccordingToScore(arrayObject?.accessor);
      case "Clients-field":
        return renderFieldsAccordingToScore(arrayObject?.accessor);
      case "Business-field":
        return renderFieldsAccordingToScore(arrayObject?.accessor);
      case "Locations-field":
        return renderFieldsAccordingToScore(arrayObject?.accessor);
      case "Teams-field":
        return renderFieldsAccordingToScore(arrayObject?.accessor);
      case "Contacts-field":
        return renderFieldsAccordingToScore(arrayObject?.accessor);
      case "Staff-field":
        return renderFieldsAccordingToScore(arrayObject?.accessor);

      case "global-Countries":
        return (
          <select
            style={{
              width: "120px",
              fontSize: "12px",
              transform: "translateX(5px)",
            }}
            name="countries"
            id="countries"
            value={arrayObject?.selectedAnswer}
            onChange={(e) => {
              setValueOption(e?.target?.value);
              if (!viewOnly) {
                onChangeQuestionAnswerValue(e.target.value, index);
                setIsSubmitData(true);
                setIsThereAnyChangesHadMad(true);
              }
            }}
          >
            <option value="">Select Country...</option>
            {countries.map((country, index) => {
              return (
                <option value={country} key={index}>
                  {country}
                </option>
              );
            })}
          </select>
        );

      case "global-Languages":
        return (
          <select
            style={{
              width: "120px",
              fontSize: "12px",
              transform: "translateX(5px)",
            }}
            name="language"
            id="languages"
            value={arrayObject?.selectedAnswer}
            onChange={(e) => {
              setValueOption(e?.target?.value);
              if (!viewOnly) {
                onChangeQuestionAnswerValue(e.target.value, index);
                setIsSubmitData(true);
                setIsThereAnyChangesHadMad(true);
              }
            }}
          >
            <option value=""> Select Language...</option>
            {languages.map((language, index) => {
              return (
                <option value={language} key={index}>
                  {language}
                </option>
              );
            })}
          </select>
        );
      case "global-Religions":
        return (
          <select
            style={{
              width: "120px",
              fontSize: "12px",
              transform: "translateX(5px)",
            }}
            name="religion"
            id="religion"
            value={arrayObject?.selectedAnswer}
            onChange={(e) => {
              setValueOption(e?.target?.value);
              if (!viewOnly) {
                onChangeQuestionAnswerValue(e.target.value, index);
                setIsSubmitData(true);
                setIsThereAnyChangesHadMad(true);
              }
            }}
          >
            <option value=""> Select Religion...</option>
            {majorReligions.map((religion, index) => {
              return (
                <option key={index} value={religion}>
                  {religion}
                </option>
              );
            })}
          </select>
        );
      default:
        return null;
    }
  };

  const [draggedItem, setDraggedItem] = useState(null);

  const handleDragStart = (index: any) => {
    setDraggedItem(index);
  };

  const handleDragOverTabs = (index: any) => {
    if (draggedItem === null) return;
    if (index === draggedItem) return;

    const newItems = [...TabsArray];
    const draggedElement = newItems[draggedItem];

    newItems.splice(draggedItem, 1);
    newItems.splice(index, 0, draggedElement);

    setTabsArray(newItems);
    setDraggedItem(null);
    updateSequnceOfTabs(
      newItems.map((res: any) => res?.id),
      selectedTab
    );
  };
  const updateSequnceOfTabs = async (tabsIdArr: any[], tabId: string) => {
    const parms = {
      custom_form_id: selectedActiveForm?.id,
      header_seq_ids: tabsIdArr,
    };
    try {
      const response = await dispatch(updateTabsSequence({ ...parms }));

      if (response?.payload?.status == "success") {
        toast.success("Tabs Sequence updated successfully!", {
          style: {},
          progressStyle: { backgroundColor: dynamicColor },
          icon: (
            <span
              style={{
                marginRight: "8px",
                borderRadius: "50%",
                width: "7rem",
                height: "35px",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: dynamicColor,
                backgroundColor: "white",
                fontSize: "1.5rem",
                border: `3px solid ${dynamicColor}`,
              }}
            >
              ✓
            </span>
          ),
        });
      }
    } catch (error: any) {
      toast.error(error?.message);
    }
  };

  const handleDragOver = (index: any) => {
    if (draggedItem === null) return;
    if (index === draggedItem) return;

    const newItems = [...customeFormArr];
    const draggedElement = newItems[draggedItem];

    newItems.splice(draggedItem, 1);
    newItems.splice(index, 0, draggedElement);

    setCustomeFormArr(newItems);
    setDraggedItem(null);
    updateSequnce(
      newItems.map((res: any) => res?.id),
      selectedTab
    );
  };

  const updateSequnce = async (questionIdArr: any[], tabId: string) => {
    const parms = {
      custom_header_id: tabId,
      question_seq_ids: questionIdArr,
    };
    try {
      const response = await dispatch(updateQuestionSequence({ ...parms }));

      if (response?.payload?.status == "success") {
        toast.success("Question Sequence updated successfully!", {
          progressStyle: { backgroundColor: dynamicColor },
          icon: (
            <span
              style={{
                marginRight: "8px",
                borderRadius: "50%",
                width: "7rem",
                height: "35px",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: dynamicColor,
                backgroundColor: "white",
                fontSize: "1.5rem",
                border: `3px solid ${dynamicColor}`,
              }}
            >
              ✓
            </span>
          ),
        });
      }
    } catch (error: any) {
      toast.error(error?.message);
    }
  };

  const [SelectedUsersTypeArr, setSelectedUsersTypeArr] = useState<any>("");
  const [SelectedUsersTypeIdArr, setSelectedUsersTypeIdArr] = useState<any>([]);

  // console.log(selectedActiveForm, "selectedActive");

  useEffect(() => {
    if (selectedActiveForm?.custom_form_id && clientId !== undefined) {
      dispatch(resetActiveFormTabsArr());
      // dispatch(
      //   getCustomFormsTabsByIdThunk({
      //     user_id: clientId,
      //     custom_form_id: selectedActiveForm?.custom_form_id,
      //     document_id: dataObject?.id,
      //   })
      // );
    }
  }, [selectedActiveForm, clientId]);

  useEffect(() => {
    if (selectedActiveForm?.id && selectedActiveForm?.usertypes) {
      setCurrentActiveFormDetails(selectedActiveForm);
      let userTypes = selectedActiveForm?.usertypes
        .map((res: any) => res?.desc)
        .join(",");
      let userTypesIdArr = selectedActiveForm?.usertypes.map(
        (res: any) => res?.id
      );
      setSelectedUsersTypeArr(userTypes);
      setSelectedUsersTypeIdArr(userTypesIdArr);
    }
  }, [selectedActiveForm]);

  useEffect(() => {
    if (selectedActiveFormTabsArr) {
      setTabsArray(selectedActiveFormTabsArr);
    }
  }, [selectedActiveFormTabsArr]);
  const {
    loading: loadingProvider,
    providersPage,
    providersFilterItems,
    lastPage: providerLastPage,
    searchValueProviders,
    selectedFilterItemsProviders,
    sortByProviderR,
  } = useAppSelector((state) => state?.providers);

  useEffect(() => {
    dispatch(resetProvidersData());
    dispatch(resetProvidersPage());
  }, [
    providersFilterItems?.searchVal,
    providersFilterItems?.filterValue,
    providersFilterItems?.sortType,
    providersFilterItems?.status,
  ]);
  useEffect(() => {
    if (SelectedUsersTypeArr) {
      let userTypesNameArr: any[] = SelectedUsersTypeArr?.split(",");
      let idsArr = userTypesNameArr?.map((res) => {
        return dynamicUsersStaffIds[res];
      });
      setSelectedUsersTypeIdArr(idsArr);
    } else {
      setSelectedUsersTypeIdArr([]);
    }
  }, [SelectedUsersTypeArr]);

  useEffect(() => {
    dispatch(
      getProvidersByFilterThunk({
        filterColumn: providersFilterItems?.filterColumn
          ? providersFilterItems?.filterColumn
          : "",
        filterValue: providersFilterItems?.filterValue
          ? providersFilterItems?.filterValue
          : "",
        searchVal: providersFilterItems?.searchVal
          ? providersFilterItems?.searchVal
          : "",
        sortColumn: providersFilterItems?.sortColumn
          ? providersFilterItems?.sortColumn
          : "",
        sortType: providersFilterItems?.sortType
          ? providersFilterItems?.sortType
          : "",
        providersPage,
        status: providersFilterItems.status ? providersFilterItems.status : "",
      })
    );
  }, [
    providersPage,
    providersFilterItems?.searchVal,
    providersFilterItems?.filterValue,
    providersFilterItems?.sortType,
    providersFilterItems?.status,
  ]);

  // console.log(getProviders,"getProviders")
  const [uniqueProvidersData, setuniqueProvidersData] = useState<any>();

  useEffect(() => {
    if (getProviders && Array.isArray(getProviders)) {
      const uniqueProvidersData = getProviders.filter(
        (item: any, index: number, array: any) => {
          return array.findIndex((obj: any) => obj.id === item.id) === index;
        }
      );
      setuniqueProvidersData(uniqueProvidersData);
    }
  }, [getProviders]);

  //@ts-ignore
  const dynamicBusinessId = parseInt(localStorage.getItem("dynamicBusinessId"));
  // @ts-ignore
  const dynamicClientId = parseInt(localStorage.getItem("dynamicClientId"));
  const dynamicProspectsId = parseInt(
    // @ts-ignore
    localStorage.getItem("dynamicProspectId")
  );
  // @ts-ignore
  const dynamicTeamsId = parseInt(localStorage.getItem("dynamicTeamsId"));
  // @ts-ignore
  const dynamicLocationId = parseInt(localStorage.getItem("dynamicLocationId"));
  // @ts-ignore
  const dynamicStaffId = parseInt(localStorage.getItem("dynamicStaffId"));
  // @ts-ignore
  const dynamicContactId = parseInt(localStorage.getItem("dynamicContactId"));

  const [dynamicUsersStaffIds, setdynamicUsersStaffIds] = useState<any>({});
  useEffect(() => {
    if (
      dynamicClientId &&
      dynamicProspectsId &&
      dynamicTeamsId &&
      dynamicLocationId &&
      dynamicStaffId &&
      dynamicContactId &&
      dynamicBusinessId
    ) {
      const userTypeObj = {
        Prospect: dynamicProspectsId,
        Client: dynamicClientId,
        Business: dynamicBusinessId,
        Location: dynamicLocationId,
        Team: dynamicTeamsId,
        Contact: dynamicContactId,
        Staff: dynamicStaffId,
      };
      setdynamicUsersStaffIds(userTypeObj);
    }
  }, [
    dynamicBusinessId,
    dynamicClientId,
    dynamicProspectsId,
    dynamicTeamsId,
    dynamicLocationId,
    dynamicStaffId,
    dynamicContactId,
  ]);

  const { error } = useAppSelector((state) => state?.customForm)

  const handleUpdateForm = async () => {
    try {
      const response = await dispatch(
        updateCustomFormByIdThunk({
          custom_form_id: CurrentActiveFormDetails?.id,
          form_name: CurrentActiveFormDetails?.form_name,
          user_types: SelectedUsersTypeIdArr,
          form_type: CurrentActiveFormDetails?.form_type,
          has_goals: CurrentActiveFormDetails?.has_goals,
          admin_title: CurrentActiveFormDetails?.admin_title,
        })
      );

      if (response?.payload?.status == "success") {
        isCreateTabPending = false;
        toast.success("Form updated successfully!", {
          progressStyle: { backgroundColor: dynamicColor },
          icon: (
            <span
              style={{
                marginRight: "8px",
                borderRadius: "50%",
                width: "7rem",
                height: "35px",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: dynamicColor,
                backgroundColor: "white",
                fontSize: "1.5rem",
                border: `3px solid ${dynamicColor}`,
              }}
            >
              ✓
            </span>
          ),
        });
        // console.log(CurrentActiveFormDetails, "CurrentActiveFormDetails");
        dispatch(
          getCustomFormsIdByThunk({
            userId: clientId,
            id: selectedActiveForm?.id,
            document_id: dataObject?.id,
          })
        );
        // dispatch(getCustomFormsIdByThunk(CurrentActiveFormDetails?.custom_form_id));
        dispatch(getCustomFormsThunk(userId));
        setIsEditForm(false);
      }else{
      toast.error(error?.data?.response);

      }
    } catch (err: any) {
      toast.error(error?.data?.response);
    }
  };

  const [IsEditForm, setIsEditForm] = useState(false);

  const [SelectedUserType, setSelectedUserType] = useState("");

  const onCLickOfField = (name: string, accessor: string) => {
    let questionInfo = {
      question: `{"entityMap":{},"blocks":[{"key":"1aqui","text":"${name}","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":${name.length},"style":"BOLD"}],"entityRanges":[]}]}`,
      answerType: SelectedUserType + "-field",
      questionText: name,
      accessor: accessor,
      isRequired: true,
      optionArr: [],
      customLogic: false,
      selectedQuestion: "",
      selectedQuestionId: "",
      expectedAnswer: "",
      optionQueAnsArr: [],
    };

    let parms = {
      custom_header_id: selectedTab,
      question: JSON.stringify(questionInfo),
    };
    createQuestion(parms);
  };

  const onCreateQuestionForGlobalsFields = (name: string) => {
    let questionInfo = {
      question: `{"entityMap":{},"blocks":[{"key":"1aqui","text":"${name}","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":${name.length},"style":"BOLD"}],"entityRanges":[]}]}`,
      answerType: "global-" + name,
      questionText: name,
      isRequired: true,
      optionArr: [],
      customLogic: false,
      selectedQuestion: "",
      selectedQuestionId: "",
      expectedAnswer: "",
      optionQueAnsArr: [],
    };

    let parms = {
      custom_header_id: selectedTab,
      question: JSON.stringify(questionInfo),
    };
    createQuestion(parms);
  };

  // console.log(currentEditCustomForm,'currentEditCustomForm')

  useEffect(() => {
    if (selectedTabStore) {
      setselectedTab(selectedTabStore);
    }
  }, [selectedTabStore]);

  return (
    <>
      {isLoading && <Spinner position={"absolute"} top={"50%"} left={"50%"} />}
      {isSideBar && (
        <div className="extra_filed_option_wrapper">
          <div className="profile_section_wrapper">
            <h3>Profile Fields</h3>
            <div
              className="content_section_wrapper"
              style={{
                marginTop: "5px",
              }}
            >
              <select
                name="user-type"
                className="user_type_select"
                id=""
                value={SelectedUserType}
                onChange={(e: any) => {
                  setSelectedUserType(e?.target?.value);
                }}
              >
                <option value="">Please Select</option>
                <option value="Prospects">Prospects</option>
                <option value="Clients">Clients</option>
                <option value="Business">Business</option>
                <option value="Locations">Locations</option>
                <option value="Teams">Teams</option>
                <option value="Contacts">Contacts</option>
                <option value="Staff">Staff</option>
              </select>
              <div className="user_type_fields_wrapper">
                {[
                  ...(SelectedUserType === "Prospects"
                    ? prospectsFieldsArr
                    : SelectedUserType === "Clients"
                    ? clientFieldsArr
                    : SelectedUserType === "Business"
                    ? businessFieldsArr
                    : SelectedUserType === "Locations"
                    ? locationsFiedlsArr
                    : SelectedUserType === "Teams"
                    ? teamsFieldsArr
                    : SelectedUserType === "Contacts"
                    ? ContactsFieldsArr
                    : SelectedUserType === "Staff"
                    ? staffsFieldsArr
                    : []),
                ]?.map((res: any, index: number) => {
                  return (
                    <button
                      draggable
                      key={index}
                      className={`tab_clinical_modal `}
                      onClick={() => {
                        onCLickOfField(
                          res?.UpperTitle?.split(":")[0],
                          res?.accessor
                        );
                      }}
                    >
                      {res?.UpperTitle?.split(":")[0]}
                    </button>
                  );
                  0;
                })}
              </div>
            </div>
          </div>
          <div className="list">
            <h3>Global Fields</h3>
            <div className="user_type_fields_wrapper">
              {["Countries", "Languages", "Religions"].map(
                (res: any, index: number) => {
                  return (
                    <button
                      draggable
                      key={index}
                      className={`tab_clinical_modal `}
                      onClick={() => {
                        onCreateQuestionForGlobalsFields(res);
                      }}
                    >
                      {res}
                    </button>
                  );
                }
              )}
            </div>
          </div>
          <div className="list">
            <h3 style={{ marginLeft: "-3px" }}>Included Providers</h3>
            <div className="user_type_fields_wrapper">
              <CustomMultiSelect
                type="primaryApprover"
                sendArraysToParent={receiveArraysFromChild}
                providers={getProviders}
                initialOptions={selectedProviders}
              />
            </div>
          </div>
          <div className="list">
            <h3>Associations</h3>
          </div>
        </div>
      )}
      <div
        className={`${isSideBar && "custom_form_main_section_wrapper"}`}
        ref={mainSectionRef}
      >
        {isSideBar && (
          <>
            <div className="upper_layer_segment custom_form_title_wrapper">
              <div className="title">{selectedActiveForm?.form_name || ""}</div>

              <EditIcon
                position={"relative"}
                onClick={() => {
                  setIsEditForm(true);
                }}
              />

              <select
                style={{
                  position: "absolute",
                  right: "20px",
                }}
                name="version_dropdown"
                id="version_dropdown"
                onChange={(e) => {
                  let vId = e.target.value;

                  localStorage.removeItem("FIRST_TIME_FORM");

                  setselectedVersionValue(e.target.value);
                  let vNo = e.target.value.split(" ")[1];

                  let id = customFormVersions.find(
                    (el: any) => el?.id == vId
                  ).id;

                  if (id) {
                    dispatch(
                      getCustomFormsIdPaginateTrueByThunk({
                        userId: clientId,
                        id: id,
                        document_id: dataObject?.id,
                      })
                    );
                  }
                }}
                value={selectedVersionValue}
              >
                {customFormVersions?.map((version: any) => {
                  return (
                    <>
                      <option value={version.id}>
                        {version?.form_name} - [v{version.version}]
                      </option>
                    </>
                  );
                })}
              </select>
            </div>
            {isAdministrator && IsEditForm && (
              <div className="edit_add_state_for_tab">
                <input
                  type="text"
                  placeholder="Enter Tab Name"
                  value={CurrentActiveFormDetails?.form_name}
                  onChange={(e) => {
                    setTabInputValue(e?.target?.value);
                    setCurrentActiveFormDetails({
                      ...CurrentActiveFormDetails,
                      form_name: e?.target?.value,
                      custom_form_id: CurrentActiveFormDetails?.id,
                    });
                  }}
                />
                {CurrentActiveFormDetails?.form_type == "Document" && (
                  <div className="add_edit_user_type_multi_selctor">
                    <MultiSelectDropdown
                      options={(option: any) => {
                        setSelectedUsersTypeArr(
                          Array.isArray(option) && option?.length !== 0
                            ? option?.join(",")
                            : ""
                        );
                      }}
                      value={SelectedUsersTypeArr}
                      optionArr={[
                        "Prospect",
                        "Client",
                        "Business",
                        "Location",
                        "Team",
                        "Contact",
                        "Staff",
                      ]}
                      placeholder="Select User Type"
                    />
                  </div>
                )}
                <button
                  onClick={() => {
                    handleUpdateForm();
                  }}
                  // id="saveBTN"
                  // disabled={isUpdatedPending}
                >
                  {IsOnAddState ? "Save" : "Update"}
                </button>
              </div>
            )}
          </>
        )}
        <div className="upper_tabs_segment">
          {TabsArray.map((tab: any, index: number) => (
            <button
              key={index}
              draggable={true}
              onDragStart={() => {
                handleDragStart(index);
              }}
              onDragOver={(e) => {
                e.preventDefault();
              }}
              onDrop={() => {
                handleDragOverTabs(index);
              }}
              onClick={() => {
                // if (IsThereAnyChangesHadMad) {
                //   setHasUnsavedChanges(true);
                // } else {
                handleTabChange(tab);
                // }
              }}
              style={{
                backgroundColor: tab?.tab_color?.split(" ")[2],
                border:
                  selectedTab === tab?.id && tab?.tab_color
                    ? "3px solid #06a5ff"
                    : "unset",
              }}
              className={`tab_clinical_modal ${
                selectedTab === tab?.id ? "selected_active_tab" : ""
              } ${!isSideBar && selectedTab === tab?.id && CustomCalss}  `}
            >
              {tab?.custom_header_name}
              {(CurrentActiveFormDetails?.id !== tab?.custom_form_id)
              && isSideBar
              && (
                <LinkSVG invert={selectedTab === tab?.id} />
              )}
            </button>
          ))}

          {
            // isAdministrator &&
            isSideBar && isAdministrator && (
              <div className="custom_edit_btn">
                <PlusSVG
                  height="22"
                  width={"22"}
                  onClick={() => {
                    // HanldeAddNewQuestion();
                    setIsEditAddStateForTabs(true);
                    setIsOnAddState(true);
                    setTabInputValue("");
                  }}
                />
                {CurrentUserTenantIdIs === tenantId && (
                  <EditIcon
                    boxSize={5}
                    cursor={"pointer"}
                    onClick={() => {
                      setIsEditAddStateForTabs(true);
                      setIsOnAddState(false);
                      handleOnClickEdit();
                      // setTabInputValue(selectedTab);
                    }}
                  />
                )}
                {showConfirmationModal && (
                  <>
                    <ConfirmationModal
                      type="deletionConfirmation"
                      handleMoveWithSave={() => {
                        handleDeleteTab();
                        setshowConfirmationModal(false);
                      }}
                      handleMoveWithoutSaving={() =>
                        setshowConfirmationModal(false)
                      }
                    />
                  </>
                )}
                {CurrentUserTenantIdIs === tenantId && (
                  <DeleteSVG
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      confirmDeletion();
                    }}
                  />
                )}
              </div>
            )
          }

          {isSideBar && isAdministrator && (
            <span onClick={() => setShowCfListDropdown(!showCfListDropdown)}>
              <LinkSVG width={16} height={12} />
            </span>
          )}
          {showCfListDropdown && (
            <select
              onChange={(event) => setselectedHeader(event.target.value)}
              className="select_dropdown"
            >
              {getCustomFormList?.data &&
                getCustomFormList?.data.map((elem) => (
                  <option key={elem?.id} value={elem?.id}>
                    {elem?.form_name}
                  </option>
                ))}
            </select>
          )}

          {showCfListDropdown &&
            getCustomFormList?.data?.find((el: any) => el.id == selectedHeader)
              ?.headers?.length >= 1 && (
              <select
                onChange={(e) => setselectedCustomHeader(e.target.value)}
                className="select_dropdown"
              >
                {getCustomFormList?.data?.find(
                  (el: any) => el.id == selectedHeader
                )?.headers?.length >= 1 &&
                  getCustomFormList.data
                    .find((el: any) => el.id == selectedHeader)
                    .headers.map((elem) => {
                      return (
                        <>
                          <option value={elem.id}>
                            {elem.custom_header_name}
                          </option>
                        </>
                      );
                    })}
              </select>
            )}

          {showCfListDropdown &&
            getCustomFormList?.data?.find((el: any) => el.id == selectedHeader)
              ?.headers?.length >= 1 && (
              <>
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    dispatch(
                      linkCustomFormTabHeaderThunk({
                        custom_header_id: selectedCustomHeader,
                        custom_form_id: CurrentActiveFormDetails?.id,
                      })
                    );
                    dispatch(
                      getCustomFormsIdPaginateTrueByThunk({
                        userId: clientId,
                        id: CurrentActiveFormDetails?.id,
                        document_id: dataObject?.id,
                      })
                    );
                  }}
                >
                  Link
                </button>
              </>
            )}
        </div>
        {isEditAddStateForTabs && (
          <div className="edit_add_state_for_tab">
            <input
              type="text"
              placeholder="Enter Tab Name"
              value={tabInputValue}
              onChange={(e) => {
                setTabInputValue(e?.target?.value);
              }}
            />
            <button
              onClick={() => {
                handleAddUpdate();
              }}
            >
              {IsOnAddState ? "Save" : "Update"}
            </button>
          </div>
        )}
        <div>
          {isSideBar && CurrentUserTenantIdIs === tenantId && (
            <div className="top_filed_section">
              Add Field{" "}
              <PlusSVG
                height="18"
                width={"18"}
                marginLeft="10px"
                onClick={() => {
                  HanldeAddNewQuestion();
                }}
              />
            </div>
          )}

          <div className="custome_form_wrapper" style={{ height: "375px" }}>
            {isSideBar &&
              customeFormArr?.length !== 0 &&
              customeFormArr?.map((res: any, index: number) => {
                if (currentActiveIndex == index) {
                  return (
                    <div
                      className="extended_selcted_question_wrapper"
                      //@ts-ignore
                      ref={selectedQuestionItemRef}
                    >
                      <div className="section_1 blue_section dot_section_wrapper">
                        {[...Array(3)].map((elem) => {
                          return (
                            <>
                              <div className="left-dots"></div>
                            </>
                          );
                        })}
                      </div>
                      {/* <div className="custom_form_delete">
                      <DeleteSVG
                        onClick={() => {
                          handleDeleteQuestion(res?.id);
                        }}
                      />
                    </div> */}
                      <div className="section_2 content_section">
                        <div className="first_row_wrapper_form">
                          <div
                            id="daybook_editor"
                            className="editor_custom_forms"
                            style={{ position: "relative", width: "75%" }}
                          >
                            <Editor
                              ref={editorRef}
                              wrapperClassName={`wrapper-class  ${
                                !editorFocus && "meal-builder-editor"
                              }`}
                              editorClassName={`editor_class_custom_form`}
                              toolbarClassName={`toolbar_class_custom_form `}
                              onFocus={() => setEditorFocus(true)}
                              editorState={editorState}
                              onEditorStateChange={(val) => {
                                HandleChangeEditor(val, index);
                                setIsThereAnyChanges(true);
                              }}
                              toolbar={{
                                options: [
                                  "inline",
                                  "list",
                                  "fontSize",
                                  // "blockType",
                                ],
                                inline: {
                                  options: ["bold", "italic", "underline"],
                                },

                                fontSize: {
                                  // icon: fontSize,
                                  options: [
                                    8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36,
                                    48, 60, 72, 96,
                                  ],
                                  className:
                                    "font_size_drop_down_wrapper_custom_form",
                                  component: undefined,
                                  dropdownClassName: undefined,
                                },
                                list: {
                                  inDropdown: false,
                                  className:
                                    "list_section_wrapper_toolbar_custom_form",
                                  options: [
                                    "unordered",
                                    "ordered",
                                    "indent",
                                    // "outdent",
                                  ],
                                },
                              }}
                              placeholder="Question..."
                            />
                          </div>
                          <div className="select_form_type_wrapper">
                            {
                              res?.id

                              ?
                              <DeleteSVG
                              onClick={() => {
                                handleDeleteQuestion(res?.id);
                              }}
                            />
                              :
                            <span onClick={() => {
                              setCurrentActiveIndex(-1)
                            }}>
                              <CrossMdSvg/>
                             </span>
                            }
                           
                            {!res?.answerType?.includes("field") &&
                              !res?.answerType?.includes("global") &&
                              res?.answerType !== "total" && (
                                <select
                                  name="input_type"
                                  id=""
                                  value={res?.answerType}
                                  onChange={(e) => {
                                    onChangeAnswerType(e.target?.value);
                                    setIsThereAnyChanges(true);
                                  }}
                                >
                                  {optionsArr?.map(
                                    (res: any, optIndex: number) => {
                                      return (
                                        <option
                                          value={res?.value}
                                          key={optIndex}
                                        >
                                          {res?.title}
                                        </option>
                                      );
                                    }
                                  )}
                                </select>
                              )}
                          </div>
                        </div>

                        <div className="out_put_section_expected">
                          {randerInputAccordingToType(
                            res?.answerType,
                            res,
                            index
                          )}
                          {/* False Send */}
                          <div className="required_section_wrapper">
                            <label>Required </label>
                            <Switch
                              colorScheme="teal"
                              size="md"
                              isChecked={res?.isRequired}
                              // zIndex={99999}
                              value={res?.isRequired}
                              onChange={(e) => {
                                handleChangeIsRequired();
                                setIsThereAnyChanges(true);
                              }}
                            />
                          </div>
                        </div>

                        {!res?.customLogic && (
                          <div
                            className="add_logic_section_wrapper"
                            onClick={() => {
                              handleChangeCustomLoginc();
                              !res?.logicType &&
                                onChangeLogicalQuestionType("equal");
                            }}
                          >
                            <div>
                              <PlusSVG width="15" height="15" /> Add logic
                            </div>
                          </div>
                        )}
                        {res?.customLogic && customeFormArr?.length > 1 && (
                          <div className="cutom_logic_condition_section_rapper">
                            <div className="logic_type_selction_wrapper">
                              <RadioGroup
                                onChange={(value) => {
                                  onChangeLogicalQuestionType(value);
                                  setIsThereAnyChanges(true);
                                }}
                                value={res?.logicType}
                              >
                                <Stack direction="row">
                                  <Radio value="equal">Equal</Radio>
                                  <Radio value="and">AND</Radio>
                                  <Radio value="or">OR</Radio>
                                </Stack>
                              </RadioGroup>
                            </div>
                            <select
                              name="questionArr"
                              id=""
                              value={res?.selectedQuestionId}
                              onChange={(e) => {
                                onChangeSelectQuestion(e?.target?.value);
                                setIsThereAnyChanges(true);
                              }}
                            >
                              <option value="">Select Question</option>
                              {customeFormArr
                                ?.filter(
                                  (res: any, qInd: number) => qInd !== index
                                )
                                .map((res) => {
                                  return (
                                    <option value={res?.id}>
                                      {res?.questionText}{" "}
                                    </option>
                                  );
                                })}
                            </select>

                            <input
                              type="text"
                              className="expected_input_class"
                              placeholder="Please enter logical values"
                              value={res?.expectedAnswer}
                              onChange={(e) => {
                                onChangeExpectedAnswer(e?.target?.value);
                                setIsThereAnyChanges(true);
                              }}
                            />
                            {(res?.logicType == "and" ||
                              res?.logicType == "or") && (
                              <div className="custom_logic_for_and_or_type">
                                <select
                                  style={{
                                    width: "100%",
                                  }}
                                  name="questionArr"
                                  id=""
                                  value={res?.selectedQuestion2Id}
                                  onChange={(e) => {
                                    onChangeSelectQuestion2(e?.target?.value);
                                    21;
                                    setIsThereAnyChanges(true);
                                  }}
                                >
                                  <option value="">Select Question</option>
                                  {customeFormArr
                                    ?.filter(
                                      (res: any, qInd: number) => qInd !== index
                                    )
                                    ?.map((res) => {
                                      return (
                                        <option value={res?.id}>
                                          {res?.questionText}
                                        </option>
                                      );
                                    })}
                                </select>

                                <input
                                  type="text"
                                  className="expected_input_class"
                                  placeholder="Please enter logical values"
                                  value={res?.expectedAnswer2}
                                  onChange={(e) => {
                                    onChangeExpectedAnswer2(e?.target?.value);
                                    setIsThereAnyChanges(true);
                                  }}
                                />

                                <span
                                  className=" plus_button"
                                  style={{
                                    fontSize: "20px",
                                    display:
                                      numberOfQuestionsHavingLogicOfAndOr.length >
                                      0
                                        ? "none"
                                        : "",
                                  }}
                                  onClick={() => {
                                    let spreadnumberOfQuestionsHavingLogicOfAndOr =
                                      [...numberOfQuestionsHavingLogicOfAndOr];
                                    spreadnumberOfQuestionsHavingLogicOfAndOr.push(
                                      Number
                                    );
                                    setnumberOfQuestionsHavingLogicOfAndOr(
                                      spreadnumberOfQuestionsHavingLogicOfAndOr
                                    );
                                  }}
                                >
                                  +
                                </span>
                              </div>
                            )}

                            {(res?.logicType == "and" ||
                              res?.logicType == "or") &&
                              numberOfQuestionsHavingLogicOfAndOr.map(
                                (res: any, index: number) => {
                                  return (
                                    <>
                                      <div className="logic_type_selction_wrapper">
                                        <RadioGroup
                                          onChange={(value) => {
                                            onChangeLogicalQuestionType(value);
                                            setIsThereAnyChanges(true);
                                          }}
                                          value={res?.logicType}
                                        >
                                          <Stack
                                            direction="row"
                                            position={"relative"}
                                            left={"45%"}
                                          >
                                            {/* <Radio value="equal">Equal</Radio> */}
                                            <Radio value="and">AND</Radio>
                                            <Radio value="or">OR</Radio>
                                          </Stack>
                                        </RadioGroup>
                                      </div>
                                      <div className="custom_logic_for_and_or_type">
                                        <select
                                          style={{
                                            width: "100%",
                                          }}
                                          name="questionArr"
                                          id=""
                                          value={res?.selectedQuestion2Id}
                                          onChange={(e) => {
                                            onChangeSelectQuestion2(
                                              e?.target?.value
                                            );
                                            21;
                                            setIsThereAnyChanges(true);
                                          }}
                                        >
                                          <option value="">
                                            Select Question
                                          </option>
                                          {customeFormArr
                                            ?.filter(
                                              (res: any, qInd: number) =>
                                                qInd !== index
                                            )
                                            ?.map((res) => {
                                              return (
                                                <option value={res?.id}>
                                                  {res?.questionText}
                                                </option>
                                              );
                                            })}
                                        </select>

                                        <input
                                          type="text"
                                          className="expected_input_class"
                                          placeholder="Please enter logical values"
                                          value={res?.expectedAnswer2}
                                          onChange={(e) => {
                                            onChangeExpectedAnswer2(
                                              e?.target?.value
                                            );
                                            setIsThereAnyChanges(true);
                                          }}
                                        />

                                        <span
                                          className=" plus_button"
                                          style={{
                                            display:
                                              index ===
                                              numberOfQuestionsHavingLogicOfAndOr.length -
                                                1
                                                ? "flex"
                                                : "none",
                                          }}
                                          onClick={() => {
                                            let spreadnumberOfQuestionsHavingLogicOfAndOr =
                                              [
                                                ...numberOfQuestionsHavingLogicOfAndOr,
                                              ];
                                            spreadnumberOfQuestionsHavingLogicOfAndOr.push(
                                              Number
                                            );
                                            setnumberOfQuestionsHavingLogicOfAndOr(
                                              spreadnumberOfQuestionsHavingLogicOfAndOr
                                            );
                                          }}
                                        >
                                          +
                                        </span>
                                      </div>
                                    </>
                                  );
                                }
                              )}
                            {
                              <div className="required_section_wrapper">
                                <label>Warning </label>
                                <Switch
                                  colorScheme="teal"
                                  size="md"
                                  isChecked={res?.isWarning}
                                  // zIndex={99999}
                                  value={res?.isWarning}
                                  onChange={(e) => {
                                    if (!res?.isWarning) {
                                      let findItsExist = customeFormArr.map(
                                        (res) => res?.isWarning
                                      );

                                      handleChangeCustomFormWarning();
                                      setIsThereAnyChanges(true);
                                    } else {
                                      handleChangeCustomFormWarning();
                                      setIsThereAnyChanges(true);
                                    }
                                  }}
                                />
                              </div>
                            }
                          </div>
                        )}

                        {res?.isWarning && res?.customLogic && (
                          <>
                            <div className="cutom_logic_condition_section_rapper">
                              <div className="logic_warning_selction_wrapper">
                                <p>Warning Type :</p>
                                <RadioGroup
                                  onChange={(value) => {
                                    handleChangeCustomFormWarningFields(
                                      value,
                                      "warningType"
                                    );
                                    setIsThereAnyChanges(true);
                                  }}
                                  size={"md"}
                                  value={res?.warningType}
                                >
                                  <Stack direction="row">
                                    <Radio value="red" size={"lg"}>
                                      Red
                                    </Radio>
                                    <Radio value="yellow" size={"lg"}>
                                      Yellow
                                    </Radio>
                                    <Radio value="orange" size={"lg"}>
                                      Orange
                                    </Radio>
                                    <Radio value="green" size={"lg"}>
                                      Green
                                    </Radio>
                                  </Stack>
                                </RadioGroup>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  );
                }
                const dataObjectMessage = res?.question;

                let parsedDataObject: any;
                try {
                  parsedDataObject = JSON.parse(dataObjectMessage);
                } catch (error) {
                  parsedDataObject = null;
                }
                const htmlContent = convertToHTML(parsedDataObject);
                return (
                  <div
                    className="question_section_wrapper"
                    key={res?.id}
                    onDragStart={() => {
                      handleDragStart(index);
                    }}
                    onDragOver={(e) => {
                      e.preventDefault();
                    }}
                    onDrop={() => {
                      handleDragOver(index);
                    }}
                    draggable
                    onClick={() => {
                      setnumberOfQuestionsHavingLogicOfAndOr([]);
                      if (
                        isAdministrator &&
                        CurrentUserTenantIdIs == res?.tenant_id
                      ) {
                        setIsThereAnyChanges(false);
                        setEditorState(EditorState.createEmpty());
                        setCurrentActiveIndex(index);
                        setSelectedQuestionInfo(res);
                        setCurrentActiveQuestionAnswerType(res?.answerType);
                        if (res?.questionText !== "") {
                          const rawValue = res?.question;
                          const string = EditorState?.createWithContent(
                            convertFromRaw(JSON.parse(rawValue))
                          );
                          setEditorState(string);
                        } else {
                          setEditorState(EditorState?.createEmpty());
                        }
                      }
                    }}
                  >
                    <div
                      className="question_wrapper"
                      dangerouslySetInnerHTML={{
                        __html: htmlContent,
                      }}
                    ></div>
                    {randerInputAccordingToType(
                      res?.answerType,
                      res,
                      index,
                      false
                    )}
                  </div>
                );
              })}

            {!isSideBar &&
              // !isAdmin &&
              customeFormArr?.map((res: any, index: number) => {
                const dataObjectMessage = res?.question;
                let parsedDataObject: any;
                try {
                  parsedDataObject = JSON.parse(dataObjectMessage);
                } catch (error) {
                  parsedDataObject = null;
                }
                const htmlContent = convertToHTML(parsedDataObject);
                return (
                  <Fragment key={index}>
                    {res?.customLogic &&
                      checkTheCustomLoginAndrender(res, index)}
                    {!res?.customLogic && (
                      <div
                        key={index}
                        id={res?.id}
                        className={`question_section_wrapper  `}
                      >
                        <div
                          className="question_wrapper"
                          dangerouslySetInnerHTML={{
                            __html: htmlContent,
                          }}
                        ></div>
                        {randerInputAccordingToType(
                          res?.answerType,
                          res,
                          index,
                          false
                        )}
                      </div>
                    )}
                  </Fragment>
                );
              })}
          </div>
        </div>

        {hasUnsavedChanges && (
          <>
            <ConfirmationModal
              type="clientScreen"
              title={"The changes would be lost. Are you sure?"}
              handleMoveWithSave={() => {
                handleSubmitAnswer("");

                sethasunsavedChanges(false);
              }}
              handleMoveWithoutSaving={() => {
                setuploadDocument(1);
                sethasunsavedChanges(false);
              }}
            />
          </>
        )}

        {!isSideBar &&
          //  !hasUnsavedChanges &&
          customeFormArr.length !== 0 && (
            <button
              className={`custom_form_modal_btn ${
                isSideBar ? "custom_form_modal_btn_sidebar" : ""
              }`}
              style={{ backgroundColor: dynamicColor }}
              onClick={() => handleSubmitAnswer("")}
            >
              Save & Close
            </button>
          )}

        {isSideBar && (
          <div
            style={{
              position: "absolute",
              right: "5px",
              bottom: "15px",
              display: "flex",
              gap: "5px",
            }}
          >
            <button
              className="save_draft_publish"
              onClick={() => {
                dispatch(
                  publishCustomFormThunk({
                    custom_form_id: selectedActiveForm?.id,
                    save_type: "draft",
                  })
                ).then((res: any) => {
                  if (res?.error) {
                    toast.error(res?.error?.message);
                    return;
                  }

                  toast.success("Saved Successfully!");
                });
              }}
            >
              Save as Draft
            </button>
            {true && (
              <>
                <button
                  onClick={() => {
                    dispatch(
                      publishCustomFormThunk({
                        custom_form_id: selectedActiveForm?.id,
                        save_type: "publish",
                      })
                    ).then((res: any) => {
                      if (res?.error) {
                        toast.error(res?.error?.message);
                        return;
                      }
                      setIsThereAnyChanges(false);
                      dispatch(getCustomFormsThunk(userId));

                      toast.success("Saved Successfully!");
                    });
                  }}
                  className="save_draft_publish"
                >
                  {" "}
                  Publish
                </button>
              </>
            )}
          </div>
        )}
      </div>

      {showsignaturePad && !isSideBar && (
        <div className="signatue_pad">
          <SignatureCanvas
            penColor="black"
            onBegin={() => setisSigning(true)}
            ref={signatureCanvas}
            canvasProps={{ width: 350, height: 130 }}
          />

          {isSigning && (
            <>
              <button
                className="save_btn  plus_button"
                style={{
                  fontSize: "12px",
                  height: "30px",
                  width: "70x",
                  padding: "3px",
                  margin: "5px",
                }}
                onClick={() => {
                  setshowSignaturePad(false);
                  clearSignature();
                }}
              >
                Save
              </button>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default CustomForm;
