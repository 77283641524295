
export function getInitials(name: any) {
  // Split the name into words
  const words = name.split(" ");

  // Check if there are two words or only one
  if (words.length === 1) {
    const firstInitial = words[0].charAt(0).toUpperCase();
    const secondInitial = words[0].charAt(1).toUpperCase() || ""; // Check if a second initial exists
    return `${firstInitial} ${secondInitial}`;
  } else if (words.length === 2) {
    const firstInitial = words[0].charAt(0).toUpperCase();
    const secondInitial = words[1].charAt(0).toUpperCase();
    return `${firstInitial} ${secondInitial}`;
  } else  {
       const firstInitial = words[0].charAt(0).toUpperCase();
       const secondInitial = words[1].charAt(0).toUpperCase();
    return `${firstInitial} ${secondInitial}`;
  }
}
