import React from "react";
import "../Styles/StatementModal.css";
import CommonTable from "../../../../../../../../components/CommonTable/InputTable";

const StatementModal = () => {
  return (
    <div className="finance_statement_form">
      <div className="finance_statement_header_section">
        <h1> Statement</h1>
      </div>
      <CommonTable type="statement" />
    </div>
  );
};

export default StatementModal;
