import React, { Fragment, useEffect, useRef, useState } from "react";
import CommonUpperBox from "../../../components/UpperBox/CommonHeader";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../Controller/redux/Hooks";
import {
  getExpenseTypeAssociatedFundingThunk,
  getExpenseTypeByFilterThunk,
  getExpenseTypeNameThunk,
  getExpenseTypeRateThunk,
  resetExpenseTypeData,
  resetExpenseTypePage,
  setExpenseTypePage,
} from "../../../../Controller/redux/features/RefDataFeature/ExpenseTypeFeature/expensetypeSlice";
import Table from "../../../components/CommonTable/Table";
import CircularNoteTrigger from "../../../components/CircularNoteTrigger/CircularNoteTrigger";
import {useNavigate} from "react-router-dom";
import CommonQuickMenu from "../../../components/CommonQuickMenu/CommonQuickMenu";
import { getStatusDetailsThunk } from "../../../../Controller/redux/features/CommonFeature/commonSlice";
const ExpenseType = () => {
  const {
    lastPage,
    expenseTypePage,
    ExpessTypeData,
    loading,
    selectedFilterItemsExpenseType,
    sortByExpenseTypeR,
    searchValExpenseType,
    expenseTypeFilterItems,
  } = useAppSelector((state) => state.expenssType);
  const { domainDetail, filterIconColor } = useAppSelector(
    (state) => state?.common
  );
  const dispatch = useAppDispatch();
  // console.log(expenseTypeFilterItems);

  useEffect(() => {
    dispatch(getStatusDetailsThunk("1,0"));
  }, []);

  useEffect(() => {
    // dispatch(getExpenseType());
    dispatch(getExpenseTypeNameThunk());
    dispatch(getExpenseTypeAssociatedFundingThunk());
    dispatch(getExpenseTypeRateThunk());
  }, []);

  useEffect(() => {
    dispatch(resetExpenseTypeData());
    dispatch(resetExpenseTypePage());
  }, [
    expenseTypeFilterItems?.searchVal,
    expenseTypeFilterItems?.filterValue,
    expenseTypeFilterItems?.sortType,
  ]);
  useEffect(() => {
    dispatch(
      getExpenseTypeByFilterThunk({
        filterColumn: expenseTypeFilterItems?.filterColumn
          ? expenseTypeFilterItems?.filterColumn
          : "",
        filterValue: expenseTypeFilterItems?.filterValue
          ? expenseTypeFilterItems?.filterValue
          : "",
        searchVal: expenseTypeFilterItems?.searchVal
          ? expenseTypeFilterItems?.searchVal
          : "",
        sortColumn: expenseTypeFilterItems?.sortColumn
          ? expenseTypeFilterItems?.sortColumn
          : "",
        sortType: expenseTypeFilterItems?.sortType
          ? expenseTypeFilterItems?.sortType
          : "",
        expenseTypePage,
      })
    );
    // console.log("SSS");
  }, [
    expenseTypePage,
    expenseTypeFilterItems?.searchVal,
    expenseTypeFilterItems?.filterValue,
    expenseTypeFilterItems?.sortType,
  ]);
  const uniqueExpenseData = ExpessTypeData?.filter(
    (item: any, index: number, array: any) => {
      return array?.findIndex((obj: any) => obj.id === item.id) === index;
    }
  );
  const columns = [
    { header: "Name", accessor: "expenseName" },
    {
      header: "Associated Funding Package",
      accessor: "associated_funding_package",
    },
    { header: "Rate", accessor: "rate" },
    { header: "Status", accessor: "status" },
  ];
  const navigate = useNavigate();

  const handleClickRow = (e: any, id: any) => {
    if (!isDropdownOpenExpenseType) {
      navigate(`/ref-data/expense-type/${window.btoa(id)}`);
    }
  };

  const tableRef = useRef();
  const expenssTypeInnerRef = useRef();

  const clientStatusDetailsArr = useAppSelector(
    (state) => state.common.statusDetailsData?.data
  );
  const dropdownRefExpenseType = useRef<any>();
  const svgParentRefExpenseType = useRef<any>();
  const [isDropdownOpenExpenseType, setIsDropdownOpenExpenseType] =
    useState(false);

  useEffect(() => {
    const handleClickOutsideClients = (event: any) => {
      if (
        dropdownRefExpenseType.current &&
        !dropdownRefExpenseType.current.contains(event.target) &&
        svgParentRefExpenseType.current &&
        !svgParentRefExpenseType.current.contains(event.target)
      ) {
        setIsDropdownOpenExpenseType(false);
      }
    };
    document.addEventListener("click", handleClickOutsideClients);
    return () => {
      document.removeEventListener("click", handleClickOutsideClients);
    };
  }, []);
  const onScroll = () => {
    if (expenssTypeInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } =
        expenssTypeInnerRef.current;

      if (
        scrollTop + clientHeight + +5 >= scrollHeight &&
        expenseTypePage < lastPage
      ) {
        dispatch(setExpenseTypePage(expenseTypePage));
      }
    }
  };
  const [stageTwoToGoalsModal, setstageTwoToGoalsModal] = useState<any>(false);
  return (
    <Fragment>
      <CommonUpperBox
        title="Expense Type"
        type={"listing"}
        backgroundWithText={true}
        text={"E T"}
        onClickPlus={() => {
          setstageTwoToGoalsModal(true);
        }}
      />
      <Table
        isLoading={loading}
        tableRef={tableRef}
        tableBodyRef={expenssTypeInnerRef}
        onScroll={onScroll}
        dynamicHeaderColor={domainDetail?.listingtbl_color}
        statusType="clientScreen"
        thData={columns}
        itemsArr={clientStatusDetailsArr}
        classNameTr={"all-expenss-type-table-head"}
        type="expenseType"
        dropdownRef={dropdownRefExpenseType}
        isDropdownOpen={isDropdownOpenExpenseType}
        onClickSvg={() => setIsDropdownOpenExpenseType(true)}
        svgParentRef={svgParentRefExpenseType}
        allClients={uniqueExpenseData}
        onClickRow={handleClickRow}
        classNameRow={"all-expenss-type-table-body"}
        fill={
          selectedFilterItemsExpenseType.length > 0 ||
          searchValExpenseType.length > 0 ||
          sortByExpenseTypeR.length > 0
            ? filterIconColor
            : "#D8D3D3"
        }
      />
      <CircularNoteTrigger type="expense" title="Add Expense" />
      {stageTwoToGoalsModal && (
        <CommonQuickMenu
          setshowGoalsModal={(value: any) => {}}
          mainType={"listing"}
          setstageTwoToGoalsModal={setstageTwoToGoalsModal}
        />
      )}
    </Fragment>
  );
};

export default ExpenseType;
