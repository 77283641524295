import "./UnderWork.css";

import { Fragment } from "react";

const UnderWork = () => {
  return (
    <Fragment>
      <div className="under-work">
        <span>🚧 Work Is Being Done 🚧</span>
      </div>
    </Fragment>
  );
};

export default UnderWork;
