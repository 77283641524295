import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { api } from "../../../services/api";

const initialState: any = {
  loading: false,
  error: null,
  teamsRecords: [],
  lastPage: 10000,
};

export const getProviderTeams = createAsyncThunk(
  "provider_teams/fetchProviderTeams",
  async ({ dynamicUserTypeId, dynamicProviderId }: any) => {
    try {
      const { data } = await api.get(
        `providers/get_provider_users?paginate=true&page=1&limit=10&sort_type=asc&type=${dynamicUserTypeId}&tenant_id=${dynamicProviderId}`
      );
      return data?.data?.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getProviderTeamsByFilterThunk = createAsyncThunk(
  "provider_teams/fetchProviderTeamsByFilterThunk",
  async ({
    dynamicUserTypeId,
    dynamicProviderId,
    filterColumn,
    filterValue,
    searchVal,
    sortColumn,
    sortType,
    status,
    TeamsPage,
  }: any) => {
    const encodeFilterVal = encodeURIComponent(filterValue);
    const encodeSearchVal = encodeURIComponent(searchVal);
    try {
      const { data } = await api.get(
        `providers/get_provider_users?paginate=true&type=${dynamicUserTypeId}&tenant_id=${dynamicProviderId}&page=${TeamsPage}&status=${status}&sort_column=${sortColumn}&sort_type=${sortType}&filter_column=${filterColumn}&limit=50&filter_value=${encodeFilterVal}&search=${encodeSearchVal}`
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
);

const providerTeamsSlice = createSlice({
  name: "provider_teams",
  initialState,
  reducers: {
    resetTeamsProviderData: (state) => {
      state.teamsRecords = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProviderTeams.pending, (state) => {
        state.loading === true;
      })
      .addCase(getProviderTeams.fulfilled, (state, action) => {
        state.loading = false;
        state.teamsRecords = action.payload;
      })
      .addCase(getProviderTeams.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getProviderTeamsByFilterThunk.pending, (state) => {
        state.loading === true;
      })
      .addCase(getProviderTeamsByFilterThunk.fulfilled, (state, action) => {
        state.loading = false;
        // state.teamsRecords = action.payload;
        state.lastPage = action.payload.data.last_page;
        state.teamsRecords = [
          ...state.teamsRecords,
          ...action.payload.data.data,
        ];
      })
      .addCase(getProviderTeamsByFilterThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { resetTeamsProviderData } = providerTeamsSlice.actions;

export default providerTeamsSlice.reducer;
