import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../../services/api";

const initialState: Object | any = {
  clientName: [],
  clientEmail: [],
  clientDob: [],
  clientResidence: [],
  clientFunding: [],
  wardData: [],
  roomData: [],
  ongoingDate: { interval: "", date: "" },
  primaryDate: null,
  setUserTypeIdForClient: null,
  clientsData: [],
  allClients: [],
  searchResults: [],
  showWidgetBox: false,
  uniqueClient: null,
  documentsTabTableData: [],
  documentsTableSearchResults: [],
  statusObject: null,
  openClientAssessmentForm: false,
  clientCommonDetails: {
    name: false,
    email: false,
    phone: false,
    dob: false,
    fundingType: false,
  },
  updateClient: null,
  primaaryApprover: [],
  ongoingApprover: [],
  updateClientStatusThunk: null,
  allData: [],
  clientTableFilterDropdown: false,
  clientDataForDayBook: [],
  //STATES OF SELECTED FILTER GOES HERE
  searchValueClients: "",
  selectedDropdownItemClients: "",
  selectedItemIndexFilter: "",
  selectedItemIndexSort: "",
  sortbyClients: "",
  selectedFilterItems: [],
  clientsDataForDaybook: [],
  clientRoles: [],
  loading: false,
  ClientFilterItems: {
    status: "1,2,3,4,5",
    filterColumn: "",
    filterValue: "",
    searchVal: "",
    sortColumn: "",
    sortType: "",
    ClientPage: 1,
  },
  ClientPage: 1,
  client: [],
  lastPage: 100000,
  clientsDataForFilter: [],
  isClinicalAddNote: false,
};

// GET CLIENT DETAIL ON BASIS OF ID
export const getUniqueClientById = createAsyncThunk(
  "clients/fetch",
  async ({ typeId, clientId }: any) => {
    try {
      const response = await api.get(
        `/users?type=${typeId}&sort_column=id&sort_type=desc&id=${clientId}&paginate=true`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
export const getUniqueProviderClientById = createAsyncThunk(
  "providerClients/fetch",
  async ({ typeId, clientId, tenant_id }: any) => {
    try {
      const response = await api.get(
        `/providers/get_provider_users?paginate=true&type=${typeId}&sort_column=id&sort_type=desc&id=${clientId}&tenant_id=${tenant_id}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getUserRolesClientsThunk = createAsyncThunk(
  "clients/fetchUserRoles",

  async (dynamciClientId: any) => {
    let id = dynamciClientId.dynamicClientId;
    try {
      const response = await api.get(`/users/get_user_roles?type=${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
export const getClientsThunk = createAsyncThunk(
  "clients/fetchClients",
  async (dynamicId: number | any) => {
    try {
      const response = await api.get(
        `/users?paginate=true&status=1,2,3,4,5&type=${dynamicId.dynamicClientId}&sort_column=residence_name&sort_type=asc&page=1&limit=10000`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
export const getClientsSelectAllThunk = createAsyncThunk(
  "clients/fetchClientsSelectAll",
  async ({ dynamicId, status }: any) => {
    try {
      const response = await api.get(
        `/users?paginate=true&status=${status}&type=${dynamicId}&sort_column=id&sort_type=desc&page=1&limit=700`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
export const getClientsByFilterThunk = createAsyncThunk(
  "clients/fetchClientsByFilter",
  async ({
    dynamicId,
    filterColumn,
    filterValue,
    searchVal,
    sortColumn,
    sortType,
    status,
    ClientPage,
  }: {
    dynamicId: number;
    filterColumn: string;
    filterValue: string;
    searchVal: string;
    sortColumn: string;
    sortType: string | any;
    status: string | any;
    ClientPage: string | any;
  }) => {
    try {
      const encodedDynamicId = encodeURIComponent(dynamicId);
      const encodedFilterColumn = encodeURIComponent(filterColumn);
      const encodedFilterValue = encodeURIComponent(filterValue);
      const encodedSearchVal = encodeURIComponent(searchVal);
      const encodedSortColumn = encodeURIComponent(sortColumn);
      const encodedSortType = encodeURIComponent(sortType);

      const response = await api.get(
        `/users?paginate=true&page=${ClientPage}&status=${status}&sort_column=${encodedSortColumn}&sort_type=${encodedSortType}&filter_column=${encodedFilterColumn}&type=${encodedDynamicId}&limit=50&filter_value=${encodedFilterValue}&search=${encodedSearchVal}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
export const getLocationTypeWardThunk = createAsyncThunk(
  "clients/fetchLocationWard",
  async (value) => {
    try {
      const response = await api.get(
        `/users/get_locations_from_type?location_type=Ward&parent_id=${value}&parent_type=Building`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
export const getLocationTypeRoomThunk = createAsyncThunk(
  "clients/fetchLocationRoom",
  async (value) => {
    try {
      const response = await api.get(
        `/users/get_locations_from_type?location_type=Room&parent_id=${value}&parent_type=Ward`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
export const updateClientStatusThunk = createAsyncThunk(
  "clients/update",
  async (statusBody: any, { rejectWithValue }) => {
    try {
      const response = await api.post(
        "/users/update_client_details",
        statusBody
      );
      return response.data;
    } catch (error: string | any) {
      return rejectWithValue(
        error.message || "Failed to upload client details"
      );
    }
  }
);
export const getClientNameThunk = createAsyncThunk(
  "client/fetchName",
  async (dynamicClientId: number | any) => {
    try {
      const { data } = await api.get(
        `/users?unique_column=name&type=${dynamicClientId}`
      );
      return data?.data?.data;
    } catch (error) {
      throw error;
    }
  }
);
export const getProviderClientNameThunk = createAsyncThunk(
  "providerClient/fetchName",
  async ({ dynamicUserTypeId, dynamicProviderId }: any) => {
    try {
      const { data } = await api.get(
        `/providers/get_provider_users?unique_column=name&type=${dynamicUserTypeId}&tenant_id=${dynamicProviderId}`
      );
      return data?.data?.data;
    } catch (error) {
      throw error;
    }
  }
);
export const getClientEmailThunk = createAsyncThunk(
  "client/fetchEmail",
  async (dynamicClientId: number | any) => {
    try {
      const { data } = await api.get(
        `/users?unique_column=email&type=${dynamicClientId}`
      );
      return data?.data?.data;
    } catch (error) {
      throw error;
    }
  }
);
export const getProviderClientEmailThunk = createAsyncThunk(
  "providerClient/fetchEmail",
  async ({ dynamicUserTypeId, dynamicProviderId }: any) => {
    try {
      const { data } = await api.get(
        `/providers/get_provider_users?unique_column=email&type=${dynamicUserTypeId}&tenant_id=${dynamicProviderId}`
      );
      return data?.data?.data;
    } catch (error) {
      throw error;
    }
  }
);
export const getClientDobThunk = createAsyncThunk(
  "client/fetchDob",
  async (dynamicClientId: number | any) => {
    try {
      const { data } = await api.get(
        `/users?unique_column=date_of_birth&type=${dynamicClientId}`
      );
      return data?.data?.data;
    } catch (error) {
      throw error;
    }
  }
);
export const getProviderClientDobThunk = createAsyncThunk(
  "providerClient/fetchDob",
  async ({ dynamicUserTypeId, dynamicProviderId }: any) => {
    try {
      const { data } = await api.get(
        `/providers/get_provider_users?unique_column=date_of_birth&type=${dynamicUserTypeId}&tenant_id=${dynamicProviderId}`
      );
      return data?.data?.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getClientFundingThunk = createAsyncThunk(
  "client/fetchFunding",
  async (dynamicClientId: number | any) => {
    try {
      const { data } = await api.get(
        `/users?unique_column=funding_type&type=${dynamicClientId}`
      );
      return data?.data?.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getProviderClientFundingThunk = createAsyncThunk(
  "providerClient/fetchFunding",
  async ({ dynamicUserTypeId, dynamicProviderId }: any) => {
    try {
      const { data } = await api.get(
        `/providers/get_provider_users?unique_column=funding_type&type=${dynamicUserTypeId}&tenant_id=${dynamicProviderId}`
      );
      return data?.data?.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getClientResidenceThunk = createAsyncThunk(
  "client/fetchResidence",
  async (dynamicClientId: number | any) => {
    try {
      const { data } = await api.get(
        `/users?unique_column=residence_name&type=${dynamicClientId}`
      );
      return data?.data?.data;
    } catch (error) {
      throw error;
    }
  }
);
export const clientsSlice = createSlice({
  name: "people",
  initialState,
  reducers: {
    // Pagination Start
    setClientPage: (state) => {
      state.ClientPage += 1;
    },
    resetClientPage: (state) => {
      state.ClientPage = 1;
    },
    resetClientData: (state) => {
      state.allClientUserData = {};
      state.client = [];
    },
    setClientFilterItems: (state, action) => {
      state.ClientFilterItems = action.payload;
    },
    resetClientFilterItems: (state) => {
      state.ClientFilterItems = {
        status: "1,2,3,4,5",
        filterColumn: "",
        filterValue: "",
        searchVal: "",
        sortColumn: "",
        sortType: "",
        ClientPage: 1,
      };
    },
    // Pagination End

    setOngoingReviewDate: (state, action) => {
      state.ongoingDate = action.payload;
    },
    setPrimaryReviewDate: (state, action) => {
      state.primaryDate = action.payload;
    },
    setUserTypeIdForClient: (state, action) => {
      state.setUserTypeIdForClient = action.payload;
    },
    setStatusDetailObject: (state, action) => {
      state.statusObject = action.payload;
    },
    setdocumentsTabTableData: (state, action) => {
      state.documentsTabTableData = action.payload;
      state.documentsTableSearchResults = action.payload;
    },

    setshowbox: (state, action) => {
      state.showWidgetBox = action.payload;
    },
    setclientTableFilterDropdown: (state) => {
      state.clientTableFilterDropdown = true;
    },
    resetclientTableFilterDropdown: (state) => {
      state.clientTableFilterDropdown = false;
    },
    setopenClientAssessmentForm: (state, action) => {
      state.openClientAssessmentForm = action.payload;
    },
    setsearchValueClients: (state, action) => {
      state.searchValueClients = action.payload;
    },
    resetsearchValueClients: (state, action) => {
      state.searchValueClients = null;
    },
    // FILTER DROPDOWN REDUCERS CLIENTS
    setselectedDropdownItemClients: (state, action) => {
      state.selectedDropdownItemClients = action.payload;
    },
    resetselectedDropdownItemClients: (state) => {
      state.selectedDropdownItemClients = null;
    },
    setselectedItemIndexFilterClients: (state, action) => {
      state.selectedItemIndexFilter = action.payload;
    },
    resetselectedItemIndexFilterClients: (state) => {
      state.selectedItemIndexFilter = null;
    },
    setselectedItemIndexSortClients: (state, action) => {
      state.selectedItemIndexSort = action.payload;
    },
    resetselectedItemIndexSortClients: (state) => {
      state.selectedItemIndexSort = null;
    },
    setsortbyClients: (state, action) => {
      state.sortbyClients = action.payload;
    },
    resetsortbyClients: (state) => {
      state.sortbyClients = "";
    },

    setselectedFilterItemsClients: (state, action) => {
      state.selectedFilterItems = action.payload;
    },
    resetselectedFilterItemsClients: (state) => {
      state.selectedFilterItems = "";
    },
    clearCurrentClientData: (state) => {
      state.uniqueClient = null;
    },
    setClinicalAddNote: (state, action) => {
      state.isClinicalAddNote = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUniqueClientById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUniqueClientById.fulfilled, (state, action) => {
        state.uniqueClient = action.payload;
      })
      // Provider Unique Client
      .addCase(getUniqueProviderClientById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUniqueProviderClientById.fulfilled, (state, action) => {
        state.loading = false;
        state.uniqueClient = action.payload;
      })
      .addCase(getClientsThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getClientsThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.allData = action.payload;
        state.allClients = action.payload.data.data;
        state.clientsData = action.payload.data.data;
        state.clientsDataForFilter = action.payload.data.data;
        // FOR DAYBOOK
        state.clientDataForDayBook = action.payload.data.data;
      })
      .addCase(getClientsThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getClientsSelectAllThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getClientsSelectAllThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.allData = action.payload;
        state.allClients = action.payload.data.data;
        // state.clientsData = action.payload.data.data;
      })
      .addCase(getClientsSelectAllThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getClientsByFilterThunk.pending, (state) => {
        state.loading = true;
      })
      //
      .addCase(getClientsByFilterThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.allData = action.payload;
        state.allClients = action.payload.data.data;
        state.lastPage = action.payload.data.last_page;
        state.client = [...state.client, ...action.payload.data.data];
      })
      .addCase(getClientsByFilterThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updateClientStatusThunk.pending, (state) => {
        state.loading = true;
        state.error = null;
      })

      .addCase(updateClientStatusThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.updateClientStatusThunk = action.payload;
      })

      .addCase(updateClientStatusThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload
          ? action.payload
          : "Failed to update client status details";
      })

      .addCase(getUserRolesClientsThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUserRolesClientsThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.clientRoles = action.payload;
      })
      .addCase(getUserRolesClientsThunk.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getLocationTypeWardThunk.pending, (state) => {
        state.loading === true;
      })
      .addCase(getLocationTypeWardThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.wardData = action.payload;
      })
      .addCase(getLocationTypeWardThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getLocationTypeRoomThunk.pending, (state) => {
        state.loading === true;
      })
      .addCase(getLocationTypeRoomThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.roomData = action.payload;
      })
      .addCase(getLocationTypeRoomThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getClientNameThunk.pending, (state) => {
        state.loading === true;
      })
      .addCase(getClientNameThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.clientName = action.payload;
      })
      .addCase(getClientNameThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      // Provider Client Name thunk
      .addCase(getProviderClientNameThunk.pending, (state) => {
        state.loading === true;
      })
      .addCase(getProviderClientNameThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.clientName = action.payload;
      })
      .addCase(getProviderClientNameThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(getClientEmailThunk.pending, (state) => {
        state.loading === true;
      })
      .addCase(getClientEmailThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.clientEmail = action.payload;
      })
      .addCase(getClientEmailThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(getProviderClientEmailThunk.pending, (state) => {
        state.loading === true;
      })
      .addCase(getProviderClientEmailThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.clientEmail = action.payload;
      })
      .addCase(getProviderClientEmailThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(getClientDobThunk.pending, (state) => {
        state.loading === true;
      })
      .addCase(getClientDobThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.clientDob = action.payload;
      })
      .addCase(getClientDobThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(getProviderClientDobThunk.pending, (state) => {
        state.loading === true;
      })
      .addCase(getProviderClientDobThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.clientDob = action.payload;
      })
      .addCase(getProviderClientDobThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(getClientFundingThunk.pending, (state) => {
        state.loading === true;
      })
      .addCase(getClientFundingThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.clientFunding = action.payload;
      })
      .addCase(getClientFundingThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(getProviderClientFundingThunk.pending, (state) => {
        state.loading === true;
      })
      .addCase(getProviderClientFundingThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.clientFunding = action.payload;
      })
      .addCase(getProviderClientFundingThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(getClientResidenceThunk.pending, (state) => {
        state.loading === true;
      })
      .addCase(getClientResidenceThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.clientResidence = action.payload;
      })
      .addCase(getClientResidenceThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const {
  setOngoingReviewDate,
  setPrimaryReviewDate,
  setUserTypeIdForClient,
  setshowbox,
  setdocumentsTabTableData,
  setopenClientAssessmentForm,
  setStatusDetailObject,
  setclientTableFilterDropdown,
  resetclientTableFilterDropdown,
  setsearchValueClients,
  resetsearchValueClients,
  setselectedDropdownItemClients,
  resetselectedDropdownItemClients,
  setselectedItemIndexFilterClients,
  resetselectedItemIndexFilterClients,
  setsortbyClients,
  resetsortbyClients,
  setselectedItemIndexSortClients,
  resetselectedItemIndexSortClients,
  setselectedFilterItemsClients,
  resetselectedFilterItemsClients,
  clearCurrentClientData,
  resetClientData,
  resetClientFilterItems,
  resetClientPage,
  setClientFilterItems,
  setClientPage,
  setClinicalAddNote,
} = clientsSlice.actions;

export default clientsSlice.reducer;
