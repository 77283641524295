import { useState } from "react";

const UpDownSvg = ({
  width = 24,
  height = 24,
  color = "#333333",
  onClick = () => {},
  hoverColor = "#06a5ff",
}: any) => {
  const [isHovered, setIsHovered] = useState<any>(false);
  return (
    <div
      onClick={onClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8 20L7.29289 20.7071L8 21.4142L8.70711 20.7071L8 20ZM10 5C10.5523 5 11 4.55228 11 4C11 3.44772 10.5523 3 10 3L10 5ZM3.29289 16.7071L7.29289 20.7071L8.70711 19.2929L4.70711 15.2929L3.29289 16.7071ZM8.70711 20.7071L12.7071 16.7071L11.2929 15.2929L7.29289 19.2929L8.70711 20.7071ZM9 20L9 6L7 6L7 20L9 20ZM9 6C9 5.44772 9.44771 5 10 5L10 3C8.34315 3 7 4.34315 7 6L9 6Z"
          fill={isHovered ? hoverColor : color}
        />
        <path
          d="M16 4L15.2929 3.29289L16 2.58579L16.7071 3.29289L16 4ZM16 19L17 19L17 19L16 19ZM14 22C13.4477 22 13 21.5523 13 21C13 20.4477 13.4477 20 14 20L14 22ZM11.2929 7.29289L15.2929 3.29289L16.7071 4.70711L12.7071 8.70711L11.2929 7.29289ZM16.7071 3.29289L20.7071 7.29289L19.2929 8.70711L15.2929 4.70711L16.7071 3.29289ZM17 4L17 19L15 19L15 4L17 4ZM17 19C17 20.6569 15.6569 22 14 22L14 20C14.5523 20 15 19.5523 15 19L17 19Z"
          fill={isHovered ? hoverColor : color}
        />
      </svg>
    </div>
  );
};

export default UpDownSvg;
