// @ts-nocheck

import { useEffect, useState, useRef } from "react";
import "../../../../../Styles/ClientAssessmentForm.css";
import "react-datepicker/dist/react-datepicker.css";
import { EditorState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import enGB from "date-fns/locale/en-GB";
import WidgetItems from "../../../../WidgetItems";
import { toast } from "react-toastify";
import CustomMultiSelect from "../../../../../../../../components/MultiSelectDropdown/MultiSelect";
import { api } from "../../../../../../../../../Controller/services/api";
import { Spinner } from "@chakra-ui/react";
import { useLocation, useParams } from "react-router-dom";
import { ThreadsBox } from "../../../../../../../../components/CommonModal/Components/ThreadsBox";

import {
  formatDateForAPI,
  formatDateFromAPIforCustom,
  formatDateWithoutTime,
  formatTimeAgoForDocumentCurrentDate,
  timeAgo,
} from "../../../../../../../../../Model/utils/Helper/formatDate";
import {
  createDocumentCommsThunk,
  getCommsOfDocumentThunk,
  getDocuments,
  getDocumentsForClinicalThunk,
  getUnreadMessagesDocsThunk,
  setUpdateDocumentDataToStore,
  updateDocumentThunk,
} from "../../../../../../../../../Controller/redux/features/CommonFeature/documentSlice";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../Controller/redux/Hooks";
import {
  setOngoingReviewDate,
  setPrimaryReviewDate,
  setopenClientAssessmentForm,
} from "../../../../../../../../../Controller/redux/features/ClientFeature/clientsSlice";
import ClientCommsForm from "./ClientCommsForm";
import { getInitials } from "../../../../../../../../../Model/utils/Helper/getInitials";
import VerticalModal from "../../../../../../../../components/CommonModal/VerticalModal";
import Tags from "../../../../../../../../components/Tags/Tags";
import {
  getAllTagsThunk,
  getFileThunk,
} from "../../../../../../../../../Controller/redux/features/CommonFeature/commonSlice";
import { getStaffThunk } from "../../../../../../../../../Controller/redux/features/StaffFeature/staffSlice";
import CreateComms from "../../../../../../../Comms/Component/CreateCommsSection/Index";
import NormalPlusSvg from "../../../../../../../../../Model/Assets/SVG/Common/NormalPlusSvg";

import { CrossMdSvg } from "../../../../../../../../../Model/Assets/SVG/Common/CrossSvg";

import {
  resetIsAssessmentFormOpenProspects,
  resetopenFormByClickProspects,
} from "../../../../../../../../../Controller/redux/features/ProspectsFeature/documentProspectsSlice";
import { resetOpenFormByClickBusiness } from "../../../../../../../../../Controller/redux/features/BusinessFeature/documentBusinessSlice";
import {
  resetIsAssessmentFormOpenContacts,
  resetopenFormByClickContacts,
} from "../../../../../../../../../Controller/redux/features/ContactsFeature/documentContactSlice";
import { resetOpenFormByClickLocation } from "../../../../../../../../../Controller/redux/features/LocationFeature/locationSlice";
import { resetOpenFormByClickTeams } from "../../../../../../../../../Controller/redux/features/TeamsFeature/documentTeamsSlice";
import {
  resetIsAssessmentFormOpenStaff,
  resetOpenFormByClickStaff,
} from "../../../../../../../../../Controller/redux/features/StaffFeature/documentStaffSlice";
import {
  getCustomFormsTabsByIdThunk,
  setCurrentActiveFormInfo,
} from "../../../../../../../../../Controller/redux/features/CustomFromFeature/customFormSlice";
import CustomDatePicker from "../../../../../../../../components/DatePicker/CustomDatePicker";
import GlobalPDFSvg from "../../../../../../../../../Model/Data/PDFSvg";
import GlobalDOCXSvg from "../../../../../../../../../Model/Data/DOCXSvg";
import EXCELSvg from "../../../../../../../../../Model/Data/EXCELSvg";
import {
  CrossSvg,
  FileInputSvg,
  MessageCounterSvg,
  UploadDocSvg,
} from "../Data/SVGs";
import { GreenCircleSvg } from "../../ClinicalTab/Data/SVGs";
import TrafficLights from "../../../../../../../../components/CommonModal/Components/TrafficLights";
import { getUserNotificationStats } from "../../../../../../../../../Controller/redux/features/NotificationsFeature/NotificaionsSlice";
import { updateTaggedUsersThunk } from "../../../../../../../../../Controller/redux/features/CommsFeature/commsSlice";
import { updateDaybookItem } from "../../../../../../../../../Controller/redux/features/DaybookFeature/daybookSlice";
import ConfirmationModal from "../../../../../../../../components/ConfirmationModal/ConfirmationModal";

registerLocale("en-GB", enGB);
setDefaultLocale("en-GB");

const ClientAssessmentForm = ({
  uploadedFilesFromComms,
  setshowDocumentForm = () => {},
  lastUpdated,
  isThereAnyChanges,
  setIsThereAnyChanges = () => {},
  triggerGetDocuments,
  type,
  userId,
  fromNote,
  setViewFiles = () => {},
  setopenForm = () => {},
  isDocumentCreateState,
  setIsFormOpenDocContacts = () => {},
  dataObject,
  setisModifyFromDaybook = () => {},
  setisModify = () => {},
  handleGetDetails = () => {},
  showStateOfDocument,
  isViewStateInComms,
  loggedInUserDetailsFromDaybook,
  setIsFormOpenDoc = () => {},
  handleChangesToForm = () => {},
  setIsFormOpenStaff = () => {},
  isCustomFormCreation,
  setDataObject = () => {},
  openFormClinical,
  clinicalType,
  setuploadedFilesFromDaybook = () => {},
  setPrimaryApprover,
  setOngoingApprover,
  triggeredFromDaybook,
  handleSaveClick = () => {},
  setShowAttachmentAddingModal = () => {},
  setopenVerticaModal = () => {},
  customFormLinkedDocument,
  setcustomFormLinkedDocument = () => {},

  setIsClickFromCross = () => {},
  setstageTwoToGoalsModal = () => {},

  setisFormOpen = () => {},
  idDaybook,
  setIsFormOpenLocation = () => {},
  setIsFormOpenTeams = () => {},
  clinicalNoteTitle = "",
  providerId = "",
  setUploadDoc = () => {},
}: string | any) => {
  let id = useParams()?.id;
  const location = useLocation();
  const pathname = location.pathname;
  let loggedInUserId = localStorage.getItem("loggedInUserId");
  const dynamicStaffId = parseInt(localStorage.getItem("dynamicStaffId"));

  //LOCAL REFS
  const commsRef = useRef<any>(null);
  const pickerRef = useRef<any>(null);
  const textAreaRef = useRef<any>(null);

  //LOCAL STATES
  const [clientId, setclientId] = useState("");
  const [profilePicture, setProfilePicture] = useState<any>("");
  const [clientData, setClientData] = useState<any>("");
  const [updateDocumentData, setUpdateDocumentData] = useState<any>({
    title: "",
    summary: "",
    primary_review: "",
    ongoing_review: "",
    period_picker: "",
    start: "",
    end: "",
    primary_approver_id: [],
    ongoing_approver_id: [],
    hard_copy_completed_date: "2023-11-23",
    document_files: [],
    user_id: clientId,
    document_id: dataObject?.id,
    tag: [],
    custom_form_id: "",
  });
  const [showLights, setShowLights] = useState<boolean>(false);
  const [trafficColors, setTrafficColors] = useState<any>(
    dataObject?.completion_status === 0
      ? "#ef3a34"
      : dataObject?.completion_status === 1
      ? "#fbbf14"
      : "#2AD400"
  );

  const [selectedMessage, setSelectedMessage] = useState("");
  const [showTag, setshowTag] = useState(false);
  const [subjectField, setsubjectField] = useState("");
  const [selectedDate, setSelectedDate] = useState<any>(
    dataObject?.ongoing_review ? new Date(dataObject?.ongoing_review) : null
  );
  const [dateWithPeriodPicker, setdateWithPeriodPicker] = useState<any>({
    startDate: null,
    endDate: null,
    sMonth: "",
    sYear: "",
    eMonth: "",
    eYear: "",
  });

  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const [selectedInterval, setSelectedInterval] = useState(
    dataObject?.period_picker ? dataObject?.period_picker : ""
  );
  const [isPickerOpen, setIsPickerOpen] = useState(false);

  const [ongoingApproverOptions, setOngoingApproverOptions] = useState<any>(
    dataObject ? dataObject?.ongoing_approvers : []
  );
  const [selectedOptions, setSelectedOptions] = useState<any>(
    dataObject ? dataObject.primary_approvers : []
  );

  const [isOpenTwo, setisOpenTwo] = useState(false);
  const [isDatePickerFocused, setIsDatePickerFocused] = useState(false);
  const [uploadDocument, setUploadDocument] = useState(
    showStateOfDocument?.includes("onRow")
      ? 1
      : showStateOfDocument?.includes("onAttachment")
      ? 2
      : triggeredFromDaybook
      ? 2
      : 1
  );
  const [startDate, setStartDate] = useState<Date | null>(
    dataObject?.primary_review ? new Date(dataObject?.primary_review) : null
  );

  const [selectedFiles, setSelectedFiles] = useState<any>([]);
  const [primaryArray, setPrimaryArray] = useState<number[]>([]);
  const [ongoingArray, setOngoingArray] = useState<number[]>([]);
  const [createDocumentDetails, setcreateDocumentDetails] = useState({
    title: "",
    summary: "",
    primaryReviewDate: formatDateForAPI(startDate),
    ongoingReviewDate: "",
    subject: "",
  });
  const [selectedItemsArr, setselectedItemsArr] = useState<any>([]);

  useEffect(() => {
    if (dataObject) {
      setselectedItemsArr(
        typeof dataObject?.tagged_person === "string"
          ? dataObject?.tagged_person
              .split(",")
              .map((item: string) => parseInt(item))
          :Array.isArray( dataObject?.tagged_person)
          ?
          dataObject?.tagged_person :
          []
      );
    }
  }, [dataObject]);


  console.log('dataObject?.tagged_person', dataObject?.tagged_person);
  const [isCustom, setIsCustom] = useState(false);
  const [hardCopyDate, setHardCopyDate] = useState<Date | null>(
    dataObject?.hard_copy_completed_date
      ? new Date(dataObject.hard_copy_completed_date)
      : null
  );

  const [keysArray, setKeysArray] = useState<any>([]);

  // console.log('selectedInterval', selectedInterval);
  const [createDocument, setCreateDocument] = useState<any>({
    title:
      clinicalType === "clinicalType"
        ? "Clinical Care Plan And Assessment"
        : createDocumentDetails.title,
    summary: createDocumentDetails.summary,
    primary_review: formatDateForAPI(startDate),
    ongoing_review: !isCustom ? formatDateForAPI(selectedDate) : "",
    period_picker: selectedInterval,
    start: isCustom ? formatDateForAPI(dateWithPeriodPicker?.startDate) : "",
    end: isCustom ? formatDateForAPI(dateWithPeriodPicker?.endDate) : "",
    primary_approver_id: [],
    ongoing_approver_id: [],
    tag: [],
    hard_copy_completed_date: formatDateForAPI(hardCopyDate),
    document_files: keysArray,
    communications: selectedMessage,
    user_id: clientId,
  });
  const [editorFocus, setEditorFocus] = useState(false);
  const [editorFocusUploadState, seteditorFocusUploadState] = useState(false);
  // const [isThereAnyChanges, setIsThereAnyChanges] = useState(false);
  const [loading, setLoading] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState<any>(
    Array.isArray(dataObject?.files) ? dataObject?.files : []
  );
  const [showWidget, setshowWidget] = useState(false);
  const [dragging, setDragging] = useState(false);
  const [documentExsistsAlready, setdocumentExsistsAlready] = useState(false);
  const [SelectedTagList, setSelectedTagList] = useState<number[]>(
    dataObject ? dataObject.tags : []
  );
  const [showModal, setShowModal] = useState(false);
  const [documentTags, setDocumentTags] = useState([]);
  const [commsMessageToSendToBackend, setcommsMessageToSendToBackend] =
    useState("");
  const [isThereAnyMessageOnEditor, setIsThereAnyMessageOnEditor] =
    useState("");
  const [isThereMessage, setisThereMessage] = useState<any>(null);
  const [editorStateEditState, seteditorStateEditState] = useState(
    EditorState.createEmpty()
  );
  const [sendCommsInEditState, setsendCommsInEditState] = useState("");
  const [sendSubjectInEditState, setsendSubjectInEditState] = useState("");
  const [createCommsData, setCreateCommsData] = useState<any>({
    link_id: dataObject?.document_uid,
    summary: sendSubjectInEditState,
    message: selectedMessage,
    comm_type: "DOCUMENT",
  });
  const [mentionUsersList, setMentionUsersList] = useState<any[]>([]);
  const [isHovering, setIsHovering] = useState(false);

  const [localStateCommsDocuments, setlocalStateCommsDocuments] = useState<any>(
    []
  );

  console.log(localStateCommsDocuments, "loclState");

  useEffect(() => {
    if (dataObject?.communications) {
      setlocalStateCommsDocuments(dataObject?.communications);
    }
  }, [dataObject]);

  // REDUX STATES
  const { getUniqueProspect } = useAppSelector((state) => state?.prospects);
  const currentProspect = getUniqueProspect?.data?.data[0];

  const getCurrentClient = useAppSelector((state) => state.people.uniqueClient);
  const currentClient = getCurrentClient?.data?.data[0];

  const { getUniqueBusiness } = useAppSelector((state) => state?.business);
  const currentBusiness = getUniqueBusiness?.data?.data[0];

  const { getUniqueTeams } = useAppSelector((state) => state?.teams);
  const currentTeams = getUniqueTeams?.data?.data[0];

  const { getUniqueContact } = useAppSelector((state) => state?.contacts);
  const currentContact = getUniqueContact?.data?.data[0];

  const { getUniqueStaff } = useAppSelector((state) => state?.staff);
  const currentStaff = getUniqueStaff?.data?.data[0];

  const { getUniqueLocationUser } = useAppSelector((state) => state?.location);

  const { getAllStaffData } = useAppSelector((state) => state?.staff);

  const { getUnreadMessages } = useAppSelector((state) => state.document);

  const updatedDoc = useAppSelector(
    (state) => state?.document?.updateDocumentData
  );

  const dynamicColor = useAppSelector((state) => state?.common?.dynamicColor);

  const documents = useAppSelector((state) => state?.document?.getDocuments);

  const tagArray = useAppSelector((state) => state?.common?.allTags);

  const { allUserTypeData } = useAppSelector((state) => state.comms);

  const currentLocation = getUniqueLocationUser?.data?.data[0];

  const [showPrimaryReviewPicker, setShowPrimaryReviewPicker] = useState(false);
  const [showOngoingReviewPicker, setShowOngoingReviewPicker] = useState(false);
  const [showHardCopyCompletedPicker, setShowHardCopyCompletedPicker] =
    useState(false);
  //////////////////////////////////////// SIDE EFFECTS

  useEffect(() => {
    setTrafficColors(
      dataObject?.completion_status === 0
        ? "#ef3a34"
        : dataObject?.completion_status === 1
        ? "#fbbf14"
        : "#2AD400"
    );
  }, [dataObject?.completion_status]);

  useEffect(() => {
    if (id) {
      let realId = window.atob(id);
      setclientId(realId);
    }
  }, [id]);

  useEffect(() => {
    if (loggedInUserDetailsFromDaybook) {
      setProfilePicture(loggedInUserDetailsFromDaybook.profile_image_s3);
    }
  }, [loggedInUserDetailsFromDaybook]);

  useEffect(() => {
    if (pathname.includes("/prospects")) {
      setClientData(currentProspect);
    } else if (pathname.includes("/clients")) {
      setClientData(currentClient);
    } else if (pathname.includes("/business")) {
      setClientData(currentBusiness);
    } else if (pathname.includes("/teams")) {
      setClientData(currentTeams);
    } else if (pathname.includes("/contacts")) {
      setClientData(currentContact);
    } else if (pathname.includes("/staff")) {
      setClientData(currentStaff);
    } else if (pathname.includes("/locations")) {
      setClientData(currentLocation);
    }
  }, [
    currentProspect,
    currentClient,
    currentBusiness,
    currentTeams,
    currentContact,
    currentStaff,
    currentLocation,
  ]);

  useEffect(() => {
    if (clientData?.profile_image) {
      const path = clientData?.profile_image;
      if (path) {
        dispatch(getFileThunk({ path })).then((res: any) =>
          setProfilePicture(res?.payload?.data)
        );
      }
    }
  }, [clientData?.profile_image]);

  useEffect(() => {
    setUpdateDocumentData({
      title: dataObject?.title,
      summary: dataObject?.summary,
      primary_review: dataObject?.primary_review,
      ongoing_review: dataObject?.ongoing_review,
      period_picker: dataObject?.period_picker,
      start: dataObject?.start,
      end: dataObject?.end,
      primary_approver_id: dataObject?.primary_approver_id,
      ongoing_approver_id: dataObject?.ongoing_approver_id,
      hard_copy_completed_date: dataObject?.hard_copy_completed_date,
      hard_copy_completed_time: time.startTime,

      document_files: newDocumentFilesArr,
      user_id: clientId,
      document_id: dataObject?.id,
      tag: dataObject?.tag,
      custom_form_id: dataObject?.custom_form_id,
    });
  }, [dataObject]);

  useEffect(() => {
    if (Array.isArray(documents)) {
      for (const item of documents) {
        if (item?.title?.includes("Clinical Care Plan And Assessment")) {
          setdocumentExsistsAlready(true);
        }
      }
    }
  }, [documents]);

  useEffect(() => {
    if (dataObject?.period_picker === "custom") {
      setIsCustom(true);
    }
  }, [dataObject]);

  useEffect(() => {
    updateDatePickerValue();
  }, [dateWithPeriodPicker]);

  useEffect(() => {
    if (dateWithPeriodPicker.startDate && dateWithPeriodPicker.endDate) {
      if (typeof setDataObject === "function") {
        setDataObject((prevDetails: any) => ({
          ...prevDetails,
          start: formatDateForAPI(dateWithPeriodPicker.startDate),
          end: formatDateForAPI(dateWithPeriodPicker.endDate),
        }));
      }
    }
  }, [dateWithPeriodPicker]);

  useEffect(() => {
    if (dataObject) {
      let getTagIdsArray = dataObject?.tags?.map((res: any) => res.id);
      setSelectedTagList(getTagIdsArray);
      setcreateDocumentDetails((prevDetails: any) => ({
        ...prevDetails,
        tag: getTagIdsArray,
      }));
    }
    return () => {};
  }, [dataObject]);

  useEffect(() => {
    dispatch(
      setOngoingReviewDate({
        interval: selectedInterval,
        date: selectedDate,
      })
    );
  }, [selectedDate, selectedInterval]);

  useEffect(() => {
    if (startDate) {
      dispatch(setPrimaryReviewDate(startDate));
    }
  }, [startDate]);

  useEffect(() => {
    if (uploadedFiles) {
      setisModify(true);
      setuploadedFilesFromDaybook(uploadedFiles);
    }
  }, [uploadedFiles]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutsideOfSubjectWrapper);
    return () => {
      document.removeEventListener(
        "mousedown",
        handleClickOutsideOfSubjectWrapper
      );
    };
  }, []);

  useEffect(() => {
    dispatch(getAllTagsThunk());
  }, []);

  useEffect(() => {
    if (tagArray) {
      setDocumentTags(tagArray);
    }
  }, [tagArray]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (uploadedFiles) {
      setuploadedFilesFromDaybook(uploadedFiles);
    }
  }, [uploadedFiles]);

  useEffect(() => {
    // VALIDATION
    if (Array.isArray(selectedOptions) && selectedOptions.length > 0) {
      // setIsThereAnyChanges(true);
      const updatedPrimaryArray = selectedOptions.map((elem: any) => elem?.id);
      setPrimaryArray(updatedPrimaryArray);
    }
    // VALIDATION
    if (Array.isArray(ongoingApproverOptions)) {
      // setIsThereAnyChanges(true);
      const updatedOngoingArray = ongoingApproverOptions.map(
        (elem: any) => elem?.id
      );
      setOngoingArray(updatedOngoingArray);
    }
  }, [selectedOptions, ongoingApproverOptions]);

  useEffect(() => {
    document.addEventListener("click", handleClickOutsideForPeriodPicker);
    return () => {
      document.removeEventListener("click", handleClickOutsideForPeriodPicker);
    };
  }, []);

  useEffect(() => {
    if (dataObject) {
      if (isThereAnyChanges) {
        setDataObject({
          ...dataObject,
          isThereAnyChanges: isThereAnyChanges,
        });
      } else {
        setDataObject({ ...dataObject, isThereAnyChanges: false });
      }
    }
  }, [isThereAnyChanges]);

  const { time } = useAppSelector((state) => state.common);
  const selectedIntervalRedux = useAppSelector(
    (state) => state?.document?.selectedInterval
  );

  useEffect(() => {
    if (selectedIntervalRedux) {
      setSelectedInterval(selectedIntervalRedux);
    }
  }, [selectedIntervalRedux]);
  // console.log('selc', selc);

  // console.log('time', time.startTime);
  useEffect(() => {
    setCreateCommsData({
      link_id: dataObject?.document_uid,
      summary: "",
      message: selectedMessage,
      comm_type: "DOCUMENT",
    });
  }, [dataObject, selectedMessage]);

  useEffect(() => {
    setCreateCommsData({
      link_id: dataObject?.document_uid,
      summary: sendSubjectInEditState,
      message: sendCommsInEditState,
      comm_type: "DOCUMENT",
    });
  }, [sendSubjectInEditState, sendCommsInEditState]);

  useEffect(() => {
    const extractedKeys = uploadedFiles?.map((elem) => elem?.key);
    const extractedNames = uploadedFiles?.map((elem) => elem?.file?.name);

    const newArray = extractedKeys?.map((key, index) => ({
      file_name: key,
      actual_file_name: extractedNames[index],
    }));

    setKeysArray(newArray);
    if (dataObject) {
      setDataObject((prevDetails: any) => ({
        ...prevDetails,
        document_files: keysArray,
      }));
    }
  }, [uploadedFiles]);

  const [completionStatus, setcompletionStatus] = useState(
    dataObject?.completion_status || ""
  );

  const onChangeTrafficLight = async (data) => {
    if (!dataObject) {
      console.error("dataObject is not defined");
      return;
    }

    const filteredDocumentFiles =
      dataObject?.document_files?.filter(
        (elem) => elem !== null && Object.keys(elem).length > 0
      ) || [];

    // alert(data)

    let completion_status;
    if (data === "#EF3A34") {
      completion_status = 0;
    } else if (data === "#FBBF14") {
      completion_status = 1;
    } else if (data === "#2AD400") {
      completion_status = 2;
    } else {
      completion_status = 1;
    }

    const { user_entity_id, ...restDataObject } = dataObject;

    const requiredParams = {
      ...restDataObject,
      user_id: dataObject?.user_entity_id,
      document_files: [],
      completion_status: completion_status,
      tag: [],
      document_id: dataObject?.id,
      primary_approver_id: Array.isArray(dataObject?.primary_approver_id)
        ? dataObject?.primary_approver_id
        : dataObject?.primary_approvers?.map((elem) => elem?.id),
      ongoing_approver_id: Array.isArray(dataObject?.ongoing_approver_id)
        ? dataObject?.ongoing_approver_id
        : dataObject?.ongoing_approvers?.map((elem) => elem?.id),
    };

    dispatch(updateDocumentThunk(requiredParams)).then((res) => {
      console.log("aaggg", res);
      if (res?.error) {
        toast.error(res?.payload);
        return;
      } else {
        setcompletionStatus(completion_status);
        dispatch(getDocuments({ clientId, providerId }));
      }
    });
  };

  useEffect(() => {
    setCreateDocument((prevCreateDocument: any) => ({
      ...prevCreateDocument,
      title:
        clinicalType === "clinicalType" && !documentExsistsAlready
          ? "Clinical Care Plan And Assessment"
          : createDocumentDetails.title,
      summary: createDocumentDetails.summary,
      primary_review: formatDateForAPI(startDate),
      ongoing_review: formatDateForAPI(selectedDate),
      period_picker: selectedInterval,
      start: isCustom ? formatDateForAPI(dateWithPeriodPicker?.startDate) : "",
      end: isCustom ? formatDateForAPI(dateWithPeriodPicker?.endDate) : "",
      hard_copy_completed_date: formatDateForAPI(hardCopyDate),
      document_files: keysArray,
      tag: SelectedTagList,
      primary_approver_id: primaryArray,
      ongoing_approver_id: ongoingArray,
      communications: [{ summary: "", message: selectedMessage }],
      user_id: clientId,
      tagged_person: selectedItemsArr?.map((el) => el?.id)
    }));
  }, [
    createDocumentDetails,
    startDate,
    selectedMessage,
    selectedDate,
    selectedInterval,
    isCustom,
    dateWithPeriodPicker,
    hardCopyDate,
    editorState,
    clientId,
    keysArray,
    primaryArray,
    ongoingArray,
    SelectedTagList,
    clinicalType,
    commsMessageToSendToBackend,
    selectedItemsArr
  ]);

  useEffect(() => {
    handleGetDetails(createDocument);
  }, [createDocument]);

  useEffect(() => {
    if (
      (isThereTextInMessage !== null &&
        isThereTextInMessage !== undefined &&
        isThereTextInMessage !== "") ||
      sendSubjectInEditState !== "" ||
      subjectField !== ""
    ) {
      seteditorFocusUploadState(true);
    } else {
      seteditorFocusUploadState(false);
    }
  }, [isThereTextInMessage, sendSubjectInEditState, subjectField]);

  useEffect(() => {
    if (dataObject && dataObject?.id && loggedInUserId) {
      dispatch(
        getUnreadMessagesDocsThunk({
          linkId: dataObject?.id,
          userId: loggedInUserId,
        })
      );
    }
  }, [loggedInUserId]);

  useEffect(() => {
    dispatch(getStaffThunk({ dynamicStaffId }));
  }, [dynamicStaffId]);

  useEffect(() => {
    if (getAllStaffData?.data) {
      let replicateAllStaffRecords = JSON.parse(
        JSON.stringify(getAllStaffData?.data?.data)
      );

      let convertIntomentionArray = replicateAllStaffRecords
        .map((res: any) => ({
          text: `${res?.first_name || ""} ${res?.last_name || ""}`,
          value: `${res?.first_name || ""} ${res?.last_name || ""}`,
          url: res?.id,
        }))
        .filter((res: any) => res?.url !== loggedInUserId);

      setMentionUsersList(convertIntomentionArray);
    }
  }, [getAllStaffData]);

  useEffect(() => {
    let arrayPrimaryIs: any[] = [];
    let arrayOngoingIs: any[] = [];
    if (dataObject) {
      arrayPrimaryIs = dataObject?.primary_approvers?.map(
        (res: any) => res?.id
      );
      arrayOngoingIs = dataObject?.ongoing_approvers?.map(
        (res: any) => res?.id
      );
    }
    let getTagIdsArray: any[] = [];
    if (dataObject) {
      getTagIdsArray = dataObject?.tags?.map((res: any) => res?.id) || [];
    }

    dispatch(
      setUpdateDocumentDataToStore({
        ...updateDocumentData,
        approver_primary_array: arrayPrimaryIs || [],
        approver_ongoing_array: arrayOngoingIs || [],
        tagArray: getTagIdsArray,
        hard_copy_completed_time: time.startTime,
      })
    );
  }, [updateDocumentData]);

  const dispatch = useAppDispatch();

  const { error } = useAppSelector((state) => state?.comms);

  console.log('errorerrorerrorerror', error?.payload);
  const handleSelectTaggedPeople = async (item) => {
    const selectedItem = allUserTypeData.find(
      
      (elem) =>
        elem?.first_name?.toLowerCase() === item?.first_name?.toLowerCase() &&
        elem?.last_name?.toLowerCase() === item?.last_name?.toLowerCase()
    );


    // console.log('selectedItem', selectedItem);
    if (selectedItem && Array.isArray(selectedItemsArr)) {
      
      const tempArr = [selectedItem, ...selectedItemsArr];

      setselectedItemsArr(tempArr);

      const selectedItemsId = tempArr.map((elem) => elem?.id);

      var selectedTaggedUsers = {
        link_uid: dataObject?.document_uid,
        comm_type: "DOCUMENT",
        tagged_users: selectedItemsId,
      };

 let response = await dispatch(updateTaggedUsersThunk(selectedTaggedUsers));
   
      if(response?.error?.message){
toast.warn(response?.payload)
      }
}
  };
const [ showConfirmationModal , setshowConfirmationModal] = useState(false);
  const handleCloseModal = () => {
    if(isThereAnyChanges){
      setshowConfirmationModal(true)
      return
    }
    localStorage.removeItem("SCAM");
    setstageTwoToGoalsModal(false);
    setshowDocumentForm((prev) => ({
      ...prev,
      state: false,
    }));
    setIsClickFromCross(false);
    setIsFormOpenTeams(false);
    setIsFormOpenDocContacts(false);
    dispatch(resetopenFormByClickContacts());
    dispatch(resetIsAssessmentFormOpenStaff());
    dispatch(resetOpenFormByClickStaff());
    setIsFormOpenStaff(false);
    setIsFormOpenDoc(false);
    setIsFormOpenLocation(false);
    dispatch(resetOpenFormByClickLocation());
    dispatch(setopenClientAssessmentForm(false));
    setopenForm(false);
    setopenVerticaModal(false);
    dispatch(resetIsAssessmentFormOpenProspects());
    dispatch(resetopenFormByClickProspects());
    setIsFormOpenDoc(false);
    dispatch(resetOpenFormByClickBusiness());
    dispatch(resetIsAssessmentFormOpenContacts());
    dispatch(resetOpenFormByClickBusiness());
    dispatch(resetOpenFormByClickTeams());
    setisFormOpen(false);
  };

  const closeModal = () => {
    localStorage.removeItem("SCAM");
    setstageTwoToGoalsModal(false);
    setshowDocumentForm((prev) => ({
      ...prev,
      state: false,
    }));
    setIsClickFromCross(false);
    setIsFormOpenTeams(false);
    setIsFormOpenDocContacts(false);
    dispatch(resetopenFormByClickContacts());
    dispatch(resetIsAssessmentFormOpenStaff());
    dispatch(resetOpenFormByClickStaff());
    setIsFormOpenStaff(false);
    setIsFormOpenDoc(false);
    setIsFormOpenLocation(false);
    dispatch(resetOpenFormByClickLocation());
    dispatch(setopenClientAssessmentForm(false));
    setopenForm(false);
    setopenVerticaModal(false);
    dispatch(resetIsAssessmentFormOpenProspects());
    dispatch(resetopenFormByClickProspects());
    setIsFormOpenDoc(false);
    dispatch(resetOpenFormByClickBusiness());
    dispatch(resetIsAssessmentFormOpenContacts());
    dispatch(resetOpenFormByClickBusiness());
    dispatch(resetOpenFormByClickTeams());
    setisFormOpen(false);

  }

  function formatDateNew() {
    const currDate = new Date();

    const daysOfWeek = ["Sun", "Mon", "Tues", "Wed", "Thurs", "Fri", "Sat"];
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const dayOfWeek = daysOfWeek[currDate.getDay()];
    const date = currDate.getDate();
    const month = months[currDate.getMonth()];
    const year = currDate.getFullYear();

    const formattedDate = `${dayOfWeek}, ${date} ${month} ${year}`;

    return formattedDate;
  }

  const formattedDate = formatDateNew();

  const updateDatePickerValue = () => {
    if (dateWithPeriodPicker.startDate && dateWithPeriodPicker.endDate) {
      const sMonth = dateWithPeriodPicker.startDate.toString().split(" ")[1];
      const sYear = dateWithPeriodPicker.startDate.toString().split(" ")[3];
      const eMonth = dateWithPeriodPicker.endDate.toString().split(" ")[1];
      const eYear = dateWithPeriodPicker.endDate.toString().split(" ")[3];

      const formattedValue = `${sMonth} ${sYear} to ${eMonth} ${eYear}`;

      if (
        formattedValue !==
        `${dateWithPeriodPicker.sMonth} ${dateWithPeriodPicker.sYear} to ${dateWithPeriodPicker.eMonth} ${dateWithPeriodPicker.eYear}`
      ) {
        setdateWithPeriodPicker((prevState: any) => ({
          ...prevState,
          sMonth,
          sYear,
          eMonth,
          eYear,
        }));
      }
    }
  };

  const handleStartDateChangePeriod = (dates: any) => {
    setdateWithPeriodPicker({
      ...dateWithPeriodPicker,
      startDate: dates,
    });
    setSelectedDate("");
  };
  const handleEndDateChangePeriod = (dates: any) => {
    setdateWithPeriodPicker({
      ...dateWithPeriodPicker,
      endDate: dates,
    });
    setSelectedDate("");
  };

  const onSelectTag = (id: number) => {
    if (dataObject) {
      let duplicateSelectedTagListArray = [...dataObject.tags];
      let findIndex = documentTags.findIndex((res: any) => res.id === id);
      let mealInfo: any = documentTags[findIndex];
      duplicateSelectedTagListArray.push({ ...mealInfo });
      setDataObject({ ...dataObject, tags: duplicateSelectedTagListArray });
      setUpdateDocumentDataToStore({
        ...updatedDoc,
        tagArray: duplicateSelectedTagListArray.map((res: any) => res?.id),
      });
      setIsThereAnyChanges(true);
    } else {
      let duplicateSelectedTagListArray = SelectedTagList;
      duplicateSelectedTagListArray.push(id);
      setSelectedTagList(duplicateSelectedTagListArray);
      setcreateDocumentDetails((prevDetails: any) => ({
        ...prevDetails,
        tag: duplicateSelectedTagListArray,
      }));
      setIsThereAnyChanges(true);
    }
  };

  const onUnSelectTag = (id: number) => {
    if (dataObject) {
      let duplicateSelectedTagListArray = dataObject.tags.filter(
        (res: any) => res.id !== id
      );
      setDataObject({ ...dataObject, tags: duplicateSelectedTagListArray });
      setUpdateDocumentDataToStore({
        ...updatedDoc,
        tagArray: duplicateSelectedTagListArray.map((res: any) => res?.id),
      });
      setIsThereAnyChanges(true);
    } else {
      let duplicateSelectedTagListArray = SelectedTagList;
      duplicateSelectedTagListArray = SelectedTagList.filter(
        (res) => res !== id
      );
      setSelectedTagList(duplicateSelectedTagListArray);
      setcreateDocumentDetails((prevDetails: any) => ({
        ...prevDetails,
        tag: duplicateSelectedTagListArray,
      }));
      setIsThereAnyChanges(true);
    }
  };

  const handleDateChange = (date: any) => {
    setIsThereAnyChanges(true);
    setSelectedDate(date);
    setIsPickerOpen(true);
    if (dataObject) {
      setDataObject((prevDetails: any) => ({
        ...prevDetails,
        ongoing_review: formatDateForAPI(date),
      }));
    }
  };

  const receiveArraysFromChild = (type: any, newOptions: any) => {
    let getApproverIdsInArr = newOptions?.map((res: any) => res?.id);
    setIsThereAnyChanges(true);
    if (type === "ongoingApprovers") {
      setOngoingApproverOptions(newOptions);
      // setIsThereAnyChanges(true);
      setOngoingApprover && setOngoingApprover(newOptions);
      dispatch(
        setUpdateDocumentDataToStore({
          ...updatedDoc,
          approver_ongoing_array: getApproverIdsInArr,
        })
      );
    } else if (type === "primaryApprovers") {
      setSelectedOptions(newOptions);
      setPrimaryApprover && setPrimaryApprover(newOptions);
      dispatch(
        setUpdateDocumentDataToStore({
          ...updatedDoc,
          approver_primary_array: getApproverIdsInArr,
        })
      );
    }
  };

  // console.log('selectedOptions', selectedOptions);
  const handleHardCopyDateChange = (date: any) => {
    let newDateTime = date + "," + time.startTime;
    setIsThereAnyChanges(true);
    setHardCopyDate(date);
    if (dataObject) {
      setDataObject((prevDetails: any) => ({
        ...prevDetails,
        hard_copy_completed_date: formatDateForAPI(date) + "," + time.startTime,
      }));
    }
  };

  const handleIntervalChange = (interval: string | any) => {
    setIsThereAnyChanges(true);

    if (interval === "custom") {
      setIsCustom(true);
    } else {
      setIsCustom(false);
    }
    setSelectedInterval(interval);

    if (dataObject) {
      setDataObject((prevDetails: any) => ({
        ...prevDetails,
        period_picker: interval,
      }));
    }
  };

  const handleClickOutsideOfSubjectWrapper = (event: any) => {
    if (
      textAreaRef.current &&
      !textAreaRef.current.contains(event.target) &&
      !event.target.classList.contains("subject-wrapper") &&
      !event.target.closest(".subject-wrapper") &&
      !event.target.classList.contains("focused-subject-wrapper") &&
      !event.target.closest(".focused-subject-wrapper") &&
      !event.target.classList.contains("manipulator") &&
      !event.target.closest(".manipulator") &&
      !event.target.classList.contains("focused-manipulator") &&
      !event.target.closest(".focused-manipulator") &&
      !event.target.classList.contains("focused-toolbar") &&
      !event.target.closest(".focused-toolbar") &&
      !event.target.classList.contains("rdw-editor-toolbar") &&
      !event.target.closest(".rdw-editor-toolbar") &&
      !event.target.classList.contains("editor-class") &&
      !event.target.closest(".editor-class") &&
      !event.target.classList.contains("focused-editor") &&
      !event.target.closest(".focused-editor")
    ) {
      setEditorFocus(false);
    }
  };

  const handleClickOutside = (event: any) => {
    if (
      pickerRef.current &&
      !pickerRef.current.contains(event.target) &&
      !event.target.classList.contains("react-datepicker") &&
      !event.target.closest(".react-datepicker") &&
      !event.target.classList.contains("some-other-classname") &&
      !event.target.closest(".some-other-classname") &&
      !event.target.classList.contains("select-container") &&
      !event.target.closest(".select-container")
    ) {
      setIsPickerOpen(false);
      setIsDatePickerFocused(true);
    }
  };

  const handleStateChange = () => {
    setIsHovering(false);

    setUploadDocument(2);
  };

  const handleMouseEnterIn = () => {
    setIsHovering(true);
  };

  const handleMouseLeaveOut = () => {
    setIsHovering(false);
  };

  const successToastStyle = {
    marginRight: "8px",
    borderRadius: "50%",
    width: "7rem",
    height: "35px",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: dynamicColor,
    backgroundColor: "white",
    fontSize: "1.5rem",
    border: `3px solid ${dynamicColor}`,
  };

  const showTagStyle = {
    backgroundColor: !showTag
      ? SelectedTagList?.length > 0
        ? "#b2ff9f"
        : "rgba(213, 213, 213, 0.8)"
      : "#06a5ff",
    color: !showTag && SelectedTagList?.length > 0 ? "#1a8400" : "white",
  };

  const profilePictureStyle = {
    visibility: profilePicture ? "visible" : "hidden",
    width: "60px",
    height: "60px",
    borderRadius: "50%",
    objectFit: "cover",
  };

  const crossSvgStyle = {
    position: "absolute",
    top: "-.35rem",
    left: "1.25rem",
    cursor: "pointer",
    width: "10px",
    height: "10px",
    background: "#F6F6F6",
  };

  const uploadDocStyle = {
    color: isHovering ? "#06A5FF" : "black",
    cursor: "pointer",
    transform: " translate(0px,-7px)",
  };

  const saveButtonStyle = {
    backgroundColor: dynamicColor,
    zIndex: dataObject ? 12 : 19,
  };

  const handleDeleteOnUpdate = async (id: string | any) => {
    setisModifyFromDaybook(true);
    setisModify(true);
    try {
      await api.post("/files/delete_document_file", { id: id });
      let filteredData = uploadedFiles.filter((res: any) => res.id !== id);
      setUploadedFiles(filteredData);
      setDataObject({ ...dataObject, files: filteredData });
      setIsThereAnyChanges(true);

      toast.success("File Deleted Successfully!", {
        progressStyle: { backgroundColor: dynamicColor },
        icon: <span style={successToastStyle}>✓</span>,
      });
    } catch (error) {
      toast.error("Failed To Delete File!");
    }
    setIsThereAnyChanges(true);
  };

  // delete from s3 bucket
  const handleDelete = async (name: string | any) => {
    if (triggeredFromDaybook) {
      setisModifyFromDaybook(true);
    }
    try {
      await api.post("/files/delete_file", { file: name });
      setUploadedFiles((prevState: [object] | any[]) => {
        return prevState.filter(
          (e: { [x: string]: any; file: { name: string | any } }) =>
            e?.key !== name
        );
      });
      if (dataObject) {
        let filteredData = uploadedFiles.filter((res: any) =>
          res.file ? res?.key !== name : res?.file_name !== name
        );
        setDataObject({ ...dataObject, files: filteredData });
      }

      toast.success("File Deleted Successfully!", {
        progressStyle: { backgroundColor: dynamicColor },
        icon: <span style={successToastStyle}>✓</span>,
      });
    } catch (error) {
      console.error("Error Deleting File:", error);
      toast.error("Failed To Delete File!");
    }
  };

  //  get file from s3 bucket
  const handleGetFile = async (name: string | any) => {
    try {
      const response = await api.get(`files/get_file?file=${name}`);
      const fileUrl = response?.data?.data;
      window.open(fileUrl, "_blank");
    } catch (error) {
      console.error("Error fetching file:", error);
    }
  };

  const handleFileSelection = async (event: any) => {
    setIsThereAnyChanges(true);
    const files = event.target.files;
    let newFiles = Array.from(files);
    let filesToUpload = [...selectedFiles, ...newFiles];

    const allowedTypes = [
      "application/pdf",
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.template",
    ];

    const invalidFiles = filesToUpload.filter(
      (file: any) => !allowedTypes.includes(file.type)
    );

    const totalFilesAfterUpload = filesToUpload.length + selectedFiles.length;

    if (totalFilesAfterUpload > 10) {
      toast.error("Maximum file limit exceeded. Please select up to 10 files!");
      return;
    }

    if (invalidFiles.length > 0) {
      toast.error(
        "Invalid file type(s). Only PDF, Docs, and Excel files are allowed!"
      );
      return;
    }

    if (filesToUpload.length + selectedFiles.length > 10) {
      toast.error("Maximum file limit exceeded. Please select up to 10 files!");
      return;
    }

    const maxSize = 20 * 1024 * 1024;

    if (filesToUpload.length > 10) {
      toast.error("Maximum file limit exceeded. Please select up to 10 files!");
      return;
    }

    for (let i = 0; i < filesToUpload.length; i++) {
      const file = filesToUpload[i];

      if (file.size > maxSize) {
        event.target.value = null;
        toast.error("File Size Can't Be More Than 20MB!");
        return;
      }
    }
    const uploadFiles = async (files: any) => {
      const allowedTypes = [
        "application/pdf",
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.template",
      ];

      const validFiles = files.filter((file: any) =>
        allowedTypes.includes(file.type)
      );
      const maxSize = 20 * 1024 * 1024;

      const fileToBase64 = (file: File): Promise<string> => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () => {
            const base64String = reader.result?.toString().split(",")[1];
            resolve(base64String || "");
          };
          reader.onerror = (error) => reject(error);
          reader.readAsDataURL(file);
        });
      };

      const filesData = [];

      for (let i = 0; i < validFiles.length; i++) {
        const file = validFiles[i];
        if (file.size > maxSize) {
          toast.error("File Size Can't Be More Than 20MB!");
          return;
        }

        try {
          const base64String = await fileToBase64(file);
          filesData.push({
            type: loggedInUserDetailsFromDaybook ? "daybooks" : "documents",
            extension: file.name.split(".").pop(),
            base64: base64String,
          });
        } catch (error) {
          console.error(`Error converting ${file.name} to base64:`, error);
        }
      }

      if (files.length + selectedFiles.length > 10) {
        toast.error(
          "Maximum file limit exceeded. Please select up to 10 files!"
        );
        return;
      }

      if (filesData.length > 10) {
        toast.error(
          "Maximum file limit exceeded. Please select up to 10 files!"
        );
        return;
      }
      try {
        setLoading(true);

        const response = await api.post("/files/upload_files", {
          files: filesData,
        });

        setLoading(false);
        const responseArray = response.data.data;

        // Combine selected files with their corresponding keys
        const updatedFiles = validFiles.map((file: File, index: number) => {
          return { file, key: responseArray[index] };
        });

        setUploadedFiles((prevUploadedFiles: any) => [
          ...prevUploadedFiles,
          ...updatedFiles,
        ]);

        toast.success("Upload Successfully!", {
          style: {},
          progressStyle: { backgroundColor: dynamicColor },
          icon: <span style={successToastStyle}>✓</span>,
        });
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

    await uploadFiles(filesToUpload);
  };

  const handleDrop = async (e: any) => {
    setIsThereAnyChanges(true);
    e.preventDefault();
    setDragging(false);

    let newFiles = Array.from(e.dataTransfer.files);
    let files = [...selectedFiles, ...newFiles];

    const allowedTypes = [
      "application/pdf",
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.template",
    ];

    const validFiles = files.filter((file: any) =>
      allowedTypes.includes(file.type)
    );
    const invalidFiles = files.filter(
      (file) => !allowedTypes.includes(file.type)
    );

    if (invalidFiles.length > 0) {
      toast.error(
        "Invalid file type(s). Only PDF, Docs, and Excel files are allowed!"
      );
      return;
    }

    const maxSize = 20 * 1024 * 1024;

    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      if (file.size > maxSize) {
        e.target.value = null;
        toast.error("File Size Can't Be More Than 20MB!");
        return;
      }
    }

    if (files.length + selectedFiles.length > 10) {
      toast.error("Maximum file limit exceeded. Please select up to 10 files!");
      return;
    }

    try {
      setLoading(true);

      const fileToBase64 = (file: any) => {
        return new Promise<string>((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () => {
            const base64String = reader.result?.toString().split(",")[1];
            resolve(base64String || "");
          };
          reader.onerror = (error) => reject(error);
          reader.readAsDataURL(file);
        });
      };

      const filesData = [];

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        try {
          const base64String = await fileToBase64(file);
          filesData.push({
            type: loggedInUserDetailsFromDaybook ? "daybooks" : "documents",
            extension: file.name.split(".").pop(),
            base64: base64String,
          });
        } catch (error) {
          console.error(`Error converting ${file.name} to base64:`, error);
        }
      }

      setLoading(false);

      try {
        setLoading(true);

        const response = await api.post("/files/upload_files", {
          files: filesData,
        });

        setLoading(false);
        const responseArray = response.data.data;

        const updatedFiles = validFiles.map((file: File, index: number) => {
          return { file, key: responseArray[index] };
        });

        setUploadedFiles((prevUploadedFiles: any) => [
          ...prevUploadedFiles,
          ...updatedFiles,
        ]);

        toast.success("Upload Successfully!", {
          progressStyle: { backgroundColor: dynamicColor },
          icon: <span style={successToastStyle}>✓</span>,
        });
      } catch (error) {
      } finally {
        setLoading(false);
      }

      toast.success("Upload Successfully!", {
        progressStyle: { backgroundColor: dynamicColor },
        icon: <span style={successToastStyle}>✓</span>,
      });
      setLoading(false);
      setSelectedFiles(files);
    } catch (error) {
      console.error("Error uploading files:", error);
    } finally {
      setLoading(false);
    }
  };

  const changeContent = () => {
    if (openFormClinical) {
      setUploadDocument(2);
    }
    if (createDocumentDetails.title) {
      setUploadDocument(2);
    } else {
      !openFormClinical && toast.warn("Title is Mandatory!");
    }
  };

  const changeContentTwo = () => {
    setUploadDocument(2);
  };

  const handleClickOutsideForPeriodPicker = (event: any) => {
    const dropdownForm = document.querySelector(
      ".dropdown-input-form, .input-dropdown-selector, .dropdown-input-form-two"
    );
    if (dropdownForm && !dropdownForm.contains(event.target)) {
      setisOpenTwo(false);
    }
  };

  const handleDragOver = (e: any) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = () => {
    setDragging(false);
  };

  //   let docItem = JSON.parse(localStorage.getItem("doc"));
  // console.log(docItem,'docItem')

  const handleStartDateChange = (date: any) => {
    setIsThereAnyChanges(true);
    const currentDate = new Date();
    const selectedMonth = date.getMonth();
    const selectedYear = date.getFullYear();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();

    if (
      selectedYear < currentYear ||
      (selectedYear === currentYear && selectedMonth < currentMonth)
    ) {
      return;
    }

    if (
      selectedYear === currentYear &&
      selectedMonth === currentMonth &&
      date.toDateString().split(" ")[2] <
        currentDate.toDateString().split(" ")[2]
    ) {
      return;
    } else {
      if (dataObject) {
        setDataObject((prevDetails: any) => ({
          ...prevDetails,
          primary_review: formatDateForAPI(date),
        }));
      }

      setStartDate(date);
    }

    setStartDate(date);
  };

  const handleTitleChange = (e: any) => {
    setcustomFormLinkedDocument(e.target.value);
    const newTitle = e.target.value;
    if (dataObject) {
       setIsThereAnyChanges(true);

      handleChangesToForm("yes");
      setDataObject((prevDetails: any) => ({
        ...prevDetails,
        title: newTitle,
      }));
    } else {
      setIsThereAnyChanges(true);

      setcreateDocumentDetails((prevFiles: any) => ({
        ...prevFiles,
        title: newTitle,
      }));
    }
  };

  let docId = dataObject?.id;

  const handleCreateDocumentComms = async () => {
    try {
      const res = await dispatch(
        createDocumentCommsThunk({ ...createCommsData, tenant_id: providerId })
      );
      if (res.payload.status === "success") {
        setEditorState(EditorState.createEmpty());
        toast.success("Message Sent Successfully!", {
          progressStyle: { backgroundColor: dynamicColor },
          icon: <span style={successToastStyle}>✓</span>,
        });
        setsendSubjectInEditState("");
        setsendCommsInEditState("");
        seteditorStateEditState(EditorState.createEmpty());
      }
    } catch (err) {
      console.error(err);
      // toast.error("Failed to create comms!");
    }
    dispatch(getDocuments({ clientId, providerId }));
    const resHere = await dispatch(
      getCommsOfDocumentThunk({ clientId, docId })
    );
  };

  var isThereTextInMessage = isThereMessage?.blocks[0]?.text;

  const keysArrayNewUploads = uploadedFiles?.filter(
    (elem) => elem?.key !== undefined
  );

  const newDocumentFilesArr = keysArrayNewUploads?.map((elem) => ({
    actual_file_name: elem?.file ? elem?.file.name : "",
    file_name: elem?.key,
  }));

  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  return (
    <>
    {
      !!showConfirmationModal && (
        <>
          <ConfirmationModal type="clientScreen"
          handleMoveWithoutSaving={() => {
            setIsThereAnyChanges(false)
            setshowConfirmationModal(false)
            closeModal()
          }}
          handleMoveWithSave={() => {
            // closeModal()
            setshowConfirmationModal(false)
            handleSaveClick()
          }}
          />
        </>
      )
    }
      {uploadDocument === 2 ? (
        <></>
      ) : uploadDocument === 1 ? (
        <>
          <div
            style={{
              zIndex: 99999,
              position: "absolute",
              top: "0.5%",
              left: "35%",
            }}
          >
            <ThreadsBox
            documentTagSelect={onSelectTag}
            documentTagUnselect={onUnSelectTag}
            isThereAnyChanges={isThereAnyChanges}
              color={dataObject?.traffic_light}
              isCF={customFormLinkedDocument}
              handleIntervalChange={handleIntervalChange}
              count={dataObject?.communications?.length}
              hideCommsSendButton={!dataObject?.id}
              setuploadedFiles={setUploadedFiles}
              isDocumentCreateState={isDocumentCreateState}
              completionStatus={completionStatus}
              setopenForm={setopenForm}
              onChangeTrafficLightFromProps={onChangeTrafficLight}
              selectedInterval={selectedInterval}
              setSelectedInterval={setSelectedInterval}
              openCustomForm={() => {
                if (dataObject && clientId) {
                  setUploadDocument(4);
                  dispatch(
                    getCustomFormsTabsByIdThunk({
                      custom_form_id: dataObject.custom_form_id,
                      user_id: clientId,
                      documentId: dataObject.id,
                    })
                  );

                  dispatch(setCurrentActiveFormInfo(dataObject));
                }
              }}
              keyPeople={dataObject?.key_person}
              required_key={"completion_status"}
              providerId={providerId}
              triggerGetDocumentsAPICall={triggerGetDocuments}
              showPrimaryReviewPicker={showPrimaryReviewPicker}
              handleSaveClick={handleSaveClick}
              linkUUID={dataObject?.document_uid}
              commType={"DOCUMENT"}
              setlocalStateCommsDocuments={setlocalStateCommsDocuments}
              commsDetail={localStateCommsDocuments}
              setSelectedMessage={setSelectedMessage}
              setIsThereAnyMessageOnEditor={setIsThereAnyMessageOnEditor}
              handleTitleChange={handleTitleChange}
              primaryReview={startDate}
              setShowPrimaryReviewPicker={setShowPrimaryReviewPicker}
              showHardCopyCompletedPicker={showHardCopyCompletedPicker}
              showOngoingReviewPicker={showOngoingReviewPicker}
              setShowHardCopyCompletedPicker={setShowHardCopyCompletedPicker}
              setShowOngoingReviewPicker={setShowOngoingReviewPicker}
              handleHardCopyDateChange={handleHardCopyDateChange}
              hardCopyDate={hardCopyDate}
              primaryApprovers={selectedOptions}
              ongoingApprovers={ongoingApproverOptions}
              handleStartDateChange={handleStartDateChange}
              receiveArraysFromChild={receiveArraysFromChild}
              title={
                isCustomFormCreation
                  ? customFormLinkedDocument
                  : clinicalType === "clinicalType"
                  ? "Doc-" +
                    (clinicalNoteTitle || "Clinical Care Plan and Assessment")
                  : dataObject
                  ? dataObject.title
                  : createDocumentDetails
                  ? createDocumentDetails.title
                  : ""
              }
              summary={dataObject?.summary}
              selectedDocument={dataObject}
              showLastUpdateAndSave={true}
              selectedElement={dataObject}
              underDocumentTab
              isDocument={[
                { first_label: "Primary Approver" },
                { second_label: "Ongoing Approver" },
              ]}
              incomingArrayTabs={["Go to Form >"]}
              selectedCurrentElement={dataObject}
              currentElem={dataObject}
              attachments={dataObject?.files}
              clientId={clientId}
              handleCloseModal={handleCloseModal}
              taggedPeople={selectedItemsArr}
              taggedPeopleOnOptionSelect={handleSelectTaggedPeople}
            />
          </div>
        </>
      ) : uploadDocument === 3 ? (
        <>
          <ClientCommsForm
            providerId={providerId}
            commsMessage={dataObject}
            clientData={clientData}
            setUploadDocument={setUploadDocument}
            profilePicture={profilePicture}
            setshowTag={setshowTag}
            showTag={showTag}
            documentTags={documentTags}
            SelectedTagList={SelectedTagList}
            onSelectTag={onSelectTag}
            onUnSelectTag={onUnSelectTag}
          />
        </>
      ) : uploadDocument === 4 ? (
        <VerticalModal
          title={
            clinicalType == "clinicalType"
              ? clinicalNoteTitle
              : dataObject && dataObject?.custom_form_id
              ? dataObject?.title
              : ""
          }
          setuploadDocument={setUploadDocument}
          dataObject={dataObject}
        />
      ) : null}
    </>
  );
};

export default ClientAssessmentForm;
