import React from "react";
// @ts-ignore
import { createRoot } from "react-dom/client";
import "../src/Model/Assets/Styles/App.css";
import { Provider } from "react-redux";

import { ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import { store } from "./Controller/redux/Store";
import "./Model/Assets/fonts/stylesheet.css";

const rootElement = document.getElementById("root");

// @ts-ignore
const appRoot = createRoot(rootElement);

appRoot.render(
  <React.StrictMode>
    <Router>
      <Provider store={store}>
        <ChakraProvider>
          <App />
        </ChakraProvider>
      </Provider>
    </Router>
  </React.StrictMode>
);
