import {
  AxiosDefaults,
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";
import {  createTokenCookies, getRefreshToken, getToken, removeTokenCookies } from '../../Model/utils/tokenCookies'
import { api } from './api'
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";


interface IFailedRequestQueue {
  onSuccess: (token: string) => void
  onFailure: (error: AxiosError) => void
}


let isRefreshing = false
let failedRequestQueue: IFailedRequestQueue[] = []

type SetAuthorizationHeaderParams = {
  request: AxiosDefaults | AxiosRequestConfig;
  token: string;
};

export function setAuthorizationHeader(params: SetAuthorizationHeaderParams) {
  const { request, token } = params;

  (request.headers as Record<string, unknown>)[
    "Authorization"
  ] = `Bearer ${token}`;
}


export const removeAuthorizationHeader = () => {
  delete api.defaults.headers.common["Authorization"];
};

function handleRefreshToken(refreshToken: string) {
  isRefreshing = true;

  api
    .post(
      "/refresh_token",
      { refreshToken },
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    )
    .then((response) => {
      const { token } = response.data;

      createTokenCookies({ token, refreshToken: response.data.refreshToken });
      setAuthorizationHeader({ request: api.defaults, token });

      failedRequestQueue.forEach((request) => request.onSuccess(token));
      failedRequestQueue = [];
    })
    .catch((error) => {
      failedRequestQueue.forEach((request) => request.onFailure(error));
      failedRequestQueue = [];

      removeTokenCookies();
    })
    .finally(() => {
      isRefreshing = false;
    });
}

function onRequest(config: AxiosRequestConfig) {
  const token = getToken();

  if (token) {
    setAuthorizationHeader({ request: config, token });
  }

  return config as any;
}

function onRequestError(error: AxiosError): Promise<AxiosError> {
  return Promise.reject(error);
}

function onResponse(response: AxiosResponse): AxiosResponse {
  return response;
}

function onResponseError(
  error: AxiosError
): Promise<AxiosError | AxiosResponse> {
  if (error?.response?.status === 401) {
    if (window.location.pathname !== "/login") {
      window.localStorage.clear();
      removeTokenCookies();
      window.location.replace("/login");
      // toast.error("Token Expired!");
    }
  }

  return Promise.reject(error);
}



// function onResponseError(
//   error: AxiosError
// ): Promise<AxiosError | AxiosResponse> {
//   if (error?.response?.status === 401) {
  
//       const token := localStorage.getItem("token");

//       !isRefreshing && handleRefreshToken(token);

//       return new Promise((resolve, reject) => {
//         failedRequestQueue.push({
//           onSuccess: (token: string) => {
//             setAuthorizationHeader(originalConfig, token);
//             resolve(api(originalConfig));
//           },
//           onFailure: (error: AxiosError) => {
//             reject(error);
//           },
//         });
//       });
  
//   }

//   return Promise.reject(error);
// }


export function setupInterceptors (axiosInstance: AxiosInstance): AxiosInstance {
  axiosInstance.interceptors.request.use(onRequest, onRequestError)
  axiosInstance.interceptors.response.use(onResponse, onResponseError)
  return axiosInstance
}
