import "../Styles/FundingType.css";

import React, { useEffect, useState } from "react";
import { setShowStickyNote } from "../../../../Controller/redux/features/CommonFeature/commonSlice";
import { api } from "../../../../Controller/services/api";
import { toast } from "react-toastify";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../Controller/redux/Hooks";
import _ from "lodash";
import { getBillingRuleByFilterThunk } from "../../../../Controller/redux/features/RefDataFeature/BillingRuleFeature/billingruleSlice";
import {
  getServiceTypeByFilterThunk,
  resetServiceTypeData,
} from "../../../../Controller/redux/features/RefDataFeature/ServiceTypeFeature/serviceTypeSlice";

const FundingForm = () => {
  const [IsLoading, setIsLoading] = useState(false);
  const dynamicColor = useAppSelector((state) => state?.common?.dynamicColor);
  const dispatch = useAppDispatch();
  const { fundingTypePage } = useAppSelector((state) => state?.fundingType);
  const [commonBody, setcommonBody] = useState<any>({
    name: "",
    billing_rule_set_id: "",
    travel_to_km_charged: "",
    travel_to_min_charged: "",
    travel_with_km_changes: "",
    tax_rate: "",
  });
  const [taxType, setTaxType] = useState("");
  const [taxRate, setTaxRate] = useState("");
  const { BillingRuleData } = useAppSelector((state) => state.billingRule);
  const uniqueBillingRule = BillingRuleData?.filter(
    (item: any, index: number, array: any) => {
      return array?.findIndex((obj: any) => obj.id === item.id) === index;
    }
  );
  useEffect(() => {
    dispatch(
      getBillingRuleByFilterThunk({
        filterColumn: "",
        filterValue: "",
        searchVal: "",
        sortColumn: "",
        sortType: "",
        //   status: "",
        billingRulePage: "1",
      })
    );
  }, []);
  const handleAddForm = async () => {
    setIsLoading(true);
    if (commonBody.billing_rule_set_id == "") {
      toast.error("Please link billing rule ID.");
      setIsLoading(false);
      return;
    }

    if (commonBody.name == "") {
      toast.error("Please enter value on required fileds");
      setIsLoading(false);
      return;
    }
    const url = "/service_types/create_service_type";
    let data = { ...commonBody, tax_rate: taxRate + " " + taxType };
    api
      .post(url, data)
      .then((response: any) => {
        if (response.data.status === "success") {
          setIsLoading(false);
          toast.success(`Service Type added successfully!`, {
            style: {},
            progressStyle: { backgroundColor: dynamicColor },
            icon: (
              <span
                style={{
                  marginRight: "8px",
                  borderRadius: "50%",
                  width: "7rem",
                  height: "35px",
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: dynamicColor,
                  backgroundColor: "white",
                  fontSize: "1.5rem",
                  border: `3px solid ${dynamicColor}`,
                }}
              >
                ✓
              </span>
            ),
          });
          const stickyNoteElement = document.querySelector(
            ".new_sticky_note_wrapper"
          );
          // dispatch(getFundingType());
          dispatch(resetServiceTypeData());
          dispatch(
            getServiceTypeByFilterThunk({
              filterColumn: "",
              filterValue: "",
              searchVal: "",
              sortColumn: "",
              sortType: "",
              serviceTypePage: "1",
            })
          );
          if (stickyNoteElement) {
            stickyNoteElement.classList.add("fade_out");
            setTimeout(() => {
              dispatch(setShowStickyNote(false));
            }, 300);
          }
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        toast.error(error.message);
        setIsLoading(false);
      });
  };
  // Debounced function (wait 300 milliseconds after the last click)
  const debouncedApiCall = _.debounce(handleAddForm, 300);

  // Attach the debounced function to your button click event
  useEffect(() => {
    const handleClick = () => {
      debouncedApiCall();
    };

    document?.getElementById("saveBTN")?.addEventListener("click", handleClick);

    return () => {
      document
        ?.getElementById("saveBTN")
        ?.removeEventListener("click", handleClick);
    };
  }, [debouncedApiCall]);

  return (
    <div className="payload_area">
      <div className="main_area">
        <div className="side_bar" />
        <div className="side_bar" />

        <div
          className="middle-block"
          // style={{ overflow: "scroll", height: "100%" }}
        >
          <p className="note-title">New Service</p>
          <div className="middle-section-content middle-section-others">
            <div className="field">
              <span className="field-title">
                {`Name`}
                <span className="asterixs_color"> * </span>
              </span>
              <input
                style={{
                  backgroundColor: "white",
                }}
                className="input-fields-of-sticky-note"
                placeholder={`Enter Name`}
                onChange={(e) =>
                  setcommonBody((prevState: any) => ({
                    ...prevState,
                    name: e.target.value,
                  }))
                }
                value={commonBody?.name}
              />

              <span className="field-border field-border-add-form"></span>
            </div>
            <div className="field">
              <span className="field-title">{`Billing Rule Set`}</span>
              <select
                className="field-type custom-select-2"
                onChange={(e) =>
                  setcommonBody((prevState: any) => ({
                    ...prevState,
                    billing_rule_set_id: e.target.value,
                  }))
                }
                value={commonBody.billing_rule_set_id}
                style={{
                  backgroundColor: "white",
                }}
              >
                <option value="">Please Select...</option>
                {true &&
                  uniqueBillingRule?.map((type: any, index: any) => (
                    <option key={type} value={type.id}>
                      {type.set_name}
                    </option>
                  ))}
              </select>

              <span className="field-border field-border-add-form"></span>
            </div>
            <div className="radio-container">
              <label className="label">Travel To KMs Charged</label>
              <div className="radio-group">
                <label className="radio-label" htmlFor="yes1">
                  Yes
                </label>
                <input
                  type="radio"
                  onChange={(e) =>
                    setcommonBody((prevState: any) => ({
                      ...prevState,
                      travel_to_km_charged: +e.target.value,
                    }))
                  }
                  value={1}
                  name="answer1"
                  id="yes1"
                />
              </div>
              <div className="radio-group">
                <label className="radio-label" htmlFor="no1">
                  No
                </label>
                <input
                  type="radio"
                  value={0}
                  name="answer1"
                  onChange={(e) =>
                    setcommonBody((prevState: any) => ({
                      ...prevState,
                      travel_to_km_charged: +e.target.value,
                    }))
                  }
                  id="no1"
                />
              </div>
            </div>

            <div className="radio-container">
              <label className="label">Travel To Minutes Charged</label>
              <div className="radio-group">
                <label className="radio-label" htmlFor="yes2">
                  Yes
                </label>
                <input
                  type="radio"
                  value={1}
                  onChange={(e) =>
                    setcommonBody((prevState: any) => ({
                      ...prevState,
                      travel_to_min_charged: +e.target.value,
                    }))
                  }
                  name="answer2"
                  id="yes2"
                />
              </div>
              <div className="radio-group">
                <label className="radio-label" htmlFor="no2">
                  No
                </label>
                <input
                  type="radio"
                  value={0}
                  onChange={(e) =>
                    setcommonBody((prevState: any) => ({
                      ...prevState,
                      travel_to_min_charged: +e.target.value,
                    }))
                  }
                  name="answer2"
                  id="no2"
                />
              </div>
            </div>

            <div className="radio-container">
              <label className="label">Travel with KMs Changes</label>
              <div className="radio-group">
                <label className="radio-label" htmlFor="yes3">
                  Yes
                </label>
                <input
                  type="radio"
                  value={1}
                  onChange={(e) =>
                    setcommonBody((prevState: any) => ({
                      ...prevState,
                      travel_with_km_changes: +e.target.value,
                    }))
                  }
                  name="answer3"
                  id="yes3"
                />
              </div>
              <div className="radio-group">
                <label className="radio-label" htmlFor="no3">
                  No
                </label>
                <input
                  type="radio"
                  value={0}
                  onChange={(e) =>
                    setcommonBody((prevState: any) => ({
                      ...prevState,
                      travel_with_km_changes: +e.target.value,
                    }))
                  }
                  name="answer3"
                  id="no3"
                />
              </div>
            </div>

          
            <div className="field">
              <span className="field-title">{`Tax Rate`}</span>
              <div className="service_type_rate_flex">
                <select
                  className="wrapper-info wrapper-info-select-dropdown"
                  // onChange={(e) =>
                  //   setcommonBody((prevState: any) => ({
                  //     ...prevState,
                  //     rate_type: e.target.value,
                  //   }))
                  // }
                  onChange={(e) => setTaxType(e.target.value)}
                  value={taxType}
                  style={{
                    border: "none",
                    outline: "none",
                    height: "fit-content",
                    backgroundColor: "white",
                    marginTop: "7px",
                  }}
                >
                  <option value="">Please Select...</option>
                  {["GST Free", "GST Exclusive", "GST Inclusive"]?.map(
                    (elem: any, index: any) => (
                      <option key={index} value={elem}>
                        {elem}
                      </option>
                    )
                  )}{" "}
                </select>
              </div>

              <span className="field-border field-border-add-form"></span>
            </div>
          </div>
          <div className="bottomBlock save_btn_funding">
            <button
              className="save-btn"
              id="saveBTN"
              // onClick={() => {
              //   handleAddForm();
              //   setDisibleSaveBTN(true);
              // }}
            >
              {IsLoading ? "Loading..." : "Save"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FundingForm;
