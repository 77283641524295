import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  // color,
} from "@chakra-ui/react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { HomeSVG } from "./Data";
import { useAppSelector } from "../../../Controller/redux/Hooks";
import "./Styles/Breadcrumb.css";
import {truncateString} from "../../../Model/utils/Helper/truncateString";

function BreadcrumbComponent() {
  const {
    // contacts_label,
    staff_label,
    locations_label,
    clients_label,
    business_label,
    prospects_label,
    teams_label,
  } = useAppSelector((state) => state?.common?.domainDetail);

  const { domainDetail } = useAppSelector((state) => state?.common);
  // const isAdmin = useAppSelector(
  //   (state) => state?.common?.user?.roles[0]?.desc
  // );
  // let isAdminActive = isAdmin === "Administrator";
  const { user } = useAppSelector((state) => state.common);
  const isAdminActive = user?.tenant?.id === user?.tenant?.parent_id;

  const hasHub = useAppSelector((state) => state.common.hasHub);

  var businessType = domainDetail?.businessmodel_type;
  var businessType = domainDetail?.businessmodel_type;

  const location = useLocation();
  const [breadcrumbItems, setBreadcrumbItems] = useState<any>([]);

  useEffect(() => {
    const pathParts = location.pathname
      .split("/")
      .filter((part) => part !== "");

    if (pathParts.includes("clients")) {
      setBreadcrumbItems(
        pathParts.map((part, index) => {
          const fullPath = "/" + pathParts.slice(0, index + 1).join("/");
          return {
            path: fullPath,
            label: capitalizeFirstLetter(part),
            isCurrentPage: index === pathParts.length - 1,
          };
        })
      );
    } else if (pathParts.includes("prospects")) {
      setBreadcrumbItems(
        pathParts.map((part, index) => {
          const fullPath = "/" + pathParts.slice(0, index + 1).join("/");
          return {
            path: fullPath,
            label: capitalizeFirstLetter(part),
            isCurrentPage: index === pathParts.length - 1,
          };
        })
      );
    } else if (pathParts.includes("staff")) {
      setBreadcrumbItems(
        pathParts.map((part, index) => {
          const fullPath = "/" + pathParts.slice(0, index + 1).join("/");
          return {
            path: fullPath,
            label: capitalizeFirstLetter(part),
            isCurrentPage: index === pathParts.length - 1,
          };
        })
      );
    } else if (pathParts.includes("contacts")) {
      setBreadcrumbItems(
        pathParts.map((part, index) => {
          const fullPath = "/" + pathParts.slice(0, index + 1).join("/");
          return {
            path: fullPath,
            label: capitalizeFirstLetter(part),
            isCurrentPage: index === pathParts.length - 1,
          };
        })
      );
    } else if (pathParts.includes("business")) {
      setBreadcrumbItems(
        pathParts.map((part, index) => {
          const fullPath = "/" + pathParts.slice(0, index + 1).join("/");
          return {
            path: fullPath,
            label: capitalizeFirstLetter(part),
            isCurrentPage: index === pathParts.length - 1,
          };
        })
      );
    } else if (pathParts.includes("teams")) {
      setBreadcrumbItems(
        pathParts.map((part, index) => {
          const fullPath = "/" + pathParts.slice(0, index + 1).join("/");
          return {
            path: fullPath,
            label: capitalizeFirstLetter(part),
            isCurrentPage: index === pathParts.length - 1,
          };
        })
      );
    } else if (pathParts.includes("locations")) {
      setBreadcrumbItems(
        pathParts.map((part, index) => {
          const fullPath = "/" + pathParts.slice(0, index + 1).join("/");
          return {
            path: fullPath,
            label: capitalizeFirstLetter(part),
            isCurrentPage: index === pathParts.length - 1,
          };
        })
      );
    } else if (pathParts.includes("providers")) {
      setBreadcrumbItems(
        pathParts.map((part, index) => {
          const fullPath = "/" + pathParts.slice(0, index + 1).join("/");
          return {
            path: isAdminActive ? "/people/clients" : fullPath,
            label: capitalizeFirstLetter(part),
            isCurrentPage: index === pathParts.length - 1,
          };
        })
      );
    } else if (pathParts.includes("service-type")) {
      setBreadcrumbItems(
        pathParts.map((part, index) => {
          const fullPath = "/" + pathParts.slice(0, index + 1).join("/");
          return {
            path: isAdminActive ? "/ref-data/service-type" : fullPath,
            label: capitalizeFirstLetter(part),
            isCurrentPage: index === pathParts.length - 1,
          };
        })
      );
    } else if (pathParts.includes("billing-set")) {
      setBreadcrumbItems(
        pathParts.map((part, index) => {
          const fullPath = "/" + pathParts.slice(0, index + 1).join("/");
          return {
            path: isAdminActive ? "/ref-data/billing-set" : fullPath,
            label: capitalizeFirstLetter(part),
            isCurrentPage: index === pathParts.length - 1,
          };
        })
      );
    } else if (pathParts.includes("expense-type")) {
      setBreadcrumbItems(
        pathParts.map((part, index) => {
          const fullPath = "/" + pathParts.slice(0, index + 1).join("/");
          return {
            path: isAdminActive ? "/ref-data/expense-type" : fullPath,
            label: capitalizeFirstLetter(part),
            isCurrentPage: index === pathParts.length - 1,
          };
        })
      );
    } else {
      setBreadcrumbItems([]);
    }
  }, [location.pathname]);

  if (location.pathname === "/people/client") {
    return null;
  }
  const pathName = useLocation().pathname.split("/").length >= 2;
  const pathname = useLocation()?.pathname;
  const isHome = pathname === "/";

  const navigate = useNavigate();

  if (!location.pathname.includes("login")) {
    return (
      <div
        className="breadcrumb_wrapper"
        style={{
          display: pathName && !isHome ? "flex" : "none",
        }}
      >
        <div
          className="home_icon cursor_pointer"
          onClick={() => {
            if (hasHub == "1") {
              navigate("/");
            } else {
              navigate("/my-daybook");
            }
          }}
        >
          <HomeSVG />
        </div>
        <Breadcrumb
          spacing="3px"
          separator={<ChevronRightIcon color="gray.500" />}
          position="fixed"
          top="3.75rem"
          left="7.40rem"
          zIndex={100}
          className="bread_crumb_wrapper"
        >
          {breadcrumbItems?.map((item: any, index: any) => {
            if (item.label === "People") {
              return null;
            }

            if (item.label === "Ref-data") {
              return null;
            }

            if (breadcrumbItems?.length == 4 && item?.label == "Clients") {
              return null;
            }

            if (item.isCurrentPage && location.pathname.includes("/clients/")) {
              return (
                <BreadcrumbItem
                  key={index}
                  fontSize="12px"
                  fontWeight="400"
                  fontFamily="Helvetica"
                  // width={"87px"}
                >
                  <BreadcrumbLink
                    color={"white"}
                    as={Link}
                    to={`${item.path}/clients`}
                  ></BreadcrumbLink>
                </BreadcrumbItem>
              );
            }

            return (
              <BreadcrumbItem
                height={"24px"}
                _hover={{ color: "white" }}
                padding={"0px 4px"}
                borderRadius={"3px"}
                fontSize={"12px"}
                fontWeight={"400"}
                fontFamily={"Helvetica"}
                key={index}
                style={{
                  background: item.isCurrentPage ? "white" : "#06A5FF",
                }}
              >
                {item.isCurrentPage ? null : (
                  <BreadcrumbLink
                    as={Link}
                    // className="breadcrumb_link"
                 
                    color={"white"}
                    to={item.path}
                  >
                    {item.label === "Staff" && businessType === "B2B"
                      ? "Employees"
                      : item.label === "Staff"
                      ? truncateString(staff_label,8)
                      : item.label === "Clients"
                      ? truncateString(clients_label,8)
                      : item.label === "Teams"
                      ? truncateString(teams_label,8)
                      : item.label === "Business"
                      ? truncateString(business_label,8)
                      : item.label === "Locations"
                      ? truncateString(locations_label,8)
                      : item.label === "Prospects"
                      ? truncateString(prospects_label,8)
                      : item.label === "Locations"
                      ? truncateString(locations_label,8)
                      : truncateString(item.label,8)}
                  </BreadcrumbLink>
                )}
              </BreadcrumbItem>
            );
          })}
        </Breadcrumb>
      </div>
    );
  } else {
    return null;
  }
}

function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export default BreadcrumbComponent;
