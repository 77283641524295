import React from "react";
import { EditorState, Modifier, convertToRaw } from "draft-js";
import { useEffect, useRef, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../Controller/redux/Hooks";
import {
  createCommsThunk,
  getCommsDetailThunk,
  getCommsForUserThunk,
  getCommsFromUserTypeThunk,
  resetAllCommsData,
  resetAllCommsPage,
  resetCommsDetailData,
  resetDetailCommsPage,
  updateCommsThunk,
} from "../../../../../Controller/redux/features/CommsFeature/commsSlice";
import { toast } from "react-toastify";
import { getStaffThunk } from "../../../../../Controller/redux/features/StaffFeature/staffSlice";
import {
  setIsDocumentIsOpenOnCommsSticky,
  setShowStickyNote,
} from "../../../../../Controller/redux/features/CommonFeature/commonSlice";
import UploadSVG from "../../../People/ClientPage/Components/UniqueClientScreen/Components/DocumentsTab/Data/UploadSVG";
import { ReplyIcon } from "../../../../components/CommonModal/Data/SVGs";
import { Modal, ModalOverlay } from "@chakra-ui/react";
import { api } from "../../../../../Controller/services/api";
import { getClientsThunk } from "../../../../../Controller/redux/features/ClientFeature/clientsSlice";
import { getProspectsThunk } from "../../../../../Controller/redux/features/ProspectsFeature/prospectsSlice";
import { getTeamsThunk } from "../../../../../Controller/redux/features/TeamsFeature/teamsSlice";
import { getContactsThunk } from "../../../../../Controller/redux/features/ContactsFeature/contactsSlice";
import { getBusinessThunk } from "../../../../../Controller/redux/features/BusinessFeature/businessSlice";
import { getLocationUserThunk } from "../../../../../Controller/redux/features/LocationFeature/locationSlice";
import { CrossMdSvg } from "../../../../../Model/Assets/SVG/Common/CrossSvg";
import EXCELSvg from "../../../../../Model/Data/EXCELSvg";
import GlobalDOCXSvg from "../../../../../Model/Data/DOCXSvg";
import GlobalPDFSvg from "../../../../../Model/Data/PDFSvg";
import { useLocation, useParams } from "react-router-dom";
import SuccessToast from "../../../../components/SuccessToast/SuccessToast";
import DeleteSVG from "../../../People/ClientPage/Components/UniqueClientScreen/Components/FinanceTab/Data/DeleteSVG";
import { AttachmentSVG, PictureSVG } from "../../Data/PictureSvg";
import {
  getDashboardReports,
  getReportsHub,
} from "../../../../../Controller/redux/features/ReportsHubFeature/ReportsHubSlice";
import AttachmentsModal from "../../../../components/CommonModal/Components/AttachmentsModal";

const greenBackgroundStyle = {
  backgroundColor: "#FFFE8",
  height: "209px",
};

const CreateComms = ({
  type,
  extraType,
  providerId,
  setShowAttachmentModalOpenedFromComms = () => {},
  isCommsActive,
  setlocalStateCommsDocuments = () => {},
  setFilesWhenClickedFromCommsBox = () => {},
  setisThereAnyChanges = () => {},
  isDatePickerThreadsOpen,
  setIsSubTaskOpen = () => {},
  isDocument,
  triggerGetDocumentsAPICall,
  linkUUID,
  commType,
  idDaybook,
  showAttachmentModal,
  setshowAttachmentModal = () => {},
  clientUserId,
  isDaybook,
  hideCommsSendButton,
  setKeyPeople = () => {},
  dayBookDetails,
  setDayBookDetails,
  isFromDayBook,
  setAttachments = () => {},
  setselectedItemsArr1 = () => {},
  showCreateComms,
  setShowCreateComms = () => {},
  setSelectedMessage = () => {},
  isClassname,
  setIsThereAnyMessageOnEditor = () => {},
  isReply,
  isFromDocs,
  isCreateCommsFromPlus,
  isFromTop,
  // clientId,
  clearMessage,
  isEmpty,
  isCol2,
  isCreateCommsFromPlusClickTrigger,
  setshowCreateComms,
  setIsSubTaskOpenComms = () => {},
  isFromReplyBox,
  isSubTaskOpen,
  selectedMessage,
  sendComms,
  triggerGetCommsAPICall,
}: any) => {
  const commsRef = useRef<any>();
  const dispatch = useAppDispatch();
  const { dynamicColor, user } = useAppSelector((state) => state?.common);
  const userId = user?.id;
  const { commsIdAndType, selectedCommsFolder } = useAppSelector(
    (state) => state?.comms
  );

  ///////////////////////////////////////////////////
  // @ts-ignore
  const dynamicClientId = parseInt(localStorage.getItem("dynamicClientId"));
  // @ts-ignore
  const dynamicStaffId = parseInt(localStorage.getItem("dynamicStaffId"));
  // @ts-ignore
  const dynamicProspectId = parseInt(localStorage.getItem("dynamicProspectId"));
  // @ts-ignore
  const dynamicContactId = parseInt(localStorage.getItem("dynamicContactId"));
  // @ts-ignore
  const dynamicBusinessId = parseInt(localStorage.getItem("dynamicBusinessId"));
  // @ts-ignore
  const dynamicTeamsId = parseInt(localStorage.getItem("dynamicTeamsId"));
  // @ts-ignore
  const dynamicLocationId = parseInt(localStorage.getItem("dynamicLocationId"));

  const { clientDataForDayBook } = useAppSelector((state) => state?.people);
  const { getProspectsData } = useAppSelector((state) => state?.prospects);
  const { getContactsData } = useAppSelector((state) => state?.contacts);
  const { allDataBusiness } = useAppSelector((state) => state?.business);
  const { allDataTeams } = useAppSelector((state) => state?.teams);
  const { allLocationUserData } = useAppSelector((state) => state?.location);

  const isAdminActive = user?.tenant?.id === user?.tenant?.parent_id;

  useEffect(() => {
    dispatch(getClientsThunk({ dynamicClientId: dynamicClientId }));
    dispatch(getStaffThunk({ dynamicStaffId: dynamicStaffId }));
    dispatch(getProspectsThunk({ dynamicProspectsId: dynamicProspectId }));
    dispatch(getContactsThunk({ dynamicContactId: dynamicContactId }));
    dispatch(getTeamsThunk(dynamicTeamsId));
    dispatch(getBusinessThunk(dynamicBusinessId));
    dispatch(getLocationUserThunk(dynamicLocationId));
  }, [
    dynamicStaffId,
    dynamicClientId,
    dynamicProspectId,
    dynamicContactId,
    dynamicTeamsId,
    dynamicBusinessId,
    dynamicLocationId,
  ]);
  // let prospectData = /;
  const [contactsData, setcontactsData] = useState([]);
  const [prospectsData, setprospectsData] = useState([]);
  const [businessData, setBusinessData] = useState([]);
  const [teamsData, setTeamsData] = useState([]);
  const [locationData, setLocationData] = useState([]);
  const { staffDataDayBook } = useAppSelector((state) => state?.staff);
  const { getHubReports, reportsHubList } = useAppSelector(
    (state) => state.reportsHub
  );
  const dynamicReportsHubId = localStorage.getItem("dynamicReportsHubId");

  const [clientsDataForAssignedTo, setClientsDataForAssignedTo] = useState<any>(
    []
  );

  useEffect(() => {
    if (getProspectsData) {
      setprospectsData(getProspectsData?.data?.data);
    }
  }, [getProspectsData]);
  useEffect(() => {
    if (getContactsData) {
      setcontactsData(getContactsData?.data?.data);
    }
  }, [getContactsData]);

  useEffect(() => {
    if (allDataBusiness?.data?.data) {
      setBusinessData(allDataBusiness?.data?.data);
    }
  }, [allDataBusiness]);

  useEffect(() => {
    if (allDataTeams?.data?.data) {
      setTeamsData(allDataTeams?.data?.data);
    }
  }, [allDataTeams]);

  useEffect(() => {
    if (allLocationUserData?.data?.data) {
      setLocationData(allLocationUserData?.data?.data);
    }
  }, [allLocationUserData]);

  ///
  const [clientId, setClientId] = useState<any>("");

  const pathname = useLocation()?.pathname;

  const [paramsId, setparamsId] = useState("");

  const [triggerSelf, setTriggerSelf] = useState(false);

  //
  const insertMention = (mentionText) => {
    const contentState = editorState.getCurrentContent();
    const selection = editorState.getSelection();

    // Create a new content state with the mention inserted
    const newContentState = Modifier.replaceText(
      contentState,
      selection,
      `@${mentionText}`,
      editorState.getCurrentInlineStyle(),
      null
    );

    // Update the editor state with the new content
    setEditorState(
      EditorState.push(editorState, newContentState, "insert-mention")
    );
  };
  // @ts-ignore
  const idFromParams: any = useParams()?.id;
  useEffect(() => {
    if (idFromParams) {
      let realId = window.atob(idFromParams);
      setClientId(realId);
    } else {
      setClientId(userId);
    }
  }, [idFromParams, userId]);

  useEffect(() => {
    if (
      staffDataDayBook &&
      prospectsData &&
      contactsData &&
      clientDataForDayBook &&
      businessData &&
      teamsData &&
      locationData &&
      getHubReports
    ) {
      let dataArr = [
        ...clientDataForDayBook,
        ...staffDataDayBook,
        ...prospectsData,
        ...contactsData,
        ...businessData,
        ...teamsData,
        ...locationData,
        ...getHubReports?.flatMap((item: any) =>
          item.reports.map((report: any) => ({
            ...report,
          }))
        ),
        ...reportsHubList,
      ];
      const uniqueIds = new Set();

      const filteredDataArr = dataArr.filter((elem) => {
        if (uniqueIds.has(elem.id)) {
          return false;
        }
        uniqueIds.add(elem.id);
        return true;
      });

      setClientsDataForAssignedTo(filteredDataArr);
    }
  }, [
    staffDataDayBook,
    prospectsData,
    contactsData,
    clientDataForDayBook,
    businessData,
    teamsData,
    locationData,
  ]);
  /////////////////////////////////////////////////////////////
  // console.log('commType', commType);

  let spreadObj = { ...commsIdAndType, link_id: linkUUID, comm_type: commType };

  const [uploadDoc, setUplaodDoc] = useState(false);
  const [commsMessageToSendToBackend, setcommsMessageToSendToBackend] =
    useState<any>("");
  const [editorState, setEditorState] = useState(EditorState?.createEmpty());

  const [editorFocus, setEditorFocus] = useState(false);
  useEffect(() => {
    dispatch(getStaffThunk({ dynamicStaffId }));
  }, [dynamicStaffId]);
  // const loginUserId = useAppSelector((state) => state?.common?.user?.id);
  // const { allCommsPage } = useAppSelector((state) => state?.comms);
  const [isThereAnyMessage, setIsThereAnyMessage] = useState<any>("");

  useEffect(() => {
    dispatch(getDashboardReports());
    isAdminActive && dispatch(getReportsHub({ type: dynamicReportsHubId }));
  }, []);
  useEffect(() => {
    dispatch(setIsDocumentIsOpenOnCommsSticky(uploadDoc));
  }, [uploadDoc]);

  // const [allData, setallData] = useState<any>([]);

  //  get file from s3 bucket
  const handleGetFile = async (name: string | any) => {
    try {
      const response = await api.get(`files/get_file?file=${name}`);
      const fileUrl = response?.data?.data;
      window.open(fileUrl, "_blank");
    } catch (error) {
      console.error("Error fetching file:", error);
    }
  };

  const [selectedItemsArr, setselectedItemsArr] = useState<any>([]);

  // @ts-ignore
  let currentLoggedInUserDetails = JSON.parse(localStorage.getItem("user"));
  let loggedInUserId = currentLoggedInUserDetails?.data?.id;
  let mentionData = clientsDataForAssignedTo?.filter(
    (elem: any) => elem.id !== loggedInUserId
  );

  // console.info(currentLoggedInUserDetails,"currentLoggedInUserDetails")
  let mentionDataArr: any = [];
  if (Array.isArray(mentionData)) {
    for (let item of mentionData) {
      let tempObj = {
        text: `${item.first_name || item?.report_name || ""} ${
          item.last_name || ""
        }`,
        value: item.first_name || item?.report_name,
        url: item.id,
      };
      mentionDataArr.push(tempObj);
    }
  }

  useEffect(() => {
    if (isSubTaskOpen) {
      setuploadedFiles([]);
    }
  }, [isSubTaskOpen]);
  const editorRef = useRef<any>(null);
  const [uploadedFiles, setuploadedFiles] = useState<any>([]);

  const handleEditorStateChange = (newEditorState: any) => {
    setEditorState(newEditorState);
    const contentState = newEditorState.getCurrentContent();
    const rawContentState = convertToRaw(contentState);
    const newObject = {
      entityMap: rawContentState.entityMap,
      blocks: rawContentState.blocks.map((block) => ({
        key: block.key,
        text: block.text,
        type: block.type,
        depth: block.depth,
        inlineStyleRanges: block.inlineStyleRanges,
        entityRanges: block.entityRanges,
      })),
    };
    setIsThereAnyMessage(newObject?.blocks[0]?.text);
    if (setIsThereAnyMessageOnEditor && newObject?.blocks[0]?.text) {
      setIsThereAnyMessageOnEditor(true);
    } else {
      setIsThereAnyMessageOnEditor && setIsThereAnyMessageOnEditor(false);
    }
    const contentString = JSON.stringify(newObject);
    setcommsMessageToSendToBackend(contentString);
    // setSelectedMessage(contentString)
  };

  useEffect(() => {
    if (commsMessageToSendToBackend) {
      setSelectedMessage(commsMessageToSendToBackend);
    }
  }, [commsMessageToSendToBackend]);

  useEffect(() => {
    if (!selectedMessage) {
      handleClearText();
    }
  }, [selectedMessage]);

  const handleClearText = () => {
    setcommsMessageToSendToBackend("");
    setEditorState(EditorState?.createEmpty());
  };

  const handleEditorFocus = () => {
    setEditorFocus(true);
  };
  let isPending = false;

  const handleSubmitComms = async (event: any) => {
    // setIsSubTaskOpen(-1)
 

    if (sendComms) {
      // if(linkUUID == "" || linkUUID == null || linkUUID == undefined){
      //    toast.warn("Please save to create a comm in create state.")
      //    return
      //  };

      sendComms()
        .then((res) => {
          setEditorState(EditorState?.createEmpty());
        })
        .catch((err) => {
          console.log("err", err);
        });
      setuploadedFiles([]);
      return;
    }

    setuploadedFiles([]);
    setselectedItemsArr([]);

    if(linkUUID == "" || linkUUID == null || linkUUID == undefined){
      toast.warn("Please save to send this comm.")
      return
 };

    const condition2 = isThereAnyMessage !== "";
    const condition3 = spreadObj.link_id !== "";

    const payload = {
      ...spreadObj,
      summary: "",
      message: commsMessageToSendToBackend,
      comm_tagged_users: selectedItemsArr.map((elem: any) => elem.id),
      comm_tag: [],
      comm_document_files: keysArray,
    };

    let addlPayload = {
      ...payload,
      sent_user_id: loggedInUserId,
      username:
        currentLoggedInUserDetails?.data?.first_name +
        " " +
        currentLoggedInUserDetails?.data?.last_name,
    };
    if (setlocalStateCommsDocuments) {
      setlocalStateCommsDocuments((prev) => [...prev, addlPayload]);
    }

    if (condition2 && condition3) {
      if (isPending) return;
      try {
        isPending = true;
        const response = await dispatch(updateCommsThunk(payload));
        if (response?.payload?.status === "success") {
          dispatch(resetCommsDetailData());
          dispatch(resetDetailCommsPage());
          isPending = false;
          <SuccessToast />;
          setEditorState(EditorState?.createEmpty());
          setcommsMessageToSendToBackend("");
          dispatch(
            getCommsDetailThunk({
              clientId: spreadObj?.link_id,
              type: spreadObj?.comm_type,
              userId,
              page: 1,
            })
          );

          triggerGetCommsAPICall();
        } else {
          toast.error("Comms cant be created without a mentioned user!");
        }
      } catch (error) {
        // toast.error("Failed To Send Comms!");
        isPending = false;
      }
    }
    setIsThereAnyMessage("");
    triggerGetCommsAPICall();
  };

  //// GLOBAL COMMS Sticky///
  let isPendingComms = false;

  const onSaveComms = async (e: any) => {
    setKeyPeople([]);
    setselectedItemsArr1([]);
    setAttachments([]);
    setIsSubTaskOpenComms(-1);
    setIsSubTaskOpen(-1);

    if (commsMessageToSendToBackend !== "") {
      let requiredParams = {
        summary: dayBookDetails?.summary,
        communications: [
          {
            summary: dayBookDetails?.summary,
            message: commsMessageToSendToBackend,
          },
        ],
        comm_document_files: keysArray,
      };
      if (isPendingComms) return;
      try {
        isPendingComms = true;
        const response = await dispatch(createCommsThunk(requiredParams));
        if (response?.payload?.status === "success") {
          isPendingComms = false;
          toast.success("Comms added successfully!", {
            progressStyle: { backgroundColor: dynamicColor },
            icon: <SuccessToast />,
          });

          setDayBookDetails({
            title: "",
            summary: "",
            assigned_to: "",
            tagged_user: [],
            date: "",
          });
          setEditorState(EditorState?.createEmpty());
          dispatch(setShowStickyNote(false));
          dispatch(resetAllCommsData());
          dispatch(resetAllCommsPage());
          dispatch(resetCommsDetailData());
          dispatch(resetDetailCommsPage());
          // triggerGetDocumentsAPICall
        } else {
          toast.error("Comms cant be created without a mentioned user!");
        }
      } catch (error) {
        // console.log(error);
      }
    } else {
      if (dayBookDetails?.title === "") {
        toast.warning("Title Is Mandatory!");
      } else if (commsMessageToSendToBackend === "") {
        toast.warning("Comms Is Mandatory!");
      } else {
        toast.error("Comms cant be created without a mentioned user!");
      }
    }

    if (selectedCommsFolder === "Inbox") {
      dispatch(
        getCommsForUserThunk({
          clientId,
          page: 1,
          endpoint: "get_comms",
          unread: "",
          sortType: "",
          search: "",
        })
      );
    } else if (selectedCommsFolder === "Sent") {
      dispatch(
        getCommsForUserThunk({
          clientId,
          page: 1,
          endpoint: "get_comms_of_sent_items",
          unread: "",
          sortType: "",
          search: "",
        })
      );
    } else if (selectedCommsFolder === "Clients") {
      dispatch(
        getCommsFromUserTypeThunk({
          userType: dynamicClientId,
          search: "",
          sortType: "",
        })
      );
    } else if (selectedCommsFolder === "Prospect") {
      dispatch(
        getCommsFromUserTypeThunk({
          userType: dynamicProspectId,
          search: "",
          sortType: "",
        })
      );
    }

    setshowCreateComms(false);
  };
  const handleMentionButtonClick = () => {
    if (editorRef.current) {
      const currentEditorState = editorRef.current.getEditorState();
      const selection = currentEditorState.getSelection();
      const contentState = currentEditorState.getCurrentContent();

      // Insert "@" at the current selection
      const contentStateWithAt = Modifier.insertText(
        contentState,
        selection,
        "@"
      );

      // Create a new EditorState with the updated content
      const newEditorState = EditorState.push(
        currentEditorState,
        contentStateWithAt,
        "insert-characters"
      );

      // Set the new EditorState
      setEditorState(newEditorState);

      // Focus on the editor
      editorRef?.current?.focusEditor();
    }
  };
  const handleDelete = async (name: string | any) => {
    try {
      await api.post("/files/delete_file", { file: name });
      setuploadedFiles((prevState: [object] | any[]) => {
        return prevState.filter(
          (e: { [x: string]: any; file: { name: string | any } }) =>
            e?.key !== name
        );
      });

      toast.success("File Deleted Successfully!", {
        style: {},
        progressStyle: { backgroundColor: dynamicColor },
        icon: (
          <span
            style={{
              marginRight: "8px",
              borderRadius: "50%",
              width: "7rem",
              height: "35px",
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: dynamicColor,
              backgroundColor: "white",
              fontSize: "1.5rem",
              border: `3px solid ${dynamicColor}`,
            }}
          >
            ✓
          </span>
        ),
      });
    } catch (error) {
      console.error("Error Deleting File:", error);
      toast.error("Failed To Delete File!");
    }
  };

  const [keysArray, setKeysArray] = useState<any>([]);

  useEffect(() => {
    const extractedKeys = uploadedFiles.map((elem: any) => elem?.key);
    const extractedNames = uploadedFiles.map((elem: any) => elem?.file?.name);

    const newArray = extractedKeys.map((key: any, index: number) => ({
      file_name: key,
      actual_file_name: extractedNames[index],
    }));
    setFilesWhenClickedFromCommsBox(newArray);
    setKeysArray(newArray);
  }, [uploadedFiles]);

  useEffect(() => {
    if (uploadedFiles.length >= 1) {
      setisThereAnyChanges(true);
    } else {
      setisThereAnyChanges(false);
    }
  }, [uploadedFiles]);

  // console.log("uploadedFiles", uploadedFiles);

  return (
    <>
      {/* {uploadDoc && (
        <>
          <Modal isOpen={true} onClose={() => setUplaodDoc(false)} isCentered>
            <ModalOverlay onClick={() => setUplaodDoc(false)} zIndex={10} />

            <ClientAssessmentForm
              type="new-form"
              uploadedFilesFromComms={uploadedFiles}
              setUploadDoc={setUplaodDoc}
              isViewStateInComms={true}
              handleGetDetails={() => {}}
              triggeredFromDaybook={true}
              loggedInUserDetailsFromDaybook={user}
              setuploadedFilesFromDaybook={setuploadedFiles}
            />
          </Modal>
        </>
      )} */}
      {uploadDoc && (
        <>
          <Modal isOpen={true} onClose={() => setUplaodDoc(false)} isCentered>
            <ModalOverlay onClick={() => setUplaodDoc(false)} zIndex={10} />

            <AttachmentsModal
              hideSaveButton
              setFilesWhenClickedFromCommsBox={setFilesWhenClickedFromCommsBox}
              setState={setuploadedFiles}
              extraType={extraType}
              attachments={uploadedFiles}
              setHideModal={setUplaodDoc}
            />
          </Modal>
        </>
      )}

      <div
        style={{
          position: "relative",
          width: "100%",
          top: isClassname ? "-15px" : "40px",
          height: isCol2 && !isFromDayBook ? "140px" : "120px",
          zIndex: isDatePickerThreadsOpen ? "-1" : "unset",
        }}
      >
        {isCreateCommsFromPlus && (
          <div
            style={{
              position: "absolute",
              top: "-45px",
              right: "9px",
              zIndex: 999,
            }}
          >
            <CrossMdSvg onClick={() => setshowCreateComms(false)} />
          </div>
        )}

        <div
          style={{
            display: "flex",
            position: "absolute",
            top: "-20px",
            right: "12px",
            gap: "3px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {uploadedFiles
            ?.slice(0, 13)
            .map((elem: object | any, index: number | any) => {
              return (
                <>
                  {elem?.file_name?.includes("pdf") ||
                  elem?.file?.name?.includes("pdf") ? (
                    <GlobalPDFSvg
                      size={14}
                      onClick={(
                        e: React.MouseEvent<HTMLDivElement, MouseEvent>
                      ) => {
                        e.stopPropagation();
                        handleGetFile(elem?.key);
                      }}
                      elem={elem}
                    />
                  ) : elem?.file_name?.includes("docx") ||
                    elem?.file?.name?.includes("docx") ? (
                    <GlobalDOCXSvg
                      size={14}
                      onClick={(
                        e: React.MouseEvent<HTMLDivElement, MouseEvent>
                      ) => {
                        e.stopPropagation();
                        handleGetFile(elem?.key);
                      }}
                      elem={elem}
                    />
                  ) : (
                    <>
                      <EXCELSvg
                        size={14}
                        onClick={(
                          e: React.MouseEvent<HTMLDivElement, MouseEvent>
                        ) => {
                          e.stopPropagation();
                          handleGetFile(elem?.key);
                        }}
                        elem={elem}
                      />
                    </>
                  )}
                </>
              );
            })}
          {uploadedFiles?.length >= 8 && (
            <>
              <span>...</span>
            </>
          )}
        </div>

        <div
          className={`${type === "globalComms" ? "globalComms_top" : ""} ${
            isFromDayBook ? "top_section_comms_daybook" : "top_section_comms"
          }`}
          style={{
            zIndex: isFromDocs ? -1 : 0,
          }}
        >
          <div className="service">
            <ReplyIcon />
            <label
              style={{
                width: "auto",
              }}
            >
              {" "}
              {isCol2 && !isEmpty ? "Reply" : "Message"}
            </label>
          </div>
        </div>
        <div
          ref={commsRef}
          style={{
            height: isCreateCommsFromPlusClickTrigger
              ? "120px"
              : isFromTop
              ? "114px"
              : "200px",
            top: "16px",
          }}
          className={`${
            isFromDayBook
              ? "editor_wrapper_dayBook"
              : isClassname
              ? "editor__wrapper__daybook-1"
              : isFromTop
              ? "editor_wrappr_div-1"
              : "editor_wrappr_div"
          }`}
        >
          <div className="editor_wrappr_div_section_1">
            <div
              className="sections_btn_group"
              key={"tag_section"}
              onClick={() => {
                handleMentionButtonClick();
              }}
            ></div>
          </div>
          <div className={`manipulator_comms `} style={{ display: "none" }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              style={{
                position: "absolute",
                right: "3%",
                cursor: "pointer",
                top: "3px",
              }}
              onClick={handleClearText}
              width="10"
              height="14"
              viewBox="0 0 8 10"
              fill="none"
            >
              <path
                d="M0.571429 8.88889C0.571429 9.5 1.08571 10 1.71429 10H6.28571C6.91429 10 7.42857 9.5 7.42857 8.88889V2.22222H0.571429V8.88889ZM8 0.555556H6L5.42857 0H2.57143L2 0.555556H0V1.66667H8V0.555556Z"
                fill="black"
              />
            </svg>
          </div>

          <div style={{ position: "relative" }}>
            <Editor
              ref={editorRef}
              wrapperClassName={`wrapper-class  ${
                type === "globalComms"
                  ? "globalComms"
                  : !editorFocus && "meal-builder-editor"
              }`}
              editorClassName={`${
                type === "globalComms"
                  ? "editor_class_comms_sticky"
                  : "editor_class_comms"
              }`}
              mention={{
                separator: " ",
                trigger: "@",
                suggestions: mentionDataArr,
                filter: (suggestion: any, query: any) => {
                  const fullName = suggestion?.text?.toLowerCase();
                  const searchQuery = query?.toLowerCase();
                  return fullName.includes(searchQuery);
                },
              }}
              toolbarClassName={`${
                type == "globalComms"
                  ? "toolbar_class_comms_global"
                  : "toolbar_class_comms"
              }   `}
              toolbarHidden={false}
              onFocus={handleEditorFocus}
              editorState={editorState}
              onEditorStateChange={handleEditorStateChange}
              customStyleMap={{
                greenBackgroundStyle: editorFocus ? greenBackgroundStyle : {},
              }}
              toolbar={{
                options: ["inline"],
                inline: {
                  inDropdown: false,
                  className: undefined,
                  component: undefined,
                  dropdownClassName: undefined,
                  options: ["bold", "italic", "underline"],
                },
              }}
              placeholder={isCol2 && !isEmpty ? "Reply..." : "Message..."}
            />

            <div
              className={
                type == "globalComms"
                  ? "bottom_section_links_global"
                  : "bottom_section_links"
              }
            >
              {/* <span style={{ display: !(type == "globalComms") ? "" : "none",position:"absolute", left:"130px", cursor:"pointer", zIndex: 999999999999999}} onClick={() => {}}><PictureSVG/></span> */}

              {/* move to external css file */}
              {!showAttachmentModal && (
                <>
                  <span
                    style={{
                      display: !(type == "globalComms") ? "" : "",
                      position: "absolute",
                      left: "123px",
                      cursor: "pointer",
                      zIndex: 999999999999999,
                      top: "-0.25px",
                    }}
                    onClick={() => {
                      setShowAttachmentModalOpenedFromComms(true);
                      setshowAttachmentModal(false);
                      setUplaodDoc(true);
                    }}
                  >
                    <AttachmentSVG
                    // isHighlighted={uploadedFiles?.length >=1}
                    />
                  </span>

                  <span
                    style={{
                      display: !(type == "globalComms") ? "" : "",
                      position: "absolute",
                      top: "5px",
                      left: "165px",
                      cursor: "pointer",
                      zIndex: 999999999999999,
                    }}
                    onClick={() => {
                      setEditorState(EditorState?.createEmpty());
                    }}
                  >
                    <DeleteSVG width="24" height="14" />
                  </span>
                </>
              )}

              {type === "globalComms" ? (
                <button
                  style={{
                    backgroundColor: "#2E80FE",
                    display: isFromDayBook ? "none" : "",
                    zIndex: uploadDoc ? -1 : 9999,
                  }}
                  className="comms_send_btn"
                  onClick={onSaveComms}
                >
                  Send
                </button>
              ) : (
                <button
                  style={{
                    backgroundColor: "#2E80FE",
                    display: isFromDayBook ? "none" : "",
                    zIndex: uploadDoc ? -1 : 8,
                  }}
                  className="comms_send_btn"
                  onClick={handleSubmitComms}
                >
                  Send
                </button>
              )}
            </div>
            <div className="bottom_btn_wrapper">
              <div
                className="upload_icon_comms"
                style={{
                  display: isFromDayBook || uploadDoc ? "none" : "none",
                }}
                onClick={() => setUplaodDoc(true)}
              >
                <span className="upload_icon">
                  <UploadSVG />
                </span>

                <span className="see_all" onClick={() => setUplaodDoc(true)}>
                  {uploadedFiles.length >= 3 ? "See All" : " "}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateComms;
