import "../Styles/ProviderScreen.css";
import { toast } from "react-toastify";
import React, { useEffect, useState } from "react";
import CommonUpperBox from "../../../components/UpperBox/CommonHeader";
import { dummyArrProviders } from "../Data/ProvidersTableData";
import CommonQuickMenu from "../../../components/CommonQuickMenu/CommonQuickMenu";
import CommonHeader from "../../../components/CommonHeader/CommonHeader";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../Controller/redux/Hooks";
import { useLocation, useParams } from "react-router-dom";
import {
  clearCurrentProviderData,
  getProvidersByFilterThunk,
  getUniqueProviderThunk,
  resetProvidersData,
  updateProviderThunk,
} from "../../../../Controller/redux/features/ProviderFeature/providerSlice";
import { Radio, RadioGroup, Spinner } from "@chakra-ui/react";
import { setunsavedChanges } from "../../../../Controller/redux/features/AuthFeature/authSlice";
import CircularNoteTrigger from "../../../components/CircularNoteTrigger/CircularNoteTrigger";
import { itemArrBusiness } from "../../People/ClientPage/Components/UniqueClientScreen/Data/WidgetDataArr";
import ProviderProspect from "./ProviderProspect";
import ProviderClient from "./ProviderClient";
import ProviderBusiness from "./ProviderBusiness";
import ProviderLocations from "./ProviderLocations";
import ProviderTeams from "./ProviderTeams";
import ProviderContacts from "./ProviderContacts";
import ProviderStaff from "./ProviderStaff";
import { setProfileTypeSticky } from "../../../../Controller/redux/features/CommonFeature/commonSlice";
import Audit from "../../Audit/Audit";
import Table from "../../../components/CommonTable/Table";
import { Comms } from "../../Comms/Comms";
import DayBook from "../../Daybook/Daybook";
import VendorAccess from "../../../components/Vendors/VendorAccess";
import ReportsHubAccess from "../../../components/ReportsHub/ReportsHubAccess";

const ProvidersScreen = () => {
  const dispatch = useAppDispatch();
  const id = useParams()?.id;
  const [providerId, setProviderId] = useState<any>("");
  const [loading, setLoading] = useState(true);
  const [value, setValue] = React.useState("1");

  const pathname = useLocation().pathname;
  useEffect(() => {
    if (pathname.includes("staff")) {
      handleClick("Staff");
    }
  }, [pathname]);

  useEffect(() => {
    if (id) {
      let realId = window.atob(id);
      setProviderId(realId);
    }
  }, [id]);

  useEffect(() => {
    if (providerId) {
      dispatch(clearCurrentProviderData());

      dispatch(getUniqueProviderThunk({ id: providerId }))
        .then(() => {
          setLoading(false);
        })
        .catch((error: any) => {
          // console.log(error);
          setLoading(false);
        });
    }
  }, [providerId]);

  const [currentProvider, setcurrentProvider] = useState<any>(null);

  const dynamicColor = useAppSelector((state) => state?.common?.dynamicColor);
  const getCurrentProvider = useAppSelector(
    (state) => state?.providers?.getUniqueProvider
  );
  const { providersPage } = useAppSelector((state) => state?.providers);

  useEffect(() => {
    if (getCurrentProvider?.data?.data.length > 0) {
      setcurrentProvider(getCurrentProvider?.data?.data[0]);
    } else {
      setcurrentProvider(null);
    }
  }, [getCurrentProvider, providerId]);

  const [stageTwoToGoalsModal, setStageTwoToGoalsModal] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  const [details, setDetails] = useState<any>({
    provider_name: "",
    provider_token: "",
    conpago_business_id: "",
    has_import: "",
    api_base_url: "",
    provider_type: "",
  });

  const [updateProvider, setUpdateProvider] = useState<any>({
    provider_id: providerId,
    provider_name: "",
    provider_token: "",
    conpago_business_id: "",
    import_data_in_our_system: "",
    api_base_url: "",
    provider_type: "",
  });

  useEffect(() => {
    const fetchedData = currentProvider;
    setDetails({
      provider_name: fetchedData?.tenant_name || "",
      provider_token: fetchedData?.provider_token,
      business_id: fetchedData?.conpago_business_id,
      has_import: fetchedData?.has_import_from_eziplan,
      api_base_url: fetchedData?.api_base_url,
      provider_type: fetchedData?.tenant_type || "",
    });
  }, [currentProvider]);
  // // console.log(details)
  useEffect(() => {
    setUpdateProvider({
      provider_id: providerId,
      provider_name: details?.provider_name || "",
      provider_token: details?.provider_token,
      business_id: details?.business_id,
      import_data_in_our_system: value,
      api_base_url: details?.api_base_url,
      provider_type: details?.provider_type,
    });
  }, [providerId, details, value]);

  const [buttonTab, setButtonTab] = useState({
    Details: true,
    Prospects: false,
    Clients: false,
    Business: false,
    Locations: false,
    Teams: false,
    Contacts: false,
    Staff: false,
    Audit: false,
    Associations: false,
    Clinical: false,
    Comms: false,
    Documents: false,
    Finance: false,
    Daybook: false,
    "Vendor Access": false,
    "Report Access": false,
  });

  useEffect(() => {
    if (pathname.includes("staff")) {
      setButtonTab({
        Details: false,
        Prospects: false,
        Clients: false,
        Business: false,
        Locations: false,
        Teams: false,
        Contacts: false,
        Staff: true,
        Audit: false,
        Associations: false,
        Clinical: false,
        Comms: false,
        Documents: false,
        Finance: false,
        Daybook: false,
        "Vendor Access": false,
        "Report Access": false,
      });
    }
  }, [buttonTab]);
  // CHANGE TO INPUT FIELDS
  const handleChange = (
    index: number,
    e: React.ChangeEvent<HTMLInputElement> | any
  ) => {
    const { value } = e.target;

    const fieldName = Object.keys(details)[index];

    setDetails((prevState: any) => ({
      ...prevState,
      [fieldName]: value,
    }));
    dispatch(setunsavedChanges(true));

    if (!hasUnsavedChanges) {
      setHasUnsavedChanges(true);
    }
  };

  const handleSaveChanges = async () => {
    setHasUnsavedChanges(false);
    dispatch(setunsavedChanges(false));
    try {
      const res = await dispatch(updateProviderThunk(updateProvider));
      dispatch(getUniqueProviderThunk({ id: currentProvider.id }));
      if (res.payload.status === "success") {
        toast.success("Clients updated successfully!", {
          // style: {},
          progressStyle: { backgroundColor: dynamicColor },
          icon: (
            <span
              style={{
                marginRight: "8px",
                borderRadius: "50%",
                width: "7rem",
                height: "35px",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: dynamicColor,
                backgroundColor: "white",
                fontSize: "1.5rem",
                border: `3px solid ${dynamicColor}`,
              }}
            >
              ✓
            </span>
          ),
        });
      } else {
        toast.error("An Error Occurred");
      }
    } catch (error) {
      console.error(error);
      toast.error("An Error Occurred");
    }
    // dispatch(getProvidersThunk());
    dispatch(resetProvidersData());
    dispatch(
      getProvidersByFilterThunk({
        status: 1,
        filterColumn: "",
        filterValue: "",
        searchVal: "",
        sortType: "",
        sortColumn: "",
        providersPage,
      })
    );
  };

  const { domainDetail, user } = useAppSelector((state) => state?.common);

  const handleClick = (button: string) => {
    const defaultTabState = {
      Details: false,
      Prospects: false,
      Clients: false,
      Business: false,
      Locations: false,
      Teams: false,
      Contacts: false,
      Staff: false,
      Audit: false,
      Associations: false,
      Clinical: false,
      Comms: false,
      Documents: false,
      Finance: false,
      Daybook: false,
      "Vendor Access": false,
      "Report Access": false,
    };
    const newTabState = { ...defaultTabState, [button]: true };
    localStorage.setItem("activeTab", button);
    setButtonTab(newTabState);
  };

  useEffect(() => {
    if (localStorage.getItem("activeTab") === "Details") {
      handleClick("Details");
    } else if (localStorage.getItem("activeTab") === "Prospects") {
      handleClick("Prospects");
    } else if (localStorage.getItem("activeTab") === "Clients") {
      handleClick("Clients");
    } else if (localStorage.getItem("activeTab") === "Business") {
      handleClick("Business");
    } else if (localStorage.getItem("activeTab") === "Locations") {
      handleClick("Locations");
    } else if (localStorage.getItem("activeTab") === "Teams") {
      handleClick("Teams");
    } else if (localStorage.getItem("activeTab") === "Contacts") {
      handleClick("Contacts");
    } else if (localStorage.getItem("activeTab") === "Staffs") {
      handleClick("Staffs");
    } else if (localStorage.getItem("activeTab") === "Audit") {
      handleClick("Audit");
    } else if (localStorage.getItem("activeTab") === "Associations") {
      handleClick("Associations");
    } else if (localStorage.getItem("activeTab") === "Vendor Access") {
      handleClick("Vendor Access");
    } else if (localStorage.getItem("activeTab") === "Report Access") {
      handleClick("Report Access");
    } else {
      handleClick("Details");
    }
  }, []);

  const {
    prospects_label,
    profile_name,
    clients_label,
    business_label,
    locations_label,
    teams_label,
    staff_label,
    contacts_label,
  } = useAppSelector((state) => state?.common?.domainDetail);

  useEffect(() => {
    buttonTab?.Prospects && dispatch(setProfileTypeSticky(prospects_label));
    buttonTab?.Clients && dispatch(setProfileTypeSticky(clients_label));
    buttonTab?.Business && dispatch(setProfileTypeSticky(business_label));
    buttonTab?.Locations && dispatch(setProfileTypeSticky(locations_label));
    buttonTab?.Teams && dispatch(setProfileTypeSticky(teams_label));
    buttonTab?.Staff && dispatch(setProfileTypeSticky(staff_label));
    buttonTab?.Contacts && dispatch(setProfileTypeSticky(contacts_label));
  }, [
    prospects_label,
    clients_label,
    business_label,
    buttonTab,
    locations_label,
    teams_label,
    staff_label,
    contacts_label,
  ]);

  return (
    <section>
      {loading && <Spinner position={"absolute"} top={"50%"} left={"50%"} />}
      <CommonUpperBox
        type="screen"
        currentUser={currentProvider}
        imageURL={currentProvider?.profile_image}
        onClickPlus={() => setStageTwoToGoalsModal(true)}
        title={`${currentProvider?.tenant_name || ""}`}
        isProvider={true}
      />
      {buttonTab.Details && (
        <>
          <CommonHeader
            mainType="providers"
            type="details"
            handleClick={handleClick}
            currentProvider={currentProvider}
            itemsArr={itemArrBusiness}
          />
          <div className="padding_left_10px">
            <div className=" client-screen-personal-details-heading">
              <span className=" client-screen-sub-heading-title">
                Provider Details
              </span>
              <span className=" client-screen-line"></span>
            </div>
            <div className="main-box-body">
              {dummyArrProviders?.slice(0, 6)?.map((elem: any, index: any) => {
                const fieldName = Object.keys(details)[index];
                return (
                  <div className="content-wrapper" key={index}>
                    <div className="wrapper-title">{elem.UpperTitle}</div>
                    {index === 3 ? (
                      <RadioGroup
                        onChange={(val) => {
                          setValue(val);
                          dispatch(setunsavedChanges(true));
                          setHasUnsavedChanges(true);
                        }}
                        value={value}
                      >
                        <div className="radio_buttons_flex">
                          <Radio value="1">Import Data</Radio>
                          <Radio value="0">Do Not Import Data</Radio>
                        </div>
                      </RadioGroup>
                    ) : index === 4 ? (
                      <select
                        name="select_url"
                        id="select_url"
                        value={details[fieldName]}
                        onChange={(e) => {
                          setHasUnsavedChanges(true);
                          dispatch(setunsavedChanges(true));
                          setDetails((prevState: any) => ({
                            ...prevState,
                            api_base_url: e.target.value,
                          }));
                        }}
                      >
                        {" "}
                        <option value="">Please Select...</option>
                        <option value="https://app.eziplan.com.au%2Cturnpoint/">
                          Eziplan
                        </option>
                        <option value="https://tp1.com.au/">Turnpoint</option>
                      </select>
                    ) : index === 5 ? (
                      <select
                        name="select_url"
                        id="select_url"
                        value={details[fieldName]}
                        onChange={(e) => {
                          setHasUnsavedChanges(true);
                          dispatch(setunsavedChanges(true));
                          setDetails((prevState: any) => ({
                            ...prevState,
                            provider_type: e.target.value,
                          }));
                        }}
                      >
                        <option value="">Please Select...</option>
                        <option value="Home Care"> Home Care</option>
                        <option value="Residential Aged Care">
                          Residential Aged Care
                        </option>
                        <option value="SIL">SIL</option>
                        <option value="Village">Village</option>
                      </select>
                    ) : (
                      <input
                        className="wrapper-info"
                        onChange={(e) => handleChange(index, e)}
                        value={details[fieldName]}
                        placeholder={elem?.placeHolder}
                        style={{
                          border: "none",
                          outline: "none",
                          height: "fit-content",
                        }}
                      />
                    )}

                    <div
                      className={`wrapper-line
                    
                    ${index === 3 ? "margintop" : "d_block"}
                    `}
                    ></div>
                  </div>
                );
              })}
              {hasUnsavedChanges && (
                <div
                  className="save"
                  style={{
                    position: "absolute",
                    marginRight: "200px",
                    bottom: "10px",
                  }}
                >
                  <p>Would you like to save your changes?</p>
                  <div className="btnDivision">
                    <button
                      onClick={handleSaveChanges}
                      style={{ backgroundColor: dynamicColor }}
                    >
                      Save
                    </button>
                    <button
                      style={{
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "18px",
                        backgroundColor: "#F9F9F9",
                        border: "none",
                        color: "#9296A2",
                      }}
                      onClick={() => setHasUnsavedChanges(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
          {hasUnsavedChanges && (
            <>
              <div
                className="save"
                style={{
                  position: "absolute",
                  marginRight: "200px",
                  bottom: "10px",
                }}
              >
                <p>Would you like to save your changes?</p>
                <div className="btnDivision">
                  <button
                    onClick={handleSaveChanges}
                    style={{ backgroundColor: dynamicColor }}
                  >
                    Save
                  </button>
                  <button
                    style={{
                      fontStyle: "normal",
                      fontWeight: "400",
                      fontSize: "16px",
                      lineHeight: "18px",
                      backgroundColor: "#F9F9F9",
                      border: "none",
                      color: "#9296A2",
                    }}
                    onClick={() => setHasUnsavedChanges(false)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </>
          )}
          <CircularNoteTrigger type="stickyHub" />
        </>
      )}
      {stageTwoToGoalsModal && (
        <CommonQuickMenu
          setshowGoalsModal={(value: any) => {}}
          mainType={"listing"}
          setstageTwoToGoalsModal={setStageTwoToGoalsModal}
        />
      )}
      {buttonTab.Associations && (
        <>
          <CommonHeader
            currentProspects={null}
            mainType="providers"
            type="associations"
            handleClick={handleClick}
          />
          {/* <UnderWork /> */}
          {/* <CommonTable type="associations" /> */}
          <Table
            dynamicHeaderColor={domainDetail?.assoctbl_color}
            type="asociations"
            classNameTable="common-table-container-associations"
            thData={[]}
            classNameTr="client-associations-tab-table-head"
            dropdownRef={null}
            onClickSvg={() => {}}
            isDropdownOpen={false}
            svgParentRef={null}
            allClients={[]}
            classNameRow="client-associations-tab-table-body"
            fill={"#D8D3D3"}
          />

          <CircularNoteTrigger type="associations" title="Association" />
          {/* <Rectangle type="associations" /> */}
        </>
      )}
      {buttonTab.Comms && (
        <>
          <CommonHeader
            currentProspects={null}
            mainType="providers"
            type="comms"
            handleClick={handleClick}
          />
          {/* <div className="comms_client_section_wrapper">
            <Comms type="prospects" />
          </div> */}

          {/* <UnderWork /> */}
          <div style={{ marginTop: "10px" }}>
            <Comms isUserType={true} />
          </div>
          {/* <Rectangle type="stickyHub" /> */}
          {/* <CircularNoteTrigger type="stickyHub" /> */}
          <div></div>
        </>
      )}
      {buttonTab.Daybook && (
        <>
          <CommonHeader
            currentProspects={null}
            mainType="providers"
            type="daybook"
            handleClick={handleClick}
          />
          <DayBook type="prospects" />
          {/* <UnderWork /> */}
          <div></div>
        </>
      )}
      {buttonTab.Documents && (
        <>
          <div className={true ? "" : ""}>
            <CommonHeader
              mainType="providers"
              type="documents"
              handleClick={handleClick}
              currentUser={null}
              itemsArr={[]}
            />

            <Table
              dynamicHeaderColor={domainDetail?.doctbl_color}
              thData={[]}
              classNameTr="client-documents-tab-table-head"
              classNameTable="documents-table-additional-styles"
              type="documents"
              dropdownRef={null}
              isDropdownOpen={false}
              onClickSvg={() => {}}
              svgParentRef={null}
              allClients={[]}
              // onClickRow={handleClickDocRow.bind(this, "onRow")}
              onClickRow={() => {}}
              classNameRow="client-documents-tab-table-body"
              fill={"#D8D3D3"}
            />

            <CircularNoteTrigger type="stickyHub" />
            {/* <Rectangle type="stickyHub" /> */}
          </div>
        </>
      )}
      {buttonTab.Finance && (
        <>
          <CommonHeader
            currentProspects={null}
            mainType="providers"
            type="finance"
            handleClick={handleClick}
          />
          <div className="widget_finance_container">
            {/* DRY RUN @ADNAN */}
            <div className="finance_component_wrapper">
              {/* {FinanseBudgestStatementArr.map((res: any, index: number) => {
                  return <BudgetStatement type={res.type} recordInfo={res} />;
                })} */}
            </div>
          </div>

          {/* <CommonTable type="finance" /> */}
          <div className="finance_table_wrapper">
            <Table
              dynamicHeaderColor={domainDetail?.fintbl_color}
              thData={[]}
              allClients={[]}
              classNameTable="common-table-container finance_table"
              classNameTr="finance_table_head"
              classNameBody="finance_model_body"
              classNameRow="normal-table-row normal-class-table-row-clients-table"
              onClickRow={() => {}}
              fill={"#D8D3D3"}
            />
          </div>

          {/* <Rectangle type="stickyHub" /> */}
          <CircularNoteTrigger type="stickyHub" />
        </>
      )}
      {buttonTab.Prospects && (
        <>
          <CommonHeader
            mainType="providers"
            type="prospects"
            handleClick={handleClick}
            currentProvider={currentProvider}
            itemsArr={itemArrBusiness}
          />
          <ProviderProspect />

          <CircularNoteTrigger
            type={prospects_label}
            //  title={prospects_label}
            title={profile_name}
          />
          {/* <CircularNoteTrigger type="stickyHub" /> */}
        </>
      )}
      {buttonTab.Business && (
        <>
          <CommonHeader
            mainType="providers"
            type="business"
            handleClick={handleClick}
            currentProvider={currentProvider}
            itemsArr={itemArrBusiness}
          />
          <ProviderBusiness />
          <CircularNoteTrigger
            type={business_label}
            //  title={business_label}
            title={profile_name}
          />
        </>
      )}
      {buttonTab.Clients && (
        <>
          <CommonHeader
            mainType="providers"
            type="clients"
            handleClick={handleClick}
            currentProvider={currentProvider}
            itemsArr={itemArrBusiness}
          />
          <ProviderClient />

          <CircularNoteTrigger
            type={clients_label}
            // title={clients_label}
            title={profile_name}
          />

          {/* <CircularNoteTrigger type="stickyHub" /> */}
        </>
      )}
      {buttonTab.Contacts && (
        <>
          <CommonHeader
            mainType="providers"
            type="contacts"
            handleClick={handleClick}
            currentProvider={currentProvider}
            itemsArr={itemArrBusiness}
          />
          <ProviderContacts />
          <CircularNoteTrigger type={contacts_label} title={profile_name} />
        </>
      )}
      {buttonTab.Locations && (
        <>
          <CommonHeader
            mainType="providers"
            type="locations"
            handleClick={handleClick}
            currentProvider={currentProvider}
            itemsArr={itemArrBusiness}
          />
          <ProviderLocations />
          <CircularNoteTrigger type={locations_label} title={profile_name} />
        </>
      )}
      {buttonTab.Staff && (
        <>
          <CommonHeader
            mainType="providers"
            type="staff"
            handleClick={handleClick}
            currentProvider={currentProvider}
            itemsArr={itemArrBusiness}
          />
          <ProviderStaff />
          <CircularNoteTrigger type={staff_label} title={profile_name} />
        </>
      )}{" "}
      {buttonTab.Teams && (
        <>
          <CommonHeader
            mainType="providers"
            type="teams"
            handleClick={handleClick}
            currentProvider={currentProvider}
            itemsArr={itemArrBusiness}
          />
          <ProviderTeams />
          <CircularNoteTrigger
            type={teams_label}
            //  title={teams_label}
            title={profile_name}
          />
        </>
      )}
      {buttonTab.Audit && (
        <>
          <CommonHeader
            mainType="providers"
            type="audit"
            handleClick={handleClick}
            currentProvider={currentProvider}
            itemsArr={itemArrBusiness}
          />
          <div style={{ marginTop: "20px" }}>
            <Audit
              currentProvider={currentProvider}
              isFromProvidersAudit={true}
            />
          </div>
          <CircularNoteTrigger
            type={teams_label}
            //  title={teams_label}
            title={profile_name}
          />
        </>
      )}
      {buttonTab["Vendor Access"] && (
        <>
          <CommonHeader
            mainType="providers"
            type="Vendor Access"
            handleClick={handleClick}
            currentProvider={currentProvider}
            itemsArr={itemArrBusiness}
          />
          <VendorAccess />
        </>
      )}
      {buttonTab["Report Access"] && (
        <>
          <CommonHeader
            mainType="providers"
            type="Report Access"
            handleClick={handleClick}
            currentProvider={currentProvider}
            itemsArr={itemArrBusiness}
          />
          <ReportsHubAccess />
        </>
      )}
    </section>
  );
};

export default ProvidersScreen;
