
const SuccessToastMessage = ({ dynamicColor} : string | any) => {
  return (
    <div>
         <span
          style={{
            marginRight: "8px",
            borderRadius: "50%",
            width: "2rem",
            height: "35px",
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: dynamicColor,
            backgroundColor: "white",
            fontSize: "1.5rem",
            border: `3px solid ${dynamicColor}`,
          }}
        >
          ✓
        </span>
    </div>
  )
}

export default SuccessToastMessage
