import React from 'react';
import SelectedSortDropdown from './SelectedSortDropdown';
import DropdownOptions from './DropdownOptions';
import SelectedFilterDropdown from './SelectedFilterDropdown';
import SelectedFilterOption from './SelectedFilterOption';

const Dropdown = ({
  selectedItem,
  data,
  ref,
  onFocus,
  inputTextValue,
  onChange,
  clearSearch,
  onClick,
  selectedType,
  selectedFilterDropDownOne,
  tableObject,
  selectedItemIndex,
  options,
  clickType,
  handleClickColumn,
  selectedOps,
  allOps,
  setselectedOps,
  dispatch,
  clientId,
  pathname,
  currElem,
  handleChangeCheckboxOption,
  selectedSortDropDownOne,
  currentActive,
  handleOpenSortOptionsOnBasisOfIndexOfColumn,
  props,
  renderSelectedSortOptions,
  tableHeadArr,
  type,
}: any) => {
  return (
    <div
      className={`dropdown-wrapper-main ${
        type === "providers" ? "providers_dropdown" : ""
      }`}
    >
      <div className="parent-dropdown">
        <div className="dropdown-2-box">
          <div className="dropdown-2">
            <DropdownOptions
              selectedItem={selectedItem}
              data={data}
              ref={ref}
              onFocus={onFocus}
              inputTextValue={inputTextValue}
              onChange={onChange}
              clearSearch={clearSearch}
              onClick={onClick}
              selectedType={selectedType}
            />
          </div>

          {/* FILTER OPTIONS */}
          {!!selectedFilterDropDownOne &&
            selectedFilterDropDownOne?.id === 1 && (
              <SelectedFilterDropdown
                options={options}
                tableObject={tableObject}
                selectedItemIndex={selectedItemIndex}
                clickType={clickType}
                handleClickColumn={handleClickColumn}
              />
            )}

          {/* Filter Options Selection */}
          {selectedFilterDropDownOne?.id === 1 && (
            <SelectedFilterOption
              selectedOps={selectedOps}
              allOps={allOps}
              setselectedOps={setselectedOps}
              dispatch={dispatch}
              clientId={clientId}
              pathname={pathname}
              currElem={currElem}
              handleChangeCheckboxOption={handleChangeCheckboxOption}
            />
          )}

          {/* SORT OPTIONS */}
          {!!selectedSortDropDownOne && currentActive === "sort" && (
            <SelectedSortDropdown
              options={tableHeadArr}
              tableObject={tableObject}
              selectedItemIndex={selectedItemIndex}
              handeChangeSortOptions={
                handleOpenSortOptionsOnBasisOfIndexOfColumn
              }
              props={props}
            />
          )}

          {/* SORT OPTIONS ITEMS */}
          {renderSelectedSortOptions(0)}
          {renderSelectedSortOptions(1)}
          {renderSelectedSortOptions(2)}
        </div>
      </div>
    </div>
  );
};

export default Dropdown;
