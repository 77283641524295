import { Checkbox } from "@chakra-ui/react";

export const tableHeadArr = [
  "Contact Name",
  "Contact Category",
  "Relationship",
  "Contact Number",
  "Associated Business/Site",
  "",
];

export const tableHeadArrAssocaitions = [
  " Name",
  " Category",
  "Relationship",
  "Contact Number",
  "Associated Business/Site",
  "Action",
];

export const tableHeadArrDoc = [
  "Document",
  "Review Date",
  "Primary Approver",
  "Complete",
  "Attachments",
  // "Status",
  "Action",
];

export const associationColumns = [
  { header: "Name", accessor: "user_name" },
  { header: "Category", accessor: "category" },
  { header: "Relationship", accessor: "relationship_name" },
  { header: "Contact Number", accessor: "number" },
  { header: "Associated Business/Site", accessor: "funding_type" },
  { header: "Action", accessor: "actionAssociation" },
];

export const documentsColumns = [
  { header: "check", accessor: "completed_status" },

  { header: "Document", accessor: "documenttitle" },
  { header: "Due Date", accessor: "primary_review" },
  { header: "Reviewers", accessor: "primary_approvers" },

  { header: "Complete", accessor: "complete" },
  { header: "Attachments", accessor: "files" },
  { header: "Action", accessor: "actionDocument" },
];
