import { combineReducers, configureStore } from "@reduxjs/toolkit";
import clientsSlice from "./features/ClientFeature/clientsSlice";
import associationSlice from "./features/CommonFeature/associationSlice";
import detailsSlice from "./features/ClientFeature/detailsSlice";
import staffSlice from "./features/StaffFeature/staffSlice";
import documentSlice from "./features/CommonFeature/documentSlice";
import contactsSlice from "./features/ContactsFeature/contactsSlice";
import prospectsSlice from "./features/ProspectsFeature/prospectsSlice";
import commonSlice from "./features/CommonFeature/commonSlice";
import authSlice from "./features/AuthFeature/authSlice";
import auditSlice from "./features/AuditFeature/auditSlice";
import daybookSlice from "./features/DaybookFeature/daybookSlice";
import mealbuilderSlice from "./features/MealsFeatures/MealFeature/mealbuilderSlice";
import menuBuilderSlice from "./features/MealsFeatures/MenuFeature/menuBuilderSlice";
import menuAssignmentSlice from "./features/MealsFeatures/MenuAssignmentFeature/menuAssignmentSlice";
import documentTeamsSlice from "./features/TeamsFeature/documentTeamsSlice";
import teamsSlice from "./features/TeamsFeature/teamsSlice";
import businessSlice from "./features/BusinessFeature/businessSlice";
import documentClientSlice from "./features/ClientFeature/documentClientSlice";
import documentStaffSlice from "./features/StaffFeature/documentStaffSlice";
import documentContactSlice from "./features/ContactsFeature/documentContactSlice";
import documentProspectsSlice from "./features/ProspectsFeature/documentProspectsSlice";
import associationStaffSlice from "./features/StaffFeature/associationStaffSlice";
import associationProspectsSlice from "./features/ProspectsFeature/associationProspectsSlice";
import documentBusinessSlice from "./features/BusinessFeature/documentBusinessSlice";
import villageSlice from "./features/VillageFeature/villageSlice";
import financeSlice from "./features/FinanceFeature/FinanceSlice";
import clinicalSlice from "./features/ClinicalFeature/clinicalSlice";
import commsSlice from "./features/CommsFeature/commsSlice";
import locationSlice from "./features/LocationFeature/locationSlice";
import providerSlice from "./features/ProviderFeature/providerSlice";
import reportsSlice from "./features/MealsFeatures/ReportsFeature/reportsSlice";
import salesSlice from "./features/SalesFeature/salesSlice";
import providerProspectSlice from "./features/ProviderFeature/providerProspect";
import providerClientSlice from "./features/ProviderFeature/providerClientsSlice";
import providerBusinessSlice from "./features/ProviderFeature/providerBusinesSlice";
import providerLocationsSlice from "./features/ProviderFeature/providerLocationSlice";
import providerTeamsSlice from "./features/ProviderFeature/providerTeamsSlice";
import providerContactsSlice from "./features/ProviderFeature/providerContactsSlice";
import providerStaffsSlice from "./features/ProviderFeature/providerStaffs";
import expensstypeSlice from "./features/RefDataFeature/ExpenseTypeFeature/expensetypeSlice";
import fundingtypeSlice from "./features/RefDataFeature/FundingTypeFeature/fundingtypeSlice";
import billingruleSlice from "./features/RefDataFeature/BillingRuleFeature/billingruleSlice";
import payruleSlice from "./features/RefDataFeature/PayRuleFeature/payruleSlice";
import serviceTypeSlice from "./features/RefDataFeature/ServiceTypeFeature/serviceTypeSlice";
import customFormSlice from "./features/CustomFromFeature/customFormSlice";
import notificationSlice from "./features/NotificationsFeature/NotificaionsSlice";
import vendorSlice from "./features/VendorFeature/VendorSlice";
import reportsHubSlice from "./features/ReportsHubFeature/ReportsHubSlice";

const rootReducer = combineReducers({
  people: clientsSlice,
  associations: associationSlice,
  details: detailsSlice,
  staff: staffSlice,
  document: documentSlice,
  contacts: contactsSlice,
  prospects: prospectsSlice,
  common: commonSlice,
  auth: authSlice,
  audit: auditSlice,
  daybook: daybookSlice,
  mealBuilder: mealbuilderSlice,
  menuBuilder: menuBuilderSlice,
  menuAssignment: menuAssignmentSlice,
  associationProspects: associationProspectsSlice,
  associationStaff: associationStaffSlice,
  documentProspects: documentProspectsSlice,
  documentContact: documentContactSlice,
  documentStaff: documentStaffSlice,
  documentClient: documentClientSlice,
  business: businessSlice,
  teams: teamsSlice,
  documentBusiness: documentBusinessSlice,
  documentTeams: documentTeamsSlice,
  villages: villageSlice,
  finance: financeSlice,
  clinical: clinicalSlice,
  comms: commsSlice,
  location: locationSlice,
  providers: providerSlice,
  reports: reportsSlice,
  sales: salesSlice,
  providerProspect: providerProspectSlice,
  providerClient: providerClientSlice,
  providerBusiness: providerBusinessSlice,
  providerLocations: providerLocationsSlice,
  providerTeams: providerTeamsSlice,
  providerContacts: providerContactsSlice,
  providerStaffs: providerStaffsSlice,
  expenssType: expensstypeSlice,
  fundingType: fundingtypeSlice,
  billingRule: billingruleSlice,
  payRule: payruleSlice,
  serviceType: serviceTypeSlice,
  expenseType: expensstypeSlice,
  customForm: customFormSlice,
  notifications: notificationSlice,
  vendors: vendorSlice,
  reportsHub: reportsHubSlice,
});

export const store: any = configureStore({
  reducer: rootReducer,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
