import React, { useEffect, useRef, useState } from "react";

import {
  useAppDispatch,
  useAppSelector,
} from "../../../../Controller/redux/Hooks";
import { ScreenCapture } from "react-screen-capture";
import * as markerjs2 from "markerjs2";
import { TextMarker, ResizeGrip } from "markerjs2";

import RecordRTC from "recordrtc";

const SupportForm = ({ sendMarkerRef }: any) => {
  const dispatch = useAppDispatch();
  const [IsLoading, setIsLoading] = useState(false);
  const initialValue = {
    message: "",
  };
  const { getButtonTypeSticky, getProfileTypeSticky } = useAppSelector(
    (state) => state?.common
  );
  const [billingFormData, setBillingFormData] = useState(initialValue);
  const { dynamicColor } = useAppSelector((state) => state?.common);

  let isPending = false;

  const handleSubmitBilling = async (e: any) => {
    e.preventDefault();
    setIsLoading(true);
  };
  ///////
  const [recording, setRecording] = useState(false);
  const videoRef = useRef<any>(null);

  const [recorder, setRecorder] = useState<any>(null);

  const [stoprecord, setStoprecord] = useState(0);
  const startRecording = async () => {
    if (stoprecord === 1) {
      stopRecording();
      setStoprecord(2);
    } else {
      const stream = await navigator.mediaDevices.getDisplayMedia({
        video: true,
      });

      const recorderInfo: any = new RecordRTC(stream, {
        type: "video",
        mimeType: "video/webm",
      });
      setRecorder(recorderInfo);
      recorderInfo.startRecording();
      setRecording(true);
      setStoprecord(1);
    }
  };

  const stopRecording = () => {
    recorder?.getDataURL((url: any) => {});
    recorder?.stopRecording(() => {
      const blob = recorder?.getBlob();

      const videoURL = URL.createObjectURL(blob);
      videoRef.current.src = videoURL;
      recorder?.destroy();
    });

    setRecording(false);
  };
  //////

  const [screenCapture, setscreenCapture] = useState<any>("");
  const imgRef = useRef<any>(null);
  const markerAreaRef = useRef<any>(null);
  useEffect(() => {
    // // console.log("screenCapture====================>", screenCapture);
  }, [screenCapture]);

  const showMarkerArea = () => {
    if (imgRef.current !== null) {
      const imgElement = imgRef.current;
      const markerArea = new markerjs2.MarkerArea(imgElement);
      markerArea.addEventListener("render", (event) => {
        if (imgElement) {
          imgElement.src = event.dataUrl;
        }
      });
      markerArea.settings.displayMode = "popup";
      markerArea.show();
      markerAreaRef.current = markerArea;
    }
  };
  useEffect(() => {
    // sendMarkerRef(markerAreaRef);
  }, [markerAreaRef]);

  return (
    <div className="payload_area">
      <div className="main_area">
        <div className="side_bar"></div>
        <div className="side_bar" />
        <div className="middle-block">
          <p className="note-title">{}</p>
          <div className="sticky_profil_btns support_btns">
            {["Screenshot", "Screen Record"]?.map(
              (elem: string, index: number) => {
                return (
                  <>
                    <ScreenCapture
                      // @ts-ignore
                      onEndCapture={(screenCapture) => {
                        setscreenCapture(screenCapture);
                        return;
                      }}
                    >
                      {({ onStartCapture }: any) => (
                        <>
                          {/* <button
                            onClick={startRecording}
                            style={{ height: "3rem", background: dynamicColor }}
                          >
                            Capture
                          </button> */}
                          <button
                            onClick={() => {
                              index === 0
                                ? onStartCapture()
                                : index === 1
                                ? startRecording()
                                : () => {};
                            }}
                            className={`${
                              getButtonTypeSticky === elem
                                ? "sticky_note_btn_active"
                                : ""
                            } sticky_note_btn`}
                          >
                            {index === 1
                              ? stoprecord === 1
                                ? "Stop Recording"
                                : elem
                              : elem}
                          </button>
                        </>
                      )}
                    </ScreenCapture>
                    {/* <button
                      onClick={() => {
                        index === 0 ? onStartCapture : () => {};
                      }}
                      className={`${
                        getButtonTypeSticky === elem
                          ? "sticky_note_btn_active"
                          : ""
                      } sticky_note_btn`}
                    >
                      {elem}
                    </button> */}
                  </>
                );
              }
            )}
          </div>
          <div className="middle-section-content middle_section_scrollable">
            <div className="field">
              <span className="field-title">
                Message <span className="asterixs_color"> * </span>
              </span>
              <textarea
                rows={8}
                style={{
                  backgroundColor: "white",
                  // zIndex:"-1",
                }}
                className="input-fields-of-sticky-note sticky_support_textarea"
                placeholder="Enter Name"
                onChange={(e) =>
                  setBillingFormData((prevState) => ({
                    ...prevState,
                    message: e.target.value,
                  }))
                }
                value={billingFormData.message}
              />

              <span className="field-border field-border-add-form"></span>
            </div>
            <div>
              <div className="field">
                {screenCapture ? (
                  <img
                    style={{ width: "100%", maxWidth: "100%" }}
                    ref={imgRef}
                    src={screenCapture}
                    alt="sample"
                    onClick={() => showMarkerArea()}
                  />
                ) : stoprecord === 2 ? (
                  <video
                    ref={videoRef}
                    controls
                    width={"200px"}
                    height={"200px"}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bottomBlock">
        <button className="save-btn" onClick={handleSubmitBilling}>
          {/* {IsLoading ? "Loading..." : "Send"} */}
          Send
        </button>
      </div>
    </div>
  );
};

export default SupportForm;
