import { Fragment } from "react";

const FilterDropdown = ({
  tableHeadArr,
  selectedItemIndex,
  handleSelectColumn,
  selectedColumnType,
}: any) => {
  return (
    <Fragment>
      <div className="dropdown-2-box-sub">
        <div className="sub-dropdown-2">
          {tableHeadArr?.map((elem: any, index: any) => (
            <div
              key={index}
              className={`dropdown-2-item ${
                selectedItemIndex === index ? "dropdown-2-item-selected" : ""
              }`}
              onClick={() => handleSelectColumn(index, selectedColumnType)}
            >
              <p>{elem}</p>
            </div>
          ))}
        </div>
      </div>
    </Fragment>
  );
};

export default FilterDropdown;
