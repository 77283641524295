import React from "react";
import { useEffect, useState } from "react";
import ClientImg from "../../../../Model/Assets/png/client.png";
import PlusSVG from "../../People/ClientPage/Components/UniqueClientScreen/Components/FinanceTab/Data/PlusSVG";
import BasketballSVG from "../../../../Model/Assets/SVG/Common/BasketballSVG";
import YellowSVG from "../../../../Model/Assets/SVG/Common/YellowSVG";
import CommentWithNumberSVG from "../../../../Model/Assets/SVG/Common/CommentWithNumberSVG";
import { api } from "../../../../Controller/services/api";
import { useAppSelector } from "../../../../Controller/redux/Hooks";
import CommonQuickMenu from "../../../components/CommonQuickMenu/CommonQuickMenu";

const DaybookHeader = ({ type, uniqueAllCommsData }: any) => {
  // const [user, setUser] = useState<any>(null);


  const { user } = useAppSelector((state) => state?.common);
  const [stageTwoToGoalsModal, setstageTwoToGoalsModal] = useState(false);
  const { getCommsUnreadMessages, getUnreadMessageColor, getUnreadMessages } =
    useAppSelector((state) => state?.comms);
  let data = getUnreadMessages[0];
  return (
    <div>
      <div className="daybook__header">
        <div className="left_side_section_wrapper">
          <div className="image_with_comment_svg_wrapper">
            {user?.profile_image_s3 ? (
              <img
                style={{
                  borderRadius: "50%",
                  objectFit: "cover",
                }}
                src={user?.profile_image_s3 ? user?.profile_image_s3 : null}
                className={
                  type === "comms"
                    ? "daybook__header__img-no-background"
                    : "daybook__header__img"
                }
              />
            ) : (
              <div className="initials__daybook">
                {user?.first_name ? user?.first_name?.charAt(0) : ""}{" "}
                {user?.last_name ? user?.last_name?.charAt(0) : ""}
              </div>
            )}

            {/* <CommentWithNumberSVG
              number={data?.data?.unread_messages || 0}
              fill={data?.data?.traffic_light || "Green"}
            /> */}
          </div>
          <h1
            className="daybook__header__name text-ellipsis"
            title={`${user?.first_name ? user?.first_name : ""} ${
              user?.last_name ? user?.last_name : ""
            }`}
          >
            {type === "comms"
              ? "Comms"
              : `${user?.first_name ? user?.first_name : ""}  ${
                  user?.last_name ? user?.last_name : ""
                }`}
          </h1>
        </div>
        <div className="daybook__header__options_top">
          {/* <BasketballSVG /> */}
          {/* <YellowSVG /> */}
          <div onClick={() => setstageTwoToGoalsModal(true)}>
            <PlusSVG />
          </div>
          {stageTwoToGoalsModal && (
            <div
              style={{
                position: "absolute",
                left: "0%",
                top: "12%",
              }}
            >
              <CommonQuickMenu
                setshowGoalsModal={(value: any) => {}}
                mainType={"listing"}
                setstageTwoToGoalsModal={setstageTwoToGoalsModal}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DaybookHeader;
