import "./index.css";

// import { useEffect, useState } from "react";

// import { ChevronRightIcon } from "@chakra-ui/icons";/
import { useNavigate } from "react-router-dom";
// import { api } from "../../../../Controller/services/api";
import BreadcrumbComponent from "../../Breadcrumb/Breadcrumb";
import {
  useAppDispatch,
  // useAppDispatch,
  useAppSelector,
} from "../../../../Controller/redux/Hooks";
import { useEffect, useRef, useState } from "react";
import {
  getModalNotifications,
  getUserNotificationDetails,
  getUserNotificationStats,
} from "../../../../Controller/redux/features/NotificationsFeature/NotificaionsSlice";
import { Modal, ModalContent, useDisclosure } from "@chakra-ui/react";
import CrossIconSVG from "../../../pages/Comms/Data/CrossIconSVG";
import { CrossMdSvg } from "../../../../Model/Assets/SVG/Common/CrossSvg";
import React from "react";
import NotificationIconSVG from "../Assets/NotificationIconSVG";

function Header({ isClients }: any) {
  const dispatch = useAppDispatch();

  const timeoutRef = useRef<any>(null);
  const [status, setStatus] = useState<any>(-1);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const favicon = useAppSelector(
    (state) => state?.common?.fromGetDomainDetails.favicon
  );
  const hasHub = useAppSelector((state) => state.common.hasHub);

  const navigate = useNavigate();

  const { notificationCount, modalNotifications } = useAppSelector(
    (state) => state.notifications
  );

  useEffect(() => {
    dispatch(getUserNotificationStats());
  }, []);

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const handleLightHover = (status: Number) => {
    setStatus(status);
    handleOpenModal();
    dispatch(getModalNotifications({ status, limit: 5 }));
  };

  const handleOpenModal = () => {
    clearTimeout(timeoutRef?.current);
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    timeoutRef.current = setTimeout(() => {
      setIsOpen(false);
    }, 300);
  };

  const handleSeeAll = () => {
    handleCloseModal();
    navigate("/notifications", {
      state: {
        status,
      },
    });
  };
  const handleClickRow = (e: any, data: any) => {
    const encryptedId = window.btoa(data?.user_id);
    if (data?.asset_type === "Document") {
      localStorage.setItem("activeTab", "Documents");
      navigate(`/people/clients/${encryptedId}`);
    } else if (data?.asset_type === "Note") {
      localStorage.setItem("activeTab", "Clinical");
      navigate(`/people/clients/${encryptedId}`);
    } else {
      navigate(`/my-daybook`, {
        state: {
          customDate: new Date(data?.start_date),
        },
      });
    }
    handleCloseModal();
  };

  return (
    <div className="header-section">
      <div className="top-left-section">
        <div className="flex">
          <img
            src={favicon}
            style={{ transform: "translateX(-20%)", cursor: "pointer" }}
            alt=""
            className="img-fluid logo-1"
            onClick={() => {
              if (hasHub == "1") {
                navigate("/");
              } else {
                navigate("/my-daybook");
              }
            }}
          />
          <div
            style={{
              transform: "translate(40%,20%)",
            }}
          >
            <BreadcrumbComponent />
          </div>
        </div>
        {/* <TextManipulationBar/> */}
      </div>
      <div className="top-right-section">
        <ul>
          <li>
            <div>
              <img alt="" className="img-fluid mt--6-sm" />
            </div>
          </li>

          <li>
            <NotificationIconSVG
              count={notificationCount?.green}
              onClick={handleSeeAll}
              onMouseEnter={() => handleLightHover(2)}
              onMouseLeave={handleCloseModal}
              fill="#2ad400"
            />
          </li>
          <li>
            <NotificationIconSVG
              count={notificationCount?.yellow}
              onClick={handleSeeAll}
              onMouseEnter={() => handleLightHover(1)}
              onMouseLeave={handleCloseModal}
              fill="#f1b60d"
            />
          </li>
          <li>
            <NotificationIconSVG
              count={notificationCount?.red}
              onClick={handleSeeAll}
              onMouseEnter={() => handleLightHover(0)}
              onMouseLeave={handleCloseModal}
              fill="#EF3A34"
            />
          </li>
        </ul>
      </div>
      {isOpen && (
        <div
          className="notification_modal"
          onMouseEnter={handleOpenModal}
          onMouseLeave={handleCloseModal}
        >
          <div className="modal_content">
            <ul className="notification_list">
              {modalNotifications?.map((item: any) => {
                return (
                  <li onClick={(e: any) => handleClickRow(e, item)}>
                    {item.title}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
}
export default Header;
