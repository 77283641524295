import { useEffect, useRef, useState } from "react";
import { useAppSelector } from "../../../../Controller/redux/Hooks";
import { SearchIconSVG } from "../Data/SVGs";

export const SearchableItemsContainer = ({
  label,
  value,
  selectedItems,
  onChange,
  onClick = () => {},
  type,
  selectedDocument,
  arrayToFilterFrom,
  allowSearch,
}: any) => {
  const { allUserTypeData, getCommDetailFull } = useAppSelector(
    (state) => state.comms
  );
  const { dynamicColor } = useAppSelector((state) => state.common);
  const [showDropdown, setshowDropdown] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [filteredResults, setfilteredResults] = useState([]);
  const [selectedApprovers, setselectedApprovers] = useState([]);

  useEffect(() => {
    setselectedApprovers(selectedItems);
  }, [selectedItems]);
  useEffect(() => {
    if (allUserTypeData) {
      setfilteredResults(allUserTypeData);
    }
  }, [allUserTypeData]);

  // console.log('allUserTypeData', allUserTypeData);
  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setshowDropdown(false);
    }
  };

  useEffect(() => {
    if (showDropdown) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showDropdown]);

  useEffect(() => {
    if (showDropdown) {
      inputRef?.current?.focus();
    }
  }, [showDropdown]);

  return (
    <div className="searchable_items_container">
      {label && (
        <>
          <div className="label_segment">
            <div className="items_container_label">{label || "Key People"}</div>
          </div>
        </>
      )}

      <div className="searchable_items_selected_items_wrapper">
        {Array.isArray(selectedItems) &&
          selectedItems?.map((item: any, index : number) => {
            return (
              <div
              key={index}
              className="single_item">
                {item?.profile_image_s3 ? (
                  <img
                    src={item?.profile_image_s3}
                    className="searchable_items_container_selected_item"
                  />
                ) : (
                  <div
                    className="searchable_items_container_selected_item no_picture"
                    style={{
                      backgroundColor: dynamicColor,
                    }}
                  >
                    {(item?.first_name?.toUpperCase() || "")[0]}
                    {(item?.last_name?.toUpperCase() || "")[0]}
                  </div>
                )}

                <span className="title_single_item">{`${(
                  item?.first_name || ""
                ).substring(0, 3)} ${
                  (item?.last_name || "").length >= 2
                    ? (item?.last_name || "").substring(0, 3) + "..."
                    : item?.last_name || ""
                }`}</span>
              </div>
            );
          })}
        <>
          {allowSearch && (
            <>
              <div className="searchable_input_wrapper searchable_input_wrapper_dropdown">
                <SearchIconSVG />
                <input
                  value={value}
                  onChange={onChange}
                  type="text"
                  className="searchable_input"
                  onFocus={() => setshowDropdown(true)}
                />
                {showDropdown && (
                  <>
                    <div ref={dropdownRef} className="search_items_container">
                      <div className="top_section_search_items_container">
                        <div className="searchable_input_wrapper ">
                          <SearchIconSVG />
                          <input
                            ref={inputRef}
                            value={inputRef?.current?.value}
                            onChange={(e) => {
                              const query = e.target.value.toLowerCase();
                              setfilteredResults((prevItems: any) => {
                        
                                const queryWords = query
                                  .split(" ")
                                  .filter(Boolean);

                                return allUserTypeData?.filter((elem: any) => {
                                  const firstName =
                                    elem?.first_name?.toLowerCase() || "";
                                  const lastName =
                                    elem?.last_name?.toLowerCase() || "";

                                  return queryWords.every(
                                    (word) =>
                                      firstName.includes(word) ||
                                      lastName.includes(word)
                                  );
                                });
                              });
                            }}
                            type="text"
                            className="searchable_input searchable_input_inner"
                          />
                        </div>
                      </div>
                      <div className="middle_section_search_items_container">
                        {filteredResults?.map((item: any, itemIndex: any) => {
                          const nameExists = !arrayToFilterFrom?.type?.some(
                            (user: any) =>
                              user?.first_name?.toLowerCase() ===
                                item?.first_name?.toLowerCase() ||
                              user?.last_name?.toLowerCase() ===
                                item?.last_name?.toLowerCase()
                          );

                          if (nameExists && type) {
                            return (
                              <li
                                onClick={() => {
                                  if (Array.isArray(selectedApprovers)) {
                                    var newSelectedItems = [
                                      ...selectedApprovers,
                                      item,
                                    ];
                                  }
                                  setselectedApprovers(newSelectedItems);

                                  if (
                                    type === "primaryApprovers" ||
                                    type === "ongoingApprovers"
                                  ) {
                                    onClick(type, newSelectedItems);
                                  } else {
                                    onClick(item);
                                  }
                                  setshowDropdown(false);
                                }}
                                key={itemIndex}
                                className="search_item"
                              >
                                {item?.first_name} {item?.last_name}
                              </li>
                            );
                          } else {
                            return null;
                          }
                        })}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </>
          )}
        </>
      </div>
    </div>
  );
};
