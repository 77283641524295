import React, { useEffect } from "react";
import CommonUpperBox from "../../components/UpperBox/CommonHeader";
import Table from "../../components/CommonTable/Table";
import CircularNoteTrigger from "../../components/CircularNoteTrigger/CircularNoteTrigger";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../Controller/redux/Hooks";

import { useNavigate } from "react-router-dom";
import {getReportsHub, getUserRolesReportsHub} from "../../../Controller/redux/features/ReportsHubFeature/ReportsHubSlice";

const ReportsScreen = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const dynamicReportsHubId = localStorage.getItem("dynamicReportsHubId");
  const { reportsHubList } = useAppSelector((state) => state?.reportsHub);

  const thData = [
    { header: "Title", accessor: "report_name" },
    { header: "Status", accessor: "status" },
    { header: "Action", accessor: "reportAction" },
  ];

  const handleClickRow = (e: any, id: any) => {
    navigate(`${window.btoa(id)}`);
  };

  useEffect(() => {
    dispatch(getReportsHub({ type: dynamicReportsHubId }));
    dispatch(getUserRolesReportsHub({ id: dynamicReportsHubId }));
  }, []);

  return (
    <>
      <CommonUpperBox title="Reports" type={"listing"} />
      <Table
        itemsArr={[]}
        allClients={reportsHubList}
        statusType="reportsScreen"
        thData={thData}
        classNameTr={`all-reports-table-head `}
        type="reports"
        onClickRow={handleClickRow}
        classNameRow={`all-reports-table-body`}
        fill={"#D8D3D3"}
      />
      <CircularNoteTrigger type="Reports" title="Reports" />
    </>
  );
};

export default ReportsScreen;
