import React, { useState } from "react";
import TimePicker from "../../../Time/Time";
import ReactDatePicker from "react-datepicker";
import CommonQuickMenu from "../../../CommonQuickMenu/CommonQuickMenu";
import ClientStatusCircleSvg from "../../../../../Model/Assets/SVG/WidgetVillage/ClientStatusCircleSvg";
import PlusSVG from "../../../../pages/People/ClientPage/Components/UniqueClientScreen/Components/FinanceTab/Data/PlusSVG";
import EarthSvg from "../../../../../Model/Assets/SVG/StickyNoteSvg/EarthSvg";
import SearchSvg from "../../../../../Model/Assets/SVG/StickyNoteSvg/SearchSvg";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../../../Controller/redux/Hooks";

const StickyHeader = ({
  type,
  isMobileScreen,
  isOpenQuickMenu,
  setIsOpenQuickMenu,
  customTitle,
  title,
  setMobileScreenStickyNoteCurrentSlide,
  setCustomTitle,
  setTabsArray,
  setcurrentSelectedBTN,
  tabsArray,
  currentSelectedBTN,
  daybookDate,
  setDaybookDate,
  setTime,
  time,
  clinicalDate,
  setClinicalDate,
  handleTabChange,
  activeTabInner,
}: any) => {
  const navigate = useNavigate();
  const pathnameComms = useLocation().pathname.includes("comms");
  // console.log({ type });
  // const [activeTab, setActiveTab] = useState<string>("Obs");
  // const handleTabChange = (index: string) => {
  //   setActiveTab(index);
  // };

  return (
    <>
      {
        <div
          className={`${
            type !== "stickyHub" || pathnameComms ? "fade_in" : "fade_out"
          }  ${type === "stickyClinical" && "clinical_sticky_header"}`}
        >
          {/* <div className="sticky_note_circle_container fade_out">
            {["0", "21", "8"].map((elem, index) => (
              <span
                key={index}
                onClick={index == 2 ? () => navigate("/audit") : () => null}
                className="sticky_note_circle"
              >
                {elem}
              </span>
            ))}
            <div className="sticky_note_search">
              <SearchSvg />
            </div>
            <span className="sticky_note_circle sticky_note_circle_end ">
              ?
            </span>
          </div> */}
          <div className="sticky_note_form_head">
            <div className="sticky_note_form_inner">
              <div
                className="sticky_note_form_dollar"
                onClick={() => {
                  if (type == "stickyDaybook" && isMobileScreen) {
                    setIsOpenQuickMenu(true);
                  }
                }}
              >
                $
              </div>
              <p className="sticky_note_form_title">
                {`${type === "stickyDaybook" ? "" : "Forms - "} ${
                  customTitle && isMobileScreen ? customTitle : title
                }`}
              </p>
            </div>
            {/* <div className="sticky_note_svg_container fade_out">
              <span className="sticky_note_circle_small">&nbsp;</span>
              <EarthSvg />
              <ClientStatusCircleSvg count={6} />
              {type == "stickyDaybook" && (
                <PlusSVG className="plus_sticky_daybook" />
              )}
            </div> */}
          </div>
        </div>
      }
      {isOpenQuickMenu && (
        <CommonQuickMenu
          setshowGoalsModal={(value: any) => {}}
          showGoalsModal={false}
          type="stickynote"
          setstageTwoToGoalsModal={setIsOpenQuickMenu}
          onClickCircle={(item: any) => {
            setMobileScreenStickyNoteCurrentSlide(item?.label);
            if (item?.label == "Daybook") {
              setCustomTitle(title);
            } else {
              let createTitle = `${item?.label}s - All ${item?.label}s`;
              setCustomTitle(createTitle);
            }
            setTabsArray(["All", "New"]);
            setcurrentSelectedBTN("All");
          }}
        />
      )}
      <div className="sticky_note_btn_container">
        {type === "Obs" || type === "stickyClinical" ? null : type ==
          "stickyDaybook" ? ( // )) //   </button> //     {elem} //   > //     } sticky_note_btn`} //       activeTab === elem ? "sticky_note_btn_active" : "" //     className={`${ //     key={index} //     }} //       handleTabChange(elem); //       setActiveTab(elem); //     onClick={() => { //   <button // ["Notes", "Obs"]?.map((elem, index) => (
          <div className="sticky_notes_btn_wrapper">
            {tabsArray?.map((elem: any, index: number) => (
              <button
                key={index}
                onClick={() => setcurrentSelectedBTN(elem)}
                className={`sticky_note_btn  ${
                  currentSelectedBTN == elem && "sticky_note_btn_active"
                }`}
              >
                {elem}
              </button>
            ))}
          </div>
        ) : type == "stickyHub" ? null : (
          ["New", "Recent", "Upcoming", "Sort"]?.map((elem, index) => (
            <button key={index} className="sticky_note_btn">
              {elem}
            </button>
          ))
        )}

        {type !== "stickyClinical" ||
          (type !== "stickyHub" && (
            <button
              // onClick={() => {
              //   if (type == "stickyDaybook") {
              //   }
              // }}
              style={{
                display:
                  type == "stickyDaybook"
                    ? "none"
                    : type == "stickyClinical"
                    ? "none"
                    : "inline-block",
              }}
              className="sticky_note_btn"
            >
              Select Date
            </button>
          ))}

        {type === "stickyDaybook" && (
          <div id="sticky_clinical_date_picker">
            <ReactDatePicker
              // placeholderText={"Select Date"}
              showYearDropdown
              showMonthDropdown
              // @ts-ignore
              selected={daybookDate}
              value={daybookDate ? daybookDate : "Select Date"}
              dateFormat="dd MMM yyyy"
              onChange={(date: any) => {
                setDaybookDate(date);
              }}
            />
          </div>
        )}

        {/* {(type === "stickyClinical" ) && (
          <>
            <div
              id="sticky_clinical_date_picker"
              className={`${
                type === "Obs" ? "fade_in" : "display_none"
              } sticky_clinical_unqiue`}
            >
              <TimePicker setTime={setTime} time={time} />
              <ReactDatePicker
                placeholderText={"Select Date"}
                showYearDropdown
                showMonthDropdown
                // @ts-ignore
                selected={clinicalDate}
                value={clinicalDate}
                dateFormat="dd MMM yyyy"
                onChange={(date: any) => {
                  setClinicalDate(date);
                }}
              />
            </div>
          </>
        )} */}
      </div>
    </>
  );
};

export default StickyHeader;
