import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { api } from "../../../services/api";

const initialState: any = {
  getStaffAssociationNames: [],
};

export const getStaffAssociationNamesThunk = createAsyncThunk(
  "clients/prospectsAssociations/fetchNames",
  async ({ id, model }: any) => {
    try {
      const response = await api.get(
        `/associations/get_names?type=${model}&id=${id}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

const associationStaffSlice = createSlice({
  name: "staffAssociations",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getStaffAssociationNamesThunk.pending, (state) => {
        state.loading = true;
        state.getStaffAssociationNames = [];
        state.error = null;
      })
      .addCase(getStaffAssociationNamesThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.getStaffAssociationNames = action.payload;
        state.error = null;
      })
      .addCase(getStaffAssociationNamesThunk.rejected, (state, action) => {
        state.loading = false;
        state.getStaffAssociationNames = [];
        state.error = action.error.message;
      });
  },
});

export const {} = associationStaffSlice.actions;

export default associationStaffSlice.reducer;
