import "./Styles/CommonHeader.css";

import { useEffect, useRef, useState } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../Controller/redux/Hooks";

import { formatDate, formatDateFromAPI } from "../../../Model/utils/Helper/formatDate";
import { useLocation, useParams } from "react-router-dom";
import {
  btnArrBillingRule,
  btnArrPayRule,
  btnArrProviders,
  btnArrReportComms,
  btnArrReportsHub,
  btnArrStaff,
  btnArrVendors,
} from "./Data/buttonArr";

import HeaderType from "./Components/HeaderType";
import React from "react";
// import {getDocuments} from "../../../Controller/redux/features/CommonFeature/documentSlice";

const CommonHeader = ({
  handleClick,
  type,
  mainType,
  currentContact,
  currentProspects,
  currentStaff,
  currentBusiness,
  currentLocation,
  currentTeams,
  currentUser,
  currentProvider,
  currentBilling,
  currentPay,
  currentVendor,
  currentReportsHub,
  itemsArr,
  currentService,
  providerId,
  isProviderBar,
  currentClient
}: any) => {
  const dispatch = useAppDispatch();
  const widgetRef = useRef<any>();

  const [showStatusWidget, setShowStatusWidget] = useState(false);
  // const [currentClient, setcurrentClient] = useState<any>(null);
  const [currentData, setcurrentData] = useState<any>([]);

  const [OpenStatusWidget, setOpenStatusWidget] = useState<boolean>(false);

  const [
    allClientsDataToTrackClickOfStatusClick,
    setAllClientsDataToTrackClickOfStatusClick,
  ] = useState(currentData ? Array(currentData.length).fill(false) : []);

  const idParams: any = useParams().id;
  const clientId = window.atob(idParams);
  const getCurrentStaff = useAppSelector(
    (state: any) => state.staff.getUniqueStaff
  );
  const currentStaffs = getCurrentStaff?.data?.data[0];

  const statusDetailObject = useAppSelector(
    (state) => state.people.statusObject
  );
  const [statusData, setstatusData] = useState<any>();
  useEffect(() => {
    if (statusDetailObject) {
      setstatusData(statusDetailObject);
    }
  }, [currentUser, clientId, dispatch, statusDetailObject, statusData]);

  const isMeals = useAppSelector((state) => state?.common?.isMeals);


  // console.log('currentUser', currentUser);
  useEffect(() => {
    const pathname = window.location.pathname;
    if (pathname.includes("/clients")) {
      setcurrentData(currentClient);
    } else if (pathname.includes("/contacts")) {
      setcurrentData(currentContact);
    } else if (pathname.includes("/prospects")) {
      setcurrentData(currentProspects);
    } else if (pathname.includes("/staff")) {
      setcurrentData(currentStaffs);
    } else if (pathname.includes("business")) {
      setcurrentData(currentBusiness);
    } else if (pathname.includes("teams")) {
      setcurrentData(currentTeams);
    } else if (pathname.includes("locations")) {
      setcurrentData(currentLocation);
    } else if (pathname.includes("providers")) {
      setcurrentData(currentProvider);
    } else if (pathname.includes("/billing-set")) {
      setcurrentData(currentBilling);
    } else if (pathname.includes("pay-rule")) {
      setcurrentData(currentPay);
    } else if (pathname.includes("expense-type")) {
      setcurrentData(currentPay);
    } else if (pathname.includes("service-type")) {
      setcurrentData(currentService);
    } else if (pathname.includes("/vendors")) {
      setcurrentData(currentVendor);
    } else if (pathname.includes("/reports")) {
      setcurrentData(currentReportsHub);
    }
  }, [
    currentUser,
    currentContact,
    currentProspects,
    getCurrentStaff,
    currentBusiness,
    currentTeams,
    currentProvider,
    currentLocation,
    currentBilling,
    currentService,
    currentVendor,
    currentReportsHub,
  ]);


  console.log(currentData,'currentData')
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleOpenWidget = (e: any) => {
    setOpenStatusWidget(true);
  };

  // FUNCTION TO CLOSE WIDGET WHEN CLICKING OUTSIDE OF IT
  const handleClickOutside = (event: any) => {
    const classNamesToExclude = ["firstNote", "secondNote", "thirdNote"];

    if (widgetRef.current && !widgetRef.current.contains(event.target)) {
      const targetClassList: any = Array.from(event.target.classList);

      if (
        !targetClassList.some((className: string | any) =>
          classNamesToExclude.includes(className)
        )
      ) {
        setShowStatusWidget(false);
      }
    }
  };

  const { entityRoles } = useAppSelector((state) => state?.business);
  const currentBusinessRoles = entityRoles?.data;

  /////////////// STATUS UPDATE NECESSATIES ////////////////
  const [openWidgetProspects, setOpenWidgetProspects] = useState(false);
  const [openWidgetContacts, setOpenWidgetContacts] = useState(false);
  // const [openWidgetStaff, setOpenWidgetStaff] = useState(false);
  const [openWidgetBusiness, setOpenWidgetBusiness] = useState(false);
  const [openWidgetTeams, setOpenWidgetTeams] = useState(false);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  ////////////////////////////////////////////////////////
  /////////////////  DYNAMIC DATA ////////////////////////
  ////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////

  const pathname = useLocation().pathname;

  const currentProspectsData = [
    {
      Name: "",
      UpperTitle: `Known As`,
      LowerTitle: ` ${
        currentData?.pref_name || currentData?.first_name || ""
      } `,
    },
    {
      UpperTitle: "Email",
      LowerTitle: currentData?.email || "",
    },
    {
      UpperTitle: "Mobile",
      LowerTitle: currentData?.phone || "",
    },
    {
      UpperTitle: "DOB",
      LowerTitle: currentData?.date_of_birth
        ? formatDate(currentData.date_of_birth)
        : "",
    },
    {
      UpperTitle: "Funding Type",
      LowerTitle: currentData?.funding_type || "",
    },
  ];


  console.log(' currentClient',  currentClient?.date_of_birth);
  const currentClientData = [
    {
      Name: "",
      UpperTitle: `Known As`,
      LowerTitle: ` ${
        currentClient?.pref_name || currentClient?.first_name || ""
      } `,
    },
    {
      UpperTitle: "Email",
      LowerTitle: currentClient?.email || "",
    },
    {
      UpperTitle: "Mobile",
      LowerTitle: currentClient?.mobile
        ? currentClient?.mobile
        : currentClient?.phone,
    },
    {
      UpperTitle: "DOB",
      LowerTitle: currentClient?.date_of_birth
      ? formatDate(formatDateFromAPI(currentClient.date_of_birth))
      : ""
    },
    {
      UpperTitle: "Client Type",
      LowerTitle: currentClient?.roles[0]?.desc
        ? currentClient?.roles[0]?.desc
        : "",
    },
    {
      UpperTitle: "Funding Type",
      LowerTitle: currentClient?.funding_type || "",
    },
  ];

  const currentVendorData = [
    {
      Name: "",
      UpperTitle: `Name`,
      LowerTitle: ` ${currentVendor?.vendor_name || ""} `,
    },
    {
      Name: "",
      UpperTitle: `Live Link`,
      LowerTitle: ` ${currentClient?.link || ""} `,
    },
  ];
  const currentReportsHubData = [
    {
      Name: "",
      UpperTitle: `Name`,
      LowerTitle: ` ${currentReportsHub?.report_name || ""} `,
    },
  ];
  const currentProvidersData = [
    {
      Name: "",
      UpperTitle: `Name`,
      LowerTitle: currentProvider?.tenant_name || "",
    },
    {
      UpperTitle: "",
      LowerTitle: "",
    },
    {
      UpperTitle: " ",
      LowerTitle: "",
    },
    {
      UpperTitle: "",
      LowerTitle: "",
    },
  ];

  const currentClientDataStaff = [
    {
      Name: "",
      UpperTitle: `Known As
      `,
      LowerTitle: ` ${
        currentData?.pref_name
          ? currentData?.pref_name
          : currentData?.first_name || ""
      }`,
    },
    {
      UpperTitle: "Email",
      LowerTitle: currentData?.email || "",
    },
    {
      UpperTitle: "Mobile",
      LowerTitle: currentData?.mobile || currentData?.phone,
    },
    {
      UpperTitle: "DOB",
      LowerTitle: currentData?.date_of_birth
      ? formatDate(formatDateFromAPI(currentData?.date_of_birth))
      : "",
    },
    {
      UpperTitle: "Role",
      LowerTitle:
        currentData?.roles?.length > 0 ? currentData.roles[0]?.desc : "",
    },
    {
      UpperTitle: "Username",
      LowerTitle: currentData?.username,
    },
  ];

  const currentClientDataContacts = [
    {
      Name: "",
      UpperTitle: `Known As`,
      LowerTitle: ` ${currentData?.pref_name || currentData?.first_name || ""}`,
    },
    {
      UpperTitle: "Email",
      LowerTitle: currentData?.email || "",
    },
    {
      UpperTitle: "Mobile",
      LowerTitle:
        currentData?.mobile !== null && currentData?.mobile !== ""
          ? currentData?.mobile
          : currentData?.phone,
    },
    {
      UpperTitle: pathname.includes("prospects")
        ? "Prospect Type"
        : pathname.includes("contacts")
        ? "Role"
        : "Role",

      LowerTitle:
        currentData?.roles?.length > 0 ? currentData.roles[0]?.desc : "",
    },
  ];

  const currentClientDataBusiness = [
    {
      Name: "",
      UpperTitle: `Name`,
      LowerTitle: currentData?.first_name || "",
    },
    {
      UpperTitle: "Email",
      LowerTitle: currentData?.email || "",
    },
    {
      UpperTitle: "Mobile",
      LowerTitle:
        currentData?.phone !== null ? currentData?.phone : currentData?.mobile,
    },
    {
      UpperTitle: "Business Type",
      LowerTitle: currentData?.roles?.desc ? currentData?.roles?.desc : "",
    },
  ];
  const currentClientDataLocation = [
    {
      Name: "",
      UpperTitle: `Name`,
      LowerTitle: currentData?.first_name,
    },
    {
      UpperTitle: "Email",
      LowerTitle: currentData?.email || "",
    },
    {
      UpperTitle: "Mobile",
      LowerTitle:
        currentData?.phone !== null ? currentData?.phone : currentData?.mobile,
    },
    {
      UpperTitle: "Location Type",
      LowerTitle:
        currentData?.roles?.length > 0 ? currentData.roles[0]?.desc : "",
    },
  ];
  const currentClientDataTeams = [
    {
      Name: "",
      UpperTitle: `Name`,
      LowerTitle: currentData?.first_name || "",
    },
    {
      UpperTitle: "Team Leader",
      LowerTitle: `${
        currentData?.team_leader?.last_name !== undefined
          ? currentData?.team_leader?.first_name
          : ""
      } ${
        currentData?.team_leader?.last_name !== undefined
          ? currentData?.team_leader?.last_name
          : ""
      } `,
    },
    {
      UpperTitle: "Phone",
      LowerTitle:
        currentData?.phone !== null ? currentData?.phone : currentData?.mobile,
    },
    {
      UpperTitle: "Associated Location ",
      LowerTitle: currentData?.business?.name,
    },
  ];

  const currentBillingRuleData = [
    {
      Name: "",
      UpperTitle: `Name`,
      LowerTitle: currentData?.name || "",
    },
    {
      UpperTitle: "",
      LowerTitle: "",
    },
    {
      UpperTitle: " ",
      LowerTitle: "",
    },
    {
      UpperTitle: "",
      LowerTitle: "",
    },
  ];
  const currentPayRuleData = [
    {
      Name: "",
      UpperTitle: `Name`,
      LowerTitle: currentData?.name || "",
    },
  ];

  const btnArr = [
    "Details",
    "Associations",
    "Clinical",
    "Comms",
    "Documents",
    "Finance",
    "Daybook",
  ];

  if (isMeals) {
    btnArr.push("Meals");
  }

  const [selectedRole, setselectedRole] = useState<any>([]);
  useEffect(() => {
    if (currentData?.roles) {
      setselectedRole(currentData?.roles);
    }
  }, [selectedRole]);

  const {
    prospect_tabs,
    staff_tabs,
    team_tabs,
    contact_tabs,
    business_tabs,
    client_tabs,
    location_tabs,
  } = useAppSelector((state) => state?.common?.domainDetail);

  const [prospectTabsArray, setProspectTabsArray] = useState<string[]>([]);
  const [clienttabArr, setClienttabArr] = useState<string[]>([]);
  const [businessTabArr, setBusinessTabArr] = useState<string[]>([]);
  const [locationTabArr, setLocationTabArr] = useState<string[]>([]);
  const [teamTabArr, setTeamTabArr] = useState<string[]>([]);
  const [contactTabArr, setContactTabArr] = useState<string[]>([]);
  const [staffTabArr, setStaffTabArr] = useState<string[]>([]);

  useEffect(() => {
    if (prospect_tabs) {
      setProspectTabsArray(prospect_tabs.split(","));
    }
    if (client_tabs) {
      setClienttabArr(client_tabs.split(","));
    }
    if (business_tabs) {
      setBusinessTabArr(business_tabs.split(","));
    }
    if (location_tabs) {
      setLocationTabArr(location_tabs.split(","));
    }
    if (location_tabs) {
      setTeamTabArr(team_tabs.split(","));
    }
    if (contact_tabs) {
      setContactTabArr(contact_tabs.split(","));
    }
    if (staff_tabs) {
      setStaffTabArr(staff_tabs.split(","));
    }
  }, [
    prospect_tabs,
    client_tabs,
    business_tabs,
    location_tabs,
    team_tabs,
    contact_tabs,
    staff_tabs,
  ]);

  const { user } = useAppSelector((state) => state.common);

  const [btnStaffArrState, setbtnStaffArrState] = useState(btnArrStaff);

  useEffect(() => {
    if (
      currentData?.id &&
      mainType === "staffScreen" &&
      user?.id &&
      user?.id == currentData?.id
    ) {
      let letcheckItIsExist = btnStaffArrState.includes("Availability");
      if (!letcheckItIsExist) {
        setbtnStaffArrState([...btnStaffArrState, "Availability"]);
      }
    }
  }, [currentData, user]);
  console.log('currentDatacurrentDatacurrentDatacurrentDatacurrentDatacurrentData', currentData);

  return (
    <HeaderType
      screenType={mainType}
      isProviderBar={isProviderBar}
      buttonsArray={
        mainType === "contacts"
          ? contactTabArr
          : mainType === "prospects"
          ? prospectTabsArray
          : mainType === "staffScreen"
          ? btnStaffArrState
          : mainType === "clinical"
          ? btnArr
          : mainType === "business"
          ? businessTabArr
          : mainType === "teams"
          ? teamTabArr
          : mainType === "providers"
          ? btnArrProviders
          : mainType === "clients"
          ? clienttabArr
          : mainType === "locations"
          ? locationTabArr
          : mainType === "billingRule"
          ? btnArrBillingRule
          : mainType === "payRule"
          ? btnArrPayRule
          : mainType === "vendor"
          ? btnArrVendors
          : mainType === "reports"
          ? btnArrReportsHub
          : mainType === "reportComms"
          ? btnArrReportComms
          : btnArr
      }
      associationTabArray={
        mainType === "contacts"
          ? currentClientDataContacts
          : mainType === "prospects"
          ? currentClientDataContacts
          : mainType === "staffScreen"
          ? currentClientDataStaff
          : mainType === "clinical"
          ? currentClientData
          : mainType === "business"
          ? currentClientDataBusiness
          : mainType === "teams"
          ? currentClientDataTeams
          : mainType === "clients"
          ? currentClientData
          : mainType === "providers"
          ? currentProvidersData
          : mainType === "locations"
          ? currentClientDataLocation
          : mainType === "billingRule"
          ? currentBillingRuleData
          : mainType === "payRule"
          ? currentPayRuleData
          : mainType === "vendor"
          ? currentVendorData
          : mainType === "reports" || mainType === "reportComms"
          ? currentReportsHubData
          : currentClientData
      }
      statusData={{ statusData }}
      type={type}
      handleClick={handleClick}
      widgetRef={widgetRef}
      currentData={currentData !== null ? currentData : currentUser}
      position={position}
      OpenStatusWidget={OpenStatusWidget}
      setOpenStatusWidget={setOpenStatusWidget}
      setAllClientsDataToTrackClickOfStatusClick={
        setAllClientsDataToTrackClickOfStatusClick
      }
      allClientsDataToTrackClickOfStatusClick={
        allClientsDataToTrackClickOfStatusClick
      }
      setOpenWidgetBusiness={setOpenWidgetBusiness}
      setOpenWidgetContacts={setOpenWidgetContacts}
      setOpenWidgetProspects={setOpenWidgetProspects}
      setOpenWidgetTeams={setOpenWidgetTeams}
      setShowStatusWidget={setShowStatusWidget}
      handleOpenWidget={handleOpenWidget}
      itemsArr={itemsArr}
    />
  );
};

export default CommonHeader;
