
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../../../services/api";

const initialState: any = {
  loading: false,
  error: "",
  BillingRuleData: [],

  // FILTER
  billingRulePage: 1,
  billingRuleFilterItems: {
    // status: "1",
    filterColumn: "",
    filterValue: "",
    searchVal: "",
    sortColumn: "",
    sortType: "",
    billingRulePage: 1,
  },
  lastPage: 100000,

  // Filter Data Start
  getBillingRuleName: [],
  getBillingRuleAssociatedFunding: [],
  getBillingRuleRate: [],
  // Filter Data End
  //  // STATES OF SELECTED FILTER GOES HERE
  searchValBillingRule: "",
  selectedDropdownItemBillingRule: "",
  selectedItemIndexFilterBillingRule: "",
  selectedItemIndexSortBillingRule: "",
  sortByBillingRuleR: "",
  selectedFilterItemsBillingRule: [],
  // End

  // For Details Page
  UniqueBillingRuleDetails: {},
};

export const getBillingRule = createAsyncThunk(
  "billing-rule/fetchBillingRule",
  async () => {
    try {
      const { data } = await api.get(
        `billing_rules?paginate=true&page=1&limit=10000`
      );
      return data?.data;
    } catch (error) {
      throw error;
    }
  }
);
export const updateBillingDetailsThunk = createAsyncThunk(
  "billling-rule/updateBilling",
  async (billingData: any, { rejectWithValue }) => {
    try {
      const response = await api.post(
        "/billing_rule_sets/update_billing_rule_set",
        billingData
      );
      return response.data;
    } catch (error: string | any) {
      return rejectWithValue(
        error.message || "Failed to upload client details"
      );
    }
  }
);
export const getBillingRuleByFilterThunk = createAsyncThunk(
  "billing-rule/fetchBillingRuleByFilter",
  async ({
    filterColumn,
    filterValue,
    searchVal,
    sortColumn,
    sortType,
    // status,
    billingRulePage,
  }: {
    filterColumn: string | any;
    filterValue: string | any;
    searchVal: string | any;
    sortColumn: string | any;
    sortType: string | any;
    // status: string | any;
    billingRulePage: string | any;
  }) => {
    const encodedFilterValue = encodeURIComponent(filterValue);

    try {
      const response = await api.get(
        `/billing_rule_sets?paginate=true&page=${billingRulePage}&limit=50&search=${searchVal}&sort_column=${sortColumn}&sort_type=${sortType}&filter_column=${filterColumn}&filter_value=${encodedFilterValue}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getBillingRuleDetailsById = createAsyncThunk(
  "billing-rule/fetchBillingRuleDetailsById",
  async (bilingId: any) => {
    try {
      const response = await api.get(`/billing_rule_sets?id=${bilingId}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getBillingRuleNameThunk = createAsyncThunk(
  "billing-rule/fetchBillingRuleName",
  async () => {
    try {
      const { data } = await api.get(
        `billing_rule_sets?unique_column=set_name`
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
);
export const getBillingRuleAssociatedFundingThunk = createAsyncThunk(
  "billing-rulefetchBillingRuleAssociatedFunding",
  async () => {
    try {
      const { data } = await api.get(
        `billing_rule_sets?unique_column=associated_funding_type`
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
);
export const getBillingRuleRateThunk = createAsyncThunk(
  "billing-rule/fetchBillingRuleRate",
  async () => {
    try {
      const { data } = await api.get(
        `billing_rule_sets?unique_column=tax_rate`
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
);

export const updateBillingRuleSet = createAsyncThunk(
  "billing-rule/updateBillingRuleRate",
  async (BillingDetails: any) => {
    try {
      const { data } = await api.post(
        `billing_rule_sets/update_billing_rule_set`,
        BillingDetails
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
);

const BillingRuleSlice = createSlice({
  name: "billingRule",
  initialState,
  reducers: {
    // Pagination Start
    setBillingRulePage: (state) => {
      state.billingRulePage += 1;
    },
    resetBillingRulePage: (state) => {
      state.billingRulePage = 1;
    },
    resetBillingRuleData: (state) => {
      state.BillingRuleData = [];
    },
    setBillingRuleFilterItems: (state, action) => {
      state.billingRuleFilterItems = action.payload;
    },
    resetBillingRuleFilterItems: (state) => {
      state.billingRuleFilterItems = {
        // status: "1",
        filterColumn: "",
        filterValue: "",
        searchVal: "",
        sortColumn: "",
        sortType: "",
        billingRulePage: 1,
      };
    },
    // Pagination End
    // Filter Dropdown Start
    setSelectedDropdownItemBillingRule: (state, action) => {
      state.selectedDropdownItemBillingRule = action.payload;
    },
    resetSelectedDropdownItemBillingRule: (state) => {
      state.selectedDropdownItemBillingRule = "";
    },
    setSelectedItemIndexFilterBillingRule: (state, action) => {
      state.selectedItemIndexFilterBillingRule = action.payload;
    },
    resetSelectedItemIndexFilterBillingRule: (state) => {
      state.selectedItemIndexFilterBillingRule = "";
    },
    setSelectedItemIndexSortBillingRule: (state, action) => {
      state.selectedItemIndexSortBillingRule = action.payload;
    },
    resetSelectedItemIndexSortBillingRule: (state) => {
      state.selectedItemIndexSortBillingRule = "";
    },
    setSortByBillingRuleR: (state, action) => {
      state.sortByBillingRuleR = action.payload;
    },
    resetSortByBillingRuleR: (state) => {
      state.sortByBillingRuleR = "";
    },
    setSelectedFilterItemsBillingRule: (state, action) => {
      state.selectedFilterItemsBillingRule = action.payload;
    },
    resetSelectedFilterItemsBillingRule: (state) => {
      state.selectedFilterItemsBillingRule = "";
    },
    setSearchValueBillingRule: (state, action) => {
      state.searchValBillingRule = action.payload;
    },
    resetSearchValueBillingRule: (state) => {
      state.searchValBillingRule = "";
    },
    // Filter End
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBillingRule.pending, (state) => {
        state.loading = true;
      })
      .addCase(getBillingRule.fulfilled, (state, action) => {
        state.loading = false;
        state.BillingRuleData = action.payload;
      })
      .addCase(getBillingRule.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(getBillingRuleByFilterThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getBillingRuleByFilterThunk.fulfilled, (state, action) => {
        state.loading = false;
        // state.ExpessTypeData = action.payload;
        state.BillingRuleData = [
          ...state.BillingRuleData,
          ...action.payload.data.data,
        ];
        state.lastPage = action.payload.data.last_page;
      })
      .addCase(getBillingRuleByFilterThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getBillingRuleNameThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getBillingRuleNameThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.getBillingRuleName = action.payload.data.data;
      })
      .addCase(getBillingRuleNameThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getBillingRuleAssociatedFundingThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        getBillingRuleAssociatedFundingThunk.fulfilled,
        (state, action) => {
          state.loading = false;
          state.getBillingRuleAssociatedFunding = action.payload.data.data;
        }
      )
      .addCase(
        getBillingRuleAssociatedFundingThunk.rejected,
        (state, action) => {
          state.loading = false;
          state.error = action.error.message;
        }
      )
      .addCase(getBillingRuleRateThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getBillingRuleRateThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.getBillingRuleRate = action.payload.data.data;
      })
      .addCase(getBillingRuleRateThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getBillingRuleDetailsById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getBillingRuleDetailsById.fulfilled, (state, action) => {
        state.loading = false;
        state.UniqueBillingRuleDetails = action.payload?.data?.data[0];
      })
      .addCase(getBillingRuleDetailsById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const {
  resetBillingRuleData,
  resetBillingRuleFilterItems,
  resetBillingRulePage,
  resetSearchValueBillingRule,
  resetSelectedDropdownItemBillingRule,
  resetSelectedFilterItemsBillingRule,
  resetSelectedItemIndexFilterBillingRule,
  resetSelectedItemIndexSortBillingRule,
  resetSortByBillingRuleR,
  setBillingRuleFilterItems,
  setBillingRulePage,
  setSearchValueBillingRule,
  setSelectedDropdownItemBillingRule,
  setSelectedFilterItemsBillingRule,
  setSelectedItemIndexFilterBillingRule,
  setSelectedItemIndexSortBillingRule,
  setSortByBillingRuleR,
} = BillingRuleSlice.actions;

export default BillingRuleSlice.reducer;
