import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../../../services/api";

const initialState: any = {
  loading: false,
  error: "",
  MenuBuilderData: [],
  hasDeleteMenu: false,
  idOfMenuToDelete: null,
};

export const getMenuBuilderData = createAsyncThunk(
  "Menu/fetchMenuBuilderRecord",
  async (info: any ) => {
    
    try {
      const { data } = await api.get(`menus?sort_type=${info.sortType}&date=${info.date}`);
      return data.data;
    } catch (error) {
      throw error;
    }
  }
);

export const deleteMenuBuilderThunk = createAsyncThunk(
  "menu/delete",
  async (id: any) => {
    try {
      const response = await api.post(`/menus/delete_menu`, {
        menu_id: id,
      });
      if (response.status !== 200) {
        throw new Error("Failed to delete Menu.");
      }
      return response.data.data;
    } catch (error) {
      throw error;
    }
  }
);

const menuBuilder = createSlice({
  name: "menubuilder",
  initialState,
  reducers: {
    setConfirmDeleteMenu: (state, action) => {
      const { flag, id } = action.payload;
      state.hasDeleteMenu = flag;
      state.idOfMenuToDelete = id;
    },
    resetConfirmDeleteMenu: (state) => {
      state.hasDeleteMenu = false;
      state.idOfMenuToDelete = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMenuBuilderData.pending, (state) => {
        state.loading === true;
      })
      .addCase(getMenuBuilderData.fulfilled, (state, action) => {
        state.loading = false;
        state.MenuBuilderData = action.payload;
      })
      .addCase(getMenuBuilderData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { setConfirmDeleteMenu, resetConfirmDeleteMenu } =
  menuBuilder.actions;
export default menuBuilder.reducer;
