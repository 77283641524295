import React from "react";

export const PdfDocSvg = ({
  onClick,
  onClickDelete,
  width = "24",
  height = "24",
}: any) => {
  return (
    <div>
      <svg
        // onClick={(e) => handleClickRowDocuments(e, elem.id, "onAttachment")}
        onClick={onClick}
        width={width}
        height={height}
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        // onClick={() => handleGetFile(elem.key)}
      >
        <g clipPath="url(#clip0_3_2514)">
          <path
            d="M18 5.58691V16.8044C18 17.4653 17.4342 18 16.7374 18H1.26256C0.565812 18 0 17.4638 0 16.8044V1.19564C0 0.536243 0.565812 0 1.26256 0H12.1657"
            fill="#E13D35"
          />
          <path d="M12.166 0V5.58691H17.9987L12.166 0Z" fill="#AF1010" />
          <path
            d="M5.33594 10.396V7.60571H6.23999C6.58291 7.60571 6.8058 7.61974 6.91024 7.6478C7.07078 7.68989 7.20327 7.7803 7.31238 7.9206C7.41993 8.0609 7.47449 8.24172 7.47449 8.46464C7.47449 8.63611 7.44332 8.77952 7.38097 8.89644C7.31862 9.01335 7.23912 9.10532 7.14404 9.17079C7.04896 9.23783 6.95076 9.28147 6.85256 9.3033C6.71851 9.3298 6.52368 9.34383 6.26805 9.34383H5.90019V10.396H5.3375H5.33594ZM5.89863 8.07804V8.86994H6.20726C6.42859 8.86994 6.57823 8.85591 6.65305 8.82629C6.72787 8.79667 6.7871 8.75147 6.82918 8.68911C6.87127 8.62676 6.89309 8.55505 6.89309 8.47243C6.89309 8.37111 6.86347 8.28693 6.80424 8.22146C6.74501 8.15599 6.66864 8.1139 6.57823 8.09831C6.51121 8.08584 6.3756 8.0796 6.17297 8.0796H5.90019L5.89863 8.07804Z"
            fill="white"
          />
          <path
            d="M7.93262 7.60571H8.96137C9.19362 7.60571 9.36975 7.62286 9.49289 7.65871C9.65655 7.70704 9.79684 7.79277 9.91374 7.91592C10.0306 8.03907 10.1195 8.19028 10.1803 8.36799C10.2411 8.5457 10.2722 8.76549 10.2722 9.02738C10.2722 9.25653 10.2442 9.4545 10.1865 9.6213C10.1164 9.82395 10.0166 9.98919 9.88724 10.1139C9.78904 10.209 9.65811 10.2838 9.49133 10.3368C9.36663 10.3758 9.20141 10.396 8.99254 10.396H7.93262V7.60571ZM8.49531 8.07804V9.92528H8.91616C9.07359 9.92528 9.18738 9.91592 9.25752 9.89878C9.34949 9.87539 9.4243 9.83798 9.48509 9.78342C9.54588 9.72886 9.5942 9.63845 9.63317 9.51374C9.67214 9.38903 9.69084 9.21912 9.69084 9.00244C9.69084 8.78576 9.67214 8.62052 9.63317 8.50517C9.59576 8.38981 9.54121 8.2994 9.47262 8.23549C9.40404 8.17002 9.31675 8.12637 9.21232 8.10454C9.13438 8.0874 8.98007 8.07804 8.74938 8.07804H8.49687H8.49531Z"
            fill="white"
          />
          <path
            d="M10.7529 10.396V7.60571H12.6655V8.07804H11.3172V8.73899H12.4815V9.21132H11.3172V10.396H10.7545H10.7529Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_3_2514">
            <rect width="18" height="18" fill="white" />
          </clipPath>
        </defs>
      </svg>
      <svg
        onClick={onClickDelete}
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="cross-icon-table"
      >
        <path
          d="M18 6L6 18M6 6L18 18"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export const DocxSvg = ({
  onClick,
  onClickDelete,
  width = "24",
  height = "24",
}: any) => {
  return (
    <div>
      <svg
        // onClick={(e) => handleClickRowDocuments(e, elem.id, "onAttachment")}
        onClick={onClick}
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        // onClick={() => handleGetFile(elem.key)}
      >
        <g clipPath="url(#clip0_177_7386)">
          <path
            d="M24 7.44921V22.4058C24 23.2871 23.2456 24 22.3166 24H1.68341C0.754416 24 0 23.285 0 22.4058V1.59418C0 0.714991 0.754416 0 1.68341 0H16.221"
            fill="#06A5FF"
          />
          <path d="M16.221 0V7.44921H23.9979L16.221 0Z" fill="#0088C4" />
          <path
            d="M17.5345 10.2637H6.46553V11.3029H17.5345V10.2637Z"
            fill="white"
          />
          <path
            d="M17.5345 12.6997H6.46553V13.7389H17.5345V12.6997Z"
            fill="white"
          />
          <path
            d="M17.5345 15.1357H6.46553V16.175H17.5345V15.1357Z"
            fill="white"
          />
          <path
            d="M12.8001 17.5718H6.46553V18.611H12.8001V17.5718Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_177_7386">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
      <svg
        onClick={onClickDelete}
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="cross-icon-table"
      >
        <path
          d="M18 6L6 18M6 6L18 18"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};
export const AllDocSvg = ({
  onClick,
  onClickDelete,
  width = "24",
  height = "24",
}: any) => {
  return (
    <div>
      {" "}
      <svg
        // onClick={(e) => handleClickRowDocuments(e, elem.id, "onAttachment")}
        onClick={onClick}
        width={width}
        height={height}
        viewBox="0 0 19 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_3_2498)">
          <path
            d="M18.334 5.58691V16.8044C18.334 17.4653 17.7682 18 17.0714 18H1.59654C0.899797 18 0.333984 17.4638 0.333984 16.8044V1.19564C0.333984 0.536243 0.899797 0 1.59654 0H12.4997"
            fill="#2AD400"
          />
          <path d="M12.5 0V5.58691H18.3327L12.5 0Z" fill="#24A500" />
          <path
            d="M4.79395 7.69751V13.9594H13.875V7.69751H4.79395ZM13.0956 9.52448H9.72415V8.47693H13.0956V9.52448ZM9.72415 10.3039H13.0956V11.3514H9.72415V10.3039ZM8.94479 11.3514H5.5733V10.3039H8.94479V11.3514ZM8.94479 8.47693V9.52448H5.5733V8.47693H8.94479ZM5.5733 12.1309H8.94479V13.1784H5.5733V12.1309ZM9.72415 13.1784V12.1309H13.0956V13.1784H9.72415Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_3_2498">
            <rect
              width="18"
              height="18"
              fill="white"
              transform="translate(0.333984)"
            />
          </clipPath>
        </defs>
      </svg>
      <svg
        onClick={onClickDelete}
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="cross-icon-table"
      >
        <path
          d="M18 6L6 18M6 6L18 18"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};
