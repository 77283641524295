import React from "react";

interface CommentWithNumberSVGProps {
  number?: number;
  fill?: string
}

const CommentWithNumberSVG = ({ number, fill }: CommentWithNumberSVGProps) => {
  const typeColors: { [key: string]: string } = {
    Green: "#2AD400",
    Yellow: "#F1B60D",
    Red: "#EF3A34",
    Blue: "#06A5FF",
  };

  // @ts-ignore
  const fillColor = typeColors[fill];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 12 12"
      fill="none"
    >
      <path
        d="M6 0C9.3138 0 12 2.6862 12 6C12 9.3138 9.3138 12 6 12C5.0424 12.0013 4.0985 11.7725 3.2478 11.3328C2.97312 11.1438 2.6433 11.1219 2.35089 11.2821C1.79004 11.5894 0.888309 12.0621 0.560239 11.976C0.431624 11.9423 0.314294 11.875 0.220271 11.7809C0.126247 11.6869 0.0589109 11.5696 0.0251613 11.441C-0.00858834 11.3123 -0.00753788 11.1771 0.028205 11.049L0.669005 8.75519C0.228216 7.90376 -0.00124128 6.95877 5.05016e-06 6C5.05016e-06 2.6862 2.6862 0 6 0ZM4.05 6.6H6.7488C6.86282 6.60003 6.97257 6.64334 7.05588 6.72118C7.13919 6.79902 7.18985 6.90558 7.19762 7.01932C7.20539 7.13307 7.16969 7.24553 7.09774 7.33398C7.02579 7.42242 6.92295 7.48026 6.81 7.4958L6.7488 7.5H4.05C3.93599 7.49996 3.82624 7.45665 3.74293 7.37881C3.65962 7.30098 3.60896 7.19442 3.60119 7.08067C3.59342 6.96692 3.62911 6.85446 3.70106 6.76602C3.77301 6.67757 3.87585 6.61974 3.9888 6.6042L4.05 6.6ZM4.05 4.5H7.953C8.06697 4.50018 8.17661 4.5436 8.25981 4.62149C8.34301 4.69937 8.39355 4.80592 8.40124 4.91962C8.40894 5.03333 8.3732 5.14571 8.30125 5.23409C8.2293 5.32247 8.1265 5.38026 8.0136 5.3958L7.953 5.4H4.05C3.93599 5.39996 3.82624 5.35665 3.74293 5.27881C3.65962 5.20098 3.60896 5.09442 3.60119 4.98067C3.59342 4.86692 3.62911 4.75446 3.70106 4.66602C3.77301 4.57757 3.87585 4.51974 3.9888 4.5042L4.05 4.5Z"
        fill={fillColor}
      />
      <text
        x="6"
        y="6"
        fill="#FFF"
        textAnchor="middle"
        fontFamily="Arial"
        fontSize="6px"
        fontStyle="normal"
        fontWeight="500"
        letterSpacing="0.25px"
        alignmentBaseline="central"
      >
        {number}
      </text>
    </svg>
  );
};

CommentWithNumberSVG.deafultProps = {
  number: 0,
};

export default CommentWithNumberSVG;
