import "./Styles/Villages.css";

import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import {
  useAppDispatch,
  useAppSelector,
} from "../../../Controller/redux/Hooks";
import CommonReportingWidget from "../../../View/components/ReportingWidget/ReportingWidget";
import { getClientsThunk } from "../../../Controller/redux/features/ClientFeature/clientsSlice";
import {
  getLastUpdateClientThunk,
  getStatusStatsProspectsThunk,
  getStatusStatsThunk,
  getVillageThunk,
} from "../../../Controller/redux/features/VillageFeature/villageSlice";
import RightArrowSvg from "../../../Model/Assets/SVG/WidgetVillage/RightArrowSvg";
import ClientStatus from "../../components/ClientStatus/ClientStatus";
import CircularNoteTrigger from "../../components/CircularNoteTrigger/CircularNoteTrigger";
import CommonUpperBox from "../../components/UpperBox/CommonHeader";
import CommonQuickMenu from "../../components/CommonQuickMenu/CommonQuickMenu";

import { statusMapClient } from "../../../Model/utils/Helper/statusMap";
import ClientStatusVillage from "../../components/ClientStatus/ClientStatusVillage";
import {Spinner} from "@chakra-ui/react";

const Villages = () => {
  // @ts-ignore
  const dynamicClientId = parseInt(localStorage.getItem("dynamicClientId"));
  // @ts-ignore
  const dynamicProspectId = parseInt(localStorage.getItem("dynamicProspectId"));

  const dispatch = useAppDispatch();
  const { dynamicColor, domainDetail } = useAppSelector(
    (state) => state?.common
  );
  const { getVillageData } = useAppSelector((state) => state?.villages);
  console.log(getVillageData);

  useEffect(() => {
    dispatch(getVillageThunk());
  }, []);

  const [ isLoading , setisLoading] = useState(false);

  const { getStatusStats, getLastUpdatedClients, getStatusStatsProspect } =
    useAppSelector((state) => state?.villages);

    const b2bCondition = domainDetail?.businessmodel_type === "B2B";
    let barArr: any[] = [];
    if (Array.isArray(getStatusStats.data)) {
      for (let item of getStatusStats.data) {
        if (item.status === "5") {
          // @ts-ignore
          let itemAtIndexOne = parseInt(barArr[1] || 0);
          let countCurrent = parseInt(item.count);
          let total = itemAtIndexOne + countCurrent;

          barArr.splice(1, 1, total);
        } else {
          barArr.push(parseInt(item.count));
        }
      }
    }
    useEffect(() => {
      dispatch(getClientsThunk({ dynamicClientId }));
      setisLoading(true)
      dispatch(getLastUpdateClientThunk(dynamicClientId)).then((res: any) => {
        if(res.payload.status === "success"){
          setisLoading(false)
        }else{
          setisLoading(false)
        }
      });
      dispatch(
        getStatusStatsThunk({ dynamicId: dynamicClientId, status: "1,2,3,5" })
      );
      dispatch(
        getStatusStatsProspectsThunk({
          dynamicId: dynamicProspectId,
          status: "12,13,4",
        })
      );
    }, [dynamicClientId, dynamicProspectId]);

    // const getAllClientsDataThunk = useAppSelector(
    //   (state) => state?.people?.allData
    // );
    const [stageTwoToGoalsModal, setstageTwoToGoalsModal] = useState(false);

    const VillageTabArr = useMemo(() => {
      return [
        {
          chartWidth: "60%",
          chartHeight: "auto",
          title: "Status",
          data_1: barArr || [],
          chartType: "bar",
          heading: "By Location ",
          labels: ["Active", "On Leave", "Hospital"],
          color: "#97D94A",
          statusData: [
            { data: "At Home", color: "#6AE24C" },
            { data: "On Leave", color: "#FFFF5B" },
            { data: "Hospital", color: "#d99c23" },
          ],
          dates_X: getStatusStats?.data?.map(
            (elem: any) => statusMapClient[elem.status]?.label
          ),
          displayX: true,
          displayY: true,
          name: "Clients",
          providerName: getStatusStats?.provider_name,
        },
        {
          chartWidth: "45%",
          chartHeight: "150px",
          title: "Status",
          data_1: getStatusStatsProspect?.data?.map((elem: any) => elem.count),
          chartType: "doughnut",
          heading: "By Status",
          labels: ["Open", "In Progress", "Waitlist"],
          color: "#97D94A",
          statusData: [
            { data: "Open", color: "#6AE24C" },
            { data: "In Progress", color: "#FFFF5B" },
            { data: "Waitlist", color: "rgb(217, 156, 35)" },
          ],
          dates_X: getStatusStatsProspect?.data?.map(
            (elem: any) => statusMapClient[elem.status]?.label
          ),
          name: "Prospects",
          providerName: getStatusStatsProspect?.provider_name,
        },
      ];
    }, [getStatusStats, getStatusStatsProspect]);

    const renderClinicalWidgets = useCallback(() => {
      return (
        <div className="village_widgets_wrapper">
               
          {VillageTabArr?.map((elem: any, index: number) => (
            <Fragment key={index}>
              <CommonReportingWidget
                isFromVillage={index === 1 ? false : true}
                statusData={elem?.statusData}
                labels={elem.labels}
                key={index}
                title={elem.heading}
                data_2={elem.data_2}
                data={elem.data_1}
                name={elem?.name}
                chartType={elem.chartType}
                chartLabel={"Status"}
                pointRadius={3}
                chartSize={"medium"}
                heading={elem.heading}
                chartWidth={elem.chartWidth}
                chartHeight={elem.chartHeight}
                pieChartBackgroundColors={["#6AE24C", "#FFFF5B", "#d99c23"]}
                borderWidth={1}
                // displayX={elem?.displayX}
                displayY={elem?.displayY}
                providerName={elem?.providerName}
              />
            </Fragment>
          ))}
        </div>
      );
    }, [VillageTabArr]);
    return (
      <>
        <div>
          {/* <UpperBox type="village" /> */}
          <CommonUpperBox
            title={"Village"}
            type={"listing"}
            backgroundWithText
            text={"V D"}
            onClickPlus={() => {
              setstageTwoToGoalsModal(true);
            }}
            // child={renderClinicalWidgets()}
          />
          <div className="village_page_wrapper">
            <div className="village_flex">
              {domainDetail?.has_recentchanges != 0 && (
                <div className="village_recent_changes_wrapper">
                  <div className="header_section_wrapper">
                    <div className="left_side_header_section">
                      <div className="section_1_wrapper">
                        <div className="title_wrapper">
                          <div className="text_with_backgroud_wrapper">
                            <h1>Recent Changes</h1>
                          </div>
                        </div>
                      </div>

                      <div className="sub_title_wrapper"></div>
                    </div>
                  </div>
                  <div className="content_section_wrapper_vil">
                    <div className="village_widget_container_update ">
                      {isLoading && !getLastUpdatedClients?.data?.length && (
                        <Spinner
                          position={"absolute"}
                          left={"40%"}
                          top={"50px"}
                        />
                      )}
                      {getLastUpdatedClients?.data?.map(
                        (elem: any, index: number) => (
                          <div key={index} className="village_update_wrapper">
                            <div
                              className="village_widget_updated"
                              style={{
                                // border: `1px dashed ${dynamicColor}`,
                                padding: "15px 5px",
                              }}
                              key={elem.id}
                            >
                              <ClientStatus
                                isResidence={true}
                                residence={elem?.residence_name}
                                title={elem?.first_name}
                                imageURL={elem?.profile_image_s3}
                                status={elem?.old_status}
                                date={elem?.old_status_time}
                                time={elem?.old_status_time}
                                backgroundWithText={`${elem?.first_name?.[0]} ${
                                  b2bCondition ? "" : elem?.last_name?.[0]
                                }`}
                              />
                              <div className="village_widget_arrow">
                                <RightArrowSvg dynamicColor={dynamicColor} />
                              </div>
                              <ClientStatus
                                imageURL={elem?.profile_image_s3}
                                title={elem?.first_name}
                                status={elem?.current_status}
                                date={elem?.current_status_time}
                                time={elem?.current_status_time}
                                backgroundWithText={`${elem?.first_name?.[0]} ${
                                  b2bCondition ? "" : elem?.last_name?.[0]
                                }`}
                              />
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </div>
              )}
              <div className="">{renderClinicalWidgets()}</div>
            </div>
            <div className="village_recent_changes_wrapper">
              <div className="header_section_wrapper">
                <div className="left_side_header_section">
                  <div className="section_1_wrapper">
                    <div className="title_wrapper">
                      <div className="text_with_backgroud_wrapper">
                        <h1>All Residents</h1>
                      </div>
                    </div>
                  </div>
                  <div className="sub_title_wrapper">
                    {/* <p>
                    ‘Event Name’: <span> 10:00 am - 15:30 pm </span>
                  </p> */}
                  </div>
                </div>
                {/* <div className="right_side_header_section">
                <p>Last update: Today 13:03</p>
      
              </div> */}
              </div>
              <div className="village_widget_container_res">
                {getVillageData?.map((elem: any, index: number) =>
                  elem?.users && elem?.users.length > 0 ? (
                    elem?.users?.map((user: any, userIndex: number) => (
                      <ClientStatusVillage
                        isResidence={true}
                        residence={elem?.first_name}
                        key={`${index}-${userIndex}`}
                        title={`${user?.first_name} ${user?.last_name}`}
                        status={user?.status}
                        date={user?.created_at}
                        time={user?.created_at}
                        imageURL={user?.profile_image_s3}
                        isUserAvailable={elem?.users?.length > 0}
                        backgroundWithText={`${
                          user?.first_name ? user?.first_name?.[0] : ""
                        } ${b2bCondition ? "" : user?.last_name?.[0]}`}
                      />
                    ))
                  ) : (
                    <ClientStatusVillage
                      key={index}
                      isResidence={true}
                      residence={elem?.first_name}
                      isUserAvailable={elem?.users?.length > 0}
                    />
                  )
                )}
              </div>
            </div>
          </div>
          <CircularNoteTrigger 
              type="stickyHub"
              title="Comms"
              isOutside
              extraType="globalCommsBox"
          />
          {stageTwoToGoalsModal && (
            <CommonQuickMenu
              setshowGoalsModal={(value: any) => {}}
              mainType={"listing"}
              setstageTwoToGoalsModal={setstageTwoToGoalsModal}
            />
          )}
        </div>
      </>
    );
};

export default React.memo(Villages);