import { useRef, useState } from "react";
import { PlusSVG } from "../../../pages/Roster/Data/PlusSVG";
import { GoalsPlusSVG } from "../Data/SVGs";
import ExpandedNote from "../../ClinicalProgress/Components/ExpandedNote";
import ActivityDialog from "./ActivityDialog";
import CollapsedNote from "../../ClinicalProgress/Components/CollapsedNote";
import {
  createGoalsThunk,
  getGoalsThunk,
  updateGoalsThunk,
} from "../../../../Controller/redux/features/ClinicalFeature/clinicalSlice";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../Controller/redux/Hooks";
import { toast } from "react-toastify";
import TagsInput from "../../Tags/TagBuilder";
import useWindowSize from "../../../../Model/utils/useWindowSize";

const GoalPlanner = ({
  goalsData,
  setGoalsData,
  clientId,
  dynamicColor,
  isThereAnyChangesGoal,
  setisThereAnyChangesGoal,
  selectedTags,
  setSelectedTags,
  expandedNote,
  setIsExpandedNote,
  expandedGoal,
  setexpandedGoal,
  maxHeight,
  showTags,
  setShowTags,
}: any) => {
  const dispatch = useAppDispatch();

  const allTags = useAppSelector((state) => state?.common?.allTags);

  const expandedGoalsRef = useRef<HTMLDivElement | null>(null);

  const [isHoveredForAdd, setIsHoveredForAdd] = useState(false);
  const [goalTitles, setgoalTitles] = useState<string[]>([""]);

  const topMargin = 60000 / useWindowSize().height;

  const handleMouseEnterForAdd = () => {
    setIsHoveredForAdd(true);
  };

  const handleMouseLeaveForAdd = () => {
    setIsHoveredForAdd(false);
  };

  const handleAddGoal = async () => {
    const nextGoalId = goalsData.length + 1;
    const newGoal = {
      user_id: clientId,
      title: `Goal ${nextGoalId}`,
      summary: "",
      activities: [],
    };
    const updatedGoalsData = [...goalsData, newGoal];
    setGoalsData(updatedGoalsData);
    setgoalTitles([...goalTitles, ""]);
    try {
      const res = await dispatch(createGoalsThunk({ data: newGoal }));
      if (res?.payload?.status === "success") {
        toast.success("Goal Created Successfully!", {
          style: {},
          progressStyle: { backgroundColor: dynamicColor },
          icon: (
            <span
              style={{
                marginRight: "8px",
                borderRadius: "50%",
                width: "7rem",
                height: "35px",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: dynamicColor,
                backgroundColor: "white",
                fontSize: "1.5rem",
                border: `3px solid ${dynamicColor}`,
              }}
            >
              ✓
            </span>
          ),
        });
      }
    } catch (error) {
      toast.error("Failed To Create Goal!");
    }

    await dispatch(getGoalsThunk({ clientId }));
  };

  const handleSelectedTagIdsChange = async (newSelectedTagIds: any) => {
    setisThereAnyChangesGoal(true);
    const newSelectedTags = [...newSelectedTagIds];
    setSelectedTags(newSelectedTags);
    const updatedGoalsData = JSON.parse(JSON.stringify(goalsData));

    let currentGoal = updatedGoalsData[expandedNote];
    let currentGoalTags = updatedGoalsData[expandedNote]?.tag;
    currentGoalTags = [...selectedTags];

    const updatedCurrentGoal = {
      ...currentGoal,
      goal_id: currentGoal?.id,
      tag: newSelectedTags,
    };

    let res = await dispatch(updateGoalsThunk({ data: updatedCurrentGoal }));

    await dispatch(getGoalsThunk({ clientId }));
  };

  async function handleUpdateGoal() {
    const updatedGoalsData = JSON.parse(JSON.stringify(goalsData));
    let currentGoal = updatedGoalsData[expandedNote];
    let currentGoalTags = updatedGoalsData[expandedNote]?.tag;
    currentGoalTags = [...selectedTags];

    const updatedCurrentGoal = {
      ...currentGoal,
      goal_id: currentGoal?.id,
    };

    let res = await dispatch(updateGoalsThunk({ data: updatedCurrentGoal }));
    if (res?.payload?.status === "success") {
      toast.success("Goal Updated Successfully !", {
        progressStyle: { backgroundColor: dynamicColor },
        icon: (
          <span
            style={{
              marginRight: "8px",
              borderRadius: "50%",
              width: "7rem",
              height: "35px",
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: dynamicColor,
              backgroundColor: "white",
              fontSize: "1.5rem",
              border: `3px solid ${dynamicColor}`,
            }}
          >
            ✓
          </span>
        ),
      });
    }
    await dispatch(getGoalsThunk({ clientId }));
  }

  const handleNoteClick = (index: any) => {
    setIsExpandedNote((prev: any) => (prev === index ? null : index));
  };

  return (
    <div
      ref={expandedGoalsRef}
      className={` expanded_goals_section ${
        showTags[expandedNote] ? "is_tags_active" : ""
      }`}
    >
      <div className="goals_heading">
        <div className="lower_goals_section-1">
          <span className="flex_row">
            <span className="goal_planner_heading"> Goal Planner</span>
            <span className="mx-3px">
              {expandedGoal ? (
                <span
                  className="minus_wrapper"
                  onClick={() => setexpandedGoal(false)}
                >
                  -
                </span>
              ) : (
                <PlusSVG />
              )}
            </span>
          </span>
        </div>
        <div className="add_goals">
          <div
            className="add_activity_button"
            onMouseEnter={handleMouseEnterForAdd}
            onMouseLeave={handleMouseLeaveForAdd}
          >
            <GoalsPlusSVG isHovered={isHoveredForAdd} onClick={handleAddGoal} />
          </div>
        </div>
      </div>

      <div className="goals_container" style={{ top: topMargin }}>
        {goalsData &&
          goalsData.length > 0 &&
          goalsData?.map((goal: any, index: number) => {
            const isExpanded = index === expandedNote;

            return (
              <div className="goal_container">
                {isExpanded ? (
                  <>
                    {showTags[expandedNote] && (
                      <div className="tag_wrapper_box">
                        <TagsInput
                          isGoalsModal={true}
                          allTags={allTags}
                          selectedTags={selectedTags}
                          setSelectedTags={(info: any) => setSelectedTags(info)}
                          onSelectTagIdChange={handleSelectedTagIdsChange}
                          selectedTask={goalsData[expandedNote]}
                        />
                      </div>
                    )}
                    <div
                      style={{
                        maxHeight: `${maxHeight}px`,
                        opacity: isExpanded ? 1 : 1,
                        transition: "max-height 1s ease, opacity 0.3s ease",
                      }}
                      className={`expanded_goal_with_activities`}
                    >
                      <ExpandedNote
                        setshowTags={setShowTags}
                        showTags={showTags}
                        forSticky={false}
                        activities={goal?.activities}
                        title={goal?.title}
                        currentGoal={goal}
                        selectedTags={goal?.tags}
                        goalIndex={index}
                        isOpen={index === expandedNote}
                        onClickPlus={() => {
                          setIsExpandedNote(-1);
                        }}
                        goalInfo={goal}
                        handelSaveNote={() => {}}
                        isGoalsModal={true}
                        isTagsActive={showTags[index]}
                        setGoalsData={setGoalsData}
                        index={index}
                        goal={goal}
                        goalsData={goalsData}
                        // selectedTags={goal?.tags}
                        setisThereAnyChangesGoal={setisThereAnyChangesGoal}
                        isThereAnyChangesGoal={isThereAnyChangesGoal}
                      />
                      <ActivityDialog
                        goalIndex={index}
                        goalsData={goalsData}
                        selectedTags={selectedTags}
                        setGoalsData={setGoalsData}
                        currentGoal={goal}
                        setisThereAnyChangesGoal={setisThereAnyChangesGoal}
                      />

                      <button
                        onClick={handleUpdateGoal}
                        className="save_btn save_btn_goal"
                      >
                        Save
                      </button>
                    </div>
                  </>
                ) : (
                  <div className="collapsed_note">
                    <CollapsedNote
                      isOpen={index === expandedNote}
                      forSticky={false}
                      index={index}
                      title={goal?.title}
                      selectedTags={goal?.tags}
                      isGoalsModal={true}
                      goal={goal}
                      onClickPlus={() => handleNoteClick(index)}
                    />
                  </div>
                )}
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default GoalPlanner;
