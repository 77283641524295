import React, { useContext, useEffect, useState } from "react";
import { dummyArrContacts } from "../../../../pages/People/ClientPage/Components/UniqueClientScreen/Data/ClientScreenContentArr";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../Controller/redux/Hooks";
import DropdownIcon from "../../../../../Model/Assets/png/drop.png";
import {
  getContactByIdThunk,
  updateContactThunk,
} from "../../../../../Controller/redux/features/ContactsFeature/contactsSlice";
import { toast } from "react-toastify";
import { setunsavedChanges } from "../../../../../Controller/redux/features/AuthFeature/authSlice";

const ContactDetailsSticky = ({ currentContact }: any) => {
  // @ts-ignore
  let dynamicContactId = parseInt(localStorage.getItem("dynamicContactId"));
  const dispatch = useAppDispatch();

  const { domainDetail } = useAppSelector((state) => state?.common);
  const [dynamicColor, setDynamicColor] = useState("");
  useEffect(() => {
    if (domainDetail) {
      setDynamicColor(domainDetail?.sticky_background_color);
    }
  }, [domainDetail]);

  const [details, setDetails] = useState<any>({
    givenName: "",
    middleName: "",
    familyName: "",
    knownAs: "",
    address: "",
    email: "",
    mobile: "",
    role: "",
  });

  useEffect(() => {
    const fetchedData: any = currentContact;
    if (fetchedData) {
      setDetails({
        givenName: currentContact?.first_name,
        middleName: currentContact?.middle_name,
        familyName: currentContact?.last_name,
        knownAs: currentContact?.pref_name
          ? currentContact?.pref_name
          : currentContact?.first_name,
        address: currentContact?.user_addresses[0]?.address || "",
        email: currentContact?.email,
        mobile: currentContact?.mobile !== null ? currentContact?.mobile : "",
        role: currentContact ? currentContact?.roles[0]?.id : "",
      });
    } else {
      setDetails({
        givenName: "",
        familyName: "",
        knownAs: "",
        address: "",
        email: "",
        mobile: "",
        role: "",
      });
    }
  }, [currentContact]);

  const [updatecontact, setUpdateContact] = useState<any>({
    first_name: "",
    middle_name: "",
    last_name: "",
    pref_name: "",
    email: "",
    mobile: "",
    // phone: "",
    dob: "",
    funding_type: "",
    package_level: "",
    funding_available: "",
    acat_assessment: "",
    initial_enquiry: "",
    role: 4,
    user_id: currentContact?.id,
    type: 9,
    address: "",
  });

  useEffect(() => {
    // console.log(details, "mehhh");
    setUpdateContact({
      first_name: details.givenName,
      middle_name: details.middleName,
      last_name: details.familyName,
      pref_name: details.knownAs,
      address: details.address,
      dob: "",
      mobile: details.mobile,
      package_level: "",
      funding_available: "",
      acat_assessment: "",
      initial_enquiry: "",
      email: details.email,
      funding_type: "",
      role: details?.role,
      user_id: currentContact?.id,
      type: dynamicContactId,
      phone: currentContact?.phone,
    });
  }, [details]);

  ////////////////////////////////////////////////////////////////////// FETCH USER ROLES ////////////////////////////////////////////////////////////////////
  const getUserRoles = useAppSelector((state) => state?.contacts?.contactRoles);
  const [userRoles, setUserRoles] = useState([]);

  useEffect(() => {
    if (getUserRoles?.data && getUserRoles?.data?.length > 0) {
      setUserRoles(getUserRoles?.data);
    }
  }, [getUserRoles]);

  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  // CHANGE TO INPUT FIELDS
  const handleChange = (
    index: number,
    e: React.ChangeEvent<HTMLInputElement> | any
  ) => {
    const { value } = e.target;

    const fieldName = Object.keys(details)[index];

    setDetails((prevState: any) => ({
      ...prevState,
      [fieldName]: value,
    }));
    dispatch(setunsavedChanges(true));

    if (!hasUnsavedChanges) {
      setHasUnsavedChanges(true);
    }
  };

  const [selectedAddress, setSelectedAddress] = useState<any>("");
  const [possibleAddresses, setPossibleAddresses] = useState<any>([]);

  function initService(query: any) {
    const displaySuggestions = function (predictions: any, status: any) {
      // @ts-ignore
      if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
        // console.log("Location service down!");
        return;
      }
      if (!predictions) {
        setPossibleAddresses([]);
        return;
      }
      setPossibleAddresses(predictions);
    };
    // @ts-ignore
    const service = new window.google.maps.places.AutocompleteService();
    // console.log(query);
    service.getQueryPredictions({ input: query }, displaySuggestions);
  }

  const handleChangeForAddress = (index: number, e: any) => {
    const { value } = e.target;
    setDetails({ ...details, address: value });
    initService(value);
    if (value.length === 0) {
      setDetails({ ...details, address: "" });
      setPossibleAddresses([]);
    }
    // setDetails((prevState: any) => ({
    //   ...prevState,
    //   [fieldName]: value,
    // }));

    // setunsavedChanges(true);
    // if (!hasUnsavedChanges) {
    //   setHasUnsavedChanges(true);
    // }
  };

  const selectAddress = (e: any) => {
    if (!hasUnsavedChanges) {
      setHasUnsavedChanges(true);
    }
    setSelectedAddress(e.target.innerHTML);
    setDetails({ ...details, address: e.target.innerHTML });
    dispatch(setunsavedChanges(true));
    setPossibleAddresses([]);
  };

  const handleSaveChanges = async () => {
    setHasUnsavedChanges(false);
    dispatch(setunsavedChanges(false));
    try {
      const res = await dispatch(updateContactThunk(updatecontact));
      dispatch(
        getContactByIdThunk({
          typeId: dynamicContactId,
          clientId: currentContact?.id,
        })
      );
      if (res.payload.status === "success") {
        toast.success("Contact updated successfully!", {
          progressStyle: { backgroundColor: dynamicColor },
          icon: (
            <span
              style={{
                marginRight: "8px",
                borderRadius: "50%",
                width: "7rem",
                height: "35px",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: dynamicColor,
                backgroundColor: "white",
                fontSize: "1.5rem",
                border: `3px solid ${dynamicColor}`,
              }}
            >
              ✓
            </span>
          ),
        });
      } else {
        toast.error("An Error Occurred!");
      }
    } catch (error) {
      console.error(error);
      toast.error("An Error Occurred!");
    }
  };

  return (
    <div className="staff_details_section_wrapper_sticky_note">
      <div className=" client-screen-personal-details-heading">
        <span className=" client-screen-sub-heading-title">
          Personal Details
        </span>
        <span className=" client-screen-line"></span>
      </div>
      <div className="main-box-body">
        {dummyArrContacts.map((elem: any, index: any) => {
          const fieldName = Object.keys(details)[index];

          return (
            <div className="content-wrapper" key={index}>
              <div className="wrapper-title">{elem.UpperTitle}</div>

              {index === 7 ? (
                <select
                  className="wrapper-info wrapper-info-select-dropdown"
                  onChange={(e) => handleChange(index, e)}
                  value={details[fieldName]}
                  style={{
                    border: "none",
                    outline: "none",
                    height: "fit-content",
                    backgroundColor: "white",
                    // width: "256px",
                  }}
                >
                  <option>Please Select...</option>

                  {userRoles?.map((elem: any, index: number | any) => (
                    <>
                      <option key={index} value={elem?.id}>
                        {elem?.desc}
                      </option>
                    </>
                  ))}
                </select>
              ) : index === 4 ? (
                /*furkan location*/
                <>
                  <div>
                    <input
                      type="text"
                      onChange={(e) => handleChangeForAddress(index, e)}
                      className="wrapper-info"
                      placeholder="Enter an address"
                      value={details[fieldName]}
                    />
                    <div className="address_listing">
                      <ul>
                        {possibleAddresses.length > 0
                          ? possibleAddresses.map((address: any, ind: any) => {
                              return (
                                <li
                                  onClick={selectAddress}
                                  className="address_listing_item"
                                >
                                  {address.description}
                                </li>
                              );
                            })
                          : null}
                      </ul>
                    </div>
                  </div>
                </>
              ) : (
                <input
                  className="wrapper-info"
                  onChange={(e) => handleChange(index, e)}
                  value={details[fieldName]}
                  placeholder={elem?.placeHolder}
                  style={{
                    border: "none",
                    outline: "none",
                    height: "fit-content",
                  }}
                />
              )}

              {elem.index === 5 && (
                <div className="dropdown-icon">
                  <img src={DropdownIcon} alt="dropdown-icon" />
                </div>
              )}
              <div className="wrapper-line"></div>
            </div>
          );
        })}
        <div className="content-wrapper">
          {hasUnsavedChanges && (
            <button
              className="client_sticky_save_btn"
              style={{
                background: dynamicColor,
              }}
              onClick={() => {
                handleSaveChanges();
              }}
            >
              save
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ContactDetailsSticky;
