import React, { Fragment, useState, useRef, useEffect } from "react";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@chakra-ui/icons";
import "./CustomSlder.css";

const CustomSlider = () => {
  const items = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const containerRef = useRef<any>(null);
  const itemWidth = 109;

  const [containerWidth, setContainerWidth] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(0);

  const [startIndex, setStartIndex] = useState(0);

  const handlePrevClick = () => {
    if (startIndex > 0) {
      setStartIndex(startIndex - 1);
    }
  };

  const handleNextClick = () => {
    if (startIndex < items.length - itemsPerPage) {
      setStartIndex(startIndex + 1);
    }
  };

  const handleScroll = (e: any) => {
    const scrollAmount = e.deltaY;
    if (scrollAmount > 0 && startIndex < items.length - itemsPerPage) {
      handleNextClick();
    } else if (scrollAmount < 0 && startIndex > 0) {
      handlePrevClick();
    }
  };

  useEffect(() => {
    const updateDimensions = () => {
      if (containerRef.current) {
        const newContainerWidth = containerRef.current.offsetWidth;
        setContainerWidth(newContainerWidth);

        // Calculate items per page dynamically based on container width
        const newItemsPerPage = Math.floor(newContainerWidth / itemWidth);
        setItemsPerPage(newItemsPerPage);
      }
    };

    updateDimensions();

    window.addEventListener("resize", updateDimensions);

    return () => {
      window.removeEventListener("resize", updateDimensions);
    };
  }, []);

  return (
    <Fragment>
      <div className="custom-slider-container" onWheel={handleScroll}>
        <div className="custom-slider" ref={containerRef}>
          <div
            className="slider-inner"
            style={{
              width: `${items.length * itemWidth}px`,
              transform: `translateX(-${startIndex * itemWidth}px)`,
            }}
          >
            {items.map((item, index) => (
              <div key={item} className="slider-item">
                <div className="right-box">
                  <h5 className="right-box-upper-title">Sales Form:</h5>
                  <div className="right-box-lower-box">
                    <h5 className="right-box-lower-title">On Track</h5>
                    <span className="right-box-lower-box-vector"></span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        {startIndex > 0 && (
          <button
            className={`slider-button prev ${
              startIndex === 0 ? "disabled" : ""
            }`}
            style={{
              fontSize: "12px",
              transform: "translate(32%,-55%)",
            }}
            onClick={handlePrevClick}
          >
            <ArrowLeftIcon />
          </button>
        )}
        {startIndex < items.length - itemsPerPage && (
          <button
            className={`slider-button next ${
              startIndex >= items.length - itemsPerPage ? "disabled" : ""
            }`}
            onClick={handleNextClick}
            style={{
              fontSize: "12px",
              transform: "translate(-2%,-55%)",
            }}
          >
            <ArrowRightIcon />
          </button>
        )}
      </div>
    </Fragment>
  );
};

export default CustomSlider;
