import React, { Fragment, useEffect, useRef, useState } from "react";
import UpperBox from "../../../components/UpperBox/UpperBox";
import CommonTable from "../../../components/CommonTable/InputTable";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../Controller/redux/Hooks";
import {
  createMeal,
  getMealBuilder,
  getUniqueTagsMealsThunk,
  setSelectedMealDataObject,
  setopenMealBuilderForm,
} from "../../../../Controller/redux/features/MealsFeatures/MealFeature/mealbuilderSlice";
import UnsavedChangesPopup from "../../../components/UnsavedChangesPopup/UnsavedChangesPopup";
import { Modal, ModalOverlay } from "@chakra-ui/react";
import { toast } from "react-toastify";
import { api } from "../../../../Controller/services/api";
import MealsBuilderForm from "../../People/ClientPage/Components/UniqueClientScreen/Components/MealsTab/MealBuilderForm";
import CircularNoteTrigger from "../../../components/CircularNoteTrigger/CircularNoteTrigger";
import CommonHeader from "../../../components/UpperBox/CommonHeader";
import CommonUpperBox from "../../../components/UpperBox/CommonHeader";
import CommonQuickMenu from "../../../components/CommonQuickMenu/CommonQuickMenu";
import Table from "../../../components/CommonTable/Table";

const MealBuilder = () => {
  const openMealBuilderForm = useAppSelector(
    (state) => state.mealBuilder.openMealBuilderForm
  );

  const [openForm, setopenForm] = useState(false);
  // TABLE START//
  const dropdownRefMeals = useRef<any>();
  const svgParentRefMeals = useRef<any>();
  const [isDropdownOpenMeals, setIsDropdownOpenMeals] = useState(false);
  const { selectedFilterItems, sortbyMeals, searchValueMeals } = useAppSelector(
    (state) => state?.mealBuilder
  );
  const { filterIconColor } = useAppSelector((state) => state?.common);
  const handleClickOutsideMeals = (event: any) => {
    if (
      dropdownRefMeals.current &&
      !dropdownRefMeals.current.contains(event.target) &&
      svgParentRefMeals.current &&
      !svgParentRefMeals.current.contains(event.target)
    ) {
      setIsDropdownOpenMeals(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutsideMeals);
    return () => {
      document.removeEventListener("click", handleClickOutsideMeals);
    };
  }, []);
  const columns = [
    { header: "Meal Name", accessor: "meal_name" },
    { header: "Tags", accessor: "tags" },
    { header: "Prep Time", accessor: "prep_time" },
    { header: "Cooking Time", accessor: "cooking_time" },
    { header: "Attachment", accessor: "files" },
    { header: "Actions", accessor: "mealAction" },
  ];
  const mealsArr = useAppSelector(
    (state) => state.mealBuilder.MealsBuilderData?.data?.data
  );
  const [isOpenMealForm, setIsOpenMealForm] = useState(false);
  const [selectedObject, setSelectedObject] = useState<any>(null);
  const [showStateOfDocument, setShowStateOfDocument] = useState<any>("");
  const [uploadedFiles, setUploadedFiles] = useState<
    {
      file: File;
      key: string;
      file_name?: string;
      actual_file_name?: string;
    }[]
  >(selectedObject && selectedObject?.files ? selectedObject?.files : []);

  const handleClickRowMealBuilder = (e: any, id: any, title: string | any) => {
    e.stopPropagation();

    if (isDropdownOpenMeals) {
      // setIsDropdownOpenMeals(false);
    } else {
      const selectedMeals = mealsArr?.find((elem: any) => {
        return elem.id === id;
      });

      setIsOpenMealForm(true);
      // dispatch(setIsFormOpenTrue());
      let mealsSelectedrecord = {
        ...selectedMeals,
        prep_time_unit: selectedMeals?.prep_time_unit
          ? selectedMeals?.prep_time_unit
          : "minute",
        cooking_time_unit: selectedMeals?.cooking_time_unit
          ? selectedMeals?.cooking_time_unit
          : "minute",
      };
      setSelectedObject(selectedMeals);
      dispatch(setSelectedMealDataObject(selectedMeals));
      setUploadedFiles(selectedMeals.files);
      // setShowStateOfDocument(title);
    }
  };

  const handleOverlayClickOnMealUpdate = async () => {
    if (!selectedObject.isThereAnyChanges) {
      setIsOpenMealForm(false);
      return;
    }
    if (
      (selectedObject.meal_ingredients.length !== 0 ||
        selectedObject.prep_time ||
        selectedObject.cooking_time) &&
      !selectedObject.meal_name
    ) {
      toast.warn("Meal Name Is Mandatory!");
      return;
    }

    // setIsOpenMealForm(false);
    let mealsDetails = {
      meal_name: selectedObject.meal_name,
      tag: selectedObject.tags.map((res: any) => res.id),
      notes: selectedObject.notes,
      prep_time: selectedObject.prep_time,
      prep_time_unit: selectedObject.prep_time_unit,
      cooking_time: selectedObject.cooking_time,
      cooking_time_unit: selectedObject.cooking_time_unit,
      meal_ingredients: selectedObject.meal_ingredients,
      document_files: mealInfo.document_files,
      meal_id: selectedObject.id,
    };

    if (mealsDetails.meal_name || mealsDetails.notes) {
      try {
        const res = await api.post("/meals/update_meal", mealsDetails);

        if (res.data.status === "success") {
          toast.success("Meal updated successfully!", {
            style: {},
            progressStyle: { backgroundColor: dynamicColor },
            icon: (
              <span
                style={{
                  marginRight: "8px",
                  borderRadius: "50%",
                  width: "7rem",
                  height: "35px",
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: dynamicColor,
                  backgroundColor: "white",
                  fontSize: "1.5rem",
                  border: `3px solid ${dynamicColor}`,
                }}
              >
                ✓
              </span>
            ),
          });
        } else {
          toast.error("Failed To Update Meal. Please Try Again!");
        }
        dispatch(getMealBuilder());
        setIsOpenMealForm(false);
        dispatch(setopenMealBuilderForm(false))
      } catch (error: any) {
        setIsOpenMealForm(false);
        toast.error(error.message);
        dispatch(getMealBuilder());
        dispatch(setopenMealBuilderForm(false))
      } finally {
        setIsOpenMealForm(false);
        dispatch(getMealBuilder());
        dispatch(setopenMealBuilderForm(false))
      }
    } else {
      setIsOpenMealForm(false);
      dispatch(setopenMealBuilderForm(false));
      dispatch(getMealBuilder());
      toast.error("Failed To Update Meal. Please Try Again.");
    }
  };
  const [changesMadeToTheForm, setChangesMadeToTheForm] = useState(false);
  //TABLE END //
  // Access a Meals Records form Redux //
  const MealBuilderReduxInfo = useAppSelector(
    (state) => state?.mealBuilder.MealsBuilderData
  );

  const isDeleteMealActive = useAppSelector(
    (state) => state?.mealBuilder?.hasDeleteMeal
  );

  const dynamicColor = useAppSelector((state) => state?.common?.dynamicColor);

  // FETCH All Meals Records  //
  const dispatch = useAppDispatch();

  //  Fetch a Meals Records
  useEffect(() => {
    dispatch(getMealBuilder());
    dispatch(getUniqueTagsMealsThunk());
  }, []);

  // HANLDE FORM OPEN FROM UPPER BOX VIA REDUX
  useEffect(() => {
    if (openMealBuilderForm) {
      setopenForm(true);
    } else {
      setopenForm(false);
    }
  }, [openMealBuilderForm]);

  const handleCloseMealFormModal = () => {
    if (!selectedObject?.isThereAnyChanges) {
      dispatch(setopenMealBuilderForm(false));
      setIsOpenMealForm(false);
      setopenForm(false);
      return;
    }
    dispatch(setopenMealBuilderForm(false));
    setopenForm(false);
  };
  const [stageTwoToGoalsModal, setstageTwoToGoalsModal] = useState<any>(false);

  const [mealInfo, setMealInfo] = useState<any>({
    meal_name: "",
  });

  const handleOverlayClickOnMealModel =  async() => {
    if (
      (mealInfo.meal_ingredients.length !== 0 ||
        mealInfo.prep_time ||
        mealInfo.cooking_time) &&
      !mealInfo.meal_name
    ) {
      toast.warn("Meal Name Is Mandatory.");
      return;
    }

    setopenForm(false);
    dispatch(setopenMealBuilderForm(false));
    if (mealInfo.meal_name) {
      // dispatch(createMeal(mealInfo))
      try {
        const res = await api.post("/meals/create_meal", mealInfo);

        if (res.data.status === "success") {
          toast.success("Meal Created Successfully!", {
            style: {},
            progressStyle: { backgroundColor: dynamicColor },
            icon: (
              <span
                style={{
                  marginRight: "8px",
                  borderRadius: "50%",
                  width: "7rem",
                  height: "35px",
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: dynamicColor,
                  backgroundColor: "white",
                  fontSize: "1.5rem",
                  border: `3px solid ${dynamicColor}`,
                }}
              >
                ✓
              </span>
            ),
          });
        } else {
          toast.error("Failed to create meal. Please try again.");
        }
        dispatch(getMealBuilder());
        setopenForm(false);
        dispatch(setopenMealBuilderForm(false));
      } catch (error: any) {
        // setopenForm(false);
        if (error?.response?.data?.response == "Meal exists") {
          toast.error("Meal Is Already Existing");
        } else {
          toast.error(error.message);
        }
        dispatch(getMealBuilder());
        dispatch(setopenMealBuilderForm(false));
      } finally {
        setopenForm(false);

        dispatch(getMealBuilder());
        dispatch(setopenMealBuilderForm(false));
      }
    } else {
      setopenForm(false);
      dispatch(setopenMealBuilderForm(false));
      dispatch(getMealBuilder());
      // toast.error("Failed to create meal. Please try again.");
    }
  };

  const { domainDetail } = useAppSelector((state) => state?.common);

  return (
    <Fragment>

      <div style={{
        position:"relative"
      }}>
      {stageTwoToGoalsModal && (
        <CommonQuickMenu
          setshowGoalsModal={(value: any) => {}}
          mainType={"listing"}
          setstageTwoToGoalsModal={setstageTwoToGoalsModal}
        />
      )}
       <CommonUpperBox
        title="Meal Builder"
        // onClickPlus={() => dispatch(setopenMealBuilderForm(true))}
        type={"screen"}
        isMealBuilder={"MB"}
        onClickPlus={() => {
          setstageTwoToGoalsModal(true);
        }}
      />
      </div>
     
      <Table
        dynamicHeaderColor={domainDetail?.mealstbl_color}
        thData={columns}
        allClients={MealBuilderReduxInfo?.data?.data}
        classNameTable="meal-all-data-table"
        classNameTr="client-documents-tab-table-head"
        type="mealsBuilder"
        dropdownRef={dropdownRefMeals}
        isDropdownOpen={isDropdownOpenMeals}
        onClickSvg={() => setIsDropdownOpenMeals(true)}
        svgParentRef={svgParentRefMeals}
        onClickRow={handleClickRowMealBuilder}
        classNameRow="meal-builder-tab-table-body"
        fill={
          selectedFilterItems?.length > 0 ||
          searchValueMeals?.length > 0 ||
          sortbyMeals?.length > 0
            ? filterIconColor
            : "#D8D3D3"
        }
      />
      {isOpenMealForm && (
        <Modal
          isOpen={isOpenMealForm}
          onClose={() => {}}
          closeOnOverlayClick={true}
          isCentered
        >
          <ModalOverlay onClick={() => {}} zIndex={10} />
          <div>
            <MealsBuilderForm
              // type="MealBuilder"
              dataObject={selectedObject}
              setIsOpenMealForm={setIsOpenMealForm}
              setopenForm={setopenForm}
              setDataObject={setSelectedObject}
              setisFormOpen={setIsOpenMealForm}
              handleOverlayClickOnMealUpdate={handleOverlayClickOnMealUpdate}
              handleGetDetails={(info: any) => {
                setMealInfo(info);
                // setIsThereAnyChanges(true);
              }}
              handleOverlayClickOnMealModel={handleOverlayClickOnMealModel}
              showStateOfDocument={showStateOfDocument}
              uploadedFiles={uploadedFiles}
              setUploadedFiles={setUploadedFiles}
              changesMadeToTheForm={changesMadeToTheForm}
              setChangesMadeToTheForm={setChangesMadeToTheForm}
              handleCloseMealFormModal={handleCloseMealFormModal}
            />
          </div>
        </Modal>
      )}

      {isDeleteMealActive && <UnsavedChangesPopup type="mealBuilder"
      
      />}
      {/* mealBuilder */}
      {/* <Rectangle type="mealbuilder" /> */}
      <CircularNoteTrigger type="stickyHub" title="Comms"
      
      isOutside
          extraType="globalCommsBox"/>
      {openForm && (
        <Modal
          isOpen={openForm}
          onClose={() => {}}
          closeOnOverlayClick={true}
          isCentered
        >
          <ModalOverlay onClick={() => {}} zIndex={10} />
          <div>
            <MealsBuilderForm
              handleGetDetails={(info: any) => {
                setMealInfo(info);
              }}
              handleOverlayClickOnMealModel={handleOverlayClickOnMealModel}
              setopenForm={setopenForm}
              handleOverlayClickOnMealUpdate={handleOverlayClickOnMealUpdate}
              handleCloseMealFormModal={handleCloseMealFormModal}
            />
          </div>
        </Modal>
      )}

    </Fragment>
  );
};

export default MealBuilder;
