import { useAppSelector } from "../../../../Controller/redux/Hooks";
import ClinicalProgress from "../../ClinicalProgress/ClinicalProgress";
import CommonTaskItem from "../../CommonTaskItem/Components/CommonTaskItemClinical/CommonTaskItem";
import SubTaskItem from "../../CommonTaskItem/Components/SubTaskItem/SubTaskItem";

const ClinicalForm = ({
  clinicalObs,
  isSubTaskOpen,
  type,
  handleChangeValueTemp,
  handleChangeValue,
  handleOpenSubTask,
}: // activeTab,
any) => {
  const { getButtonTypeSticky } = useAppSelector((state) => state?.common);
  return (
    <>
      {getButtonTypeSticky === "Obs" && (
        <div className="sticky_note_form_in_clinical">
          {clinicalObs?.map((val: any, index: any) => (
            <div key={index}>
              {isSubTaskOpen !== index && (
                <CommonTaskItem
                  className="sticky_note_common_task"
                  key={index}
                  type={type}
                  dayBookTask={val}
                  subTask={isSubTaskOpen}
                  tagColorYellow={"#FBBF14"}
                  onClick={(e: any) => handleOpenSubTask(e, index)}
                />
              )}
              {isSubTaskOpen === index && (
                <SubTaskItem
                  title={val?.name}
                  value1={val?.value1}
                  value2={val?.value2}
                  name1={val?.name}
                  name2={val?.name}
                  onChange={(e: any) => handleChangeValue(e, index, val?.name)}
                  onChangeTemp={(e: any) =>
                    handleChangeValueTemp(e, index, val?.name)
                  }
                />
              )}
            </div>
          ))}
        </div>
      )}
      {/* {getButtonTypeSticky === "Notes" && <ClinicalProgress forSticky={true} />} */}
    </>
  );
};

export default ClinicalForm;
