import React, { useContext, useEffect, useRef, useState } from "react";
import {
  dummyArr,
  dummyArrClientSticky,
} from "../../../../pages/People/ClientPage/Components/UniqueClientScreen/Data/ClientScreenContentArr";
import {
  formatDate,
  formatDateForAPI,
  getCurrentDate,
} from "../../../../../Model/utils/Helper/formatDate";
import ReactDatePicker from "react-datepicker";
import DropdownIcon from "../../../../../Model/Assets/png/drop.png";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../Controller/redux/Hooks";
import { updateClientThunk } from "../../../../../Controller/redux/features/ClientFeature/detailsSlice";
import {
  getClientsThunk,
  getUniqueClientById,
} from "../../../../../Controller/redux/features/ClientFeature/clientsSlice";
import { toast } from "react-toastify";
import { setunsavedChanges } from "../../../../../Controller/redux/features/AuthFeature/authSlice";
import CustomDatePicker from "../../../DatePicker/CustomDatePicker";

const ClientDetailsSticky = ({ currentClient }: any) => {
  const [selectedDate, setSelectedDate] = useState<Date | null | any>("");
  const dispatch = useAppDispatch();
  const [details, setDetails] = useState<any>({
    givenName: "",
    middleName: "",
    familyName: "",
    knownAs: "",
    address: "",
    email: "",
    phone: "",
    mobile: "",
    dateOfBirth: "",
    fundingType: "",
    fundingAvailable: "",
    packageLevel: "",
    acatAssessment: "",
    role: "",
  });

  const datepickerRef = useRef<any>(null);

  useEffect(() => {
    const fetchedData = currentClient;

    if (fetchedData) {
      // Set the current client's data
      // setcurrentClient(fetchedData);

      setDetails({
        givenName: fetchedData?.first_name,
        middleName: fetchedData?.middle_name,
        familyName: fetchedData?.last_name,
        knownAs: fetchedData?.pref_name,
        address: fetchedData?.user_addresses[0]?.address || "",
        email: fetchedData?.email,
        phone: fetchedData?.phone,
        mobile: fetchedData?.mobile,
        dateOfBirth:
          fetchedData?.date_of_birth !== null
            ? formatDate(fetchedData?.date_of_birth)
            : "",
        fundingType:
          fetchedData?.funding_type !== null ? fetchedData?.funding_type : "",
        packageLevel:
          fetchedData?.package_level !== null ? fetchedData?.package_level : "",
        acatAssessment: fetchedData?.acat_assessment,
        role: fetchedData ? fetchedData?.roles[0]?.id : "",
        fundingAvailable: formatDateForAPI(fetchedData?.funding_available),
      });

      const currentClientDOB = fetchedData?.date_of_birth;
      if (currentClientDOB !== null) {
        setSelectedDate(new Date(currentClientDOB));
      } else {
        setSelectedDate(null);
      }
    } else {
      // setcurrentClient(null);
      setDetails({
        givenName: "",
        middleName: "",
        familyName: "",
        knownAs: "",
        address: "",
        dateOfBirth: "",
        email: "",
        phone: "",
        fundingType: "",
        packageLevel: "",
        acatAssessment: "",
        role: "",
        fundingAvailable: "",
      });
      setSelectedDate(null);
    }
  }, [currentClient]);
  3;

  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  const handleDateChange = (date: Date | any) => {
    dispatch(setunsavedChanges(true));
    if (!hasUnsavedChanges) {
      setHasUnsavedChanges(true);
    }
    setSelectedDate(date);
    setDOBDatePicker(false);
  };
  // @ts-ignore
  let dynamicClientId = parseInt(localStorage.getItem("dynamicClientId"));

  const { unsavedChanges } = useAppSelector((state) => state.auth);

  const [selectedAddress, setSelectedAddress] = useState<any>("");
  const [possibleAddresses, setPossibleAddresses] = useState<any>([]);

  function initService(query: any) {
    const displaySuggestions = function (predictions: any, status: any) {
      // @ts-ignore
      if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
        // console.log("Location service down!");
        return;
      }
      if (!predictions) {
        setPossibleAddresses([]);
        return;
      }
      setPossibleAddresses(predictions);
    };
    // @ts-ignore
    const service = new window.google.maps.places.AutocompleteService();
    service.getQueryPredictions({ input: query }, displaySuggestions);
  }

  const handleChangeForAddress = (index: number, e: any) => {
    const { value } = e.target;
    setDetails({ ...details, address: value });
    initService(value);
    if (value.length === 0) {
      setDetails({ ...details, address: "" });
      setPossibleAddresses([]);
    }
  };

  const selectAddress = (e: any) => {
    if (!hasUnsavedChanges) {
      setHasUnsavedChanges(true);
    }
    setSelectedAddress(e.target.innerHTML);
    setDetails({ ...details, address: e.target.innerHTML });
    dispatch(setunsavedChanges(true));
    setPossibleAddresses([]);
  };

  // CHANGE TO INPUT FIELDS
  const handleChange = (
    index: number,
    e: React.ChangeEvent<HTMLInputElement> | any
  ) => {
    const { value } = e.target;

    const fieldName = Object.keys(details)[index];
    setDetails((prevState: any) => ({
      ...prevState,
      [fieldName]: value,
    }));
    dispatch(setunsavedChanges(true));
    if (!hasUnsavedChanges) {
      setHasUnsavedChanges(true);
    }
  };

  const [selectedACATDate, setSelectedACATDate] = useState<Date | null | any>(
    ""
  );

  const handleACATDateChange = (date: Date | any) => {
    dispatch(setunsavedChanges(true));
    if (!hasUnsavedChanges) {
      setHasUnsavedChanges(true);
    }
    setSelectedACATDate(date);
  };

  const [openACATDropdown, setopenACATDropdown] = useState(false);
  const [selectedInterval, setselectedInterval] = useState("");

  const capitalizedInterval =
    selectedInterval.charAt(0).toUpperCase() + selectedInterval.slice(1);
  const result = `${capitalizedInterval}, ${formatDate(selectedACATDate)}`;
  const handleOpenDropdown = () => {
    setopenACATDropdown(true);
  };

  const [packageLevel, setpackageLevel] = useState("");

  useEffect(() => {
    if (currentClient?.package_level) {
      setpackageLevel(currentClient?.package_level);
    } else if (currentClient?.package_level === null) {
      setpackageLevel("");
    }
  }, [currentClient]);

  const handleSelectPackageLevel = (e: any) => {
    setpackageLevel(e.target.value);
    dispatch(setunsavedChanges(true));
    if (!hasUnsavedChanges) {
      setHasUnsavedChanges(true);
    }
    setDetails({ ...details, package_level: e.target.value });
  };

  const handleIntervalChange = (interval: string | any) => {
    setHasUnsavedChanges(true);
    setselectedInterval(interval);
  };

  ///////////////////////////////////////////////////////////////////////
  const pickerRef = useRef<any>(null);

  const [selectedEFA, setselectedEFA] = useState<any>("");
  const handleDateChangeForEFA = (date: Date | any) => {
    dispatch(setunsavedChanges(true));
    if (!hasUnsavedChanges) {
      setHasUnsavedChanges(true);
    }
    setselectedEFA(date);
  };
  // funding_available
  useEffect(() => {
    if (currentClient?.funding_available) {
      setselectedEFA(new Date(currentClient?.funding_available));
    }
  }, [currentClient]);

  const getUserRoles = useAppSelector((state) => state?.people?.clientRoles);

  const [userRoles, setUserRoles] = useState([]);

  useEffect(() => {
    if (getUserRoles?.data && getUserRoles?.data?.length > 0) {
      setUserRoles(getUserRoles?.data);
    }
  }, [getUserRoles]);

  const [DOBDatePicker, setDOBDatePicker] = useState(false);

  const { domainDetail } = useAppSelector((state) => state?.common);
  const [dynamicColor, setDynamicColor] = useState("");
  useEffect(() => {
    if (domainDetail) {
      setDynamicColor(domainDetail?.sticky_background_color);
    }
  }, [domainDetail]);

  const handleSaveChanges = async () => {
    setHasUnsavedChanges(false);
    dispatch(setunsavedChanges(false));
    let requiredParams = {
      first_name: details?.givenName,
      middle_name: details?.middleName,
      last_name: details?.familyName,
      address: details?.address,
      pref_name: details?.knownAs,
      email: details?.email,
      mobile: details?.mobile,
      phone: details?.phone,
      dob: formatDateForAPI(selectedDate),
      funding_type: details?.fundingType,
      package_level: packageLevel,
      acat_assessment: details?.acatAssessment,
      user_id: currentClient?.id,
      type: dynamicClientId,
      funding_available: formatDateForAPI(selectedEFA),
    };
    try {
      if (selectedInterval === "" && selectedACATDate !== "") {
        toast.error("ACAT Assessment Interval Can't Be Blank!");
      } else {
        const res = await dispatch(updateClientThunk(requiredParams));
        if (res.payload.status === "success") {
          toast.success("Profile updated successfully!", {
            progressStyle: { backgroundColor: dynamicColor },
            icon: (
              <span
                style={{
                  marginRight: "8px",
                  borderRadius: "50%",
                  width: "7rem",
                  height: "35px",
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: dynamicColor,
                  backgroundColor: "white",
                  fontSize: "1.5rem",
                  border: `3px solid ${dynamicColor}`,
                }}
              >
                ✓
              </span>
            ),
          });
        } else {
          toast.error("An Error Occurred!");
        }
      }
    } catch (error) {
      console.error(error);
      toast.error("An Error Occurred!");
    }

    dispatch(
      getUniqueClientById({
        typeId: dynamicClientId,
        clientId: currentClient?.id,
      })
    );
    dispatch(getClientsThunk({ dynamicClientId }));
  };

  return (
    <div className="client_details_wrapper">
      <div className="client-screen-personal-details-heading">
        <span className="client-screen-sub-heading-title">
          Personal Details
        </span>
        <span className="client-screen-line"></span>
      </div>

      <div className="main-box-body relative">
        {/* MAPPING OVER DATA IN DETAILS FIELD */}
        {dummyArrClientSticky?.slice(0, 9)?.map((elem: any, index: any) => {
          const fieldName = Object.keys(details)[index];

          return (
            <div
              className={`content-wrapper ${
                index === 9 || index === 10 || index === 11 ? "" : ""
              } ${index === 8 && "client_date_picker"}`}
              key={index}
            >
              <div className="wrapper-title">{elem.UpperTitle}</div>

              {index === 8 ? (
                <ReactDatePicker
                  onChange={handleDateChange}
                  selected={selectedDate}
                  startDate={selectedDate}
                  placeholderText="Select Date"
                  showYearDropdown
                  showMonthDropdown
                  dropdownMode="select"
                  open={DOBDatePicker}
                  onFocus={() => setDOBDatePicker(true)}
                  onBlur={() => setDOBDatePicker(false)}
                  maxDate={getCurrentDate()}
                  dateFormat="EEE, dd MMMM yyyy"
                />
              ) : index === 4 ? (
                /*furkan location*/
                <>
                  <div>
                    <input
                      type="text"
                      onChange={(e) => handleChangeForAddress(index, e)}
                      className="wrapper-info"
                      placeholder="Enter an address"
                      value={details[fieldName]}
                    />
                    <div className="address_listing client_sticky_address_listing">
                      <ul>
                        {possibleAddresses.length > 0
                          ? possibleAddresses.map((address: any, ind: any) => {
                              return (
                                <li
                                  key={ind}
                                  onClick={selectAddress}
                                  className="address_listing_item"
                                >
                                  {address.description}
                                </li>
                              );
                            })
                          : null}
                      </ul>
                    </div>
                  </div>
                </>
              ) : index === 9 ? (
                <select
                  className="wrapper-info wrapper-info-select-dropdown"
                  onChange={(e) => handleChange(index, e)}
                  value={details[fieldName]}
                >
                  <option value="">Please Select...</option>

                  <option value="Aged Care">Aged Care</option>
                  <option value="Disability">Disability</option>
                  <option value="Veterans">Veterans</option>
                  <option value="Private">Private</option>
                  <option value="Insurance">Insurance</option>
                  <option value="Other">Other</option>
                </select>
              ) : index === 11 ? (
                <div className="acat_assessment_wrapper">
                  <ReactDatePicker
                    placeholderText="ACAT Assessment"
                    selected={selectedACATDate}
                    ref={datepickerRef}
                    onChange={handleACATDateChange}
                    onFocus={handleOpenDropdown}
                    showYearDropdown
                    showMonthDropdown
                    dropdownMode="select"
                    shouldCloseOnSelect={false}
                    open={openACATDropdown}
                    value={
                      selectedACATDate
                        ? `${result}`
                        : currentClient
                        ? currentClient?.acat_assessment
                        : "ACAT Assessment"
                    }
                  />
                  <div>
                    {/* {openACATDropdown && (
                            <div className="acat-items-client" ref={pickerRef}>
                              {["Booked", "Completed"]?.map((option) => (
                                <div
                                  key={option}
                                  className="select-option"
                                  style={{
                                    color: "black",
                                    cursor: "pointer",
                                    padding: "2px 6px",
                                  }}
                                >
                                  {option}
                                </div>
                              ))}
                            </div>
                          )} */}
                  </div>
                </div>
              ) : (
                <input
                  className="wrapper-info"
                  onChange={(e) => handleChange(index, e)}
                  value={details[fieldName]}
                  placeholder={elem?.placeHolder}
                  style={{
                    border: "none",
                    outline: "none",
                    height: "fit-content",
                  }}
                />
              )}

              {elem.index === 5 && (
                <div className="dropdown-icon">
                  <img src={DropdownIcon} alt="dropdown-icon" />
                </div>
              )}
              <div className="wrapper-line"></div>
            </div>
          );
        })}
      </div>
      <div className="client-screen-personal-details-heading">
        <span className=" client-screen-sub-heading-title">
          Profile Details
        </span>
        <span className=" client-screen-line-additional-details"></span>
      </div>
      <div className="main-box-body relative">
        {/* MAPPING OVER DATA IN DETAILS FIELD */}
        {dummyArrClientSticky?.slice(9, 14)?.map((elem: any, index: any) => {
          const indexTemp = index + 9;
          const fieldName = Object.keys(details)[indexTemp];

          return (
            <div
              className={`content-wrapper ${
                indexTemp === 9 || indexTemp === 10 || indexTemp === 11
                  ? ""
                  : ""
              }`}
              key={indexTemp}
            >
              <div className="wrapper-title">{elem.UpperTitle}</div>
              {indexTemp === 8 ? (
                // <ReactDatePicker
                //   onChange={handleDateChange}
                //   selected={selectedDate}
                //   startDate={selectedDate}
                //   placeholderText="Select Date"
                //   className="date-picker-details-tab-of-client-screen"
                //   showYearDropdown
                //   showMonthDropdown
                //   dropdownMode="select"
                //   maxDate={getCurrentDate()}
                //   dateFormat="EEE, dd MMMM yyyy"
                // />
                <CustomDatePicker newDate={selectedDate} maxDate={getCurrentDate()} handleChange={handleDateChange}/>
              ) : indexTemp === 9 ? (
                <select
                  className="wrapper-info wrapper-info-select-dropdown"
                  onChange={(e) => handleChange(indexTemp, e)}
                  value={details[fieldName]}
                  style={{
                    border: "none",
                    outline: "none",
                    height: "fit-content",
                    backgroundColor: "white",
                    // width: "256px",
                  }}
                >
                  <option value="">Please Select...</option>

                  <option value="Aged Care">Aged Care</option>
                  <option value="Disability">Disability</option>
                  <option value="Veterans">Veterans</option>
                  <option value="Private">Private</option>
                  <option value="Insurance">Insurance</option>
                  <option value="Other">Other</option>
                </select>
              ) : indexTemp === 10 ? (
                <select
                  className="wrapper-info wrapper-info-select-dropdown"
                  onChange={handleSelectPackageLevel}
                  value={packageLevel}
                >
                  <option value="">Please Select...</option>
                  {[
                    "NDIS - Self Managed",
                    "NDIS - Plan Managed",
                    "NDIS - NDIA Managed",
                    "DSOA",
                    "HCP Level 1",
                    "HCP Level 2",
                    "HCP Level 3",
                    "HCP Level 4",
                    "HACC",
                    "CHSP",
                    "DVA",
                    "DVA Nursing",
                    "TAC",
                    "Brokerage",
                    "Private Fee for Service",
                  ].map((elem, index) => (
                    <option key={index} value={elem}>
                      {elem}
                    </option>
                  ))}
                </select>
              ) : indexTemp === 11 ? (
                <div id="acat_all_items_wrapper">
                  {openACATDropdown && (
                    <div id="acat_wrapper" ref={pickerRef}>
                      <div id="acat_option">
                        {["Booked", "Completed"]?.map((option: any) => (
                          <div
                            key={option}
                            className={`each_acat_option   
                                      ${
                                        selectedInterval.toLowerCase() ===
                                        option.toLowerCase()
                                          ? "selected_option"
                                          : ""
                                      } 

                                  `}
                            onClick={() =>
                              handleIntervalChange(option.toLowerCase())
                            }
                          >
                            {option}
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                  {!DOBDatePicker && (
                    <ReactDatePicker
                      placeholderText="ACAT Assessment"
                      selected={selectedACATDate}
                      ref={datepickerRef}
                      onChange={handleACATDateChange}
                      onFocus={handleOpenDropdown}
                      id="react_datepikcer_acat"
                      // className="date-picker-acat"
                      onBlur={() => {
                        setopenACATDropdown(false);
                      }}
                      showYearDropdown
                      showMonthDropdown
                      dropdownMode="select"
                      shouldCloseOnSelect={true}
                      open={openACATDropdown}
                      value={
                        selectedACATDate
                          ? `${result}`
                          : currentClient
                          ? currentClient?.acat_assessment
                          : "ACAT Assessment"
                      }
                    />
                  )}
                </div>
              ) : indexTemp === 12 ? (
                <select
                  className="wrapper-info wrapper_info_clients_type"
                  onChange={(e) => handleChange(indexTemp, e)}
                  value={details[fieldName]}
                  style={{
                    border: "none",
                    outline: "none",
                    height: "fit-content",
                    backgroundColor: "white",
                    // width: "256px",
                  }}
                >
                  <option value="">Please Select...</option>

                  {userRoles?.map((elem: any, index: number | any) => (
                    <>
                      <option key={index} value={elem?.id}>
                        {elem?.desc}
                      </option>
                    </>
                  ))}
                </select>
              ) : indexTemp === 13 ? (
                // <ReactDatePicker
                //   onChange={handleDateChangeForEFA}
                //   selected={selectedEFA}
                //   startDate={selectedEFA}
                //   placeholderText="Select Date"
                //   className="date-picker-details-tab-of-client-screen date_picker_prospects_efa"
                //   showYearDropdown
                //   showMonthDropdown
                //   dropdownMode="select"
                //   minDate={getCurrentDate()}
                //   dateFormat="EEE, dd MMMM yyyy"
                // />
                <CustomDatePicker handleChange={handleDateChangeForEFA} newDate={selectedEFA} minDate={getCurrentDate()}/>
              ) : (
                <input
                  className="wrapper-info"
                  onChange={(e) => handleChange(indexTemp, e)}
                  value={details[fieldName]}
                  placeholder={elem?.placeHolder}
                  style={{
                    border: "none",
                    outline: "none",
                    height: "fit-content",
                  }}
                />
              )}

              {elem.indexTemp === 5 && (
                <div className="dropdown-icon">
                  <img src={DropdownIcon} alt="dropdown-icon" />
                </div>
              )}
              <div className="wrapper-line"></div>
            </div>
          );
        })}
        <div className="content-wrapper">
          {unsavedChanges && (
            <button
              className="client_sticky_save_btn"
              style={{
                background: dynamicColor,
              }}
              onClick={() => {
                handleSaveChanges();
              }}
            >
              save
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ClientDetailsSticky;
