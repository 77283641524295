import { useEffect, useRef, useState } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../Controller/redux/Hooks";
import {
  createClinicalNoteThunk,
  getAllClinicalNotes,
  resetClinicalRecords,
  setClinicalDetailsInfo,
  updateClinicalNoteThunk,
} from "../../../../Controller/redux/features/ClinicalFeature/clinicalSlice";
import { CrossMdSvg } from "../../../../Model/Assets/SVG/Common/CrossSvg";
import TagsInput from "../../Tags/TagBuilder";
import TildeSVG from "../Data/TildeSVG";
import ClientAssessmentForm from "../../../pages/People/ClientPage/Components/UniqueClientScreen/Components/DocumentsTab/Components/ClientAssessmentForm";
import { Modal, ModalOverlay } from "@chakra-ui/react";
import {
  extractTime,
  formatDate,
  timeAgo,
} from "../../../../Model/utils/Helper/formatDate";
import TrafficLights from "../../CommonModal/Components/TrafficLights";
import { GreenCircleSvg } from "../../../pages/People/ClientPage/Components/UniqueClientScreen/Components/ClinicalTab/Data/SVGs";
import { updateTrafficLightThunk } from "../../../../Controller/redux/features/CommonFeature/commonSlice";
import { toast } from "react-toastify";
import {
  getClientUnreadMessagesCommsThunk,
  getCommsDetailThunk,
  getCommsForUserThunk,
  getCommsFromUserTypeThunk,
  getUnreadMessagesCommsThunk,
  updateCommsTagsThunk,
} from "../../../../Controller/redux/features/CommsFeature/commsSlice";
import ConfirmationModal from "../../ConfirmationModal/ConfirmationModal";
import PdfSVG from "../Data/PdfSVG";
import DocxSVG from "../Data/DocxSVG";
import DefaultFileSVG from "../Data/DefaultFileSVG";
import { formatDateAndTime } from "../../../../Model/utils/Helper/formatTime";
import { getUserNotificationStats } from "../../../../Controller/redux/features/NotificationsFeature/NotificaionsSlice";
import { ThreadCount } from "../../CommonModal/Data/SVGs";
import {TagBox} from "../../Tags/CommonTagsComponent/TagBox";

const ClinicalNote = ({
  isFromClinicalProgressModal = false,
  isFromMobile,
  isClinicalNote,
  setisFirstMessageOpen = () => {},
  isFromDayBook,
  clinicalGoalsNote,
  setshowModal = () => {},
  selectedItemTitle,
  showModal,
  onChangeTextNote,
  fromAdvancedClinicalNote,
  allData,
  isCol2,
  userName,
  noteType,
  dropdown = false,
  editiable = false,
  dropdownValue,
  NoteInfo,
  note,
  selectedItem,
  color,
  setshowGoalsModal = () => {},
  showGoalsModal,
  onClickEnable = false,
  handleOnClick = () => {},
  userFromAdvanceCareNote,
  onChangeNoteType = () => {},
  htmlContent,
  saveBtn = false,
  folderChange,
  onClickClose = () => {},
  isExpanded,
  noteTitle,
  index,
  setIsSubTaskOpen = () => {},
  isSubTaskOpen,
  isComms,
  isFromCommsFormDocuments,
  elem,
  currentElem,
  setCurrentActiveIndex = () => {},
  clientId,
  isFromCol1,
  // noteDateTime,
  currInfo,
  setcommsDetailCurrentElement = () => {},
  isLoggedInUserSame,
  activeNote,
}: any) => {
  const { note_type } = useAppSelector((state) => state?.common?.domainDetail);
  const elementRef = useRef<any>(null);
  // @ts-ignore
  let loggedInUser = JSON.parse(localStorage.getItem("user"));

  // console.log('loggedInUser',loggedInUser)f
  let options = note_type?.split(",");
  const [selectedNoteType, setSelectedNoteType] = useState(dropdownValue);
  // console.log(dropdownValue, "dropdownvALUE");
  const [selectedIndex, setselectedIndex] = useState(-1);
  const { ClinicalNotesData } = useAppSelector((state) => state.clinical);
  const [ClinicalNotesRecords, setClinicalNotesRecords] = useState<any>([]);

  useEffect(() => {
    if (ClinicalNotesData) {
      const uniqueClientData = ClinicalNotesData?.filter(
        (item: any, index: number, array: any) => {
          return array?.findIndex((obj: any) => obj.id === item.id) === index;
        }
      );
      setClinicalNotesRecords(uniqueClientData);
    }
  }, [ClinicalNotesData, showModal]);
  let noteInfo = ClinicalNotesRecords[selectedIndex];

  // console.log(clinicalGoalsNote,'clinicalGoalsNote')
  const [storeCurrentNoteInfo, setstoreCurrentNoteInfo] = useState<any>(null);

  useEffect(() => {
    if (currInfo) {
      setstoreCurrentNoteInfo(currInfo);
    } else if (noteInfo) {
      setstoreCurrentNoteInfo(noteInfo);
    }
  }, [currInfo, noteInfo]);

  useEffect(() => {
    if (elem) {
      setcommsDetailCurrentElement(elem);
    }
  }, [elem]);

  const getCommTypeLabel = (commType: string) => {
    switch (commType) {
      case "DOCUMENT":
        return `DOC - ${elem?.asset_username} - `;
      case "DAYBOOK":
        return `EVENT - ${elem?.asset_username} - `;
      case "MEAL":
        return `Meal - `;
      default:
        return commType ? commType + ":" : "";
    }
  };

  const truncateMessage = (message: string | any[], length: number) => {
    return message && message.length > length
      ? `${message.slice(0, length)}...`
      : message;
  };

  const commType = elem?.comm_type;
  const isComm = commType === "COMM";
  const isDeleted = elem?.is_deleted;
  const commTitle = elem?.comm_title || "";
  const clinicalNote = clinicalGoalsNote || "_______________";
  const currentMessage = elem?.current_message || "";
  const firstMessage = elem?.first_message || "";
  const commSummary = elem?.comm_summary || "";

  let constructedString = "";

  if (commType) {
    if (isComm) {
      constructedString = `${clinicalNote}<br/> 
        <span style="font-weight:400">
        ${
          truncateMessage(currentMessage, 75) ||
          truncateMessage(firstMessage, 45)
        }
        </span>
        ${truncateMessage(commSummary, 80)}`;
    } else {
      let label = getCommTypeLabel(commType);
      if (isDeleted) {
        label = `Deleted ${label}`;
      }

      constructedString = `<b> ${label} ${clinicalNote}</b> 
        ${firstMessage.length > 0 ? ":" : ""}
        <br/><br/>
        <span style="font-weight:400">
        ${truncateMessage(currentMessage, 60) || firstMessage}
        </span>`;
    }
  }

  // @ts-ignore
  const dynamicClientId = parseInt(localStorage.getItem("dynamicClientId"));
  // @ts-ignore
  const dynamicProspectId = parseInt(localStorage.getItem("dynamicProspectId"));

  const [isChange, setisChange] = useState(false);

  const dynamicColor = useAppSelector((state) => state?.common?.dynamicColor);

  const [textAreaStyle, setTextAreaStyle] = useState({
    height: "auto",
  });

  const [selectedTags, setSelectedTags] = useState(elem ? elem.tags : []);

  const [viewFiles, setViewFiles] = useState(false);
  const { user } = useAppSelector((state) => state.common);
  const allTags = useAppSelector((state) => state?.common?.allTags);
  const dispatch = useAppDispatch();

  const [currUser, setCurrUser] = useState<any>(null);

  useEffect(() => {
    if (user && !fromAdvancedClinicalNote) {
      setCurrUser(user);
    } else {
      setCurrUser(userFromAdvanceCareNote?.staff_user);
    }
  }, [user, userFromAdvanceCareNote]);

  // @ts-ignore
  let id = JSON.parse(localStorage.getItem("user"));
  let userId = id?.data?.id;

  const [isUpdating, setIsUpdating] = useState(false);
  const { selectedClinicalNoteInfo } = useAppSelector(
    (state) => state.clinical
  );
  const { date, time } = formatDateAndTime(new Date());
  const {selectedCommsFolder} = useAppSelector((state) => state.comms);

  // console.log(elem,'elem')
  // console.log()
  let noteDateTime = `${date} ${time.split(" ")[0]}`;

  // console.log('selectedItem', selectedItem);
  const triggerGetCommsAPICall = () => {
    if (selectedCommsFolder === "Inbox") {
      dispatch(
        getCommsForUserThunk({
          clientId,
          page: 1,
          endpoint: "get_comms",
          sortType: "",
          search: "",
          unread: "",
        })
      );
    } else if (selectedCommsFolder === "Sent") {
      dispatch(
        getCommsForUserThunk({
          clientId,
          page: 1,
          endpoint: "get_comms_of_sent_items",
          sortType: "",
          search: "",
          unread: "",
        })
      );
    } else if (selectedCommsFolder === "Clients") {
      dispatch(
        getCommsFromUserTypeThunk({
          userType: dynamicClientId,
          search: "",
          sortType: "",
        })
      );
    } else {
      dispatch(
        getCommsFromUserTypeThunk({
          userType: dynamicProspectId,
          search: "",
          sortType: "",
        })
      );
    }
  };




  const onChangeTrafficLight = async (elem: any, data: any) => {
    // console.log(elem,'elem')
    setCurrentActiveIndex(null);
    setShowLights(false);
    setIsUpdating(true);

    if (dropdownValue) {
      try {
        const response = await dispatch(
          updateClinicalNoteThunk(
            selectedClinicalNoteInfo
              ? {
                  ...selectedClinicalNoteInfo,
                  user_id: clientId,
                  activity_note_id: selectedClinicalNoteInfo?.id,
                  note_date_time: noteDateTime,
                  traffic_light:
                    data == "#ef3a34"
                      ? 0
                      : data == "#fbbf14"
                      ? 1
                      : data == "#2ad400"
                      ? 2
                      : 1,
                }
              : {
                  ...storeCurrentNoteInfo,
                  user_id: clientId,
                  note_tag: selectedNoteType,
                  activity_note_id: storeCurrentNoteInfo?.id,
                  note_date_time: noteDateTime,
                  traffic_light:
                    data == "#ef3a34"
                      ? 0
                      : data == "#fbbf14"
                      ? 1
                      : data == "#2ad400"
                      ? 2
                      : 1,
                }
          )
        );
        if (response?.payload?.status === "success") {
          toast.success("Note updated successfully", {
            progressStyle: { backgroundColor: dynamicColor },
            icon: (
              <span
                style={{
                  marginRight: "8px",
                  borderRadius: "50%",
                  width: "7rem",
                  height: "35px",
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: dynamicColor,
                  backgroundColor: "white",
                  fontSize: "1.5rem",
                  border: `3px solid ${dynamicColor}`,
                }}
              >
                ✓
              </span>
            ),
          });
        }

        setselectedIndex(-1);
        setCurrentActiveIndex(null);

        dispatch(getAllClinicalNotes({ user_id: clientId })).then(() =>
          setIsUpdating(false)
        );
      } catch (error) {
        console.log(error, "error");
      }

      dispatch(getUnreadMessagesCommsThunk(clientId));
      dispatch(getClientUnreadMessagesCommsThunk(clientId));
    } else {
      let res = await dispatch(
        updateTrafficLightThunk({
          link_uid: parseInt(currentElem?.link_uid),
          comm_type: currentElem?.comm_type,
          communication_id: elem?.id,
          traffic_light:
            data == "#ef3a34"
              ? 0
              : data == "#fbbf14"
              ? 1
              : data == "#2ad400"
              ? 2
              : 1,
        })
      );

      if (res.payload.status === "success") {
        toast.success(`Traffic Light updated successfully!`, {
          // style: {},
          progressStyle: { backgroundColor: dynamicColor },
          icon: (
            <span
              style={{
                marginRight: "8px",
                borderRadius: "50%",
                width: "7rem",
                height: "35px",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: dynamicColor,
                backgroundColor: "white",
                fontSize: "1.5rem",
                border: `3px solid ${dynamicColor}`,
              }}
            >
              ✓
            </span>
          ),
        });
       
        triggerGetCommsAPICall();

        dispatch(
          getCommsDetailThunk({
            clientId: currentElem?.link_uid,
            type: currentElem?.comm_type,
            userId: clientId,
            page: 1,
          })
        );
      } else {
        // toast.error("An Error Occurred!");
      }
    }
    dispatch(getUserNotificationStats());
  };

  useEffect(() => {
    settagOpen(false);
  }, [isSubTaskOpen]);

  let textAreaRef = useRef();

  const autoAdjustHeight = (event: any) => {
    const textarea = event.target;
    textarea.style.height = "auto";
    textarea.style.height = `${textarea.scrollHeight}px`;
    if (!userFromAdvanceCareNote?.staff_user_id) {
      onChangeTextNote(event);
    } else if (userFromAdvanceCareNote?.staff_user_id) {
      if (
        fromAdvancedClinicalNote &&
        userFromAdvanceCareNote?.staff_user_id == userId
      ) {
        onChangeTextNote && onChangeTextNote(event);
      } else if (!fromAdvancedClinicalNote) {
        onChangeTextNote && onChangeTextNote(event);
      }
    }
  };

  if (currentElem?.link_uid && currentElem?.comm_type) {
    // var commType = currentElem?.comm_type;
    // var linkUid = currentElem?.link_uid;
  }

  const handleSelectedTagIdsChange = async (newSelectedTagIds: any) => {
    const newSelectedTags = [...newSelectedTagIds];

    var selectedTagsObject = {
      tags: newSelectedTags,
      link_uid: elem?.link_uid,
      comm_type: elem?.comm_type,
    };
    setSelectedTags(newSelectedTags);
    if (selectedTagsObject.comm_type && selectedTagsObject.link_uid) {
      try {
        await dispatch(updateCommsTagsThunk(selectedTagsObject));
      } catch (err) {
        console.error(err, "error");
      }
    }
  };

  useEffect(() => {
    if (textAreaRef?.current) {
      setTextAreaStyle({
        // @ts-ignore
        height: `${textAreaRef?.current?.scrollHeight}px`,
      });
    }
  }, []);

  const [tagOpen, settagOpen] = useState(false);
  const [trafficColors, setTrafficColors] = useState<any>("");

  useEffect(() => {
    settagOpen(false);
  }, [folderChange]);

  useEffect(() => {
    if (elem) {
      setTrafficColors(
        elem?.traffic_light === 0
          ? "#ef3a34"
          : elem?.traffic_light === 1
          ? "#fbbf14"
          : "#2ad400"
      );
    }
  }, [elem]);

  const [showLights, setShowLights] = useState<boolean>(false);

  // console.log(tagOpen,"tagOpen");

  async function handleSaveAndMove() {
    if (selectedNoteType === "Default") {
      toast.warn("Please Select A Note Type Before Saving.");
      return;
    }
    if (storeCurrentNoteInfo.note_tag === "Please Select") {
      toast.warn("Please Select A Note Type Before Saving.");
      return;
    }

    // console.log(storeCurrentNoteInfo.note_type )
    setSelectedNoteType("");

    onClickClose();
    setshowModal(false);

    if (!elem.id) {
      try {
        const response = await dispatch(
          createClinicalNoteThunk({
            ...storeCurrentNoteInfo,
            user_id: clientId,
            note_tag: selectedNoteType,
            activity_note_id: storeCurrentNoteInfo?.id,
            note_date_time: noteDateTime,
          })
        );
        if (response?.payload?.status === "success") {
          toast.success("Note Created Successfully", {
            progressStyle: { backgroundColor: dynamicColor },
            icon: (
              <span
                style={{
                  marginRight: "8px",
                  borderRadius: "50%",
                  width: "7rem",
                  height: "35px",
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: dynamicColor,
                  backgroundColor: "white",
                  fontSize: "1.5rem",
                  border: `3px solid ${dynamicColor}`,
                }}
              >
                ✓
              </span>
            ),
          });
          dispatch(resetClinicalRecords());
          dispatch(
            getAllClinicalNotes({ user_id: clientId, ClinicalPage: 1 })
          ).then(() => setIsUpdating(false));
          dispatch(getClientUnreadMessagesCommsThunk(clientId));
        }
      } catch (error: any) {}
    } else {
      try {
        const response = await dispatch(
          updateClinicalNoteThunk({
            ...storeCurrentNoteInfo,
            user_id: clientId,
            note_tag: selectedNoteType,
            activity_note_id: storeCurrentNoteInfo?.id,
            note_date_time: noteDateTime,
          })
        );

        if (response?.payload?.status === "success") {
          toast.success("Note updated successfully", {
            progressStyle: { backgroundColor: dynamicColor },
            icon: (
              <span
                style={{
                  marginRight: "8px",
                  borderRadius: "50%",
                  width: "7rem",
                  height: "35px",
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: dynamicColor,
                  backgroundColor: "white",
                  fontSize: "1.5rem",
                  border: `3px solid ${dynamicColor}`,
                }}
              >
                ✓
              </span>
            ),
          });
        }
        dispatch(resetClinicalRecords());
        dispatch(getAllClinicalNotes({ user_id: clientId })).then(() =>
          setIsUpdating(false)
        );
        dispatch(getClientUnreadMessagesCommsThunk(clientId));
      } catch (error) {}
    }
  }

  useEffect(() => {
    if (elem?.tags) {
      setSelectedTags(elem?.tags?.map((res: any) => res?.id));
    }
  }, [elem?.tags]);

  useEffect(() => {
    if (tagOpen && index == 0) {
      setisFirstMessageOpen({
        isOpen: true,
        isFirst: true,
      });
    } else {
      setisFirstMessageOpen({
        isOpen: true,
        isFirst: false,
      });
    }
  }, [tagOpen, index]);

  return (
    <div
      style={{
        cursor: isLoggedInUserSame ? "pointer" : "default",
      }}
    >
      <div>
        {/* {tagOpen && (
          <TagsInput
            allTags={allTags}
            selectedTags={selectedTags}
            setSelectedTags={(info: any) => {
              setSelectedTags(info);
            }}
            onSelectTagIdChange={handleSelectedTagIdsChange}
            isComms={true}
            elementRef={elementRef}
          />
        )} */}
      </div>
      {showModal && (
        <div>
          <ConfirmationModal
            type="clientScreen"
            handleMoveWithoutSaving={onClickClose}
            handleMoveWithSave={handleSaveAndMove}
          />
        </div>
      )}

      {viewFiles && (
        <>
          <Modal
            isOpen={true}
            onClose={() => setViewFiles(false)}
            closeOnOverlayClick={true}
            isCentered
          >
            <ModalOverlay onClick={() => setViewFiles(false)} zIndex={10} />

            <ClientAssessmentForm
              type="new-form"
              uploadedFilesFromComms={elem.files}
              handleGetDetails={() => {}}
              triggeredFromDaybook={true}
              loggedInUserDetailsFromDaybook={user}
              fromNote={true}
              setViewFiles={setViewFiles}
              dataObject={elem}
              setDataObject={() => {}}
              isViewStateInComms={true}
            />
          </Modal>
        </>
      )}

      <div
        style={
          isFromMobile
            ? { display: "flex", borderWidth: "1px", borderRadius: "12px" }
            : { position: "relative", left:"2px" }
        }
        className={`${
          isFromCommsFormDocuments
            ? "from_comms"
            : !isFromCol1
            ? "fromCol3Comms"
            : ""
        }`}
      >
        <div
          ref={elementRef}
          style={{
            background: (loggedInUser?.data?.id == elem?.user_id)  ? "#EEF9FF" : "unset"
          }}
          className={`${
            fromAdvancedClinicalNote
              ? "clinical_note_wrapper-1"
              : !isExpanded
              ? "clinical_note_wrapper"
              : isFromMobile
              ? "active_note_wrapper_mobile"
              : "active_note_wrapper"
          }`}
          onClick={(e) => {
            if (selectedItemTitle === "Inbox") {
              dispatch(
                getCommsForUserThunk({
                  clientId,
                  page: 1,
                  endpoint: "get_comms",
                  sortType: "",
                  search: "",
                  unread: "",
                })
              );
            } else if (selectedItemTitle === "Sent Items") {
              dispatch(
                getCommsForUserThunk({
                  clientId,
                  page: 1,
                  endpoint: "get_comms_of_sent_items",
                  sortType: "",
                  search: "",
                  unread: "",
                })
              );
            } else if (selectedItemTitle === "Clients") {
              dispatch(
                getCommsFromUserTypeThunk({
                  userType: dynamicClientId,
                  search: "",
                  sortType: "",
                })
              );
            } else if (selectedItemTitle === "Prospects") {
              dispatch(
                getCommsFromUserTypeThunk({
                  userType: dynamicProspectId,
                  search: "",
                  sortType: "",
                })
              );
            }

            if (onClickEnable) {
              if (color === "#FFE8F8") {
                dispatch(setClinicalDetailsInfo(NoteInfo));

                setshowGoalsModal(true);
              } else if (color === "#faf9ac") {
                if (elem?.staff_user_id == userId) {
                  dispatch(setClinicalDetailsInfo(NoteInfo));

                  handleOnClick();
                } else {
                  dispatch(setClinicalDetailsInfo(NoteInfo));

                  toast.warn(
                    "You don't have the right permissions to edit this note."
                  );
                }
              }
            }

            setIsSubTaskOpen(index);
          }}
        >
          {isFromCol1 && (
            <>
              {/* <div
                className="widget-dropdown tag_wrapper_document_form"
                style={{
                  position: "relative",
                  top: "-16px",
                }}
                onClick={(e) => {
                  settagOpen(!tagOpen);
                }}
              >
                <div
                  style={{
                    backgroundColor: !tagOpen
                      ? selectedTags?.length > 0
                        ? "#b2ff9f"
                        : "rgba(213, 213, 213, 0.8)"
                      : "#06a5ff",
                    color:
                      !tagOpen && selectedTags?.length > 0
                        ? "#1a8400"
                        : "white",
                  }}
                >
                  <span
                    style={{
                      color:
                        !tagOpen && selectedTags?.length === 0
                          ? "white"
                          : "#a1a1a1",
                    }}
                  >
                    {selectedTags?.length || 0}
                  </span>
                  Tag
                </div>
              </div> */}
           
            </>
          )}

          <div className="upper_user_section">
            <div className="section_clnical_note">
              <TildeSVG />
              <p className="note_title_wrapper">
                {isFromDayBook
                  ? noteTitle || "Message"
                  : isClinicalNote
                  ? "Notes"
                  :
                  
                  (loggedInUser?.data?.id == elem?.user_id) ?
                  "YOU" :
                  (elem?.has_first_message )=== 1
                  ? "Message"
                  : noteTitle || "Notes"}
              </p>
              {!dropdown && (
                <p className="clinical_note_type"> {noteType && noteType} </p>
              )}

              {dropdown && (
                <select
                  name="`type`"
                  className="note_type_select_tag"
                  id="noteType"
                  onChange={(e) => {
                    setSelectedNoteType(e?.target?.value);
                    onChangeNoteType(e);
                    setisChange(true);
                  }}
                  value={selectedNoteType}
                >
                  <option value="Please Select">Please Select</option>
                  {options &&
                    options.map((elem: string) => {
                      return (
                        <>
                          <option value={elem}>{elem}</option>
                        </>
                      );
                    })}
                </select>
              )}
              {true && (
                <p className="user_name_p_wrapper">
                  {userName
                    ? userName
                    : `${currUser?.first_name || ""} ${
                        currUser?.last_name || ""
                      } `}
                </p>
              )}
            </div>
            <div
              style={{
                position: "relative",
              }}
            >
                 <span
            style={{
              display:"flex",
              alignItems:'center',
              gap:"6px",
              marginRight:"6px"
            }}
              >
                <span className="section_right_text">
                {elem?.day} {elem?.created_time}
                </span>
                <span onClick={() => settagOpen(!tagOpen)}>
                <TagBox selectedItem={selectedTags}
                        currentElem={elem}
                        isComms={isComms}
                        isFromCol1
                        clientId={clientId}
                        triggerGetCommsAPICall={triggerGetCommsAPICall}
                />

                </span>
                <ThreadCount
                  count={elem?.message_count}
                  width={12}
                  height={12}
                />
             
              </span>
              {showLights && !isFromCol1 && (
                <div
                  style={{
                    position: note && "relative",
                    left: (note && !isExpanded )? "96px" : "95px",
                    top: note ? "-8px" : "10px",
                  }}
                >
                  <TrafficLights
                    isFromClinicalProgressModal={isFromClinicalProgressModal}
                    className={
                      fromAdvancedClinicalNote
                        ? "traffic_light_container_goals_1"
                        : !isComms
                        ? "clinical_note_taffic_light"
                        : "clinical_note_taffic_light_comms"
                    }
                    setShowLights={setShowLights}
                    handleTrafficColors={(data: any) => {
                      setTrafficColors(data);
                      setCurrentActiveIndex(index);
                      onChangeTrafficLight(elem, data);
                      setShowLights(false);
                    }}
                  />
                </div>
              )}
              <div className="time_date_section_wrapper">
                {!isFromCol1 && (
                  <>
                    <p>
                      {elem?.created_at
                        ? extractTime(elem?.created_at || "") +
                          " - " +
                          formatDate(elem?.created_at || "")
                        : null}
                    </p>
                  </>
                )}

                
{/* change to external */}
              
              </div>


              <div className="cursor_pointer" 
              >
                  {!isFromDayBook && (
                    <div
                      style={{
                        cursor: isFromCol1 ? "default" : "pointer",
                      }}
                    >
                      {showGoalsModal &&
                      userFromAdvanceCareNote?.staff_user_id ? (
                        <GreenCircleSvg
                          onClick={(e: any) => {
                            // setCurrentActiveIndex(index)
                            setselectedIndex(index);
                            dispatch(setClinicalDetailsInfo(allData?.[index]));

                            // setCurrentActiveIndex(index);
                            e.stopPropagation();
                            if (!isComms) {
                              if (elem?.staff_user_id == userId) {
                                setShowLights(!showLights);
                              } else {
                                setShowLights(!showLights);
                              }
                            } else {
                              setShowLights(!showLights);
                            }
                          }}
                          color={trafficColors}
                        />
                      ) : (
                        <GreenCircleSvg
                          onClick={(e: any) => {
                            setselectedIndex(index);
                            dispatch(setClinicalDetailsInfo(allData?.[index]));

                            e.stopPropagation();
                            if (!isComms) {
                              if (elem?.staff_user_id == userId) {
                                setShowLights(!showLights);
                              } else {
                                setShowLights(!showLights);
                              }
                            } else {
                              setShowLights(!showLights);
                            }
                          }}
                          color={trafficColors}
                        />
                      )}
                    </div>
                  )}
                </div>
                {dropdown && (
                  <span style={{
                    position:"relative",
                    right:"-2px"
                  }}>
                  <CrossMdSvg
                    onClick={() => {
                      if (isChange) {
                        setshowModal(true);
                      } else {
                        onClickClose();
                      }
                    }}
                  />
                  </span>
                )}
            </div>
          </div>

          <>
            <p
              style={{
                fontSize: "14px",
                marginTop: "6px",
                color: "#626262",
                cursor: "default",
                pointerEvents: "none",
              }}
              id="dangerouslySetInnerHTML"
              dangerouslySetInnerHTML={{
                __html: htmlContent,
              }}
            ></p>
          </>

          <div className="text_area_section_wrapper_clinical_note">
            {onChangeTextNote ? (
              <textarea
                //@ts-ignore
                ref={textAreaRef}
                style={{
                  ...textAreaStyle,
                }}
                id="clinicalNoteTextArea"
                value={clinicalGoalsNote || selectedClinicalNoteInfo?.note}
                onChange={autoAdjustHeight}
                disabled={editiable}
                //   rows={6}
                autoFocus={saveBtn ? true : false}
                placeholder="Summary text to show the subtitle text of any event."
              ></textarea>
            ) : (
              <p
                style={{
                  ...textAreaStyle,
                  width: "100%",
                  position:"relative",
                  lineHeight: elem?.comm_type == "COMM" ? "22px" : "12px",
                  minHeight:"41px",
                  fontWeight: elem?.comm_title?.includes("RE: ")
                    ? "bold"
                    : "normal",
                  display:
                    isFromCol1 || clinicalGoalsNote?.length > 0 ? "" : "none",
                }}
                id="clinicalNoteTextArea"

              >
                {isFromCol1 ? (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: constructedString,
                    }}
                  />
                ) : (
                  `${clinicalGoalsNote}`
                )}
              </p>
            )}
          </div>

          <div
            style={{ display: "flex", justifyContent: "flex-end", gap: "2px" }}
          >
            {elem?.files?.slice(0, 3).map((elem: any, index: number) => {
              return (
                <>
                  <div
                    className="file-wrapper"
                    style={{
                      border: "none",
                      width: "25px",
                      position: "relative",
                    }}
                  >
                    <div
                      className="actual_file_name_item_comms_clinical_note"
                      style={{
                        fontSize: "8px",
                        position: "absolute",
                        right: 0,
                        bottom: "35px",
                      }}
                    >
                      {elem?.actual_file_name}
                    </div>
                    <div
                      className="file-item"
                      style={{
                        cursor: "pointer",
                        marginBottom: "10px",
                      }}
                    >
                      <>
                        {elem?.file_name?.includes("pdf") ? (
                          <PdfSVG />
                        ) : elem?.file_name?.includes("docx") ? (
                          <DocxSVG />
                        ) : (
                          <DefaultFileSVG />
                        )}
                      </>
                    </div>
                  </div>
                </>
              );
            })}
          </div>

          {dropdown && (
            <div
              className="save_btn_wrapper_clinical_note"
              onClick={handleSaveAndMove}
            >
              {!isUpdating && (
                <>
                  <button
                    className="save_btn_clinical_note"
                    style={{ backgroundColor: dynamicColor }}
                    id="saveBTN"
                  >
                    Save
                  </button>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ClinicalNote;
