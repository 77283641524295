import React from "react";

const SearchSVG = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 33 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.831 14.9155C18.831 17.078 17.078 18.831 14.9155 18.831C12.753 18.831 11 17.078 11 14.9155C11 12.753 12.753 11 14.9155 11C17.078 11 18.831 12.753 18.831 14.9155ZM18.0198 18.7269C17.1734 19.4171 16.0928 19.831 14.9155 19.831C12.2007 19.831 10 17.6303 10 14.9155C10 12.2007 12.2007 10 14.9155 10C17.6303 10 19.831 12.2007 19.831 14.9155C19.831 16.0928 19.4171 17.1734 18.7269 18.0198L22.162 21.4549L21.4549 22.162L18.0198 18.7269Z"
        fill="#C7C7C7"
      />
    </svg>
  );
};

export default SearchSVG;
