import "../Styles/StaffDetailBox.css";

import { ClientStatusCircleXxlSvg } from "../../../../Model/Assets/SVG/WidgetVillage/ClientStatusCircleSvg";
import { MastercardSVG } from "../Data/Mastercard";
import { PlusSVG } from "../Data/PlusSVG";
import BasketballSVG from "../Data/BasketballSVG";
import StaffSVG from "../Data/StaffSVG";
import TagSVG from "../Data/TagSVG";

const StaffDetailBox = () => {
  return (
    <div className="staff_detail_box_wrapper">
      <div className="top_section">
        <div className="top_section_left">
          <StaffSVG />
          <PlusSVG />
          <span className="text_service">SERVICE</span>
          {["S3", "GS4"].map((elem) => {
            return <div className="top_circle">{elem}</div>;
          })}
          <MastercardSVG />
        </div>
        <div className="top_section_right">
          <div className="circle_icon">0</div>
        </div>
      </div>
      <div className="middle_section">
        <p className="text_left_section">Billy.Emur Doctors appointment</p>
        <div className="buttons_right_section">
          <button>START</button>
          <button>STOP</button>
        </div>
      </div>
      <div className="bottom_section">
        <div className="bottom_section_left">
          <BasketballSVG />
          <TagSVG />
        </div>
        <div className="bottom_section_right">
          <ClientStatusCircleXxlSvg isSVG={true} />
        </div>
      </div>
    </div>
  );
};

export default StaffDetailBox;
