import React, { useEffect, useRef, useState } from "react";
import CommonUpperBox from "../../components/UpperBox/CommonHeader";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../Controller/redux/Hooks";
import {
  getUserNotificationDetails,
  resetSelectedDropdownItemNotifications,
} from "../../../Controller/redux/features/NotificationsFeature/NotificaionsSlice";
import Table from "../../components/CommonTable/Table";

const Notifications = () => {
  const dispatch = useAppDispatch();
  const status = useLocation()?.state?.status;
  const navigate = useNavigate();

  const tableRef = useRef();
  const dropdownRef = useRef<any>(null);
  const svgParentRef = useRef<any>(null);

  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

  let title = status == 0 ? "#ed1c24" : status == 1 ? "#ffa41c" : "#2ad400";

  const columns2 = [
    { header: "Status", accessor: "status" },
    { header: "Title", accessor: "title" },
    { header: "Type", accessor: "asset_type" },
    { header: "Date", accessor: "start_date" },
    { header: "Description", accessor: "description" },
  ];

  const { notificationDetails, loading } = useAppSelector(
    (state) => state.notifications
  );

  const handleClickOutsideDocuments = (event: any) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      svgParentRef.current &&
      !svgParentRef.current.contains(event.target)
    ) {
      setIsDropdownOpen(false);
      dispatch(resetSelectedDropdownItemNotifications());
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutsideDocuments);
    return () => {
      document.removeEventListener("click", handleClickOutsideDocuments);
    };
  }, []);

  useEffect(() => {
    dispatch(getUserNotificationDetails(status !== undefined ? status : ""));
  }, [status]);

  const handleClickRow = (e: any, data: any) => {
    const encryptedId = window.btoa(data?.user_id);
    if (data?.asset_type === "Document") {
      localStorage.setItem("activeTab", "Documents");
      navigate(`/people/clients/${encryptedId}`);
    } else if (data?.asset_type === "Note") {
      localStorage.setItem("activeTab", "Clinical");
      navigate(`/people/clients/${encryptedId}`);
    } else {
      navigate(`/my-daybook`, {
        state: {
          customDate: new Date(data?.start_date),
        },
      });
    }
  };

  return (
    <>
      <CommonUpperBox title="Notifications" type={"listing"} status={title} />
      <Table
        isLoading={loading}
        tableRef={tableRef}
        itemsArr={[]}
        allClients={notificationDetails}
        statusType="notificationsScreen"
        thData={columns2}
        classNameTr={` all-prospects-table-head `}
        type="notifications"
        onClickRow={handleClickRow}
        classNameRow={`all-prospects-table-body`}
        fill={"#D8D3D3"}
        onClickSvg={() => setIsDropdownOpen(!isDropdownOpen)}
        isDropdownOpen={isDropdownOpen}
        dropdownRef={dropdownRef}
        svgParentRef={svgParentRef}
      />
    </>
  );
};

export default Notifications;
