import React, { useEffect, useState } from "react";
import CommonUpperBox from "../../components/UpperBox/CommonHeader";
import "./styles/index.css";
import { useLocation, useParams } from "react-router-dom";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../Controller/redux/Hooks";
import {
  getUserRolesVendors,
  getVendorById,
  updateVendor,
} from "../../../Controller/redux/features/VendorFeature/VendorSlice";
import UploadSvg from "../../../Model/Assets/SVG/Common/UploadSvg";
import { toast } from "react-toastify";
import CommonHeader from "../../components/CommonHeader/CommonHeader";
import CircularNoteTrigger from "../../components/CircularNoteTrigger/CircularNoteTrigger";

const VendorDetails = () => {
  const { id }: any = useParams();
  const location = useLocation();

  const vendorId = window.atob(id);

  const dispatch = useAppDispatch();

  const dynamicVendorsId = localStorage.getItem("dynamicVendorsId");

  const { currentVendor, getUserRoleVendors } = useAppSelector(
    (state) => state?.vendors
  );
  const { domainDetail } = useAppSelector((state) => state?.common);

  const [commonBody, setCommonBody] = useState<any>(currentVendor || {});

  const [vendorTypeArr, setVendorTypeArr] = useState<any>([]);

  const [userRoles, setUserRoles] = useState([]);

  useEffect(() => {
    if (getUserRoleVendors?.data && getUserRoleVendors?.data?.length > 0) {
      setUserRoles(getUserRoleVendors?.data);
    }
  }, [getUserRoleVendors]);

  useEffect(() => {
    if (domainDetail && domainDetail?.vendor_type) {
      const vendorType = domainDetail?.vendor_type.split(",");
      setVendorTypeArr(vendorType);
    }
  }, [domainDetail]);
  // console.log(currentVendor,'currentVendor')
  useEffect(() => {
    dispatch(getVendorById({ type: dynamicVendorsId, id: vendorId }));
    dispatch(getUserRolesVendors({ id: dynamicVendorsId }));
  }, []);

  useEffect(() => {
    setCommonBody(currentVendor);
  }, [currentVendor]);

  useEffect(() => {
    setCommonBody({});
  }, [location]);

  const dynamicColor = useAppSelector((state) => state?.common?.dynamicColor);

  const handleLogoUpload = async (event: any) => {
    const file: any = event.target.files[0];

    const toBase64 = (file) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
      });

    const base64Data: any = await toBase64(file);
    const extension = file.name.split(".").pop();

    setCommonBody({
      ...commonBody,
      extension,
      base64: base64Data?.split(",")[1],
    });
  };

  const handleUpdate = () => {
    if (JSON.stringify(commonBody) === JSON.stringify(currentVendor)) {
      toast.warn("No changes were made!");
      return;
    }
    dispatch(
      updateVendor({
        type: dynamicVendorsId,
        vendor_id: commonBody?.id,
        extension: commonBody?.extension,
        base64: commonBody?.base64,
        vendor_name: commonBody?.vendor_name,
        link: commonBody?.link,
        role: commonBody?.role,
      })
    ).then((response: any) => {
      if (response.error) {
        toast.error(response?.payload || "Error updating vendor");
        return;
      }
      toast.success("Vendor updated successfully!", {
        progressStyle: { backgroundColor: dynamicColor },
        icon: (
          <span
            style={{
              marginRight: "8px",
              borderRadius: "50%",
              width: "7rem",
              height: "35px",
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: dynamicColor,
              backgroundColor: "white",
              fontSize: "1.5rem",
              border: `3px solid ${dynamicColor}`,
            }}
          >
            ✓
          </span>
        ),
      });
      dispatch(getVendorById({ type: dynamicVendorsId, id: vendorId }));
    });
  };

  return (
    <div>
      <CommonUpperBox
        type={"listing"}
        title={currentVendor?.vendor_name}
        imageURL={currentVendor?.profile_image_s3}
      />

      <div className="unique-client-details-wrapper">
        <CommonHeader
          mainType="vendor"
          type="details"
          handleClick={() => {}}
          currentVendor={currentVendor}
        />
        <div
          style={{
            position: "relative",
            top: "4px",
          }}
        >
          <div className="vendor_form">
            <div className="form_group">
              <label htmlFor="name" className="label_vendors">
                Name
              </label>
              <input
                type="text"
                id="name"
                className="text_input_vendors"
                value={commonBody?.vendor_name}
                onChange={(e) =>
                  setCommonBody({ ...commonBody, vendor_name: e.target.value })
                }
              />
            </div>

            <div className="form_group">
              <label htmlFor="liveLink" className="label_vendors">
                Live Link
              </label>
              <input
                type="text"
                id="liveLink"
                className="text_input_vendors"
                value={commonBody?.link}
                onChange={(e) =>
                  setCommonBody({ ...commonBody, link: e.target.value })
                }
              />
            </div>
            <div className="form_group">
              <label htmlFor="category" className="label_vendors">
                Category
              </label>
              <select
                required
                id="category"
                className="category_select"
                onChange={(e) =>
                  setCommonBody({ ...commonBody, role: e.target.value })
                }
                value={commonBody?.role || currentVendor?.category_id}
              >
                <option value="">Please Select...</option>

                {userRoles?.map((elem: any, index: number | any) => {
                  let isExist: any = vendorTypeArr.includes(`${elem?.desc}`);
                  if (isExist) {
                    return (
                      <option key={index} value={elem?.id}>
                        {elem.desc}
                      </option>
                    );
                  }
                })}
              </select>
            </div>

            <div className="form_group">
              <label className="label_vendors">Icon/Tile Image</label>

              <input type="file" onChange={handleLogoUpload} accept="image/*" />
            </div>

            {commonBody?.profile_image_s3 && (
              <div className="current_logo">
                <img src={commonBody?.profile_image_s3} alt="logo" />
              </div>
            )}

            <button className="update_button" onClick={handleUpdate}>
              Update Vendor
            </button>
          </div>
        </div>
      </div>
      <CircularNoteTrigger type="Vendors" title="Vendors" />
    </div>
  );
};

export default VendorDetails;
