import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../../services/api";

const initialState: any = {
  // GOALS
  addNewActivityToGoal: null,
  createGoalsData: null,
  allGoalsData: [],
  CustomFormError: null,
  clinicalGoalModalDetails: [],
  updateClinicalGoalModalDetails: null,
  currentGoal: null,
  getClinicalObs: [],
  graphData: {
    "Blood Pressure Standing": [],
    "Blood Pressure Sitting": [],
    Temperature: [],
    Weight: [],
    Respirations: [],
    "Heart Rate": [],
  },
  // Clical Note
  clicalDetails: {
    note: "",
  },
  ClinicalNotesData: [],
  lastPage: 0,
  ClinicalPage: 1,

  selectedClinicalNoteInfo: {},

  // CustomForm Start
  customFormTabs: [],
  noteInfoProgress: {},
  error: {}
  // clinical note info
};

export const createGoalsThunk = createAsyncThunk(
  "goals/createGoal",
  async ({ data }: any) => {
    try {
      const response = await api.post("/goals/create_goal", data);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const updateGoalsThunk = createAsyncThunk(
  "goals/updateGoal",
  async ({ data }: any) => {
    try {
      const response = await api.post("/goals/update_goal", data);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getGoalsThunk = createAsyncThunk(
  "goals/getGoals",
  async ({ clientId, service_type_id }: any) => {
    let url = `/goals?user_id=${clientId}`;
    if (service_type_id) {
      url = url + `&service_type_id=${service_type_id}`;
    }
    try {
      const response = await api.get(url);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getClinicalGoalModalDetailsThunk = createAsyncThunk(
  "goals/getClinicalGoalModalDetails",
  async ({ clientId }: any) => {
    try {
      const response = await api.get(`/custom_form?user_id=${clientId}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const updateClinicalGoalModalDetailsThunk = createAsyncThunk(
  "goals/updateClinicalGoalModalDetails",
  async ({ data }: any) => {
    try {
      const response = await api.post(
        `/custom_form/update_custom_form_details`,
        data
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const updateClinicalObsThunk = createAsyncThunk(
  "clients/updateClinicalObs",
  async (clinicalBody: any, { rejectWithValue }) => {
    try {
      const response = await api.post(
        "/clinical/update_clinical",
        clinicalBody
      );
      return response.data;
    } catch (error: string | any) {
      return rejectWithValue(
        error.message || "Failed to upload client details"
      );
    }
  }
);
export const getClinicalObsThunk = createAsyncThunk(
  "clients/fetchClinicalObs",
  async ({ dynamicId, date, time }: any) => {
    try {
      const response = await api.get(
        `/clinical?user_id=${dynamicId}&date=${date}&time=${time}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getClinicalGraphDataThunk = createAsyncThunk(
  "goals/getClinicalGraphDataThunk",
  async ({ clientId, observation, days }: any) => {
    try {
      const response = await api.get(
        `/clinical/graph_data?user_id=${clientId}&observation=${observation}&day=${days}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const deleteGoalThunk = createAsyncThunk(
  "goals/deleteGoal",
  async ({ clientId, goalId }: any) => {
    try {
      const goal = { user_id: clientId, goal_id: goalId };
      const response = await api.post(`/goals/delete_goal`, goal);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const deleteActivityThunk = createAsyncThunk(
  "goals/deleteActivity",
  async ({ activityId, goalId }: any) => {
    try {
      const activity = { activity_id: activityId, goal_id: goalId };
      const response = await api.post(`/goals/delete_goal_activity`, activity);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const createClinicalNoteThunk = createAsyncThunk(
  "clinicalNote/createNote",
  async (clinicalNoteInfo: any, { rejectWithValue }) => {
    try {
      const response = await api.post(
        "/goals/create_activity_note",
        clinicalNoteInfo
      );
      if (response.data.status === "success") {
        return response.data;
      } else {
        return rejectWithValue("Failed to create clinical note");
      }
    } catch (error: any) {
      // throw error;
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateClinicalNoteThunk = createAsyncThunk(
  "clinicalNote/updateNote",
  async (clinicalNoteInfo: any, { rejectWithValue }) => {
    try {
      const response = await api.post(
        "/goals/update_activity_note",
        clinicalNoteInfo
      );
      if (response.data.status === "success") {
        return response.data;
      } else {
        return rejectWithValue("Failed to create clinical note");
      }
    } catch (error: any) {
      // throw error;
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAllClinicalNotes = createAsyncThunk(
  "clinicalNote/fetchNote",
  async ({ user_id, sortType = "", searchVal = "", unreadFlag = "" }: any) => {
    try {
      const response = await api.get(
        `/goals/get_activity_note?paginate=true&page=1&limit=100000&user_id=${user_id}&sort_type=${sortType}&search=${searchVal}&unread=${unreadFlag}`
      );
      return response?.data?.data;
    } catch (error) {
      throw error;
    }
  }
);

// export const getCustomFormsTabs = createAsyncThunk(
//   "customForm/fetchTabs",
//   async ({ clientId }: any) => {
//     try {
//       const response = await api.get(`/custom_form?user_id=${clientId}`);
//       return response?.data?.data;
//     } catch (error) {
//       throw error;
//     }
//   }
// );

export const createCustomFormTab = createAsyncThunk(
  "customForm/createTab",
  async (tabInfo: any, { rejectWithValue }) => {
    try {
      const response = await api.post(`/custom_form/create_tab`, tabInfo);
      return response?.data;
    } catch (error:any) {
      return rejectWithValue(error?.response?.data?.response);
    }
  }
);

export const updateCustomFormTab = createAsyncThunk(
  "customForm/updateTab",
  async (tabInfo: any) => {
    try {
      const response = await api.post(`/custom_form/update_tab`, tabInfo);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
);

export const createQuestionCustomFormTab = createAsyncThunk(
  "customForm/createQuestions",
  async (questionInfo: any,{ rejectWithValue }) => {
    try {
      const response = await api.post(
        `/custom_form/create_question`,
        questionInfo
      );
      return response?.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data?.response)
    }
  }
);
export const createAnswerCustomFormTab = createAsyncThunk(
  "customForm/createAnswer",
  async (answerInfo: any ,{ rejectWithValue }) => {
    try {
      const response = await api.post(`/custom_form/create_answer`, answerInfo);
      return response?.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data?.response);
    }
  }
);
export const deleteQuestionCustomFormTab = createAsyncThunk(
  "customForm/deltetQuestion",
  async (id: any ,{ rejectWithValue }) => {
    try {
      const response = await api.post(`/custom_form/delete_question`, id);
      return response?.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data?.response);
    }
  }
);
export const deleteTabCustomFormTab = createAsyncThunk(
  "customForm//deleteTab",
  async (id: any,{ rejectWithValue }) => {
    try {
      const response = await api.post(`/custom_form/delete_tab`, id);
      return response?.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data?.response);
    }
  }
);

export const updateQuestionCustomFormTab = createAsyncThunk(
  "customForm/updateQuestions",
  async (questionInfo: any, { rejectWithValue }) => {
    try {
      const response = await api.post(
        `/custom_form/update_question`,
        questionInfo
      );
      return response?.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data?.response);
    }
  }
);

export const updateQuestionSequence = createAsyncThunk(
  "customForm/updateQuestionsSequence",
  async (parmas: any) => {
    try {
      const response = await api.post(
        `/custom_form/update_question_sequence`,
        parmas
      );
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
);

const clinicalSlice = createSlice({
  name: "clinical",
  initialState,
  reducers: {
    setcurrentGoal: (state, action) => {
      state.currentGoal = action.payload;
    },

    resetcurrentGoal: (state, action) => {
      state.currentGoal = null;
    },
    clearGraphData: (state) => {
      state.graphData = [];
    },
    clearClinicalObs: (state) => {
      state.getClinicalObs = [];
    },
    setClinicalDetails: (state, action) => {
      state.clicalDetails = action.payload;
    },
    setClinicalPage: (state) => {
      state.ClinicalPage += 1;
    },
    resetClinicalPage: (state) => {
      state.ClinicalPage = 1;
    },
    resetClinicalRecords: (state) => {
      state.ClinicalNotesData = [];
    },
    setClinicalDetailsInfo: (state, action) => {
      state.selectedClinicalNoteInfo = action.payload;
    },
    setaddNewActivityToGoal: (state, action) => {
      state.addNewActivityToGoal = action.payload;
    },
    resetaddNewActivityToGoal: (state, action) => {
      state.addNewActivityToGoal = null;
    },
    setnoteInfoProgress: (state, action) => {
      state.noteInfoProgress = action.payload
    },
    resetnoteInfoProgress: (state, action) => {
      state.noteInfoProgress = ""
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(createGoalsThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(createGoalsThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.createGoalsData = action.payload;
      })
      .addCase(createGoalsThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getClinicalGraphDataThunk.fulfilled, (state, action) => {
        state.loading = false;
        const payloadData = action.payload.data;
        // GRAB DATA FROM META INSTEAD OF LOOP.
        const observation = action.meta.arg.observation;

        if (payloadData) {
          state.graphData[observation] = payloadData;
        }
      })
      .addCase(getGoalsThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getGoalsThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.allGoalsData = action.payload;
      })


      .addCase(createAnswerCustomFormTab.rejected, (state, action) => {
        state.loading = false;
        state.CustomFormError = action.payload;
      })


      .addCase(getGoalsThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getClinicalGoalModalDetailsThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getClinicalGoalModalDetailsThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.clinicalGoalModalDetails = action.payload.data;
      })
      .addCase(getClinicalGoalModalDetailsThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updateClinicalGoalModalDetailsThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        updateClinicalGoalModalDetailsThunk.fulfilled,
        (state, action) => {
          state.loading = false;
          state.updateClinicalGoalModalDetails = action.payload;
        }
      )
      .addCase(
        updateClinicalGoalModalDetailsThunk.rejected,
        (state, action) => {
          state.loading = false;
          state.error = action.error.message;
        }
      )
      .addCase(updateClinicalObsThunk.pending, (state) => {
        state.loading = true;
        state.error = null;
      })

      .addCase(updateClinicalObsThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.updateClinicalObsThunk = action.payload;
      })
      .addCase(updateClinicalObsThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload
          ? action.payload
          : "Failed To Update Clinical Details!";
      })
      .addCase(getClinicalObsThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getClinicalObsThunk.fulfilled, (state, action) => {
        state.getClinicalObs = action.payload;
      })
      .addCase(updateQuestionCustomFormTab.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(createQuestionCustomFormTab.rejected, (state, action) => {
        state.error = action.payload;
      })
      // createQuestionCustomFormTab
      //updateQuestionCustomFormTab
      .addCase(getClinicalObsThunk.rejected, (state, action) => {
        state.loading =  action.error.message;
      })
      .addCase(deleteGoalThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteGoalThunk.fulfilled, (state, action) => {
        state.deleteGoal = action.payload;
      })
      .addCase(deleteGoalThunk.rejected, (state) => {
        state.loading = true;
      })
      .addCase(deleteActivityThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteActivityThunk.fulfilled, (state, action) => {
        state.deleteActivity = action.payload;
      })
      .addCase(deleteActivityThunk.rejected, (state) => {
        state.loading = true;
      })
      .addCase(getAllClinicalNotes.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllClinicalNotes.fulfilled, (state, action) => {
        state.lastPage = action?.payload?.last_page;
        state.loading = false;
        state.ClinicalNotesData = [...action.payload.data];
      })
      .addCase(getAllClinicalNotes.rejected, (state) => {
        state.loading = true;
      })
      //Custom Fomr
      // .addCase(getCustomFormsTabs.pending, (state) => {
      //   state.loading = true;
      // })
      // .addCase(getCustomFormsTabs.fulfilled, (state, action) => {
      //   state.customFormTabs = action?.payload;
      // })
      // .addCase(getCustomFormsTabs.rejected, (state) => {
      //   state.loading = true;
      // });
  },
});

export const {
  setcurrentGoal,
  resetcurrentGoal,
  clearGraphData,
  clearClinicalObs,
  setClinicalDetails,
  resetClinicalPage,
  setClinicalPage,
  resetClinicalRecords,
  setClinicalDetailsInfo,
  setaddNewActivityToGoal,
  resetaddNewActivityToGoal,
  setnoteInfoProgress,
  resetnoteInfoProgress
} = clinicalSlice.actions;
export default clinicalSlice.reducer;
