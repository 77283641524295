import React from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../Controller/redux/Hooks";
import {
  resetchangeStickyForClinicalWhenMobileView,
  setShowStickyNote,
} from "../../../../../../Controller/redux/features/CommonFeature/commonSlice";
import CommentWithNumberSVG from "../../../../../../Model/Assets/SVG/Common/CommentWithNumberSVG";

const UserPictureWithCommsCount = ({
  isCountNeeded,
  isFromStickyNote,
  isFromMobileView,
}: any) => {
  const dispatch = useAppDispatch();

  const { user, dynamicColor } = useAppSelector((state) => state?.common);

  const isAdminActive = user?.tenant?.id === user?.tenant?.parent_id;

  const { getUnreadMessages } = useAppSelector((state) => state?.comms);
  let data = getUnreadMessages[0];

  const { showStickyNote } = useAppSelector((state) => state?.common);

  const stickyNoteContainerStyle = {
    background: dynamicColor,
    border: `2px solid ${dynamicColor}`,
    marginTop: isAdminActive ? "75px" : "0px",
  };

  const initialsNameStyle = {
    borderRadius: "50%",
    width: "44px",
    height: "44px",
    letterSpacing: isFromStickyNote ? "2.6px" : "1.35px",
  };

  return (
    <div
      className="sticky_note_img_container"
      style={!isFromMobileView ? stickyNoteContainerStyle : {}}
      onClick={() => {
        dispatch(resetchangeStickyForClinicalWhenMobileView(false));
        dispatch(setShowStickyNote(!showStickyNote));
      }}
    >
      {user?.profile_image_s3 ? (
        <img
          src={user?.profile_image_s3 ? user?.profile_image_s3 : ""}
          alt="profile"
          className="user_profile_img"
        />
      ) : (
        <div className="initials__name" style={initialsNameStyle}>
          {`${user?.first_name?.charAt(0) ? user.first_name?.charAt(0) : ""}${
            user?.last_name?.charAt(0) ? user.last_name?.charAt(0) : ""
          }`}
        </div>
      )}
      {isCountNeeded && (
        <div className="circular_note_svg">
          <CommentWithNumberSVG
            number={data?.data?.unread_messages || 0}
            fill={data?.data?.traffic_light || "Green"}
          />
        </div>
      )}
    </div>
  );
};

export default UserPictureWithCommsCount;
