export const itemsArr = [
  {
    status: 1,
    type: "Active",
    color: "#97D94A",
    bgCol: "#F8FFEF",
  },
  {
    status: 2,
    type: "On Leave",
    color: "#FFBC10",
    bgCol: "#FEFFE5",
  },
  {
    status: 3,
    type: "Hospital",
    color: "#FFBC10",
    bgCol: "#FEFFE5",
  },
  {
    status: 4,
    type: "Waitlist",
    color: "#FFBC10",
    bgCol: "#FEFFE5",
  },
  {
    status: 5,
    type: "Respite",
    color: "#FFBC10",
    bgCol: "#FEFFE5",
  },
  {
    status: 0,
    type: "Inactive",
    color: "#EB5757",
    bgCol: "#FFF0F0",
  },
  {
    status: 6,
    type: "Deceased",
    color: "#EB5757",
    bgCol: "#FFF0F0",
  },
  {
    status: 8,
    type: "Deposit",
    color: "#3498db",
    bgCol: "#f0f8ff",
  },
  {
    status: 9,
    type: "Moved In",
    color: "#3498db",
    bgCol: "#f0f8ff",
  },
  {
    status: 10,
    type: "For Future",
    color: "#3498db",
    bgCol: "#f0f8ff",
  },
  { status: 11, type: "Make Client", color: "#97D94A", bgCol: "#f8ffef" },
  {
    status: 12,
    type: "Open",
    color: "#97D94A",
    bgCol: "#F8FFEF",
  },
  {
    status: 13,
    type: "In Progress",
    color: "#FFBC10",
    bgCol: "#FEFFE5",
  },
  {
    status: 14,
    type: "Closed",
    color: "#EB5757",
    bgCol: "#FFF0F0",
  },
  {
    status: 15,
    type: "Lost",
    color: "#EB5757",
    bgCol: "#FFF0F0",
  },
];

export const itemsArrProspectsAll = [
  {
    status: 1,
    type: "Active",
    color: "#97D94A",
    bgCol: "#F8FFEF",
  },
  {
    status: 2,
    type: "On Leave",
    color: "#FFBC10",
    bgCol: "#FEFFE5",
  },
  {
    status: 3,
    type: "Hospital",
    color: "#FFBC10",
    bgCol: "#FEFFE5",
  },
  {
    status: 4,
    type: "Waitlist",
    color: "#FFBC10",
    bgCol: "#FEFFE5",
  },
  {
    status: 5,
    type: "Respite",
    color: "#FFBC10",
    bgCol: "#FEFFE5",
  },
  {
    status: 0,
    type: "Inactive",
    color: "#EB5757",
    bgCol: "#FFF0F0",
  },
  {
    status: 6,
    type: "Deceased",
    color: "#EB5757",
    bgCol: "#FFF0F0",
  },
  {
    status: 8,
    type: "Deposit",
    color: "#3498db",
    bgCol: "#f0f8ff",
  },
  {
    status: 9,
    type: "Moved In",
    color: "#3498db",
    bgCol: "#f0f8ff",
  },
  {
    status: 10,
    type: "For Future",
    color: "#3498db",
    bgCol: "#f0f8ff",
  },
  { status: 11, type: "Make Client", color: "#97D94A", bgCol: "#f8ffef" },
  {
    status: 12,
    type: "Open",
    color: "#97D94A",
    bgCol: "#F8FFEF",
  },
  {
    status: 13,
    type: "In Progress",
    color: "#FFBC10",
    bgCol: "#FEFFE5",
  },
  {
    status: 14,
    type: "Closed",
    color: "#EB5757",
    bgCol: "#FFF0F0",
  },
  {
    status: 15,
    type: "Lost",
    color: "#EB5757",
    bgCol: "#FFF0F0",
  },
];
export const itemsArrProspects = [
  {
    status: 1,
    type: "Active",
    color: "#97D94A",
    bgCol: "#F8FFEF",
  },
  // {
  //   status: 2,
  //   type: "On Leave",
  //   color: "#FFBC10",
  //   bgCol: "#FEFFE5",
  // },
  // {
  //   status: 3,
  //   type: "Hospital",
  //   color: "#FFBC10",
  //   bgCol: "#FEFFE5",
  // },
  {
    status: 4,
    type: "Waitlist",
    color: "#FFBC10",
    bgCol: "#FEFFE5",
  },
  // {
  //   status: 5,
  //   type: "Respite",
  //   color: "#FFBC10",
  //   bgCol: "#FEFFE5",
  // },
  {
    status: 0,
    type: "Inactive",
    color: "#EB5757",
    bgCol: "#FFF0F0",
  },
  {
    status: 6,
    type: "Deceased",
    color: "#EB5757",
    bgCol: "#FFF0F0",
  },
  {
    status: 8,
    type: "Deposit",
    color: "#3498db",
    bgCol: "#f0f8ff",
  },
  {
    status: 9,
    type: "Moved In",
    color: "#3498db",
    bgCol: "#f0f8ff",
  },
  {
    status: 10,
    type: "For Future",
    color: "#3498db",
    bgCol: "#f0f8ff",
  },
  { status: 11, type: "Make Client", color: "#97D94A", bgCol: "#f8ffef" },
];

export const itemsArrProspectsHomeCare = [
  {
    status: 12,
    type: "Open",
    color: "#97D94A",
    bgCol: "#F8FFEF",
  },
  {
    status: 13,
    type: "In Progress",
    color: "#FFBC10",
    bgCol: "#FEFFE5",
  },
  {
    status: 14,
    type: "Closed",
    color: "#EB5757",
    bgCol: "#FFF0F0",
  },
  {
    status: 15,
    type: "Lost",
    color: "#EB5757",
    bgCol: "#FFF0F0",
  },
  {
    status: 4,
    type: "Wait List",
    color: "#3498db",
    bgCol: "#f0f8ff",
  },
  { status: 11, type: "Make Client", color: "#97D94A", bgCol: "#f8ffef" },
];

export const itemsArrProspectsVillage = [
  {
    status: 12,
    type: "Open",
    color: "#97D94A",
    bgCol: "#F8FFEF",
  },
  {
    status: 13,
    type: "In Progress",
    color: "#FFBC10",
    bgCol: "#FEFFE5",
  },
  {
    status: 10,
    type: "For Future",
    color: "#3498db",
    bgCol: "#f0f8ff",
  },
  {
    status: 14,
    type: "Closed",
    color: "#EB5757",
    bgCol: "#FFF0F0",
  },
  {
    status: 15,
    type: "Lost",
    color: "#EB5757",
    bgCol: "#FFF0F0",
  },
  {
    status: 4,
    type: "Wait List",
    color: "#3498db",
    bgCol: "#f0f8ff",
  },
  {
    status: 9,
    type: "Moved In",
    color: "#3498db",
    bgCol: "#f0f8ff",
  },
  { status: 11, type: "Make Client", color: "#97D94A", bgCol: "#f8ffef" },
];

export const itemsArrContacts = [
  {
    status: 1,
    type: "Active",
    color: "#97D94A",
    bgCol: "#F8FFEF",
  },
  // {
  //   status: 2,
  //   type: "On Leavdee",
  //   color: "#FFBC10",
  //   bgCol: "#FEFFE5",
  // },
  // {
  //   status: 3,
  //   type: "Hospital",
  //   color: "#FFBC10",
  //   bgCol: "#FEFFE5",
  // },
  // {
  //   status: 4,
  //   type: "Waitlist",
  //   color: "#FFBC10",
  //   bgCol: "#FEFFE5",
  // },
  // {
  //   status: 5,
  //   type: "Respite",
  //   color: "#FFBC10",
  //   bgCol: "#FEFFE5",
  // },
  {
    status: 0,
    type: "Inactive",
    color: "#EB5757",
    bgCol: "#FFF0F0",
  },
  // {
  //   status: 6,
  //   type: "Deceased",
  //   color: "#EB5757",
  //   bgCol: "#FFF0F0",
  // },
];

export const itemArrStaff = [
  {
    status: 0,
    type: "Inactive",
    color: "#EB5757",
    bgCol: "#FFF0F0",
  },
  {
    status: 1,
    type: "Active",
    color: "#97D94A",
    bgCol: "#F8FFEF",
  },
  {
    status: 2,
    type: "On Leave",
    color: "#FFBC10",
    bgCol: "#FEFFE5",
  },
  {
    status: 7,
    type: "Probation",
    color: "#FFBC10",
    bgCol: "#FEFFE5",
  },
];

export const itemArrBusiness = [
  {
    status: 1,
    type: "Active",
    color: "#97D94A",
    bgCol: "#F8FFEF",
  },
  {
    status: 0,
    type: "Inactive",
    color: "#EB5757",
    bgCol: "#FFF0F0",
  },
];
