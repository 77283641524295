import React from "react";
import "./Styles/Table.css";

import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

import {
  useAppDispatch,
  useAppSelector,
} from "../../../Controller/redux/Hooks";
import { api } from "../../../Controller/services/api";

import {
  DateFormat,
  formatDate,
  formatPrimaryReview1,
  timeAgo,
} from "../../../Model/utils/Helper/formatDate";
import { statusMap } from "../../../Model/utils/Helper/statusMap";

import TableDropdown2 from "./Components/TableDropDown2";
import WidgetItems from "../../pages/People/ClientPage/Components/WidgetItems";

import {
  getDocuments,
  getDocumentsForClinicalThunk,
  setConfirmDeleteDocument,
  updateDocumentThunk,
} from "../../../Controller/redux/features/CommonFeature/documentSlice";
import { setConfirmDelete } from "../../../Controller/redux/features/CommonFeature/associationSlice";
import {
  createMeal,
  setConfirmDeleteMeal,
} from "../../../Controller/redux/features/MealsFeatures/MealFeature/mealbuilderSlice";

import FilterSvg from "../../../Model/Assets/SVG/Common/FilterSvg";
import DeleteSvg from "../../../Model/Assets/SVG/Common/DeleteSvg";
import GreenTickSvg from "../../../Model/Assets/SVG/Common/GreenTickSvg";
import {
  AllDocSvg,
  DocxSvg,
  PdfDocSvg,
} from "../../../Model/Assets/SVG/Common/AllDocsSvg";
import { GreenCircleSvg } from "../../pages/People/ClientPage/Components/UniqueClientScreen/Components/ClinicalTab/Data/SVGs";
import { Modal, ModalOverlay, Spinner, Switch } from "@chakra-ui/react";
import { setisDropdownOpen } from "../../../Controller/redux/features/ProspectsFeature/prospectsSlice";
import PencilSvg from "../../../Model/Assets/SVG/Common/PencilSvg";
import UpDownSvg from "../../../Model/Assets/SVG/Common/UpDownSvg";
import {
  cloneCustomFormThunk,
  createCustomForm,
  getCustomFormVersionsThunk,
  getCustomFormsThunk,
  getFormTypeByFilterThunk,
  publishCustomFormThunk,
  setConfirmDeleteForm,
  setcurrentEditCustomForm,
} from "../../../Controller/redux/features/CustomFromFeature/customFormSlice";
import CopySVG from "../../pages/Daybook/Data/CopySVG";
import { toast } from "react-toastify";
import { EditIcon } from "@chakra-ui/icons";
import TrafficLights from "../CommonModal/Components/TrafficLights";
import { getUserNotificationStats } from "../../../Controller/redux/features/NotificationsFeature/NotificaionsSlice";
import { setCurrentEditVendor } from "../../../Controller/redux/features/VendorFeature/VendorSlice";
import { setShowStickyNote } from "../../../Controller/redux/features/CommonFeature/commonSlice";
import { setCurrentEditReportsHub } from "../../../Controller/redux/features/ReportsHubFeature/ReportsHubSlice";
import GreenColorSVG from "../../pages/Comms/Data/GreenColorSVG";
import { PolygonSVG } from "../CommonModal/Data/SVGs";
import TriangularTrafficLights from "../TrianglularTrafficLights/TriangularTrafficLights";

const Table = ({
  dynamicHeaderColor = "#f3f3f3",
  classNameTable,
  classNameTr,
  currentEditCustomForm,
  isAdminActive,
  providerId,
  thData,
  classNameTh,
  svgParentRef,
  onClickSvg = () => {},
  fill,
  isDropdownOpen,
  dropdownRef,
  type,
  allClients,
  classNameRow,
  tableBodyRef,
  onScroll,
  classNameBody,
  onClickRow = () => {},
  statusType,
  hasInput = false,
  itemsArr,
  isLoading = false,
  handleReinviteStaff,
  isFromNotifications,
}: any) => {
  const { dateFormat, showStickyNote } = useAppSelector(
    (state) => state?.common
  );
  const dispatch = useAppDispatch();
  const id: any = useParams()?.id;
  const [clientId, setClientId] = useState("");
  useEffect(() => {
    if (id) {
      let realId = window.atob(id);
      setClientId(realId);
    }
  }, [id]);

  ///////////// Fetch user id from local storage  ////////////////////////
  // @ts-ignore
  const payloadDetails = JSON.parse(localStorage.getItem("user"));
  // @ts-ignore
  const payloadId = payloadDetails?.data?.id;
  //////////////////////////////////////

  const { error } = useAppSelector((state) => state.customForm);

  function handleOpenStickyNoteToUpdateCustomForm(id: number | any) {
    dispatch(setcurrentEditCustomForm(id));
  }

  const handleUpdateVendor = (data: any) => {
    dispatch(setCurrentEditVendor(data));
    dispatch(setShowStickyNote(!showStickyNote));
  };

  const handleUpdateReportsHub = (data: any) => {
    dispatch(setCurrentEditReportsHub(data));
    dispatch(setShowStickyNote(!showStickyNote));
  };

  // START STATUS UPDATE //
  const [OpenStatusWidget, setOpenStatusWidget] = useState<boolean>(false);
  const [
    allClientsDataToTrackClickOfStatusClick,
    setAllClientsDataToTrackClickOfStatusClick,
  ] = useState(allClients ? Array(allClients.length).fill(false) : []);

  const tableRef = useRef<any>();
  const statusWidgetRef = useRef<any>();

  // @ts-ignore
  const userDetails = JSON.parse(localStorage.getItem("user"));
  const userId = userDetails?.data?.id;

  const ifAllRowsChecked = allClients?.every(
    (elem: { completion_status: number }) => elem.completion_status == 2
  );

  const handleOpenStatusWidgetClick = (e: any, index: any, id: any) => {
    e.stopPropagation();
    setOpenStatusWidget(true);
    let spreadAllRowsOfClients = [...allClientsDataToTrackClickOfStatusClick];
    if (spreadAllRowsOfClients[index]) {
      // spreadAllRowsOfClients[index] = false;
    } else {
      for (let i = 0; i < spreadAllRowsOfClients.length; i++) {
        spreadAllRowsOfClients[i] = false;
      }

      spreadAllRowsOfClients[index] = true;
    }
    setAllClientsDataToTrackClickOfStatusClick(spreadAllRowsOfClients);
  };
  const { lastPage, formTypePage, formTypeFilterItems } = useAppSelector(
    (state) => state.customForm
  );

  // END STATUS UPDATE //

  // DELETE ASSOCIATION START //
  const handleDeleteAssociation = async (id: any) => {
    dispatch(setConfirmDelete({ flag: true, id }));
  };
  const handleDeleteDocument = async (e: any, id: any) => {
    e.stopPropagation();
    await dispatch(setConfirmDeleteDocument({ flag: true, id, clientId }));
  };
  // DELETE ASSOCIATION END //

  // DELETE MEAL START //
  const handleDeleteMeal = async (e: any, id: any) => {
    e.stopPropagation();
    await dispatch(setConfirmDeleteMeal({ flag: true, id }));
  };

  const { required_percentage } = useAppSelector(
    (state) => state?.common?.domainDetail
  );

  // console.log(required_percentage,'required_percentage')
  // DELETE MEAL END//
  const handleDeleteFile = async (id: any) => {
    try {
      await api.post("/files/delete_document_file", { idloading });
      dispatch(getDocuments(clientId));
    } catch (error) {
      console.error("API Error:", error);
    }
  };

  const unitMapping: any = {
    minute: ["minute", "minutes"],
    hour: ["hour", "hours"],
    // second: ["second", "seconds"],
    minutes: ["minute", "minutes"],
    hours: ["hour", "hours"],
    seconds: ["second", "seconds"],
  };

  // Custom Form
  const [showLights, setShowLights] = useState<any>(
    Array.isArray(allClients) ? Array(allClients.length).fill(false) : []
  );

  const falsifyAllLights = () => {
    setShowLights(Array(allClients?.length).fill(false));
  };

  useEffect(() => {
    if (Array.isArray(allClients)) {
      setShowLights(Array(allClients.length).fill(false));
    }
  }, [allClients]);

  const updateCompletionOfAllDocs = () => {
    if (allClients) {
      allClients.forEach(
        (doc: {
          id: any;
          custom_form_id: any;
          title: any;
          user_id: any;
          primary_approver_id: string;
          ongoing_approver_id: string;
          note_details: any;
        }) => {
          if (true) {
            const updateTrafficLightPayload = {
              completion_status: 2,
              custom_form_id: doc.custom_form_id,
              document_id: doc.id,
              title: doc.title,
              user_id: doc.user_id || clientId,
              primary_approver_id: doc.primary_approver_id?.split(",") || [],
              ongoing_approver_id: doc.ongoing_approver_id?.split(",") || [],
              note_details: doc?.note_details || {},
            };

            dispatch(updateDocumentThunk(updateTrafficLightPayload)).then(
              (res: { payload: { status: string } }) => {
                dispatch(
                  getDocumentsForClinicalThunk({ clientId, providerId })
                );
                dispatch(getDocuments({ clientId, providerId }));
              }
            );
          }
        }
      );
    }
  };

  const handleDeleteForm = async (e: any, id: any) => {
    e.stopPropagation();
    await dispatch(setConfirmDeleteForm({ flag: true, id }));
  };
  // @ts-ignore
  const tenantId = userDetails?.data?.tenant_id;

  const dynamicColor = useAppSelector((state) => state?.common?.dynamicColor);

  const handleDuplicateForm = async (e: any, elem: any) => {
    e.stopPropagation();
    const copyElem = {
      custom_form_id: elem.id,
      tenant_id: tenantId,
      clone_type: "clone",
    };
    const response = await dispatch(cloneCustomFormThunk(copyElem));
    if (response?.payload?.status === "success") {
      toast.success(`Form Cloned Successfully!`, {
        progressStyle: { backgroundColor: dynamicColor },
        icon: (
          <span
            style={{
              marginRight: "8px",
              borderRadius: "50%",
              width: "7rem",
              height: "35px",
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: dynamicColor,
              backgroundColor: "white",
              fontSize: "1.5rem",
              border: `3px solid ${dynamicColor}`,
            }}
          >
            ✓
          </span>
        ),
      });
      dispatch(getCustomFormsThunk(payloadId));
    } else {
      toast.error(error);
    }
  };

  const handleLiftForm = async (e: any, elem: any) => {
    e.stopPropagation();
    const copyElem = {
      custom_form_id: elem.id,
      tenant_id: tenantId,
      clone_type: "lift",
    };

    const response = await dispatch(cloneCustomFormThunk(copyElem));
    if (response?.payload?.status === "success") {
      toast.success(`Form Lifted Successfully!`, {
        progressStyle: { backgroundColor: dynamicColor },
        icon: (
          <span
            style={{
              marginRight: "8px",
              borderRadius: "50%",
              width: "7rem",
              height: "35px",
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: dynamicColor,
              backgroundColor: "white",
              fontSize: "1.5rem",
              border: `3px solid ${dynamicColor}`,
            }}
          >
            ✓
          </span>
        ),
      });
      dispatch(getCustomFormsThunk(payloadId));
    } else {
      toast.error(error);
    }

    dispatch(getCustomFormVersionsThunk(elem.id));
  };



  const [showNoDataMessage, setShowNoDataMessage] = useState(false);

  useEffect(() => {
    if (!isLoading && allClients?.length === 0) {
      const timeoutId = setTimeout(() => {
        setShowNoDataMessage(true);
      }, 5000); 

      return () => clearTimeout(timeoutId); // Cleanup timeout if component unmounts or dependencies change
    } else {
      setShowNoDataMessage(false); // Reset if data is loading or present
    }
  }, [isLoading, allClients]);
  // console.log('allClients', allClients);

  const renderCellContent = (
    elem1: any,
    elem2: any,
    dateFormat: any,
    statusMap: any,
    index: any
  ) => {
    let { accessor } = elem2;
    let cellValue;

    if (accessor === "mobile") {
      cellValue = elem1["mobile"] || elem1["phone"];
    } else {
      cellValue = elem1[accessor];
    }


    // // console.log(cellValue, "cellValue");
    switch (accessor) {
      case "created_at":
        return `${DateFormat(cellValue, dateFormat)}    ${elem1.created_time}`;

      case "completed_status":
        return (
          <div className="triangle_section_wrapper">
            <span
              className="lights_trigger"
              onClick={(e: { stopPropagation: () => void }) => {
                e.stopPropagation();
                setShowLights((prev: any[]) => {
                  if (Array.isArray(prev)) {
                    return prev.map((elem, id) => {
                      if (id === index) {
                        return true;
                      } else {
                        return elem;
                      }
                    });
                  } else {
                    return [];
                  }
                });
              }}
            >
              <PolygonSVG
                color={
                  elem1?.completion_status === 0
                    ? "#ef3a34"
                    : elem1?.completion_status === 1
                    ? "#fbbf14"
                    : "#2AD400"
                }
              />
            </span>
            {showLights[index] && (
              <>
                <TriangularTrafficLights
                  onChangeTrafficLight={(data: any, e: any) => {
                    e.stopPropagation();
                    const updateTrafficLightPayload = {
                      completion_status:
                        data === "#ef3a34" ? 0 : data === "#fbbf14" ? 1 : 2,
                      custom_form_id: elem1.custom_form_id,
                      document_id: elem1.id,
                      title: elem1.title,
                      user_id: elem1.user_id || clientId,
                      primary_approver_id:
                        elem1?.primary_approver_id?.split(",") || [],
                      ongoing_approver_id:
                        elem1?.ongoing_approver_id?.split(",") || [],
                      note_details: elem1?.note_details || {},
                    };
                    // setTrafficColors(data);
                    setShowLights(false);
                    dispatch(
                      updateDocumentThunk(updateTrafficLightPayload)
                    ).then((res: any) => {
                      dispatch(
                        getDocumentsForClinicalThunk({
                          clientId,
                          providerId,
                        })
                      );
                      dispatch(getDocuments({ clientId, providerId }));
                      dispatch(getUserNotificationStats());

                      if (res?.payload?.status === "success") {
                        toast.success(`Traffic Light updated successfully!`, {
                          // style: {},
                          progressStyle: { backgroundColor: dynamicColor },
                          icon: (
                            <span
                              style={{
                                marginRight: "8px",
                                borderRadius: "50%",
                                width: "7rem",
                                height: "35px",
                                textAlign: "center",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                color: dynamicColor,
                                backgroundColor: "white",
                                fontSize: "1.5rem",
                                border: `3px solid ${dynamicColor}`,
                              }}
                            >
                              ✓
                            </span>
                          ),
                        });
                      } else {
                        toast.error(res?.payload);
                      }
                    });
                  }}
                />
              </>
            )}
          </div>
        );
      case "desc":
        if (cellValue && cellValue.length > 50) {
          return cellValue.substr(0, 30) + "...";
        } else {
          return cellValue;
        }

      case "business":
        return cellValue !== null ? cellValue?.name : "";
      case "first_name":
        return (
          elem1?.first_name +
          " " +
          (elem1?.last_name !== null ? elem1?.last_name : "")
        );
      case "name":
        return elem1?.first_name;
      case "expenseName":
        return elem1?.name;
      case "fundingName":
        return elem1?.name;
      case "role":
        return elem1?.roles[0]?.desc;
      case "prep_time":
        let prepTime: any = cellValue ? parseFloat(cellValue) : "";
        let prepTimeUnit = elem1?.prep_time_unit?.toLowerCase();
        if (unitMapping.hasOwnProperty(prepTimeUnit)) {
          prepTimeUnit =
            prepTime <= 1
              ? unitMapping[prepTimeUnit][0]
              : unitMapping[prepTimeUnit][1];
        }
        return (
          <span>{prepTime !== "" ? `${prepTime} ${prepTimeUnit}` : "-"} </span>
        );
      case "documenttitle":
        return elem1?.title?.length > 18
          ? elem1?.title?.slice(0, 18)?.trim() + "..."
          : elem1?.title;
      case "cooking_time":
        let cookTime: any = cellValue ? parseFloat(cellValue) : "";
        let cookTimeUnit = elem1?.cooking_time_unit?.toLowerCase();
        if (unitMapping?.hasOwnProperty(cookTimeUnit)) {
          cookTimeUnit =
            cookTime <= 1
              ? unitMapping[cookTimeUnit][0]
              : unitMapping[cookTimeUnit][1];
        }
        return (
          <span>{cookTime !== "" ? `${cookTime} ${cookTimeUnit}` : "-"}</span>
        );
      case "tags":
        let tagsList = cellValue?.map((elem: any) => elem?.tag_name);
        let createString = tagsList.join(",") || "No Tags Found";
        return <span>{createString}</span>;
      case "primary_review":
        return (
          <span>
            {/* {dateFormat?.includes("dd-mm-yyyy")
              ? formatPrimaryReview1(cellValue)
              : cellValue} */}
              {
                elem1?.ongoing_review == null ? formatPrimaryReview1(elem1?.primary_review) : formatPrimaryReview1(elem1?.ongoing_review)
              }





             
          </span>
        );
        case "primary_approvers":
          return elem1?.ongoing_review == null ? (
              elem1?.primary_approvers?.map((elem: any, key: number) => (
                  <span key={key}>
                      {elem?.first_name} {elem?.last_name}{" "}
                  </span>
              ))
          ) : (
              elem1?.ongoing_approvers?.map((elem: any, key: number) => (
                  <span key={key}>
                      {elem?.first_name} {elem?.last_name}{" "}
                  </span>
              ))
          );
      
      case "complete":
        return (
          <>
            <GreenTickSvg />
            {required_percentage == 0
              ? (elem1 && elem1?.percentage_completed) || 0
              : (elem1 && elem1?.percentage_completed_required) || 0}
            %
          </>
        );
      case "files":
        return (
          <div className="attachments-wrapper">
            {cellValue
              ?.slice(0, 3)
              ?.map((elem: any, index: number) =>
                elem?.file_name?.includes("pdf") ? (
                  <PdfDocSvg
                    key={index}
                    onClick={(e: any) =>
                      onClickRow(e, elem1?.id, "onAttachment")
                    }
                    onClickDelete={() => handleDeleteFile(elem1?.id)}
                  />
                ) : elem?.file_name?.includes("docx") ? (
                  <DocxSvg
                    key={index}
                    onClick={(e: any) =>
                      onClickRow(e, elem1?.id, "onAttachment")
                    }
                    onClickDelete={() => handleDeleteFile(elem1?.id)}
                  />
                ) : (
                  <AllDocSvg
                    key={index}
                    onClick={(e: any) =>
                      onClickRow(e, elem1?.id, "onAttachment")
                    }
                    onClickDelete={() => handleDeleteFile(elem1?.id)}
                  />
                )
              )}
          </div>
        );
      case "user_name":
        return elem1?.user_contact_name
          ? elem1?.user_contact_name
          : elem1?.entity_contact_name;
      case "category":
        return elem1?.user_contact_category
          ? elem1?.user_contact_category
          : elem1?.entity_contact_category;
      case "number":
        return elem1?.user_contact_number
          ? elem1?.user_contact_number
          : elem1?.entity_contact_number;
      case "providerName":
        return `${elem1?.admin_first_name || ""} ${
          elem1?.admin_last_name || ""
        } `;
      case "checkbox_cf":
        return (
          <input
            style={{
              position: "relative",
              left: "50px",
            }}
            width={32}
            height={32}
            onClick={(e) => e.stopPropagation()}
            onChange={(e) => {
              e.stopPropagation();

              dispatch(
                publishCustomFormThunk({
                  custom_form_id: elem1?.id,
                  save_type: "publish",
                })
              ).then((res) => {
                toast.success('Form is Published!')
              });

              dispatch(
                getFormTypeByFilterThunk({
                  userId: userId,
                  filterColumn: formTypeFilterItems?.filterColumn
                    ? formTypeFilterItems?.filterColumn
                    : "",
                  filterValue: formTypeFilterItems?.filterValue
                    ? formTypeFilterItems?.filterValue
                    : "",
                  searchVal: formTypeFilterItems?.searchVal
                    ? formTypeFilterItems?.searchVal
                    : "",
                  sortColumn: formTypeFilterItems?.sortColumn
                    ? formTypeFilterItems?.sortColumn
                    : "",
                  sortType: formTypeFilterItems?.sortType
                    ? formTypeFilterItems?.sortType
                    : "",
                  formTypePage,
                })
              );
            }}
            type="checkbox"
            checked={elem1?.is_published}
          />
        );

      case "actionAssociation":
        return (
          <>
            <DeleteSvg onClick={() => handleDeleteAssociation(elem1?.id)} />
          </>
        );
      case "actionDocument":
        return (
          <>
            {!elem1?.custom_form_id && (
              <DeleteSvg
                onClick={(e: any) => handleDeleteDocument(e, elem1?.id)}
              />
            )}
          </>
        );
      case "mealAction":
        return (
          <>
            <DeleteSvg onClick={(e: any) => handleDeleteMeal(e, elem1?.id)} />
          </>
        );
      case "actionFinance":
        return (
          <>
            <DeleteSvg onClick={(e: any) => {}} />
          </>
        );
      case "actionStaff":
        const condition = elem1?.username === null;
        return (
          condition && (
            <UpDownSvg
              onClick={(e: any) =>
                handleReinviteStaff(e, elem1?.id, elem1?.tenant_id)
              }
            />
          )
        );

      case "providerActions":
        return;

      case "actionForm":
        return (
          <div className="table_action_data">
            <DeleteSvg
              onClick={(e: any) => {
                handleDeleteForm(e, elem1?.id);
              }}
            />
            <button
              style={{
                width: "7.25px",
              }}
              onClick={(e: any) => handleDuplicateForm(e, elem1)}
            >
              <CopySVG width={16} height={22} />
            </button>
            <button
              style={{
                width: "7.25px",
              }}
              onClick={(e: any) => handleLiftForm(e, elem1)}
            >
              <CopySVG color={"green"} width={16} height={22} />
            </button>
            <button>
              <EditIcon
                position={"relative"}
                boxSize={5}
                onClick={(e) => {
                  e.stopPropagation();
                  handleOpenStickyNoteToUpdateCustomForm(elem1);
                }}
              />
            </button>
          </div>
        );
      case "has_goals":
        return cellValue == 1 ? "Yes" : "No";

      case "date":
        return cellValue ? formatDate(cellValue) : "";
      case "status":
        if (type === "notifications") {
          return (
            <GreenColorSVG
              color={
                cellValue === "Red"
                  ? "#ef3a34"
                  : cellValue === "Yellow"
                  ? "#fbbf14"
                  : "#2AD400"
              }
            />
          );
        }
        const statusInfo = statusMap[cellValue] || statusMap.default;
        return (
          <>
            <span
              style={{
                color: statusInfo.color,
                backgroundColor: statusInfo.backgroundColor,
              }}
              onClick={(e) => handleOpenStatusWidgetClick(e, index, elem1?.id)}
              className="table_status_btn table_status_btn_active"
            >
              {statusInfo.label}
              {OpenStatusWidget &&
                allClientsDataToTrackClickOfStatusClick[index] && (
                  <div ref={statusWidgetRef}>
                    <WidgetItems
                      type={statusType}
                      itemsArr={itemsArr}
                      setAllClientsDataToTrackClickOfStatusClick={
                        setAllClientsDataToTrackClickOfStatusClick
                      }
                      allClientsDataToTrackClickOfStatusClick={
                        allClientsDataToTrackClickOfStatusClick
                      }
                      index={index}
                      hasUserIdFromListingTable={elem1?.id}
                      setOpenStatusWidget={(value: boolean) => {
                        setTimeout(() => {
                          setOpenStatusWidget(value);
                        }, 1000);
                      }}
                    />
                  </div>
                )}
            </span>
          </>
        );
      case "vendorAction":
        return (
          <div className="table_action_data">
            <button>
              <EditIcon
                position={"relative"}
                boxSize={5}
                onClick={(e) => {
                  e.stopPropagation();
                  handleUpdateVendor(elem1);
                }}
              />
            </button>
          </div>
        );
      case "reportAction":
        return (
          <div className="table_action_data">
            <button>
              <EditIcon
                position={"relative"}
                boxSize={5}
                onClick={(e) => {
                  e.stopPropagation();
                  handleUpdateReportsHub(elem1);
                }}
              />
            </button>
          </div>
        );

      default:
        return cellValue;
    }
  };

  useEffect(() => {
    const handleOutsideClick = (event: any) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        // setIsDropdownOpen(false);
      }
    };

    if (isDropdownOpen) {
      document.body.classList.add("container-blur");
      document.addEventListener("click", handleOutsideClick);
    } else {
      document.body.classList.remove("container-blur");
      document.removeEventListener("click", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [isDropdownOpen]);

  return (
    <>
      <table
        className={`common-table-container  ${
          classNameTable ? classNameTable : "common-table-container-all-clients"
        } ${isDropdownOpen ? "blur-element" : ""}`}
        ref={tableRef}
      >
        <thead style={{ backgroundColor: dynamicHeaderColor }}>
          <tr className={classNameTr}>
            {thData?.map((elem: any, index: any) => (
              <th
                style={{
                  backgroundColor: dynamicHeaderColor,
                  width: elem?.header === "check" ? "20px" : "unset",
                }}
                className={`${classNameTh} 
                
                ${elem.last_col_providers ? "provider-table-th" : ""}
                table-head-common-table-all-clients`}
                key={index}
              >
                {elem?.header === "check" ? (
                  <></>
                ) : (
                  //   <input
                  //   type="checkbox"
                  //   onChange={(e) => {
                  //     const allDocsCompleted = allClients.every((doc: {completion_status: number;}) => doc.completion_status === 2);
                  //     if(!allDocsCompleted){
                  //       // updateCompletionOfAllDocs();

                  //     }else{
                  //       toast.warn("All Documents are completed already!");

                  //     }

                  //   }}
                  //   checked={ifAllRowsChecked}
                  // />

                  <span>{elem?.header}</span>
                )}
              </th>
            ))}
            <th
              style={{ backgroundColor: dynamicHeaderColor }}
              ref={svgParentRef}
            >
              <FilterSvg onClick={onClickSvg} fill={fill} />
              {isDropdownOpen && (
                <Modal
                  isOpen={true}
                  onClose={() => setisDropdownOpen(false)}
                  closeOnOverlayClick={true}
                >
                  <ModalOverlay
                    onClick={() => setisDropdownOpen(false)}
                    zIndex={1000}
                    style={{ backdropFilter: "blur(0.25px)" }}
                  />
                  <div
                    ref={dropdownRef}
                    className={`dropdown_filter ${
                      type == "prospects" ||
                      type == "clients" ||
                      type == "business" ||
                      type == "contacts" ||
                      type == "staff" ||
                      type == "teams" ||
                      type == "mealsBuilder" ||
                      type == "locations" ||
                      type == "audit" ||
                      type === "expenseType" ||
                      type === "fundingType" ||
                      type === "billingRule" ||
                      type === "payRule" ||
                      type === "form" ||
                      type === "notifications"
                        ? "dropdown_filter_main_screen"
                        : ""
                    } `}
                  >
                    <TableDropdown2 type={type} />
                  </div>
                </Modal>
              )}
            </th>
          </tr>
        </thead>
        <tbody
      ref={tableBodyRef}
      onScroll={onScroll}
      className={`${classNameBody} document-table-body `}
    >
      { allClients?.length > 0 ? (
        allClients.map((elem1: any, index1: any) => (
          <tr
            key={index1}
            onClick={(e) => {
              (!OpenStatusWidget || type === "providers") &&
              type === "notifications"
                ? onClickRow(e, elem1)
                : onClickRow(e, elem1?.id, "onRow");
              falsifyAllLights();
            }}
            className={`active_class_table_row ${classNameRow} normal-class-table-row-clients-table `}
          >
            {thData?.map((elem2: any, index: any) => (
              <td
                style={{
                  position: "relative",
                }}
                key={index}
                className={`${"table_row "} ${
                  elem2?.accessor === "primary_approvers" ||
                  elem2?.accessor === "tags"
                    ? "table_primary_approver"
                    : ""
                } `}
              >
                {renderCellContent(
                  elem1,
                  elem2,
                  dateFormat,
                  statusMap,
                  index1
                )}
              </td>
            ))}
          </tr>
        ))
      ) : showNoDataMessage ? (
        <h1
          style={{
            display: "flex",
            justifyContent: "center",
            fontSize: "12px",
            marginTop: "215px",
          }}
        >
          No Data Found!
        </h1>
      ) : null}
    </tbody>
      </table>

      {isLoading && (
        <div style={{ position: "absolute", top: "50%", left: "50%" }}>
          <Spinner thickness="2px" size="md" speed="0.65s" />
        </div>
      )}
    </>
  );
};

export default Table;
