import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { api } from "../../../services/api";

const initialState: any = {
  staffName: [],
  staffEmail: [],
  staffPhone: [],
  staffPage: 1,
  staffFilterItems: {
    status: "1,2,7",
    filterColumn: "",
    filterValue: "",
    searchVal: "",
    sortColumn: "",
    sortType: "",
    staffPage: 1,
  },
  staff: [],
  lastPage: 100000,
  getStaffNames: [],
  getStaffData: [],
  getUserTypeIdForStaff: null,
  getUniqueStaff: null,
  updateStaff: null,
  getAllStaffData: [],
  selectedFilterOptions: [],
  selectedSortOptions: [],
  searchValueStaff: "",
  staffRoles: [],
  isStaffFilterDropdownOpen: false,
  dayBookStaff: [],
  staffUserRoles: [],
  loading: false,
};

export const getStaffNamesThunk = createAsyncThunk(
  "staff/fetchStaffNames",
  async (dynamicStaffId: number | any) => {
    try {
      const response = await api.get(
        `/users?unique_column=name&type=${dynamicStaffId}&status=1`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
export const getStaffThunk = createAsyncThunk(
  "staff/fetchStaff",
  async (dynamicId: number | any) => {
    try {
      const response = await api.get(
        `/users?paginate=true&type=${dynamicId.dynamicStaffId}&status=1,2,7&sort_column=id&sort_type=desc&page=1&limit=10000`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getStaffSelectAllThunk = createAsyncThunk(
  "staff/fetchStaffSelectAll",
  async ({ dynamicId, status }: any) => {
    try {
      const response = await api.get(
        `/users?paginate=true&status=${status}&type=${dynamicId}&sort_column=id&sort_type=desc&page=1&limit=200`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getStaffRolesThunk = createAsyncThunk(
  "staff/fetchStaffRoles",
  async (dynamicId: number | any) => {
    try {
      const response = await api.get(
        `/users?&type=${dynamicId.dynamicStaffId}&unique_column=role`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getProviderStaffRolesThunk = createAsyncThunk(
  "providerStaff/fetchStaffRoles",
  async ({ dynamicUserTypeId, dynamicProviderId }: any) => {
    try {
      const response = await api.get(
        `/providers/get_provider_users?unique_column=role&type=${dynamicUserTypeId}&tenant_id=${dynamicProviderId}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getUserRolesStaffThunk = createAsyncThunk(
  "staff/getUserRolesStaffThunk",
  async ({ dynamicStaffId }: any) => {
    try {
      const response = await api.get(
        `/users/get_user_roles?type=${dynamicStaffId}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getStaffByIdThunk = createAsyncThunk(
  "staff/getStaffByIdThunk",
  async ({ typeId, clientId }: any) => {
    try {
      const response = await api.get(
        `/users?paginate=true&type=${typeId}&sort_column=id&sort_type=desc&id=${clientId}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const updateStaffThunk = createAsyncThunk(
  "staff/updateStaff",
  async (staffData: any, { rejectWithValue }) => {
    try {
      const response = await api.post("/users/update_user", staffData);
      return response.data;
    } catch (error: string | any) {
      return rejectWithValue(
        error.message || "Failed to upload client details"
      );
    }
  }
);
export const reinviteStaffThunk = createAsyncThunk(
  "staff/reinviteStaff",
  async (id: any, { rejectWithValue }) => {
    try {
      const response = await api.post("/users/re_invite_user", id);
      return response.data;
    } catch (error: string | any) {
      return rejectWithValue(error.message || "Failed to send invite request");
    }
  }
);
export const getStaffNameThunk = createAsyncThunk(
  "staff/fetchName",
  async (dynamicStaffId: number | any) => {
    try {
      const { data } = await api.get(
        `/users?unique_column=name&type=${dynamicStaffId}`
      );
      return data?.data?.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getProviderStaffNameThunk = createAsyncThunk(
  "providerStaff/fetchName",
  async ({ dynamicUserTypeId, dynamicProviderId }: any) => {
    try {
      const { data } = await api.get(
        `/providers/get_provider_users?unique_column=name&type=${dynamicUserTypeId}&tenant_id=${dynamicProviderId}`
      );
      return data?.data?.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getStaffEmailThunk = createAsyncThunk(
  "staff/fetchEmail",
  async (dynamicStaffId: number | any) => {
    try {
      const { data } = await api.get(
        `/users?unique_column=email&type=${dynamicStaffId}`
      );
      return data?.data?.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getProviderStaffEmailThunk = createAsyncThunk(
  "providerStaff/fetchEmail",
  async ({ dynamicUserTypeId, dynamicProviderId }: any) => {
    try {
      const { data } = await api.get(
        `/providers/get_provider_users?unique_column=email&type=${dynamicUserTypeId}&tenant_id=${dynamicProviderId}`
      );
      return data?.data?.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getStaffPhoneThunk = createAsyncThunk(
  "staff/fetchPhone",
  async (dynamicStaffId: number | any) => {
    try {
      const { data } = await api.get(
        `/users?unique_column=mobile&type=${dynamicStaffId}`
      );
      return data?.data?.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getProviderStaffPhoneThunk = createAsyncThunk(
  "providerStaff/fetchPhone",
  async ({ dynamicUserTypeId, dynamicProviderId }: any) => {
    try {
      const { data } = await api.get(
        `/providers/get_provider_users?unique_column=mobile&type=${dynamicUserTypeId}&tenant_id=${dynamicProviderId}`
      );
      return data?.data?.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getStaffByFilterThunk = createAsyncThunk(
  "contacts/fetchContactsByFilter",
  async ({
    dynamicId,
    filterColumn,
    filterValue,
    searchVal,
    sortColumn,
    sortType,
    status,
    staffPage,
  }: {
    dynamicId: object | any;
    filterColumn: string;
    filterValue: string;
    searchVal: string;
    sortColumn: string;
    sortType: string | any;
    status: string | any;
    staffPage: string | any;
  }) => {
    let dynamicContactId = dynamicId;
    const encodedFilterValue = encodeURIComponent(filterValue);
    const encodedSearchValue = encodeURIComponent(searchVal);

    try {
      const response = await api.get(
        `/users?paginate=true&page=${staffPage}&sort_column=${sortColumn}&sort_type=${sortType}&filter_column=${filterColumn}&type=${dynamicContactId}&status=${status}&limit=50000000&filter_value=${encodedFilterValue}&search=${encodedSearchValue}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getProviderStaffByIdThunk = createAsyncThunk(
  "providerStaff/fetchProviderStaffById",
  async ({ typeId, clientId, tenant_id }: any) => {
    try {
      const response = await api.get(
        `/providers/get_provider_users?paginate=true&type=${typeId}&sort_column=id&sort_type=desc&id=${clientId}&tenant_id=${tenant_id}`
      );
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
);

const staffTypeSlice = createSlice({
  name: "staff",
  initialState,
  reducers: {
    // Pagination Start
    setStaffPage: (state) => {
      state.staffPage += 1;
    },
    resetStaffPage: (state) => {
      state.staffPage = 1;
    },
    resetStaffData: (state) => {
      state.allDataStaff = {};
      state.staff = [];
    },
    setStaffFilterItems: (state, action) => {
      state.staffFilterItems = action.payload;
    },
    resetStaffFilterItems: (state) => {
      state.staffFilterItems = {
        status: "1,2,7",
        filterColumn: "",
        filterValue: "",
        searchVal: "",
        sortColumn: "",
        sortType: "",
        staffPage: 1,
      };
    },
    // Pagination End
    setUserTypeIdForStaff: (state, action) => {
      state.getUserTypeIdForStaff = action.payload;
    },
    setFilterOptions: (state, action) => {
      state.selectedFilterOptions = action.payload;
    },
    resetFilterOptions: (state) => {
      state.selectedFilterOptions = [];
    },
    setSortOptions: (state, action) => {
      state.selectedSortOptions = action.payload;
    },
    resetSortOptions: (state) => {
      state.selectedSortOptions = [];
    },
    setSearchValueStaff: (state, action) => {
      state.searchValueStaff = action.payload;
    },
    resetSearchValueStaff: (state) => {
      state.searchValueStaff = "";
    },
    setisStaffFilterDropdownOpen: (state) => {
      state.isStaffFilterDropdownOpen = true;
    },
    resetisStaffFilterDropdownOpen: (state) => {
      state.isStaffFilterDropdownOpen = false;
    },
    clearCurrentStaffData: (state) => {
      state.getUniqueStaff = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getStaffThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getStaffThunk.fulfilled, (state, action) => {
        // // console.log(action.payload,"allDATAsTAFF")
        state.loading = false;

        state.getStaffData = action.payload;
        state.getAllStaffData = action.payload;
        state.staffDataDayBook = action?.payload?.data?.data;
      })
      .addCase(getStaffThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getStaffRolesThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getStaffRolesThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.staffRoles = action.payload;
      })
      .addCase(getStaffRolesThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(getProviderStaffRolesThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProviderStaffRolesThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.staffRoles = action.payload;
      })
      .addCase(getProviderStaffRolesThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getUserRolesStaffThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUserRolesStaffThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.staffUserRoles = action.payload;
        // state.staffRoles = action.payload;
      })
      .addCase(getUserRolesStaffThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      // getUserRolesStaffThunk
      .addCase(getStaffByFilterThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getStaffByFilterThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.lastPage = action.payload.data.last_page;
        state.staff = action.payload.data.data;
        state.getStaffData = action.payload;
      })
      .addCase(getStaffByFilterThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getStaffSelectAllThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getStaffSelectAllThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.getStaffData = action.payload;
      })
      .addCase(getStaffSelectAllThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getStaffByIdThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getStaffByIdThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.getUniqueStaff = action.payload;
      })
      .addCase(getStaffByIdThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updateStaffThunk.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getStaffNamesThunk.pending, (state) => {
        state.loading === true;
      })
      .addCase(getStaffNamesThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.getStaffNames = action.payload;
      })
      .addCase(getStaffNamesThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });

    builder.addCase(updateStaffThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.updateStaff = action.payload;
    });

    builder
      .addCase(updateStaffThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload
          ? action.payload
          : "Failed to upload client details";
      })
      .addCase(getStaffNameThunk.pending, (state) => {
        state.loading === true;
      })
      .addCase(getStaffNameThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.staffName = action.payload;
      })
      .addCase(getStaffNameThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(getProviderStaffNameThunk.pending, (state) => {
        state.loading === true;
      })
      .addCase(getProviderStaffNameThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.staffName = action.payload;
      })
      .addCase(getProviderStaffNameThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getStaffEmailThunk.pending, (state) => {
        state.loading === true;
      })
      .addCase(getStaffEmailThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.staffEmail = action.payload;
      })
      .addCase(getStaffEmailThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(getProviderStaffEmailThunk.pending, (state) => {
        state.loading === true;
      })
      .addCase(getProviderStaffEmailThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.staffEmail = action.payload;
      })
      .addCase(getProviderStaffEmailThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getStaffPhoneThunk.pending, (state) => {
        state.loading === true;
      })
      .addCase(getStaffPhoneThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.staffPhone = action.payload;
      })
      .addCase(getStaffPhoneThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getProviderStaffPhoneThunk.pending, (state) => {
        state.loading === true;
      })
      .addCase(getProviderStaffPhoneThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.staffPhone = action.payload;
      })
      .addCase(getProviderStaffPhoneThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      //Provider Unique Staff
      .addCase(getProviderStaffByIdThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProviderStaffByIdThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.getUniqueStaff = action.payload;
      })
      .addCase(getProviderStaffByIdThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const {
  setUserTypeIdForStaff,
  setFilterOptions,
  resetFilterOptions,
  setSortOptions,
  resetSortOptions,
  resetSearchValueStaff,
  setSearchValueStaff,
  clearCurrentStaffData, // Page
  setStaffPage,
  resetStaffPage,
  resetStaffData,
  setStaffFilterItems,
  resetStaffFilterItems,
  resetisStaffFilterDropdownOpen,
} = staffTypeSlice.actions;

export default staffTypeSlice.reducer;
