import React from "react";

const UnderscorSVG = () => {
  return (
    <svg
      width="8"
      height="12"
      viewBox="0 0 8 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.64324 10.6827V12H0.0721436V10.6827H7.64324ZM6.02086 6.21381C6.00306 6.64851 5.8971 7.07106 5.71247 7.4437C5.52784 7.81635 5.27028 8.12748 4.96281 8.34929C4.65535 8.57111 4.30755 8.69669 3.9505 8.71482C3.59345 8.73296 3.23827 8.64307 2.91671 8.4532C2.54607 8.25791 2.23175 7.9323 2.014 7.51808C1.79626 7.10386 1.68502 6.61989 1.69452 6.12819V0.806333H0.612936V6.21381C0.631232 6.83386 0.769145 7.43996 1.01551 7.98303C1.26188 8.52611 1.60977 8.9909 2.03104 9.33979C2.45231 9.68868 2.9351 9.91185 3.44035 9.99126C3.94559 10.0707 4.45909 10.0041 4.93928 9.79684C5.57801 9.53754 6.13219 9.03686 6.52122 8.3676C6.91026 7.69834 7.11387 6.89538 7.10245 6.07549V0.806333H6.02086V6.21381ZM6.02086 0.80304H7.10245H6.02086ZM1.69452 0.80304H0.612936H1.69452Z"
        fill="black"
      />
    </svg>
  );
};

export default UnderscorSVG;
