const FilterSvg = ({ onClick, fill }: any) => {
  return (
    <div>
      <svg
        width="10"
        height="14"
        onClick={onClick}
        className="svg-icon-tablehead"
        viewBox="0 0 10 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_92_2215)">
          <path
            d="M9.32712 7.30114L5.11159 13.8495C4.98177 14.0535 4.69987 14.0535 4.57005 13.8495L0.354526 7.30114C0.204302 7.06741 0.360089 6.75049 0.625298 6.75049H9.05449C9.3197 6.75049 9.47548 7.06741 9.32526 7.30114H9.32712Z"
            fill={fill}
          />
          <path
            d="M9.35279 2.80859H0.328265C0.146969 2.80859 0 2.96556 0 3.15919V5.37368C0 5.5673 0.146969 5.72427 0.328265 5.72427H9.35279C9.53408 5.72427 9.68105 5.5673 9.68105 5.37368V3.15919C9.68105 2.96556 9.53408 2.80859 9.35279 2.80859Z"
            fill={fill}
          />
          <path
            d="M9.35279 0H0.328265C0.146969 0 0 0.156966 0 0.350594V1.43407C0 1.6277 0.146969 1.78466 0.328265 1.78466H9.35279C9.53408 1.78466 9.68105 1.6277 9.68105 1.43407V0.350594C9.68105 0.156966 9.53408 0 9.35279 0Z"
            fill={fill}
          />
        </g>
        <defs>
          <clipPath id="clip0_92_2215">
            <rect width="9.68105" height="14" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default FilterSvg;
