import {
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";
import { api } from "../../../services/api";
import {
  createTokenCookies,
  removeTokenCookies,
} from "../../../../Model/utils/tokenCookies";
import {
  removeAuthorizationHeader,
  setAuthorizationHeader,
} from "../../../services/interceptors";
import { AxiosError } from "axios";
import {
  TOKEN_REFRESH_TIME,
} from "../../../../Model/utils/constants";


const initialState: any = {
user: {},
  isAuthenticated:
    localStorage.getItem("isAuthenticated") === "true" ? true : false,
  loading: false,
  error: null,
  loadingUserData: false,
  intervalId: "",
  // setrouteString: "",
  routeString: "",
  unsavedChanges: false,
  // setunsavedChanges: "",
  // setshowUnsavedModal: "",
  showUnsavedModal: false,
  newUserId: -1,
  newUserName: ""
};

const refreshToken = () => {
  api
    .post("refresh_token", null, {})
    .then((response) => {
      const token = response?.data?.data?.token;
      const expires_in = response?.data?.data?.expires_in;
      // localStorage.setItem("token", token);
      createTokenCookies(token);
      setAuthorizationHeader({ request: api.defaults, token });
      // const expirationTimestamp = Date.now() + expires_in * 1000;
      // localStorage.setItem("expires_in", expirationTimestamp.toString());
    })
    .catch((error) => {
      // Handle errors
    });
};

export const refreshTokenThunk = createAsyncThunk(
  "auth/refreshToken",
  async () => {
    try {
      const expiry_from_ls: any = TOKEN_REFRESH_TIME;
      const expiresIn = parseInt(expiry_from_ls) - Date.now();
      const refreshInterval = expiresIn - 10 * 60 * 1000; // Refresh 10 minutes before expiration
      const intervalId = setInterval(refreshToken, refreshInterval);
      return intervalId;
    } catch (error) {
      throw error;
    }
  }
);

export const loginThunk = createAsyncThunk(
  "auth/login",
  async (loginData: any) => {
    try {
      const response = await api.post("/login", loginData);
      return response?.data?.data;
    } catch (error) {
      throw error;
    }
  }
);

export const logoutThunk = createAsyncThunk("auth/logout", async () => {
  try {
    const response = await api.post("/logout");

    if (response) {
      window.location.replace("/login");
    }
    return response.data;
  } catch (error) {
    throw error;
  }
});

export const getUserDataThunk = createAsyncThunk(
  "auth/getUserData",
  async (arg, { getState }) => {
    const state: any = getState();
    if (state.user) {
      try {
        const response = await api.get("/user_profile");

        if (response?.data?.data) {
          return response.data.data;
          // user = { username: username, email: email, first_name: first_name, last_name: last_name, permissions:['*'], roles:['*'] }
        }
      } catch (error) {
        // console.log(error);
      }
    }
  }
);

export const forgotPasswordThunk = createAsyncThunk(
  "auth/forgotPassword",
  async ({ username }: any, { rejectWithValue }) => {
    try {
      const response = await api.post("/send_reset_password_mail", {
        username,
      });
      return response.data;
    } catch (error: string | any) {
      return rejectWithValue(
        error.message || "Failed to upload client details"
      );
    }
  }
);

export const verifyResetPasswordLinkThunk = createAsyncThunk(
  "auth/verifyResetPasswordLink",
  async ({ password_token }: any, { rejectWithValue }) => {
    try {
      const response = await api.post("/verify_reset_password_link", {
        password_token,
      });
      return response.data?.data?.username;
    } catch (error: any) {
      return rejectWithValue(
        error.message || "Failed to upload client details"
      );
    }
  }
);

export const signUpThunk = createAsyncThunk(
  "auth/signUp",
  async ({ id, username, password }: any, { getState, rejectWithValue }) => {
    try {
      const response = await api.post("/sign_up", { id, username, password });

      // toast.success(response.data?.response);
      return response?.data?.response;
      //implement:navigate("/login");
    } catch (error) {
      const err = error as AxiosError;

      return rejectWithValue("Invalid credentials!");
    }
  }
);

export const verifySignUpLinkThunk = createAsyncThunk(
  "auth/verifySignUpLink",
  async ({ signup_token }: any, { getState, rejectWithValue }) => {
    try {
      const response = await api.post("/users/verify_user_invite_link", {
        token: signup_token,
      });
      // toast.success(response.data?.response)
      return response.data?.data;
    } catch (error) {
      return rejectWithValue("Invalid invite!");
    }
  }
);

export const resetPasswordThunk = createAsyncThunk(
  "auth/resetPassword",
  async (
    { username, new_password, confirm_password, password_token }: any,
    { rejectWithValue }
  ) => {
    try {
      const response = await api.post("/reset_password", {
        username,
        new_password,
        confirm_password,
        password_token,
      });
      return response.data;
    } catch (error: string | any) {
      return rejectWithValue(
        error.message || "Failed to upload client details"
      );
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setrouteString: (state, action) => {
      const route = action.payload;
      state.routeString = route;
    },
    setunsavedChanges: (state, action) => {
      state.unsavedChanges = false;
    },
    setshowUnsavedModal: (state, action) => {
      state.showUnsavedModal = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginThunk.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.loadingUserData = true;
      })
      .addCase(loginThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.isAuthenticated = true;
        localStorage.setItem("isAuthenticated", "true");
        const { token, token_type, user_data, expires_in } = action.payload;
        const expirationTimestamp = Date.now() + expires_in * 1000;
        // localStorage.setItem("token", token);
        // localStorage.setItem("expires_in", expirationTimestamp.toString());
        createTokenCookies(token);

        state.user = {
          username: user_data?.username,
          email: user_data?.email,
          first_name: user_data?.first_name,
          last_name: user_data?.last_name,
          permissions: ["*"],
          roles: ["*"],
          id: user_data?.id,
        };

        localStorage.setItem("loggedInUserId", user_data?.id);
        setAuthorizationHeader({ request: api.defaults, token });
        state.loadingUserData = false;
        state.error = null;
      })
      .addCase(loginThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      })
      .addCase(logoutThunk.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(logoutThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.isAuthenticated = false;
        state.loadingUserData = false;
        state.user = {};
        state.error = null;
        removeTokenCookies();
        localStorage.clear();
        state.intervalId = "";
        removeAuthorizationHeader();

        clearInterval(state.intervalId);
      })
      .addCase(logoutThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      })
      .addCase(getUserDataThunk.pending, (state) => {
        state.loading = true;
        state.loadingUserData = true;
        state.error = null;
      })
      .addCase(getUserDataThunk.fulfilled, (state, action) => {
        state.loading = false;
        const { username, permissions, first_name, last_name, roles, email } =
          action.payload;

        state.user = {
          username,
          email,
          first_name,
          last_name,
          permissions: ["*"],
          roles: ["*"],
        };
        state.loadingUserData = false;
        state.user = {};
        state.error = null;
      })
      .addCase(getUserDataThunk.rejected, (state, action) => {
        state.loading = false;
        state.loadingUserData = false;
        state.error = action.error;
      })
      .addCase(refreshTokenThunk.pending, (state) => {
        state.error = null;
      })
      .addCase(refreshTokenThunk.fulfilled, (state, action) => {
        state.loading = false;
        const intervalId = action.payload;
        state.intervalId = intervalId;
        state.error = null;
      })
      .addCase(refreshTokenThunk.rejected, (state, action) => {
        state.isAuthenticated = false;
        state.user = {};
        removeTokenCookies();
      })
      .addCase(verifySignUpLinkThunk.pending, (state) => {
        state.error = null;
      })
      .addCase(verifySignUpLinkThunk.fulfilled, (state, action) => {
        state.newUserId = action.payload.id;
        state.newUserName = action.payload.name;
        state.error = null;
      })
      .addCase(verifySignUpLinkThunk.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(signUpThunk.pending, (state) => {
        state.error = null;
      })
      .addCase(signUpThunk.fulfilled, (state, action) => {
        // removeTokenCookies();
        // localStorage.clear();
        // state.loading = false;
        // state.isAuthenticated = false;
        // state.loadingUserData = false;
        // state.user = {};
        // state.error = null;
        // state.intervalId = "";
      })
      .addCase(signUpThunk.rejected, (state, action) => {
        state.error = action.error;
      });
  },
});

export default authSlice.reducer;
export const { setunsavedChanges, setshowUnsavedModal, setrouteString } =
  authSlice.actions;