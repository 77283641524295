import ClientStatusCircleSvg from "../../../../../Model/Assets/SVG/WidgetVillage/ClientStatusCircleSvg";
import { formatDate } from "../../../../../Model/utils/Helper/formatDate";
import "../../../../pages/Daybook/Daybook.css";
import PlusSVG from "../../../../pages/People/ClientPage/Components/UniqueClientScreen/Components/FinanceTab/Data/PlusSVG";
import "../../Styles/CommonTaskItem.css";
import { useState, useEffect } from "react";
import TrafficLights from "../../../CommonModal/Components/TrafficLights";
import { updateDaybookItem } from "../../../../../Controller/redux/features/DaybookFeature/daybookSlice";
import {
  useAppSelector,
  useAppDispatch,
} from "../../../../../Controller/redux/Hooks";
import { toast } from "react-toastify";
import { getDaybookItems } from "../../../../../Controller/redux/features/DaybookFeature/daybookSlice";
import { getUserNotificationStats } from "../../../../../Controller/redux/features/NotificationsFeature/NotificaionsSlice";
import { CollapsedDaybookItem } from "../../../../pages/Daybook/Components/CollapsedDaybookItem";

const CommonTaskItem = ({
  selectedServiceType,
  localStateTrafficLight,
  color,
  type,
  getDaybookCall,
  type2,
  onChangeTrafficLight,
  currTask,
  selectedDayBookItem,
  setState,
  dayBookList,
  dayBookIndex,
  setdayBookIndex,
  setdayBookTaskIndex,
  dayBookTaskIndex,
  handleAddTask,
  dayBook,
  tagColorYellow,
  isFromNote,
  dayBookTask,
  onClick,
  onClickTask,
  completeColor,
  className,
  uploadedFiles,
  comm_title,
  comm_summary,
  commsCount,
  setTrafficColors,
  setIsModify,
  userId,
  setShowCommsForm,
  isSelected,
  selectedSortDateCustom,
  setSelectedDayBookItem = () => {},
  setTaskIndex = () => {},

  startDate,
  selectedSortTab,
  currentDate,
  chooseDate,
}: // updateDayBookItem,
any) => {
  // // console.log(dayBookTask,"dayBookTask")

  const [showLights, setShowLights] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const prefixDate = (date: number) => {
    if (date < 10) return `0${date}`;
    else return date;
  };
  const updateDayBookItem = async (ind: number, saveType: string) => {
    let requiredParmas = {
      ...dayBookTask,
      tag: [],
      tagged_user: [],
      daybook_id: dayBookTask?.id,
      traffic_light: ind,
      save_type: saveType,
    };
    try {
      const response = await dispatch(updateDaybookItem(requiredParmas));
      if (response?.meta?.requestStatus === "fulfilled") {
        toast.success("Task updated successfully!", {
          style: {},
          progressStyle: { backgroundColor: dynamicColor },
          icon: (
            <span
              className="updateDaybookItem_Toast"
              style={{
                color: dynamicColor,
                border: `3px solid ${dynamicColor}`,
              }}
            >
              ✓
            </span>
          ),
        });
        // setStartTime("");
        // setEndTime("");
        setIsModify(false);
      }
      if (response?.meta?.requestStatus === "rejected") {
        toast.error(response?.payload);
        setIsModify(false);
      }
    } catch (error) {
      setIsModify(false);
      throw error;
    }
    dispatch(getUserNotificationStats());
  };

  useEffect(() => {
    if (selectedDayBookItem) {
      setShowLights(false);
    }
  }, [selectedDayBookItem]);

  let date = new Date(selectedSortDateCustom);
  const dynamicColor = useAppSelector((state) => state?.common?.dynamicColor);

  return (
    <>
      <div className="daybook__content__row-3">
        <div
          className="task-item-wrapper"
          onClick={
            type === "stickyClinical" ||
            type === "Obs" ||
            type === "commsGlobal"
                ? onClick // Directly trigger onClick for specific types
                : () => {
                    // Set dayBookIndex and dayBookTaskIndex states
                    setdayBookIndex(dayBookIndex);
                    setdayBookTaskIndex(dayBookTaskIndex);
        
                    if (!selectedDayBookItem) {
                        setState((prevData: any) => ({
                            ...prevData,
                            selectedDayBookItem: {
                                time: dayBookList[dayBookIndex]?.time,
                                task: dayBookList[dayBookIndex]?.task[dayBookTaskIndex],
                                taskIndex: dayBookTaskIndex,
                            },
                        }));
                    } else if(selectedDayBookItem?.task?.id !== currTask?.id){

                        toast.warn("Can't open another task while one is open.");
                    }
                }
        }
        
        >
          <div className={` ${className} task-item`}>
            {dayBookTaskIndex === 0 && (
              <div
                className="task-item-time"
                style={{ cursor: "default" }}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                {/* <img
                onClick={() => handleAddTask(dayBook)}
                src={PlusIcon}
                className="plus-icon-1"
                alt=""
              /> */}

                <PlusSVG
                  onClick={(e: any) => {
                    e?.stopPropagation();
                    handleAddTask(dayBook);
                  }}
                />
                <span>
                  {type === "clinicalSticky" || type === "Obs"
                    ? dayBookTask.title
                    : dayBook?.time}
                </span>
              </div>
            )}

            <div
              style={{ width: "100%" }}
              onClick={() => {
                onClickTask;
              }}
            >
              <CollapsedDaybookItem
              color={color}
                getDaybookCall={getDaybookCall}
                daybookTask={currTask}
                localStateTrafficLight={localStateTrafficLight}
                onChangeTrafficLight={onChangeTrafficLight}
                isSelected={isSelected}
                selectedServiceType={selectedServiceType}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CommonTaskItem;
