import { createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  openClientFinanceForm: false,
  loading: false,
  error: "",
  fundingType: "NDIS",
  budgetFinanceServicesRecords: {
    NDIS: [
      {
        Type: "",
        Code: "",
        Amount_pweek: "",
        Rate: "",
        Sub_Total: "",
      },
    ],
    HCP: [
      {
        Type: "Personal Care - Weekday",
        Code: "",
        Amount_pweek: "6",
        Rate: "62",
        Sub_Total: "",
      },
      {
        Type: "Personal Care - Weekday Evening",
        Code: "",
        Amount_pweek: "4",
        Rate: "75",
        Sub_Total: "",
      },
      {
        Type: "Social Support - Weekday",
        Code: "",
        Amount_pweek: "6",
        Rate: "60",
        Sub_Total: "",
      },
      {
        Type: "Travel WITH - KMs",
        Code: "",
        Amount_pweek: "100",
        Rate: "1.2",
        Sub_Total: "",
      },
      {
        Type: "Travel TO - Mins",
        Code: "",
        Amount_pweek: "60",
        Rate: "1.2",
        Sub_Total: "",
      },
      {
        Type: "Travel TO - KMs",
        Code: "",
        Amount_pweek: "65",
        Rate: "1.2",
        Sub_Total: "",
      },
    ],
    Private: [
      {
        Type: "",
        Code: "",
        Amount_pweek: "",
        Rate: "",
        Sub_Total: "",
      },
    ],
  },
  budgetFinanceFeesRecords: {
    NDIS: [
      {
        Type: "",
        Code: "",
        Amount_pweek: "",
        Rate: "",
        Sub_Total: "",
      },
    ],
    HCP: [
      {
        Type: "Package Management",
        Code: "",
        Amount_pweek: "7",
        Rate: "12",
        Sub_Total: "",
      },
      {
        Type: "Case Management",
        Code: "",
        Amount_pweek: "7",
        Rate: "11.50",
        Sub_Total: "",
      },
    ],
    Private: [
      {
        Type: "",
        Code: "",
        Amount_pweek: "",
        Rate: "",
        Sub_Total: "",
      },
    ],
  },
  budgetFinanceTotalsRecords: {
    NDIS: [
      {
        Title: "Starting Balance",
        Amount: 0,
      },
      {
        Title: "Predicted Funds",
        Amount: 0,
      },
      {
        Title: "Predicted Expenses",
        Amount: 0,
      },
      {
        Title: "Predicted Closing Balance",
        Amount: 0,
      },
    ],
    HCP: [
      {
        Title: "Starting Balance",
        Amount: 3257,
      },
      {
        Title: "Predicted Funds",
        Amount: 53500,
      },
      {
        Title: "Predicted Expenses",
        Amount: 54000,
      },
      {
        Title: "Predicted Closing Balance",
        Amount: 2757,
      },
    ],
    Private: [
      {
        Title: "Starting Balance",
        Amount: 0,
      },
      {
        Title: "Predicted Funds",
        Amount: 0,
      },
      {
        Title: "Predicted Expenses",
        Amount: 0,
      },
      {
        Title: "Predicted Closing Balance",
        Amount: 0,
      },
    ],
  },
  budgetFinanceExpensesRecords: {
    NDIS: [
      {
        Type: "",
        Code: "",
        Amount_pweek: "",
        Rate: "",
        Sub_Total: "",
      },
    ],
    HCP: [
      {
        Type: "Cab Charges",
        Code: "",
        Amount_pweek: "1",
        Rate: "40",
        Sub_Total: "",
      },
    ],
    Private: [
      {
        Type: "",
        Code: "",
        Amount_pweek: "",
        Rate: "",
        Sub_Total: "",
      },
    ],
  },
  budgetFinanceFundingRecords: {
    NDIS: [
      {
       //Support Purpose, Support Category and Outcome Domain 
        subCategory: "Support Purpose",
        amount: "",
      },
      {
        subCategory: " Support Category",
        amount: "",
      },
      {
        subCategory: "Outcome Domain",
        amount: "",
      },
    ],
    HCP: [
      {
        subCategory: "Personal Care",
        amount: "15000",
      },
      {
        subCategory: "Social Support",
        amount: "10000",
      },
      {
        subCategory: "Transport",
        amount: "4000",
      },
      {
        subCategory: "Home Modifications",
        amount: "5000",
      },
    ],
    Private: [
      {
        subCategory: "",
        amount: "",
      },
    ],
  },
};

const finance = createSlice({
  name: "finance",
  initialState,
  reducers: {
    setOpenFinanceModelForm: (state, action) => {
      state.openClientFinanceForm = action.payload;
    },
    addBudgetFinanceRecords: (state, action) => {
      const { type }: any = action?.payload;
      let duplicateBudgetFinanceRecords =
        state.budgetFinanceServicesRecords[type];
      duplicateBudgetFinanceRecords.push({
        Type: "",
        Code: "",
        Amount_pweek: "",
        Rate: "",
        Sub_Total: "",
      });
      state.budgetFinanceServicesRecords[type] = duplicateBudgetFinanceRecords;
    },
    onChnageFiledValueBudget: (state: any, action: any) => {
      const { type, duplicateAllClientsrecord }: any = action?.payload;
      state.budgetFinanceServicesRecords[type] = duplicateAllClientsrecord;
    },
    addBudgetFinanceFeesRecords: (state, action) => {
      const { type }: any = action?.payload;
      let duplicateBudgetFinanceFeesRecords =
        state.budgetFinanceFeesRecords[type];
      duplicateBudgetFinanceFeesRecords.push({
        Type: "",
        Code: "",
        Amount_pweek: "",
        Rate: "",
        Sub_Total: "",
      });
      state.budgetFinanceFeesRecords[type] = duplicateBudgetFinanceFeesRecords;
    },
    onChnageFiledValueBudgetFees: (state: any, action: any) => {
      const { type, duplicateAllClientsrecord }: any = action?.payload;
      state.budgetFinanceFeesRecords[type] = duplicateAllClientsrecord;
    },
    addBudgetFinanceTotalsRecords: (state, action) => {
      const { type }: any = action?.payload;
      let duplicateBudgetFinanceFeesRecords =
        state.budgetFinanceTotalsRecords[type];
      duplicateBudgetFinanceFeesRecords.push({
        Type: "",
        Code: "",
        Amount_pweek: "",
        Rate: "",
        Sub_Total: "",
      });
      state.budgetFinanceFeesRecords[type] = duplicateBudgetFinanceFeesRecords;
    },
    onChnageFiledValueBudgetTotals: (state: any, action: any) => {
      const { type, duplicateAllClientsrecord }: any = action?.payload;

      state.budgetFinanceTotalsRecords[type] = duplicateAllClientsrecord;
    },
    addBudgetFinanceExpensesRecords: (state, action: any) => {
      const { type }: any = action?.payload;
      let duplicateBudgetFinanceFeesRecords =
        state.budgetFinanceExpensesRecords[type];
      duplicateBudgetFinanceFeesRecords.push({
        Type: "",
        Code: "",
        Amount_pweek: "",
        Rate: "",
        Sub_Total: "",
      });
      state.budgetFinanceExpensesRecords[type] =
        duplicateBudgetFinanceFeesRecords;
    },
    onChnageFiledValueBudgetExpenses: (state: any, action: any) => {
      const { type, duplicateAllClientsrecord }: any = action?.payload;
      state.budgetFinanceExpensesRecords[type] = duplicateAllClientsrecord;
    },
    addBudgetFinanceFundingRecords: (state, action) => {
      const { type }: any = action?.payload;
      let duplicateBudgetFinanceFeesRecords =
        state.budgetFinanceFundingRecords[type];
      duplicateBudgetFinanceFeesRecords.push({
        subCategory: "",
        amount: "",
      });
      state.budgetFinanceFundingRecords[type] =
        duplicateBudgetFinanceFeesRecords;
    },
    onChnageFiledValueBudgetFunding: (state: any, action: any) => {
      const { type, duplicateAllClientsrecord }: any = action?.payload;
      state.budgetFinanceFundingRecords[type] = duplicateAllClientsrecord;
    },
    onChangeFunding: (state, action) => {
      state.fundingType = action?.payload;
    },
  },
});

export const {
  setOpenFinanceModelForm,
  addBudgetFinanceRecords,
  onChnageFiledValueBudget,
  addBudgetFinanceFeesRecords,
  onChnageFiledValueBudgetFees,
  addBudgetFinanceTotalsRecords,
  onChnageFiledValueBudgetTotals,
  addBudgetFinanceExpensesRecords,
  onChnageFiledValueBudgetExpenses,
  addBudgetFinanceFundingRecords,
  onChnageFiledValueBudgetFunding,
  onChangeFunding,
} = finance.actions;
export default finance.reducer;
