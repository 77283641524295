import React from "react";
import {
  getStaffByFilterThunk,
  getStaffEmailThunk,
  getStaffNameThunk,
  getStaffPhoneThunk,
  getStaffRolesThunk,
  getStaffThunk,
  getUserRolesStaffThunk,
  reinviteStaffThunk,
  resetStaffData,
  resetStaffPage,
  setStaffPage,
  setUserTypeIdForStaff,
} from "../../../../Controller/redux/features/StaffFeature/staffSlice";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../Controller/redux/Hooks";
import { Fragment, useEffect, useRef, useState } from "react";
import CircularNoteTrigger from "../../../components/CircularNoteTrigger/CircularNoteTrigger";
import CommonUpperBox from "../../../components/UpperBox/CommonHeader";
import CommonQuickMenu from "../../../components/CommonQuickMenu/CommonQuickMenu";
import { useNavigate } from "react-router-dom";
import Table from "../../../components/CommonTable/Table";
import {
  getStatusDetailsThunk,
  resetStatusDetails,
  setProfileTypeSticky,
} from "../../../../Controller/redux/features/CommonFeature/commonSlice";
import { toast } from "react-toastify";

const StaffDetailsPage = () => {
  const switchRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  //  @ts-ignore
  const dynamicStaffId = parseInt(localStorage.getItem("dynamicStaffId"));
  const dynamicColor = useAppSelector((state) => state?.common?.dynamicColor);

  const { staff, lastPage, staffPage, staffFilterItems, loading } =
    useAppSelector((state) => state?.staff);

  const tableRef = useRef();
  const staffInnerRef = useRef();
  useEffect(() => {
    dispatch(resetStaffData());
    dispatch(resetStaffPage());
  }, [
    staffFilterItems?.searchVal,
    staffFilterItems?.filterValue,
    staffFilterItems?.sortType,
    staffFilterItems?.status,
    dynamicStaffId,
  ]);
  const onScroll = () => {
    if (staffInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = staffInnerRef.current;

      if (
        scrollTop + clientHeight + +5 >= scrollHeight &&
        staffPage < lastPage
      ) {
        dispatch(setStaffPage(staffPage));
      }
    }
  };
  const uniqueStaffData = staff?.filter(
    (item: any, index: number, array: any) => {
      return array?.findIndex((obj: any) => obj.id === item.id) === index;
    }
  );
  useEffect(() => {
    dispatch(getStaffNameThunk(dynamicStaffId));
    dispatch(getStaffEmailThunk(dynamicStaffId));
    dispatch(getStaffPhoneThunk(dynamicStaffId));
    dispatch(getStaffRolesThunk({ dynamicStaffId }));
  }, [dynamicStaffId]);

  useEffect(() => {
    dispatch(
      getStaffByFilterThunk({
        filterColumn: staffFilterItems?.filterColumn
          ? staffFilterItems?.filterColumn
          : "",
        filterValue: staffFilterItems?.filterValue
          ? staffFilterItems?.filterValue
          : "",
        searchVal: staffFilterItems?.searchVal
          ? staffFilterItems?.searchVal
          : "",
        sortColumn: staffFilterItems?.sortColumn
          ? staffFilterItems?.sortColumn
          : "",
        sortType: staffFilterItems?.sortType ? staffFilterItems?.sortType : "",
        staffPage,
        dynamicId: dynamicStaffId,
        status: staffFilterItems.status ? staffFilterItems.status : "",
      })
    );
  }, [
    staffPage,
    staffFilterItems?.searchVal,
    staffFilterItems?.filterValue,
    staffFilterItems?.sortType,
    staffFilterItems?.status,
    dynamicStaffId,
  ]);
  // GOTO DETAILS WHEN PAGE LOADS EVERYTIME
  useEffect(() => {
    localStorage.removeItem("activeTab");
  }, []);

  const getStaffFromThunk = useAppSelector(
    (state) => state?.staff?.getStaffData
  );

  useEffect(() => {
    // dispatch(getStaffThunk({ dynamicStaffId }));
    dispatch(getUserRolesStaffThunk({ dynamicStaffId }));
  }, [dynamicStaffId]);
  const [isChecking, setIsChecking] = useState<boolean>(false);
  const [stageTwoToGoalsModal, setstageTwoToGoalsModal] = useState(false);
  const dropdownRefStaff = useRef<any>();
  const svgParentRefStaff = useRef<any>();
  const [isDropdownOpenStaff, setIsDropdownOpenStaff] = useState(false);
  const { selectedFilterOptions, selectedSortOptions, searchValueStaff } =
    useAppSelector((state) => state?.staff);
  const { filterIconColor } = useAppSelector((state) => state?.common);

  const handleClickRow = (e: any, id: any) => {
    e.stopPropagation();
    if (!isDropdownOpenStaff) {
      navigate(`/people/staff/${window.btoa(id)}`);
    }
  };

  useEffect(() => {
    const handleClickOutsideStaff = (event: any) => {
      if (
        dropdownRefStaff.current &&
        !dropdownRefStaff.current.contains(event.target) &&
        svgParentRefStaff.current &&
        !svgParentRefStaff.current.contains(event.target)
      ) {
        setIsDropdownOpenStaff(false);
      }
    };
    document.addEventListener("click", handleClickOutsideStaff);
    return () => {
      document.removeEventListener("click", handleClickOutsideStaff);
    };
  }, []);

  ///////////////////////////////////////////////////////////////////////////////////////
  const staffStatusDetailsNums = useAppSelector(
    (state) => state.common.domainDetail.staff_status
  );
  const staffStatusDetailsArr = useAppSelector(
    (state) => state.common.statusDetailsData?.data
  );
  // // console.log(clientStatusDetailsArr,"clientStatusDetailsArr")
  useEffect(() => {
    dispatch(resetStatusDetails());
    dispatch(getStatusDetailsThunk(staffStatusDetailsNums));
  }, [staffStatusDetailsNums]);
  ///////////////////////////////////////////////////////////////////////////////////////
  const columns = [
    { header: "Name", accessor: "first_name" },
    { header: "Email", accessor: "email" },
    { header: "Mobile", accessor: "mobile" },
    { header: "Role", accessor: "role" },
    { header: "Status", accessor: "status" },
    { header: "Reinvite", accessor: "actionStaff" },
  ];
  const { domainDetail } = useAppSelector((state) => state?.common);

  let isPending = false;
  const handleReinviteStaff = async (e: any, id: any) => {
    e.stopPropagation();
    if (id !== "") {
      if (isPending) return;
      try {
        isPending = true;
        const res = await dispatch(reinviteStaffThunk({ id: id }));
        // console.log(res);
        if (res.payload.status === "success") {
          isPending = false;
          toast.success("Mail Sent Successfully!", {
            progressStyle: { backgroundColor: dynamicColor },
            icon: (
              <span
                style={{
                  marginRight: "8px",
                  borderRadius: "50%",
                  width: "7rem",
                  height: "35px",
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: dynamicColor,
                  backgroundColor: "white",
                  fontSize: "1.5rem",
                  border: `3px solid ${dynamicColor}`,
                }}
              >
                ✓
              </span>
            ),
          });
        }
      } catch (err: any) {
        toast.error(err.message);
      }
    }
  };

  var businessType = domainDetail?.businessmodel_type;
  const { staff_label } = useAppSelector(
    (state) => state?.common?.domainDetail
  );

  useEffect(() => {
    dispatch(setProfileTypeSticky(staff_label));
  }, [staff_label]);
  const { profile_name } = useAppSelector((state) => state.common.domainDetail);
  return (
    <Fragment>
      <div
        style={{
          position: "relative",
        }}
      >
        {stageTwoToGoalsModal && (
          <CommonQuickMenu
            setshowGoalsModal={(value: any) => {}}
            mainType={"listing"}
            setstageTwoToGoalsModal={setstageTwoToGoalsModal}
          />
        )}
        <CommonUpperBox
          onClickPlus={() => {
            setstageTwoToGoalsModal(true);
          }}
          type={"listing"}
          title={businessType === "B2B" ? "Employees" : staff_label}
        />
      </div>
      {/* <CommonTable type="staff" allClients={getStaffFromThunk?.data?.data} /> */}
      <Table
        handleReinviteStaff={handleReinviteStaff}
        isLoading={loading}
        tableRef={tableRef}
        tableBodyRef={staffInnerRef}
        onScroll={onScroll}
        dynamicHeaderColor={domainDetail?.listingtbl_color}
        statusType="staffScreen"
        thData={columns}
        itemsArr={staffStatusDetailsArr}
        classNameTr="all-staffs-table-head"
        type="staff"
        dropdownRef={dropdownRefStaff}
        isDropdownOpen={isDropdownOpenStaff}
        onClickSvg={() => setIsDropdownOpenStaff(true)}
        svgParentRef={svgParentRefStaff}
        allClients={uniqueStaffData}
        onClickRow={handleClickRow}
        classNameRow="all-staffs-table-body"
        fill={
          selectedFilterOptions.length > 0 ||
          selectedSortOptions.length > 0 ||
          searchValueStaff.length > 0
            ? filterIconColor
            : "#D8D3D3"
        }
      />
      <CircularNoteTrigger
        type={staff_label}
        // title={businessType === "B2B" ? "Employees" : staff_label}
        title={profile_name}
      />
    </Fragment>
  );
};

export default StaffDetailsPage;
