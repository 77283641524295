import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../../services/api";

const initialState: any = {
  getAssociationTypes: [],
  getAssociationNames: [],
  getAssociationRelationships: [],
  addAssociations: [],
  loading: false,
  error: null,
  success: null,
  getAssociationByUserId: [],
  allDataAfterPagination: [],
  hasDeleteAssociation: false,
  warningPopupAssociation: false,
  idOfAssociationToDelete: null,
  getAllAssociations: [],
  getAssociationByFilter: [],
  selectedNames: [],
  selectedItemIndexAssociations: null,
  selectedItemIndexAssociationsSort: null,
  // Filter
  searchValAssociations: "",
  selectedDropdownItemAssociations: "",
  selectedItemIndexFilterAssociations: "",
  selectedItemIndexSortAssociations: "",
  sortByAssociationsR: "",
  selectedFilterItemsAssociations: [],
  parentType: "",
  childType: "",
  getRelationshipsWithTypes: [],
  getReverseRelationshipsWithTypes: [],
};

export const getAssociationTypesThunk = createAsyncThunk(
  "clients/associations/fetchTypes",
  async () => {
    try {
      const response = await api.get("/associations/get_types");
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getRelationshipsWithTypesThunk = createAsyncThunk(
  "clients/associations/getRelationshipsWithTypesThunk",
  async ({ parent, child, relName }: any) => {
    try {
      const response = await api.get(
        `/associations/get_relationships?parent_type_id=${parent}&child_type_id=${child}&relationship_name=`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getReverseRelationshipsWithTypesThunk = createAsyncThunk(
  "clients/associations/getReverseRelationshipsWithTypesThunk",
  async ({ parent, child, relName }: any) => {
    try {
      const response = await api.get(
        `/associations/get_relationships?parent_type_id=${parent}&child_type_id=${child}&relationship_name=${relName}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getAssociationNamesThunk = createAsyncThunk(
  "clients/associations/fetchNames",
  async ({ id, model }: any) => {
    try {
      const response = await api.get(
        `/associations/get_names?type=${model}&id=${id}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const deleteAssociationThunk = createAsyncThunk(
  "clients/associations/deleteAssociation",
  async (id: any, { rejectWithValue }) => {
    try {
      const response = await api.post("/associations/delete_association", {
        id,
      });
      if (response.data.status === "success") {
        return response.data;
      } else {
        return rejectWithValue("Failed to delete association");
      }
    } catch (error) {
      return rejectWithValue(
        "An error occurred while deleting the association"
      );
    }
  }
);

export const getAssociations = createAsyncThunk(
  "clients/associations/fetchAssociations",
  async ({
    clientId,
    searchVal,
    type,
    pageNumber,
  }: {
    clientId: any;
    searchVal: any;
    type: any;
    pageNumber: any;
  }) => {
    try {
      const response = await api.get(
        `/associations?paginate=true&page=1&limit=10000&sort_column=name&user_id=${clientId}&search=${searchVal}&sort_type=${type}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getAssociationsForEntity = createAsyncThunk(
  "clients/associations/fetchAssociations",
  async ({
    clientId,
    searchVal,
    type,
    pageNumber,
  }: {
    clientId: any;
    searchVal: any;
    type: any;
    pageNumber: any;
  }) => {
    try {
      const response = await api.get(
        `/associations?paginate=true&page=1&limit=500&sort_column=name&user_id=${clientId}&search=${searchVal}&sort_type=${type}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
export const getAssociationsEntityByFilter = createAsyncThunk(
  "clients/documents/fetchAssociationEntityByFilter",
  async ({
    clientId,
    filterVal,
    columnType,
    searchVal,
    sortType,
  }: {
    clientId: any;
    filterVal: any;
    columnType: any;
    searchVal: any;
    sortType: any;
  }) => {
    const encodedFilterVal = encodeURIComponent(filterVal);
    try {
      const response = await api.get(
        `/associations?paginate=true&page=1&limit=50&sort_column=${columnType}&sort_type=${sortType}&entity_id=${clientId}&filter_column=${columnType}&filter_value=${encodedFilterVal}&search=${searchVal}`
      );
      return response.data.data.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getAssociationsByFilter = createAsyncThunk(
  "clients/documents/fetchDocumentsByFilter",
  async ({
    clientId,
    filterVal,
    columnType,
    uniqueCol,
    searchVal,
    sortType,
  }: {
    clientId: any;
    filterVal: any;
    columnType: any;
    uniqueCol: any;
    searchVal: any;
    sortType: any;
  }) => {
    const encodedFilterVal = encodeURIComponent(filterVal);
    try {
      const response = await api.get(
        `/associations?paginate=true&page=1&limit=50&sort_column=${columnType}&sort_type=${sortType}&user_id=${clientId}&filter_column=${columnType}&filter_value=${encodedFilterVal}&search=${searchVal}`
      );
      return response.data.data.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getAssociationRelationshipsThunk = createAsyncThunk(
  "clients/associations/fetchRelationships",
  async () => {
    try {
      const response = await api.get(`/associations/get_relationships`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const addAssociationThunk = createAsyncThunk(
  "clients/associations/addAssociation",
  async (formData: any, { rejectWithValue }) => {
    try {
      const response = await api.post(
        "/associations/create_association",
        formData
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response);
    }
  }
);

const associationTypesSlice = createSlice({
  name: "associations",
  initialState,
  reducers: {
    setConfirmDelete: (state, action) => {
      const { flag, id } = action.payload;
      state.hasDeleteAssociation = flag;
      state.idOfAssociationToDelete = id;
    },
    resetConfirmDelete: (state) => {
      state.hasDeleteAssociation = false;
      state.idOfAssociationToDelete = null;
    },
    setWarningPopupAssociation: (state, action) => {
      state.warningPopupAssociation = action.payload;
    },
    setselectedNames: (state, action) => {
      state.selectedNames = action.payload;
    },
    resetselectedNames: (state, action) => {
      state.selectedNames = "";
    },
    setselectedItemIndexAssociations: (state, action) => {
      state.selectedItemIndexAssociations = action.payload;
    },
    resetselectedItemIndexAssociations: (state, action) => {
      state.selectedItemIndexAssociations = "";
    },
    setselectedItemIndexAssociationsSort: (state, action) => {
      state.selectedItemIndexAssociationsSort = action.payload;
    },
    resetselectedItemIndexAssociationsSort: (state, action) => {
      state.selectedItemIndexAssociationsSort = "";
    },
    // Filter
    setinputSearchValAssociations: (state, action) => {
      state.searchValAssociations = action.payload;
    },
    resetinputSearchValAssociations: (state, action) => {
      state.searchValAssociations = "";
    },
    setSelectedDropdownItemAssociations: (state, action) => {
      state.selectedDropdownItemAssociations = action.payload;
    },
    resetSelectedDropdownItemAssociations: (state) => {
      state.selectedDropdownItemAssociations = "";
    },
    setSelectedItemIndexFilterAssociations: (state, action) => {
      state.selectedItemIndexFilterAssociations = action.payload;
    },
    resetSelectedItemIndexFilterAssociations: (state) => {
      state.selectedItemIndexFilterAssociations = "";
    },
    setSelectedItemIndexSortAssociations: (state, action) => {
      state.selectedItemIndexSortAssociations = action.payload;
    },
    resetSelectedItemIndexSortAssociations: (state) => {
      state.selectedItemIndexSortAssociations = "";
    },
    setSortByAssociationsR: (state, action) => {
      state.sortByAssociationsR = action.payload;
    },
    resetSortByAssociationsR: (state) => {
      state.sortByAssociationsR = "";
    },
    setSelectedFilterItemsAssociations: (state, action) => {
      state.selectedFilterItemsAssociations = action.payload;
    },
    resetSelectedFilterItemsAssociations: (state) => {
      state.selectedFilterItemsAssociations = "";
    },
    setAssociationTypes: (state, action) => {
      state.parentType = action.payload.parent;
      state.childType = action.payload.child;
    },
    resetRelationshipReverseData: (state) => {
      state.getReverseRelationshipsWithTypes = null
    },
    resetAssociationTypes: (state, action) => {
      state.parentType = null;
      state.childType = null;
    },
  
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAssociationTypesThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAssociationTypesThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.getAssociationTypes = action.payload;
      })
      .addCase(getAssociationTypesThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getAssociationNamesThunk.pending, (state) => {
        state.loading = true;
        state.getAssociationNames = [];
        state.error = null;
      })
      .addCase(getAssociationNamesThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.getAssociationNames = action.payload;
        state.error = null;
      })
      .addCase(getAssociationNamesThunk.rejected, (state, action) => {
        state.loading = false;
        state.getAssociationNames = [];
        state.error = action.error.message;
      })
      .addCase(getAssociationRelationshipsThunk.pending, (state) => {
        state.loading = true;
        state.getAssociationRelationships = [];
        state.error = null;
      })
      .addCase(getAssociationRelationshipsThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.getAssociationRelationships = action.payload;
        state.error = null;
      })
      .addCase(getAssociationRelationshipsThunk.rejected, (state, action) => {
        state.loading = false;
        state.getAssociationRelationships = [];
        state.error = action.error.message;
      })
      .addCase(addAssociationThunk.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addAssociationThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.addAssociations = action.payload;
        state.error = null;
      })
      .addCase(addAssociationThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getAssociations.fulfilled, (state, action) => {
        const newData = action.payload;
        state.getAssociationByUserId = newData.data.data;
        state.getAllAssociations = newData.data;
      })

      .addCase(getAssociations.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAssociations.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getAssociationsByFilter.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAssociationsByFilter.fulfilled, (state, action) => {
        state.loading = false;
        state.getAssociationByUserId = action.payload;
      })
      .addCase(getAssociationsByFilter.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getAssociationsEntityByFilter.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAssociationsEntityByFilter.fulfilled, (state, action) => {
        state.loading = false;
        state.getAssociationByUserId = action.payload;
      })
      .addCase(getAssociationsEntityByFilter.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getRelationshipsWithTypesThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getRelationshipsWithTypesThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.getRelationshipsWithTypes = action.payload;
      })
      .addCase(getRelationshipsWithTypesThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getReverseRelationshipsWithTypesThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        getReverseRelationshipsWithTypesThunk.fulfilled,
        (state, action) => {
          state.loading = false;
          state.getReverseRelationshipsWithTypes = action.payload;
        }
      )
      .addCase(
        getReverseRelationshipsWithTypesThunk.rejected,
        (state, action) => {
          state.loading = false;
          state.error = action.error.message;
        }
      );
  },
});

export const {
  setConfirmDelete,
  resetConfirmDelete,
  setWarningPopupAssociation,
  setinputSearchValAssociations,
  resetinputSearchValAssociations,
  setselectedNames,
  resetselectedNames,
  setselectedItemIndexAssociations,
  resetselectedItemIndexAssociations,
  setselectedItemIndexAssociationsSort,
  resetselectedItemIndexAssociationsSort,
  setSelectedDropdownItemAssociations, //Filter
  resetSelectedDropdownItemAssociations,
  setSelectedItemIndexFilterAssociations,
  resetSelectedItemIndexFilterAssociations,
  setSelectedItemIndexSortAssociations,
  resetSelectedItemIndexSortAssociations,
  setSortByAssociationsR,
  resetSortByAssociationsR,
  setSelectedFilterItemsAssociations,
  resetSelectedFilterItemsAssociations,
  setAssociationTypes,
  resetAssociationTypes,
  resetRelationshipReverseData
} = associationTypesSlice.actions;
export default associationTypesSlice.reducer;
