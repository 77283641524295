import React from "react";

const SHEETSVG = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2_208)">
        <path
          d="M12 3.7246V11.2029C12 11.6435 11.6228 12 11.1583 12H0.841704C0.377208 12 0 11.6425 0 11.2029V0.79709C0 0.357495 0.377208 0 0.841704 0H8.1105"
          fill="#2AD400"
        />
        <path d="M8.11047 0V3.7246H11.9989L8.11047 0Z" fill="#24A500" />
        <path
          d="M2.97302 5.13173V9.30632H9.02706V5.13173H2.97302ZM8.50749 6.34971H6.25983V5.65134H8.50749V6.34971ZM6.25983 6.86932H8.50749V7.56769H6.25983V6.86932ZM5.74025 7.56769H3.49259V6.86932H5.74025V7.56769ZM5.74025 5.65134V6.34971H3.49259V5.65134H5.74025ZM3.49259 8.0873H5.74025V8.78567H3.49259V8.0873ZM6.25983 8.78567V8.0873H8.50749V8.78567H6.25983Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_2_208">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SHEETSVG;
