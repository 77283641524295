import React, {useEffect} from "react";
import VendorCard from "./VendorCard";
import {useAppDispatch} from "../../../Controller/redux/Hooks";

function Category({
  category,
  vendor_softwares,
}) {

  
  return (
    <div className="category">
      <h2>{category}</h2>
      <div className="vendor_list">
        {vendor_softwares?.length > 0 ? (
          vendor_softwares.map((vendor: any) => (
            <VendorCard
              key={vendor?.id}
              {...vendor}
            />
          ))
        ) : (
          <p>No vendors in this category yet.</p>
        )}
      </div>
    </div>
  );
}

export default Category;
