import React from "react";
import "./Styles/Menu.css";
import { Fragment, useEffect, useRef, useState } from "react";
import { api } from "../../../../Controller/services/api";
import {
  getMealBuilder,
} from "../../../../Controller/redux/features/MealsFeatures/MealFeature/mealbuilderSlice";
import { toast } from "react-toastify";
import ReactDatePicker from "react-datepicker";
import {
  deleteMenuBuilderThunk,
  getMenuBuilderData,
  resetConfirmDeleteMenu,
  setConfirmDeleteMenu,
} from "../../../../Controller/redux/features/MealsFeatures/MenuFeature/menuBuilderSlice";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../Controller/redux/Hooks";
import CircularNoteTrigger from "../../../components/CircularNoteTrigger/CircularNoteTrigger";
import CommonUpperBox from "../../../components/UpperBox/CommonHeader";
import CommonQuickMenu from "../../../components/CommonQuickMenu/CommonQuickMenu";
import DeleteSVG from "../../People/ClientPage/Components/UniqueClientScreen/Components/FinanceTab/Data/DeleteSVG";
import UnsavedChangesPopup from "../../../components/UnsavedChangesPopup/UnsavedChangesPopup";
import PlusSVG from "../../People/ClientPage/Components/UniqueClientScreen/Components/FinanceTab/Data/PlusSVG";
import SortSVG from "../../Daybook/Data/SortSVG";

const MenuBuilder = () => {
  const yesterday = new Date();
  const tomorrow = new Date();
  const dayAfterTomorrow = new Date();

  yesterday.setDate(dayAfterTomorrow.getDate() - 1);
  tomorrow.setDate(dayAfterTomorrow.getDate() + 1);
  dayAfterTomorrow.setDate(dayAfterTomorrow.getDate() + 2);

  const monthNames = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];

  const [selectedSortTab, setSelectedSortTab] = useState<any>({});

  const [selectedSortDate, setSelectedSortDate] = useState(
    new Date(selectedSortTab?.value || new Date())
  );

  const [selectedSortDateCustom, setSelectedSortDateCustom] =
    useState<any>(null);

  const [currentDate, setCurrentDate] = useState(new Date());
  const [datePickeerSelectedDate, setDatePickeerSelectedDate] =
    useState(currentDate);
  const [currentDateIndexIs, setCurrentDateIndexIs] = useState<any>(null);

  const [daysArray, setDaysArray] = useState<any[]>([]);

  const [inputValue, setInputValue] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const MealBuilderReduxInfo: any = useAppSelector(
    (state) => state?.mealBuilder.MealsBuilderData?.data?.data
  );

  const [MealRecords, setMealRecords] = useState<any[]>([]);
  const [filteredMealRecords, setFilteredMealRecords] = useState<any>([]);
  useEffect(() => {
    if (MealBuilderReduxInfo) {
      const filteredTags = MealBuilderReduxInfo.filter((mealInfo: any) =>
        mealInfo?.meal_name.toLowerCase()?.includes(inputValue.toLowerCase())
      );
      setFilteredMealRecords(filteredTags);
    }
  }, [inputValue, MealBuilderReduxInfo]);

  const [UseEffectCalling, setUseEffectCalling] = useState(0);
  // // console.log(MealRecords, "MealRecords");

  useEffect(() => {
    if (currentDate) {
      function getWeekDataForDateSecons(date: Date) {
        // // console.log("inside second====================>");

        const todayDate = new Date(date);
        const currentDay = todayDate.getDay();
        const dayOrder = [1, 2, 3, 4, 5, 6, 7, 8]; // Mon, Tue, Wed, Thu, Fri, Sat, Sun, Choose Date
        let daysSequenceArr = [
          "Mon",
          "Tue",
          "Wed",
          "Thu",
          "Fri",
          "Sat",
          "Sun",
          "Choose Date",
        ];
        const monday = new Date(todayDate);
        monday.setDate(
          todayDate.getDate() -
            currentDay +
            (currentDay === 1 ? 1 : currentDay === 0 ? -6 : 1)
        );

        const weekData = [];

        for (let i = 0; i < 8; i++) {
          const day = new Date(monday);
          day.setDate(monday.getDate() + dayOrder[i]);

          const options = { weekday: "short" };
          const dayObject = {
            id: i + 1,
            title: daysSequenceArr[i], // Set the locale to "en-US"
            value: day.toISOString().slice(0, 10),
          };

          weekData.push(dayObject);
        }
        return weekData;
      }

      function getWeekDataForDate(date: Date) {
        const currentDate = new Date(date);
        const currentDayOfWeek = currentDate.getDay();
        const daysInWeek = 7;
        const days = [];

        const monday = new Date(currentDate);
        monday.setDate(currentDate.getDate() - currentDayOfWeek + 1);

        if (currentDayOfWeek === 0) {
          monday.setDate(monday.getDate() - daysInWeek);
        }

        for (let i = 0; i <= daysInWeek; i++) {
          const day = new Date(monday);
          day.setDate(monday.getDate() + i);
          days.push({
            id: i + 1,
            title:
              i == 7
                ? `${date?.getDate()} ${
                    monthNames[date?.getMonth()]
                  } ${date?.getFullYear()} `
                : day?.toLocaleDateString(undefined, { weekday: "short" }),
            value: day?.toISOString().slice(0, 10),
          });
        }

        return days;
      }
      // setTimeout(() => {
      var weeksMewData: any;
      // if (UseEffectCalling == 0) {
      weeksMewData = getWeekDataForDate(currentDate);
      // } else {
      //   weeksMewData = getWeekDataForDateSecons(currentDate);
      // }
      setUseEffectCalling(UseEffectCalling + 1);
      // // console.log(
      //   "weeksMewData===================================>",
      //   weeksMewData
      // );

      setDaysArray(weeksMewData);
      let todayDate = currentDate;
      let indexIS =
        todayDate.getDay() == 0
          ? 6
          : todayDate.getDay() == 1
          ? 0
          : todayDate.getDay() - 1;
      // if (selectedSortDateCustom == null) {
      setSelectedSortTab(weeksMewData[indexIS]);
      setCurrentDateIndexIs(indexIS);
      // }
      // }, 1000);
    }
  }, [currentDate]);

  useEffect(() => {
    if (selectedSortTab) {
      let currentDateTimeStamp;
      // let todayTimeStamp = today.getTime();
      if (selectedSortTab.id == 8) {
        currentDateTimeStamp = selectedSortDateCustom?.getTime();
      } else {
        currentDateTimeStamp = new Date(selectedSortTab?.value).getTime();
      }
      setSelectedSortDate(new Date(selectedSortTab.value));
    }
  }, [selectedSortTab]);

  const MenuBuilderRedux = useAppSelector(
    (state) => state?.menuBuilder.MenuBuilderData
  );

  // // console.log(MenuBuilderRedux, "MenuBuilderRedux");

  const dynamicColor = useAppSelector((state) => state?.common?.dynamicColor);

  // FETCH All Menu Records  //
  const dispatch = useAppDispatch();

  //  Fetch a Menu Records
  useEffect(() => {
    if (typeof currentDateIndexIs == "number") {
      // setTimeout(() => {
      dispatch(
        getMenuBuilderData({
          sortType: "desc",
          date:
            selectedSortDateCustom == null
              ? daysArray[currentDateIndexIs].value
              : `${selectedSortDateCustom.getFullYear()}-${
                  selectedSortDateCustom.getMonth() + 1
                }-${selectedSortDateCustom.getDate()}`,
        })
      );
      // }, 1000);
    }
    return () => {};
  }, [currentDateIndexIs, selectedSortDateCustom]);
  const isDeleteMenu = useAppSelector(
    (state) => state?.menuBuilder?.hasDeleteMenu
  );
  const MenuId = useAppSelector(
    (state) => state?.menuBuilder?.idOfMenuToDelete
  );
  const handleDeleteMenu = async () => {
    let dateIS =
      selectedSortDateCustom == null
        ? daysArray[currentDateIndexIs].value
        : `${selectedSortDateCustom.getFullYear()}-${
            selectedSortDateCustom.getMonth() + 1
          }-${selectedSortDateCustom.getDate()}`;
    setSelectedMenuItem(null);
    try {
      const response = await dispatch(deleteMenuBuilderThunk(MenuId));
      if (response?.meta?.requestStatus === "fulfilled") {
        toast.success("Menu Deleted Successfully", {
          style: {},
          progressStyle: { backgroundColor: dynamicColor },
          icon: (
            <span
              style={{
                marginRight: "8px",
                borderRadius: "50%",
                width: "7rem",
                height: "35px",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: dynamicColor,
                backgroundColor: "white",
                fontSize: "1.5rem",
                border: `3px solid ${dynamicColor}`,
              }}
            >
              ✓
            </span>
          ),
        });
      }
    } catch (error) {
      throw error;
    }
    setSelectedMenuItem(null);
    dispatch(getMenuBuilderData({ sortType: "desc", date: dateIS }));
    dispatch(resetConfirmDeleteMenu());
    // setSelectedDayBookItem(null);
  };

  const { domainDetail } = useAppSelector((state) => state?.common);

  const [duplicateData, setDuplicateData] = useState<any>({});

  const [menuItemsList, setMenuItemsList] = useState<any>([]);

  const [sortType, setSortType] = useState("asc");

  const handleSortMenus = () => {
    // alert('helloooo')
    const newSortType = sortType === "asc" ? "desc" : "asc";
    setSortType(newSortType);
    dispatch(
      getMenuBuilderData({
        sortType: newSortType,

        date:
          selectedSortDateCustom == null
            ? daysArray[currentDateIndexIs].value
            : `${selectedSortDateCustom.getFullYear()}-${
                selectedSortDateCustom.getMonth() + 1
              }-${selectedSortDateCustom.getDate()}`,
      })
    );
  };

  useEffect(() => {
    if (MenuBuilderRedux) {
      const menuTypes = [
        "All Meals",
        "Breakfast",
        "MorningTea",
        "Lunch",
        "AfternoonTea",
        "Dinner",
        "Supper",
      ];
      ``;
      const arrayNew = menuTypes.map((key) => {
        const dessertMeals: any[] = [];
        const updatedList = (MenuBuilderRedux[key] || []).filter(
          (meal: any) => {
            if (meal.tags.some((tag: any) => tag.tag_name === "Dessert")) {
              dessertMeals.push(meal);
              return false;
            }
            return true;
          }
        );

        return {
          name: key,
          title:
            key === "All Meals"
              ? "All Meals"
              : key === "MorningTea"
              ? "Morning Tea"
              : key === "AfternoonTea"
              ? "Afternoon Tea"
              : key,
          list: updatedList.concat(dessertMeals),
        };
      });

      setMenuItemsList(arrayNew);
      setDuplicateData(MenuBuilderRedux);
    }
  }, [MenuBuilderRedux]);

  // const onDateDateOnSelect = (date: string) => {
  //   dispatch(getMenuBuilderData({ date }));
  // };

  const selectedMenuItemRef = useRef();
  const deletePopupRef = useRef<any>();
  const deleteMenuRef = useRef<any>();

  const [selectedMenuItem, setSelectedMenuItem] = useState<{
    menu_type?: string;
    itemIndex?: number;
    meal_id?: number;
    item?: any;
  } | null>(null);

  const [isLoading, setisLoading] = useState(false);

  const addMeanuItem = async (loadAll = true) => {
    setisLoading(true);
    let selectedmenuItemInfo = selectedMenuItem;
    let dateIS =
      selectedSortDateCustom == null
        ? daysArray[currentDateIndexIs].value
        : `${selectedSortDateCustom.getFullYear()}-${
            selectedSortDateCustom.getMonth() + 1
          }-${selectedSortDateCustom.getDate()}`;

    let requiredParams = {
      meal_id: selectedMenuItem?.meal_id,
      menu_date: dateIS,
      menu_type: selectedMenuItem?.menu_type,
    };
    loadAll &&
      setTimeout(() => {
        setSelectedMenuItem(null);
      }, 1000);

    try {
      const response = await api.post(`/menus/create_menu`, requiredParams);
      if (response.data.status === "success") {
        setisLoading(false);
        toast.success("Menu Created Successfully!", {
          style: {},
          progressStyle: { backgroundColor: dynamicColor },
          icon: (
            <span
              style={{
                marginRight: "8px",
                borderRadius: "50%",
                width: "7rem",
                height: "35px",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: dynamicColor,
                backgroundColor: "white",
                fontSize: "1.5rem",
                border: `3px solid ${dynamicColor}`,
              }}
            >
              ✓
            </span>
          ),
        });
        loadAll &&
          dispatch(getMenuBuilderData({ sortType: "desc", date: dateIS }));
      }
      loadAll &&
        setTimeout(() => {
          setSelectedMenuItem(null);
        }, 1000);
      setInputValue("");
    } catch (error: any) {
      if (error?.response?.data?.response == "Meal exists") {
        toast.error("Menu Is Already Existing!");
        setisLoading(false);
      }
      dispatch(getMenuBuilderData({ sortType: "desc", date: dateIS }));
      throw error;
    }
  };

  const updateMeanuItem = async (loadAll = true) => {
    let dateIS =
      selectedSortDateCustom == null
        ? daysArray[currentDateIndexIs].value
        : `${selectedSortDateCustom.getFullYear()}-${
            selectedSortDateCustom.getMonth() + 1
          }-${selectedSortDateCustom.getDate()}`;

    let requiredParams = {
      meal_id: selectedMenuItem?.meal_id,
      menu_date: dateIS,
      menu_type: selectedMenuItem?.menu_type,
      menu_id: selectedMenuItem?.item?.id,
    };
    loadAll &&
      setTimeout(() => {
        setSelectedMenuItem(null);
      }, 1000);

    try {
      const response = await api.post(`/menus/update_menu`, requiredParams);

      if (response.data.status === "success") {
        toast.success("Menu updated successfully!", {
          style: {},
          progressStyle: { backgroundColor: dynamicColor },
          icon: (
            <span
              style={{
                marginRight: "8px",
                borderRadius: "50%",
                width: "7rem",
                height: "35px",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: dynamicColor,
                backgroundColor: "white",
                fontSize: "1.5rem",
                border: `3px solid ${dynamicColor}`,
              }}
            >
              ✓
            </span>
          ),
        });
        loadAll &&
          dispatch(getMenuBuilderData({ sortType: "desc", date: dateIS }));
      }
    } catch (error) {
      throw error;
    }
    loadAll &&
      setTimeout(() => {
        setSelectedMenuItem(null);
      }, 1000);
    setInputValue("");
  };

  useEffect(() => {
    function handleClickOutside(event: any) {
      const isOutsideClick =
        selectedMenuItemRef.current &&
        //   @ts-ignore
        !selectedMenuItemRef.current.contains(event.target);

      if (isOutsideClick) {
        const closestClass = (className: string) =>
          event.target.closest(`.${className}`);

        let isClickableAreaDelete = !closestClass("delete_menu_icon");
        if (!isClickableAreaDelete) {
          dispatch(
            setConfirmDeleteMenu({
              flag: true,
              id: selectedMenuItem?.item?.id,
            })
          );
          return;
        }
        if (selectedMenuItem?.item?.id) {
          updateMeanuItem();
        } else {
          if (!isLoading) {
            addMeanuItem();
          }
        }
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [selectedMenuItemRef, selectedMenuItem, selectedMenuItem?.item]);

  // useOutsideAlerter();

  const handleAddMenu = (itemInfo: any) => {
    const tempMenuItemsList = JSON.parse(JSON.stringify(menuItemsList));
    let itemIndex = -1;
    menuItemsList.find((item: any, index: any) => {
      if (item.name === itemInfo.name) {
        itemIndex = index;
      }
    });
    if (selectedMenuItem?.meal_id) {
      if (selectedMenuItem?.item?.id) {
        updateMeanuItem(false);
      } else {
        addMeanuItem(false);
      }
    }

    if (tempMenuItemsList[itemIndex].list.length) {
      tempMenuItemsList[itemIndex].list.unshift({
        isAdding: true,
        meal_name: "",
        notes: null,
        meal_id: 0,
        tags: [],
      });
    } else {
      tempMenuItemsList[itemIndex].list = [
        {
          isAdding: true,
          meal_name: "",
          meal_id: 0,
          notes: null,
          tags: [],
        },
      ];
    }

    setMenuItemsList(tempMenuItemsList);
    setTimeout(() => {
      setSelectedMenuItem({
        menu_type: itemInfo.name,
        itemIndex: 0,
        item: null,
      });
      setInputValue("");
    }, 500);
  };

  useEffect(() => {
    dispatch(getMealBuilder());
  }, []);

  useEffect(() => {
    if (MealBuilderReduxInfo) {
      setMealRecords(MealBuilderReduxInfo);
    }
  }, [MealBuilderReduxInfo]);

  const onSelectMeals = (mealInfo: any, index: number, itemInd: number) => {
    let tempMenuItemsList = JSON.parse(JSON.stringify(menuItemsList));
    let itemIndex = -1;
    menuItemsList.find((item: any, index: any) => {
      if (item.name === selectedMenuItem?.menu_type) {
        itemIndex = index;
      }
    });

    // let index =
    if (itemIndex !== -1) {
      let replicateSelectedMenu = { ...tempMenuItemsList[itemIndex] };

      // // console.log(replicateSelectedMenu.list, mealInfo, index);

      // Ensure replicateSelectedMenu.list exists and create it if it doesn't
      if (!replicateSelectedMenu.list) {
        replicateSelectedMenu.list = [];
      }

      // Ensure replicateSelectedMenu.list[index] exists and create it if it doesn't
      if (!replicateSelectedMenu.list[itemInd]) {
        replicateSelectedMenu.list[itemInd] = {};
      }

      replicateSelectedMenu.list[itemInd]["meal_id"] = mealInfo?.id;
      replicateSelectedMenu.list[itemInd].meal_name = mealInfo?.meal_name;
      replicateSelectedMenu.list[itemInd].tags = mealInfo?.tags;
      replicateSelectedMenu.list[itemInd].notes = mealInfo?.notes;
      tempMenuItemsList[itemIndex] = replicateSelectedMenu;

      setMenuItemsList(tempMenuItemsList);
    }
  };
  const [user, setUser] = useState<any>(null);

  const profilePictureRefetched = useAppSelector(
    (state) => state?.common?.user
  );
  let profilePictureCurrentUser = profilePictureRefetched;

  useEffect(() => {
    if (profilePictureCurrentUser) {
      setUser(profilePictureCurrentUser);
    }
  }, [profilePictureCurrentUser]);

  // useEffect(() => {
  //   dispatch(getProfileDetailsThunk());
  // }, []);
  const [stageTwoToGoalsModal, setstageTwoToGoalsModal] = useState(false);

  return (
    <Fragment>
      <CommonUpperBox
        title="Menu Builder"
        type={"screen"}
        isMealBuilder={"MB"}
        onClickPlus={() => {
          setstageTwoToGoalsModal(true);
        }}
      />
      <div
        className="menu_assignment_wrapper"
        style={{
          height: 24,
          marginBottom: 15,
        }}
      >
        <div className="menu_sidebar_row_1"></div>
        <div className="menu_content_row_1">
          <h1>Select Date:</h1>
          {daysArray.map(({ title, id, value }: any, index) => {
            return (
              <div
                key={index}
                style={{
                  width: id === 8 ? 88 : "fit-content",
                  textAlign: "center",
                  padding: "0 20px",
                }}
                className={`relative sort__tab menu-builder  ${
                  (selectedSortTab?.id === id && "sort__tab__selected") || ""
                }`}
                onClick={(e) => {
                  if (id !== 8) {
                    setSelectedSortTab({ title, id, value });
                    setCurrentDateIndexIs(index);
                    setSelectedSortDateCustom(null);
                    setDatePickeerSelectedDate(new Date(value));
                  }
                }}
              >
                {id === 8
                  ? `${selectedSortDate?.getDate()} ${
                      monthNames[selectedSortDate?.getMonth()]
                    } ${selectedSortDate?.getFullYear()} `
                  : title}
                {id === 8 && (
                  <div className="date-picker-wrapper">
                    <ReactDatePicker
                      showYearDropdown
                      showMonthDropdown
                      selected={datePickeerSelectedDate}
                      value={selectedSortDateCustom}
                      onChange={(date: Date) => {
                        setSelectedSortTab({ title, id, value });
                        let dateIS = new Date(date);
                        setDatePickeerSelectedDate(dateIS);
                        setSelectedSortDateCustom(dateIS);
                        setCurrentDate(dateIS);
                      }}
                    />
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
      <div className="menu_assignment_wrapper">
        <div className="menu_sidebar_row_2">
          <span>Sort:</span>
          {/* <img src={SortIcon} className="svg-icon-tablehead" alt="" /> */}
          <SortSVG
            fill="#06a5ff"
            onClick={handleSortMenus}
            Styles={{
              transform: sortType == "asc" ? "none" : "rotateX(180deg)",
            }}
          />
        </div>
        <div className="menu_delete_box">
          {selectedMenuItem?.item?.id && (
            <div ref={deleteMenuRef}>
              <DeleteSVG
                style={{ cursor: "pointer" }}
                className="delete_menu_icon"
              />
            </div>
          )}
        </div>
      </div>
      <div
        style={{
          marginTop: "12px",
        }}
      >
        {menuItemsList?.map((res: any, index: number) => {
          let createString = `has_${res.name.replace(/ /g, "")}`.toLowerCase();
          let checkIsEnable = domainDetail[createString];
          if (checkIsEnable === "1") {
            return (
              <div key={index} className="menu_assignment_wrapper">
                <div className="main_section_wrapper">
                  <PlusSVG
                    onClick={() => {
                      // if (!selectedMenuItem) {
                      handleAddMenu(res);
                      // }
                    }}
                  />
                  <span>{res?.title}</span>
                </div>
                <div className="main_item_wrapper">
                  {res.list.map((item: any, itemIndex: number) => {
                    let checkExistDessert = item?.tags?.some(
                      (tagInfo: any) => tagInfo.tag_name === "Dessert"
                    );
                    return selectedMenuItem?.itemIndex == itemIndex &&
                      selectedMenuItem?.menu_type == res.name ? (
                      <div
                        className=" menu__wrapper-fixed-height"
                        key={itemIndex}
                        // @ts-ignore
                        ref={selectedMenuItemRef}
                      >
                        <div className="daybook__content__row-3">
                          <div
                            style={{
                              flexDirection: "column",
                            }}
                            className="task-item-wrapper-popup "
                          >
                            <div className={`task-item`}>
                              <div className="left-three-dots-wrapper">
                                {[...Array(3)].map((_, index) => (
                                  <div className="left-dots" key={index} />
                                ))}
                              </div>
                              <div
                                className="description-wrapper"
                                style={{
                                  marginLeft: "30px",
                                }}
                              >
                                <input
                                  className="description-heading-input"
                                  style={{
                                    borderRadius: "10px",
                                  }}
                                  placeholder="Search meal....."
                                  type="text"
                                  value={inputValue}
                                  onChange={({ target }) => {
                                    setInputValue(target?.value);
                                  }}
                                  onFocus={() => setDropdownOpen(true)}
                                  onBlur={() => {
                                    setTimeout(() => {
                                      setDropdownOpen(false);
                                    }, 500);
                                  }}
                                />
                                {dropdownOpen &&
                                  filteredMealRecords?.length > 0 && (
                                    <div className="menu_select_dropdown">
                                      {filteredMealRecords
                                        .sort((a: any, b: any) =>
                                          a.meal_name.localeCompare(b.meal_name)
                                        )
                                        .map(
                                          (
                                            mealInfo: any,
                                            mealIndex: number
                                          ) => {
                                            return (
                                              <div
                                                key={mealIndex}
                                                className="menu_select_dropdown_option"
                                                onClick={() => {
                                                  setSelectedMenuItem({
                                                    ...selectedMenuItem,
                                                    meal_id: mealInfo?.id,
                                                  });
                                                  setInputValue(
                                                    mealInfo?.meal_name
                                                  );
                                                  onSelectMeals(
                                                    mealInfo,
                                                    index,
                                                    itemIndex
                                                  );
                                                }}
                                              >
                                                {mealInfo?.meal_name}
                                              </div>
                                            );
                                          }
                                        )}
                                    </div>
                                  )}
                                <p
                                  style={{
                                    fontSize: "12px",
                                    marginBottom: "15px",
                                  }}
                                >
                                  Include Summary Text with extra detail to see
                                  how word count will adjust...
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div
                        className=" menu__wrapper-fixed-height"
                        key={itemIndex}
                        onClick={() => {
                          if (!selectedMenuItem) {
                            setSelectedMenuItem({
                              itemIndex: itemIndex,
                              menu_type: res.name,
                              item: item,
                            });
                            setInputValue(item.meal_name || "");
                          }
                        }}
                      >
                        <div className="daybook__content__row-3">
                          <div
                            style={{
                              flexDirection: "column",
                            }}
                            className="task-item-wrapper-popup "
                          >
                            <div
                              className={`task-item green ${
                                checkExistDessert ? "pink" : "green"
                              }`}
                            >
                              <div className="left-three-dots-wrapper ">
                                {[...Array(3)].map((_, mapIndex) => (
                                  <div className="left-dots" key={mapIndex} />
                                ))}
                              </div>
                              <div
                                className="description-wrapper"
                                style={{
                                  marginLeft: "30px",
                                }}
                              >
                                <h1> {item.meal_name} </h1>
                                <div
                                  className="description-sub-heading-input-wrapper"
                                  style={{
                                    marginTop: "5px",
                                    display: "flex",
                                    gap: "10px",
                                    flexDirection: "row",
                                  }}
                                >
                                  {item?.tags.map(
                                    (tagInfo: any, tagIndex: number) => {
                                      return (
                                        <button
                                          key={tagIndex}
                                          style={{
                                            width: "auto",
                                          }}
                                          className={`menu-tag-label`}
                                        >
                                          <p className="menu-tag-label-value">
                                            {tagInfo?.tag_name}
                                          </p>
                                        </button>
                                      );
                                    }
                                  )}
                                </div>
                                <p
                                  style={{
                                    fontSize: "12px",
                                    marginTop: "5px",
                                  }}
                                >
                                  {item.notes}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          }
        })}
      </div>
      {/* <Rectangle type="mealbuilder" /> */}
      <CircularNoteTrigger type="stickyHub" title="Comms" 
      
        
      isOutside
          extraType="globalCommsBox"/>
      <div ref={deletePopupRef}>
        {isDeleteMenu && (
          <UnsavedChangesPopup
            type="menuBuilder"
            onConfirmClick={handleDeleteMenu}
          />
        )}
      </div>
      {stageTwoToGoalsModal && (
        <CommonQuickMenu
          setshowGoalsModal={(value: any) => {}}
          mainType={"listing"}
          setstageTwoToGoalsModal={setstageTwoToGoalsModal}
        />
      )}
    </Fragment>
  );
};

export default MenuBuilder;
