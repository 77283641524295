


const SortDropdown = ({ tableHeadArr, selectedSortIndex, onClick }: any) => {
  return (
    <>
      <div className="dropdown-2-box-sub">
        <div className="sub-dropdown-2">
          {tableHeadArr?.map((elem: any, index: number) => (
            <div
              key={index}
              className={`dropdown-2-item ${
                selectedSortIndex === index ? "dropdown-2-item-selected" : ""
              }`}
              onClick={() => onClick(index, "")}
            >
              <p>{elem}</p>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default SortDropdown;
