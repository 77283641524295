const CrossSvg = ({ className, width, height }: any) => {
  return (
    <div className={className}>
      <svg
        width={width}
        height={height}
        viewBox={`0 0 16 15`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="16" height="15" fill="#1E1E1E" />
        <g id="Group 36496">
          <g id="Group 36346" filter="url(#filter0_d_0_1)">
            <g id="Group 36282">
              <g id="label">
                <rect
                  x="-8"
                  y="107"
                  width="116"
                  height="32"
                  rx="12"
                  transform="rotate(-90 -8 107)"
                  fill="#ffffff"
                />
              </g>
            </g>
          </g>
          <g id="Status Circle">
            <path
              d="M20 7C20 0.372586 14.6274 -5 8 -5C1.37258 -5 -4 0.372585 -4 7C-4 13.6274 1.37258 19 8 19C14.6274 19 20 13.6274 20 7Z"
              fill="#ffffff"
            />
            <path
              d="M20 7C20 0.372586 14.6274 -5 8 -5C1.37258 -5 -4 0.372585 -4 7C-4 13.6274 1.37258 19 8 19C14.6274 19 20 13.6274 20 7Z"
              stroke="black"
            />
          </g>
          <g id="Group 36345">
            <g id="Status Circle_2">
              <path
                d="M18 7C18 1.47715 13.5229 -3 8 -3C2.47715 -3 -2 1.47715 -2 7C-2 12.5228 2.47715 17 8 17C13.5229 17 18 12.5228 18 7Z"
                fill="#ffffff"
              />
              <path
                d="M18 7C18 1.47715 13.5229 -3 8 -3C2.47715 -3 -2 1.47715 -2 7C-2 12.5228 2.47715 17 8 17C13.5229 17 18 12.5228 18 7Z"
                stroke="#ffffff"
              />
            </g>
            <g id="Group 35670">
              <path
                id="Vector 145"
                d="M1.32812 0.34375L14.6615 13.6771"
                stroke="#212121"
              />
              <path
                id="Vector 146"
                d="M14.6719 0.34375L1.33854 13.6771"
                stroke="#212121"
              />
            </g>
          </g>
        </g>
        <defs>
          <filter
            id="filter0_d_0_1"
            x="-16"
            y="-15"
            width="48"
            height="132"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="2" />
            <feGaussianBlur stdDeviation="4" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_0_1"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_0_1"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    </div>
  );
};
CrossSvg.defaultProps = {
  width: "16",
  height: "15",
};

export default CrossSvg;

export const CrossMdSvg = ({
  className,
  bgColor = "#F3F3F3",
  onClick = () => {},
  id = "",
}: any) => {
  return (
    <div onClick={onClick} className={`${className} pointer`} id={id}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
      >
        <circle cx="6" cy="6.00391" r="6" fill="#06A5FF" fillOpacity="0.8" />
        <path
          d="M9 3.00391L3 9.00388"
          stroke="#212121"
          strokeWidth="2"
          strokeLinecap="square"
        />
        <path
          d="M9 9.00391L3 3.00393"
          stroke="#212121"
          strokeWidth="2"
          strokeLinecap="square"
        />
      </svg>
    </div>
  );
};
