import { useState } from "react";

const GlobalDOCXSvg = ({ onClick, onHover, elem , size}: any) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div className="parent_wrapper_file">
      {isHovered && (
        <span
          className={` ${
            elem?.file?.name
              ? "common_hover_file_name-1"
              : "common_hover_file_name"
          }`}
        >
          {elem?.actual_file_name || elem?.file_name || elem?.file?.name}
          {(elem?.actual_file_name || elem?.file_name || elem?.file?.name) &&
            (elem?.actual_file_name || elem?.file_name || elem?.file?.name)
              .length > 10 &&
            "..."}
        </span>
      )}
      <svg
        className="cursor_pointer"
        xmlns="http://www.w3.org/2000/svg"
        width={size || "24"}
        height={size || "24"}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        viewBox="0 0 24 24"
        fill="none"
        onClick={onClick}
      >
        <g clipPath="url(#clip0_177_7386)">
          <path
            d="M24 7.44921V22.4058C24 23.2871 23.2456 24 22.3166 24H1.68341C0.754416 24 0 23.285 0 22.4058V1.59418C0 0.714991 0.754416 0 1.68341 0H16.221"
            fill="#06A5FF"
          />
          <path d="M16.221 0V7.44921H23.9979L16.221 0Z" fill="#0088C4" />
          <path
            d="M17.5345 10.2637H6.46553V11.3029H17.5345V10.2637Z"
            fill="white"
          />
          <path
            d="M17.5345 12.6997H6.46553V13.7389H17.5345V12.6997Z"
            fill="white"
          />
          <path
            d="M17.5345 15.1357H6.46553V16.175H17.5345V15.1357Z"
            fill="white"
          />
          <path
            d="M12.8001 17.5718H6.46553V18.611H12.8001V17.5718Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_177_7386">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default GlobalDOCXSvg;
