import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../../services/api";

const initialState: any = {
  lastPage: 100000,
  getProspectsData: [],
  loading: false,
  error: null,
  getUserTypeIdForProspects: null,
  getUniqueProspect: null,
  updateProspects: null,
  openDropdownProspects: false,
  getAllProspectsData: [],
  isDropdownOpen: null,
  selectedProspects: [],
  selectedSortValuesProspects: [],
  // STATES OF SELECTED FILTER GOES HERE
  searchValProspects: "",
  selectedDropdownItemProspect: "",
  selectedItemIndexFilterProspect: "",
  selectedItemIndexSortProspect: "",
  sortByProspect: "",
  selectedFiltferItemsProspect: [],
  prospectRoles: [],
  ProspectPageFilter: 1,
  ProspectFilterItems: {
    filterColumn: "",
    filterValue: "",
    searchVal: "",
    sortColumn: "",
    sortType: "",
    status: "1,2,3,4,5,8,9,10,11,12,13,14,15",
    prospectPageFilter: 1,
  },
  getProspectName: [],
  getProspectEmail: [],
  getProspectPhone: [],
  getProspectStatus: [],
  getProspectReffer: [],

  // Provider Prospect
  getUniqueProviderProspect: {},
};

export const getProspectsThunk = createAsyncThunk(
  "prospects/fetchProspects",
  async (dynamicId: number | any) => {
    try {
      const response = await api.get(
        `/users?paginate=true&status=1,2,3,4,5,8,9,10,11,12,13,14,15&page=1&limit=10000&sort_column=id&sort_type=desc&users?paginate=true&type=${dynamicId.dynamicProspectsId}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getProspectsSelectAllThunk = createAsyncThunk(
  "prospects/fetchProspectsSelectAll",
  async ({ dynamicId, status }: any) => {
    try {
      const response = await api.get(
        `/users?paginate=true&status=${status}&type=${dynamicId}&sort_column=id&sort_type=desc&page=1&limit=222200`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getProspectsByFilterThunk = createAsyncThunk(
  "clients/fetchClientsByFilter",
  async ({
    dynamicId,
    filterColumn,
    filterValue,
    searchVal,
    sortColumn,
    sortType,
    status,
    ProspectPageFilter,
  }: {
    dynamicId: number;
    filterColumn: string;
    filterValue: string;
    searchVal: string;
    sortColumn: string;
    sortType: string | any;
    status: any;
    ProspectPageFilter: any;
  }) => {
    try {
      const encodeFilterVal = encodeURIComponent(filterValue);
      const encodeSearchVal = encodeURIComponent(searchVal);
      const response = await api.get(
        `/users?paginate=true&page=${ProspectPageFilter}&status=${status}&sort_column=${sortColumn}&sort_type=${sortType}&filter_column=${filterColumn}&type=${dynamicId}&limit=5000000&filter_value=${encodeFilterVal}&search=${encodeSearchVal}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getProspectsByIdThunk = createAsyncThunk(
  "prospects/fetchProspectsById",
  async ({ typeId, clientId }: any) => {
    try {
      const response = await api.get(
        `/users?paginate=true&type=${typeId}&sort_column=id&sort_type=desc&id=${clientId}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getProviderProspectsByIdThunk = createAsyncThunk(
  "providerProspects/fetchProspectsById",
  async ({ typeId, clientId, tenant_id }: any) => {
    try {
      const response = await api.get(
        `/providers/get_provider_users?paginate=true&type=${typeId}&sort_column=id&sort_type=desc&id=${clientId}&tenant_id=${tenant_id}`
      );
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
);

export const updateProspectsThunk = createAsyncThunk(
  "contacts/updateContact",
  async (prospectData: any, { rejectWithValue }) => {
    try {
      const response = await api.post("/users/update_user", prospectData);
      return response.data;
    } catch (error: string | any) {
      return rejectWithValue(
        error.message || "Failed to upload client details"
      );
    }
  }
);

export const updateProspectsUserType = createAsyncThunk(
  "prospects/updateProspectsType",
  async ({ user_id, type, new_type }: any, { rejectWithValue }) => {
    try {
      const response = await api.post("/users/update_user_type", {
        user_id: user_id,
        type: type,
        new_type: new_type,
      });
      return response.data;
    } catch (error: string | any) {
      return rejectWithValue(
        error.message || "Failed to upload client details"
      );
    }
  }
);

export const getUserRolesProspectsThunk = createAsyncThunk(
  "prospects/fetchUserRoles",
  async (dynamicProspectId: any) => {
    let id = dynamicProspectId.dynamicProspectsId;

    try {
      const response = await api.get(`/users/get_user_roles?type=${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getProspectNameThunk = createAsyncThunk(
  "prospect/fetchName",
  async (dynamicProspectsId: number | any) => {
    try {
      const { data } = await api.get(
        `/users?unique_column=name&type=${dynamicProspectsId}`
      );
      return data?.data?.data;
    } catch (error) {
      throw error;
    }
  }
);
// Provider Prospect Uniqu Name Records
export const getProviderProspectNameThunk = createAsyncThunk(
  "providerProspect/fetchName",
  async ({ dynamicProspectsId, dynamicProviderId }: any) => {
    try {
      const { data } = await api.get(
        `/providers/get_provider_users?unique_column=name&type=${dynamicProspectsId}&tenant_id=${dynamicProviderId}`
      );
      return data?.data?.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getProspectEmailThunk = createAsyncThunk(
  "prospect/fetchEmail",
  async (dynamicProspectsId: number | any) => {
    try {
      const { data } = await api.get(
        `/users?unique_column=email&type=${dynamicProspectsId}`
      );
      return data?.data?.data;
    } catch (error) {
      throw error;
    }
  }
);
// Provider Prospect Unique Emails Records
export const getProviderProspectEmailThunk = createAsyncThunk(
  "providerProspect/fetchEmail",
  async ({ dynamicProspectsId, dynamicProviderId }: any) => {
    try {
      const { data } = await api.get(
        `/providers/get_provider_users?unique_column=email&type=${dynamicProspectsId}&tenant_id=${dynamicProviderId}`
      );
      return data?.data?.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getProspectPhoneThunk = createAsyncThunk(
  "prospect/fetchPhone",
  async (dynamicProspectsId: number | any) => {
    try {
      const { data } = await api.get(
        `/users?unique_column=mobile&type=${dynamicProspectsId}`
      );
      return data?.data?.data;
    } catch (error) {
      throw error;
    }
  }
);
// Provider Prospect Unique Phone Records
export const getProviderProspectPhoneThunk = createAsyncThunk(
  "providerProspect/fetchPhone",
  async ({ dynamicProspectsId, dynamicProviderId }: any) => {
    try {
      const { data } = await api.get(
        `/providers/get_provider_users?unique_column=phone&type=${dynamicProspectsId}&tenant_id=${dynamicProviderId}`
      );
      return data?.data?.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getProspectStatusThunk = createAsyncThunk(
  "prospect/fetchStatus",
  async (dynamicProspectsId: number | any) => {
    try {
      const { data } = await api.get(
        `/users?unique_column=status&type=${dynamicProspectsId}`
      );
      return data?.data?.data;
    } catch (error) {
      throw error;
    }
  }
);
// Provider Prospect Unique Status Records
export const getProviderProspectStatusThunk = createAsyncThunk(
  "providerProspect/fetchStatus",
  async ({ dynamicProspectsId, dynamicProviderId }: any) => {
    try {
      const { data } = await api.get(
        `/providers/get_provider_users?unique_column=status&type=${dynamicProspectsId}&tenant_id=${dynamicProviderId}`
      );
      return data?.data?.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getProspectRefferThunk = createAsyncThunk(
  "prospect/fetchReffer",
  async (dynamicProspectsId: number | any) => {
    try {
      const { data } = await api.get(
        `/users?unique_column=referrer_name&type=${dynamicProspectsId}`
      );
      return data?.data?.data;
    } catch (error) {
      throw error;
    }
  }
);
// referrer
// Provider Prospect Unique Reffer Records
export const getProviderProspectRefferThunk = createAsyncThunk(
  "providerProspect/fetchReffer",
  async ({ dynamicProspectsId, dynamicProviderId }: any) => {
    try {
      const { data } = await api.get(
        `/providers/get_provider_users?unique_column=referrer_name&type=${dynamicProspectsId}&tenant_id=${dynamicProviderId}`
      );
      return data?.data?.data;
    } catch (error) {
      throw error;
    }
  }
);

const prospectsSlice = createSlice({
  name: "prospects",
  initialState,
  reducers: {
    setProspectPageFilter: (state) => {
      state.ProspectPageFilter += 1;
    },
    resetProspectPageFilter: (state) => {
      state.ProspectPageFilter = 1;
    },
    resetProspectData: (state) => {
      state.getProspectsData = {};
    },
    setProspectFilterItems: (state, action) => {
      state.ProspectFilterItems = action.payload;
    },
    resetProspectFilterItems: (state) => {
      state.ProspectFilterItems = {
        filterColumn: "",
        filterValue: "",
        searchVal: "",
        sortColumn: "",
        sortType: "",
        prospectPageFilter: 1,
        status: "1,2,3,4,5,8,9,10,11,12,13,14,15",
      };
    },

    setUserTypeIdForProspects: (state, action) => {
      state.getUserTypeIdForProspects = action.payload;
    },
    setopenDropdownProspects: (state) => {
      state.openDropdownProspects = true;
    },
    resetopenDropdownProspects: (state) => {
      state.openDropdownProspects = null;
    },
    setsearchvalProspects: (state, action) => {
      state.searchValProspects = action.payload;
    },
    resetsearchvalProspects: (state) => {
      state.searchValProspects = null;
    },
    setisDropdownOpen: (state, action) => {
      state.isDropdownOpen = action.payload;
    },
    setselectedProspects: (state, action) => {
      state.selectedProspects = action.payload;
    },
    resetSelectedProspects: (state, action) => {
      state.selectedProspects = [];
    },
    setselectedSortValuesProspects: (state, action) => {
      state.selectedSortValuesProspects = action.payload;
    },
    resetselectedSortValuesProspects: (state, action) => {
      state.selectedSortValuesProspects = [];
    },
    // Filter Dropdown
    setSelectedDropdownItemProspect: (state, action) => {
      state.selectedDropdownItemProspect = action.payload;
    },
    resetSelectedDropdownItemProspect: (state) => {
      state.selectedDropdownItemProspect = null;
    },
    setSelectedItemIndexFilterProspect: (state, action) => {
      state.selectedItemIndexFilterProspect = action.payload;
    },
    resetSelectedItemIndexFilterProspect: (state) => {
      state.selectedItemIndexFilterProspect = null;
    },
    setSelectedItemIndexSortProspect: (state, action) => {
      state.selectedItemIndexSortProspect = action.payload;
    },
    resetSelectedItemIndexSortProspect: (state) => {
      state.selectedItemIndexSortProspect = null;
    },
    setSortByProspect: (state, action) => {
      state.sortByProspect = action.payload;
    },
    resetSortByProspect: (state) => {
      state.sortByProspect = null;
    },
    setSelectedFilterItemsProspect: (state, action) => {
      state.selectedFilterItemsProspect = action.payload;
    },
    resetSelectedFilterItemsProspect: (state) => {
      state.selectedFilterItemsProspect = null;
    },
    clearCurrentProspectData: (state) => {
      state.getUniqueProspect = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProspectsThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProspectsThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.getProspectsData = action.payload;
        state.getAllProspectsData = action.payload;
      })
      .addCase(getProspectsThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getUserRolesProspectsThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUserRolesProspectsThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.prospectRoles = action.payload;
      })
      .addCase(getUserRolesProspectsThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getProspectsByFilterThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProspectsByFilterThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.lastPage = action.payload.data.last_page;
        // if (JSON.parse(JSON.stringify(state.getProspectsData)).data) {
        //   const data = [
        //     ...JSON.parse(JSON.stringify(state.getProspectsData)).data.data,
        //     ...action.payload.data.data,
        //   ];
        //   action.payload.data.data = data;
        // }
        // state.getProspectsData = action.payload;
        if (action?.payload && action?.payload?.data) {
          state.getProspectsData = action.payload;

        } else {
          state.getProspectsData = action.payload;
        }
      })
      .addCase(getProspectsByFilterThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(getProspectsSelectAllThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProspectsSelectAllThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.getProspectsData = action.payload;
      })
      .addCase(getProspectsSelectAllThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      // getProspectsSelectAllThunk
      .addCase(getProspectsByIdThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProspectsByIdThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.getUniqueProspect = action.payload;
      })
      .addCase(getProspectsByIdThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updateProspectsThunk.pending, (state) => {
        state.loading = true;
        state.error = null;
      })

      .addCase(getProspectNameThunk.pending, (state) => {
        state.loading === true;
      })
      .addCase(getProspectNameThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.getProspectName = action.payload;
      })
      .addCase(getProspectNameThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(getProviderProspectNameThunk.pending, (state) => {
        state.loading === true;
      })
      .addCase(getProviderProspectNameThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.getProspectName = action.payload;
      })
      .addCase(getProviderProspectNameThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      }) //

      .addCase(getProspectEmailThunk.pending, (state) => {
        state.loading === true;
      })
      .addCase(getProspectEmailThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.getProspectEmail = action.payload;
      })
      .addCase(getProspectEmailThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      }) //
      // Provder
      .addCase(getProviderProspectEmailThunk.pending, (state) => {
        state.loading === true;
      })
      .addCase(getProviderProspectEmailThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.getProspectEmail = action.payload;
      })
      .addCase(getProviderProspectEmailThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      }) //

      .addCase(getProspectPhoneThunk.pending, (state) => {
        state.loading === true;
      })
      .addCase(getProspectPhoneThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.getProspectPhone = action.payload;
      })
      .addCase(getProspectPhoneThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      }) //
      // Provider
      .addCase(getProviderProspectPhoneThunk.pending, (state) => {
        state.loading === true;
      })
      .addCase(getProviderProspectPhoneThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.getProspectPhone = action.payload;
      })
      .addCase(getProviderProspectPhoneThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      }) //

      .addCase(getProspectStatusThunk.pending, (state) => {
        state.loading === true;
      })
      .addCase(getProspectStatusThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.getProspectStatus = action.payload;
      })
      .addCase(getProspectStatusThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      // Provider
      .addCase(getProviderProspectStatusThunk.pending, (state) => {
        state.loading === true;
      })
      .addCase(getProviderProspectStatusThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.getProspectStatus = action.payload;
      })
      .addCase(getProviderProspectStatusThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(getProspectRefferThunk.pending, (state) => {
        state.loading === true;
      })
      .addCase(getProspectRefferThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.getProspectReffer = action.payload;
      })
      .addCase(getProspectRefferThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      // Provider
      .addCase(getProviderProspectRefferThunk.pending, (state) => {
        state.loading === true;
      })
      .addCase(getProviderProspectRefferThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.getProspectReffer = action.payload;
      })
      .addCase(getProviderProspectRefferThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });

    builder.addCase(updateProspectsThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.updateProspects = action.payload;
    });

    builder
      .addCase(updateProspectsThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload
          ? action.payload
          : "Failed to upload client details";
      })

      .addCase(getProviderProspectsByIdThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProviderProspectsByIdThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.getUniqueProviderProspect = action.payload;
      })
      .addCase(getProviderProspectsByIdThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const {
  setUserTypeIdForProspects,
  setopenDropdownProspects,
  resetopenDropdownProspects,
  setsearchvalProspects,
  resetsearchvalProspects,
  setisDropdownOpen,
  setselectedSortValuesProspects,
  setselectedProspects,
  resetSelectedProspects,
  resetselectedSortValuesProspects,
  setSelectedDropdownItemProspect, // Filter
  resetSelectedDropdownItemProspect,
  setSelectedItemIndexFilterProspect,
  resetSelectedItemIndexFilterProspect,
  setSelectedItemIndexSortProspect,
  resetSelectedItemIndexSortProspect,
  setSortByProspect,
  resetSortByProspect,
  setSelectedFilterItemsProspect,
  resetSelectedFilterItemsProspect,
  clearCurrentProspectData,
  resetProspectFilterItems,
  setProspectFilterItems,
  resetProspectData,
  resetProspectPageFilter,
  setProspectPageFilter,
} = prospectsSlice.actions;

export default prospectsSlice.reducer;
