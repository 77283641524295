import React, { useEffect, useState } from "react";
import CommonUpperBox from "../../components/UpperBox/CommonHeader";
import "./styles/index.css";
import { useLocation, useParams } from "react-router-dom";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../Controller/redux/Hooks";
import {
  getUserRolesReportsHub,
  getReportById,
  updateReportsHub,
} from "../../../Controller/redux/features/ReportsHubFeature/ReportsHubSlice";
import { toast } from "react-toastify";
import CommonHeader from "../../components/CommonHeader/CommonHeader";
import CircularNoteTrigger from "../../components/CircularNoteTrigger/CircularNoteTrigger";
import UploadSvg from "../../../Model/Assets/SVG/Common/UploadSvg";
import { Comms } from "../Comms/Comms";

const ReportsHubDetails = () => {
  const { id }: any = useParams();
  const location = useLocation();
  const reportComms: any = useLocation()?.state?.reportComms;

  const reportsHubId = window.atob(id);

  const dispatch = useAppDispatch();

  const dynamicReportsHubId = localStorage.getItem("dynamicReportsHubId");

  const { currentReportsHub, getUserRoleReportsHub } = useAppSelector(
    (state) => state?.reportsHub
  );
  const { domainDetail } = useAppSelector((state) => state?.common);

  const [commonBody, setCommonBody] = useState<any>({});

  const [reportsHubTypeArr, setReportsHubTypeArr] = useState<any>();

  const [userRoles, setUserRoles] = useState([]);
  const [currentActiveTab, setCurrentActiveTab] = useState(
    reportComms ? "comms" : "details"
  );

  useEffect(() => {
    if (
      getUserRoleReportsHub?.data &&
      getUserRoleReportsHub?.data?.length > 0
    ) {
      setUserRoles(getUserRoleReportsHub?.data);
    }
  }, [getUserRoleReportsHub]);

  useEffect(() => {
    if (domainDetail && domainDetail?.report_type) {
      const reportsHubType = domainDetail?.report_type.split(",");
      setReportsHubTypeArr(reportsHubType);
    }
  }, [domainDetail]);

  useEffect(() => {
    dispatch(getReportById({ type: dynamicReportsHubId, id: reportsHubId }));
    dispatch(getUserRolesReportsHub({ id: dynamicReportsHubId }));
  }, []);

  useEffect(() => {
    setCommonBody(currentReportsHub || {});
  }, [currentReportsHub]);

  useEffect(() => {
    setCommonBody({});
  }, [location]);

  const dynamicColor = useAppSelector((state) => state?.common?.dynamicColor);

  const handleUpdate = () => {
    if (JSON.stringify(commonBody) === JSON.stringify(currentReportsHub)) {
      toast.warn("No changes were made!");
      return;
    }
    dispatch(
      updateReportsHub({
        type: dynamicReportsHubId,
        report_id: commonBody?.id,
        report_name: commonBody?.report_name,
        iframe: commonBody?.iframe,
        role: commonBody?.role,
        extension: commonBody?.extension,
        base64: commonBody?.base64,
      })
    ).then((response: any) => {
      if (response.error) {
        toast.error(response?.payload || "Error updating report");
        return;
      }
      toast.success("Report updated successfully!", {
        progressStyle: { backgroundColor: dynamicColor },
        icon: (
          <span
            style={{
              marginRight: "8px",
              borderRadius: "50%",
              width: "7rem",
              height: "35px",
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: dynamicColor,
              backgroundColor: "white",
              fontSize: "1.5rem",
              border: `3px solid ${dynamicColor}`,
            }}
          >
            ✓
          </span>
        ),
      });
      dispatch(getReportById({ type: dynamicReportsHubId, id: reportsHubId }));
    });
  };

  const handleLogoUpload = async (event: any) => {
    const file: any = event.target.files[0];

    const toBase64 = (file) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
      });

    const base64Data: any = await toBase64(file);
    const extension = file.name.split(".").pop();

    setCommonBody({
      ...commonBody,
      extension,
      base64: base64Data?.split(",")[1],
    });
  };

  return (
    <div>
      <CommonUpperBox
        type={"listing"}
        title={reportComms ? reportComms : currentReportsHub?.report_name}
      />

      <div className="unique-client-details-wrapper">
        <CommonHeader
          mainType={reportComms ? "reportComms" : "reports"}
          type={currentActiveTab}
          handleClick={(button: string) => {
            setCurrentActiveTab(button?.toLowerCase());
          }}
          currentReportsHub={
            reportComms ? { report_name: reportComms } : currentReportsHub
          }
        />
        {currentActiveTab === "comms" && (
          <div style={{ marginTop: reportComms ? "20px" : "10px" }}>
            <Comms isUserType={true} userTypeId={window.atob(id)} />
          </div>
        )}
        {currentActiveTab === "details" && (
          <div
            style={{
              position: "relative",
              top: "4px",
            }}
          >
            <div className="reports_hub_form">
              <div className="form_group">
                <label htmlFor="name" className="label_reports_hub">
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  className="text_input_reports_hub"
                  value={commonBody?.report_name}
                  onChange={(e) =>
                    setCommonBody({
                      ...commonBody,
                      report_name: e.target.value,
                    })
                  }
                />
              </div>

              <div className="form_group">
                <label htmlFor="liveLink" className="label_reports_hub">
                  Live Link
                </label>
                <input
                  type="text"
                  id="liveLink"
                  className="text_input_reports_hub"
                  value={commonBody?.iframe}
                  onChange={(e) =>
                    setCommonBody({ ...commonBody, iframe: e.target.value })
                  }
                />
              </div>
              <div className="form_group">
                <label htmlFor="category" className="label_reports_hub">
                  Category
                </label>
                <select
                  required
                  id="category"
                  className="category_select"
                  onChange={(e) =>
                    setCommonBody({ ...commonBody, role: e.target.value })
                  }
                  value={commonBody?.role || currentReportsHub?.category_id}
                >
                  <option value="">Please Select...</option>

                  {userRoles?.map((elem: any, index: number | any) => {
                    let isExist: any = reportsHubTypeArr?.includes(
                      `${elem?.desc}`
                    );
                    if (isExist) {
                      return (
                        <option key={index} value={elem?.id}>
                          {elem.desc}
                        </option>
                      );
                    }
                  })}
                </select>
              </div>

              <div className="form_group">
                <label className="label_reports_hub">Icon/Tile Image</label>
                <input
                  type="file"
                  onChange={handleLogoUpload}
                  accept="image/*"
                />
              </div>
              {commonBody?.profile_image_s3 && (
                <div className="current_logo">
                  <img src={commonBody?.profile_image_s3} alt="logo" />
                </div>
              )}

              <button className="update_button" onClick={handleUpdate}>
                Update Reports
              </button>
            </div>
          </div>
        )}
      </div>
      <CircularNoteTrigger type="Reports" title="Reports" />
    </div>
  );
};

export default ReportsHubDetails;
