//ts-nocheck
import "../../Styles/ProspectsScreen.css";
import { Modal, ModalOverlay } from "@chakra-ui/modal";
import CustomDatePicker from "../../../../../components/DatePicker/CustomDatePicker";
import React, {
  // Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  formatDate,
  formatDateForAPI,
  formatDateFromAPI,
} from "../../../../../../Model/utils/Helper/formatDate";
import {
  getAssociationRelationshipsThunk,
  getAssociationTypesThunk,
  getAssociations,
  resetSelectedDropdownItemAssociations,
  resetSelectedFilterItemsAssociations,
  resetSelectedItemIndexFilterAssociations,
} from "../../../../../../Controller/redux/features/CommonFeature/associationSlice";

import {
  createDocumentThunk,
  getDocuments,
  getDocumentsByFilter,
  getUniquePrimaryApproversThunk,
  resetIsDocumentsDropdownOpen,
  resetSelectedDropdownItemDocuments,
  resetSelectedFilterItemsDocuments,
  resetSelectedItemIndexFilterDocuments,
  resetinputSearchVal,
  setIsFormOpenTrue,
  setWarningPopup,
  setselectedDataObject,
  updateDocumentThunk,
} from "../../../../../../Controller/redux/features/CommonFeature/documentSlice";

import {
  clearCurrentProspectData,
  getProspectsByIdThunk,
  getUserRolesProspectsThunk,
  updateProspectsThunk,
} from "../../../../../../Controller/redux/features/ProspectsFeature/prospectsSlice";

import {
  resetIsAssessmentFormOpenProspects,
  resetopenFormByClickProspects,
  setIsAssessmentFormOpenProspects,
  setopenFormByClickProspects,
} from "../../../../../../Controller/redux/features/ProspectsFeature/documentProspectsSlice";
import {
  resetselectedItemIndexAssociations,
  resetselectedNames,
} from "../../../../../../Controller/redux/features/CommonFeature/associationSlice";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../Controller/redux/Hooks";
import { useNavigate, useParams } from "react-router-dom";
import ClientAssessmentForm from "../../../ClientPage/Components/UniqueClientScreen/Components/DocumentsTab/Components/ClientAssessmentForm";
import CommonHeader from "../../../../../components/CommonHeader/CommonHeader";
import CommonTable from "../../../../../components/CommonTable/InputTable";
import ConfirmationModal from "../../../../../components/ConfirmationModal/ConfirmationModal";
// import DropdownIcon from "../../../../../../Model/Assets/png/drop.png";
import ReactDatePicker from "react-datepicker";
// import Rectangle from "../../../ClientPage/Components/StickyNote/Rectangle";
// import UnderWork from "../../../../../components/UnderWork/UnderWork";
import UnsavedChangesPopup from "../../../../../components/UnsavedChangesPopup/UnsavedChangesPopup";
// import UpperBox from "../../../../../components/UpperBox/UpperBox";
import CommonUpperBox from "../../../../../components/UpperBox/CommonHeader";
import { api } from "../../../../../../Controller/services/api";
import {
  dummyArrBusiness,
  dummyArrProspects,
} from "../../../ClientPage/Components/UniqueClientScreen/Data/ClientScreenContentArr";
import {
  getStatusDetailsThunk,
  getUserTypesThunk,
} from "../../../../../../Controller/redux/features/CommonFeature/commonSlice";
// import { setUserTypeIdForClient } from "../../../../../../Controller/redux/features/clientsSlice";
// import { setUserTypeIdForContact } from "../../../../../../Controller/redux/features/contactsSlice";
// import { setUserTypeIdForStaff } from "../../../../../../Controller/redux/features/staffSlice";
import { toast } from "react-toastify";
import { Comms } from "../../../../Comms/Comms";
import Daybook from "../../../../Daybook/Daybook";
import { translateYIndices } from "../../Data/IndicesData";
import CircularNoteTrigger from "../../../../../components/CircularNoteTrigger/CircularNoteTrigger";
import ProfilePictureModal from "../../../../../components/ProfilePictureModal/ProfilePictureModal";
import CommonQuickMenu from "../../../../../components/CommonQuickMenu/CommonQuickMenu";
import { Spinner } from "@chakra-ui/react";

import UnderWork from "../../../../../components/UnderWork/UnderWork";
import Table from "../../../../../components/CommonTable/Table";
import {
  associationColumns,
  documentsColumns,
} from "../../../../../components/CommonTable/Data/AssociationHeaderDataArr";
import { isPending } from "@reduxjs/toolkit";
import {
  setshowUnsavedModal,
  setunsavedChanges,
} from "../../../../../../Controller/redux/features/AuthFeature/authSlice";
import { getEntityRoleThunk } from "../../../../../../Controller/redux/features/BusinessFeature/businessSlice";
import { setWarningMessage } from "../../../../../../Controller/redux/features/ClientFeature/documentClientSlice";
// import TagsInput from "../../../../../components/Tags/TagBuilder";
// import { hover } from "@testing-library/user-event/dist/hover";

const ProspectsScreen = () => {
  const dispatch = useAppDispatch();
  const { hasDeleteAssociation, warningPopupAssociation } = useAppSelector(
    (state) => state?.associations
  );
  const [clientId, setclientId] = useState("");
  const [currentProspects, setcurrentProspects] = useState<any>(null);
  const [selectedDate, setSelectedDate] = useState<Date | null | any>("");
  const [selectedEFA, setselectedEFA] = useState<any>("");
  const [selectedIED, setSelectedIED] = useState<any>("");
  const [selectedACATDate, setSelectedACATDate] = useState<Date | null | any>(
    ""
  );
  const [selectedAddress, setSelectedAddress] = useState<any>("");

  const selectAddress = (e: any) => {
    if (!hasUnsavedChanges) {
      setHasUnsavedChanges(true);
    }
    setSelectedAddress(e.target.innerHTML);
    setDetails({ ...details, address: e.target.innerHTML });
    setunsavedChanges(true);
    setPossibleAddresses([]);
  };

  const [possibleAddresses, setPossibleAddresses] = useState<any>([]);

  function initService(query: any) {
    const displaySuggestions = function (predictions: any, status: any) {
      // @ts-ignore
      if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
        // console.log("Location service down!");
        return;
      }
      if (!predictions) {
        setPossibleAddresses([]);
        return;
      }
      setPossibleAddresses(predictions);
    };
    if (window.google && window.google.maps) {
      // @ts-ignore
      const service = new window.google.maps.places.AutocompleteService();

      service.getQueryPredictions({ input: query }, displaySuggestions);
    }
  }

  // @ts-ignore
  const dynamicProspectId = parseInt(localStorage.getItem("dynamicProspectId"));

  const [userRoles, setUserRoles] = useState<any[]>([]);
  const getUserRoles = useAppSelector(
    (state) => state?.prospects?.prospectRoles
  );

  useEffect(() => {
    if (getUserRoles?.data && getUserRoles?.data?.length > 0) {
      setUserRoles(getUserRoles?.data);
    }
  }, [getUserRoles]);

  const getCurrentProspects = useAppSelector(
    (state: any) => state?.prospects?.getUniqueProspect
  );
  const { hasDeleteDocument, warningPopup } = useAppSelector(
    (state) => state?.document
  );

  //dh
  const [openSwitch, setOpenswitch] = useState(false);
  const [dob, setDob] = useState(new Date());
  const toggleSwitch = () => {
    setOpenswitch(!openSwitch);
  };
  useEffect(() => {
    handleDateChange(dob);
  }, [dob]);

  const datepickerRef = useRef<any>(null);

  const { id, pro_id }: any = useParams();
  const [providerId, setProviderId] = useState("");

  useEffect(() => {
    if (id) {
      let realId = window.atob(id);
      setclientId(realId);
    }
    if (pro_id) {
      setProviderId(window?.atob(pro_id));
    }
  }, [id]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (clientId) {
      dispatch(clearCurrentProspectData());
      dispatch(getProspectsByIdThunk({ dynamicProspectId, clientId }))
        .then(() => {
          setLoading(false);
        })
        .catch((error: any) => {
          // console.log(error);
          setLoading(false);
        });
    }
  }, [clientId]);

  const pickerRef = useRef<any>(null);

  useEffect(() => {
    // dispatch(clearCurrentProspectData());
    if (getCurrentProspects?.data?.data.length > 0) {
      setcurrentProspects(getCurrentProspects?.data?.data[0]);
    } else {
      setcurrentProspects(null);
    }
  }, [getCurrentProspects, clientId]);

  const {
    routeString,

    showUnsavedModal,
  } = useAppSelector((state) => state.auth);

  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const navigate = useNavigate();
  const dynamicColor = useAppSelector((state) => state?.common?.dynamicColor);
  const initialDate = currentProspects?.date_of_birth;

 
  useEffect(() => {
    if (initialDate) {
      const rearrangedDateString = formatDateFromAPI(initialDate);
      setSelectedDate(new Date(rearrangedDateString));
    } else {
      setSelectedDate(null);
    }
  }, [initialDate]);


  const initialEFA = currentProspects?.funding_available;
  useEffect(() => {
    if (initialEFA) {
      setselectedEFA(new Date(initialEFA));
    }
  }, [initialEFA]);

  const [selectedInterval, setselectedInterval] = useState("");

  const capitalizedInterval =
    selectedInterval.charAt(0).toUpperCase() + selectedInterval.slice(1);
  const result = `${capitalizedInterval}, ${formatDate(selectedACATDate)}`;

  const handleMoveWithoutSaving = () => {
    dispatch(setshowUnsavedModal(false));
    dispatch(setunsavedChanges(false));
    navigate(routeString);
  };

  useEffect(() => {
    dispatch(setshowUnsavedModal(false));
    dispatch(setunsavedChanges(false));
  }, []);
  const [buttonTab, setbuttonTab] = useState({
    Details: true,
    Associations: false,
    Clinical: false,
    Comms: false,
    Documents: false,
    Finance: false,
    Daybook: false,
  });

  // useEffect(() => {
  //   if (getUserRoles?.data && getUserRoles?.data?.length > 0) {
  //     setUserRoles(
  //       getUserRoles?.data.filter((elem: any) => elem.desc !== "Care at Home")
  //     );
  //   }
  // }, [getUserRoles]);

  const documentsArr = useAppSelector((state) => state.document.getDocuments);

  const [documentArrRecords, setDocumentArrRecords] = useState([]);

  useEffect(() => {
    if (documentsArr && buttonTab.Documents) {
      // // console.log("documentArrRecords====================>", documentsArr);
      setDocumentArrRecords(documentsArr);
    }
  }, [documentsArr, buttonTab.Documents]);

  useEffect(() => {
    if (clientId && buttonTab.Documents) {
      dispatch(getDocuments({ clientId, providerId }));
    }
  }, [clientId, buttonTab.Documents, providerId]);

  const isFormOpen = useAppSelector(
    (state) => state?.documentProspects?.openFormByClick
  );

  const handleIntervalChange = (interval: string | any) => {
    setselectedInterval(interval);
  };

  const handleMoveWithSave = async () => {
    dispatch(setshowUnsavedModal(false));
    dispatch(setunsavedChanges(false));
    navigate(routeString);
    try {
      const res = await dispatch(updateProspectsThunk(updateProspects));
      dispatch(getProspectsByIdThunk({ typeId: dynamicProspectId, clientId }));
      if (res?.payload.status === "success") {
        toast.success("Prospect updated successfully!", {
          style: {},
          progressStyle: { backgroundColor: dynamicColor },
          icon: (
            <span
              style={{
                marginRight: "8px",
                borderRadius: "50%",
                width: "7rem",
                height: "35px",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: dynamicColor,
                backgroundColor: "white",
                fontSize: "1.5rem",
                border: `3px solid ${dynamicColor}`,
              }}
            >
              ✓
            </span>
          ),
        });
      } else {
        toast.error("An Error Occurred!");
      }
    } catch (error) {
      console.error(error);
      toast.error("An Error Occurred!");
    }
  };
  let isPending = false;
  const handleCloseModal = async () => {
    if (documentDetails.title || documentDetails.summary) {
      if (isPending) return;
      try {
        isPending = true;

        dispatch(createDocumentThunk(documentDetails))
          .then(() => {
            isPending = false;

            toast.success("Document Created Successfully!", {
              style: {},
              progressStyle: { backgroundColor: dynamicColor },
              icon: (
                <span
                  style={{
                    marginRight: "8px",
                    borderRadius: "50%",
                    width: "7rem",
                    height: "35px",
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: dynamicColor,
                    backgroundColor: "white",
                    fontSize: "1.5rem",
                    border: `3px solid ${dynamicColor}`,
                  }}
                >
                  ✓
                </span>
              ),
            });
            dispatch(getUniquePrimaryApproversThunk(clientId));
          })
          .catch(() => {
            isPending = false;

            toast.error("Failed To Create Document. Please Try Again!");
          });
        dispatch(getDocuments({ clientId, providerId }));
        // dispatch(resetIsAssessmentFormOpenProspects());
        // dispatch(resetopenFormByClickProspects());
      } catch {
        dispatch(getDocuments({ clientId, providerId }));
        // dispatch(resetIsAssessmentFormOpenProspects());

        // dispatch(resetopenFormByClickProspects());
      } finally {
        // dispatch(resetIsAssessmentFormOpenProspects());
        // dispatch(resetopenFormByClickProspects());

        dispatch(getDocuments({ clientId, providerId }));
      }
    } else {
      // dispatch(resetIsAssessmentFormOpenProspects());
      // dispatch(resetopenFormByClickProspects());

      dispatch(getDocuments({ clientId, providerId }));
    }
  };

  const [documentDetails, setdocumentDetails] = useState<any>();

  const handleGetDetails = (elem: any) => {
    setdocumentDetails(elem);
  };

  const handleOverlayClick = async (isFormSave: any) => {
    // // console.log("isFormSave ====================>", isFormSave);
    let duplicateDetailsInfo = { ...documentDetails, user_id: "" };
    if (documentDetails?.title == "") {
      const hasValueInOtherFields = Object.keys(duplicateDetailsInfo);
      let nonEmptyFieldCount = 0;

      let ishereAnyFieldsHaveVaule = false;
      hasValueInOtherFields.map((res) => {
        // if (documentDetails[res]) {
        if (!ishereAnyFieldsHaveVaule) {
          if (typeof duplicateDetailsInfo[res] === "string") {
            if (!!duplicateDetailsInfo[res]) {
              ishereAnyFieldsHaveVaule = true;
              !isFormSave
                ? dispatch(setWarningMessage(true))
                : toast.warning("Title is mandatory");
              return;
            }
          } else if (typeof duplicateDetailsInfo[res] === "object") {
            if (!!duplicateDetailsInfo[res]?.length) {
              if (!!duplicateDetailsInfo[res]) {
                ishereAnyFieldsHaveVaule = true;
                // toast.warning("Title is mandatory");
                !isFormSave
                  ? dispatch(setWarningMessage(true))
                  : toast.warning("Title is mandatory");
                return;
              }
            }
          }
        }
      });

      if (ishereAnyFieldsHaveVaule) {
        return;
      }
    }

    if (documentDetails?.title || documentDetails?.summary) {
      if (isPending) return;
      try {
        isPending = true;

        dispatch(createDocumentThunk(documentDetails))
          .then(() => {
            isPending = false;

            toast.success("Document Created Successfully!", {
              // style: {},
              progressStyle: { backgroundColor: dynamicColor },
              icon: (
                <span
                  style={{
                    marginRight: "8px",
                    borderRadius: "50%",
                    width: "7rem",
                    height: "35px",
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: dynamicColor,
                    backgroundColor: "white",
                    fontSize: "1.5rem",
                    border: `3px solid ${dynamicColor}`,
                  }}
                >
                  ✓
                </span>
              ),
            });
            dispatch(getUniquePrimaryApproversThunk(clientId));
            dispatch(getDocuments({ clientId, providerId }));
          })
          .catch(() => {
            isPending = false;

            toast.error("Failed To Create Document. Please Try Again!");
          });
        dispatch(getDocuments({ clientId, providerId }));
        dispatch(resetIsAssessmentFormOpenProspects());
        dispatch(resetopenFormByClickProspects());
      } catch {
        dispatch(getDocuments({ clientId, providerId }));
        dispatch(resetIsAssessmentFormOpenProspects());
        dispatch(resetopenFormByClickProspects());
      } finally {
        dispatch(getDocuments({ clientId, providerId }));
        dispatch(resetIsAssessmentFormOpenProspects());
        dispatch(resetopenFormByClickProspects());
      }
    } else {
      dispatch(resetIsAssessmentFormOpenProspects());
      dispatch(resetopenFormByClickProspects());

      dispatch(getDocuments({ clientId, providerId }));
    }
  };

  const [details, setDetails] = useState<any>({
    givenName: "",
    middleName: "",
    familyName: "",
    knownAs: "",
    address: "",
    email: "",
    phone: "",
    mobile: "",
    dateOfBirth: "",
    null1: "",
    null2: "",
    null3: "",
    fundingType: "",
    packageLevel: "",
    packageNumber: "",
    efa: "",
    acatAssessment: "",
    addInitialEnquiry: "",
    role: "",
  });

  const { domainDetail, user } = useAppSelector((state) => state?.common);
  let businessType = domainDetail?.businessmodel_type;
  // const isUserAdmin = user?.roles[0]?.desc?.toLowerCase();
  // const isAdminActive = isUserAdmin === "administrator";
  const isAdminActive = user?.tenant?.id === user?.tenant?.parent_id;

  useEffect(() => {
    const fetchedData: any = currentProspects;
    if (fetchedData) {
      setDetails({
        givenName: `${fetchedData.first_name}` || "",
        middleName: fetchedData.middle_name || "",
        familyName: fetchedData.last_name || "",
        knownAs: fetchedData.pref_name || "",
        address: fetchedData?.user_addresses[0]?.address || "",
        email: fetchedData?.email === null ? "" : fetchedData?.email,
        phone: fetchedData?.phone === null ? "" : fetchedData?.phone,
        mobile: fetchedData?.mobile === null ? "" : fetchedData?.mobile,

        dateOfBirth:
          fetchedData?.date_of_birth !== null
            ? formatDate(fetchedData.date_of_birth)
            : "",
        null1: "",
        null2: "",
        null3: "",

        fundingType:
          fetchedData?.funding_type !== null ? fetchedData?.funding_type : "",

        packageLevel:
          fetchedData?.package_level !== null ? fetchedData?.package_level : "",
        packageNumber:
          fetchedData?.package_number !== null
            ? fetchedData?.package_number
            : "",

        efa:
          fetchedData?.funding_available !== null
            ? fetchedData?.funding_available
            : "",

        acatAssessment: fetchedData?.acat_assessment,
        addInitialEnquiry: fetchedData?.initial_enquiry,
        role: currentProspects ? currentProspects?.roles[0]?.id : "",
      });

      const currentClientDOB = fetchedData?.date_of_birth;
      if (currentClientDOB !== null && currentClientDOB !== "") {
        const rearrangedDateString = formatDateFromAPI(currentClientDOB);
        setSelectedDate(new Date(rearrangedDateString));
      } else {
        setSelectedDate(null);
      }

      const currentEFA = fetchedData?.funding_available;
      if (currentEFA !== null) {
        setselectedEFA(new Date(currentEFA));
      } else {
        setselectedEFA(null);
      }
      const currentIED = fetchedData?.initial_enquiry;
      if (currentIED !== null) {
        setSelectedIED(new Date(currentIED));
      } else {
        setSelectedIED(null);
      }
    } else {
      setDetails({
        givenName: "",
        middleName: "",
        familyName: "",
        knownAs: "",
        address: "",
        email: "",
        phone: "",
        mobile: "",
        dateOfBirth: "",

        fundingType: "",
        packageLevel: "",
        packageNumber: "",
        efa: "",
        acatAssessment: "",
        addInitialEnquiry: "",
      });
    }

    if (businessType == "B2B" || isAdminActive) {
      setSelectedDate(null);
      setDetails({
        businessName: fetchedData?.first_name,
        email: fetchedData?.email,
        address:
          (fetchedData?.user_addresses &&
            fetchedData?.user_addresses.length !== 0 &&
            fetchedData?.user_addresses[0]?.address) ||
          "",
        phone: fetchedData?.phone,
        role:
          fetchedData?.roles && fetchedData?.roles?.length !== 0
            ? fetchedData?.roles[0].id
            : "",
      });
    }
  }, [currentProspects, clientId]);

  const [uploadedFiles, setUploadedFiles] = useState<
    { file: File; key: string; file_name?: string; actual_file_name?: string }[]
  >([]);

  const handleClickOutside = (event: any) => {
    if (
      pickerRef.current &&
      !pickerRef.current.contains(event.target) &&
      !event.target.classList.contains("react-datepicker") &&
      !event.target.closest(".react-datepicker") &&
      !event.target.classList.contains("acat-items-client") &&
      !event.target.closest(".acat-items-client") &&
      !event.target.classList.contains("select-container-acat-clients") &&
      !event.target.closest(".select-container-acat-clients")
    ) {
      setopenACATDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setHasUnsavedChanges(false);
  }, []);

  const handleSaveChanges = async () => {
    setHasUnsavedChanges(false);
    dispatch(setunsavedChanges(false));

    try {
      const res = await dispatch(updateProspectsThunk(updateProspects));
      dispatch(getProspectsByIdThunk({ typeId: dynamicProspectId, clientId }));
      if (res?.payload.status === "success") {
        toast.success("Prospect updated successfully!", {
          style: {},
          progressStyle: { backgroundColor: dynamicColor },
          icon: (
            <span
              style={{
                marginRight: "8px",
                borderRadius: "50%",
                width: "7rem",
                height: "35px",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: dynamicColor,
                backgroundColor: "white",
                fontSize: "1.5rem",
                border: `3px solid ${dynamicColor}`,
              }}
            >
              ✓
            </span>
          ),
        });
      } else {
        toast.error("An Error Occurred!");
      }
    } catch (error) {
      console.error(error);
      toast.error("An Error Occurred!");
    }
  };

  // RETAIN THE STATE
  useEffect(() => {
    const tabHandlers = {
      Documents: "Documents",
      Associations: "Associations",
      Meals: "Meals",
      Comms: "Comms",
      Clinical: "Clinical",
      Finance: "Finance",
      Daybook: "Daybook",
    };

    const activeTab = localStorage.getItem("activeTab");
    // @ts-ignore
    const handler = tabHandlers[activeTab];

    if (handler) {
      handleClick(handler);
    }
  }, []);

  const handleClick = (button: string) => {
    setstageTwoToGoalsModal(false);
    dispatch(resetselectedItemIndexAssociations(""));
    dispatch(resetselectedNames(""));
    dispatch(resetIsDocumentsDropdownOpen());
    dispatch(resetinputSearchVal(""));

    const defaultTabState = {
      Details: false,
      Associations: false,
      Clinical: false,
      Comms: false,
      Documents: false,
      Finance: false,
      Daybook: false,
      Meals: false,
    };
    if (!hasDeleteDocument) {
      const newTabState = { ...defaultTabState, [button]: true };
      localStorage.setItem("activeTab", button);
      setbuttonTab(newTabState);
    } else {
      setbuttonTab({ ...defaultTabState, Documents: true });
      localStorage.setItem("activeTab", "Documents");
      dispatch(setWarningPopup(true));
    }
    setbuttonTab((prevState) => ({
      ...prevState,
      Details: button === "Details",
      Associations: button === "Associations",
      Clinical: button === "Clinical",
      Comms: button === "Comms",
      Documents: button === "Documents",
      Finance: button === "Finance",
      Daybook: button === "Daybook",
    }));
    dispatch(resetIsAssessmentFormOpenProspects());
    dispatch(resetopenFormByClickProspects());
    dispatch(resetSelectedDropdownItemAssociations());
    dispatch(resetSelectedItemIndexFilterAssociations());
    dispatch(resetSelectedFilterItemsAssociations());
    dispatch(resetSelectedDropdownItemDocuments());
    dispatch(resetSelectedItemIndexFilterDocuments());
    dispatch(resetSelectedFilterItemsDocuments());

    if (button === "Documents") {
      dispatch(setIsAssessmentFormOpenProspects());
    }
  };

  const getCurrentDate = () => {
    const currentDate = new Date();
    return currentDate;
  };

  const handleDateChange = (date: Date | any) => {
    // dispatch(setunsavedChanges(true));
    if (!hasUnsavedChanges) {
      // setHasUnsavedChanges(true);
    }
    setSelectedDate(date);
  };

  const handleDateChangeForEFA = (date: Date | any) => {
    dispatch(setunsavedChanges(true));
    if (!hasUnsavedChanges) {
      setHasUnsavedChanges(true);
    }
    setselectedEFA(date);
  };

  const handleDateChangeForIED = (date: Date | any) => {
    dispatch(setunsavedChanges(true));
    if (!hasUnsavedChanges) {
      setHasUnsavedChanges(true);
    }
    setSelectedIED(date);
  };

  const handleACATDateChange = (date: Date | any) => {
    dispatch(setunsavedChanges(true));
    if (!hasUnsavedChanges) {
      setHasUnsavedChanges(true);
    }
    setSelectedACATDate(date);
  };

  const handleChangeForAddress = (index: number, e: any) => {
    dispatch(setunsavedChanges(true));
    if (!hasUnsavedChanges) {
      setHasUnsavedChanges(true);
    }
    const { value } = e.target;
    setDetails({ ...details, address: value });
    initService(value);
    if (value.length === 0) {
      setDetails({ ...details, address: "" });
      setPossibleAddresses([]);
    }
  };

  const handleChange = (
    index: number,
    e: React.ChangeEvent<HTMLInputElement> | any
  ) => {
    const { value } = e.target;
    const fieldName = Object.keys(details)[index];
    setDetails((prevState: any) => ({
      ...prevState,
      [fieldName]: value,
    }));

    dispatch(setunsavedChanges(true));
    if (!hasUnsavedChanges) {
      setHasUnsavedChanges(true);
    }
  };

  const [openACATDropdown, setopenACATDropdown] = useState(false);

  const handleOpenDropdown = () => {
    setopenACATDropdown(true);
  };
  const [updateProspects, setUpdateProspects] = useState<any>({
    first_name: "",
    middle_name: "",
    last_name: "",
    pref_name: "",
    email: "",
    mobile: "",
    dob: "",
    funding_type: "",
    package_level: "",
    package_number: "",
    funding_available: "",
    acat_assessment: "",
    initial_enquiry: "",
    role: 4,
    user_id: clientId,
    type: -1,
    phone: "",
    address: "",
  });

  useEffect(() => {
    if (businessType == "B2B" || isAdminActive) {
      setUpdateProspects({
        first_name: details?.businessName, //this
        last_name: "",
        email: details?.email, //this
        phone: details?.phone,
        address: details?.address,
        role: details?.role, //this
        user_id: clientId, //this
        type: dynamicProspectId, //this
      });
    } else {
      setUpdateProspects({
        first_name: details.givenName,
        middle_name: details.middleName,
        last_name: details.familyName,
        pref_name: details.knownAs,
        dob: selectedDate ? formatDateForAPI(selectedDate) : initialDate,
        mobile: details?.mobile,
        package_level: details.packageLevel,
        package_number: details.packageNumber,
        funding_available: formatDateForAPI(selectedEFA),
        acat_assessment: result,
        initial_enquiry: formatDateForAPI(selectedIED),
        email: details.email,
        funding_type: details.fundingType,
        role: details.role,
        user_id: clientId,
        type: dynamicProspectId,
        phone: details.phone,
        address: details.address,
      });
    }
  }, [clientId, details, selectedDate, selectedEFA, result, selectedIED]);

  useEffect(() => {
    if (buttonTab.Associations) {
      dispatch(getAssociationTypesThunk());
      dispatch(getAssociationRelationshipsThunk());
      dispatch(getUserTypesThunk());
    }
  }, [buttonTab]);

  useEffect(() => {
    if (clientId && buttonTab.Associations) {
      dispatch(
        getAssociations({ clientId, searchVal: "", type: "", pageNumber: 1 })
      );
      dispatch(getUniquePrimaryApproversThunk(clientId));
    }
  }, [clientId, buttonTab]);

  const [showProfile, setShowProfile] = useState<boolean>(false);
  const [showGoalsModal, setshowGoalsModal] = useState(false);
  const [stageTwoToGoalsModal, setstageTwoToGoalsModal] = useState(false);

  useEffect(() => {
    if (isFormOpen) {
      setbuttonTab({
        Details: false,
        Associations: false,
        Clinical: false,
        Comms: false,
        Documents: true,
        Finance: false,
        Daybook: false,
      });
    }
  }, [isFormOpen]);
  /// ASSOCIATION TABLE START //
  const dropdownRefAssociation = useRef<any>();
  const svgParentRefAssociation = useRef<any>();
  const [isDropdownOpenAssociation, setIsDropdownOpenAssociation] =
    useState(false);
  const {
    selectedFilterItemsAssociations,
    sortByAssociationsR,
    searchValAssociations,
  } = useAppSelector((state) => state?.associations);

  const { filterIconColor } = useAppSelector((state) => state?.common);
  const handleClickOutsideAssociation = (event: any) => {
    if (
      dropdownRefAssociation.current &&
      !dropdownRefAssociation.current.contains(event.target) &&
      svgParentRefAssociation.current &&
      !svgParentRefAssociation.current.contains(event.target)
    ) {
      setIsDropdownOpenAssociation(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutsideAssociation);
    return () => {
      document.removeEventListener("click", handleClickOutsideAssociation);
    };
  }, []);
  // const associationColumns = [
  //   { header: "Name", accessor: "user_name" },
  //   { header: "Category", accessor: "category" },
  //   { header: "Relationship", accessor: "relationship_name" },
  //   { header: "Contact Number", accessor: "number" },
  //   { header: "Associated Business/Site", accessor: "funding_type" },
  //   { header: "Action", accessor: "actionAssociation" },
  // ];
  const { getAssociationByUserId } = useAppSelector(
    (state) => state?.associations
  );
  /// ASSOCIATION TABLE END //
  // DOCUMENTS TABLE START //
  const dropdownRefDocuments = useRef<any>();
  const svgParentRefDocuments = useRef<any>();
  const [isDropdownOpenDocuments, setIsDropdownOpenDocuments] = useState(false);
  const { selectedFilterItemsDocuments, sortByDocumentsR, searchValDoc } =
    useAppSelector((state) => state?.document);
  const handleClickOutsideDocuments = (event: any) => {
    if (
      dropdownRefDocuments.current &&
      !dropdownRefDocuments.current.contains(event.target) &&
      svgParentRefDocuments.current &&
      !svgParentRefDocuments.current.contains(event.target)
    ) {
      setIsDropdownOpenDocuments(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutsideDocuments);
    return () => {
      document.removeEventListener("click", handleClickOutsideDocuments);
    };
  }, []);

  const getDocumentsData = useAppSelector(
    (state) => state?.document?.getDocuments
  );
  const updatedDoc = useAppSelector(
    (state) => state?.document?.updateDocumentData
  );
  const selectedOps = useAppSelector(
    (state) => state?.document?.selectedOptions
  );

  const [primaryApprover, setPrimaryApprover] = useState([]);
  const [ongoingApprover, setOngoingApprover] = useState([]);

  const [isFormOpenDoc, setIsFormOpenDoc] = useState(false);
  const [selectedObject, setSelectedObject] = useState<any>(null);
  const [showStateOfDocument, setShowStateOfDocument] = useState<any>("");
  const handleClickDocRow = (e: any, id: any, title: any) => {
    e.stopPropagation();
    if (isDropdownOpenDocuments) {
      setIsDropdownOpenDocuments(false);
    } else {
      const selectedDocument = getDocumentsData?.find((elem: any) => {
        return elem?.id === id;
      });
      setIsFormOpenDoc(true);
      dispatch(setIsFormOpenTrue());
      setSelectedObject(selectedDocument);
      setPrimaryApprover(selectedDocument?.primary_approvers);
      setOngoingApprover(selectedDocument?.ongoing_approvers);
      dispatch(setselectedDataObject(selectedDocument));
      setUploadedFiles(selectedDocument?.files);
      setShowStateOfDocument(title);
    }
  };
  const handleOverlayClickUpdate = async () => {
    if (!selectedObject?.isThereAnyChanges) {
      setIsFormOpenDoc(false);
      return;
    }

    try {
      dispatch(
        updateDocumentThunk({
          title: updatedDoc?.title,
          summary: updatedDoc.summary,
          primary_review: updatedDoc?.primary_review,
          ongoing_review: updatedDoc.ongoing_review,
          period_picker: updatedDoc.period_picker,
          start: updatedDoc.start,
          end: updatedDoc.end,
          primary_approver_id: primaryApprover.map((res: any) => res?.id),
          ongoing_approver_id: ongoingApprover.map((res: any) => res?.id),
          hard_copy_completed_date: updatedDoc.hard_copy_completed_date,
          document_files: updatedDoc.document_files,
          user_id: updatedDoc.user_id,
          document_id: updatedDoc?.document_id,
          tag: updatedDoc?.tagArray,
          note_details: updatedDoc?.note_details || {},

        })
      )
        .then(() => {
          toast.success("Document updated successfully!", {
            style: {},
            progressStyle: { backgroundColor: dynamicColor },
            icon: (
              <span
                style={{
                  marginRight: "8px",
                  borderRadius: "50%",
                  width: "7rem",
                  height: "35px",
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: dynamicColor,
                  backgroundColor: "white",
                  fontSize: "1.5rem",
                  border: `3px solid ${dynamicColor}`,
                }}
              >
                ✓
              </span>
            ),
          });
          dispatch(getUniquePrimaryApproversThunk(clientId));
          dispatch(getDocuments({ clientId, providerId }));
          // dispatch(
          //   getDocumentsByFilter({
          //     clientId,
          //     filterValue: selectedOps?.join(","),
          //     searchVal: "",
          //     sortType: "",
          //     filterColumn: "",
          //     sortColumn: "",
          //     providerId,
          //   })
          // );
        })
        .catch(() =>
          toast.error("Failed To Update Document. Please Try Again!")
        );
    } catch (err) {}

    setchangesToForm("");
    setIsFormOpenDoc(false);
    dispatch(getUniquePrimaryApproversThunk(clientId));
  };
  const [changesToForm, setchangesToForm] = useState("");

  const handleChangesToForm = (val: any) => {
    setchangesToForm(val);
  };
  // DOCUMENTS TABLE END //
  const [prospectTypeArr, setProspectTypeArr] = useState<any>([]);

  useEffect(() => {
    dispatch(
      getUserRolesProspectsThunk({
        dynamicProspectsId: dynamicProspectId,
      })
    );
  }, []);

  useEffect(() => {
    if (domainDetail && domainDetail?.prospect_type) {
      let splitTheProspectTypeString = domainDetail?.prospect_type.split(",");
      setProspectTypeArr(splitTheProspectTypeString);
    }
  }, [domainDetail]);

  // // console.log("prospectTypeArr===================================>", userRoles);
  const prospectsStatusDetailsNums = useAppSelector(
    (state) => state.common.domainDetail.prospect_status
  );

  useEffect(() => {
    dispatch(getStatusDetailsThunk(prospectsStatusDetailsNums));
  }, [prospectsStatusDetailsNums]);

  const openWarningSection = useAppSelector(
    (state) => state?.documentClient?.openWarningSection
  );

  const prospectsStatusDetailsArr = useAppSelector(
    (state) => state.common.statusDetailsData?.data
  );
  return (
    <div>
      {loading && <Spinner position={"absolute"} top={"50%"} left={"50%"} />}
      <div
        style={{
          position: "relative",
        }}
      >
        {stageTwoToGoalsModal && (
          <CommonQuickMenu
            setshowGoalsModal={setshowGoalsModal}
            showGoalsModal={showGoalsModal}
            setstageTwoToGoalsModal={setstageTwoToGoalsModal}
            type="prospects"
            filterItems={true}
          />
        )}
        <CommonUpperBox
          imageSection
          type={"screen"}
          currentUser={currentProspects}
          imageURL={currentProspects?.profile_image}
          title={`${currentProspects?.first_name || ""} ${
            currentProspects?.last_name || ""
          }`}
          onClickUserImage={() => setShowProfile(true)}
          onClickPlus={() => {
            setstageTwoToGoalsModal(true);
          }}
        />
      </div>

      {showProfile && (
        <ProfilePictureModal
          showProfile={showProfile}
          className="profile_picture_external"
          setShowProfile={setShowProfile}
          currentClient={currentProspects}
        />
      )}

      {buttonTab.Details && (
        <>
          <CommonHeader
            currentProspects={currentProspects}
            mainType="prospects"
            type="details"
            handleClick={handleClick}
          />

          <div className="main-box">
            {hasUnsavedChanges && (
              <>
                <div
                  className="save"
                  style={{
                    position: "absolute",
                    marginRight: "200px",
                    bottom: "10px",
                  }}
                >
                  <p>Would you like to save your changes?</p>
                  <div className="btnDivision">
                    <button
                      style={{
                        backgroundColor: dynamicColor,
                      }}
                      onClick={handleSaveChanges}
                    >
                      Save
                    </button>
                    <button
                      style={{
                        fontFamily: "Helvetica",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "18px",
                        backgroundColor: "#F9F9F9",
                        border: "none",
                        color: "#9296A2",
                      }}
                      onClick={() => {
                        dispatch(
                          getProspectsByIdThunk({ dynamicProspectId, clientId })
                        );
                        setHasUnsavedChanges(false);
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </>
            )}
            {showUnsavedModal && (
              <div>
                <div className="showunsave-modal-navigate">
                  <span className="span1">
                    You have unsaved changes on this page.
                  </span>
                  <span className="span2">
                    Do you wish to move on and lose these changes?
                  </span>
                  <div className="btnDiv-modal">
                    <button
                      className="button1"
                      onClick={handleMoveWithoutSaving}
                    >
                      Move on without saving
                    </button>
                    <button className="button2" onClick={handleMoveWithSave}>
                      Save and move on
                    </button>
                  </div>
                </div>
              </div>
            )}

            <div>
              <div className=" client-screen-personal-details-heading">
                <span className=" client-screen-sub-heading-title">
                  Personal Details
                </span>
                <span className="client-screen-line"></span>
              </div>
              <div className="main-box-body">
                {[
                  ...(businessType == "B2B" || isAdminActive
                    ? dummyArrBusiness
                    : dummyArrProspects),
                ].map((elem: any, index: any) => {
                  const fieldName = Object.keys(details)[index];
                  let searchString = "Business";
                  let replacementString = "Prospect";
                  let titleName = elem?.UpperTitle.replace(
                    searchString,
                    replacementString
                  );

                  return (
                    <div
                      className={`content-wrapper ${
                        index === 8 && "prospect_date_filter"
                      }`}
                      key={index}
                      style={{
                        transform: translateYIndices.includes(index)
                          ? "translateY(130%)"
                          : "none",
                        visibility:
                          index === 9 || index === 10 || index === 11
                            ? "hidden"
                            : "visible",
                      }}
                    >
                      <div className="wrapper-title">
                        {titleName ? titleName : ""}
                      </div>

                      {index === 8 ? (
                        <>
                          <CustomDatePicker
                            newDate={selectedDate}
                            maxDate={getCurrentDate()}
                            // currentClient={currentProspects}
                            setchanges={setHasUnsavedChanges}
                            handleChange={handleDateChange}
                          />
                        </>
                      ) : index === 12 ? (
                        <select
                          className="wrapper-info wrapper-info-select-dropdown"
                          onChange={(e) => handleChange(index, e)}
                          value={details[fieldName]}
                        >
                          <option value="">Please Select...</option>

                          <option value="Aged Care">Aged Care</option>
                          <option value="Disability">Disability</option>
                          <option value="Veterans">Veterans</option>
                          <option value="Private">Private</option>
                          <option value="Insurance">Insurance</option>
                          <option value="Other">Other</option>
                        </select>
                      ) : index === 15 ? (
                        <>
                          <CustomDatePicker
                            newDate={selectedEFA}
                            handleChange={handleDateChangeForEFA}
                            // minDate={getCurrentDate()}
                          />
                          {/* <ReactDatePicker
                            onChange={handleDateChangeForEFA}
                            selected={selectedEFA}
                            startDate={selectedEFA}
                            placeholderText="Select Date"
                            className="date-picker-details-tab-of-client-screen date_picker_prospects_efa"
                            showYearDropdown
                            showMonthDropdown
                            dropdownMode="select"
                            dateFormat="EEE, dd MMMM yyyy"
                          /> */}
                        </>
                      ) : index === 16 ? (
                        <>
                          <div id="date-picker-prospects">
                            {openACATDropdown && (
                              <div
                                id="acat_wrapper"
                                style={{
                                  marginTop: "17px",
                                }}
                                ref={pickerRef}
                              >
                                <div id="acat_option">
                                  {["Booked", "Completed"]?.map(
                                    (option: any) => (
                                      <div
                                        key={option}
                                        className={`each_acat_option   
                                      ${
                                        selectedInterval.toLowerCase() ===
                                        option.toLowerCase()
                                          ? "selected_option"
                                          : ""
                                      }
                                  `}
                                        onClick={() =>
                                          handleIntervalChange(
                                            option.toLowerCase()
                                          )
                                        }
                                      >
                                        {option}
                                      </div>
                                    )
                                  )}
                                </div>
                              </div>
                            )}

                            <ReactDatePicker
                              placeholderText="ACAT Assessment"
                              selected={selectedACATDate}
                              ref={datepickerRef}
                              onChange={handleACATDateChange}
                              onFocus={handleOpenDropdown}
                              className="date-picker-acat"
                              showYearDropdown
                              showMonthDropdown
                              dropdownMode="select"
                              shouldCloseOnSelect={false}
                              open={openACATDropdown}
                              value={
                                selectedACATDate
                                  ? `${result}`
                                  : currentProspects
                                  ? currentProspects?.acat_assessment
                                  : "ACAT Assessment"
                              }
                            />
                          </div>
                        </>
                      ) : index === 17 ? (
                        <>
                          <CustomDatePicker
                            newDate={selectedIED}
                            // maxDate={getCurrentDate()}
                            handleChange={handleDateChangeForIED}
                          />
                          {/* <ReactDatePicker
                            onChange={handleDateChangeForIED}
                            selected={selectedIED}
                            startDate={selectedIED}
                            placeholderText="Select Date"
                            className="date-picker-details-tab-of-client-screen date_picker_prospects_efa"
                            showYearDropdown
                            showMonthDropdown
                            dropdownMode="select"
                            dateFormat="EEE, dd MMMM yyyy"
                          /> */}
                        </>
                      ) : index === 13 ? (
                        <select
                          className="wrapper-info wrapper-info-select-dropdown"
                          onChange={(e) => handleChange(index, e)}
                          value={details[fieldName]}
                        >
                          <option value="">Please Select...</option>
                          {[
                            "NDIS - Self Managed",
                            "NDIS - Plan Managed",
                            "NDIS - NDIA Managed",
                            "DSOA",
                            "HCP Level 1",
                            "HCP Level 2",
                            "HCP Level 3",
                            "HCP Level 4",
                            "HACC",
                            "CHSP",
                            "DVA",
                            "DVA Nursing",
                            "TAC",
                            "Brokerage",
                            "Private Fee for Service",
                          ].map((elem, index) => (
                            <option key={index} value={elem}>
                              {elem}
                            </option>
                          ))}
                        </select>
                      ) : index === 18 ? (
                        <>
                          <select
                            className="wrapper-info wrapper-info-select-dropdown"
                            onChange={(e) => handleChange(index, e)}
                            value={details[fieldName]}
                          >
                            <option>Please Select...</option>

                            {userRoles?.map(
                              (elem: any, index: number | any) => {
                                let isExirOnProspectArray: any =
                                  prospectTypeArr.includes(`${elem?.desc}`);
                                if (isExirOnProspectArray) {
                                  return (
                                    <option key={index} value={elem?.id}>
                                      {elem?.desc}
                                    </option>
                                  );
                                }
                              }
                            )}
                          </select>
                        </>
                      ) : index === 4 ? (
                        <>
                          {businessType == "B2B" || isAdminActive ? (
                            <select
                              className="wrapper-info wrapper-info-select-dropdown"
                              onChange={(e) => handleChange(index, e)}
                              value={details[fieldName]}
                              style={{
                                border: "none",
                                outline: "none",
                                height: "fit-content",
                                backgroundColor: "white",
                              }}
                            >
                              <option>Please Select...</option>
                              {userRoles?.map(
                                (elem: any, index: number | any) => {
                                  let isExist: any = prospectTypeArr.includes(
                                    `${elem?.desc}`
                                  );
                                  if (isExist) {
                                    return (
                                      <option key={index} value={elem?.id}>
                                        {elem.desc}
                                      </option>
                                    );
                                  }
                                }
                              )}
                            </select>
                          ) : (
                            <div>
                              <input
                                type="text"
                                onChange={(e) =>
                                  handleChangeForAddress(index, e)
                                }
                                className="wrapper-info"
                                placeholder="Enter an address"
                                value={details[fieldName]}
                              />
                              <div className="address_listing">
                                <ul>
                                  {possibleAddresses.length > 0
                                    ? possibleAddresses.map(
                                        (address: any, ind: any) => {
                                          return (
                                            <li
                                              onClick={selectAddress}
                                              className="address_listing_item"
                                            >
                                              {address.description}
                                            </li>
                                          );
                                        }
                                      )
                                    : null}
                                </ul>
                              </div>
                            </div>
                          )}
                        </>
                      ) : index === 14 ? (
                        <input
                          type="text"
                          placeholder="Enter Package Number"
                          value={details[fieldName]}
                          onChange={(e) => handleChange(index, e)}
                        />
                      ) : (
                        <>
                          <input
                            className="wrapper-info"
                            onChange={(e) => handleChange(index, e)}
                            value={details[fieldName]}
                            placeholder={elem?.placeHolder?.replace(
                              "Business",
                              "Prospects"
                            )}
                            style={{
                              border: "none",
                              outline: "none",
                              height: "fit-content",
                            }}
                          />
                        </>
                      )}

                      <div className="wrapper-line"></div>
                    </div>
                  );
                })}
              </div>
              {/* {businessType === "B2C" && ( */}
              <div
                className={`${
                  businessType === "B2B" || isAdminActive ? "fade_out" : ""
                } prospects-second-div`}
              >
                <div
                  className="client-screen-personal-details-heading"
                  style={{
                    transform: "translateY(-135px)",
                  }}
                >
                  <span className="client-screen-sub-heading-title">
                    Profile Details
                  </span>
                  <span className="client-screen-line"></span>
                </div>
              </div>
              {/* )} */}
            </div>
          </div>

          {/* <Rectangle type="stickyHub" /> */}
          <CircularNoteTrigger type="stickyHub" />
        </>
      )}

      {buttonTab.Documents && (
        <>
          <CommonHeader
            currentProspects={currentProspects}
            mainType="prospects"
            type="documents"
            handleClick={handleClick}
          />
          {/* <UnderWork /> */}
          {/* <CommonTable type="documents" /> */}
          <Table
            dynamicHeaderColor={domainDetail?.doctbl_color}
            thData={documentsColumns}
            classNameTr="client-documents-tab-table-head"
            classNameTable="documents-table-additional-styles"
            type="documents"
            dropdownRef={dropdownRefDocuments}
            isDropdownOpen={isDropdownOpenDocuments}
            onClickSvg={() => setIsDropdownOpenDocuments(true)}
            svgParentRef={svgParentRefDocuments}
            allClients={getDocumentsData}
            // onClickRow={handleClickDocRow.bind(this, "onRow")}
            onClickRow={handleClickDocRow}
            classNameRow="client-documents-tab-table-body"
            fill={
              selectedFilterItemsDocuments.length > 0 ||
              searchValDoc.length > 0 ||
              sortByDocumentsR.length > 0
                ? filterIconColor
                : "#D8D3D3"
            }
          />
          {showUnsavedModal && (
            <div>
              <div className="showunsave-modal-navigate">
                <span className="span1">
                  You have unsaved changes on this page.
                </span>
                <span className="span2">
                  Do you wish to move on and lose these changes?
                </span>
                <div className="btnDiv-modal">
                  <button className="button1" onClick={handleMoveWithoutSaving}>
                    Move on without saving
                  </button>
                  <button className="button2" onClick={handleMoveWithSave}>
                    Save and move on
                  </button>
                </div>
              </div>
            </div>
          )}
          {/* <Rectangle type="documents" /> */}
          <CircularNoteTrigger type="documents" />
          {warningPopup && (
            <>
              <ConfirmationModal type="" />
            </>
          )}
          {hasDeleteDocument && (
            <>
              <UnsavedChangesPopup type="documents" />
            </>
          )}
          {isFormOpen && openWarningSection && (
            <ConfirmationModal
              type="documents"
              handleMoveWithoutSaving={() => {
                dispatch(setWarningMessage(false));
              }}
              handleMoveWithSave={() => {
                dispatch(setWarningMessage(false));
                dispatch(resetopenFormByClickProspects());
                // dispatch(setopenClientAssessmentForm(false));
              }}
            />
          )}

          {isFormOpen && (
            <div className="client-assessment-form-div">
              {isFormOpen && (
                <Modal
                  isOpen={true}
                  onClose={() => {}}
                  closeOnOverlayClick={true}
                  isCentered
                >
                  <ModalOverlay onClick={() => {}} zIndex={10} />

                  <ClientAssessmentForm
                    type="new-form"
                    // openForm={openForm}
                    // setopenForm={setopenForm}
                    // title={typeCheckForAssessmentform}
                    handleGetDetails={handleGetDetails}
                    uploadedFiles={uploadedFiles}
                    setUploadedFiles={setUploadedFiles}
                    // changesMadeToTheForm={changesMadeToTheForm}
                    // setChangesMadeToTheForm={setChangesMadeToTheForm}
                    handleSaveClick={handleOverlayClick}
                  />
                </Modal>
              )}
            </div>
          )}
          {isFormOpenDoc && (
            <Modal
              isOpen={true}
              onClose={() => {}}
              closeOnOverlayClick={true}
              isCentered
            >
              <ModalOverlay onClick={() => {}} zIndex={10} />
              <ClientAssessmentForm
                type="new-form"
                handleChangesToForm={handleChangesToForm}
                dataObject={selectedObject}
                setIsFormOpenDoc={setIsFormOpenDoc}
                handleOverlayClickUpdateProspects={handleOverlayClickUpdate}
                setDataObject={setSelectedObject}
                handleGetDetails={() => {}}
                showStateOfDocument={showStateOfDocument}
                uploadedFiles={uploadedFiles}
                setUploadedFiles={setUploadedFiles}
                primaryApprover={primaryApprover}
                setPrimaryApprover={setPrimaryApprover}
                ongoingApprover={ongoingApprover}
                setOngoingApprover={setOngoingApprover}
                handleSaveClick={handleOverlayClickUpdate}
              />
            </Modal>
          )}
          {/* <Rectangle type="stickyHub" /> */}
          {/* <CircularNoteTrigger type="stickyHub" /> */}
        </>
      )}

      {buttonTab.Associations && (
        <>
          <CommonHeader
            currentProspects={currentProspects}
            mainType="prospects"
            type="associations"
            handleClick={handleClick}
          />
          {/* <UnderWork /> */}
          {/* <CommonTable type="associations" /> */}
          <Table
            dynamicHeaderColor={domainDetail?.assoctbl_color}
            type="asociations"
            classNameTable="common-table-container-associations"
            thData={associationColumns}
            classNameTr="client-associations-tab-table-head"
            dropdownRef={dropdownRefAssociation}
            onClickSvg={() => setIsDropdownOpenAssociation(true)}
            isDropdownOpen={isDropdownOpenAssociation}
            svgParentRef={svgParentRefAssociation}
            allClients={getAssociationByUserId}
            classNameRow="client-associations-tab-table-body"
            fill={
              selectedFilterItemsAssociations.length > 0 ||
              searchValAssociations.length > 0 ||
              sortByAssociationsR.length > 0
                ? filterIconColor
                : "#D8D3D3"
            }
          />
          <div>
            {showUnsavedModal && (
              <div>
                <div className="showunsave-modal-navigate">
                  <span className="span1">
                    You have unsaved changes on this page.
                  </span>
                  <span className="span2">
                    Do you wish to move on and lose these changes?
                  </span>
                  <div className="btnDiv-modal">
                    <button
                      className="button1"
                      onClick={handleMoveWithoutSaving}
                    >
                      Move on without saving
                    </button>
                    <button className="button2" onClick={handleMoveWithSave}>
                      Save and move on
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
          {warningPopupAssociation && (
            <>
              <ConfirmationModal type="" />
            </>
          )}
          {hasDeleteAssociation && (
            <>
              <UnsavedChangesPopup type="associations" />
            </>
          )}
          <CircularNoteTrigger type="associations" title="Association" />
          {/* <Rectangle type="associations" /> */}
        </>
      )}

      {buttonTab.Comms && (
        <>
          <CommonHeader
            currentProspects={currentProspects}
            mainType="prospects"
            type="comms"
            handleClick={handleClick}
          />
          {/* <div className="comms_client_section_wrapper">
            <Comms type="prospects" />
          </div> */}

          {/* <UnderWork /> */}
          <div style={{ marginTop: "10px" }}>
            <Comms isUserType={true} />
          </div>
          {/* <Rectangle type="stickyHub" /> */}
          {/* <CircularNoteTrigger type="stickyHub" /> */}
          <div>
            {showUnsavedModal && (
              <div>
                <div className="showunsave-modal-navigate">
                  <span className="span1">
                    You have unsaved changes on this page.
                  </span>
                  <span className="span2">
                    Do you wish to move on and lose these changes?
                  </span>
                  <div className="btnDiv-modal">
                    <button
                      className="button1"
                      onClick={handleMoveWithoutSaving}
                    >
                      Move on without saving
                    </button>
                    <button className="button2" onClick={handleMoveWithSave}>
                      Save and move on
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      )}

      {buttonTab.Daybook && (
        <>
          <CommonHeader
            currentProspects={currentProspects}
            mainType="prospects"
            type="daybook"
            handleClick={handleClick}
          />
          <Daybook type="prospects" />
          {/* <UnderWork /> */}
          <div>
            {showUnsavedModal && (
              <div>
                <div className="showunsave-modal-navigate">
                  <span className="span1">
                    You have unsaved changes on this page.
                  </span>
                  <span className="span2">
                    Do you wish to move on and lose these changes?
                  </span>
                  <div className="btnDiv-modal">
                    <button
                      className="button1"
                      onClick={handleMoveWithoutSaving}
                    >
                      Move on without saving
                    </button>
                    <button className="button2" onClick={handleMoveWithSave}>
                      Save and move on
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div>
            {showUnsavedModal && (
              <div>
                <div className="showunsave-modal-navigate">
                  <span className="span1">
                    You have unsaved changes on this page.
                  </span>
                  <span className="span2">
                    Do you wish to move on and lose these changes?
                  </span>
                  <div className="btnDiv-modal">
                    <button
                      className="button1"
                      onClick={handleMoveWithoutSaving}
                    >
                      Move on without saving
                    </button>
                    <button className="button2" onClick={handleMoveWithSave}>
                      Save and move on
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      )}

      {/* <Rectangle type="stickyHub" /> */}
    </div>
  );
};

export default ProspectsScreen;
