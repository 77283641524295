import React from "react";

const ItalicIconSVG = () => {
  return (
    <svg
      width="5"
      height="12"
      viewBox="0 0 5 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.63458 3.19913H4.18603L2.47943 11.197H0.927979L2.63458 3.19913ZM3.9378 0C3.78438 0 3.6344 0.0469065 3.50683 0.134788C3.37926 0.222669 3.27983 0.347578 3.22112 0.49372C3.16241 0.639861 3.14705 0.800671 3.17698 0.955813C3.20691 1.11096 3.28079 1.25346 3.38928 1.36532C3.49777 1.47717 3.63599 1.55334 3.78646 1.5842C3.93694 1.61506 4.09291 1.59922 4.23466 1.53869C4.3764 1.47815 4.49756 1.37564 4.58279 1.24412C4.66803 1.1126 4.71353 0.957966 4.71353 0.799783C4.71353 0.587668 4.6318 0.38424 4.48632 0.234251C4.34085 0.0842628 4.14354 0 3.9378 0Z"
        fill="black"
      />
    </svg>
  );
};

export default ItalicIconSVG;
