import { useState } from "react";
import { NormalCapsule } from "./Capsule";
import { formatTimeClientStatus } from "../../../../Model/utils/Helper/formatDate";
import { GreenCircleSvg } from "../../../pages/People/ClientPage/Components/UniqueClientScreen/Components/ClinicalTab/Data/SVGs";
import TrafficLights from "../../CommonModal/Components/TrafficLights";
import PurplePlusSVG from "../Data/PurplePlusSVG";
import { toast } from "react-toastify";
import { ReplyPlusSvg } from "../../../../Model/Assets/SVG/Common/ReplySvg";
import TildeSVG from "../Data/TildeSVG";

const InnerNote = ({
  eventClassName,
  title,
  summary,
  tags,
  time,
  services,
  forSticky,
  isForStatic,
  onChangeTextNote,
  maxHeight,
  clinicalGoalsNote,
  trafficColorsStatus,
  onChangeTrafficLight = () => {},
  setshowTrafficLightsActivities = () => {},
  showTrafficLightsActivities,
  activity,
  isLoggedInUserSame,
  fromAdvancedClinicalNote,
}: any) => {
  const [trafficColors, setTrafficColors] = useState<any>(trafficColorsStatus);
  const [showLights, setShowLights] = useState<boolean>(false);

  return (
    <>
      <div
        className="inner_note_container"
        style={{
          cursor: isLoggedInUserSame ? "pointer" : "default",
        }}
      >
        <div className="pink_wrapper">
          <div
            onClick={(e) => e.stopPropagation()}
            className="inner_note_traffic_light_container"
          >
            <span>
              <GreenCircleSvg
                onClick={() => {
                  if (true) {
                    const updatedActivities = showTrafficLightsActivities?.map(
                      (elem: { id: any; status: any }) => {
                        if (elem?.id === activity?.id) {
                          return { ...elem, status: !elem.status };
                        } else {
                          return { ...elem, status: false };
                        }
                      }
                    );
                    setshowTrafficLightsActivities(updatedActivities);
                  } else {
                    toast.warn("You don't have the right permissions.");
                  }
                }}
                color={trafficColors}
              />
            </span>
            {showTrafficLightsActivities?.find(
              (el: any) => el?.id == activity?.id
            )?.status && (
              <TrafficLights
                setshowTrafficLightsActivities={setshowTrafficLightsActivities}
                showTrafficLightsActivities={showTrafficLightsActivities}
                className={"client_taffic_light"}
                type="goals"
                setShowLights={setShowLights}
                handleTrafficColors={(data: any) => {
                  setTrafficColors(data);
                  onChangeTrafficLight(data);
                  setShowLights(false);
                  const updatedActivities = showTrafficLightsActivities?.map(
                    (elem: { id: any; status: any }) => {
                      if (elem?.id === activity?.id) {
                        return { ...elem, status: false };
                      } else {
                        return { ...elem, status: false };
                      }
                    }
                  );
                  setshowTrafficLightsActivities(updatedActivities);
                }}
              />
            )}
          </div>
        </div>

        <div className="text_wrapper_section_inner_note">
          <div className="inner_note_flex">
            <div className="clinical_flex">
              {fromAdvancedClinicalNote ? (
                // <ReplyPlusSvg
                // color={"#9150de"}
                // />
                <TildeSVG color={"#9150de"} />
              ) : (
                <PurplePlusSVG />
              )}
              <span className={`${eventClassName} inner_event`}>
                {isForStatic ? "Notes" : " ACTIVITIES"}
              </span>
            </div>
            <h4
              className={`${
                forSticky ? "inner_note_title_sticky" : "inner_note_title"
              } `}
            >
              {title}
            </h4>

            <p
              className={`${
                forSticky ? "inner__note_time_sticky" : "inner__note_time"
              } `}
            >
              {time ? formatTimeClientStatus(time) : ""}
            </p>
          </div>
          {tags?.length > 0 && (
            <div className="clinical_capsule_inner_flex">
              <>
                {tags?.map((elem: any, index: number) => (
                  <NormalCapsule
                    key={index}
                    title={`${elem?.first_name} ${elem?.last_name}`}
                    className={`${
                      forSticky && "normal_text_sticky"
                    } inner_note_capsule`}
                  />
                ))}
              </>
            </div>
          )}
          {services?.length > 0 && (
            <div className="clinical_capsule_inner_flex">
              <>
                {services?.map((elem: any, index: number) => (
                  <NormalCapsule
                    key={index}
                    title={elem?.name}
                    className={`${
                      forSticky && "normal_text_sticky"
                    } inner_note_capsule`}
                  />
                ))}
              </>
            </div>
          )}
          <div className="inner_note_input_container">
            {forSticky ? (
              <h6 className="inner_note_input_sticky">{summary}</h6>
            ) : (
              <textarea
                className={`${
                  forSticky ? "inner_note_input_sticky" : "inner_note_input"
                }   `}
                placeholder={`${
                  summary ? summary : "Enter Activity Summary..."
                } `}
                value={clinicalGoalsNote || ""}
                onChange={(e) => {
                  if (isForStatic && onChangeTextNote) {
                    onChangeTextNote(e);
                  }
                }}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default InnerNote;
