import { useEffect, useState } from "react";

import { api } from "../../../../Controller/services/api";
import { toast } from "react-toastify";
import {
  useAppSelector,
  useAppDispatch,
} from "../../../../Controller/redux/Hooks";

import {
  getTeamsByFilterThunk,
  getTeamsThunk,
  resetTeamsData,
} from "../../../../Controller/redux/features/TeamsFeature/teamsSlice";
import {
  getBusinessByFilterThunk,
  getBusinessThunk,
  resetBusinessData,
} from "../../../../Controller/redux/features/BusinessFeature/businessSlice";
import {
  getLocationUserByFilterThunk,
  getLocationUserThunk,
  getUserRolesLocationThunk,
  resetLocationData,
} from "../../../../Controller/redux/features/LocationFeature/locationSlice";
import {
  setProfileTypeSticky,
  setShowStickyNote,
} from "../../../../Controller/redux/features/CommonFeature/commonSlice";
import { useLocation, useParams } from "react-router-dom";
import {
  getProviderBusinessFilterThunk,
  resetBusinessProviderData,
} from "../../../../Controller/redux/features/ProviderFeature/providerBusinesSlice";
import {getProviderLocationsByFilterThunk, resetLocationProviderData} from "../../../../Controller/redux/features/ProviderFeature/providerLocationSlice";
import {getProviderTeamsByFilterThunk, resetTeamsProviderData} from "../../../../Controller/redux/features/ProviderFeature/providerTeamsSlice";

interface BusinessAndTeamFormProps {
  type: any;
  currentTitle?: string;
  activeTabProfile?: string;
  // setShowStickyNote: any;
  comp?: any;
}

const BusinessAndTeamForm: React.FC<BusinessAndTeamFormProps> = ({
  type,
  currentTitle = "Profiles",
  activeTabProfile,
  comp,
  // setShowStickyNote,
}) => {
  const [BusinessAndTeamData, setBusinessAndTeamData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    role: "",
    type: "",
  });
  const dispatch = useAppDispatch();

  const id = useParams()?.id;

  const [providerId, setProviderId] = useState<any>("");
  useEffect(() => {
    if (id) {
      let realId = window.atob(id);
      setProviderId(realId);
    }
  }, [id]);

  const { LocationPage } = useAppSelector((state) => state.location);
  const { TeamsPage } = useAppSelector((state) => state.teams);
  const { BusinessPage } = useAppSelector((state) => state.business);
  const dynamicColor = useAppSelector((state) => state?.common?.dynamicColor);

  // @ts-ignore
  const dynamicBusinessId = parseInt(localStorage.getItem("dynamicBusinessId"));
  // @ts-ignore
  const dynamicTeamsId = parseInt(localStorage.getItem("dynamicTeamsId"));

  const dynamicLocationsId = parseInt(
    // @ts-ignore
    localStorage.getItem("dynamicLocationId")
  );

  const { domainDetail } = useAppSelector((state) => state?.common);

  const [userType, setUserType] = useState<any>([]);
  useEffect(() => {
    if (domainDetail && domainDetail?.location_type) {
      let spliteTheTypeString =
        type === business_label
          ? domainDetail?.business_type?.split(",")
          : type === locations_label
          ? domainDetail?.location_type?.split(",")
          : domainDetail?.location_type?.split(",");
      setUserType(spliteTheTypeString);
    }
  }, [domainDetail, type]);

  const pathname = useLocation()?.pathname;
  const {
    prospects_label,
    clients_label,
    business_label,
    locations_label,
    teams_label,
    contacts_label,
    staff_label,
  } = useAppSelector((state) => state?.common?.domainDetail);
  const { getProfileTypeSticky } = useAppSelector((state) => state?.common);
  const currLocation =
    type === clients_label
      ? domainDetail?.client_type?.split(",")
      : type === prospects_label
      ? domainDetail?.prospect_type?.split(",")
      : type === staff_label
      ? domainDetail?.staff_type?.split(",")
      : type === contacts_label
      ? domainDetail?.contact_type?.split(",")
      : type === locations_label
      ? domainDetail?.location_type?.split(",")
      : type === business_label
      ? domainDetail?.business_type?.split(",")
      : "";

  // @ts-ignore
  const dynamicLocationId = parseInt(localStorage.getItem("dynamicLocationId"));
  const { locationRoles } = useAppSelector((state) => state?.location);
  const currentLocationRoles = locationRoles?.data;

  useEffect(() => {
    dispatch(getUserRolesLocationThunk(dynamicLocationId));
  }, [dynamicLocationId]);

  let isPendingTeams = false;
  let isPendingLocation = false;
  let isPendingBusiness = false;


  function lastCharecterEmitter(label: string) {
    let nameArr = label.split("");
    let resolved = nameArr.filter(
      (e: any, index: number) => index !== nameArr.length - 1
    );
    let res = resolved.join("");
    return res;
  }

  const handleFormBusinessAndTeams = async (e: any) => {
    e.preventDefault();
    const url = "/users/create_user";
    let modifiedData;

    switch (type) {
      case business_label:
        modifiedData = { ...BusinessAndTeamData, type: dynamicBusinessId };
        break;
      case teams_label:
        modifiedData = { ...BusinessAndTeamData, type: dynamicTeamsId };
        break;
      case locations_label:
        modifiedData = { ...BusinessAndTeamData, type: dynamicLocationsId };
        break;
    }
    if (type === teams_label) {
      if (BusinessAndTeamData?.first_name !== "") {
        if (isPendingTeams) return;
        try {
          isPendingTeams = true;
          const response = await api.post(url, {
            ...modifiedData,
            tenant_id: providerId,
          });
          if (response.data.status === "success") {
            isPendingTeams = false;
            if (type === teams_label) {
              toast.success(
                `${lastCharecterEmitter(teams_label)} added successfully! `,
                {
                  style: {},
                  progressStyle: { backgroundColor: dynamicColor },
                  icon: (
                    <span
                      style={{
                        marginRight: "8px",
                        borderRadius: "50%",
                        width: "7rem",
                        height: "35px",
                        textAlign: "center",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: dynamicColor,
                        backgroundColor: "white",
                        fontSize: "1.5rem",
                        border: `3px solid ${dynamicColor}`,
                      }}
                    >
                      ✓
                    </span>
                  ),
                }
              );
            }
          }
          if (type === teams_label) {
            // dispatch(getTeamsThunk(dynamicTeamsId));
            if (pathname.includes("providers") && providerId) {
              dispatch(resetTeamsProviderData());
              dispatch(
                getProviderTeamsByFilterThunk({
                  filterColumn: "",
                  filterValue: "",
                  searchVal: "",
                  sortColumn: "",
                  sortType: "",
                  TeamsPage,
                  status: "",
                  dynamicProviderId: providerId,
                  dynamicUserTypeId: dynamicTeamsId,
                })
              );
            } else {
              dispatch(resetTeamsData());
              dispatch(
                getTeamsByFilterThunk({
                  filterColumn: "",
                  filterValue: "",
                  searchVal: "",
                  sortColumn: "",
                  sortType: "",
                  TeamsPage,
                  dynamicId: dynamicTeamsId,
                  status: "",
                })
              );
            }
          }
          const stickyNoteElement = document.querySelector(
            ".new_sticky_note_wrapper"
          );
          if (stickyNoteElement) {
            stickyNoteElement.classList.add("fade_out");
            setTimeout(() => {
              dispatch(setShowStickyNote(false));
            }, 300);
          }
        } catch (error: any) {
          toast.error(error.message);
        }
      } else {
        toast.error("All Fields Required!");
      }
    }
    if (type === locations_label) {
      if (BusinessAndTeamData?.first_name !== "") {
        if (isPendingLocation) return;
        try {
          isPendingLocation = true;
          const response = await api.post(url, {
            ...modifiedData,
            tenant_id: providerId,
          });
          if (response.data.status === "success") {
            isPendingLocation = false;
            if (type === locations_label) {
              toast.success(
                `${lastCharecterEmitter(locations_label)} added successfully!`,
                {
                  style: {},
                  progressStyle: { backgroundColor: dynamicColor },
                  icon: (
                    <span
                      style={{
                        marginRight: "8px",
                        borderRadius: "50%",
                        width: "7rem",
                        height: "35px",
                        textAlign: "center",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: dynamicColor,
                        backgroundColor: "white",
                        fontSize: "1.5rem",
                        border: `3px solid ${dynamicColor}`,
                      }}
                    >
                      ✓
                    </span>
                  ),
                }
              );
            }
          }
          if (type === locations_label) {
            if (pathname.includes("providers") && providerId) {
              dispatch(resetLocationProviderData());
              dispatch(
                getProviderLocationsByFilterThunk({
                  filterColumn: "",
                  filterValue: "",
                  searchVal: "",
                  sortColumn: "",
                  sortType: "",
                  LocationPage,
                  status: "",
                  dynamicProviderId: providerId,
                  dynamicUserTypeId: dynamicLocationId,
                })
              );
            } else {
              dispatch(resetLocationData());
              dispatch(
                getLocationUserByFilterThunk({
                  filterColumn: "",
                  filterValue: "",
                  searchVal: "",
                  sortColumn: "",
                  sortType: "",
                  LocationPage,
                  dynamicId: dynamicLocationsId,
                  status: "",
                })
              );
            }
          }
          const stickyNoteElement = document.querySelector(
            ".new_sticky_note_wrapper"
          );
          if (stickyNoteElement) {
            stickyNoteElement.classList.add("fade_out");
            setTimeout(() => {
              dispatch(setShowStickyNote(false));
            }, 300);
          }
        } catch (error: any) {
          toast.error(error.message);
        }
      } else {
        toast.error("All Fields Required!");
      }
    }
    if (type === business_label) {
      if (
        BusinessAndTeamData.first_name !== "" &&
        BusinessAndTeamData.email !== ""
      ) {
        if (isPendingBusiness) return;
        try {
          isPendingBusiness = true;
          const response = await api.post(url, {
            ...modifiedData,
            tenant_id: providerId,
          });
          if (response.data.status === "success") {
            isPendingBusiness = false;
            if (type === business_label) {
              toast.success(`${business_label} added successfully!`, {
                style: {},
                progressStyle: { backgroundColor: dynamicColor },
                icon: (
                  <span
                    style={{
                      marginRight: "8px",
                      borderRadius: "50%",
                      width: "7rem",
                      height: "35px",
                      textAlign: "center",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: dynamicColor,
                      backgroundColor: "white",
                      fontSize: "1.5rem",
                      border: `3px solid ${dynamicColor}`,
                    }}
                  >
                    ✓
                  </span>
                ),
              });
            }
          }
          if (type === business_label) {
            // dispatch(getBusinessThunk(dynamicBusinessId));
            if (pathname.includes("providers") && providerId) {
              dispatch(resetBusinessProviderData());
              dispatch(
                getProviderBusinessFilterThunk({
                  filterColumn: "",
                  filterValue: "",
                  searchVal: "",
                  sortColumn: "",
                  sortType: "",
                  BusinessPage,
                  status: "",
                  dynamicProviderId: providerId,
                  dynamicUserTypeId: dynamicBusinessId,
                })
              );
            } else {
              dispatch(resetBusinessData());
              dispatch(
                getBusinessByFilterThunk({
                  filterColumn: "",
                  filterValue: "",
                  searchVal: "",
                  sortColumn: "",
                  sortType: "",
                  BusinessPage,
                  dynamicId: dynamicBusinessId,
                  status: "",
                })
              );
            }
          }
          const stickyNoteElement = document.querySelector(
            ".new_sticky_note_wrapper"
          );
          if (stickyNoteElement) {
            stickyNoteElement.classList.add("fade_out");
            setTimeout(() => {
              dispatch(setShowStickyNote(false));
            }, 300);
          }
        } catch (error: any) {
          if (!BusinessAndTeamData.email.includes("@")) {
            toast.error("Enter Valid Email!");
          } else {
            toast.error(error.message);
          }
        }
      } else {
        toast.error("All Fields Required!");
      }
    }
  };
  const { profile_name } = useAppSelector((state) => state.common.domainDetail);
  return (
    <>
      {activeTabProfile === profile_name && (
        <div className="payload_area">
          <div className="main_area">
            <div className="side_bar"></div>
            <div className="side_bar" />
            <div className="middle-block">
              <p className="note-title">
                {/* {type === "business"
              ? "New Business"
              : type === "teams"
              ? "New Teams"
              : type === "locations"
              ? "New Locations"
              : ""} */}
                {`New ${currentTitle}`}
              </p>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                  transform: "translateY(-5%)",
                  // marginBottom:"30px"
                }}
                className="middle-section-content "
              >
                {" "}
                <div className="field">
                  <span className="field-title">
                    {profile_name} Type{" "}
                    <span className="asterixs_color"> * </span>
                  </span>
                  <select
                    className="field-type custom-select-2"
                    style={{
                      backgroundColor: "white",
                    }}
                    onChange={(e) =>
                      dispatch(setProfileTypeSticky(e?.target?.value))
                    }
                    value={getProfileTypeSticky}
                  >
                    {[
                      prospects_label,
                      clients_label,
                      business_label,
                      locations_label,
                      teams_label,
                      contacts_label,
                      staff_label,
                    ]?.map((elem: string) => {
                      return (
                        <option key={elem} value={elem}>
                          {elem}
                        </option>
                      );
                    })}
                  </select>
                  <span className="field-border field-border-add-form"></span>
                </div>
                <div className="field">
                  <span className="field-title">
                    Name <span className="asterixs_color"> * </span>
                  </span>
                  <input
                    style={{
                      backgroundColor: "white",
                    }}
                    className="input-fields-of-sticky-note"
                    placeholder="Enter Name"
                    onChange={(e) =>
                      setBusinessAndTeamData((prevState) => ({
                        ...prevState,
                        first_name: e.target.value,
                      }))
                    }
                    value={BusinessAndTeamData.first_name}
                  />

                  <span className="field-border field-border-add-form"></span>
                </div>
                <div className="field">
                  <span className="field-title">
                    Email
                    {(type === business_label || type === teams_label) && (
                      <span className="asterixs_color"> * </span>
                    )}
                  </span>
                  <input
                    style={{
                      backgroundColor: "white",
                    }}
                    className="input-fields-of-sticky-note"
                    placeholder="Enter Email"
                    onChange={(e) =>
                      setBusinessAndTeamData((prevState) => ({
                        ...prevState,
                        email: e.target.value,
                      }))
                    }
                    value={BusinessAndTeamData.email}
                  />
                  <span className="field-border field-border-add-form"></span>
                </div>
                <div className="field">
                  <span className="field-title">Phone</span>
                  <input
                    style={{
                      backgroundColor: "white",
                    }}
                    className="input-fields-of-sticky-note"
                    placeholder="Enter Phone"
                    onChange={(e) =>
                      setBusinessAndTeamData((prevState) => ({
                        ...prevState,
                        phone: e.target.value,
                      }))
                    }
                    value={BusinessAndTeamData.phone}
                  />

                  <span className="field-border field-border-add-form"></span>
                </div>
                {(type === business_label || type === locations_label) && (
                  <div className="field">
                    <span className="field-title">
                      {" "}
                      {type === business_label
                        ? business_label
                        : type === teams_label
                        ? teams_label
                        : type === locations_label
                        ? locations_label
                        : ""}{" "}
                      Type
                    </span>

                    <select
                      className="field-type custom-select-2"
                      style={{
                        backgroundColor: "white",
                      }}
                      onChange={(e) =>
                        setBusinessAndTeamData((prevState) => ({
                          ...prevState,
                          role: e.target.value,
                        }))
                      }
                      value={BusinessAndTeamData.role}
                    >
                      <option value="">
                        Select{" "}
                        {type === business_label
                          ? business_label
                          : type === teams_label
                          ? teams_label
                          : type === locations_label
                          ? locations_label
                          : ""}{" "}
                        Type...
                      </option>
                      {currentLocationRoles?.map(
                        (elem: any, index: number | any) => {
                          let isExist: any = userType?.includes(
                            `${elem?.desc}`
                          );
                          if (isExist) {
                            return (
                              <option key={index} value={elem?.id}>
                                {elem.desc}
                              </option>
                            );
                          }
                        }
                      )}
                    </select>

                    <span className="field-border field-border-add-form"></span>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="bottomBlock">
            <button className="save-btn" onClick={handleFormBusinessAndTeams}>
              Save
            </button>
          </div>
        </div>
      )}
      {activeTabProfile === "Comms" && comp}
    </>
  );
};

export default BusinessAndTeamForm;
