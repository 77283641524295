import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../../../services/api";

const initialState: any = {
  serviceTypePage: 1,
  serviceTypeFilterItems: {
    // status: "1",
    filterColumn: "",
    filterValue: "",
    searchVal: "",
    sortColumn: "",
    sortType: "",
    serviceTypePage: 1,
  },
  lastPage: 100000,

  loading: false,
  error: "",
  serviceTypeData: [],
  uniqueServiceType: [],
  
  getServiceTypeName: [],
  getServiceTypeBillingRule: [],
  getServiceTypeTaxRate: [],  
  searchValServiceType: "",
  selectedDropdownItemServiceType: "",
  selectedItemIndexFilterServiceType: "",
  selectedItemIndexSortServiceType: "",
  sortByServiceTypeR: "",
  selectedFilterItemsServiceType: [],
};

export const getServiceTypeByFilterThunk = createAsyncThunk(
  " Service-type/fetchServiceTypeByFilter",
  async ({
    filterColumn,
    filterValue,
    searchVal,
    sortColumn,
    sortType,
    // status,
    serviceTypePage,
  }: {
    filterColumn: string | any;
    filterValue: string | any;
    searchVal: string | any;
    sortColumn: string | any;
    sortType: string | any;
    // status: string | any;
    serviceTypePage: string | any;
  }) => {
    const encodedFilterValue = encodeURIComponent(filterValue);

    try {
      const response = await api.get(
        `/service_types?paginate=true&page=${serviceTypePage}&limit=50000&search=${searchVal}&sort_column=${sortColumn}&sort_type=${sortType}&filter_column=${filterColumn}&filter_value=${encodedFilterValue}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
export const getServiceTypeDetailsById = createAsyncThunk(
  "service-type/fetchServiceTypeId",
  async (serviceTypeId: any) => {
    try {
      const { data } = await api.get(
        `service_types?paginate=true&page=1&limit=10000&id=${serviceTypeId}`
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
);
export const getServiceTypeNameThunk = createAsyncThunk(
  "Service-type/fetchServiceTypeName",
  async () => {
    try {
      const { data } = await api.get(`service_types?unique_column=name`);
      return data?.data;
    } catch (error) {
      throw error;
    }
  }
);
export const getServiceTypeTaxRateThunk = createAsyncThunk(
  "Service-type/fetchServiceTypeTax",
  async () => {
    try {
      const { data } = await api.get(`service_types?unique_column=tax_rate`);
      return data?.data;
    } catch (error) {
      throw error;
    }
  }
);

export const updateServiceType = createAsyncThunk(
  "Service-type/updateServiceTypeTax",
  async (ServiceTypeDetails: any) => {
    try {
      const { data } = await api.post(
        `service_types/update_service_type`,
        ServiceTypeDetails
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
);

const ServiceTypeSlice = createSlice({
  name: "serviceType",
  initialState,
  reducers: {
    // Pagination Start
    setServiceTypePage: (state) => {
      state.serviceTypePage += 1;
    },
    resetServiceTypePage: (state) => {
      state.serviceTypePage = 1;
    },
    resetServiceTypeData: (state) => {
      state.serviceTypeData = [];
    },
    setServiceTypeFilterItems: (state, action) => {
      state.serviceTypeFilterItems = action.payload;
    },
    resetServiceTypeFilterItems: (state) => {
      state.serviceTypeFilterItems = {
        // status: "1",
        filterColumn: "",
        filterValue: "",
        searchVal: "",
        sortColumn: "",
        sortType: "",
        serviceTypePage: 1,
      };
    },
    // Pagination End
    // Filter Dropdown Start
    setSelectedDropdownItemServiceType: (state, action) => {
      state.selectedDropdownItemServiceType = action.payload;
    },
    resetSelectedDropdownItemServiceType: (state) => {
      state.selectedDropdownItemServiceType = "";
    },
    setSelectedItemIndexFilterServiceType: (state, action) => {
      state.selectedItemIndexFilterServiceType = action.payload;
    },
    resetSelectedItemIndexFilterServiceType: (state) => {
      state.selectedItemIndexFilterServiceType = "";
    },
    setSelectedItemIndexSortServiceType: (state, action) => {
      state.selectedItemIndexSortServiceType = action.payload;
    },
    resetSelectedItemIndexSortServiceType: (state) => {
      state.selectedItemIndexSortServiceType = "";
    },
    setSortByServiceTypeR: (state, action) => {
      state.sortByServiceTypeR = action.payload;
    },
    resetSortByServiceTypeR: (state) => {
      state.sortByServiceTypeR = "";
    },
    setSelectedFilterItemsServiceType: (state, action) => {
      state.selectedFilterItemsServiceType = action.payload;
    },
    resetSelectedFilterItemsServiceType: (state) => {
      state.selectedFilterItemsServiceType = "";
    },
    setSearchValueServiceType: (state, action) => {
      state.searchValServiceType = action.payload;
    },
    resetSearchValueServiceType: (state) => {
      state.searchValServiceType = "";
    },
    clearUniqueServiceTypeData : (state) => {
         state.uniqueServiceType = null;
    }
  },
  extraReducers: (builder) => {
    builder

      .addCase(getServiceTypeByFilterThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getServiceTypeByFilterThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.serviceTypeData = [
          // ...state.serviceTypeData,
          ...action.payload.data.data,
        ];
        state.lastPage = action.payload.data.last_page;
      })
      .addCase(getServiceTypeByFilterThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(getServiceTypeDetailsById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getServiceTypeDetailsById.fulfilled, (state, action) => {
        state.loading = false;
        state.uniqueServiceType = action.payload.data.data;
      })
      .addCase(getServiceTypeDetailsById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getServiceTypeNameThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getServiceTypeNameThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.getServiceTypeName = action.payload.data;
      })
      .addCase(getServiceTypeNameThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getServiceTypeTaxRateThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getServiceTypeTaxRateThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.getServiceTypeTaxRate = action.payload.data;
      })
      .addCase(getServiceTypeTaxRateThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});
export const {
  // Filter Start
  setSelectedDropdownItemServiceType,
  resetSelectedDropdownItemServiceType,
  setSelectedItemIndexFilterServiceType,
  resetSelectedItemIndexFilterServiceType,
  setSelectedItemIndexSortServiceType,
  resetSelectedItemIndexSortServiceType,
  setSortByServiceTypeR,
  resetSortByServiceTypeR,
  setSelectedFilterItemsServiceType,
  resetSelectedFilterItemsServiceType,
  setSearchValueServiceType,
  resetSearchValueServiceType,
  // Filter End
  clearUniqueServiceTypeData,

  // Pagination Start
  setServiceTypePage,
  resetServiceTypePage,
  resetServiceTypeData,
  setServiceTypeFilterItems,
  resetServiceTypeFilterItems,
  // Pagination End
} = ServiceTypeSlice.actions;
export default ServiceTypeSlice.reducer;
