import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../../services/api";

const initialState: any = {
  isAssessmentFormOpenStaff: false,
  openFormStaff: false,
};

const documentStaffSlice = createSlice({
  name: "staffAssociations",
  initialState,
  reducers: {
    setIsAssessmentFormOpenStaff: (state) => {
      state.isAssessmentFormOpenStaff = true;
    },
    resetIsAssessmentFormOpenStaff: (state) => {
      state.isAssessmentFormOpenStaff = false;
    },
    setOpenFormByClickStaff: (state) => {
      state.openFormStaff = true;
    },
    resetOpenFormByClickStaff: (state) => {
      state.openFormStaff = false;
    },
  },
  extraReducers: (builder) => {
    builder;
  },
});
export const {
  setIsAssessmentFormOpenStaff,
  resetIsAssessmentFormOpenStaff,
  setOpenFormByClickStaff,
  resetOpenFormByClickStaff,
} = documentStaffSlice.actions;
export default documentStaffSlice.reducer;
