import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../../../services/api";

const initialState: any = {
  loading: false,
  error: "",
  menuAssignmentData: [],
  hasDeleteMenuAssignment: false,
  idOfMenuAssignToDelete: null,
};

export const getMenuAssignmentThunk = createAsyncThunk(
  "menuAssignmentThunk/fetchMenuAssessmentData",
  async ({ id, date }: any) => {
    try {
      const response = await api.get(
        `/menu_assignments?user_id=${id}&date=${date}`
      );
      return response.data.data[0];
    } catch (error) {
      return error;
    }
  }
);
export const deleteMenuAssignmentThunk = createAsyncThunk(
  "menu/delete",
  async (id: any) => {
    try {
      const response = await api.post(
        `/menu_assignments/delete_menu_assignment`,
        {
          menu_assignment_id: id,
        }
      );
      if (response.status !== 200) {
        throw new Error("Failed to delete Menu.");
      }
      return response.data.data;
    } catch (error) {
      throw error;
    }
  }
);

const menuAssignment = createSlice({
  name: "menuAssignment",
  initialState,
  reducers: {
    setConfirmDeleteMenuAssignment: (state, action) => {
      const { flag, id } = action.payload;
      state.hasDeleteMenuAssignment = flag;
      state.idOfMenuAssignToDelete = id;
    },
    resetConfirmDeleteMenuAssignment: (state) => {
      state.hasDeleteMenuAssignment = false;
      state.idOfMenuAssignToDelete = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMenuAssignmentThunk.pending, (state) => {
        state.loading === true;
      })
      .addCase(getMenuAssignmentThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.menuAssignmentData = action.payload;
      })
      .addCase(getMenuAssignmentThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const {
  setConfirmDeleteMenuAssignment,
  resetConfirmDeleteMenuAssignment,
} = menuAssignment.actions;
export default menuAssignment.reducer;
