import React from "react";
import ReportsHubCard from "./ReportsHubCard";

function Category({ category, reports }) {
  return (
    <div className="category">
      <h2>{category}</h2>
      <div className="reports_hub_list">
        {reports?.length > 0 ? (
          reports.map((report: any) => (
            <ReportsHubCard key={report?.id} {...report} />
          ))
        ) : (
          <p>No Reports in this category yet.</p>
        )}
      </div>
    </div>
  );
}

export default Category;
