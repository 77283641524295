import React from "react";
import "../../Styles/ClinicalTabSticky.css";

import UserPictureWithCommsCount from "./Components/UserPictureWithCommsCount";

import { useState } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../Controller/redux/Hooks";
import {
  // resetButtonTypeSticky,
  // resetProfileTypeSticky,
  setButtonTypeSticky,
  setProfileTypeSticky,
} from "../../../../../Controller/redux/features/CommonFeature/commonSlice";
// import TimePicker from "../../../Time/Time";
import ReactDatePicker from "react-datepicker";
import { useLocation } from "react-router-dom";
import { convertTime } from "../../../../../Model/utils/Helper/formatTime";

const ClinicalTabSticky = ({
  midleSection,
  title = "Profile",
  type,
  handleProfileTabChange,
  handleTabChangeInner,
  setTime,
  // time,
  clinicalDate,
  setClinicalDate,
  isMobileScreen,
  isFinanceScreen,
}: any) => {
  // // console.log(type,"Clients ")
  const dispatch = useAppDispatch();
  const { profile_name } = useAppSelector((state) => state.common.domainDetail);
  const [activeTab, setActiveTab] = useState<string>(profile_name);
  const [activeTabInner, setActiveTabInner] = useState<string>("");
  const location = useLocation()?.pathname;
  const isClientPath = location?.includes("MTg2");
  const isOtherPathname =
    location?.includes("daybook") ||
    location?.includes("comms") ||
    location?.includes("villages") ||
    location?.includes("rac") ||
    location?.includes("sil") ||
    location?.includes("sales") ||
    location?.includes("meals") ||
    location?.includes("audit") ||
    location?.includes("ref-data") ||
    location === "/";
  // let isAdminActive = isAdmin === "Administrator";
  const { user } = useAppSelector((state) => state.common);
  const isAdminActive = user?.tenant?.id === user?.tenant?.parent_id;
  const {
    prospects_label,
    clients_label,
    business_label,
    locations_label,
    teams_label,
    contacts_label,
    staff_label,
  } = useAppSelector((state) => state?.common?.domainDetail);
  const showItems =
    type === prospects_label ||
    type === clients_label ||
    type === locations_label ||
    type === business_label ||
    type === teams_label ||
    type === contacts_label ||
    type === staff_label ||
    type === "providers";
  const { getButtonTypeSticky, getProfileTypeSticky } = useAppSelector(
    (state) => state?.common
  );
  const [PreviousProfiletypeSticky, setPreviousProfiletypeSticky] =
    useState("");

  const pathname = useLocation().pathname;
  let isClients = pathname.includes("clients");
  let dataArr = isClients
    ? ["Associations", "Comms", "Obs"]
    : ["Associations", "Comms", "Daybook"];

  const [back, setBack] = useState(false);
  
  const handleRecorder = () => {
    if (back) {
      dispatch(setButtonTypeSticky(PreviousProfiletypeSticky));
      dispatch(setProfileTypeSticky(PreviousProfiletypeSticky));
      setBack(false);
      setPreviousProfiletypeSticky("");
    } else {
      dispatch(setButtonTypeSticky("Support"));
      dispatch(setProfileTypeSticky("Support"));
      setPreviousProfiletypeSticky(getProfileTypeSticky);
      setBack(true);
    }
  };
  return (
    <div className="container_clinical_tab_sticky">
      <div className="upper_segment">
        <div className="header_section_wrapper_sticky">
          {!!getButtonTypeSticky ? (
            <p>
              {getButtonTypeSticky === "Associations" && !isFinanceScreen
                ? "Association"
                : getButtonTypeSticky === "Notes" ||
                  getButtonTypeSticky === "Observations"
                ? "Clinical"
                : getButtonTypeSticky === "Expense"
                ? "Add Expense"
                : getButtonTypeSticky === "Funding"
                ? "Add Service"
                : getButtonTypeSticky === "Billing"
                ? "Add Billing Set"
                : getButtonTypeSticky === "PayRule"
                ? "Add Pay Rule"
                : getButtonTypeSticky === "Support"
                ? "Support"
                : type === "stickyHub"
                ? "Comms"
                : type === "form"
                ? "Forms"
                : getButtonTypeSticky === "Asso" ||
                  getButtonTypeSticky === "Associations"
                ? "Associations"
                : getButtonTypeSticky}
            </p>
          ) : (
            <p>
              {isAdminActive
                ? title.replace("Providers", profile_name || "")
                : title}
            </p>
          )}
        </div>
      </div>
      {getButtonTypeSticky === "Support" ||
      getProfileTypeSticky === "Support" ||
      type === "Vendors" ||
      type === "Reports" ? (
        <></>
      ) : (
        showItems && (
          <div className="sticky_profil_btns">
            {[profile_name, "Comms"]?.map((elem: string, index: number) => {
              return (
                <button
                  onClick={() => {
                    setActiveTab(elem);
                    handleProfileTabChange(elem);
                  }}
                  className={`${
                    activeTab === elem ? "sticky_note_btn_active" : ""
                  } sticky_note_btn`}
                >
                  {elem}
                </button>
              );
            })}
          </div>
        )
      )}
      {getButtonTypeSticky === "Support" ||
      getProfileTypeSticky === "Support" ||
      type === "Vendors" ||
      type === "Reports" ? (
        <></>
      ) : (
        !showItems &&
        !isMobileScreen &&
        !isOtherPathname && (
          <>
            <div className="sticky_profil_btns">
              {(isClientPath
                ? ["Associations", "Comms", "Daybook", "Notes"]
                : type == "form"
                ? null
                : dataArr
              )?.map((elem: string, index: number) => {
                return (
                  <button
                    onClick={() => {
                      setActiveTabInner(elem);
                      handleTabChangeInner(elem);
                      dispatch(setButtonTypeSticky(elem));
                    }}
                    className={`${
                      elem?.includes(getButtonTypeSticky)
                        ? "sticky_note_btn_active"
                        : ""
                    } sticky_note_btn`}
                  >
                    {elem}
                  </button>
                );
              })}
            </div>
            <div className="sticky_profil_btns">
              {(isClientPath
                ? ["Observations"]
                : type == "form"
                ? null
                : null
              )?.map((elem: string, index: number) => {
                return (
                  <button
                    onClick={() => {
                      setActiveTabInner(elem);
                      handleTabChangeInner(elem);
                      dispatch(setButtonTypeSticky(elem));
                    }}
                    className={`${
                      getButtonTypeSticky === elem
                        ? "sticky_note_btn_active"
                        : ""
                    } sticky_note_btn`}
                  >
                    {elem}
                  </button>
                );
              })}
            </div>
          </>
        )
      )}
      <>
        <div
          id="sticky_clinical_date_picker"
          className={`${
            getButtonTypeSticky === "Observations" ? "fade_in" : "display_none"
          } sticky_clinical_unqiue`}
        >
          {/* <TimePicker setTime={setTime} time={time} /> */}
          <input
            type="time"
            onChange={(e) => {
              setTime(convertTime(e.target.value));
            }}
          />

          <ReactDatePicker
            placeholderText={"Select Date"}
            showYearDropdown
            showMonthDropdown
            // @ts-ignore
            selected={clinicalDate}
            value={clinicalDate}
            dateFormat="dd MMM yyyy"
            onChange={(date: any) => {
              setClinicalDate(date);
            }}
          />
          {/* <CustomDatePicker
            newDate={clinicalDate}
            handleChange={setClinicalDate}
          /> */}
        </div>
      </>
      {/* {showItems && activeTab === "Profile" && (
        <div className="">
          <select
            className=""
            style={{
              backgroundColor: "white",
            }}
            onChange={(e) =>
              dispatch(setProfileTypeSticky(e?.target?.value?.toLowerCase()))
            }
            value={getProfileTypeSticky?.toLowerCase()}
          >
            <option value="">Select Type...</option>
            {[
              prospects_label,
              clients_label,
              business_label,
              locations_label,
              teams_label,
              contacts_label,
              staff_label,
            ]?.map((elem: string) => {
              return (
                <option key={elem} value={elem.toLowerCase()}>
                  {elem}
                </option>
              );
            })}
          </select>
        </div>
      )} */}

      <div className="new_sticky_middle_extion_wrapper">
        {midleSection}
        <div className="lower_segment">
          <div className="tabs_container">
            {/* {back ? (
              <BackButtonSVG onClick={handleRecorder} />
            ) : (
              <QuestionMarkSVG onClick={handleRecorder} />
            )} */}

            <div
              className={`${
                isAdminActive
                  ? "user_picture_segment_admin"
                  : "user_picture_segment_normal"
              }`}
            >
              <UserPictureWithCommsCount isCountNeeded={true} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClinicalTabSticky;
