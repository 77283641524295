import { Fragment, useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../Controller/redux/Hooks";
import { toast } from "react-toastify";

import {
  addAssociationThunk,
  getAssociationTypesThunk,
  getAssociations,
  getReverseRelationshipsWithTypesThunk,
  resetRelationshipReverseData,
} from "../../../../Controller/redux/features/CommonFeature/associationSlice";
import { useLocation, useParams } from "react-router-dom";
import {
  createAssociationLocationThunk,
  getAssociationLocationsTypeBuildingThunk,
  getAssociationLocationsTypeRoomThunk,
  getAssociationLocationsTypeWardThunk,
} from "../../../../Controller/redux/features/LocationFeature/locationSlice";
import { setShowStickyNote } from "../../../../Controller/redux/features/CommonFeature/commonSlice";
// import { api } from "../../../../Controller/services/api";

interface AssociationFormProps {
  selectedName: any;
  selectedType: any;
  relationship: any;
  setRelationship: any;
  handleTypeSelect: any;
  handleNameChange: any;
  handleSaveAssociation: any;
  setreverseRelationship: any;
  reverseRelationship: string;
  formData?: any;
}

const AssociationForm: React.FC<AssociationFormProps> = ({
  selectedName,
  selectedType,
  relationship,
  setRelationship,
  handleNameChange,
  handleSaveAssociation,
  handleTypeSelect,
  setreverseRelationship,
  reverseRelationship,
  formData,
}) => {
  ///////////////////////////////////////////////////////
  const locationPathname = useLocation()?.pathname?.includes("locations");
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getAssociationLocationsTypeRoomThunk());
    dispatch(getAssociationLocationsTypeBuildingThunk());
    dispatch(getAssociationLocationsTypeWardThunk());
  }, []);
  const {
    geAssociationtLocationTypeBuilding,
    geAssociationtLocationTypeWard,
    geAssociationtLocationTypeRoom,
  } = useAppSelector((state) => state?.location);
  //@ts-ignore
  const dynamicColor = useAppSelector((state) => state?.common?.dynamicColor);
  const getRelationships = useAppSelector(
    (state) => state.associations.getRelationshipsWithTypes
  );
  const pathName = useLocation().pathname;
  const isLocation = pathName.includes("locations");
  const condition = locationPathname;
  const [buildingType, setBuildingType] = useState("");
  const [wardType, setWardType] = useState("");
  const [roomType, setRoomType] = useState("");

  let isPending = false;
  const [clientId, setClientId] = useState<any>("");

  const id = useParams()?.id;

  useEffect(() => {
    if (id) {
      const realId = window.atob(id);
      setClientId(realId);
    }
  }, [id]);

  let pathname = useLocation()?.pathname;

  let isLocationScreen = pathname.includes("locations");

  const handleCreateAssociation = async (e: any) => {
    e.preventDefault();

    if (isLocationScreen) {
      let modifiedData;
      modifiedData = {
        building_id: buildingType,
        ward_id: wardType,
        room_id: roomType,
      };
      if (true) {
        if (isPending) return;
        try {
          isPending = true;
          const res = await dispatch(
            createAssociationLocationThunk(modifiedData)
          );
          if (res.payload === "Association exists") {
            isPending = false;
            toast.error("Association Already Exists!");
          }
          if (res.payload.status === "success") {
            isPending = false;
            toast.success("Association Created Successfully!", {
              progressStyle: { backgroundColor: dynamicColor },
              icon: (
                <span
                  style={{
                    marginRight: "8px",
                    borderRadius: "50%",
                    width: "7rem",
                    height: "35px",
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: dynamicColor,
                    backgroundColor: "white",
                    fontSize: "1.5rem",
                    border: `3px solid ${dynamicColor}`,
                  }}
                >
                  ✓
                </span>
              ),
            });
            dispatch(
              getAssociations({
                clientId,
                searchVal: "",
                type: "",
                pageNumber: 1,
              })
            );
          }

          const stickyNoteElement = document.querySelector(
            ".new_sticky_note_wrapper"
          );
          if (stickyNoteElement) {
            stickyNoteElement.classList.add("fade_out");
            setTimeout(() => {
              dispatch(setShowStickyNote(false));
            }, 300);
          }
        } catch (err: any) {
          // console.log(err);
          toast.error(err.message);
        }
      } else {
        toast.error("All Fields Required!");
      }
    } else {
      if (selectedName !== "" && selectedType !== "") {
        const res = await dispatch(addAssociationThunk(formData));
        if (res.payload.status === "success") {
          toast.success("Association Created Successfully!", {
            style: {},
            progressStyle: { backgroundColor: dynamicColor },
            icon: (
              <span
                style={{
                  marginRight: "8px",
                  borderRadius: "50%",
                  width: "7rem",
                  height: "35px",
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: dynamicColor,
                  backgroundColor: "white",
                  fontSize: "1.5rem",
                  border: `3px solid ${dynamicColor}`,
                }}
              >
                ✓
              </span>
            ),
          });
          setRelationship("");
          setreverseRelationship("");
          // TO UPDATE THE DATA IN COMMON TABLE FOR ASSOCIATIONS.
          dispatch(
            getAssociations({
              clientId,
              searchVal: "",
              type: "",
              pageNumber: 1,
            })
          );
          const stickyNoteElement = document.querySelector(
            ".new_sticky_note_wrapper"
          );
          if (stickyNoteElement) {
            stickyNoteElement.classList.add("fade_out");
            setTimeout(() => {
              dispatch(setShowStickyNote(false));
            }, 300);
          }
        } else {
          toast.error("All Fields Required!");
        }
      } else {
        toast.error("All Fields Required!");
      }
    }
  };
  ////////////////////////////////////////////////////////////////////
  const { parentType, childType } = useAppSelector(
    (state) => state.associations
  );
  const reverseRelationships = useAppSelector(
    (state) => state.associations.getReverseRelationshipsWithTypes
  );

  useEffect(() => {
    dispatch(resetRelationshipReverseData());
  }, []);
  const notIncludeStatusCode = [0, 6];

  const handleRelationshipChange = (event: any) => {
    setRelationship(event.target.value);
    dispatch(
      getReverseRelationshipsWithTypesThunk({
        parent: parentType,
        child: childType,
        relName: event.target.value,
      })
    );
  };

  const getNames = useAppSelector(
    (state) => state.associations.getAssociationNames
  );
  const getTypes = useAppSelector(
    (state) => state.associations.getAssociationTypes
  );
  useEffect(() => {
    dispatch(getAssociationTypesThunk());
  }, []);

  return (
    <div className="payload_area">
      <div className="main_area">
        <div className="side_bar"></div>
        <div className="side_bar" />
        <div className="middle-block">
          <p className="note-title">New Association</p>
          <div className="middle-section-content">
            <div className="field">
              <span className="field-title">Type</span>
              <select
                className="field-type custom-select"
                style={{
                  backgroundColor: "white",
                }}
                onChange={handleTypeSelect}
                value={selectedType}
              >
                <option value="">Select Type...</option>

                {isLocation
                  ? getTypes.map((type: any) => (
                      <option
                        key={type.desc}
                        value={type.desc}
                        style={{
                          display: type?.desc?.includes("Company Admin")
                            ? "none"
                            : "block",
                        }}
                      >
                        {type.desc}
                      </option>
                    ))
                  : getTypes
                      .filter((type: any) => !type.desc.includes("Location"))
                      .map((type: any) => (
                        <option
                          key={type.desc}
                          value={type.desc}
                          style={{
                            display: type?.desc?.includes("Company Admin")
                              ? "none"
                              : "block",
                          }}
                        >
                          {type.desc}
                        </option>
                      ))}
              </select>

              <span className="field-border"></span>
            </div>
            {!condition && (
              <>
                <div className="field">
                  <span className="field-title">Name</span>
                  <select
                    className="field-type custom-select-2"
                    style={{
                      backgroundColor: "white",
                    }}
                    onChange={(e) => handleNameChange(e, "associations")}
                    value={selectedName}
                  >
                    <option value="">Select Name...</option>
                    {getNames &&
                      getNames.map((elem: any) => {
                        let statusIs = parseInt(elem.status);
                        if (!notIncludeStatusCode.includes(statusIs)) {
                          return (
                            <option key={elem.name} value={elem.name}>
                              {elem.name}
                            </option>
                          );
                        }
                      })}
                  </select>

                  <span className="field-border"></span>
                </div>{" "}
                <div className="field">
                  <span className="field-title">Relationship</span>
                  <select
                    className="field-type custom-select-2"
                    onChange={handleRelationshipChange}
                    value={relationship}
                    style={{
                      backgroundColor: "white",
                    }}
                  >
                    <option value="">Please Select...</option>
                    {getRelationships &&
                      getRelationships.map((relationship: any) => (
                        <option key={relationship} value={relationship}>
                          {relationship}
                        </option>
                      ))}
                  </select>
                  <span className="field-border"></span>
                </div>
                <div
                  className="field"
                  style={{
                    display: reverseRelationships?.length >= 2 ? "" : "none",
                  }}
                >
                  <span className="field-title"> Reverse Relationship</span>
                  <select
                    className="field-type custom-select-2"
                    onChange={(e) => setreverseRelationship(e.target.value)}
                    value={reverseRelationship}
                    style={{
                      backgroundColor: "white",
                    }}
                  >
                    <option value="">Please Select...</option>
                    {reverseRelationships &&
                      reverseRelationships.map((reverseRelationship: any) => (
                        <option
                          key={reverseRelationship}
                          value={reverseRelationship}
                        >
                          {reverseRelationship}
                        </option>
                      ))}
                  </select>
                  <span className="field-border"></span>
                </div>
              </>
            )}
            {condition && (
              <>
                <div className="field">
                  <span className="field-title">Building Name</span>
                  <select
                    className="field-type custom-select-2"
                    onChange={(e) => setBuildingType(e.target.value)}
                    value={buildingType}
                    style={{
                      backgroundColor: "white",
                    }}
                  >
                    <option value="">Please Select...</option>
                    {geAssociationtLocationTypeBuilding &&
                      geAssociationtLocationTypeBuilding?.map(
                        (relationship: any) => (
                          <option
                            key={relationship?.id}
                            value={relationship?.id}
                          >
                            {relationship.first_name}
                          </option>
                        )
                      )}
                  </select>
                  <span className="field-border"></span>
                </div>
                <div className="field">
                  <span className="field-title">Ward Name</span>
                  <select
                    className="field-type custom-select-2"
                    onChange={(e) => setWardType(e.target.value)}
                    value={wardType}
                    style={{
                      backgroundColor: "white",
                    }}
                  >
                    <option value="">Please Select...</option>
                    {geAssociationtLocationTypeWard &&
                      geAssociationtLocationTypeWard?.map(
                        (relationship: any) => (
                          <option
                            key={relationship?.id}
                            value={relationship?.id}
                          >
                            {relationship?.first_name}
                          </option>
                        )
                      )}
                  </select>
                  <span className="field-border"></span>
                </div>
                <div className="field">
                  <span className="field-title">Room Name</span>
                  <select
                    className="field-type custom-select-2"
                    onChange={(e) => setRoomType(e.target.value)}
                    value={roomType}
                    style={{
                      backgroundColor: "white",
                    }}
                  >
                    <option value="">Please Select...</option>
                    {geAssociationtLocationTypeRoom &&
                      geAssociationtLocationTypeRoom?.map(
                        (relationship: any) => (
                          <option
                            key={relationship?.id}
                            value={relationship?.id}
                          >
                            {relationship?.first_name}
                          </option>
                        )
                      )}
                  </select>
                  <span className="field-border"></span>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="bottomBlock">
        <button
          className="save-btn"
          onClick={
            
              (condition && handleCreateAssociation) ||
              (!condition && handleSaveAssociation)
          }
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default AssociationForm;
