import React, { Fragment, useEffect, useRef, useState } from "react";
import {
  getContactEmailThunk,
  getContactNameThunk,
  getContactPhoneThunk,
  getContactsByFilterThunk,
  getUserRolesContactsThunk,
  resetContactData,
  resetContactPage,
  setContactPage,
} from "../../../../Controller/redux/features/ContactsFeature/contactsSlice";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../Controller/redux/Hooks";

// import CommonTable from "../../../components/CommonTable/InputTable";
import CircularNoteTrigger from "../../../components/CircularNoteTrigger/CircularNoteTrigger";
// import CommonHeader from "../../../components/UpperBox/CommonHeader";
import CommonUpperBox from "../../../components/UpperBox/CommonHeader";
import CommonQuickMenu from "../../../components/CommonQuickMenu/CommonQuickMenu";
import { useNavigate } from "react-router-dom";
import Table from "../../../components/CommonTable/Table";
import {
  getStatusDetailsThunk,
  resetStatusDetails,
  setProfileTypeSticky,
} from "../../../../Controller/redux/features/CommonFeature/commonSlice";

const ContactPage = () => {
  const navigate = useNavigate();
  const tableRef = useRef();
  const contactInnerRef = useRef();
  // GOTO DETAILS WHEN PAGE LOADS EVERYTIME
  useEffect(() => {
    localStorage.removeItem("activeTab");
  }, []);

  const {
    getContactsData,
    ContactPage,
    ContactFilterItems,
    lastPage,
    contacts,
    loading,
  } = useAppSelector((state) => state?.contacts);
  //  @ts-ignore
  const dynamicContactId = parseInt(localStorage.getItem("dynamicContactId"));

  const dispatch = useAppDispatch();
  useEffect(() => {
    // dispatch(getContactsThunk({ dynamicContactId }));
    dispatch(getUserRolesContactsThunk({ dynamicContactId }));
  }, [dynamicContactId]);
  const [stageTwoToGoalsModal, setstageTwoToGoalsModal] = useState(false);

  const dropdownRefContacts = useRef<any>();
  const svgParentRefContacts = useRef<any>();
  const [isDropdownOpenContacts, setIsDropdownOpenContacts] = useState(false);
  const {
    selectedFilterOptionsContact,
    selectedSortOptionsContact,
    searchValContacts,
    sortByContacts,
  } = useAppSelector((state) => state?.contacts);
  const { filterIconColor } = useAppSelector((state) => state?.common);
  const handleClickRow = (e: any, id: any) => {
    if (!isDropdownOpenContacts) {
      navigate(`/people/contacts/${window.btoa(id)}`);
    }
  };

  useEffect(() => {
    const handleClickOutsideContacts = (event: any) => {
      if (
        dropdownRefContacts.current &&
        !dropdownRefContacts.current.contains(event.target) &&
        svgParentRefContacts.current &&
        !svgParentRefContacts.current.contains(event.target)
      ) {
        setIsDropdownOpenContacts(false);
      }
    };
    document.addEventListener("click", handleClickOutsideContacts);
    return () => {
      document.removeEventListener("click", handleClickOutsideContacts);
    };
  }, []);
  const columns = [
    { header: "Name", accessor: "first_name" },
    { header: "Email", accessor: "email" },
    { header: "Mobile", accessor: "mobile" },
    { header: "Associated Business", accessor: "associated_business" },
    { header: "Status", accessor: "status" },
  ];
  const columnsForB2B = [
    { header: "Name", accessor: "first_name" },
    { header: "Email", accessor: "email" },
    { header: "Mobile", accessor: "mobile" },
    { header: "Status", accessor: "status" },
  ];
  useEffect(() => {
    dispatch(resetContactData());
    dispatch(resetContactPage());
  }, [
    ContactFilterItems?.searchVal,
    ContactFilterItems?.filterValue,
    ContactFilterItems?.sortType,
    ContactFilterItems?.status,
    dynamicContactId,
  ]);
  useEffect(() => {
    dispatch(getContactNameThunk(dynamicContactId));
    dispatch(getContactEmailThunk(dynamicContactId));
    dispatch(getContactPhoneThunk(dynamicContactId));
  }, [dynamicContactId]);

  useEffect(() => {
    dispatch(
      getContactsByFilterThunk({
        filterColumn: ContactFilterItems?.filterColumn
          ? ContactFilterItems?.filterColumn
          : "",
        filterValue: ContactFilterItems?.filterValue
          ? ContactFilterItems?.filterValue
          : "",
        searchVal: ContactFilterItems?.searchVal
          ? ContactFilterItems?.searchVal
          : "",
        sortColumn: ContactFilterItems?.sortColumn
          ? ContactFilterItems?.sortColumn
          : "",
        sortType: ContactFilterItems?.sortType
          ? ContactFilterItems?.sortType
          : "",
        ContactPage,
        dynamicId: dynamicContactId,
        status: ContactFilterItems.status ? ContactFilterItems.status : "",
      })
    );
  }, [
    ContactPage,
    ContactFilterItems?.searchVal,
    ContactFilterItems?.filterValue,
    ContactFilterItems?.sortType,
    ContactFilterItems?.status,
    dynamicContactId,
  ]);
  const onScroll = () => {
    if (contactInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = contactInnerRef.current;

      if (
        scrollTop + clientHeight + +5 >= scrollHeight &&
        ContactPage < lastPage
      ) {
        dispatch(setContactPage(ContactPage));
      }
    }
  };
  const uniqueContactData = contacts?.filter(
    (item: any, index: number, array: any) => {
      return array?.findIndex((obj: any) => obj.id === item.id) === index;
    }
  );

  const { domainDetail } = useAppSelector((state) => state?.common);
  let businessType = domainDetail?.businessmodel_type;

  ///////////////////////////////////////////////////////////////////////////////////////
  const contactsStatusDetailsNums = useAppSelector(
    (state) => state.common.domainDetail.contact_status
  );
  const contactsStatusDetailsArr = useAppSelector(
    (state) => state.common.statusDetailsData?.data
  );
  // // console.log(clientStatusDetailsArr,"clientStatusDetailsArr")
  useEffect(() => {
    dispatch(resetStatusDetails());
    dispatch(getStatusDetailsThunk(contactsStatusDetailsNums));
  }, [contactsStatusDetailsNums]);
  const { contacts_label } = useAppSelector(
    (state) => state?.common?.domainDetail
  );

  useEffect(() => {
    dispatch(setProfileTypeSticky(contacts_label));
  }, [contacts_label]);
  ///////////////////////////////////////////////////////////////////////////////////////
  const { profile_name } = useAppSelector((state) => state.common.domainDetail);
  return (
    <Fragment>
     <div
        style={{
          position: "relative",
        }}
      >
        {stageTwoToGoalsModal && (
          <CommonQuickMenu
            setshowGoalsModal={(value: any) => {}}
            mainType={"listing"}
            setstageTwoToGoalsModal={setstageTwoToGoalsModal}
          />
        )}
        <CommonUpperBox
          onClickPlus={() => {
            setstageTwoToGoalsModal(true);
          }}
          type={"listing"}
          title={contacts_label}
        />
      </div>
      {/* <CommonTable
        type="contacts"
        allClients={getContactsDataThunk?.data?.data}
      /> */}
      <Table
        isLoading={loading}
        tableRef={tableRef}
        tableBodyRef={contactInnerRef}
        onScroll={onScroll}
        dynamicHeaderColor={domainDetail?.listingtbl_color}
        statusType="contactsScreen"
        thData={businessType === "B2B" ? columnsForB2B : columns}
        classNameTr={`all-contacts-table-head ${
          businessType === "B2B" && "all_contact_B2B_table_body"
        }`}
        type="contacts"
        dropdownRef={dropdownRefContacts}
        isDropdownOpen={isDropdownOpenContacts}
        itemsArr={contactsStatusDetailsArr}
        onClickSvg={() => {
          setIsDropdownOpenContacts(true);
        }}
        svgParentRef={svgParentRefContacts}
        allClients={uniqueContactData}
        onClickRow={handleClickRow}
        classNameRow={`all-contacts-table-head ${
          businessType === "B2B" && "all_contact_B2B_table_body"
        }`}
        fill={
          selectedFilterOptionsContact.length > 0 ||
          searchValContacts ||
          selectedSortOptionsContact.length > 0 ||
          sortByContacts
            ? filterIconColor
            : "#D8D3D3"
        }
      />

      <CircularNoteTrigger
        type={contacts_label}
        //  title={contacts_label}
        title={profile_name}
      />

    </Fragment>
  );
};

export default ContactPage;
