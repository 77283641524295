import React from "react";

const CrossSVGTrafficLightsSm = ({onChangeTrafficLight}: any) => {
  const handleClick = (alertNumber) => {
    alert(`Alert ${alertNumber}`);
  };

  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="29"
        height="76"
        viewBox="0 0 31 98"
        fill="none"
      >
        <rect x="3" width="25" height="98" rx="12.5" fill="#E6E6E6" />
        <ellipse
          cx="15.7603"
          cy="12.5"
          rx="10.5"
          ry="10.7593"
          transform="rotate(90 15.7603 12.5)"
          fill="white"
        />
        <path
          d="M15.7191 14.5136L11.8028 18.4299L9.8058 16.4329L13.7221 12.5166L9.65997 8.45452L11.7269 6.38759L15.789 10.4497L19.7053 6.53342L21.7023 8.53043L17.786 12.4467L21.8708 16.5315L19.8039 18.5984L15.7191 14.5136Z"
          fill="#E6E6E6"
        />

        {/* 2AD400 */}

        <path
          d="M12.0359 27.5C13.5755 24.8333 17.4245 24.8333 18.9641 27.5L25.4593 38.75C26.9989 41.4167 25.0744 44.75 21.9952 44.75H9.00481C5.9256 44.75 4.00111 41.4167 5.54071 38.75L12.0359 27.5Z"
          fill="#EF3A34"
          onClick={() => onChangeTrafficLight("#EF3A34")}
          style={{ cursor: "pointer" }}
        />
        <path
          d="M12.0359 49.5C13.5755 46.8333 17.4245 46.8333 18.9641 49.5L25.4593 60.75C26.9989 63.4167 25.0744 66.75 21.9952 66.75H9.00481C5.9256 66.75 4.00111 63.4167 5.54071 60.75L12.0359 49.5Z"
          fill="#FBBF14"
          onClick={() => onChangeTrafficLight("#2AD400")}
          style={{ cursor: "pointer" }}
        />
        <path
          d="M12.0359 72.5C13.5755 69.8333 17.4245 69.8333 18.9641 72.5L25.4593 83.75C26.9989 86.4167 25.0744 89.75 21.9952 89.75H9.00481C5.9256 89.75 4.00111 86.4167 5.54071 83.75L12.0359 72.5Z"
          fill="#2AD400"
          onClick={() => onChangeTrafficLight("#FBBF14")}
          style={{ cursor: "pointer" }}
        />
      </svg>
    </div>
  );
};

export default CrossSVGTrafficLightsSm;
