import React, { Fragment, useEffect, useRef, useState } from "react";
import {
  getClientDobThunk,
  getClientEmailThunk,
  getClientFundingThunk,
  getClientNameThunk,
  getClientResidenceThunk,
  getClientsByFilterThunk,
  // getClientsThunk,
  getUserRolesClientsThunk,
  resetClientData,
  resetClientPage,
  setClientPage,
  // setUserTypeIdForClient,
} from "../../../../Controller/redux/features/ClientFeature/clientsSlice";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../Controller/redux/Hooks";

import {
  getStatusDetailsThunk,
  getUserTypesThunk,
  resetHasClinicalFromURL,
  resetStatusDetails,
  resetisModal,
  setHasClinicalFromURL,
  setProfileTypeSticky,
  setisModal,
} from "../../../../Controller/redux/features/CommonFeature/commonSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { api } from "../../../../Controller/services/api";
import CircularNoteTrigger from "../../../components/CircularNoteTrigger/CircularNoteTrigger";
import CommonHeader from "../../../components/UpperBox/CommonHeader";
import CommonUpperBox from "../../../components/UpperBox/CommonHeader";
import CommonQuickMenu from "../../../components/CommonQuickMenu/CommonQuickMenu";
import Table from "../../../components/CommonTable/Table";
import {
  getProvidersByFilterThunk,
  getProvidersUniqueTenantNameThunk,
  getProvidersUniqueTenantTypeThunk,
  resetProvidersData,
  resetProvidersPage,
  setProvidersPage,
} from "../../../../Controller/redux/features/ProviderFeature/providerSlice";
import { providerColumns } from "../../Providers/Data/ProvidersTableData";

const ClientPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  // @ts-ignore
  const dynamicClientId = parseInt(localStorage.getItem("dynamicClientId"));
  const getAllClientsDataThunk = useAppSelector(
    (state) => state?.people?.allData
  );
  const tableRef = useRef();
  const clientInnerRef = useRef();
  const { client, lastPage, ClientFilterItems, ClientPage, loading } =
    useAppSelector((state) => state.people);

  useEffect(() => {
    dispatch(resetClientData());
    dispatch(resetClientPage());
  }, [
    ClientFilterItems?.searchVal,
    ClientFilterItems?.filterValue,
    ClientFilterItems?.sortType,
    ClientFilterItems?.status,
    dynamicClientId,
  ]);

  useEffect(() => {
    dispatch(getClientNameThunk(dynamicClientId));
    dispatch(getClientEmailThunk(dynamicClientId));
    dispatch(getClientDobThunk(dynamicClientId));
    dispatch(getClientResidenceThunk(dynamicClientId));
    dispatch(getClientFundingThunk(dynamicClientId));
  }, [dynamicClientId]);

  const onScroll = () => {
    if (clientInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = clientInnerRef.current;

      if (
        scrollTop + clientHeight + +5 >= scrollHeight &&
        ClientPage < lastPage
      ) {
        dispatch(setClientPage(ClientPage));
      }
    }
  };
  const pathname = useLocation()?.search;
  let splitPathname = pathname.split("&");

  let uuidFromParams = splitPathname[0]?.split("=");
  let currentTab = splitPathname[1]?.split("=")[1];
  let isModal = splitPathname[2]?.split("=")[1];

  // const getProviders = useAppSelector(
  //   (state) => state?.providers?.getProviders
  // );

  // useEffect(() => {
  //   dispatch(getProvidersThunk());
  // }, []);

  // Providers /////
  const {
    getProviders,
    loading: loadingProvider,
    providersPage,
    providersFilterItems,
    lastPage: providerLastPage,
    searchValueProviders,
    selectedFilterItemsProviders,
    sortByProviderR,
  } = useAppSelector((state) => state?.providers);

  useEffect(() => {
    dispatch(resetProvidersData());
    dispatch(resetProvidersPage());
  }, [
    providersFilterItems?.searchVal,
    providersFilterItems?.filterValue,
    providersFilterItems?.sortType,
    providersFilterItems?.status,
  ]);
  useEffect(() => {
    dispatch(getProvidersUniqueTenantNameThunk());
    dispatch(getProvidersUniqueTenantTypeThunk());
  }, []);
  useEffect(() => {
    dispatch(
      getProvidersByFilterThunk({
        filterColumn: providersFilterItems?.filterColumn
          ? providersFilterItems?.filterColumn
          : "",
        filterValue: providersFilterItems?.filterValue
          ? providersFilterItems?.filterValue
          : "",
        searchVal: providersFilterItems?.searchVal
          ? providersFilterItems?.searchVal
          : "",
        sortColumn: providersFilterItems?.sortColumn
          ? providersFilterItems?.sortColumn
          : "",
        sortType: providersFilterItems?.sortType
          ? providersFilterItems?.sortType
          : "",
        providersPage,
        status: providersFilterItems.status ? providersFilterItems.status : "",
      })
    );
  }, [
    providersPage,
    providersFilterItems?.searchVal,
    providersFilterItems?.filterValue,
    providersFilterItems?.sortType,
    providersFilterItems?.status,
  ]);

  const providersInnerRef = useRef();
  const onScrollProviders = () => {
    if (providersInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } =
        providersInnerRef.current;

      if (
        scrollTop + clientHeight + +5 >= scrollHeight &&
        providersPage < providerLastPage
      ) {
        dispatch(setProvidersPage(providersPage));
      }
    }
  };
  const [uniqueProvidersData, setuniqueProvidersData] = useState<any>();

  useEffect(() => {
    if (getProviders && Array.isArray(getProviders)) {
      const uniqueProvidersData = getProviders.filter(
        (item: any, index: number, array: any) => {
          return array.findIndex((obj: any) => obj.id === item.id) === index;
        }
      );
      setuniqueProvidersData(uniqueProvidersData);
    }
  }, [getProviders]);

  // Providers End ////
  useEffect(() => {
    if (isModal === "true") {
      localStorage.setItem("flagHere", JSON.stringify(isModal));
    } else {
      localStorage.setItem("flagHere", JSON.stringify(false));
    }
  }, [isModal]);

  // let tab = currentTab[1]?.split("=")

  let currentUUID = uuidFromParams[1];
  const [isDropdownOpenProviders, setIsDropdownOpenProviders] = useState(false);

  useEffect(() => {
    if (currentTab === "clinical") {
      dispatch(setHasClinicalFromURL());
    } else {
      dispatch(resetHasClinicalFromURL());
    }
  }, [currentTab]);

  // let findUUID = getAllClientsDataThunk?.data?.data.find(
  //   (elem: any) => elem?.user_uuid === currentUUID
  // );

  const svgParentRefProviders = useRef<any>();

  ///////////////////////////////////////////////////////////////////////////////////////
  const clientStatusDetailsNums = useAppSelector(
    (state) => state.common.domainDetail.client_status
  );
  const clientStatusDetailsArr = useAppSelector(
    (state) => state.common.statusDetailsData?.data
  );
  // // console.log(clientStatusDetailsArr, "clientStatusDetailsArr");
  useEffect(() => {
    dispatch(resetStatusDetails());
    dispatch(getStatusDetailsThunk(clientStatusDetailsNums));
  }, [clientStatusDetailsNums]);
  ///////////////////////////////////////////////////////////////////////////////////////

  const [findUUID, setfindUUID] = useState<any>(null);
  const { selectedProspects, selectedSortValuesProspects, searchValProspects } =
    useAppSelector((state) => state?.prospects);
  useEffect(() => {
    const fetchData = async () => {
      const res = await api.get(
        `/users/get_user_by_uuid?user_uuid=${currentUUID}`
      );
      const actualData = await res?.data;
      setfindUUID(actualData);
    };
    if (currentUUID) {
      fetchData();
    }
  }, [currentUUID]);

  let encryptedId = window.btoa(findUUID?.data?.id);
  if (findUUID?.data?.id) {
    navigate(`/people/clients/${encryptedId}`);
  }

  // GOTO DETAILS WHEN PAGE LOADS EVERYTIME
  useEffect(() => {
    localStorage.removeItem("activeTab");
    // dispatch(clearGraphData())
  }, []);

  // // console.log(getAllClientsDataThunk,"getAllClientsDataThunk")

  useEffect(() => {
    if (dynamicClientId) {
      // dispatch(getClientsThunk({ dynamicClientId }));
      dispatch(getUserTypesThunk());

      dispatch(getUserRolesClientsThunk({ dynamicClientId }));
    }
  }, [dynamicClientId]);

  const { user } = useAppSelector((state) => state.common);
  const isAdminActive = user?.tenant?.id === user?.tenant?.parent_id;

  const isAdmin = useAppSelector(
    (state) => state?.common?.user?.roles[0]?.desc
  );
  // let isAdminActive = isAdmin === "Administrator";
  useEffect(() => {
    dispatch(
      getClientsByFilterThunk({
        filterColumn: ClientFilterItems?.filterColumn
          ? ClientFilterItems?.filterColumn
          : "",
        filterValue: ClientFilterItems?.filterValue
          ? ClientFilterItems?.filterValue
          : "",
        searchVal: ClientFilterItems?.searchVal
          ? ClientFilterItems?.searchVal
          : "",
        sortColumn: ClientFilterItems?.sortColumn
          ? ClientFilterItems?.sortColumn
          : "",
        sortType: ClientFilterItems?.sortType
          ? ClientFilterItems?.sortType
          : "",
        ClientPage,
        dynamicId: dynamicClientId,
        status: ClientFilterItems.status ? ClientFilterItems.status : "",
      })
    );
  }, [
    ClientPage,
    ClientFilterItems?.searchVal,
    ClientFilterItems?.filterValue,
    ClientFilterItems?.sortType,
    ClientFilterItems?.status,
    dynamicClientId,
  ]);
  const dropdownRefProviders = useRef<any>();

  const [stageTwoToGoalsModal, setstageTwoToGoalsModal] = useState(false);
  const dropdownRefClients = useRef<any>();
  const svgParentRefClients = useRef<any>();
  const [isDropdownOpenClients, setIsDropdownOpenClients] = useState(false);
  const { selectedFilterItems, sortbyClients, searchValueClients } =
    useAppSelector((state) => state?.people);

  const { filterIconColor } = useAppSelector((state) => state?.common);
  const handleClickRow = (e: any, id: any) => {
    if (!isDropdownOpenClients && !isAdminActive) {
      navigate(`/people/clients/${window.btoa(id)}`);
    } else if (isAdminActive) {
      navigate(`/providers/${window.btoa(id)}`);
    }
    let getLocalStorage: any = localStorage.getItem("currentClientNumber");
    if (getLocalStorage) {
      getLocalStorage =
        parseInt(getLocalStorage) == 5 ? 1 : parseInt(getLocalStorage) + 1;
    } else {
      getLocalStorage = 1;
    }
    localStorage.setItem("currentClientNumber", getLocalStorage);
  };

  useEffect(() => {
    const handleClickOutsideClients = (event: any) => {
      if (
        dropdownRefClients.current &&
        !dropdownRefClients.current.contains(event.target) &&
        svgParentRefClients.current &&
        !svgParentRefClients.current.contains(event.target)
      ) {
        setIsDropdownOpenClients(false);
      }
    };
    document.addEventListener("click", handleClickOutsideClients);
    return () => {
      document.removeEventListener("click", handleClickOutsideClients);
    };
  }, []);
  useEffect(() => {
    const handleClickOutsideClients = (event: any) => {
      if (
        dropdownRefProviders.current &&
        !dropdownRefProviders.current.contains(event.target) &&
        svgParentRefProviders.current &&
        !svgParentRefProviders.current.contains(event.target)
      ) {
        setIsDropdownOpenProviders(false);
      }
    };
    document.addEventListener("click", handleClickOutsideClients);
    return () => {
      document.removeEventListener("click", handleClickOutsideClients);
    };
  }, []);
  const { domainDetail } = useAppSelector((state) => state?.common);
  let businessType = domainDetail?.businessmodel_type;

  const columns = [
    { header: "Name", accessor: "first_name" },
    { header: "Contact", accessor: "email" },
    { header: "DOB", accessor: "date_of_birth" },
    { header: "Funding Type", accessor: "funding_type" },
    { header: "Residence", accessor: "residence_name" },
    { header: "Status", accessor: "status" },
  ];
  let b2cColsClients = [
    { header: "Name", accessor: "first_name" },
    { header: "Email", accessor: "email" },
    { header: "Phone", accessor: "phone" },
    { header: "Status", accessor: "status" },
  ];

  const uniqueClientData = client?.filter(
    (item: any, index: number, array: any) => {
      return array?.findIndex((obj: any) => obj.id === item.id) === index;
    }
  );
  const { clients_label } = useAppSelector(
    (state) => state?.common?.domainDetail
  );

  useEffect(() => {
    dispatch(setProfileTypeSticky(clients_label));
  }, [clients_label]);
  const { profile_name } = useAppSelector((state) => state.common.domainDetail);
  return (
    <Fragment>

      <div style={{
        position:"relative"
      }}>

    {stageTwoToGoalsModal && (
        <CommonQuickMenu
          setshowGoalsModal={(value: any) => {}}
          mainType={"listing"}
          setstageTwoToGoalsModal={setstageTwoToGoalsModal}
        />
      )}
       <CommonUpperBox
        onClickPlus={() => {
          setstageTwoToGoalsModal(true);
        }}
        type={"listing"}
        title={clients_label}
      />
      </div>
     

   
      {isAdminActive ? (
        <Table
          isLoading={loadingProvider}
          tableRef={tableRef}
          tableBodyRef={providersInnerRef}
          onScroll={onScrollProviders}
          dynamicHeaderColor={domainDetail?.listingtbl_color}
          onClickRow={handleClickRow}
          isAdminActive={true}
          statusType="providersScreen"
          thData={providerColumns}
          allClients={uniqueProvidersData}
          classNameTr="all-prospects-table-head"
          classNameRow="all-prospects-table-body"
          dropdownRef={dropdownRefProviders}
          isDropdownOpen={isDropdownOpenProviders}
          onClickSvg={() => setIsDropdownOpenProviders(true)}
          type="providers"
          itemsArr={clientStatusDetailsArr}
          svgParentRef={svgParentRefProviders}
          fill={
            selectedFilterItemsProviders?.length > 0 ||
            searchValueProviders?.length > 0 ||
            sortByProviderR?.length > 0
              ? filterIconColor
              : "#D8D3D3"
          }
        />
      ) : (
        <Table
          isLoading={loading}
          tableRef={tableRef}
          tableBodyRef={clientInnerRef}
          onScroll={onScroll}
          dynamicHeaderColor={domainDetail?.listingtbl_color}
          statusType="clientScreen"
          thData={businessType === "B2B" ? b2cColsClients : columns}
          itemsArr={clientStatusDetailsArr}
          classNameTr={
            businessType === "B2B"
              ? "all-business-table-head"
              : "all-client-table-head"
          }
          type="clients"
          dropdownRef={dropdownRefClients}
          isDropdownOpen={isDropdownOpenClients}
          onClickSvg={() => setIsDropdownOpenClients(true)}
          svgParentRef={svgParentRefClients}
          allClients={uniqueClientData}
          onClickRow={handleClickRow}
          classNameRow={
            businessType === "B2B"
              ? "all-business-table-head"
              : "all-client-table-body"
          }
          fill={
            selectedFilterItems?.length > 0 ||
            searchValueClients?.length > 0 ||
            sortbyClients?.length > 0
              ? filterIconColor
              : "#D8D3D3"
          }
        />
      )}
      <CircularNoteTrigger
        type={clients_label}
        // title={businessType === "B2B" ? "Employees" : staff_label}
        title={profile_name}
      />
  
    </Fragment>
  );
};

export default ClientPage;
