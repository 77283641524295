// @ts-nocheck
const BasketballSVG = () => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="23"
        viewBox="0 0 14 23"
        fill="none"
      >
        <circle
          cx="7"
          cy="11.25"
          r="6"
          fill="#F3F3F3"
          stroke="white"
          strokeWidth="1.2"
        />
        <path
          d="M11.929 7.06595C11.3862 7.65244 10.6651 8.13366 9.82538 8.46985C8.98562 8.80603 8.05126 8.98754 7.09949 8.99938C6.14771 9.01122 5.20584 8.85304 4.35168 8.53792C3.49751 8.22281 2.75558 7.75978 2.18716 7.18711"
          stroke="white"
          strokeWidth="1.2"
        />
        <path
          d="M11.929 15.4341C11.3862 14.8476 10.6651 14.3663 9.82538 14.0302C8.98562 13.694 8.05126 13.5125 7.09949 13.5006C6.14771 13.4888 5.20584 13.647 4.35168 13.9621C3.49751 14.2772 2.75558 14.7402 2.18716 15.3129"
          stroke="white"
          strokeWidth="1.2"
        />
        <path d="M7 5.25V17.25" stroke="white" strokeWidth="1.2" />
        <path d="M13 11.25H1" stroke="white" strokeWidth="1.2" />
      </svg>
    </div>
  );
};

export default BasketballSVG;
