import { useEffect, useRef, useState } from "react";
import "./Time.css";
import ClockIconSVG from "../../pages/Comms/Data/ClockIconSVG";
const TimePicker = ({
  setTime,
  activityIndex,
  type,
  time,
  istimepickeropen,
  startTime,
  setisModify = () => {},
  isStart,
  tp,
  setWhichTimePickerOpen = () => {},
  display,
  setisT1Open,
  setisT2Open,
  sethitStartTimeToSetEndTime = () => {},
  setEndTime = () => {},
  selectedHoursAndMinutesFromTimePicker1,
  isThreads
}: any) => {
  const [isOpen, setIsOpen] = useState(false);

  const [selectedHour, setSelectedHour] = useState<string>(
    time?.length > 0 ? time?.toString().slice(0, 2) : "--"
  );
  const [selectedMinute, setSelectedMinute] = useState<string>(
    time?.length > 0 ? time?.toString().slice(3, 5) : "--"
  );

  const [selectedFormat, setSelectedFormat] = useState(
    time ? time.slice(-2) : "AM"
  );
  const timePickerRef = useRef<any>(null);
  const [num, setNum] = useState<number>(1);
const [  selectedFormatValueThreads, setselectedFormatValueThreads] = useState("");

  // console.log(time,'______time')
  useEffect(() => {
    function handleClickOutsideOfTimePicker(event: any) {
      if (
        timePickerRef.current &&
        !timePickerRef.current.contains(event.target)
      ) {
        if (selectedHour && selectedMinute && selectedFormat) {
          const formattedHour = String(selectedHour).padStart(2, "0");
          const formattedMinute = selectedMinute.padStart(2, "0");
          if (type === "goalsClinical") {
            setTime({
              timeVal: `${formattedHour}:${formattedMinute}${selectedFormat}`,
              activityIndexVal: activityIndex.toString(),
            });
          } else if (setTime || setEndTime) {
            let hour = formattedHour;
            let minute = formattedMinute || '00'; 
            let format = selectedFormat || 'AM'; 
        
            setTime(`${hour}:${minute}${format}`);
            // setisModify(true);
            setEndTime(`${hour}:${minute}${format}`);
          }
  
        }
      }
    }

    document.addEventListener("mousedown", handleClickOutsideOfTimePicker);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideOfTimePicker);
    };
  }, [
    selectedHour,
    selectedMinute,
    selectedFormat,
    activityIndex,
    type,
    setTime,
    time,
    istimepickeropen,
  ]);

  useEffect(() => {
    if (selectedHour != "" && selectedMinute != "" && selectedFormat) {
      const formattedHour = String(selectedHour).padStart(2, "0");
      const formattedMinute = selectedMinute.padStart(2, "0");
      if (type === "goalsClinical") {
        setTime({
          timeVal: `${formattedHour}:${formattedMinute}${selectedFormat}`,
          activityIndexVal: activityIndex.toString(),
        });
      } else if (setTime) {
        let hour = formattedHour;
        let minute = formattedMinute || '00'; 
        let format = selectedFormat || 'AM'; 
    
        setTime(`${hour}:${minute}${format}`);
      }
      if (num % 2 === 1 && num > 1) {
        setIsOpen(false);
        // setIsOpenEnd(false);
      }
    }
    // setisModify(true);
  }, [
    selectedHour,
    selectedMinute,
    selectedFormat,
    activityIndex,
    type,
    startTime,
    time,
  ]);
  const handleTogglePicker = (isStart: string) => {
    if (tp === 1) {
      setisT2Open(-1);
      setWhichTimePickerOpen((prev: number) => prev * -1);
    } else if (tp === 2) {
      setisT1Open(-1);
      setWhichTimePickerOpen((prev: number) => (prev === 2 ? -1 : 2));
    }
    sethitStartTimeToSetEndTime({
      isHit: true,
      selectedHours:
        new Date().getHours() > 12
          ? (new Date().getHours() - 12).toString().padStart(2, "0")
          : new Date().getHours() === 0
          ? "12"
          : new Date().getHours().toString() || "12",
      selectedMinutes: new Date().getMinutes().toString().padStart(2, "0") || "00",
    });
    if (selectedHour === "--") {
      setSelectedHour(
        new Date().getHours() > 12
          ? (new Date().getHours() - 12).toString().padStart(2, "0")
          : new Date().getHours() === 0
          ? "12"
          : new Date().getHours().toString() || "12"
      );
      setSelectedMinute(new Date().getMinutes().toString().padStart(2, "0") || "00");
    }

    // setIsOpen(!isOpen)
  };

  const handleTimeChangeHour = (event: React.ChangeEvent<HTMLInputElement>) => {
    setisModify(true);
    sethitStartTimeToSetEndTime({
      isHit: true,
      selectedHours:
        new Date().getHours() > 12
          ? (new Date().getHours() - 12).toString().padStart(2, "0")
          : new Date().getHours() === 0
          ? "12"
          : new Date().getHours().toString(),
      selectedMinutes: new Date().getMinutes().toString().padStart(2, "0"),
    });
    let value = event.target.value;
    if (value.length > 2) {
      value = value.slice(0, 2);
    }

    setSelectedHour(value);
  };

  const handleHourChange = (hour: any, e: any) => {
    setisModify(true);
    sethitStartTimeToSetEndTime((prev: any) => ({
      ...prev,
      selectedHours: hour,
    }));
    if (type === "endTime") {
      // if(hour){
      //   setSelectedMinute("00")
      //   setSelectedFormat("PM");
      // }
      const format1 = startTime.toString().slice(5, 7);

      if (selectedFormat === format1) {
        if (parseInt(hour) > parseInt(startTime.toString().slice(0, 2))) {
          setSelectedHour(hour);
        } else if (
          parseInt(hour) === parseInt(startTime.toString().slice(0, 2))
        ) {
          if (
            parseInt(selectedMinute) >=
            parseInt(startTime.toString().slice(3, 5))
          ) {
            setSelectedHour(hour);
          }
        }
      } else {
        setSelectedHour(hour);
      }
    } else {
      setSelectedHour(hour);
      // if(hour){
      //   setSelectedMinute("00")
      //   setSelectedFormat("PM");
      // }
      sethitStartTimeToSetEndTime({
        isHit: true,
        selectedHours: hour,
        selectedMinutes: new Date().getMinutes().toString().padStart(2, "0") ,
      });
    }

    setNum(num + 1);
  };

  const handleTimeChangeMin = (event: React.ChangeEvent<HTMLInputElement>) => {
    setisModify(true);
    let value = event.target.value;
    // const isValidTimeMin = /^[0-5]?[0-9]$/.test(value);
    if (value.length > 2) {
      value = value.slice(0, 2);
    }

    setSelectedMinute(value);
  };
  const handleMinuteChange = (value: any, e: any) => {
    setisModify(true);
    sethitStartTimeToSetEndTime((prev: any) => ({
      ...prev,
      selectedMinutes: value,
    }));
    if (type === "endTime") {
      // if(value){
      //   setSelectedHour("12")
      //   setSelectedFormat("PM");
      // }
      const format1 = startTime.toString().slice(5, 7);
      const format2 = startTime.toString().slice(0, 2);
      if (selectedFormat === format1 && selectedHour === format2) {
        if (parseInt(value) >= parseInt(startTime.toString().slice(3, 5))) {
          setSelectedMinute(value);
        }
      } else {
        setSelectedMinute(value);
      }
    } else {
      setSelectedMinute(value);
      // if(value){
      //   setSelectedHour("12")
      //   setSelectedFormat("PM");
      // }
    }
    setNum(num + 1);
  };

  const handleTimeChangeAMPM = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setisModify(true);

    const value = event.target.value;

    if (type === "endTime") {
      // if(value){
      //   setSelectedHour("12")
      //   setSelectedMinute("00");
      // }
      if (startTime.slice(5, 7) === "PM") {
        setSelectedFormat("PM");
        sethitStartTimeToSetEndTime((prev: any) => ({
          ...prev,
          selectedFormat: "PM",
        }));
      } else {
        setSelectedFormat(value);
        sethitStartTimeToSetEndTime((prev: any) => ({
          ...prev,
          selectedFormat: value,
        }));
      }
    } else {
      // if(value){
      //   setSelectedHour("12")
      //   setSelectedMinute("00");
      // }
      setSelectedFormat(value);
      sethitStartTimeToSetEndTime((prev: any) => ({
        ...prev,
        selectedFormat: value,
      }));
    }
  };
  const handleFormatChange = (format: any, e: any) => {
    setisModify(true);
    sethitStartTimeToSetEndTime((prev: any) => ({
      ...prev,
      selectedFormat: format,
    }));
    if (type === "endTime") {
      if (startTime.slice(5, 7) === "PM") {
        setSelectedFormat("PM");
      } else {
        setSelectedFormat(format);
      }
    } else {
      setSelectedFormat(format);
    }
  };
  useEffect(() => {
    if (type === "endTime") {
      if (startTime?.slice(5, 7) === "PM") {
        setSelectedFormat("PM");
      }
    }
  });

  return (
    <div className={`${isThreads ? "threads_modal_time_picker" : "time-picker"}`}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <b>
          <input
            type="number"
            value={selectedHour.toString()}
            onChange={(e) => {
              if (
                parseInt(e.target.value) < 1 ||
                parseInt(e.target.value) > 12 ||
                e.target.value === ""
              ) {
                setSelectedHour("");
              } else {
                handleTimeChangeHour(e);
              }
            }}
            placeholder="--"
            className="TimePicker_Input"
          />
          <label style={{fontWeight:100}}>:</label>
          <input
          style={{
            marginLeft: isThreads ? "6px" : "unset"
          }}
            type="number"
            value={selectedMinute.toString()}
            onChange={(e) => {
              if (
                parseInt(e.target.value) < 0 ||
                parseInt(e.target.value) > 59 ||
                e.target.value === ""
              ) {
                setSelectedMinute("");
              } else {
                handleTimeChangeMin(e);
              }
            }}
            placeholder="--"
            className="TimePicker_Input"
          />
          {
            isThreads && (
              <>
                <input
  style={{
    marginLeft: isThreads ? "3px" : "unset"
  }}
  type="text"
  value={selectedFormatValueThreads}
  onChange={(e) => {
    let value = e.target.value.toLowerCase();
    // @ts-ignore
    if (e?.nativeEvent?.inputType === 'deleteContentBackward') {
      setselectedFormatValueThreads('');
      return;
    }
    if (value === 'a') {
      value = 'am';
    } else if (value === 'p') {
      value = 'pm';
    }
    if (/^(a|p|am|pm)$/.test(value)) {
      setselectedFormatValueThreads(value);
    }
  }}
  placeholder="--"
  className="TimePicker_Input"
/>

              </>
            )
          }
          
          {
            !isThreads && (
              <>
                   <select
            value={selectedFormat}
            onChange={(e) => {
              handleTimeChangeAMPM(e);
            }}
            style={{ background: "white", fontSize: "11px" }}
          >
            <option value="AM">AM</option>
            <option value="PM">PM</option>
          </select>
              </>
            )
          }
     
        </b>
        {
          !isThreads && (
            <>
               <div onClick={() => handleTogglePicker(isStart)}>
          <ClockIconSVG />
        </div>
            </>
          )
        }
     
      </div>

      {true && (
        <div
          className="picker-dropdown"
          style={{
            display: display ? "" : "none",
          }}
        >
          <div className="picker-column hour-picker-scroll">
            {Array.from({ length: 12 }, (elem, i) => (
              <div
                key={i}
                onClick={(e) => {
                  handleHourChange(i + 1 < 10 ? `0${i + 1}` : `${i + 1}`, e);
                }}
                className={`picker-item ${
                  selectedHour === (i + 1 < 10 ? `0${i + 1}` : `${i + 1}`) ||
                  selectedHour === `${i + 1}`
                    ? "selected"
                    : ""
                }`}
              >
                {i + 1}
              </div>
            ))}
          </div>
          <div className="picker-column minute-picker">
            <div className="minute-picker-scroll">
              {Array.from({ length: 60 }, (elem, i) => (
                <div
                  key={i}
                  onClick={(e) =>
                    handleMinuteChange(i < 10 ? `0${i}` : `${i}`, e)
                  }
                  className={`picker-item ${
                    selectedMinute === (i < 10 ? `0${i}` : `${i}`)
                      ? "selected"
                      : ""
                  }`}
                >
                  {i < 10 ? `0${i}` : `${i}`}
                </div>
              ))}
            </div>
          </div>
          
          <div className="picker-column">
            <div
              onClick={(e) => handleFormatChange("AM", e)}
              className={`picker-item ${
                selectedFormat === "AM" ? "selected" : ""
              }`}
            >
              AM
            </div>
            <div
              onClick={(e) => handleFormatChange("PM", e)}
              className={`picker-item ${
                selectedFormat === "PM" ? "selected" : ""
              }`}
            >
              PM
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TimePicker;
