import React from "react";
const RightArrowSvg = ({ dynamicColor }: any) => {
  // const {};
  return (
    <div>
      {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
        <g data-name="19-Arrow Right">
          <path d="M16 0a16 16 0 1 0 16 16A16 16 0 0 0 16 0zm0 30a14 14 0 1 1 14-14 14 14 0 0 1-14 14z" />
          <path d="m26.71 15.29-7-7-1.42 1.42 5.3 5.29H5v2h18.59l-5.29 5.29 1.41 1.41 7-7a1 1 0 0 0 0-1.41z" />
        </g>
      </svg> */}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 25 25"
        strokeWidth="2"
      >
        <title>Artboard-34</title>
        <g id="Right-2" data-name="Right">
          <polygon
            points="17.5 5.999 16.793 6.706 22.086 11.999 1 11.999 1 12.999 22.086 12.999 16.792 18.294 17.499 19.001 24 12.499 17.5 5.999"
            // fill="#232326"
            fill={dynamicColor}
          />
        </g>
      </svg>
      {/* <svg
        xmlns="http://www.w3.org/2000/svg"
        width="50"
        height="2"
        viewBox="0 0 67 2"
        fill="none"
      >
        <path
          d="M1 1L66 0.999994"
          stroke="#1D2759"
          strokeLinecap="square"
          strokeLinejoin="round"
          stroke-dasharray="4"
        />
      </svg> */}
    </div>
  );
};

export default React.memo(RightArrowSvg);
