export const financeColumns = [
  { header: "check", accessor: "check" },
  { header: "Invoice Number", accessor: "invoice_number" },
  { header: "Invoice Date", accessor: "invoice_date" },
  { header: "Invoice Sent On", accessor: "invoice_sent" },
  { header: "Status", accessor: "status" },
  { header: "Action", accessor: "actionFinance" },
];
export const financeData = [
  {
    check: "check",
    invoice_number: "INV-001",
    invoice_date: "01-01-23",
    invoice_sent: "01-02-23",
    status: 1,
  },
  {
    check: "check",
    invoice_number: "INV-002",
    invoice_date: "01-02-23",
    invoice_sent: "01-03-23",
    status: 1,
  },
  {
    check: "check",
    invoice_number: "INV-003",
    invoice_date: "01-03-23",
    invoice_sent: "01-04-23",
    status: 1,
  },
  {
    check: "check",
    invoice_number: "INV-004",
    invoice_date: "01-04-23",
    invoice_sent: "01-05-23",
    status: 1,
  },
];
