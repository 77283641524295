import React from "react";

interface ClockIconSVGProps {
  width?: string;
  height?: string;
}

const ClockIconSVG = ({ width, height }: ClockIconSVGProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{marginTop: "5px",marginLeft: "13px"}}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 1.2C3.34903 1.2 1.2 3.34903 1.2 6C1.2 8.65097 3.34903 10.8 6 10.8C8.65097 10.8 10.8 8.65097 10.8 6C10.8 3.34903 8.65097 1.2 6 1.2ZM0 6C0 2.68629 2.68629 0 6 0C9.31371 0 12 2.68629 12 6C12 9.31371 9.31371 12 6 12C2.68629 12 0 9.31371 0 6ZM6 2.4C6.33137 2.4 6.6 2.66863 6.6 3V5.4H9C9.33137 5.4 9.6 5.66863 9.6 6C9.6 6.33137 9.33137 6.6 9 6.6H6C5.66863 6.6 5.4 6.33137 5.4 6V3C5.4 2.66863 5.66863 2.4 6 2.4Z"
        fill="black"
      />
    </svg>
  );
};

ClockIconSVG.defaultProps = {
  width: "12",
  height: "12",
};

export default ClockIconSVG;
