import { Switch } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import _ from "lodash";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../Controller/redux/Hooks";
import {
  createReportsHub,
  getUserRolesReportsHub,
  getReportsHub,
  updateReportsHub,
} from "../../../Controller/redux/features/ReportsHubFeature/ReportsHubSlice";
import { setShowStickyNote } from "../../../Controller/redux/features/CommonFeature/commonSlice";
import { toast } from "react-toastify";

const ReportsHubStickyForm = () => {
  const dispatch = useAppDispatch();
  const dynamicColor = useAppSelector((state) => state?.common?.dynamicColor);

  const dynamicReportsHubId = localStorage.getItem("dynamicReportsHubId");

  const { domainDetail } = useAppSelector((state) => state?.common);
  const { currentEditReportsHub, loading, getUserRoleReportsHub } =
    useAppSelector((state) => state?.reportsHub);

  const [commonBody, setcommonBody] = useState<any>({
    report_name: "",
    status: 1,
  });
  const [reportsHubTypeArr, setReportsHubTypeArr] = useState<any>([]);
  const [userRoles, setUserRoles] = useState([]);

  useEffect(() => {
    setcommonBody(currentEditReportsHub || { report_name: "", status: 1 });
    dispatch(getUserRolesReportsHub({ id: dynamicReportsHubId }));
  }, []);

  useEffect(() => {
    if (
      getUserRoleReportsHub?.data &&
      getUserRoleReportsHub?.data?.length > 0
    ) {
      setUserRoles(getUserRoleReportsHub?.data);
    }
  }, [getUserRoleReportsHub]);

  useEffect(() => {
    if (domainDetail && domainDetail?.report_type) {
      const reportsHubType = domainDetail?.report_type.split(",");
      setReportsHubTypeArr(reportsHubType);
    }
  }, [domainDetail]);

  const handleAddForm = async () => {
    if (!commonBody?.report_name?.trim()) {
      toast.error("Please enter report name");
      return;
    }
    if (currentEditReportsHub) {
      dispatch(
        updateReportsHub({
          report_name: commonBody?.report_name,
          status: commonBody?.status,
          type: dynamicReportsHubId,
          report_id: currentEditReportsHub.id,
          role: commonBody?.role,
        })
      ).then((response: any) => {
        if (response.error) {
          toast.error(response?.payload || "Error updating Report");
          return;
        }
        toast.success("Report Updated Successfully!", {
          // style: {},
          progressStyle: { backgroundColor: dynamicColor },
          icon: (
            <span
              style={{
                marginRight: "8px",
                borderRadius: "50%",
                width: "7rem",
                height: "35px",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: dynamicColor,
                backgroundColor: "white",
                fontSize: "1.5rem",
                border: `3px solid ${dynamicColor}`,
              }}
            >
              ✓
            </span>
          ),
        });
        dispatch(getReportsHub({ type: dynamicReportsHubId }));
        dispatch(setShowStickyNote(false));
      });
      return;
    }
    dispatch(
      createReportsHub({
        report_name: commonBody?.report_name,
        status: commonBody?.status || 0,
        role: commonBody?.role,
        type: dynamicReportsHubId,
      })
    ).then((response: any) => {
      if (response.error) {
        toast.error(response?.payload || "Error creating reportsHub");
        return;
      }
      toast.success("Report created successfully");
      dispatch(getReportsHub({ type: dynamicReportsHubId }));
      dispatch(setShowStickyNote(false));
    });
  };

  const debouncedApiCall = _.debounce(handleAddForm, 300);

  useEffect(() => {
    const handleClick = () => {
      debouncedApiCall();
    };

    document?.getElementById("saveBTN")?.addEventListener("click", handleClick);

    return () => {
      document
        ?.getElementById("saveBTN")
        ?.removeEventListener("click", handleClick);
    };
  }, [debouncedApiCall]);

  return (
    <div className="payload_area">
      <div className="main_area">
        <div className="side_bar"></div>
        <div className="side_bar" />

        <div className="middle-block">
          <p className="note-title">
            {currentEditReportsHub ? "Update" : "Add"} Report
          </p>
          <div className="middle-section-content middle-section-others">
            <div className="field">
              <span className="field-title">
                {`Name`}
                <span className="asterixs_color"> * </span>
              </span>
              <input
                style={{
                  backgroundColor: "white",
                }}
                className="input-fields-of-sticky-note"
                placeholder={`Enter Name`}
                onChange={(e) =>
                  setcommonBody((prevState: any) => ({
                    ...prevState,
                    report_name: e.target.value,
                  }))
                }
                value={commonBody?.report_name}
              />

              <span className="field-border field-border-add-form"></span>
            </div>

            <div className="field">
              <span className="field-title">
                Category
                <span className="asterixs_color"> * </span>
              </span>
              <select
                required
                id="category"
                className="category-select"
                onChange={(e) =>
                  setcommonBody({ ...commonBody, role: e.target.value })
                }
                value={commonBody?.role || currentEditReportsHub?.category_id}
              >
                <option value="">Please Select...</option>

                {userRoles?.map((elem: any, index: number | any) => {
                  let isExist: any = reportsHubTypeArr.includes(
                    `${elem?.desc}`
                  );
                  if (isExist) {
                    return (
                      <option key={index} value={elem?.id}>
                        {elem.desc}
                      </option>
                    );
                  }
                })}
              </select>
            </div>

            <div className="switch_custom_form_sticky_wrapper">
              <label>Active</label>
              <Switch
                colorScheme="teal"
                size="md"
                isChecked={commonBody?.status == 1 ? true : false}
                zIndex={9}
                onChange={(e) => {
                  setcommonBody({
                    ...commonBody,
                    status: e.target.checked ? 1 : 0,
                  });
                }}
              />
            </div>
          </div>

          <div className="bottomBlock save_btn_custom_form_sticky">
            <button className="save-btn" id="saveBTN">
              {loading ? "loading" : "Save"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportsHubStickyForm;
