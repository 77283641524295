export const extractTimeComponents = (timeString) => {
    if (!timeString || typeof timeString !== "string" || timeString.length < 4) {
      throw new Error("Invalid time string");
    }
  
    const format = timeString.slice(-2);
    const timePart = timeString.slice(0, -2);
    
    if (!timePart.includes(":")) {
      throw new Error("Invalid time format");
    }
  
    const [hour, minute] = timePart.split(":");
  
    return {
      hour: parseInt(hour, 10),
      minute: parseInt(minute, 10),
      format: format
    };
  };
  