import React, {useEffect, useState} from "react";
import ReactDatePicker from "react-datepicker";
import "./Styles/DateTimePicker.css";
import { TimePickerIntervals } from "./Components/TimePickerIntervals";

// Helper function to validate the date format
const isValidDate = (date: Date, format: string = "dd-MM-yyyy") => {
  const regex = /^\d{2}-\d{2}-\d{4}$/; // Modify this regex based on your format
  const dateString = date.toLocaleDateString("en-GB").replace(/\//g, "-");
  return regex.test(dateString);
};

const DateTimePicker = ({
  dateTime,
  setDateTime,
  ends,
  hasTimepicker,
  handleChange = () => {},
  selectedDate,
  setStartDateDaybook = () => {},
  setStartTime,
  setEndTime,
  startTime,
  endTime,
  handleStartTimeChange,
  selectedCurrentElement,
  handleEndTimeChange,
  setEndDateDaybook = () => {},
  selectedEndDate,
}: any) => {
  // Validate selected dates
  const validStartDate = isValidDate(selectedDate) ? selectedDate : new Date();
  const validEndDate = isValidDate(selectedEndDate) ? selectedEndDate : new Date();
  const [ selectedReactDatePickerDate ,setselectedReactDatePickerDate] = useState("");

  useEffect(() => {
    if(selectedDate)[
      setselectedReactDatePickerDate(selectedDate)
    ]
  },[selectedDate])

  console.log('selectedDate', selectedDate);
  return (
    <div className="date_time_picker_wrapper">
      <div className="title">Date Time Picker</div>
      <div className="start_section flex_row">
        <div className="date_time_label">Starts:</div>
        <div className="date_time_section">
          <ReactDatePicker
            placeholderText="dd-mm-yyyy"
            dateFormat={"dd-MM-yyyy"}
            onChange={(date) => {
              if (isValidDate(date)) {
                if (hasTimepicker) {
                  setStartDateDaybook(date);
                  handleChange(date);
                } else {
                  setDateTime((prev: string | any) => ({
                    ...prev,
                    startDate: date,
                  }));
                }
              } else {
                console.error("Invalid date format");
              }
            }}
            selected={hasTimepicker ? selectedReactDatePickerDate : dateTime?.startDate}
            className="date_input datepicker_box"
          />

          {hasTimepicker && (
            <>
              <TimePickerIntervals
                setTime={setStartTime}
                dateFormat={"dd-MM-yyyy"}
                handleSetTime={handleStartTimeChange}
                time={startTime}
                setDateTime={setDateTime}
                dateTime={dateTime}
              />
            </>
          )}
        </div>
      </div>
      {ends && (
        <>
          <div className="end_section flex_row">
            <div className="date_time_label">Ends:</div>
            <div className="date_time_section">
              <ReactDatePicker
                placeholderText="dd-mm-yyyy"
                minDate={dateTime?.startDate}
                dateFormat={"dd-MM-yyyy"}
                onChange={(date) => {
                  if (isValidDate(date)) {
                    if (hasTimepicker) {
                      setEndDateDaybook(date);
                      handleChange(date);
                    } else {
                      setDateTime((prev: string | any) => ({
                        ...prev,
                        startDate: date,
                      }));
                    }
                  } else {
                    console.error("Invalid date format");
                  }
                }}
                selected={hasTimepicker ? selectedReactDatePickerDate : dateTime?.endDate}
                className="date_input datepicker_box"
              />
              {hasTimepicker && (
                <>
                  <TimePickerIntervals
                    setTime={setEndTime}
                    time={endTime}
                    setDateTime={setDateTime}
                    dateTime={dateTime}
                    ends={ends}
                    handleSetTime={handleEndTimeChange}
                  />
                </>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default DateTimePicker;
