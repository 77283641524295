import { useEffect, useState } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../Controller/redux/Hooks";
import {
  getCommsForUserThunk,
  getCommsFromUserTypeThunk,
  resetAllCommsLimit,
} from "../../../../../Controller/redux/features/CommsFeature/commsSlice";
import SortSVG from "../../../Daybook/Data/SortSVG";
import { SearchSVG } from "../../../../components/Breadcrumb/Data";
import PlusSVG from "../../../People/ClientPage/Components/UniqueClientScreen/Components/FinanceTab/Data/PlusSVG";
import CreateComms from "../CreateCommsSection/Index";
import { getAllClinicalNotes } from "../../../../../Controller/redux/features/ClinicalFeature/clinicalSlice";
import NewCreateCommsBox from "../CreateCommsSection/NewCreateCommsBox";
import {setShowStickyNote} from "../../../../../Controller/redux/features/CommonFeature/commonSlice";

const GroupButton = ({
  selectedItem,
  clientId,
  dynamicClientId,
  setKeyPeople = () => {},
  dynamicProspectId,
  setSortTypeComms,
  setIsUnreadComms,
  setIsSubTaskOpen,
  isSubTaskOpen,
  setisCommsDetailIndex = () => {},
  isUserType,
  setAttachments = () => {},
  setselectedItemsArr1 = () => {},
  isClinicalNote,
  handleAddNote = () => {},
}: any) => {
  const dispatch = useAppDispatch();

  const [selectedBtn, setSelectedBtn] = useState("All");
  const [sortType, setSortType] = useState("desc");
  const [showCreateComms, setshowCreateComms] = useState(false);
  const [dayBookDetails, setDayBookDetails] = useState<any>({
    title: "",
    summary: "",
    assigned_to: "",
    tagged_user: [],
    date: "",
  });


  // useEffect(() => {
  //   if(selectedBtn == "New Message"){
    
  //   }
  // },[selectedBtn])

  const { allCommsLimit }: any = useAppSelector((state) => state?.comms);

  const [prevSortingType, setprevSortingType] = useState("");

  const handleClick = (res: string) => {
    if (res !== "Sort") {
      setprevSortingType(res);
    }
    setIsSubTaskOpen(-1);
    setAttachments([]);
    setKeyPeople([]);

    setselectedItemsArr1([]);
    if (res == "Sort") {
      // setIsUnreadComms(false);
      let selectedSortType = sortType === "asc" ? "desc" : "asc";
      setSortType(selectedSortType);
      setSortTypeComms(selectedSortType);

      if (selectedItem == 0) {
        if (isClinicalNote) {
          dispatch(
            getAllClinicalNotes({
              user_id: clientId,
              sortType: selectedSortType,
              unreadFlag: prevSortingType == "All" ? "no" : "yes",
              searchVal: "",
            })
          );
        } else {
          dispatch(
            getCommsForUserThunk({
              clientId,
              endpoint: "get_comms",
              sortType: selectedSortType,
              search: "",
              limit: allCommsLimit,
            })
          );
        }
      } else if (selectedItem == 1) {
        dispatch(
          getCommsForUserThunk({
            clientId,
            endpoint: "get_comms_of_sent_items",
            sortType: selectedSortType,
            search: "",
            limit: allCommsLimit,
          })
        );
      } else if (selectedItem == 2) {
        dispatch(
          getCommsFromUserTypeThunk({
            userType: dynamicClientId,
            search: "",
            sortType: selectedSortType,
            limit: allCommsLimit,
          })
        );
      } else if(selectedItem == 3) {
        dispatch(
          getCommsFromUserTypeThunk({
            userType: dynamicProspectId,
            search: "",
            sortType: selectedSortType,
            limit: allCommsLimit,
          })
        );
      }
    }

    if (res === "All") {
      // setIsUnreadComms(false);
      let selectedSortType = sortType === "asc" ? "desc" : "asc";
      setSortType(selectedSortType);
      if (selectedItem == 0) {
        if (isClinicalNote) {
          dispatch(
            getAllClinicalNotes({
              user_id: clientId,
              sortType: "",
              unreadFlag: "",
              searchVal: "",
            })
          );
        } else {
          dispatch(
            getCommsForUserThunk({
              clientId,
              endpoint: "get_comms",
              sortType: "",
              search: "",
              unread: "",
              limit: allCommsLimit,
            })
          );
        }
      } else if (selectedItem == 1) {
        dispatch(
          getCommsForUserThunk({
            clientId,
            endpoint: "get_comms_of_sent_items",
            sortType: "",
            search: "",
            unread: "",
            limit: allCommsLimit,
          })
        );
      } else if (selectedItem == 2) {
        dispatch(
          getCommsFromUserTypeThunk({
            userType: dynamicClientId,
            search: "",
            sortType: "",
            limit: allCommsLimit,
          })
        );
      } else {
        dispatch(
          getCommsFromUserTypeThunk({
            userType: dynamicProspectId,
            search: "",
            sortType: "",
            limit: allCommsLimit,
          })
        );
      }
    }

    if (res === "Unread") {
      if (isClinicalNote) {
        dispatch(
          getAllClinicalNotes({
            user_id: clientId,
            sortType: "",
            unreadFlag: "yes",
            searchVal: "",
          })
        );
      } else {
        // setIsUnreadComms(true);
        dispatch(
          getCommsForUserThunk({
            clientId,
            endpoint: "get_comms",
            sortType: "",
            search: "",
            unread: "yes",
            limit: allCommsLimit,
          })
        );
      }
    }

    if(res === "New Message" ){
      setIsSubTaskOpen(-1);
      setisCommsDetailIndex(-1);
      dispatch(setShowStickyNote(true))
      // setshowCreateComms(true);
    }
    setSelectedBtn(res);
    
  if(res == "New Note"){
    handleAddNote()
    setIsSubTaskOpen(-1);
    setisCommsDetailIndex(-1);

  }
  };

  // const [searchInput, setSearchInput] = useState("");
  // let timeoutId: any = null;



  let arr =
    
  !isClinicalNote
  ?

  selectedItem === 0
      ? ["New Message","All", "Unread", "Sort", "Search"]
      : ["New Message","All", "Sort", "Search"]

  :

  selectedItem === 0
  ? ["New Note","All", "Unread", "Sort", "Search"]
  : ["New Note","All", "Sort", "Search"];

  

  return (
    <div style={{ position: "relative" }}>
      <div className="comms_content_row_btn">
        {arr.map((res: string, btnIndex: number) => {
          return (
            <div key={btnIndex}>
              {res === "Sort" ? (
                <button className="sort_item" onClick={() => handleClick(res)}>
                  Sort
                  <SortSVG
                    fill="#06a5ff"
                    Styles={{
                      transform: sortType == "asc" ? "none" : "rotateX(180deg)",
                      marginLeft: "10px",
                    }}
                  />
                </button>
              ) : res === "Search" ? (
                <div className="sort_item" style={{ width: "100px" }}>
                  <SearchSVG />
                  <input
                    className="sort_item sort_item1"
                    style={{
                      width: "85%",
                    }}
                    type="text"
                    onChange={(e) => {
                      resetAllCommsLimit();
                      if (selectedItem == 0) {
                        if (isClinicalNote) {
                          dispatch(
                            getAllClinicalNotes({
                              user_id: clientId,
                              sortType: "",
                              unreadFlag: "yes",
                              searchVal: e.target.value,
                            })
                          );
                        } else {
                          dispatch(
                            getCommsForUserThunk({
                              clientId,
                              endpoint: "get_comms",
                              sortType: "",
                              search: e.target.value,
                              limit: allCommsLimit,
                            })
                          );
                        }
                      } else if (selectedItem == 1) {
                        dispatch(
                          getCommsForUserThunk({
                            clientId,
                            endpoint: "get_comms_of_sent_items",
                            sortType: "",
                            search: e.target.value,
                            limit: allCommsLimit,
                          })
                        );
                      } else if (selectedItem == 2) {
                        dispatch(
                          getCommsFromUserTypeThunk({
                            userType: dynamicClientId,
                            search: e.target.value,
                            sortType: "",
                            limit: allCommsLimit,
                          })
                        );
                      } else {
                        dispatch(
                          getCommsFromUserTypeThunk({
                            userType: dynamicProspectId,
                            search: e.target.value,
                            sortType: "",
                            limit: allCommsLimit,
                          })
                        );
                      }
                    }}
                  />
                </div>
              ) : (
                <div
                  key={btnIndex}
                  className={`sort_tab sort_item ${
                    (isClinicalNote ? prevSortingType : selectedBtn) === res
                      ? "sort_tab_selected"
                      : ""
                  }`}
                  style={{
                    width: res == "New Note" ? "80px" : "fit-content",
                    textAlign: "center",
                    
                  }}
                  onClick={() => handleClick(res)}
                >
                  {res}
                </div>
              )}
            </div>
          );
        })}
        {/* {!isClinicalNote && (
          <>
            <div
              onClick={() => {
                setIsSubTaskOpen(-1);
                setisCommsDetailIndex(-1);

                setshowCreateComms(true);
              }}
            >
              <PlusSVG width="24" height="24" />
            </div>
          </>
        )} */}
      </div>

      {showCreateComms && (
        <div style={{
          position:"relative",
          top:"-25px"
        }}>
          <CreateComms
            isCreateCommsFromPlus={true}
            setselectedItemsArr1={setselectedItemsArr1}
            setAttachments={setAttachments}
            setKeyPeople={setKeyPeople}
            type="globalComms"
            dayBookDetails={dayBookDetails}
            setDayBookDetails={setDayBookDetails}
            isCreateCommsFromPlusClickTrigger={true}
            setshowCreateComms={setshowCreateComms}
            showCreateComms={showCreateComms}
          />

          {/* <NewCreateCommsBox
          
          
          isCreateCommsFromPlus={true}
          setselectedItemsArr1={setselectedItemsArr1}
          setAttachments={setAttachments}
          setKeyPeople={setKeyPeople}
          type="globalComms"
          dayBookDetails={dayBookDetails}
          setDayBookDetails={setDayBookDetails}
          isCreateCommsFromPlusClickTrigger={true}
          setshowCreateComms={setshowCreateComms}
          showCreateComms={showCreateComms}/> */}
        </div>
      )}
    </div>
  );
};

export default GroupButton;
