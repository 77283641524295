// @ts-ignore
import { destroyCookie, parseCookies, setCookie } from 'nookies'

import { COOKIE_EXPIRATION_TIME, REFRESH_TOKEN_COOKIE, TOKEN_COOKIE } from './constants'

type CreateSessionCookiesParams = {
  token?: string | any
  refreshToken?: string
}

export function createTokenCookies(params: CreateSessionCookiesParams) {
  const  token : any = params

  if (token) {
    setCookie(null, TOKEN_COOKIE, token , {
      maxAge: COOKIE_EXPIRATION_TIME,
      path: '/'
    })
  }

  // if (refreshToken) {
  //   setCookie(null, REFRESH_TOKEN_COOKIE, refreshToken, {
  //     maxAge: COOKIE_EXPIRATION_TIME,
  //     path: '/'
  //   })
  // }
}



export function removeTokenCookies () {
  // // console.log('removeTokenCookies')
  localStorage.removeItem("token");
  destroyCookie(null, TOKEN_COOKIE);
  destroyCookie(null, REFRESH_TOKEN_COOKIE);
}

export function getToken () {
  const cookies = parseCookies()
  return cookies[TOKEN_COOKIE]
}

export function getRefreshToken () {
  const cookies = parseCookies()
  return cookies[REFRESH_TOKEN_COOKIE]
}
