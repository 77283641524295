import { useEffect, useRef, useState } from "react";
import CommonUpperBox from "../../components/UpperBox/CommonHeader";
import CircularNoteTrigger from "../../components/CircularNoteTrigger/CircularNoteTrigger";
import Table from "../../components/CommonTable/Table";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../Controller/redux/Hooks";
import { providerColumns, providerData } from "./Data/ProvidersTableData";
import { useNavigate } from "react-router-dom";
import CommonQuickMenu from "../../components/CommonQuickMenu/CommonQuickMenu";
import {
  getProvidersByFilterThunk,
  getProvidersThunk,
  getProvidersUniqueTenantNameThunk,
  getProvidersUniqueTenantTypeThunk,
  resetProvidersData,
  resetProvidersPage,
  setProvidersPage,
} from "../../../Controller/redux/features/ProviderFeature/providerSlice";

const Providers = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const tableRef = useRef();
  const providersInnerRef = useRef();
  const [stageTwoToGoalsModal, setstageTwoToGoalsModal] = useState(false);
  const { domainDetail } = useAppSelector((state) => state?.common);
  const { filterIconColor } = useAppSelector((state) => state?.common);

  const handleClickRow = (e: any, id: any) => {
    navigate(`/providers/${window.btoa(id)}`);
  };

  const {
    selectedFilterItemsProviders,
    sortByProviderR,
    searchValueProviders,
  } = useAppSelector((state) => state?.providers);
  const {
    getProviders,
    loading,
    providersPage,
    providersFilterItems,
    lastPage,
  } = useAppSelector((state) => state?.providers);
  const [isDropdownOpenProviders, setIsDropdownOpenProviders] = useState(false);

  // useEffect(() => {
  //   dispatch(getProvidersThunk());
  // }, []);
  useEffect(() => {
    dispatch(resetProvidersData());
    dispatch(resetProvidersPage());
  }, [
    providersFilterItems?.searchVal,
    providersFilterItems?.filterValue,
    providersFilterItems?.sortType,
    providersFilterItems?.status,
  ]);
  useEffect(() => {
    dispatch(getProvidersUniqueTenantNameThunk());
    dispatch(getProvidersUniqueTenantTypeThunk());
  }, []);
  useEffect(() => {
    dispatch(
      getProvidersByFilterThunk({
        filterColumn: providersFilterItems?.filterColumn
          ? providersFilterItems?.filterColumn
          : "",
        filterValue: providersFilterItems?.filterValue
          ? providersFilterItems?.filterValue
          : "",
        searchVal: providersFilterItems?.searchVal
          ? providersFilterItems?.searchVal
          : "",
        sortColumn: providersFilterItems?.sortColumn
          ? providersFilterItems?.sortColumn
          : "",
        sortType: providersFilterItems?.sortType
          ? providersFilterItems?.sortType
          : "",
        providersPage,
        status: providersFilterItems.status ? providersFilterItems.status : "",
      })
    );
  }, [
    providersPage,
    providersFilterItems?.searchVal,
    providersFilterItems?.filterValue,
    providersFilterItems?.sortType,
    providersFilterItems?.status,
  ]);
  const dropdownRefProviders = useRef<any>();
  const svgParentRefProviders = useRef<any>();

  useEffect(() => {
    const handleClickOutsideStaff = (event: any) => {
      if (
        dropdownRefProviders.current &&
        !dropdownRefProviders.current.contains(event.target) &&
        svgParentRefProviders.current &&
        !svgParentRefProviders.current.contains(event.target)
      ) {
        setIsDropdownOpenProviders(false);
      }
    };
    document.addEventListener("click", handleClickOutsideStaff);
    return () => {
      document.removeEventListener("click", handleClickOutsideStaff);
    };
  }, []);

  const onScroll = () => {
    if (providersInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } =
        providersInnerRef.current;

      if (
        scrollTop + clientHeight + +5 >= scrollHeight &&
        providersPage < lastPage
      ) {
        dispatch(setProvidersPage(providersPage));
      }
    }
  };
  const uniqueProvidersData = getProviders?.filter(
    (item: any, index: number, array: any) => {
      return array?.findIndex((obj: any) => obj.id === item.id) === index;
    }
  );
  return (
    <section>
      <CommonUpperBox
        title="Providers"
        type="listing"
        onClickPlus={() => setstageTwoToGoalsModal(true)}
      />
      <Table
        isLoading={loading}
        tableRef={tableRef}
        tableBodyRef={providersInnerRef}
        onScroll={onScroll}
        dynamicHeaderColor={domainDetail?.listingtbl_color}
        onClickRow={handleClickRow}
        statusType="providerScreen"
        thData={providerColumns}
        allClients={uniqueProvidersData}
        classNameTr="all-prospects-table-head"
        classNameRow="all-prospects-table-body"
        dropdownRef={dropdownRefProviders}
        isDropdownOpen={isDropdownOpenProviders}
        onClickSvg={() => setIsDropdownOpenProviders(true)}
        type="providers"
        svgParentRef={svgParentRefProviders}
        fill={
          selectedFilterItemsProviders?.length > 0 ||
          searchValueProviders?.length > 0 ||
          sortByProviderR?.length > 0
            ? filterIconColor
            : "#D8D3D3"
        }
      />
      <CircularNoteTrigger type="providers" title="Providers" />
      {stageTwoToGoalsModal && (
        <CommonQuickMenu
          setshowGoalsModal={(value: any) => {}}
          mainType={"listing"}
          setstageTwoToGoalsModal={setstageTwoToGoalsModal}
        />
      )}
    </section>
  );
};

export default Providers;
