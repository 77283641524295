// @ts-nocheck
import "./App.css";


import useWindowSize from "./Model/utils/useWindowSize";
import { useLocation } from "react-router-dom";
import { RouteList } from "./View/routes";
import { ToastContainer } from "react-toastify";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "./Controller/redux/Hooks";
import {
  getDomainDetailsThunk,
  // getProfileDetailsThunk,
  resetchangeStickyForClinicalWhenMobileView,
  setchangeStickyForClinicalWhenMobileView,
} from "./Controller/redux/features/CommonFeature/commonSlice";
import {setallUserTypeData} from "./Controller/redux/features/CommsFeature/commsSlice";
import GrooveWidget from "./View/components/Groove/GrooveWidget";

const App = () => {
  const dispatch = useAppDispatch();
  const isVillages = useAppSelector((state) => state?.common?.hasVillages);
  const isDaybook = useAppSelector((state) => state?.common?.hasDaybook);
  const pathname = useLocation()?.pathname;
  const search = useLocation().search;



  
  
  
  const { staffDataDayBook } = useAppSelector((state) => state?.staff);

  const { clientDataForDayBook } = useAppSelector((state) => state?.people);

  const [allData, setallData] = useState<any>([]);

  const [contactsData, setcontactsData] = useState([]);
  const [prospectsData, setprospectsData] = useState([]);
  const [businessData, setBusinessData] = useState([]);
  const [teamsData, setTeamsData] = useState([]);
  const [locationData, setLocationData] = useState([]);


  // @ts-ignore
  const dynamicClientId = parseInt(localStorage.getItem("dynamicClientId"));
  // @ts-ignore
  const dynamicProspectId = parseInt(localStorage.getItem("dynamicProspectId"));
  // @ts-ignore
  const dynamicContactId = parseInt(localStorage.getItem("dynamicContactId"));
  // @ts-ignore
  const dynamicBusinessId = parseInt(localStorage.getItem("dynamicBusinessId"));
  // @ts-ignore
  const dynamicTeamsId = parseInt(localStorage.getItem("dynamicTeamsId"));
  // @ts-ignore
  const dynamicLocationId = parseInt(localStorage.getItem("dynamicLocationId"));
  //  @ts-ignore
  const dynamicStaffId = parseInt(localStorage.getItem("dynamicStaffId"));


  useEffect(() => {
    if (
      clientDataForDayBook &&
      staffDataDayBook &&
      contactsData &&
      prospectsData &&
      businessData &&
      teamsData &&
      locationData
    ) {
      setallData([
        ...clientDataForDayBook,
        ...staffDataDayBook,
        ...prospectsData,
        ...contactsData,
        ...businessData,
        ...teamsData,
        ...locationData,
      ]);
      dispatch(
        setallUserTypeData([
          ...clientDataForDayBook,
          ...staffDataDayBook,
          ...prospectsData,
          ...contactsData,
          ...businessData,
          ...teamsData,
          ...locationData,
        ])
      );
    }
  }, [
    clientDataForDayBook,
    staffDataDayBook,
    prospectsData,
    contactsData,
    businessData,
    teamsData,
    locationData,
  ]);


  useEffect(() => {
    if (search !== "") {
      const storedData = {
        search: search,
      };
      localStorage.setItem("itemsAvailable", JSON.stringify(storedData));
    }
  }, [search]);

  const shouldClearLocalStorage =
    (isVillages === "0" && pathname.includes("villages")) ||
    (isDaybook === "0" && pathname.includes("my-daybook"));
  const { width } = useWindowSize();

  useEffect(() => {
    if (width < 600 && search?.split("&")[2]?.split("=")[1] === "true") {
      dispatch(setchangeStickyForClinicalWhenMobileView(true));
    } else if (width > 600) {
      dispatch(resetchangeStickyForClinicalWhenMobileView(false));
    }
  }, [width]);

  const favicon = useAppSelector(
    (state) => state?.common?.fromGetDomainDetails.favicon
  );

  useEffect(() => {
    dispatch(getDomainDetailsThunk());
    // dispatch(getProfileDetailsThunk());
  }, []);

  useEffect(() => {
    if (favicon) {
      let link: any = document?.querySelector("link[rel~='icon']");
      if (!link) {
        link = document?.createElement("link");
        link.rel = "icon";
        link.href = favicon;
        document.getElementsByTagName("head")[0].appendChild(link);
      } else {
        link.href = favicon;
      }
    }
  }, [favicon]);

  return (
    <>
      <ToastContainer />
      {shouldClearLocalStorage ? (
        <>
          {localStorage.clear()}
          {window.location.replace("/login")}
        </>
      ) : (
        <>
         {<GrooveWidget />}
        <RouteList />

        </>
      )}
    </>
  );
};

export default App;
