// @ts-nocheck

import "./Styles/CommonTable.css";
import { Fragment, useEffect, useRef, useState } from "react";
import { setConfirmDelete } from "../../../Controller/redux/features/CommonFeature/associationSlice";
import {
  resetIsDocumentsDropdownOpen,
  setIsFormOpenFalse,
} from "../../../Controller/redux/features/CommonFeature/documentSlice";
import {
  resetFilterOptions,
  resetSearchValueStaff,
  resetSortOptions,
} from "../../../Controller/redux/features/StaffFeature/staffSlice";
import { tableHeadArr, tableHeadArrDoc } from "./Data/AssociationHeaderDataArr";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../Controller/redux/Hooks";
import { useLocation, useParams } from "react-router-dom";
import { itemsArr } from "../../pages/People/ClientPage/Components/UniqueClientScreen/Data/WidgetDataArr";
import {
  resetclientTableFilterDropdown,
  resetsearchValueClients,
} from "../../../Controller/redux/features/ClientFeature/clientsSlice";
import PlusSVG from "../../pages/People/ClientPage/Components/UniqueClientScreen/Components/FinanceTab/Data/PlusSVG";
import DollarSVG from "../../pages/People/ClientPage/Components/UniqueClientScreen/Components/FinanceTab/Data/DollarSVG";
import { GreenCircleSvg } from "../../pages/People/ClientPage/Components/UniqueClientScreen/Components/ClinicalTab/Data/SVGs";
import DeleteSVG from "../../pages/People/ClientPage/Components/UniqueClientScreen/Components/FinanceTab/Data/DeleteSVG";
import {
  addBudgetFinanceExpensesRecords,
  addBudgetFinanceFeesRecords,
  addBudgetFinanceFundingRecords,
  addBudgetFinanceRecords,
  onChnageFiledValueBudget,
  onChnageFiledValueBudgetExpenses,
  onChnageFiledValueBudgetFees,
  onChnageFiledValueBudgetFunding,
  onChnageFiledValueBudgetTotals,
} from "../../../Controller/redux/features/FinanceFeature/FinanceSlice";
import { StatementDataArr } from "./Data/StatementDataArr";

const CommonTable = ({ type, allClients }: any) => {
  const dispatch = useAppDispatch();
  const pathname = useLocation().pathname;

  // ////////////
  const selectedOps = useAppSelector(
    (state) => state?.document?.selectedOptions
  );

  const selectedNameAssociations = useAppSelector(
    (state) => state?.associations?.selectedNames
  );

  const selectedItemIndexAssociations = useAppSelector(
    (state) => state?.associations?.selectedItemIndexAssociations
  );

  const selectedItemIndexAssociationsSort = useAppSelector(
    (state) => state?.associations?.selectedItemIndexAssociationsSort
  );

  const getCurrentClient = useAppSelector(
    (state) => state?.people?.uniqueClient
  );

  const getAssociationsByUserId = useAppSelector(
    (state) => state.associations.getAssociationByUserId
  );

  const isSelectedColumn = useAppSelector(
    (state) => state?.document?.selectedColumn
  );

  const isSelectedColumnForSort = useAppSelector(
    (state) => state?.document?.selectedColumnForSort
  );

  useEffect(() => {
    dispatch(resetFilterOptions());
    dispatch(resetSortOptions());
    dispatch(resetsearchValueClients());
    dispatch(resetSearchValueStaff());
  }, [pathname]);

  const filterIconCo̥lor = useAppSelector(
    (state) => state?.common?.filterIconColor
  );

  ///////////////////// CLIENT ID //////////////////////////////
  const id: any = useParams()?.id;
  const [clientId, setClientId] = useState("");
  useEffect(() => {
    if (id) {
      let realId = window.atob(id);
      setClientId(realId);
    }
  }, [id]);

  // const clientId = window.atob(id)

  ////////////////////////////////////////////////////////

  const dropdownDocumentRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        dropdownDocumentRef.current &&
        !dropdownDocumentRef.current.contains(event.target)
      ) {
        setDocumentDropDownOpen(false);
        setOpenDropdownClients(false);
        setOpenDropdownAssociations(false);
      }
    };
    window.addEventListener("click", handleOutsideClick);
    return () => {
      window.removeEventListener("click", handleOutsideClick);
    };
  }, []);
  const handleDeleteAssociation = async (id: any) => {
    dispatch(setConfirmDelete({ flag: true, id }));
  };

  const [openDropdownAssociations, setOpenDropdownAssociations] =
    useState(false);

  const [associations, setAssociations] = useState<any>([]);

  const [isLoadingNewData, setIsLoadingNewData] = useState(false);

  const pathName = useLocation();

  useEffect(() => {
    dispatch(resetIsDocumentsDropdownOpen());
  }, [pathName]);

  const [active, setActive] = useState<boolean[]>([]);

  useEffect(() => {
    if (getAssociationsByUserId) {
      setAssociations(getAssociationsByUserId);
    }
  }, [getAssociationsByUserId]);

  useEffect(() => {
    setActive(Array.from({ length: associations?.length }, () => false));
  }, [associations]);

  const noteRef = useRef<Object | any>(null);

  const dropdownRefClients = useRef<Object | any>(null);

  const svgParentRef = useRef<Object | any>();

  const [openDropdown, setOpenDropdown] = useState<boolean[] | any>(
    Array(tableHeadArrDoc.length).fill(false)
  );

  const [documentDropDownOpen, setDocumentDropDownOpen] = useState(false);
  const [openDropdownClients, setOpenDropdownClients] = useState<any>(false);
  const tableRef = useRef<Object | any>(null);

  // CLOSE CLIENTS DROPDOWN ON OUTSIDE CLICK EXCEPT SVG CLICK
  const handleClickOutside = (event) => {
    if (
      dropdownRefClients.current &&
      !dropdownRefClients.current.contains(event.target) &&
      svgParentRef.current &&
      !svgParentRef.current.contains(event.target)
    ) {
      setOpenDropdownClients(false);
      dispatch(resetclientTableFilterDropdown());
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const dropdownRefAssociations = useRef<any>();
  const dropdownRefDocuments = useRef<any>();
  const svgParentRefAssociations = useRef<any>();
  const svgParentRefDocuments = useRef<any>();

  // CLOSE AUDIT TABLE ON CLICK

  // Association
  const handleClickOutsideAssociation = (event) => {
    if (
      dropdownRefAssociations.current &&
      !dropdownRefAssociations.current.contains(event.target) &&
      svgParentRefAssociations.current &&
      !svgParentRefAssociations.current.contains(event.target)
    ) {
      setOpenDropdownAssociations(false);
    }
  };

  // Documents
  const handleClickOutsideDocument = (event) => {
    if (
      dropdownRefDocuments.current &&
      !dropdownRefDocuments.current.contains(event.target) &&
      svgParentRefDocuments.current &&
      !svgParentRefDocuments.current.contains(event.target)
    ) {
      setDocumentDropDownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutsideAssociation);
    return () => {
      document.removeEventListener("click", handleClickOutsideAssociation);
    };
  }, []);
  useEffect(() => {
    document.addEventListener("click", handleClickOutsideDocument);
    return () => {
      document.removeEventListener("click", handleClickOutsideDocument);
    };
  }, []);

  // CLOSE CLIENT ASSESSMENT FORM ON OUTSIDE CLICK WITH EXCLUDED CLASSNAMES
  useEffect(() => {
    const handleClickOutsideOfForm = () => {};
    document.addEventListener("click", handleClickOutsideOfForm);
    return () => {
      document.removeEventListener("click", handleClickOutsideOfForm);
    };
  }, []);
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  // const handleCloseModal = () => {
  //   setisFormOpen(false);
  // };

  // const handleDropdownClick = (event: any) => {
  //   event.stopPropagation();
  // };

  const [changesToForm, setchangesToForm] = useState("");

  const handleChangesToForm = (val: any) => {
    setchangesToForm(val);
  };

  const updatedDoc = useAppSelector(
    (state) => state?.document?.updateDocumentData
  );

  // UPDATE GOES HERE

  const [statusData, setstatusData] = useState<any>();
  useEffect(() => {
    const fetchedData = getCurrentClient?.data?.data[0];
    const statusData = itemsArr.find(
      (item) => item.status == fetchedData?.status
    );
    setstatusData(statusData);
  }, [getCurrentClient, clientId]);

  const [showStatusWidget, setShowStatusWidget] = useState(
    Array(associations?.length).fill(false)
  );
  useEffect(() => {
    setShowStatusWidget(Array(associations?.length).fill(false));
  }, [associations]);

  ///////////////////////////////////////////////////////// Meal Builder Start Here //////////////////////////////////////////////

  const tableHeadArrMealBuilder = [
    "Meal Name",
    "Tags",
    "Prep Time",
    "Cooking Time",
    "Attachment",
    "Actions",
  ];

  const mealsArr = useAppSelector(
    (state) => state.mealBuilder.MealsBuilderData?.data?.data
  );

  const [isOpenMealForm, setisOpenMealForm] = useState(false);

  const [mealInfo, setMealInfo] = useState({});

  /////////////////////////////////////////////////////////////////////// Meal Builder End Here /////////////////////////////////////////////////

  /////////////////////////////////////////////////////////////////////// Manu Builder Start Here ///////////////////////////////////////////////////////

  const tableHeadArrMenuBuilder = [
    "Menu Date",
    "Lunch Option 1",
    "Lunch Option 2",
    "Dessert Option 1",
    "Dessert Option 2",
    "Dinner Option 1",
    "Dinner Option 2",
  ];

  //////////////////////////////////////////////// Budget Model table ///////////////////////////////////////////////////

  const tableHeadArrBudgetModel = [
    "Type",
    "Code",
    "Amount",
    "Unit",
    "Rate",
    "Sub Total",
  ];

  const [budgetAllTheRecords, setBudgetAllTheRecords] = useState([]);

  const [unitName, setUnitName] = useState("");

  const fundingType = useAppSelector((state) => state?.finance?.fundingType);

  useEffect(() => {
    if (fundingType) {
      setUnitName(fundingType);
    }
  }, [fundingType]);

  useEffect(() => {
    if (allClients) {
      setBudgetAllTheRecords(allClients);
    }
  }, [allClients]);

  const handleOnChnageBudgetRecord = (e: any, arrayIndex: number) => {
    let { value, name } = e?.target;
    let valueIs = value;

    const duplicateAllClientsrecord = JSON.parse(
      JSON.stringify(budgetAllTheRecords)
    );

    duplicateAllClientsrecord[arrayIndex][`${name}`] = valueIs;

    setBudgetAllTheRecords(duplicateAllClientsrecord);

    if (type == "budgetModelServices") {
      dispatch(
        onChnageFiledValueBudget({ duplicateAllClientsrecord, type: unitName })
      );
    } else if (type == "budgetModelFees") {
      dispatch(
        onChnageFiledValueBudgetFees({
          duplicateAllClientsrecord,
          type: unitName,
        })
      );
    } else if (type == "budgetModelTotals") {
      dispatch(
        onChnageFiledValueBudgetTotals({
          duplicateAllClientsrecord,
          type: unitName,
        })
      );
    } else if (type == "budgetModelExpenses") {
      dispatch(
        onChnageFiledValueBudgetExpenses({
          duplicateAllClientsrecord,
          type: unitName,
        })
      );
    } else if (type == "budgetModelFunding") {
      dispatch(
        onChnageFiledValueBudgetFunding({
          duplicateAllClientsrecord,
          type: unitName,
        })
      );
    }
  };

  const handleRemoveRecordBudget = (index: number) => {
    const duplicateAllClientsrecord: any[] = JSON.parse(
      JSON.stringify(budgetAllTheRecords)
    );
    let filtredrecords = duplicateAllClientsrecord.filter(
      (res, ind) => ind !== index
    );
    // // console.log("filtredrecords=========================>", filtredrecords);

    if (type == "budgetModelServices") {
      dispatch(
        onChnageFiledValueBudget({ duplicateAllClientsrecord: filtredrecords })
      );
    } else if (type == "budgetModelFees") {
      dispatch(
        onChnageFiledValueBudgetFees({
          duplicateAllClientsrecord: filtredrecords,
          type: unitName,
        })
      );
    } else if (type == "budgetModelTotals") {
      dispatch(
        onChnageFiledValueBudgetTotals({
          duplicateAllClientsrecord: filtredrecords,
          type: unitName,
        })
      );
    } else if (type == "budgetModelExpenses") {
      dispatch(
        onChnageFiledValueBudgetExpenses({
          duplicateAllClientsrecord: filtredrecords,
          type: unitName,
        })
      );
    } else if (type == "budgetModelFunding") {
      dispatch(
        onChnageFiledValueBudgetFunding({
          duplicateAllClientsrecord: filtredrecords,
          type: unitName,
        })
      );
    }
  };

  //////////////////////////////////////////////// Finance table ///////////////////////////////////////////////////

  //////////////////////////////////////////////// Invoices table ///////////////////////////////////////////////////

  const tableHeadArrFinanceInvoices = [
    "Input",
    "Item",
    "Start Date/Time",
    "End Date/Time",
    "Quantity",
    "Rate",
    "Sub Total",
  ];

  const tableHeadArrBudgetFundingModel = [" Category", "Amount"];

  const [recordCounter, setRecordCounter] = useState(0);

  //////////////////////////////////////////////// Statement table ///////////////////////////////////////////////////

  const tableHeadArrServiceType = [
    "Service Time",
    "Service Rate",
    "Service Code",
    "KM Rate",
    "Travel to KM Code",
    "Travel To Min Code",
    "Travel with Code",
  ];

  //////////////////////////////////////////////// Tools budget table ///////////////////////////////////////////////////

  const tableHeadArrBudgetTools = ["Category", "Amount"];

  return (
    <Fragment>
      {type === "budgetModelFunding" && (
        <div className="budget_model_table_wrapper">
          <table
            className="common-table-container budget_model_table funding_table"
            ref={tableRef}
          >
            <thead>
              <tr className="budget_model_table_head">
                {tableHeadArrBudgetFundingModel.map((elem, index) => (
                  <th
                    // className="table-head-common-table-all-clients mealbuilder-all-th"
                    key={index}
                  >
                    <span>{elem}</span>
                    {index == 1 && (
                      <div>
                        <svg
                          width="12"
                          height="12"
                          className="svg-icon-tablehead"
                          style={{}}
                          // onClick={handleDropdownClickClientsBySVG}
                          viewBox="0 0 12 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clipPath="url(#clip0_92_2215)">
                            <path
                              d="M9.32712 7.30114L5.11159 13.8495C4.98177 14.0535 4.69987 14.0535 4.57005 13.8495L0.354526 7.30114C0.204302 7.06741 0.360089 6.75049 0.625298 6.75049H9.05449C9.3197 6.75049 9.47548 7.06741 9.32526 7.30114H9.32712Z"
                              fill={
                                openDropdownClients ||
                                selectedItemIndexAssociations ||
                                selectedItemIndexAssociationsSort ||
                                isSelectedColumn ||
                                isSelectedColumnForSort ||
                                selectedOps
                                  ? filterIconCo̥lor
                                  : "#D8D3D3"
                              }
                            />
                            <path
                              d="M9.35279 2.80859H0.328265C0.146969 2.80859 0 2.96556 0 3.15919V5.37368C0 5.5673 0.146969 5.72427 0.328265 5.72427H9.35279C9.53408 5.72427 9.68105 5.5673 9.68105 5.37368V3.15919C9.68105 2.96556 9.53408 2.80859 9.35279 2.80859Z"
                              fill={
                                openDropdownClients ||
                                selectedItemIndexAssociations ||
                                isSelectedColumn ||
                                isSelectedColumnForSort ||
                                selectedItemIndexAssociationsSort ||
                                selectedOps
                                  ? filterIconCo̥lor
                                  : "#D8D3D3"
                              }
                            />
                            <path
                              d="M9.35279 0H0.328265C0.146969 0 0 0.156966 0 0.350594V1.43407C0 1.6277 0.146969 1.78466 0.328265 1.78466H9.35279C9.53408 1.78466 9.68105 1.6277 9.68105 1.43407V0.350594C9.68105 0.156966 9.53408 0 9.35279 0Z"
                              fill={
                                openDropdownClients ||
                                selectedItemIndexAssociations ||
                                isSelectedColumn ||
                                isSelectedColumnForSort ||
                                selectedItemIndexAssociationsSort ||
                                selectedOps
                                  ? filterIconCo̥lor
                                  : "#D8D3D3"
                              }
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_92_2215">
                              <rect width="9.68105" height="14" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                        <PlusSVG
                          onClick={() => {
                            dispatch(
                              addBudgetFinanceFundingRecords({ type: unitName })
                            );
                          }}
                        />
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            </thead>

            <tbody ref={tableRef} className="budget_model_body">
              {isLoadingNewData && (
                <tr>
                  <td
                    style={{
                      textAlign: "center",
                      position: "absolute",
                      left: "45%",
                      top: "45%",
                    }}
                  ></td>
                </tr>
              )}
              {Array.isArray(budgetAllTheRecords) &&
                budgetAllTheRecords?.map((elem: any, index: number) => {
                  return (
                    <tr
                      key={index}
                      className={
                        "normal-table-row normal-class-table-row-clients-table"
                      }
                    >
                      <td>
                        <input
                          type="text"
                          value={elem?.subCategory}
                          name="subCategory"
                          placeholder="Core"
                          onChange={(e) => {
                            handleOnChnageBudgetRecord(e, index);
                          }}
                        />
                      </td>
                      <td>
                        <DollarSVG />
                        <input
                          type="text"
                          name="amount"
                          onKeyPress={(event) => {
                            if (!/[0-9.]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          value={elem?.amount}
                          onChange={(e) => {
                            handleOnChnageBudgetRecord(e, index);
                          }}
                          placeholder="000"
                        />
                        <DeleteSVG
                          width="15"
                          height="15"
                          onClick={() => {
                            handleRemoveRecordBudget(index);
                          }}
                        />
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      )}
      {type === "budgetModelServices" && (
        <div className="budget_model_table_wrapper ">
          <table
            className="common-table-container budget_model_table"
            ref={tableRef}
          >
            <thead>
              <tr className="budget_model_table_head">
                {tableHeadArrBudgetModel.map((elem, index) => (
                  <th
                    // className="table-head-common-table-all-clients mealbuilder-all-th"
                    key={index}
                  >
                    <span>{elem}</span>
                    {index == 4 && (
                      <div>
                        <svg
                          width="12"
                          height="12"
                          className="svg-icon-tablehead"
                          style={{}}
                          // onClick={handleDropdownClickClientsBySVG}
                          viewBox="0 0 12 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clipPath="url(#clip0_92_2215)">
                            <path
                              d="M9.32712 7.30114L5.11159 13.8495C4.98177 14.0535 4.69987 14.0535 4.57005 13.8495L0.354526 7.30114C0.204302 7.06741 0.360089 6.75049 0.625298 6.75049H9.05449C9.3197 6.75049 9.47548 7.06741 9.32526 7.30114H9.32712Z"
                              fill={
                                openDropdownClients ||
                                selectedItemIndexAssociations ||
                                selectedItemIndexAssociationsSort ||
                                isSelectedColumn ||
                                isSelectedColumnForSort ||
                                selectedOps
                                  ? filterIconCo̥lor
                                  : "#D8D3D3"
                              }
                            />
                            <path
                              d="M9.35279 2.80859H0.328265C0.146969 2.80859 0 2.96556 0 3.15919V5.37368C0 5.5673 0.146969 5.72427 0.328265 5.72427H9.35279C9.53408 5.72427 9.68105 5.5673 9.68105 5.37368V3.15919C9.68105 2.96556 9.53408 2.80859 9.35279 2.80859Z"
                              fill={
                                openDropdownClients ||
                                selectedItemIndexAssociations ||
                                isSelectedColumn ||
                                isSelectedColumnForSort ||
                                selectedItemIndexAssociationsSort ||
                                selectedOps
                                  ? filterIconCo̥lor
                                  : "#D8D3D3"
                              }
                            />
                            <path
                              d="M9.35279 0H0.328265C0.146969 0 0 0.156966 0 0.350594V1.43407C0 1.6277 0.146969 1.78466 0.328265 1.78466H9.35279C9.53408 1.78466 9.68105 1.6277 9.68105 1.43407V0.350594C9.68105 0.156966 9.53408 0 9.35279 0Z"
                              fill={
                                openDropdownClients ||
                                selectedItemIndexAssociations ||
                                isSelectedColumn ||
                                isSelectedColumnForSort ||
                                selectedItemIndexAssociationsSort ||
                                selectedOps
                                  ? filterIconCo̥lor
                                  : "#D8D3D3"
                              }
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_92_2215">
                              <rect width="9.68105" height="14" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                        <PlusSVG
                          onClick={() => {
                            dispatch(
                              addBudgetFinanceRecords({ type: unitName })
                            );
                          }}
                        />
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            </thead>

            <tbody ref={tableRef} className="budget_model_body">
              {isLoadingNewData && (
                <tr>
                  <td
                    style={{
                      textAlign: "center",
                      position: "absolute",
                      left: "45%",
                      top: "45%",
                    }}
                  ></td>
                </tr>
              )}
              {Array.isArray(budgetAllTheRecords) &&
                budgetAllTheRecords?.map((elem: any, index: number) => {
                  let calculatedValue =
                    elem?.Rate !== "" && elem?.Amount_pweek !== ""
                      ? elem?.Rate * elem?.Amount_pweek
                      : 0;
                  return (
                    <tr
                      key={index}
                      className={
                        "normal-table-row normal-class-table-row-clients-table"
                      }
                    >
                      <td>
                        <input
                          type="text"
                          value={elem?.Type}
                          name="Type"
                          placeholder="Enter type here"
                          onChange={(e) => {
                            handleOnChnageBudgetRecord(e, index);
                          }}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name={"Code"}
                          value={elem?.Code}
                          onChange={(e) => {
                            handleOnChnageBudgetRecord(e, index);
                          }}
                          placeholder="CODE123"
                        />
                      </td>

                      <td>
                        <input
                          type="text"
                          placeholder="0000"
                          name="Amount_pweek"
                          value={elem?.Amount_pweek}
                          onKeyPress={(event) => {
                            if (!/[0-9.]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          onChange={(e) => {
                            handleOnChnageBudgetRecord(e, index);
                          }}
                        />
                      </td>
                      <td
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <select placeholder="select Unit">
                          <option value="">Hour</option>
                          <option value="">Day</option>
                          <option value="">Week</option>
                          <option value="">Month</option>
                        </select>
                      </td>
                      <td
                        style={{
                          width: "20%",
                        }}
                      >
                        <DollarSVG />
                        <input
                          type="text"
                          name="Rate"
                          onKeyPress={(event) => {
                            if (!/[0-9.]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          value={elem?.Rate}
                          onChange={(e) => {
                            handleOnChnageBudgetRecord(e, index);
                          }}
                          placeholder="000"
                        />
                      </td>
                      <td>
                        <DollarSVG />
                        <input
                          type="text"
                          disabled
                          placeholder="0000"
                          value={calculatedValue.toFixed(2)}
                        />
                        <DeleteSVG
                          width="15"
                          height="15"
                          onClick={() => {
                            handleRemoveRecordBudget(index);
                          }}
                        />
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      )}
      {type === "budgetModelFees" && (
        <div className="budget_model_table_wrapper">
          <table
            className="common-table-container budget_model_table "
            ref={tableRef}
          >
            <thead>
              <tr className="budget_model_table_head">
                {tableHeadArrBudgetModel.map((elem, index) => (
                  <th
                    // className="table-head-common-table-all-clients mealbuilder-all-th"
                    key={index}
                  >
                    <span>{elem}</span>
                    {index == 4 && (
                      <div>
                        <svg
                          width="12"
                          height="12"
                          className="svg-icon-tablehead"
                          style={{}}
                          // onClick={handleDropdownClickClientsBySVG}
                          viewBox="0 0 12 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clipPath="url(#clip0_92_2215)">
                            <path
                              d="M9.32712 7.30114L5.11159 13.8495C4.98177 14.0535 4.69987 14.0535 4.57005 13.8495L0.354526 7.30114C0.204302 7.06741 0.360089 6.75049 0.625298 6.75049H9.05449C9.3197 6.75049 9.47548 7.06741 9.32526 7.30114H9.32712Z"
                              fill={
                                openDropdownClients ||
                                selectedItemIndexAssociations ||
                                selectedItemIndexAssociationsSort ||
                                isSelectedColumn ||
                                isSelectedColumnForSort ||
                                selectedOps
                                  ? filterIconCo̥lor
                                  : "#D8D3D3"
                              }
                            />
                            <path
                              d="M9.35279 2.80859H0.328265C0.146969 2.80859 0 2.96556 0 3.15919V5.37368C0 5.5673 0.146969 5.72427 0.328265 5.72427H9.35279C9.53408 5.72427 9.68105 5.5673 9.68105 5.37368V3.15919C9.68105 2.96556 9.53408 2.80859 9.35279 2.80859Z"
                              fill={
                                openDropdownClients ||
                                selectedItemIndexAssociations ||
                                isSelectedColumn ||
                                isSelectedColumnForSort ||
                                selectedItemIndexAssociationsSort ||
                                selectedOps
                                  ? filterIconCo̥lor
                                  : "#D8D3D3"
                              }
                            />
                            <path
                              d="M9.35279 0H0.328265C0.146969 0 0 0.156966 0 0.350594V1.43407C0 1.6277 0.146969 1.78466 0.328265 1.78466H9.35279C9.53408 1.78466 9.68105 1.6277 9.68105 1.43407V0.350594C9.68105 0.156966 9.53408 0 9.35279 0Z"
                              fill={
                                openDropdownClients ||
                                selectedItemIndexAssociations ||
                                isSelectedColumn ||
                                isSelectedColumnForSort ||
                                selectedItemIndexAssociationsSort ||
                                selectedOps
                                  ? filterIconCo̥lor
                                  : "#D8D3D3"
                              }
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_92_2215">
                              <rect width="9.68105" height="14" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                        <PlusSVG
                          onClick={() => {
                            dispatch(
                              addBudgetFinanceFeesRecords({ type: unitName })
                            );
                          }}
                        />
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            </thead>

            <tbody ref={tableRef} className="budget_model_body">
              {isLoadingNewData && (
                <tr>
                  <td
                    style={{
                      textAlign: "center",
                      position: "absolute",
                      left: "45%",
                      top: "45%",
                    }}
                  ></td>
                </tr>
              )}

              {Array.isArray(budgetAllTheRecords) &&
                budgetAllTheRecords?.map((elem: any, index: number) => {
                  let calculatedValue =
                    elem?.Rate !== "" && elem?.Amount_pweek !== ""
                      ? elem?.Rate * elem?.Amount_pweek
                      : 0;
                  return (
                    <tr
                      key={index}
                      className={
                        "normal-table-row normal-class-table-row-clients-table"
                      }
                    >
                      <td>
                        <input
                          type="text"
                          value={elem?.Type}
                          name="Type"
                          placeholder="Enter type here"
                          onChange={(e) => {
                            handleOnChnageBudgetRecord(e, index);
                          }}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name={"Code"}
                          value={elem?.Code}
                          onChange={(e) => {
                            handleOnChnageBudgetRecord(e, index);
                          }}
                          placeholder="CODE123"
                        />
                      </td>
                      <td
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <input
                          type="text"
                          placeholder="0000"
                          name="Amount_pweek"
                          value={elem?.Amount_pweek}
                          onKeyPress={(event) => {
                            if (!/[0-9.]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          onChange={(e) => {
                            handleOnChnageBudgetRecord(e, index);
                          }}
                        />
                      </td>
                      <td
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <select placeholder="select Unit">
                          <option value="">Hour</option>
                          <option value="">Day</option>
                          <option value="">Week</option>
                          <option value="">Month</option>
                        </select>
                      </td>
                      <td
                        style={{
                          position: "relative",
                          width: "20%",
                        }}
                      >
                        <DollarSVG />
                        <input
                          type="text"
                          name="Rate"
                          onKeyPress={(event) => {
                            if (!/[0-9.]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          value={elem?.Rate}
                          onChange={(e) => {
                            handleOnChnageBudgetRecord(e, index);
                          }}
                          placeholder="000"
                        />
                      </td>
                      <td>
                        <DollarSVG />
                        <input
                          type="text"
                          disabled
                          placeholder="0000"
                          value={calculatedValue.toFixed(2)}
                        />
                        <DeleteSVG
                          width="15"
                          height="15"
                          onClick={() => {
                            handleRemoveRecordBudget(index);
                          }}
                        />
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      )}
      {type === "budgetModelTotals" && (
        <div className="budget_model_table_wrapper">
          <table
            className="common-table-container tools_model_table"
            ref={tableRef}
          >
            <thead>
              <tr className="tools_model_table_head">
                {tableHeadArrBudgetTools.map((elem, index) => (
                  <th
                    // className="table-head-common-table-all-clients mealbuilder-all-th"
                    key={index}
                  >
                    <span>{elem}</span>
                  </th>
                ))}
              </tr>
            </thead>

            <tbody ref={tableRef} className="tools_model_body">
              {isLoadingNewData && (
                <tr>
                  <td
                    style={{
                      textAlign: "center",
                      position: "absolute",
                      left: "45%",
                      top: "45%",
                    }}
                  ></td>
                </tr>
              )}
              {Array.isArray(budgetAllTheRecords) &&
                budgetAllTheRecords?.map((elem: any, index: number) => {
                  return (
                    <tr
                      key={index}
                      className={
                        "normal-table-row normal-class-table-row-clients-table"
                      }
                    >
                      <td>
                        <span>{elem.Title}</span>
                      </td>

                      <td>
                        <span>
                          <DollarSVG />
                          {elem.Amount}
                        </span>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      )}

      {type === "budgetModelExpenses" && (
        <div className="budget_model_table_wrapper">
          <table
            className="common-table-container budget_model_table"
            ref={tableRef}
          >
            <thead>
              <tr className="budget_model_table_head">
                {tableHeadArrBudgetModel.map((elem, index) => (
                  <th
                    // className="table-head-common-table-all-clients mealbuilder-all-th"
                    key={index}
                  >
                    <span>{elem}</span>
                    {index == 4 && (
                      <div>
                        <svg
                          width="12"
                          height="12"
                          className="svg-icon-tablehead"
                          style={{}}
                          // onClick={handleDropdownClickClientsBySVG}
                          viewBox="0 0 12 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clipPath="url(#clip0_92_2215)">
                            <path
                              d="M9.32712 7.30114L5.11159 13.8495C4.98177 14.0535 4.69987 14.0535 4.57005 13.8495L0.354526 7.30114C0.204302 7.06741 0.360089 6.75049 0.625298 6.75049H9.05449C9.3197 6.75049 9.47548 7.06741 9.32526 7.30114H9.32712Z"
                              fill={
                                openDropdownClients ||
                                selectedItemIndexAssociations ||
                                selectedItemIndexAssociationsSort ||
                                isSelectedColumn ||
                                isSelectedColumnForSort ||
                                selectedOps
                                  ? filterIconCo̥lor
                                  : "#D8D3D3"
                              }
                            />
                            <path
                              d="M9.35279 2.80859H0.328265C0.146969 2.80859 0 2.96556 0 3.15919V5.37368C0 5.5673 0.146969 5.72427 0.328265 5.72427H9.35279C9.53408 5.72427 9.68105 5.5673 9.68105 5.37368V3.15919C9.68105 2.96556 9.53408 2.80859 9.35279 2.80859Z"
                              fill={
                                openDropdownClients ||
                                selectedItemIndexAssociations ||
                                isSelectedColumn ||
                                isSelectedColumnForSort ||
                                selectedItemIndexAssociationsSort ||
                                selectedOps
                                  ? filterIconCo̥lor
                                  : "#D8D3D3"
                              }
                            />
                            <path
                              d="M9.35279 0H0.328265C0.146969 0 0 0.156966 0 0.350594V1.43407C0 1.6277 0.146969 1.78466 0.328265 1.78466H9.35279C9.53408 1.78466 9.68105 1.6277 9.68105 1.43407V0.350594C9.68105 0.156966 9.53408 0 9.35279 0Z"
                              fill={
                                openDropdownClients ||
                                selectedItemIndexAssociations ||
                                isSelectedColumn ||
                                isSelectedColumnForSort ||
                                selectedItemIndexAssociationsSort ||
                                selectedOps
                                  ? filterIconCo̥lor
                                  : "#D8D3D3"
                              }
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_92_2215">
                              <rect width="9.68105" height="14" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                        <PlusSVG
                          onClick={() => {
                            dispatch(
                              addBudgetFinanceExpensesRecords({
                                type: unitName,
                              })
                            );
                          }}
                        />
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            </thead>

            <tbody ref={tableRef} className="budget_model_body">
              {isLoadingNewData && (
                <tr>
                  <td
                    style={{
                      textAlign: "center",
                      position: "absolute",
                      left: "45%",
                      top: "45%",
                    }}
                  ></td>
                </tr>
              )}
              {Array.isArray(budgetAllTheRecords) &&
                budgetAllTheRecords?.map((elem: any, index: number) => {
                  let calculatedValue =
                    elem?.Rate !== "" && elem?.Amount_pweek !== ""
                      ? elem?.Rate * elem?.Amount_pweek
                      : 0;
                  return (
                    <tr
                      key={index}
                      className={
                        "normal-table-row normal-class-table-row-clients-table"
                      }
                    >
                      <td>
                        <input
                          type="text"
                          value={elem?.Type}
                          name="Type"
                          placeholder="Enter type here"
                          onChange={(e) => {
                            handleOnChnageBudgetRecord(e, index);
                          }}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name={"Code"}
                          value={elem?.Code}
                          onChange={(e) => {
                            handleOnChnageBudgetRecord(e, index);
                          }}
                          placeholder="CODE123"
                        />
                      </td>

                      <td
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <input
                          type="text"
                          placeholder="0000"
                          name="Amount_pweek"
                          value={elem?.Amount_pweek}
                          onKeyPress={(event) => {
                            if (!/[0-9.]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          onChange={(e) => {
                            handleOnChnageBudgetRecord(e, index);
                          }}
                        />
                      </td>
                      <td
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <select placeholder="select Unit">
                          <option value="">Hour</option>
                          <option value="">Day</option>
                          <option value="">Week</option>
                          <option value="">Month</option>
                        </select>
                      </td>
                      <td
                        style={{
                          width: "20%",
                        }}
                      >
                        <DollarSVG />
                        <input
                          type="text"
                          name="Rate"
                          onKeyPress={(event) => {
                            if (!/[0-9.]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          value={elem?.Rate}
                          onChange={(e) => {
                            handleOnChnageBudgetRecord(e, index);
                          }}
                          placeholder="000"
                        />
                      </td>
                      <td>
                        <DollarSVG />
                        <input
                          type="text"
                          disabled
                          placeholder="0000"
                          value={calculatedValue.toFixed(2)}
                        />
                        <DeleteSVG
                          width="15"
                          height="15"
                          onClick={() => {
                            handleRemoveRecordBudget(index);
                          }}
                        />
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      )}
      {type === "invoices" && (
        <div className="invoices_table_wrapper">
          <table
            className="common-table-container invoices_table"
            ref={tableRef}
          >
            <thead>
              <tr className="invoices_table_head">
                {tableHeadArrFinanceInvoices.map((elem, index) => (
                  <th key={index}>
                    <span>
                      {index == 0 ? (
                        <input type="checkbox" />
                      ) : index == 6 ? (
                        <>
                          {elem}{" "}
                          <PlusSVG
                            onClick={() => {
                              setRecordCounter(recordCounter + 1);
                            }}
                          />
                        </>
                      ) : (
                        elem
                      )}
                    </span>
                  </th>
                ))}
              </tr>
            </thead>

            <tbody ref={tableRef} className="invoices_model_body">
              {isLoadingNewData && (
                <tr>
                  <td
                    style={{
                      textAlign: "center",
                      position: "absolute",
                      left: "45%",
                      top: "45%",
                    }}
                  ></td>
                </tr>
              )}
              {recordCounter !== 0 &&
                [...Array(recordCounter)].map((elem: any, index: any) => {
                  return (
                    <tr
                      key={index}
                      className={
                        "normal-table-row normal-class-table-row-clients-table"
                      }
                    >
                      <td>
                        <span
                          style={{
                            width: "100%",
                          }}
                        >
                          <div
                            style={{
                              marginLeft: "15px",
                            }}
                          >
                            <GreenCircleSvg />
                          </div>
                        </span>
                      </td>
                      <td>
                        <input type="text" />
                      </td>
                      <td>
                        <input type="text" />
                      </td>
                      <td>
                        <input type="text" />
                      </td>
                      <td>
                        <input type="text" />
                      </td>
                      <td>
                        <DollarSVG />
                        <input type="text" />
                      </td>
                      <td>
                        <DollarSVG />
                        <input type="text" />
                        {/* <DeleteSVG onClick={() => {

                        }} /> */}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      )}
      {type === "statement" && (
        <div className="statement_table_wrapper">
          <table
            className="common-table-container statement_table"
            ref={tableRef}
          >
            <thead>
              <tr className="statement_table_head">
                {tableHeadArrFinanceStatement.map((elem, index) => (
                  <th key={index}>
                    <span>{elem}</span>
                  </th>
                ))}
              </tr>
            </thead>

            <tbody ref={tableRef} className="statement_model_body">
              {isLoadingNewData && (
                <tr>
                  <td
                    style={{
                      textAlign: "center",
                      position: "absolute",
                      left: "45%",
                      top: "45%",
                    }}
                  ></td>
                </tr>
              )}
              {StatementDataArr &&
                StatementDataArr.map((elem: any, index: any) => {
                  return (
                    <Fragment key={index}>
                      <tr
                        key={index}
                        className={
                          "normal-table-row normal-class-table-row-clients-table"
                        }
                      >
                        <td>
                          <span
                            style={{
                              width: "100%",
                              marginLeft: "65px",
                            }}
                            className="header_text_table_statement"
                          >
                            {elem?.title}
                          </span>
                        </td>
                        <td>{/* <input type="text" /> */}</td>
                        <td>{/* <input type="text" /> */}</td>
                      </tr>
                      {elem?.sub_section.map((res: any, resIndex: number) => {
                        return (
                          <tr
                            key={resIndex}
                            className={
                              "normal-table-row normal-class-table-row-clients-table"
                            }
                          >
                            <td>
                              <span
                                style={{
                                  width: "100%",
                                }}
                              >
                                {/* {res.Category} */}
                              </span>
                            </td>
                            <td>
                              <span
                                style={{
                                  width: "100%",
                                  marginLeft: "22px",
                                }}
                              >
                                {res?.Category}
                              </span>
                            </td>
                            <td>
                              <span
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  // justifyContent: "center",
                                  alignItems: "center",
                                  gap: "2px",
                                  marginLeft: "45px",
                                }}
                              >
                                <DollarSVG /> {res?.Amount}
                              </span>
                            </td>
                          </tr>
                        );
                      })}
                    </Fragment>
                  );
                })}
            </tbody>
          </table>
        </div>
      )}
      {type === "serviceType" && (
        <div className="statement_table_wrapper">
          <table
            className="common-table-container statement_table"
            ref={tableRef}
          >
            <thead>
              <tr className="statement_table_head">
                {tableHeadArrServiceType.map((elem, index) => (
                  <th key={index}>
                    <span>{elem}</span>
                  </th>
                ))}
              </tr>
            </thead>

            <tbody ref={tableRef} className="service_type_body">
              {isLoadingNewData && (
                <tr>
                  <td
                    style={{
                      textAlign: "center",
                      position: "absolute",
                      left: "45%",
                      top: "45%",
                    }}
                  ></td>
                </tr>
              )}
              {[]?.map((elem: any, index: number) => {
                return (
                  <tr
                    key={index}
                    className={
                      "normal-table-row normal-class-table-row-clients-table"
                    }
                  >
                    {[
                      "Weekday Daytime/One Only",
                      "Weekday Evening",
                      "Weekday Night",
                      "Saturday",
                      "Sunday",
                      "Public Holiday",
                    ]?.map((elem: any, index: number) => (
                      <td key={index}>{elem}</td>
                    ))}
                    <td>
                      <input
                        type="text"
                        value={elem}
                        name="subCategory"
                        placeholder=""
                        onChange={() => {}}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        value={elem}
                        name="subCategory"
                        placeholder=""
                        onChange={() => {}}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        value={elem}
                        name="subCategory"
                        placeholder=""
                        onChange={() => {}}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        value={elem}
                        name="subCategory"
                        placeholder=""
                        onChange={() => {}}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        value={elem}
                        name="subCategory"
                        placeholder=""
                        onChange={() => {}}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        value={elem}
                        name="subCategory"
                        placeholder=""
                        onChange={() => {}}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </Fragment>
  );
};

export default CommonTable;
