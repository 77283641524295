import React from "react";

const SelectedSortOptions = ({
  options,
  sortBy,
  sortChange,
  selectedSortIndex,
  iconColor,
}: any) => {
  return (
    <div>
      <div className="dropdown-2-box-sub-2">
        <div className="sub-dropdown-2">
          {options.map((title: any, index: number) => {
            return (
              <div
                className={`dropdown-2-item capitalize hover:text-white  ${
                  title === sortBy && "dropdown-2-item-selected text-white"
                }`}
                onClick={() => sortChange(title, selectedSortIndex)}
              >
                <span
                  className="font-normal"
                  style={{
                    textDecoration: "none",
                    textTransform: "none",
                  }}
                >
                  {index === 0 ? "A to Z" : "Z to A"}
                </span>
                <div className="relative left-[2px] flex">
                  {index === 0 ? (
                    <svg
                      width="20px"
                      height="20px"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12 4V20M12 20L8 16M12 20L16 16"
                        stroke={iconColor}
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  ) : (
                    <svg
                      width="20px"
                      height="20px"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12 4V20M12 4L8 8M12 4L16 8"
                        stroke={iconColor}
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default SelectedSortOptions;
