import "./index.css";
import Content from "./Content";
import Header from "./Header";
import Sidebar from "./Sidebar";
import useWindowSize from "../../../Model/utils/useWindowSize";
import { useEffect, useState } from "react";


interface LayoutInterface {
  children: string | JSX.Element | JSX.Element[];
}

const Layout = ({ children }: LayoutInterface) => {
  const { width } = useWindowSize();

  const [isMobileScreen, setIsMobileScreen] = useState<any>(false);

  useEffect(() => {
    if (width <= 600) {
      setIsMobileScreen(true);
    }
  }, [width, children]);



  // const isClinicalAndMobile = useAppSelector(
  //   (state) => state.common.changeStickyForClinicalWhenMobileView
  // );
  return (
    <div className={isMobileScreen ? "":"layout"}>
    
      
      {!isMobileScreen && (
        <>
          <Header />
          <div className="layout__sidebar_content__wrapper">
            <Sidebar />
            <Content>{children}</Content>
          </div>
        </>
      )}
      {isMobileScreen && <Content>{children}</Content>}
    </div>
  );
};

export default Layout;
