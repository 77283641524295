import React, { useEffect, useRef, useState } from "react";
import Table from "../../../components/CommonTable/Table";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../Controller/redux/Hooks";
import { useNavigate, useParams } from "react-router-dom";
import {
  getProviderBusiness,
  getProviderBusinessFilterThunk,
  resetBusinessProviderData,
} from "../../../../Controller/redux/features/ProviderFeature/providerBusinesSlice";
import {
  getProviderBusinessEmailThunk,
  getProviderBusinessNameThunk,
  getProviderBusinessPhoneThunk,
  resetBusinessPage,
  setBusinessPage,
} from "../../../../Controller/redux/features/BusinessFeature/businessSlice";

const ProviderBusiness = () => {
  // @ts-ignore
  const dynamicBusinessId = parseInt(localStorage.getItem("dynamicBusinessId"));
  const tableRef = useRef();
  const businessInnerRef = useRef();
  const navigate = useNavigate();
  const businessStatusDetailsArr = useAppSelector(
    (state) => state.common.statusDetailsData?.data
  );

  const { domainDetail } = useAppSelector((state) => state?.common);
  const columns = [
    { header: "Name", accessor: "name" },
    { header: "Email", accessor: "email" },
    { header: "Phone", accessor: "phone" },
    { header: "Status", accessor: "status" },
  ];
  const dropdownRefBusiness = useRef<any>();
  const svgParentRefBusiness = useRef<any>();
  const [isDropdownOpenBusiness, setIsDropdownOpenBusiness] = useState(false);

  useEffect(() => {
    const handleClickOutsideBusiness = (event: any) => {
      if (
        dropdownRefBusiness.current &&
        !dropdownRefBusiness.current.contains(event.target) &&
        svgParentRefBusiness.current &&
        !svgParentRefBusiness.current.contains(event.target)
      ) {
        setIsDropdownOpenBusiness(false);
      }
    };
    document.addEventListener("click", handleClickOutsideBusiness);
    return () => {
      document.removeEventListener("click", handleClickOutsideBusiness);
    };
  }, []);
  const id: any = useParams()?.id;
  const [providerId, setProviderId] = useState<any>("");
 
  const handleClickRow = (e: any, id: any) => {
     navigate(
      `/providers/${window.btoa(providerId)}/business/${window.btoa(id)}`
    );
  };

 
  useEffect(() => {
    if (id) {
      let realId = window?.atob(id);
      setProviderId(realId);
    }
  }, [id]);
  const dispatch = useAppDispatch();
  useEffect(() => {
    // dispatch(
    //   getProviderBusiness({
    //     dynamicProviderId: providerId,
    //     dynamicUserTypeId: dynamicBusinessId,
    //   })
    // );
    dispatch(
      getProviderBusinessNameThunk({
        dynamicProviderId: providerId,
        dynamicUserTypeId: dynamicBusinessId,
      })
    );
    dispatch(
      getProviderBusinessEmailThunk({
        dynamicProviderId: providerId,
        dynamicUserTypeId: dynamicBusinessId,
      })
    );
    dispatch(
      getProviderBusinessPhoneThunk({
        dynamicProviderId: providerId,
        dynamicUserTypeId: dynamicBusinessId,
      })
    );
  }, [providerId, dynamicBusinessId]);

  const { businessRecords, loading, lastPage } = useAppSelector(
    (state) => state?.providerBusiness
  );

  const [providerBusinessRecords, setProviderBusinessRecords] = useState([]);

  useEffect(() => {
    if (businessRecords) {
      setProviderBusinessRecords(businessRecords);
    }
  }, [businessRecords]);

  const { BusinessPage, BusinessFilterItems } = useAppSelector(
    (state) => state?.business
  );
  const { selectedFilterItemsBusiness, sortByBusinessR, searchValBusiness } =
    useAppSelector((state) => state?.business);
  const { filterIconColor } = useAppSelector((state) => state?.common);
  useEffect(() => {
    dispatch(resetBusinessProviderData());
    dispatch(resetBusinessPage());
  }, [
    BusinessFilterItems?.searchVal,
    BusinessFilterItems?.filterValue,
    BusinessFilterItems?.sortType,
    BusinessFilterItems?.status,
    dynamicBusinessId,
  ]);
  useEffect(() => {
    dispatch(
      getProviderBusinessFilterThunk({
        dynamicProviderId: providerId,
        dynamicUserTypeId: dynamicBusinessId,
        filterColumn: BusinessFilterItems?.filterColumn
          ? BusinessFilterItems?.filterColumn
          : "",
        filterValue: BusinessFilterItems?.filterValue
          ? BusinessFilterItems?.filterValue
          : "",
        searchVal: BusinessFilterItems?.searchVal
          ? BusinessFilterItems?.searchVal
          : "",
        sortColumn: BusinessFilterItems?.sortColumn
          ? BusinessFilterItems?.sortColumn
          : "",
        sortType: BusinessFilterItems?.sortType
          ? BusinessFilterItems?.sortType
          : "",
        status: BusinessFilterItems.status ? BusinessFilterItems.status : "",
        BusinessPage,
      })
    );
  }, [
    BusinessPage,
    providerId,
    BusinessFilterItems?.searchVal,
    BusinessFilterItems?.filterValue,
    BusinessFilterItems?.sortType,
    BusinessFilterItems?.status,
    dynamicBusinessId,
  ]);
  const onScroll = () => {
    if (businessInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } =
        businessInnerRef.current;

      if (
        scrollTop + clientHeight + +5 >= scrollHeight &&
        BusinessPage < lastPage
      ) {
        dispatch(setBusinessPage(BusinessPage));
      }
    }
  };
  return (
    <div className="provider_prospect_wrapper">
      <Table
        isLoading={loading}
        tableRef={tableRef}
        tableBodyRef={businessInnerRef}
        onScroll={onScroll}
        itemsArr={businessStatusDetailsArr}
        dynamicHeaderColor={domainDetail?.listingtbl_color}
        statusType="providerBusinessScreen"
        thData={columns}
        classNameTr="all-business-table-head"
        type="providerBusiness"
        dropdownRef={dropdownRefBusiness}
        isDropdownOpen={isDropdownOpenBusiness}
        onClickSvg={() => setIsDropdownOpenBusiness(true)}
        svgParentRef={svgParentRefBusiness}
        allClients={providerBusinessRecords}
        onClickRow={handleClickRow}
        classNameRow="all-business-table-head"
        fill={
          selectedFilterItemsBusiness.length > 0 ||
          searchValBusiness.length > 0 ||
          sortByBusinessR.length > 0
            ? filterIconColor
            : "#D8D3D3"
        }
      />
    </div>
  );
};

export default ProviderBusiness;
