import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { api } from "../../../services/api";

const initialState: any = {
  loading: false,
  error: null,
  currentEditVendor: {},
  vendorList: [],
  getUserTypeIdForVendors: null,
  currentVendor: {},
  softwareAccessList: [],
  allowedVendorIds: "",
  getUserRoleVendors: [],
};

export const getVendors = createAsyncThunk(
  "vendors/get_vendors",
  async ({ type }: any, { rejectWithValue }) => {
    try {
      const { data } = await api.get(`/vendors?paginate=true&type=${type}`);
      return data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data?.response);
    }
  }
);

export const getVendorById = createAsyncThunk(
  "vendors/get_vendor",
  async ({ type, id }: any, { rejectWithValue }) => {
    try {
      const { data } = await api.get(
        `/vendors?paginate=true&type=${type}&id=${id}`
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data?.response);
    }
  }
);

export const createVendor = createAsyncThunk(
  "vendors/create_vendor",
  async (vendorData: any, { rejectWithValue }) => {
    try {
      const { data } = await api.post(`/vendors/create_vendor`, vendorData);
      return data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data?.response);
    }
  }
);

export const updateVendor = createAsyncThunk(
  "vendors/update_vendor",
  async (vendorData: any, { rejectWithValue }) => {
    try {
      const { data } = await api.post(`/vendors/update_vendor`, vendorData);
      return data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data?.response);
    }
  }
);

export const getSoftwareAccess = createAsyncThunk(
  "vendors/view_software_access",
  async ({ type, id, parentId }: any, { rejectWithValue }) => {
    try {
      const { data } = await api.get(
        `/vendors/view_software_access?type=${type}&tenant_id=${id}&api_tenant_parent_id=${parentId}`
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data?.response);
    }
  }
);

export const addSoftwareAccess = createAsyncThunk(
  "vendors/vendors_software_access",
  async ({ id, vendors }: any, { rejectWithValue }) => {
    try {
      const { data } = await api.post(`/vendors/software_access`, {
        tenant_id: id,
        vendors,
      });
      return data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data?.response);
    }
  }
);

export const getUserRolesVendors = createAsyncThunk(
  "vendors/fetchUserRoles",
  async ({ id }: any, { rejectWithValue }) => {
    try {
      const response = await api.get(`/users/get_user_roles?type=${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.response);
    }
  }
);

const vendorSlice = createSlice({
  name: "vendors",
  initialState,
  reducers: {
    resetCurrentEditVendor: (state) => {
      state.currentEditVendor = null;
    },
    setCurrentEditVendor: (state, action) => {
      state.currentEditVendor = action.payload;
    },
    setUserTypeIdForVendors: (state, action) => {
      state.getUserTypeIdForVendors = action.payload;
    },
    setAllowedVendorIds: (state, action) => {
      state.allowedVendorIds = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getVendors.pending, (state) => {
        state.loading === true;
      })
      .addCase(getVendors.fulfilled, (state, action) => {
        state.loading = false;
        state.vendorList = action.payload?.data?.data;
      })
      .addCase(getVendors.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(createVendor.pending, (state) => {
        state.loading === true;
      })
      .addCase(createVendor.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(createVendor.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updateVendor.pending, (state) => {
        state.loading === true;
      })
      .addCase(updateVendor.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(updateVendor.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getVendorById.pending, (state) => {
        state.loading === true;
      })
      .addCase(getVendorById.fulfilled, (state, action) => {
        state.loading = false;
        state.currentVendor = action.payload?.data?.data[0];
      })
      .addCase(getVendorById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getSoftwareAccess.pending, (state) => {
        state.loading === true;
      })
      .addCase(getSoftwareAccess.fulfilled, (state, action) => {
        state.loading = false;
        const data = action.payload?.data;
        state.softwareAccessList = data;
        state.allowedVendorIds = data
          .flatMap((category) =>
            category.vendor_softwares
              .filter((software) => software.has_access)
              .map((software) => software.id)
          )
          .join(",");
      })
      .addCase(getSoftwareAccess.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(addSoftwareAccess.pending, (state) => {
        state.loading === true;
      })
      .addCase(addSoftwareAccess.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(addSoftwareAccess.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getUserRolesVendors.pending, (state) => {
        state.loading === true;
      })
      .addCase(getUserRolesVendors.fulfilled, (state, action) => {
        state.loading = false;
        state.getUserRoleVendors = action.payload;
      })
      .addCase(getUserRolesVendors.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const {
  resetCurrentEditVendor,
  setCurrentEditVendor,
  setUserTypeIdForVendors,
  setAllowedVendorIds,
} = vendorSlice.actions;

export default vendorSlice.reducer;
