import React, { useEffect, useRef, useState } from "react";
import "./Tags.style.css";
import CustomMultiSelect from "../MultiSelectDropdown/MultiSelect";
import { useLocation } from "react-router-dom";

const Tags = ({
  type,
  tagArray,
  selectedArrayListOfId,
  onSelectunAndSelectTag,
  onUnSelectTag,
  selectedTags,
}: any) => {
  const SVGIcon = (
    <svg
      width="12"
      height="14"
      viewBox="0 0 15 15"
      className="svg-cross-icon"
      xmlns="http://www.w3.org/2000/svg"
    >
      <text
        x="50%"
        y="50%"
        fill="#A1A1A1"
        textAnchor="middle"
        dominantBaseline="middle"
        fontFamily="Arial"
        fontSize="10px"
        fontWeight="bold"
      >
        x
      </text>
    </svg>
  );

  const [showTagsDropdownDaybook, setshowTagsDropdownDaybook] =
    useState<any>(false);

  const [daybookTagsArray, setdaybookTagsArray] = useState<any>([]);
  const pathname = useLocation()?.pathname;

  useEffect(() => {
    if (pathname?.includes("daybook")) {
      if (tagArray) {
        setdaybookTagsArray([...tagArray]);
      }
    }
  }, [tagArray, pathname]);

  const daybookTagsDropdownRef = useRef<any>();

  const [selectedOptions, setSelectedOptions] = useState<any>([]);

  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (e: any) => {
    const newValue = e.target.value.toLowerCase();
    setInputValue(newValue);
    setshowTagsDropdownDaybook(true);
  };
  const [filteredTags, setFilteredTags] = useState<any>([]);
  useEffect(() => {
    if (tagArray) {
      const filteredTags = daybookTagsArray.filter((user: any) =>
        user.tag_name.toLowerCase()?.includes(inputValue.toLowerCase())
      );

      setFilteredTags(filteredTags);
    }
  }, [inputValue, tagArray]);

  const handleSelectTag = (tag_name: any) => {
    setSelectedOptions([...selectedOptions, tag_name]);
  };

  const handleUnselectTag = (tag_name: any) => {
    setSelectedOptions(selectedOptions.filter((tag: any) => tag !== tag_name));
  };

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (
        daybookTagsDropdownRef.current &&
        !daybookTagsDropdownRef.current.contains(event.target)
      ) {
        setTimeout(() => {
          setshowTagsDropdownDaybook(false);
        }, 0);
      }
    }

    if (showTagsDropdownDaybook) {
      document.addEventListener("click", handleClickOutside);
    }
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [showTagsDropdownDaybook]);

  function handleKeyDown(e: any) {
    if (e.key === "Enter") {
      e.preventDefault();
      // addTag(inputValue);
    } else if (e.key === "Backspace" && inputValue === "") {
      // removeTag(tags.length - 1);
    }
  }

  const [inputValueMeal, setInputValueMeal] = useState("");
  const [filteredMealTags, setFilteredMealTags] = useState<any>([]);
  useEffect(() => {
    if (tagArray) {
      const filteredTags = tagArray.filter((user: any) =>
        user.tag_name.toLowerCase()?.includes(inputValueMeal.toLowerCase())
      );
      setFilteredMealTags(filteredTags);
    }
  }, [inputValueMeal, tagArray]);

  const handleInputChangeMealBuilder = (e: any) => {
    setInputValueMeal(e.target.value);
  };

  const [dropdownOpen, setDropdownOpen] = useState(false);

  let uniqueTagArray = filteredMealTags.filter(
    (item: { tag_name: any }, index: any) => {
      return (
        filteredMealTags.findIndex(
          (obj: { tag_name: any }) => obj.tag_name === item.tag_name
        ) === index
      );
    }
  );

  // console.log(uniqueTagArray,"uniqueTagArray");

  return (
    <>
      {type === "mealbuilder" && (
        <>
          <div className="meal-all-tags">
            {/* {tagArray?.length === 0 && <p>No tags found</p>} */}
            {tagArray?.length > 0 &&
              tagArray?.map((res: any, index: number) => {
                const isSelected = selectedArrayListOfId?.includes(res?.id);
                if (isSelected) {
                  return (
                    <div className="tag-label" key={index}>
                      <span className="tag__label__value">{res.tag_name}</span>
                      <span
                        onClick={() => {
                          onUnSelectTag(res.id);
                        }}
                      >
                        {SVGIcon}
                      </span>
                    </div>
                  );
                }
              })}
            <div className="input_dropdown__wrapper">
              <input
                onKeyDown={handleKeyDown}
                onChange={handleInputChangeMealBuilder}
                type="text"
                placeholder="Search Tag..."
                value={inputValueMeal}
                className="daybook__input__search__field"
                onFocus={() =>
                  // setState((prevData: any) => ({ ...prevData, dropdownOpen: true }))
                  setDropdownOpen(true)
                }
                onBlur={() => {
                  setTimeout(() => {
                    setDropdownOpen(false);
                  }, 500);
                }}
              />
            </div>
          </div>
          {dropdownOpen && inputValueMeal.length !== 0 && (
            <div className="meal-select-dropdown">
              {uniqueTagArray.map((res: any, index: number) => {
                const isSelected = selectedArrayListOfId?.includes(res?.id);
                if (!isSelected) {
                  return (
                    <div
                      key={index}
                      className="meal-select-dropdown-option"
                      onClick={() => {
                        onSelectunAndSelectTag(res.id);
                        setInputValueMeal("");
                      }}
                    >
                      {res.tag_name}
                    </div>
                  );
                }
              })}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Tags;
