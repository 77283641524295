import {useState} from "react";
import CommonQuickMenu from "../../components/CommonQuickMenu/CommonQuickMenu";
import CommonUpperBox from "../../components/UpperBox/CommonHeader";
import RosterWrapper from "./Components/RosterWrapper";
import SelectDate from "./Components/SelectDate";

const Roster = () => {
  const [stageTwoToGoalsModal, setstageTwoToGoalsModal] = useState<boolean>(false);

  return (
    <>
      <CommonUpperBox type={"listing"} title="Roster" 
        onClickPlus={() => {
          setstageTwoToGoalsModal(true);
      }}/>
      <SelectDate />
      <RosterWrapper />
      {stageTwoToGoalsModal && (
        <CommonQuickMenu
          setshowGoalsModal={(value: any) => {}}
          mainType={"listing"}
          setstageTwoToGoalsModal={setstageTwoToGoalsModal}
        />
      )}
    </>
  );
};

export default Roster;
