import React, { useEffect, useRef, useState } from "react";
import "./TagBuilder.css";
import "./Tags.style.css";
import useDimensions from "../../../Model/utils/useDimensions";

function TagsInput({
  allTags,
  setSelectedTags,
  selectedTask,
  selectedTags,
  onSelectTagIdChange,
  isGoalsModal,
  isComms,
  tagStatus,
  isDaybook,
  isClinicalProgress,
  elementRef,
}: any) {
  const [tags, setTags] = useState<any>([]);
  const [inputValue, setInputValue] = useState<any>("");
  const [suggestions, setSuggestions] = useState<any>([]);
  const [filteredSuggestions, setFilteredSuggestions] = useState<any>([]);
  const [selectedTagIds, setSelectedTagIds] = useState<number[]>([]);
  const [showTagsDropdownDaybook, setshowTagsDropdownDaybook] =
    useState<any>(false);
  const daybookTagsDropdownRef = useRef<any>(null);
  const SVGIcon = (
    <svg
      width="12"
      height="14"
      viewBox="0 0 15 15"
      className="svg-cross-icon"
      xmlns="http://www.w3.org/2000/svg"
    >
      <text
        x="50%"
        y="50%"
        fill="#A1A1A1"
        textAnchor="middle"
        dominantBaseline="middle"
        fontFamily="Arial"
        fontSize="10px"
        fontWeight="bold"
      >
        x
      </text>
    </svg>
  );

  useEffect(() => {
    const tagSuggestions = Object.values(allTags).map(
      (tag: any) => tag.tag_name
    );
    setSuggestions(tagSuggestions);

    if (typeof selectedTask?.tag === "string") {
      const selectedTagIdArray = selectedTask?.tag
        .split(",")
        .map((id: any) => parseInt(id.trim(), 10));
      const initialTags = selectedTagIdArray.map((tagId: any) => {
        const matchingTag = allTags.find((tag: any) => tag.id === tagId);
        return matchingTag ? matchingTag.tag_name : "";
      });

      setTags(initialTags);
      setSelectedTagIds(selectedTagIdArray);
    }

    if ((isComms || isDaybook) && Array.isArray(selectedTags) && selectedTags) {
      let getTagsNameArr =
        Array.isArray(selectedTags) &&
        selectedTags?.map((res: any) => {
          let tagsInfo = allTags.find((tag: any) => tag.id === res);
          return tagsInfo?.tag_name;
        });
      setTags(getTagsNameArr);
      setSelectedTagIds(selectedTags);
    }
  }, [allTags, selectedTask?.tag]);
  // console.log(tags,"selectedTagIdArray")

  // useEffect(() => {
  //   onSelectTagIdChange(selectedTagIds);
  // }, [selectedTagIds, setSelectedTags, selectedTags, onSelectTagIdChange]);

  function handleKeyDown(e: any) {
    if (e.key === "Enter") {
      e.preventDefault();
      addTag(inputValue);
    } else if (e.key === "Backspace" && inputValue === "") {
      removeTag(tags.length - 1);
    }
  }

  function addTag(tag: any) {
    if (tag.trim() === "") return;

    setTags((prevTags: any) => {
      if (!prevTags.includes(tag)) {
        const newTags = [...prevTags, tag];
        const selectedTag = allTags.find((t: any) => t.tag_name === tag);
        if (selectedTag) {
          setSelectedTagIds((prevIds) => {
            const newIds = [...prevIds, selectedTag.id];
            onSelectTagIdChange(new Set(newIds));
            return Array.from(new Set(newIds));
          });
        }
        return newTags;
      }
      return prevTags;
    });

    setInputValue("");
    setFilteredSuggestions([]);
  }

  function removeTag(index: any) {
    const updatedTags = tags.filter((_: any, i: any) => i !== index);
    setTags(updatedTags);

    if (tags[index]) {
      const selectedTag = allTags.find((t: any) => t.tag_name === tags[index]);
      if (selectedTag) {
        onSelectTagIdChange(
          selectedTagIds.filter((id) => id !== selectedTag.id)
        );
        setSelectedTagIds(selectedTagIds.filter((id) => id !== selectedTag.id));
      }
    }
  }

  function handleInputChange(e: any) {
    const value = e.target.value;
    setInputValue(value);

    setshowTagsDropdownDaybook(true);

    let filteredSuggestions: any;

    if (value.trim() === "") {
      filteredSuggestions = suggestions;
    } else {
      filteredSuggestions = suggestions.filter((suggestion: any) =>
        suggestion.toLowerCase().includes(value.toLowerCase())
      );
    }

    const filteredSuggestionsWithoutSelected = filteredSuggestions.filter(
      (suggestion: any) => !tags.includes(suggestion)
    );

    setFilteredSuggestions(filteredSuggestionsWithoutSelected);
  }

  const handleSuggestionClick = (suggestion: any) => {
    addTag(suggestion);
  };

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (
        daybookTagsDropdownRef.current &&
        !daybookTagsDropdownRef.current.contains(event.target)
      ) {
        setTimeout(() => {
          setshowTagsDropdownDaybook(false);
        }, 0);
      }
    }

    if (showTagsDropdownDaybook) {
      document.addEventListener("click", handleClickOutside);
    }
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [showTagsDropdownDaybook]);

  const { width: listItemWidth } = useDimensions(elementRef);

  // useEffect(() => {
  //   setSelectedTags(selectedTagIds);
  // }, [selectedTagIds]);

  //   const sendSelectedTagsToBackend = () => {
  //     // console.log("Selected Tag IDs:", selectedTagIds);
  //   };
  // ${
  //   tagStatus === "collapsed" ? "collpased" : "expanded"
  // } `
  return (
    <div className={`${!isComms && "tags_input_container"}`}>
      <div
        className={`daybook-tags-wrapper ${
          tagStatus === "collapsed" ? "collpased" : "expanded"
        }`}
      >
        <div
          className={`daybook-all-tags ${
            tagStatus === "collapsed" ? "collpased" : "expanded"
          } ${
            isGoalsModal ? "is_goals_modal_active" : isComms ? "is_comms" : ""
          }`}
          style={{ width: listItemWidth }}
        >
          {isClinicalProgress
            ? selectedTags?.map((tag: any, index: any) => (
                <div className="tag-label" key={index}>
                  <span className="tag__label__value">{tag.tag_name}</span>
                </div>
              ))
            : tags.map((tag: any, index: any) => (
                <div className="tag-label" key={index}>
                  <span className="tag__label__value">{tag}</span>
                  <span onClick={() => removeTag(index)}>{SVGIcon}</span>
                </div>
              ))}

          <div className="input_dropdown__wrapper">
            <input
              onKeyDown={handleKeyDown}
              readOnly={isClinicalProgress}
              onChange={handleInputChange}
              type="text"
              placeholder={isClinicalProgress ? "" : "Search Tag..."}
              value={inputValue}
              className="daybook__input__search__field"
            />
          </div>
        </div>
      </div>

      <div className={`daybook__dropdown__tags__wrapper`}>
        {showTagsDropdownDaybook && inputValue.length > 0 && (
          <div className="daybook__tags__dropdown" ref={daybookTagsDropdownRef}>
            {inputValue !== ""
              ? filteredSuggestions.map((suggestion: any, index: any) => (
                  <div
                    className="daybook__tag__item"
                    key={index}
                    onClick={() => handleSuggestionClick(suggestion)}
                  >
                    {suggestion}
                  </div>
                ))
              : null}
          </div>
        )}
      </div>
    </div>
  );
}

export default TagsInput;
