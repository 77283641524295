export const CustomFormExpectedArray = [
  {
    question:
      '{"entityMap":{},"blocks":[{"key":"al9ld","text":"Are you developer?","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[]}]}',
    answerType: "radio",
    questionText: "Are you developer?",
    isRequired: true,
    optionArr: ["Yes", "No"],
    customLogic: false,
    selectedQuestion: "",
    expectedAnswer: "",
    selectedAnswer: "",
  },
  {
    question:
      '{"entityMap":{},"blocks":[{"key":"1m80j","text":"Please selecte your technology.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[]}]}',
    answerType: "radio",
    questionText: "Please selecte your technology.",
    isRequired: true,
    optionArr: ["React JS", "Node JS", "Next JS"],
    customLogic: true,
    selectedQuestion: "Are you developer?",
    expectedAnswer: "Yes",
    selectedAnswer: "",
    
  },
  {
    question:
      '{"entityMap":{},"blocks":[{"key":"equjq","text":"How much do you have experince with Node JS","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":44,"style":"BOLD"}],"entityRanges":[]}]}',
    answerType: "text",
    questionText: "How much do you have experince with React JS",
    isRequired: true,
    optionArr: [],
    customLogic: true,
    selectedQuestion: "Please selecte your technology.",
    expectedAnswer: "Node JS",
    selectedAnswer: "",
  },
];
