import { createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  openFormTeams: false,
};

const documentTeamsSlice = createSlice({
  name: "documentTeamsSlice",
  initialState,
  reducers: {
    setOpenFormByClickTeams: (state) => {
      state.openFormTeams = true;
    },
    resetOpenFormByClickTeams: (state) => {
      state.openFormTeams = false;
    },
  },
});

export const { setOpenFormByClickTeams, resetOpenFormByClickTeams } =
  documentTeamsSlice.actions;
export default documentTeamsSlice.reducer;
