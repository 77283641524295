import React from "react";

interface TildeSVGProps {
  width: string;
  height: string;
  color?: string;
}

const TildeSVG = ({ height, width, color }: TildeSVGProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="12" height="12" rx="6" fill={color || "#06A5FF"} />
      <path
        d="M6.9384 7.07422C6.6584 7.07422 6.2984 6.96422 5.8684 6.72422C5.5284 6.55422 5.2884 6.46422 5.1384 6.46422C4.8384 6.46422 4.6684 6.66422 4.6084 7.04422H3.6884C3.7084 6.54422 3.8384 6.14422 4.0784 5.85422C4.3184 5.56422 4.6484 5.41422 5.0684 5.41422C5.3384 5.41422 5.6984 5.53422 6.1584 5.76422C6.4784 5.94422 6.7184 6.02422 6.8584 6.02422C7.1484 6.02422 7.3284 5.83422 7.3884 5.44422H8.3084C8.2884 5.95422 8.1584 6.35422 7.9184 6.64422C7.6784 6.93422 7.3484 7.07422 6.9384 7.07422Z"
        fill={ "#FCFCFC"}
      />
    </svg>
  );
};

TildeSVG.defaultProps = {
  width: "12",
  height: "12",
};

export default TildeSVG;
