import { Fragment, useContext, useEffect, useState } from "react";

import AddButtonWithIcons from "./AddButtonWithIcons";
import Pic from "../../../../Model/Assets/images/rectangleImage.svg";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../Controller/redux/Hooks";
import ClientImg from "../../../../Model/Assets/png/client.png";
import { useLocation, useParams } from "react-router-dom";
import { getFileThunk } from "../../../../Controller/redux/features/CommonFeature/commonSlice";

const LeftBox = ({
  type,
  // currentContact,
  // currentProspects,
  // currentStaff,
  // currentBusiness,
  onClick,
}: // currentClient,
any) => {
  const dispatch = useAppDispatch();

  const location = useLocation();
  const pathname = location.pathname;
  const profilePic = useAppSelector((state) => state?.common?.profilePicture);

  const [currentData, setCurrentData] = useState<any>([]);

  const { getUniqueProspect } = useAppSelector((state) => state?.prospects);
  const currentProspect = getUniqueProspect?.data?.data[0];

  const getCurrentClient = useAppSelector((state) => state.people.uniqueClient);
  const currentClient = getCurrentClient?.data?.data[0];

  const { getUniqueBusiness } = useAppSelector((state) => state?.business);
  const currentBusiness = getUniqueBusiness?.data?.data[0];

  const { getUniqueTeams } = useAppSelector((state) => state?.teams);
  const currentTeams = getUniqueTeams?.data?.data[0];

  const { getUniqueContact } = useAppSelector((state) => state?.contacts);
  const currentContact = getUniqueContact?.data?.data[0];

  const { getUniqueStaff } = useAppSelector((state) => state?.staff);
  const currentStaff = getUniqueStaff?.data?.data[0];

  useEffect(() => {
    if (pathname.includes("/prospects")) {
      setCurrentData(currentProspect);
    } else if (pathname.includes("/clients")) {
      setCurrentData(currentClient);
    } else if (pathname.includes("/business")) {
      setCurrentData(currentBusiness);
    } else if (pathname.includes("/teams")) {
      setCurrentData(currentTeams);
    } else if (pathname.includes("/contacts")) {
      setCurrentData(currentContact);
    } else if (pathname.includes("/staff")) {
      setCurrentData(currentStaff);
    }
  }, [
    currentProspect,
    currentClient,
    currentBusiness,
    currentTeams,
    currentContact,
    currentStaff,
  ]);

  const { user } = useAppSelector((state) => state.auth);
  const [clientId, setClientId] = useState<any>(null);
  const id = useParams()?.id;

  // Effect 1: Update clientId when id changes
  useEffect(() => {
    if (id) {
      const realId = window.atob(id);
      setClientId(realId);
    }
  }, [id]);
  const [image, setImage] = useState<any>(null);

  useEffect(() => {
    const path = currentData?.profile_image;
    if (path) {
      dispatch(getFileThunk({ path })).then((res: any) => {
        setImage(res?.payload?.data);
      });
    } else if (path === null) {
      setImage(null);
    }
  }, [currentData?.profile_image]);

  return (
    <Fragment>
      {type === "prospectsScreen" ? (
        <>
          <div>
            <div className="left-box-documents">
              <img
                onClick={onClick}
                src={currentData?.profile_image ? image : Pic}
                alt=""
                width={"70"}
                className="left_box_img"
              />

              <div className="left-box-upper-title-documents">
                {currentData?.first_name || ""} {currentData?.last_name || ""}
              </div>
              <div className="top-icons-wrapper-header_inner">
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="#FBBF14"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6 12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 0 6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12Z"
                    fill="#2AD400"
                  />
                  <path
                    d="M6 12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 0 6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12Z"
                    stroke=""
                  />
                </svg>

                <svg
                  width="15"
                  height="25"
                  viewBox="0 0 15 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="7.5"
                    cy="12.1876"
                    r="6.5"
                    fill="url(#paint0_linear_129_7288)"
                    stroke="white"
                    strokeWidth="1.2"
                  />
                  <path
                    d="M12.8397 7.65478C12.2517 8.29014 11.4706 8.81146 10.5608 9.17567C9.65109 9.53987 8.63887 9.73651 7.60778 9.74933C6.57669 9.76215 5.55632 9.5908 4.63098 9.24942C3.70564 8.90804 2.90188 8.40643 2.28609 7.78604"
                    stroke="white"
                    strokeWidth="1.2"
                  />
                  <path
                    d="M12.8397 16.7202C12.2517 16.0849 11.4706 15.5635 10.5608 15.1993C9.65109 14.8351 8.63887 14.6385 7.60778 14.6257C6.57669 14.6128 5.55632 14.7842 4.63098 15.1256C3.70564 15.467 2.90188 15.9686 2.28609 16.589"
                    stroke="white"
                    strokeWidth="1.2"
                  />
                  <path
                    d="M7.5 5.68762V18.6876"
                    stroke="white"
                    strokeWidth="1.2"
                  />
                  <path d="M14 12.1875H1" stroke="white" strokeWidth="1.2" />
                  <defs>
                    <linearGradient
                      id="paint0_linear_129_7288"
                      x1="1"
                      y1="5.68762"
                      x2="14.6626"
                      y2="15.6907"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#9035EE" />
                      <stop offset="0.152794" stopColor="#9930EF" />
                      <stop offset="0.531809" stopColor="#5737EE" />
                      <stop offset="1" stopColor="#795CEB" />
                    </linearGradient>
                  </defs>
                </svg>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                >
                  <path
                    d="M6 0C9.3138 0 12 2.6862 12 6C12 9.3138 9.3138 12 6 12C5.0424 12.0013 4.0985 11.7725 3.2478 11.3328C2.97312 11.1438 2.6433 11.1219 2.35089 11.2821C1.79004 11.5894 0.888309 12.0621 0.560239 11.976C0.431624 11.9423 0.314294 11.875 0.220271 11.7809C0.126247 11.6869 0.0589109 11.5696 0.0251613 11.441C-0.00858834 11.3123 -0.00753788 11.1771 0.028205 11.049L0.669005 8.75519C0.228216 7.90376 -0.00124128 6.95877 5.05016e-06 6C5.05016e-06 2.6862 2.6862 0 6 0ZM4.05 6.6H6.7488C6.86282 6.60003 6.97257 6.64334 7.05588 6.72118C7.13919 6.79902 7.18985 6.90558 7.19762 7.01932C7.20539 7.13307 7.16969 7.24553 7.09774 7.33398C7.02579 7.42242 6.92295 7.48026 6.81 7.4958L6.7488 7.5H4.05C3.93599 7.49996 3.82624 7.45665 3.74293 7.37881C3.65962 7.30098 3.60896 7.19442 3.60119 7.08067C3.59342 6.96692 3.62911 6.85446 3.70106 6.76602C3.77301 6.67757 3.87585 6.61974 3.9888 6.6042L4.05 6.6ZM4.05 4.5H7.953C8.06697 4.50018 8.17661 4.5436 8.25981 4.62149C8.34301 4.69937 8.39355 4.80592 8.40124 4.91962C8.40894 5.03333 8.3732 5.14571 8.30125 5.23409C8.2293 5.32247 8.1265 5.38026 8.0136 5.3958L7.953 5.4H4.05C3.93599 5.39996 3.82624 5.35665 3.74293 5.27881C3.65962 5.20098 3.60896 5.09442 3.60119 4.98067C3.59342 4.86692 3.62911 4.75446 3.70106 4.66602C3.77301 4.57757 3.87585 4.51974 3.9888 4.5042L4.05 4.5Z"
                    fill="#EF3A34"
                  />
                  <text
                    x="6"
                    y="6"
                    fill="#FFF"
                    textAnchor="middle"
                    fontFamily="Arial"
                    fontSize="10px"
                    fontStyle="normal"
                    fontWeight="400"
                    letterSpacing="0.25px"
                    alignmentBaseline="central"
                  >
                    0
                  </text>
                </svg>
              </div>
            </div>
          </div>
        </>
      ) : type === "staffScreen" ? (
        <>
          <div>
            <div className="left-box-documents">
              <img
                onClick={onClick}
                src={image || Pic}
                alt=""
                width={"70"}
                className="left_box_img"
              />

              <div className="left-box-upper-title-documents">
                {currentData?.first_name || ""} {currentData?.last_name || ""}
              </div>
              <div className="top-icons-wrapper-header_inner">
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="#FBBF14"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6 12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 0 6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12Z"
                    fill="#2AD400"
                  />
                  <path
                    d="M6 12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 0 6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12Z"
                    stroke=""
                  />
                </svg>

                <svg
                  width="15"
                  height="25"
                  viewBox="0 0 15 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="7.5"
                    cy="12.1876"
                    r="6.5"
                    fill="url(#paint0_linear_129_7288)"
                    stroke="white"
                    strokeWidth="1.2"
                  />
                  <path
                    d="M12.8397 7.65478C12.2517 8.29014 11.4706 8.81146 10.5608 9.17567C9.65109 9.53987 8.63887 9.73651 7.60778 9.74933C6.57669 9.76215 5.55632 9.5908 4.63098 9.24942C3.70564 8.90804 2.90188 8.40643 2.28609 7.78604"
                    stroke="white"
                    strokeWidth="1.2"
                  />
                  <path
                    d="M12.8397 16.7202C12.2517 16.0849 11.4706 15.5635 10.5608 15.1993C9.65109 14.8351 8.63887 14.6385 7.60778 14.6257C6.57669 14.6128 5.55632 14.7842 4.63098 15.1256C3.70564 15.467 2.90188 15.9686 2.28609 16.589"
                    stroke="white"
                    strokeWidth="1.2"
                  />
                  <path
                    d="M7.5 5.68762V18.6876"
                    stroke="white"
                    strokeWidth="1.2"
                  />
                  <path d="M14 12.1875H1" stroke="white" strokeWidth="1.2" />
                  <defs>
                    <linearGradient
                      id="paint0_linear_129_7288"
                      x1="1"
                      y1="5.68762"
                      x2="14.6626"
                      y2="15.6907"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#9035EE" />
                      <stop offset="0.152794" stopColor="#9930EF" />
                      <stop offset="0.531809" stopColor="#5737EE" />
                      <stop offset="1" stopColor="#795CEB" />
                    </linearGradient>
                  </defs>
                </svg>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                >
                  <path
                    d="M6 0C9.3138 0 12 2.6862 12 6C12 9.3138 9.3138 12 6 12C5.0424 12.0013 4.0985 11.7725 3.2478 11.3328C2.97312 11.1438 2.6433 11.1219 2.35089 11.2821C1.79004 11.5894 0.888309 12.0621 0.560239 11.976C0.431624 11.9423 0.314294 11.875 0.220271 11.7809C0.126247 11.6869 0.0589109 11.5696 0.0251613 11.441C-0.00858834 11.3123 -0.00753788 11.1771 0.028205 11.049L0.669005 8.75519C0.228216 7.90376 -0.00124128 6.95877 5.05016e-06 6C5.05016e-06 2.6862 2.6862 0 6 0ZM4.05 6.6H6.7488C6.86282 6.60003 6.97257 6.64334 7.05588 6.72118C7.13919 6.79902 7.18985 6.90558 7.19762 7.01932C7.20539 7.13307 7.16969 7.24553 7.09774 7.33398C7.02579 7.42242 6.92295 7.48026 6.81 7.4958L6.7488 7.5H4.05C3.93599 7.49996 3.82624 7.45665 3.74293 7.37881C3.65962 7.30098 3.60896 7.19442 3.60119 7.08067C3.59342 6.96692 3.62911 6.85446 3.70106 6.76602C3.77301 6.67757 3.87585 6.61974 3.9888 6.6042L4.05 6.6ZM4.05 4.5H7.953C8.06697 4.50018 8.17661 4.5436 8.25981 4.62149C8.34301 4.69937 8.39355 4.80592 8.40124 4.91962C8.40894 5.03333 8.3732 5.14571 8.30125 5.23409C8.2293 5.32247 8.1265 5.38026 8.0136 5.3958L7.953 5.4H4.05C3.93599 5.39996 3.82624 5.35665 3.74293 5.27881C3.65962 5.20098 3.60896 5.09442 3.60119 4.98067C3.59342 4.86692 3.62911 4.75446 3.70106 4.66602C3.77301 4.57757 3.87585 4.51974 3.9888 4.5042L4.05 4.5Z"
                    fill="#EF3A34"
                  />
                  <text
                    x="6"
                    y="6"
                    fill="#FFF"
                    textAnchor="middle"
                    fontFamily="Arial"
                    fontSize="10px"
                    fontStyle="normal"
                    fontWeight="400"
                    letterSpacing="0.25px"
                    alignmentBaseline="central"
                  >
                    0
                  </text>
                </svg>
              </div>
            </div>
          </div>
        </>
      ) : type === "others" ? (
        <>
          <div>
            <div className="left-box-documents">
              <AddButtonWithIcons />
            </div>
          </div>
        </>
      ) : type === "contactsScreen" ? (
        <>
          <div>
            <div className="left-box-documents">
              <img
                onClick={onClick}
                src={image || Pic}
                alt=""
                width={"70"}
                className="left_box_img"
              />

              <div className="left-box-upper-title-documents">
                {currentData?.first_name || ""} {currentData?.last_name || ""}
              </div>
              <div className="top-icons-wrapper-header_inner">
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="#FBBF14"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6 12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 0 6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12Z"
                    fill="#2AD400"
                  />
                  <path
                    d="M6 12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 0 6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12Z"
                    stroke=""
                  />
                </svg>

                <svg
                  width="15"
                  height="25"
                  viewBox="0 0 15 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="7.5"
                    cy="12.1876"
                    r="6.5"
                    fill="url(#paint0_linear_129_7288)"
                    stroke="white"
                    strokeWidth="1.2"
                  />
                  <path
                    d="M12.8397 7.65478C12.2517 8.29014 11.4706 8.81146 10.5608 9.17567C9.65109 9.53987 8.63887 9.73651 7.60778 9.74933C6.57669 9.76215 5.55632 9.5908 4.63098 9.24942C3.70564 8.90804 2.90188 8.40643 2.28609 7.78604"
                    stroke="white"
                    strokeWidth="1.2"
                  />
                  <path
                    d="M12.8397 16.7202C12.2517 16.0849 11.4706 15.5635 10.5608 15.1993C9.65109 14.8351 8.63887 14.6385 7.60778 14.6257C6.57669 14.6128 5.55632 14.7842 4.63098 15.1256C3.70564 15.467 2.90188 15.9686 2.28609 16.589"
                    stroke="white"
                    strokeWidth="1.2"
                  />
                  <path
                    d="M7.5 5.68762V18.6876"
                    stroke="white"
                    strokeWidth="1.2"
                  />
                  <path d="M14 12.1875H1" stroke="white" strokeWidth="1.2" />
                  <defs>
                    <linearGradient
                      id="paint0_linear_129_7288"
                      x1="1"
                      y1="5.68762"
                      x2="14.6626"
                      y2="15.6907"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#9035EE" />
                      <stop offset="0.152794" stopColor="#9930EF" />
                      <stop offset="0.531809" stopColor="#5737EE" />
                      <stop offset="1" stopColor="#795CEB" />
                    </linearGradient>
                  </defs>
                </svg>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                >
                  <path
                    d="M6 0C9.3138 0 12 2.6862 12 6C12 9.3138 9.3138 12 6 12C5.0424 12.0013 4.0985 11.7725 3.2478 11.3328C2.97312 11.1438 2.6433 11.1219 2.35089 11.2821C1.79004 11.5894 0.888309 12.0621 0.560239 11.976C0.431624 11.9423 0.314294 11.875 0.220271 11.7809C0.126247 11.6869 0.0589109 11.5696 0.0251613 11.441C-0.00858834 11.3123 -0.00753788 11.1771 0.028205 11.049L0.669005 8.75519C0.228216 7.90376 -0.00124128 6.95877 5.05016e-06 6C5.05016e-06 2.6862 2.6862 0 6 0ZM4.05 6.6H6.7488C6.86282 6.60003 6.97257 6.64334 7.05588 6.72118C7.13919 6.79902 7.18985 6.90558 7.19762 7.01932C7.20539 7.13307 7.16969 7.24553 7.09774 7.33398C7.02579 7.42242 6.92295 7.48026 6.81 7.4958L6.7488 7.5H4.05C3.93599 7.49996 3.82624 7.45665 3.74293 7.37881C3.65962 7.30098 3.60896 7.19442 3.60119 7.08067C3.59342 6.96692 3.62911 6.85446 3.70106 6.76602C3.77301 6.67757 3.87585 6.61974 3.9888 6.6042L4.05 6.6ZM4.05 4.5H7.953C8.06697 4.50018 8.17661 4.5436 8.25981 4.62149C8.34301 4.69937 8.39355 4.80592 8.40124 4.91962C8.40894 5.03333 8.3732 5.14571 8.30125 5.23409C8.2293 5.32247 8.1265 5.38026 8.0136 5.3958L7.953 5.4H4.05C3.93599 5.39996 3.82624 5.35665 3.74293 5.27881C3.65962 5.20098 3.60896 5.09442 3.60119 4.98067C3.59342 4.86692 3.62911 4.75446 3.70106 4.66602C3.77301 4.57757 3.87585 4.51974 3.9888 4.5042L4.05 4.5Z"
                    fill="#EF3A34"
                  />
                  <text
                    x="6"
                    y="6"
                    fill="#FFF"
                    textAnchor="middle"
                    fontFamily="Arial"
                    fontSize="10px"
                    fontStyle="normal"
                    fontWeight="400"
                    letterSpacing="0.25px"
                    alignmentBaseline="central"
                  >
                    0
                  </text>
                </svg>
              </div>
            </div>
          </div>
        </>
      ) : type === "BusinessScreen" ? (
        <>
          <div>
            <div className="left-box-documents">
              <img
                onClick={onClick}
                src={image || Pic}
                alt="Profile"
                width={"70"}
                className="left_box_img"
              />

              <div className="left-box-upper-title-documents">
                {currentData?.name || ""}
              </div>
              <div className="top-icons-wrapper-header_inner">
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="#FBBF14"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6 12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 0 6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12Z"
                    fill="#2AD400"
                  />
                  <path
                    d="M6 12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 0 6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12Z"
                    stroke=""
                  />
                </svg>

                <svg
                  width="15"
                  height="25"
                  viewBox="0 0 15 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="7.5"
                    cy="12.1876"
                    r="6.5"
                    fill="url(#paint0_linear_129_7288)"
                    stroke="white"
                    strokeWidth="1.2"
                  />
                  <path
                    d="M12.8397 7.65478C12.2517 8.29014 11.4706 8.81146 10.5608 9.17567C9.65109 9.53987 8.63887 9.73651 7.60778 9.74933C6.57669 9.76215 5.55632 9.5908 4.63098 9.24942C3.70564 8.90804 2.90188 8.40643 2.28609 7.78604"
                    stroke="white"
                    strokeWidth="1.2"
                  />
                  <path
                    d="M12.8397 16.7202C12.2517 16.0849 11.4706 15.5635 10.5608 15.1993C9.65109 14.8351 8.63887 14.6385 7.60778 14.6257C6.57669 14.6128 5.55632 14.7842 4.63098 15.1256C3.70564 15.467 2.90188 15.9686 2.28609 16.589"
                    stroke="white"
                    strokeWidth="1.2"
                  />
                  <path
                    d="M7.5 5.68762V18.6876"
                    stroke="white"
                    strokeWidth="1.2"
                  />
                  <path d="M14 12.1875H1" stroke="white" strokeWidth="1.2" />
                  <defs>
                    <linearGradient
                      id="paint0_linear_129_7288"
                      x1="1"
                      y1="5.68762"
                      x2="14.6626"
                      y2="15.6907"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#9035EE" />
                      <stop offset="0.152794" stopColor="#9930EF" />
                      <stop offset="0.531809" stopColor="#5737EE" />
                      <stop offset="1" stopColor="#795CEB" />
                    </linearGradient>
                  </defs>
                </svg>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                >
                  <path
                    d="M6 0C9.3138 0 12 2.6862 12 6C12 9.3138 9.3138 12 6 12C5.0424 12.0013 4.0985 11.7725 3.2478 11.3328C2.97312 11.1438 2.6433 11.1219 2.35089 11.2821C1.79004 11.5894 0.888309 12.0621 0.560239 11.976C0.431624 11.9423 0.314294 11.875 0.220271 11.7809C0.126247 11.6869 0.0589109 11.5696 0.0251613 11.441C-0.00858834 11.3123 -0.00753788 11.1771 0.028205 11.049L0.669005 8.75519C0.228216 7.90376 -0.00124128 6.95877 5.05016e-06 6C5.05016e-06 2.6862 2.6862 0 6 0ZM4.05 6.6H6.7488C6.86282 6.60003 6.97257 6.64334 7.05588 6.72118C7.13919 6.79902 7.18985 6.90558 7.19762 7.01932C7.20539 7.13307 7.16969 7.24553 7.09774 7.33398C7.02579 7.42242 6.92295 7.48026 6.81 7.4958L6.7488 7.5H4.05C3.93599 7.49996 3.82624 7.45665 3.74293 7.37881C3.65962 7.30098 3.60896 7.19442 3.60119 7.08067C3.59342 6.96692 3.62911 6.85446 3.70106 6.76602C3.77301 6.67757 3.87585 6.61974 3.9888 6.6042L4.05 6.6ZM4.05 4.5H7.953C8.06697 4.50018 8.17661 4.5436 8.25981 4.62149C8.34301 4.69937 8.39355 4.80592 8.40124 4.91962C8.40894 5.03333 8.3732 5.14571 8.30125 5.23409C8.2293 5.32247 8.1265 5.38026 8.0136 5.3958L7.953 5.4H4.05C3.93599 5.39996 3.82624 5.35665 3.74293 5.27881C3.65962 5.20098 3.60896 5.09442 3.60119 4.98067C3.59342 4.86692 3.62911 4.75446 3.70106 4.66602C3.77301 4.57757 3.87585 4.51974 3.9888 4.5042L4.05 4.5Z"
                    fill="#EF3A34"
                  />
                  <text
                    x="6"
                    y="6"
                    fill="#FFF"
                    textAnchor="middle"
                    fontFamily="Arial"
                    fontSize="10px"
                    fontStyle="normal"
                    fontWeight="400"
                    letterSpacing="0.25px"
                    alignmentBaseline="central"
                  >
                    0
                  </text>
                </svg>
              </div>
            </div>
          </div>
        </>
      ) : type === "menubuilder" ? (
        <div>
          <div className="left-box-documents">
            <img
              onClick={onClick}
              src={ClientImg}
              alt=""
              width={"70"}
              className="left_box_img"
            />

            <div className="left-box-upper-title-documents">
              {user?.first_name || ""} {user?.last_name || ""}
            </div>
            <div className="top-icons-wrapper-header">
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="#FBBF14"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 0 6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12Z"
                  fill="#2AD400"
                />
                <path
                  d="M6 12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 0 6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12Z"
                  stroke=""
                />
              </svg>

              <svg
                width="15"
                height="25"
                viewBox="0 0 15 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="7.5"
                  cy="12.1876"
                  r="6.5"
                  fill="url(#paint0_linear_129_7288)"
                  stroke="white"
                  strokeWidth="1.2"
                />
                <path
                  d="M12.8397 7.65478C12.2517 8.29014 11.4706 8.81146 10.5608 9.17567C9.65109 9.53987 8.63887 9.73651 7.60778 9.74933C6.57669 9.76215 5.55632 9.5908 4.63098 9.24942C3.70564 8.90804 2.90188 8.40643 2.28609 7.78604"
                  stroke="white"
                  strokeWidth="1.2"
                />
                <path
                  d="M12.8397 16.7202C12.2517 16.0849 11.4706 15.5635 10.5608 15.1993C9.65109 14.8351 8.63887 14.6385 7.60778 14.6257C6.57669 14.6128 5.55632 14.7842 4.63098 15.1256C3.70564 15.467 2.90188 15.9686 2.28609 16.589"
                  stroke="white"
                  strokeWidth="1.2"
                />
                <path
                  d="M7.5 5.68762V18.6876"
                  stroke="white"
                  strokeWidth="1.2"
                />
                <path d="M14 12.1875H1" stroke="white" strokeWidth="1.2" />
                <defs>
                  <linearGradient
                    id="paint0_linear_129_7288"
                    x1="1"
                    y1="5.68762"
                    x2="14.6626"
                    y2="15.6907"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#9035EE" />
                    <stop offset="0.152794" stopColor="#9930EF" />
                    <stop offset="0.531809" stopColor="#5737EE" />
                    <stop offset="1" stopColor="#795CEB" />
                  </linearGradient>
                </defs>
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
              >
                <path
                  d="M6 0C9.3138 0 12 2.6862 12 6C12 9.3138 9.3138 12 6 12C5.0424 12.0013 4.0985 11.7725 3.2478 11.3328C2.97312 11.1438 2.6433 11.1219 2.35089 11.2821C1.79004 11.5894 0.888309 12.0621 0.560239 11.976C0.431624 11.9423 0.314294 11.875 0.220271 11.7809C0.126247 11.6869 0.0589109 11.5696 0.0251613 11.441C-0.00858834 11.3123 -0.00753788 11.1771 0.028205 11.049L0.669005 8.75519C0.228216 7.90376 -0.00124128 6.95877 5.05016e-06 6C5.05016e-06 2.6862 2.6862 0 6 0ZM4.05 6.6H6.7488C6.86282 6.60003 6.97257 6.64334 7.05588 6.72118C7.13919 6.79902 7.18985 6.90558 7.19762 7.01932C7.20539 7.13307 7.16969 7.24553 7.09774 7.33398C7.02579 7.42242 6.92295 7.48026 6.81 7.4958L6.7488 7.5H4.05C3.93599 7.49996 3.82624 7.45665 3.74293 7.37881C3.65962 7.30098 3.60896 7.19442 3.60119 7.08067C3.59342 6.96692 3.62911 6.85446 3.70106 6.76602C3.77301 6.67757 3.87585 6.61974 3.9888 6.6042L4.05 6.6ZM4.05 4.5H7.953C8.06697 4.50018 8.17661 4.5436 8.25981 4.62149C8.34301 4.69937 8.39355 4.80592 8.40124 4.91962C8.40894 5.03333 8.3732 5.14571 8.30125 5.23409C8.2293 5.32247 8.1265 5.38026 8.0136 5.3958L7.953 5.4H4.05C3.93599 5.39996 3.82624 5.35665 3.74293 5.27881C3.65962 5.20098 3.60896 5.09442 3.60119 4.98067C3.59342 4.86692 3.62911 4.75446 3.70106 4.66602C3.77301 4.57757 3.87585 4.51974 3.9888 4.5042L4.05 4.5Z"
                  fill="#EF3A34"
                />
                <text
                  x="6"
                  y="6"
                  fill="#FFF"
                  textAnchor="middle"
                  fontFamily="Arial"
                  fontSize="10px"
                  fontStyle="normal"
                  fontWeight="400"
                  letterSpacing="0.25px"
                  alignmentBaseline="central"
                >
                  0
                </text>
              </svg>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className="left-box-documents">
            <img
              onClick={onClick}
              src={profilePic || Pic}
              alt=""
              width={"70"}
              className="left_box_img"
            />

            <div className="left-box-upper-title-documents">
              {currentData?.first_name || ""} {currentData?.last_name || ""}
            </div>
            <div className="top-icons-wrapper-header_inner">
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="#FBBF14"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 0 6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12Z"
                  fill="#2AD400"
                />
                <path
                  d="M6 12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 0 6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12Z"
                  stroke=""
                />
              </svg>

              <svg
                width="15"
                height="25"
                viewBox="0 0 15 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="7.5"
                  cy="12.1876"
                  r="6.5"
                  fill="url(#paint0_linear_129_7288)"
                  stroke="white"
                  strokeWidth="1.2"
                />
                <path
                  d="M12.8397 7.65478C12.2517 8.29014 11.4706 8.81146 10.5608 9.17567C9.65109 9.53987 8.63887 9.73651 7.60778 9.74933C6.57669 9.76215 5.55632 9.5908 4.63098 9.24942C3.70564 8.90804 2.90188 8.40643 2.28609 7.78604"
                  stroke="white"
                  strokeWidth="1.2"
                />
                <path
                  d="M12.8397 16.7202C12.2517 16.0849 11.4706 15.5635 10.5608 15.1993C9.65109 14.8351 8.63887 14.6385 7.60778 14.6257C6.57669 14.6128 5.55632 14.7842 4.63098 15.1256C3.70564 15.467 2.90188 15.9686 2.28609 16.589"
                  stroke="white"
                  strokeWidth="1.2"
                />
                <path
                  d="M7.5 5.68762V18.6876"
                  stroke="white"
                  strokeWidth="1.2"
                />
                <path d="M14 12.1875H1" stroke="white" strokeWidth="1.2" />
                <defs>
                  <linearGradient
                    id="paint0_linear_129_7288"
                    x1="1"
                    y1="5.68762"
                    x2="14.6626"
                    y2="15.6907"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#9035EE" />
                    <stop offset="0.152794" stopColor="#9930EF" />
                    <stop offset="0.531809" stopColor="#5737EE" />
                    <stop offset="1" stopColor="#795CEB" />
                  </linearGradient>
                </defs>
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
              >
                <path
                  d="M6 0C9.3138 0 12 2.6862 12 6C12 9.3138 9.3138 12 6 12C5.0424 12.0013 4.0985 11.7725 3.2478 11.3328C2.97312 11.1438 2.6433 11.1219 2.35089 11.2821C1.79004 11.5894 0.888309 12.0621 0.560239 11.976C0.431624 11.9423 0.314294 11.875 0.220271 11.7809C0.126247 11.6869 0.0589109 11.5696 0.0251613 11.441C-0.00858834 11.3123 -0.00753788 11.1771 0.028205 11.049L0.669005 8.75519C0.228216 7.90376 -0.00124128 6.95877 5.05016e-06 6C5.05016e-06 2.6862 2.6862 0 6 0ZM4.05 6.6H6.7488C6.86282 6.60003 6.97257 6.64334 7.05588 6.72118C7.13919 6.79902 7.18985 6.90558 7.19762 7.01932C7.20539 7.13307 7.16969 7.24553 7.09774 7.33398C7.02579 7.42242 6.92295 7.48026 6.81 7.4958L6.7488 7.5H4.05C3.93599 7.49996 3.82624 7.45665 3.74293 7.37881C3.65962 7.30098 3.60896 7.19442 3.60119 7.08067C3.59342 6.96692 3.62911 6.85446 3.70106 6.76602C3.77301 6.67757 3.87585 6.61974 3.9888 6.6042L4.05 6.6ZM4.05 4.5H7.953C8.06697 4.50018 8.17661 4.5436 8.25981 4.62149C8.34301 4.69937 8.39355 4.80592 8.40124 4.91962C8.40894 5.03333 8.3732 5.14571 8.30125 5.23409C8.2293 5.32247 8.1265 5.38026 8.0136 5.3958L7.953 5.4H4.05C3.93599 5.39996 3.82624 5.35665 3.74293 5.27881C3.65962 5.20098 3.60896 5.09442 3.60119 4.98067C3.59342 4.86692 3.62911 4.75446 3.70106 4.66602C3.77301 4.57757 3.87585 4.51974 3.9888 4.5042L4.05 4.5Z"
                  fill="#EF3A34"
                />
                <text
                  x="6"
                  y="6"
                  fill="#FFF"
                  textAnchor="middle"
                  fontFamily="Arial"
                  fontSize="10px"
                  fontStyle="normal"
                  fontWeight="400"
                  letterSpacing="0.25px"
                  alignmentBaseline="central"
                >
                  0
                </text>
              </svg>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default LeftBox;
