import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { api } from "../../../services/api";

const initialState: any = {
  getSalesDetails: {},
  loading: false,
  error: null,
};

export const getUserSalesReportThunk = createAsyncThunk(
  "sales/fetchDetails",
  async ({ type, past_days, future_days, statusArr }: any) => {
    try {
      const { data } = await api.get(
        `/users/get_sale_details?type=${type}&past_days=${past_days}&future_days=${future_days}&status=${statusArr}`
      );
      return data?.data;
    } catch (error) {
      throw error;
    }
  }
);

export const salesSlice = createSlice({
  name: "sales",
  initialState,
  reducers: {
    resetSalesData: (state) => {
      state.getSalesDetails = []
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserSalesReportThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUserSalesReportThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.getSalesDetails = action.payload;
      })
      .addCase(getUserSalesReportThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});
export const {resetSalesData} = salesSlice.actions;
export default salesSlice.reducer;
