import { statusMap } from "../../../../Model/utils/Helper/statusMap";

import WidgetItems from "../../../pages/People/ClientPage/Components/WidgetItems";

interface StatusClinicalPropsTypes {
  position: any; //
  setOpenStatusWidget: (arg: any) => any; //
  OpenStatusWidget: any;
  widgetRef: any;
  setAllClientsDataToTrackClickOfStatusClick: (arg: any) => any;
  allClientsDataToTrackClickOfStatusClick: any;
  handleOpenWidget: (arg: any) => any;
  statusData: any;
  setShowStatusWidget: (arg: any) => any;
  itemsArr: any;
}

const StatusClinical: React.FC<StatusClinicalPropsTypes> = ({
  position,
  setOpenStatusWidget,
  OpenStatusWidget,
  widgetRef,
  setAllClientsDataToTrackClickOfStatusClick,
  allClientsDataToTrackClickOfStatusClick,
  handleOpenWidget,
  statusData,
  setShowStatusWidget,
  itemsArr,
}) => {
  return (
    <span
      className="status-btn status-btn-active"
      style={{
        color: statusData?.color,
        backgroundColor: statusData?.bgCol,
        minWidth: statusData?.type?.includes("On Leave") ? "90px" : "auto",
        border: `1px solid ${statusData?.color}`,
        fontWeight: 700,
      }}
      onClick={(e) => handleOpenWidget(e)}
    >
      <span>{statusData?.type}</span>
      {OpenStatusWidget && (
        <div ref={widgetRef} className="status-widget-div">
          <WidgetItems
            setOpenStatusWidget={setOpenStatusWidget}
            type={"clientScreen"}
            position={position}
            widgetRef={widgetRef}
            setShowStatusWidget={setShowStatusWidget}
            setAllClientsDataToTrackClickOfStatusClick={
              setAllClientsDataToTrackClickOfStatusClick
            }
            allClientsDataToTrackClickOfStatusClick={
              allClientsDataToTrackClickOfStatusClick
            }
            itemsArr={itemsArr}
          />
        </div>
      )}
    </span>
  );
};

export default StatusClinical;
