export const prospectsFieldsArr = [
  {
    UpperTitle: "Given Name:",
    accessor: "first_name",
  },
  {
    UpperTitle: "Middle Name(s):",
    accessor: "middle_name",
  },
  {
    UpperTitle: "Family Name:",
    accessor: "last_name",
  },
  {
    UpperTitle: "Known As:",
    accessor: "pref_name",
  },
  {
    UpperTitle: "Address:",
    accessor: "user_addresses",
  },

  {
    UpperTitle: "Email:",
    accessor: "email",
  },
  {
    UpperTitle: "Phone:",
    accessor: "phone",
  },
  {
    UpperTitle: "Mobile:",
    accessor: "mobile",
  },
  {
    UpperTitle: "Date of Birth:",
    accessor: "date_of_birth",
  },
];

export const clientFieldsArr = [
  {
    UpperTitle: "Given Name:",
    accessor: "first_name",
  },
  {
    UpperTitle: "Middle Name(s):",
    accessor: "middle_name",
  },

  {
    UpperTitle: "Family Name:",
    accessor: "last_name",
  },
  {
    UpperTitle: "Known As:",
    accessor: "pref_name",
  },
  {
    UpperTitle: "Address:",
    accessor: "user_addresses",
  },
  {
    UpperTitle: "Email:",
    accessor: "email",
  },
  {
    UpperTitle: "Phone:",
    accessor: "phone",
  },
  {
    UpperTitle: "Mobile:",
    accessor: "mobile",
  },
  {
    UpperTitle: "Date of Birth:",
    accessor: "date_of_birth",
  },
];

export const businessFieldsArr = [
  {
    UpperTitle: "Business Name:",
    accessor: "first_name",
  },
  {
    UpperTitle: "Email:",
    accessor: "email",
  },

  {
    UpperTitle: "Address:",
    accessor: "user_addresses",
  },

  {
    UpperTitle: "Contact Number:",
    accessor: "phone",
  },

  {
    UpperTitle: "Business Type:",
    accessor: "roles",
  },
];

export const locationsFiedlsArr = [
  {
    UpperTitle: "Location Name:",
    accessor: "first_name",
  },
  {
    UpperTitle: "Email:",
    accessor: "email",
  },

  {
    UpperTitle: "Address:",
    accessor: "user_addresses",
  },

  {
    UpperTitle: "Contact Number:",
    accessor: "phone",
  },

  {
    UpperTitle: "Location Type:",
    accessor: "roles",
  },
];

export const teamsFieldsArr = [
  {
    UpperTitle: "Team Name:",
    accessor: "first_name",
  },
  {
    UpperTitle: "Site/Business:",
    accessor: "",
  },
  {
    UpperTitle: "Team Leader:",
    accessor: "",
  },

  {
    UpperTitle: "Team Contact:",
    accessor: "phone",
  },
  {
    UpperTitle: "Email:",
    accessor: "email",
  },
];
export const ContactsFieldsArr = [
  {
    UpperTitle: "Given Name:",
    accessor: "first_name",
  },
  {
    UpperTitle: "Middle Name(s):",
    accessor: "middle_name",
  },
  {
    UpperTitle: "Family Name:",
    accessor: "last_name",
  },
  {
    UpperTitle: "Known As:",
    accessor: "pref_name",
  },
  {
    UpperTitle: "Address:",
    accessor: "user_addresses",
  },

  {
    UpperTitle: "Email:",
    accessor: "email",
  },
  {
    UpperTitle: "Mobile:",
    accessor: "mobile",
  },
  {
    UpperTitle: "Role:",
    accessor: "role",
  },
];

export const staffsFieldsArr = [
  {
    UpperTitle: "Given Name:",
    accessor: "first_name",
  },
  {
    UpperTitle: "Middle Name(s):",
    accessor: "middle_name",
  },
  {
    UpperTitle: "Family Name:",
    accessor: "last_name",
  },
  {
    UpperTitle: "Known As:",
    accessor: "pref_name",
  },
  {
    UpperTitle: "Address:",
    accessor: "user_addresses",
  },

  {
    UpperTitle: "Date of Birth:",
    accessor: "date_of_birth",
  },
  {
    UpperTitle: "Email:",
    accessor: "email",
  },
  {
    UpperTitle: "Mobile:",
    accessor: "mobile",
  },
  {
    UpperTitle: "Role:",
    accessor: "role",
  },
];
