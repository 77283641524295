import { ReactNode, useContext } from "react";

import BreadcrumbComponent from "../components/Breadcrumb/Breadcrumb";
import Layout from "../components/Layout";
import { Navigate } from "react-router-dom";
import { validateUserPermissions } from "../../Model/utils/validateUserPermissions";
import { useAppSelector } from "../../Controller/redux/Hooks";
import { getToken } from "../../Model/utils/tokenCookies";

// import { Header } from "../components/Header/Header";

interface IPrivateRoute {
  permissions?: string[];
  roles?: string[];
  redirectTo?: string;
  children: ReactNode;
}

export function PrivateRoute({
  permissions,
  roles,
  redirectTo = "/login",
  children,
}: IPrivateRoute | any) {
  const { user, isAuthenticated, loadingUserData } = useAppSelector(
    (state) => state?.auth
  );

  // const token = localStorage.getItem("token");
  const token = getToken();
  if (loadingUserData) {
    return null;
  }

  if (!token) {
    return <Navigate to={redirectTo} />;
  }

  const { hasAllPermissions } = validateUserPermissions({
    user,
    permissions,
    roles,
  });

  if (!hasAllPermissions) {
    return <Navigate to={redirectTo} />;
  }

  return (
    <>
      <Layout>{children}</Layout>
      {/* <div className="h-screen w-screen max-h-[100vh] relative">
        <div className="h-[60px]"></div>
        <div className="h-[calc(100vh-60px)] max-h-[calc(100vh-60px)] flex">
          <div className="w-[100px] border-r border-r-[#82828233]">
            <SideNavbar />
          </div>
          <div className="w-[calc(100vw-100px)] p-6 overflow-y-auto overflow-x-auto">
            <div className="min-w-[1200px] ">
              <div className="mb-[10px]">
                <BreadcrumbComponent />
              </div>
              {children}
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
}
