
import React, { Fragment, useEffect, useRef, useState } from "react";
import CommonUpperBox from "../../../components/UpperBox/CommonHeader";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../Controller/redux/Hooks";
import Table from "../../../components/CommonTable/Table";
import {
  getPayRule,
  getPayRuleAssociatedUserThunk,
  getPayRuleByFilterThunk,
  getPayRuleNameThunk,
  getPayRuleRateThunk,
  resetPayRuleData,
  resetPayRulePage,
  setPayRulePage,
} from "../../../../Controller/redux/features/RefDataFeature/PayRuleFeature/payruleSlice";
import CircularNoteTrigger from "../../../components/CircularNoteTrigger/CircularNoteTrigger";
import { useNavigate } from "react-router-dom";
import CommonQuickMenu from "../../../components/CommonQuickMenu/CommonQuickMenu";
const PayRule = () => {
  const { PayRuleData, loading } = useAppSelector((state) => state.payRule);
  const { domainDetail, filterIconColor } = useAppSelector(
    (state) => state?.common
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    // dispatch(getPayRule());
    dispatch(getPayRuleNameThunk());
    dispatch(getPayRuleAssociatedUserThunk());
    dispatch(getPayRuleRateThunk());
  }, []);

  const {
    lastPage,
    payRulePage,
    payRuleData,
    // loading,
    selectedFilterItemsPayRule,
    sortByPayRuleR,
    searchValPayRule,
    payRuleFilterItems,
  } = useAppSelector((state) => state.payRule);
  useEffect(() => {
    dispatch(resetPayRuleData());
    dispatch(resetPayRulePage());
  }, [
    payRuleFilterItems?.searchVal,
    payRuleFilterItems?.filterValue,
    payRuleFilterItems?.sortType,
  ]);
  useEffect(() => {
    dispatch(
      getPayRuleByFilterThunk({
        filterColumn: payRuleFilterItems?.filterColumn
          ? payRuleFilterItems?.filterColumn
          : "",
        filterValue: payRuleFilterItems?.filterValue
          ? payRuleFilterItems?.filterValue
          : "",
        searchVal: payRuleFilterItems?.searchVal
          ? payRuleFilterItems?.searchVal
          : "",
        sortColumn: payRuleFilterItems?.sortColumn
          ? payRuleFilterItems?.sortColumn
          : "",
        sortType: payRuleFilterItems?.sortType
          ? payRuleFilterItems?.sortType
          : "",
        payRulePage,
      })
    );
  }, [
    payRulePage,
    payRuleFilterItems?.searchVal,
    payRuleFilterItems?.filterValue,
    payRuleFilterItems?.sortType,
  ]);
  const uniquePayRule = PayRuleData?.filter(
    (item: any, index: number, array: any) => {
      return array?.findIndex((obj: any) => obj.id === item.id) === index;
    }
  );

  // const [PayRuleRecords, setPayRuleRecords] = useState([]);

  // useEffect(() => {
  //   if (PayRuleData?.data) {
  //     setPayRuleRecords(PayRuleData?.data);
  //   }
  // }, [PayRuleData]);

  const columns = [
    { header: "Name", accessor: "expenseName" },
    {
      header: "Associated User Type",
      accessor: "associated_user_type",
    },
    { header: "Rate", accessor: "rate" },
    { header: "Action", accessor: "action" },
  ];
  const navigate = useNavigate();
  const handleClickRow = (e: any, id: any) => {
    if (!isDropdownOpenPayRule) {
      navigate(`/ref-data/pay-rule/${window.btoa(id)}`);
    }
  };

  const tableRef = useRef();
  const payRuleInnerRef = useRef();

  const clientStatusDetailsArr = useAppSelector(
    (state) => state.common.statusDetailsData?.data
  );
  const dropdownRefPayRule = useRef<any>();
  const svgParentRefPayRule = useRef<any>();
  const [isDropdownOpenPayRule, setIsDropdownOpenPayRule] = useState(false);

  useEffect(() => {
    const handleClickOutsideClients = (event: any) => {
      if (
        dropdownRefPayRule.current &&
        !dropdownRefPayRule.current.contains(event.target) &&
        svgParentRefPayRule.current &&
        !svgParentRefPayRule.current.contains(event.target)
      ) {
        setIsDropdownOpenPayRule(false);
      }
    };
    document.addEventListener("click", handleClickOutsideClients);
    return () => {
      document.removeEventListener("click", handleClickOutsideClients);
    };
  }, []);

  const onScroll = () => {
    if (payRuleInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = payRuleInnerRef.current;
      if (
        scrollTop + clientHeight + +5 >= scrollHeight &&
        payRulePage < lastPage
      ) {
        dispatch(setPayRulePage(payRulePage));
      }
    }
  };
  const [stageTwoToGoalsModal, setstageTwoToGoalsModal] = useState<any>(false);

  return (
    <Fragment>
      <CommonUpperBox
        title="Pay Rule"
        type={"listing"}
        backgroundWithText={true}
        text={"P R"}
        onClickPlus={() => {
            setstageTwoToGoalsModal(true);
        }}
      />
      <Table
        isLoading={loading}
        tableRef={tableRef}
        tableBodyRef={payRuleInnerRef}
        onScroll={onScroll}
        dynamicHeaderColor={domainDetail?.listingtbl_color}
        statusType="payRuleScreen"
        thData={columns}
        itemsArr={clientStatusDetailsArr}
        classNameTr={"all-expenss-type-table-head"}
        type="payRule"
        dropdownRef={dropdownRefPayRule}
        isDropdownOpen={isDropdownOpenPayRule}
        onClickSvg={() => setIsDropdownOpenPayRule(true)}
        svgParentRef={svgParentRefPayRule}
        allClients={uniquePayRule}
        onClickRow={handleClickRow}
        classNameRow={"all-expenss-type-table-body"}
        fill={
          selectedFilterItemsPayRule.length > 0 ||
          searchValPayRule.length > 0 ||
          sortByPayRuleR.length > 0
            ? filterIconColor
            : "#D8D3D3"
        }
      />
      <CircularNoteTrigger type="payrule" title="Add Pay Rule" />
      {stageTwoToGoalsModal && (
        <CommonQuickMenu
          setshowGoalsModal={(value: any) => {}}
          mainType={"listing"}
          setstageTwoToGoalsModal={setstageTwoToGoalsModal}
        />
      )}
    </Fragment>
  );
};

export default PayRule;
