import React, { useEffect } from "react";
import CommonUpperBox from "../../components/UpperBox/CommonHeader";
import Table from "../../components/CommonTable/Table";
import {
  resetButtonTypeSticky,
  setButtonTypeSticky,
  setShowStickyNote,
} from "../../../Controller/redux/features/CommonFeature/commonSlice";
import CircularNoteTrigger from "../../components/CircularNoteTrigger/CircularNoteTrigger";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../Controller/redux/Hooks";
import {
  getUserRolesVendors,
  getVendors,
} from "../../../Controller/redux/features/VendorFeature/VendorSlice";
import { useNavigate } from "react-router-dom";

const VendorScreen = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const dynamicVendorsId = localStorage.getItem("dynamicVendorsId");
  const { vendorList } = useAppSelector((state) => state?.vendors);

  const thData = [
    { header: "Title", accessor: "vendor_name" },
    { header: "Status", accessor: "status" },
    { header: "Action", accessor: "vendorAction" },
  ];

  const handleClickRow = (e: any, id: any) => {
    navigate(`${window.btoa(id)}`);
  };

  useEffect(() => {
    dispatch(getVendors({ type: dynamicVendorsId }));
    dispatch(getUserRolesVendors({ id: dynamicVendorsId }));
  }, []);

  return (
    <>
      <CommonUpperBox title="Vendors" type={"listing"} />
      <Table
        itemsArr={[]}
        allClients={vendorList}
        statusType="vendorScreen"
        thData={thData}
        classNameTr={` all-vendors-table-head `}
        type="vendors"
        onClickRow={handleClickRow}
        classNameRow={`all-vendors-table-body`}
        fill={"#D8D3D3"}
      />
      <CircularNoteTrigger type="Vendors" title="Vendors" />
    </>
  );
};

export default VendorScreen;
