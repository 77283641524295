import React, { useEffect, useState, Fragment, useRef } from "react";
import "../Login/login.css";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { LogoLeft } from "../../../components/LogoLeft/LogoLeft";
import {
  signUpThunk,
  verifySignUpLinkThunk,
} from "../../../../Controller/redux/features/AuthFeature/authSlice";
import { toast } from "react-toastify";
import { removeTokenCookies } from "../../../../Model/utils/tokenCookies";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../Controller/redux/Hooks";
import { getDomainDetailsThunk } from "../../../../Controller/redux/features/CommonFeature/commonSlice";
import { Spinner } from "@chakra-ui/react";

type PasswordStrength =
  | "Very Weak"
  | "Weak"
  | "Medium"
  | "Strong"
  | "Very Strong";

export function SignUp() {
  const { error, newUserId, newUserName } = useAppSelector(
    (state) => state.auth
  );

  const { logo, success_colour } = useAppSelector(
    (state) => state?.common?.fromGetDomainDetails
  );
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const params: any = useParams();
  const [errorNo, setErrorNo] = useState(0);

  useEffect(() => {
    dispatch(verifySignUpLinkThunk({ signup_token: params.signup_token }));
    dispatch(getDomainDetailsThunk());
  }, []);

  useEffect(() => {
    if (error === "Invalid invite!" && errorNo === 0) {
      navigate("/login");
    }
  }, [error]);

  const [password, setPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [passwordStrength, setPasswordStrength] =
    useState<PasswordStrength>("Very Weak");

  const inputHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const enteredValue = event.target.value;
    setPassword(enteredValue);
    passwordRef(enteredValue);
  };

  const newinputHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const enteredValue = event.target.value.trim();
    setNewPassword(enteredValue);
    // passwordRef(enteredValue);
  };
  useEffect(() => {
    if (password.length <= 6) {
      setPasswordStrength("Very Weak");
    } else if (password.length <= 8) {
      setPasswordStrength("Medium");
    } else if (password.length <= 12) {
      setPasswordStrength("Strong");
    } else {
      setPasswordStrength("Very Strong");
    }
  }, [password]);

  // const [values, setValues] = useState(initialFormValues);
  const [requestStatus, setRequestStatus] = useState("success");

  const {
    register: validation,
    handleSubmit: handlevalidation,
    formState: { errors },
  } = useForm();

  const userNameRef = useRef<HTMLInputElement | null>(null);
  const userPasswordRef = useRef<HTMLInputElement | null>(null);

  const { ref: usernameRef, ...restusername } = validation("username");
  const { ref: passwordRef, ...restpassword } = validation("password");

  async function handleSignup(e: any) {
    if (password !== newPassword) {
      toast.error("Password not matching");
      return;
    }
    setRequestStatus("loading");
  
    const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*?&])[0-9a-zA-Z@$!%*?&]{6,}$/;
    if (!passwordRegex.test(password)) {
      toast.warn(
        "Password must contain at least 6 characters including uppercase, lowercase letters, one number, and one of the following symbols: @$!%*?&"
      );
      setRequestStatus("error");
      return;
    }else{
      const res = await dispatch(
        signUpThunk({ ...e, id: newUserId, password: password })
      );
    
     
      if (res.payload === "User account created successfully") {
        toast.success("User Account Created Successfully!", {
          style: {},
          progressStyle: { backgroundColor: success_colour },
          icon: (
            <span
              style={{
                marginRight: "8px",
                borderRadius: "50%",
                width: "7rem",
                height: "35px",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: success_colour,
                backgroundColor: "white",
                fontSize: "1.5rem",
                border: `3px solid ${success_colour}`,
              }}
            >
              ✓
            </span>
          ),
        });
      }
      setRequestStatus("success");
      removeTokenCookies();
      window.localStorage.clear();
      navigate("/login");
    
    }

      }
  

  return (
    <Fragment>
      <div className="main-section main-section-display login-screen"
      
      style={{
        position:"relative",
        height:"100vh"
      }}>
        <LogoLeft />
      



                <div className=""
                
                style={{
                  backgroundColor: "#e9e9e9",
                  position:'absolute',
                  right:"0px",
                  marginRight:"2%",
                  marginBottom:"30px",
                  bottom:"0px"
                }}>
                  <div className="login-heading">
                    <div className="logo-section">
                      <a href="">
                        <img
                          src={logo}
                          alt="logo"
                          className="imf-fluid display-block-img"
                        />
                      </a>
                    </div>
                  </div>
                  <form onSubmit={handlevalidation(handleSignup)}>
                    <div
                      className={`form-section ${
                        errors.username || errors.password ? "changeColor" : ""
                      }`}
                    >
                      <div className="form-group error">
                        <div className="wrapper">
                          <h3>Welcome </h3>
                          <h3>{newUserName}</h3>
                        </div>

                        <div className="">
                          <label>Create Your Username & Password </label>
                          <div className="input-section">
                            <input
                              {...restusername}
                              type="text"
                              id="username"
                              placeholder="Create Username"
                              disabled={requestStatus === "loading"}
                              {...validation("username", { required: true })}
                              ref={(e) => {
                                usernameRef(e);
                                userNameRef.current = e;
                              }}
                            />
                          </div>
                          {errors.username && (
                            <span className="errorname">
                              Username is required
                            </span>
                          )}
                        </div>
                        <br />
                        <div className="form-group error error-svg">
                          <div className="input-section">
                            <input
                              {...restpassword}
                              type="password"
                              placeholder="New Password"
                              disabled={requestStatus === "loading"}
                              // {...validation("password", { required: true })}
                              onChange={(e) => {
                                inputHandler(e);
                              }}
                              value={password}
                         
                            />
                          </div>
                          {errors.password && (
                            <span className="errorname">
                              Password is required
                            </span>
                          )}
                        </div>

                        <div
                          style={{
                            width: 300,
                            height: 10,
                            marginTop: 20,
                            background: "#fff",
                            border: "1px solid #444",
                            borderRadius: "5px",
                          }}
                        >
                          <div
                            style={{
                              height: "100%",
                              maxWidth: "100%",
                              backgroundColor: `${
                                (password.length / 16) * 100 <= 40
                                  ? "red"
                                  : (password.length / 16) * 100 <= 50
                                  ? "orange"
                                  : (password.length / 16) * 100 <= 80
                                  ? "lightgreen"
                                  : "darkgreen"
                              }`,
                              width: `${(password.length / 16) * 100}%`,
                            }}
                          ></div>
                          <div style={{ padding: "20px 0" }}>
                            {passwordStrength}
                          </div>
                        </div>

                        <br />
                        <div className="form-group error error-svg">
                          <div className="input-section">
                            <input
                              {...restpassword}
                              type="password"
                              placeholder="Confirm Password"
                              onChange={newinputHandler}
                            />
                          </div>
                          {errors.password && (
                            <span className="errorname">
                              Password is required
                            </span>
                          )}
                        </div>
                        <div className="btn-section">
                          <button className="comman-btn" type="submit">
                            {requestStatus === "loading" ? (
                              <Spinner
                                thickness="4px"
                                size="md"
                                speed="0.65s"
                              />
                            ) : (
                              "SIGN UP"
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
    </Fragment>
  );
}
