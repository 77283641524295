export const updateProfileArr = [
  {
    UpperTitle: "Current Password:",
    LowerTitle: "",
    placeHolder: "Enter Current Password",
  },
  {
    UpperTitle: "New Password:",
    LowerTitle: "",
    placeHolder: "Enter New Password",
  },
  {
    UpperTitle: "Confirm Password:",
    LowerTitle: "",
    placeHolder: "Enter Confirm Password",
  },
];
