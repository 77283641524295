import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../../../services/api";

const initialState: any = {
  loading: false,
  error: "",
  reports: [],
  kitchenReportrecord: [],
};

export const getReportsThunk = createAsyncThunk(
  "meals/fetchReports",
  async ({ date, mealType }: any) => {
    try {
      const { data } = await api.get(
        `reports/meal_session_report?date=${date}&meal_type=${mealType}`
      );
      return data.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getKitchenReportsThunk = createAsyncThunk(
  "meals/fetchKitchenReports",
  async ({ date }: any) => {
    try {
      const { data } = await api.get(
        `reports/daily_kitchen_report?date=${date}&meal_type=All Meals,Breakfast,MorningTea,Lunch,AfternoonTea,Dinner,Supper`
      );
      return data.data;
    } catch (error) {
      throw error;
    }
  }
);

const reports = createSlice({
  name: "reports",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getReportsThunk.pending, (state) => {
        state.loading === true;
      })
      .addCase(getReportsThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.reports = action.payload;
      })
      .addCase(getReportsThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getKitchenReportsThunk.pending, (state) => {
        state.loading === true;
      })
      .addCase(getKitchenReportsThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.kitchenReportrecord = action.payload;
      })
      .addCase(getKitchenReportsThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const {} = reports.actions;
export default reports.reducer;
