import React from "react";

const DefaultFileSVG = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3_2498)">
        <path
          d="M18.334 5.58691V16.8044C18.334 17.4653 17.7682 18 17.0714 18H1.59654C0.899797 18 0.333984 17.4638 0.333984 16.8044V1.19564C0.333984 0.536243 0.899797 0 1.59654 0H12.4997"
          fill="#2AD400"
        />
        <path d="M12.5 0V5.58691H18.3327L12.5 0Z" fill="#24A500" />
        <path
          d="M4.79395 7.69751V13.9594H13.875V7.69751H4.79395ZM13.0956 9.52448H9.72415V8.47693H13.0956V9.52448ZM9.72415 10.3039H13.0956V11.3514H9.72415V10.3039ZM8.94479 11.3514H5.5733V10.3039H8.94479V11.3514ZM8.94479 8.47693V9.52448H5.5733V8.47693H8.94479ZM5.5733 12.1309H8.94479V13.1784H5.5733V12.1309ZM9.72415 13.1784V12.1309H13.0956V13.1784H9.72415Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_3_2498">
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(0.333984)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default DefaultFileSVG;
