import React from "react";
import { useEffect, useState } from "react";
import { api } from "../../../../Controller/services/api";
import { toast } from "react-toastify";
import {
  useAppSelector,
  useAppDispatch,
} from "../../../../Controller/redux/Hooks";
import {
  getStaffByFilterThunk,
  getStaffThunk,
  getUserRolesStaffThunk,
  resetStaffData,
} from "../../../../Controller/redux/features/StaffFeature/staffSlice";
import {
  getProspectsByFilterThunk,
  getProspectsThunk,
  getUserRolesProspectsThunk,
  resetProspectData,
} from "../../../../Controller/redux/features/ProspectsFeature/prospectsSlice";
import {
  getContactsByFilterThunk,
  getContactsThunk,
  getUserRolesContactsThunk,
  resetContactData,
  resetContactPage,
} from "../../../../Controller/redux/features/ContactsFeature/contactsSlice";
import {
  getClientsByFilterThunk,
  getClientsThunk,
  getUserRolesClientsThunk,
  resetClientData,
} from "../../../../Controller/redux/features/ClientFeature/clientsSlice";
import { getLocationUserThunk } from "../../../../Controller/redux/features/LocationFeature/locationSlice";
import {
  setProfileTypeSticky,
  setShowStickyNote,
} from "../../../../Controller/redux/features/CommonFeature/commonSlice";
import _ from "lodash";
import { Switch } from "@chakra-ui/react";
import { useLocation, useParams } from "react-router-dom";
import { getBusinessByFilterThunk } from "../../../../Controller/redux/features/BusinessFeature/businessSlice";
import {
  getProviderProspectFilterThunk,
  resetProspectProviderData,
} from "../../../../Controller/redux/features/ProviderFeature/providerProspect";
import {
  getProviderClientFilterThunk,
  resetClientProviderData,
} from "../../../../Controller/redux/features/ProviderFeature/providerClientsSlice";
import {
  getProviderStaffsByFilterThunk,
  resetStaffProviderData,
} from "../../../../Controller/redux/features/ProviderFeature/providerStaffs";
import {
  getProviderContactsByFilterThunk,
  resetContactProviderData,
} from "../../../../Controller/redux/features/ProviderFeature/providerContactsSlice";

const OtherForm: React.FC<any> = ({
  type,
  currentTitle,
  activeTabProfile,
  comp,
}) => {
  const id = useParams()?.id;

  const [providerId, setProviderId] = useState<any>("");
  useEffect(() => {
    if (id) {
      let realId = window.atob(id);
      setProviderId(realId);
    }
  }, [id]);
  const {
    prospects_label,
    clients_label,
    business_label,
    locations_label,
    teams_label,
    contacts_label,
    staff_label,
  } = useAppSelector((state) => state?.common?.domainDetail);

  const StatusDetailsArr = useAppSelector(
    (state) => state.common.statusDetailsData?.data
  );

  const { getProfileTypeSticky } = useAppSelector((state) => state?.common);
  const { domainDetail } = useAppSelector((state) => state?.common);

  const pathname = useLocation()?.pathname;
  const getUserRoles = useAppSelector((state) => state?.people?.clientRoles);
  const prospectRoles = useAppSelector(
    (state) => state?.prospects?.prospectRoles
  );
  const contactRoles = useAppSelector((state) => state?.contacts?.contactRoles);
  const staffRoles = useAppSelector((state) => state?.staff?.staffUserRoles);
  const { ContactPage } = useAppSelector((state) => state.contacts);
  const { ClientPage } = useAppSelector((state) => state.people);
  const { staffPage } = useAppSelector((state) => state.staff);
  const { ProspectPageFilter } = useAppSelector((state) => state.prospects);

  function lastCharecterEmitter(label: string) {
    let nameArr = label.split("");
    let resolved = nameArr.filter(
      (e: any, index: number) => index !== nameArr.length - 1
    );
    let res = resolved.join("");
    return res;
  }

  //  @ts-ignore
  const dynamicStaffId = parseInt(localStorage.getItem("dynamicStaffId"));
  useEffect(() => {
    dispatch(getUserRolesClientsThunk({ dynamicClientId }));
    dispatch(getUserRolesStaffThunk({ dynamicStaffId }));
    dispatch(
      getUserRolesProspectsThunk({
        dynamicProspectsId,
      })
    );
    dispatch(getUserRolesContactsThunk({ dynamicContactId }));
  }, [dynamicStaffId]);
  const b2bCondition =
    domainDetail?.businessmodel_type === "B2B" &&
    (type === clients_label ||
      type === prospects_label ||
      type === contacts_label);

  const currLocation =
    type === clients_label
      ? domainDetail?.client_type?.split(",")
      : type === prospects_label
      ? domainDetail?.prospect_type?.split(",")
      : type === staff_label
      ? domainDetail?.staff_type?.split(",")
      : type === contacts_label
      ? domainDetail?.contact_type?.split(",")
      : type === locations_label
      ? domainDetail?.location_type?.split(",")
      : type === business_label
      ? domainDetail?.business_type?.split(",")
      : "";
  const [selectedType, setselectedType] = useState("");

  const handleSelectType = (e: any) => {
    setselectedType(e.target.value);
  };

  const currType =
    type === clients_label
      ? getUserRoles?.data
          ?.map((elem: any) => (currLocation.includes(elem.desc) ? elem : null))
          .filter((elem: any) => elem !== null)
      : type === prospects_label
      ? prospectRoles?.data
          ?.map((elem: any) => (currLocation.includes(elem.desc) ? elem : null))
          .filter((elem: any) => elem !== null)
      : type === staff_label
      ? staffRoles?.data
          ?.map((elem: any) => (currLocation.includes(elem.desc) ? elem : null))
          .filter((elem: any) => elem !== null)
      : type === contacts_label
      ? contactRoles?.data
          ?.map((elem: any) => (currLocation.includes(elem.desc) ? elem : null))
          .filter((elem: any) => elem !== null)
      : "";
  const dispatch = useAppDispatch();
  const [commonBody, setcommonBody] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    dob: "",
    funding_type: "",
    type: type === staff_label ? 2 : 1,
    status: "",
  });
  const [deliveryValue, setDeliveryValue] = useState<number>(0);
  //@ts-ignore
  const dynamicContactId = parseInt(localStorage.getItem("dynamicContactId"));
  // @ts-ignore
  const dynamicClientId = parseInt(localStorage.getItem("dynamicClientId"));
  const dynamicColor = useAppSelector((state) => state?.common?.dynamicColor);

  const dynamicProspectsId = parseInt(
    // @ts-ignore
    localStorage.getItem("dynamicProspectId")
  );

  // @ts-ignore
  const dynamicLocationId = parseInt(localStorage.getItem("dynamicLocationId"));
  // @ts-ignore
  const dynamicBusinessId = parseInt(localStorage.getItem("dynamicBusinessId"));
  // @ts-ignore
  const dynamicTeamsId = parseInt(localStorage.getItem("dynamicTeamsId"));
  const [IsLoading, setIsLoading] = useState(false);

  const handleAddForm = async () => {
    setIsLoading(true);
    const url = "/users/create_user";
    let modifiedCommonBody;
    switch (type) {
      case staff_label:
        modifiedCommonBody = {
          ...commonBody,
          type: dynamicStaffId,
          has_sent_mail: deliveryValue,
          role: selectedType,
        };
        break;
      case prospects_label:
        role: selectedType;
        modifiedCommonBody = {
          ...commonBody,
          type: dynamicProspectsId,
          role: selectedType,
        };
        break;
      case contacts_label:
        modifiedCommonBody = {
          ...commonBody,
          type: dynamicContactId,
          role: selectedType,
        };
        break;
      case clients_label:
        modifiedCommonBody = {
          ...commonBody,
          type: dynamicClientId,
          role: selectedType,
        };
        break;

      default:
        modifiedCommonBody = { ...commonBody, role: selectedType };
        break;
    }
    if (
      type === staff_label &&
      commonBody.first_name !== "" &&
      commonBody.last_name !== "" &&
      commonBody.email !== "" &&
      commonBody.status !== "" &&
      type !== contacts_label &&
      type !== clients_label &&
      type !== prospects_label
    ) {
      try {
        const response = await api.post(url, {
          ...modifiedCommonBody,
          tenant_id: providerId,
        });
        if (response.data.status === "success") {
          setIsLoading(false);
          if (type === staff_label) {
            toast.success(`${staff_label} added successfully!`, {
              style: {},
              progressStyle: { backgroundColor: dynamicColor },
              icon: (
                <span
                  style={{
                    marginRight: "8px",
                    borderRadius: "50%",
                    width: "7rem",
                    height: "35px",
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: dynamicColor,
                    backgroundColor: "white",
                    fontSize: "1.5rem",
                    border: `3px solid ${dynamicColor}`,
                  }}
                >
                  ✓
                </span>
              ),
            });
          }
          setcommonBody({
            first_name: "",
            last_name: "",
            email: "",
            phone: "",
            dob: "",
            funding_type: "",
            type: type === staff_label ? 2 : 1,
          });
        }
        if (type === staff_label) {
          // dispatch(getStaffThunk({ dynamicStaffId }));
          if (pathname.includes("providers") && providerId) {
            dispatch(resetStaffProviderData());
            dispatch(
              getProviderStaffsByFilterThunk({
                filterColumn: "",
                filterValue: "",
                searchVal: "",
                sortColumn: "",
                sortType: "",
                staffPage,
                status: "",
                dynamicProviderId: providerId,
                dynamicUserTypeId: dynamicStaffId,
              })
            );
          } else {
            dispatch(resetStaffData());
            dispatch(
              getStaffByFilterThunk({
                filterColumn: "",
                filterValue: "",
                searchVal: "",
                sortColumn: "",
                sortType: "",
                staffPage,
                dynamicId: dynamicStaffId,
                status: "1,2,7",
              })
            );
          }
        }
        const stickyNoteElement = document.querySelector(
          ".new_sticky_note_wrapper"
        );
        if (stickyNoteElement) {
          stickyNoteElement.classList.add("fade_out");
          setTimeout(() => {
            dispatch(setShowStickyNote(false));
          }, 300);
        }
      } catch (error: any) {
        console.error("Error:", error);
        toast.error(error.message);
      }
    } else {
      if (type === staff_label) {
        setIsLoading(false);
        toast.error("All Fields Required!");
      }
    }
    if (
      (type === prospects_label || type === clients_label) &&
      commonBody.first_name !== "" &&
      commonBody.email !== "" &&
      type !== contacts_label &&
      type !== staff_label
    ) {
      if (!b2bCondition && commonBody.last_name === "") {
        toast.error("All Fields Required!");
        setIsLoading(false);
        return;
      }
      api
        .post(url, { ...modifiedCommonBody, tenant_id: providerId })
        .then((response: any) => {
          if (response.data.status === "success") {
            if (type === clients_label) {
              toast.success(
                `${lastCharecterEmitter(clients_label)} added successfully!`,
                {
                  style: {},
                  progressStyle: { backgroundColor: dynamicColor },
                  icon: (
                    <span
                      style={{
                        marginRight: "8px",
                        borderRadius: "50%",
                        width: "7rem",
                        height: "35px",
                        textAlign: "center",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: dynamicColor,
                        backgroundColor: "white",
                        fontSize: "1.5rem",
                        border: `3px solid ${dynamicColor}`,
                      }}
                    >
                      ✓
                    </span>
                  ),
                }
              );
            } else if (type === prospects_label) {
              toast.success(
                `${lastCharecterEmitter(prospects_label)} added successfully!`,
                {
                  style: {},
                  progressStyle: { backgroundColor: dynamicColor },
                  icon: (
                    <span
                      style={{
                        marginRight: "8px",
                        borderRadius: "50%",
                        width: "7rem",
                        height: "35px",
                        textAlign: "center",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: dynamicColor,
                        backgroundColor: "white",
                        fontSize: "1.5rem",
                        border: `3px solid ${dynamicColor}`,
                      }}
                    >
                      ✓
                    </span>
                  ),
                }
              );
            }
            setcommonBody({
              first_name: "",
              last_name: "",
              email: "",
              phone: "",
              dob: "",
              funding_type: "",
              type: type === staff_label ? 2 : 1,
            });
          }
          if (type === clients_label) {
            if (pathname.includes("providers") && providerId) {
              dispatch(resetClientProviderData());
              dispatch(
                getProviderClientFilterThunk({
                  filterColumn: "",
                  filterValue: "",
                  searchVal: "",
                  sortColumn: "",
                  sortType: "",
                  ClientPage,
                  dynamicProviderId: providerId,
                  dynamicUserTypeId: dynamicClientId,
                  status: "",
                })
              );
            } else {
              dispatch(resetClientData());
              dispatch(
                getClientsByFilterThunk({
                  filterColumn: "",
                  filterValue: "",
                  searchVal: "",
                  sortColumn: "",
                  sortType: "",
                  ClientPage,
                  dynamicId: dynamicClientId,
                  status: "1,2,3,4,5",
                })
              );
            }
          }
          if (type === prospects_label) {
            dispatch(resetProspectData());
            if (pathname.includes("providers") && providerId) {
              dispatch(resetProspectProviderData());
              dispatch(
                getProviderProspectFilterThunk({
                  filterColumn: "",
                  filterValue: "",
                  searchVal: "",
                  sortColumn: "",
                  sortType: "",
                  ProspectPageFilter,
                  status: "",
                  dynamicProviderId: providerId,
                  dynamicUserTypeId: dynamicProspectsId,
                })
              );
            } else {
              dispatch(
                getProspectsByFilterThunk({
                  filterColumn: "",
                  filterValue: "",
                  searchVal: "",
                  sortColumn: "",
                  sortType: "",
                  ProspectPageFilter,
                  dynamicId: dynamicProspectsId,
                  status: "",
                })
              );
            }
          }

          const stickyNoteElement = document.querySelector(
            ".new_sticky_note_wrapper"
          );
          if (stickyNoteElement) {
            stickyNoteElement.classList.add("fade_out");
            setTimeout(() => {
              dispatch(setShowStickyNote(false));
            }, 300);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          toast.error(error.message);
          setIsLoading(false);
        });
    } else {
      if (type === clients_label || type === prospects_label) {
        setIsLoading(false);
        toast.error("All Fields Required!");
      }
    }
    if (
      type === contacts_label &&
      commonBody.first_name !== "" &&
      type !== clients_label &&
      type !== prospects_label &&
      type !== staff_label
    ) {
      if (!b2bCondition && commonBody.last_name === "") {
        toast.error("All Fields Required!");
        setIsLoading(false);
        return;
      }
      try {
        const response = await api.post(url, {
          ...modifiedCommonBody,
          tenant_id: providerId,
        });
        if (response.data.status === "success") {
          setIsLoading(false);
          if (type === contacts_label) {
            toast.success(
              `${lastCharecterEmitter(contacts_label)} added successfully!`,
              {
                style: {},
                progressStyle: { backgroundColor: dynamicColor },
                icon: (
                  <span
                    style={{
                      marginRight: "8px",
                      borderRadius: "50%",
                      width: "7rem",
                      height: "35px",
                      textAlign: "center",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: dynamicColor,
                      backgroundColor: "white",
                      fontSize: "1.5rem",
                      border: `3px solid ${dynamicColor}`,
                    }}
                  >
                    ✓
                  </span>
                ),
              }
            );
          }
          setcommonBody({
            first_name: "",
            last_name: "",
            email: "",
            phone: "",
            dob: "",
            funding_type: "",
            type: type === contacts_label ? 2 : 1,
          });
        }
        if (type === contacts_label) {
          // dispatch(getStaffThunk({ dynamicStaffId }));
          if (pathname.includes("providers") && providerId) {
            dispatch(resetContactProviderData());
            dispatch(
              getProviderContactsByFilterThunk({
                filterColumn: "",
                filterValue: "",
                searchVal: "",
                sortColumn: "",
                sortType: "",
                ContactPage,
                status: "",
                dynamicProviderId: providerId,
                dynamicUserTypeId: dynamicContactId,
              })
            );
          } else {
            dispatch(resetContactPage());
            dispatch(
              getContactsByFilterThunk({
                filterColumn: "",
                filterValue: "",
                searchVal: "",
                sortColumn: "",
                sortType: "",
                ContactPage,
                dynamicId: dynamicStaffId,
                status: "",
              })
            );
          }
        }

        const stickyNoteElement = document.querySelector(
          ".new_sticky_note_wrapper"
        );
        if (stickyNoteElement) {
          stickyNoteElement.classList.add("fade_out");
          setTimeout(() => {
            dispatch(setShowStickyNote(false));
          }, 300);
        }
      } catch (error: any) {
        console.error("Error:", error);
        toast.error(error.message);
      }
    } else {
      if (type === contacts_label) {
        setIsLoading(false);
        toast.error("All Fields Required!");
      }
    }
  };

  // Debounced function (wait 300 milliseconds after the last click)
  const debouncedApiCall = _.debounce(handleAddForm, 300);

  // Attach the debounced function to your button click event
  useEffect(() => {
    const handleClick = () => {
      debouncedApiCall();
    };

    document?.getElementById("saveBTN")?.addEventListener("click", handleClick);

    return () => {
      document
        ?.getElementById("saveBTN")
        ?.removeEventListener("click", handleClick);
    };
  }, [debouncedApiCall]);
  const { profile_name } = useAppSelector((state) => state.common.domainDetail);
  return (
    <>
      {activeTabProfile === profile_name && (
        <>
          <div className="payload_area">
            <div className="main_area">
              <div className="side_bar"></div>
              <div className="side_bar" />

              <div className="middle-block">
                <p className="note-title">
                  New {currentTitle === "Employees" ? "Employee" : currentTitle}
                </p>
                <div className="middle-section-content middle-section-others">
                  <div className="field">
                    <span className="field-title">
                      {profile_name} Type{" "}
                      <span className="asterixs_color"> * </span>{" "}
                    </span>
                    <select
                      className="field-type custom-select-2"
                      style={{
                        backgroundColor: "white",
                      }}
                      onChange={(e) =>
                        dispatch(setProfileTypeSticky(e?.target?.value))
                      }
                      value={getProfileTypeSticky}
                    >
                      {[
                        prospects_label,
                        clients_label,
                        business_label,
                        locations_label,
                        teams_label,
                        contacts_label,
                        staff_label,
                      ]?.map((elem: string) => {
                        return (
                          <option key={elem} value={elem}>
                            {elem}
                          </option>
                        );
                      })}
                    </select>
                    <span className="field-border field-border-add-form"></span>
                  </div>
                  <div className="field">
                    <span className="field-title">
                      {`${(!b2bCondition && "First") || ""} Name`}
                      <span className="asterixs_color"> * </span>
                    </span>
                    <input
                      style={{
                        backgroundColor: "white",
                      }}
                      className="input-fields-of-sticky-note"
                      placeholder={`Enter ${
                        (!b2bCondition && "First ") || ""
                      }Name`}
                      onChange={(e) =>
                        setcommonBody((prevState) => ({
                          ...prevState,
                          first_name: e.target.value,
                        }))
                      }
                      value={commonBody.first_name}
                    />

                    <span className="field-border field-border-add-form"></span>
                  </div>
                  {!b2bCondition && (
                    <div className="field">
                      <span className="field-title">
                        Last Name <span className="asterixs_color"> * </span>
                      </span>

                      <input
                        style={{
                          backgroundColor: "white",
                        }}
                        className="input-fields-of-sticky-note"
                        placeholder="Enter Last Name"
                        onChange={(e) =>
                          setcommonBody((prevState) => ({
                            ...prevState,
                            last_name: e.target.value,
                          }))
                        }
                        value={commonBody.last_name}
                      />

                      <span className="field-border field-border-add-form"></span>
                    </div>
                  )}

                  <div className="field">
                    <span className="field-title">
                      Email
                      {type !== contacts_label && (
                        <span className="asterixs_color"> * </span>
                      )}
                    </span>
                    <input
                      style={{
                        backgroundColor: "white",
                      }}
                      className="input-fields-of-sticky-note"
                      placeholder="Enter Email"
                      onChange={(e) =>
                        setcommonBody((prevState) => ({
                          ...prevState,
                          email: e.target.value,
                        }))
                      }
                      value={commonBody.email}
                    />

                    <span className="field-border field-border-add-form"></span>
                  </div>
                  <div className="field">
                    <span className="field-title">Phone</span>
                    <input
                      style={{
                        backgroundColor: "white",
                      }}
                      className="input-fields-of-sticky-note"
                      placeholder="Enter Phone"
                      onChange={(e) =>
                        setcommonBody((prevState) => ({
                          ...prevState,
                          phone: e.target.value,
                        }))
                      }
                      value={commonBody.phone}
                    />

                    <span className="field-border field-border-add-form"></span>
                  </div>
                  <div className="field">
                    <span className="field-title">
                      {type[0]?.toUpperCase() + type.slice(1) + " "}
                      Type
                      {/* {currType} */}
                    </span>
                    <select
                      className="field-type custom-select-2"
                      style={{
                        backgroundColor: "white",
                      }}
                      onChange={(e) => handleSelectType(e)}
                      value={selectedType}
                    >
                      <option value="">
                        Select{" "}
                        {/* {currentTitle === "Employees" ? "Employee" : currentTitle} */}
                        {type[0]?.toUpperCase() + type.slice(1) + " "}
                        Type...
                      </option>

                      {currType &&
                        currType?.map((elem: any, index: number) => {
                          return (
                            <option key={index} value={elem?.id}>
                              {elem?.desc}
                            </option>
                          );
                        })}
                    </select>
                    <span className="field-border field-border-add-form"></span>
                  </div>

                  <div className="field">
                    <span className="field-title">
                      Status <span className="asterixs_color"> * </span>
                    </span>
                    <select
                      id="select_url"
                      value={setcommonBody?.status}
                      onChange={(e) => {
                        setcommonBody((prevState: any) => ({
                          ...prevState,
                          status: e.target.value,
                        }));
                      }}
                    >
                      <option value="">Please Select...</option>
                      {StatusDetailsArr?.map((elem: any) => {
                        return (
                          <>
                            <option value={elem?.id}>{elem?.text}</option>
                          </>
                        );
                      })}
                    </select>

                    <span className="field-border field-border-add-form"></span>
                  </div>

                  {type === staff_label && (
                    <div className="switch_sticky">
                      <label>Invite</label>
                      <Switch
                        colorScheme="teal"
                        size="md"
                        // position={"absolute"}
                        // right={"-20px"}
                        isChecked={deliveryValue == 0 ? false : true}
                        zIndex={9}
                        onChange={(e) => {
                          setDeliveryValue(e.target.checked ? 1 : 0);
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="bottomBlock other_form_bottom_block">
            <button
              className="save-btn"
              id="saveBTN"
              // onClick={() => {
              //   handleAddForm();
              //   setDisibleSaveBTN(true);
              // }}
            >
              {IsLoading ? "Loading..." : "Save"}
            </button>
          </div>
        </>
      )}
      {activeTabProfile === "Comms" && comp}
    </>
  );
};
export default OtherForm;
