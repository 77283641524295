import React from "react";

const BoldSVG = () => {
  return (
    <svg
      width="8"
      height="12"
      viewBox="0 0 8 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.08506 5.43853C6.4315 5.20007 6.72387 4.87341 6.93885 4.48462C7.15383 4.09582 7.28548 3.65563 7.3233 3.19913C7.32986 2.78635 7.26441 2.37615 7.1307 1.99197C6.99698 1.60778 6.79762 1.25713 6.54399 0.960051C6.29037 0.66297 5.98745 0.425278 5.65254 0.26055C5.31763 0.0958226 4.95728 0.00728688 4.5921 0H0V11.197H4.95295C5.30048 11.1928 5.64387 11.1113 5.96352 10.9571C6.28317 10.8029 6.57281 10.579 6.81592 10.2983C7.05903 10.0176 7.25083 9.68545 7.38038 9.32093C7.50993 8.9564 7.57468 8.5666 7.57094 8.17379V8.07781C7.57119 7.52365 7.4315 6.98092 7.16829 6.5134C6.90508 6.04588 6.52928 5.67298 6.08506 5.43853ZM1.41513 1.59957H4.3869C4.68845 1.58902 4.98563 1.68297 5.23858 1.86882C5.49153 2.05466 5.68814 2.32351 5.80203 2.63929C5.91728 3.0614 5.88 3.51792 5.69836 3.90906C5.51672 4.30021 5.20548 4.59418 4.83267 4.72672C4.68789 4.77468 4.53777 4.79892 4.3869 4.7987H1.41513V1.59957ZM4.66993 9.5974H1.41513V6.39827H4.66993C4.97147 6.38772 5.26866 6.48167 5.52161 6.66752C5.77456 6.85336 5.97117 7.12221 6.08506 7.43799C6.2003 7.8601 6.16303 8.31662 5.98139 8.70776C5.79975 9.09891 5.48851 9.39288 5.11569 9.52542C4.97091 9.57338 4.8208 9.59762 4.66993 9.5974Z"
        fill="black"
      />
    </svg>
  );
};

export default BoldSVG;
