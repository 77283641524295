import React, { Fragment, useEffect, useState } from "react";
import "./Styles/AuditModal.css";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../Controller/redux/Hooks";
import { DateFormat } from "../../../Model/utils/Helper/formatDate";

import CommonChart from "../../components/CommonCharts/CommonChart";
import { ClinicalListItem } from "../People/ClientPage/Components/UniqueClientScreen/Components/ClinicalTab/Components/ClinicalListItem";
import {
  convertDateTimeFormat,
  formatDateAndTime,
} from "../../../Model/utils/Helper/formatTime";

import { CrossMdSvg } from "../../../Model/Assets/SVG/Common/CrossSvg";

import ClinicalNote from "../../components/ClinicalProgress/Components/ClinicalNote";

const AuditModal = ({
  type,
  selectedWidgdet,
  data,
  data_2,
  graph = "line",
  setIsAuditModal,
  className,
  onClickCross = () => {},
}: any) => {
  const dispatch = useAppDispatch();
  const [expandedNote, setIsExpandedNote] = useState<number>(-1);
  const { AuditRowId, getAuditData } = useAppSelector((state) => state?.audit);
  const AuditData = getAuditData?.data?.data?.find(
    (item: any) => item.id === +AuditRowId
  );
  const [inputData, setInputData] = useState("");
  const handleNoteClick = (index: number) => {
    setIsExpandedNote(index === expandedNote ? -1 : index);
  };
  const initialValue = {
    noteType: "New",
    Time: `Today, ${formatDateAndTime(new Date()).time}`,
    Date: convertDateTimeFormat(new Date()),
    note: "",
    note_1: "",
    note_2: "",
  };
  const [notes, setNotes] = useState([]);

  const handleNewNoteClick = () => {
    const newNote = initialValue;
    // @ts-ignore
    setNotes([...notes, newNote]);
    setNotes((prevNotes: any) => {
      const lastNoteIndex = prevNotes.length - 1;
      return prevNotes?.map((note: any, index: number) =>
        index === lastNoteIndex ? { ...note, note: inputData } : note
      );
    });
    setInputData("");
  };
  const handleInputField = (data: string) => {
    setInputData(data);
  };

  const { selectedClinicalNoteInfo } = useAppSelector(
    (state) => state.clinical
  );

  useEffect(() => {
    if (selectedClinicalNoteInfo?.id) {
      setInputData(selectedClinicalNoteInfo?.note);
    }
  }, [selectedClinicalNoteInfo]);

  const { ClinicalNotesData } = useAppSelector((state) => state.clinical);

  const [ClinicalNotesRecords, setClinicalNotesRecords] = useState([]);

  useEffect(() => {
    if (ClinicalNotesData) {
      const uniqueClientData = ClinicalNotesData?.filter(
        (item: any, index: number, array: any) => {
          return array?.findIndex((obj: any) => obj.id === item.id) === index;
        }
      );
      setClinicalNotesRecords(uniqueClientData);
    }
  }, [ClinicalNotesData]);

  return (
    <Fragment>
      <div className={` modal_container_audit`}>
        <div
          className={`${className} modal_audit_container ${
            selectedWidgdet ? "clinical__modal__container" : ""
          } `}
        >
          {type === "clinical" ? (
            <>
              {/* CLINICAL MODAL */}
              <div className="clinical__modal__item__wrapper">
                <h1>{selectedWidgdet?.title}</h1>
                <div className="clinical_modal_graph">
                  <CommonChart
                    values={data}
                    data_2={data_2}
                    pointBackgroundColor={"rgb(6, 165, 255)"}
                    borderWidth={2}
                    pointBorderColor={"rgba(0, 0, 0, 0)"}
                    displayX={true}
                    displayY={true}
                    pointRadius={5}
                    chartLabel={selectedWidgdet.title}
                    chartType={graph}
                    labels={selectedWidgdet.dates_X}
                    chartLineColor={"#000"}
                    chartWidth={"70%"}
                    chartSize={"large"}
                    dataColors={["red", "green", "blue"]}
                  />
                </div>
                {/* <div className="bottom__items__widget">
                  <h1>On Track</h1>
                  <span
                    className="right__box__lower__box__vector"
                    style={{
                      background: selectedWidgdet.color,
                    }}
                  ></span>
                </div> */}
              </div>
            </>
          ) : type === "clinicalNote" ? (
            <>
              <CrossMdSvg className="cross_button" onClick={onClickCross} />
              
              {ClinicalNotesRecords?.map((elem: any, index: number) => {
                if (selectedClinicalNoteInfo?.id !== elem?.id) {
                  return (
                    <ClinicalNote
                      editiable={false}
                      clinicalGoalsNote={elem?.note}
                      dateTime={elem?.note_date_time}
                      noteType={elem?.note_type}
                      userName={`${elem?.staff_user?.first_name} ${elem?.staff_user?.last_name}`}
                      color={elem?.goal_id !== null ? "#FFE8F8" : "#faf9ac"}
                      // setshowGoalsModal={setshowGoalsModal}
                      NoteInfo={elem}
                      // dropdown={true}
                    />
                  );
                }
              })}
              {notes?.map((elem: any, index: number) => {
                return (
                  <>
                    {/* <ClinicalListItem
                      key={index}
                      noteType={elem.noteType}
                      noteModal={true}
                      Time={`Today, ${formatDateAndTime(new Date()).time}`}
                      Date={convertDateTimeFormat(new Date())}
                      className={"add_note_modal_container"}
                      color="#faf9ac"
                      note={elem.note}
                      note_1={elem?.note_1}
                      note_2={elem?.note_2}
                    /> */}

                    <ClinicalNote
                      editiable={true}
                      clinicalGoalsNote={elem?.note}
                      dateTime={convertDateTimeFormat(new Date())}
                      noteType={elem?.note_type}
                      color={"#faf9ac"}
                      // setshowGoalsModal={setshowGoalsModal}
                      NoteInfo={elem}
                      // dropdown={true}
                    />
                  </>
                );
              })}
              <ClinicalListItem
                noteType={"Default"}
                noteModal={true}
                Time={`Today, ${formatDateAndTime(new Date()).time}`}
                Date={convertDateTimeFormat(new Date())}
                className={"add_note_modal_container"}
                color="#e9f5db"
                note={""}
                note_1={""}
                note_2={""}
                onClickPlus={handleNewNoteClick}
                expandedNoteItem={true}
                handleInputField={(data: string) => {
                  handleInputField(data);
                }}
                textAreaData={inputData}
              />
            </>
          ) : (
            <div>
              {/* <span
                className="cross_button"
                onClick={() => setIsAuditModal(false)}
              >
                x
              </span> */}
              <CrossMdSvg
                className="cross_button"
                onClick={() => setIsAuditModal(false)}
              />
              <h1 className="modal_audit_heading">Audit Detail</h1>
              <div className="modal_audit_inner_container">
                {/* <div className="modal_audit_flex"> */}
                <div className="modal_audit_inner_flex">
                  <div className="modal_audit_tags">Created At : </div>
                  {/* <span>{DateFormat(AuditData?.created_at)}</span> */}
                  <span>{DateFormat(AuditData?.created_at, "dd-mm-yyyy")}</span>
                </div>
                <div className="modal_audit_inner_flex">
                  <div className="modal_audit_tags">Username : </div>
                  <span>{AuditData?.username}</span>
                </div>
                <div className="modal_audit_inner_flex">
                  <div className="modal_audit_tags">Activity Type : </div>
                  <span>{AuditData?.activity_type}</span>
                </div>
                {/* </div> */}
                {/* <div className="modal_audit_flex"> */}
                <div className="modal_audit_inner_flex">
                  <div className="modal_audit_tags">Table Name : </div>
                  <span>{AuditData?.table_name}</span>
                </div>
                <div className="modal_audit_inner_flex">
                  <div className="modal_audit_tags">Ip Address : </div>
                  <span>{AuditData?.ip_address}</span>
                </div>
                {/* </div> */}
                <div className="modal_audit_desc">
                  <div className="modal_audit_tags">Description : </div>
                  <p>{AuditData?.desc}</p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default AuditModal;
