import React, { useEffect, useState } from "react";
import Category from "./Category";
import "./styles/index.css";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../Controller/redux/Hooks";
import {
  addReportSoftwareAccess,
  getReportSoftwareAccess,
} from "../../../Controller/redux/features/ReportsHubFeature/ReportsHubSlice";
import { useParams } from "react-router-dom";
import CircularNoteTrigger from "../CircularNoteTrigger/CircularNoteTrigger";
import { toast } from "react-toastify";

const ReportsHubAccess = () => {
  const dispatch = useAppDispatch();
  const { id }: any = useParams();
  const dynamicReportsHubId = localStorage.getItem("dynamicReportsHubId");
  const userString: any = localStorage.getItem("user");
  const user: any = JSON.parse(userString);

  const { allowedReportsHubIds } = useAppSelector((state) => state.reportsHub);
  const dynamicColor = useAppSelector((state) => state?.common?.dynamicColor);
  const { softwareAccessList } = useAppSelector((state) => state?.reportsHub);

  useEffect(() => {
    dispatch(
      getReportSoftwareAccess({
        type: dynamicReportsHubId,
        id: window.atob(id),
        parentId: user?.data?.tenant_id,
      })
    );
  }, []);

  const handleAddSoftwareReportAccess = () => {
    dispatch(
      addReportSoftwareAccess({
        id: window.atob(id),
        reports: allowedReportsHubIds,
      })
    ).then((res) => {
      if (res?.error) {
        toast.error(res?.payload);
        return;
      }
      toast.success("Updated Report Access Successfully!", {
        progressStyle: { backgroundColor: dynamicColor },
        icon: (
          <span
            style={{
              marginRight: "8px",
              borderRadius: "50%",
              width: "7rem",
              height: "35px",
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: dynamicColor,
              backgroundColor: "white",
              fontSize: "1.5rem",
              border: `3px solid ${dynamicColor}`,
            }}
          >
            ✓
          </span>
        ),
      });
      dispatch(
        getReportSoftwareAccess({
          type: dynamicReportsHubId,
          id: window.atob(id),
          parentId: user?.data?.tenant_id,
        })
      );
    });
  };

  return (
    <div>
      {softwareAccessList?.map((category: any) => (
        <Category key={category?.id} {...category} />
      ))}
      <button
        className="save_button_reports_hub"
        onClick={handleAddSoftwareReportAccess}
      >
        Save
      </button>
      <CircularNoteTrigger />
    </div>
  );
};

export default ReportsHubAccess;
