import React, { Fragment, useEffect, useRef, useState } from "react";
import "../../Styles/DaybookSticky.css";
import { useLocation, useParams } from "react-router-dom";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../Controller/redux/Hooks";
import {
  convertFormatDate,
  formatCreatedDate,
  formatDate,
  formatDateForAPI,
  formatDateFromAPIforCustom,
  getCurrentDates,
} from "../../../../../Model/utils/Helper/formatDate";
import { EditorState, convertToRaw } from "draft-js";
import { toast } from "react-toastify";
import {
  createDaybookItem,
  getDaybookItems,
  updateDaybookItem,
} from "../../../../../Controller/redux/features/DaybookFeature/daybookSlice";
import { createDocumentCommsThunk } from "../../../../../Controller/redux/features/CommonFeature/documentSlice";
import ReactDatePicker from "react-datepicker";
import BoldSVG from "../../../../pages/People/ClientPage/Components/UniqueClientScreen/Components/FinanceTab/Data/BoldSVG";
import ItalicIconSVG from "../../../../pages/People/ClientPage/Components/UniqueClientScreen/Components/FinanceTab/Data/ItalicIconSVG";
import UnderscorSVG from "../../../../pages/People/ClientPage/Components/UniqueClientScreen/Components/FinanceTab/Data/UnderscorSVG";
import PlusSVG from "../../../../pages/People/ClientPage/Components/UniqueClientScreen/Components/FinanceTab/Data/PlusSVG";
import TagsInput from "../../../Tags/TagBuilder";
import TrafficLights from "../../../CommonModal/Components/TrafficLights";
import { Editor } from "react-draft-wysiwyg";
import {
  AssignedToSelect,
  DateStartEndTime,
  TagBookSelect,
} from "../../../../pages/Daybook/Daybook";
import { PDFSvg } from "../../../../pages/People/ClientPage/Components/UniqueClientScreen/Components/DocumentsTab/Data/SVGs";
import ClientStatusCircleSvg from "../../../../../Model/Assets/SVG/WidgetVillage/ClientStatusCircleSvg";
import CustomDatePicker from "../../../DatePicker/CustomDatePicker";
import {getUserNotificationStats} from "../../../../../Controller/redux/features/NotificationsFeature/NotificaionsSlice";

const monthNames = [
  "JAN",
  "FEB",
  "MAR",
  "APR",
  "MAY",
  "JUN",
  "JUL",
  "AUG",
  "SEP",
  "OCT",
  "NOV",
  "DEC",
];
const yesterday = new Date();
const today = new Date();
const tomorrow = new Date();
const dayAfterTomorrow = new Date();

yesterday.setDate(dayAfterTomorrow.getDate() - 1);
tomorrow.setDate(dayAfterTomorrow.getDate() + 1);
dayAfterTomorrow.setDate(dayAfterTomorrow.getDate() + 2);

const prefixDate = (date: number) => {
  if (date < 10) return `0${date}`;
  else return date;
};

const selectDateCategories = [
  {
    id: 1,
    title: "Yesterday",
    value: `${yesterday.getFullYear()}-${prefixDate(
      yesterday.getMonth() + 1
    )}-${yesterday.getDate()}`,
  },
  {
    id: 2,
    title: "Today",
    value: `${today.getFullYear()}-${prefixDate(
      today.getMonth() + 1
    )}-${today.getDate()}`,
  },
  {
    id: 3,
    title: "Tomorrow",
    value: `${tomorrow.getFullYear()}-${prefixDate(
      tomorrow.getMonth() + 1
    )}-${tomorrow.getDate()}`,
  },
];

const DaybookSticky = ({ customDate, onChangeTab }: any) => {
  const [dayBookList, setDayBookList] = useState<any>([]);
  const dispatch = useAppDispatch();
  const [userId, setUserId] = useState("");
  const { dynamicColor } = useAppSelector((state: any) => state?.common);
  const [isModify, setIsModify] = useState(false);
  const [selectedDayBookItem, setSelectedDayBookItem] = useState<any>(null);
  const [trafficColors, setTrafficColors] = useState<any>("");
  const [refreshAttempt, setRefreshAttempt] = useState(0);
  const [showLights, setShowLights] = useState<boolean>(false);
  // Getting Yesterday , today , Tomorrow , Day After Tomorrow
  const currentDate = getCurrentDates();
  const [additionalState, setAdditionalState] = useState<any>({
    showTags: true,
    showTagsArr: Array(dayBookList?.length).fill(false),
    selectedTags: [],
  });
  const pathname = useLocation().pathname;
  const [selectedSortDateCustom, setSelectedSortDateCustom] =
    useState<any>(null);
  const [chooseDate, setChooseDate] = useState("");
  const allTags = useAppSelector((state: any) => state?.common?.allTags);
  const [selectedTags, setSelectedTags] = useState([]);
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [createCommsData, setCreateCommsData] = useState<any>({
    link_id: "",
    summary: "",
    message: selectedDayBookItem,
    comm_type: "DAYBOOK",
  });
  const [selectedSortTab, setSelectedSortTab] = useState<any>(
    selectDateCategories[1]
  );

  useEffect(() => {
    let loggedInUserId = localStorage.getItem("loggedInUserId");
    if (loggedInUserId) {
      setUserId(loggedInUserId);
    }
  }, []);

  useEffect(() => {
    if (customDate) {
      setSelectedSortTab(null);
    }
  }, [customDate]);

  useEffect(() => {
    setSelectedSortDateCustom(null);
  }, []);

  const isClientsOrStaffScreen =
    pathname.includes("clients") ||
    pathname.includes("staff") ||
    pathname.includes("prospects") ||
    pathname.includes("business") ||
    pathname.includes("teams") ||
    pathname.includes("contacts") ||
    pathname.includes("locations");

  useEffect(() => {
    setCreateCommsData({
      link_id: selectedDayBookItem?.task?.id,
      summary: "",
      message: selectedDayBookItem?.task?.communications,
      comm_type: "DAYBOOK",
    });
  }, [
    selectedDayBookItem?.task?.id,
    selectedDayBookItem?.task?.communications,
  ]);

  const [paramsId, setparamsId] = useState("");

  // @ts-ignore
  const idFromParams: any = useParams()?.id;

  useEffect(() => {
    if (idFromParams) {
      let realId = window.atob(idFromParams);
      setparamsId(realId);
    }
  }, [idFromParams]);

  const [clientId, setclientId] = useState("");
  useEffect(() => {
    if (
      pathname.includes("clients") ||
      pathname.includes("staff") ||
      pathname.includes("prospects") ||
      pathname.includes("business") ||
      pathname.includes("teams") ||
      pathname.includes("contacts") ||
      pathname.includes("locations")
    ) {
      setclientId(paramsId);
    }
  }, [pathname, idFromParams, paramsId]);

  useEffect(() => {
    const currentDate = new Date();
    if (userId || clientId) {
      dispatch(
        // @ts-ignore
        getDaybookItems({
          userId:
            pathname.includes("clients") ||
            pathname.includes("staff") ||
            pathname.includes("prospects") ||
            pathname.includes("business") ||
            pathname.includes("teams") ||
            pathname.includes("contacts") ||
            pathname.includes("locations")
              ? clientId
              : userId,
          date:
            // customDate
            //   ? formatDateForAPI(customDate)
            //   :
            selectedSortTab
              ? selectedSortTab.id !== 5
                ? selectedSortTab?.value
                : // @ts-ignore
                  `${date.getFullYear()}-${prefixDate(
                    // @ts-ignore
                    date.getMonth() + 1
                    // @ts-ignore
                  )}-${date.getDate()}`
              : formatDateForAPI(customDate),
        })
      );
    }
  }, [
    selectedSortTab,
    refreshAttempt,
    userId,
    selectedSortDateCustom,
    clientId,
    customDate,
  ]);

  const { dayBookList: dayBookListRedux } = useAppSelector(
    (state: any) => state?.daybook
  );

  useEffect(() => {
    if (dayBookListRedux) {
      const parsedDayBookList =
        Object.keys(dayBookListRedux).length === 0
          ? [{ time: "All Day", task: [] }]
          : Object.keys(dayBookListRedux).map((key) => {
              return {
                time: key === "all" ? "All Day" : key,
                task: dayBookListRedux[key],
              };
            });
      setDayBookList(parsedDayBookList);
    }
  }, [dayBookListRedux]);

  useEffect(() => {
    setChooseDate(
      selectedSortDateCustom ? convertFormatDate(selectedSortDateCustom) : ""
    );
  }, [selectedSortDateCustom]);

  const handleSelectedTagIdsChange = (newSelectedTagIds: any) => {
    const newSelectedTags = newSelectedTagIds;
    setSelectedTags(newSelectedTags);
    if (selectedDayBookItem?.task?.id) {
      setIsModify(true);
    }
  };

  const selectedDayBookItemRef = useRef();

  const { clientDataForDayBook } = useAppSelector(
    (state: any) => state?.people
  );
  const { staffDataDayBook } = useAppSelector((state: any) => state?.staff);
  const { getProspectsData } = useAppSelector((state: any) => state?.prospects);
  const { getContactsData } = useAppSelector((state: any) => state?.contacts);
  const { allDataBusiness } = useAppSelector((state: any) => state?.business);
  const { allDataTeams } = useAppSelector((state: any) => state?.teams);

  const [contactsData, setcontactsData] = useState([]);
  const [prospectsData, setprospectsData] = useState([]);
  const [businessData, setBusinessData] = useState([]);
  const [teamsData, setTeamsData] = useState([]);

  // // console.log(prospectsData,"prospectsData")
  useEffect(() => {
    if (getProspectsData) {
      setprospectsData(getProspectsData?.data?.data);
    }
  }, [getProspectsData]);
  // // console.log(prospectsData,"prospectsData")
  useEffect(() => {
    if (getContactsData) {
      setcontactsData(getContactsData?.data?.data);
    }
  }, [getContactsData]);

  useEffect(() => {
    if (allDataBusiness?.data?.data) {
      setBusinessData(allDataBusiness?.data?.data);
    }
  }, [allDataBusiness]);

  useEffect(() => {
    if (allDataTeams?.data?.data) {
      setTeamsData(allDataTeams?.data?.data);
    }
  }, [allDataTeams]);

  useEffect(() => {
    if (
      staffDataDayBook &&
      prospectsData &&
      contactsData &&
      clientDataForDayBook &&
      businessData &&
      teamsData
    ) {
      setClientsDataForAssignedTo([
        ...clientDataForDayBook,
        ...staffDataDayBook,
        ...prospectsData,
        ...contactsData,
        ...businessData,
        ...teamsData,
      ]);
    }
  }, [
    staffDataDayBook,
    prospectsData,
    contactsData,
    clientDataForDayBook,
    businessData,
    teamsData,
  ]);

  useEffect(() => {
    if (
      clientDataForDayBook &&
      staffDataDayBook &&
      contactsData &&
      prospectsData &&
      businessData &&
      teamsData
    ) {
      setClientsDataForTaggedUser([
        ...clientDataForDayBook,
        ...staffDataDayBook,
        ...prospectsData,
        ...contactsData,
        ...businessData,
        ...teamsData,
      ]);
    }
  }, [
    clientDataForDayBook,
    staffDataDayBook,
    prospectsData,
    contactsData,
    businessData,
    teamsData,
  ]);

  const [clientsDataForAssignedTo, setClientsDataForAssignedTo] = useState<any>(
    []
  );

  const [clientsDataForTaggedUser, setClientsDataForTaggedUser] = useState<any>(
    []
  );

  const [editorState, setEditorState] = useState(EditorState?.createEmpty());
  const [isThereMessage, setisThereMessage] = useState<any>(null);
  let isThereTextInMessage = isThereMessage?.blocks[0]?.text;
  const [trafficLightColors, setTrafficLightColors] = useState<any>({
    0: "#ef3a34",
    1: "#fbbf14",
    2: "#2ad400",
  });

  const handleTaskItemChange = (
    type:
      | "task-title"
      | "task-summary"
      | "task-comms"
      | "task-tagged-people"
      | "task-date"
      | "task-start-time"
      | "task-end-time"
      | "task-assigned-to"
      | "task-editor",

    dayBookIndex: any,
    dayBookTaskIndex: any,
    value: any
  ) => {
    let tempDayBookList = JSON.parse(JSON.stringify(dayBookList));

    if (type === "task-title") {
      tempDayBookList[dayBookIndex].task[dayBookTaskIndex].title = value;
    } else if (type === "task-summary") {
      tempDayBookList[dayBookIndex].task[dayBookTaskIndex].summary = value;
    } else if (type === "task-comms") {
      tempDayBookList[dayBookIndex].task[dayBookTaskIndex].communications =
        value;
    } else if (type === "task-tagged-people") {
      tempDayBookList[dayBookIndex].task[dayBookTaskIndex].tagged_user = value;
    } else if (type === "task-assigned-to") {
      tempDayBookList[dayBookIndex].task[dayBookTaskIndex].assigned_to = value;
    } else if (type === "task-date") {
      const date = new Date(value);
      tempDayBookList[dayBookIndex].task[
        dayBookTaskIndex
      ].date = `${date.getFullYear()}-${prefixDate(
        date.getMonth() + 1
      )}-${prefixDate(date.getDate())}`;
    } else if (type === "task-start-time") {
      tempDayBookList[dayBookIndex].task[dayBookTaskIndex].start_time = value;
      // setStartTime(value);
    } else if (type === "task-end-time") {
      tempDayBookList[dayBookIndex].task[dayBookTaskIndex].end_time = value;
      // setEndTime(value);
    } else if (type === "task-editor") {
      //   setState((prevData: any) => ({ ...prevData, editorState: value }));
      setEditorState(value);
      const contentState = value.getCurrentContent();
      const rawContentState = convertToRaw(contentState);
      const newObject = {
        entityMap: rawContentState.entityMap,
        blocks: rawContentState.blocks.map((block) => ({
          key: block.key,
          text: block.text,
          type: block.type,
          depth: block.depth,
          inlineStyleRanges: block.inlineStyleRanges,
          entityRanges: block.entityRanges,
        })),
      };
      //   // console.log("newObject ==================?>", newObject);
      setisThereMessage(newObject);
      const contentString = JSON.stringify(newObject);
      tempDayBookList[dayBookIndex].task[dayBookTaskIndex].communications =
        contentString;
    }
    setDayBookList(tempDayBookList);
    // // console.log(
    //   "start_time================================>",
    //   tempDayBookList[dayBookIndex].task[dayBookTaskIndex]
    // );
    if (tempDayBookList[dayBookIndex]?.task[dayBookTaskIndex]?.id) {
      setIsModify(true);
    }
    setSelectedDayBookItem({
      ...selectedDayBookItem,
      time: tempDayBookList[dayBookIndex].time,
      task: tempDayBookList[dayBookIndex].task[dayBookTaskIndex],
      taskIndex: dayBookTaskIndex,
    });
  };

  useEffect(() => {
    function handleClickOutside(event: any) {
      const isOutsideClick =
        selectedDayBookItemRef.current &&
        // @ts-ignore
        !selectedDayBookItemRef.current.contains(event.target);

      if (isOutsideClick) {
        const closestClass = (className: string) =>
          event.target.closest(`.${className}`);

        const isClickableArea =
          !closestClass("public-DraftStyleDefault-block") &&
          !closestClass("description-heading-input") &&
          !closestClass("task-item-wrapper-popup") &&
          !closestClass("task-item-third-column") &&
          !closestClass("description-sub-heading-input") &&
          !closestClass("time-picker-input") &&
          !closestClass("tagbook-select-placeholder") &&
          !closestClass("description-description-tagged-people-wrapper") &&
          !closestClass("daybook-all-tags") &&
          !closestClass("react-datepicker__input-container") &&
          !closestClass("daybook__input__search__field") &&
          !closestClass("daybook__tag__item") &&
          !closestClass("picker-item") &&
          !closestClass("picker-item") &&
          !closestClass("tagbook-select-wrapper");

        let isClickableAreaDelete = !closestClass("delete_daybook_icon");
        if (!isClickableAreaDelete) {
          //   // handleDeleteDaybook();
          //   dispatch(
          //     setConfirmDeleteTask({
          //       flag: true,
          //       id: selectedDayBookItem?.task?.id,
          //     })
          //   );
          // // console.log("selectedDayBookItem");

          return;
        }

        if (isClickableArea) {
          if (selectedDayBookItem?.task?.id) {
            if (isThereTextInMessage) {
              sendComms();
            }
            updateDayBookItem();
            // setSelectedDayBookItem(null);
          } else {
            addDayBookItem();
            // setSelectedDayBookItem(null);
          }
        }
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [
    selectedDayBookItemRef,
    selectedDayBookItem,
    selectedDayBookItem?.task?.tagged_user,
    selectedTags,
    selectedDayBookItem?.task?.start_time,
    selectedDayBookItem?.task?.end_time,
    selectedDayBookItem?.time,
    isThereTextInMessage,
    startTime,
    endTime,
    trafficColors,
  ]);

  const updateDayBookItem = async () => {
    if (selectedDayBookItem?.task?.title == "") {
      setSelectedDayBookItem(null);
      return;
    }

    if (
      selectedDayBookItem?.task?.title !== "" &&
      endTime !== "" &&
      startTime == ""
    ) {
      toast.warning("Can't have end time without having start time!");
      return;
    }
    let requiredParmas = {
      ...selectedDayBookItem.task,
      tag: selectedTags || [],
      tagged_user:
        typeof selectedDayBookItem.task.tagged_user === "string"
          ? selectedDayBookItem.task.tagged_user
              .split(",")
              .map((item: any) => parseInt(item))
          : selectedDayBookItem.task.tagged_user || [],
      assigned_to: selectedDayBookItem.task.assigned_to,
      daybook_id: selectedDayBookItem.task.id,
      start_time: startTime,
      end_time: endTime,
      date: selectedDayBookItem.task.date
        ? selectedDayBookItem.task.date
        : selectedSortTab.id == 5
        ? `${new Date(chooseDate).getFullYear()}-${prefixDate(
            new Date(chooseDate).getMonth() + 1
          )}-${new Date(chooseDate).getDate()}`
        : selectedSortTab?.value,
      traffic_light:
        trafficColors == "#ef3a34"
          ? 0
          : trafficColors == "#fbbf14"
          ? 1
          : trafficColors == "#2ad400"
          ? 2
          : 1,
    };

    if (!isModify) {
      setSelectedDayBookItem(null);
      return;
    }

    try {
      const response = await dispatch(updateDaybookItem(requiredParmas));
      if (response?.meta?.requestStatus === "fulfilled") {
        toast.success("Task updated successfully!", {
          style: {},
          progressStyle: { backgroundColor: dynamicColor },
          icon: (
            <span
              style={{
                marginRight: "8px",
                borderRadius: "50%",
                width: "7rem",
                height: "35px",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: dynamicColor,
                backgroundColor: "white",
                fontSize: "1.5rem",
                border: `3px solid ${dynamicColor}`,
              }}
            >
              ✓
            </span>
          ),
        });
        setStartTime("");
        setEndTime("");
        setRefreshAttempt(refreshAttempt + 1);
        setIsModify(false);
      }
    } catch (error) {
      setIsModify(false);
      setSelectedDayBookItem(null);

      throw error;
    }
    dispatch(getUserNotificationStats());

    setSelectedDayBookItem(null);
  };

  const sendComms = async () => {
    try {
      const res = await dispatch(createDocumentCommsThunk(createCommsData));
      if (res?.payload?.status === "success") {
        toast.success("Message Sent Successfully!", {
          style: {},
          progressStyle: { backgroundColor: dynamicColor },
          icon: (
            <span
              style={{
                marginRight: "8px",
                borderRadius: "50%",
                width: "7rem",
                height: "35px",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: dynamicColor,
                backgroundColor: "white",
                fontSize: "1.5rem",
                border: `3px solid ${dynamicColor}`,
              }}
            >
              ✓
            </span>
          ),
        });
      }
    } catch (err) {
      console.error(err);
      // toast.error("Failed to create comms.");
    }
  };

  const handleAddTask = (daybookItem: any) => {
    // setState((prevData: any) => ({ ...prevData, editorState: EditorState?.createEmpty() }));
    const tempDayBookList = JSON.parse(JSON.stringify(dayBookList));

    let itemIndex = -1;

    dayBookList?.find((item: any, index: any) => {
      if (item.time === daybookItem.time) {
        itemIndex = index;
      }
    });
    // // console.log(selectedSortTab);
    if (tempDayBookList[itemIndex]?.task?.length) {
      tempDayBookList[itemIndex]?.task.unshift({
        title: "",
        summary: "",
        communications: "",
        tag: [],
        tagged_user: [],
        date: selectedSortTab?.value || "",
        start_time:
          tempDayBookList[itemIndex]?.time === "All Day"
            ? ""
            : tempDayBookList[itemIndex]?.time,
        end_time:
          tempDayBookList[itemIndex]?.time === "All Day"
            ? ""
            : tempDayBookList[itemIndex]?.time,
        assigned_to: isClientsOrStaffScreen ? clientId : userId,
      });
    } else if (tempDayBookList?.length > 0) {
      tempDayBookList[itemIndex].task = [
        {
          title: "",
          summary: "",
          communications: "",
          tag: [],
          tagged_user: [],
          date: selectedSortTab?.value || "",
          start_time:
            tempDayBookList[itemIndex]?.time === "All Day"
              ? ""
              : tempDayBookList[itemIndex]?.time,
          end_time:
            tempDayBookList[itemIndex]?.time === "All Day"
              ? ""
              : tempDayBookList[itemIndex]?.time,
          assigned_to: isClientsOrStaffScreen ? clientId : userId,
        },
      ];
    }

    setDayBookList(tempDayBookList);
    setEditorState(EditorState?.createEmpty());
    setTimeout(() => {
      setSelectedDayBookItem({
        time: tempDayBookList.length > 0 ? tempDayBookList[itemIndex].time : "",
        task:
          tempDayBookList.length > 0
            ? tempDayBookList[itemIndex].task[0]
            : null,
        taskIndex: 0,
      });
      // setTimeout(() => {
      setStartTime("");
      setEndTime("");
      setTrafficColors("#fbbf14");
      setShowLights(false);
      // }, 500);
    });
  };

  const addDayBookItem = async () => {
    if (selectedDayBookItem?.task?.title == "") {
      setSelectedDayBookItem(null);
      return;
    }
    let requiredParmas = {
      ...selectedDayBookItem?.task,
      tag: selectedTags || [],
      tagged_user: selectedDayBookItem?.task.tagged_user || userId,
      assigned_to: selectedDayBookItem?.task.assigned_to,
      start_time: startTime,
      end_time: endTime,
      communications: [
        {
          summary: "",
          message: selectedDayBookItem?.task?.communications,
        },
      ],
      date: selectedDayBookItem?.task?.date
        ? selectedDayBookItem?.task?.date
        : selectedSortTab?.id == 5
        ? `${new Date(chooseDate).getFullYear()}-${prefixDate(
            new Date(chooseDate).getMonth() + 1
          )}-${new Date(chooseDate).getDate()}`
        : selectedSortTab?.value,
      traffic_light:
        trafficColors == "#ef3a34"
          ? 0
          : trafficColors == "#fbbf14"
          ? 1
          : trafficColors == "#2ad400"
          ? 2
          : 1,
    };

    if (endTime !== "" && startTime == "") {
      toast.warning("Can't have end time without having start time!");
      return;
    }

    try {
      const response = await dispatch(createDaybookItem(requiredParmas));

      if (response?.meta?.requestStatus === "fulfilled") {
        toast.success("Task added successfully!", {
          style: {},
          progressStyle: { backgroundColor: dynamicColor },
          icon: (
            <span
              style={{
                marginRight: "8px",
                borderRadius: "50%",
                width: "7rem",
                height: "35px",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: dynamicColor,
                backgroundColor: "white",
                fontSize: "1.5rem",
                border: `3px solid ${dynamicColor}`,
              }}
            >
              ✓
            </span>
          ),
        });
        setRefreshAttempt(refreshAttempt + 1);
        setSelectedDayBookItem(null);
        setStartTime("");
        setEndTime("");
      }
    } catch (error) {
      setSelectedDayBookItem(null);

      throw error;
    }
    setSelectedDayBookItem(null);
    setRefreshAttempt(refreshAttempt + 1);
  };

  useEffect(() => {
    setSelectedSortDateCustom(null);
  }, []);

  return (
    <div className="daybook_sticky_wrapper">
      <div className="date_picker_section_wrapper">
        <span>Select Date:</span>
        {selectDateCategories.map(({ title, id, value }: any, index) => (
          <div
            key={index}
            style={{
              textAlign: "center",
            }}
            className={` sort_tab_sticky_daybook ${
              (selectedSortTab?.id === id && "sort_tab_selected") || ""
            }`}
            onClick={(e) => {
              setSelectedSortTab({ title, id, value });
              if (id !== 5) {
                setSelectedSortDateCustom(null);
              }
              onChangeTab();
            }}
          >
            {selectedSortDateCustom && id === 5
              ? `${selectedSortDateCustom?.getDate()} ${
                  monthNames[selectedSortDateCustom?.getMonth()]
                } ${selectedSortDateCustom?.getFullYear()}`
              : title}
            {id === 5 && (
              <div className="date-picker-wrapper">
                <CustomDatePicker
                  newDate={selectedSortDateCustom}
                  handleChange={setSelectedSortDateCustom}
                />
              </div>
            )}
          </div>
        ))}
      </div>
      <div className="daybook_wrapper_sort_section">
        <div className="daybook_sort_content_row"> </div>
        <div className="daybook_sort_content_row">
          <div className="editor_btn_group">
            <BoldSVG />
            <ItalicIconSVG />
            <UnderscorSVG />
          </div>
        </div>
      </div>
      <div className="day_book_main_wrapper_for_all_list">
        {dayBookList.map((res: any, index: number) => {
          return (
            <div className="main_time_wrapper_for_all_daybook" key={index}>
              <div className="time_section_wrapper">
                <PlusSVG
                  marginLeft="2px"
                  onClick={() => {
                    if (!selectedDayBookItem) {
                      handleAddTask(res);
                    }
                  }}
                />
                <span>{res?.time}</span>
              </div>
              <div className="daybook_task_wrapper">
                {res?.task?.map((dayBookTask: any, dayBookTaskIndex: any) => {
                  if (
                    selectedDayBookItem?.time === res?.time &&
                    selectedDayBookItem?.taskIndex === dayBookTaskIndex
                  ) {
                    return (
                      <Fragment key={dayBookTaskIndex}>
                        {additionalState?.showTagsArr[dayBookTaskIndex] && (
                          // @ts-ignore
                          <div>
                            <TagsInput
                              allTags={allTags}
                              selectedTags={selectedTags}
                              setSelectedTags={(info: any) => {
                                // setSelectedTags(info);
                              }}
                              selectedTask={dayBookTask}
                              onSelectTagIdChange={handleSelectedTagIdsChange}
                            />
                          </div>
                        )}
                        <div
                          className="day_book_task_item_wrapper active_daybook_task"
                          // @ts-ignore
                          ref={selectedDayBookItemRef}
                        >
                          <div className="left_three_dots_wrapper_daybook_sticky">
                            {[...Array(3)].map((_, index) => (
                              <div className="left_dots_wrapper" key={index} />
                            ))}
                          </div>
                          <div
                            className="color_tags_daybook_sticky"
                            style={{
                              alignItems: "flex-start",
                              marginTop: "10px",
                            }}
                          >
                            <div
                              className="color-tag"
                              style={{
                                cursor: "pointer",
                                backgroundColor: trafficColors
                                  ? trafficColors
                                  : "#fbbf14",
                              }}
                              onClick={() => setShowLights(!showLights)}
                            />
                            {showLights && (
                              <TrafficLights
                                className="daybook_sticky_clinical_note_taffic_light"
                                isSmall={true}
                                setShowLights={setShowLights}
                                handleTrafficColors={(data: any) => {
                                  setTrafficColors(data);
                                  setShowLights(false);
                                  if (dayBookTask?.id) {
                                    setIsModify(true);
                                  }
                                }}
                              />
                            )}
                          </div>
                          <div className="description_wrapper_ative_task_daybook">
                            <input
                              className="description_heading_input"
                              type="text"
                              value={dayBookTask.title}
                              onChange={({ target }) => {
                                handleTaskItemChange(
                                  "task-title",
                                  index,
                                  dayBookTaskIndex,
                                  target.value
                                );
                              }}
                            />
                            <div className="description_sub_heading_input_wrapper_active_daybook">
                              <span className="description_sub_heading_input_daybook_active">
                                Summary:
                              </span>
                              <input
                                className="description_sub_heading_input_active_daybook_task"
                                type="text"
                                value={
                                  dayBookTask?.summary?.length > 136
                                    ? dayBookTask?.summary?.slice(0, 136) +
                                      "..."
                                    : dayBookTask?.summary
                                }
                                onChange={({ target }) => {
                                  handleTaskItemChange(
                                    "task-summary",
                                    index,
                                    dayBookTaskIndex,
                                    target.value
                                  );
                                }}
                              />
                            </div>
                            {/* <div className="description_description_daybook_wrapper">
                              <div className="description_description_daybook_wrapper_placeholder">
                                Comms:
                              </div>
                              <Editor
                                // ref={editorRef}
                                // wrapperClassName="wrapper-class"
                                mention={{
                                  separator: " ",
                                  trigger: "@",
                                  suggestions: [
                                    {
                                      text: "APPLE",
                                      value: "apple",
                                      url: "apple",
                                    },
                                    {
                                      text: "BANANA",
                                      value: "banana",
                                      url: "banana",
                                    },
                                    {
                                      text: "CHERRY",
                                      value: "cherry",
                                      url: "cherry",
                                    },
                                    {
                                      text: "DURIAN",
                                      value: "durian",
                                      url: "durian",
                                    },
                                    {
                                      text: "EGGFRUIT",
                                      value: "eggfruit",
                                      url: "eggfruit",
                                    },
                                    { text: "FIG", value: "fig", url: "fig" },
                                    {
                                      text: "GRAPEFRUIT",
                                      value: "grapefruit",
                                      url: "grapefruit",
                                    },
                                    {
                                      text: "HONEYDEW",
                                      value: "honeydew",
                                      url: "honeydew",
                                    },
                                  ],
                                }}
                                editorClassName={`editor-class-daybook daybook_height_fixing `}
                                //   editorState={editorState}
                                onEditorStateChange={(val) => {
                                  handleTaskItemChange(
                                    "task-editor",
                                    index,
                                    dayBookTaskIndex,
                                    val
                                  );
                                }}
                                editorStyle={{ fontSize: 12 }}
                                toolbar={{
                                  options: ["inline"],
                                  inline: {
                                    options: ["bold", "italic", "underline"],
                                  },
                                }}
                                // placeholder="Send Comms:"
                              />
                            </div> */}
                            <div className="description-description-tagged-people-wrapper">
                              <AssignedToSelect
                                taggedPeopleIDs={dayBookTask.assigned_to}
                                taggedPeopleList={clientsDataForAssignedTo}
                                addTaggedPeople={(data: any) => {
                                  handleTaskItemChange(
                                    "task-assigned-to",
                                    index,
                                    dayBookTaskIndex,
                                    data
                                  );
                                }}
                                type={"stickynote"}
                              />
                            </div>
                            <div className="description-description-tagged-people-wrapper">
                              <TagBookSelect
                                taggedPeopleIDs={
                                  typeof dayBookTask.tagged_user === "string"
                                    ? dayBookTask.tagged_user
                                        .split(",")
                                        .map((item: string) => parseInt(item))
                                    : dayBookTask.tagged_user
                                }
                                taggedPeopleList={clientsDataForTaggedUser}
                                addTaggedPeople={(data: any) => {
                                  handleTaskItemChange(
                                    "task-tagged-people",
                                    index,
                                    dayBookTaskIndex,
                                    data
                                  );
                                }}
                                type={"stickynote"}
                              />
                            </div>
                            <div className=" date_picker_satrt_end_time_wrapper_active_daybook_task">
                              <ReactDatePicker
                                // @ts-ignore
                                //   value={dayBookTask?.date}
                                //   placeholderText="Select Date"
                                //   selected={dayBookTask?.date}
                                showYearDropdown
                                showMonthDropdown
                                value={
                                  dayBookTask?.date
                                    ? `${new Date(
                                        dayBookTask?.date
                                      ).getDate()} ${
                                        monthNames[
                                          new Date(dayBookTask?.date).getMonth()
                                        ]
                                      } ${new Date(
                                        dayBookTask?.date
                                      ).getFullYear()}`
                                    : selectedSortTab?.id === 1
                                    ? currentDate?.yesterday
                                    : selectedSortTab?.id === 2
                                    ? currentDate?.today
                                    : selectedSortTab?.id === 3
                                    ? currentDate?.tomorrow
                                    : selectedSortTab?.id === 4
                                    ? currentDate?.dayAfterTomorrow
                                    : selectedSortTab?.id === 5
                                    ? chooseDate
                                      ? chooseDate
                                      : "Select Date"
                                    : ""
                                }
                                //   dateFormat={"dd MMM yyyy"}
                                className="date_Picker"
                                onChange={(date: any) => {
                                  handleTaskItemChange(
                                    "task-date",
                                    index,
                                    dayBookTaskIndex,
                                    date
                                  );
                                }}
                              />
                              <DateStartEndTime
                                startTimeValue={startTime}
                                endTimeValue={endTime}
                                onStartTimeChange={(time: any) => {
                                  setStartTime(time);
                                  if (dayBookTask?.id) {
                                    setIsModify(true);
                                  }
                                }}
                                onEndTimeChange={(time: any) => {
                                  setEndTime(time);
                                  if (dayBookTask?.id) {
                                    setIsModify(true);
                                  }
                                }}
                              />
                              {/* <div className="start_end_time_wrapper">
                    </div> */}
                            </div>
                            <div
                              className="tag_wrapper_daybook_sticky"
                              onClick={() => {
                                setAdditionalState((prevState: any) => {
                                  const updatedShowTagsArr = [
                                    ...prevState.showTagsArr,
                                  ];
                                  updatedShowTagsArr[dayBookTaskIndex] =
                                    !updatedShowTagsArr[dayBookTaskIndex];

                                  return {
                                    ...prevState,
                                    showTagsArr: updatedShowTagsArr,
                                  };
                                });
                              }}
                            >
                              <span className="tag-index">
                                {typeof dayBookTask?.tag === "string"
                                  ? dayBookTask?.tag.split(",").length
                                  : "0"}
                              </span>
                              Tag
                            </div>
                          </div>
                        </div>
                      </Fragment>
                    );
                  } else {
                    return (
                      <div
                        key={dayBookTaskIndex}
                        className="day_book_task_item_wrapper"
                        onClick={() => {
                          if (!selectedDayBookItem) {
                            setSelectedDayBookItem({
                              time: dayBookList[index]?.time,
                              task: dayBookList[index]?.task[dayBookTaskIndex],
                              comms: [...dayBookTask.communications],
                              taskIndex: dayBookTaskIndex,
                            });
                            setIsModify(false);
                            setStartTime(dayBookTask?.start_time);
                            setEndTime(dayBookTask?.end_time);
                            setEditorState(EditorState?.createEmpty());
                            let colorIs = dayBookTask?.traffic_light;
                            setTrafficColors(
                              () => trafficLightColors[`${colorIs}`]
                            );
                          }
                        }}
                      >
                        <div className="left_three_dots_wrapper_daybook_sticky">
                          {[...Array(3)].map((_, index) => (
                            <div className="left_dots_wrapper" key={index} />
                          ))}
                        </div>
                        <div className="color_tags_daybook_sticky">
                          <div
                            className="color-tag"
                            style={{
                              background:
                                dayBookTask.traffic_light == 0
                                  ? "#ef3a34"
                                  : dayBookTask.traffic_light == 1
                                  ? "#fbbf14"
                                  : dayBookTask.traffic_light == 2
                                  ? "#2ad400"
                                  : "",
                            }}
                          />
                        </div>
                        <div className="info_wrapper_daybook_task">
                          <div className="description_wrapper_daybook_sticky">
                            <div className=" description_heading  ">
                              <p>{dayBookTask?.title}</p>
                            </div>
                            <div className="description_sub_heading">
                              {dayBookTask?.summary?.length > 40
                                ? dayBookTask?.summary?.slice(0, 40) + "..."
                                : dayBookTask?.summary?.slice(0, 40)}{" "}
                            </div>
                          </div>
                          <div className="task_item_third_column_daybook_sticky">
                            <div className="date_value_wrapper">
                              {dayBookTask?.date
                                ? formatDateFromAPIforCustom(dayBookTask?.date)
                                : ""}
                            </div>
                            <div
                              className={"cloumn_wrapper_taga_and_attachment"}
                            >
                              <div className="tag_wrapper_daybook_sticky">
                                <span className="tag-index">
                                  {typeof dayBookTask?.tag === "string"
                                    ? dayBookTask?.tag.split(",").length
                                    : "0"}
                                </span>
                                Tag
                              </div>
                              <PDFSvg />
                              <ClientStatusCircleSvg
                                count={dayBookTask?.communications?.length}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  }
                })}
              </div>
            </div>
          );
        })}
        {/* <div className="daybook_sort_content_row"> </div> */}
        {/* <div className="daybook_sort_content_row-1"> </div> */}
      </div>
    </div>
  );
};

export default DaybookSticky;
