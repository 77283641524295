import React, { useEffect, useRef, useState } from "react";
import Table from "../../../components/CommonTable/Table";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../Controller/redux/Hooks";
import { useNavigate, useParams } from "react-router-dom";
import {
  getProviderProspect,
  getProviderProspectFilterThunk,
  resetProspectProviderData,
} from "../../../../Controller/redux/features/ProviderFeature/providerProspect";
import {
  getProviderProspectEmailThunk,
  getProviderProspectNameThunk,
  getProviderProspectPhoneThunk,
  getProviderProspectRefferThunk,
  getProviderProspectStatusThunk,
  resetProspectPageFilter,
  setProspectPageFilter,
} from "../../../../Controller/redux/features/ProspectsFeature/prospectsSlice";

const ProviderProspect = () => {
  const tableRef = useRef();
  const prospectInnerRef = useRef();
  const dropdownRefProspects = useRef<any>();
  const svgParentRefProspects = useRef<any>();
  const navigate = useNavigate();
  const [isDropdownOpenProspects, setIsDropdownOpenProspects] = useState(false);
  const { domainDetail } = useAppSelector((state) => state?.common);
  const columns = [
    { header: "Name", accessor: "first_name" },
    { header: "Email", accessor: "email" },
    { header: "Mobile", accessor: "phone" },
    { header: "Referrer", accessor: "referrer_name" },
    { header: "Status", accessor: "status" },
  ];

  const handleClickRow = (e: any, id: any) => {
    // if (hasStatusWidgetOpen) {
    //   return;
    // }
    // if (!isDropdownOpenProspects) {
    navigate(
      `/providers/${window.btoa(providerId)}/prospects/${window.btoa(id)}`
    );
    // }
  };
  const handleClickOutsideProspects = (event: any) => {
    if (
      dropdownRefProspects.current &&
      !dropdownRefProspects.current.contains(event.target) &&
      svgParentRefProspects.current &&
      !svgParentRefProspects.current.contains(event.target)
    ) {
      setIsDropdownOpenProspects(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutsideProspects);
    return () => {
      document.removeEventListener("click", handleClickOutsideProspects);
    };
  }, []);

  const id: any = useParams()?.id;
  const [providerId, setProviderId] = useState<any>("");
  useEffect(() => {
    if (id) {
      let realId = window?.atob(id);
      setProviderId(realId);
    }
  }, [id]);

  const dynamicProspectsId = parseInt(
    // @ts-ignore
    localStorage.getItem("dynamicProspectId")
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    // dispatch(
    //   getProviderProspect({
    //     dynamicProviderId: providerId,
    //     dynamicUserTypeId: dynamicProspectsId,
    //   })
    // );
    dispatch(
      getProviderProspectNameThunk({
        dynamicProspectsId: dynamicProspectsId,
        dynamicProviderId: providerId,
      })
    );
    dispatch(
      getProviderProspectEmailThunk({
        dynamicProspectsId: dynamicProspectsId,
        dynamicProviderId: providerId,
      })
    );
    dispatch(
      getProviderProspectPhoneThunk({
        dynamicProspectsId: dynamicProspectsId,
        dynamicProviderId: providerId,
      })
    );
    dispatch(
      getProviderProspectRefferThunk({
        dynamicProspectsId: dynamicProspectsId,
        dynamicProviderId: providerId,
      })
    );
    dispatch(
      getProviderProspectStatusThunk({
        dynamicProspectsId: dynamicProspectsId,
        dynamicProviderId: providerId,
      })
    );
  }, [providerId, dynamicProspectsId]);

  const { prospectRecords, loading, lastPage } = useAppSelector(
    (state) => state?.providerProspect
  );

  const [providerProspectRecords, setProviderProspectRecords] = useState<any[]>(
    []
  );

  useEffect(() => {
    setProviderProspectRecords(prospectRecords);
  }, [prospectRecords]);

  const { ProspectPageFilter, ProspectFilterItems } = useAppSelector(
    (state) => state?.prospects
  );
  useEffect(() => {
    dispatch(resetProspectProviderData());
    dispatch(resetProspectPageFilter());
  }, [
    ProspectFilterItems?.searchVal,
    ProspectFilterItems?.filterValue,
    ProspectFilterItems?.sortType,
    ProspectFilterItems?.status,
    dynamicProspectsId,
    providerId,
  ]);
  useEffect(() => {
    dispatch(
      getProviderProspectFilterThunk({
        dynamicProviderId: providerId,
        dynamicUserTypeId: dynamicProspectsId,
        filterColumn: ProspectFilterItems?.filterColumn
          ? ProspectFilterItems?.filterColumn == "mobile"
            ? "phone"
            : ProspectFilterItems?.filterColumn
          : "",
        filterValue: ProspectFilterItems?.filterValue
          ? ProspectFilterItems?.filterValue
          : "",
        searchVal: ProspectFilterItems?.searchVal
          ? ProspectFilterItems?.searchVal
          : "",
        sortColumn: ProspectFilterItems?.sortColumn
          ? ProspectFilterItems?.sortColumn == "mobile"
            ? "phone"
            : ProspectFilterItems?.sortColumn
          : "",
        sortType: ProspectFilterItems?.sortType
          ? ProspectFilterItems?.sortType
          : "",
        status: ProspectFilterItems.status ? ProspectFilterItems.status : "",
        ProspectPageFilter,
      })
    );
  }, [
    ProspectPageFilter,
    dynamicProspectsId,
    providerId,
    ProspectFilterItems?.searchVal,
    ProspectFilterItems?.filterValue,
    ProspectFilterItems?.sortType,
    ProspectFilterItems?.status,
  ]);
  const onScroll = () => {
    if (prospectInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } =
        prospectInnerRef.current;

      if (
        scrollTop + clientHeight + +5 >= scrollHeight &&
        ProspectPageFilter < lastPage
      ) {
        dispatch(setProspectPageFilter(ProspectPageFilter));
      }
    }
  };

  const uniqueProspectData = providerProspectRecords?.filter(
    (item: any, index: number, array: any) => {
      return array.findIndex((obj: any) => obj.id === item.id) === index;
    }
  );

  return (
    <div className="provider_prospect_wrapper">
      <Table
        isLoading={loading}
        tableRef={tableRef}
        tableBodyRef={prospectInnerRef}
        onScroll={onScroll}
        dynamicHeaderColor={domainDetail?.listingtbl_color}
        itemsArr={[]}
        statusType="providerProspect"
        thData={columns}
        classNameTr={` all-prospects-table-head  `}
        type="providerProspect"
        dropdownRef={dropdownRefProspects}
        isDropdownOpen={isDropdownOpenProspects}
        onClickSvg={() => setIsDropdownOpenProspects(true)}
        svgParentRef={svgParentRefProspects}
        allClients={uniqueProspectData}
        onClickRow={handleClickRow}
        classNameRow={`all-prospects-table-body  `}
        // hasStatusWidgetOpen={(value: any) => {
        //   setHasStatusWidgetOpen(value);
        // }}
        fill={"#D8D3D3"}
      />
    </div>
  );
};

export default ProviderProspect;
