import React, { useContext, useEffect, useState, Fragment } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { LogoLeft } from "../../../components/LogoLeft/LogoLeft";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../Controller/redux/Hooks";
import {
  getDomainDetailsThunk,
  getProviderDetailThunk,
} from "../../../../Controller/redux/features/CommonFeature/commonSlice";
import {
  resetPasswordThunk,
  verifyResetPasswordLinkThunk,
} from "../../../../Controller/redux/features/AuthFeature/authSlice";
import { Spinner } from "@chakra-ui/react";
import { toast } from "react-toastify";

interface InValue {
  username: string;
  new_password: string;
  confirm_password: string;
  password_token: string;
}

function initialFormValues(): InValue {
  return {
    username: "",
    new_password: "",
    confirm_password: "",
    password_token: "",
  };
}

export function ResetPassword() {
  const navigate = useNavigate();
  const { logo, success_colour } = useAppSelector(
    (state) => state?.common?.fromGetDomainDetails
  );
  const password_token = useParams();

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getDomainDetailsThunk());
  }, []);

  const [values, setValues] = useState(initialFormValues);
  const [requestStatus, setRequestStatus] = useState("success");

  const {
    register: validation,
    handleSubmit: handleValidation,
    reset,
    formState,
  } = useForm();
  const { errors } = formState;

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });
  }

  async function handleResetpassword(e: any) {
    setRequestStatus("loading");

    if (
      !values.new_password ||
      !values.confirm_password ||
      values.new_password !== values.confirm_password
    ) {
      toast.warn("Password and Confirm Password must match!");
      setRequestStatus("success");
      return;
    }
    const passwordRegex =
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*?&])[0-9a-zA-Z@$!%*?&]{6,}$/;

    if (!passwordRegex.test(values.new_password)) {
      toast.warn(
        "Password must contain at least 6 characters including uppercase, lowercase letters and '@'"
      );
      setRequestStatus("success");
      return;
    }
    try {
      const response = await dispatch(resetPasswordThunk(values));
      if (response.payload.status === "success") {
        toast.success("Password Changed Successfully!", {
          style: {},
          progressStyle: { backgroundColor: success_colour },
          icon: (
            <span
              style={{
                marginRight: "8px",
                borderRadius: "50%",
                width: "7rem",
                height: "35px",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: success_colour,
                backgroundColor: "white",
                fontSize: "1.5rem",
                border: `3px solid ${success_colour}`,
              }}
            >
              ✓
            </span>
          ),
        });
      }
      if (response.payload.status === "success") {
        navigate("/login");
      }
    } catch (error: string | any) {
      // console.log(error);
    }
    setRequestStatus("success");
  }

  useEffect(() => {
    async function verifyPasswordToken(password_token: any) {
      let username = await dispatch(
        verifyResetPasswordLinkThunk(password_token)
      );
      if (username) {
        setValues({
          ...values,
          username: username.payload,
          password_token: password_token.password_token,
        });
      } else {
        navigate("/login");
      }
    }
    if (password_token?.password_token) {
      verifyPasswordToken(password_token);
    }
    return () => setRequestStatus("success");
  }, []);
  return (
    <Fragment>
      <div className="main-section main-section-display reset-password-screen">
        <LogoLeft />
        <div className="login_section_right">
          <div className="container">
            <div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div className="login-content-section">
                  <div className="login-heading">
                    <div className="logo-section">
                      <a href="">
                        <img
                          src={logo}
                          alt="logo"
                          className="imf-fluid display-block-img"
                        />
                      </a>
                    </div>
                  </div>
                  <form onSubmit={handleValidation(handleResetpassword)}>
                    <div
                      className={`form-section ${
                        errors.new_password || errors.confirm_password
                          ? "changeColor"
                          : ""
                      }`}
                    >
                      <div className="form-group error">
                        <h3 className="">Change Password</h3>
                        <div>
                          <label className="reset_password_label">
                            Enter & Confirm Your Password
                          </label>
                          <div className="input-section">
                            <input
                              id="new_password"
                              name="new_password"
                              type="password"
                              placeholder="Password"
                              disabled={requestStatus === "loading"}
                              value={values.new_password}
                              onChange={handleChange}
                            />
                          </div>
                          {errors.new_password && (
                            <span className="errorname">
                              {errors.new_password.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <br />
                      <div className="form-group error">
                        <div className="">
                          <div className="input-section">
                            <input
                              id="confirm_password"
                              name="confirm_password"
                              type="password"
                              placeholder="Confirm Password"
                              disabled={requestStatus === "loading"}
                              value={values.confirm_password}
                              onChange={handleChange}
                            />
                          </div>
                          {errors.confirm_password && (
                            <span className="errorname">
                              {errors.confirm_password.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="form-group ">
                        <div className="btn-section">
                          <button className="comman-btn">
                            {requestStatus === "loading" ? (
                              <Spinner
                                thickness="4px"
                                size="md"
                                speed="0.65s"
                              />
                            ) : (
                              "Change"
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
