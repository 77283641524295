import React from "react";
import { ReactNode } from "react";
import { Navigate } from "react-router-dom";
import { getToken } from "../../Model/utils/tokenCookies";

interface IPublicRoute {
  children: ReactNode;
}

export function PublicRoute({ children }: IPublicRoute) {
  // const token = localStorage.getItem("token");
  const token = getToken();
  return token ? <Navigate to="/" /> : <>{children}</>;
}
