import React, { useEffect, useState } from "react";
import Category from "./Category";
import "./styles/index.css";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../Controller/redux/Hooks";
import {
  addSoftwareAccess,
  getSoftwareAccess,
} from "../../../Controller/redux/features/VendorFeature/VendorSlice";
import { useParams } from "react-router-dom";
import CircularNoteTrigger from "../CircularNoteTrigger/CircularNoteTrigger";
import { toast } from "react-toastify";

const VendorAccess = () => {
  const dispatch = useAppDispatch();
  const { id }: any = useParams();
  const dynamicVendorId = localStorage.getItem("dynamicVendorsId");
  const userString: any = localStorage.getItem("user");
  const user: any = JSON.parse(userString);

  const dynamicColor = useAppSelector((state) => state?.common?.dynamicColor);
  const { allowedVendorIds } = useAppSelector((state) => state.vendors);
  const { softwareAccessList } = useAppSelector((state) => state?.vendors);
  
  useEffect(() => {
    dispatch(
      getSoftwareAccess({
        type: dynamicVendorId,
        id: window.atob(id),
        parentId: user?.data?.tenant_id,
      })
    );
  }, []);

  const handleAddSoftwareAccess = () => {
    dispatch(
      addSoftwareAccess({ id: window.atob(id), vendors: allowedVendorIds })
    ).then((res) => {
      if (res?.error) {
        toast.error(res?.payload);
        return;
      }
      toast.success("Updated Software Access Successfully!", {
        progressStyle: { backgroundColor: dynamicColor },
        icon: (
          <span
            style={{
              marginRight: "8px",
              borderRadius: "50%",
              width: "7rem",
              height: "35px",
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: dynamicColor,
              backgroundColor: "white",
              fontSize: "1.5rem",
              border: `3px solid ${dynamicColor}`,
            }}
          >
            ✓
          </span>
        ),
      });
      dispatch(
        getSoftwareAccess({
          type: dynamicVendorId,
          id: window.atob(id),
          parentId: user?.data?.tenant_id,
        })
      );
    });
  };

  return (
    <div>
      {softwareAccessList?.map((category: any) => (
        <Category key={category?.id} {...category} />
      ))}
      <button className="save_button_vendor" onClick={handleAddSoftwareAccess}>
        Save
      </button>
      <CircularNoteTrigger />
    </div>
  );
};

export default VendorAccess;
