import React from "react";

const GreenTickSvg = () => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
        style={{
          marginRight: 5,
        }}
      >
        <path
          d="M6 12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 0 6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12Z"
          fill="#2AD400"
        />
        <path
          d="M6.00016 10.6663C8.57749 10.6663 10.6668 8.577 10.6668 5.99967C10.6668 3.42234 8.57749 1.33301 6.00016 1.33301C3.42283 1.33301 1.3335 3.42234 1.3335 5.99967C1.3335 8.577 3.42283 10.6663 6.00016 10.6663Z"
          fill="white"
        />
        <path
          d="M3.3335 5.85841L5.41824 8L9.3335 4"
          stroke="#2AD400"
          strokeWidth="1.5"
        />
      </svg>
    </div>
  );
};

export default GreenTickSvg;
