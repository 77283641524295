import React, { useCallback, useEffect, useRef, useState } from "react";
import DropdownOptions from "../../components/CommonTable/Components/DropdownOptions";
import SelectedFilterOption from "../../components/CommonTable/Components/SelectedFilterOption";
import SelectedSortDropdown from "../../components/CommonTable/Components/SelectedSortDropdown";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../Controller/redux/Hooks";
import {
  filterIcon,
  searchIcon,
  sortIcon,
} from "../../components/CommonTable/Components/TableDropDown2";
import SelectedFilterDropdown from "../../components/CommonTable/Components/SelectedFilterDropdown";
import SelectedSortOptions from "../../components/CommonTable/Components/SelectedSortOptions";
import {
  getUserNotificationDetails,
  getUserNotificationDetailsByFilter,
  resetInputSearchValNotifications,
  resetSelectedDropdownItemNotifications,
  resetSelectedFilterItemsNotifications,
  resetSelectedItemIndexFilterNotifications,
  resetSelectedItemIndexSortNotifications,
  resetSortByNotifications,
  setInputSearchValNotifications,
  setSelectedDropdownItemNotifications,
  setSelectedFilterItemsNotifications,
  setSelectedItemIndexFilterNotifications,
  setSelectedItemIndexSortNotifications,
  setSortByNotifications,
} from "../../../Controller/redux/features/NotificationsFeature/NotificaionsSlice";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router";
import _ from "lodash";

const NotificationsDropdown = ({
  selectedFilterDropDownOne,
  selectedSortDropDownOne,
  clientId,
  setState,
}: any) => {
  const dispatch = useAppDispatch();
  const status = useLocation()?.state?.status;

  const Data: any[] = [
    {
      id: 1,
      icon: filterIcon,
      title: "filter",
    },
    {
      id: 2,
      icon: searchIcon,
      title: "search",
    },
    {
      id: 3,
      icon: sortIcon,
      title: "sort",
    },
  ];

  const tableHeadArrNotifications = ["Status"];
  const tableHeadArrNotificationsSort = ["Status", "Title", "Date"];

  const inputRefNotifications = useRef<any>(null);

  const {
    selectedDropdownItemNotifications,
    selectedItemIndexFilterNotifications,
    selectedItemIndexSortNotifications,
    selectedFilterItemsNotifications,
  } = useAppSelector((state) => state?.notifications);
  const selectedItemIndexAssociationsSort = useAppSelector(
    (state) => state?.associations?.selectedItemIndexAssociationsSort
  );
  const selectedItemIndexAssociations = useAppSelector(
    (state) => state?.associations?.selectedItemIndexAssociations
  );
  const filterIconColor = useAppSelector(
    (state) => state?.common?.filterIconColor
  );

  const { selectedSortByOption } = useAppSelector(
    (state) => state?.notifications
  );

  const [sortNotifications, setSortNotifications] =
    useState(selectedSortByOption);

  const [selectedTitles, setSelectedTitles] = useState<string[] | any[]>([]);

  ////////////////////////////////////////////  INDEX IF SORT / FILTER ////////////////////////////////////////////////////
  const [selectedSortIndex, setselectedSortIndex] = useState<any>(
    selectedItemIndexAssociationsSort
  );
  const [selectedItemIndex, setSelectedItemIndex] = useState(
    selectedItemIndexAssociations
  );
  const [notificationsTableDataObject, setNotificationsTableDataObject] =
    useState<any>({
      currentActive: selectedDropdownItemNotifications,
      selectedItemIndex: selectedItemIndexFilterNotifications,
      selectedSortIndex: selectedItemIndexSortNotifications,
    });

  const [inputValueTextNotifications, setinputValueTextNotifications] =
    useState<any>("");

  useEffect(() => {
    const selected = status === 0 ? "Red" : status === 1 ? "Yellow" : "Green";

    if (selectedTitles.length === 0 && selected) {
      handleCheckboxChangeNotifications(selected);
    }
  }, []);

  const handleSortChangeNotifications = (val: any) => {
    const capturedVal = val === "newest on top" ? "asc" : "desc";
    setSortNotifications(val);

    dispatch(setSortByNotifications(val));

    let sortColumn = "";
    switch (notificationsTableDataObject?.selectedSortIndex) {
      case 0:
        sortColumn = "status";
        break;
      case 1:
        sortColumn = "title";
        break;
      case 2:
        sortColumn = "date";
        break;
      default:
        sortColumn = "";
        break;
    }

    const filterData = {
      columnTypeFilter: "",
      filterVal: "",
      searchVal: "",
      columnTypeSort: sortColumn,
      sortType: capturedVal,
    };
    dispatch(getUserNotificationDetailsByFilter(filterData));
  };

  const renderSelectedSortOptionsNotifications = (index: number) =>
    notificationsTableDataObject.selectedSortIndex === index &&
    selectedSortDropDownOne?.id === 3 && (
      <SelectedSortOptions
        options={["newest on top", "oldest on top"]}
        sortBy={sortNotifications}
        sortChange={handleSortChangeNotifications}
        selectedSortIndex={notificationsTableDataObject.selecredSortIndex}
        iconColor={filterIconColor}
      />
    );

  const getNotificationsDebounce = useCallback(
    _.debounce((newInputValue: any) => {
      dispatch(
        getUserNotificationDetailsByFilter({
          status,
          filterVal: "",
          columnType: "",
          uniqueCol: "",
          searchVal: newInputValue,
          sortType: "",
        })
      );
      dispatch(setInputSearchValNotifications(newInputValue));
    }, 300),
    []
  );
  const handleInputChangeNotifications = (e: any) => {
    const newInputValue = e.target.value;
    setinputValueTextNotifications(newInputValue);

    getNotificationsDebounce(newInputValue);
  };

  const handleClearSearchNotifications = () => {
    resetSelectedItemIndexFilterNotifications();
    setSelectedItemIndex(null);
    setselectedSortIndex(null);
    setinputValueTextNotifications("");
    dispatch(resetInputSearchValNotifications());
    dispatch(resetSelectedFilterItemsNotifications());
    dispatch(getUserNotificationDetails(status));
    dispatch(resetSelectedDropdownItemNotifications());
  };

  const handleItemClickDropdownOneDocuments = (
    id: any,
    icon: any,
    title: any,
    props: any
  ) => {
    if (title.includes("filter")) {
      dispatch(resetInputSearchValNotifications());
      dispatch(resetSelectedItemIndexFilterNotifications());
      dispatch(resetSortByNotifications());
      setNotificationsTableDataObject((prevState: any) => ({
        ...prevState,
        selectedSortIndex: null,
      }));
    } else if (title.includes("sort")) {
      dispatch(resetInputSearchValNotifications());
      dispatch(resetSelectedItemIndexFilterNotifications());
      dispatch(resetSelectedFilterItemsNotifications());
      setNotificationsTableDataObject((prevState: any) => ({
        ...prevState,
        selectedItemIndex: null,
      }));
    } else {
      dispatch(resetSelectedItemIndexFilterNotifications());
      dispatch(resetSelectedItemIndexSortNotifications());
      dispatch(resetSortByNotifications());
      dispatch(resetSelectedFilterItemsNotifications());
    }
    setNotificationsTableDataObject((prevState: any) => ({
      ...prevState,
      currentActive: title,
    }));

    dispatch(setSelectedDropdownItemNotifications(title));
    setState((prevData: any) => ({
      ...prevData,
      selectedFilterDropDownOne:
        id === 1 ? { icon, title, id, ...props } : null,
      selectedSortDropDownOne: id === 3 ? { icon, title, id, ...props } : null,
      selectedSearchItem: id === 2,
      selectedFilterDropDownTwo: null,
      selectedSortDropDownTwo: null,
      selectedItem: { icon, title, id, ...props },
    }));
  };

  const handleClickNotificationsColumn = (index: any) => {
    setNotificationsTableDataObject((prevState: any) => ({
      ...prevState,
      selectedItemIndex: index,
    }));
    dispatch(setSelectedItemIndexFilterNotifications(index));
    setSelectedTitles([]);
  };

  const handleChangeOfDropdownNotifications = (names: any, type: any) => {
    const filterColumn = "status";
    const filterValue = names.join(",");
    if (names.length != 0) {
      dispatch(
        getUserNotificationDetailsByFilter({
          filterVal: filterValue,
          columnTypeFilter: filterColumn,
          uniqueCol: "",
          searchVal: "",
          sortType: "",
          columnTypeSort: "",
        })
      );
    } else {
      dispatch(getUserNotificationDetails(status));
    }
  };

  const handleCheckboxChangeNotifications = (elem: any) => {
    const updatedSelectedTitles = selectedTitles?.includes(elem)
      ? selectedTitles.filter((t) => t !== elem)
      : [...selectedTitles, elem];

    setSelectedTitles(updatedSelectedTitles);
    dispatch(setSelectedFilterItemsNotifications(updatedSelectedTitles));
    handleChangeOfDropdownNotifications(updatedSelectedTitles, "status");
  };

  const handleSortOptionNotifications = (index: any) => {
    setNotificationsTableDataObject((prevState: any) => ({
      ...prevState,
      selectedSortIndex: index,
    }));
    dispatch(setSelectedItemIndexSortNotifications(index));
  };

  return (
    <div className="dropdown-wrapper-main">
      <div className="notifications-table-dropdown">
        <div className="dropdown-2-box">
          <div className="dropdown-2">
            <DropdownOptions
              selectedItem={notificationsTableDataObject?.currentActive}
              data={Data}
              ref={inputRefNotifications}
              onFocus={() => {
                setSelectedItemIndex(null);
                setselectedSortIndex(null);
              }}
              inputTextValue={inputValueTextNotifications}
              onChange={handleInputChangeNotifications}
              clearSearch={handleClearSearchNotifications}
              onClick={handleItemClickDropdownOneDocuments}
              selectedType={"notifications"}
            />
          </div>

          {/* FILTER OPTIONS */}
          {selectedFilterDropDownOne?.id === 1 && (
            <>
              <SelectedFilterDropdown
                options={tableHeadArrNotifications}
                tableObject={notificationsTableDataObject}
                selectedItemIndex={
                  notificationsTableDataObject?.selectedItemIndex
                }
                clickType="notifications"
                handleClickColumn={handleClickNotificationsColumn}
              />
            </>
          )}

          {/* FILTERING NOTIFICATIONS */}
          {selectedFilterDropDownOne?.id === 1 && (
            <>
              <SelectedFilterOption
                selectedOps={selectedTitles}
                allOps={["Red", "Yellow", "Green"]}
                setselectedOps={setSelectedTitles}
                dispatch={dispatch}
                clientId={clientId}
                currElem="color"
                type="notifications"
                handleChangeCheckboxOption={handleCheckboxChangeNotifications}
                handelChangeSelectAll={(records: any, filterColumn: any) => {
                  handleChangeOfDropdownNotifications(records, filterColumn);
                }}
              />
            </>
          )}

          {/* SORT OPTIONS */}
          {notificationsTableDataObject.currentActive === "sort" ? (
            <>
              <SelectedSortDropdown
                options={tableHeadArrNotificationsSort}
                tableObject={notificationsTableDataObject}
                selectedItemIndex={
                  notificationsTableDataObject.selectedSortIndex
                }
                props={"notifications"}
                handeChangeSortOptions={handleSortOptionNotifications}
              />
              {renderSelectedSortOptionsNotifications(0)}
              {renderSelectedSortOptionsNotifications(1)}
              {renderSelectedSortOptionsNotifications(2)}
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default NotificationsDropdown;
