import "./Styles/Reports.css";

import { useEffect, useRef, useState } from "react";
import CircularNoteTrigger from "../../../components/CircularNoteTrigger/CircularNoteTrigger";
import CommonUpperBox from "../../../components/UpperBox/CommonHeader";
import CommonQuickMenu from "../../../components/CommonQuickMenu/CommonQuickMenu";
import ToggleButtons from "./Components/ToggleButtons";
import ExportSegment from "./Components/ExportSegment";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../Controller/redux/Hooks";
import NestedTable, {
  ReportsTable,
} from "../../../components/CommonTable/Components/NestedTable";
import { getKitchenReportsThunk } from "../../../../Controller/redux/features/MealsFeatures/ReportsFeature/reportsSlice";
import { formatDateForAPI } from "../../../../Model/utils/Helper/formatDate";
//@ts-ignore
import jsPDF from "jspdf";
//@ts-ignore
import html2canvas from "html2canvas";

const Reports = () => {
  const dispatch = useAppDispatch();

  const [stageTwoToGoalsModal, setstageTwoToGoalsModal] = useState<
    boolean | any
  >(false);
  const [tab, setTab] = useState<string | any>("Sessions Report");
  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());
  const [selectedMealType, setSelectedMealType] = useState<number | string>("");

  useEffect(() => {
    if (tab == "Kitchen Report" && selectedDate) {
      dispatch(
        getKitchenReportsThunk({ date: formatDateForAPI(selectedDate) })
      );
    }
  }, [tab]);
  const [tableRef, setTableRef] = useState<any>(null);
  const { reports } = useAppSelector((state) => state?.reports);

  const tableExportRef = useRef();

  const handleExportToPDF = () => {
    const input = tableExportRef?.current;
    if (input) {
      const pdf = new jsPDF("p", "mm", tab == "Kitchen Report" ? "a3" : "a3");
      pdf.text("Meals Reports", 150, 12, { align: "center" });

      const scale = 0.8;

      // @ts-ignore
      html2canvas(input, { scale: scale, quality: 4 }).then((canvas: any) => {
        const imgData = canvas.toDataURL("image/png");

        // @ts-ignore
        pdf.addImage(imgData, "PNG", 4, 25);

        pdf.save("report.pdf");
      });
    }
  };
  return (
    <>
      <CommonUpperBox
        title="Menu Reports"
        type={"screen"}
        isMealBuilder={"MR"}
        onClickPlus={() => {
          setstageTwoToGoalsModal(true);
        }}
      />
      <ToggleButtons setTab={setTab} tab={tab} />
      <ExportSegment
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        selectedMealType={selectedMealType}
        setSelectedMealType={setSelectedMealType}
        handleExportToPDF={handleExportToPDF}
        tab={tab}
      />
      <div
        // @ts-ignore
        ref={tableExportRef}
        className={`dining_room table_wrapper  ${
          tab === "Sessions Report" ? "hide" : "show"
        }`}
      >
        <ReportsTable
          setTableRef={setTableRef}
          elem={"Dining"}
          selectedMealType={selectedMealType}
          dataArray={reports.dining}
          selectedDate={selectedDate}
        />{" "}
        <ReportsTable
          setTableRef={setTableRef}
          elem={"Delivery"}
          selectedMealType={selectedMealType}
          dataArray={reports.delivery}
          selectedDate={selectedDate}
        />
      </div>
      <CircularNoteTrigger type="stickyHub" title="Comms"
        
        isOutside
        extraType="globalCommsBox" />
      {stageTwoToGoalsModal && (
        <CommonQuickMenu
          setshowGoalsModal={(value: any) => {}}
          mainType={"listing"}
          setstageTwoToGoalsModal={setstageTwoToGoalsModal}
        />
      )}
      {tab === "Kitchen Report" && (
        <div
          // @ts-ignore
          ref={tableExportRef}
        >
          <NestedTable />{" "}
        </div>
      )}
    </>
  );
};

export default Reports;
