import "../Styles/ToggleButton.css";

interface IToggleButtons {
  setTab?: (tab: string) => void;
  tab?: string | any;
}

const ToggleButtons = ({ setTab, tab }: IToggleButtons) => {
  const handleTabChange = (tabName: string) => {
    if (setTab) {
      setTab(tabName);
    }
  };

  return (
    <div className="toggle_button_wrapper">
      {["Sessions Report", "Kitchen Report"].map(
        (elem: string, index: number) => (
          <button
            key={index}
            className={`toggle_button ${
              elem.toLowerCase() === tab.toLowerCase()
                ? "active_tab"
                : "inactive_tab"
            }`}
            onClick={() => handleTabChange(elem)}
          >
            {elem}
          </button>
        )
      )}
    </div>
  );
};

export default ToggleButtons;
