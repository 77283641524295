import { useEffect, useRef, useState } from "react";
import CommonUpperBox from "../../components/UpperBox/CommonHeader";
import CircularNoteTrigger from "../../components/CircularNoteTrigger/CircularNoteTrigger";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../Controller/redux/Hooks";
import Table from "../../components/CommonTable/Table";
import {
  getCustomFormsThunk,
  getFormNameThunk,
  getFormTypeThunk,
  setCurrentActiveFormInfo,
  setFormTypePage,
  getFormTypeByFilterThunk,
  resetFormTypePage,
  resetFormTypeData,
  resetCurrentActiveFormInfo,
  resetActiveFormTabsArr,
} from "../../../Controller/redux/features/CustomFromFeature/customFormSlice";
import UnsavedChangesPopup from "../../components/UnsavedChangesPopup/UnsavedChangesPopup";
import CustomForm from "../../components/CommonModal/Components/CustomForm";
import { Modal, ModalOverlay } from "@chakra-ui/react";
import { CrossMdSvg } from "../../../Model/Assets/SVG/Common/CrossSvg";
import "./Styles/Form.css";
import CommonQuickMenu from "../../components/CommonQuickMenu/CommonQuickMenu";
const Form = () => {
  const tableRef = useRef();
  const FormTypeInnerRef = useRef();
  const dispatch = useAppDispatch();
  const { lastPage, formTypePage, formTypeFilterItems } = useAppSelector(
    (state) => state.customForm
  );
  const { domainDetail } = useAppSelector((state) => state?.common);
  // // console.log(formTypeFilterItems);
  // // console.log(formTypeFilterItems);
  useEffect(() => {
    dispatch(getFormNameThunk(userId));
    dispatch(getFormTypeThunk(userId));
  }, []);
  const columns = [

    { header: "Name", accessor: "form_name" },
    { header: "Admin Title", accessor: "admin_title" },

    {
      header: "Form Type",
      accessor: "form_type",
    },
    { header: "Published Status", accessor: "checkbox_cf" },

    { header: "Action", accessor: "actionForm" },

  ];
  useEffect(() => {
    dispatch(resetFormTypeData());
    dispatch(resetFormTypePage());
    // // console.log("2222");
  }, [
    formTypeFilterItems?.searchVal,
    formTypeFilterItems?.filterValue,
    formTypeFilterItems?.sortType,
  ]);
  useEffect(() => {
    dispatch(
      getFormTypeByFilterThunk({
        userId: userId,
        filterColumn: formTypeFilterItems?.filterColumn
          ? formTypeFilterItems?.filterColumn
          : "",
        filterValue: formTypeFilterItems?.filterValue
          ? formTypeFilterItems?.filterValue
          : "",
        searchVal: formTypeFilterItems?.searchVal
          ? formTypeFilterItems?.searchVal
          : "",
        sortColumn: formTypeFilterItems?.sortColumn
          ? formTypeFilterItems?.sortColumn
          : "",
        sortType: formTypeFilterItems?.sortType
          ? formTypeFilterItems?.sortType
          : "",
        formTypePage,
      })
    );
    // // console.log("3333");
  }, [
    formTypePage,
    formTypeFilterItems?.searchVal,
    formTypeFilterItems?.filterValue,
    formTypeFilterItems?.sortType,
  ]);

  const dropdownRefFormType = useRef<any>();
  const svgParentRefFormType = useRef<any>();
  const [isDropdownOpenFormType, setIsDropdownOpenFormType] = useState(false);

  useEffect(() => {
    const handleClickOutsideClients = (event: any) => {
      if (
        dropdownRefFormType.current &&
        !dropdownRefFormType.current.contains(event.target) &&
        svgParentRefFormType.current &&
        !svgParentRefFormType.current.contains(event.target)
      ) {
        setIsDropdownOpenFormType(false);
      }
    };
    document.addEventListener("click", handleClickOutsideClients);
    return () => {
      document.removeEventListener("click", handleClickOutsideClients);
    };
  }, []);

  const { customFormList, loading, hasDeleteFrom } = useAppSelector(
    (state) => state?.customForm
  );

  // @ts-ignore
  const userDetails = JSON.parse(localStorage.getItem("user"));
  const userId = userDetails?.data?.id;
  useEffect(() => {
    dispatch(getCustomFormsThunk(userId));
  }, []);

  // const [customFormRecord, setCustomFormRecord] = useState<any[]>([]);

  // // console.log(customFormList, "customFormList");
  const [isOpenCustomModal, setIsOpenCustomModal] = useState(false);

  const handleClickRow = (e: any, id: any) => {
    let clickedInfo = customFormList?.data?.filter(
      (res: any) => res?.id === id
    );
    dispatch(setCurrentActiveFormInfo(clickedInfo[0]));
    localStorage.setItem("FIRST_TIME_FORM", "true")
    setIsOpenCustomModal(true);
  };
  const [currentEditCustomForm, setcurrentEditCustomForm] = useState(null);
  const [stageTwoToGoalsModal, setstageTwoToGoalsModal] = useState<any>(false);
  const onScroll = () => {
    if (FormTypeInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } =
        FormTypeInnerRef.current;

      if (
        scrollTop + clientHeight + +5 >= scrollHeight &&
        formTypePage < lastPage
      ) {
        dispatch(setFormTypePage(formTypePage));
      }
    }
  };

  // console.log(currentEditCustomForm,'currentEditCustomForm')

  return (
    <div>
      <CommonUpperBox
        type={"listing"}
        title={"Forms"}
        backgroundWithText={true}
        text={"F"}
        onClickPlus={() => {
          setstageTwoToGoalsModal(true);
        }}
      />
      <Table
        isLoading={loading}
        tableRef={tableRef}
        tableBodyRef={FormTypeInnerRef}
        onScroll={onScroll}
        setcurrentEditCustomForm={setcurrentEditCustomForm}
        currentEditCustomForm={currentEditCustomForm}
        dynamicHeaderColor={domainDetail?.listingtbl_color}
        statusType="customForm"
        thData={columns}
        // userId={userId}
        itemsArr={[]}
        classNameTr={"all_custom_form_table_head"}
        type="form"
        dropdownRef={dropdownRefFormType}
        isDropdownOpen={isDropdownOpenFormType}
        onClickSvg={() => setIsDropdownOpenFormType(true)}
        svgParentRef={svgParentRefFormType}
        allClients={
          Array.isArray(customFormList?.data) ? customFormList?.data : []
        }
        onClickRow={handleClickRow}
        classNameRow={"all_custom_form_table_body"}
        fill={"#D8D3D3"}
      />
      {hasDeleteFrom && <UnsavedChangesPopup type="customForm" />}

      {isOpenCustomModal && (
        <>
          <Modal
            isOpen={isOpenCustomModal}
            onClose={() => {
              setIsOpenCustomModal(false);
            }}
            closeOnOverlayClick={false}
            isCentered
          >
            <ModalOverlay
              zIndex={10}
              onClick={() => {
                // setIsOpenCustomModal(false);
              }}
            />
            <div className="vertical_modal_sidebar form_screen_modal_wrapper">
              {/* <div>
                <InfoIcon boxSize={3} /> */}
              <CrossMdSvg
                className="cross_button"
                onClick={() => {
                  setIsOpenCustomModal(false);
                  dispatch(resetCurrentActiveFormInfo());
                  dispatch(resetActiveFormTabsArr());
                }}
              />
              {/* </div> */}

              {/* <div className="custom_form_main_section_wrapper"> */}
              <CustomForm isSideBar={true} />
              {/* </div> */}
            </div>
          </Modal>
        </>
      )}

      <CircularNoteTrigger
        type={"form"}
        mainType="form"
        title={"New Form"}
        currentEditCustomForm={currentEditCustomForm}
        setcurrentEditCustomForm={setcurrentEditCustomForm}
      />
      {stageTwoToGoalsModal && (
        <CommonQuickMenu
          setshowGoalsModal={(value: any) => {}}
          mainType={"listing"}
          setstageTwoToGoalsModal={setstageTwoToGoalsModal}
        />
      )}
    </div>
  );
};

export default Form;
