import React from 'react';

const MessageCount = ({ count , color}: any) => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        style={{
          position: "absolute",
          bottom: "-1px",
          right: "-1px"
        }}
      >
        <path
          d="M8 0C12.4184 0 16 3.5816 16 8C16 12.4184 12.4184 16 8 16C6.7232 16.0018 5.46466 15.6967 4.33041 15.1104C3.96415 14.8584 3.5244 14.8292 3.13451 15.0428C2.38673 15.4525 1.18441 16.0828 0.746986 15.9681C0.575499 15.9231 0.419059 15.8333 0.293694 15.7079C0.16833 15.5825 0.0785479 15.4261 0.0335484 15.2546C-0.0114511 15.0831 -0.0100505 14.9028 0.0376067 14.732L0.892007 11.6736C0.304288 10.5383 -0.00165504 9.27835 6.73355e-06 8C6.73355e-06 3.5816 3.58161 0 8 0Z"
          fill={
            color === 0
            ? "#ef3a34"
            : color === 1
            ? "#fbbf14"
            : "#E6E6E6"
          }
        />
        <text x="8" y="11" 
          style={{
            color:"#fff"
          }}
        textAnchor="middle" fill={ "#fff" } fontSize="8" fontWeight="bold">
          {count || 0}
        </text>
      </svg>
    </div>
  );
};

export default MessageCount;
