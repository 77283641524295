import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../../../services/api";
import { toast } from "react-toastify";

const initialState: any = {
  openMealBuilderForm: false,
  loading: false,
  error: "",
  MealsBuilderData: [],
  MealsTags: [],
  searchValueMeal: "",
  hasDeleteMeal: false,
  idOfMealToDelete: null,
  selectedDataObject: {},
  persistentMealusData: [],
  // REDUCERS FOR MEALS...
  searchValueMeals: "",
  selectedDropdownItemMeals: "",
  selectedItemIndexFilterMeals: "",
  selectedItemIndexSortMeals: "",
  sortbyMeals: "",
  selectedFilterItems: [],
  uniqueTags: [],
};

export const getMealBuilder = createAsyncThunk(
  "Meal/fetchMealBuilder",
  async () => {
    try {
      const { data } = await api.get(`meals?paginate=true&page=1&limit=10000`);
      return data;
    } catch (error) {
      throw error;
    }
  }
);

export const getUniqueTagsMealsThunk = createAsyncThunk(
  "Meal/getUniqueTagsThunk",
  async () => {
    try {
      const response = await api.get(`/meals?unique_column=tags`);
      return response.data.data.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getMealsTags = createAsyncThunk(
  "Tags/FatchMealsTags",
  async () => {
    try {
      const { data } = await api.get(`tags/get_tags_by_type?tag_type=MEAL`);
      return data.data;
    } catch (error) {
      throw error;
    }
  }
);

export const deleteMealThunk = createAsyncThunk(
  "meals/delete_meal",
  async ({ mealId }: any, { rejectWithValue }) => {
    try {
      const response = await api.post("/meals/delete_meal", {
        meal_id: mealId,
      });

      if (response.data.status === "success") {
        return response.data;
      } else {
        return rejectWithValue("Failed to delete meal");
      }
    } catch (error) {
      return rejectWithValue("An error occurred while deleting the meal");
    }
  }
);

export const getMealsByFilterThunk = createAsyncThunk(
  "meals/fetchMealsByFilter",
  async ({
    filterColumn,
    filterValue,
    searchVal,
    sortColumn,
    sortType,
  }: {
    filterColumn: string;
    filterValue: string;
    searchVal: string;
    sortColumn: string;
    sortType: string | any;
  }) => {
    try {
      const encodeFilterVal = encodeURIComponent(filterValue);
      const encodeSearchVal = encodeURIComponent(searchVal);
      const { data } = await api.get(
        `/meals?paginate=true&page=1&sort_column=${sortColumn}&sort_type=${sortType}&filter_column=${filterColumn}&limit=10000&filter_value=${encodeFilterVal}&search=${encodeSearchVal}`
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
);

export const createMeal = createAsyncThunk(
  "meals/create_meal",
  async (mealInfo: any, { rejectWithValue }) => {
    // // console.log(mealId);
    try {
      const response = await api.post("/meals/create_meal", mealInfo);

      if (response.data.status === "success") {
        return response.data;
      } else {
        // // console.log(
        //   "else error=================================",
        //   JSON.stringify(response)
        // );

        return rejectWithValue("Failed to create meal");
      }
    } catch (error: any) {
      // // console.log("error=================>", JSON.stringify(error));
      return rejectWithValue(error.response.data);
    }
  }
);

const mealBuilder = createSlice({
  name: "mealbuilder",
  initialState,
  reducers: {
    setopenMealBuilderForm: (state, action) => {
      state.openMealBuilderForm = action.payload;
    },
    setsearchValueMealBuilder: (state, action) => {
      state.searchValueMeal = action.payload;
    },
    setConfirmDeleteMeal: (state, action) => {
      const { flag, id } = action.payload;
      state.hasDeleteMeal = flag;
      state.idOfMealToDelete = id;
    },
    resetConfirmDeleteMeal: (state) => {
      state.hasDeleteMeal = false;
      state.idOfMealToDelete = null;
    },

    setSelectedMealDataObject: (state, action) => {
      state.selectedDataObject = action.payload;
    },
    // FILTER DROPDOWN REDUCERS MEALS

    setsearchValueMeals: (state, action) => {
      state.searchValueMeals = action.payload;
    },
    resetsearchValueMeals: (state, action) => {
      state.searchValueMeals = null;
    },
    setselectedDropdownItemMeals: (state, action) => {
      state.selectedDropdownItemMeals = action.payload;
    },
    resetselectedDropdownItemMeals: (state) => {
      state.selectedDropdownItemMeals = null;
    },
    setselectedItemIndexFilterMeals: (state, action) => {
      state.selectedItemIndexFilterMeals = action.payload;
    },
    resetselectedItemIndexFilterMeals: (state) => {
      state.selectedItemIndexFilterMeals = null;
    },
    setselectedItemIndexSortMeals: (state, action) => {
      state.selectedItemIndexSortMeals = action.payload;
    },
    resetselectedItemIndexSortMeals: (state) => {
      state.selectedItemIndexSortMeals = null;
    },
    setsortbyMeals: (state, action) => {
      state.sortbyMeals = action.payload;
    },
    resetsortbyMeals: (state) => {
      state.sortbyMeals = "";
    },

    setselectedFilterItemsMeals: (state, action) => {
      state.selectedFilterItems = action.payload;
    },
    resetselectedFilterItemsMeals: (state) => {
      state.selectedFilterItems = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMealBuilder.pending, (state) => {
        state.loading === true;
      })
      .addCase(getMealBuilder.fulfilled, (state, action) => {
        state.loading = false;
        state.MealsBuilderData = action.payload;
        state.persistentMealsData = action.payload;
      })
      .addCase(getMealBuilder.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getUniqueTagsMealsThunk.pending, (state) => {
        state.loading === true;
      })
      .addCase(getUniqueTagsMealsThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.uniqueTags = action.payload;
      })
      .addCase(getUniqueTagsMealsThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(getMealsByFilterThunk.pending, (state) => {
        state.loading === true;
      })
      .addCase(getMealsByFilterThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.MealsBuilderData = action.payload;
      })
      .addCase(getMealsByFilterThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // .addCase(getMealsTags.pending, (state) => {
      //   state.loading === true;
      // })
      // .addCase(getMealsTags.fulfilled, (state, action) => {
      //   state.loading = false;
      //   state.MealsTags = action.payload;
      // })
      // .addCase(getMealsTags.rejected, (state, action) => {
      //   state.loading = false;
      //   state.error = action.error.message;
      // })

      .addCase(getMealsTags.pending, (state) => {
        state.loading === true;
      })
      .addCase(getMealsTags.fulfilled, (state, action) => {
        state.loading = false;
        state.MealsTags = action.payload;
      })
      .addCase(getMealsTags.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(createMeal.pending, (state) => {
        state.loading === true;
      })
      .addCase(createMeal.fulfilled, (state, action) => {
        state.loading = false;
        state.MealsTags = action.payload;
      })
      .addCase(createMeal.rejected, (state, action: any) => {
        if (action?.payload?.data == "Meal is already existing") {
          toast.error(action?.payload?.data);
        }
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const {
  setopenMealBuilderForm,
  setsearchValueMealBuilder,
  setConfirmDeleteMeal,
  resetConfirmDeleteMeal,

  setSelectedMealDataObject,
  resetsearchValueMeals,
  setsearchValueMeals,
  setselectedDropdownItemMeals,
  resetselectedDropdownItemMeals,
  setselectedItemIndexFilterMeals,
  resetselectedItemIndexFilterMeals,
  setselectedItemIndexSortMeals,
  resetselectedItemIndexSortMeals,
  setsortbyMeals,
  resetsortbyMeals,
  setselectedFilterItemsMeals,
  resetselectedFilterItemsMeals,
} = mealBuilder.actions;
export default mealBuilder.reducer;
