import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../../services/api";

const initialState: any = {
  getProspectsAssociationNames: [],
  isAssessmentFormOpenContacts: false,
  openFormByClick: false,
};

const documentContactSlice = createSlice({
  name: "contactsSlice",
  initialState,
  reducers: {
    setIsAssessmentFormOpenContacts: (state) => {
      state.isAssessmentFormOpenContacts = true;
    },
    resetIsAssessmentFormOpenContacts: (state) => {
      state.isAssessmentFormOpenContacts = false;
    },
    setopenFormByClickContacts: (state) => {
      state.openFormByClick = true;
    },
    resetopenFormByClickContacts: (state) => {
      state.openFormByClick = false;
    },
  },
  extraReducers: (builder) => {
  },
});

export const {
  setIsAssessmentFormOpenContacts,
  resetIsAssessmentFormOpenContacts,
  setopenFormByClickContacts,
  resetopenFormByClickContacts,
} = documentContactSlice.actions;
export default documentContactSlice.reducer;
