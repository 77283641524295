import { DragHandleIcon } from "@chakra-ui/icons";
import { calculateWidthInPixels } from "../Helpers/timeDifferenceInMinutes";
import { useEffect, useState, useRef } from "react";
import { PlusSVG } from "../Data/PlusSVG";
import  StaffSVG  from "../Data/StaffSVG";

const RosterCapsule = ({
  handleDragStart,
  rosterItemIndex,
  matchingItem,
  minute,
  initialXRefLeft,
  handleDecrementStartime,
  handleIncrementStartTime,
  initialXRefRight,
  handleDecrementEndTime,
  handleIncrementEndTime,
  onClick,
  onDoubleClick
}: any) => {
  const [isHoveringLeft, setisHoveringLeft] = useState(false);
  const [isHoveringRight, setisHoveringRight] = useState(false);

  useEffect(() => {
    const handleMouseMoveLeft = (e: MouseEvent) => {
      if (initialXRefLeft.current !== null && isHoveringLeft) {
        const deltaX = e.clientX - initialXRefLeft.current;
        // console.log(deltaX);

        if (deltaX !== 0) {
          if (deltaX <= 0) {
            handleDecrementStartime(rosterItemIndex, matchingItem.type, deltaX);
          } else {
            handleIncrementStartTime(
              rosterItemIndex,
              matchingItem.type,
              deltaX
            );
          }
          initialXRefLeft.current = e.clientX;
        }
      }
    };

    const handleMouseUpLeft = () => {
      initialXRefLeft.current = null;
      setisHoveringLeft(false);
    };

    document.addEventListener("mousemove", handleMouseMoveLeft);
    document.addEventListener("mouseup", handleMouseUpLeft);

    return () => {
      document.removeEventListener("mousemove", handleMouseMoveLeft);
      document.removeEventListener("mouseup", handleMouseUpLeft);
    };
  }, [
    isHoveringLeft,
    handleDecrementStartime,
    handleIncrementStartTime,
    rosterItemIndex,
    matchingItem.type,
    initialXRefLeft,
  ]);

  useEffect(() => {
    const handleMouseMoveRight = (e: MouseEvent) => {
      if (initialXRefRight.current !== null && isHoveringRight) {
        const deltaX = e.clientX - initialXRefRight.current;
        if (deltaX !== 0) {
          if (deltaX <= 0) {
            handleDecrementEndTime(rosterItemIndex, matchingItem.type, deltaX);
          } else {
            handleIncrementEndTime(rosterItemIndex, matchingItem.type, deltaX);
          }
          initialXRefRight.current = e.clientX;
        }
      }
    };

    const handleMouseUpRight = () => {
      initialXRefRight.current = null;
      setisHoveringRight(false);
    };

    document.addEventListener("mousemove", handleMouseMoveRight);
    document.addEventListener("mouseup", handleMouseUpRight);

    return () => {
      document.removeEventListener("mousemove", handleMouseMoveRight);
      document.removeEventListener("mouseup", handleMouseUpRight);
    };
  }, [
    isHoveringRight,
    handleDecrementEndTime,
    handleIncrementEndTime,
    rosterItemIndex,
    matchingItem.type,
    initialXRefRight,
  ]);

  return (
    <>
      <div
        onClick={onClick}
        onDoubleClick={onDoubleClick}
        draggable
        onDragStart={(event) => {
          handleDragStart(event, rosterItemIndex, matchingItem);
        }}
        key={`${matchingItem.start_time}-${matchingItem.end_time}`}
        className="current_roster_item_type"
        style={{
          marginLeft: `${(minute / 60) * 100}px`,
          width:
            matchingItem?.start_time && matchingItem?.end_time
              ? calculateWidthInPixels(
                  matchingItem?.start_time,
                  matchingItem?.end_time
                )
              : "100px",
        }}
      >
        <div className="plus_event_wrapper">
          <PlusSVG />
          <span className="text_service"> {matchingItem.type}</span>
        </div>

        <div className="staff_img_capsule">
          <StaffSVG />
        </div>

        <div
          onMouseDown={(e) => {
            setisHoveringLeft(true);
            initialXRefLeft.current = e.clientX;
          }}
        >
          {isHoveringLeft && (
            <span className="current_time_left">{matchingItem.start_time}</span>
          )}
          <DragHandleIcon
            position="absolute"
            left="-10px"
            top="6px"
            className="drag_handle_left_icon"
            cursor="grab"
            color="blackAlpha.300"
            onMouseEnter={() => setisHoveringLeft(true)}
            onMouseLeave={() => setisHoveringLeft(false)}
          />
        </div>
        <div
          onMouseDown={(e) => {
            setisHoveringRight(true);
            initialXRefRight.current = e.clientX;
          }}
        >
          <DragHandleIcon
            position="absolute"
            right="-10px"
            top="6px"
            className="drag_handle_right_icon"
            cursor="grab"
            color="blackAlpha.300"
            onMouseEnter={() => setisHoveringRight(true)}
            onMouseLeave={() => setisHoveringRight(false)}
          />
          {isHoveringRight && (
            <span className="current_time_right">{matchingItem.end_time}</span>
          )}
        </div>
      </div>
    </>
  );
};

export default RosterCapsule;
