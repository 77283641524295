import React from "react";

interface ICopySVG {
  width?: number;
  height?: number;
  color?: string;
}

const CopySVG = ({ width = 10, height = 10 ,color}: ICopySVG) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width={width}
      height={height}
      viewBox="0 0 128 128"
    >
      <g transform="translate(0 -924.36)">
        <g fill="#333" transform="translate(-101.01 570.97) scale(.99829)">
          <path
            fill={color || "#06a5ff"}
            d="M134.94 354c-3.324 0-6 2.676-6 6v96c0 3.324 2.676 6 6 6h82c3.324 0 6-2.676 6-6v-62h-40c-3.324 0-6-2.676-6-6v-34h-42z"
          ></path>
          <path d="M113.66 374.22c-3.324 0-6 2.676-6 6v96c0 3.324 2.676 6 6 6h82c3.324 0 6-2.676 6-6v-7h-73c-3.324 0-6-2.676-6-6v-89h-9z"></path>
          <path d="M180.94 354v30c0 3.324 2.676 6 6 6h36z"></path>
        </g>
      </g>
    </svg>
  );
};

export default CopySVG;
