import React, { Fragment, useEffect, useRef, useState } from "react";
import { dummyArrStaff } from "../../../ClientPage/Components/UniqueClientScreen/Data/ClientScreenContentArr";
import {
  formatDate,
  formatDateForAPI,
  formatDateFromAPI,
} from "../../../../../../Model/utils/Helper/formatDate";
import {
  clearCurrentStaffData,
  getProviderStaffByIdThunk,
  getStaffByIdThunk,
  getUserRolesStaffThunk,
  updateStaffThunk,
} from "../../../../../../Controller/redux/features/StaffFeature/staffSlice";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../Controller/redux/Hooks";
import { useNavigate, useParams } from "react-router-dom";

import CommonHeader from "../../../../../components/CommonHeader/CommonHeader";
import DropdownIcon from "../../../../../../Model/Assets/png/drop.png";
import LeftBox from "../../../../../components/UpperBox/Components/LeftBox";
import ReactDatePicker from "react-datepicker";
import UnderWork from "../../../../../components/UnderWork/UnderWork";
import UpperBox from "../../../../../components/UpperBox/UpperBox";
import CommonUpperBox from "../../../../../components/UpperBox/CommonHeader";
import { toast } from "react-toastify";
import CommonTable from "../../../../../components/CommonTable/InputTable";
import ConfirmationModal from "../../../../../components/ConfirmationModal/ConfirmationModal";
import UnsavedChangesPopup from "../../../../../components/UnsavedChangesPopup/UnsavedChangesPopup";
import ClientAssessmentForm from "../../../ClientPage/Components/UniqueClientScreen/Components/DocumentsTab/Components/ClientAssessmentForm";
import { Modal, ModalOverlay, Spinner } from "@chakra-ui/react";
import {
  resetIsAssessmentFormOpenStaff,
  resetOpenFormByClickStaff,
  setIsAssessmentFormOpenStaff,
} from "../../../../../../Controller/redux/features/StaffFeature/documentStaffSlice";
import { api } from "../../../../../../Controller/services/api";
import {
  createDocumentThunk,
  getDocuments,
  getDocumentsByFilter,
  getUniquePrimaryApproversThunk,
  resetIsDocumentsDropdownOpen,
  resetSelectedDropdownItemDocuments,
  resetSelectedFilterItemsDocuments,
  resetSelectedItemIndexFilterDocuments,
  resetinputSearchVal,
  setIsFormOpenTrue,
  setWarningPopup,
  setselectedDataObject,
  updateDocumentThunk,
} from "../../../../../../Controller/redux/features/CommonFeature/documentSlice";
import {
  getAssociations,
  resetSelectedDropdownItemAssociations,
  resetSelectedFilterItemsAssociations,
  resetSelectedItemIndexFilterAssociations,
  resetselectedItemIndexAssociations,
  resetselectedNames,
  setWarningPopupAssociation,
} from "../../../../../../Controller/redux/features/CommonFeature/associationSlice";
import {
  getAssociationRelationshipsThunk,
  getAssociationTypesThunk,
} from "../../../../../../Controller/redux/features/CommonFeature/associationSlice";
import {
  getAllTagsThunk,
  getEntityTypesThunk,
  getProfileDetailsThunk,
  getStatusDetailsThunk,
  getUserTypesThunk,
} from "../../../../../../Controller/redux/features/CommonFeature/commonSlice";
import Daybook from "../../../../Daybook/Daybook";
import MenuAssignment from "../../../../Meals/MenuAssignment/MenuAssignment";
import { Comms } from "../../../../Comms/Comms";
import CircularNoteTrigger from "../../../../../components/CircularNoteTrigger/CircularNoteTrigger";
import CommonQuickMenu from "../../../../../components/CommonQuickMenu/CommonQuickMenu";
import ProfilePictureModal from "../../../../../components/ProfilePictureModal/ProfilePictureModal";
import Table from "../../../../../components/CommonTable/Table";
import { documentsColumns } from "../../../../../components/CommonTable/Data/AssociationHeaderDataArr";
import {
  setshowUnsavedModal,
  setunsavedChanges,
} from "../../../../../../Controller/redux/features/AuthFeature/authSlice";
import { setWarningMessage } from "../../../../../../Controller/redux/features/ClientFeature/documentClientSlice";
import UpdateClientDetails from "../../../ClientPage/Components/UniqueClientScreen/UpdateClientDetails";
import UpdateProfileDetails from "../../../../Account/User/UpdateProfileDetails";
import CustomDatePicker from "../../../../../components/DatePicker/CustomDatePicker";
import {getCustomFormsThunk} from "../../../../../../Controller/redux/features/CustomFromFeature/customFormSlice";

const StaffScreen = ({ screenType }: any) => {
  const dispatch = useAppDispatch();

  const { id, pro_id }: any = useParams();
  const [clientId, setclientId] = useState("");
  const [providerId, setProviderId] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (id) {
      let realId = window.atob(id);
      setclientId(realId);
      if (screenType) {
        setProviderId(window?.atob(pro_id));
      }
    }
  }, [id, screenType, pro_id]);
  /////////////////////////////////////

  // const mapAndDispatch = (elem: any) => {
  //   for (const item of elem) {
  //     const lowerDesc = item.desc.toLowerCase();
  //     if (lowerDesc.includes("staff")) {
  //       dispatch(setUserTypeIdForStaff(item));
  //     } else if (lowerDesc.includes("contact")) {
  //       dispatch(setUserTypeIdForContact(item));
  //     } else if (lowerDesc.includes("company admin")) {
  //     } else if (lowerDesc.includes("prospect")) {
  //       dispatch(setUserTypeIdForProspects(item));
  //     } else if (lowerDesc.includes("client")) {
  //       dispatch(setUserTypeIdForClient(item));
  //     }
  //   }
  // };

  // const userTypesFromRedux = useAppSelector(
  //   (state) => state.common.getUserTypes.data
  // );

  // useEffect(() => {
  //   dispatch(getUserTypesThunk());
  // }, []);

  // useEffect(() => {
  //   if (userTypesFromRedux) {
  //     mapAndDispatch(userTypesFromRedux);
  //   }
  // }, [userTypesFromRedux]);

  ///////////////////////////////////////

  // const currentStaffType = useAppSelector(
  //   (state: any) => state.staff.getUserTypeIdForStaff
  // );
  // @ts-ignore
  let dynamicStaffId = parseInt(localStorage.getItem("dynamicStaffId"));
  const getCurrentStaff = useAppSelector(
    (state: any) => state.staff.getUniqueStaff
  );
  // useEffect(() => {
  //   if (clientId) {
  //     dispatch(clearCurrentStaffData());
  //     dispatch(getStaffByIdThunk({ typeId: dynamicStaffId, clientId }));
  //     dispatch(getUserRolesStaffThunk({ dynamicStaffId: dynamicStaffId }));
  //   }
  // }, [clientId]);

  const [openForm, setOpenForm] = useState(false);
  const [typeToOpenForm, setTypeToOpenForm] = useState("");
  const [typeCheckForAssessmentForm, settypeCheckForAssessmentForm] =
    useState("");
  const [uploadedFiles, setUploadedFiles] = useState<
    {
      file: File;
      key: string;
      file_name?: string;
      actual_file_name?: string;
    }[]
  >([]);
  const [changesMadeToTheForm, setChangesMadeToTheForm] = useState(false);
  const [documentDetails, setDocumentDetails] = useState<any>();

  const { hasDeleteDocument, warningPopup } = useAppSelector(
    (state) => state?.document
  );
  const { hasDeleteAssociation, warningPopupAssociation } = useAppSelector(
    (state) => state?.associations
  );

  const handleGetDetails = (elem: any) => {
    setDocumentDetails({ ...elem, tenant_id: providerId });
  };

  const { openFormStaff } = useAppSelector((state) => state?.documentStaff);
  let isPending = false;
  const handleCloseModal = async () => {
    if (documentDetails.title || documentDetails.summary) {
      if (isPending) return;
      try {
        isPending = true;

        dispatch(createDocumentThunk(documentDetails))
          .then(() => {
            isPending = false;

            toast.success("Document Created Successfully!", {
              style: {},
              progressStyle: { backgroundColor: dynamicColor },
              icon: (
                <span
                  style={{
                    marginRight: "8px",
                    borderRadius: "50%",
                    width: "7rem",
                    height: "35px",
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: dynamicColor,
                    backgroundColor: "white",
                    fontSize: "1.5rem",
                    border: `3px solid ${dynamicColor}`,
                  }}
                >
                  ✓
                </span>
              ),
            });
            dispatch(getUniquePrimaryApproversThunk(clientId));
          })
          .catch(() => {
            isPending = false;

            toast.error("Failed To Create Document. Please Try Again!");
          });
        dispatch(getDocuments({ clientId, providerId }));
        dispatch(resetIsAssessmentFormOpenStaff());
        dispatch(resetOpenFormByClickStaff());
      } catch {
        dispatch(getDocuments({ clientId, providerId }));
        dispatch(resetIsAssessmentFormOpenStaff());

        dispatch(resetOpenFormByClickStaff());
      } finally {
        dispatch(resetIsAssessmentFormOpenStaff());
        dispatch(resetOpenFormByClickStaff());
        dispatch(getDocuments({ clientId, providerId }));
        dispatch(resetIsAssessmentFormOpenStaff());
      }
    } else {
      dispatch(resetIsAssessmentFormOpenStaff());
      dispatch(resetOpenFormByClickStaff());
      dispatch(getDocuments({ clientId, providerId }));
    }
  };

  const handleOverlayClick = async (isFormSave: any) => {
    let duplicateDetailsInfo = { ...documentDetails, user_id: "" };
    if (documentDetails?.title == "") {
      const hasValueInOtherFields = Object.keys(duplicateDetailsInfo);
      let nonEmptyFieldCount = 0;

      let ishereAnyFieldsHaveVaule = false;
      hasValueInOtherFields.map((res) => {
        // if (documentDetails[res]) {
        if (!ishereAnyFieldsHaveVaule) {
          if (typeof duplicateDetailsInfo[res] === "string") {
            if (!!duplicateDetailsInfo[res]) {
              ishereAnyFieldsHaveVaule = true;
              !isFormSave
                ? dispatch(setWarningMessage(true))
                : toast.warning("Title is mandatory");
              return;
            }
          } else if (typeof duplicateDetailsInfo[res] === "object") {
            if (!!duplicateDetailsInfo[res]?.length) {
              if (!!duplicateDetailsInfo[res]) {
                ishereAnyFieldsHaveVaule = true;
                // toast.warning("Title is mandatory");
                !isFormSave
                  ? dispatch(setWarningMessage(true))
                  : toast.warning("Title is mandatory");
                return;
              }
            }
          }
        }
      });

      if (ishereAnyFieldsHaveVaule) {
        return;
      }
    }

    if (documentDetails?.title || documentDetails?.summary) {
      if (isPending) return;
      try {
        isPending = true;

        dispatch(createDocumentThunk(documentDetails))
          .then(() => {
            isPending = false;

            toast.success("Document Created Successfully!", {
              progressStyle: { backgroundColor: dynamicColor },
              icon: (
                <span
                  style={{
                    marginRight: "8px",
                    borderRadius: "50%",
                    width: "7rem",
                    height: "35px",
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: dynamicColor,
                    backgroundColor: "white",
                    fontSize: "1.5rem",
                    border: `3px solid ${dynamicColor}`,
                  }}
                >
                  ✓
                </span>
              ),
            });
            dispatch(getUniquePrimaryApproversThunk(clientId));
          })
          .catch(() => {
            isPending = false;

            toast.error("Failed To Create Document. Please Try Again!");
          });
        dispatch(getDocuments({ clientId, providerId }));
        dispatch(resetIsAssessmentFormOpenStaff());
        dispatch(resetOpenFormByClickStaff());
      } catch {
        dispatch(getDocuments({ clientId, providerId }));
        dispatch(resetIsAssessmentFormOpenStaff());
        dispatch(resetOpenFormByClickStaff());
      } finally {
        dispatch(getDocuments({ clientId, providerId }));
        dispatch(resetIsAssessmentFormOpenStaff());
        dispatch(resetOpenFormByClickStaff());
      }
    } else {
      dispatch(resetIsAssessmentFormOpenStaff());
      dispatch(resetOpenFormByClickStaff());

      dispatch(getDocuments({ clientId, providerId }));
    }
  };

  const initialDate = getCurrentStaff?.data?.data[0]?.date_of_birth;
  useEffect(() => {
    if (initialDate) {
      const rearrangedDateString = formatDateFromAPI(initialDate);
      setSelectedDate(new Date(rearrangedDateString));
    } else {
      setSelectedDate(null);
    }
  }, [initialDate]);

  // const currentStaff = getCurrentStaff?.data?.data[0];
  const [currentStaff, setCurrentStaff] = useState<any>("");

  const getUserRoles = useAppSelector((state) => state?.staff?.staffUserRoles);

  const [userRoles, setUserRoles] = useState([]);

  // FETCH USER ROLES
  useEffect(() => {
    if (getUserRoles?.data && getUserRoles?.data?.length > 0) {
      setUserRoles(getUserRoles?.data);
    }
  }, [getUserRoles]);

  useEffect(() => {
    if (clientId) {
      dispatch(clearCurrentStaffData());
      if (screenType) {
        if (providerId && clientId && dynamicStaffId) {
          dispatch(
            getProviderStaffByIdThunk({
              typeId: dynamicStaffId,
              clientId,
              tenant_id: providerId,
            })
          )
            .then(() => {
              setLoading(false);
            })
            .catch((error: any) => {
              // console.log(error);
              setLoading(false);
            });
        }
      } else {
        dispatch(getStaffByIdThunk({ typeId: dynamicStaffId, clientId }))
          .then(() => {
            setLoading(false);
          })
          .catch((error: any) => {
            // console.log(error);
            setLoading(false);
          });
      }
      dispatch(getUserRolesStaffThunk({ dynamicStaffId: dynamicStaffId }));
    }
  }, [clientId, dynamicStaffId, providerId]);

  useEffect(() => {
    if (userRoles) {
      //Commenting below code to avoid the flash of details on provider staff screen
      // if (clientId) {
      //   dispatch(getStaffByIdThunk({ typeId: dynamicStaffId, clientId }));
      // }
      dispatch(getUniquePrimaryApproversThunk(clientId));
    }
  }, [clientId]);

  useEffect(() => {
    if (getCurrentStaff?.data?.data.length > 0) {
      setCurrentStaff(getCurrentStaff?.data?.data[0]);
    } else {
      setCurrentStaff(null);
    }
  }, [getCurrentStaff, clientId]);

  const { routeString, showUnsavedModal } = useAppSelector(
    (state) => state.auth
  );

  const dynamicColor = useAppSelector((state) => state?.common?.dynamicColor);

  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const navigate = useNavigate();

  const [selectedDate, setSelectedDate] = useState<Date | null | any>();

  const handleMoveWithoutSaving = () => {
    dispatch(setshowUnsavedModal(false));
    // dispatch(setunsavedChanges(false));
    navigate(routeString);
  };

  const [buttonTab, setbuttonTab] = useState({
    Details: true,
    Associations: false,
    Clinical: false,
    Comms: false,
    Documents: false,
    Finance: false,
    Daybook: false,
    Meals: false,
    Availability: false,
  });

  const [details, setDetails] = useState<any>({
    givenName: "",
    middleName: "",
    familyName: "",
    knownAs: "",
    address: "",
    dob: "",
    email: "",
    mobile: "",
    role: "",
    fundingType: "",
  });

  useEffect(() => {
    if (clientId) {
      dispatch(getDocuments({ clientId, providerId }));
      dispatch(getUniquePrimaryApproversThunk(clientId));
    }
  }, [clientId]);

  useEffect(() => {
    setHasUnsavedChanges(false);
  }, []);

  const handleSaveChanges = async () => {
    setHasUnsavedChanges(false);
    dispatch(setunsavedChanges(false));
    try {
      let parms = updateStaff;
      if (screenType) {
        parms.tenant_id = providerId;
      }
      const res = await dispatch(updateStaffThunk(parms));
      dispatch(getStaffByIdThunk({ typeId: dynamicStaffId, clientId }));
      if (res.payload.status === "success") {
        toast.success("Staff updated successfully!", {
          style: {},
          progressStyle: { backgroundColor: dynamicColor },
          icon: (
            <span
              style={{
                marginRight: "8px",
                borderRadius: "50%",
                width: "7rem",
                height: "35px",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: dynamicColor,
                backgroundColor: "white",
                fontSize: "1.5rem",
                border: `3px solid ${dynamicColor}`,
              }}
            >
              ✓
            </span>
          ),
        });
        dispatch(getProfileDetailsThunk());
      } else {
        toast.error("An Error Occurred");
      }
    } catch (error) {
      console.error(error);
      toast.error("An Error Occurred");
    }
    if (screenType) {
      dispatch(
        getProviderStaffByIdThunk({
          typeId: dynamicStaffId,
          clientId,
          tenant_id: providerId,
        })
      )
        .then(() => {
          setLoading(false);
        })
        .catch((error: any) => {
          // console.log(error);
          setLoading(false);
        });
    } else {
      dispatch(getStaffByIdThunk({ typeId: dynamicStaffId, clientId }))
        .then(() => {
          setLoading(false);
        })
        .catch((error: any) => {
          // console.log(error);
          setLoading(false);
        });
    }
  };

  async function getUserById() {
    await dispatch(getStaffByIdThunk({ typeId: dynamicStaffId, clientId }));
  }
  const handleMoveWithSave = async () => {
    dispatch(setshowUnsavedModal(false));
    dispatch(setunsavedChanges(false));

    navigate(routeString);
    try {
      let parms = updateStaff;
      if (screenType) {
        parms.tenant_id = providerId;
      }
      const res = await dispatch(updateStaffThunk(parms));
      dispatch(getStaffByIdThunk({ typeId: dynamicStaffId, clientId }));
      if (res.payload.status === "success") {
        toast.success("Staff updated successfully", {
          style: {},
          progressStyle: { backgroundColor: dynamicColor },
          icon: (
            <span
              style={{
                marginRight: "8px",
                borderRadius: "50%",
                width: "7rem",
                height: "35px",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: dynamicColor,
                backgroundColor: "white",
                fontSize: "1.5rem",
                border: `3px solid ${dynamicColor}`,
              }}
            >
              ✓
            </span>
          ),
        });
      } else {
        toast.error("An Error Occurred");
      }
    } catch (error) {
      console.error(error);
      toast.error("An Error Occurred");
    }
    if (screenType) {
      dispatch(
        getProviderStaffByIdThunk({
          typeId: dynamicStaffId,
          clientId,
          tenant_id: providerId,
        })
      )
        .then(() => {
          setLoading(false);
        })
        .catch((error: any) => {
          // console.log(error);
          setLoading(false);
        });
    } else {
      dispatch(getStaffByIdThunk({ typeId: dynamicStaffId, clientId }))
        .then(() => {
          setLoading(false);
        })
        .catch((error: any) => {
          // console.log(error);
          setLoading(false);
        });
    }
  };

  // RETAIN THE STATE
  useEffect(() => {
    if (localStorage.getItem("activeTab") === "Documents") {
      handleClick("Documents");
    } else if (localStorage.getItem("activeTab") === "Associations") {
      handleClick("Associations");
    } else if (localStorage.getItem("activeTab") === "Meals") {
      handleClick("Meals");
    } else if (localStorage.getItem("activeTab") === "Comms") {
      handleClick("Comms");
    } else if (localStorage.getItem("activeTab") === "Clinical") {
      handleClick("Clinical");
    } else if (localStorage.getItem("activeTab") === "Finance") {
      handleClick("Finance");
    } else if (localStorage.getItem("activeTab") === "Daybook") {
      handleClick("Daybook");
    }
  }, []);

  const handleClick = (button: string) => {
    setstageTwoToGoalsModal(false);
    dispatch(resetselectedItemIndexAssociations(""));
    dispatch(resetselectedNames(""));
    dispatch(resetIsDocumentsDropdownOpen());
    dispatch(resetinputSearchVal(""));
    dispatch(resetSelectedDropdownItemAssociations());
    dispatch(resetSelectedItemIndexFilterAssociations());
    dispatch(resetSelectedFilterItemsAssociations());
    dispatch(resetSelectedDropdownItemDocuments());
    dispatch(resetSelectedItemIndexFilterDocuments());
    dispatch(resetSelectedFilterItemsDocuments());

    const defaultTabState = {
      Details: false,
      Associations: false,
      Clinical: false,
      Comms: false,
      Documents: false,
      Finance: false,
      Daybook: false,
      Meals: false,
      Availability: false,
    };
    if (!hasDeleteDocument) {
      const newTabState = { ...defaultTabState, [button]: true };
      localStorage.setItem("activeTab", button);
      setbuttonTab(newTabState);
    } else {
      setbuttonTab({ ...defaultTabState, Documents: true });
      localStorage.setItem("activeTab", "Documents");
      dispatch(setWarningPopup(true));
    }
    setbuttonTab((prevState) => ({
      ...prevState,
      Details: button === "Details",
      Associations: button === "Associations",
      Clinical: button === "Clinical",
      Comms: button === "Comms",
      Documents: button === "Documents",
      Finance: button === "Finance",
      Daybook: button === "Daybook",
      Meals: button === "Meals",
    }));
    dispatch(resetIsAssessmentFormOpenStaff());
    dispatch(resetOpenFormByClickStaff());
    if (button === "Documents") {
      dispatch(setIsAssessmentFormOpenStaff());
    }
  };

  const getCurrentDate = () => {
    const currentDate = new Date();
    return currentDate;
  };

  const handleDateChange = (date: Date | any) => {
    if (!hasUnsavedChanges) {
      // setHasUnsavedChanges(true);
    }
    setSelectedDate(date);
  };

  // CHANGE TO INPUT FIELDS
  const handleChange = (
    index: number,
    e: React.ChangeEvent<HTMLInputElement> | any
  ) => {
    const { value } = e.target;

    const staffRole = getUserRoles?.data?.find(
      (val: any) => val?.id === +value
    );
    const fieldName = Object.keys(details)[index];

    setDetails((prevState: any) => ({
      ...prevState,
      [fieldName]: value,
    }));

    // // dispatch(setunsavedChanges(true));
    if (!hasUnsavedChanges) {
      setHasUnsavedChanges(true);
    }
  };

  useEffect(() => {
    const fetchedData: any = currentStaff;
    if (fetchedData) {
      setDetails({
        givenName: `${fetchedData.first_name}` || "",
        middleName: fetchedData.middle_name || "",
        familyName: fetchedData.last_name || "",
        knownAs: fetchedData.pref_name || "",
        address: fetchedData?.user_addresses[0]?.address || "",
        dob:
          fetchedData?.date_of_birth !== null
            ? formatDate(fetchedData.date_of_birth)
            : "",
        email: fetchedData?.email,
        mobile: fetchedData?.mobile ? fetchedData?.mobile : fetchedData?.phone,
        role: fetchedData ? fetchedData?.roles[0]?.id : "",
        fundingType: fetchedData?.funding_type || "",
      });
      const currentClientDOB = fetchedData?.date_of_birth;
      if (currentClientDOB !== null) {
        const rearrangedDateString = formatDateFromAPI(currentClientDOB);
        setSelectedDate(new Date(rearrangedDateString));
      } else {
        setSelectedDate(null);
      }
    } else {
      setDetails({
        givenName: "",
        middleName: "",
        familyName: "",
        knownAs: "",
        address: "",
        dateOfBirth: "",
        email: "",
        mobile: "",
        role: "",
        fundingType: "",
      });
    }
  }, [currentStaff]);

  const [updateStaff, setUpdateStaff] = useState<any>({
    first_name: "",
    middle_name: "",
    address: "",
    last_name: "",
    pref_name: "",
    email: "",
    mobile: "",
    phone: "",
    dob: "",
    funding_type: "",
    package_level: "",
    funding_available: "",
    acat_assessment: "",
    initial_enquiry: "",
    role: 4,
    user_id: clientId,
    type: "",
  });

  useEffect(() => {
    setUpdateStaff({
      first_name: details.givenName,
      middle_name: details.middleName,
      last_name: details.familyName,
      pref_name: details.knownAs,
      address: details.address,
      dob: selectedDate ? formatDateForAPI(selectedDate) : initialDate,
      mobile: details.mobile,
      package_level: "",
      funding_available: "",
      acat_assessment: "",
      initial_enquiry: "",
      email: details.email,
      phone: details.mobile ? details.mobile : details.phone,
      funding_type: details.fundingType,
      role: details?.role,
      user_id: clientId,
      type: dynamicStaffId,
    });
  }, [clientId, details, selectedDate, dynamicStaffId]);

  useEffect(() => {
    if (buttonTab.Associations) {
      dispatch(getAssociationTypesThunk());
      dispatch(getAssociationRelationshipsThunk());
      dispatch(getUserTypesThunk());
    }
  }, [buttonTab]);
  useEffect(() => {
    if (clientId) {
      dispatch(
        getAssociations({ clientId, searchVal: "", type: "", pageNumber: 1 })
      );
    }
  }, [clientId]);

  const [showProfile, setShowProfile] = useState<boolean>(false);
  const [showGoalsModal, setshowGoalsModal] = useState(false);
  const [stageTwoToGoalsModal, setstageTwoToGoalsModal] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState<any>("");
  const [possibleAddresses, setPossibleAddresses] = useState<any>([]);

  function initService(query: any) {
    const displaySuggestions = function (predictions: any, status: any) {
      // @ts-ignore
      if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
        // console.log("Location service down!");
        return;
      }
      if (!predictions) {
        setPossibleAddresses([]);
        return;
      }
      setPossibleAddresses(predictions);
    };
    // @ts-ignore

    // @ts-ignore
    const service = new window.google.maps.places.AutocompleteService();
    // console.log(query);
    service.getQueryPredictions({ input: query }, displaySuggestions);
  }

  const handleChangeForAddress = (index: number, e: any) => {
    // // dispatch(setunsavedChanges(true));
    if (!hasUnsavedChanges) {
      setHasUnsavedChanges(true);
    }
    const { value } = e.target;
    setDetails({ ...details, address: value });
    initService(value);
    if (value.length === 0) {
      setDetails({ ...details, address: "" });
      setPossibleAddresses([]);
    }
    // setDetails((prevState: any) => ({
    //   ...prevState,
    //   [fieldName]: value,
    // }));
  };

  const selectAddress = (e: any) => {
    if (!hasUnsavedChanges) {
      setHasUnsavedChanges(true);
    }
    setSelectedAddress(e.target.innerHTML);
    setDetails({ ...details, address: e.target.innerHTML });
    // dispatch(setunsavedChanges(true));
    setPossibleAddresses([]);
  };

  useEffect(() => {
    if (openFormStaff) {
      setbuttonTab({
        Details: false,
        Associations: false,
        Clinical: false,
        Comms: false,
        Documents: true,
        Finance: false,
        Daybook: false,
        Meals: false,
        Availability: false,
      });
    }
  }, [openFormStaff]);
  /// ASSOCIATION TABLE START //
  const dropdownRefAssociation = useRef<any>();
  const svgParentRefAssociation = useRef<any>();
  const [isDropdownOpenAssociation, setIsDropdownOpenAssociation] =
    useState(false);
  const {
    selectedFilterItemsAssociations,
    sortByAssociationsR,
    searchValAssociations,
  } = useAppSelector((state) => state?.associations);

  const { filterIconColor } = useAppSelector((state) => state?.common);
  const handleClickOutsideAssociation = (event: any) => {
    if (
      dropdownRefAssociation.current &&
      !dropdownRefAssociation.current.contains(event.target) &&
      svgParentRefAssociation.current &&
      !svgParentRefAssociation.current.contains(event.target)
    ) {
      setIsDropdownOpenAssociation(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutsideAssociation);
    return () => {
      document.removeEventListener("click", handleClickOutsideAssociation);
    };
  }, []);
  const associationColumns = [
    { header: "Name", accessor: "user_name" },
    { header: "Category", accessor: "category" },
    { header: "Relationship", accessor: "relationship_name" },
    { header: "Contact Number", accessor: "number" },
    { header: "Associated Business/Site", accessor: "funding_type" },
    { header: "Action", accessor: "actionAssociation" },
  ];
  const { getAssociationByUserId } = useAppSelector(
    (state) => state?.associations
  );
  /// ASSOCIATION TABLE END //
  // DOCUMENTS TABLE START //
  const dropdownRefDocuments = useRef<any>();
  const svgParentRefDocuments = useRef<any>();
  const [isDropdownOpenDocuments, setIsDropdownOpenDocuments] = useState(false);
  const { selectedFilterItemsDocuments, sortByDocumentsR, searchValDoc } =
    useAppSelector((state) => state?.document);
  const handleClickOutsideDocuments = (event: any) => {
    if (
      dropdownRefDocuments.current &&
      !dropdownRefDocuments.current.contains(event.target) &&
      svgParentRefDocuments.current &&
      !svgParentRefDocuments.current.contains(event.target)
    ) {
      setIsDropdownOpenDocuments(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutsideDocuments);
    return () => {
      document.removeEventListener("click", handleClickOutsideDocuments);
    };
  }, []);

  const getDocumentsData = useAppSelector(
    (state) => state?.document?.getDocuments
  );
  const updatedDoc = useAppSelector(
    (state) => state?.document?.updateDocumentData
  );
  const selectedOps = useAppSelector(
    (state) => state?.document?.selectedOptions
  );
  const [isFormOpenDoc, setIsFormOpenDoc] = useState(false);
  const [selectedObject, setSelectedObject] = useState<any>(null);
  const [showStateOfDocument, setShowStateOfDocument] = useState<any>("");

  const [primaryApprover, setPrimaryApprover] = useState([]);
  const [ongoingApprover, setOngoingApprover] = useState([]);

  const handleClickDocRow = (e: any, id: any, title: any) => {
    e.stopPropagation();
    if (isDropdownOpenDocuments) {
      setIsDropdownOpenDocuments(false);
    } else {
      const selectedDocument = getDocumentsData?.find((elem: any) => {
        return elem?.id === id;
      });
      setIsFormOpenDoc(true);
      dispatch(setIsFormOpenTrue());
      setSelectedObject(selectedDocument);
      setPrimaryApprover(selectedDocument?.primary_approvers);
      setOngoingApprover(selectedDocument?.ongoing_approvers);
      dispatch(setselectedDataObject(selectedDocument));
      setUploadedFiles(selectedDocument?.files);
      setShowStateOfDocument(title);
    }
  };
  const handleOverlayClickUpdate = async () => {
    if (!selectedObject?.isThereAnyChanges) {
      setIsFormOpenDoc(false);
      return;
    }

    try {
      //dispatch(getDocuments( {  clientId, providerId }));

      /* */
      dispatch(
        updateDocumentThunk({
          title: updatedDoc?.title,
          summary: updatedDoc.summary,
          primary_review: updatedDoc?.primary_review,
          ongoing_review: updatedDoc.ongoing_review,
          period_picker: updatedDoc.period_picker,
          start: updatedDoc.start,
          end: updatedDoc.end,
          primary_approver_id: primaryApprover.map((res: any) => res?.id),
          ongoing_approver_id: ongoingApprover.map((res: any) => res?.id),
          hard_copy_completed_date: updatedDoc.hard_copy_completed_date,
          document_files: updatedDoc.document_files,
          user_id: updatedDoc.user_id,
          document_id: updatedDoc?.document_id,
          tag: updatedDoc?.tagArray,
          tenant_id: providerId,
          note_details: updatedDoc?.note_details || {},
        })
      )
        .then(() => {
          toast.success("Document updated successfully!", {
            style: {},
            progressStyle: { backgroundColor: dynamicColor },
            icon: (
              <span
                style={{
                  marginRight: "8px",
                  borderRadius: "50%",
                  width: "7rem",
                  height: "35px",
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: dynamicColor,
                  backgroundColor: "white",
                  fontSize: "1.5rem",
                  border: `3px solid ${dynamicColor}`,
                }}
              >
                ✓
              </span>
            ),
          });

          dispatch(getUniquePrimaryApproversThunk(clientId));
          dispatch(getDocuments({ clientId, providerId }));
          dispatch(
            getDocumentsByFilter({
              clientId,
              filterValue: selectedOps?.join(","),
              searchVal: "",
              sortType: "",
              filterColumn: "",
              sortColumn: "",
              providerId,
            })
          );
        })
        .catch(() =>
          toast.error("Failed To Update Document. Please Try Again!")
        );
    } catch (err) {}

    setchangesToForm("");
    setIsFormOpenDoc(false);
    dispatch(getUniquePrimaryApproversThunk(clientId));
  };
  const [changesToForm, setchangesToForm] = useState("");

  const handleChangesToForm = (val: any) => {
    setchangesToForm(val);
  };

  // DOCUMENTS TABLE END //
  const { domainDetail } = useAppSelector((state) => state?.common);
  const { user } = useAppSelector((state) => state?.common);
  const IsUserAdmin = user?.roles[0]?.desc.toLowerCase();
  const hasProvider = IsUserAdmin === "administrator";
  const [staffRole, setStaffRole] = useState<any>("");
  useEffect(() => {
    if (currentStaff?.roles?.length > 0) {
      setStaffRole(currentStaff?.roles[0]?.desc);
    }
  }, [currentStaff]);

  const [userTypes, setUserTypes] = useState<any>([]);
  useEffect(() => {
    if (domainDetail && domainDetail?.staff_type) {
      let spliteTheTypeString = domainDetail?.staff_type.split(",");
      setUserTypes(spliteTheTypeString);
    }
  }, [domainDetail]);

  ///////////////////////////////////////////////////////////////////////////////////////
  const staffStatusDetailsNums = useAppSelector(
    (state) => state.common.domainDetail.staff_status
  );
  useEffect(() => {
    dispatch(getStatusDetailsThunk(staffStatusDetailsNums));
  }, [staffStatusDetailsNums]);
  ///////////////////////////////////////////////////////////////////////////////////////

  const openWarningSection = useAppSelector(
    (state) => state?.documentClient?.openWarningSection
  );

  const [itIsLoggedInUser, setItIsLoggedInUser] = useState(false);

  useEffect(() => {
    if (user?.id && clientId && user?.id == clientId) {
      // console.log("clientId same hereererre");
      setItIsLoggedInUser(true);
    }
  }, [user, clientId]);


  const { clinicalCustomFormRecords, customFormList } = useAppSelector(
    (state) => state?.customForm
  );
    ////////// CUSTOM FORM OF DOCUMENTS  /////////////
    let customFormsWithTypeDocument = customFormList?.data?.filter(
      (elem: any) => elem.form_type === "Document" && elem?.is_repeating == 1
    );

  // @ts-ignore
  const userDetails = JSON.parse(localStorage.getItem("user"));
  const userId = userDetails?.data?.id;
    useEffect(() => {
      if (userId) {
        dispatch(getCustomFormsThunk(userId));
      }
    }, [userId]);


    let customFormsWithTypeClinical = customFormList?.data?.filter(
      (elem: any) => elem.form_type === "Clinical" && elem?.is_repeating == 1
    );
     /////////////////////////////////////////////////

     console.log('customFormList', customFormList);
  return (
    <div>
      {loading && <Spinner position={"absolute"} top={"50%"} left={"50%"} />}
      <div
        style={{
          position: "relative",
        }}
      >
        {stageTwoToGoalsModal && (
          <CommonQuickMenu
            setshowGoalsModal={setshowGoalsModal}
            showGoalsModal={showGoalsModal}
            setstageTwoToGoalsModal={setstageTwoToGoalsModal}
            type="staff"
            filterItems={true}
            inDocument={
              buttonTab.Documents
                ? customFormsWithTypeDocument
                : buttonTab.Clinical
                ? customFormsWithTypeClinical
                : []
            }
          />
        )}
        <CommonUpperBox
          imageSection
          type={"screen"}
          currentUser={currentStaff}
          imageURL={currentStaff?.profile_image}
          title={`${currentStaff?.first_name || ""} ${
            currentStaff?.last_name || ""
          }`}
          onClickUserImage={() => setShowProfile(true)}
          onClickPlus={() => {
            // if (buttonTab.Documents) {
            setstageTwoToGoalsModal(true);
            // }
          }}
        />
      </div>

      {showProfile && (
        <ProfilePictureModal
          showProfile={showProfile}
          className="profile_picture_external"
          setShowProfile={setShowProfile}
          currentClient={currentStaff}
        />
      )}

      {buttonTab.Details && (
        <>
          <CommonHeader
            mainType="staffScreen"
            type="details"
            handleClick={handleClick}
            currentStaff={currentStaff}
          />
          <div className="main-box">
            {hasUnsavedChanges && (
              <>
                <div
                  className="save"
                  // style={{
                  //   position: "absolute",
                  //   top: "95vh",
                  // }}
                  style={{
                    position: "absolute",
                    marginRight: "200px",
                    bottom: "10px",
                  }}
                >
                  <p>Would you like to save your changes?</p>
                  <div className="btnDivision">
                    <button
                      style={{
                        backgroundColor: dynamicColor,
                      }}
                      onClick={handleSaveChanges}
                    >
                      Save
                    </button>
                    <button
                      style={{
                        fontFamily: "Helvetica",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "18px",
                        backgroundColor: "#F9F9F9",
                        border: "none",
                        color: "#9296A2",
                      }}
                      onClick={() => {
                        getUserById();
                        setHasUnsavedChanges(false);
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </>
            )}
            {showUnsavedModal && (
              <div>
                <div className="showunsave-modal-navigate">
                  <span className="span1">
                    You have unsaved changes on this page.
                  </span>
                  <span className="span2">
                    Do you wish to move on and lose these changes?
                  </span>
                  <div className="btnDiv-modal">
                    <button
                      className="button1"
                      onClick={handleMoveWithoutSaving}
                    >
                      Move on without saving
                    </button>
                    <button className="button2" onClick={handleMoveWithSave}>
                      Save and move on
                    </button>
                  </div>
                </div>
              </div>
            )}
            <div
            //   style={{
            //     height: "100vh",
            //     position: "relative",
            //   }}
            ></div>
            {/* <div className="main-box main-box-associations-tab"></div> */}

            <div className="px-[10px]">
              <div className=" client-screen-personal-details-heading">
                <span className=" client-screen-sub-heading-title">
                  Personal Details
                </span>
                <span className=" client-screen-line"></span>
              </div>
              <div className="main-box-body">
                {dummyArrStaff.slice(0, 8).map((elem: any, index: any) => {
                  const fieldName = Object.keys(details)[index];
                  return (
                    <div
                      className={`content-wrapper ${
                        index === 5 && "staff_date_filter"
                      }`}
                      key={index}
                    >
                      <div className="wrapper-title">{elem.UpperTitle}</div>

                      {index === 5 ? (
                        // <ReactDatePicker
                        //   onChange={handleDateChange}
                        //   selected={selectedDate}
                        //   startDate={selectedDate}
                        //   placeholderText="Select Date"
                        //   className="date-picker-details-tab-of-client-screen"
                        //   showYearDropdown
                        //   showMonthDropdown
                        //   dropdownMode="select"
                        //   maxDate={getCurrentDate()}
                        //   dateFormat="EEE, dd MMMM yyyy"
                        // />
                        <CustomDatePicker
                          maxDate={getCurrentDate()}
                          setchanges={setHasUnsavedChanges}
                          handleChange={handleDateChange}
                          newDate={selectedDate}
                        />
                      ) : index === 9 ? (
                        <select
                          className="wrapper-info wrapper-info-select-dropdown"
                          onChange={(e) => handleChange(index, e)}
                          value={details[fieldName]}
                          style={{
                            border: "none",
                            outline: "none",
                            height: "fit-content",
                            backgroundColor: "white",
                            // width: "256px",
                          }}
                        >
                          <option value="">Please Select...</option>

                          <option value="Aged Care">Aged Care</option>
                          <option value="Disability">Disability</option>
                          <option value="Veterans">Veterans</option>
                          <option value="Private">Private</option>
                          <option value="Insurance">Insurance</option>
                          <option value="Other">Other</option>
                        </select>
                      ) : index === 8 ? (
                        <select
                          className="wrapper-info wrapper-info-select-dropdown"
                          onChange={(e) => handleChange(index, e)}
                          value={details[fieldName]}
                          style={{
                            border: "none",
                            outline: "none",
                            height: "fit-content",
                            backgroundColor: "white",
                            // width: "256px",
                          }}
                        >
                          {userRoles?.map((elem: any, index: number | any) => {
                            let isExist: any = userTypes?.includes(
                              `${elem?.desc}`
                            );
                            if (isExist) {
                              return (
                                <option key={index} value={elem?.id}>
                                  {elem?.desc}
                                </option>
                              );
                            }
                          })}
                        </select>
                      ) : index === 4 ? (
                        /*furkan location*/
                        <>
                          <div>
                            <input
                              type="text"
                              onChange={(e) => handleChangeForAddress(index, e)}
                              className="wrapper-info"
                              placeholder="Enter an address"
                              value={details[fieldName]}
                            />
                            <div className="address_listing">
                              <ul>
                                {possibleAddresses.length > 0
                                  ? possibleAddresses.map(
                                      (address: any, ind: any) => {
                                        return (
                                          <li
                                            onClick={selectAddress}
                                            className="address_listing_item"
                                          >
                                            {address.description}
                                          </li>
                                        );
                                      }
                                    )
                                  : null}
                              </ul>
                            </div>
                          </div>
                        </>
                      ) : (
                        <input
                          className="wrapper-info"
                          onChange={(e) => handleChange(index, e)}
                          value={details[fieldName]}
                          placeholder={elem?.placeHolder}
                          style={{
                            border: "none",
                            outline: "none",
                            height: "fit-content",
                          }}
                        />
                      )}

                      {elem.index === 5 && (
                        <div className="dropdown-icon">
                          <img src={DropdownIcon} alt="dropdown-icon" />
                        </div>
                      )}
                      <div className="wrapper-line"></div>
                    </div>
                  );
                })}
              </div>

              <div className=" client-screen-personal-details-heading">
                <span className="client-screen-sub-heading-title">
                  Profile Details
                </span>
                <span className=" client-screen-line"></span>
              </div>
              <div className="main-box-body">
                {dummyArrStaff.slice(8, 9).map((elem: any, index: any) => {
                  const indexTemp = index + 8;
                  const fieldName = Object.keys(details)[indexTemp];
                  return (
                    <div className="content-wrapper" key={indexTemp}>
                      <div className="wrapper-title">{elem.UpperTitle}</div>

                      {indexTemp === 5 ? (
                        // <ReactDatePicker
                        //   onChange={handleDateChange}
                        //   selected={selectedDate}
                        //   startDate={selectedDate}
                        //   placeholderText="Select Date"
                        //   className="date-picker-details-tab-of-client-screen"
                        //   showYearDropdown
                        //   showMonthDropdown
                        //   dropdownMode="select"
                        //   maxDate={getCurrentDate()}
                        //   dateFormat="EEE, dd MMMM yyyy"
                        // />
                        <CustomDatePicker
                          maxDate={getCurrentDate()}
                          handleChange={handleDateChange}
                          newDate={selectedDate}
                        />
                      ) : indexTemp === 9 ? (
                        <select
                          className="wrapper-info wrapper-info-select-dropdown"
                          onChange={(e) => handleChange(indexTemp, e)}
                          value={details[fieldName]}
                          style={{
                            border: "none",
                            outline: "none",
                            height: "fit-content",
                            backgroundColor: "white",
                            // width: "256px",
                          }}
                        >
                          <option value="">Please Select...</option>

                          <option value="Aged Care">Aged Care</option>
                          <option value="Disability">Disability</option>
                          <option value="Veterans">Veterans</option>
                          <option value="Private">Private</option>
                          <option value="Insurance">Insurance</option>
                          <option value="Other">Other</option>
                        </select>
                      ) : indexTemp === 8 ? (
                        // hasProvider ? (
                        <select
                          className="wrapper-info wrapper-info-select-dropdown"
                          onChange={(e) => handleChange(indexTemp, e)}
                          value={details[fieldName]}
                          style={{
                            border: "none",
                            outline: "none",
                            height: "fit-content",
                            backgroundColor: "white",
                            // width: "256px",
                          }}
                        >
                          <option>Please Select...</option>
                          {userRoles?.map((elem: any, index: number | any) => {
                            let isExist: any = userTypes?.includes(
                              `${elem?.desc}`
                            );
                            if (isExist) {
                              return (
                                <option key={index} value={elem?.id}>
                                  {elem?.desc}
                                </option>
                              );
                            }
                          })}
                        </select>
                      ) : (
                        <input
                          className="wrapper-info"
                          onChange={(e) => handleChange(indexTemp, e)}
                          value={details[fieldName]}
                          style={{
                            border: "none",
                            outline: "none",
                            height: "fit-content",
                          }}
                        />
                      )}

                      {elem.index === 5 && (
                        <div className="dropdown-icon">
                          <img src={DropdownIcon} alt="dropdown-icon" />
                        </div>
                      )}
                      <div className="wrapper-line"></div>
                    </div>
                  );
                })}
              </div>
            </div>
            {itIsLoggedInUser && <UpdateProfileDetails />}
          </div>

          {/* <Rectangle type="stickyHub" /> */}
          <CircularNoteTrigger type="stickyHub" />
        </>
      )}

      {buttonTab.Documents && (
        <>
          {/* <Header />
          <SideNavbar /> */}
          {/* <LeftBox type="contact-details" /> */}
          <Fragment>
            <div className={openForm ? "" : ""}>
              <CommonHeader
                mainType="staffScreen"
                type="documents"
                handleClick={handleClick}
                currentStaff={currentStaff}
              />
              {/* <CommonTable
                type="documents"
                // setOpenForm={setOpenForm}
                // openForm={openForm}
                currentStaff={currentStaff}
              /> */}
              <Table
                dynamicHeaderColor={domainDetail?.doctbl_color}
                thData={documentsColumns}
                classNameTr="client-documents-tab-table-head"
                classNameTable="documents-table-additional-styles"
                type="documents"
                dropdownRef={dropdownRefDocuments}
                isDropdownOpen={isDropdownOpenDocuments}
                onClickSvg={() => setIsDropdownOpenDocuments(true)}
                svgParentRef={svgParentRefDocuments}
                allClients={getDocumentsData}
                // onClickRow={handleClickDocRow.bind(this, "onRow")}
                onClickRow={handleClickDocRow}
                classNameRow="client-documents-tab-table-body"
                fill={
                  selectedFilterItemsDocuments.length > 0 ||
                  searchValDoc.length > 0 ||
                  sortByDocumentsR.length > 0
                    ? filterIconColor
                    : "#D8D3D3"
                }
              />
              {/* <UnderWork /> */}
              {/* <Rectangle type="stickyHub" /> */}
              <CircularNoteTrigger type="stickyHub" />
              {warningPopup && (
                <>
                  <ConfirmationModal type="" />
                </>
              )}
              {hasDeleteDocument && (
                <>
                  <UnsavedChangesPopup type="documents" />
                </>
              )}
            </div>
          </Fragment>

          {openFormStaff && openWarningSection && (
            <ConfirmationModal
              type="documents"
              handleMoveWithoutSaving={() => {
                dispatch(setWarningMessage(false));
              }}
              handleMoveWithSave={() => {
                dispatch(setWarningMessage(false));
                dispatch(resetOpenFormByClickStaff());
                // dispatch(setopenClientAssessmentForm(false));
              }}
            />
          )}

          {openFormStaff ? (
            <div className="client-assessment-form-div">
              {openFormStaff && (
                <Modal
                  isOpen={true}
                  onClose={() => {}}
                  closeOnOverlayClick={true}
                  isCentered
                >
                  <ModalOverlay onClick={() => {}} zIndex={10} />

                  <ClientAssessmentForm
                    type="new-form"
                    // openForm={openForm}
                    // setopenForm={setOpenForm}
                    handleOverlayClickStaff={handleOverlayClick}
                    // title={typeCheckForAssessmentForm}
                    handleGetDetails={handleGetDetails} //done-
                    uploadedFiles={uploadedFiles} //
                    setUploadedFiles={setUploadedFiles} //
                    // changesMadeToTheForm={changesMadeToTheForm}
                    // setChangesMadeToTheForm={setChangesMadeToTheForm}
                    handleSaveClick={handleOverlayClick}
                  />
                </Modal>
              )}
            </div>
          ) : (
            openForm &&
            typeToOpenForm === "by-note-click" && (
              <div className="client-assessment-form-div">
                <ClientAssessmentForm
                  type="form-from-note-click"
                  openForm={openForm}
                  title={typeCheckForAssessmentForm}
                  handleOverlayClickUpdateStaff={handleOverlayClickUpdate}
                  handleGetDetails={handleGetDetails}
                />
              </div>
            )
          )}
          {isFormOpenDoc && (
            <Modal
              isOpen={true}
              onClose={() => {}}
              closeOnOverlayClick={true}
              isCentered
            >
              <ModalOverlay onClick={() => {}} zIndex={10} />
              <ClientAssessmentForm
                type="new-form"
                setIsFormOpenStaff={setIsFormOpenDoc}
                handleChangesToForm={handleChangesToForm}
                dataObject={selectedObject}
                handleOverlayClickUpdateStaff={handleOverlayClickUpdate}
                setDataObject={setSelectedObject}
                handleGetDetails={() => {}}
                showStateOfDocument={showStateOfDocument}
                uploadedFiles={uploadedFiles}
                setUploadedFiles={setUploadedFiles}
                primaryApprover={primaryApprover}
                setPrimaryApprover={setPrimaryApprover}
                ongoingApprover={ongoingApprover}
                setOngoingApprover={setOngoingApprover}
                handleSaveClick={handleOverlayClickUpdate}
              />
            </Modal>
          )}
          {/* <Rectangle type="stickyHub" /> */}
          <CircularNoteTrigger type="stickyHub" />
        </>
      )}

      {buttonTab.Associations && (
        <>
          {/* <Header />
          <SideNavbar /> */}
          {/* <LeftBox type="contact-details" /> */}
          <CommonHeader
            type="associations"
            mainType="staffScreen"
            handleClick={handleClick}
            currentStaff={currentStaff}
          />
          {/* <CommonTable type="associations" currentStaff={currentStaff} /> */}
          <Table
            dynamicHeaderColor={domainDetail?.assoctbl_color}
            type="asociations"
            classNameTable="common-table-container-associations"
            thData={associationColumns}
            classNameTr="client-associations-tab-table-head"
            dropdownRef={dropdownRefAssociation}
            onClickSvg={() => setIsDropdownOpenAssociation(true)}
            isDropdownOpen={isDropdownOpenAssociation}
            svgParentRef={svgParentRefAssociation}
            allClients={getAssociationByUserId}
            classNameRow="client-associations-tab-table-body"
            fill={
              selectedFilterItemsAssociations.length > 0 ||
              searchValAssociations.length > 0 ||
              sortByAssociationsR.length > 0
                ? filterIconColor
                : "#D8D3D3"
            }
          />
          {/* <Rectangle type="associations" /> */}
          <CircularNoteTrigger type="associations" title="Association" />
          {warningPopupAssociation && (
            <>
              <ConfirmationModal type="" />
            </>
          )}
          {hasDeleteAssociation && (
            <>
              <UnsavedChangesPopup type="associations" />
            </>
          )}
          {/* <UnderWork /> */}
        </>
      )}

      {/* {buttonTab.Clinical && ( */}
      {/* <> */}
      {/* <Header />
          <SideNavbar /> */}
      {/* <LeftBox type="contact-details" /> */}

      {/* <CommonHeader
            mainType="staffScreen"
            type="clinical"
            handleClick={handleClick}
          />
          <UnderWork />
          <Rectangle type="stickyClinical" /> */}
      {/* </> */}
      {/* )} */}

      {buttonTab.Comms && (
        <>
          {/* <Header />
          <SideNavbar /> */}
          {/* <LeftBox type="contact-details" /> */}

          <CommonHeader
            mainType="staffScreen"
            type="comms"
            handleClick={handleClick}
          />
          {/* <div className="comms_client_section_wrapper">
            <Comms type="staff" />
          </div> */}
          {/* <UnderWork /> */}
          <div style={{ marginTop: "10px" }}>
            <Comms isUserType={true} />
          </div>
          {/* <Rectangle type="stickyHub" /> */}
          {/* <CircularNoteTrigger type="stickyHub" /> */}
        </>
      )}

      {buttonTab.Finance && (
        <>
          {/* <Header />
          <SideNavbar /> */}
          {/* <LeftBox type="contact-details" /> */}

          <CommonHeader
            mainType="staffScreen"
            type="finance"
            handleClick={handleClick}
          />
          <UnderWork />
          <CircularNoteTrigger type="stickyHub" />
          {/* <Rectangle type="stickyHub" /> */}
        </>
      )}

      {buttonTab.Daybook && (
        <>
          {/* <Header />
          <SideNavbar /> */}
          {/* <LeftBox type="contact-details" /> */}
          <CommonHeader
            mainType="staffScreen"
            type="daybook"
            handleClick={handleClick}
          />
          <Daybook type="staff" />
        </>
      )}

      {buttonTab.Meals && (
        <>
          {/* <Header />
          <SideNavbar /> */}
          {/* <LeftBox type="contact-details" /> */}
          <CommonHeader
            mainType="staffScreen"
            type="meals"
            handleClick={handleClick}
          />
          <MenuAssignment />
          {/* <Rectangle type="stickyHub" /> */}
          <CircularNoteTrigger type="stickyHub" />
        </>
      )}

      {buttonTab.Availability && (
        <>
          <CommonHeader
            mainType="staffScreen"
            type="availability"
            handleClick={handleClick}
          />
        </>
      )}
    </div>
  );
};

export default StaffScreen;
