import UserPictureWithCommsCount from "../Components/ClinicalTabSticky/Components/UserPictureWithCommsCount";
import "./MobileCard.css";
import menu from "./assets/icons/menu.png";
import StickyItems from "./StickyItems";
import { useEffect, useState } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../Controller/redux/Hooks";
import { getClientsThunk } from "../../../../Controller/redux/features/ClientFeature/clientsSlice";
import { GreenCircleSvg } from "../../../pages/People/ClientPage/Components/UniqueClientScreen/Components/ClinicalTab/Data/SVGs";
import CommonQuickMenu from "../../CommonQuickMenu/CommonQuickMenu";

export const MobileStickyHeader = ({ title }: any) => {
  const [stageTwoToGoalsModal, setstageTwoToGoalsModal] = useState(false);
  const [showModal, setShowModal] = useState(false);

  return (
    <div id="header">
      <div className="mobile_sticky_header">
        <GreenCircleSvg
          onClick={() => {}}
          color={"#2AD400"}
          size={24}
          className="sticky_green_dot"
        />
        <span onClick={() => {}}>
          <UserPictureWithCommsCount
            isCountNeeded={true}
            isFromMobileView={true}
          />
        </span>
        <div
          onClick={() => {
            setShowModal(!showModal);
            // setstageTwoToGoalsModal(true);
            // alert(showModal)
          }}
          className="menu_icon_container"
        >
          <img src={menu} height={30} width={30} />
          {showModal && (
            <span className="mobile_quick_menu_container">
              <CommonQuickMenu
                isFromMobileCard={true}
                setShowModal={setShowModal}
                setshowGoalsModal={(value: any) => {}}
                mainType={"listing"}
                setstageTwoToGoalsModal={setstageTwoToGoalsModal}
              />
            </span>
          )}
        </div>
      </div>
      {title && (
        <div style={{ marginBottom: "92px" }}>
          <h3 className="header_title_light">{`${title.split("  ")[0]}`}</h3>
          <h3 className="header_title_bold">{`${title.split("  ")[1]}`}</h3>
        </div>
      )}
    </div>
  );
};

function MobileCard() {
  const dispatch = useAppDispatch();
  const clientsData = useAppSelector((state) => state.people.allClients);
  const [activeItemId, setActiveItemId] = useState(-1);
  const [activeTab, setActiveTab] = useState("home");

  //@ts-ignore
  const dynamicClientId = localStorage.getItem("dynamicClientId");
  useEffect(() => {
    dispatch(getClientsThunk({ dynamicClientId }));
  }, [dynamicClientId]);

  
  const handleScroll = (e: any) => {
    const bottom =
      Math.abs(
        e.target.scrollHeight - e.target.clientHeight - e.target.scrollTop
      ) < 1;
  };
  return (
    <div className="card_container">
      <MobileStickyHeader title={"Review Your  Clients"} />
      <div className="card_inner_container">
        {Array.isArray(clientsData) &&
          clientsData?.slice(0, 6).map((item, index) => {
            return (
              <StickyItems
                key={item.id}
                setActiveItemId={setActiveItemId}
                isActive={index === activeItemId}
                item={item}
                index={index}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                handleScroll={handleScroll}
                isFromDayBook={false}
                isFromClient={true}
              />
            );
          })}
      </div>
    </div>
  );
}

export default MobileCard;
