import "./Styles/Sales.css";

import ClientStatus from "../../components/ClientStatus/ClientStatus";
import CircularNoteTrigger from "../../components/CircularNoteTrigger/CircularNoteTrigger";
import CommonUpperBox from "../../components/UpperBox/CommonHeader";
import CommonQuickMenu from "../../components/CommonQuickMenu/CommonQuickMenu";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../Controller/redux/Hooks";
import { useCallback, useEffect, useState } from "react";
import { itemsArrProspectsAll } from "../People/ClientPage/Components/UniqueClientScreen/Data/WidgetDataArr";
import {
  getUserSalesReportThunk,
  resetSalesData,
} from "../../../Controller/redux/features/SalesFeature/salesSlice";
import CommonChart from "../../components/CommonCharts/CommonChart";
import ClientImage from "../../../Model/Assets/png/clientImg.png";
import { YellowIcon } from "../../components/ReportingWidget/Data/SVGs";

const Sales = () => {
  const [stageTwoToGoalsModal, setstageTwoToGoalsModal] = useState(false);

  // @ts-ignore
  const dynamicProspectId = parseInt(localStorage.getItem("dynamicProspectId"));
  const dispatch = useAppDispatch();

  const { user, domainDetail } = useAppSelector((state) => state?.common);

  const { provider_type } = user;

  // console.log(user?.tenant?.tenant_name,"user")
  const { pastcontact_days, futurecontact_days, prospect_status } =
    domainDetail;

  const [dynamicUserTypes, setDynamicUserTypes] = useState<any[]>([]);
  const [DynamicUsertypesArrId, setDynamicUsertypesArrId] = useState<any[]>([]);
  const b2bCondition = domainDetail?.businessmodel_type === "B2B";

  let splitProspectStatus = prospect_status?.split(",");

  let resRecords = itemsArrProspectsAll?.filter((elem: any) => {
    let isExists = false;
    for (let i = 0; i < splitProspectStatus?.length; i++) {
      if (elem.status == splitProspectStatus[i]) {
        isExists = true;
        break;
      }
    }
    return isExists;
  });

  useEffect(() => {
    setDynamicUserTypes(resRecords.filter((res) => res.type !== "Make Client"));
    setDynamicUsertypesArrId(
      resRecords
        .filter((res) => res.type !== "Make Client")
        .map((res: any) => res?.status)
    );
  }, [provider_type, user]);

  const [isLoading, setisLoading] = useState(false);

  function handleFetchSales() {
    setisLoading(true);
    if (
      dynamicProspectId &&
      futurecontact_days &&
      pastcontact_days &&
      prospect_status
    ) {
      let dataPrams = {
        type: dynamicProspectId,
        past_days: pastcontact_days,
        future_days: futurecontact_days,
        statusArr: prospect_status,
      };

      dispatch(getUserSalesReportThunk(dataPrams)).then(() =>
        setisLoading(false)
      );
    }
  }
  useEffect(() => {
    dispatch(resetSalesData());
    handleFetchSales();
  }, [
    pastcontact_days,
    futurecontact_days,
    dynamicProspectId,
    dynamicUserTypes,
    DynamicUsertypesArrId,
  ]);

  const { getSalesDetails } = useAppSelector((state) => state?.sales);

  const [dueForReview, setDueForReview] = useState<any[]>([]);
  const [bokkedForReview, setBokkedForReview] = useState<any[]>([]);
  const [underControl, setUnderControl] = useState<any[]>([]);
  const [statusRecords, setStatusRecords] = useState<any>([]);

  // const pathname = useLocation()?.pathname;

  useEffect(() => {
    if (getSalesDetails) {
      setDueForReview(getSalesDetails?.due_for_review);
      setBokkedForReview(getSalesDetails?.booked_for_review);
      setUnderControl(getSalesDetails?.under_control);
      setStatusRecords(getSalesDetails?.stats);
    }
  }, [getSalesDetails]);

  const [datasetsRecordsChart, setDatasetsRecordsChart] = useState<any>([]);

  useEffect(() => {
    if (statusRecords) {
      // handleFetchSales()

      let datasetsRecords = dynamicUserTypes?.map((res: any) => {
        let dataSetObject = {
          label: res?.type,
          backgroundColor: res?.bgCol,
          data: [
            statusRecords?.due_for_review[res?.status],
            statusRecords?.booked_for_review[res?.status],
            statusRecords?.under_control[res?.status],
          ],
        };
        return dataSetObject;
      });
      setDatasetsRecordsChart(datasetsRecords);
    }
  }, [statusRecords]);

  const salesReportObject = {
    labels: dynamicUserTypes.map((res) => res?.type),
    datasets: [
      {
        label: "Due For Review",
        backgroundColor: "#6AE24C",
        data: dynamicUserTypes.map(
          (res) =>
            statusRecords &&
            statusRecords?.due_for_review &&
            statusRecords?.due_for_review[res?.status]
        ),
      },
      {
        label: "Booked For Review",
        backgroundColor: "#FFFF5B",
        data: dynamicUserTypes.map(
          (res) =>
            statusRecords &&
            statusRecords?.booked_for_review &&
            statusRecords?.booked_for_review[res?.status]
        ),
      },
      {
        label: "Under Control",
        backgroundColor: "#d99c23",
        data: dynamicUserTypes.map(
          (res) =>
            statusRecords &&
            statusRecords?.under_control &&
            statusRecords?.under_control[res?.status]
        ),
      },
    ],
  };

  const renderClinicalWidgets = useCallback(() => {
    return (
      <CommonChart
        values={[]}
        displayX={true}
        displayY={true}
        barThickness={20}
        pointBackgroundColor={"#B7A9FF"}
        borderWidth={1}
        pointBorderColor={"rgba(0, 0, 0, 0)"}
        pointRadius={3}
        chartLabel={""}
        isFromSales={true}
        datasets={salesReportObject?.datasets}
        chartType={"bar"}
        labels={salesReportObject.labels}
        isFromVillage={false}
        chartLineColor={"#000"}
        chartWidth={"450px"}
        chartSize={"medium"}
        dataColors={["red", "green", "blue"]}
        chartHeight={"auto"}
      />
    );
  }, [salesReportObject]);

  const [BarNameRecords, setBarNameRecords] = useState([
    {
      type: "Due For Review",
      color: "#6AE24C",
    },
    {
      type: "Booked For Review",
      color: "#FFFF5B",
    },
    {
      type: "Under Control",
      color: "#d99c23",
    },
  ]);

  return (
    <>
      <CommonUpperBox
        type={"listing"}
        onClickPlus={() => {
          setstageTwoToGoalsModal(true);
        }}
        title="Sales"
        backgroundWithText
        text="S D"
      />
      <div className="sales_page_wrapper">
        <div className="due_and_chart_section_wrapper">
          <div className="sales_recent_changes_wrapper">
            <div className="sales_header_section_wrapper">
              <div className="sales_left_side_header_section">
                <div className="sales_section_1_wrapper">
                  {/* <GreenColorSVG key={"green_svg"} width="24" height="24" /> */}
                  <div className="sales_title_wrapper">
                    <div className="sales_text_with_backgroud_wrapper">
                      <h1>Due For Review</h1>
                    </div>
                  </div>
                </div>
                <div className="sales_sub_title_wrapper" />
              </div>
              <div className="sales_right_side_header_section"></div>
            </div>
            <div className="sales_widget_container">
              {dueForReview?.map((elem: any, index: any) => (
                <ClientStatus
                  key={index}
                  title={elem?.first_name}
                  status={elem?.status}
                  date={elem?.created_at}
                  time={elem?.created_at}
                  imageURL={elem?.profile_image_s3}
                  backgroundWithText={` ${
                    b2bCondition
                      ? ""
                      : `${elem?.first_name?.split("")[0] || ""} ${
                          elem?.last_name?.split("")[0] || ""
                        }`
                  }`}
                />
              ))}
            </div>
          </div>
          <div className="chat_section_wrapper sales_chart_section_wrapper  ">
            <div>
              <div className="upper__segment__widget">
                <YellowIcon />

                <img
                  src={ClientImage}
                  alt="client-img"
                  className="note__sub__title-1-client__image"
                  loading="lazy"
                />

                <div className="client__name">{"Prospects"}</div>
              </div>
              <div className={`report__title `}>
                By Status
                {/* {heading} */}
              </div>
              <div className="widget_graph_container">
                {renderClinicalWidgets()}
              </div>
              <div className={`lower__segment__widget`}>
                {[
                  {
                    key: "Name",
                    detail: user?.tenant?.tenant_name,
                  },
                ].map((detail, index) => {
                  return (
                    <div key={index} className="client__personal__details">
                      <div className="client__key sales_key">{detail?.key}</div>
                      <div className="client__detail">{detail?.detail}</div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div
              className={`
          lower__segment__widget
      `}
            >
              <div className="reporting_chart_status sales_reporting_chart_status_wrapper">
                {BarNameRecords?.map((elem: any, index: any) => (
                  <div className="reporting_chart_status_inner" key={index}>
                    <span
                      style={{
                        backgroundColor: elem?.color,
                      }}
                      className="reporting_chart_status_color"
                    >
                      &nbsp;
                    </span>
                    <span>{elem?.type}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="sales_recent_changes_wrapper">
          <div className="sales_header_section_wrapper">
            <div className="sales_left_side_header_section">
              <div className="sales_section_1_wrapper">
                {/* <GreenColorSVG key={"green_svg"} width="24" height="24" /> */}
                <div className="sales_title_wrapper">
                  <div className="sales_text_with_backgroud_wrapper_booked">
                    <h1>Booked For Review</h1>
                    {/* <PlusSVG /> */}
                  </div>
                </div>
                {/* <FilterSVG /> */}
              </div>
              <div className="sales_sub_title_wrapper">
                {/* <p>
                    ‘Event Name’: <span> 10:00 am - 15:30 pm </span>
                  </p> */}
              </div>
            </div>
            <div className="sales_right_side_header_section">
              {/* <p>Last update: Today 13:03</p> */}
              {/* <button>Capacity</button>
                <img src={clientImg} />
                <ClientStatusCircleXxlSvg /> */}
            </div>
          </div>
          <div className="sales_widget_container">
            {bokkedForReview?.map((elem: any, index: any) => (
              <ClientStatus
                key={index}
                title={elem?.first_name}
                status={elem?.status}
                date={elem?.created_at}
                time={elem?.created_at}
                imageURL={elem?.profile_image_s3}
                backgroundWithText={` ${
                  b2bCondition
                    ? ""
                    : `${elem?.first_name?.split("")[0] || ""} ${
                        elem?.last_name?.split("")[0] || ""
                      }`
                }`}
              />
            ))}
          </div>
        </div>
        <div className="sales_recent_changes_wrapper">
          <div className="sales_header_section_wrapper">
            <div className="sales_left_side_header_section">
              <div className="sales_section_1_wrapper">
                {/* <GreenColorSVG key={"green_svg"} width="24" height="24" /> */}
                <div className="sales_title_wrapper">
                  <div className="sales_text_with_backgroud_wrapper">
                    <h1>Under Control</h1>
                  </div>
                </div>
              </div>
              <div className="sales_sub_title_wrapper">
                {/* <p>
                    ‘Event Name’: <span> 10:00 am - 15:30 pm </span>
                  </p> */}
              </div>
            </div>
            <div className="sales_right_side_header_section">
              {/* <p>Last update: Today 13:03</p> */}
              {/* <button>Capacity</button>
                <img src={clientImg} />
                <ClientStatusCircleXxlSvg /> */}
            </div>
          </div>
          <div className="sales_widget_container">
            {underControl?.map((elem: any, index: any) => (
              <ClientStatus
                key={index}
                title={elem?.first_name}
                status={elem?.status}
                date={elem?.created_at}
                time={elem?.created_at}
                imageURL={elem?.profile_image_s3}
                backgroundWithText={` ${
                  b2bCondition
                    ? ""
                    : `${elem?.first_name?.split("")[0] || ""} ${
                        elem?.last_name?.split("")[0] || ""
                      }`
                }`}
              />
            ))}
          </div>
        </div>
      </div>
      <CircularNoteTrigger 
              type="stickyHub"
              title="Comms"
              isOutside
              extraType="globalCommsBox"
          />

      {stageTwoToGoalsModal && (
        <CommonQuickMenu
          setshowGoalsModal={(value: any) => {}}
          mainType={"listing"}
          setstageTwoToGoalsModal={setstageTwoToGoalsModal}
        />
      )}
    </>
  );
};

export default Sales;
