import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../../../services/api";

const initialState: any = {
  loading: false,
  error: "",
  PayRuleData: [],

  // FILTER
  payRulePage: 1,
  payRuleFilterItems: {
    // status: "1",
    filterColumn: "",
    filterValue: "",
    searchVal: "",
    sortColumn: "",
    sortType: "",
    payRulePage: 1,
  },
  lastPage: 100000,

  // Filter Data Start
  getPayRuleName: [],
  getPayRuleAssociatedUser: [],
  getPayRuleRate: [],
  // Filter Data End
  //  // STATES OF SELECTED FILTER GOES HERE
  searchValPayRule: "",
  selectedDropdownItemPayRule: "",
  selectedItemIndexFilterPayRule: "",
  selectedItemIndexSortPayRule: "",
  sortByPayRuleR: "",
  selectedFilterItemsPayRule: [],
  // End

  // Pay Rule Details
  uniquePayRule: {},
};

export const getPayRule = createAsyncThunk(
  "pay-rule/fetchPayRule",
  async () => {
    try {
      const { data } = await api.get(
        `pay_rules?paginate=true&page=1&limit=10000`
      );
      return data?.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getPayRuleByFilterThunk = createAsyncThunk(
  "pay_rules/fetchPayRuleByFilter",
  async ({
    filterColumn,
    filterValue,
    searchVal,
    sortColumn,
    sortType,
    // status,
    payRulePage,
  }: {
    filterColumn: string | any;
    filterValue: string | any;
    searchVal: string | any;
    sortColumn: string | any;
    sortType: string | any;
    // status: string | any;
    payRulePage: string | any;
  }) => {
    const encodedFilterValue = encodeURIComponent(filterValue);

    try {
      const response = await api.get(
        `/pay_rules?paginate=true&page=${payRulePage}&limit=50&search=${searchVal}&sort_column=${sortColumn}&sort_type=${sortType}&filter_column=${filterColumn}&filter_value=${encodedFilterValue}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getPayRuleDetailsById = createAsyncThunk(
  "pay_rules/fetchPayRulDetailsByID",
  async (payRuleId: any) => {
    try {
      const { data } = await api.get(`pay_rules?id=${payRuleId}`);
      return data;
    } catch (error) {
      throw error;
    }
  }
);

export const getPayRuleNameThunk = createAsyncThunk(
  "pay_rules/fetchPayRuleName",
  async () => {
    try {
      const { data } = await api.get(`pay_rules?unique_column=name`);
      return data;
    } catch (error) {
      throw error;
    }
  }
);
export const getPayRuleAssociatedUserThunk = createAsyncThunk(
  "pay_rules/fetchPayRuleAssociatedFunding",
  async () => {
    try {
      const { data } = await api.get(
        `pay_rules?unique_column=associated_user_type`
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
);
export const getPayRuleRateThunk = createAsyncThunk(
  "pay_rules/fetchPayRuleRate",
  async () => {
    try {
      const { data } = await api.get(`pay_rules?unique_column=rate`);
      return data;
    } catch (error) {
      throw error;
    }
  }
);


export const updatePayRuleSlice = createAsyncThunk(
  "pay-rule/updatePayRule",
  async (payRuleDetails: any) => {
    try {
      const { data } = await api.post(
        `/pay_rules/update_pay_rule`,
        payRuleDetails
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
);

const PayRuleSlice = createSlice({
  name: "payRule",
  initialState,
  reducers: {
    // Pagination Start
    setPayRulePage: (state) => {
      state.payRulePage += 1;
    },
    resetPayRulePage: (state) => {
      state.payRulePage = 1;
    },
    resetPayRuleData: (state) => {
      state.PayRuleData = [];
    },
    setPayRuleFilterItems: (state, action) => {
      state.payRuleFilterItems = action.payload;
    },
    resetPayRuleFilterItems: (state) => {
      state.payRuleFilterItems = {
        // status: "1",
        filterColumn: "",
        filterValue: "",
        searchVal: "",
        sortColumn: "",
        sortType: "",
        payRulePage: 1,
      };
    },
    // Pagination End
    // Filter Dropdown Start
    setSelectedDropdownItemPayRule: (state, action) => {
      state.selectedDropdownItemPayRule = action.payload;
    },
    resetSelectedDropdownItemPayRule: (state) => {
      state.selectedDropdownItemPayRule = "";
    },
    setSelectedItemIndexFilterPayRule: (state, action) => {
      state.selectedItemIndexFilterPayRule = action.payload;
    },
    resetSelectedItemIndexFilterPayRule: (state) => {
      state.selectedItemIndexFilterPayRule = "";
    },
    setSelectedItemIndexSortPayRule: (state, action) => {
      state.selectedItemIndexSortPayRule = action.payload;
    },
    resetSelectedItemIndexSortPayRule: (state) => {
      state.selectedItemIndexSortPayRule = "";
    },
    setSortByPayRuleR: (state, action) => {
      state.sortByPayRuleR = action.payload;
    },
    resetSortByPayRuleR: (state) => {
      state.sortByPayRuleR = "";
    },
    setSelectedFilterItemsPayRule: (state, action) => {
      state.selectedFilterItemsPayRule = action.payload;
    },
    resetSelectedFilterItemsPayRule: (state) => {
      state.selectedFilterItemsPayRule = "";
    },
    setSearchValuePayRule: (state, action) => {
      state.searchValPayRule = action.payload;
    },
    resetSearchValuePayRule: (state) => {
      state.searchValPayRule = "";
    },
    // Filter End
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPayRule.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPayRule.fulfilled, (state, action) => {
        state.loading = false;
        state.PayRuleData = action.payload;
      })
      .addCase(getPayRule.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(getPayRuleByFilterThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPayRuleByFilterThunk.fulfilled, (state, action) => {
        state.loading = false;
        // state.ExpessTypeData = action.payload;
        state.PayRuleData = [...state.PayRuleData, ...action.payload.data.data];
        state.lastPage = action.payload.data.last_page;
      })
      .addCase(getPayRuleByFilterThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getPayRuleNameThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPayRuleNameThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.getPayRuleName = action.payload.data.data;
      })
      .addCase(getPayRuleNameThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getPayRuleAssociatedUserThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPayRuleAssociatedUserThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.getPayRuleAssociatedUser = action.payload.data.data;
      })
      .addCase(getPayRuleAssociatedUserThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getPayRuleRateThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPayRuleRateThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.getPayRuleRate = action.payload.data.data;
      })
      .addCase(getPayRuleRateThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getPayRuleDetailsById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPayRuleDetailsById.fulfilled, (state, action) => {
        state.loading = false;
        state.uniquePayRule = action.payload?.data?.data[0];
      })
      .addCase(getPayRuleDetailsById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const {
  resetPayRuleData,
  resetPayRuleFilterItems,
  resetPayRulePage,
  resetSearchValuePayRule,
  resetSelectedDropdownItemPayRule,
  resetSelectedFilterItemsPayRule,
  resetSelectedItemIndexFilterPayRule,
  resetSelectedItemIndexSortPayRule,
  resetSortByPayRuleR,
  setPayRuleFilterItems,
  setPayRulePage,
  setSearchValuePayRule,
  setSelectedDropdownItemPayRule,
  setSelectedFilterItemsPayRule,
  setSelectedItemIndexFilterPayRule,
  setSelectedItemIndexSortPayRule,
  setSortByPayRuleR,
} = PayRuleSlice.actions;

export default PayRuleSlice.reducer;
