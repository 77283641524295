export const StatementDataArr = [
  {
    title: "Opening Balance",
    sub_section: [
      {
        Category: "Government",
        Amount: "50",
      },
      {
        Category: "Client Contribution",
        Amount: "30",
      },
      {
        Category: "Held By Provider",
        Amount: "20",
      },
    ],
  },
  {
    title: "Incoming",
    sub_section: [
      {
        Category: "Government",
        Amount: "50",
      },
      {
        Category: "Client Contribution",
        Amount: "30",
      },
    ],
  },
  {
    title: "Services",
    sub_section: [
      {
        Category: "Personal Care",
        Amount: "50",
      },
      {
        Category: "Domestic Assistance",
        Amount: "30",
      },
    ],
  },
  {
    title: "Expenses",
    sub_section: [
      {
        Category: "Wheelchair",
        Amount: "100",
      },
      {
        Category: "Domestic Assistance",
        Amount: "30",
      },
    ],
  },
  {
    title: "Fees",
    sub_section: [
      {
        Category: "Package Management",
        Amount: "150",
      },
    ],
  },
  {
    title: "Closing Balance",
    sub_section: [
      {
        Category: "Government",
        Amount: "50",
      },
      {
        Category: "Client Contribution",
        Amount: "30",
      },
      {
        Category: "Held By Provider",
        Amount: "20",
      },
    ],
  },
];
