import { Switch } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import _ from "lodash";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../Controller/redux/Hooks";
import {
  createVendor,
  getUserRolesVendors,
  getVendors,
  updateVendor,
} from "../../../Controller/redux/features/VendorFeature/VendorSlice";
import { setShowStickyNote } from "../../../Controller/redux/features/CommonFeature/commonSlice";
import { toast } from "react-toastify";

const VendorStickyForm = () => {
  const dispatch = useAppDispatch();
  const dynamicColor = useAppSelector((state) => state?.common?.dynamicColor);


  const dynamicVendorsId = localStorage.getItem("dynamicVendorsId");

  const { domainDetail } = useAppSelector((state) => state?.common);
  const { currentEditVendor, loading, getUserRoleVendors } = useAppSelector(
    (state) => state?.vendors
  );

  const [commonBody, setcommonBody] = useState<any>({
    vendor_name: "",
    status: 1,
  });
  const [vendorTypeArr, setVendorTypeArr] = useState<any>([]);
  const [userRoles, setUserRoles] = useState([]);

  useEffect(() => {
    setcommonBody(currentEditVendor || { vendor_name: "", status: 1 });
    dispatch(getUserRolesVendors({ id: dynamicVendorsId }));
  }, []);

  useEffect(() => {
    if (getUserRoleVendors?.data && getUserRoleVendors?.data?.length > 0) {
      setUserRoles(getUserRoleVendors?.data);
    }
  }, [getUserRoleVendors]);

  useEffect(() => {
    if (domainDetail && domainDetail?.vendor_type) {
      const vendorType = domainDetail?.vendor_type.split(",");
      setVendorTypeArr(vendorType);
    }
  }, [domainDetail]);

  const handleAddForm = async () => {
    if (!commonBody?.vendor_name?.trim()) {
      toast.error("Please enter vendor name");
      return;
    }
    if (currentEditVendor) {
      dispatch(
        updateVendor({
          vendor_name: commonBody?.vendor_name,
          status: commonBody?.status,
          type: dynamicVendorsId,
          vendor_id: currentEditVendor.id,
          role: commonBody?.role,
        })
      ).then((response: any) => {
        if (response.error) {
          toast.error(response?.payload || "Error updating vendor");
          return;
        }
        toast.success("vendor Updated Successfully!", {
          // style: {},
          progressStyle: { backgroundColor: dynamicColor },
          icon: (
            <span
              style={{
                marginRight: "8px",
                borderRadius: "50%",
                width: "7rem",
                height: "35px",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: dynamicColor,
                backgroundColor: "white",
                fontSize: "1.5rem",
                border: `3px solid ${dynamicColor}`,
              }}
            >
              ✓
            </span>
          ),
        });
        dispatch(getVendors({ type: dynamicVendorsId }));
        dispatch(setShowStickyNote(false));
      });
      return;
    }
    dispatch(
      createVendor({
        vendor_name: commonBody?.vendor_name,
        status: commonBody?.status || 0,
        role: commonBody?.role,
        type: dynamicVendorsId,
      })
    ).then((response: any) => {
      if (response.error) {
        toast.error(response?.payload || "Error creating vendor");
        return;
      }
      toast.success("Vendor created successfully");
      dispatch(getVendors({ type: dynamicVendorsId }));
      dispatch(setShowStickyNote(false));
    });
  };

  const debouncedApiCall = _.debounce(handleAddForm, 300);

  useEffect(() => {
    const handleClick = () => {
      debouncedApiCall();
    };

    document?.getElementById("saveBTN")?.addEventListener("click", handleClick);

    return () => {
      document
        ?.getElementById("saveBTN")
        ?.removeEventListener("click", handleClick);
    };
  }, [debouncedApiCall]);

  return (
    <div className="payload_area">
      <div className="main_area">
        <div className="side_bar"></div>
        <div className="side_bar" />

        <div className="middle-block">
          <p className="note-title">
            {currentEditVendor ? "Update" : "Add"} Vendor
          </p>
          <div className="middle-section-content middle-section-others">
            <div className="field">
              <span className="field-title">
                {`Name`}
                <span className="asterixs_color"> * </span>
              </span>
              <input
                style={{
                  backgroundColor: "white",
                }}
                className="input-fields-of-sticky-note"
                placeholder={`Enter Name`}
                onChange={(e) =>
                  setcommonBody((prevState: any) => ({
                    ...prevState,
                    vendor_name: e.target.value,
                  }))
                }
                value={commonBody?.vendor_name}
              />

              <span className="field-border field-border-add-form"></span>
            </div>

            <div className="field">
              <span className="field-title">
                Category
                <span className="asterixs_color"> * </span>
              </span>
              <select
                required
                id="category"
                className="category-select"
                onChange={(e) =>
                  setcommonBody({ ...commonBody, role: e.target.value })
                }
                value={commonBody?.role || currentEditVendor?.category_id}
              >
                <option value="">Please Select...</option>

                {userRoles?.map((elem: any, index: number | any) => {
                  let isExist: any = vendorTypeArr.includes(`${elem?.desc}`);
                  if (isExist) {
                    return (
                      <option key={index} value={elem?.id}>
                        {elem.desc}
                      </option>
                    );
                  }
                })}
              </select>
            </div>

            <div className="switch_custom_form_sticky_wrapper">
              <label>Active</label>
              <Switch
                colorScheme="teal"
                size="md"
                isChecked={commonBody?.status == 1 ? true : false}
                zIndex={9}
                onChange={(e) => {
                  setcommonBody({
                    ...commonBody,
                    status: e.target.checked ? 1 : 0,
                  });
                }}
              />
            </div>
          </div>

          <div className="bottomBlock save_btn_custom_form_sticky">
            <button className="save-btn" id="saveBTN">
              {loading ? "loading" : "Save"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VendorStickyForm;
