import React, { useEffect, useRef, useState } from "react";
import "./MultiSelectDropdown.css";

const MultiSelectDropdown = ({
  value,
  options,
  isAdmin = true,
  optionArr,
  placeholder = "Please select...",
}: any) => {
  const [selectedOptions, setSelectedOptions] = useState<any>(
    value !== "" ? value?.split(",") : []
  );
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  
  useEffect(() => {
    options(selectedOptions);
  }, [selectedOptions]);

  const handleSelectChange = (event: any) => {
    const value = event.target.value;
    setSelectedOptions((prevSelectedOptions: any) => {
      if (!prevSelectedOptions.includes(value)) {
        return [...prevSelectedOptions, value];
      }
      return prevSelectedOptions;
    });
  };
  const handleRemoveOption = (e: any, optionToRemove: any) => {
    e.stopPropagation();
    setSelectedOptions(
      selectedOptions.filter((option: any) => option !== optionToRemove)
    );
    setShowDropdown(false);
  };
  const uniqueValue = selectedOptions
    ?.map((data: any) => data)
    ?.filter((elem: any) => elem !== null && elem !== "");
  const multiRef = useRef<any>(null);
  const multiRefDropdown = useRef<any>(null);
  useEffect(() => {
    const handleClickOutsideBusiness = (event: any) => {
      if (
        multiRef.current &&
        !multiRef?.current?.contains(event.target) &&
        multiRefDropdown.current &&
        !multiRefDropdown?.current?.contains(event.target)
      ) {
        setShowDropdown(false);
      }
    };
    document.addEventListener("click", handleClickOutsideBusiness);
    return () => {
      document.removeEventListener("click", handleClickOutsideBusiness);
    };
  }, []);
  return (
    <div className="multi_select_container">
      <div ref={multiRef} onClick={() => setShowDropdown(!showDropdown)}>
        {uniqueValue?.length === 0 && (
          <p className="wrapper-info">{placeholder}</p>
        )}
        <div className="multi_select_values">
          {uniqueValue?.map((option: any, index: number) => (
            <div className="wrapper-info multi_select_val" key={index}>
              <span>{option}</span>
              {isAdmin && (
                <button
                  className="tag-close"
                  onClick={(e: any) => handleRemoveOption(e, option)}
                >
                  &times;
                </button>
              )}
            </div>
          ))}
        </div>
      </div>
      <div className="wrapper-line"></div>
      {isAdmin && showDropdown && (
        <select
          ref={multiRefDropdown}
          multiple
          onChange={handleSelectChange}
          className="multi_select_dropdown_selection"
        >
          {[
            ...(optionArr
              ? optionArr
              : [
                  "Sunday",
                  "Monday",
                  "Tuesday",
                  "Wednesday",
                  "Thursday",
                  "Friday",
                  "Saturday",
                ]),
          ]?.map((elem: any, index: any) => (
            <option className="multi_select_option" key={index} value={elem}>
              {elem}
            </option>
          ))}
        </select>
      )}
    </div>
  );
};

export default MultiSelectDropdown;
