import "./FinanceModal.css";

const FinanceModal = () => {
  return (
    <>
      <div className="modal_container_finance">
        <div className="modal_finance_container ">
          <div className="clinical__modal__item__wrapper">
            <h1>Budget</h1>
            <div className="modal_finance_cost">$1,000</div>
            <div className="bottom__items__widget">
              <h1>On Track</h1>
              <span className="right__box__lower__box__vector__finance"></span>
            </div>
          </div>
        </div>
      </div>
    </>
);
};

export default FinanceModal;
