import React from "react";

const DollarSVG = () => {
  return (
    <svg
      width="8"
      height="13"
      viewBox="0 0 8 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.31445 9.72168C4.67448 9.67611 4.93652 9.59408 5.10059 9.47559C5.3877 9.26595 5.53125 8.90365 5.53125 8.38867C5.53125 7.99674 5.39681 7.68685 5.12793 7.45898C4.96842 7.32682 4.69727 7.1901 4.31445 7.04883V9.72168ZM3.66504 2.57812C3.26855 2.58724 2.97461 2.69434 2.7832 2.89941C2.5918 3.09993 2.49609 3.37565 2.49609 3.72656C2.49609 4.10938 2.63965 4.41243 2.92676 4.63574C3.08626 4.75879 3.33236 4.87272 3.66504 4.97754V2.57812ZM4.32812 12.6201H3.66504V11.2598C2.76725 11.1595 2.09733 10.9635 1.65527 10.6719C0.875977 10.1478 0.493164 9.25456 0.506836 7.99219H2.35254C2.41634 8.56641 2.50521 8.9515 2.61914 9.14746C2.79688 9.4528 3.14551 9.65104 3.66504 9.74219V6.82324L3.11133 6.65918C2.24089 6.40397 1.62565 6.0485 1.26562 5.59277C0.910156 5.13704 0.732422 4.58789 0.732422 3.94531C0.732422 3.52148 0.800781 3.13639 0.9375 2.79004C1.07878 2.44368 1.27474 2.14518 1.52539 1.89453C1.84896 1.57096 2.20898 1.34766 2.60547 1.22461C2.84701 1.14714 3.2002 1.09017 3.66504 1.05371V0.144531H4.31445V1.06738C5.05729 1.12663 5.64062 1.30892 6.06445 1.61426C6.83464 2.10189 7.2334 2.89258 7.26074 3.98633H5.46289C5.42643 3.58073 5.35807 3.28451 5.25781 3.09766C5.08464 2.77409 4.77018 2.59635 4.31445 2.56445V5.16895C5.39909 5.54264 6.12826 5.87305 6.50195 6.16016C7.11719 6.63867 7.4248 7.31315 7.4248 8.18359C7.4248 9.33203 7.00326 10.166 6.16016 10.6855C5.64518 11.0046 5.03451 11.1914 4.32812 11.2461V12.6201Z"
        fill="black"
      />
    </svg>
  );
};

export default DollarSVG;
