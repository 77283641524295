import React, { useState } from "react";
import "../Styles/Finance.css";
import ItalicIconSVG from "../Data/ItalicIconSVG";
import UnderscorSVG from "../Data/UnderscorSVG";
import GallaryIconSVG from "../Data/GallaryIconSVG";
import BoldSVG from "../Data/BoldSVG";
import PlusSVG from "../Data/PlusSVG";
import SearchSVG from "../Data/SearchSVG";
// import { PDFSVG } from "../../PDFSVG";

import DOCSSVG from "../../../Data/DOCSSVG";
// import SHEETSVG from "../../SHEETSVG";
import DeleteSVG from "../Data/DeleteSVG";
import CommonTable from "../../../../../../../../components/CommonTable/InputTable";
import SHEETSVG from "../../../Data/SHEETSVG";
import { PDFSVG } from "../../../Data/PDFSVG";

const FinanceInvoiceModal = () => {
  const [selectedBTN, setSelectedBTN] = useState("Services");
  const btnGroupArr = ["Services", "Expenses"];
  const [selectedSection, setSelectedSection] = useState("Import");
  return (
    <div className="finance_client_form">
      <div className="finance_client_form_header">
        <h1>Invoices</h1>
        <div className="finance_btn_content_row_1">
          {btnGroupArr.map((res: string, index: number) => {
            return (
              <div
                key={res}
                className={`sort_tab_btn  ${
                  res == selectedBTN && "sort_tab_btn_selected"
                } `}
                style={{
                  width: "fit-content",
                  textAlign: "center",
                }}
                onClick={() => {
                  setSelectedBTN(res);
                }}
              >
                {res}
              </div>
            );
          })}
        </div>
        <div className="form_field_wrapper">
          <div className="form_field_section">
            <span>Invoices:</span>
            <input type="text" placeholder="Enter here" />
          </div>
          <div className="form_field_section">
            <span>Date Range:</span>
            <input type="text" placeholder="Enter here" />
          </div>
          <div className="form_field_section blue">
            <span>Invoice Date:</span>
            <input type="text" placeholder="Enter here" />
          </div>
          <div className="form_field_section blue">
            <span>Due Date:</span>
            <input type="text" placeholder="Enter here" />
          </div>
        </div>
        <div className="form_field_wrapper">
          <div className="form_field_section">
            <span>Invoices #:</span>
            <input type="text" placeholder="Enter here" />
          </div>
          <div className="form_field_section blue">
            <span>Invoice $:</span>
            <input type="text" placeholder="Enter here" />
          </div>
          <div className="form_field_section blue">
            <span>Key People:</span>
            <input type="text" placeholder="Enter here" />
          </div>
        </div>
      </div>
      <div className="finance_client_form_table_section">
        <div className="finance_btn_content_row_1">
          {["Import", "Export To Accounts", "Export To Claims", "Save"].map(
            (res: string, index: number) => {
              return (
                <div
                  key={res}
                  className={`sort_tab_btn  ${
                    res == selectedSection && "sort_tab_btn_selected"
                  } `}
                  style={{
                    width: "fit-content",
                    textAlign: "center",
                  }}
                  onClick={() => {
                    setSelectedSection(res);
                  }}
                >
                  {res}
                </div>
              );
            }
          )}
        </div>
        <div className="filter_content_row_2">
          <div className="serach_sections">
            <SearchSVG key={"search_icon"} />
            <input className="finanse_" type="text" placeholder="Search" />
            <PlusSVG key={"serach_sections_plus_icon"} />
            <div className="menu_option_section">
              <p>file </p>
              <p>edit </p>
              <p>function </p>
            </div>
            <div className="btn_group_section_content">
              <BoldSVG key={"bold_svg"} />
              <ItalicIconSVG key={"italic_svg"} />
              <UnderscorSVG key={"underscor_svg"} />
              <GallaryIconSVG key={"gallary_svg"} />
            </div>
          </div>
          <div className="section_right_side_wrapper">
            <div className="all_btn_group_wrapper">
              <div className="btn_with_icon adjustment">
                <p>Adjustment</p>
                <PlusSVG marginLeft="5px" key={"plus_svg_on_btn_adjustment"} />
              </div>
              <div className="btn_with_icon accepted ">
                <p>Accepted</p>
                <PlusSVG marginLeft="5px" key={"plus_svg_accepted"} />
              </div>
              <div className="btn_with_icon task">
                <p>Task</p>
                <PlusSVG marginLeft="5px" key={"plus_svg_task"} />
              </div>
              <div className="btn_with_icon issue">
                <p>Issue</p>
                <PlusSVG marginLeft="5px" key={"plus_svg_issue"} />
              </div>
            </div>
            <div className="attachment_and_remove_btn_section">
              <p>export</p>
              <PDFSVG key={"pdf_svg"} />
              <DOCSSVG key={"docs_svg"} />
              <SHEETSVG key={"sheet_svg"} />
              <DeleteSVG style={{ marginLeft: "15px" }} key={"delete_svg"} />
            </div>
          </div>
        </div>
        <CommonTable type="invoices" />
      </div>
    </div>
  );
};

export default FinanceInvoiceModal;
