import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../../../services/api";

const initialState: any = {
  expenseTypePage: 1,
  expenseTypeFilterItems: {
    // status: "1",
    filterColumn: "",
    filterValue: "",
    searchVal: "",
    sortColumn: "",
    sortType: "",
    expenseTypePage: 1,
  },
  lastPage: 100000,

  loading: false,
  error: "",

  ExpessTypeData: [],
  // Filter Data Start
  getExpenseTypeName: [],
  getExpenseTypeAssociatedFunding: [],
  getExpenseTypeRate: [],
  // Filter Data End
  //  // STATES OF SELECTED FILTER GOES HERE
  searchValExpenseType: "",
  selectedDropdownItemExpenseType: "",
  selectedItemIndexFilterExpenseType: "",
  selectedItemIndexSortExpenseType: "",
  sortByExpenseTypeR: "",
  selectedFilterItemsExpenseType: [],
  uniqueExpenseTypeDetails: null
  // End
};

// export const getExpenseType = createAsyncThunk(
//   "expenss-type/fetchExpenseType",
//   async () => {
//     try {
//       const { data } = await api.get(
//         `expense_types?paginate=true&page=1&limit=10000`
//       );
//       return data?.data;
//     } catch (error) {
//       throw error;
//     }
//   }
// );
export const getExpenseTypeByFilterThunk = createAsyncThunk(
  "expenses-type/fetchExpenseTypeByFilter",
  async ({
    filterColumn,
    filterValue,
    searchVal,
    sortColumn,
    sortType,
    // status,
    expenseTypePage,
  }: {
    filterColumn: string | any;
    filterValue: string | any;
    searchVal: string | any;
    sortColumn: string | any;
    sortType: string | any;
    // status: string | any;
    expenseTypePage: string | any;
  }) => {
    const encodedFilterValue = encodeURIComponent(filterValue);

    try {
      const response = await api.get(
        `/expense_types?paginate=true&page=${expenseTypePage}&limit=10000&search=${searchVal}&sort_column=${sortColumn}&sort_type=${sortType}&filter_column=${filterColumn}&filter_value=${encodedFilterValue}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);


export const updateExpenseType = createAsyncThunk(
  "expense-type/updateExpenseType",
  async (expenseTypeDetails: any) => {
    try {
      const { data } = await api.post(
        `expense_types/update_expense_type`,
        expenseTypeDetails
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
);
export const getExpenseTypeDetailsId = createAsyncThunk(
  "expense-type/getExpenseTypeDetailsId",
  async (expenseTypeId: any) => {
    try {
      const { data } = await api.get(
        `expense_types?paginate=true&page=1&limit=10000&id=${expenseTypeId}`
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
);
export const getExpenseTypeNameThunk = createAsyncThunk(
  "expenss-type/fetchExpenseTypeName",
  async () => {
    try {
      const { data } = await api.get(`expense_types?unique_column=name`);
      return data;
    } catch (error) {
      throw error;
    }
  }
);
export const getExpenseTypeAssociatedFundingThunk = createAsyncThunk(
  "expenss-type/fetchExpenseTypeAssociatedFunding",
  async () => {
    try {
      const { data } = await api.get(
        `expense_types?unique_column=associated_funding_package`
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
);
export const getExpenseTypeRateThunk = createAsyncThunk(
  "expenss-type/fetchExpenseTypeRate",
  async () => {
    try {
      const { data } = await api.get(`expense_types?unique_column=rate`);
      return data;
    } catch (error) {
      throw error;
    }
  }
);

const ExpenseTypeSlice = createSlice({
  name: "expenssType",
  initialState,
  reducers: {
    // Pagination Start
    setExpenseTypePage: (state) => {
      state.expenseTypePage += 1;
    },
    resetExpenseTypePage: (state) => {
      state.expenseTypePage = 1;
    },
    resetExpenseTypeData: (state) => {
      state.ExpessTypeData = [];
    },
    setExpenseTypeFilterItems: (state, action) => {
      state.expenseTypeFilterItems = action.payload;
    },
    resetExpenseTypeFilterItems: (state) => {
      state.expenseTypeFilterItems = {
        // status: "1",
        filterColumn: "",
        filterValue: "",
        searchVal: "",
        sortColumn: "",
        sortType: "",
        expenseTypePage: 1,
      };
    },
    // Pagination End
    // Filter Dropdown Start
    setSelectedDropdownItemExpenseType: (state, action) => {
      state.selectedDropdownItemExpenseType = action.payload;
    },
    resetSelectedDropdownItemExpenseType: (state) => {
      state.selectedDropdownItemExpenseType = "";
    },
    setSelectedItemIndexFilterExpenseType: (state, action) => {
      state.selectedItemIndexFilterExpenseType = action.payload;
    },
    resetSelectedItemIndexFilterExpenseType: (state) => {
      state.selectedItemIndexFilterExpenseType = "";
    },
    setSelectedItemIndexSortExpenseType: (state, action) => {
      state.selectedItemIndexSortExpenseType = action.payload;
    },
    resetSelectedItemIndexSortExpenseType: (state) => {
      state.selectedItemIndexSortExpenseType = "";
    },
    setSortByExpenseTypeR: (state, action) => {
      state.sortByExpenseTypeR = action.payload;
    },
    resetSortByExpenseTypeR: (state) => {
      state.sortByExpenseTypeR = "";
    },
    setSelectedFilterItemsExpenseType: (state, action) => {
      state.selectedFilterItemsExpenseType = action.payload;
    },
    resetSelectedFilterItemsExpenseType: (state) => {
      state.selectedFilterItemsExpenseType = "";
    },
    setSearchValueExpenseType: (state, action) => {
      state.searchValExpenseType = action.payload;
    },
    resetSearchValueExpenseType: (state) => {
      state.searchValExpenseType = "";
    },
    // Filter End
  },
  extraReducers: (builder) => {
    builder
      // .addCase(getExpenseType.pending, (state) => {
      //   state.loading === true;
      // })
      // .addCase(getExpenseType.fulfilled, (state, action) => {
      //   state.loading = false;
      //   state.ExpessTypeData = action.payload;
      // })
      // .addCase(getExpenseType.rejected, (state, action) => {
      //   state.loading = false;
      //   state.error = action.error.message;
      // })
      .addCase(getExpenseTypeByFilterThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getExpenseTypeByFilterThunk.fulfilled, (state, action) => {
        state.loading = false;
        // state.ExpessTypeData = action.payload;
        state.ExpessTypeData = [
          ...state.ExpessTypeData,
          ...action.payload.data.data,
        ];
        state.lastPage = action.payload.data.last_page;
      })
      .addCase(getExpenseTypeByFilterThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getExpenseTypeDetailsId.pending, (state) => {
        state.loading = true;
      })
      .addCase(getExpenseTypeDetailsId.fulfilled, (state, action) => {
        state.loading = false;
        state.uniqueExpenseTypeDetails = action.payload.data.data;
      })
      .addCase(getExpenseTypeDetailsId.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getExpenseTypeNameThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getExpenseTypeNameThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.getExpenseTypeName = action.payload.data.data;
      })
      .addCase(getExpenseTypeNameThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getExpenseTypeAssociatedFundingThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        getExpenseTypeAssociatedFundingThunk.fulfilled,
        (state, action) => {
          state.loading = false;
          state.getExpenseTypeAssociatedFunding = action.payload.data.data;
        }
      )
      .addCase(
        getExpenseTypeAssociatedFundingThunk.rejected,
        (state, action) => {
          state.loading = false;
          state.error = action.error.message;
        }
      )
      .addCase(getExpenseTypeRateThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getExpenseTypeRateThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.getExpenseTypeRate = action.payload.data.data;
      })
      .addCase(getExpenseTypeRateThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const {
  // Filter Start
  setSelectedDropdownItemExpenseType,
  resetSelectedDropdownItemExpenseType,
  setSelectedItemIndexFilterExpenseType,
  resetSelectedItemIndexFilterExpenseType,
  setSelectedItemIndexSortExpenseType,
  resetSelectedItemIndexSortExpenseType,
  setSortByExpenseTypeR,
  resetSortByExpenseTypeR,
  setSelectedFilterItemsExpenseType,
  resetSelectedFilterItemsExpenseType,
  setSearchValueExpenseType,
  resetSearchValueExpenseType,
  // Filter End
  // Pagination Start
  setExpenseTypePage,
  resetExpenseTypePage,
  resetExpenseTypeData,
  setExpenseTypeFilterItems,
  resetExpenseTypeFilterItems,
  // Pagination End
} = ExpenseTypeSlice.actions;

export default ExpenseTypeSlice.reducer;
