import { Fragment, useEffect, useRef, useState } from "react";

import {
  useAppDispatch,
  useAppSelector,
} from "../../../../Controller/redux/Hooks";
import {
  getBusinessByFilterThunk,
  getBusinessEmailThunk,
  getBusinessNameThunk,
  getBusinessPhoneThunk,
  getBusinessStatusThunk,
  resetBusinessData,
  resetBusinessPage,
  setBusinessPage,
} from "../../../../Controller/redux/features/BusinessFeature/businessSlice";

import {
  getEntityTypesThunk,
  getStatusDetailsThunk,
  resetStatusDetails,
  setProfileTypeSticky,
} from "../../../../Controller/redux/features/CommonFeature/commonSlice";
import CircularNoteTrigger from "../../../components/CircularNoteTrigger/CircularNoteTrigger";
import CommonUpperBox from "../../../components/UpperBox/CommonHeader";
import CommonQuickMenu from "../../../components/CommonQuickMenu/CommonQuickMenu";
import Table from "../../../components/CommonTable/Table";
import { useNavigate } from "react-router-dom";

const BusinessPage = () => {
  // @ts-ignore
  const dynamicBusinessId = parseInt(localStorage.getItem("dynamicBusinessId"));
  const tableRef = useRef();
  const businessInnerRef = useRef();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    loading,
    allDataBusiness,
    BusinessPage,
    BusinessFilterItems,
    business,
    lastPage,
  } = useAppSelector((state) => state?.business);

  useEffect(() => {
    dispatch(resetBusinessData());
    dispatch(resetBusinessPage());
  }, [
    BusinessFilterItems?.searchVal,
    BusinessFilterItems?.filterValue,
    BusinessFilterItems?.sortType,
    BusinessFilterItems?.status,
    dynamicBusinessId,
  ]);
  useEffect(() => {
    dispatch(
      getBusinessByFilterThunk({
        filterColumn: BusinessFilterItems?.filterColumn
          ? BusinessFilterItems?.filterColumn
          : "",
        filterValue: BusinessFilterItems?.filterValue
          ? BusinessFilterItems?.filterValue
          : "",
        searchVal: BusinessFilterItems?.searchVal
          ? BusinessFilterItems?.searchVal
          : "",
        sortColumn: BusinessFilterItems?.sortColumn
          ? BusinessFilterItems?.sortColumn
          : "",
        sortType: BusinessFilterItems?.sortType
          ? BusinessFilterItems?.sortType
          : "",
        BusinessPage,
        dynamicId: dynamicBusinessId,
        status: BusinessFilterItems.status ? BusinessFilterItems.status : "",
      })
    );
  }, [
    BusinessPage,
    BusinessFilterItems?.searchVal,
    BusinessFilterItems?.filterValue,
    BusinessFilterItems?.sortType,
    BusinessFilterItems?.status,
    dynamicBusinessId,
  ]);
  const dropdownRefBusiness = useRef<any>();
  const svgParentRefBusiness = useRef<any>();
  const [isDropdownOpenBusiness, setIsDropdownOpenBusiness] = useState(false);
  const { selectedFilterItemsBusiness, sortByBusinessR, searchValBusiness } =
    useAppSelector((state) => state?.business);
  const { filterIconColor } = useAppSelector((state) => state?.common);
  const handleClickRow = (e: any, id: any) => {
    if (!isDropdownOpenBusiness) {
      navigate(`/people/business/${window.btoa(id)}`);
    }
  };

  ///////////////////////////////////////////////////////////////////////////////////////
  const businessStatusDetailsNums = useAppSelector(
    (state) => state.common.domainDetail.business_status
  );
  const businessStatusDetailsArr = useAppSelector(
    (state) => state.common.statusDetailsData?.data
  );
  // // console.log(clientStatusDetailsArr,"clientStatusDetailsArr")
  useEffect(() => {
    dispatch(resetStatusDetails());
    dispatch(getStatusDetailsThunk(businessStatusDetailsNums));
  }, [businessStatusDetailsNums]);
  ///////////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    const handleClickOutsideBusiness = (event: any) => {
      if (
        dropdownRefBusiness.current &&
        !dropdownRefBusiness.current.contains(event.target) &&
        svgParentRefBusiness.current &&
        !svgParentRefBusiness.current.contains(event.target)
      ) {
        setIsDropdownOpenBusiness(false);
      }
    };
    document.addEventListener("click", handleClickOutsideBusiness);
    return () => {
      document.removeEventListener("click", handleClickOutsideBusiness);
    };
  }, []);

  useEffect(() => {
    dispatch(getBusinessNameThunk(dynamicBusinessId));
    dispatch(getBusinessEmailThunk(dynamicBusinessId));
    dispatch(getBusinessPhoneThunk(dynamicBusinessId));
    dispatch(getBusinessStatusThunk(dynamicBusinessId));
  }, [dynamicBusinessId]);

  useEffect(() => {
    localStorage.removeItem("activeTab");
    dispatch(getEntityTypesThunk());
  }, []);

  const onScroll = () => {
    if (businessInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } =
        businessInnerRef.current;

      if (
        scrollTop + clientHeight + +5 >= scrollHeight &&
        BusinessPage < lastPage
      ) {
        dispatch(setBusinessPage(BusinessPage));
      }
    }
  };
  // useEffect(() => {
  //   // dispatch(getBusinessRoleThunk(dynamicBusinessId));
  //   // dispatch(getBusinessThunk(dynamicBusinessId));
  // }, [dynamicBusinessId]);

  const uniqueBusinessData = business?.filter(
    (item: any, index: number, array: any) => {
      return array?.findIndex((obj: any) => obj.id === item.id) === index;
    }
  );
  const [stageTwoToGoalsModal, setstageTwoToGoalsModal] = useState(false);
  const columns = [
    { header: "Name", accessor: "name" },
    { header: "Email", accessor: "email" },
    { header: "Phone", accessor: "phone" },
    { header: "Status", accessor: "status" },
  ];
  // 2fr 2fr 2fr 2fr 0fr 0px 0px
  const { domainDetail } = useAppSelector((state) => state?.common);
  const { business_label } = useAppSelector(
    (state) => state?.common?.domainDetail
  );
  useEffect(() => {
    dispatch(setProfileTypeSticky(business_label));
  }, [business_label]);
  const { profile_name } = useAppSelector((state) => state.common.domainDetail);
  return (
    <Fragment>
      {/* <UpperBox type="business" /> */}
      <div
        style={{
          position: "relative",
        }}
      >
        {stageTwoToGoalsModal && (
          <CommonQuickMenu
            setshowGoalsModal={(value: any) => {}}
            mainType={"listing"}
            setstageTwoToGoalsModal={setstageTwoToGoalsModal}
          />
        )}
        <CommonUpperBox
          onClickPlus={() => {
            setstageTwoToGoalsModal(true);
          }}
          type={"listing"}
          title={business_label}
        />
      </div>
      <Table
        isLoading={loading}
        tableRef={tableRef}
        tableBodyRef={businessInnerRef}
        onScroll={onScroll}
        itemsArr={businessStatusDetailsArr}
        dynamicHeaderColor={domainDetail?.listingtbl_color}
        statusType="businessScreen"
        thData={columns}
        classNameTr="all-business-table-head"
        type="business"
        dropdownRef={dropdownRefBusiness}
        isDropdownOpen={isDropdownOpenBusiness}
        onClickSvg={() => setIsDropdownOpenBusiness(true)}
        svgParentRef={svgParentRefBusiness}
        allClients={uniqueBusinessData}
        onClickRow={handleClickRow}
        classNameRow="all-business-table-head"
        fill={
          selectedFilterItemsBusiness.length > 0 ||
          searchValBusiness.length > 0 ||
          sortByBusinessR.length > 0
            ? filterIconColor
            : "#D8D3D3"
        }
      />

      <CircularNoteTrigger
        type={business_label}
        //  title={business_label}
        title={profile_name}
      />
 
    </Fragment>
  );
};

export default BusinessPage;
