import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { api } from "../../../services/api";

const initialState: any = {
  loading: false,
  error: null,
  locationsRecords: [],
  lastPage: 10000,
};

export const getProviderLocations = createAsyncThunk(
  "provider_locations/fetchProviderLocations",
  async ({ dynamicUserTypeId, dynamicProviderId }: any) => {
    try {
      const { data } = await api.get(
        `providers/get_provider_users?paginate=true&page=1&limit=10&sort_type=asc&type=${dynamicUserTypeId}&tenant_id=${dynamicProviderId}`
      );
      return data?.data?.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getProviderLocationsByFilterThunk = createAsyncThunk(
  "provider_locations/fetchProviderLocationsByFilterThunk",
  async ({
    dynamicUserTypeId,
    dynamicProviderId,
    filterColumn,
    filterValue,
    searchVal,
    sortColumn,
    sortType,
    status,
    LocationPage,
  }: any) => {
    const encodeFilterVal = encodeURIComponent(filterValue);
    const encodeSearchVal = encodeURIComponent(searchVal);
    try {
      const { data } = await api.get(
        `providers/get_provider_users?paginate=true&type=${dynamicUserTypeId}&tenant_id=${dynamicProviderId}&page=${LocationPage}&status=${status}&sort_column=${sortColumn}&sort_type=${sortType}&filter_column=${filterColumn}&limit=50&filter_value=${encodeFilterVal}&search=${encodeSearchVal}`
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
);

const providerLocationsSlice = createSlice({
  name: "provider_locations",
  initialState,
  reducers: {
    resetLocationProviderData: (state) => {
      state.locationsRecords = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProviderLocations.pending, (state) => {
        state.loading === true;
      })
      .addCase(getProviderLocations.fulfilled, (state, action) => {
        state.loading = false;
        state.locationsRecords = action.payload;
      })
      .addCase(getProviderLocations.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getProviderLocationsByFilterThunk.pending, (state) => {
        state.loading === true;
      })
      .addCase(getProviderLocationsByFilterThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.lastPage = action.payload.data.last_page;
        // state.locationsRecords = action.payload;
        state.locationsRecords = [
          ...state.locationsRecords,
          ...action.payload.data.data,
        ];
      })
      .addCase(getProviderLocationsByFilterThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { resetLocationProviderData } = providerLocationsSlice.actions;

export default providerLocationsSlice.reducer;
