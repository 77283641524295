import React, { useEffect, useRef, useState } from "react";
import Table from "../../../components/CommonTable/Table";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../Controller/redux/Hooks";
import { useNavigate, useParams } from "react-router-dom";
import {
  getProviderStaffs,
  getProviderStaffsByFilterThunk,
  resetStaffProviderData,
} from "../../../../Controller/redux/features/ProviderFeature/providerStaffs";
import {
  getProviderStaffEmailThunk,
  getProviderStaffNameThunk,
  getProviderStaffPhoneThunk,
  getProviderStaffRolesThunk,
  reinviteStaffThunk,
  resetStaffPage,
  setStaffPage,
} from "../../../../Controller/redux/features/StaffFeature/staffSlice";
import { toast } from "react-toastify";

const ProviderStaff = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  //  @ts-ignore
  const dynamicStaffId = parseInt(localStorage.getItem("dynamicStaffId"));
  const tableRef = useRef();
  const staffInnerRef = useRef();
  const dropdownRefStaff = useRef<any>();
  const svgParentRefStaff = useRef<any>();
  const [isDropdownOpenStaff, setIsDropdownOpenStaff] = useState(false);
  const handleClickRow = (e: any, id: any) => {
    navigate(`/providers/${window.btoa(providerId)}/staff/${window.btoa(id)}`);
  };
  const columns = [
    { header: "Name", accessor: "first_name" },
    { header: "Email", accessor: "email" },
    { header: "Mobile", accessor: "mobile" },
    { header: "Role", accessor: "role" },
    { header: "Status", accessor: "status" },
    { header: "Reinvite", accessor: "actionStaff" },
  ];
  const { domainDetail } = useAppSelector((state) => state?.common);
  let isPending = false;
  const dynamicColor = useAppSelector((state) => state?.common?.dynamicColor);

  const handleReinviteStaff = async (e: any, id: any, tenant_id: number | string) => {
    e.stopPropagation();
    if (id !== "") {
      if (isPending) return;
      try {
        isPending = true;
        const res = await dispatch(reinviteStaffThunk({ id: id, tenant_id: tenant_id }));
        if (res.payload.status === "success") {
          isPending = false;
          toast.success("Mail Sent Successfully!", {
            progressStyle: { backgroundColor: dynamicColor },
            icon: (
              <span
                style={{
                  marginRight: "8px",
                  borderRadius: "50%",
                  width: "7rem",
                  height: "35px",
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: dynamicColor,
                  backgroundColor: "white",
                  fontSize: "1.5rem",
                  border: `3px solid ${dynamicColor}`,
                }}
              >
                ✓
              </span>
            ),
          });
        } else {
          toast.error("You cant reinvite this user.");
        }
      } catch (err: any) {
        toast.error("You cant reinvite this user.");
      }
    }
  };

  const staffStatusDetailsArr = useAppSelector(
    (state) => state.common.statusDetailsData?.data
  );

  const id: any = useParams()?.id;
  const [providerId, setProviderId] = useState<any>("");
  useEffect(() => {
    if (id) {
      let realId = window?.atob(id);
      setProviderId(realId);
    }
  }, [id]);
  useEffect(() => {
    if (providerId && dynamicStaffId) {
      // dispatch(
      //   getProviderStaffs({
      //     dynamicProviderId: providerId,
      //     dynamicUserTypeId: dynamicStaffId,
      //   })
      // );
      dispatch(
        getProviderStaffNameThunk({
          dynamicProviderId: providerId,
          dynamicUserTypeId: dynamicStaffId,
        })
      );
      dispatch(
        getProviderStaffEmailThunk({
          dynamicProviderId: providerId,
          dynamicUserTypeId: dynamicStaffId,
        })
      );
      dispatch(
        getProviderStaffPhoneThunk({
          dynamicProviderId: providerId,
          dynamicUserTypeId: dynamicStaffId,
        })
      );
      dispatch(
        getProviderStaffRolesThunk({
          dynamicProviderId: providerId,
          dynamicUserTypeId: dynamicStaffId,
        })
      );
    }
  }, [providerId, dynamicStaffId]);

  const { staffsRecords, loading, lastPage } = useAppSelector(
    (state) => state?.providerStaffs
  );

  useEffect(() => {
    const handleClickOutsideStaff = (event: any) => {
      if (
        dropdownRefStaff.current &&
        !dropdownRefStaff.current.contains(event.target) &&
        svgParentRefStaff.current &&
        !svgParentRefStaff.current.contains(event.target)
      ) {
        setIsDropdownOpenStaff(false);
      }
    };
    document.addEventListener("click", handleClickOutsideStaff);
    return () => {
      document.removeEventListener("click", handleClickOutsideStaff);
    };
  }, []);

  const [providerStaffsRecords, setProviderStaffsRecords] = useState([]);

  useEffect(() => {
    if (staffsRecords) {
      setProviderStaffsRecords(staffsRecords);
    }
  }, [staffsRecords]);

  const { staff, staffPage, staffFilterItems } = useAppSelector(
    (state) => state?.staff
  );
  const { selectedFilterOptions, selectedSortOptions, searchValueStaff } =
    useAppSelector((state) => state?.staff);
  const { filterIconColor } = useAppSelector((state) => state?.common);
  useEffect(() => {
    dispatch(resetStaffProviderData());
    dispatch(resetStaffPage());
  }, [
    staffFilterItems?.searchVal,
    staffFilterItems?.filterValue,
    staffFilterItems?.sortType,
    staffFilterItems?.status,
    dynamicStaffId,
  ]);
  useEffect(() => {
    dispatch(
      getProviderStaffsByFilterThunk({
        dynamicProviderId: providerId,
        dynamicUserTypeId: dynamicStaffId,
        filterColumn: staffFilterItems?.filterColumn
          ? staffFilterItems?.filterColumn
          : "",
        filterValue: staffFilterItems?.filterValue
          ? staffFilterItems?.filterValue
          : "",
        searchVal: staffFilterItems?.searchVal
          ? staffFilterItems?.searchVal
          : "",
        sortColumn: staffFilterItems?.sortColumn
          ? staffFilterItems?.sortColumn
          : "",
        sortType: staffFilterItems?.sortType ? staffFilterItems?.sortType : "",
        status: staffFilterItems.status ? staffFilterItems.status : "",
        staffPage,
      })
    );
  }, [
    staffFilterItems?.searchVal,
    staffFilterItems?.filterValue,
    staffFilterItems?.sortType,
    staffFilterItems?.status,
    staffPage,
    providerId,
    dynamicStaffId,
  ]);
  const onScroll = () => {
    if (staffInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = staffInnerRef.current;

      if (
        scrollTop + clientHeight + +5 >= scrollHeight &&
        staffPage < lastPage
      ) {
        dispatch(setStaffPage(staffPage));
      }
    }
  };
  return (
    <div className="provider_prospect_wrapper">
      <Table
        handleReinviteStaff={handleReinviteStaff}
        isLoading={loading}
        tableRef={tableRef}
        tableBodyRef={staffInnerRef}
        onScroll={onScroll}
        dynamicHeaderColor={domainDetail?.listingtbl_color}
        statusType="providerStaffScreen"
        thData={columns}
        itemsArr={staffStatusDetailsArr}
        classNameTr="all-staffs-table-head"
        type="providerStaff"
        dropdownRef={dropdownRefStaff}
        isDropdownOpen={isDropdownOpenStaff}
        onClickSvg={() => setIsDropdownOpenStaff(true)}
        svgParentRef={svgParentRefStaff}
        allClients={providerStaffsRecords}
        onClickRow={handleClickRow}
        classNameRow="all-staffs-table-body"
        fill={
          selectedFilterOptions.length > 0 ||
          selectedSortOptions.length > 0 ||
          searchValueStaff.length > 0
            ? filterIconColor
            : "#D8D3D3"
        }
      />
    </div>
  );
};

export default ProviderStaff;
