export function getCurrentTime(showAmOrPm = true) {
  const currentTime = new Date();
  let hours = currentTime.getHours();
  let minutes = currentTime.getMinutes();
  let amOrPm = hours >= 12 ? "PM" : "AM";

  hours = hours % 12;
  hours = hours ? hours : 12;

  if (minutes < 10) {
    // @ts-ignore
    minutes = "0" + minutes;
  }

  if (hours < 10) {
    // @ts-ignore
    hours = "0" + hours;
  }

  const timeString = showAmOrPm
    ? `${hours}:${minutes}${amOrPm}`
    : `${hours}:${minutes}`;
  return timeString;
}

export function timeAgo(dateString: any) {
  const now: any = new Date();
  const date: any = new Date(dateString);
  const diff = now - date;

  const minutes = Math.floor(diff / (1000 * 60));
  const hours = Math.floor(diff / (1000 * 60 * 60));
  const days = Math.floor(diff / (1000 * 60 * 60 * 24));
  const weeks = Math.floor(diff / (1000 * 60 * 60 * 24 * 7));
  const months = Math.floor(diff / (1000 * 60 * 60 * 24 * 30));
  const years = Math.floor(diff / (1000 * 60 * 60 * 24 * 365));

  if (diff < 60000) {
    return "Just now";
  } else if (minutes < 60) {
    return `${minutes} minute${minutes !== 1 ? "s" : ""} ago`;
  } else if (hours < 24) {
    return `${hours} hour${hours !== 1 ? "s" : ""} ago`;
  } else if (days === 1) {
    return "Yesterday";
  } else if (days < 7) {
    return `${days} day${days !== 1 ? "s" : ""} ago`;
  } else if (weeks < 4) {
    return `${weeks} week${weeks !== 1 ? "s" : ""} ago`;
  } else if (months < 12) {
    return `${months} month${months !== 1 ? "s" : ""} ago`;
  } else {
    return `${years} year${years !== 1 ? "s" : ""} ago`;
  }
}


// OUTPUT 18-12-2023 14:17
export function convertDateTimeFormat(inputDate: any) {
  const dateObject = new Date(inputDate);

  const day = dateObject.getDate();
  const month = dateObject.getMonth() + 1;
  const year = dateObject.getFullYear();
  const hours = dateObject.getHours();
  const minutes = dateObject.getMinutes();

  const formattedDate = `${day < 10 ? "0" : ""}${day}-${
    month < 10 ? "0" : ""
  }${month}-${year} ${hours < 10 ? "0" : ""}${hours}:${
    minutes < 10 ? "0" : ""
  }${minutes}`;

  return formattedDate;
}
// OUTPUT Date: 2023-12-04 , Time: 02:50 PM
export function formatDateAndTime(inputDate: any) {
  // Create a Date object from the input string
  const dateObject = new Date(inputDate);

  // Format the date part
  const formattedDate = `${dateObject.getFullYear()}-${String(
    dateObject.getMonth() + 1
  ).padStart(2, "0")}-${String(dateObject.getDate()).padStart(2, "0")}`;

  // Format the time part in 24-hour format
  const formattedTime = dateObject.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });

  // Return an object with separate properties for date and time
  return {
    date: formattedDate,
    time: formattedTime,
  };
}

export function convertTime(time: any) {
  let hour = time[0] + time[1];
  let min = time[3] + time[4];
  let part = hour > 12 ? "PM" : "AM";
  hour =
    hour > 12
      ? String(hour - 12).padStart(2, "0")
      : String(hour).padStart(2, "0");
  let new_time = hour;
  new_time += ":";
  new_time += min;
  // new_time += " ";
  new_time += part;

  return new_time;
}

export function addOneHour(time: string): string {
  let [hours, minutes] = time?.split(":");
  let ampm = minutes?.slice(-2);
  if (parseInt(hours) === 12) {
    hours = "01";
  } else {
    hours = String(parseInt(hours) + 1).padStart(2, "0");
  }
  if(parseInt(hours) === 12){
    ampm = ampm === "AM" ? "PM" : "AM";
  }

  return `${hours}:${minutes?.slice(0, 2)}${ampm}`;
}