import React, { useState } from "react";

interface PlusSVGProps {
  marginLeft?: string;
  className?: string;
  width?: string;
  height?: string;
  onClick?: any;
  isGoalsModal?: boolean;
}

const PlusSVG = ({
  marginLeft,
  className,
  onClick,
  height,
  width,
  isGoalsModal,
}: PlusSVGProps) => {
  const [mouseOverAddDoc, setmouseOverAddDoc] = useState(true);

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 12"
      fill={"#F3F3F3"}
      style={{
        marginLeft: marginLeft,
        cursor: "pointer",
      }}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      onMouseEnter={() => setmouseOverAddDoc(false)}
      onMouseLeave={() => setmouseOverAddDoc(true)}
      onClick={(e) => {
        onClick(e);
      }}
    >
      <circle cx="6" cy="6" r="6" fill={!mouseOverAddDoc ? "white" : "white"} />
      <path
        d="M6.83126 5.21952H9.99609V6.79447H6.83126V9.99805H5.16093V6.79447H1.99609V5.21952H5.16093V1.99805H6.83126V5.21952Z"
        fill={!mouseOverAddDoc ? "#87CEFA" : "#F3F3F3"}
      />
    </svg>
  );
};

PlusSVG.defaultProps = {
  marginLeft: "0px",
  className: "",
  onClick: () => {},
  height: "12",
  width: "12",
};

export default PlusSVG;
