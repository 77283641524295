export const majorReligions = [
    "Christianity",
    "Islam",
    "Hinduism",
    "Buddhism",
    "Sikhism",
    "Judaism",
    "Bahá'í Faith",
    "Jainism",
    "Shinto",
    "Taoism",
    "Zoroastrianism",
    "Wicca",
    "Spiritism",
    "Cao Dai",
    "Tenrikyo",
    "Rastafari",
    "Druidry",
    "Shamanism",
    "Heathenry",
    "Paganism",
    "Yazidism",
    "Atheism",
    "Agnosticism",
    "Humanism",
    "New Age",
    "Raelism",
    "Unitarian Universalism",
    "Vodou",
    "Asatru",
    "Odinism",
    "Candomblé",
    "Santería",
    "Voodoo",
    "Hellenism",
    "Native American Spirituality",
    "Neo-Paganism",
    "Eckankar",
    "Seventh-day Adventist Church",
    "Jehovah's Witnesses",
    "Church of Scientology",
    "Mormonism",
    "Catholicism",
    "Protestantism",
    "Orthodoxy",
    "Sunni Islam",
    "Shi'a Islam",
    "Alevism",
    "Alawism",
    "Ahmadiyya",
    "Ismailism",
    "Druze",
  ];
  