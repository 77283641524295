import React from "react";
import CrossSVGTrafficLights from "./Data/CrossSVGTrafficLights";
import CrossSVGTrafficLightsSm from "./Data/CrossSVGTrafficLightsSm";

const TriangularTrafficLightsSm = ({onChangeTrafficLight}: any) => {
  return (
    <div
      style={{
        position: "absolute",
        width: "18px",
        height: "68px",
        borderRadius: "13px",
        right: "6px",
        display: "flex",
        flexDirection: "column",
        zIndex: 9,
        top:"0px"
      }}
    >
      <CrossSVGTrafficLightsSm onChangeTrafficLight={onChangeTrafficLight}/>
      <span
        style={{
          zIndex: 9999,
        }}
      ></span>
    </div>
  );
};

export default TriangularTrafficLightsSm;
