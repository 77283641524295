import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { api } from "../../../services/api";

const initialState: any = {
  LocationPage: 1,
  LocationFilterItems: {
    filterColumn: "",
    filterValue: "",
    searchVal: "",
    sortColumn: "",
    sortType: "",
    LocationPage: 1,
    status: "1",
  },
  location: [],
  lastPage: 100000,
  getUserTypeIdForLocation: null,
  allLocationUserData: [],
  getUniqueLocationUser: [],
  getLocationName: [],
  getLocationEmail: [],
  getLocationPhone: [],
  getLocationStatus: [],
  openFormLocation: false,
  locationRoles: [],
  getLocationTypeResidence: [],
  getLocationTypeBuilding: [],
  getLocationTypeSil: [],
  getLocationTypeWard: [],
  getLocationTypeRoom: [],
  getLocationTypeTable: [],
  geAssociationtLocationTypeBuilding: [],
  geAssociationtLocationTypeWard: [],
  geAssociationtLocationTypeRoom: [],
  typeAssociation: "",

  searchValLocation: "",
  selectedDropdownItemLocation: "",
  selectedItemIndexFilterLocation: "",
  selectedItemIndexSortLocation: "",
  sortByLocationR: "",
  selectedFilterItemsLocation: [],

  loading: false,
};

export const getLocationUserThunk = createAsyncThunk(
  "location,fetchLocation",
  async (dynamicId: number | any) => {
    try {
      const response = await api.get(
        `/users?paginate=true&page=1&limit=1000&status=1&type=${dynamicId}`
      );
      return response.data;
    } catch (err) {
      throw err;
    }
  }
);
export const getLocationUserByFilterThunk = createAsyncThunk(
  "locations/fetchLocationsByFilter",
  async ({
    dynamicId,
    filterColumn,
    filterValue,
    searchVal,
    sortColumn,
    sortType,
    status,
    LocationPage,
  }: {
    dynamicId: object | any;
    filterColumn: string;
    filterValue: string;
    searchVal: string;
    sortColumn: string;
    sortType: string | any;
    status: string | any;
    LocationPage: string | any;
  }) => {
    try {
      const encodedFilterVal = encodeURIComponent(filterValue);
      const encodedSearchVal = encodeURIComponent(searchVal);
      const response = await api.get(
        `/users?paginate=true&page=${LocationPage}&sort_column=${sortColumn}&sort_type=${sortType}&status=${status}&filter_column=${filterColumn}&type=${dynamicId}&limit=10&filter_value=${encodedFilterVal}&search=${encodedSearchVal}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
export const getLocationsUserByIdThunk = createAsyncThunk(
  "location/getLocationById",
  async ({ dynamicId, locationId }: any) => {
    try {
      const response = await api.get(
        `/users?type=${dynamicId}&id=${locationId}&paginate=true`
      );
      return response.data;
    } catch (err) {
      throw err;
    }
  }
);
export const getLocationsTypesResidenceThunk = createAsyncThunk(
  "location/getLocationTypeResidence",
  async () => {
    try {
      const response = await api.get(
        `/users/get_locations_from_type?location_type=Residence`
      );
      return response.data;
    } catch (err) {
      throw err;
    }
  }
);

export const getLocationsTypesSilThunk = createAsyncThunk(
  "location/getLocationsTypesSilThunk",
  async () => {
    try {
      const response = await api.get(
        `/users/get_locations_from_type?location_type=Sil`
      );
      return response.data;
    } catch (err) {
      throw err;
    }
  }
);
export const getLocationsTypesBuildingThunk = createAsyncThunk(
  "location/getLocationTypeBuilding",
  async () => {
    try {
      const response = await api.get(
        `/users/get_locations_from_type?location_type=Building`
      );
      return response.data;
    } catch (err) {
      throw err;
    }
  }
);
export const getLocationsTypesWardThunk = createAsyncThunk(
  "location/getLocationTypeWard",
  async () => {
    try {
      const response = await api.get(
        `/users/get_locations_from_type?location_type=Ward`
      );
      return response.data;
    } catch (err) {
      throw err;
    }
  }
);
export const getLocationsTypesRoomThunk = createAsyncThunk(
  "location/getLocationTypeRoom",
  async () => {
    try {
      const response = await api.get(
        `/users/get_locations_from_type?location_type=Room`
      );
      return response.data;
    } catch (err) {
      throw err;
    }
  }
);
export const getLocationsTypesTableThunk = createAsyncThunk(
  "location/getLocationTypeTable",
  async () => {
    try {
      const response = await api.get(
        `/users/get_locations_from_type?location_type=Table`
      );
      return response.data;
    } catch (err) {
      throw err;
    }
  }
);
export const getAssociationLocationsTypeBuildingThunk = createAsyncThunk(
  "location/getAssociationLocationTypeBuilding",
  async () => {
    try {
      const response = await api.get(
        `/associations/get_locations_from_type?location_type=Building`
      );
      return response.data;
    } catch (err) {
      throw err;
    }
  }
);
export const getAssociationLocationsTypeWardThunk = createAsyncThunk(
  "location/getAssociationLocationTypeWard",
  async () => {
    try {
      const response = await api.get(
        `/users/get_locations_from_type?location_type=Ward`
      );
      return response.data;
    } catch (err) {
      throw err;
    }
  }
);
export const getAssociationLocationsTypeRoomThunk = createAsyncThunk(
  "location/getAssociationLocationTypeRoom",
  async () => {
    try {
      const response = await api.get(
        `/users/get_locations_from_type?location_type=Room`
      );
      return response.data;
    } catch (err) {
      throw err;
    }
  }
);

export const updateLocationUserThunk = createAsyncThunk(
  "location/updateLocation",
  async (locationsData: any, { rejectWithValue }) => {
    try {
      const response = await api.post("/users/update_user", locationsData);
      return response.data;
    } catch (error: string | any) {
      return rejectWithValue(
        error?.response?.data?.response || "Failed to upload locations details"
      );
    }
  }
);
export const getLocationUserNameThunk = createAsyncThunk(
  "location/fetchName",
  async (dynamicId: number | any) => {
    try {
      const { data } = await api.get(
        `/users?unique_column=name&type=${dynamicId}`
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
);

export const getProviderLocationUserNameThunk = createAsyncThunk(
  "providerLocation/fetchName",
  async ({ dynamicUserTypeId, dynamicProviderId }: any) => {
    try {
      const { data } = await api.get(
        `/providers/get_provider_users?unique_column=name&type=${dynamicUserTypeId}&tenant_id=${dynamicProviderId}`
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
);
export const getLocationEmailThunk = createAsyncThunk(
  "location/fetchEmail",
  async (dynamicId: number | any) => {
    try {
      const { data } = await api.get(
        `/users?unique_column=email&type=${dynamicId}`
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
);

export const getProviderLocationEmailThunk = createAsyncThunk(
  "providerLocation/fetchEmail",
  async ({ dynamicUserTypeId, dynamicProviderId }: any) => {
    try {
      const { data } = await api.get(
        `/providers/get_provider_users?unique_column=email&type=${dynamicUserTypeId}&tenant_id=${dynamicProviderId}`
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
);

export const getLocationPhoneThunk = createAsyncThunk(
  "location/fetchPhone",
  async (dynamicId: number | any) => {
    try {
      const { data } = await api.get(
        `/users?unique_column=phone&type=${dynamicId}`
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
);

export const getProviderLocationPhoneThunk = createAsyncThunk(
  "providerLocation/fetchPhone",
  async ({ dynamicUserTypeId, dynamicProviderId }: any) => {
    try {
      const { data } = await api.get(
        `/providers/get_provider_users?unique_column=phone&type=${dynamicUserTypeId}&tenant_id=${dynamicProviderId}`
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
);

export const getLocationStatusThunk = createAsyncThunk(
  "location/fetchStatus",
  async (dynamicId: number | any) => {
    try {
      const { data } = await api.get(
        `/users?unique_column=status&type=${dynamicId}`
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
);

export const getProviderLocationStatusThunk = createAsyncThunk(
  "providerLocation/fetchStatus",
  async ({ dynamicUserTypeId, dynamicProviderId }: any) => {
    try {
      const { data } = await api.get(
        `/providers/get_provider_users?unique_column=status&type=${dynamicUserTypeId}&tenant_id=${dynamicProviderId}`
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
);

export const getUserRolesLocationThunk = createAsyncThunk(
  "location/fetchUserRoles",
  async (dynamicLocationId: any) => {
    try {
      const response = await api.get(
        `/users/get_user_roles?type=${dynamicLocationId}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
export const createAssociationLocationThunk = createAsyncThunk(
  "location/associations/createLocationAssociation",
  async (formData: any, { rejectWithValue }) => {
    try {
      const response = await api.post(
        "/associations/create_location_association",
        formData
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.response);
    }
  }
);

//Provider Location Thunk
export const getProviderLocationByIdThunk = createAsyncThunk(
  "providerLocation/fetchProviderLocationById",
  async ({ typeId, clientId, tenant_id }: any) => {
    try {
      const response = await api.get(
        `/providers/get_provider_users?paginate=true&type=${typeId}&sort_column=id&sort_type=desc&id=${clientId}&tenant_id=${tenant_id}`
      );
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
);

export const locationSlice = createSlice({
  name: "location",
  initialState,
  reducers: {
    // Pagination Start
    setLocationPage: (state) => {
      state.LocationPage += 1;
    },
    resetLocationPage: (state) => {
      state.LocationPage = 1;
    },
    resetLocationData: (state) => {
      state.allLocationUserData = {};
      state.location = [];
    },
    setLocationFilterItems: (state, action) => {
      state.LocationFilterItems = action.payload;
    },
    resetLocationFilterItems: (state) => {
      state.LocationFilterItems = {
        filterColumn: "",
        filterValue: "",
        searchVal: "",
        sortColumn: "",
        sortType: "",
        LocationPage: 1,
        status: "1",
      };
    },
    setTypeAssociation: (state, action) => {
      state.typeAssociation = action.payload;
    },
    resetTypeAssociation: (state) => {
      state.typeAssociation = "";
    },
    // Pagination End
    setUserTypeIdForLocation: (state, action) => {
      state.getUserTypeIdForLocation = action.payload;
    },
    clearCurrentLocationData: (state) => {
      state.getUniqueLocationUser = null;
    },
    setOpenFormByClickLocation: (state) => {
      state.openFormLocation = true;
    },
    resetOpenFormByClickLocation: (state) => {
      state.openFormLocation = false;
    }, // Filter Dropdown
    setSearchValueLocation: (state, action) => {
      state.searchValLocation = action.payload;
    },
    resetSearchValueLocation: (state) => {
      state.searchValLocation = "";
    },
    setSelectedFilterOptionsLocation: (state) => {
      state.selectedFilterOptionsLocation = true;
    },
    resetSelectedFilterOptionsLocation: (state) => {
      state.selectedFilterOptionsLocation = false;
    },
    setSelectedDropdownItemLocation: (state, action) => {
      state.selectedDropdownItemLocation = action.payload;
    },
    resetSelectedDropdownItemLocation: (state) => {
      state.selectedDropdownItemLocation = "";
    },
    setSelectedItemIndexFilterLocation: (state, action) => {
      state.selectedItemIndexFilterLocation = action.payload;
    },
    resetSelectedItemIndexFilterLocation: (state) => {
      state.selectedItemIndexFilterLocation = "";
    },
    setSelectedItemIndexSortLocation: (state, action) => {
      state.selectedItemIndexSortLocation = action.payload;
    },
    resetSelectedItemIndexSortLocation: (state) => {
      state.selectedItemIndexSortLocation = "";
    },
    setSortByLocationR: (state, action) => {
      state.sortByLocationR = action.payload;
    },
    resetSortByLocationR: (state) => {
      state.sortByLocationR = "";
    },
    setSelectedFilterItemsLocation: (state, action) => {
      state.selectedFilterItemsLocation = action.payload;
    },
    resetSelectedFilterItemsLocation: (state) => {
      state.selectedFilterItemsLocation = [];
    },
    setLocationTypeBuilding: (state, action) => {
      state.getLocationTypeBuilding = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLocationUserThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getLocationUserThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.allLocationUserData = action.payload;
        state.location = action.payload.data.data;
        // state.location = [...action.payload.data.data, ...state.location];
      })
      .addCase(getLocationUserThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      // getLocationsTypesSilThunk
      .addCase(getLocationUserByFilterThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getLocationUserByFilterThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.lastPage = action.payload.data.last_page;
        state.location = [...state.location, ...action.payload.data.data];

        state.allLocationUserData = action.payload;
      })
      .addCase(getLocationUserByFilterThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getLocationsUserByIdThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getLocationsUserByIdThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.getUniqueLocationUser = action.payload;
      })
      .addCase(getLocationsUserByIdThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getLocationUserNameThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getLocationUserNameThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.getLocationName = action.payload;
      })
      .addCase(getLocationUserNameThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      // Provider Name
      .addCase(getProviderLocationUserNameThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProviderLocationUserNameThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.getLocationName = action.payload;
      })
      .addCase(getProviderLocationUserNameThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getLocationEmailThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getLocationEmailThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.getLocationEmail = action.payload;
      })
      .addCase(getLocationEmailThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(getProviderLocationEmailThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProviderLocationEmailThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.getLocationEmail = action.payload;
      })
      .addCase(getProviderLocationEmailThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(getLocationPhoneThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getLocationPhoneThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.getLocationPhone = action.payload;
      })
      .addCase(getLocationPhoneThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(getProviderLocationPhoneThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProviderLocationPhoneThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.getLocationPhone = action.payload;
      })
      .addCase(getProviderLocationPhoneThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(getLocationStatusThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getLocationStatusThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.getLocationStatus = action.payload;
      })
      .addCase(getLocationStatusThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(getProviderLocationStatusThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProviderLocationStatusThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.getLocationStatus = action.payload;
      })
      .addCase(getProviderLocationStatusThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(getUserRolesLocationThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUserRolesLocationThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.locationRoles = action.payload;
      })
      .addCase(getUserRolesLocationThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getLocationsTypesResidenceThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getLocationsTypesResidenceThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.getLocationTypeResidence = action.payload;
      })
         .addCase(getLocationsTypesSilThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.getLocationTypeSil = action.payload;
      })
      .addCase(getLocationsTypesResidenceThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getLocationsTypesBuildingThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getLocationsTypesBuildingThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.getLocationTypeBuilding = action.payload;
      })
      .addCase(getLocationsTypesBuildingThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getLocationsTypesRoomThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getLocationsTypesRoomThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.getLocationTypeRoom = action.payload;
      })
      .addCase(getLocationsTypesRoomThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getLocationsTypesWardThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getLocationsTypesWardThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.getLocationTypeWard = action.payload;
      })
      .addCase(getLocationsTypesWardThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getLocationsTypesTableThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getLocationsTypesTableThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.getLocationTypeTable = action.payload;
      })
      .addCase(getLocationsTypesTableThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getAssociationLocationsTypeBuildingThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        getAssociationLocationsTypeBuildingThunk.fulfilled,
        (state, action) => {
          state.loading = false;
          state.geAssociationtLocationTypeBuilding = action.payload.data;
        }
      )
      .addCase(
        getAssociationLocationsTypeBuildingThunk.rejected,
        (state, action) => {
          state.loading = false;
          state.error = action.error.message;
        }
      )
      .addCase(getAssociationLocationsTypeWardThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        getAssociationLocationsTypeWardThunk.fulfilled,
        (state, action) => {
          state.loading = false;
          state.geAssociationtLocationTypeWard = action.payload.data;
        }
      )
      .addCase(
        getAssociationLocationsTypeWardThunk.rejected,
        (state, action) => {
          state.loading = false;
          state.error = action.error.message;
        }
      )
      .addCase(getAssociationLocationsTypeRoomThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        getAssociationLocationsTypeRoomThunk.fulfilled,
        (state, action) => {
          state.loading = false;
          state.geAssociationtLocationTypeRoom = action.payload.data;
        }
      )
      .addCase(
        getAssociationLocationsTypeRoomThunk.rejected,
        (state, action) => {
          state.loading = false;
          state.error = action.error.message;
        }
      )
      //Provider Unique Location
      .addCase(getProviderLocationByIdThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProviderLocationByIdThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.getUniqueLocationUser = action.payload;
      })
      .addCase(getProviderLocationByIdThunk.rejected, (state, action) => {
        (state.loading = false), (state.error = action.error.message);
      });
  },
});

export const {
  setTypeAssociation,
  resetTypeAssociation,
  setUserTypeIdForLocation,
  clearCurrentLocationData,
  setOpenFormByClickLocation,
  resetOpenFormByClickLocation,
  setSelectedDropdownItemLocation, // Filter
  setSearchValueLocation,
  resetSearchValueLocation,
  setSelectedFilterOptionsLocation,
  resetSelectedFilterOptionsLocation,
  resetSelectedDropdownItemLocation,
  setSelectedItemIndexFilterLocation,
  resetSelectedItemIndexFilterLocation,
  setSelectedItemIndexSortLocation,
  resetSelectedItemIndexSortLocation,
  setSortByLocationR,
  resetSortByLocationR,
  setSelectedFilterItemsLocation,
  resetSelectedFilterItemsLocation,

  // Page
  setLocationPage,
  resetLocationPage,
  resetLocationData,
  setLocationFilterItems,
  resetLocationFilterItems,

  setLocationTypeBuilding,
} = locationSlice.actions;
export default locationSlice.reducer;
