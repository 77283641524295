import { Fragment, useEffect, useRef, useState } from "react";
import CommonUpperBox from "../../../components/UpperBox/CommonHeader";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../Controller/redux/Hooks";
import Table from "../../../components/CommonTable/Table";
import CircularNoteTrigger from "../../../components/CircularNoteTrigger/CircularNoteTrigger";
import { useNavigate } from "react-router-dom";
import {
  getServiceTypeByFilterThunk,
  getServiceTypeNameThunk,
  getServiceTypeTaxRateThunk,
  resetServiceTypeData,
  resetServiceTypePage,
  setServiceTypePage,
} from "../../../../Controller/redux/features/RefDataFeature/ServiceTypeFeature/serviceTypeSlice";
import CommonQuickMenu from "../../../components/CommonQuickMenu/CommonQuickMenu";
import { getStatusDetailsThunk } from "../../../../Controller/redux/features/CommonFeature/commonSlice";
const ServiceType = () => {
  const {
    serviceTypePage,
    serviceTypeData,
    loading,
    serviceTypeFilterItems,
    lastPage,
    selectedFilterItemsServiceType,
    sortByServiceTypeR,
    searchValServiceType,
  } = useAppSelector((state) => state.serviceType);

  const { domainDetail, filterIconColor } = useAppSelector(
    (state) => state?.common
  );

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getStatusDetailsThunk("1,0"));
  }, []);

  useEffect(() => {
    dispatch(getServiceTypeNameThunk());
    dispatch(getServiceTypeTaxRateThunk());
  }, []);
  useEffect(() => {
    dispatch(resetServiceTypeData());
    dispatch(resetServiceTypePage());
  }, [
    serviceTypeFilterItems?.searchVal,
    serviceTypeFilterItems?.filterValue,
    serviceTypeFilterItems?.sortType,
  ]);
  useEffect(() => {
    dispatch(
      getServiceTypeByFilterThunk({
        filterColumn: serviceTypeFilterItems?.filterColumn
          ? serviceTypeFilterItems?.filterColumn
          : "",
        filterValue: serviceTypeFilterItems?.filterValue
          ? serviceTypeFilterItems?.filterValue
          : "",
        searchVal: serviceTypeFilterItems?.searchVal
          ? serviceTypeFilterItems?.searchVal
          : "",
        sortColumn: serviceTypeFilterItems?.sortColumn
          ? serviceTypeFilterItems?.sortColumn
          : "",
        sortType: serviceTypeFilterItems?.sortType
          ? serviceTypeFilterItems?.sortType
          : "",
        serviceTypePage: 1,
      })
    );
  }, [
    serviceTypePage,
    serviceTypeFilterItems?.searchVal,
    serviceTypeFilterItems?.filterValue,
    serviceTypeFilterItems?.sortType,
  ]);

  const uniqueServiceData = serviceTypeData?.filter(
    (item: any, index: number, array: any) => {
      return array?.findIndex((obj: any) => obj.id === item.id) === index;
    }
  );

  const columns = [
    { header: "Name", accessor: "fundingName" },
    {
      header: "Billing Rule",
      accessor: "funding_category",
    },
    { header: "Tax Rate", accessor: "tax_rate" },
    { header: "Status", accessor: "status" },
  ];

  const tableRef = useRef();
  const fundingTypeInnerRef = useRef();

  const clientStatusDetailsArr = useAppSelector(
    (state) => state.common.statusDetailsData?.data
  );

  const dropdownRefFundingType = useRef<any>();
  const svgParentRefFundingType = useRef<any>();
  const [isDropdownOpenFundingType, setIsDropdownOpenFundingType] =
    useState(false);

  const handleClickRow = (e: any, id: any) => {
    if (!isDropdownOpenFundingType) {
      navigate(`/ref-data/service-type/${window.btoa(id)}`);
    }
  };

  useEffect(() => {
    const handleClickOutsideClients = (event: any) => {
      if (
        dropdownRefFundingType.current &&
        !dropdownRefFundingType.current.contains(event.target) &&
        svgParentRefFundingType.current &&
        !svgParentRefFundingType.current.contains(event.target)
      ) {
        setIsDropdownOpenFundingType(false);
      }
    };
    document.addEventListener("click", handleClickOutsideClients);
    return () => {
      document.removeEventListener("click", handleClickOutsideClients);
    };
  }, []);
  const onScroll = () => {
    if (fundingTypeInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } =
        fundingTypeInnerRef.current;

      if (
        scrollTop + clientHeight + +5 >= scrollHeight &&
        serviceTypePage < lastPage
      ) {
        dispatch(setServiceTypePage(serviceTypePage));
      }
    }
  };
  const [stageTwoToGoalsModal, setstageTwoToGoalsModal] = useState<any>(false);

  return (
    <Fragment>
      <CommonUpperBox
        title="Service Type"
        type={"listing"}
        backgroundWithText={true}
        text={"S T"}
        onClickPlus={() => {
          setstageTwoToGoalsModal(true);
        }}
      />
      <Table
        isLoading={loading}
        tableRef={tableRef}
        tableBodyRef={fundingTypeInnerRef}
        onScroll={onScroll}
        dynamicHeaderColor={domainDetail?.listingtbl_color}
        statusType="clientScreen"
        thData={columns}
        itemsArr={clientStatusDetailsArr}
        classNameTr={"all-funding-type-table-head"}
        type="fundingType"
        dropdownRef={dropdownRefFundingType}
        isDropdownOpen={isDropdownOpenFundingType}
        onClickSvg={() => setIsDropdownOpenFundingType(true)}
        svgParentRef={svgParentRefFundingType}
        allClients={uniqueServiceData}
        onClickRow={handleClickRow}
        classNameRow={"all-funding-type-table-body"}
        fill={
          selectedFilterItemsServiceType.length > 0 ||
          searchValServiceType.length > 0 ||
          sortByServiceTypeR.length > 0
            ? filterIconColor
            : "#D8D3D3"
        }
      />
      <CircularNoteTrigger type="funding" title="Add Service" />
      {stageTwoToGoalsModal && (
        <CommonQuickMenu
          setshowGoalsModal={(value: any) => {}}
          mainType={"listing"}
          setstageTwoToGoalsModal={setstageTwoToGoalsModal}
        />
      )}
    </Fragment>
  );
};

export default ServiceType;
