import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { api } from "../../../services/api";

const initialState: any = {
  getLastUpdatedClients: [],
  getSilDetails: [],
  getSilStatus: {},
  getStatusStats: [],
  getStatusStatsProspect: [],
  getRACDetails: [],
  getVillageData: [],
};

export const getLastUpdateClientThunk = createAsyncThunk(
  "villages/lastUpdate",
  async (dynamicClientId: any) => {
    try {
      const { data } = await api.get(
        `/users/get_last_updated_user_details?type=${dynamicClientId}&days=3`
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
);
export const getVillageThunk = createAsyncThunk(
  "villages/villageData",
  async () => {
    try {
      const { data } = await api.get(`/users/get_village_details`);
      return data;
    } catch (error) {
      throw error;
    }
  }
);

export const getSilDetailsThunk = createAsyncThunk(
  "villages/silDetails",
  async ({ type, dynamicLocationType }: any) => {
    try {
      const { data } = await api.get(
        `/users/get_sil_details?type=${type}&location_type=${dynamicLocationType}`
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
);

export const getSilStatusThunk = createAsyncThunk(
  "villages/silStatus",
  async ({ dynamicStaffId, date }: any) => {
    try {
      const { data } = await api.get(
        `/users/get_sil_stats?type=${dynamicStaffId}&work_hours_in_minutes=3600&work_hours_days=28&individual_task=20&individual_task_days=28&days=28&working_hours_of_day_in_minutes=720&date=${date}&less_than_hours_between_shifts_in_minutes=120&more_than_hours_between_shifts_in_minutes=30&less_than_shift_period_in_minutes=120`
      );
      return data?.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getRACDetailsThunk = createAsyncThunk(
  "villages/racDetails",
  async ({ type }: any) => {
    try {
      const { data } = await api.get(`/users/get_rac_details?type=${type}`);
      return data;
    } catch (error) {
      throw error;
    }
  }
);
export const getStatusStatsThunk = createAsyncThunk(
  "villages/statusStats",
  async ({ dynamicId, status }: any) => {
    try {
      const { data } = await api.get(
        `/users/get_status_stats?type=${dynamicId}&status=${status}`
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
);

export const getStatusStatsProspectsThunk = createAsyncThunk(
  "villages/statusStatsProspects",
  async ({ dynamicId, status }: any) => {
    try {
      const { data } = await api.get(
        `/users/get_status_stats?type=${dynamicId}&status=${status}`
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
);

export const villageSlice = createSlice({
  name: "villages",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getLastUpdateClientThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getLastUpdateClientThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.getLastUpdatedClients = action.payload;
      })
      .addCase(getLastUpdateClientThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getStatusStatsProspectsThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getStatusStatsProspectsThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.getStatusStatsProspect = action.payload;
      })
      .addCase(getStatusStatsProspectsThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getSilDetailsThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSilDetailsThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.getSilDetails = action.payload;
      })
      .addCase(getSilDetailsThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getSilStatusThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSilStatusThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.getSilStatus = action.payload;
      })
      .addCase(getSilStatusThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getStatusStatsThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getStatusStatsThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.getStatusStats = action.payload;
      })
      .addCase(getStatusStatsThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getRACDetailsThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getRACDetailsThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.getRACDetails = action.payload;
      })
      .addCase(getRACDetailsThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getVillageThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getVillageThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.getVillageData = action.payload.data;
      })
      .addCase(getVillageThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});
export const {} = villageSlice.actions;
export default villageSlice.reducer;
