import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { api } from "../../../services/api";

const initialState: any = {
  loading: false,
  BusinessPage: 1,
  BusinessFilterItems: {
    status: "1",
    filterColumn: "",
    filterValue: "",
    searchVal: "",
    sortColumn: "",
    sortType: "",
    BusinessPage: 1,
  },
  lastPage: 100000,
  business: [],
  getUserTypeIdForBusiness: null,
  getBusinessName: [],
  getBusinessEmail: [],
  getBusinessPhone: [],
  getBusinessStatus: [],
  allDataBusiness: [],
  getUniqueBusiness: null,
  selectedFilterOptionsBusiness: false,
  selectedSortOptionsBusiness: [],
  isBusinessFilterDropdownOpen: false,
  entityRoles: [],
  //  // STATES OF SELECTED FILTER GOES HERE
  searchValBusiness: "",
  selectedDropdownItemBusiness: "",
  selectedItemIndexFilterBusiness: "",
  selectedItemIndexSortBusiness: "",
  sortByBusinessR: "",
  selectedFilterItemsBusiness: [],
  //Provider business
  getUniqueProviderBusiness: {},
};

export const getBusinessNameThunk = createAsyncThunk(
  "business/fetchName",
  async (dynamicBusinessId: number | any) => {
    try {
      const { data } = await api.get(
        `/users?unique_column=name&type=${dynamicBusinessId}`
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
);

export const getProviderBusinessNameThunk = createAsyncThunk(
  "providerBusiness/fetchName",
  async ({ dynamicUserTypeId, dynamicProviderId }: any) => {
    try {
      const { data } = await api.get(
        `/providers/get_provider_users?unique_column=name&type=${dynamicUserTypeId}&tenant_id=${dynamicProviderId}`
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
);

export const getBusinessEmailThunk = createAsyncThunk(
  "users/fetchEmail",
  async (dynamicBusinessId: number | any) => {
    try {
      const { data } = await api.get(
        `/users?unique_column=email&type=${dynamicBusinessId}`
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
);

export const getProviderBusinessEmailThunk = createAsyncThunk(
  "providerBusiness/fetchEmail",
  async ({ dynamicUserTypeId, dynamicProviderId }: any) => {
    try {
      const { data } = await api.get(
        `/providers/get_provider_users?unique_column=email&type=${dynamicUserTypeId}&tenant_id=${dynamicProviderId}`
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
);

export const getBusinessPhoneThunk = createAsyncThunk(
  "users/fetchPhone",
  async (dynamicBusinessId: number | any) => {
    try {
      const { data } = await api.get(
        `/users?unique_column=phone&type=${dynamicBusinessId}`
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
);

export const getProviderBusinessPhoneThunk = createAsyncThunk(
  "providerBusiness/fetchPhone",
  async ({ dynamicUserTypeId, dynamicProviderId }: any) => {
    try {
      const { data } = await api.get(
        `/providers/get_provider_users?unique_column=phone&type=${dynamicUserTypeId}&tenant_id=${dynamicProviderId}`
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
);

export const getBusinessStatusThunk = createAsyncThunk(
  "business/fetchStatus",
  async (dynamicBusinessId: number | any) => {
    try {
      const { data } = await api.get(
        `/users?unique_column=status&type=${dynamicBusinessId}`
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
);
export const getEntityRoleThunk = createAsyncThunk(
  "business/fetchRoles",
  async (dynamicId: number | any) => {
    try {
      const { data } = await api.get(`/users/get_user_roles?type=${dynamicId}`);
      return data;
    } catch (error) {
      throw error;
    }
  }
);

export const getBusinessThunk = createAsyncThunk(
  "business/fetchBusiness",
  async (dynamicBusinessId: number | any) => {
    try {
      const response = await api.get(
        `/users?paginate=true&page=1&limit=50&status=1&type=${dynamicBusinessId}`
      );
      return response.data;
    } catch (err) {
      throw err;
    }
  }
);
export const getBusinessByFilterThunk = createAsyncThunk(
  "business/fetchBusinessByFilter",
  async ({
    filterColumn,
    filterValue,
    searchVal,
    sortColumn,
    sortType,
    status,
    dynamicId,
    BusinessPage,
  }: {
    filterColumn: string | any;
    filterValue: string | any;
    searchVal: string | any;
    sortColumn: string | any;
    sortType: string | any;
    status: string | any;
    dynamicId: string | any;
    BusinessPage: string | any;
  }) => {
    const encodedFilterValue = encodeURIComponent(filterValue);

    try {
      const response = await api.get(
        `/users?paginate=true&page=${BusinessPage}&limit=50&search=${searchVal}&sort_column=${sortColumn}&sort_type=${sortType}&status=${status}&filter_column=${filterColumn}&filter_value=${encodedFilterValue}&type=${dynamicId}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getProviderBusinessByIdThunk = createAsyncThunk(
  "providerBusiness/fetchProviderBusinessById",
  async ({ typeId, clientId, tenant_id }: any) => {
    try {
      const response = await api.get(
        `/providers/get_provider_users?paginate=true&type=${typeId}&sort_column=id&sort_type=desc&id=${clientId}&tenant_id=${tenant_id}`
      );
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
);
export const updateBusinessThunk = createAsyncThunk(
  "business/updateBusiness",
  async (businessData: any, { rejectWithValue }) => {
    try {
      const response = await api.post("/users/update_user", businessData);
      return response.data;
    } catch (error: string | any) {
      return rejectWithValue(
        error.message || "Failed to upload client details"
      );
    }
  }
);

export const getBusinessByIdThunk = createAsyncThunk(
  "business/getBusinessById",
  async ({ dynamicId, businessId }: any) => {
    try {
      const response = await api.get(
        `/users?type=${dynamicId}&id=${businessId}&paginate=true`
      );
      return response.data;
    } catch (err) {
      throw err;
    }
  }
);
export const businessSlice: any = createSlice({
  name: "business",
  initialState,
  reducers: {
    // Pagination Start
    setBusinessPage: (state) => {
      state.BusinessPage += 1;
    },
    resetBusinessPage: (state) => {
      state.BusinessPage = 1;
    },
    resetBusinessData: (state) => {
      state.allDataBusiness = {};
      state.business = [];
    },
    setBusinessFilterItems: (state, action) => {
      state.BusinessFilterItems = action.payload;
    },
    resetBusinessFilterItems: (state) => {
      state.BusinessFilterItems = {
        status: "1",
        filterColumn: "",
        filterValue: "",
        searchVal: "",
        sortColumn: "",
        sortType: "",
        BusinessPage: 1,
      };
    },
    // Pagination End
    setUserTypeIdForBusiness: (state, action) => {
      state.getUserTypeIdForBusiness = action.payload;
    },
    setFilterOptionsBusiness: (state, action) => {
      state.selectedFilterOptionsBusiness = action.payload;
    },
    resetFilterOptionsBusiness: (state) => {
      state.selectedFilterOptionsBusiness = [];
    },
    setSortOptionsBusiness: (state, action) => {
      state.selectedSortOptionsBusiness = action.payload;
    },
    resetSortOptionsBusiness: (state) => {
      state.selectedSortOptionsBusiness = [];
    },
    setSearchValueBusiness: (state, action) => {
      state.searchValBusiness = action.payload;
    },
    resetSearchValueBusiness: (state) => {
      state.searchValBusiness = "";
    },
    setIsBusinessFilterDropdownOpen: (state) => {
      state.isBusinessFilterDropdownOpen = true;
    },
    resetIsBusinessFilterDropdownOpen: (state) => {
      state.isBusinessFilterDropdownOpen = false;
    }, // Filter Dropdown
    setSelectedFilterOptionsBusiness: (state) => {
      state.selectedFilterOptionsBusiness = true;
    },
    resetSelectedFilterOptionsBusiness: (state) => {
      state.selectedFilterOptionsBusiness = false;
    },
    setSelectedDropdownItemBusiness: (state, action) => {
      state.selectedDropdownItemBusiness = action.payload;
    },
    resetSelectedDropdownItemBusiness: (state) => {
      state.selectedDropdownItemBusiness = "";
    },
    setSelectedItemIndexFilterBusiness: (state, action) => {
      state.selectedItemIndexFilterBusiness = action.payload;
    },
    resetSelectedItemIndexFilterBusiness: (state) => {
      state.selectedItemIndexFilterBusiness = "";
    },
    setSelectedItemIndexSortBusiness: (state, action) => {
      state.selectedItemIndexSortBusiness = action.payload;
    },
    resetSelectedItemIndexSortBusiness: (state) => {
      state.selectedItemIndexSortBusiness = "";
    },
    setSortByBusinessR: (state, action) => {
      state.sortByBusinessR = action.payload;
    },
    resetSortByBusinessR: (state) => {
      state.sortByBusinessR = "";
    },
    setSelectedFilterItemsBusiness: (state, action) => {
      state.selectedFilterItemsBusiness = action.payload;
    },
    resetSelectedFilterItemsBusiness: (state) => {
      state.selectedFilterItemsBusiness = "";
    },
    clearCurrentBusinessData: (state) => {
      state.getUniqueBusiness = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBusinessThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getBusinessThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.allDataBusiness = action.payload;
      })
      .addCase(getBusinessThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      }) //
      // Provider Unique Business
      .addCase(getProviderBusinessByIdThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProviderBusinessByIdThunk.fulfilled, (state, action) => {
       state.getUniqueBusiness = action.payload;
      state.loading = false;
      })
      .addCase(getProviderBusinessByIdThunk.rejected, (state, action)=>{
        state.loading = false;
        state.getUniqueProviderBusiness = action.error.message;
      })
      .addCase(getBusinessByFilterThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getBusinessByFilterThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.lastPage = action.payload.data.last_page;
        state.business = [...state.business, ...action.payload.data.data];
        state.allDataBusiness = action.payload;
      })
      .addCase(getBusinessByFilterThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      }); 
    builder.addCase(getBusinessByIdThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getBusinessByIdThunk.fulfilled, (state, action) => {
      state.getUniqueBusiness = action.payload;
      state.loading = false;
    });
    builder
      .addCase(getBusinessByIdThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      }) 
      .addCase(getBusinessNameThunk.pending, (state) => {
        state.loading === true;
      })
      .addCase(getBusinessNameThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.getBusinessName = action.payload;
      })
      .addCase(getBusinessNameThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      }) 
      .addCase(getProviderBusinessNameThunk.pending, (state) => {
        state.loading === true;
      })
      .addCase(getProviderBusinessNameThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.getBusinessName = action.payload;
      })
      .addCase(getProviderBusinessNameThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      }) 
      .addCase(getBusinessEmailThunk.pending, (state) => {
        state.loading === true;
      })
      .addCase(getBusinessEmailThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.getBusinessEmail = action.payload;
      })
      .addCase(getBusinessEmailThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      }) 
      .addCase(getProviderBusinessEmailThunk.pending, (state) => {
        state.loading === true;
      })
      .addCase(getProviderBusinessEmailThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.getBusinessEmail = action.payload;
      })
      .addCase(getProviderBusinessEmailThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getBusinessPhoneThunk.pending, (state) => {
        state.loading === true;
      })
      .addCase(getBusinessPhoneThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.getBusinessPhone = action.payload;
      })
      .addCase(getBusinessPhoneThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      }) 
      .addCase(getProviderBusinessPhoneThunk.pending, (state) => {
        state.loading === true;
      })
      .addCase(getProviderBusinessPhoneThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.getBusinessPhone = action.payload;
      })
      .addCase(getProviderBusinessPhoneThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getBusinessStatusThunk.pending, (state) => {
        state.loading === true;
      })
      .addCase(getBusinessStatusThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.getBusinessStatus = action.payload;
      })
      .addCase(getBusinessStatusThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getEntityRoleThunk.pending, (state) => {
        state.loading === true;
      })
      .addCase(getEntityRoleThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.entityRoles = action.payload;
      })
      .addCase(getEntityRoleThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const {
  setUserTypeIdForBusiness,
  setFilterOptionsBusiness,
  resetFilterOptionsBusiness,
  setSortOptionsBusiness,
  resetSortOptionsBusiness,
  setSearchValueBusiness,
  resetSearchValueBusiness,
  setIsBusinessFilterDropdownOpen,
  resetIsBusinessFilterDropdownOpen,
  clearCurrentBusinessData,
  setSelectedDropdownItemBusiness, // Filter
  setSelectedFilterOptionsBusiness,
  resetSelectedFilterOptionsBusiness,
  resetSelectedDropdownItemBusiness,
  setSelectedItemIndexFilterBusiness,
  resetSelectedItemIndexFilterBusiness,
  setSelectedItemIndexSortBusiness,
  resetSelectedItemIndexSortBusiness,
  setSortByBusinessR,
  resetSortByBusinessR,
  setSelectedFilterItemsBusiness,
  resetSelectedFilterItemsBusiness,
  // Page
  setBusinessPage,
  resetBusinessPage,
  resetBusinessData,
  setBusinessFilterItems,
  resetBusinessFilterItems,
} = businessSlice.actions;
export default businessSlice.reducer;
