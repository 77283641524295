import React from "react";
import { ReplyIcon } from "../Data/SVGs";
import { NormalCapsule } from "../../ClinicalProgress/Components/Capsule";

const MultiSelectInputBox = ({
  label = "People",
  color = "#06a5ff",
  setFocus = () => {},
  value,
  onChange,
  index,
  type,
  icon,
  onSelect,
  currentDropdown,
  options,
  selectType,
  selectedItems,
  dataMatch,
}: any) => {

    
  return (
    <div className="multi_select_input_box_wrapper">
      {icon && (
        <>
          <div className="label_wrapper">
            {icon}
            <label
              style={{
                color: color,
              }}
            >
              {label}
            </label>
          </div>
        </>
      )}

      <div className="multi_tag_input_container">
        <input
          onFocus={() => setFocus(true)}
          type="text"
          value={value}
          onChange={(e: any) => onChange(type, index, e)}
        />
        {selectedItems?.map((elem: any) => {
          return (
            <>
              <NormalCapsule
                image={elem?.profile_image_s3}
                className={"normal_capsule_goals"}
                title={(() => {
                  const firstName = dataMatch?.find(
                    (user: any) => user?.id === +elem || user?.id == elem
                  )?.first_name;
                  const lastName = dataMatch?.find(
                    (user: any) => user?.id === +elem || user?.id == elem
                  )?.last_name;
                  const name = dataMatch?.find(
                    (user: any) => user?.id === +elem || user?.id == elem
                  )?.name;

                  let selectedName =
                    selectType === "associatedServices"
                      ? name
                      : `${firstName} ${lastName}`;

                  return selectedName;
                })()}
              />
            </>
          );
        })}
      </div>

      {currentDropdown && (
        <>
          <div className="searchable_dropdown">
            {options &&
              options.map((option: any) => {
                return (
                  <>
                    <li onClick={() => onSelect(option, index, selectType)}>
                      {option?.first_name || option?.name}{" "}
                      {option.last_name || ""}
                    </li>
                  </>
                );
              })}
          </div>
        </>
      )}
    </div>
  );
};

export default MultiSelectInputBox;
