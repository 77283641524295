export const SlashSvg = () => {
  return (
    <>
      <svg
        width="5"
        height="8"
        viewBox="0 0 5 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.68147 8H0.461468L3.01147 0.88H4.23147L1.68147 8Z"
          fill="black"
        />
      </svg>
    </>
  );
};

export const GreenCircleSvg = ({ color, onClick, size }: any) => {
  return (
    <div onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size?size:"12"}
        height={size?size:"12"}
        viewBox="0 0 12 12"
        fill="none"
      >
        <path
          d="M6 12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 0 6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12Z"
          fill={color || "#2AD400"}
        />
      </svg>
    </div>
  );
};

export const ZeroSvg = () => {
  return (
    <>
      <svg
        width="11"
        height="21"
        viewBox="0 0 6 7"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.99472 6.128C2.24272 6.128 1.67472 5.872 1.29072 5.36C0.906719 4.848 0.714719 4.112 0.714719 3.152C0.714719 2.192 0.906719 1.456 1.29072 0.944C1.67472 0.432 2.24272 0.176 2.99472 0.176C3.73872 0.176 4.30672 0.432 4.69872 0.944C5.08272 1.456 5.28272 2.192 5.28272 3.152C5.28272 4.112 5.08272 4.848 4.69872 5.36C4.30672 5.872 3.73872 6.128 2.99472 6.128ZM2.99472 5.216C3.76272 5.216 4.15472 4.528 4.15472 3.152C4.15472 1.776 3.76272 1.088 2.99472 1.088C2.22672 1.088 1.84272 1.776 1.84272 3.152C1.84272 4.528 2.22672 5.216 2.99472 5.216Z"
          fill="white"
        />
      </svg>
    </>
  );
};

export const BallSvg = ({onClick} : any) => {
  return (
    <>
      <svg
        width="11"
        height="21"
        viewBox="0 0 15 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        onClick={onClick}
      >
        <circle
          cx="7.5"
          cy="12.1875"
          r="6.5"
          fill="#F3F3F3"
          stroke="white"
          strokeWidth="1.2"
        />
        <path
          d="M12.8397 7.65478C12.2517 8.29014 11.4706 8.81146 10.5608 9.17567C9.65109 9.53987 8.63887 9.73651 7.60778 9.74933C6.57669 9.76215 5.55632 9.5908 4.63098 9.24942C3.70564 8.90804 2.90188 8.40643 2.28609 7.78604"
          stroke="white"
          strokeWidth="1.2"
        />
        <path
          d="M12.8397 16.7202C12.2517 16.0849 11.4706 15.5635 10.5608 15.1993C9.65109 14.8351 8.63887 14.6385 7.60778 14.6257C6.57669 14.6128 5.55632 14.7842 4.63098 15.1256C3.70564 15.467 2.90188 15.9686 2.28609 16.589"
          stroke="white"
          strokeWidth="1.2"
        />
        <path d="M7.5 5.6875V18.6875" stroke="white" strokeWidth="1.2" />
        <path d="M14 12.1875H1" stroke="white" strokeWidth="1.2" />
      </svg>
    </>
  );
};

export const PlusSvg = ({ onClick, isGoalModal }: any) => {
  return (
    <div onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="11"
        height="21"
        viewBox="0 0 9 8"
        fill="none"
      >
        <path
          d="M5.83126 3.22148H8.99609V4.79642H5.83126V8H4.16093V4.79642H0.996094V3.22148H4.16093V0H5.83126V3.22148Z"
          fill={`${isGoalModal} ? "black" : "white"`}
        />
      </svg>
    </div>
  );
};
