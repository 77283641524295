// @ts-nocheck

import "./Style/Comms.css";
import CapsuleWithnumber from "./Component/CapsuleAndNumber";
import { Fragment, useEffect, useRef, useState } from "react";
import convertToHTML from "draftjs-to-html";
import GroupButton from "./Component/GroupBTN/Index";
import DaybookHeader from "../Daybook/Components/DaybookHeader";
import CircularNoteTrigger from "../../components/CircularNoteTrigger/CircularNoteTrigger";
import { useLocation, useParams } from "react-router-dom";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../Controller/redux/Hooks";
import {
  getCommsDetailThunk,
  getCommsForUserThunk,
  getCommsFromUserTypeThunk,
  getUnreadMessagesCommsThunk,
  resetAllCommsData,
  resetAllCommsPage,
  resetCommsDetailData,
  resetDetailCommsPage,
  setCommsIdAndType,
  setDetailCommsPage,
  setselectedCommsFolder,
  updateTaggedUsersThunk,
  setAllCommsLimit,
  resetAllCommsLimit,
  getClientUnreadMessagesCommsThunk,
  setallUserTypeData,
} from "../../../Controller/redux/features/CommsFeature/commsSlice";
import { Spinner } from "@chakra-ui/react";
import { TaggedCapsule } from "../../components/ClinicalProgress/Components/Capsule";
import ClinicalNote from "../../components/ClinicalProgress/Components/ClinicalNote";
import { SearchSVG } from "../../components/Breadcrumb/Data";
import { api } from "../../../Controller/services/api";
import { getAllTagsThunk } from "../../../Controller/redux/features/CommonFeature/commonSlice";
import { toast } from "react-toastify";
import { getAllClinicalNotes } from "../../../Controller/redux/features/ClinicalFeature/clinicalSlice";
import ClinicalNotes from "../People/ClientPage/Components/UniqueClientScreen/Components/ClinicalTab/Components/ClinicalList";
import {
  AllDocSvg,
  DocxSvg,
  PdfDocSvg,
} from "../../../Model/Assets/SVG/Common/AllDocsSvg";
import CrossIconSVG from "./Data/CrossIconSVG";
import { ThreadsBox } from "../../components/CommonModal/Components/ThreadsBox";

interface CommsProps {
  type?: string;
  isUserType?: boolean;
  userTypeId?: any;
}

export const Comms = ({ isUserType, userTypeId }: CommsProps) => {
  const { dynamicColor, user } = useAppSelector((state) => state?.common);
  const dispatch = useAppDispatch();
  const [clientId, setClientId] = useState("");
  const [isSubTaskOpen, setIsSubTaskOpen] = useState<number>(0);
  const [trafficColors, setTrafficColors] = useState({});
  const [isUnread, setIsUnread] = useState<boolean>(false);
  const [sortType, setSortType] = useState("desc");
  const [selectedItem, setselectedItem] = useState(0);
  const [isFirstMessage, setisFirstMessageOpen] = useState({
    isOpen: false,
    isFirst: false,
  });

  // const [selectedCapsuleItem, setselectedCapsuleItem] = useState<number>(-1);
  const allCommsPageRef = useRef<any>(null);
  const detailsPageRef = useRef<any>(null);

  const location = useLocation();
  const pathname = location?.pathname;

  const [commsDetail, setCommsDetail] = useState<any>([]);
  const {
    loading,
    getAllComms,
    getCommsDetail,
    getCommDetailFull,
    allCommsPage,
    detailCommsPage,
    lastPageAllComms,
    lastPageDetailsComms,
    allCommsLimit,
  }: any = useAppSelector((state) => state?.comms);

  // console.log('getCommDetailFull', getCommDetailFull);

  const [assetDetails, setassetDetails] = useState<any>(null);
  const [detailsSpecificToCommsOnly, setDetailsSpecificToCommsOnly] =
    useState<any>(null);
  useEffect(() => {
    if (getCommDetailFull?.data) {
      setassetDetails(getCommDetailFull?.data);
      setDetailsSpecificToCommsOnly(getCommDetailFull);
    }
  }, [getCommDetailFull]);

  // console.log('assetDetails', assetDetails);
  const { ClinicalNotesData, lastPage, ClinicalPage } = useAppSelector(
    (state) => state.clinical
  );

  // console.log('detailsSpecificToCommsOnly', detailsSpecificToCommsOnly);

  // console.log("commsDetail", commsDetail);
  const userId = user?.id;

  // @ts-ignore
  const idFromParams: any = useParams()?.id;

  // console.log("assetDetails", assetDetails);
  useEffect(() => {
    if (idFromParams) {
      let realId = window.atob(idFromParams);
      setClientId(realId);
    } else {
      setClientId(userId);
    }
  }, [idFromParams, userId]);

  const [uniqueAllCommsData, setuniqueAllCommsData] = useState<any>([]);

  useEffect(() => {
    if (getAllComms) {
      setuniqueAllCommsData(getAllComms);
    }
  }, [getAllComms]);
  useEffect(() => {
    // dispatch(resetAllCommsPage());
    // dispatch(resetAllCommsData());
    // dispatch(resetCommsDetailData());
    // dispatch(resetDetailCommsPage());
    // dispatch(resetAllCommsLimit());
  }, [location, selectedItem, isUnread]);

  const [uploadedFiles, setuploadedFiles] = useState<any>([]);
  const [clinicalNoteRecords, setClinicalNoteRecords] = useState<any>([]);

  const handleGetFile = async (name: string | any) => {
    try {
      const response = await api.get(`files/get_file?file=${name}`);
      const fileUrl = response?.data?.data;
      window.open(fileUrl, "_blank");
    } catch (error) {
      console.error("Error fetching file:", error);
    }
  };


  useEffect(() => {
    if (ClinicalNotesData) {
      const uniqueClientData = ClinicalNotesData?.filter(
        (item: any, index: number, array: any) => {
          return array?.findIndex((obj: any) => obj.id === item.id) === index;
        }
      );
      setClinicalNoteRecords(uniqueClientData);
    }
  }, [ClinicalNotesData]);

  useEffect(() => {
    dispatch(getAllTagsThunk());
  }, []);

  useEffect(() => {
    if (clientId) {
      if (selectedItem === 0) {
        dispatch(
          getCommsForUserThunk({
            clientId,
            page: allCommsPage,
            endpoint: "get_comms",
            search: "",
            sortType: "",
            unread: isUnread ? "yes" : "",
            limit: allCommsLimit,
          })
        );
        dispatch(setselectedCommsFolder("Inbox"));
      } else if (selectedItem === 1) {
        if (selectedItemTitle === "Notes") {
          dispatch(getAllClinicalNotes({ user_id: clientId }));
        } else {
          dispatch(
            getCommsForUserThunk({
              clientId,
              page: allCommsPage,
              endpoint: "get_comms_of_sent_items",
              unread: "",
              sortType: "",
              search: "",
              limit: allCommsLimit,
            })
          );
          dispatch(setselectedCommsFolder("Sent"));
        }
      } else if (selectedItem === 2) {
        dispatch(
          getCommsFromUserTypeThunk({
            userType: dynamicClientId,
            search: "",
            sortType: "",
            limit: allCommsLimit,
          })
        );
        // setselectedCommsFolder("Clients")
        dispatch(setselectedCommsFolder("Clients"));
      } else if (selectedItem == 3) {
        dispatch(
          getCommsFromUserTypeThunk({
            userType: dynamicProspectId,
            search: "",
            sortType: "",
            limit: allCommsLimit,
          })
        );
        dispatch(setselectedCommsFolder("Prospect"));
      }
    }
    // if(clientId &&( !(selectedItem ==1) || !(selectedItem ==2) || (!selectedItem ==3)  )){
    //   dispatch(
    //     getCommsForUserThunk({
    //       clientId,
    //       page: allCommsPage,
    //       endpoint: "get_comms",
    //       search: "",
    //       sortType: "",
    //       unread: isUnread ? "yes" : "",
    //       limit: allCommsLimit,
    //     })
    //   );
    //   dispatch(setselectedCommsFolder("Inbox"));
    // }
  }, [clientId, selectedItem]);


  const id = isSubTaskOpen >= 0 ? isSubTaskOpen : 0;
  let commTypeSelected = uniqueAllCommsData[id]?.comm_type;

  useEffect(() => {
    const index = isSubTaskOpen >= 0 ? isSubTaskOpen : 0;

    if (getAllComms?.length > 0) {
      setIsSubTaskOpen(index);
      dispatch(
        getCommsDetailThunk({
          clientId: getAllComms[index]?.link_uid,
          type: getAllComms[index]?.comm_type,
          userId: clientId,
          page: detailCommsPage,
        })
      );

      // dispatch(setCommsDetails())

      dispatch(
        setCommsIdAndType({
          link_id: uniqueAllCommsData[index]?.link_uid,
          comm_type: uniqueAllCommsData[index]?.comm_type,
        })
      );
    }
    if (isUserType) {
      dispatch(getClientUnreadMessagesCommsThunk(userTypeId || clientId));
    } else {
      dispatch(getUnreadMessagesCommsThunk(userId));
    }
  }, [getAllComms, isSubTaskOpen, clientId, detailCommsPage, userId]);

  const uniqueCommsDetailData = getCommsDetail?.filter(
    (item: any, index: number, array: any) => {
      return array?.findIndex((obj: any) => obj.id === item.id) === index;
    }
  );

  // @ts-ignore
  const index = isSubTaskOpen >= 0 ? isSubTaskOpen : 0;

  let uuidComms = uniqueAllCommsData[index]?.link_uid;
  const [commsType, setcommsType] = useState("");
  const [linkUUIDcomms, setlinkuuidcomms] = useState("");

  let commType = uniqueAllCommsData[index]?.comm_type;

  useEffect(() => {
    if (uuidComms) {
      setlinkuuidcomms(uuidComms);
    }
  }, [uuidComms]);

  useEffect(() => {
    if (commType) {
      setcommsType(commType);
    }
  }, [commType]);

  const { staffDataDayBook } = useAppSelector((state) => state?.staff);

  const { clientDataForDayBook } = useAppSelector((state) => state?.people);

  const [allData, setallData] = useState<any>([]);

  const [contactsData, setcontactsData] = useState([]);
  const [prospectsData, setprospectsData] = useState([]);
  const [businessData, setBusinessData] = useState([]);
  const [teamsData, setTeamsData] = useState([]);
  const [locationData, setLocationData] = useState([]);
  const [commsDetailCurrentElement, setcommsDetailCurrentElement] =
    useState(null);

  // @ts-ignore
  const dynamicClientId = parseInt(localStorage.getItem("dynamicClientId"));
  // @ts-ignore
  const dynamicProspectId = parseInt(localStorage.getItem("dynamicProspectId"));
  // @ts-ignore
  const dynamicContactId = parseInt(localStorage.getItem("dynamicContactId"));
  // @ts-ignore
  const dynamicBusinessId = parseInt(localStorage.getItem("dynamicBusinessId"));
  // @ts-ignore
  const dynamicTeamsId = parseInt(localStorage.getItem("dynamicTeamsId"));
  // @ts-ignore
  const dynamicLocationId = parseInt(localStorage.getItem("dynamicLocationId"));

  const [folderChange, setFolderChange] = useState(false);
  const [isCommsDetailIndex, setisCommsDetailIndex] = useState(-1);
  //  @ts-ignore
  const dynamicStaffId = parseInt(localStorage.getItem("dynamicStaffId"));
  useEffect(() => {
    // dispatch(getClientsThunk({ dynamicClientId: dynamicClientId }));
    // dispatch(getStaffThunk({ dynamicStaffId: dynamicStaffId }));
    // dispatch(getProspectsThunk({ dynamicProspectsId: dynamicProspectId }));
    // dispatch(getContactsThunk({ dynamicContactId: dynamicContactId }));
    // dispatch(getTeamsThunk(dynamicTeamsId));
    // dispatch(getBusinessThunk(dynamicBusinessId));
    // dispatch(getLocationUserThunk(dynamicLocationId));
  }, [
    dynamicStaffId,
    dynamicClientId,
    dynamicProspectId,
    dynamicContactId,
    dynamicTeamsId,
    dynamicBusinessId,
    dynamicLocationId,
  ]);

  useEffect(() => {
    if (clientId && ClinicalPage) {
      dispatch(
        getAllClinicalNotes({ user_id: clientId, ClinicalPage: ClinicalPage })
      );
    }

    setKeyPeople([]);
    setAttachments([]);
  }, [clientId, ClinicalPage]);
  const [ClinicalNotesRecords, setClinicalNotesRecords] = useState<any>([]);

  let elem = uniqueAllCommsData?.find((el, index) => isSubTaskOpen === index);

  // console.log('ELEM_COMMS_INT', getAllComms[index]?.comm_count);

  useEffect(() => {
    if (ClinicalNotesData) {
      const uniqueClientData = ClinicalNotesData?.filter(
        (item: any, index: number, array: any) => {
          return array?.findIndex((obj: any) => obj.id === item.id) === index;
        }
      );
      setClinicalNotesRecords(uniqueClientData);
    }
  }, [ClinicalNotesData]);

  // console.log(commsDetail,"commsDetail")

  useEffect(() => {
    if (
      clientDataForDayBook &&
      staffDataDayBook &&
      contactsData &&
      prospectsData &&
      businessData &&
      teamsData &&
      locationData
    ) {
      setallData([
        ...clientDataForDayBook,
        ...staffDataDayBook,
        ...prospectsData,
        ...contactsData,
        ...businessData,
        ...teamsData,
        ...locationData,
      ]);
      dispatch(
        setallUserTypeData([
          ...clientDataForDayBook,
          ...staffDataDayBook,
          ...prospectsData,
          ...contactsData,
          ...businessData,
          ...teamsData,
          ...locationData,
        ])
      );
    }
  }, [
    clientDataForDayBook,
    staffDataDayBook,
    prospectsData,
    contactsData,
    businessData,
    teamsData,
    locationData,
  ]);

  console.log("allData", allData);
  const {
    getCommsUnreadMessages,
    getUnreadMessages,
    getClientCommsUnreadMessages,
    getClientUnreadMessages,
  } = useAppSelector((state) => state?.comms);

  let sentItems = {
    key: "Sent Items",
    data: {
      unread_messages: 0,
      traffic_light: "Green",
    },
  };
  const [speadGetUnreadMessages, setspeadGetUnreadMessages] = useState(null);
  useEffect(() => {
    if (getClientUnreadMessages || getUnreadMessages) {
      let speadGetUnreadMessages = isUserType
        ? [...getClientUnreadMessages].slice(
            0,
            [...getClientUnreadMessages].slice(
              0,
              getClientUnreadMessages.length
            ).length
          )
        : [...getUnreadMessages].slice(1, getUnreadMessages.length);
      speadGetUnreadMessages.splice(1, 0, sentItems);

      setspeadGetUnreadMessages(speadGetUnreadMessages);
    }
  }, [getClientUnreadMessages, getUnreadMessages]);

  let res = speadGetUnreadMessages?.find(
    (obj) => obj?.key === "Notes".toLowerCase()
  )?.data?.traffic_light;

  useEffect(() => {
    if (clientId) {
      dispatch(getAllClinicalNotes({ user_id: clientId, ClinicalPage: 1 }));
    }
  }, [clientId]);

  useEffect(() => {
    if (ClinicalNotesData) {
      const uniqueClientData = ClinicalNotesData?.filter(
        (item: any, index: number, array: any) => {
          return array?.findIndex((obj: any) => obj.id === item.id) === index;
        }
      );
      setClinicalNotesRecords(uniqueClientData);
    }
  }, [ClinicalNotesData]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setisSearching(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const dropdownRef = useRef(null);

  useEffect(() => {
    if (isUserType) {
      setIsSubTaskOpen(0);
      setselectedItemsArr([]);
      setKeyPeople([]);
      setAttachments([]);
    }
  }, [isUserType]);

  const [isSearching, setisSearching] = useState(false);
  const [selectedItemsArr, setselectedItemsArr] = useState<any>([]);
  const [filteredResults, setfilteredResults] = useState(allData);
  const [inputValue, setinputValue] = useState("");
  const [keyPeople, setKeyPeople] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [selectedItemTitle, setselectedItemTitle] = useState("");

  useEffect(() => {
    if (getCommDetailFull?.files) {
      setAttachments(getCommDetailFull?.files);
    }
  }, [getCommDetailFull]);

  useEffect(() => {
    if (getCommDetailFull?.mention_users) {
      setKeyPeople(getCommDetailFull?.mention_users);
    }
  }, [getCommDetailFull]);

  useEffect(() => {
    if (getCommDetailFull?.tagged_users) {
      setselectedItemsArr(getCommDetailFull?.tagged_users || []);
    } else {
      setselectedItemsArr(getCommDetailFull?.data?.tagged_person || []);
    }
  }, [getCommDetailFull]);

  const handleSelectTaggedPeople = async (item) => {
    setinputValue("");
    const selectedItem = allData.find(
      (elem) =>
        elem?.first_name?.toLowerCase() === item?.first_name?.toLowerCase() &&
        elem?.last_name?.toLowerCase() === item?.last_name?.toLowerCase()
    );


    if (selectedItem && Array.isArray(selectedItemsArr) ) {
      const tempArr = [selectedItem, ...selectedItemsArr];
      setselectedItemsArr(tempArr);
      setisSearching(false);

      const selectedItemsId = tempArr.map((elem) => elem?.id);
      const index = isSubTaskOpen >= 0 ? isSubTaskOpen : 0;
      const linkUUID = uniqueAllCommsData[index]?.link_uid;
      const commType = uniqueAllCommsData[index]?.comm_type;

      const selectedTaggedUsers = {
        link_uid: linkUUID,
        comm_type: commType,
        tagged_users: selectedItemsId,
      };

      let response = await dispatch(
        updateTaggedUsersThunk(selectedTaggedUsers)
      );

      if (response?.error?.message) {
        toast.warn(response?.payload);
      }
      dispatch(
        getCommsDetailThunk({
          clientId: linkUUID,
          type: commType,
          userId: clientId,
          page: 1,
        })
      );
      dispatch(
        getCommsDetailThunk({
          clientId: linkUUID,
          type: commType,
          userId: clientId,
          page: 1,
        })
      );
    }
  };

  const handleInputChangeAllData = (e: any) => {
    setinputValue(e.target.value);
    if (e.target.value === "") {
      setisSearching(false);
    } else {
      setisSearching(true);
    }
    // setfilteredResults((prevItems: any) => {
    //   return allData.filter(
    //     (elem: any) =>
    //       elem?.first_name
    //         ?.toLowerCase()
    //         .includes(e.target.value.toLowerCase()) ||
    //       elem?.last_name?.toLowerCase().includes(e.target.value.toLowerCase())
    //   );
    // });
  };
  useEffect(() => {
    if (uniqueCommsDetailData?.length > 0) {
      setCommsDetail(uniqueCommsDetailData);
    }
  }, [getCommsDetail]);

  let currentElem = uniqueAllCommsData.find(
    (_: any, index: number) => index === isSubTaskOpen
  );


  const onScrollCommsPage = () => {
    if (allCommsPageRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = allCommsPageRef.current;
      if (scrollTop + +5 + clientHeight >= scrollHeight) {
        dispatch(setAllCommsLimit());
      }
    }
  };

  const onScrollDetailCommsPage = () => {
    if (detailsPageRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = detailsPageRef.current;
      if (
        scrollTop + clientHeight >= scrollHeight &&
        detailCommsPage < lastPageDetailsComms
      ) {
        dispatch(setDetailCommsPage());
      }
    }
  };
  const taggedUsers: any = [];
  getCommsDetail?.forEach((goal: any) => {
    goal?.tagged_users?.forEach((user: any) => {
      taggedUsers?.push({
        id: user?.id,
        first_name: user?.first_name,
        last_name: user?.last_name,
      });
    });
  });

  const toastStyle = {
    marginRight: "8px",
    borderRadius: "50%",
    width: "7rem",
    height: "35px",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: dynamicColor,
    backgroundColor: "white",
    fontSize: "1.5rem",
    border: `3px solid ${dynamicColor}`,
  };

  const crossIconStyle = {
    position: "absolute",
    cursor: "pointer",
    top: "-10px",
    right: "-7px",
    width: "10px",
    height: "10px",
    background: "#F6F6F6",
    visibility: "hidden",
  };

  const handleDelete = async (name: string | any) => {
    try {
      await api.post("/files/delete_file", { file: name });
      setuploadedFiles((prevState: [object] | any[]) => {
        return prevState.filter(
          (e: { [x: string]: any; file: { name: string | any } }) =>
            e?.key !== name
        );
      });

      toast.success("File Deleted Successfully!", {
        progressStyle: { backgroundColor: dynamicColor },
        icon: <span style={toastStyle}>✓</span>,
      });
    } catch (error) {
      console.error("Error Deleting File:", error);
      toast.error("Failed To Delete File!");
    }
    dispatch(
      getCommsDetailThunk({
        clientId: linkUUIDcomms,
        type: commTypeSelected,
        userId: clientId,
        page: 1,
      })
    );
  };


  const handleDeleteOnUpdate = async (id: string | any) => {
    try {
      await api.post("/files/delete_document_file", { id: id });
      toast.success("File Deleted Successfully!", {
        progressStyle: { backgroundColor: dynamicColor },
        icon: <span style={toastStyle}>✓</span>,
      });
    } catch (error) {
      toast.error("Failed To Delete File!");
    }
    dispatch(
      getCommsDetailThunk({
        clientId: linkUUIDcomms,
        type: commTypeSelected,
        userId: clientId,
        page: 1,
      })
    );
  };

  let isUserTypeData = !isUserType
    ? [
        { title: " Inbox", type: "yellow", item: "inbox" },
        { title: "Sent Items", type: "green", item: "sent_items" },
        { title: "Clients", type: "green", item: "clients" },
        { title: "Prospects", type: "green", item: "prospects" },
      ]
    : [
        { title: " Inbox", type: "yellow", item: "inbox" },
        { title: "Notes", type: "yellow", item: "notes" },
      ];

  return (
    <>
      <div>
        {pathname.includes("comms") && (
          <DaybookHeader type="comms" uniqueAllCommsData={uniqueAllCommsData} />
        )}
        <div className="comms_main_sections">
          {!(selectedItemTitle === "Notes") && (
            <div>
              <GroupButton
                setselectedItem={setselectedItem}
                setSortTypeComms={setSortType}
                setIsUnreadComms={setIsUnread}
                setselectedItemsArr1={setselectedItemsArr}
                setAttachments={setAttachments}
                selectedItem={selectedItem}
                setselectedItemTitle={setselectedItemTitle}
                setIsSubTaskOpen={setIsSubTaskOpen}
                clientId={clientId}
                setKeyPeople={setKeyPeople}
                dynamicClientId={dynamicClientId}
                dynamicProspectId={dynamicProspectId}
                setIsSubTaskOpen={setIsSubTaskOpen}
                isSubTaskOpen={isSubTaskOpen}
                isUserType={isUserType}
                setisCommsDetailIndex={setisCommsDetailIndex}
              />
            </div>
          )}
          <div className="comms_main_sections_column_1">
            <div className="all_inbox_list">
              {isUserTypeData.map((elem: any, index: any) => {
                return (
                  <>
                    <CapsuleWithnumber
                      isFromMobile={false}
                      type={
                        isUserType
                          ? getClientCommsUnreadMessages === 0
                            ? "green"
                            : "red"
                          : getCommsUnreadMessages === 0
                          ? "green"
                          : "red"
                      }
                      fill={
                        speadGetUnreadMessages?.find(
                          (obj) => obj?.key === elem?.item
                        )?.data?.traffic_light || "Green"
                      }
                      number={
                        // @ts-ignore
                        speadGetUnreadMessages?.find(
                          (obj) => obj?.key == elem?.item
                        )?.data?.unread_messages || 0
                      }
                      title={elem?.title || ""}
                      isSelected={index === selectedItem}
                      onClick={() => {
                        resetAllCommsLimit();
                        setselectedItemsArr([]);
                        setAttachments([]);
                        setKeyPeople([]);
                        setFolderChange(!folderChange);
                        setselectedItem(index);
                        setselectedItemTitle(elem?.title);
                        setCommsDetail([]);
                        setIsSubTaskOpen(0);
                        if (index === 0) {
                          dispatch(
                            getCommsForUserThunk({
                              clientId,
                              page: allCommsPage,
                              endpoint: "get_comms",
                              sortType: "",
                              search: "",
                              unread: "",
                              limit: allCommsLimit,
                            })
                          );
                          dispatch(setselectedCommsFolder("Inbox"));
                        } else if (index === 1) {
                          if (selectedItemTitle === "Notes") {
                            dispatch(
                              getAllClinicalNotes({ user_id: clientId })
                            );
                          } else {
                            dispatch(
                              getCommsForUserThunk({
                                clientId,
                                page: allCommsPage,
                                endpoint: "get_comms_of_sent_items",
                                unread: "",
                                sortType: "",
                                search: "",
                                limit: allCommsLimit,
                              })
                            );
                            dispatch(setselectedCommsFolder("Sent"));
                          }

                          // setselectedCommsFolder("Sent")
                        } else if (index === 2) {
                          dispatch(
                            getCommsFromUserTypeThunk({
                              userType: dynamicClientId,
                              search: "",
                              sortType: "",
                              limit: allCommsLimit,
                            })
                          );
                          // setselectedCommsFolder("Clients")
                          dispatch(setselectedCommsFolder("Clients"));
                        } else {
                          dispatch(
                            getCommsFromUserTypeThunk({
                              userType: dynamicProspectId,
                              search: "",
                              sortType: "",
                              limit: allCommsLimit,
                            })
                          );
                          dispatch(setselectedCommsFolder("Prospect"));
                        }
                      }}
                    />
                  </>
                );
              })}
            </div>

            <div
              className="comms_area"
              ref={allCommsPageRef}
              onScroll={onScrollCommsPage}
              style={{
                width: selectedItemTitle == "Notes" ? "unset" : "437px",
                overflowX: "hidden",
              }}
            >
              {selectedItemTitle === "Notes" ? (
                <ClinicalNotes
                  isFromComms={true}
                  selectedItemTitle={selectedItemTitle}
                />
              ) : (
                uniqueAllCommsData && (
                  <>
                    {uniqueAllCommsData.map((elem: any, index: number) => {
                      return (
                        <Fragment key={index}>
                          {isSubTaskOpen === index ? (
                            <ClinicalNote
                              dropdownValue={false}
                              isComms
                              folderChange={folderChange}
                              clientId={clientId}
                              userName={elem?.username || ""}
                              isSubTaskOpen={isSubTaskOpen}
                              commDetail={commsDetail}
                              index={index}
                              setisFirstMessageOpen={setisFirstMessageOpen}
                              uniqueAllCommsData={uniqueAllCommsData}
                              elem={elem}
                              isFromCol1={true}
                              isExpanded={isSubTaskOpen == index}
                              isUserType={true}
                              noteTitle={"Message"}
                              clinicalGoalsNote={(() => {
                                switch (elem.comm_type) {
                                  case "COMM":
                                    return elem?.comm_title || "";
                                  case "DAYBOOK":
                                    return elem.daybook_title || "";
                                  case "DOCUMENT":
                                    return elem.document_title || "";
                                  case "MEAL":
                                    return elem.meal_title || "";
                                  default:
                                    return "";
                                }
                              })()}
                            />
                          ) : (
                            <>
                              <ClinicalNote
                                isComms
                                isFromCol1
                                dropdownValue={false}
                                folderChange={folderChange}
                                selectedItemTitle={selectedItemTitle}
                                commDetail={commsDetail}
                                index={index}
                                userName={elem?.username || ""}
                                clientId={clientId}
                                dropdownValue={elem?.note_tag}
                                setisFirstMessageOpen={setisFirstMessageOpen}
                                note={elem?.note}
                                isFromCol1={true}
                                isSubTaskOpen={isSubTaskOpen}
                                setIsSubTaskOpen={setIsSubTaskOpen}
                                elem={elem}
                                isUserType={true}
                                noteTitle={"Message"}
                                clinicalGoalsNote={(() => {
                                  switch (elem.comm_type) {
                                    case "COMM":
                                      return elem?.comm_title || "";
                                    case "DAYBOOK":
                                      return elem.daybook_title || "";
                                    case "DOCUMENT":
                                      return elem.document_title || "";
                                    case "MEAL":
                                      return elem.meal_title || "";
                                    default:
                                      return elem?.note;
                                  }
                                })()}
                              />
                            </>
                          )}
                        </Fragment>
                      );
                    })}
                  </>
                )
              )}
            </div>

            {selectedItemTitle !== "Notes" &&
              uniqueAllCommsData?.length >= 1 && (
                <div className="comms_main_sections_column_3">
                  {getAllComms[index]?.comm_type == "DOCUMENT" ? (
                    <ThreadsBox
                      clientId={clientId}
                      selectedFolderIndex={selectedItem}
                      color={assetDetails?.traffic_light}
                      selectedInterval={assetDetails?.period_picker}
                      currentElem={currentElem}
                      blockPointerEvents={true}
                      commsDetail={commsDetail}
                      assetDetails
                      primaryApprovers={assetDetails?.primary_approvers}
                      ongoingApprovers={assetDetails?.ongoing_approvers}
                      count={assetDetails?.comm_count}
                      incomingArrayTabs={[]}
                      selectedElement={assetDetails}
                      taggedPeople={
                        assetDetails?.tagged_person === "string"
                          ? assetDetails?.tagged_person
                              .split(",")
                              .map((item: string) => parseInt(item))
                          : assetDetails?.tagged_person
                      }
                      selectedDocument={assetDetails}
                      selectedCurrentElement={assetDetails}
                      attachments={assetDetails?.files}
                      keyPeople={assetDetails?.key_person}
                      selectedInterval={assetDetails?.period_picker}
                      isDocument={getAllComms[index]?.comm_type == "DOCUMENT"}
                      selectedItem={assetDetails}
                      commsDetail={assetDetails?.communications}
                      completionStatus={assetDetails?.completion_status}
                      primaryReview={assetDetails?.primary_review}
                      hardCopyDate={assetDetails?.hard_copy_completed_date}
                      hardCopyTime={assetDetails?.hard_copy_completed_time}
                      linkUUID={assetDetails?.document_uid}
                      commType={"DOCUMENT"}
                      title={(() => {
                        switch (elem?.comm_type) {
                          case "COMM":
                            return "";
                          case "DAYBOOK":
                            return elem?.daybook_title
                              ? `EVENT - ${elem?.asset_username} ${elem?.daybook_title}`
                              : "Event";
                          case "DOCUMENT":
                            return elem?.document_title
                              ? `DOC - ${elem?.asset_username} - ${elem?.document_title}`
                              : "Doc";
                          case "MEAL":
                            return elem?.meal_title
                              ? `Meal - ${elem?.meal_title}`
                              : "Meal";
                          default:
                            return "";
                        }
                      })()}
                      selectedInterval={assetDetails?.period_picker}
                      taggedPeopleOnOptionSelect={handleSelectTaggedPeople}
                    />
                  ) : getAllComms[index]?.comm_type == "DAYBOOK" ? (
                    <ThreadsBox
                      clientId
                      color={assetDetails?.comms_traffic_light}
                      selectedFolderIndex={selectedItem}
                      count={assetDetails?.comm_count}
                      currentElem={assetDetails}
                      selectedServiceType={assetDetails?.service_types}
                      selectedCurrentElement={assetDetails}
                      isDocument={false}
                      startDateDaybook={assetDetails?.date}
                      endDateDaybook={assetDetails?.end_date}
                      assetDetails
                      startTime={assetDetails?.start_time}
                      endTime={assetDetails?.end_time}
                      notComms
                      assignedTo={[assetDetails?.assigned_to]}
                      blockPointerEvents={true}
                      taggedPeopleDaybook={
                        typeof assetDetails?.tagged_user === "string"
                          ? assetDetails?.tagged_user
                              .split(",")
                              .map((item: string) => parseInt(item))
                          : assetDetails?.tagged_user
                      }
                      commsDetail={commsDetail}
                      incomingArrayTabs={[]}
                      selectedElement={assetDetails}
                      taggedPeople={
                        assetDetails?.tagged_person === "string"
                          ? assetDetails?.tagged_person
                              .split(",")
                              .map((item: string) => parseInt(item))
                          : assetDetails?.tagged_person
                      }
                      selectedDocument={assetDetails}
                      selectedCurrentElement={assetDetails}
                      attachments={assetDetails?.files}
                      keyPeople={assetDetails?.key_person}
                      selectedInterval={assetDetails?.period_picker}
                      isDaybook
                      selectedItem={assetDetails}
                      commsDetail={assetDetails?.communications}
                      completionStatus={assetDetails?.completion_status}
                      linkUUID={assetDetails?.id}
                      clientId={clientId}
                      fetchCommDetailsForDaybook
                      currentElem={assetDetails}
                      assetDetails
                      commType={"DAYBOOK"}
                      title={(() => {
                        switch (elem?.comm_type) {
                          case "COMM":
                            return "";
                          case "DAYBOOK":
                            return elem?.daybook_title
                              ? `EVENT - ${elem?.asset_username} - ${elem?.daybook_title}`
                              : "Event";
                          case "DOCUMENT":
                            return elem?.document_title
                              ? `DOC - ${elem?.document_title}`
                              : "Doc";
                          case "MEAL":
                            return elem?.meal_title
                              ? `Meal - ${elem?.meal_title}`
                              : "Meal";
                          default:
                            return "";
                        }
                      })()}
                      isDocument={false}
                      periodPicker={false}
                      isNotComms={true}
                      taggedPeopleOnOptionSelect={handleSelectTaggedPeople}
                    />
                  ) : (
                    <>
                      <ThreadsBox
                        isCommsModal
                        clientId
                        color={detailsSpecificToCommsOnly?.traffic_light}
                        count={detailsSpecificToCommsOnly?.comm_count}
                        selectedFolderIndex={selectedItem}
                        currentElem={getAllComms[index]}
                        commsDetail={assetDetails?.data}
                        clientId={getAllComms[index]?.link_uid}
                        summary={elem?.comm_summary}
                        linkUid={getAllComms[index]?.link_uid}
                        commType={getAllComms[index]?.comm_type}
                        index={index}
                        selectedItem={selectedItem}
                        dynamicClientId={dynamicClientId}
                        dynamicProspectId={dynamicProspectId}
                        isSubTaskOpen={isSubTaskOpen}
                        linkUUID={linkUUIDcomms}
                        commType={commsType}
                        isFromReplyBox={true}
                        clientId={clientId}
                        isEmpty={commsDetail.length === 0}
                        isCol2={true}
                        setIsSubTaskOpen={setIsSubTaskOpen}
                        clientId={clientId}
                        selectedComm={elem}
                        attachments={detailsSpecificToCommsOnly?.files}
                        keyPeople={keyPeople}
                        taggedPeople={detailsSpecificToCommsOnly?.tagged_users}
                        selectedComm={detailsSpecificToCommsOnly?.tags}
                        title={elem?.comm_title || ""}
                        // isCommsModal
                        hideSummary
                        taggedPeopleOnOptionSelect={handleSelectTaggedPeople}
                      />
                    </>
                  )}

                  <div className="editor_section_top">
                    {uniqueAllCommsData?.map((elem: any, index: any) => {
                      return (
                        <Fragment key={index}>
                          {/* {isSubTaskOpen === index && (
                        <div key={index}>
                          <h1 className="comms_title_name">
                            {(() => {
                              switch (elem.comm_type) {
                                case "COMM":
                                  return "";
                                case "DAYBOOK":
                                  return elem.daybook_title
                                    ? `Event - ${elem.daybook_title}`
                                    : "Event";
                                case "DOCUMENT":
                                  return elem.document_title
                                    ? `Doc - ${elem.document_title}`
                                    : "Doc";
                                case "MEAL":
                                  return elem.meal_title
                                    ? `Meal - ${elem.meal_title}`
                                    : "Meal";
                                default:
                                  return "";
                              }
                            })()}
                          </h1>
                          <p>{`${elem?.comm_summary || ""}`}</p>
                        </div>
                      )} */}
                        </Fragment>
                      );
                    })}

                    <div
                      ref={detailsPageRef}
                      onScroll={onScrollDetailCommsPage}
                      className="comms_record_all_wrapper"
                    >
                      {commsDetail &&
                        isSubTaskOpen > -1 &&
                        commsDetail?.map((elem: any, index: number) => {
                          let dataObjectMessage = elem?.message;
                          let parsedRes;
                          try {
                            parsedRes = JSON.parse(dataObjectMessage);
                          } catch (error) {
                            console.error("Error parsing JSON:", error);
                            return null;
                          }
                          const htmlContent = convertToHTML(parsedRes);

                          // const showLights = activeTrafficLightId === index;
                          // @ts-ignore
                          const color = trafficColors[index];
                          let isFirst = commsDetail[0];
                          return (
                            <div>
                              {/* {isCommsDetailIndex === index ? (
                            <ClinicalNote
                              index={index}
                              noteTitle={"Reply"}
                              isComms
                              elem={elem}
                              userName={elem.username}
                              dropdownValue={false}
                              clientId={clientId}
                              setcommsDetailCurrentElement={
                                setcommsDetailCurrentElement
                              }
                              isExpanded={true}
                              isFirst={isFirst}
                              htmlContent={htmlContent}
                              setIsSubTaskOpen={setisCommsDetailIndex}
                              isCol2={true}
                              currentElem={currentElem}
                              color={color}
                              selectedItem={selectedItem}
                            />
                          ) : (
                            <ClinicalNote
                              index={index}
                              elem={elem}
                              clientId={clientId}
                              isCol2={true}
                              dropdownValue={false}
                              noteTitle={"Reply"}
                              isFirst={isFirst}
                              userName={elem.username}
                              htmlContent={htmlContent}
                              setIsSubTaskOpen={setisCommsDetailIndex}
                              currentElem={currentElem}
                              color={color}
                              isComms
                              selectedItem={selectedItem}
                            />
                          )} */}
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              )}
            {selectedItemTitle !== "Notes" &&
              uniqueAllCommsData?.length >= 1 && (
                // <div className="comms_main_sections_column_4">
                //   <div className="section_key_people_container">
                //     <div className="section_key_people">
                //       <p className="tag_margin">KEY PEOPLE</p>
                //       {keyPeople &&
                //         keyPeople.map((item: any, index: number) => (
                //           <div key={index}>
                //             <TaggedCapsule
                //               image={item?.profile_image_s3}
                //               title={`${(item?.first_name || "").substring(
                //                 0,
                //                 10
                //               )} ${
                //                 (item?.last_name || "").length >= 8
                //                   ? (item?.last_name || "").substring(0, 6) + "..."
                //                   : item?.last_name || ""
                //               }`}
                //               count={0}
                //               isTaggedCapsule={true}
                //             />
                //           </div>
                //         ))}
                //     </div>
                //   </div>

                //   <div className="tagged_people_section_wraper">
                //     <div className="section_key_people tagged_people_section">
                //       <p className="tag_margin">TAGGED PEOPLE</p>

                //       <div className="tag_people_all_wrapper">
                //         <div
                //           className="multi_tag_input"
                //           style={{
                //             height: "25px",
                //             width: "280%",
                //             marginLeft: "-13px",
                //             border: "none",
                //             position: "relative",
                //           }}
                //         >
                //           <div
                //             className="search"
                //             style={{
                //               position: "absolute",
                //               top: "5px",
                //               zIndex: 1,
                //             }}
                //           >
                //             <SearchSVG width={16} height={16} />
                //           </div>
                //           {/* @ts-ignore */}
                //           <input
                //             // onFocus={() => setFocusPeople(true)}
                //             type="text"
                //             className="responsible_parties_input"
                //             value={inputValue}
                //             onChange={(e: any) => handleInputChangeAllData(e)}
                //             placeholder=""
                //             style={{
                //               width: "30%",
                //             }}
                //           />
                //         </div>
                //         {isSearching && (
                //           <div ref={dropdownRef} className="filter_dropdown_comms">
                //             {filteredResults?.map((item: any, itemIndex: any) => {
                //               const isNameInMentionUsers =
                //                 !getCommDetailFull?.mention_users?.some(
                //                   (mentionUser: any) =>
                //                     mentionUser?.first_name?.toLowerCase() ===
                //                       item?.first_name?.toLowerCase() ||
                //                     mentionUser?.last_name?.toLowerCase() ===
                //                       item?.last_name?.toLowerCase()
                //                 );

                //               if (isNameInMentionUsers) {
                //                 return (
                //                   <div key={itemIndex}>
                //
                //                   </div>
                //                 );
                //               } else {
                //                 return null;
                //               }
                //             })}
                //           </div>
                //         )}

                //         {selectedItemsArr?.map((elem: any, index: number) => (
                //           <TaggedCapsule
                //             index={elem?.id}
                //             image={elem?.profile_image_s3}
                //             title={`${(elem?.first_name || "").substring(0, 10)} ${
                //               (elem?.last_name || "").length >= 10
                //                 ? (elem?.last_name || "").substring(0, 5) + "..."
                //                 : elem?.last_name || ""
                //             }`}
                //             count={0}
                //             isTaggedCapsule={true}
                //           />
                //         ))}
                //       </div>
                //     </div>
                //   </div>

                //   <div className="tagged_people_section_wraper">
                //     <div className="section_key_people tagged_people_section">
                //       <p className="attachments_label">ATTACHMENTS</p>
                //       {attachments &&
                //         attachments.map((elem: any) => {
                //           return (
                //             <div
                //               style={{
                //                 display: "flex",
                //                 width: "100%",
                //               }}
                //             >
                //               <div
                //                 style={{
                //                   position: "relative",
                //                   border: "none",
                //                   width: "fit-content",
                //                 }}
                //               >
                //                 <div
                //                   className="file-item"
                //                   style={{
                //                     cursor: "pointer",
                //                     marginBottom: "5px",
                //                     fontSize: "8px",
                //                   }}
                //                 >
                //                   {elem?.file_name?.includes("pdf") ? (
                //                     <PdfDocSvg
                //                       onClick={() => handleGetFile(elem.file_name)}
                //                     />
                //                   ) : elem?.file_name?.includes("docx") ? (
                //                     <DocxSvg
                //                       onClick={() => handleGetFile(elem.file_name)}
                //                     />
                //                   ) : (
                //                     <AllDocSvg
                //                       onClick={() => handleGetFile(elem.file_name)}
                //                     />
                //                   )}

                //                   <CrossIconSVG
                //                     onClick={(e) => {
                //                       e.stopPropagation();
                //                       if (elem.id) {
                //                         handleDeleteOnUpdate(elem.id);
                //                       } else {
                //                         handleDelete(elem?.key || elem?.file_name);
                //                       }
                //                     }}
                //                     style={crossIconStyle}
                //                   />
                //                 </div>
                //               </div>

                //               <span
                //                 style={{
                //                   fontSize: "8px",
                //                   marginLeft: "5px",
                //                   marginTop: "3px",
                //                 }}
                //               >
                //                 {elem.actual_file_name}
                //               </span>
                //             </div>
                //           );
                //         })}
                //       {/* <div className="section_attachments attachments_section"></div> */}
                //     </div>
                //   </div>
                // </div>
                <></>
              )}
          </div>
        </div>
        <CircularNoteTrigger
          type="stickyHub"
          title="Comms"
          isOutside
          extraType="globalCommsBox"
        />
      </div>
      {loading && (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            zIndex: "999999999",
          }}
        >
          <Spinner
            thickness="2px"
            size="md"
            speed="0.65s"
            zIndex={99999999999}
          />
        </div>
      )}
    </>
  );
};
