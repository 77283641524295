import "./ProfilePictureModal.css";

import { useEffect, useRef, useState } from "react";

import ProfileImg from "../../../Model/Assets/png/client.png";
import UploadSvg from "../../../Model/Assets/SVG/Common/UploadSvg";
import { toast } from "react-toastify";
import { useLocation, useParams } from "react-router-dom";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../Controller/redux/Hooks";
import {
  getFileThunk,
  getProfileDetailsThunk,
  uploadProfilePictureEntityThunk,
  uploadProfilePictureThunk,
} from "../../../Controller/redux/features/CommonFeature/commonSlice";
import { getUniqueClientById } from "../../../Controller/redux/features/ClientFeature/clientsSlice";
import { getProspectsByIdThunk } from "../../../Controller/redux/features/ProspectsFeature/prospectsSlice";
import { getStaffByIdThunk } from "../../../Controller/redux/features/StaffFeature/staffSlice";
import { getContactByIdThunk } from "../../../Controller/redux/features/ContactsFeature/contactsSlice";
import { getBusinessByIdThunk } from "../../../Controller/redux/features/BusinessFeature/businessSlice";
import { getTeamsByIdThunk } from "../../../Controller/redux/features/TeamsFeature/teamsSlice";
import { getInitials } from "../../../Model/utils/Helper/getInitials";
import React from "react";

const ProfilePictureModal = ({
  setShowProfile,
  showProfile,
  className,
  currentClient,
  setstageTwoToGoalsModal,
}: any) => {
  //////////////////////////// HOOKS ///////////////////////////////////////////////////////
  const dispatch = useAppDispatch();

  const [user, setUser] = useState<any>(null);

  const profilePictureRefetched = useAppSelector(
    (state) => state?.common?.user
  );
  let profilePictureCurrentUser = profilePictureRefetched;
  useEffect(() => {
    if (profilePictureRefetched) {
      setUser(profilePictureCurrentUser);
    }
  }, [profilePictureRefetched]);
  const [profilePicture, setProfilePicture] = useState<any>("");

  useEffect(() => {
    if (currentClient?.profile_image) {
      const path = currentClient?.profile_image;
      if (path) {
        dispatch(getFileThunk({ path })).then((res: any) => {
          setProfilePicture(res?.payload?.data);
        });
      }
    }
  }, [currentClient?.profile_image]);

  ///////////////////////////////////////////////////////////////////////////////////////////

  const [selectedProfile, setSelectedProfile] = useState<any>(null);
  const [previewProfile, setPreviewProfile] = useState<any>(null);

  const [profilePictureDetails, setprofilePictureDetails] = useState<any>({
    user_id: null,
    type: null,
    extension: "",
    base64: "",
  });

  ////////////////////////////////////// REF //////////////////////////////////////////////////////////////////
  const profilePictureRef = useRef<null>(null);
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////

  //////////////////////////////////////// LOCATION ///////////////////////////////////////////////////////////
  const pathname = useLocation().pathname;

  const pathnameBusinessAndTeams =
    pathname.includes("business") || pathname.includes("teams");
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////

  //////////////////////////////////////////////// CLIENT ID //////////////////////////////////////////////////

  const id: any = useParams()?.id;
  const clientId = window.atob(id);
  // @ts-ignore
  const dynamicProspectsId = parseInt(
    // @ts-ignore
    localStorage.getItem("dynamicProspectId")
  );
  // @ts-ignore
  const dynamicClientId = parseInt(localStorage.getItem("dynamicClientId"));
  // @ts-ignore
  const dynamicStaffId = parseInt(localStorage.getItem("dynamicStaffId"));
  // @ts-ignore
  const dynamicContactId = parseInt(localStorage.getItem("dynamicContactId"));
  // @ts-ignore
  const dynamicBusinessId = parseInt(localStorage.getItem("dynamicBusinessId"));
  // @ts-ignore
  const dynamicTeamsId = parseInt(localStorage.getItem("dynamicTeamsId"));

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  const { dynamicColor } = useAppSelector((state) => state?.common);

  useEffect(() => {
    setprofilePictureDetails((prevDetails: any) => ({
      ...prevDetails,
      user_id: clientId,
    }));
  }, [clientId]);

  useEffect(() => {
    setprofilePictureDetails((prevDetails: any) => ({
      ...prevDetails,
      type: pathname.includes("prospects")
        ? dynamicProspectsId
        : pathname.includes("clients")
        ? dynamicClientId
        : pathname.includes("staff")
        ? dynamicStaffId
        : pathname.includes("contacts")
        ? dynamicContactId
        : pathname.includes("business")
        ? dynamicBusinessId
        : pathname.includes("teams")
        ? dynamicTeamsId
        : "",
    }));
  }, [
    dynamicProspectsId,
    dynamicClientId,
    dynamicStaffId,
    dynamicContactId,
    dynamicBusinessId,
    dynamicTeamsId,
  ]);

  const handleProfileChange = async (event: any) => {
    const file = event.target.files[0];
    if (file && file.type.includes("image/")) {
      if (file.size <= 2 * 1024 * 1024) {
        setSelectedProfile(file);
        const reader = new FileReader();
        reader.onload = async () => {
          setPreviewProfile(reader.result);
          // @ts-ignore
          const base64Data = reader.result.split(",")[1];
          const extension = file.name.split(".").pop();

          let tempObj;
          let response;

          if (pathnameBusinessAndTeams) {
            tempObj = {
              user_id: profilePictureDetails.user_id,
              type: profilePictureDetails.type,
              extension: extension,
              base64: base64Data,
            };
            response = await dispatch(
              uploadProfilePictureThunk({ data: tempObj })
            );
            dispatch(
              getBusinessByIdThunk({
                dynamicId: dynamicBusinessId,
                businessId: clientId,
              })
            );
            dispatch(
              getTeamsByIdThunk({
                dynamicId: dynamicBusinessId,
                teamsId: clientId,
              })
            );
            if (response?.payload?.status === "success") {
              toast.success("Profile Picture updated successfully!", {
                style: {},
                progressStyle: { backgroundColor: dynamicColor },
                icon: (
                  <span
                    style={{
                      marginRight: "8px",
                      borderRadius: "50%",
                      width: "7rem",
                      height: "35px",
                      textAlign: "center",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: dynamicColor,
                      backgroundColor: "white",
                      fontSize: "1.5rem",
                      border: `3px solid ${dynamicColor}`,
                    }}
                  >
                    ✓
                  </span>
                ),
              });
            }
          } else {
            tempObj = {
              user_id: profilePictureDetails.user_id,
              type: profilePictureDetails.type,
              extension: extension,
              base64: base64Data,
            };
            response = await dispatch(
              uploadProfilePictureThunk({ data: tempObj })
            );
            dispatch(getUniqueClientById({ clientId }));
            dispatch(getProspectsByIdThunk({ clientId }));
            dispatch(getStaffByIdThunk({ clientId }));
            dispatch(getContactByIdThunk({ clientId }));

            dispatch(getProfileDetailsThunk());

            if (response?.payload?.status === "success") {
              toast.success("Profile Picture updated successfully!", {
                style: {},
                progressStyle: { backgroundColor: dynamicColor },
                icon: (
                  <span
                    style={{
                      marginRight: "8px",
                      borderRadius: "50%",
                      width: "7rem",
                      height: "35px",
                      textAlign: "center",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: dynamicColor,
                      backgroundColor: "white",
                      fontSize: "1.5rem",
                      border: `3px solid ${dynamicColor}`,
                    }}
                  >
                    ✓
                  </span>
                ),
              });
            }
          }
        };
        reader.readAsDataURL(file);
      } else {
        toast.error("Image size exceeds the maximum allowed size of 2MB.");
        event.target.value = null;
        setPreviewProfile(null);
        setSelectedProfile(null);
      }
    } else {
      toast.error("Please select a valid image.");
      event.target.value = null;
      setPreviewProfile(null);
      setSelectedProfile(null);
    }
  };

  const handleClickOutside = (event: any) => {
    if (
      profilePictureRef?.current &&
      // @ts-ignore
      !profilePictureRef?.current?.contains(event.target)
    ) {
      const profileElement = document.querySelector(
        ".profile_picture_container"
      );
      if (profileElement) {
        profileElement.classList.add("profile_fade_out");
        setTimeout(() => {
          setShowProfile(false);
        }, 200);
      }
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const profileElement = document.querySelector(".profile_picture_container");
    if (profileElement) {
      if (showProfile) {
        profileElement.classList.remove("profile_fade_out");
        profileElement.classList.add("profile_fade_in");
      } else {
        profileElement.classList.remove("profile_fade_in");
        profileElement.classList.add("profile_fade_out");
        setTimeout(() => {
          setShowProfile(false);
        }, 200);
      }
    }
  }, [showProfile]);
  const handleUploadClick = () => {
    // @ts-ignore
    document.getElementById("profileInput").click();
  };

  return (
    // @ts-ignore
    <div
      ref={profilePictureRef}
      className={` ${
        showProfile ? "profile_fade_in" : "profile_fade_out"
      } profile_picture_container ${className}`}
    >
      <h4 className="profile_picture_heading">Update Profile Picture</h4>
      <div className="profile_picture_img_inner">
        <div>
          {profilePicture ? (
            <img
              className="profile_img"
              src={profilePicture ? profilePicture : null}
              alt="profile"
            />
          ) : (
            <div
              className="initials__daybook"
              style={{
                visibility: previewProfile ? "hidden" : "visible",
              }}
            >
              {currentClient?.name ? (
                <>
                  <div className="meal_builder">
                    {getInitials(currentClient?.name)}
                  </div>
                </>
              ) : (
                <>
                  <div className="meal_builder">
                    {currentClient?.first_name?.charAt(0).toUpperCase()}
                    {currentClient?.last_name?.charAt(0).toUpperCase()}
                  </div>
                </>
              )}
            </div>
          )}
        </div>
        <div>
          <div onClick={handleUploadClick} className="profile_upload">
            <UploadSvg />
            <label className="profile_picture_label">Upload</label>
            <input
              className="profile_picture_input"
              id="profileInput"
              accept="image/png, image/gif, image/jpeg,image/*"
              onChange={handleProfileChange}
              type="file"
              placeholder="upload"
            />
          </div>
          <p className="profile_drag">
            Drag in <br /> or Select
          </p>
        </div>
      </div>

      <button
        onClick={() => setShowProfile(false)}
        className="profile_picture_btn profile_btn_not_save"
      >
        Continue without saving
      </button>
      <button
        onClick={() => {
          dispatch(getUniqueClientById({ clientId }));
          setShowProfile(false);
        }}
        className="profile_picture_btn profile_btn_save"
      >
        Save
      </button>
    </div>
  );
};

export default ProfilePictureModal;
