import "./Styles/UpdateProfileDetails.css";

import { useState } from "react";

import DaybookHeader from "../../Daybook/Components/DaybookHeader";
import { updateProfileArr } from "./Data/UpdateProfileArr";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../Controller/redux/Hooks";
import { toast } from "react-toastify";
import CircularNoteTrigger from "../../../components/CircularNoteTrigger/CircularNoteTrigger";
import { updateUserPasswordThunk } from "../../../../Controller/redux/features/CommonFeature/commonSlice";
import { useLocation } from "react-router-dom";

const UpdateProfileDetails = () => {
  const dispatch = useAppDispatch();
  const pathname = useLocation().pathname;

  const { dynamicColor } = useAppSelector((state) => state?.common);
  const [details, setDetails] = useState<any>({
    old_password: "",
    new_password: "",
    confirm_password: "",
  });
  const handleSubmit = async () => {
    if (
      details.old_password === "" ||
      details.new_password === "" ||
      details.confirm_password === ""
    ) {
      toast.error("All Fields Required!");
      return;
    }

    if (details.new_password !== details.confirm_password) {
      toast.warn("New Password and Confirm password do not match!");
      return;
    }
    const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*?&])[0-9a-zA-Z@$!%*?&]{6,}$/;
    if (!passwordRegex.test(password)) {
      toast.warn(
        "Password must contain at least 6 characters including uppercase, lowercase letters, one number, and one of the following symbols: @$!%*?&"
      );
      // setRequestStatus("success");
      return;
    }
    try {
      const response = await dispatch(updateUserPasswordThunk(details));

      if (
        response?.payload?.response?.data?.response ===
        "Your old password is not matched with our data, please enter valid old password"
      ) {
        toast.warn("Please Enter a Valid Current Password!");
        return;
      }

      if (
        response?.payload?.response?.data?.data?.new_password[0] ===
        "Old password and new password must not be same"
      ) {
        toast.warn("Current Password and New Password must not be same!");
        return;
      }

      if (response.payload.status === "success") {
        toast.success("Password updated successfully!", {
          style: {},
          progressStyle: { backgroundColor: dynamicColor },
          icon: (
            <span
              style={{
                marginRight: "8px",
                borderRadius: "50%",
                width: "7rem",
                height: "35px",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: dynamicColor,
                backgroundColor: "white",
                fontSize: "1.5rem",
                border: `3px solid ${dynamicColor}`,
              }}
            >
              ✓
            </span>
          ),
        });
      } else {
        toast.error("An Error Occurred!");
      }
      setDetails({ old_password: "", new_password: "", confirm_password: "" });
    } catch (error) {
      // console.log(error);
    }
  };
  const handleChange = (
    index: number,
    e: React.ChangeEvent<HTMLInputElement> | any
  ) => {
    const { value } = e.target;
    const fieldName = Object.keys(details)[index];
    
    setDetails((prevState: any) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  return (
    <section>
      {!pathname.includes("staff") && <DaybookHeader />}
      <div className="px-[10px]">
        <div
          className={`profile_update_heading client-screen-personal-details-heading ${
            pathname.includes("staff") && "update_profile_by_staff_screen"
          }`}
        >
          <span className=" client-screen-sub-heading-title">
            Update Password
          </span>
          <span className=" client-screen-line"></span>
        </div>
        <div className="main-box-body">
          {updateProfileArr.map((elem: any, index: any) => {
            const fieldName = Object.keys(details)[index];
            return (
              <div className="content-wrapper" key={index}>
                <div className="wrapper-title">{elem.UpperTitle}</div>
                <input
                  className="wrapper-info"
                  type="password"
                  onChange={(e) => handleChange(index, e)}
                  value={details[fieldName]}
                  placeholder={elem?.placeHolder}
                  style={{
                    border: "none",
                    outline: "none",
                    height: "fit-content",
                  }}
                />
                <div className="wrapper-line"></div>
              </div>
            );
          })}
          {details.old_password !== "" &&
            details.new_password !== "" &&
            details.confirm_password !== "" && (
              <button
                onClick={handleSubmit}
                style={{ backgroundColor: dynamicColor }}
                className="update_profile_btn"
              >
                Update
              </button>
            )}
        </div>
      </div>
      <CircularNoteTrigger type="stickyHub" title="Comms" />
    </section>
  );
};

export default UpdateProfileDetails;
