//@ts-nocheck
import "./Styles/ClinicalProgress.css";
import { CrossMdSvg } from "../../../Model/Assets/SVG/Common/CrossSvg";
import { PlusIconWhite } from "../../../Model/Assets/SVG/WidgetVillage/PlusIcon";
import { NormalCapsule, TextCapsule } from "./Components/Capsule";
import ExpandedNote from "./Components/ExpandedNote";
import CollapsedNote from "./Components/CollapsedNote";
import { useEffect, useState } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../Controller/redux/Hooks";
import { useParams } from "react-router-dom";
import {
  createClinicalNoteThunk,
  getAllClinicalNotes,
  getGoalsThunk,
  resetClinicalRecords,
  setnoteInfoProgress,
  updateClinicalNoteThunk,
} from "../../../Controller/redux/features/ClinicalFeature/clinicalSlice";
import { formatDateForAPI } from "../../../Model/utils/Helper/formatDate";
import { toast } from "react-toastify";
import CustomDatePicker from "../DatePicker/CustomDatePicker";
import { SuccessToastComponent } from "../SuccessToast/SuccessToast";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
import ClinicalNote from "./Components/ClinicalNote";
import { convertDateTimeFormat } from "../../../Model/utils/Helper/formatTime";

const ClinicalProgress = ({
  forSticky = false,
  onClickCross = () => {},
  setshowGoalsModal = () => {}
}: any) => {
  const dispatch = useAppDispatch();
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [expandedNote, setIsExpandedNote] = useState<number>(-1);
  const [clinicalDate, setClinicalDate] = useState<Date | any>(new Date());

  const getCurrentTime = () => {
    const now = new Date();
    const hours = now.getHours().toString().padStart(2, "0");
    const minutes = now.getMinutes().toString().padStart(2, "0");
    return `${hours}:${minutes}`;
  };
  const [clinicalGoalsNote, setClinicalGoalsNote] = useState("");
  // const [ isThereAnyChange, setisThereAnyChange] = useState(false);

  const [time, setTime] = useState<string>(getCurrentTime());

  useEffect(() => {
    setTime(getCurrentTime());
  }, []);

  const handleNoteClick = (index: number) => {
    setIsExpandedNote(index === expandedNote ? -1 : index);
  };

  const { user } = useAppSelector((state) => state.common);
  const id: any = useParams()?.id;

  //  Client ID null checking...
  const [clientId, setclientId] = useState("");
  useEffect(() => {
    if (id) {
      let clientId = window.atob(id);
      setclientId(clientId);
    }
  }, [id]);

  useEffect(() => {
    if (clientId) {
      dispatch(getGoalsThunk({ clientId }));
    }
  }, [clientId]);

  const allGoalsData = useAppSelector(
    (state) => state?.clinical?.allGoalsData?.data
  );
  const clientDetail = useAppSelector(
    (state) => state?.people?.uniqueClient?.data?.data[0]
  );

  const [GoalsData, setGoalsData] = useState<any[]>([]);

  useEffect(() => {
    setGoalsData(allGoalsData);
  }, [allGoalsData]);

  const responsibleParties: any = [];
  allGoalsData?.forEach((goal: any) => {
    goal?.activities?.forEach((activity: any) => {
      activity?.responsible_parties?.forEach((party: any) => {
        responsibleParties?.push({
          id: party?.id,
          first_name: party?.first_name,
          last_name: party?.last_name,
        });
      });
    });
  });
  const uniqueResponsibleParties = responsibleParties?.filter(
    (item: any, index: number, array: any) => {
      return array?.findIndex((obj: any) => obj.id === item.id) === index;
    }
  );

  const { selectedClinicalNoteInfo } = useAppSelector(
    (state) => state.clinical
  );

  // @ts-ignore
  let realId = JSON.parse(localStorage.getItem("user"));
  let userId = realId.data.id;

  const dynamicColor = useAppSelector((state) => state?.common?.dynamicColor);

  const handleSaveNote = async (info: any) => {
    let noteInfo = info;
    noteInfo.note_date_time = `${formatDateForAPI(
      clinicalDate
    )} ${time.substring(0, 5)}`;

    if (selectedClinicalNoteInfo?.id) {
      if (selectedClinicalNoteInfo?.staff_user_id !== userId) {
        // toast.warn("You don't have the right permissions to edit this note.");
        return;
      }
      let pamrs = {
        ...selectedClinicalNoteInfo,
        activity_note_id: selectedClinicalNoteInfo?.id,
        note: info?.note,
        note_date_time: (noteInfo.note_date_time = `${formatDateForAPI(
          clinicalDate
        )} ${time.substring(0, 5)}`),
      };
      try {
        const response = await dispatch(updateClinicalNoteThunk(pamrs));
        if (response?.payload?.status === "success") {
          <SuccessToastComponent title={"Note Saved Successfully!"} />;
          dispatch(resetClinicalRecords());
          dispatch(getAllClinicalNotes({ user_id: clientId, ClinicalPage: 1 }));
        }
      } catch (error: any) {}
      return;
    }
    dispatch(resetClinicalRecords());

    try {
      const response = await dispatch(createClinicalNoteThunk(noteInfo));
      if (response?.payload?.status === "success") {
        toast.success("Note Created Successfully", {
          progressStyle: { backgroundColor: dynamicColor },
          icon: (
            <span
              style={{
                marginRight: "8px",
                borderRadius: "50%",
                width: "7rem",
                height: "35px",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: dynamicColor,
                backgroundColor: "white",
                fontSize: "1.5rem",
                border: `3px solid ${dynamicColor}`,
              }}
            >
              ✓
            </span>
          ),
        });
        dispatch(resetClinicalRecords());
        dispatch(getAllClinicalNotes({ user_id: clientId, ClinicalPage: 1 }));
      }
      setClinicalGoalsNote("");
    } catch (error: any) {}
    dispatch(resetClinicalRecords());
  };

  useEffect(() => {
    if (selectedClinicalNoteInfo?.id ) {
      let findGoalIndex = allGoalsData?.findIndex(
        (res: any) => res?.id === selectedClinicalNoteInfo?.goal_id
      );
      if (typeof findGoalIndex === "number") {
        // setTimeout(() => {
        setIsExpandedNote(findGoalIndex);
      }
    }
  }, [selectedClinicalNoteInfo, allGoalsData]);

  const [isChange, setisChange] = useState(false);
  const { allServiceTypeRecord } = useAppSelector((state) => state?.common);
  const [ServiceTypeArr, setServiceTypeArr] = useState<any[]>([]);

  useEffect(() => {
    if (allServiceTypeRecord?.data) {
      setServiceTypeArr(allServiceTypeRecord?.data);
    }
  }, [allServiceTypeRecord]);

  useEffect(() => {
    if (selectedOption) {
      dispatch(getGoalsThunk({ clientId, service_type_id: selectedOption }));
      // return () => {};
    }
  }, [selectedOption]);
  const [showModal, setShowModal] = useState(false);

  let isLoggedInUserSame = selectedClinicalNoteInfo?.staff_user_id == userId;

  let loggedInUser = JSON.parse(localStorage.getItem("user"))?.data;

  const staffInitials =
    (selectedClinicalNoteInfo?.staff_user?.first_name?.[0] ||
      loggedInUser?.first_name?.[0] ||
      "") +
    "." +
    (selectedClinicalNoteInfo?.staff_user?.last_name?.slice(0, 9) ||
      loggedInUser?.last_name?.slice(0, 9) ||
      "");

  return (
    <div
      className={`${
        forSticky ? "clinical_container_sticky" : "clinical_container"
      } `}
      style={{
        cursor: isLoggedInUserSame ? "pointer" : "default",
      }}
    >
      {showModal && (
        <>
          <ConfirmationModal
            type="clientScreen"
            handleMoveWithSave={() => {
              let obj = {
                goal_id: null,
                note: clinicalGoalsNote,
                note_tag: "Progress",
                note_date_time: "",
                user_id: clientId,
                note_type: "Advance Care",
              };
              handleSaveNote(obj);
              onClickCross();
              
            }}
            handleMoveWithoutSaving={() => setshowGoalsModal(false)}
          />
        </>
      )}
      <div style={{ position: "absolute", right: "0px" }}>
        <CrossMdSvg
          onClick={() => {
            if (isChange) {
              setShowModal(true);
            } else {
              onClickCross();
            }
          }}
          className={`pointer ${
            forSticky ? "display_none" : "clinical_cross"
          } `}
        />
      </div>
      <div
        className={`${
          forSticky
            ? "clinical_header_container_sticky"
            : "clinical_header_container"
        } `}
      >
        <div className="clinical_img_title_wrapper">
          {clientDetail?.profile_image_s3 ? (
            <img
              className="clinical_img"
              src={clientDetail?.profile_image_s3}
              alt="profile"
            />
          ) : (
            <div
              className={`${
                forSticky
                  ? "clinical_initails_sticky clinical_img_sticky"
                  : "clinical_initails clinical_img"
              } `}
            >
              {`${clientDetail?.first_name[0]}  ${clientDetail?.last_name[0]}`}
            </div>
          )}
          <div>
            <h5
              className={`${
                forSticky ? "clinical_title_sticky" : "clinical_title"
              } `}
            >
              Clinical Progress
            </h5>
            <span
              className={`fade_out ${
                forSticky ? "clinical_client_sticky" : "clinical_client"
              } `}
            >
              {clientDetail?.first_name.slice(0, 10) +
                " " +
                clientDetail?.last_name.slice(0, 10)}
            </span>
          </div>
        </div>
        <div className="clinical_date_time_container">
          <div className="clinical_flex_mb">
            <span className="due_text">Date :</span>
            <div className="date_text" id="clinical_progress_date_id">
              <CustomDatePicker
                newDate={clinicalDate}
                setisChange={setisChange}
                handleChange={setClinicalDate}
                type="input"
                minDate={new Date()}
              />
            </div>
          </div>
          <div className="clinical_flex_mb">
            <span className="due_text">Time :</span>
            <span className="date_text" id="clinical_progress_time_id">
              {/* <TimePicker setTime={setTime} time={time} /> */}
              <input
                type="time"
                value={time}
                onChange={(e) => {
                  setisChange(true);
                  setTime(e.target.value);
                }}
              />
            </span>
          </div>
        </div>
      </div>
      <div
        className={`${
          forSticky
            ? "clinical_capsule_container_sticky"
            : "clinical_capsule_container"
        } `}
      >
        <div className="clinical_drop_container">
          <select
            className="clinical_progress_dropdown"
            value={selectedOption}
            onChange={(e) => {
              // setisChange(true);
              setSelectedOption(e?.target?.value);
              setIsDropdownOpen(false);
            }}
            title={
              selectedOption
                ? JSON.parse(selectedOption)?.name?.length > 14 &&
                  JSON.parse(selectedOption)?.name
                : ""
            }
          >
            <option value={""}>Please Select</option>
            {ServiceTypeArr.map((option) => (
              <option
                key={option?.id}
                value={JSON.stringify(option)}
                title={option?.name}
              >
                {option?.name?.length > 14
                  ? option?.name?.slice(0, 14) + "..."
                  : option?.name}
              </option>
            ))}
          </select>
        </div>

        <div className="clinical_capsule_flex">
          <div>
            <NormalCapsule
              image={
                selectedClinicalNoteInfo?.staff_user?.profile_image_s3 ||
                user?.profile_image_s3
              }
              className={`${
                forSticky && "normal_text_sticky"
              }  normal_capsule_mb`}
              title={staffInitials}
            />
          </div>
          <div className="clinical_responsible">
            {uniqueResponsibleParties?.map((elem: any) => (
              <NormalCapsule
                index={elem?.id}
                title={
                  elem?.first_name?.slice(0, 6) +
                  " " +
                  elem?.last_name?.slice(0, 6)
                }
                className={`${
                  forSticky && "normal_text_sticky"
                } clinical_grey_capsule`}
              />
            ))}
          </div>
        </div>
      </div>
      <div
        className={`${
          forSticky
            ? "clinical_text_capsule_container_sticky"
            : "clinical_text_capsule_container"
        } fade_out `}
      >
        {["Reply", "Clinical Note", "Schedule", "Pin"].map(
          (elem: any, index: number) => (
            <TextCapsule
              className={`${forSticky && "text_capsule_sticky"} `}
              key={index}
              title={elem}
            />
          )
        )}
        <PlusIconWhite
          className="clinical_plus_icon "
          width="24"
          height="24"
          color="#ffffff"
        />
      </div>
      <hr />
      <h3
        className={`fade_out h-0 ${
          forSticky ? "clinical_title_main_sticky" : "clinical_title"
        } `}
      >
        {clientDetail?.first_name.slice(0, 10) +
          " " +
          clientDetail?.last_name.slice(0, 10) +
          " "}
      </h3>

      <div
        className={`${
          forSticky
            ? "clinical_expan_collap_container_sticky"
            : "clinical_expan_collap_container"
        } `}
        style={{
          cursor: isLoggedInUserSame ? "pointer" : "default",
        }}
      >
        {GoalsData?.map((elem: any, index: number) => {
          const isExpanded = index === expandedNote;
          return (
            <>
              {isExpanded ? (
                <ExpandedNote
                  forSticky={forSticky}
                  selectedTags={elem?.tags}
                  isLoggedInUserSame={isLoggedInUserSame}
                  activities={elem?.activities}
                  title={elem?.summary}
                  isOpen={index === expandedNote}
                  onClickPlus={() => setIsExpandedNote(-1)}
                  goalInfo={elem}
                  ClinicalProgress={true}
                  handelSaveNote={handleSaveNote}
                  fromAdvancedClinicalNote={true}
                  goalIndex={index}
                  setGoalsData={setGoalsData}
                  clinicalGoalsNote={clinicalGoalsNote}
                  setClinicalGoalsNote={setClinicalGoalsNote}
                />
              ) : (
                <CollapsedNote
                  isOpen={index === expandedNote}
                  forSticky={forSticky}
                  index={index}
                  title={elem?.summary}
                  selectedTags={elem?.tags}
                  fromAdvancedClinicalNote={true}

                  // onClick={}
                  onClickPlus={() => handleNoteClick(index)}
                />
              )}
            </>
          );
        })}
        <div
          style={{
            position: "absolute",
            bottom: 0,
            width: "99%",
          }}
        >
          {true && (
            <div
              style={{
                cursor: isLoggedInUserSame ? "pointer" : "default",
              }}
            >
              <ClinicalNote
                isFromClinicalProgressModal
                clinicalGoalsNote={clinicalGoalsNote}
                isLoggedInUserSame={isLoggedInUserSame}
                dropdownValue={true}
                onChangeTextNote={(e: any) => {
                  setisChange(true)
                  dispatch(setnoteInfoProgress(e.target.value));
                  setClinicalGoalsNote(e.target.value);
                }}
                clientId={clientId}
                dateTime={convertDateTimeFormat(new Date())}
                fromAdvancedClinicalNote={true}
                userFromAdvanceCareNote={selectedClinicalNoteInfo}
                elem={selectedClinicalNoteInfo}
              />
            </div>
          )}
        </div>
      </div>

      <button
        className="save_btn"
        style={{
          marginRight: "12px",
          marginBottom: "9px",
          display: isLoggedInUserSame ? "" : "none",
        }}
        onClick={onClickCross}
      >
        Save
      </button>
    </div>
  );
};
export default ClinicalProgress;
