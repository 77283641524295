import React from "react";
import {useState} from "react";

export const CommsIcon = ({ comms }: string[] | any) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="16"
        viewBox="0 0 12 12"
        fill="none"
      >
        <path
          d="M6 0C9.3138 0 12 2.6862 12 6C12 9.3138 9.3138 12 6 12C5.0424 12.0013 4.0985 11.7725 3.2478 11.3328C2.97312 11.1438 2.6433 11.1219 2.35089 11.2821C1.79004 11.5894 0.888309 12.0621 0.560239 11.976C0.431624 11.9423 0.314294 11.875 0.220271 11.7809C0.126247 11.6869 0.0589109 11.5696 0.0251613 11.441C-0.00858834 11.3123 -0.00753788 11.1771 0.028205 11.049L0.669005 8.7552C0.228216 7.90376 -0.00124128 6.95877 5.05017e-06 6C5.05017e-06 2.6862 2.6862 0 6 0ZM4.05 6.6H6.7488C6.86282 6.60003 6.97257 6.64334 7.05588 6.72118C7.13919 6.79902 7.18985 6.90558 7.19762 7.01933C7.20539 7.13307 7.16969 7.24553 7.09774 7.33398C7.02579 7.42242 6.92295 7.48026 6.81 7.4958L6.7488 7.5H4.05C3.93599 7.49996 3.82624 7.45665 3.74293 7.37881C3.65962 7.30098 3.60896 7.19442 3.60119 7.08067C3.59342 6.96692 3.62911 6.85446 3.70106 6.76602C3.77301 6.67757 3.87585 6.61974 3.9888 6.6042L4.05 6.6ZM4.05 4.5H7.953C8.06697 4.50018 8.17661 4.5436 8.25981 4.62149C8.343 4.69937 8.39355 4.80592 8.40124 4.91962C8.40893 5.03333 8.3732 5.14571 8.30125 5.23409C8.2293 5.32247 8.1265 5.38026 8.0136 5.3958L7.953 5.4H4.05C3.93599 5.39996 3.82624 5.35665 3.74293 5.27881C3.65962 5.20098 3.60896 5.09442 3.60119 4.98067C3.59342 4.86692 3.62911 4.75446 3.70106 4.66602C3.77301 4.57757 3.87585 4.51974 3.9888 4.5042L4.05 4.5Z"
          fill="#06A5FF"
        />
        <text
          x="6"
          y="6"
          fill="#FFF"
          textAnchor="middle"
          fontFamily="Arial"
          fontSize="10px"
          fontStyle="normal"
          fontWeight="400"
          letterSpacing="0.25px"
          alignmentBaseline="central"
        >
          {comms?.length || 6}
        </text>
      </svg>
    </>
  );
};

export const AddIconClinicalModal = ({
  onMouseLeave,
  onClick,
  onMouseEnter,
  setmouseOverPlusIcon,
  mouseOverPlusIcon,
  className,
}: any) => {
  return (
    <>
      <svg
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={onClick}
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill={!mouseOverPlusIcon ? "#87CEFA" : "#F3F3F3"}
      >
        <circle
          cx="6"
          cy="6"
          r="6"
          fill={!mouseOverPlusIcon ? "white" : "white"}
        />
        <path
          d="M6.83516 5.22148H10V6.79642H6.83516V10H5.16484V6.79642H2V5.22148H5.16484V2H6.83516V5.22148Z"
          fill={!mouseOverPlusIcon ? "#87CEFA" : "#F3F3F3"}
        />
      </svg>
    </>
  );
};

export const AddIconClinicalModalModified = ({
  style,
  onClick,
  height,
  width,
  className,
  fill,
}: any) => {
  return (
    <>
      <svg
        onClick={onClick}
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        width="28"
        height="28"
        viewBox="0 0 12 12"
        fill={"#87CEFA"}
      >
        <circle cx="6" cy="6" r="6" fill={"white"} />
        <path
          d="M6.83516 5.22148H10V6.79642H6.83516V10H5.16484V6.79642H2V5.22148H5.16484V2H6.83516V5.22148Z"
          fill={fill || "light blue"}
        />
      </svg>
    </>
  );
};

export const StatusIcon = ({ color }: any) => {
  return (
    <>
      <svg
        width="9"
        height="9"
        viewBox="0 0 2 2"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          borderRadius: "10px",
        }}
      >
        <path
          d="M1 2C1.55228 2 2 1.55229 2 1C2 0.447715 1.55228 0 1 0C0.447715 0 0 0.447715 0 1C0 1.55229 0.447715 2 1 2Z"
          fill={color}
        />
        <path
          d="M1 2C1.55228 2 2 1.55229 2 1C2 0.447715 1.55228 0 1 0C0.447715 0 0 0.447715 0 1C0 1.55229 0.447715 2 1 2Z"
          stroke={color}
        />
      </svg>
    </>
  );
};

export const StatusIconClinical = ({ color }: any) => {
  return (
    <>
      <svg
        width="19"
        height="19"
        viewBox="0 0 2 2"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          borderRadius: "10px",
        }}
      >
        <path
          d="M1 2C1.55228 2 2 1.55229 2 1C2 0.447715 1.55228 0 1 0C0.447715 0 0 0.447715 0 1C0 1.55229 0.447715 2 1 2Z"
          fill={color}
        />
        <path
          d="M1 2C1.55228 2 2 1.55229 2 1C2 0.447715 1.55228 0 1 0C0.447715 0 0 0.447715 0 1C0 1.55229 0.447715 2 1 2Z"
          stroke={color}
        />
      </svg>
    </>
  );
};

export const HamIcon = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="35"
        height="35"
        viewBox="0 0 35 35"
        fill="none"
      >
        <path
          d="M4.375 17.5H30.625"
          stroke="black"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.375 8.75H30.625"
          stroke="black"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.375 26.25H30.625"
          stroke="black"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};

export const CircleIcon = () => {
  return (
    <>
      <svg
        width="3"
        height="3"
        viewBox="0 0 2 2"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          borderRadius: "10px",
        }}
      >
        <path
          d="M1 2C1.55228 2 2 1.55229 2 1C2 0.447715 1.55228 0 1 0C0.447715 0 0 0.447715 0 1C0 1.55229 0.447715 2 1 2Z"
          fill={"white"}
        />
        <path
          d="M1 2C1.55228 2 2 1.55229 2 1C2 0.447715 1.55228 0 1 0C0.447715 0 0 0.447715 0 1C0 1.55229 0.447715 2 1 2Z"
          stroke={"white"}
        />
      </svg>
    </>
  );
};

export const AttachmentIcon = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
      >
        <g clipPath="url(#clip0_3624_12386)">
          <path
            d="M36 11.1738V33.6087C36 34.9306 34.8684 36 33.4749 36H2.52511C1.13162 36 0 34.9275 0 33.6087V2.39127C0 1.07249 1.13162 0 2.52511 0H24.3315"
            fill="#06A5FF"
          />
          <path d="M24.3281 0V11.1738H35.9935L24.3281 0Z" fill="#24A500" />
          <path
            d="M8.92188 15.3945V27.9183H27.084V15.3945H8.92188ZM25.5253 19.0485H18.7823V16.9534H25.5253V19.0485ZM18.7823 20.6073H25.5253V22.7024H18.7823V20.6073ZM17.2236 22.7024H10.4806V20.6073H17.2236V22.7024ZM17.2236 16.9534V19.0485H10.4806V16.9534H17.2236ZM10.4806 24.2612H17.2236V26.3563H10.4806V24.2612ZM18.7823 26.3563V24.2612H25.5253V26.3563H18.7823Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_3624_12386">
            <rect width="36" height="36" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};

export const ReplyIcon = () => {
  return (
    <>
      <svg
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="12" height="12" rx="6" fill="#06A5FF" />
        <path
          d="M6.9384 7.0752C6.6584 7.0752 6.2984 6.9652 5.8684 6.7252C5.5284 6.5552 5.2884 6.4652 5.1384 6.4652C4.8384 6.4652 4.6684 6.6652 4.6084 7.0452H3.6884C3.7084 6.5452 3.8384 6.1452 4.0784 5.8552C4.3184 5.5652 4.6484 5.4152 5.0684 5.4152C5.3384 5.4152 5.6984 5.5352 6.1584 5.7652C6.4784 5.9452 6.7184 6.0252 6.8584 6.0252C7.1484 6.0252 7.3284 5.8352 7.3884 5.4452H8.3084C8.2884 5.9552 8.1584 6.3552 7.9184 6.6452C7.6784 6.9352 7.3484 7.0752 6.9384 7.0752Z"
          fill="#FCFCFC"
        />
      </svg>
    </>
  );
};

export const ServiceReply = () => {
  return (
    <>
      <svg
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="12" height="12" rx="6" fill="#EF3A34" />
        <path
          d="M6.9384 7.0752C6.6584 7.0752 6.2984 6.9652 5.8684 6.7252C5.5284 6.5552 5.2884 6.4652 5.1384 6.4652C4.8384 6.4652 4.6684 6.6652 4.6084 7.0452H3.6884C3.7084 6.5452 3.8384 6.1452 4.0784 5.8552C4.3184 5.5652 4.6484 5.4152 5.0684 5.4152C5.3384 5.4152 5.6984 5.5352 6.1584 5.7652C6.4784 5.9452 6.7184 6.0252 6.8584 6.0252C7.1484 6.0252 7.3284 5.8352 7.3884 5.4452H8.3084C8.2884 5.9552 8.1584 6.3552 7.9184 6.6452C7.6784 6.9352 7.3484 7.0752 6.9384 7.0752Z"
          fill="#FCFCFC"
        />
      </svg>
    </>
  );
};

export const SearchIcon = () => {
  return (
    <>
      <svg
        style={{
          position: "absolute",
          left: "45px",
          zIndex: 12121211211,
          color: "#C7C7C7",
        }}
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g opacity="0.2">
          <mask
            id="path-1-outside-1_0_504"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="20"
            height="20"
            fill="#C7C7C7"
          >
            <rect fill="#C7C7C7" width="20" height="20" />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M14.7465 8.37326C14.7465 11.8931 11.8931 14.7465 8.37326 14.7465C4.85341 14.7465 2 11.8931 2 8.37326C2 4.85341 4.85341 2 8.37326 2C11.8931 2 14.7465 4.85341 14.7465 8.37326ZM12.966 14.1418C11.7061 15.1462 10.1098 15.7465 8.37326 15.7465C4.30112 15.7465 1 12.4454 1 8.37326C1 4.30112 4.30112 1 8.37326 1C12.4454 1 15.7465 4.30112 15.7465 8.37326C15.7465 10.3487 14.9697 12.1427 13.7048 13.4664L18.5965 18.3581L17.8894 19.0652L12.966 14.1418Z"
            />
          </mask>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.7465 8.37326C14.7465 11.8931 11.8931 14.7465 8.37326 14.7465C4.85341 14.7465 2 11.8931 2 8.37326C2 4.85341 4.85341 2 8.37326 2C11.8931 2 14.7465 4.85341 14.7465 8.37326ZM12.966 14.1418C11.7061 15.1462 10.1098 15.7465 8.37326 15.7465C4.30112 15.7465 1 12.4454 1 8.37326C1 4.30112 4.30112 1 8.37326 1C12.4454 1 15.7465 4.30112 15.7465 8.37326C15.7465 10.3487 14.9697 12.1427 13.7048 13.4664L18.5965 18.3581L17.8894 19.0652L12.966 14.1418Z"
            fill="#C7C7C7"
          />
          <path
            d="M12.966 14.1418L13.3195 13.7883L13.0036 13.4724L12.6543 13.7509L12.966 14.1418ZM13.7048 13.4664L13.3433 13.121L13.0056 13.4744L13.3512 13.82L13.7048 13.4664ZM18.5965 18.3581L18.95 18.7117L19.3036 18.3581L18.95 18.0046L18.5965 18.3581ZM17.8894 19.0652L17.5358 19.4188L17.8894 19.7724L18.2429 19.4188L17.8894 19.0652ZM8.37326 15.2465C12.1693 15.2465 15.2465 12.1693 15.2465 8.37326H14.2465C14.2465 11.617 11.617 14.2465 8.37326 14.2465V15.2465ZM1.5 8.37326C1.5 12.1693 4.57726 15.2465 8.37326 15.2465V14.2465C5.12955 14.2465 2.5 11.617 2.5 8.37326H1.5ZM8.37326 1.5C4.57726 1.5 1.5 4.57726 1.5 8.37326H2.5C2.5 5.12955 5.12955 2.5 8.37326 2.5V1.5ZM15.2465 8.37326C15.2465 4.57726 12.1693 1.5 8.37326 1.5V2.5C11.617 2.5 14.2465 5.12955 14.2465 8.37326H15.2465ZM8.37326 16.2465C10.227 16.2465 11.9324 15.6052 13.2776 14.5328L12.6543 13.7509C11.4797 14.6872 9.99251 15.2465 8.37326 15.2465V16.2465ZM0.5 8.37326C0.5 12.7215 4.02498 16.2465 8.37326 16.2465V15.2465C4.57726 15.2465 1.5 12.1693 1.5 8.37326H0.5ZM8.37326 0.5C4.02498 0.5 0.5 4.02498 0.5 8.37326H1.5C1.5 4.57726 4.57726 1.5 8.37326 1.5V0.5ZM16.2465 8.37326C16.2465 4.02498 12.7215 0.5 8.37326 0.5V1.5C12.1693 1.5 15.2465 4.57726 15.2465 8.37326H16.2465ZM14.0663 13.8118C15.4165 12.3988 16.2465 10.4825 16.2465 8.37326H15.2465C15.2465 10.215 14.5228 11.8866 13.3433 13.121L14.0663 13.8118ZM13.3512 13.82L18.2429 18.7117L18.95 18.0046L14.0583 13.1129L13.3512 13.82ZM18.2429 18.0046L17.5358 18.7117L18.2429 19.4188L18.95 18.7117L18.2429 18.0046ZM18.2429 18.7117L13.3195 13.7883L12.6124 14.4954L17.5358 19.4188L18.2429 18.7117Z"
            fill="#C7C7C7"
            mask="url(#path-1-outside-1_0_504)"
          />
        </g>
      </svg>
    </>
  );
};

export const GoalsPlusSVG = ({
  width = 24,
  height = 24,
  onClick,
  isHovered,
}: any) => {
  return (
    <>
      <svg
        style={{
          cursor: "pointer",
        }}
        width={width}
        onClick={onClick}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="12"
          cy="12"
          r="12"
          fill={isHovered ? "#0a6491" : "#f3f3f3"}
        />
        <path
          d="M13.6625 10.439H19.9922V13.5889H13.6625V19.9961H10.3219V13.5889H3.99219V10.439H10.3219V3.99609H13.6625V10.439Z"
          fill="white"
        />
      </svg>
    </>
  );
};

export const ThreadCount = ({ count, width = 52, height = 52 }: any) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 52 52"
        fill="none"
      >
        <path
          d="M26 0C40.3598 0 52 11.6402 52 26C52 40.3598 40.3598 52 26 52C21.8504 52.0057 17.7602 51.0141 14.0738 49.1088C12.8835 48.2899 11.4543 48.1949 10.1872 48.8891C7.75686 50.2206 3.84934 52.2692 2.4277 51.8962C1.87037 51.7499 1.36194 51.4581 0.954506 51.0507C0.547071 50.6433 0.255281 50.1348 0.109032 49.5775C-0.0372161 49.0202 -0.0326641 48.434 0.122222 47.879L2.89902 37.9392C0.988937 34.2496 -0.00537888 30.1547 2.1884e-05 26C2.1884e-05 11.6402 11.6402 0 26 0Z"
          fill="#E6E6E6"
        />
        <text
          x="50%"
          y="50%"
          dominantBaseline="middle"
          textAnchor="middle"
          style={{
            fill: "#212121",
            textAlign: "center",
            fontFamily: "Helvetica Now Display",
            fontSize: "27px",
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "100%",
            letterSpacing: "0.5px",
            transform: "translateY(2.5px)",
          }}
        >
          {count || "13"}
        </text>
      </svg>
    </>
  );
};

export const PolygonSVG = ({ color, width, height }: any) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width || "21"}
        height={height || "20"}
        viewBox="0 0 21 20"
        fill="none"
      >
        <path
          d="M7.0359 2C8.5755 -0.66667 12.4245 -0.666667 13.9641 2L20.4593 13.25C21.9989 15.9167 20.0744 19.25 16.9952 19.25H4.00481C0.925604 19.25 -0.998893 15.9167 0.540708 13.25L7.0359 2Z"
          fill={color || "#F1B60D"}
        />
      </svg>
    </>
  );
};

export const DatePickerDropdown = () => {
  return (
    <>
      <svg
        style={{
          position: "absolute",
          right: "5",
        }}
        xmlns="http://www.w3.org/2000/svg"
        width="6"
        height="4"
        viewBox="0 0 6 4"
        fill="none"
      >
        <path
          d="M3.1375 3.13957L5.6375 0.63957C5.7875 0.48957 5.7875 0.25957 5.6375 0.10957C5.4875 -0.0404297 5.2575 -0.0404297 5.1075 0.10957L2.8775 2.33957L0.6375 0.10957C0.4875 -0.0404299 0.2575 -0.0404299 0.1075 0.10957C0.0374999 0.17957 -0.00250007 0.27957 -0.00250007 0.37957C-0.00250008 0.47957 0.0374999 0.56957 0.1075 0.64957L2.6075 3.14957C2.7575 3.29957 2.9875 3.29957 3.1375 3.14957L3.1375 3.13957Z"
          fill="black"
        />
      </svg>
    </>
  );
};

export const SearchIconSVG = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="10"
        height="11"
        viewBox="0 0 10 11"
        fill="none"
        style={{
          position: "absolute",
          left: "10",
        }}
      >
        <g clipPath="url(#clip0_39_3185)">
          <path
            d="M9.89 9.44L7.39 6.94C8.02 6.2 8.42 5.26 8.42 4.21C8.42 1.89 6.53 0 4.21 0C1.89 0 0 1.89 0 4.21C0 6.53 1.89 8.42 4.21 8.42C5.22 8.42 6.13 8.05 6.85 7.46L9.36 9.97C9.43 10.04 9.53 10.08 9.63 10.08C9.73 10.08 9.82 10.04 9.9 9.97C10.05 9.82 10.05 9.59 9.9 9.44H9.89ZM0.75 4.21C0.75 2.3 2.3 0.75 4.21 0.75C6.12 0.75 7.67 2.3 7.67 4.21C7.67 6.12 6.12 7.67 4.21 7.67C2.3 7.67 0.75 6.12 0.75 4.21Z"
            fill="black"
          />
        </g>
        <defs>
          <clipPath id="clip0_39_3185">
            <rect width="10" height="10.08" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};

export const AddAttachmentSVG = () => {
  // const [ onHover, setonHover] = useState(false);

  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
        cursor={"pointer"}
        style={{
          marginTop: "2.75px",
        }}
         className="circle_hover"
      >
        <circle cx="6" cy="6" r="6" fill="#f3f3f3" 
        className="circle_hover"
        />
        <path
          d="M6.83126 5.21952H9.99609V6.79447H6.83126V9.99805H5.16093V6.79447H1.99609V5.21952H5.16093V1.99805H6.83126V5.21952Z"
          fill="white"
        />
      </svg>
    </>
  );
};

export const BacktickSVG = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="13"
        viewBox="0 0 12 13"
        fill="none"
      >
        <rect y="0.617188" width="12" height="12" rx="6" fill="#06A5FF" />
        <text
          x="50%"
          y="50%"
          dominantBaseline="middle"
          textAnchor="middle"
          style={{
            fill: "#fff",
            textAlign: "center",
            fontFamily: "Helvetica Now Display",
            fontSize: "12px",
            transform: "translateY(1px)",
          }}
        >
          {"~"}
        </text>
      </svg>
    </>
  );
};

export const AttachmentModalCrossIcon = () => {
  return (
    <>
      <svg
        className="attachmentModalCrossIcon"
        style={{
          cursor: "pointer",
        }}
        xmlns="http://www.w3.org/2000/svg"
        width="10"
        height="10"
        viewBox="0 0 10 10"
        fill="none"
      >
        <path
          d="M10 5C10 2.24 7.76 -9.79135e-08 5 -2.18557e-07C2.24 -3.392e-07 -9.79135e-08 2.24 -2.18557e-07 5C-3.392e-07 7.76 2.24 10 5 10C7.76 10 10 7.76 10 5ZM0.75 5C0.75 2.66 2.66 0.75 5 0.75C7.34 0.75 9.25 2.66 9.25 5C9.25 7.34 7.34 9.25 5 9.25C2.66 9.25 0.75 7.34 0.75 5Z"
          fill="black"
        />
        <path
          d="M3.14468 7.391L4.9973 5.53838L6.84992 7.391C6.99841 7.53949 7.23883 7.53949 7.38732 7.391C7.53582 7.24251 7.53582 7.00209 7.38732 6.8536L5.5347 5.00098L7.38732 3.14836C7.53582 2.99986 7.53582 2.75945 7.38732 2.61096C7.23883 2.46246 6.99841 2.46246 6.84992 2.61096L4.9973 4.46358L3.14468 2.61096C2.99619 2.46246 2.75577 2.46246 2.60728 2.61096C2.45879 2.75945 2.45879 2.99986 2.60728 3.14836L4.4599 5.00098L2.60728 6.8536C2.45879 7.00209 2.45879 7.2425 2.60728 7.391C2.75577 7.53949 2.99619 7.53949 3.14468 7.391Z"
          fill="black"
        />
      </svg>
    </>
  );
};

export const LinkSVG = ({ invert, width = 10, height = 6 }: any) => {
  return (
    <>
      <svg
        cursor={"pointer"}
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 10 6"
        fill="none"
        className="svg-icon"
      >
        <path
          d="M4 5.55482H2.55C1.14 5.55482 0 4.40482 0 3.00482C0 1.60482 1.14 0.444824 2.54 0.444824H3.99C4.2 0.444824 4.37 0.614824 4.37 0.824824C4.37 1.03482 4.2 1.20482 3.99 1.20482H2.54C1.55 1.20482 0.74 2.01482 0.74 3.00482C0.74 3.99482 1.55 4.80482 2.54 4.80482H3.99C4.2 4.80482 4.37 4.97482 4.37 5.18482C4.37 5.39482 4.2 5.56482 3.99 5.56482L4 5.55482Z"
          fill={invert ? "#FFF" : "black"}
        />
        <path
          d="M7.3925 5.55506H5.9425C5.7325 5.55506 5.5625 5.38506 5.5625 5.17506C5.5625 4.96506 5.7325 4.79506 5.9425 4.79506H7.3925C8.3825 4.79506 9.1925 3.98506 9.1925 2.99506C9.1925 2.00506 8.3825 1.19506 7.3925 1.19506H5.9425C5.7325 1.19506 5.5625 1.02506 5.5625 0.815059C5.5625 0.605059 5.7325 0.435059 5.9425 0.435059H7.3925C8.8025 0.435059 9.9425 1.58506 9.9425 2.98506C9.9425 4.38506 8.7925 5.53506 7.3925 5.53506V5.55506Z"
          fill={invert ? "#FFF" : "black"}
        />
        <path
          d="M7.74875 3.37475H2.34875C2.13875 3.37475 1.96875 3.20475 1.96875 2.99475C1.96875 2.78475 2.13875 2.61475 2.34875 2.61475H7.74875C7.95875 2.61475 8.12875 2.78475 8.12875 2.99475C8.12875 3.20475 7.95875 3.37475 7.74875 3.37475Z"
          fill={invert ? "#FFF" : "black"}
        />
      </svg>
    </>
  );
};

export const CrossTagSVG = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="6"
        height="6"
        viewBox="0 0 6 6"
        fill="none"
        style={{
          position: "absolute",
          top: "-1.25px",
          right: "-1.25px",
        }}
      >
        <circle cx="3" cy="3" r="3" fill="white" />
        <path
          d="M6 3C6 1.344 4.656 -5.87481e-08 3 -1.31134e-07C1.344 -2.0352e-07 -5.87481e-08 1.344 -1.31134e-07 3C-2.0352e-07 4.656 1.344 6 3 6C4.656 6 6 4.656 6 3ZM0.45 3C0.45 1.596 1.596 0.45 3 0.45C4.404 0.45 5.55 1.596 5.55 3C5.55 4.404 4.404 5.55 3 5.55C1.596 5.55 0.45 4.404 0.45 3Z"
          fill="black"
        />
        <path
          d="M1.8893 4.43401L3.00087 3.32244L4.11244 4.43401C4.20154 4.52311 4.34579 4.52311 4.43488 4.43401C4.52398 4.34492 4.52398 4.20067 4.43488 4.11157L3.32331 3L4.43488 1.88843C4.52398 1.79933 4.52398 1.65508 4.43488 1.56599C4.34579 1.47689 4.20154 1.47689 4.11244 1.56599L3.00087 2.67756L1.8893 1.56599C1.8002 1.47689 1.65595 1.47689 1.56686 1.56599C1.47776 1.65508 1.47776 1.79933 1.56686 1.88843L2.67843 3L1.56686 4.11157C1.47776 4.20067 1.47776 4.34492 1.56686 4.43401C1.65595 4.52311 1.8002 4.52311 1.8893 4.43401Z"
          fill="black"
        />
      </svg>
    </>
  );
};



export const DocumentSVG = () => {
  return(
    <>
    
    <svg xmlns="http://www.w3.org/2000/svg" width="27" height="33" viewBox="0 0 27 33" fill="none">
  <path d="M26.464 8.99082C26.3124 8.62714 26.0936 8.29714 25.804 8.01092L18.6787 0.882245C18.3891 0.59602 18.0591 0.373776 17.6988 0.225612C17.3351 0.077449 16.9479 0 16.5438 0H3.59969C2.64 0 1.73755 0.373776 1.05735 1.05398C0.373776 1.73755 0 2.64 0 3.59969V29.4003C0 30.36 0.373776 31.2624 1.05735 31.946C1.74092 32.6262 2.64337 33 3.59969 33H23.0899C24.0496 33 24.952 32.6262 25.6322 31.946C26.3124 31.2658 26.6896 30.36 26.6896 29.4037V10.1492C26.6896 9.74173 26.6121 9.35112 26.464 8.99082ZM16.9209 9.76867C17.5843 10.432 18.4632 10.7991 19.3993 10.7991H23.9957V29.4003C23.9957 29.6394 23.9014 29.865 23.7297 30.0367C23.558 30.2085 23.329 30.3028 23.0899 30.3028H3.59969C3.36061 30.3028 3.13163 30.2085 2.96327 30.0367C2.79153 29.865 2.69724 29.636 2.69724 29.3969V3.59969C2.69724 3.36061 2.79153 3.13163 2.96327 2.9599C3.135 2.78816 3.36061 2.69388 3.60306 2.69388H15.8939V7.29031C15.8939 8.22643 16.2609 9.10867 16.9243 9.76867H16.9209ZM18.5844 7.29031V4.5998L22.0898 8.1052H19.3993C19.1838 8.1052 18.9784 8.02102 18.8235 7.86612C18.6686 7.71122 18.5844 7.50582 18.5844 7.29031Z" fill="#C7C7C7"/>
  <path d="M21.2917 21.4668H5.40123C4.75133 21.4668 4.22266 21.9955 4.22266 22.6454C4.22266 23.2953 4.75133 23.8239 5.40123 23.8239H21.2917C21.9416 23.8239 22.4703 23.2953 22.4703 22.6454C22.4703 21.9955 21.9416 21.4668 21.2917 21.4668Z" fill="#C7C7C7"/>
  <path d="M21.2917 16.9651H5.40123C4.75133 16.9651 4.22266 17.4938 4.22266 18.1437C4.22266 18.7936 4.75133 19.3222 5.40123 19.3222H21.2917C21.9416 19.3222 22.4703 18.7936 22.4703 18.1437C22.4703 17.4938 21.9416 16.9651 21.2917 16.9651Z" fill="#C7C7C7"/>
  <path d="M4.22266 13.6378C4.22266 14.2877 4.75133 14.8164 5.40123 14.8164H21.2917C21.9416 14.8164 22.4703 14.2877 22.4703 13.6378C22.4703 12.9879 21.9416 12.4592 21.2917 12.4592H5.40123C4.75133 12.4592 4.22266 12.9879 4.22266 13.6378Z" fill="#C7C7C7"/>
</svg>
    </>
  )
}