// @ts-nocheck
import { useEffect, useRef, useState } from "react";
import {
  AddAttachmentSVG,
  BacktickSVG,
  CrossTagSVG,
  DatePickerDropdown,
  DocumentSVG,
  PloygonSVG,
  PolygonSVG,
  SearchIconSVG,
  ThreadCount,
} from "../Data/SVGs";
import "../Styles/ThreadsBox.css";
import convertToHTML from "draftjs-to-html";
import CreateComms from "../../../pages/Comms/Component/CreateCommsSection/Index";
import GreenColorSVG from "../../../pages/Comms/Data/GreenColorSVG";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../Controller/redux/Hooks";
import {
  AllDocSvg,
  DocxSvg,
  PdfDocSvg,
} from "../../../../Model/Assets/SVG/Common/AllDocsSvg";
import TrafficLights from "./TrafficLights";
import { toast } from "react-toastify";
import { getUserNotificationStats } from "../../../../Controller/redux/features/NotificationsFeature/NotificaionsSlice";
import { updateTrafficLightThunk } from "../../../../Controller/redux/features/CommonFeature/commonSlice";
import {
  getCommsDetailThunk,
  getCommsForUserThunk,
  getCommsFromUserTypeThunk,
  pinCommunicationThunk,
  updateCommsTagsThunk,
  updateMessageViewedStatusOnClickThunk,
} from "../../../../Controller/redux/features/CommsFeature/commsSlice";
import DateTimePicker from "../../CommonDateTimePicker/DateTimePicker";
import SuccessToastMessage from "../../SuccessToast/SuccessToastMessage";
import { formatDate, timeAgo } from "../../../../Model/utils/Helper/formatDate";
import AttachmentsModal from "./AttachmentsModal";
import { Modal, ModalOverlay, Progress, Spinner, Tag } from "@chakra-ui/react";
import { api } from "../../../../Controller/services/api";
import EXCELSvg from "../../../../Model/Data/EXCELSvg";
import GlobalDOCXSvg from "../../../../Model/Data/DOCXSvg";
import GlobalPDFSvg from "../../../../Model/Data/PDFSvg";
import { TagBox } from "../../Tags/CommonTagsComponent/TagBox";
import {
  CalendarAndCount,
  DocumentAndCount,
  TidleAndEventLabel,
} from "../../../pages/Daybook/Components/CollapsedDaybookItem";
import { CalendarSVG } from "../../../pages/Daybook/Data/CalendarSVG";
import MessageCount from "../../../pages/Daybook/Data/MessageCount";
import ReactDatePicker from "react-datepicker";
import {
  IntervalDrodpown,
  ServicesDropdown,
} from "../../CommonDateTimePicker/Components/TimePickerIntervals";
import TriangularTrafficLights from "../../TrianglularTrafficLights/TriangularTrafficLights";
import ThreadsNote from "./ThreadsNote";
import TriangularTrafficLightsSm from "../../TrianglularTrafficLights/TriangularTrafficLightsSm";
import { setUpdateDocumentDataToStore } from "../../../../Controller/redux/features/CommonFeature/documentSlice";
import { setSelectedInterval } from "../../../../Controller/redux/features/CommonFeature/documentSlice";
import { GreenCircleSvg } from "../../../pages/People/ClientPage/Components/UniqueClientScreen/Components/ClinicalTab/Data/SVGs";
import { DateTimePickerWithLabel } from "./DateTimePickerWithLabel";
import { ProgressCompletedSection } from "./ProgressCompletedSection";
import { SearchableItemsContainer } from "./SearchableItemsContainer";
import { TriangleSection, TriangleSectionSm } from "./TriangleCircleSection";
import { AttachmentsContainer } from "./AttachmentsContainer";
import { setopenClientAssessmentForm } from "../../../../Controller/redux/features/ClientFeature/clientsSlice";

export const ThreadsBox = ({
  title,
  summary,
  count,
  color,
  completionStatus,
  setSelectedTags,
  blockPointerEvents,
  setuploadedFiles = () => {},
  setopenForm = () => {},
  triggerGetDocumentsAPICall,
  receiveArraysFromChild,
  changeTags,
  selectedComm,
  taggedPeopleInputValue,
  taggedPeopleDropdownOptions,
  onChangeTaggedPeople,
  showingInComms,
  assetDetails,
  localStateTrafficLight,
  commsDetail = [],
  taggedPeopleOnOptionSelect,
  taggedPeople,
  keyPeople,
  attachments,
  currentElem,
  selectedItem,
  clientId,
  dynamicClientId,
  dynamicProspectId,
  isSubTaskOpen,
  linkUUID,
  commType,
  setIsSubTaskOpen,
  isNotComms,
  isDocument,
  incomingArrayTabs,
  selectedDocument,
  showPrimaryReviewPicker,
  fetchCommDetailsForDaybook,
  setShowPrimaryReviewPicker,
  showOngoingReviewPicker,
  setShowOngoingReviewPicker,
  showHardCopyCompletedPicker,
  handleStartDateChange,
  setShowHardCopyCompletedPicker,
  primaryReview,
  hardCopyDate,
  handleHardCopyDateChange,
  primaryApprovers,
  ongoingApprovers,
  handleSaveClick = () => {},
  handleTitleChange,
  handleSummaryChange,
  setSelectedMessage,
  setIsThereAnyMessageOnEditor,
  providerId,
  openCustomForm,
  showLastUpdateAndSave,
  selectedElement,
  keyPeopleOnOptionSelect,
  taggedPeopleOnOptionSelectDaybook,
  assignedTo,
  assignedToPeopleOnOptionSelect,
  taggedPeopleDaybook,
  showDateTimePickerDaybook,
  setshowDateTimePickerDaybook,
  setStartDate,
  handleStartDateDaybook,
  startDateDaybook,
  setStartDateDaybook,
  setEndDateDaybook,
  setStartTime = () => {},
  setEndTime = () => {},
  isDocumentCreateState,
  startTime,
  endTime,
  selectedCurrentElement,
  serviceTypes,
  handleServiceTypeChange,
  setServiceType,
  selectedServiceType,
  getDaybookCall = () => {},
  onChangeTrafficLightFromProps,
  sendComms,
  handleStartTimeChange,
  handleEndTimeChange,
  endDateDaybook,
  isDaybook,
  handleCloseModal,
  required_key,
  isCommsModal,
  isInUserType,
  hideSummary,
  hideCommsSendButton,
  clearMessage,
  setState = () => {},
  handleDelete,
  isCreateState,
  setFilesWhenClickedFromCommsBox,
  handleIntervalChange,
  selectedFolderIndex,
  setlocalStateCommsDocuments = () => {},
  isCF,
  underDocumentTab,
  isThereAnyChanges,
  documentTagSelect,
  documentTagUnselect,
  loading,
}: any) => {
  const dispatch = useAppDispatch();
  const dropdownRef = useRef();
  const { getCommDetailFull } = useAppSelector((state) => state?.comms);
  const selectedServiceTypeDetails = useAppSelector(
    (state) => state?.daybook?.selectedServiceType
  );
  const { dynamicColor } = useAppSelector((state) => state?.common);
  const [selectedTab, setSelectedTab] = useState("Reply");
  const [showTrafficlight, setShowTrafficLight] = useState(-1);
  const [selectedMessage, setselectedMessage] = useState<any>(null);
  const [selectedMessageindex, setselectedMessageIndex] = useState<any>(null);
  const [pinnedMessage, setPinnedMessage] = useState<any>(false);
  const [htmlContentPinned, sethtmlContentPinned] = useState<any>(null);
  const [notComms, setnotComms] = useState(false);
  const [showAttachmentModal, setShowAttachmentModal] = useState(false);
  const [
    showAttachmentModalOpenedFromComms,
    setShowAttachmentModalOpenedFromComms,
  ] = useState(false);
  const [showServiceDropdown, setShowServiceDropdown] = useState(false);
  const [currentInterval, setCurrentInterval] = useState<string>(
    selectedDocument?.period_picker || "None"
  );

  useEffect(() => {
    if(selectedDocument){
        setCurrentInterval(selectedDocument?.period_picker)
    }   
  },[selectedDocument])

  
  const handleGetFile = async (name: string | any) => {
    try {
      const response = await api.get(`files/get_file?file=${name}`);
      const fileUrl = response?.data?.data;
      window.open(fileUrl, "_blank");
    } catch (error) {
      console.error("Error fetching file:", error);
    }
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowServiceDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // @ts-ignore
  let loggedInUser = JSON.parse(localStorage.getItem("user"));

  // DateTimePicker
  const [dateTime, setDateTime] = useState({
    startDate: new Date(),
    startTime: "",
    endDate: "",
    endTime: "",
  });

  useEffect(() => {
    if (isNotComms) {
      setnotComms(true);
    }
  }, [isNotComms]);

  useEffect(() => {
    if (getCommDetailFull) {
      const pinnedMessage = getCommDetailFull?.data?.data?.find(
        (elem) => elem?.is_pinned == 1
      );
      setPinnedMessage(pinnedMessage);
    }
  }, [getCommDetailFull]);

  useEffect(() => {
    if (selectedCurrentElement?.communications) {
      const pinnedMessage = selectedCurrentElement?.communications?.find(
        (elem) => elem?.is_pinned == 1
      );
      setPinnedMessage(pinnedMessage);
    }
  }, [selectedCurrentElement]);

  useEffect(() => {
    if (pinnedMessage) {
      try {
        let parsedRes;
        parsedRes = JSON?.parse(pinnedMessage?.message);

        const htmlContentPinned = convertToHTML(parsedRes);
        sethtmlContentPinned(htmlContentPinned);
      } catch (err) {
        console.log("Error");
      }
    }
  }, [pinnedMessage]);

  let seenMessage = localStorage.getItem("seenMessage");
  useEffect(() => {
    if (!seenMessage) {
      setselectedMessage(null);
      setselectedMessageIndex(null);
      if (!selectedMessageindex && commsDetail?.length >= 1) {
        setselectedMessageIndex(0);
        setselectedMessage(commsDetail[0]);
      }
    }
  }, [commsDetail, seenMessage]);

  const handlePin = (attr: string) => {
    if (attr == "Pin") {
      dispatch(pinCommunicationThunk({ id: selectedMessage?.id })).then(
        (res) => {
          getDaybookCall();

          setselectedMessage(null);
          setselectedMessageIndex(-1);
          //  refresh
          if (fetchCommDetailsForDaybook) {
            dispatch(
              getCommsDetailThunk({
                clientId: linkUUID,
                type: commType,
                userId: clientId,
                page: 1,
              })
            );
          }
          dispatch(
            getCommsDetailThunk({
              clientId: currentElem?.link_uid,
              type: currentElem?.comm_type,
              userId: clientId,
              page: 1,
            })
          );
        }
      );
    } else {
      dispatch(
        pinCommunicationThunk({ id: pinnedMessage?.id, unpin: "true" })
      ).then((res) => {
        getDaybookCall();
        if (fetchCommDetailsForDaybook) {
          dispatch(
            getCommsDetailThunk({
              clientId: linkUUID,
              type: commType,
              userId: clientId,
              page: 1,
            })
          );
        }
        dispatch(
          getCommsDetailThunk({
            clientId: currentElem?.link_uid,
            type: currentElem?.comm_type,
            userId: clientId,
            page: 1,
          })
        );
      });

      setPinnedMessage(null);
      setselectedMessage(null);
      setselectedMessageIndex(0);
      setselectedMessage(commsDetail[0]);
    }
    if(underDocumentTab){
      toast.info(`Please save the document and reopen to view latest changes!`);
    }
  };

  const triggerGetCommsAPICall = () => {
    if (selectedFolderIndex === 0) {
      // alert(123123)
      dispatch(
        getCommsForUserThunk({
          clientId,
          page: 1,
          endpoint: "get_comms",
          sortType: "",
          search: "",
          unread: "",
        })
      );
    } else if (selectedFolderIndex === 1) {
      dispatch(
        getCommsForUserThunk({
          clientId,
          page: 1,
          endpoint: "get_comms_of_sent_items",
          sortType: "",
          search: "",
          unread: "",
        })
      );
    } else if (selectedFolderIndex === 2) {
      dispatch(
        getCommsFromUserTypeThunk({
          userType: dynamicClientId,
          search: "",
          sortType: "",
        })
      );
    } else if (selectedFolderIndex === 3) {
      dispatch(
        getCommsFromUserTypeThunk({
          userType: dynamicProspectId,
          search: "",
          sortType: "",
        })
      );
    }
  };
  // console.log('currentElem', currentElem);
  const onChangeTrafficLight = async (elem: any, data: any) => {
    setShowTrafficLight(-1);

    let link_uid = currentElem?.link_uid || currentElem?.id;
    if(underDocumentTab){
      link_uid = currentElem?.document_uid
    }
    const comm_type =
      currentElem?.comm_type || currentElem?.communications?.[0]?.comm_type;
    const traffic_light =
      data === "#ef3a34"
        ? 0
        : data === "#fbbf14"
        ? 1
        : data === "#2ad400"
        ? 2
        : 1;


    if (link_uid) {
      try {
        const res = await dispatch(
          updateTrafficLightThunk({
            link_uid,
            comm_type,
            communication_id: elem?.id,
            traffic_light,
          })
        );

        if (res?.payload?.status === "success") {
          toast.success(`Traffic Light updated successfully!`, {
            progressStyle: { backgroundColor: dynamicColor },
            icon: <SuccessToastMessage dynamicColor={dynamicColor} />,
          });
          if(underDocumentTab){
            toast.info(`Please save the document and reopen to view latest changes!`);
          }
        } else {
          console.log("Traffic Light update failed, re-fetching details.");
        }

        dispatch(getUserNotificationStats());
      } catch (error) {
        console.error("Error in updateTrafficLightThunk:", error);
      }

      dispatch(
        getCommsDetailThunk({
          clientId: link_uid,
          type: comm_type,
          userId: clientId,
          page: 1,
        })
      );

      triggerGetCommsAPICall();
      getDaybookCall();
    } else {
      console.error("Link UID is missing or invalid.");
    }
  };

  const replyComponent = () => {
    return (
      <>
        <div className="pinned_message_section mt_21">
          <div
            className={`
            
            
             ${
               loggedInUser?.data?.id == pinnedMessage?.sent_user_id
                 ? "reply_thread"
                 : "reply_thread_even"
             } `}
          >
            <div className="top_section_reply_thread">
              <div className="section_left">
                <BacktickSVG />
                <span className="reply_thread_label">
                  {loggedInUser?.data?.id == pinnedMessage?.sent_user_id
                    ? "YOU"
                    : "REPLY"}
                </span>
                <span className="reply_thread_author">
                  {pinnedMessage?.username}
                </span>
              </div>
              <div className="section_right">
                <span className="section_right_text">
                  {pinnedMessage?.day} {pinnedMessage?.created_time}
                </span>
                <span className="section_right_files">
                  {pinnedMessage?.files?.slice(0, 10)?.map((item: any) => {
                    return (
                      <>
                        {item?.file_name?.includes("pdf") ||
                        item?.file?.name?.includes("pdf") ? (
                          <GlobalPDFSvg
                            size={14}
                            onClick={(
                              e: React.MouseEvent<HTMLDivElement, MouseEvent>
                            ) => {
                              // e.stopPropagation();
                              handleGetFile(item?.file_name || item?.key);
                            }}
                            elem={item}
                          />
                        ) : item?.file_name?.includes("docx") ||
                          item?.file?.name?.includes("docx") ? (
                          <GlobalDOCXSvg
                            size={14}
                            onClick={(
                              e: React.MouseEvent<HTMLDivElement, MouseEvent>
                            ) => {
                              // e.stopPropagation();
                              handleGetFile(item?.file_name || item?.key);
                            }}
                            elem={item}
                          />
                        ) : (
                          <>
                            <EXCELSvg
                              size={14}
                              onClick={(
                                e: React.MouseEvent<HTMLDivElement, MouseEvent>
                              ) => {
                                // e.stopPropagation();
                                handleGetFile(item?.file_name || item?.key);
                              }}
                              elem={item}
                            />
                          </>
                        )}
                      </>
                    );
                  })}
                  {pinnedMessage?.files?.length >= 10 && <span>...</span>}
                </span>
                <div className="traffic_light_trigger">
                  <GreenColorSVG
                    color={
                      pinnedMessage?.traffic_light === 0
                        ? "#ef3a34"
                        : pinnedMessage?.traffic_light === 1
                        ? "#fbbf14"
                        : "#2ad400"
                    }
                  />
                  {false && (
                    <div className="traffic_lights_container">
                      <TrafficLights
                        setShowLights={setShowTrafficLight}
                        isThreads={true}
                        handleTrafficColors={(data: any) => {
                          onChangeTrafficLight(selectedMessage, data);
                          setShowTrafficLight(-1);
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div
              className="text_section_reply_thread"
              id="dangerouslySetInnerHTML"
              dangerouslySetInnerHTML={{
                __html: htmlContentPinned,
              }}
            ></div>
          </div>
        </div>
      </>
    );
  };

  const conditionallyRenderIcon = () => {
    return (
      <>
        {isNotComms ? (
          <>
            <CalendarAndCount count={count} color={color} />
          </>
        ) : isDocument ? (
          <DocumentAndCount count={count} color={color} />
        ) : (
          <ThreadCount count={count} color={color} />
        )}
      </>
    );
  };

  return (
    <div
      className={`${
        isInUserType
          ? "threads_box_in_user_types_daybook"
          : assetDetails
          ? "threads_box_no_interaction"
          : showingInComms
          ? "daybook_modal_in_coomms"
          : isDaybook && "thread_wrapper_daybook"
      } thread_box_wrapper mt-6`}
    >
      {loading && <Spinner left={"50%"} top={"50%"} position={"absolute"} />}
      <div
        className={`${
          isCommsModal ? "thread_box_section_1_comms" : "thread_box_section_1"
        }`}
      >
        <div className="thread_box_section_1-1">
          {conditionallyRenderIcon()}
        </div>
        <div className="thread_box_section_1-2">
          <textarea
            onChange={handleTitleChange}
            className={`${
              blockPointerEvents ? "blockPointerEvents" : ""
            } title_segment`}
            value={isCF ? isCF : title || ""}
            placeholder="Title..."
          />

          {!isDocument && !hideSummary && (
            <>
              <textarea
                onChange={handleSummaryChange}
                className={`${
                  blockPointerEvents ? "blockPointerEvents" : ""
                } summary_segment`}
                placeholder="Summary..."
                value={summary || ""}
              />
            </>
          )}

          {!isDocument && (
            <>
              <div
                className={`${
                  blockPointerEvents ? "blockPointerEvents" : ""
                } inner_section_threads`}
              >
                {notComms && (
                  <>
                    {" "}
                    <div className="selectServiceType_picker_segment selected_typography">
                      <span className="tilde_event_label">
                        {" "}
                        <TidleAndEventLabel />
                        <DatePickerDropdown />
                      </span>
                    </div>
                  </>
                )}

                {notComms && (
                  <>
                    {" "}
                    <DateTimePickerWithLabel
                      setSelectedInterval={setSelectedInterval}
                      setShowDateTimePickerModal={setshowDateTimePickerDaybook}
                      showDateTimePickerModal={showDateTimePickerDaybook}
                      hasTimepicker={true}
                      handleChange={handleStartDateDaybook}
                      selectedDate={startDateDaybook}
                      selectedEndDate={endDateDaybook}
                      setStartDateDaybook={setStartDateDaybook}
                      setEndDateDaybook={setEndDateDaybook}
                      setStartTime={setStartTime}
                      setEndDateDaybook={setEndDateDaybook}
                      ends={true}
                      startTime={startTime}
                      endTime={endTime}
                      setEndTime={setEndTime}
                      handleEndTimeChange={handleEndTimeChange}
                      handleStartTimeChange={handleStartTimeChange}
                      handleEndTimeChange={handleEndTimeChange}
                      selectedCurrentElement={selectedCurrentElement}
                    />
                  </>
                )}
                {notComms && (
                  <>
                    {" "}
                    <div
                      className="selectServiceType_picker_segment selected_typography"
                      onClick={() =>
                        setShowServiceDropdown(!showServiceDropdown)
                      }
                    >
                      <span className="tilde_event_label">
                        {" "}
                        {selectedServiceTypeDetails
                          ? selectedServiceTypeDetails?.name
                          : selectedServiceType
                          ? selectedServiceType?.name
                          : "Service Type"}
                        <span>
                          <DatePickerDropdown />
                        </span>
                      </span>
                      {showServiceDropdown && (
                        <div ref={dropdownRef}>
                          <ServicesDropdown
                            services={serviceTypes}
                            onClick={setServiceType}
                            setShowServiceDropdown={setShowServiceDropdown}
                          />
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>
            </>
          )}
        </div>
        <div
          className={`${
            blockPointerEvents ? "blockPointerEvents" : ""
          } thread_box_section_1-3`}
        >
          {isCommsModal ? (
            // <CircleSection />
            <></>
          ) : (
            <TriangleSection
              code={"HC2131231231231"}
              localStateTrafficLight={localStateTrafficLight}
              isDocument={isDocument}
              required_key={required_key}
              selectedCurrentElement={selectedCurrentElement}
              onChangeTrafficLight={onChangeTrafficLightFromProps}
              completionStatus={completionStatus}
            />
          )}
        </div>
      </div>
      {isNotComms && !isDocument && (
        <>
          <div
            className={`${
              blockPointerEvents ? "blockPointerEvents" : ""
            } thread_box_section_2`}
          >
            <div className="grid-item">
              <SearchableItemsContainer
                label="Assigned To"
                selectedItems={assignedTo}
                type={"mention_users"}
                onChange={() => {}}
                arrayToFilterFrom={getCommDetailFull}
                onClick={assignedToPeopleOnOptionSelect}
                allowSearch={true}
              />
            </div>
            <div className="grid-item">
              <SearchableItemsContainer
                label="Assigned With"
                onChange={onChangeTaggedPeople}
                value={taggedPeopleInputValue}
                options={taggedPeopleDropdownOptions}
                onClick={taggedPeopleOnOptionSelectDaybook}
                selectedItems={taggedPeopleDaybook}
                arrayToFilterFrom={getCommDetailFull}
                type={"mention_users"}
                allowSearch={true}
              />
            </div>

            <div className="grid-item"></div>
          </div>
        </>
      )}

      {/* <ThreadsNote/> */}

      {isDocument && (
        <>
          <div
            className={`${
              blockPointerEvents ? "blockPointerEvents" : ""
            } thread_box_section_2 `}
          >
            <div className="grid_item">
              <DateTimePickerWithLabel
                dateTime={selectedDocument?.primaryReviewDateTime}
                showDateTimePickerModal={showPrimaryReviewPicker}
                setShowDateTimePickerModal={setShowPrimaryReviewPicker}
                label={"Primary Review"}
                isDocument={isDocument}
                handleChange={handleStartDateChange}
                selectedDate={primaryReview}
              />
            </div>
            <div className="grid_item">
              <DateTimePickerWithLabel
                dateTime={selectedDocument?.ongoingReviewDateTime}
                showDateTimePickerModal={showOngoingReviewPicker}
                setShowDateTimePickerModal={setShowOngoingReviewPicker}
                setDateTime={setDateTime}
                label={"Ongoing Review"}
                periodPicker={true}
                currentInterval={currentInterval}
                setCurrentInterval={setCurrentInterval}
                handleIntervalChange={handleIntervalChange}
              />
            </div>
            <div className="grid_item">
              <SearchableItemsContainer
                label="Primary Approver"
                selectedDocument={selectedDocument}
                selectedItems={primaryApprovers}
                type={"primaryApprovers"}
                onClick={receiveArraysFromChild}
                arrayToFilterFrom={selectedDocument?.primary_approvers}
                allowSearch={true}
              />
            </div>
            <div className="grid_item">
              <SearchableItemsContainer
                label="Ongoing Approver"
                selectedItems={ongoingApprovers}
                type={"ongoingApprovers"}
                onClick={receiveArraysFromChild}
                arrayToFilterFrom={selectedDocument?.ongoing_approvers}
                allowSearch={true}
              />
            </div>
            <div className="grid_item">
              <DateTimePickerWithLabel
                showDateTimePickerModal={showHardCopyCompletedPicker}
                setShowDateTimePickerModal={setShowHardCopyCompletedPicker}
                label={"Hard Copy Completed"}
                selectedDate={hardCopyDate}
                handleChange={handleHardCopyDateChange}
                hasTimepicker={true}
              />
            </div>
            <div className="grid_item">
              <ProgressCompletedSection
                progressValue={selectedDocument?.percentage_completed}
                label={"Progress Completed"}
              />
            </div>
          </div>
        </>
      )}

      {true && (
        <>
          <div className={`${false ? "blockPointerEvents" : ""} tab_section`}>
            <div className="tab_buttons">
              {incomingArrayTabs?.map((button: string) => {
                return (
                  <>
                    <button
                      className={`tab_section_button 
                  ${selectedTab === button ? "selected_tab" : ""}
                  
                  `}
                      onClick={() => {
                        if (button == "Go to Form >") {
                          openCustomForm();
                        }
                        setSelectedTab(button);
                      }}
                    >
                      {button}
                    </button>
                  </>
                );
              })}
            </div>

            <TagBox
            documentTagSelect={documentTagSelect}
            documentTagUnselect={documentTagUnselect}
              changeTags={changeTags}
              blockPointerEvents={blockPointerEvents}
              selectedItem={
                selectedComm?.tags ||
                selectedDocument?.tags ||
                selectedCurrentElement?.tags
              }
              triggerGetCommsAPICall={triggerGetCommsAPICall}
              currentElem={
                currentElem || selectedDocument || selectedCurrentElement
              }
              clientId={clientId}
            />
          </div>
        </>
      )}

      {/* PINNED MESSAGE */}
      {pinnedMessage ? <>{replyComponent()}</> : ""}

      <div className="thread_box_section_2">
        <div className="grid-item">
          <SearchableItemsContainer
            label="Key People"
            selectedItems={keyPeople}
            type={"mention_users"}
            onChange={() => {}}
            arrayToFilterFrom={getCommDetailFull}
            onClick={keyPeopleOnOptionSelect}
            allowSearch={false}
          />
        </div>
        <div className={`${false ? "blockPointerEvents" : ""} grid-item `}>
          <SearchableItemsContainer
            label="Tagged People"
            onChange={onChangeTaggedPeople}
            value={taggedPeopleInputValue}
            options={taggedPeopleDropdownOptions}
            onClick={taggedPeopleOnOptionSelect}
            selectedItems={taggedPeople}
            type={"tagged_people"}
            arrayToFilterFrom={getCommDetailFull}
            allowSearch={true}
          />
        </div>
        <div className="grid-item">
          <AttachmentsContainer
            setFilesWhenClickedFromCommsBox={setFilesWhenClickedFromCommsBox}
            isDocumentCreateState={isDocumentCreateState}
            attachments={attachments}
            currentElem={
              currentElem || selectedDocument || selectedCurrentElement
            }
            isDaybook={isDaybook && isCreateState}
            clientId={clientId}
            providerId={providerId}
            showAttachmentModalOpenedFromComms={
              showAttachmentModalOpenedFromComms
            }
            setShowAttachmentModal={setShowAttachmentModal}
            showAttachmentModal={showAttachmentModal}
            getDaybookCall={getDaybookCall}
            triggerGetCommsAPICall={triggerGetCommsAPICall}
            setState={setuploadedFiles}
          />
        </div>
        <div className="grid-item"></div>
      </div>

      <div className="pin_section">
        <button
          onClick={() => {
            handlePin(
              pinnedMessage?.id == selectedMessage?.id ? "Unpin" : "Pin"
            );
          }}
          className={`pin_button ${
            pinnedMessage !== undefined &&
            pinnedMessage?.id == selectedMessage?.id &&
            "pin_button_active"
          }`}
        >
          {pinnedMessage?.id == selectedMessage?.id && pinnedMessage
            ? "Unpin"
            : "Pin"}
        </button>
      </div>

      <div className="thread_box_section_3">
        {commsDetail?.map((elem: any, index: any) => {
          let dataObjectMessage = elem?.message;
          let parsedRes;
          try {
            parsedRes = JSON.parse(dataObjectMessage);
          } catch (error) {
            console.error("Error parsing JSON:", error);
            return null;
          }
          const htmlContent = convertToHTML(parsedRes);

          return (
            <>
              <div
              key={index}
                onClick={async () => {
                  if (!pinnedMessage) {
                    setselectedMessage(elem);
                    setselectedMessageIndex(index);
                  } else {
                    setselectedMessage(elem);
                    setselectedMessageIndex(index);
                  }
                  let res = await dispatch(
                    updateMessageViewedStatusOnClickThunk(elem?.id)
                  );
                  localStorage.setItem("seenMessage", true);
                  if (res?.payload?.status == "success") {
                    if (isDaybook) {
                      await dispatch(
                        getCommsDetailThunk({
                          clientId: currentElem?.id,
                          type: "DAYBOOK",
                          userId: clientId,
                          page: 1,
                        })
                      );
                    }
                    await dispatch(
                      getCommsDetailThunk({
                        clientId: currentElem?.link_uid,
                        type: currentElem?.comm_type,
                        userId: clientId,
                        page: 1,
                      })
                    );

                    triggerGetCommsAPICall();
                    getDaybookCall();
                  }
                }}
                className={`
                   ${
                     loggedInUser?.data?.id == elem?.sent_user_id
                       ? "reply_thread"
                       : "reply_thread_even"
                   } 
                  
              
              ${selectedMessage?.id == elem?.id ? "active_message" : ""}
              `}
              >
                <div className="top_section_reply_thread">
                  <div className="section_left">
                    <BacktickSVG />
                    <span className="reply_thread_label">
                      {loggedInUser?.data?.id == elem?.sent_user_id
                        ? "YOU"
                        : "REPLY"}
                    </span>
                    <span className="reply_thread_author">
                      {elem?.username}
                    </span>
                  </div>
                  <div className="section_right">
                    <span className="section_right_text">
                      {elem?.day} {elem?.created_time}
                    </span>
                    <span className="section_right_files">
                      {elem?.files?.slice(0, 10)?.map((item: any) => {
                        return (
                          <>
                            {item?.file_name?.includes("pdf") ||
                            item?.file?.name?.includes("pdf") ? (
                              <GlobalPDFSvg
                                size={14}
                                onClick={(
                                  e: React.MouseEvent<
                                    HTMLDivElement,
                                    MouseEvent
                                  >
                                ) => {
                                  e.stopPropagation();
                                  handleGetFile(item?.file_name || item?.key);
                                }}
                                elem={item}
                              />
                            ) : item?.file_name?.includes("docx") ||
                              item?.file?.name?.includes("docx") ? (
                              <GlobalDOCXSvg
                                size={14}
                                onClick={(
                                  e: React.MouseEvent<
                                    HTMLDivElement,
                                    MouseEvent
                                  >
                                ) => {
                                  e.stopPropagation();
                                  handleGetFile(item?.file_name || item?.key);
                                }}
                                elem={item}
                              />
                            ) : (
                              <>
                                <EXCELSvg
                                  size={14}
                                  onClick={(
                                    e: React.MouseEvent<
                                      HTMLDivElement,
                                      MouseEvent
                                    >
                                  ) => {
                                    e.stopPropagation();
                                    handleGetFile(item?.file_name || item?.key);
                                  }}
                                  elem={item}
                                />
                              </>
                            )}
                          </>
                        );
                      })}
                      {elem?.files?.length >= 10 && <span>...</span>}
                    </span>
                    <div
                      onClick={(e) =>
                        setShowTrafficLight((prev) => (prev == -1 ? index : -1))
                      }
                      className="traffic_light_trigger"
                    >
                      <GreenColorSVG
                        color={
                          elem?.traffic_light === 0
                            ? "#ef3a34"
                            : elem?.traffic_light === 1
                            ? "#fbbf14"
                            : "#2ad400"
                        }
                      />
                      {showTrafficlight === index && (
                        <div className="traffic_lights_container">
                          <TrafficLights
                            setShowLights={setShowTrafficLight}
                            isThreads={true}
                            handleTrafficColors={(data: any) => {
                              onChangeTrafficLight(elem, data);
                              setShowTrafficLight(-1);
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div
                  className="text_section_reply_thread"
                  id="dangerouslySetInnerHTML"
                  dangerouslySetInnerHTML={{
                    __html: htmlContent,
                  }}
                ></div>
              </div>
            </>
          );
        })}
        <CreateComms
        triggerGetCommsAPICall={triggerGetCommsAPICall}
        setlocalStateCommsDocuments={setlocalStateCommsDocuments}
          setFilesWhenClickedFromCommsBox={setFilesWhenClickedFromCommsBox}
          clearMessage={clearMessage}
          isDaybook={isDaybook}
          setShowAttachmentModalOpenedFromComms={
            setShowAttachmentModalOpenedFromComms
          }
          hideCommsSendButton={hideCommsSendButton}
          providerId={providerId}
          isDocument={isDocument}
          triggerGetDocumentsAPICall={triggerGetDocumentsAPICall}
          setSelectedMessage={setSelectedMessage}
          setIsThereAnyMessageOnEditor={setIsThereAnyMessageOnEditor}
          isCommsThreads={true}
          isSubTaskOpen={isSubTaskOpen}
          linkUUID={linkUUID}
          commType={commType}
          isFromReplyBox={true}
          clientId={clientId}
          isEmpty={commsDetail?.length === 0}
          isCol2={true}
          setIsSubTaskOpen={setIsSubTaskOpen}
          setshowAttachmentModal={setShowAttachmentModal}
          showAttachmentModal={showAttachmentModal}
          sendComms={sendComms}
          // isDatePickerThreadsOpen={showDateTimePickerModal}
        />
      </div>

      {showLastUpdateAndSave && (
        <>
          <div className="save_or_cancel">
            <div>
              Last updated {selectedElement?.day}{" "}
              {selectedElement?.updated_time || selectedElement?.created_time}{" "}
            </div>
            <div className="button_segment">
              {isDaybook && (
                <>
                  {!isCreateState && (
                    <>
                      <button className="cancel" onClick={() => handleDelete()}>
                        Delete
                      </button>
                    </>
                  )}
                </>
              )}
              <button
                className="cancel"
                onClick={() => {

                    handleCloseModal();
                    // setopenForm(false);

                }}
              >
                Cancel
              </button>
              <button
                className="save_button-threads"
                onClick={() => {
                  // setopenForm(false);
                  // handleCloseModal();
                  handleSaveClick();
                }}
              >
                Save
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
