import React, { useEffect, useRef, useState } from "react";
import Table from "../../../components/CommonTable/Table";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../Controller/redux/Hooks";
import {useNavigate, useParams } from "react-router-dom";
import {
  getProviderTeams,
  getProviderTeamsByFilterThunk,
  resetTeamsProviderData,
} from "../../../../Controller/redux/features/ProviderFeature/providerTeamsSlice";
import {
  getProviderTeamsAssociateThunk,
  getProviderTeamsEmailThunk,
  getProviderTeamsNameThunk,
  getProviderTeamsPhoneThunk,
  resetTeamsPage,
  setTeamsPage,
} from "../../../../Controller/redux/features/TeamsFeature/teamsSlice";

const ProviderTeams = () => {
  const tableRef = useRef();
  const teamsInnerRef = useRef();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  // @ts-ignore
  const dynamicTeamsId = parseInt(localStorage.getItem("dynamicTeamsId"));
  const dropdownRefTeams = useRef<any>();
  const svgParentRefTeams = useRef<any>();
  const [isDropdownOpenTeams, setIsDropdownOpenTeams] = useState(false);
  const handleClickRow = (e: any, id: any) => {
     navigate(
      `/providers/${window.btoa(providerId)}/teams/${window.btoa(id)}`
    );
  };
  const columns = [
    { header: "Name", accessor: "name" },
    { header: "Email", accessor: "email" },
    { header: "Phone", accessor: "phone" },
    { header: "Associated Business", accessor: "business" },
    { header: "Status", accessor: "status" },
  ];
  const { domainDetail } = useAppSelector((state) => state?.common);

  const teamsStatusDetailsArr = useAppSelector(
    (state) => state.common.statusDetailsData?.data
  );
  const id: any = useParams()?.id;
  const [providerId, setProviderId] = useState<any>("");

  useEffect(() => {
    if (id) {
      let realId = window?.atob(id);
      setProviderId(realId);
    }
  }, [id]);

  useEffect(() => {
    if (providerId && dynamicTeamsId) {
      // dispatch(
      //   getProviderTeams({
      //     dynamicProviderId: providerId,
      //     dynamicUserTypeId: dynamicTeamsId,
      //   })
      // );
      dispatch(
        getProviderTeamsNameThunk({
          dynamicProviderId: providerId,
          dynamicUserTypeId: dynamicTeamsId,
        })
      );
      dispatch(
        getProviderTeamsEmailThunk({
          dynamicProviderId: providerId,
          dynamicUserTypeId: dynamicTeamsId,
        })
      );
      dispatch(
        getProviderTeamsPhoneThunk({
          dynamicProviderId: providerId,
          dynamicUserTypeId: dynamicTeamsId,
        })
      );
      dispatch(
        getProviderTeamsAssociateThunk({
          dynamicProviderId: providerId,
          dynamicUserTypeId: dynamicTeamsId,
        })
      );
    }
  }, [providerId, dynamicTeamsId]);

  const { teamsRecords, loading, lastPage } = useAppSelector(
    (state) => state?.providerTeams
  );

  const [providerTeamsRecords, setProviderTeamsRecords] = useState([]);

  useEffect(() => {
    if (teamsRecords) {
      setProviderTeamsRecords(teamsRecords);
    }
  }, [teamsRecords]);

  useEffect(() => {
    const handleClickOutsideTeams = (event: any) => {
      if (
        dropdownRefTeams.current &&
        !dropdownRefTeams.current.contains(event.target) &&
        svgParentRefTeams.current &&
        !svgParentRefTeams.current.contains(event.target)
      ) {
        setIsDropdownOpenTeams(false);
      }
    };
    document.addEventListener("click", handleClickOutsideTeams);
    return () => {
      document.removeEventListener("click", handleClickOutsideTeams);
    };
  }, []);

  const { selectedFilterItemsTeams, sortByTeamsR, searchValTeams } =
    useAppSelector((state) => state?.teams);
  const { filterIconColor } = useAppSelector((state) => state?.common);
  const { TeamsPage, TeamsFilterItems } = useAppSelector(
    (state) => state?.teams
  );
  useEffect(() => {
    dispatch(resetTeamsProviderData());
    dispatch(resetTeamsPage());
  }, [
    TeamsFilterItems?.searchVal,
    TeamsFilterItems?.filterValue,
    TeamsFilterItems?.sortType,
    TeamsFilterItems?.status,
    dynamicTeamsId,
  ]);
  useEffect(() => {
    dispatch(
      getProviderTeamsByFilterThunk({
        dynamicProviderId: providerId,
        dynamicUserTypeId: dynamicTeamsId,
        filterColumn: TeamsFilterItems?.filterColumn
          ? TeamsFilterItems?.filterColumn
          : "",
        filterValue: TeamsFilterItems?.filterValue
          ? TeamsFilterItems?.filterValue
          : "",
        searchVal: TeamsFilterItems?.searchVal
          ? TeamsFilterItems?.searchVal
          : "",
        sortColumn: TeamsFilterItems?.sortColumn
          ? TeamsFilterItems?.sortColumn
          : "",
        sortType: TeamsFilterItems?.sortType ? TeamsFilterItems?.sortType : "",
        status: TeamsFilterItems.status ? TeamsFilterItems.status : "",
        TeamsPage,
      })
    );
  }, [
    TeamsPage,
    providerId,
    dynamicTeamsId,
    TeamsFilterItems?.searchVal,
    TeamsFilterItems?.filterValue,
    TeamsFilterItems?.sortType,
    TeamsFilterItems?.status,
  ]);
  const onScroll = () => {
    if (teamsInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = teamsInnerRef.current;

      if (
        scrollTop + clientHeight + +5 >= scrollHeight &&
        TeamsPage < lastPage
      ) {
        dispatch(setTeamsPage(TeamsPage));
      }
    }
  };
  return (
    <div className="provider_prospect_wrapper">
      <Table
        isLoading={loading}
        itemsArr={teamsStatusDetailsArr}
        tableRef={tableRef}
        tableBodyRef={teamsInnerRef}
        onScroll={onScroll}
        dynamicHeaderColor={domainDetail?.listingtbl_color}
        statusType="providerTeamsScreen"
        thData={columns}
        classNameTr="all-contacts-table-head"
        type="providerTeams"
        dropdownRef={dropdownRefTeams}
        isDropdownOpen={isDropdownOpenTeams}
        onClickSvg={() => setIsDropdownOpenTeams(true)}
        svgParentRef={svgParentRefTeams}
        allClients={providerTeamsRecords}
        // tableRef={}
        onClickRow={handleClickRow}
        classNameRow="all-contacts-table-head"
        fill={
          selectedFilterItemsTeams.length > 0 ||
          searchValTeams.length > 0 ||
          sortByTeamsR.length > 0
            ? filterIconColor
            : "#D8D3D3"
        }
      />
    </div>
  );
};

export default ProviderTeams;
