import "../Styles/NestedTable.css";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../Controller/redux/Hooks";
// import { nestedTableData } from "../Data/NestedTableData";
import { SessionsTableData } from "../../../pages/Meals/Reports/Data/DiningTableData";
import { useEffect, useRef, useState } from "react";
import convertToHTML from "draftjs-to-html";
import { api } from "../../../../Controller/services/api";
import { getReportsThunk } from "../../../../Controller/redux/features/MealsFeatures/ReportsFeature/reportsSlice";
import { formatDateForAPI } from "../../../../Model/utils/Helper/formatDate";

const KitchenReportMainTable = () => {
  const { domainDetail } = useAppSelector((state) => state?.common);
  const dynamicHeaderColor = domainDetail?.reportstbl_color;
  const { kitchenReportrecord } = useAppSelector((state) => state?.reports);

  const [usersKitchanRecords, setUsersKitchanRecords] = useState([]);

  useEffect(() => {
    if (kitchenReportrecord) {
      setUsersKitchanRecords(
        kitchenReportrecord?.user_meals?.length !== 0
          ? kitchenReportrecord?.user_meals
          : []
      );
    }
  }, [kitchenReportrecord]);

  const transformObject = (inputObject: any) => {
    const transformedObject = {
      first_name: inputObject.first_name,
      last_name: inputObject.last_name,
      type: Object.keys(inputObject)
        .map((category) => {
          if (Array.isArray(inputObject[`${category}`])) {
            return {
              name: category,
              records:
                inputObject[category].length > 0 ? inputObject[category] : [],
            };
          }
        })
        .filter((category: any) => category?.records.length > 0),
    };

    return transformedObject;
  };

  return (
    <div className="table-container">
      <table>
        <thead style={{ backgroundColor: dynamicHeaderColor }}>
          <tr>
            <th colSpan={4}>Tuesday</th>
          </tr>
          {/* <tr>
            <th colSpan={2}>Client Details</th>
            <th colSpan={3}>Breakfast</th>
            <th colSpan={5}>Lunch</th>
            <th colSpan={3}>Dinner</th>
          </tr> */}
          <tr>
            <th>Client Name</th>
            <th> Meal Type</th>
            <th>Meal Name</th>
            <th>Delivery</th>
            <th>Comment</th>
            <th>Type</th>
            <th>Size</th>
            {/* <th>Dessert</th>
            <th>Delivery</th>
            <th>Comment</th>
            <th>Main</th>
            <th>Delivery</th>
            <th>Comment</th> */}
          </tr>
        </thead>
        <tbody className="kitchen_report_table_body">
          {usersKitchanRecords && usersKitchanRecords?.length !== 0 ? (
            usersKitchanRecords?.map((rowData, index) => {
              let usersInfo = transformObject(rowData);
              return (
                <tr key={index} style={{ border: "none" }}>
                  {/* <td rowSpan={5}>{rowData?.clientName}</td>
              <td>{rowData?.note}</td>
              <td>{rowData?.breakfast.choice}</td>
              <td>{rowData?.breakfast.delivery}</td>
              <td>{rowData?.breakfast.comment}</td>
              <td>{rowData?.lunch.main}</td>
              <td>{rowData?.lunch.size}</td> */}
                  {/* <td>{rowData.lunch.dessert}</td>
              <td>{rowData.lunch.delivery}</td>
              <td>{rowData.lunch.comment}</td>
              <td>{rowData.dinner.main}</td>
              <td>{rowData.dinner.delivery}</td>
              <td>{rowData.dinner.comment}</td> */}
                  <td rowSpan={1}>
                    {usersInfo?.first_name} {usersInfo?.last_name}
                  </td>
                  <td
                    colSpan={6}
                    className="padding_none"
                    style={{ border: "none" }}
                  >
                    {usersInfo?.type?.map((elem: any) => (
                      <tr>
                        <td>{elem?.name}</td>
                        <td
                          colSpan={5}
                          // rowSpan={usersInfo?.type?.length}
                          className="padding_none"
                          style={{ border: "none" }}
                        >
                          {elem?.records.map((elem: any) => {
                            const dataObjectMessage = elem?.notes;
                            let parsedDataObject: any;
                            try {
                              parsedDataObject = JSON.parse(dataObjectMessage);
                            } catch (error) {
                              parsedDataObject = null;
                            }
                            const htmlContent = convertToHTML(parsedDataObject);
                            return (
                              <tr>
                                <td>{elem?.meal_name}</td>
                                <td>
                                  {elem?.has_delivery == 0 ? "No" : "Yes"}{" "}
                                </td>
                                <td
                                  dangerouslySetInnerHTML={{
                                    __html: htmlContent,
                                  }}
                                ></td>
                                <td>{elem?.type}</td>
                                <td>{elem?.meal_size} </td>
                              </tr>
                            );
                          })}
                        </td>
                      </tr>
                    ))}
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td
                colSpan={7}
                className="padding_none"
                // style={{ border: "none" }}
              >
                No Records Found
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

const ReportsTable = ({
  title,
  isDining,
  isDelivery,
  setTableRef,
  titleType,
  dataArray,
  elem,
  selectedDate,
  selectedMealType,
}: {
  title?: string | any;
  isDining?: boolean;
  isDelivery?: boolean;
  setTableRef?: any;
  titleType?: string | any;
  dataArray?: any;
  elem?: any;
  selectedDate?: any;
  selectedMealType?: any;
}) => {
  const { domainDetail } = useAppSelector((state) => state?.common);

  const dynamicHeaderColor = domainDetail?.reportstbl_color;
  const tableRef = useRef<HTMLElement | any>(null);

  useEffect(() => {
    if (tableRef.current) {
      setTableRef(tableRef.current);
    }
  }, [tableRef]);

  const [localDataArray, setLocalDataArray] = useState<any>([]);
  useEffect(() => {
    if (dataArray) {
      setLocalDataArray(dataArray);
    }
  }, [dataArray]);

  const dispatch = useAppDispatch();
  const handleChangeCheckbox = async (
    e: any,
    rowIndex: number,
    mealIndex: number
  ) => {
    const updatedDataArray = JSON.parse(JSON.stringify(localDataArray));

    updatedDataArray[rowIndex].meals[mealIndex].served_delivered_status = e
      .target.checked
      ? 1
      : 0;

    setLocalDataArray(updatedDataArray);
    // console.log(updatedDataArray[rowIndex].meals[mealIndex].menu_assignement_id,"CHECK___")
    try {
      const response = await api.post("/reports/update_assignment_status", {
        served_delivered_status: updatedDataArray[rowIndex].meals[mealIndex]
          .served_delivered_status
          ? 1
          : 0,
        menu_assignment_id:
          updatedDataArray[rowIndex].meals[mealIndex].menu_assignement_id,
      });

      if (response) {
      } else {
        console.error("API call failed");
      }
    } catch (error) {
      console.error("Error during API call", error);
    }

    dispatch(
      getReportsThunk({
        date: formatDateForAPI(selectedDate),
        mealType: selectedMealType,
      })
    );
  };

  // console.log(localDataArray,"localDatArray")

  return (
    <>
      <span>
        {elem} - {selectedMealType}
      </span>
      <div className="table-container">
        <table style={{ height: "auto", marginTop: "10px" }}>
          <thead style={{ backgroundColor: dynamicHeaderColor }}>
            <tr>
              <thead style={{ backgroundColor: dynamicHeaderColor }}>
                <tr>
                  {SessionsTableData.map((elem: Object | any) => (
                    <th colSpan={elem.col} key={elem.name}>
                      {elem.name}
                    </th>
                  ))}
                </tr>
              </thead>
            </tr>
          </thead>
          <tbody className="report_table_body">
            {localDataArray && localDataArray?.length !== 0 ? (
              localDataArray?.map((elem: any, rowIndex: number) => {
                return (
                  <tr key={rowIndex}>
                    <td rowSpan={1}>
                      {elem.first_name} {elem.last_name}
                    </td>
                    <td colSpan={5} className="padding_none">
                      {elem.meals.map((meal: any, mealIndex: number) => {
                        return (
                          <tr key={mealIndex}>
                            <td
                              style={{
                                borderBottom: "none",
                                borderTop: "none",
                              }}
                            >
                              {" "}
                              {meal.meal_name}
                            </td>
                            <td
                              style={{
                                borderBottom: "none",
                                borderTop: "none",
                              }}
                            >
                              {meal.meal_size}
                            </td>
                            <td
                              style={{
                                borderBottom: "none",
                                borderTop: "none",
                              }}
                            >
                              {meal.type}
                            </td>
                            <td
                              style={{
                                borderBottom: "none",
                                borderTop: "none",
                              }}
                            >
                              1
                            </td>
                            <td
                              style={{
                                borderBottom: "none",
                                borderTop: "none",
                              }}
                            >
                              <input
                                type="checkbox"
                                name=""
                                id=""
                                checked={
                                  localDataArray[rowIndex].meals[mealIndex]
                                    .served_delivered_status
                                }
                                onChange={(e) =>
                                  handleChangeCheckbox(e, rowIndex, mealIndex)
                                }
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={5} className="padding_none">
                  No Records Found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export const TotalReportsTable = ({ thead }: { thead?: Object[] }) => {
  const { domainDetail } = useAppSelector((state) => state?.common);
  const { reports } = useAppSelector((state) => state?.reports);
  const dynamicHeaderColor = domainDetail?.reportstbl_color;

  const { kitchenReportrecord } = useAppSelector((state) => state?.reports);

  const [totalRecordsArr, setTotalRecordsArr] = useState<any>([]);
console.log(kitchenReportrecord?.meal_stats,"totalRecordsArr")
  useEffect(() => {
    if (kitchenReportrecord?.meal_stats) {
      function transformObjectToArray(inputObject: any) {
        return Object.entries(inputObject)
          .map(([category, records]: any) => ({
            name: category,
            records: records,
          }))
          .filter((res) => res.records.length > 0);
      }
      const transformedArray = transformObjectToArray(
        kitchenReportrecord?.meal_stats
      );
      setTotalRecordsArr(transformedArray || []);
      // // console.log(transformedArray);
    }
  }, [kitchenReportrecord]);
console.log(totalRecordsArr,'ashdoasdh')
  return (
    <div className="table-container ">
      <table style={{ height: "auto", marginTop: "10px" }}>
        <thead style={{ backgroundColor: dynamicHeaderColor }}>
          <tr>
            <thead style={{ backgroundColor: dynamicHeaderColor }}>
              <tr
                style={{
                  borderBottom: "none",
                  borderTop: "none",
                }}
              >
                <th
                  style={{
                    borderBottom: "none",
                    borderTop: "none",
                  }}
                  colSpan={3}
                >
                  Meals
                </th>
         
                <th
                  style={{
                    borderBottom: "none",
                    borderTop: "none",
                  }}
                >
                  Total Number Extra Small
                </th>

                <th
                  style={{
                    borderBottom: "none",
                    borderTop: "none",
                  }}
                >
                  Total Number Small
                </th>
                <th
                  style={{
                    borderBottom: "none",
                    borderTop: "none",
                  }}
                >
                  {" "}
                  Total Number Medium
                </th>
                <th
                  style={{
                    borderBottom: "none",
                    borderTop: "none",
                  }}
                >
                  Total Number Large
                </th>

                <th
                  style={{
                    borderBottom: "none",
                    borderTop: "none",
                  }}
                >
                  Number for Delivery
                </th>
              </tr>
            </thead>
          </tr>
        </thead>
        <tbody className="total_report_table_body">
          {reports && totalRecordsArr?.length > 0 ? (
            totalRecordsArr?.map((elem: any) => {
              return (
                <>
                  <tr>
                    <td rowSpan={3}>
                      {/* {elem.first_name} {elem.last_name} */}
                      {elem.name}
                    </td>
                    <td colSpan={3} className="padding_none">
                      {elem?.records?.length !== 0 ? (
                        elem?.records?.map((elem: any) => {
                          return (
                            <tr
                              style={{
                                borderBottom: "none",
                                borderTop: "none",
                              }}
                            >
                              <td
                                style={{
                                  borderBottom: "none",
                                  borderTop: "none",
                                  borderLeft: "none",
                                }}
                              >
                                {" "}
                                {elem.meal_name}
                              </td>
                              <td
                                style={{
                                  borderBottom: "none",
                                  borderTop: "none",
                                }}
                              >
                                {elem.extra_small}
                              </td>
                              <td
                                style={{
                                  borderBottom: "none",
                                  borderTop: "none",
                                }}
                              >
                                {elem.small}
                              </td>
                              <td
                                style={{
                                  borderBottom: "none",
                                  borderTop: "none",
                                }}
                              >
                                {elem.medium}
                              </td>
                              <td
                                style={{
                                  borderBottom: "none",
                                  borderTop: "none",
                                }}
                              >
                                {elem.large}
                              </td>

                              <td
                                style={{
                                  borderBottom: "none",
                                  borderTop: "none",
                                }}
                              >
                                {elem.delivery}
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td>-</td>
                          <td>0</td>
                          <td>0</td>
                          <td>0</td>
                        </tr>
                      )}
                    </td>
                  </tr>
                </>
              );
            })
          ) : (
            <tr>
              <td
                colSpan={5}
                className="padding_none"
                // style={{ border: "none" }}
              >
                No Records Found
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export { ReportsTable };

const NestedTable = () => {
  return (
    <>
      <KitchenReportMainTable />
      <TotalReportsTable />
    </>
  );
};

export default NestedTable;
