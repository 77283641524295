import React from "react";
import "./Styles/UpperBox.css";

import { Fragment, useContext, useEffect, useRef, useState } from "react";
import { upperBoxArr } from "../../pages/Daybook/Data/UpperBoxArr";
// import {
//   useAppDispatch,
//   // useAppSelector,
// } from "../../../Controller/redux/Hooks";
import AddButtonWithIcons from "./Components/AddButtonWithIcons";
import ClientImg from "../../../Model/Assets/png/client.png";
// import QrCodeImg from "../../../Model/Assets/images/qr-code.png";
import CustomSlider from "../Slider/CustomSlider";
import LeftBox from "./Components/LeftBox";
import { Modal, ModalOverlay } from "@chakra-ui/react";
import AuditModal from "../../pages/Audit/AuditModal";
import VerticalModal from "../CommonModal/VerticalModal";
import CommonReportingWidget from "../ReportingWidget/ReportingWidget";
import { useParams } from "react-router-dom";
import { setOpenFinanceModelForm } from "../../../Controller/redux/features/FinanceFeature/FinanceSlice";
import ProfilePictureModal from "../ProfilePictureModal/ProfilePictureModal";
import CommonQuickMenu from "../CommonQuickMenu/CommonQuickMenu";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../Controller/redux/Hooks";

const UpperBox = ({
  type,
  name,
  currentContact,
  currentProspects,
  currentStaff,
  currentBusiness,
  // isModalFromURL,
  currentClient,
}: any) => {
  const { user } = useAppSelector((state) => state.auth);

  // const [showAddWidgetBox, setshowAddWidgetBox] = useState(false);
  const [mouseOverAddDoc, setmouseOverAddDoc] = useState(true);
  const [showGoalsModal, setshowGoalsModal] = useState(false);
  const [showProfile, setShowProfile] = useState<boolean>(false);
  const [stageTwoToGoalsModal, setstageTwoToGoalsModal] = useState(false);

  const verticalModalRef = useRef<any>(null);
  const clinicalGoalsModal = useRef<any>(null);
  // @ts-ignore
  let activeTabDoc = localStorage.getItem("activeTab") === "Documents";

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (
        clinicalGoalsModal.current &&
        !clinicalGoalsModal.current.contains(event.target)
      ) {
        setshowGoalsModal(false);

        localStorage.removeItem("flagHere");
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleOverlayClick = () => {
    setshowGoalsModal(false);

    localStorage.removeItem("flagHere");
  };

  const handleClickOutside = (e: any) => {
    if (
      verticalModalRef.current &&
      !verticalModalRef.current.contains(e.target)
    ) {
      setshowGoalsModal(false);
    }
  };

  useEffect(() => {
    if (showGoalsModal) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [showGoalsModal]);

  let data = localStorage.getItem("flagHere");
  // @ts-ignore
  let parsedData = JSON.parse(data);

  useEffect(() => {
    if (parsedData) {
      setshowGoalsModal(true);
    }
  }, [parsedData]);
  const [clientId, setclientId] = useState<any>("");

  const id: any = useParams()?.id;
  useEffect(() => {
    if (id) {
      let realId = window.atob(id);
      setclientId(realId);
    }
  }, [id]);

  const dispatch = useAppDispatch();

  // useEffect(() => {
  //   setcurrentClient(getCurrentClient?.data?.data[0]);
  // }, []);

  // const handleOpenDocumentFormProspects = () => {
  //   // dispatch(setopenFormByClickProspects());
  //   setstageTwoToGoalsModal(!stageTwoToGoalsModal);
  // };

  const [openModal, setopenModal] = useState(false);
  const [selectedWigdetItem, setselectedWidgetItem] = useState(null);

  // OPENS LARGE CLINICAL MODAL
  const handleOpenClinicalModal = (index: number, selectedWidget: any) => {
    // console.log("Hello");
    setopenModal(true);
    setselectedWidgetItem(selectedWidget);
  };

  const handleOpenFinanceFromClient = () => {
    dispatch(setOpenFinanceModelForm(true));
  };

  return (
    <Fragment>
      {/* HUB */}
      {type === "hub" && (
        <div className="upper-box-group upper-box-group-hub">
          <div className="left-box left-box-hub">
            <h3 className="left-box-title left-box-title-hub">{name}</h3>
          </div>
        </div>
      )}

      {/* MY DAYBOOK ACTIVE */}
      {type === "daybook" && (
        <div className="upper-box-group">
          <div className="left-box" style={{ width: "650px" }}>
            <div className="detail-box detail-box-clinical">
              <img
                src={ClientImg}
                alt="client-image"
                id="client-image"
                style={{ visibility: "hidden" }}
              />
              <div
                className="nameOverview"
                style={{ transform: "translateX(-25%)" }}
              >
                <h3 className="left-box-title">Day Book</h3>
                <h5
                  className="left-box-overview"
                  style={{ transform: "translateX(-65%)" }}
                >
                  All Tasks...
                </h5>
              </div>
            </div>
          </div>

          <div className="clinical-upper-right-section"></div>
          {upperBoxArr.map((element: any) => {
            return (
              <>
                <div
                  className="right-box"
                  style={{
                    background:
                      element.name === "Chirstmas Delivery"
                        ? "#1D2759"
                        : "#F3F3F3",
                  }}
                >
                  <div className="title-div-daybook">
                    <h5
                      className="right-box-upper-title"
                      style={{
                        width: "90px",
                        color:
                          element.name === "Chirstmas Delivery"
                            ? "white"
                            : "#1d2759",
                      }}
                    >
                      {element.name}
                    </h5>
                    <h5
                      className="right-box-upper-title"
                      style={{ transform: "translateY(-85%)" }}
                    >
                      {element.count}
                    </h5>
                  </div>

                  <div className="right-box-lower-box right-box-lower-box-clinical">
                    <div className="lower-box-daybook">
                      <img
                        src={ClientImg}
                        alt="client-image"
                        id="client-image"
                        style={{
                          width: "37px",
                          height: "37px",
                          position: "relative",
                          bottom: "3em",
                          left: "-0.3em",
                        }}
                      />
                      <span
                        style={{
                          color:
                            element.name === "Chirstmas Delivery"
                              ? "white"
                              : "#1d2759",
                          fontSize: "10px",
                          letterSpacing: "0.35px",
                          position: "absolute",
                          top: "-3em",
                          right: "0.7em",
                        }}
                      >
                        {element.time}
                      </span>
                    </div>
                    <span
                      className="right-box-lower-box-vector"
                      style={{
                        background: element.color,
                        position: "relative",
                        top: "-6.2em",
                        right: "-.6em",
                      }}
                    ></span>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      )}

      {/* CLIENTS ACTIVE */}
      {type === "clients" && (
        <div className="upper-box-group">
          <div className="left-box">
            <h3 className="left-box-title left-box-upper-title-documents">
              Clients
              <AddButtonWithIcons type="prospects" />
            </h3>
          </div>
          <CustomSlider />
        </div>
      )}

      {/* CLIENTS ACTIVE */}
      {type === "village" && (
        <div className="upper-box-group">
          <div className="left-box">
            <span className="upper_box_sales_title">V D </span>
            <h3 className="left-box-title left_box_upper_sales">
              Village
              <AddButtonWithIcons styleType="sales" type="prospects" />
            </h3>
          </div>
          <CustomSlider />
        </div>
      )}

      {/* CLIENTS ACTIVE */}
      {type === "sales" && (
        <div className="upper-box-group">
          <div className="left-box">
            <span className="upper_box_sales_title">S D </span>
            <h3 className="left-box-title left_box_upper_sales">
              Sales
              <AddButtonWithIcons styleType="sales" type="prospects" />
            </h3>
          </div>
          <CustomSlider />
        </div>
      )}

      {/* AUDIT ACTIVE */}
      {type === "audit" && (
        <div className="upper-box-group">
          <div className="left-box">
            <h3 className="left-box-title left-box-upper-title-documents">
              Audit
              <AddButtonWithIcons />
            </h3>
          </div>
          <CustomSlider />
        </div>
      )}

      {/* MEAL BUILDER ACTIVE */}
      {type === "MealBuilder" && (
        <div className="upper-box-group">
          <div className="left-box">
            <h3 className="left-box-title left-box-upper-title-documents">
              Meal Builder
              <AddButtonWithIcons type="mealBuilder" />
            </h3>
          </div>
          <CustomSlider />
        </div>
      )}

      {/* MENU BUILDER */}
      {type === "menubuilder" && (
        <div className="upper-box-group">
          <div className="left-box">
            <img
              src={ClientImg}
              alt="client-image"
              id="client-image"
              style={{ marginLeft: "20px" }}
            />
            <h3 className="left-box-title left-box-upper-title-documents">
              {user?.first_name || ""} {user?.last_name || ""}
              <AddButtonWithIcons type="menuBuilder" />
            </h3>
          </div>
          <CustomSlider />
        </div>
      )}

      {/* REPORTS ACTIVE */}
      {type === "reports" && (
        <div className="upper-box-group">
          <div className="left-box">
            <h3 className="left-box-title left-box-upper-title-documents">
              Reports
              <AddButtonWithIcons type="mealBuilder" />
            </h3>
          </div>
          <CustomSlider />
        </div>
      )}

      {/* COMMS GLOBAL  */}
      {type === "commsGlobal" && (
        <div className="upper-box-group">
          <div className="left-box">
            <h3 className="left-box-title left-box-upper-title-documents">
              <img
                src={ClientImg}
                alt="client-image"
                id="client-image"
                width={"78px"}
                height={"78px"}
              />
              Prospects
              <AddButtonWithIcons type="prospects" />
            </h3>
          </div>
          <CustomSlider />
        </div>
      )}

      {/* PROSPECTS ACTIVE */}
      {type === "prospects" && (
        <div className="upper-box-group">
          <div className="left-box">
            <h3 className="left-box-title left-box-upper-title-documents">
              Prospects
              <AddButtonWithIcons type="prospects" />
            </h3>
          </div>
          <CustomSlider />
        </div>
      )}

      {/* CONTACTS ACTIVE */}
      {type === "contacts" && (
        <div className="upper-box-group">
          <div className="left-box">
            <h3 className="left-box-title left-box-upper-title-documents">
              Contacts
              <AddButtonWithIcons type="prospects" />
            </h3>
          </div>
          <CustomSlider />
        </div>
      )}

      {/* BUSINESS */}
      {type === "business" && (
        <div className="upper-box-group">
          <div className="left-box">
            <h3 className="left-box-title left-box-upper-title-documents">
              Business
              <AddButtonWithIcons type="prospects" />
            </h3>
          </div>
          <CustomSlider />
        </div>
      )}
      {/* TEAMS */}
      {type === "teams" && (
        <div className="upper-box-group">
          <div className="left-box">
            <h3 className="left-box-title left-box-upper-title-documents">
              Teams
              <AddButtonWithIcons type="prospects" />
            </h3>
          </div>
          <CustomSlider />
        </div>
      )}

      {/* STAFF ACTIVE */}
      {type === "staff" && (
        <div className="upper-box-group">
          <div className="left-box">
            <h3 className="left-box-title left-box-upper-title-documents">
              Staff
              <AddButtonWithIcons type="prospects" />
            </h3>
          </div>

          <CustomSlider />
        </div>
      )}

      {/* DETAILS ACTIVE */}
      {type === "client" && (
        <div className="upper-box-group">
          <div
            style={{
              position: "relative",
            }}
          >
            <svg
              onMouseEnter={() => setmouseOverAddDoc(false)}
              onMouseLeave={() => setmouseOverAddDoc(true)}
              style={{
                width: "12px",
                height: "12px",
                position: "absolute",
                right: "0.35rem",
                top: "5rem",
                cursor: "pointer",
              }}
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill={!mouseOverAddDoc ? "#87CEFA" : "#F3F3F3"}
            >
              <circle
                cx="6"
                cy="6"
                r="6"
                fill={!mouseOverAddDoc ? "white" : "white"}
              />
              <path
                d="M6.83516 5.22148H10V6.79642H6.83516V10H5.16484V6.79642H2V5.22148H5.16484V2H6.83516V5.22148Z"
                fill={!mouseOverAddDoc ? "#87CEFA" : "#F3F3F3"}
              />
            </svg>
            <LeftBox type="details" />
          </div>
          <CustomSlider />
        </div>
      )}

      {/* ASSOCIATIONS ACTIVE */}
      {type === "association" && (
        <div className="upper-box-group">
          <div
            style={{
              position: "relative",
            }}
          >
            <svg
              onMouseEnter={() => setmouseOverAddDoc(false)}
              onMouseLeave={() => setmouseOverAddDoc(true)}
              style={{
                width: "12px",
                height: "12px",
                position: "absolute",
                right: ".35rem",
                top: "5rem",
                cursor: "pointer",
                zIndex: 1,
              }}
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill={!mouseOverAddDoc ? "#87CEFA" : "#F3F3F3"}
            >
              <circle
                cx="6"
                cy="6"
                r="6"
                fill={!mouseOverAddDoc ? "white" : "white"}
              />
              <path
                d="M6.83516 5.22148H10V6.79642H6.83516V10H5.16484V6.79642H2V5.22148H5.16484V2H6.83516V5.22148Z"
                fill={!mouseOverAddDoc ? "#87CEFA" : "#F3F3F3"}
              />
            </svg>
            <LeftBox type="association" />
          </div>

          <CustomSlider />
        </div>
      )}

      {/* PROSPECTS SCREEN ACTIVE */}
      {type === "prospectsScreen" && (
        <>
          <div className="upper-box-group-documents">
            <div
              style={{
                position: "relative",
              }}
            >
              <svg
                onMouseEnter={() => setmouseOverAddDoc(false)}
                onMouseLeave={() => setmouseOverAddDoc(true)}
                onClick={() =>
                  activeTabDoc && setstageTwoToGoalsModal(!stageTwoToGoalsModal)
                }
                style={{
                  width: "12px",
                  height: "12px",
                  position: "absolute",
                  right: ".35rem",
                  top: "5rem",
                  cursor: "pointer",
                  zIndex: 1,
                }}
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill={!mouseOverAddDoc ? "#87CEFA" : "#F3F3F3"}
              >
                <circle
                  cx="6"
                  cy="6"
                  r="6"
                  fill={!mouseOverAddDoc ? "white" : "white"}
                />
                <path
                  d="M6.83516 5.22148H10V6.79642H6.83516V10H5.16484V6.79642H2V5.22148H5.16484V2H6.83516V5.22148Z"
                  fill={!mouseOverAddDoc ? "#87CEFA" : "#F3F3F3"}
                />
              </svg>
              <LeftBox
                onClick={() => setShowProfile(true)}
                type="prospectsScreen"
                currentProspects={currentProspects}
              />
              {showProfile && (
                <ProfilePictureModal
                  showProfile={showProfile}
                  className="profile_picture_external"
                  setShowProfile={setShowProfile}
                  currentClient={currentProspects}
                />
              )}
              {activeTabDoc && stageTwoToGoalsModal && (
                <CommonQuickMenu
                  setshowGoalsModal={setshowGoalsModal}
                  showGoalsModal={showGoalsModal}
                  setstageTwoToGoalsModal={setstageTwoToGoalsModal}
                  type="prospects"
                />
              )}
            </div>
            <CustomSlider />
          </div>
        </>
      )}

      {/* CONTACTS SCREEN ACTIVE */}
      {type === "contactsScreen" && (
        <>
          <div className="upper-box-group-documents">
            <div
              style={{
                position: "relative",
              }}
            >
              <svg
                onMouseEnter={() => setmouseOverAddDoc(false)}
                onMouseLeave={() => setmouseOverAddDoc(true)}
                onClick={() =>
                  activeTabDoc && setstageTwoToGoalsModal(!stageTwoToGoalsModal)
                }
                style={{
                  width: "12px",
                  height: "12px",
                  position: "absolute",
                  right: ".35rem",
                  top: "5rem",
                  cursor: "pointer",
                  zIndex: 1,
                }}
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill={!mouseOverAddDoc ? "#87CEFA" : "#F3F3F3"}
              >
                <circle
                  cx="6"
                  cy="6"
                  r="6"
                  fill={!mouseOverAddDoc ? "white" : "white"}
                />
                <path
                  d="M6.83516 5.22148H10V6.79642H6.83516V10H5.16484V6.79642H2V5.22148H5.16484V2H6.83516V5.22148Z"
                  fill={!mouseOverAddDoc ? "#87CEFA" : "#F3F3F3"}
                />
              </svg>
              <LeftBox
                onClick={() => setShowProfile(true)}
                type="contactsScreen"
                currentContact={currentContact}
              />
              {showProfile && (
                <ProfilePictureModal
                  showProfile={showProfile}
                  className="profile_picture_external"
                  setShowProfile={setShowProfile}
                  currentClient={currentContact}
                />
              )}
              {activeTabDoc && stageTwoToGoalsModal && (
                <CommonQuickMenu
                  setshowGoalsModal={setshowGoalsModal}
                  showGoalsModal={showGoalsModal}
                  setstageTwoToGoalsModal={setstageTwoToGoalsModal}
                  type="contacts"
                />
              )}
            </div>
            <CustomSlider />
          </div>
        </>
      )}

      {/* CLIENTS SCREEN ACTIVE */}
      {type === "clientsScreen" && (
        <>
          <div className="upper-box-group-documents">
            <div
              style={{
                position: "relative",
              }}
            >
              <svg
                onMouseEnter={() => setmouseOverAddDoc(false)}
                onMouseLeave={() => setmouseOverAddDoc(true)}
                // onClick={handleOpenDocumentFormProspects}
                style={{
                  width: "12px",
                  height: "12px",
                  position: "absolute",
                  right: ".35rem",
                  top: "5rem",
                  cursor: "pointer",
                  zIndex: 1,
                }}
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill={!mouseOverAddDoc ? "#87CEFA" : "#F3F3F3"}
              >
                <circle
                  cx="6"
                  cy="6"
                  r="6"
                  fill={!mouseOverAddDoc ? "white" : "white"}
                />
                <path
                  d="M6.83516 5.22148H10V6.79642H6.83516V10H5.16484V6.79642H2V5.22148H5.16484V2H6.83516V5.22148Z"
                  fill={!mouseOverAddDoc ? "#87CEFA" : "#F3F3F3"}
                />
              </svg>

              <LeftBox
                type="prospectsScreen"
                currentClient={currentClient}
                onClick={() => setShowProfile(true)}
              />

              {showProfile && (
                <ProfilePictureModal
                  showProfile={showProfile}
                  className="profile_picture_external"
                  setShowProfile={setShowProfile}
                  currentClient={currentClient}
                />
              )}
            </div>
            <CustomSlider />
          </div>
        </>
      )}

      {/* CLIENTS SCREEN FIANANCE TAB ACTIVE */}
      {type === "finance" && (
        <>
          <div className="upper-box-group-documents">
            <div
              style={{
                position: "relative",
              }}
            >
              <svg
                onMouseEnter={() => setmouseOverAddDoc(false)}
                onMouseLeave={() => setmouseOverAddDoc(true)}
                onClick={handleOpenFinanceFromClient}
                style={{
                  width: "12px",
                  height: "12px",
                  position: "absolute",
                  right: ".35rem",
                  top: "5rem",
                  cursor: "pointer",
                  zIndex: 1,
                }}
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill={!mouseOverAddDoc ? "#87CEFA" : "#F3F3F3"}
              >
                <circle
                  cx="6"
                  cy="6"
                  r="6"
                  fill={!mouseOverAddDoc ? "white" : "white"}
                />
                <path
                  d="M6.83516 5.22148H10V6.79642H6.83516V10H5.16484V6.79642H2V5.22148H5.16484V2H6.83516V5.22148Z"
                  fill={!mouseOverAddDoc ? "#87CEFA" : "#F3F3F3"}
                />
              </svg>
              <LeftBox
                type="prospectsScreen"
                onClick={() => setShowProfile(true)}
                currentClient={currentClient}
              />
              {showProfile && (
                <ProfilePictureModal
                  showProfile={showProfile}
                  className="profile_picture_external"
                  setShowProfile={setShowProfile}
                  currentClient={currentClient}
                />
              )}
            </div>
            <CustomSlider />
          </div>
        </>
      )}

      {/* Business SCREEN ACTIVE */}
      {type === "businessScreen" && (
        <>
          <div className="upper-box-group-documents">
            <div
              style={{
                position: "relative",
              }}
            >
              <svg
                onMouseEnter={() => setmouseOverAddDoc(false)}
                onMouseLeave={() => setmouseOverAddDoc(true)}
                onClick={() =>
                  activeTabDoc && setstageTwoToGoalsModal(!stageTwoToGoalsModal)
                }
                style={{
                  width: "12px",
                  height: "12px",
                  position: "absolute",
                  right: ".35rem",
                  top: "5rem",
                  cursor: "pointer",
                  zIndex: 1,
                }}
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill={!mouseOverAddDoc ? "#87CEFA" : "#F3F3F3"}
              >
                <circle
                  cx="6"
                  cy="6"
                  r="6"
                  fill={!mouseOverAddDoc ? "white" : "white"}
                />
                <path
                  d="M6.83516 5.22148H10V6.79642H6.83516V10H5.16484V6.79642H2V5.22148H5.16484V2H6.83516V5.22148Z"
                  fill={!mouseOverAddDoc ? "#87CEFA" : "#F3F3F3"}
                />
              </svg>
              <LeftBox
                onClick={() => setShowProfile(true)}
                type="BusinessScreen"
                currentBusiness={currentBusiness}
              />
              {showProfile && (
                <ProfilePictureModal
                  showProfile={showProfile}
                  className="profile_picture_external"
                  setShowProfile={setShowProfile}
                  currentClient={currentBusiness}
                />
              )}
              {activeTabDoc && stageTwoToGoalsModal && (
                <CommonQuickMenu
                  setshowGoalsModal={setshowGoalsModal}
                  showGoalsModal={showGoalsModal}
                  setstageTwoToGoalsModal={setstageTwoToGoalsModal}
                  type="business"
                />
              )}
            </div>
            <CustomSlider />
          </div>
        </>
      )}

      {/* Teams SCREEN ACTIVE */}
      {type === "teamsScreen" && (
        <>
          <div className="upper-box-group-documents">
            <div
              style={{
                position: "relative",
              }}
            >
              <svg
                onMouseEnter={() => setmouseOverAddDoc(false)}
                onMouseLeave={() => setmouseOverAddDoc(true)}
                onClick={() =>
                  activeTabDoc && setstageTwoToGoalsModal(!stageTwoToGoalsModal)
                }
                style={{
                  width: "12px",
                  height: "12px",
                  position: "absolute",
                  right: ".35rem",
                  top: "5rem",
                  cursor: "pointer",
                  zIndex: 1,
                }}
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill={!mouseOverAddDoc ? "#87CEFA" : "#F3F3F3"}
              >
                <circle
                  cx="6"
                  cy="6"
                  r="6"
                  fill={!mouseOverAddDoc ? "white" : "white"}
                />
                <path
                  d="M6.83516 5.22148H10V6.79642H6.83516V10H5.16484V6.79642H2V5.22148H5.16484V2H6.83516V5.22148Z"
                  fill={!mouseOverAddDoc ? "#87CEFA" : "#F3F3F3"}
                />
              </svg>
              <LeftBox
                onClick={() => setShowProfile(true)}
                type="BusinessScreen"
                currentBusiness={currentBusiness}
              />
              {showProfile && (
                <ProfilePictureModal
                  showProfile={showProfile}
                  className="profile_picture_external"
                  setShowProfile={setShowProfile}
                  currentClient={currentBusiness}
                />
              )}
              {activeTabDoc && stageTwoToGoalsModal && (
                <CommonQuickMenu
                  setshowGoalsModal={setshowGoalsModal}
                  showGoalsModal={showGoalsModal}
                  setstageTwoToGoalsModal={setstageTwoToGoalsModal}
                  type="teams"
                />
              )}
            </div>
            <CustomSlider />
          </div>
        </>
      )}

      {/* DOCUMENTS ACTIVE */}
      {type === "documents" && (
        <>
          <div className="upper-box-group-documents">
            <div
              style={{
                position: "relative",
              }}
            >
              <svg
                // onClick={() => dispatch(setopenClientAssessmentForm(true))}
                onClick={() =>
                  activeTabDoc && setstageTwoToGoalsModal(!stageTwoToGoalsModal)
                }
                onMouseEnter={() => setmouseOverAddDoc(false)}
                onMouseLeave={() => setmouseOverAddDoc(true)}
                style={{
                  width: "12px",
                  height: "12px",
                  position: "absolute",
                  right: ".35rem",
                  top: "5rem",
                  cursor: "pointer",
                  zIndex: 1,
                }}
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill={!mouseOverAddDoc ? "#87CEFA" : "#F3F3F3"}
              >
                <circle
                  cx="6"
                  cy="6"
                  r="6"
                  fill={!mouseOverAddDoc ? "white" : "white"}
                />
                <path
                  d="M6.83516 5.22148H10V6.79642H6.83516V10H5.16484V6.79642H2V5.22148H5.16484V2H6.83516V5.22148Z"
                  fill={!mouseOverAddDoc ? "#87CEFA" : "#F3F3F3"}
                />
              </svg>
              <LeftBox
                onClick={() => setShowProfile(true)}
                type="prospectsScreen"
                currentClient={currentClient}
              />
              {showProfile && (
                <ProfilePictureModal
                  showProfile={showProfile}
                  className="profile_picture_external"
                  setShowProfile={setShowProfile}
                  currentClient={currentClient}
                />
              )}
              {stageTwoToGoalsModal && (
                <CommonQuickMenu
                  setshowGoalsModal={setshowGoalsModal}
                  showGoalsModal={showGoalsModal}
                  setstageTwoToGoalsModal={setstageTwoToGoalsModal}
                  type="clients"
                />
              )}
            </div>
            <CustomSlider />
          </div>
        </>
      )}

      {/* STAFF SCREEN ACTIVE */}
      {type === "staffScreen" && (
        <>
          <div className="upper-box-group-documents">
            <div
              style={{
                position: "relative",
              }}
            >
              <svg
                onMouseEnter={() => setmouseOverAddDoc(false)}
                onMouseLeave={() => setmouseOverAddDoc(true)}
                onClick={() =>
                  activeTabDoc && setstageTwoToGoalsModal(!stageTwoToGoalsModal)
                }
                style={{
                  width: "12px",
                  height: "12px",
                  position: "absolute",
                  right: ".35rem",
                  top: "5rem",
                  cursor: "pointer",
                  zIndex: 1,
                }}
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill={!mouseOverAddDoc ? "#87CEFA" : "#F3F3F3"}
              >
                <circle
                  cx="6"
                  cy="6"
                  r="6"
                  fill={!mouseOverAddDoc ? "white" : "white"}
                />
                <path
                  d="M6.83516 5.22148H10V6.79642H6.83516V10H5.16484V6.79642H2V5.22148H5.16484V2H6.83516V5.22148Z"
                  fill={!mouseOverAddDoc ? "#87CEFA" : "#F3F3F3"}
                />
              </svg>
              <LeftBox
                type="staffScreen"
                currentStaff={currentStaff}
                onClick={() => setShowProfile(true)}
              />
              {showProfile && (
                <ProfilePictureModal
                  showProfile={showProfile}
                  className="profile_picture_external"
                  setShowProfile={setShowProfile}
                  currentClient={currentStaff}
                />
              )}
              {activeTabDoc && stageTwoToGoalsModal && (
                <CommonQuickMenu
                  setshowGoalsModal={setshowGoalsModal}
                  showGoalsModal={showGoalsModal}
                  setstageTwoToGoalsModal={setstageTwoToGoalsModal}
                  type="staff"
                />
              )}
            </div>
            <CustomSlider />
          </div>
        </>
      )}

      {/* UPDATE CLIENT ACTIVE */}
      {type === "updateClient" && (
        <div className="upper-box-group">
          <h5
            className="left-box-overview"
            style={{
              position: "absolute",
              bottom: "12%",
              color: "#1D2759",
              cursor: "pointer",
              zIndex: 1,
            }}
          >
            Click for overview...
          </h5>

          <div className="left-box">
            <div className="detail-box">
              <img src={ClientImg} alt="client-image" id="client-image" />
              <div className="nameOverview">
                <h3 className="left-box-title">{name}</h3>
              </div>
            </div>
          </div>
          <div className="right-box">
            <h5 className="right-box-upper-title">Sales Form:</h5>

            <div className="right-box-lower-box">
              <h5 className="right-box-lower-title">On Track</h5>
              <span className="right-box-lower-box-vector"></span>
            </div>
          </div>
        </div>
      )}

      {/* CLINICAL ACTIVE */}
      {type === "clinical" && (
        <div className="upper-box-group">
          <div
            style={{
              display: showGoalsModal ? "block" : "none",
            }}
          >
            <Modal
              isOpen={showGoalsModal}
              onClose={() => {
                setshowGoalsModal(false);
                localStorage.removeItem("flagHere");
              }}
              closeOnOverlayClick={false}
              isCentered
            >
              <ModalOverlay zIndex={10} onClick={handleOverlayClick} />
              <div className="vertical_modal_clinical" ref={clinicalGoalsModal}>
                <VerticalModal type={"clinicalModalFromAdd"} />
              </div>
            </Modal>
          </div>
          <div
            style={{
              position: "relative",
              height: "100%",
            }}
          >
            <svg
              onMouseEnter={() => setmouseOverAddDoc(false)}
              onMouseLeave={() => setmouseOverAddDoc(true)}
              onClick={() => setstageTwoToGoalsModal(!stageTwoToGoalsModal)}
              style={{
                width: "12px",
                height: "12px",
                position: "absolute",
                right: ".35rem",
                top: "5rem",
                cursor: "pointer",
                zIndex: 1,
              }}
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill={!mouseOverAddDoc ? "#87CEFA" : "#F3F3F3"}
            >
              <circle
                cx="6"
                cy="6"
                r="6"
                fill={!mouseOverAddDoc ? "white" : "white"}
              />
              <path
                d="M6.83516 5.22148H10V6.79642H6.83516V10H5.16484V6.79642H2V5.22148H5.16484V2H6.83516V5.22148Z"
                fill={!mouseOverAddDoc ? "#87CEFA" : "#F3F3F3"}
              />
            </svg>
            <LeftBox
              type="prospectsScreen"
              currentClient={currentClient}
              onClick={() => setShowProfile(true)}
            />
            {showProfile && (
              <ProfilePictureModal
                showProfile={showProfile}
                className="profile_picture_external"
                setShowProfile={setShowProfile}
                currentClient={currentClient}
              />
            )}
            {stageTwoToGoalsModal && (
              <CommonQuickMenu
                setshowGoalsModal={setshowGoalsModal}
                showGoalsModal={showGoalsModal}
                setstageTwoToGoalsModal={setstageTwoToGoalsModal}
              />
            )}
          </div>
          <CustomSlider />

          {/* <div className="upper_box_clinical_widget_container">
            {ClinicalTabArr.map((elem, index) => (
              <CommonReportingWidget
                key={index}
                title={
                  elem.title.length > 11
                    ? elem.title.substring(0, 14) + "..."
                    : elem.title
                }
                onClick={() => handleOpenClinicalModal(index, elem)}
                chartType={"line"}
                chartLabel={"Health"}
                pointRadius={3}
                chartSize={"small"}
                chartWidth="75%"
                pieChartBackgroundColors={[
                  "#5737EE",
                  "#9150DE",
                  "#B7A9FF",
                  "#7D6AD8",
                ]}
                borderWidth={1}
              />
            ))}
          </div> */}

          {openModal && (
            <>
              <Modal
                isOpen={openModal}
                onClose={() => {
                  setopenModal(false);
                }}
                closeOnOverlayClick={true}
                isCentered
              >
                <ModalOverlay
                  onClick={() => {
                    setopenModal(false);
                  }}
                  zIndex={10}
                />
                {/* NAME TO BE CHANGED TO COMMON LARGE MODAL */}
                <AuditModal
                  type="clinical"
                  selectedWidgdet={selectedWigdetItem}
                  setopenModal={setopenModal}
                />
              </Modal>
            </>
          )}
        </div>
      )}

      {/* COMMS ACTIVE */}
      {type === "comms" && (
        <div className="upper-box-group">
          <div
            style={{
              position: "relative",
            }}
          >
            {/* <svg
                onMouseEnter={() => setmouseOverAddDoc(false)}
              onMouseLeave={() => setmouseOverAddDoc(true)}
                 style={{
              
                position: "absolute",
                right: ".15rem",
                top: "3.65rem",
                cursor:"pointer"
              }}
            xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12"      fill={!mouseOverAddDoc ? "#87CEFA" : "#F3F3F3"}>
  <circle cx="6" cy="6" r="6"      fill={!mouseOverAddDoc ? "white" : "white"}/>
  <path d="M6.83516 5.22148H10V6.79642H6.83516V10H5.16484V6.79642H2V5.22148H5.16484V2H6.83516V5.22148Z"      fill={!mouseOverAddDoc ? "#87CEFA" : "#F3F3F3"}/>
</svg> */}
            <svg
              onMouseEnter={() => setmouseOverAddDoc(false)}
              onMouseLeave={() => setmouseOverAddDoc(true)}
              style={{
                width: "12px",
                height: "12px",
                position: "absolute",
                right: ".35rem",
                top: "5rem",
                cursor: "pointer",
                zIndex: 1,
              }}
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill={!mouseOverAddDoc ? "#87CEFA" : "#F3F3F3"}
            >
              <circle
                cx="6"
                cy="6"
                r="6"
                fill={!mouseOverAddDoc ? "white" : "white"}
              />
              <path
                d="M6.83516 5.22148H10V6.79642H6.83516V10H5.16484V6.79642H2V5.22148H5.16484V2H6.83516V5.22148Z"
                fill={!mouseOverAddDoc ? "#87CEFA" : "#F3F3F3"}
              />
            </svg>
            <LeftBox type="documents" onClick={setShowProfile(true)} />{" "}
            {showProfile && (
              <ProfilePictureModal
                showProfile={showProfile}
                className="profile_picture_external"
                setShowProfile={setShowProfile}
              />
            )}
          </div>

          <CustomSlider />
        </div>
      )}

      {/* {type === "clients" ? <SearchBox type={"clients"} /> : ""} */}
    </Fragment>
  );
};

export default UpperBox;
