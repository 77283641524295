import React from "react";
import ReactDatePicker from "react-datepicker";
import WidgetItems from "../../../../WidgetItems";
import { useEffect, useMemo, useState } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../Controller/redux/Hooks";
import { getLocationUserThunk } from "../../../../../../../../../Controller/redux/features/LocationFeature/locationSlice";
import { dummyArrBusiness } from "../../../Data/ClientScreenContentArr";
import CustomDatePicker from "../../../../../../../../components/DatePicker/CustomDatePicker";

const ClientDetails = ({
  currentClient,
  DropdownIcon,
  handleChange,
  handleClick,
  details,
  dataObject,
  result,
  position,
  selectedDate,
  currentClientData,
  setShowStatusWidget,
  showStatusWidget,
  widgetRef,
  pickerRef,
  // dummyArr,
  handleDateChange,
  getCurrentDate,
  selectedACATDate,
  openACATDropdown,
  handleOpenDropdown,
  handleIntervalChange,
  highlightOption,
  unhighlightOption,
  datepickerRef,
  isMeals,
  handleACATDateChange,
  selectedInterval,
  packageLevel,
  setpackageLevel,
  setunsavedChanges,
  setHasUnsavedChanges,
  hasUnsavedChanges,
  handleChangeForAddress,
  selectAddress,
  possibleAddresses,
  handleDateChangeForEFA,
  selectedEFA,
  locationRoomData,
  locationWardData,
  itemsArr,
  setHasUnsavedChangesPopup,
  setIsThereAnyChange,
}: any) => {
  const { client_tabs } = useAppSelector(
    (state) => state?.common?.domainDetail
  );

  const [btnArr, setbtnArr] = useState<string[]>([]);

  useEffect(() => {
    if (client_tabs) {
      let tabArray: string[] = client_tabs.split(",");
      let isExist = tabArray.includes("Meals");
      if (isMeals && !isExist) {
        tabArray.push("Meals");
      }
      setbtnArr(tabArray);
    }
  }, [client_tabs]);
  const dummyArrWhenRAC = [
    {
      UpperTitle: "Given Name:",
      LowerTitle: "",
      placeHolder: "Enter Given Name",
    },
    {
      UpperTitle: "Middle Name(s):",
      LowerTitle: "",
      placeHolder: "Enter Middle Name",
    },

    {
      UpperTitle: "Family Name:",
      LowerTitle: "",
      placeHolder: "Enter Family Name",
    },
    {
      UpperTitle: "Known As:",
      LowerTitle: "",
      placeHolder: "Enter Known As",
    },
    {
      UpperTitle: "Address:",
      LowerTitle: "",
      placeHolder: "Enter Address",
    },
    {
      UpperTitle: "Email:",
      LowerTitle: "",
      placeHolder: "Enter Email",
    },
    {
      UpperTitle: "Phone:",
      LowerTitle: "",
      placeHolder: "Enter Phone",
    },
    {
      UpperTitle: "Mobile:",
      LowerTitle: "",
      placeHolder: "Enter Mobile",
    },
    {
      UpperTitle: "Date of Birth:",
      LowerTitle: "",
      placeHolder: "Enter Date of Birth",
    },
    {
      UpperTitle: "Funding Type:",
      LowerTitle: "",
      placeHolder: "Enter Funding Type",
    },
    {
      UpperTitle: "Package Level:",
      LowerTitle: "",
      placeHolder: "Enter Package Level",
    },
    {
      UpperTitle: "Package Number:",
      LowerTitle: "",
      placeHolder: "Enter Package Number",
    },
    {
      UpperTitle: "ACAT Assessment:",
      LowerTitle: "",
      placeHolder: "Enter ACAT Assessment",
    },
    {
      UpperTitle: "Client Type:",
      LowerTitle: "",
      placeHolder: "Enter Client Type",
    },

    {
      UpperTitle: "Expected Funding Date:",
      LowerTitle: "",
      placeHolder: "Enter Expected Funding Date",
    },
    {
      UpperTitle: "Residence:",
      LowerTitle: "",
      placeHolder: "Enter Resident",
    },
    {
      UpperTitle: "Building:",
      LowerTitle: "",
      placeHolder: "Enter Building",
    },
    {
      UpperTitle: "Ward:",
      LowerTitle: "",
      placeHolder: "Enter Ward",
    },
    {
      UpperTitle: "Room:",
      LowerTitle: "",
      placeHolder: "Enter Room",
    },
    {
      UpperTitle: "Table:",
      LowerTitle: "",
      placeHolder: "Enter Table",
    },
  ];
  const dummyArrWhenVillage = [
    {
      UpperTitle: "Given Name:",
      LowerTitle: "",
      placeHolder: "Enter Given Name",
    },
    {
      UpperTitle: "Middle Name(s):",
      LowerTitle: "",
      placeHolder: "Enter Middle Name",
    },

    {
      UpperTitle: "Family Name:",
      LowerTitle: "",
      placeHolder: "Enter Family Name",
    },
    {
      UpperTitle: "Known As:",
      LowerTitle: "",
      placeHolder: "Enter Known As",
    },
    {
      UpperTitle: "Address:",
      LowerTitle: "",
      placeHolder: "Enter Address",
    },
    {
      UpperTitle: "Email:",
      LowerTitle: "",
      placeHolder: "Enter Email",
    },
    {
      UpperTitle: "Phone:",
      LowerTitle: "",
      placeHolder: "Enter Phone",
    },
    {
      UpperTitle: "Mobile:",
      LowerTitle: "",
      placeHolder: "Enter Mobile",
    },
    {
      UpperTitle: "Date of Birth:",
      LowerTitle: "",
      placeHolder: "Enter Date of Birth",
    },
    {
      UpperTitle: "Funding Type:",
      LowerTitle: "",
      placeHolder: "Enter Funding Type",
    },
    {
      UpperTitle: "Package Level:",
      LowerTitle: "",
      placeHolder: "Enter Package Level",
    },
    {
      UpperTitle: "Package Number:",
      LowerTitle: "",
      placeHolder: "Enter Package Number",
    },
    {
      UpperTitle: "ACAT Assessment:",
      LowerTitle: "",
      placeHolder: "Enter ACAT Assessment",
    },
    {
      UpperTitle: "Client Type:",
      LowerTitle: "",
      placeHolder: "Enter Client Type",
    },

    {
      UpperTitle: "Expected Funding Date:",
      LowerTitle: "",
      placeHolder: "Enter Expected Funding Date",
    },

    {
      UpperTitle: "Residence:",
      LowerTitle: "",
      placeHolder: "Enter Resident",
    },

    {
      UpperTitle: "Table:",
      LowerTitle: "",
      placeHolder: "Enter Table",
    },
  ];
  const dummyArrWhenSil = [
    {
      UpperTitle: "Given Name:",
      LowerTitle: "",
      placeHolder: "Enter Given Name",
    },
    {
      UpperTitle: "Middle Name(s):",
      LowerTitle: "",
      placeHolder: "Enter Middle Name",
    },

    {
      UpperTitle: "Family Name:",
      LowerTitle: "",
      placeHolder: "Enter Family Name",
    },
    {
      UpperTitle: "Known As:",
      LowerTitle: "",
      placeHolder: "Enter Known As",
    },
    {
      UpperTitle: "Address:",
      LowerTitle: "",
      placeHolder: "Enter Address",
    },
    {
      UpperTitle: "Email:",
      LowerTitle: "",
      placeHolder: "Enter Email",
    },
    {
      UpperTitle: "Phone:",
      LowerTitle: "",
      placeHolder: "Enter Phone",
    },
    {
      UpperTitle: "Mobile:",
      LowerTitle: "",
      placeHolder: "Enter Mobile",
    },
    {
      UpperTitle: "Date of Birth:",
      LowerTitle: "",
      placeHolder: "Enter Date of Birth",
    },
    {
      UpperTitle: "Funding Type:",
      LowerTitle: "",
      placeHolder: "Enter Funding Type",
    },
    {
      UpperTitle: "Package Level:",
      LowerTitle: "",
      placeHolder: "Enter Package Level",
    },
    {
      UpperTitle: "Package Number:",
      LowerTitle: "",
      placeHolder: "Enter Package Number",
    },
    {
      UpperTitle: "ACAT Assessment:",
      LowerTitle: "",
      placeHolder: "Enter ACAT Assessment",
    },
    {
      UpperTitle: "Client Type:",
      LowerTitle: "",
      placeHolder: "Enter Client Type",
    },

    {
      UpperTitle: "Expected Funding Date:",
      LowerTitle: "",
      placeHolder: "Enter Expected Funding Date",
    },
    {
      UpperTitle: "Residence:",
      LowerTitle: "",
      placeHolder: "Enter Resident",
    },
  ];
  const dummyArr = [
    {
      UpperTitle: "Given Name:",
      LowerTitle: "",
      placeHolder: "Enter Given Name",
    },
    {
      UpperTitle: "Middle Name(s):",
      LowerTitle: "",
      placeHolder: "Enter Middle Name",
    },

    {
      UpperTitle: "Family Name:",
      LowerTitle: "",
      placeHolder: "Enter Family Name",
    },
    {
      UpperTitle: "Known As:",
      LowerTitle: "",
      placeHolder: "Enter Known As",
    },
    {
      UpperTitle: "Address:",
      LowerTitle: "",
      placeHolder: "Enter Address",
    },
    {
      UpperTitle: "Email:",
      LowerTitle: "",
      placeHolder: "Enter Email",
    },
    {
      UpperTitle: "Phone:",
      LowerTitle: "",
      placeHolder: "Enter Phone",
    },
    {
      UpperTitle: "Mobile:",
      LowerTitle: "",
      placeHolder: "Enter Mobile",
    },
    {
      UpperTitle: "Date of Birth:",
      LowerTitle: "",
      placeHolder: "Enter Date of Birth",
    },
    {
      UpperTitle: "Funding Type:",
      LowerTitle: "",
      placeHolder: "Enter Funding Type",
    },
    {
      UpperTitle: "Package Level:",
      LowerTitle: "",
      placeHolder: "Enter Package Level",
    },
    {
      UpperTitle: "Package Number:",
      LowerTitle: "",
      placeHolder: "Enter Package Number",
    },
    {
      UpperTitle: "ACAT Assessment:",
      LowerTitle: "",
      placeHolder: "Enter ACAT Assessment",
    },
    {
      UpperTitle: "Client Type:",
      LowerTitle: "",
      placeHolder: "Enter Client Type",
    },

    {
      UpperTitle: "Expected Funding Date:",
      LowerTitle: "",
      placeHolder: "Enter Expected Funding Date",
    },
  ];
  /////////////////

  const dispatch = useAppDispatch();
  // @ts-ignore
  const dynamicLocationId = parseInt(localStorage.getItem("dynamicLocationId"));
  useEffect(() => {
    dispatch(getLocationUserThunk(dynamicLocationId));
  }, [dynamicLocationId]);

  const handleCallBackToToggleVisibilityOfStatuWidget = () => {
    setShowStatusWidget(false);
  };

  const handleSelectPackageLevel = (e: any) => {
    setHasUnsavedChangesPopup(true);
    setpackageLevel(e.target.value);
    setunsavedChanges(true);
  };

  useEffect(() => {
    setHasUnsavedChanges(false);
  }, []);
  const getUserRoles = useAppSelector((state) => state?.people?.clientRoles);

  const [userRoles, setUserRoles] = useState([]);

  useEffect(() => {
    if (getUserRoles?.data && getUserRoles?.data?.length > 0) {
      setUserRoles(getUserRoles?.data);
    }
  }, [getUserRoles]);

  const {
    getLocationTypeResidence,
    getLocationTypeBuilding,
    getLocationTypeWard,
    getLocationTypeRoom,
    getLocationTypeTable,
    getLocationTypeSil,
  } = useAppSelector((state) => state?.location);

  const [locationResidence, setLocationResidence] = useState<any>([]);
  const [locationBuilding, setLocationBuilding] = useState([]);
  const [locationWard, setLocationWard] = useState([]);
  const [locationRoom, setLocationRoom] = useState([]);
  const [locationTable, setLocationTable] = useState([]);

  //usestate by dhruv

  // const [package_number, setPackage_number] = useState("");
  useEffect(() => {
    if (
      getLocationTypeResidence?.data &&
      getLocationTypeResidence?.data?.length > 0 &&
      getLocationTypeSil?.data
    ) {
      setLocationResidence([
        ...getLocationTypeResidence?.data,
        ...getLocationTypeSil.data,
      ]);
    }
    if (
      getLocationTypeBuilding?.data &&
      getLocationTypeBuilding?.data?.length > 0
    ) {
      setLocationBuilding(getLocationTypeBuilding?.data);
    }
    if (getLocationTypeWard?.data && getLocationTypeWard?.data?.length > 0) {
      setLocationWard(getLocationTypeWard?.data);
    }
    if (getLocationTypeRoom?.data && getLocationTypeRoom?.data?.length > 0) {
      setLocationRoom(getLocationTypeRoom?.data);
    }
    if (getLocationTypeTable?.data && getLocationTypeTable?.data?.length > 0) {
      setLocationTable(getLocationTypeTable?.data);
    }
  }, [
    getLocationTypeResidence,
    getLocationTypeBuilding,
    getLocationTypeRoom,
    getLocationTypeWard,
    getLocationTypeTable,
  ]);

  const [OpenStatusWidget, setOpenStatusWidget] = useState<boolean>(false);
  const [
    allClientsDataToTrackClickOfStatusClick,
    setAllClientsDataToTrackClickOfStatusClick,
  ] = useState(currentClient ? Array(currentClient.length).fill(false) : []);

  const {
    detailstab_color,
    assoctab_color,
    clinicaltab_color,
    commstab_color,
    doctab_color,
    fintab_color,
    daybtab_color,
    mealstab_color,
    has_detailstab,
    has_assoctab,
    has_doctab,
    has_daytab,
    has_fintab,
    has_mealstab,
    has_commstab,
    has_clinicaltab,
  } = useAppSelector((state) => state?.common?.domainDetail);
  const buttonConditions = {
    Details: has_detailstab === "1",
    Associations: has_assoctab === "1",
    Clinical: has_clinicaltab === "1",
    Comms: has_commstab === "1",
    Documents: has_doctab === "1",
    Finance: has_fintab === "1",
    Daybook: has_daytab === "1",
    Meals: has_mealstab === "1",
  };

  const { domainDetail } = useAppSelector((state) => state?.common);
  let businessType = domainDetail?.businessmodel_type;

  const [clientType, setClientType] = useState<any>([]);

  useEffect(() => {
    if (domainDetail && domainDetail?.client_type) {
      let splitTheTypeString = domainDetail?.client_type.split(",");
      setClientType(splitTheTypeString);
    }
  }, [domainDetail]);

  const [clientTypeArr, setclientTypeArr] = useState<any>([]);

  useEffect(() => {
    if (domainDetail && domainDetail?.client_type) {
      let splitTheProspectTypeString = domainDetail?.client_type.split(",");
      setclientTypeArr(splitTheProspectTypeString);
    }
  }, [domainDetail]);

  const [tempClientType, settempClientType] = useState<any>("");

  useEffect(() => {
    if (currentClient) {
      settempClientType(currentClient?.roles[0]?.desc);
    }
  }, [currentClient]);

  const [conditionalRenderOfArray, setconditionalRenderOfArray] =
    useState<any>(dummyArr);
  // let clientTypeObj = getUserRoles?.data?.find((elem: any) => {
  //   if (elem.id == tempClientType) {
  //     return elem;
  //   }
  // });
  // let elemDesc = clientTypeObj?.desc;
  const [elemDesc, setelemDesc] = useState("");

  let clientTypeObj = getUserRoles?.data?.find((elem: any) => {
    if (elem.id == tempClientType) {
      return elem;
    }
  });

  // console.log(tempClientType,"clientTypeObj")

  useEffect(() => {
    if (clientTypeObj) {
      setelemDesc(clientTypeObj?.desc);
    } else {
      setelemDesc(currentClient?.roles[0]?.desc);
    }
  }, [currentClient, clientType, clientTypeObj, tempClientType]);

  useEffect(() => {
    if (currentClient) {
      setelemDesc(currentClient?.roles[0]?.desc);
    }
  }, [currentClient]);
  // console.log(elemDesc,"elemdesc")

  // console.log(currentClient.roles[0].desc,"currentClient")
  // console.log(elemDesc, "elemdESC");

  useEffect(() => {
    if (elemDesc) {
      let tempArray =
        elemDesc == "RAC"
          ? dummyArrWhenRAC
          : elemDesc === "Village"
          ? dummyArrWhenVillage
          : elemDesc == "SIL"
          ? dummyArrWhenSil
          : dummyArr;

      setconditionalRenderOfArray(tempArray);
    }
  }, [elemDesc, clientTypeObj]);
  let tempArray =
    elemDesc == "RAC"
      ? dummyArrWhenRAC
      : elemDesc === "Village"
      ? dummyArrWhenVillage
      : elemDesc == "SIL"
      ? dummyArrWhenSil
      : dummyArr;

  // console.log(elemDesc,"conditionalRenderOfArray")

  let dropdownTableWhenVillage =
    elemDesc == "Village" ? locationTable : locationBuilding;

  return (
    <div>
      <div className="unique-client-details-wrapper">
        <div className="buttonDiv">
          {btnArr?.map((button: any, index: any) => {
            const btnDetail = button === "Details";
            const btnAssociation = button === "Associations";
            const btnClinical = button === "Clinical";
            const btnComms = button === "Comms";
            const btnDocuments = button === "Documents";
            const btnFinance = button === "Finance";
            const btnDaybook = button === "Daybook";
            const btnMeals = button === "Meals";

            const buttonStyle =
              button === "Details"
                ? {
                    backgroundColor: "white",
                    fontWeight: 600,
                    borderBottom: "none",
                    transform: "translateY(-9%)",
                    height: "40px",
                  }
                : {
                    backgroundColor: btnDetail
                      ? detailstab_color
                      : btnAssociation
                      ? assoctab_color
                      : btnClinical
                      ? clinicaltab_color
                      : btnComms
                      ? commstab_color
                      : btnDocuments
                      ? doctab_color
                      : btnFinance
                      ? fintab_color
                      : btnDaybook
                      ? daybtab_color
                      : btnMeals
                      ? mealstab_color
                      : "",
                    //@ts-ignore-
                    display: buttonConditions[button] ? "" : "none",
                  };

            return (
              <button
                key={index}
                style={buttonStyle}
                className="on-hover-btn-client-screen"
                onClick={() => handleClick(button)}
              >
                {button}
              </button>
            );
          })}
        </div>

        <div className="main-box">
          <div className="main-box main-box-associations-tab">
            <div className="main_box_header_client main-box-header-associations-tab">
              {currentClientData?.map((elem: any, index: any) => {
                return (
                  <>
                    <div key={index} className="item item-funding-type">
                      <span className="association-tab-upper-item">
                        {elem.UpperTitle}
                      </span>
                      <span className="association-tab-lower-item">
                        {elem.UpperTitle?.includes("Email")
                          ? elem.LowerTitle
                          : elem.LowerTitle?.length > 18
                          ? elem.LowerTitle?.slice(0, 17) + "..."
                          : elem.LowerTitle}
                      </span>
                    </div>
                  </>
                );
              })}
              <div
                className="status-btn status-btn-active status-btn-active-clients"
                style={{
                  position: "absolute",
                  right: "20px",
                  top: "25%",
                  fontWeight: 700,

                  cursor: "pointer",
                  color:
                    currentClient?.status == 1
                      ? "#97D94A"
                      : currentClient?.status == 2 ||
                        currentClient?.status == 3 ||
                        currentClient?.status == 4 ||
                        currentClient?.status == 5
                      ? "#FFBC10"
                      : currentClient?.status == 0 || currentClient?.status == 6
                      ? "#EB5757"
                      : "#000",

                  backgroundColor:
                    currentClient?.status == 1
                      ? "#F8FFEF"
                      : currentClient?.status == 2 ||
                        currentClient?.status == 3 ||
                        currentClient?.status == 4 ||
                        currentClient?.status == 5
                      ? "#FEFFE5"
                      : currentClient?.status == 0 || currentClient?.status == 6
                      ? "#FFF0F0"
                      : "#f8ffef",
                }}
                onClick={() => setOpenStatusWidget(true)}
              >
                {currentClient?.status == 1
                  ? "Active"
                  : currentClient?.status == 2
                  ? "On Leave"
                  : currentClient?.status == 3
                  ? "Hospital"
                  : currentClient?.status == 4
                  ? "Waitlist"
                  : currentClient?.status == 5
                  ? "Respite"
                  : currentClient?.status == 0
                  ? "Inactive"
                  : currentClient?.status == 6
                  ? "Deceased"
                  : "Loading..."}
                {OpenStatusWidget && (
                  <div
                    ref={widgetRef}
                    style={{
                      position: "absolute",
                      top: "135%",
                      right: 0,
                      zIndex: 1,
                    }}
                  >
                    <WidgetItems
                      type={"clientScreen"}
                      position={position}
                      widgetRef={widgetRef}
                      setShowStatusWidget={setShowStatusWidget}
                      setOpenStatusWidget={setOpenStatusWidget}
                      setAllClientsDataToTrackClickOfStatusClick={
                        setAllClientsDataToTrackClickOfStatusClick
                      }
                      allClientsDataToTrackClickOfStatusClick={
                        allClientsDataToTrackClickOfStatusClick
                      }
                      showStatusWidget={showStatusWidget}
                      handleCallBack={
                        handleCallBackToToggleVisibilityOfStatuWidget
                      }
                      itemsArr={itemsArr}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>

          <div>
            <div className="client-screen-personal-details-heading">
              <span className="client-screen-sub-heading-title">
                Personal Details
              </span>
              <span className="client-screen-line"></span>
            </div>

            <div className="main-box-body relative">
              {[...(businessType === "B2B" ? dummyArrBusiness : dummyArr)]
                ?.slice(0, 9)
                ?.map((elem: any, index: any) => {
                  const fieldName = Object.keys(details)[index];

                  return (
                    <div
                      className={`content-wrapper ${
                        index === 9 || index === 10 || index === 11 ? "" : ""
                      } ${index === 8 && "client_date_picker"}`}
                      key={index}
                    >
                      <div className="wrapper-title">
                        {elem.UpperTitle === "Business Type:"
                          ? "Client Type:"
                          : elem.UpperTitle === "Business Name:"
                          ? "Client Name:"
                          : elem.UpperTitle}
                      </div>
                      {index == 8 && (
                        <>
                          <div className="wrapper-title"></div>
                        </>
                      )}
                      {index === 8 ? (
                        <div className="custom_date_picker_absolute">
                          <CustomDatePicker
                            currentClient={currentClient}
                            newDate={selectedDate}
                            handleChange={handleDateChange}
                            maxDate={getCurrentDate()}
                            hasUnsavedChanges={hasUnsavedChanges}
                            setHasUnsavedChangesPopup={
                              setHasUnsavedChangesPopup
                            }
                            setHasUnsavedChanges={setHasUnsavedChanges}
                            setIsThereAnyChange={setIsThereAnyChange}
                          />
                        </div>
                      ) : index === 4 ? (
                        <>
                          {businessType === "B2B" ? (
                            <>
                              <select
                                className="wrapper-info wrapper-info-select-dropdown"
                                onChange={(e) => handleChange(index, e)}
                                value={details[fieldName]}
                                style={{
                                  border: "none",
                                  outline: "none",
                                  height: "fit-content",
                                  backgroundColor: "white",
                                }}
                              >
                                <option value="">Please Select...</option>

                                {userRoles?.map(
                                  (elem: any, index: number | any) => {
                                    let isExist: any = clientTypeArr.includes(
                                      `${elem?.desc}`
                                    );
                                    if (isExist) {
                                      return (
                                        <option key={index} value={elem?.id}>
                                          {elem.desc}
                                        </option>
                                      );
                                    }
                                  }
                                )}
                              </select>
                            </>
                          ) : (
                            <div>
                              <input
                                type="text"
                                onChange={(e) =>
                                  handleChangeForAddress(index, e)
                                }
                                className="wrapper-info"
                                placeholder="Enter an address"
                                value={details[fieldName]}
                              />
                              <div className="address_listing">
                                <ul>
                                  {possibleAddresses.length > 0
                                    ? possibleAddresses.map(
                                        (address: any, index: any) => {
                                          return (
                                            <li
                                              key={index}
                                              onClick={selectAddress}
                                              className="address_listing_item"
                                            >
                                              {address.description}
                                            </li>
                                          );
                                        }
                                      )
                                    : null}
                                </ul>
                              </div>
                            </div>
                          )}
                        </>
                      ) : index === 9 ? (
                        <select
                          className="wrapper-info wrapper-info-select-dropdown"
                          onChange={(e) => handleChange(index, e)}
                          value={details[fieldName]}
                        >
                          <option value="">Please Select...</option>

                          <option value="Aged Care">Aged Care</option>
                          <option value="Disability">Disability</option>
                          <option value="Veterans">Veterans</option>
                          <option value="Private">Private</option>
                          <option value="Insurance">Insurance</option>
                          <option value="Other">Other</option>
                        </select>
                      ) : index === 11 ? (
                        <div className="acat_assessment_wrapper">
                          <ReactDatePicker
                            placeholderText="ACAT Assessment"
                            selected={selectedACATDate}
                            ref={datepickerRef}
                            onChange={handleACATDateChange}
                            onFocus={handleOpenDropdown}
                            showYearDropdown
                            showMonthDropdown
                            dropdownMode="select"
                            shouldCloseOnSelect={false}
                            open={openACATDropdown}
                            value={
                              selectedACATDate
                                ? `${result}`
                                : currentClient
                                ? currentClient?.acat_assessment
                                : "ACAT Assessment"
                            }
                          />
                        </div>
                      ) : (
                        <input
                          className="wrapper-info"
                          onChange={(e) => handleChange(index, e)}
                          value={details[fieldName]}
                          placeholder={elem?.placeHolder}
                          style={{
                            border: "none",
                            outline: "none",
                            height: "fit-content",
                          }}
                        />
                      )}

                      {elem.index === 5 && (
                        <div className="dropdown-icon">
                          <img src={DropdownIcon} alt="dropdown-icon" />
                        </div>
                      )}
                      <div className="wrapper-line"></div>
                    </div>
                  );
                })}
            </div>
            {businessType === "B2C" && (
              <>
                <div className="client-screen-personal-details-heading">
                  <span className=" client-screen-sub-heading-title">
                    Profile Details
                  </span>
                  <span className=" client-screen-line-additional-details"></span>
                </div>
                <div className="main-box-body relative">
                  {/* MAPPING OVER DATA IN DETAILS FIELD */}
                  {conditionalRenderOfArray
                    ?.slice(9, 20)

                    .map((elem: any, index: any) => {
                      const indexTemp = index + 9;
                      const fieldName = Object.keys(details)[indexTemp];

                      return (
                        <div
                          className={`content-wrapper ${
                            indexTemp === 9 ||
                            indexTemp === 10 ||
                            indexTemp === 11
                              ? ""
                              : ""
                          }`}
                          style={{
                            display:
                              elemDesc === "RAC" && indexTemp === 15
                                ? "none"
                                : "block",
                          }}
                          key={indexTemp}
                        >
                          <div
                            className="wrapper-title"
                            style={{
                              display:
                                elemDesc === "RAC" && indexTemp === 15
                                  ? "none"
                                  : "block",
                            }}
                          >
                            {elem.UpperTitle}
                          </div>
                          {indexTemp === 8 ? (
                            <CustomDatePicker
                              newDate={selectedDate}
                              handleChange={handleDateChange}
                              maxDate={getCurrentDate()}
                              hasUnsavedChanges={hasUnsavedChanges}
                              setHasUnsavedChangesPopup={
                                setHasUnsavedChangesPopup
                              }
                              setHasUnsavedChanges={setHasUnsavedChanges}
                              setIsThereAnyChange={setIsThereAnyChange}
                            />
                          ) : indexTemp === 9 ? (
                            <select
                              className="wrapper-info wrapper-info-select-dropdown"
                              onChange={(e) => handleChange(indexTemp, e)}
                              value={details[fieldName]}
                              style={{
                                border: "none",
                                outline: "none",
                                height: "fit-content",
                                backgroundColor: "white",
                              }}
                            >
                              <option value="">Please Select...</option>

                              <option value="Aged Care">Aged Care</option>
                              <option value="Disability">Disability</option>
                              <option value="Veterans">Veterans</option>
                              <option value="Private">Private</option>
                              <option value="Insurance">Insurance</option>
                              <option value="Other">Other</option>
                            </select>
                          ) : indexTemp === 10 ? (
                            <select
                              className="wrapper-info wrapper-info-select-dropdown"
                              onChange={handleSelectPackageLevel}
                              value={packageLevel}
                            >
                              <option value="">Please Select...</option>
                              {[
                                "NDIS - Self Managed",
                                "NDIS - Plan Managed",
                                "NDIS - NDIA Managed",
                                "DSOA",
                                "HCP Level 1",
                                "HCP Level 2",
                                "HCP Level 3",
                                "HCP Level 4",
                                "HACC",
                                "CHSP",
                                "DVA",
                                "DVA Nursing",
                                "TAC",
                                "Brokerage",
                                "Private Fee for Service",
                              ].map((elem, index) => (
                                <option key={index} value={elem}>
                                  {elem}
                                </option>
                              ))}
                            </select>
                          ) : indexTemp === 11 ? (
                            <input
                              type="text"
                              placeholder="Enter Package Number"
                              value={details[fieldName]}
                              onChange={(e) => handleChange(indexTemp, e)}
                              style={{ marginTop: "5px" }}
                            />
                          ) : indexTemp === 12 ? (
                            <div id="acat_all_items_wrapper" ref={pickerRef}>
                              {openACATDropdown && (
                                <div id="acat_wrapper">
                                  <div id="acat_option">
                                    {["Booked", "Completed"]?.map(
                                      (option: any) => (
                                        <div
                                          key={option}
                                          className={`each_acat_option   
                                      ${
                                        selectedInterval.toLowerCase() ===
                                        option.toLowerCase()
                                          ? "selected_option"
                                          : ""
                                      } 

                                  `}
                                          onClick={() =>
                                            handleIntervalChange(
                                              option.toLowerCase()
                                            )
                                          }
                                        >
                                          {option}
                                        </div>
                                      )
                                    )}
                                  </div>
                                </div>
                              )}
                              <ReactDatePicker
                                placeholderText="ACAT Assessment"
                                selected={selectedACATDate}
                                ref={datepickerRef}
                                onChange={handleACATDateChange}
                                onFocus={handleOpenDropdown}
                                id="react_datepikcer_acat"
                                // className="date-picker-acat"
                                showYearDropdown
                                showMonthDropdown
                                dropdownMode="select"
                                shouldCloseOnSelect={false}
                                open={openACATDropdown}
                                value={
                                  selectedACATDate
                                    ? `${result}`
                                    : currentClient
                                    ? currentClient?.acat_assessment
                                    : "ACAT Assessment"
                                }
                              />
                            </div>
                          ) : indexTemp === 13 ? (
                            <select
                              className="wrapper-info wrapper_info_clients_type"
                              onChange={(e) => {
                                settempClientType(e.target.value);
                                handleChange(indexTemp, e);
                              }}
                              value={details[fieldName]}
                              style={{
                                border: "none",
                                outline: "none",
                                height: "fit-content",
                                backgroundColor: "white",
                                // width: "256px",
                              }}
                            >
                              <option value="">Please Select...</option>

                              {userRoles?.map(
                                (elem: any, index: number | any) => {
                                  let isExist: any = clientType.includes(
                                    `${elem?.desc}`
                                  );
                                  if (isExist) {
                                    return (
                                      <>
                                        <option key={index} value={elem?.id}>
                                          {elem?.desc}
                                        </option>
                                      </>
                                    );
                                  }
                                }
                              )}
                            </select>
                          ) : indexTemp === 14 ? (
                            <CustomDatePicker
                              newDate={selectedEFA}
                              handleChange={handleDateChangeForEFA}
                              hasUnsavedChanges={hasUnsavedChanges}
                              setHasUnsavedChangesPopup={
                                setHasUnsavedChangesPopup
                              }
                              setHasUnsavedChanges={setHasUnsavedChanges}
                              setIsThereAnyChange={setIsThereAnyChange}
                            />
                          ) : indexTemp === 15 ? (
                            <select
                              className="wrapper-info wrapper_info_clients_type"
                              onChange={(e) => handleChange(indexTemp, e)}
                              value={details[fieldName]}
                              style={{
                                border: "none",
                                outline: "none",
                                height: "fit-content",
                                backgroundColor: "white",
                                // width: "256px",
                                display: elemDesc === "RAC" ? "none" : "block",
                              }}
                            >
                              <option value="">Please Select...</option>

                              {locationResidence?.map(
                                (elem: any, index: number | any) => (
                                  <>
                                    <option key={index} value={elem?.id}>
                                      {elem?.first_name}
                                    </option>
                                  </>
                                )
                              )}
                            </select>
                          ) : indexTemp === 16 ? (
                            <select
                              className="wrapper-info wrapper_info_clients_type"
                              onChange={(e) => handleChange(indexTemp, e)}
                              value={details?.building}
                              style={{
                                border: "none",
                                outline: "none",
                                height: "fit-content",
                                backgroundColor: "white",
                                // width: "256px",
                              }}
                            >
                              <option value="">Please Select...</option>

                              {dropdownTableWhenVillage?.map(
                                (elem: any, index: number | any) => (
                                  <>
                                    <option key={index} value={elem?.id}>
                                      {elem?.first_name}
                                    </option>
                                  </>
                                )
                              )}
                            </select>
                          ) : indexTemp === 17 ? (
                            <select
                              className="wrapper-info wrapper_info_clients_type"
                              onChange={(e) => handleChange(indexTemp, e)}
                              value={details?.ward}
                              style={{
                                border: "none",
                                outline: "none",
                                height: "fit-content",
                                backgroundColor: "white",
                                // width: "256px",
                              }}
                            >
                              <option value="">Please Select...</option>

                              {locationWardData?.map(
                                (elem: any, index: number | any) => (
                                  <>
                                    <option key={index} value={elem?.id}>
                                      {elem?.first_name}
                                    </option>
                                  </>
                                )
                              )}
                            </select>
                          ) : indexTemp === 18 ? (
                            <select
                              className="wrapper-info wrapper_info_clients_type"
                              onChange={(e) => handleChange(indexTemp, e)}
                              value={details?.room}
                              style={{
                                border: "none",
                                outline: "none",
                                height: "fit-content",
                                backgroundColor: "white",
                                // width: "256px",
                              }}
                            >
                              <option value="">Please Select...</option>

                              {locationRoomData?.map(
                                (elem: any, index: number | any) => (
                                  <>
                                    <option key={index} value={elem?.id}>
                                      {elem?.first_name}
                                    </option>
                                  </>
                                )
                              )}
                            </select>
                          ) : (
                              elemDesc == "Village"
                                ? indexTemp === 10
                                : indexTemp === 19
                            ) ? (
                            <select
                              className="wrapper-info wrapper_info_clients_type"
                              onChange={(e) => handleChange(indexTemp, e)}
                              value={details[fieldName]}
                              style={{
                                border: "none",
                                outline: "none",
                                height: "fit-content",
                                backgroundColor: "white",
                                // width: "256px",
                              }}
                            >
                              <option value="">Please Select...</option>

                              {locationTable?.map(
                                (elem: any, index: number | any) => (
                                  <>
                                    <option key={index} value={elem?.id}>
                                      {elem?.first_name}
                                    </option>
                                  </>
                                )
                              )}
                            </select>
                          ) : (
                            <input
                              className="wrapper-info"
                              onChange={(e) => handleChange(indexTemp, e)}
                              value={details[fieldName]}
                              placeholder={elem?.placeHolder}
                              style={{
                                border: "none",
                                outline: "none",
                                height: "fit-content",
                              }}
                            />
                          )}

                          {elem.indexTemp === 5 && (
                            <div className="dropdown-icon">
                              <img src={DropdownIcon} alt="dropdown-icon" />
                            </div>
                          )}
                          <div className="wrapper-line"></div>
                        </div>
                      );
                    })}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientDetails;
