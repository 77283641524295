import { useLocation } from "react-router-dom";
import GreenColorSVG from "../../../pages/Comms/Data/GreenColorSVG";
import TrafficLights from "../../CommonModal/Components/TrafficLights";
import { Editor } from "react-draft-wysiwyg";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../Controller/redux/Hooks";
import { getStaffThunk } from "../../../../Controller/redux/features/StaffFeature/staffSlice";
import { useEffect, useState } from "react";
import CreateComms from "../../../pages/Comms/Component/CreateCommsSection/Index";

const CommsForm = ({
  trafficColors,
  setShowLights,
  showLights,extraType,
  isCommsActive,
  setTrafficColors,
  setIsSubTaskOpenComms = () => {},
  titleHub,
  setisThereAnyChanges = () => {},
  // dayBookDetails,
  // setDayBookDetails,
  editorState,
  handleEditorStateChange,
  onSaveComms,
  type,
}: any) => {
  const dispatch = useAppDispatch();

  //  @ts-ignore
  const dynamicStaffId = parseInt(localStorage.getItem("dynamicStaffId"));
  //  @ts-ignore
  const loggedInUserId = parseInt(localStorage.getItem("loggedInUserId"));

  const { getAllStaffData } = useAppSelector((state) => state?.staff);

  const [mentionUsersList, setMentionUsersList] = useState<any[]>([]);
  const [dayBookDetails, setDayBookDetails] = useState<any>({
    title: "",
    summary: "",
    assigned_to: "",
    tagged_user: [],
    date: "",
  });
  useEffect(() => {
    if (getAllStaffData?.data) {
      let replicateAllStaffRecords = JSON.parse(
        JSON.stringify(getAllStaffData?.data?.data)
      );

      let convertIntomentionArray = replicateAllStaffRecords
        .map((res: any) => ({
          text: `${res?.first_name || ""} ${res?.last_name || ""}`,
          value: `${res?.first_name || ""} ${res?.last_name || ""}`,
          url: res?.id,
        }))
        .filter((res: any) => res?.url !== loggedInUserId);
      setMentionUsersList(convertIntomentionArray);
    }
  }, [getAllStaffData]);

  // alert(isCommsActive)
  return (
    <>
      <div className={`${extraType ? " daybook_wrapper_globalCommsBox" :
        
        
        "day_book_wrapper_sticky"} `}>
        {/* <div className="day_book_wrapper_sticky_left_section">
          <div className="side_bar_section">
            {[...Array(3)].map((_, mapIndex) => (
              <div className="left-dots" key={mapIndex} />
            ))}
          </div>
          <GreenColorSVG
            color={trafficColors ? trafficColors : "#2AD400"}
            onClick={() => setShowLights(!showLights)}
            className="sticky_daybook_green_dot"
          />
          {showLights && (
            <TrafficLights
              className="sticky_daybook_traffic_lights"
              setShowLights={setShowLights}
              handleTrafficColors={(data: any) => {
                setTrafficColors(data);
                setShowLights(false);
              }}
              isSmall={false}
            />
          )}
        </div> */}
           <CreateComms
          //  isCommsActive={isCommsActive}
            type="globalComms"
            extraType={extraType}
            dayBookDetails={dayBookDetails}
            setDayBookDetails={setDayBookDetails}
            setIsSubTaskOpenComms={setIsSubTaskOpenComms}
            setisThereAnyChanges={setisThereAnyChanges}
          />
        {/* <div className="form_wrapper_daybook_sticky_notes">
          <div className="title_header_wrapper">
            <h1>{titleHub}</h1>
          </div>
          <div className="description_wrapper_sticky_notes">
            
          </div> */}
       
        {/* </div> */}
      </div>
    </>
  );
};

export default CommsForm;
