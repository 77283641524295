import { useState } from "react";
import TrafficLights from "../../../CommonModal/Components/TrafficLights";
import "../../Styles/SubTaskItem.css";
import ClientStatusCircleSvg from "../../../../../Model/Assets/SVG/WidgetVillage/ClientStatusCircleSvg";

const SubTaskItem = ({
  title,
  value1,
  value2,
  onChange,
  onChangeTemp,
  onClick,
  name1,
  name2,
  classNameInput,
  bgColor,
  type,
  htmlContent = "",
  commsCount,
}: any) => {
  const [showLights, setShowLights] = useState<boolean>(false);
  const [trafficColors, setTrafficColors] = useState<any>("");
  return (
    <div onClick={onClick} className="sub_tack_container">
      <div className="sub_task_inner">
        {/* <div className="sub_task_inner_wrapper"> */}
        <div className="sub_task_dots_container">
          {[...Array(3)].map((_, key) => (
            <span className="sub_task_dot" key={key}></span>
          ))}
        </div>
        <span
          onClick={() => setShowLights(!showLights)}
          style={{ backgroundColor: trafficColors ? trafficColors : bgColor }}
          className="sub_tack_circle"
        >
          &nbsp;
        </span>
        {showLights && (
          <TrafficLights
            className="traffic_light_sub_task"
            setShowLights={setShowLights}
            type="goals"
            handleTrafficColors={(data: any) => {
              setTrafficColors(data);
              setShowLights(false);
            }}
          />
        )}
        {/* </div> */}
        <div className="sub_task_col_2">
          <span className="sub_task_heading">{title}</span>
          <div className={`sub_task_input_container `}>
            {type === "commsGlobal" ? (
              <p
                style={{ fontSize: "14px" }}
                id="dangerouslySetInnerHTML"
                dangerouslySetInnerHTML={{
                  __html: htmlContent.slice(0, 40),
                }}
              ></p>
            ) : (
              <input
                className={`${classNameInput} sub_task_input`}
                value={value1}
                onChange={onChange}
                placeholder={
                  title?.toLowerCase()?.includes("blood")
                    ? "Systolic"
                    : "Enter Value"
                }
                name={name1}
              />
            )}
            {title === "Blood Pressure Standing" && (
              <input
                className="sub_task_input"
                value={title === "Blood Pressure Standing" ? value2 : ""}
                onChange={onChangeTemp}
                placeholder={
                  title?.toLowerCase()?.includes("standing")
                    ? "Diastolic"
                    : "Enter Value"
                }
                name={title === "Blood Pressure Standing" ? name2 : ""}
              />
            )}
            {title === "Blood Pressure Sitting" && (
              <input
                className="sub_task_input"
                value={title === "Blood Pressure Sitting" ? value2 : ""}
                onChange={onChangeTemp}
                placeholder={
                  title?.toLowerCase()?.includes("blood")
                    ? "Diastolic"
                    : "Enter Value"
                }
                name={title === "Blood Pressure Sitting" ? name2 : ""}
              />
            )}
          </div>
        </div>
      </div>
      {type === "commsGlobal" && (
        <ClientStatusCircleSvg count={commsCount ?? 0} />
      )}
    </div>
  );
};

export default SubTaskItem;
