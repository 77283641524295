export const providerColumns = [
  { header: "Client Name", accessor: "tenant_name" },
  { header: "Client Type", accessor: "tenant_type" },
  { header: "Admin Name", accessor: "providerName" },
  { header: "Admin Email", accessor: "admin_email" },
  { header: "Status", accessor: "status" },
  // { header: "Action", accessor: "providerActions" },
];

export const providerData = [
  {
    first_name: "Adnan",
    admin_name: "Kaushik",
    admin_email: "ks@gmail.com",
    mobile: "8888000000",
    status: 1,
  },
];

export const providerDetailData = [{ name: "Adnan" }];

export const dummyArrProviders = [
  {
    UpperTitle: "Provider Name:",
    LowerTitle: "",
    placeHolder: "Enter Name",
  },
  {
    UpperTitle: "Provider Token:",
    LowerTitle: "",
    placeHolder: "Enter Token",
  },
  {
    UpperTitle: "Business ID:",
    LowerTitle: "",
    placeHolder: "Enter Business ID",
  },
  {
    UpperTitle: "Import Data:",
    LowerTitle: "",
    placeHolder: "Enter Data",
  },
  {
    UpperTitle: "API Base Url:",
    LowerTitle: "",
    placeHolder: "Enter Base URL",
  },
  {
    UpperTitle: "Provider Type:",
    LowerTitle: "",
    placeHolder: "Enter Providers Type",
  },
];
