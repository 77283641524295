import "./Styles/Villages.css";

import { Fragment, useCallback, useEffect, useMemo, useState } from "react";

import {
  useAppDispatch,
  useAppSelector,
} from "../../../Controller/redux/Hooks";

import { getClientsThunk } from "../../../Controller/redux/features/ClientFeature/clientsSlice";
import {
  getLastUpdateClientThunk,
  getRACDetailsThunk,
  getSilDetailsThunk,
  getStatusStatsProspectsThunk,
  getStatusStatsThunk,
} from "../../../Controller/redux/features/VillageFeature/villageSlice";
import RightArrowSvg from "../../../Model/Assets/SVG/WidgetVillage/RightArrowSvg";
import ClientStatus from "../../components/ClientStatus/ClientStatus";
import CircularNoteTrigger from "../../components/CircularNoteTrigger/CircularNoteTrigger";
// import FilterSVG from "./Data/FilterSVG";
import QuestionMarkSVG from "./Data/QuestionMarkSVG";
import CommonUpperBox from "../../components/UpperBox/CommonHeader";
import CommonQuickMenu from "../../components/CommonQuickMenu/CommonQuickMenu";
import { statusMapClient } from "../../../Model/utils/Helper/statusMap";
import CommonReportingWidget from "../../../View/components/ReportingWidget/ReportingWidget";
import {Spinner} from "@chakra-ui/react";
import React from "react";

const Rac = ({ sil }: any) => {
  const dispatch = useAppDispatch();

  // @ts-ignore
  const dynamicClientId = parseInt(localStorage.getItem("dynamicClientId"));
  // @ts-ignore
  const dynamicLocationId = parseInt(localStorage.getItem("dynamicLocationId"));
  // @ts-ignore
  const dynamicProspectId = parseInt(localStorage.getItem("dynamicProspectId"));

  const { dynamicColor, hasSil, domainDetail } = useAppSelector(
    (state: { common: any }) => state?.common
  );
  const [isLoading, setisLoading] = useState(false);

  const b2bCondition = domainDetail?.businessmodel_type === "B2B";
  const { getRACDetails } = useAppSelector((state) => state.villages);
  useEffect(() => {
    dispatch(getClientsThunk({ dynamicClientId }));
    setisLoading(true);
    dispatch(getLastUpdateClientThunk(dynamicClientId)).then((res: any) => {
      if (res.payload.status === "success") {
        setisLoading(false);
      } else {
        setisLoading(false);
      }
    });
    dispatch(
      getRACDetailsThunk({
        type: dynamicClientId,
      })
    );
  }, [dynamicClientId]);

  useEffect(() => {
    dispatch(
      getSilDetailsThunk({
        type: dynamicClientId,
        dynamicLocationType: dynamicLocationId,
      })
    );
    dispatch(
      getStatusStatsThunk({ dynamicId: dynamicClientId, status: "1,2,3,5" })
    );
    dispatch(
      getStatusStatsProspectsThunk({
        dynamicId: dynamicProspectId,
        status: "12,13,4",
      })
    );
  }, []);

  const transformObjectToArray = (dataObject: any) => {
    const dataArray = [];

    for (const key in dataObject) {
      if (dataObject.hasOwnProperty(key)) {
        const obj = {
          name: key,
          data: dataObject[key],
        };
        dataArray.push(obj);
      }
    }

    return dataArray;
  };

  const transformedArray = transformObjectToArray(getRACDetails?.data);

  const { getLastUpdatedClients } = useAppSelector(
    (state: { villages: any }) => state?.villages
  );

  const getAllClientsDataThunk = useAppSelector(
    (state: { people: { allData: any } }) => state?.people?.allData
  );

  const [stageTwoToGoalsModal, setstageTwoToGoalsModal] = useState(false);

  const { getStatusStats, getStatusStatsProspect } = useAppSelector(
    (state) => state?.villages
  );

  let barArr: any[] = [];
  if (Array.isArray(getStatusStats.data)) {
    for (let item of getStatusStats.data) {
      if (item.status === "5") {
        // @ts-ignore
        let itemAtIndexOne = parseInt(barArr[1] || 0);
        let countCurrent = parseInt(item.count);
        let total = itemAtIndexOne + countCurrent;

        barArr.splice(1, 1, total);
      } else {
        barArr.push(parseInt(item.count));
      }
    }
  }

  const [prospectStatusRecordsArr, setProspectStatusRecordsArr] = useState<
    any[]
  >([]);

  useEffect(() => {
    if (
      getStatusStatsProspect &&
      getStatusStatsProspect?.data &&
      getStatusStatsProspect?.data?.lenght !== 0
    ) {
      let totalCount = getStatusStatsProspect?.data?.reduce(
        (sum: any, obj: any) => sum + obj?.count,
        0
      );
      const duplicateProspectStatusArr: any[] = getStatusStatsProspect?.data
        ? [...getStatusStatsProspect?.data]
        : [];
      duplicateProspectStatusArr.push({
        status: "newStatus", // Replace with the desired status value
        count: totalCount,
      });
      setProspectStatusRecordsArr(duplicateProspectStatusArr);
    }
  }, [getStatusStatsProspect]);

  const VillageTabArr = useMemo(() => {
    return [
      {
        chartWidth: "75%",
        title: "Status",
        data_1: barArr || [],
        chartType: "bar",
        heading: "By Location ",
        labels: ["Active", "On Leave", "Hospital"],
        color: "#97D94A",
        statusData: [
          { data: "At Home", color: "#6AE24C" },
          { data: "On Leave", color: "#FFFF5B" },
          { data: "Hospital", color: "#d99c23" },
        ],
        dates_X: getStatusStats?.data?.map(
          (elem: any) => statusMapClient[elem.status]?.label
        ),
        displayX: true,
        displayY: true,
        name: "Clients",
        providerName: getStatusStats?.provider_name,
      },
      {
        chartWidth: "65%",
        chartHeight: "152px",
        title: "Status",
        data_1: prospectStatusRecordsArr?.map((elem: any) => elem.count),
        chartType: "bar",
        heading: "By Status",
        labels: ["Open", "In Progress", "Waitlist", "Total"],
        color: "#97D94A",
        displayX: true,
        displayY: true,
        statusData: [
          { data: "Open", color: "#6AE24C" },
          { data: "In Progress", color: "#FFFF5B" },
          { data: "Waitlist", color: "rgb(217, 156, 35)" },
          { data: "Total", color: "rgb(192, 70, 70)" },
        ],
        dates_X: prospectStatusRecordsArr?.map(
          (elem: any) => statusMapClient[elem.status]?.label
        ),
        name: "Prospects",
        providerName: getStatusStatsProspect?.provider_name,
      },
    ];
  }, [getStatusStats, getStatusStatsProspect, prospectStatusRecordsArr]);

  const renderClinicalWidgets = useCallback(() => {
    return (
      <div className="village_widgets_wrapper">
        {VillageTabArr?.map((elem: any, index: number) => (
          <Fragment key={index}>
            <CommonReportingWidget
              isFromVillage={index === 1 ? false : true}
              statusData={elem?.statusData}
              labels={elem.labels}
              key={index}
              title={elem.heading}
              data_2={elem.data_2}
              data={elem.data_1}
              name={elem?.name}
              chartType={elem.chartType}
              chartLabel={"Status"}
              pointRadius={3}
              chartSize={"medium"}
              heading={elem.heading}
              chartWidth={elem.chartWidth}
              chartHeight={elem.chartHeight}
              pieChartBackgroundColors={[
                "#6AE24C",
                "#FFFF5B",
                "#d99c23",
                "rgb(100, 70, 70)",
              ]}
              borderWidth={1}
              // displayX={elem?.displayX}
              displayY={elem?.displayY}
              providerName={elem?.providerName}
            />
          </Fragment>
        ))}
      </div>
    );
  }, [VillageTabArr]);

  return (
    <>
      <div>
        {/* <UpperBox type="village" /> */}
        <CommonUpperBox
          title={"RAC"}
          type={"listing"}
          backgroundWithText
          text={"R D"}
          onClickPlus={() => {
            setstageTwoToGoalsModal(true);
          }}
        />

        <div className="sil_page_wrapper">
          <div className="sli_recent_chnages_present_section">
            {domainDetail?.has_recentchanges != 0 && (
              <div className="sil_recent_changes_wrapper_1">
                <div className="header_section_wrapper">
                  <div className="left_side_header_section">
                    <div className="section_1_wrapper">
                      {/* <GreenColorSVG key={"green_svg"} width="24" height="24" /> */}
                      <div className="title_wrapper">
                        <div className="text_with_backgroud_wrapper">
                          <h1>Recent Changes</h1>
                          {/* <PlusSVG /> */}
                        </div>
                      </div>
                      {/* <FilterSVG /> */}
                    </div>
                  </div>
                  {/* <div className="right_side_header_section">
                <p>Last update: Today 13:03</p>
              </div> */}
                  <div
                    className="locations_wrapper"
                    style={{
                      display: hasSil === 1 ? "" : "none",
                    }}
                  >
                    {[1, 2, 3].map((elem, index: number) => {
                      return (
                        <div
                          key={index}
                          className="sub_section_location_wrapper_1"
                        >
                          <span className="location_span">Location {elem}</span>
                          <div className="flex_with_gap">
                            {getAllClientsDataThunk?.data?.data
                              ?.slice(0, 2)
                              .map((elem: any, index: any) => (
                                <ClientStatus
                                  key={index}
                                  title={elem?.first_name}
                                  status={elem?.status}
                                  date={elem?.created_at}
                                  time={elem?.created_at}
                                  imageURL={elem?.profile_image_s3}
                                  backgroundWithText={` ${
                                    b2bCondition
                                      ? ""
                                      : `${
                                          elem?.first_name?.split("")[0] || ""
                                        } ${
                                          elem?.last_name?.split("")[0] || ""
                                        }`
                                  }`}
                                />
                              ))}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div
                  className="content_section_wrapper_sil"
                  style={{
                    height: "101%",
                  }}
                >
                  <div className="village_widget_container_update ">
                    {isLoading && !getLastUpdatedClients?.data?.length && (
                      <Spinner
                        position={"absolute"}
                        left={"40%"}
                        top={"100px"}
                      />
                    )}
                    {/* 
                  {
                    console.log(getLastUpdatedClients,"getLastUpdatedClients")
                  } */}
                    {getLastUpdatedClients?.data?.map(
                      (elem: any, index: number) => {
                        return (
                          <div key={index} className="village_update_wrapper">
                            <div
                              className="village_widget_updated"
                              style={{
                                // border: `1px dashed ${dynamicColor}`,
                                padding: "15px 5px",
                              }}
                              key={elem.id}
                            >
                              <ClientStatus
                                isRecentChanges={true}
                                title={elem?.first_name}
                                imageURL={elem?.profile_image_s3}
                                status={elem?.old_status}
                                date={elem?.old_status_time}
                                time={elem?.old_status_time}
                                backgroundWithText={` ${
                                  b2bCondition
                                    ? ""
                                    : `${
                                        elem?.first_name?.split("")[0] || ""
                                      } ${elem?.last_name?.split("")[0] || ""}`
                                }`}
                              />
                              <div className="village_widget_arrow">
                                <RightArrowSvg dynamicColor={dynamicColor} />
                              </div>
                              <ClientStatus
                                imageURL={elem?.profile_image_s3}
                                title={elem?.first_name}
                                status={elem?.current_status}
                                date={elem?.current_status_time}
                                time={elem?.current_status_time}
                                backgroundWithText={` ${
                                  b2bCondition
                                    ? ""
                                    : `${
                                        elem?.first_name?.split("")[0] || ""
                                      } ${elem?.last_name?.split("")[0] || ""}`
                                }`}
                              />
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
              </div>
            )}
            {/* <div className="report_section_wrapper">
              {renderClinicalWidgets()}
            </div> */}
          </div>
          <div className="sub_sil_wrapper">
            {transformedArray
              .sort((a: any, b: any) =>
                a?.room_name?.localeCompare(b.room_name)
              )

              .map((elem, index: number) => {
                return (
                  <Fragment key={index}>
                    <div key={index} className="sil_recent_changes_wrapper">
                      <div className="header_section_wrapper">
                        <div className="left_side_header_section">
                          <div className="section_1_wrapper">
                            <div className="title_wrapper">
                              <div className="text_with_backgroud_wrapper">
                                <h1>{elem?.name}</h1>
                                {/* <PlusSVG /> */}
                              </div>
                            </div>
                            {/* <FilterSVG /> */}
                          </div>
                          <div className="sub_title_wrapper"></div>
                        </div>
                      </div>
                      <div className="village_widget_container">
                        {Array.isArray(elem?.data) &&
                          [...elem?.data]
                            .sort((a: any, b: any) =>
                              a?.room_name?.localeCompare(b.room_name)
                            )
                            .map((elem: any, index: any) => (
                              <Fragment key={index}>
                                <ClientStatus
                                  key={index}
                                  isResidence={true}
                                  residence={elem?.room_name}
                                  title={elem?.first_name}
                                  status={elem?.status}
                                  date={elem?.created_at}
                                  time={elem?.created_at}
                                  imageURL={elem?.profile_image_s3}
                                  backgroundWithText={` ${
                                    b2bCondition
                                      ? ""
                                      : `${
                                          elem?.first_name?.split("")[0] || ""
                                        } ${
                                          elem?.last_name?.split("")[0] || ""
                                        }`
                                  }`}
                                />
                              </Fragment>
                            ))}
                      </div>
                      <div
                        className="owner_section_wrapper_sil"
                        style={{ display: "none" }}
                      >
                        <div className="right_side_owner_wrapper">
                          <QuestionMarkSVG />
                        </div>
                      </div>
                    </div>
                  </Fragment>
                );
              })}
          </div>
        </div>
        <CircularNoteTrigger 
              type="stickyHub"
              title="Comms"
              isOutside
              extraType="globalCommsBox"
          />
        {stageTwoToGoalsModal && (
          <CommonQuickMenu
            setshowGoalsModal={(value: any) => {}}
            mainType={"listing"}
            setstageTwoToGoalsModal={setstageTwoToGoalsModal}
          />
        )}
      </div>
    </>
  );
};

export default Rac;
