import React from "react";
import "../Styles/widget.css";

import { Fragment, useEffect, useRef, useState } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../Controller/redux/Hooks";

import { api } from "../../../../../Controller/services/api";
import {
  itemsArrContacts,
  itemsArrProspects,
  itemArrStaff,
  itemArrBusiness,
  itemsArrProspectsHomeCare,
  itemsArrProspectsVillage,
} from "./UniqueClientScreen/Data/WidgetDataArr";
import { toast } from "react-toastify";
import { useLocation, useParams } from "react-router-dom";
import {
  getClientsByFilterThunk,
  getClientsThunk,
  getUniqueClientById,
  getUniqueProviderClientById,
  resetClientData,
} from "../../../../../Controller/redux/features/ClientFeature/clientsSlice";
import {
  getProspectsByFilterThunk,
  getProspectsByIdThunk,
  getProspectsThunk,
  getProviderProspectsByIdThunk,
  resetProspectData,
  updateProspectsUserType,
} from "../../../../../Controller/redux/features/ProspectsFeature/prospectsSlice";
import {
  getStaffByFilterThunk,
  getStaffByIdThunk,
  getStaffThunk,
  resetStaffData,
} from "../../../../../Controller/redux/features/StaffFeature/staffSlice";
import {
  getContactByIdThunk,
  getContactsByFilterThunk,
  getContactsThunk,
  getProviderContactByIdThunk,
  resetContactData,
} from "../../../../../Controller/redux/features/ContactsFeature/contactsSlice";
import {
  getBusinessByFilterThunk,
  getBusinessByIdThunk,
  getBusinessThunk,
  getProviderBusinessByIdThunk,
  resetBusinessData,
} from "../../../../../Controller/redux/features/BusinessFeature/businessSlice";
import {
  getProviderTeamsByIdThunk,
  getTeamsByFilterThunk,
  getTeamsByIdThunk,
  getTeamsThunk,
  resetTeamsData,
} from "../../../../../Controller/redux/features/TeamsFeature/teamsSlice";
import { formatDateStatusApi } from "../../../../../Model/utils/Helper/formatDate";
import {
  getLocationUserByFilterThunk,
  getLocationUserThunk,
  getLocationsUserByIdThunk,
  resetLocationData,
} from "../../../../../Controller/redux/features/LocationFeature/locationSlice";
import {
  getProvidersByFilterThunk,
  getProvidersThunk,
  getUniqueProviderThunk,
  resetProvidersData,
} from "../../../../../Controller/redux/features/ProviderFeature/providerSlice";
import {
  getBillingRule,
  getBillingRuleDetailsById,
} from "../../../../../Controller/redux/features/RefDataFeature/BillingRuleFeature/billingruleSlice";
import { getServiceTypeDetailsById } from "../../../../../Controller/redux/features/RefDataFeature/ServiceTypeFeature/serviceTypeSlice";
import { getExpenseTypeDetailsId } from "../../../../../Controller/redux/features/RefDataFeature/ExpenseTypeFeature/expensetypeSlice";
import { trackUserClickThunk } from "../../../../../Controller/redux/features/AuditFeature/auditSlice";

const WidgetItems = ({
  position,
  type,
  hasUserIdFromListingTable,
  setOpenStatusWidget,
  setShowStatusWidget,
  setOpenWidgetProspects,
  setOpenWidgetContacts,
  setOpenWidgetStaff,
  setOpenWidgetBusiness,
  setOpenWidgetTeams,
  allClientsDataToTrackClickOfStatusClick,
  setAllClientsDataToTrackClickOfStatusClick,
  showStatusWidget,
  handleCallBack,
  index,
  userTypeDynamicId,
  itemsArr,
}: any) => {

  const dispatch = useAppDispatch();
  const { id, pro_id }: any = useParams();
  const [clientId, setclientId] = useState("");
  const [providerId, setProviderId] = useState("");

  useEffect(() => {
    if (id) {
      let realId = window.atob(id);
      setclientId(realId);
      if (pro_id) {
        setProviderId(window?.atob(pro_id));
      }
    }
  }, [id]);

  const getClientTypeId = useAppSelector(
    (state) => state?.people?.setUserTypeIdForClient
  );
  const { LocationPage } = useAppSelector((state) => state.location);
  const { TeamsPage } = useAppSelector((state) => state.teams);
  const { BusinessPage } = useAppSelector((state) => state.business);
  const { ContactPage } = useAppSelector((state) => state.contacts);
  const { ClientPage } = useAppSelector((state) => state.people);
  const { staffPage } = useAppSelector((state) => state.staff);
  const { ProspectPageFilter } = useAppSelector((state) => state.prospects);
  const { providersPage } = useAppSelector((state) => state?.providers);
  // @ts-ignore
  const dynamicProspectsId = parseInt(
    // @ts-ignore
    localStorage.getItem("dynamicProspectId")
  );
  // @ts-ignore
  const dynamicStaffId = parseInt(localStorage.getItem("dynamicStaffId"));
  // @ts-ignore
  const dynamicContactsId = parseInt(localStorage.getItem("dynamicContactId"));
  // @ts-ignore
  const dynamicBusinessId = parseInt(localStorage.getItem("dynamicBusinessId"));
  // @ts-ignore
  const dynamicTeamsId = parseInt(localStorage.getItem("dynamicTeamsId"));
  // @ts-ignore
  const dynamicClientId = parseInt(localStorage.getItem("dynamicClientId"));
  // @ts-ignore
  const dynamicLocationId = parseInt(localStorage.getItem("dynamicLocationId"));

  const containerRef = useRef<any>(null);
  const prospectsContainerRef = useRef<any>(null);
  const staffContainerRef = useRef<any>(null);

  const [updateStatusDetails, setupdateStatusDetails] = useState<any>({
    user_id: clientId,
    status: "",
    type: getClientTypeId?.id,
  });

  const [dynamiClientId, setDynamicClientId] = useState<any>(null);

  useEffect(() => {
    if (getClientTypeId) {
      setDynamicClientId(getClientTypeId?.id);
    }
  }, [dynamiClientId]);
  const [showWidgetBox, setshowWidgetBox] = useState(false);
  const [nameSelected, setnameSelcected] = useState<any>(1);

  const dynamicColor = useAppSelector((state) => state?.common?.dynamicColor);

  const [selectedItem, setSelectedItem] = useState<any>(null);

  const initialData = {
    status_date_from: "",
    status_date_to: "",
    status_time_from: "",
    status_time_to: "",
    status_contact: "",
  };

  const [statusData, setStatusData] = useState<any>(initialData);
  const [controlledStatusProspects, setcontrolledStatusProspects] =
    useState<any>([]);

  const providerType = useAppSelector(
    (state) => state?.common?.refetchCurrentUser?.data?.provider_type
  );

  // const providerType = "Home Care";

  useEffect(() => {
    // @ts-ignore
    if (providerType === "") {
      setcontrolledStatusProspects(itemsArrProspects);
      // @ts-ignore
    } else if (providerType === "Home Care") {
      setcontrolledStatusProspects(itemsArrProspectsHomeCare);
      // @ts-ignore
    } else if (providerType === "Village") {
      // @ts-ignore
      setcontrolledStatusProspects(itemsArrProspectsVillage);
    } else {
      setcontrolledStatusProspects(itemsArrProspects);
    }
  }, [providerType]);

  const handleStatusWidgetBox = (name: string | any) => {
    const selectedItem = itemsArr.find(
      (element: any) => element.type.toUpperCase() == name.toUpperCase()
    );
    setnameSelcected(selectedItem?.type);
    setselectedObj(selectedItem);

    setshowWidgetBox(true);
  };

  const [selectedObj, setselectedObj] = useState<any>(null);

  const pathname = useLocation()?.pathname;

  // const handleStatusWidgetData = (data: any) => {
  //   setStatusData((prevState: any) => ({
  //     ...prevState,
  //     status_date_from: data?.dateFrom
  //       ? formatDateStatusApi(data?.dateFrom)
  //       : "",
  //     status_date_to: data?.dateTo ? formatDateStatusApi(data?.dateTo) : "",
  //     status_time_from: data?.timeFrom,
  //     status_time_to: data?.timeTo,
  //     status_contact: data?.staffId,
  //   }));
  // };

  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////
  const handleItemClick = async (item: any, props: any) => {
    if (props === "clients") {
      const selectedItem = itemsArr.find(
        (element: any) => element.text.toUpperCase() == item.text.toUpperCase()
      );
      setSelectedItem(selectedItem);

      setnameSelcected(selectedItem?.text);
      setselectedObj(selectedItem);

      setshowWidgetBox(true);
    }
    if (props === "refData") {
      const selectedItem = [
        {
          id: 1,
          text: "Active",
          value: 1,
          color: "#97D94A",
          background_color: "#F8FFEF",
          status: 1,
        },
        {
          id: 2,
          text: "Inactive",
          value: 0,

          status: 0,
          color: "#EB5757",
          background_color: "#FFF0F0",
        },
      ].find(
        (element: any) => element.text.toUpperCase() == item.text.toUpperCase()
      );
      setSelectedItem(selectedItem);
      if (selectedItem) {
        if (type == "refDataScreen") {
          // alert("providersScreen Type isssss");
          if (pathname.includes("service-type")) {
            const response: any = await api.post(
              "/service_types/update_service_type_status",
              {
                service_type_id: clientId,
                status: selectedItem.status,
              }
            );
            if (response) {
              dispatch(getServiceTypeDetailsById(clientId));
              setOpenStatusWidget(false);
            }
          }

          if (pathname.includes("billing-set")) {
            const response: any = await api.post(
              "/billing_rule_sets/update_billing_rule_set_status",
              {
                billing_rule_set_id: clientId,
                status: selectedItem.status,
              }
            );
            if (response) {
              dispatch(getBillingRuleDetailsById(clientId));
              setOpenStatusWidget(false);
            }
          }

          if (pathname.includes("expense-type")) {
            const response: any = await api.post(
              "/expense_types/update_expense_type_status",
              {
                expense_type_id: clientId,
                status: selectedItem.status,
              }
            );
            if (response) {
              dispatch(getExpenseTypeDetailsId(clientId));
              setOpenStatusWidget(false);
            }
          }
        }
      }

      setOpenStatusWidget(false);

      setnameSelcected(selectedItem?.text);
      setselectedObj(selectedItem);

      setshowWidgetBox(true);
    } else if (props === "prospects") {
      // @ts-ignore
      const selectedItem = !providerType
        ? itemsArr.find(
            (element: any) =>
              element.text.toUpperCase() == item.text.toUpperCase()
          )
        : providerType === "Home Care"
        ? itemsArr.find(
            (element: any) =>
              element.text.toUpperCase() == item.text.toUpperCase()
          )
        : providerType === "Village"
        ? itemsArr.find(
            (element: any) =>
              element.text.toUpperCase() == item.text.toUpperCase()
          )
        : itemsArr.find(
            (element: any) =>
              element.text.toUpperCase() == item.text.toUpperCase()
          );

      setSelectedItem(selectedItem);

      setnameSelcected(selectedItem?.text);
      setselectedObj(selectedItem);

      setshowWidgetBox(true);
    } else if (props === "staff") {
      const selectedItem = itemsArr.find(
        (element: any) => element.text.toUpperCase() == item.text.toUpperCase()
      );
      setSelectedItem(selectedItem);
      setnameSelcected(selectedItem.text);
      setselectedObj(selectedItem);
      setshowWidgetBox(true);
    } else if (props === "contacts") {
      const selectedItem = itemsArr.find(
        (element: any) => element.text.toUpperCase() == item.text.toUpperCase()
      );
      setSelectedItem(selectedItem);

      setnameSelcected(selectedItem?.text);
      setselectedObj(selectedItem);
      setshowWidgetBox(true);
    } else if (props === "business") {
      const selectedItem = itemsArr.find(
        (element: any) => element.text.toUpperCase() == item.text.toUpperCase()
      );
      setSelectedItem(selectedItem);
      setnameSelcected(selectedItem?.text);
      setselectedObj(selectedItem);
      setshowWidgetBox(true);
    } else if (props === "locations") {
      const selectedItem = itemsArr.find(
        (element: any) => element.text.toUpperCase() == item.text.toUpperCase()
      );
      setSelectedItem(selectedItem);
      setnameSelcected(selectedItem?.text);
      setselectedObj(selectedItem);
      setshowWidgetBox(true);
    } else if (props === "teams") {
      const selectedItem = itemsArr.find(
        (element: any) => element.text.toUpperCase() == item.text.toUpperCase()
      );
      setSelectedItem(selectedItem);

      setnameSelcected(selectedItem?.text);
      setselectedObj(selectedItem);
      setshowWidgetBox(true);
    } else if (props === "providers") {
      const selectedItem = itemArrBusiness.find(
        (element: any) => element.type.toUpperCase() == item.type.toUpperCase()
      );
      setSelectedItem(selectedItem);

      setnameSelcected(selectedItem?.type);
      setselectedObj(selectedItem);
      setshowWidgetBox(true);
    }
  };

  const handleClickOutside = async (event?: any) => {
    setshowWidgetBox(false);
    setOpenStatusWidget(false);

    // // console.log("hererererererere", type);

    if (pathname.includes("clients") || type == "clientScreen") {
      if (allClientsDataToTrackClickOfStatusClick) {
        let spreadRows = [...allClientsDataToTrackClickOfStatusClick];
        spreadRows[index] = false;
        setAllClientsDataToTrackClickOfStatusClick(spreadRows);
      } else {
        setOpenStatusWidget(false);
        setShowStatusWidget(false);
      }
    } else if (pathname.includes("prospects") || type == "prospectsScreen") {
      if (allClientsDataToTrackClickOfStatusClick) {
        let spreadRows = [...allClientsDataToTrackClickOfStatusClick];
        spreadRows[index] = false;
        setAllClientsDataToTrackClickOfStatusClick(spreadRows);
      } else {
        setOpenStatusWidget(false);
        setOpenWidgetProspects(false);
      }
    } else if (pathname.includes("contacts") || type == "contactsScreen") {
      if (allClientsDataToTrackClickOfStatusClick) {
        let spreadRows = [...allClientsDataToTrackClickOfStatusClick];
        spreadRows[index] = false;
        setAllClientsDataToTrackClickOfStatusClick(spreadRows);
      } else {
        setOpenStatusWidget(false);
        setOpenWidgetContacts(false);
      }
    } else if (pathname.includes("staff") || type == "staffScreen") {
      if (allClientsDataToTrackClickOfStatusClick) {
        let spreadRows = [...allClientsDataToTrackClickOfStatusClick];
        spreadRows[index] = false;
        setAllClientsDataToTrackClickOfStatusClick(spreadRows);
      } else {
        setOpenStatusWidget(false);
        setOpenWidgetProspects(false);
      }
    } else if (pathname.includes("business")) {
      if (allClientsDataToTrackClickOfStatusClick) {
        let spreadRows = [...allClientsDataToTrackClickOfStatusClick];
        spreadRows[index] = false;
        setAllClientsDataToTrackClickOfStatusClick(spreadRows);
      } else {
        setOpenStatusWidget(false);
        setOpenWidgetBusiness(false);
      }
    } else if (pathname.includes("locations")) {
      if (allClientsDataToTrackClickOfStatusClick) {
        let spreadRows = [...allClientsDataToTrackClickOfStatusClick];
        spreadRows[index] = false;
        setAllClientsDataToTrackClickOfStatusClick(spreadRows);
      } else {
        setOpenStatusWidget(false);
        setOpenWidgetBusiness(false);
      }
    } else if (pathname.includes("teams")) {
      if (allClientsDataToTrackClickOfStatusClick) {
        let spreadRows = [...allClientsDataToTrackClickOfStatusClick];
        spreadRows[index] = false;
        setAllClientsDataToTrackClickOfStatusClick(spreadRows);
      } else {
        setOpenStatusWidget(false);
        setOpenWidgetTeams(false);
      }
    } else if (pathname.includes("teams")) {
      if (allClientsDataToTrackClickOfStatusClick) {
        let spreadRows = [...allClientsDataToTrackClickOfStatusClick];
        spreadRows[index] = false;
        setAllClientsDataToTrackClickOfStatusClick(spreadRows);
      } else {
        setOpenStatusWidget(false);
        setOpenWidgetTeams(false);
      }
    }

    if (selectedItem) {
      if (type == "providersScreen") {
        const response: any = await api.post(
          "/providers/update_provider_status",
          {
            provider_id: hasUserIdFromListingTable
              ? hasUserIdFromListingTable
              : clientId,
            status: selectedItem.status,
          }
        );

        if (response) {
          dispatch(resetProvidersData());
          // dispatch(getProvidersThunk());
          dispatch(
            getProvidersByFilterThunk({
              filterColumn: "",
              filterValue: "",
              searchVal: "",
              sortColumn: "",
              sortType: "",
              providersPage,
              status: "",
            })
          );
          dispatch(
            getUniqueProviderThunk({
              id: hasUserIdFromListingTable
                ? hasUserIdFromListingTable
                : clientId,
            })
          );

          toast.success("Status updated successfully!", {
            progressStyle: { backgroundColor: dynamicColor },
            icon: (
              <span
                style={{
                  marginRight: "8px",
                  borderRadius: "50%",
                  width: "7rem",
                  height: "35px",
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: dynamicColor,
                  backgroundColor: "white",
                  fontSize: "1.5rem",
                  border: `3px solid ${dynamicColor}`,
                }}
              >
                ✓
              </span>
            ),
          });
        }
        return;
      } else {
        try {
          const response: any = await api.post("/users/update_user_status", {
            user_id: hasUserIdFromListingTable
              ? hasUserIdFromListingTable
              : clientId,
            status: selectedItem.value,
            type: userTypeDynamicId
              ? userTypeDynamicId
              : pathname?.includes("clients") || type == "clientScreen"
              ? dynamicClientId
              : pathname?.includes("prospects") || type == "prospectsScreen"
              ? dynamicProspectsId
              : pathname.includes("staff") || type == "staffScreen"
              ? dynamicStaffId
              : pathname?.includes("contacts") || type == "contactsScreen"
              ? dynamicContactsId
              : pathname?.includes("business")
              ? dynamicBusinessId
              : pathname?.includes("teams")
              ? dynamicTeamsId
              : pathname?.includes("locations")
              ? dynamicLocationId
              : "",
            ...statusData,
            tenant_id: providerId,
          });
          if (pathname?.includes("prospects") || type == "prospectsScreen") {
            if (selectedItem.text == "Make Client") {
              let responeUpdateUserType = await dispatch(
                updateProspectsUserType({
                  user_id: hasUserIdFromListingTable
                    ? hasUserIdFromListingTable
                    : clientId,
                  type: dynamicProspectsId,
                  new_type: dynamicClientId,
                })
              );
              if (responeUpdateUserType?.payload?.status === "success") {
                toast.success("Make Client Successfully!", {
                  progressStyle: { backgroundColor: dynamicColor },
                  icon: (
                    <span
                      style={{
                        marginRight: "8px",
                        borderRadius: "50%",
                        width: "7rem",
                        height: "35px",
                        textAlign: "center",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: dynamicColor,
                        backgroundColor: "white",
                        fontSize: "1.5rem",
                        border: `3px solid ${dynamicColor}`,
                      }}
                    >
                      ✓
                    </span>
                  ),
                });
              }
            }
          }

          if (pathname.includes("clients") || type == "clientScreen") {
            dispatch(getUniqueClientById({ dynamicClientId, clientId }));
            dispatch(resetClientData());
            dispatch(
              getClientsByFilterThunk({
                filterColumn: "",
                filterValue: "",
                searchVal: "",
                sortColumn: "",
                sortType: "",
                ClientPage,
                dynamicId: dynamicClientId,
                status: "1,2,3,4,5",
              })
            );
          } else if (
            pathname.includes("prospects") ||
            type == "prospectsScreen"
          ) {
            dispatch(
              getProspectsByIdThunk({ typeId: dynamicProspectsId, clientId })
            );
          } else if (
            pathname.includes("contacts") ||
            type == "contactsScreen"
          ) {
            dispatch(getContactByIdThunk({ dynamicContactsId, clientId }));
            dispatch(resetContactData());
            dispatch(
              getContactsByFilterThunk({
                filterColumn: "",
                filterValue: "",
                searchVal: "",
                sortColumn: "",
                sortType: "",
                ContactPage,
                dynamicId: dynamicContactsId,
                status: "1",
              })
            );
          } else if (pathname.includes("staff") || type == "staffScreen") {
            dispatch(getStaffByIdThunk({ dynamicStaffId, clientId }));
            dispatch(resetStaffData());
            dispatch(
              getStaffByFilterThunk({
                filterColumn: "",
                filterValue: "",
                searchVal: "",
                sortColumn: "",
                sortType: "",
                staffPage,
                dynamicId: dynamicStaffId,
                status: "1,2,7",
              })
            );
          } else if (pathname.includes("business")) {
            dispatch(resetBusinessData());
            dispatch(
              getBusinessByIdThunk({
                dynamicId: dynamicBusinessId,
                businessId: clientId,
              })
            );
            dispatch(
              getBusinessByFilterThunk({
                filterColumn: "",
                filterValue: "",
                searchVal: "",
                sortColumn: "",
                sortType: "",
                BusinessPage,
                dynamicId: dynamicBusinessId,
                status: "1",
              })
            );
          } else if (pathname.includes("locations")) {
            dispatch(resetLocationData());
            dispatch(
              getLocationsUserByIdThunk({
                dynamicId: dynamicLocationId,
                locationId: clientId,
              })
            );
            dispatch(
              getLocationUserByFilterThunk({
                filterColumn: "",
                filterValue: "",
                searchVal: "",
                sortColumn: "",
                sortType: "",
                LocationPage,
                dynamicId: dynamicLocationId,
                status: "1",
              })
            );
          } else if (pathname.includes("teams")) {
            dispatch(resetTeamsData());
            dispatch(
              getTeamsByIdThunk({
                dynamicId: dynamicTeamsId,
                teamsId: clientId,
              })
            );
            dispatch(
              getTeamsByFilterThunk({
                filterColumn: "",
                filterValue: "",
                searchVal: "",
                sortColumn: "",
                sortType: "",
                TeamsPage,
                dynamicId: dynamicTeamsId,
                status: "1",
              })
            );
          }
          //  else if (pathname.includes("teams")) {
          // }

          // dispatch(
          //   getProspectsByIdThunk({ typeId: dynamicProspectsId, clientId })
          // );
          // dispatch(getStaffByIdThunk({ dynamicStaffId, clientId }));
          // dispatch(getContactByIdThunk({ dynamicContactsId, clientId }));
          // dispatch(getClientsThunk({ dynamicClientId }));
          // dispatch(getLocationUserThunk(dynamicLocationId));
          // dispatch(getStaffThunk({ dynamicStaffId }));
          // dispatch(getBusinessThunk(dynamicBusinessId));
          // dispatch(resetTeamsData());
          // dispatch(
          //   getTeamsByFilterThunk({
          //     filterColumn: "",
          //     filterValue: "",
          //     searchVal: "",
          //     sortColumn: "",
          //     sortType: "",
          //     TeamsPage,
          //     dynamicId: dynamicTeamsId,
          //     status: "1",
          //   })
          // );
          // dispatch(resetBusinessData());
          // dispatch(
          //   getBusinessByFilterThunk({
          //     filterColumn: "",
          //     filterValue: "",
          //     searchVal: "",
          //     sortColumn: "",
          //     sortType: "",
          //     BusinessPage,
          //     dynamicId: dynamicBusinessId,
          //     status: "1",
          //   })
          // );
          // dispatch(resetLocationData());
          // dispatch(
          //   getLocationUserByFilterThunk({
          //     filterColumn: "",
          //     filterValue: "",
          //     searchVal: "",
          //     sortColumn: "",
          //     sortType: "",
          //     LocationPage,
          //     dynamicId: dynamicLocationId,
          //     status: "1",
          //   })
          // );
          // dispatch(resetStaffData());
          // dispatch(
          //   getStaffByFilterThunk({
          //     filterColumn: "",
          //     filterValue: "",
          //     searchVal: "",
          //     sortColumn: "",
          //     sortType: "",
          //     staffPage,
          //     dynamicId: dynamicStaffId,
          //     status: "1",
          //   })
          // );
          // dispatch(getProspectsThunk({ dynamicProspectsId }));
          // dispatch(getTeamsThunk(dynamicTeamsId));
          // dispatch(getProspectsThunk({ dynamicProspectsId }));
          // dispatch(resetContactData());
          // dispatch(
          //   getContactsByFilterThunk({
          //     filterColumn: "",
          //     filterValue: "",
          //     searchVal: "",
          //     sortColumn: "",
          //     sortType: "",
          //     ContactPage,
          //     dynamicId: dynamicContactsId,
          //     status: "1",
          //   })
          // );
          // dispatch(resetClientData());
          // dispatch(
          //   getClientsByFilterThunk({
          //     filterColumn: "",
          //     filterValue: "",
          //     searchVal: "",
          //     sortColumn: "",
          //     sortType: "",
          //     ClientPage,
          //     dynamicId: dynamicClientId,
          //     status: "1,2,3,4,5",
          //   })
          // );
          // dispatch(
          //   getLocationsUserByIdThunk({
          //     dynamicId: dynamicLocationId,
          //     locationId: clientId,
          //   })
          // );
          // dispatch(
          //   getBusinessByIdThunk({
          //     dynamicId: dynamicBusinessId,
          //     businessId: clientId,
          //   })
          // );
          // dispatch(
          //   getTeamsByIdThunk({ dynamicId: dynamicTeamsId, teamsId: clientId })
          // );
          let dynamicContactId;
          dynamicContactId = dynamicContactsId;
          // dispatch(getContactsThunk({ dynamicContactId }));

          dispatch(
            trackUserClickThunk({
              desc: `Updated Status`,
              screen: `Status update`,
            })
          );
          if (response) {
            toast.success("Status updated successfully!", {
              style: {},
              progressStyle: { backgroundColor: dynamicColor },
              icon: (
                <span
                  style={{
                    marginRight: "8px",
                    borderRadius: "50%",
                    width: "7rem",
                    height: "35px",
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: dynamicColor,
                    backgroundColor: "white",
                    fontSize: "1.5rem",
                    border: `3px solid ${dynamicColor}`,
                  }}
                >
                  ✓
                </span>
              ),
            });

            dispatch(getUniqueClientById({ dynamiClientId, clientId }));
            if (
              pro_id &&
              pathname.includes("providers") &&
              pathname.includes("clients")
            ) {
              setTimeout(() => {
                dispatch(
                  getUniqueProviderClientById({
                    typeId: dynamicClientId,
                    clientId,
                    tenant_id: providerId,
                  })
                );
              }, 1000);
            }
            dispatch(
              getProspectsByIdThunk({ typeId: dynamicProspectsId, clientId })
            );
            if (
              pro_id &&
              pathname.includes("providers") &&
              pathname.includes("prospects")
            ) {
              setTimeout(() => {
                dispatch(
                  getProviderProspectsByIdThunk({
                    typeId: dynamicProspectsId,
                    clientId,
                    tenant_id: providerId,
                  })
                );
              }, 500);
            }
            if (
              pro_id &&
              pathname.includes("providers") &&
              pathname.includes("business")
            ) {
              setTimeout(() => {
                dispatch(
                  getProviderBusinessByIdThunk({
                    typeId: dynamicBusinessId,
                    clientId,
                    tenant_id: providerId,
                  })
                );
              }, 500);
            }
            if (
              pro_id &&
              pathname.includes("providers") &&
              pathname.includes("teams")
            ) {
              setTimeout(() => {
                dispatch(
                  getProviderTeamsByIdThunk({
                    typeId: dynamicTeamsId,
                    clientId,
                    tenant_id: providerId,
                  })
                );
              }, 500);
            }
            if (
              pro_id &&
              pathname.includes("providers") &&
              pathname.includes("business")
            ) {
              setTimeout(() => {
                dispatch(
                  getProviderContactByIdThunk({
                    typeId: dynamicContactsId,
                    clientId,
                    tenant_id: providerId,
                  })
                );
              }, 500);
            }
            dispatch(getStaffByIdThunk({ dynamicStaffId, clientId }));
            dispatch(getContactByIdThunk({ dynamicContactsId, clientId }));
            setOpenStatusWidget(false);

            dispatch(resetProspectData());
            setTimeout(() => {
              dispatch(
                getProspectsByFilterThunk({
                  filterColumn: "",
                  filterValue: "",
                  searchVal: "",
                  sortColumn: "",
                  sortType: "",
                  ProspectPageFilter,
                  dynamicId: dynamicProspectsId,
                  status: "1,4,5,6,8,9,10,11,13",
                })
              );
            }, 2000);
          } else {
            toast.error("An Error Occured!");
            setOpenStatusWidget(false);
          }
        } catch (error) {
          console.error("Error sending API request:", error);
        }
      }
    }
  };

  const handleSaveButtonClick = async (event: any) => {
    if (pathname.includes("clients") || type == "clientScreen") {
      setshowWidgetBox(false);
      if (allClientsDataToTrackClickOfStatusClick) {
        let spreadRows = [...allClientsDataToTrackClickOfStatusClick];
        spreadRows[index] = false;
        setAllClientsDataToTrackClickOfStatusClick(spreadRows);
      } else {
        // setShowStatusWidget(false);
        setOpenStatusWidget(false);
      }
    } else if (pathname.includes("prospects") || type == "prospectsScreen") {
      setshowWidgetBox(false);
      if (allClientsDataToTrackClickOfStatusClick) {
        let spreadRows = [...allClientsDataToTrackClickOfStatusClick];
        spreadRows[index] = false;
        setAllClientsDataToTrackClickOfStatusClick(spreadRows);
      } else {
        // setOpenWidgetProspects(false);
        setOpenStatusWidget(false);
      }
    } else if (pathname.includes("contacts") || type == "contactsScreen") {
      setshowWidgetBox(false);
      if (allClientsDataToTrackClickOfStatusClick) {
        let spreadRows = [...allClientsDataToTrackClickOfStatusClick];
        spreadRows[index] = false;
        setAllClientsDataToTrackClickOfStatusClick(spreadRows);
      } else {
        // setOpenWidgetContacts(false);
        setOpenStatusWidget(false);
      }
    } else if (pathname.includes("staff") || type == "staffScreen") {
      setshowWidgetBox(false);
      if (allClientsDataToTrackClickOfStatusClick) {
        let spreadRows = [...allClientsDataToTrackClickOfStatusClick];
        spreadRows[index] = false;
        setAllClientsDataToTrackClickOfStatusClick(spreadRows);
      } else {
        // setOpenWidgetProspects(false);
        setOpenStatusWidget(false);
      }
    } else if (pathname.includes("business")) {
      setshowWidgetBox(false);
      if (allClientsDataToTrackClickOfStatusClick) {
        let spreadRows = [...allClientsDataToTrackClickOfStatusClick];
        spreadRows[index] = false;
        setAllClientsDataToTrackClickOfStatusClick(spreadRows);
      } else {
        setOpenStatusWidget(false);
      }
    } else if (pathname.includes("locations")) {
      setshowWidgetBox(false);
      if (allClientsDataToTrackClickOfStatusClick) {
        let spreadRows = [...allClientsDataToTrackClickOfStatusClick];
        spreadRows[index] = false;
        setAllClientsDataToTrackClickOfStatusClick(spreadRows);
      } else {
        setOpenStatusWidget(false);
      }
    } else if (pathname.includes("teams")) {
      setshowWidgetBox(false);
      if (allClientsDataToTrackClickOfStatusClick) {
        let spreadRows = [...allClientsDataToTrackClickOfStatusClick];
        spreadRows[index] = false;
        setAllClientsDataToTrackClickOfStatusClick(spreadRows);
      } else {
        // setOpenWidgetTeams(false);
        setOpenStatusWidget(false);
      }
    }
    if (selectedItem) {
      try {
        const response: any = await api.post("/users/update_user_status", {
          user_id: hasUserIdFromListingTable
            ? hasUserIdFromListingTable
            : clientId,
          status: selectedItem.status,
          type:
            pathname?.includes("clients") || type == "clientScreen"
              ? dynamicClientId
              : pathname?.includes("prospects")
              ? dynamicProspectsId
              : pathname.includes("staff") || type == "staffScreen"
              ? dynamicStaffId
              : pathname?.includes("contacts") || type == "contactsScreen"
              ? dynamicContactsId
              : pathname?.includes("locations")
              ? dynamicLocationId
              : pathname?.includes("business")
              ? dynamicBusinessId
              : pathname?.includes("teams")
              ? dynamicTeamsId
              : "",
          ...statusData,
        });
        dispatch(getUniqueClientById({ dynamicClientId, clientId }));
        dispatch(
          getProspectsByIdThunk({ typeId: dynamicProspectsId, clientId })
        );
        dispatch(getStaffByIdThunk({ dynamicStaffId, clientId }));
        dispatch(getContactByIdThunk({ dynamicContactsId, clientId }));
        dispatch(getClientsThunk({ dynamicClientId }));
        dispatch(getStaffThunk({ dynamicStaffId }));
        dispatch(
          getBusinessByIdThunk({
            dynamicId: dynamicBusinessId,
            businessId: clientId,
          })
        );
        dispatch(
          getTeamsByIdThunk({
            dynamicId: dynamicTeamsId,
            teamsId: clientId,
          })
        );

        dispatch(getBusinessThunk(dynamicBusinessId));
        dispatch(getLocationUserThunk(dynamicLocationId));
        dispatch(getTeamsThunk(dynamicTeamsId));
        dispatch(getProspectsThunk({ dynamicProspectsId }));
        let dynamicContactId;
        dynamicContactId = dynamicContactsId;
        dispatch(getContactsThunk({ dynamicContactId }));
        dispatch(
          getLocationsUserByIdThunk({
            dynamicId: dynamicLocationId,
            locationId: clientId,
          })
        );

        if (response) {
          toast.success("Status updated successfully!", {
            style: {},
            progressStyle: { backgroundColor: dynamicColor },
            icon: (
              <span
                style={{
                  marginRight: "8px",
                  borderRadius: "50%",
                  width: "7rem",
                  height: "35px",
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: dynamicColor,
                  backgroundColor: "white",
                  fontSize: "1.5rem",
                  border: `3px solid ${dynamicColor}`,
                }}
              >
                ✓
              </span>
            ),
          });

          dispatch(getUniqueClientById({ dynamiClientId, clientId }));
          dispatch(
            getProspectsByIdThunk({ typeId: dynamicProspectsId, clientId })
          );
          dispatch(getStaffByIdThunk({ dynamicStaffId, clientId }));
          dispatch(getContactByIdThunk({ dynamicContactsId, clientId }));
          setOpenStatusWidget(false);
        } else {
          setOpenStatusWidget(false);
          toast.error("An Error Occurred!");
        }
      } catch (error) {
        console.error("Error sending API request:", error);
      }
    }
  };
  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////

  const handleClickOutsideWidget = async (e: any) => {
    const isOutsideClick =
      containerRef?.current && !containerRef?.current?.contains(e?.target);
    if (isOutsideClick) {
      setTimeout(() => {
        setshowWidgetBox(false);
        setOpenStatusWidget(false);
      }, 500);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutsideWidget);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideWidget);
    };
  }, [containerRef, clientId, getClientTypeId, statusData]);

  useEffect(() => {
    if (selectedItem) {
      handleClickOutside();
    }
  }, [selectedItem?.status, nameSelected]);

  const [inputCount, setInputCount] = useState(0);

  const handleAddInput = () => {
    setInputCount((prevCount) => prevCount + 1);
  };

  useEffect(() => {
    setupdateStatusDetails({
      user_id: clientId,
      status: selectedObj?.status,
      type: getClientTypeId?.id,
    });
  }, [clientId, getClientTypeId, selectedObj]);
  return (
    <Fragment>
      {type === "table" && (
        <>
          <div
            className="all-widget-box"
            style={{
              position: "absolute",
              right: "10.2%",
              top: position?.y,
            }}
          >
            <div></div>
            <div className={`status-widget-items `}>
              {itemsArr?.map((element: any, index: any) => {
                return (
                  <>
                    <div
                      className="status-widget-item"
                      style={{
                        backgroundColor: element.bgCol,
                        color: element.color,
                        fontSize: "12px",
                        outline:
                          element.type === nameSelected
                            ? `3px solid ${element.color}`
                            : `1px solid ${element.color}`,
                      }}
                      onClick={() => handleStatusWidgetBox(element.type)}
                    >
                      {element.type}
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </>
      )}
      {type === "clientScreen" && (
        <>
          <div
            ref={containerRef}
            className="all-widget-box"
            style={{
              position: "absolute",
              top: "135%",
              right: -10,
              zIndex: 1,
            }}
          >
            <div
              className={`status-widget-items ${
                hasUserIdFromListingTable ? "client_listing" : ""
              } `}
            >
              {itemsArr?.map((item: any, index: any) => {
                return (
                  <>
                    <div
                      className="status-widget-item"
                      style={{
                        backgroundColor: item.background_color,
                        color: item.color,
                        fontSize: "12px",
                        outline:
                          item.text === nameSelected
                            ? `3px solid ${item.color}`
                            : `1px solid ${item.color}`,
                      }}
                      onClick={() => handleItemClick(item, "clients")}
                    >
                      {item.text}
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </>
      )}
      {type === "prospectsScreen" && (
        <>
          <div
            ref={containerRef}
            className="all-widget-box"
            style={{
              position: "absolute",
              top: "125%",
              right: 0,
              zIndex: 1,
            }}
          >
            <div className="status-widget-box">
              {/* {showWidgetBox && (
                <StatusWidget
                  name={nameSelected}
                  setshowWidgetBox={setshowWidgetBox}
                  onClick={handleSaveButtonClick}
                  handleStatusWidgetData={handleStatusWidgetData}
                />
              )} */}
            </div>
            <div className="status-widget-items">
              {itemsArr?.map((item: any, index: any) => {
                return (
                  <>
                    <div
                      className="status-widget-item"
                      style={{
                        backgroundColor: item.background_color,
                        color: item.color,
                        fontSize: "12px",
                        outline:
                          item.text === nameSelected
                            ? `3px solid ${item.color}`
                            : `1px solid ${item.color}`,
                      }}
                      onClick={() => handleItemClick(item, "prospects")}
                    >
                      {item.text}
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </>
      )}
      {type === "staffScreen" && (
        <>
          <div
            ref={containerRef}
            className="all-widget-box"
            style={{
              position: "absolute",
              top: "125%",
              right: 0,
              zIndex: 1,
            }}
          >
            <div className="status-widget-box">
              {/* {showWidgetBox && (
                <StatusWidget
                  name={nameSelected}
                  setshowWidgetBox={setshowWidgetBox}
                  onClick={handleSaveButtonClick}
                  handleStatusWidgetData={handleStatusWidgetData}
                />
              )} */}
            </div>
            <div className="status-widget-items">
              {itemsArr?.map((item: any, index: any) => {
                return (
                  <>
                    <div
                      className="status-widget-item"
                      style={{
                        backgroundColor: item.background_color,
                        color: item.color,
                        fontSize: "12px",
                        outline:
                          item.text === nameSelected
                            ? `3px solid ${item.color}`
                            : `1px solid ${item.color}`,
                      }}
                      onClick={() => handleItemClick(item, "staff")}
                    >
                      {item.text}
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </>
      )}
      {type === "businessScreen" && (
        <>
          <div
            ref={containerRef}
            className="all-widget-box"
            style={{
              position: "absolute",
              top: "125%",
              right: 0,
              zIndex: 1,
            }}
          >
            <div className="status-widget-box">
              {/* {showWidgetBox && (
                <StatusWidget
                  name={nameSelected}
                  setshowWidgetBox={setshowWidgetBox}
                  onClick={handleSaveButtonClick}
                  handleStatusWidgetData={handleStatusWidgetData}
                />
              )} */}
            </div>
            <div className="status-widget-items">
              {itemsArr?.map((item: any, index: any) => {
                return (
                  <>
                    <div
                      className="status-widget-item"
                      style={{
                        backgroundColor: item.background_color,
                        color: item.color,
                        fontSize: "12px",
                        outline:
                          item.text === nameSelected
                            ? `3px solid ${item.color}`
                            : `1px solid ${item.color}`,
                      }}
                      onClick={() => handleItemClick(item, "business")}
                    >
                      {item.text}
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </>
      )}
      {type === "locationScreen" && (
        <>
          <div
            ref={containerRef}
            className="all-widget-box"
            style={{
              position: "absolute",
              top: "125%",
              right: 0,
              zIndex: 1,
            }}
          >
            <div className="status-widget-box">
              {/* {showWidgetBox && (
                <StatusWidget
                  name={nameSelected}
                  setshowWidgetBox={setshowWidgetBox}
                  onClick={handleSaveButtonClick}
                  handleStatusWidgetData={handleStatusWidgetData}
                />
              )} */}
            </div>
            <div className="status-widget-items">
              {itemsArr?.map((item: any, index: any) => {
                return (
                  <>
                    <div
                      className="status-widget-item"
                      style={{
                        backgroundColor: item.background_color,
                        color: item.color,
                        fontSize: "12px",
                        outline:
                          item.type === nameSelected
                            ? `3px solid ${item.color}`
                            : `1px solid ${item.color}`,
                      }}
                      onClick={() => handleItemClick(item, "locations")}
                    >
                      {item.text}
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </>
      )}
      {type === "teamsScreen" && (
        <>
          <div
            ref={containerRef}
            className="all-widget-box"
            style={{
              position: "absolute",
              top: "125%",
              right: 0,
              zIndex: 1,
            }}
          >
            <div className="status-widget-box">
              {/* {showWidgetBox && (
                <StatusWidget
                  name={nameSelected}
                  setshowWidgetBox={setshowWidgetBox}
                  onClick={handleSaveButtonClick}
                  handleStatusWidgetData={handleStatusWidgetData}
                />
              )} */}
            </div>
            <div className="status-widget-items">
              {itemsArr?.map((item: any, index: any) => {
                return (
                  <>
                    <div
                      className="status-widget-item"
                      style={{
                        backgroundColor: item.background_color,
                        color: item.color,
                        fontSize: "12px",
                        outline:
                          item.text === nameSelected
                            ? `3px solid ${item.color}`
                            : `1px solid ${item.color}`,
                      }}
                      onClick={() => handleItemClick(item, "teams")}
                    >
                      {item.text}
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </>
      )}

      {type === "providersScreen" && (
        <>
          <div
            ref={containerRef}
            className="all-widget-box"
            style={{
              position: "absolute",
              top: "125%",
              right: 0,
              zIndex: 1,
            }}
          >
            <div className="status-widget-box">
              {/* {showWidgetBox && (
                <StatusWidget
                  name={nameSelected}
                  setshowWidgetBox={setshowWidgetBox}
                  onClick={handleSaveButtonClick}
                  handleStatusWidgetData={handleStatusWidgetData}
                />
              )} */}
            </div>
            <div className="status-widget-items">
              {itemArrBusiness.map((item: any, index: any) => {
                return (
                  <>
                    <div
                      className="status-widget-item"
                      style={{
                        backgroundColor: item.bgCol,
                        color: item.color,
                        fontSize: "12px",
                        outline:
                          item.type === nameSelected
                            ? `3px solid ${item.color}`
                            : `1px solid ${item.color}`,
                      }}
                      onClick={() => handleItemClick(item, "providers")}
                    >
                      {item.type}
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </>
      )}
      {type === "contactsScreen" && (
        <>
          <div
            ref={containerRef}
            className="all-widget-box"
            style={{
              position: "absolute",
              top: "125%",
              right: 0,
              zIndex: 1,
            }}
          >
            <div className="status-widget-box">
              {/* {showWidgetBox && (
                <StatusWidget
                  name={nameSelected}
                  setshowWidgetBox={setshowWidgetBox}
                  onClick={handleSaveButtonClick}
                  handleStatusWidgetData={handleStatusWidgetData}
                />
              )} */}
            </div>
            <div className="status-widget-items">
              {itemsArr?.map((item: any, index: any) => {
                return (
                  <>
                    <div
                      className="status-widget-item"
                      style={{
                        backgroundColor: item.background_color,
                        color: item.color,
                        fontSize: "12px",
                        outline:
                          item.text === nameSelected
                            ? `3px solid ${item.color}`
                            : `1px solid ${item.color}`,
                      }}
                      onClick={() => handleItemClick(item, "contacts")}
                    >
                      {item.text}
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </>
      )}
      {type === "refDataScreen" && (
        <>
          <div
            ref={containerRef}
            className="all-widget-box"
            style={{
              position: "absolute",
              top: "125%",
              right: 0,
              zIndex: 1,
            }}
          >
            <div className="status-widget-items">
              {[
                {
                  id: 1,
                  text: "Active",
                  value: 1,
                  color: "#97D94A",
                  background_color: "#F8FFEF",
                  status: 1,
                },
                {
                  id: 2,
                  text: "Inactive",
                  value: 0,

                  status: 0,
                  color: "#EB5757",
                  background_color: "#FFF0F0",
                },
              ]?.map((item: any, index: any) => {
                return (
                  <>
                    <div
                      className="status-widget-item"
                      style={{
                        backgroundColor: item.background_color,
                        color: item.color,
                        fontSize: "12px",
                        outline:
                          item.text === nameSelected
                            ? `3px solid ${item.color}`
                            : `1px solid ${item.color}`,
                      }}
                      onClick={() => handleItemClick(item, "refData")}
                    >
                      {item.text}
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </>
      )}
      {type === "newForm" && (
        <>
          <div
            className="all-widget-box"
            style={{ position: "absolute", right: "0.10rem", top: "2.75rem" }}
          >
            <div
              className="status-widget-items"
              style={{ overflowY: "scroll" }}
            >
              <input
                type="text"
                className="status-widget-item input-widget-item"
                placeholder="Category 1"
              />
              <input
                type="text"
                className="status-widget-item input-widget-item"
                placeholder="Category 2"
              />
              <input
                type="text"
                className="status-widget-item input-widget-item"
                placeholder="Category 3"
              />
              {Array.from({ length: inputCount }, (_, index) => (
                <input
                  type="text"
                  className="status-widget-item input-widget-item"
                  placeholder={`Category ${index + 1}`}
                  style={{ width: "100%" }}
                  key={index}
                />
              ))}
              <button onClick={handleAddInput}>Add Another</button>
            </div>
          </div>
        </>
      )}
      {type === "associationTable" && (
        <>
          <div className="all-widget-box">
            <div className="status-widget-box"></div>
            <div className="status-widget-items">
              {itemsArr?.map((element: any, index: any) => {
                return (
                  <>
                    <div
                      className="status-widget-item"
                      style={{
                        backgroundColor: element.bgCol,
                        color: element.color,
                        fontSize: "12px",
                        outline:
                          element.type === nameSelected
                            ? `3px solid ${element.color}`
                            : `1px solid ${element.color}`,
                      }}
                      onClick={() => handleStatusWidgetBox(element.type)}
                    >
                      {element.type}
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </>
      )}
      {type === "clinicalTabClientsScreen" && (
        <>
          <div className="all-widget-box">
            <div className="status-widget-box">
              {/* {showWidgetBox && (
                <StatusWidget
                  name={nameSelected}
                  setshowWidgetBox={setshowWidgetBox}
                  onClick={handleSaveButtonClick}
                  handleStatusWidgetData={handleStatusWidgetData}
                />
              )} */}
            </div>
            <div className="status-widget-items">
              {itemsArr?.map((element: any, index: any) => {
                return (
                  <>
                    <div
                      className="status-widget-item"
                      style={{
                        backgroundColor: element.bgCol,
                        color: element.color,
                        fontSize: "12px",
                        outline:
                          element.type === nameSelected
                            ? `3px solid ${element.color}`
                            : `1px solid ${element.color}`,
                      }}
                      onClick={() => handleStatusWidgetBox(element.type)}
                    >
                      {element.type}
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </>
      )}
    </Fragment>
  );
};

export default WidgetItems;
