import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../../services/api";

const initialState: any = {
  getProspectsAssociationNames: [],
  isAssessmentFormOpenProspects: false,
  openFormByClick: false,
};

const documentProspectsSlice = createSlice({
  name: "documentProspectsSlice",
  initialState,
  reducers: {
    setIsAssessmentFormOpenProspects: (state) => {
      state.isAssessmentFormOpenProspects = true;
    },
    resetIsAssessmentFormOpenProspects: (state) => {
      state.isAssessmentFormOpenProspects = false;
    },
    setopenFormByClickProspects: (state) => {
      state.openFormByClick = true;
    },
    resetopenFormByClickProspects: (state) => {
      state.openFormByClick = false;
    },
  },
  extraReducers: (builder) => {
  },
});

export const {
  setIsAssessmentFormOpenProspects,
  resetIsAssessmentFormOpenProspects,
  setopenFormByClickProspects,
  resetopenFormByClickProspects,
} = documentProspectsSlice.actions;
export default documentProspectsSlice.reducer;
