import React, { Fragment, useEffect, useRef, useState } from "react";
import {
  getProspectEmailThunk,
  getProspectNameThunk,
  getProspectPhoneThunk,
  getProspectRefferThunk,
  getProspectStatusThunk,
  getProspectsByFilterThunk,
  getProspectsThunk,
  getUserRolesProspectsThunk,
  resetProspectData,
  resetProspectPageFilter,
  setProspectPageFilter,
  setUserTypeIdForProspects,
} from "../../../../Controller/redux/features/ProspectsFeature/prospectsSlice";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../Controller/redux/Hooks";

import CommonTable from "../../../components/CommonTable/InputTable";
import UpperBox from "../../../components/UpperBox/UpperBox";
import CircularNoteTrigger from "../../../components/CircularNoteTrigger/CircularNoteTrigger";
import CommonHeader from "../../../components/UpperBox/CommonHeader";
import CommonUpperBox from "../../../components/UpperBox/CommonHeader";
import CommonQuickMenu from "../../../components/CommonQuickMenu/CommonQuickMenu";
import Table from "../../../components/CommonTable/Table";
import { useNavigate } from "react-router-dom";
import {
  getStatusDetailsThunk,
  resetStatusDetails,
  setProfileTypeSticky,
} from "../../../../Controller/redux/features/CommonFeature/commonSlice";

const Prospectus = () => {
  // GOTO DETAILS WHEN PAGE LOADS EVERYTIME
  const navigate = useNavigate();
  useEffect(() => {
    localStorage.removeItem("activeTab");
  }, []);
  const getProspectsDataThunk = useAppSelector(
    (state) => state?.prospects?.getProspectsData
  );
  const dynamicProspectsId = parseInt(
    // @ts-ignore
    localStorage.getItem("dynamicProspectId")
  );
  // const isUserAdmin = user?.roles[0]?.desc?.toLowerCase();
  // const isAdminActive = isUserAdmin === "administrator";
  const { user } = useAppSelector((state) => state.common);
  const isAdminActive = user?.tenant?.id === user?.tenant?.parent_id;

  const tableRef = useRef();
  const prospectInnerRef = useRef();

  const dropdownRefProspects = useRef<any>();
  const svgParentRefProspects = useRef<any>();
  const [isDropdownOpenProspects, setIsDropdownOpenProspects] = useState(false);
  const [hasStatusWidgetOpen, setHasStatusWidgetOpen] = useState(false);

  const { selectedProspects, selectedSortValuesProspects, searchValProspects } =
    useAppSelector((state) => state?.prospects);
  const { filterIconColor } = useAppSelector((state) => state?.common);
  const handleClickRow = (e: any, id: any) => {
    // if (hasStatusWidgetOpen) {
    //   return;
    // }
    if (!isDropdownOpenProspects) {
      navigate(`/people/prospects/${window.btoa(id)}`);
    }
  };
  const handleClickOutsideProspects = (event: any) => {
    if (
      dropdownRefProspects.current &&
      !dropdownRefProspects.current.contains(event.target) &&
      svgParentRefProspects.current &&
      !svgParentRefProspects.current.contains(event.target)
    ) {
      setIsDropdownOpenProspects(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutsideProspects);
    return () => {
      document.removeEventListener("click", handleClickOutsideProspects);
    };
  }, []);

  const { ProspectPageFilter, ProspectFilterItems, loading, lastPage } =
    useAppSelector((state) => state?.prospects);

  useEffect(() => {
    dispatch(getProspectNameThunk(dynamicProspectsId));
    dispatch(getProspectEmailThunk(dynamicProspectsId));
    dispatch(getProspectPhoneThunk(dynamicProspectsId));
    dispatch(getProspectStatusThunk(dynamicProspectsId));
    dispatch(getProspectRefferThunk(dynamicProspectsId));
  }, [dynamicProspectsId]);

  // useEffect(() => {
  //   dispatch(getProspectsThunk({ dynamicProspectsId }));
  // }, []);

  // const onScroll = () => {
  //   if (prospectInnerRef.current) {
  //     const { scrollTop, scrollHeight, clientHeight } =
  //       prospectInnerRef.current;

  //     if (
  //       scrollTop + clientHeight + +5 >= scrollHeight &&
  //       ProspectPageFilter < lastPage
  //     ) {
  //       dispatch(setProspectPageFilter(ProspectPageFilter));
  //     }
  //   }
  // };

  useEffect(() => {
    dispatch(
      getProspectsByFilterThunk({
        filterColumn: ProspectFilterItems?.filterColumn
          ? ProspectFilterItems?.filterColumn
          : "",
        filterValue: ProspectFilterItems?.filterValue
          ? ProspectFilterItems?.filterValue
          : "",
        searchVal: ProspectFilterItems?.searchVal
          ? ProspectFilterItems?.searchVal
          : "",
        sortColumn: ProspectFilterItems?.sortColumn
          ? ProspectFilterItems?.sortColumn
          : "",
        sortType: ProspectFilterItems?.sortType
          ? ProspectFilterItems?.sortType
          : "",
        ProspectPageFilter,
        dynamicId: dynamicProspectsId,
        status: ProspectFilterItems.status ? ProspectFilterItems.status : "",
      })
    );
  }, [
    ProspectPageFilter,
    ProspectFilterItems?.searchVal,
    ProspectFilterItems?.filterValue,
    ProspectFilterItems?.sortType,
    ProspectFilterItems?.status,
    dynamicProspectsId,
  ]);

  const uniqueProspectData = getProspectsDataThunk?.data?.data?.filter(
    (item: any, index: number, array: any) => {
      return array.findIndex((obj: any) => obj.id === item.id) === index;
    }
  );

  const dispatch = useAppDispatch();
  useEffect(() => {
    // dispatch(getProspectsThunk({ dynamicProspectsId }));
    dispatch(
      getUserRolesProspectsThunk({
        dynamicProspectsId,
      })
    );
  }, [dynamicProspectsId]);
  const [stageTwoToGoalsModal, setstageTwoToGoalsModal] = useState(false);
  const columns = [
    { header: "Name", accessor: "first_name" },
    { header: "Email", accessor: "email" },
    { header: "Mobile", accessor: "phone" },
    { header: "Referrer", accessor: "referrer_name" },
    { header: "Status", accessor: "status" },
  ];
  let b2cColsClients = [
    { header: "Name", accessor: "first_name" },
    { header: "Email", accessor: "email" },
    { header: "Phone", accessor: "phone" },
    { header: "Status", accessor: "status" },
  ];
  const { domainDetail } = useAppSelector((state) => state?.common);
  let businessType = domainDetail?.businessmodel_type;
  // // console.log(":businessType ===================>", businessType);
  ///////////////////////////////////////////////////////////////////////////////////////
  const prospectsStatusDetailsNums = useAppSelector(
    (state) => state.common.domainDetail.prospect_status
  );
  const prospectsStatusDetailsArr = useAppSelector(
    (state) => state.common.statusDetailsData?.data
  );
  // // console.log(clientStatusDetailsArr,"clientStatusDetailsArr")
  useEffect(() => {
    if (prospectsStatusDetailsNums) {
      dispatch(resetStatusDetails());
      dispatch(getStatusDetailsThunk(prospectsStatusDetailsNums));
    }
  }, [prospectsStatusDetailsNums]);
  const { prospects_label } = useAppSelector(
    (state) => state?.common?.domainDetail
  );
  useEffect(() => {
    dispatch(setProfileTypeSticky(prospects_label));
  }, [prospects_label]);
  ///////////////////////////////////////////////////////////////////////////////////////
  const { profile_name } = useAppSelector((state) => state.common.domainDetail);

  return (
    <Fragment>
      <div
        style={{
          position: "relative",
        }}
      >
        {stageTwoToGoalsModal && (
          <CommonQuickMenu
            setshowGoalsModal={(value: any) => {}}
            mainType={"listing"}
            setstageTwoToGoalsModal={setstageTwoToGoalsModal}
          />
        )}
        <CommonUpperBox
          onClickPlus={() => {
            setstageTwoToGoalsModal(true);
          }}
          type={"listing"}
          title={prospects_label}
        />
      </div>
      <Table
        isLoading={loading}
        tableRef={tableRef}
        tableBodyRef={prospectInnerRef}
        dynamicHeaderColor={domainDetail?.listingtbl_color}
        itemsArr={prospectsStatusDetailsArr}
        statusType="prospectsScreen"
        thData={
          businessType === "B2B" || isAdminActive ? b2cColsClients : columns
        }
        classNameTr={` all-prospects-table-head  ${
          (businessType === "B2B" || isAdminActive) &&
          "all_prospects_B2B_table_body"
        }`}
        type="prospects"
        dropdownRef={dropdownRefProspects}
        isDropdownOpen={isDropdownOpenProspects}
        onClickSvg={() => setIsDropdownOpenProspects(true)}
        svgParentRef={svgParentRefProspects}
        allClients={uniqueProspectData}
        onClickRow={handleClickRow}
        classNameRow={`all-prospects-table-body ${
          (businessType === "B2B" || isAdminActive) &&
          "all_prospects_B2B_table_body"
        } `}
        // hasStatusWidgetOpen={(value: any) => {
        //   setHasStatusWidgetOpen(value);
        // }}
        fill={
          selectedProspects?.length > 0 ||
          searchValProspects?.length > 0 ||
          selectedSortValuesProspects?.length > 0
            ? filterIconColor
            : "#D8D3D3"
        }
      />
      <CircularNoteTrigger
        type={prospects_label}
        //  title={prospects_label}
        title={profile_name}
      />
    </Fragment>
  );
};

export default Prospectus;
