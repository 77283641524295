import React, { useCallback, useRef, useState } from "react";
import debounce from "lodash/debounce";
import DropdownOptions from "../DropdownOptions";
import { Data, filterIcon } from "../TableDropDown2";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../Controller/redux/Hooks";

import { setselectedTypeOfManipulation } from "../../../../../Controller/redux/features/CommonFeature/documentSlice";
import { setisDropdownOpen } from "../../../../../Controller/redux/features/ProspectsFeature/prospectsSlice";
import { Checkbox } from "@chakra-ui/react";
import {
  resetselectedItemIndexAssociations,
  setselectedItemIndexAssociationsSort,
} from "../../../../../Controller/redux/features/CommonFeature/associationSlice";
import {
  resetSearchValueServiceType,
  resetSelectedFilterItemsServiceType,
  resetSelectedItemIndexFilterServiceType,
  resetSelectedItemIndexSortServiceType,
  resetSortByServiceTypeR,
  setSearchValueServiceType,
  setSelectedDropdownItemServiceType,
  setSelectedFilterItemsServiceType,
  setSelectedItemIndexFilterServiceType,
  setSelectedItemIndexSortServiceType,
  setServiceTypeFilterItems,
  setSortByServiceTypeR,
} from "../../../../../Controller/redux/features/RefDataFeature/ServiceTypeFeature/serviceTypeSlice";

const ServiceTypeFilter = () => {
  const dispatch = useAppDispatch();
  const inputRefService = useRef<HTMLInputElement | any>(null);
  const {
    sortByServiceTypeR,
    serviceTypePage,
    getServiceTypeName,
    getServiceTypeTaxRate,
    getServiceTypeBillingRule,
    selectedDropdownItemServiceType,

    selectedItemIndexFilterServiceType,
    selectedItemIndexSortServiceType,
  } = useAppSelector((state) => state?.serviceType);

  const filterIconCo̥lor = useAppSelector(
    (state) => state?.common?.filterIconColor
  );
  const ServiceName = getServiceTypeName
    ?.map((data: any) => data?.name)
    ?.filter((elem: any) => elem !== null);

  const serviceTaxRate = getServiceTypeTaxRate
    ?.map((data: any) => data?.tax_rate)
    ?.filter((elem: any) => elem !== null);

  const ServiceSubCategory = getServiceTypeBillingRule
    ?.map((data: any) => data?.Service_sub_category)
    ?.filter((elem: any) => elem !== null);

  const [sortByService, setSortByService] = useState(sortByServiceTypeR);
  const [ServiceTableDataObject, setServiceTableDataObject] = useState<any>({
    currentActive: selectedDropdownItemServiceType,
    selectedItemIndex: selectedItemIndexFilterServiceType,
    selectedSortIndex: selectedItemIndexSortServiceType,
  });
  const [inputValueTextServiceType, setinputValueTextServiceType] =
    useState("");

  //////////////////// START //////////////

  const selectedItemIndexAssociationsSort = useAppSelector(
    (state) => state?.associations?.selectedItemIndexAssociationsSort
  );
  const selectedItemIndexAssociations = useAppSelector(
    (state) => state?.associations?.selectedItemIndexAssociations
  );

  const [selectedSortIndex, setselectedSortIndex] = useState<any>(
    selectedItemIndexAssociationsSort
  );
  const [selectedItemIndex, setSelectedItemIndex] = useState(
    selectedItemIndexAssociations
  );
  const handleFoucsInSearchOfAssociations = () => {
    setSelectedItemIndex(null);
    setselectedSortIndex(null);
  };

  const debounceInputChangeBusiness = useCallback(
    debounce((newSearchText: string) => {
      dispatch(
        setServiceTypeFilterItems({
          filterColumn: "",
          filterValue: "",
          searchVal: newSearchText,
          sortColumn: "",
          sortType: "",
          //   status: newSearchText === "" || newSearchText === null ? "1" : "",
          serviceTypePage,
        })
      );

      dispatch(setSearchValueServiceType(newSearchText));
    }, 300),
    []
  );

  const handleInputChangeBusiness = (e: string | any) => {
    const newInputValue = e.target.value;
    setinputValueTextServiceType(newInputValue);

    debounceInputChangeBusiness(newInputValue);
  };
  const handleClearSearchBusiness = () => {
    setinputValueTextServiceType("");
    dispatch(resetSearchValueServiceType());
    dispatch(
      setServiceTypeFilterItems({
        filterColumn: "",
        filterValue: "",
        searchVal: "",
        sortColumn: "",
        sortType: "",
        // status: "1",
        serviceTypePage,
      })
    );
  };

  const selectedTypeOFManipulation = useAppSelector(
    (state) => state?.document?.selectedTypeOfManipulation
  );
  const seleectedColumnForSort = useAppSelector(
    (state) => state?.document?.selectedColumnForSort
  );
  const initialState: any = {
    selectedFilterDropDownOne: selectedTypeOFManipulation,
    selectedSortDropDownOne: selectedTypeOFManipulation,
  };
  const [{ selectedFilterDropDownOne, selectedSortDropDownOne }, setState] =
    useState(initialState);
  const handleItemClickDropdownOneDocuments = (
    id: any,
    icon: any,
    title: any,
    props: any
  ) => {
    if (props === "ServiceType") {
      if (title.includes("filter")) {
        dispatch(resetSearchValueServiceType());
        dispatch(resetSelectedItemIndexSortServiceType());
        dispatch(resetSortByServiceTypeR());
        setServiceTableDataObject((prevState: any) => ({
          ...prevState,
          selectedSortIndex: null,
        }));

        dispatch(
          setServiceTypeFilterItems({
            filterColumn: "",
            filterValue: "",
            searchVal: "",
            sortColumn: "",
            sortType: "",
            // status: "1",
            serviceTypePage,
          })
        );
      } else if (title.includes("sort")) {
        dispatch(resetSearchValueServiceType());
        dispatch(resetSelectedItemIndexFilterServiceType());
        dispatch(resetSelectedFilterItemsServiceType());
        setServiceTableDataObject((prevState: any) => ({
          ...prevState,
          selectedItemIndex: null,
        }));

        dispatch(
          setServiceTypeFilterItems({
            filterColumn: "",
            filterValue: "",
            searchVal: "",
            sortColumn: "",
            sortType: "",
            // status: "1",
            serviceTypePage,
          })
        );
      } else {
        dispatch(resetSelectedItemIndexFilterServiceType());
        dispatch(resetSelectedItemIndexSortServiceType());
        dispatch(resetSortByServiceTypeR());
        dispatch(resetSelectedFilterItemsServiceType());

        dispatch(
          setServiceTypeFilterItems({
            filterColumn: "",
            filterValue: "",
            searchVal: "",
            sortColumn: "",
            sortType: "",
            status: "",
            serviceTypePage,
          })
        );
      }
      setServiceTableDataObject((prevState: any) => ({
        ...prevState,
        currentActive: title,
      }));
      dispatch(setSelectedDropdownItemServiceType(title));
    }
    setState((prevData: any) => ({
      ...prevData,
      selectedFilterDropDownOne:
        id === 1 ? { icon, title, id, ...props } : null,
      selectedSortDropDownOne: id === 3 ? { icon, title, id, ...props } : null,
      selectedSearchItem: id === 2,
      selectedFilterDropDownTwo: null,
      selectedSortDropDownTwo: null,
      selectedItem: { icon, title, id, ...props },
    }));
    dispatch(setselectedTypeOfManipulation({ icon, title, id, ...props }));
  };
  const [selectedTitles, setSelectedTitles] = useState<string[] | any[]>([]);
  const handleClickAssociationsColumn = (index: number | any, props: any) => {
    if (props === "ServiceType") {
      setServiceTableDataObject((prevState: any) => ({
        ...prevState,
        selectedItemIndex: index,
      }));
      dispatch(setSelectedItemIndexFilterServiceType(index));
      setSelectedTitles([]);
    } else {
      setSelectedItemIndex(index);
      setselectedSortIndex(null);
      dispatch(setisDropdownOpen(true));
      setSelectedTitles([]);
    }
  };
  const handleCheckboxChangeService = (elem: any, type: any, index: any) => {
    let targetType;
    switch (true) {
      case type.includes("name"):
        targetType = "name";
        break;
      case type.includes("tax_rate"):
        targetType = "tax_rate";
        break;
      case type.includes("Service_sub_category"):
        targetType = "Service_sub_category";
        break;
    }
    const updatedSelectedTitles = selectedTitles?.includes(elem)
      ? selectedTitles.filter((t) => t !== elem)
      : [...selectedTitles, elem];

    setSelectedTitles(updatedSelectedTitles);
    dispatch(setSelectedFilterItemsServiceType(updatedSelectedTitles));
    handleChangeOfDropdownService(updatedSelectedTitles, targetType);
  };
  const handleChangeOfDropdownService = (names: any, type: any) => {
    let filterColumn;

    switch (type) {
      case "name":
        filterColumn = "name";
        break;
      case "tax_rate":
        filterColumn = "tax_rate";
        break;
      case "Service_category":
        filterColumn = "Service_sub_category";
        break;
      // default:
      //   const statusMap: any = {
      //     Inactive: 0,
      //     Active: 1,
      //   };
      //   const numericalValues = names.map((name: any) => statusMap[name]);
      //   filterColumn = "status";
      //   names = numericalValues;
    }

    let filterValue;

    filterValue = names.join(",");

    if (names.length !== 0) {
      dispatch(
        setServiceTypeFilterItems({
          filterColumn,
          filterValue,
          searchVal: "",
          sortColumn: "",
          sortType: "",
          // status: "",

          serviceTypePage,
        })
      );
    } else {
      dispatch(
        setServiceTypeFilterItems({
          filterColumn,
          filterValue,
          searchVal: "",
          sortColumn: "",
          sortType: "",
          // status: "1",
          serviceTypePage,
        })
      );
    }
  };
  const handleOpenSortOptionsOnBasisOfIndexOfColumn = (
    index: number | any,
    props: any
  ) => {
    if (props === "ServiceType") {
      setServiceTableDataObject((prevState: any) => ({
        ...prevState,
        selectedSortIndex: index,
      }));
      dispatch(setSelectedItemIndexSortServiceType(index));
    } else {
      setselectedSortIndex(index);
      dispatch(setselectedItemIndexAssociationsSort(index));
      dispatch(resetselectedItemIndexAssociations(""));
      setSelectedItemIndex(null);
    }
  };
  const handleSortChangeService = (val: string, selectedSortIndex: number) => {
    const capturedVal = val === "newest on top" ? "asc" : "desc";
    setSortByService(val);
    dispatch(setSortByServiceTypeR(val));

    let sortColumn = "";
    switch (ServiceTableDataObject.selectedSortIndex) {
      case 0:
        sortColumn = "name";
        break;
      case 1:
        sortColumn = "tax_rate";
        break;
      default:
        sortColumn = "Service_sub_category";
        break;
    }

    const actionPayload = {
      filterColumn: "",
      filterValue: "",
      searchVal: "",
      sortColumn,
      sortType: capturedVal,
      // status: "1",

      serviceTypePage,
    };
    dispatch(setServiceTypeFilterItems(actionPayload));
  };
  /////////////////////////// END///////////////////////

  return (
    <div className="dropdown-wrapper-main">
      <div className="associations-table-dropdown">
        <div className="dropdown-2-box">
          <div className="dropdown-2">
            <DropdownOptions
              selectedItem={ServiceTableDataObject?.currentActive}
              data={Data}
              ref={inputRefService}
              onFocus={handleFoucsInSearchOfAssociations}
              inputTextValue={inputValueTextServiceType}
              onChange={handleInputChangeBusiness}
              clearSearch={handleClearSearchBusiness}
              onClick={handleItemClickDropdownOneDocuments}
              selectedType={"ServiceType"}
            />
          </div>

          {/* ///////////////////////////////////////////////////// FILTER OPTIONS ////////////////////////////////////////////////////////// */}
          {!!selectedFilterDropDownOne &&
          selectedFilterDropDownOne?.id === 1 ? (
            <div className="dropdown-2-box-sub">
              <div className="sub-dropdown-2">
                {["Name", "Tax Rate"]?.map((elem, index) => (
                  <div
                    key={index}
                    className={`dropdown-2-item ${
                      ServiceTableDataObject.selectedItemIndex === index
                        ? "dropdown-2-item-selected"
                        : ""
                    }`}
                    onClick={() =>
                      handleClickAssociationsColumn(index, "ServiceType")
                    }
                  >
                    <p>{elem}</p>
                  </div>
                ))}
              </div>
            </div>
          ) : null}

          {/* ////////////////////////////////////////////////    FILTERING    ////////////////////////////////////////////////////////////////////// */}
          {ServiceTableDataObject.selectedItemIndex === 0 &&
            selectedFilterDropDownOne?.id === 1 && (
              <div className="dropdown-2-box-sub-2">
                <div className="sub-dropdown-2">
                  <Checkbox
                    isChecked={selectedTitles?.length === ServiceName?.length}
                    className="dropdown-2-item dropdown-2-item-no-hover-effect w-full"
                    onChange={() => {
                      if (selectedTitles?.length === ServiceName?.length) {
                        setSelectedTitles([]);
                      } else {
                        setSelectedTitles(ServiceName);

                        dispatch(
                          setServiceTypeFilterItems({
                            filterColumn: "",
                            filterValue: "",
                            searchVal: "",
                            sortColumn: "",
                            sortType: "",
                            // status: "",
                            serviceTypePage,
                          })
                        );
                      }
                    }}
                  >
                    (Select All)
                  </Checkbox>

                  {ServiceName?.map((elem: any, index: any) => (
                    <div
                      className="dropdown-2-item dropdown-2-item-no-hover-effect"
                      // note here key forces re render
                      key={elem?.id}
                    >
                      <Checkbox
                        isChecked={selectedTitles.includes(elem)}
                        onChange={() =>
                          handleCheckboxChangeService(elem, "name", index)
                        }
                      >
                        {elem}
                      </Checkbox>
                    </div>
                  ))}
                </div>
              </div>
            )}
          {ServiceTableDataObject.selectedItemIndex === 1 &&
            selectedFilterDropDownOne?.id === 1 && (
              <div className="dropdown-2-box-sub-2">
                <div className="sub-dropdown-2">
                  <Checkbox
                    isChecked={selectedTitles.length === serviceTaxRate.length}
                    className="dropdown-2-item dropdown-2-item-no-hover-effect w-full"
                    onChange={() => {
                      if (selectedTitles.length === serviceTaxRate.length) {
                        setSelectedTitles([]);
                      } else {
                        setSelectedTitles(serviceTaxRate);

                        dispatch(
                          setServiceTypeFilterItems({
                            filterColumn: "",
                            filterValue: "",
                            searchVal: "",
                            sortColumn: "",
                            sortType: "",
                            // status: "",
                            serviceTypePage,
                          })
                        );
                      }
                    }}
                  >
                    (Select All)
                  </Checkbox>

                  {serviceTaxRate?.map((elem: any, index: any) => (
                    <div
                      className="dropdown-2-item dropdown-2-item-no-hover-effect"
                      key={index}
                    >
                      {elem !== null && (
                        <Checkbox
                          onChange={() =>
                            handleCheckboxChangeService(elem, "tax_rate", index)
                          }
                          isChecked={selectedTitles.includes(elem)}
                        >
                          {elem}
                        </Checkbox>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            )}
          {/* {ServiceTableDataObject.selectedItemIndex === 2 &&
            selectedFilterDropDownOne?.id === 1 && (
              <div className="dropdown-2-box-sub-2">
                <div className="sub-dropdown-2">
                  <Checkbox
                    isChecked={
                      selectedTitles.length === ServiceSubCategory.length
                    }
                    className="dropdown-2-item dropdown-2-item-no-hover-effect w-full"
                    onChange={() => {
                      if (selectedTitles.length === ServiceSubCategory.length) {
                        setSelectedTitles([]);
                      } else {
                        setSelectedTitles(ServiceSubCategory);

                        dispatch(
                          setServiceTypeFilterItems({
                            filterColumn: "",
                            filterValue: "",
                            searchVal: "",
                            sortColumn: "",
                            sortType: "",
                            // status: "",
                            serviceTypePage,
                          })
                        );
                      }
                    }}
                  >
                    (Select All)
                  </Checkbox>

                  {ServiceSubCategory?.map((elem: any, index: any) => (
                    <div
                      className="dropdown-2-item dropdown-2-item-no-hover-effect"
                      key={index}
                    >
                      {elem !== null && (
                        <Checkbox
                          isChecked={selectedTitles?.includes(elem)}
                          onChange={() =>
                            handleCheckboxChangeService(
                              elem,
                              "Service_sub_category",
                              index
                            )
                          }
                        >
                          {elem}
                        </Checkbox>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            )} */}
          {/*  /////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
          {/*  selectedSortDropDownOne?.id === 3 */}
          {/* SORT OPTIONS */}

          {!!selectedSortDropDownOne &&
          ServiceTableDataObject.currentActive === "sort" ? (
            <div className="dropdown-2-box-sub">
              <div className="sub-dropdown-2">
                {["Name", "Tax Rate"]?.map((elem: any, index: number) => (
                  <div
                    key={index}
                    className={`dropdown-2-item ${
                      ServiceTableDataObject.selectedSortIndex === index
                        ? "dropdown-2-item-selected"
                        : ""
                    }`}
                    onClick={() =>
                      handleOpenSortOptionsOnBasisOfIndexOfColumn(
                        index,
                        "ServiceType"
                      )
                    }
                  >
                    <p>{elem}</p>
                  </div>
                ))}
              </div>
            </div>
          ) : null}

          {/* SORT OPTIONS ITEMS */}

          {ServiceTableDataObject.selectedSortIndex === 0 &&
            selectedSortDropDownOne?.id === 3 && (
              <div className="dropdown-2-box-sub-2">
                <div className="sub-dropdown-2">
                  {["newest on top", "oldest on top"].map((title, index) => {
                    return (
                      <div
                        className={`dropdown-2-item capitalize hover:text-white  ${
                          title === sortByService &&
                          "dropdown-2-item-selected text-white"
                        }`}
                        onClick={() =>
                          handleSortChangeService(title, selectedSortIndex)
                        }
                      >
                        <span
                          className="font-normal"
                          style={{
                            textDecoration: "none",
                            textTransform: "none",
                          }}
                        >
                          {index === 0 ? "A to Z" : "Z to A"}
                        </span>
                        <div className="relative left-[2px] flex">
                          {index === 0 ? (
                            <svg
                              width="20px"
                              height="20px"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M12 4V20M12 20L8 16M12 20L16 16"
                                stroke={filterIconCo̥lor}
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          ) : (
                            <svg
                              width="20px"
                              height="20px"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M12 4V20M12 4L8 8M12 4L16 8"
                                stroke={filterIconCo̥lor}
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}

          {ServiceTableDataObject.selectedSortIndex === 1 &&
            selectedSortDropDownOne?.id === 3 && (
              <div className="dropdown-2-box-sub-2">
                <div className="sub-dropdown-2">
                  {["newest on top", "oldest on top"].map((title, index) => {
                    return (
                      <div
                        className={`dropdown-2-item capitalize hover:text-white  ${
                          title === sortByService &&
                          "dropdown-2-item-selected text-white"
                        }`}
                        onClick={() =>
                          handleSortChangeService(
                            title,

                            selectedSortIndex
                          )
                        }
                      >
                        <span
                          className="font-normal"
                          style={{
                            textDecoration: "none",
                            textTransform: "none",
                          }}
                        >
                          {index === 0 ? "A to Z" : "Z to A"}
                        </span>
                        <div className="relative left-[2px] flex">
                          {index === 0 ? (
                            <svg
                              width="20px"
                              height="20px"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M12 4V20M12 20L8 16M12 20L16 16"
                                stroke={filterIconCo̥lor}
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          ) : (
                            <svg
                              width="20px"
                              height="20px"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M12 4V20M12 4L8 8M12 4L16 8"
                                stroke={filterIconCo̥lor}
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}

          {/* {
            ServiceTableDataObject.selectedSortIndex === 2 &&
              selectedSortDropDownOne?.id === 3 && (
                <div className="dropdown-2-box-sub-2">
                  <div className="sub-dropdown-2">
                    {["newest on top", "oldest on top"].map((title, index) => {
                      return (
                        <div
                          className={`dropdown-2-item capitalize hover:text-white  ${
                            title === sortByService &&
                            "dropdown-2-item-selected text-white"
                          }`}
                          onClick={() =>
                            handleSortChangeService(title, selectedSortIndex)
                          }
                        >
                          <span
                            className="font-normal"
                            style={{
                              textDecoration: "none",
                              textTransform: "none",
                            }}
                          >
                            {index === 0 ? "A to Z" : "Z to A"}
                          </span>
                          <div className="relative left-[2px] flex">
                            {index === 0 ? (
                              <svg
                                width="20px"
                                height="20px"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M12 4V20M12 20L8 16M12 20L16 16"
                                  stroke={filterIconCo̥lor}
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            ) : (
                              <svg
                                width="20px"
                                height="20px"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M12 4V20M12 4L8 8M12 4L16 8"
                                  stroke={filterIconCo̥lor}
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )
          } */}
        </div>
      </div>
    </div>
  );
};

export default ServiceTypeFilter;
