import "./Styles/Villages.css";

import { Fragment, useCallback, useEffect, useMemo, useState } from "react";

import {
  useAppDispatch,
  useAppSelector,
} from "../../../Controller/redux/Hooks";
import ClientImage from "../../../Model/Assets/png/clientImg.png";
import { getClientsThunk } from "../../../Controller/redux/features/ClientFeature/clientsSlice";
import {
  getLastUpdateClientThunk,
  getSilDetailsThunk,
  getSilStatusThunk,
  getStatusStatsProspectsThunk,
  getStatusStatsThunk,
} from "../../../Controller/redux/features/VillageFeature/villageSlice";
import RightArrowSvg from "../../../Model/Assets/SVG/WidgetVillage/RightArrowSvg";
import ClientStatus from "../../components/ClientStatus/ClientStatus";
import CircularNoteTrigger from "../../components/CircularNoteTrigger/CircularNoteTrigger";
import QuestionMarkSVG from "./Data/QuestionMarkSVG";
import CommonUpperBox from "../../components/UpperBox/CommonHeader";
import CommonQuickMenu from "../../components/CommonQuickMenu/CommonQuickMenu";
import CommonChart from "../../components/CommonCharts/CommonChart";
import { statusMapClient } from "../../../Model/utils/Helper/statusMap";
import { formatDateForAPI } from "../../../Model/utils/Helper/formatDate";
import { YellowIcon } from "../../components/ReportingWidget/Data/SVGs";
import { Spinner } from "@chakra-ui/react";
import React from "react";

const Sil = ({ sil }: any) => {
  const dispatch = useAppDispatch();

  // @ts-ignore
  const dynamicClientId = parseInt(localStorage.getItem("dynamicClientId"));
  // @ts-ignore
  const dynamicLocationId = parseInt(localStorage.getItem("dynamicLocationId"));
  // @ts-ignore
  const dynamicProspectId = parseInt(localStorage.getItem("dynamicProspectId"));
  // @ts-ignore
  const dynamicStaffId = parseInt(localStorage.getItem("dynamicStaffId"));

  const { dynamicColor, hasSil, domainDetail } = useAppSelector(
    (state: { common: any }) => state?.common
  );

  const { getSilDetails, getSilStatus } = useAppSelector(
    (state) => state.villages
  );
  const [isLoading, setisLoading] = useState(false);

  const b2bCondition = domainDetail?.businessmodel_type === "B2B";
  useEffect(() => {
    dispatch(getClientsThunk({ dynamicClientId }));
    setisLoading(true);

    dispatch(getLastUpdateClientThunk(dynamicClientId)).then((res: any) => {
      if (res.payload.status === "success") {
        setisLoading(false);
      } else {
        setisLoading(false);
      }
    });
    dispatch(
      getSilDetailsThunk({
        type: dynamicClientId,
        dynamicLocationType: dynamicLocationId,
      })
    );
  }, [dynamicClientId]);

  useEffect(() => {
    dispatch(
      getSilDetailsThunk({
        type: dynamicClientId,
        dynamicLocationType: dynamicLocationId,
      })
    );
    dispatch(
      getStatusStatsThunk({ dynamicId: dynamicClientId, status: "1,2,3,5" })
    );
    dispatch(
      getStatusStatsProspectsThunk({
        dynamicId: dynamicProspectId,
        status: "12,13,4",
      })
    );
  }, []);

  const transformObjectToArray = (dataObject: any) => {
    const dataArray = [];

    for (const key in dataObject) {
      if (dataObject.hasOwnProperty(key)) {
        const obj = {
          name: key,
          data: dataObject[key],
        };
        dataArray.push(obj);
      }
    }

    return dataArray;
  };

  const transformedArray = transformObjectToArray(getSilDetails?.data);

  const { getLastUpdatedClients } = useAppSelector(
    (state: { villages: any }) => state?.villages
  );

  const getAllClientsDataThunk = useAppSelector(
    (state: { people: { allData: any } }) => state?.people?.allData
  );

  const [stageTwoToGoalsModal, setstageTwoToGoalsModal] = useState(false);

  const { getStatusStats, getStatusStatsProspect } = useAppSelector(
    (state) => state?.villages
  );

  let barArr: any[] = [];
  if (Array.isArray(getStatusStats.data)) {
    for (let item of getStatusStats.data) {
      if (item.status === "5") {
        // @ts-ignore
        let itemAtIndexOne = parseInt(barArr[1] || 0);
        let countCurrent = parseInt(item.count);
        let total = itemAtIndexOne + countCurrent;

        barArr.splice(1, 1, total);
      } else {
        barArr.push(parseInt(item.count));
      }
    }
  }

  const [prospectStatusRecordsArr, setProspectStatusRecordsArr] = useState<
    any[]
  >([]);

  useEffect(() => {
    if (
      getStatusStatsProspect &&
      getStatusStatsProspect?.data &&
      getStatusStatsProspect?.data?.lenght !== 0
    ) {
      let totalCount = getStatusStatsProspect?.data?.reduce(
        (sum: any, obj: any) => sum + obj?.count,
        0
      );
      const duplicateProspectStatusArr: any[] = getStatusStatsProspect?.data
        ? [...getStatusStatsProspect?.data]
        : [];
      duplicateProspectStatusArr.push({
        status: "newStatus", // Replace with the desired status value
        count: totalCount,
      });
      setProspectStatusRecordsArr(duplicateProspectStatusArr);
    }
  }, [getStatusStatsProspect]);

  useEffect(() => {
    if (dynamicStaffId) {
      dispatch(
        getSilStatusThunk({
          dynamicStaffId,
          date: formatDateForAPI(new Date()),
        })
      );
    }
  }, [dynamicStaffId]);

  const [SilStatusRecords, setSilStatusRecords] = useState<any>({});

  useEffect(() => {
    if (getSilStatus) {
      setSilStatusRecords(getSilStatus);
    }
  }, [getSilStatus]);

  const [BarNameRecords, setBarNameRecords] = useState([
    {
      type: "Staff Working Over 20 Individual Days in 28 Days",
      color: "rgba(255, 159, 64, 0.2)",
    },
    {
      type: "Staff Working More than 60 Hours in 28 Days",
      color: "rgba(255, 205, 86, 0.2)",
    },
    {
      type: "Staff with Work Span > 12 Hours",
      color: "rgba(75, 192, 192, 0.2)",
    },
    {
      type: "Staff with Shift Gaps < 3 Hours",
      color: "rgba(54, 162, 235, 0.2)",
    },
    {
      type: "Staff with >30min Between Appointments",
      color: "rgba(153, 102, 255, 0.2)",
    },
    {
      type: "Staff Working <2 Hours per Shift",
      color: "rgba(201, 203, 207, 0.2)",
    },
  ]);

  const renderStaffChartWidgets = useCallback(() => {
    return (
      <CommonChart
        values={[
          SilStatusRecords?.work_hours_staff_count,
          SilStatusRecords?.individual_task_staff_count,
          SilStatusRecords?.extra_working_days_count,
          SilStatusRecords?.less_hours_between_shifts,
          SilStatusRecords?.more_hours_between_shifts,
          SilStatusRecords?.less_than_shift_period,
        ]}
        barThickness={10}
        displayX={false}
        displayY={true}
        pointBackgroundColor={"#B7A9FF"}
        borderWidth={1}
        pointBorderColor={"rgba(0, 0, 0, 0)"}
        pointRadius={3}
        chartLabel={""}
        // isFromSales={true}
        chartType={"bar"}
        labels={BarNameRecords.map((res) => res?.type)}
        isFromVillage={false}
        dataColors={[
          "rgba(255, 99, 132, 0.2)",
          "rgba(255, 159, 64, 0.2)",
          "rgba(255, 205, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(201, 203, 207, 0.2)",
        ]}
        // labels={statusData?.map((elem: any) => elem?.data)}
        chartLineColor={"#000"}
        chartWidth={"350px"}
        chartSize={"medium"}
        // dataColors={["red", "green", "blue"]}
        chartHeight={"180px"}
      />
    );
  }, [SilStatusRecords]);

  return (
    <>
      <div>
        {/* <UpperBox type="village" /> */}
        <CommonUpperBox
          title={"SIL"}
          type={"listing"}
          backgroundWithText
          text={"S D"}
          onClickPlus={() => {
            setstageTwoToGoalsModal(true);
          }}
        />

        <div className="sil_page_wrapper">
          <div className="sli_recent_chnages_present_section">
            {domainDetail?.has_recentchanges != 0 && (
              <div className="sil_recent_changes_wrapper_1 sil_recent_changes_section_wrapper">
                <div className="header_section_wrapper">
                  <div className="left_side_header_section">
                    <div className="section_1_wrapper">
                      {/* <GreenColorSVG key={"green_svg"} width="24" height="24" /> */}
                      <div className="title_wrapper">
                        <div className="text_with_backgroud_wrapper">
                          <h1>Recent Changes</h1>
                          {/* <PlusSVG /> */}
                        </div>
                      </div>
                      {/* <FilterSVG /> */}
                    </div>

                    <div className="sub_title_wrapper"></div>
                  </div>
                  {/* <div className="right_side_header_section">
                <p>Last update: Today 13:03</p>
              </div> */}
                  <div
                    className="locations_wrapper"
                    style={{
                      display: hasSil === 1 ? "" : "none",
                    }}
                  >
                    {[1, 2, 3].map((elem, index: number) => {
                      return (
                        <div
                          key={index}
                          className="sub_section_location_wrapper_1"
                        >
                          <span className="location_span">
                            Location {elem || ""}
                          </span>
                          <div className="flex_with_gap">
                            {getAllClientsDataThunk?.data?.data
                              ?.slice(0, 2)
                              .map((elem: any, index: any) => (
                                <Fragment key={index}>
                                  <ClientStatus
                                    key={index}
                                    title={elem?.first_name}
                                    status={elem?.status}
                                    date={elem?.created_at}
                                    time={elem?.created_at}
                                    imageURL={elem?.profile_image_s3}
                                    backgroundWithText={`${
                                      elem?.first_name[0] || ""
                                    } ${
                                      b2bCondition
                                        ? ""
                                        : elem?.last_name[0] || ""
                                    }`}
                                  />
                                </Fragment>
                              ))}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div
                  className="content_section_wrapper_sil"
                  style={{
                    height: "101%",
                  }}
                >
                  <div className="village_widget_container_update ">
                    {isLoading && !getLastUpdatedClients?.data?.length && (
                      <Spinner
                        position={"absolute"}
                        left={"40%"}
                        top={"100px"}
                      />
                    )}
                    {getLastUpdatedClients?.data?.map(
                      (elem: any, index: number) => (
                        <div key={index} className="village_update_wrapper">
                          <div
                            className="village_widget_updated"
                            style={{
                              padding: "15px 5px",
                              height: "100px",
                              display: "flex",
                              flexDirection: "row",
                              position: "relative",
                              alignItems: "center",
                            }}
                            key={elem.id}
                          >
                            <ClientStatus
                              title={elem?.first_name}
                              imageURL={elem?.profile_image_s3}
                              status={elem?.old_status}
                              date={elem?.old_status_time}
                              time={elem?.old_status_time}
                              backgroundWithText={`${elem?.first_name[0]} ${
                                b2bCondition ? "" : elem?.last_name[0]
                              }`}
                            />
                            <div className="village_widget_arrow">
                              <RightArrowSvg dynamicColor={dynamicColor} />
                            </div>
                            <ClientStatus
                              imageURL={elem?.profile_image_s3}
                              title={elem?.first_name}
                              status={elem?.current_status}
                              date={elem?.current_status_time}
                              time={elem?.current_status_time}
                              backgroundWithText={`${elem?.first_name[0]} ${
                                b2bCondition ? "" : elem?.last_name[0]
                              }`}
                            />
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </div>
              </div>
            )}

            <div className="report_section_wrapper report_section_wrapper_sil">
              <div>
                <div>
                  <div className="upper__segment__widget">
                    <YellowIcon />
                    <img
                      src={ClientImage}
                      alt="client-img"
                      className="note__sub__title-1-client__image"
                    />

                    <div className="client__name">{"Staff"}</div>
                  </div>
                  <div className={`report__title `}>
                    By Shifts
                    {/* {heading} */}
                  </div>
                  {/* {renderClinicalWidgets()} */}
                  {renderStaffChartWidgets()}
                </div>
                <div className={`lower__segment__widget`}>
                  {[
                    {
                      key: "Name",
                      detail: "Workdone",
                    },
                  ].map((detail, index) => {
                    return (
                      <div key={index} className="client__personal__details">
                        <div className="client__key sales_key">
                          {detail?.key}
                        </div>
                        <div className="client__detail">{detail?.detail}</div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div
                className={`
          lower__segment__widget
      `}
              >
                <div className="reporting_chart_status sil_status_wrapper">
                  {BarNameRecords?.map((elem: any, index: any) => (
                    <div className="reporting_chart_status_inner" key={index}>
                      <span
                        style={{
                          backgroundColor: elem?.color,
                        }}
                        className="reporting_chart_status_color"
                      >
                        &nbsp;
                      </span>
                      <span>{elem?.type}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="sub_sil_wrapper">
            {transformedArray
              .sort((a: any, b: any) => a.name.localeCompare(b.name))
              .map((elem, index: number) => {
                return (
                  <div key={index} className="sil_recent_changes_wrapper">
                    <div className="header_section_wrapper">
                      <div className="left_side_header_section">
                        <div className="section_1_wrapper">
                          {/* <GreenColorSVG
                            key={"green_svg"}
                            width="24"
                            height="24"
                          /> */}
                          <div className="title_wrapper">
                            <div className="text_with_backgroud_wrapper">
                              <h1>{elem?.name}</h1>
                              {/* <PlusSVG /> */}
                            </div>
                          </div>
                          {/* <FilterSVG /> */}
                        </div>
                        <div className="sub_title_wrapper">
                          {/* <p>
                    ‘Event Name’: <span> 10:00 am - 15:30 pm </span>
                  </p> */}
                        </div>
                      </div>
                    </div>
                    <div className="village_widget_container">
                      {elem?.data.length >= 1 &&
                        elem?.data?.map((elem: any, index: any) => (
                          <ClientStatus
                            key={index}
                            title={elem?.first_name}
                            status={elem?.status}
                            date={elem?.created_at}
                            time={elem?.created_at}
                            imageURL={elem?.profile_image_s3}
                            backgroundWithText={`${
                              elem?.first_name ? elem?.first_name[0] : ""
                            } ${
                              b2bCondition
                                ? ""
                                : elem?.last_name
                                ? elem?.last_name[0]
                                : ""
                            }`}
                          />
                        ))}
                    </div>
                    {/* {// console.log(elem?.data, " data")} */}
                    <div
                      className="owner_section_wrapper_sil"
                      style={{ display: "none" }}
                    >
                      <div className="right_side_owner_wrapper">
                        <QuestionMarkSVG />
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
        <CircularNoteTrigger 
              type="stickyHub"
              title="Comms"
              isOutside
              extraType="globalCommsBox"
          />
        {stageTwoToGoalsModal && (
          <CommonQuickMenu
            setshowGoalsModal={(value: any) => {}}
            mainType={"listing"}
            setstageTwoToGoalsModal={setstageTwoToGoalsModal}
          />
        )}
      </div>
    </>
  );
};

export default Sil;
