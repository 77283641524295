import "../../Styles/UpdateClientDetails.css";
import "../../Styles/ClientScreen.css";

import { Fragment, useContext, useEffect, useRef, useState } from "react";
import {
  UpdateClientDetailsArr,
  UpdateClientDetailsArr2,
  documentBoxArr,
  mainBoxArr,
  villageDetailsDocumentsArr,
} from "./Data/ClientScreenContentArr";
import { useNavigate, useParams } from "react-router-dom";

import DropdownIcon from "../../../../../../Model/Assets/png/drop.png";
import UpperBox from "../../../../../components/UpperBox/UpperBox";
import { getUniqueClientById } from "../../../../../../Controller/redux/features/ClientFeature/clientsSlice";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../Controller/redux/Hooks";
import { setunsavedChanges } from "../../../../../../Controller/redux/features/AuthFeature/authSlice";
import CircularNoteTrigger from "../../../../../components/CircularNoteTrigger/CircularNoteTrigger";

const UpdateClientDetails = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const widgetRef = useRef<any>(null);
  const { showUnsavedModal } = useAppSelector((state) => state.auth);

  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [showStatusWidget, setShowStatusWidget] = useState(false);
  const [showDropdown, setshowDropdown] = useState(false);

  useEffect(() => {
    if (showUnsavedModal) {
      setHasUnsavedChanges(false);
    }
  }, [showUnsavedModal]);

  const [details, setDetails] = useState<any>({
    givenName: "Frank",
    familyName: "Green",
    knownAs: "Franko",
    address: "3, Gilbert Street, Elizabeth Town, VIC",
    postCode: "3322",
    dateOfBirth: "1, February 1970",
    phone: "020 00 0230",
  });

  const [items, setItems] = useState<any>({
    updatePassword: "PASSWORD",
    confirmPassword: "PASSWORD",
  });

  const [buttonTab, setbuttonTab] = useState({
    Details: true,
    Associations: false,
    Clinical: false,
    Comms: false,
    Documents: false,
    Finance: false,
    Daybook: false,
  });

  const id: any = useParams().id;
  const clientId = window.atob(id);

  useEffect(() => {
    dispatch(setunsavedChanges(false));
    // dispatch(getUniqueClientById(clientId));
    // console.log(clientId, "CLIENT_ID");
  }, [clientId]);

  // MAKE WIDGET CLOSE ON CLICK OUTSIDE
  const handleClickOutside = (event: any) => {
    if (widgetRef.current && !widgetRef.current.contains(event.target)) {
      if (event.target.innerHTML !== "Open") {
        setShowStatusWidget(false);
      }
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // CHANGE TO INPUT FIELDS
  const handleChange = (
    index: number,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = e.target;
    const fieldName = Object.keys(details)[index];
    setDetails((prevState: any) => ({
      ...prevState,
      [fieldName]: value,
    }));
    dispatch(setunsavedChanges(true));

    if (!hasUnsavedChanges) {
      setHasUnsavedChanges(true);
    }
  };
  // CHANGE TO PASSWORD
  const handleChangePassword = (
    index: number,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = e.target;
    const fieldName = Object.keys(items)[index];
    setItems((prevState: any) => ({
      ...prevState,
      [fieldName]: value,
    }));
    dispatch(setunsavedChanges(true));

    if (!hasUnsavedChanges) {
      setHasUnsavedChanges(true);
    }
  };

  return (
    <Fragment>
      <UpperBox type="updateClient" name="Frank Greeen" />

      <div className="details-box" style={{ zIndex: "-1" }}>
        <span className="heading-update-details">Update my Details</span>
        <div className="main-box-body main-box-body-update-details">
          {UpdateClientDetailsArr?.map((elem: any, index: any) => {
            const fieldName = Object.keys(details)[index];
            return (
              <div
                className="content-wrapper content-wrapper-update-details"
                key={index}
              >
                <div className="wrapper-title">{elem.UpperTitle}</div>
                <input
                  className="wrapper-info"
                  onChange={(e) => handleChange(index, e)}
                  value={details[fieldName]}
                  style={{
                    border: "none",
                    outline: "none",
                    height: "fit-content",
                    background: "none",
                  }}
                />
                {elem.UpperTitle === "Date of Birth:" && (
                  <div className="dropdown-icon">
                    <img
                      src={DropdownIcon}
                      alt="dropdown-icon"
                      style={{
                        position: "absolute",
                        right: "4rem",
                        top: "2rem",
                      }}
                    />
                  </div>
                )}
                {elem.UpperTitle === "Address:" && (
                  <div>
                    <img
                      src={DropdownIcon}
                      alt="dropdown-icon"
                      style={{
                        position: "absolute",
                        left: "24rem",
                        top: "9rem",
                      }}
                    />
                  </div>
                )}

                <div className="wrapper-line"></div>
              </div>
            );
          })}
        </div>

        <div
          className="main-box-body main-box-body-update-details"
          style={{
            position: "absolute",
            top: "27rem",
            display: "flex",
            justifyContent: "flex-start",
            gap: "10.45555555rem",
          }}
        >
          {UpdateClientDetailsArr2?.map((elem: any, index: any) => {
            const fieldName = Object.keys(items)[index];
            return (
              <div
                className="content-wrapper content-wrapper-update-details"
                key={index}
              >
                <div className="update-password-box">
                  <div className="wrapper-title">{elem.UpperTitle}</div>
                  <span>
                    {elem.UpperTitle === "Update Password:" ? elem.minChar : ""}
                  </span>
                </div>

                <input
                  type="password"
                  className="wrapper-info"
                  onChange={(e) => handleChangePassword(index, e)}
                  value={items[fieldName]}
                  style={{
                    border: "none",
                    outline: "none",
                    height: "fit-content",
                    background: "none",
                    fontSize: "22px",
                  }}
                />

                <div className="wrapper-line"></div>
              </div>
            );
          })}
        </div>

        <button className="save-btn-update-screen">Update Profile</button>
      </div>
      <CircularNoteTrigger />
    </Fragment>
  );
};

export default UpdateClientDetails;
