
const SelectedSortDropdown = ({
  options,
  tableObject,
  handeChangeSortOptions,
  selectedItemIndex,
  props
}: any) => {
  return (
    <div>
      <div className="dropdown-2-box-sub">
        <div className="sub-dropdown-2">
          {options?.map((elem: any, index: number) => (
            <div
              key={index}
              className={`dropdown-2-item ${
                tableObject.selectedSortIndex === index
                  ? "dropdown-2-item-selected"
                  : ""
              }`}
              onClick={() => handeChangeSortOptions(index, props)}
            >
              <p>{elem}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SelectedSortDropdown;
