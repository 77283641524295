import React from "react";

const GallaryIconSVG = () => {
  return (
    <svg
      width="14"
      height="12"
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.50021 5.70171L10.8378 9.20076H3.45597L5.30142 6.4715L6.59324 8.36099L8.37718 5.70171H8.50021ZM4.99385 5.70171C5.48597 5.70171 5.91657 5.21185 5.91657 4.652C5.91657 4.09215 5.48597 3.60228 4.99385 3.60228C4.50173 3.60228 4.07112 4.09215 4.07112 4.652C4.07112 5.21185 4.50173 5.70171 4.99385 5.70171ZM13.2984 2.20266V10.6004C13.2984 11.3702 12.7448 12 12.0681 12H2.22566C1.549 12 0.995361 11.3702 0.995361 10.6004V2.20266C0.995361 1.43287 1.549 0.80304 2.22566 0.80304H12.0681C12.7448 0.80304 13.2984 1.43287 13.2984 2.20266ZM12.0681 4.16213V2.20266H2.22566V10.6004H12.0681V4.16213Z"
        fill="black"
      />
    </svg>
  );
};

export default GallaryIconSVG;
