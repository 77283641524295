import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { api } from "../../../services/api";

const initialState: any = {
  TeamsPage: 1,
  TeamsFilterItems: {
    filterColumn: "",
    filterValue: "",
    searchVal: "",
    sortColumn: "",
    sortType: "",
    TeamsPage: 1,
    status: "1",
  },
  teams: [],
  lastPage: 100000,
  getUserTypeIdForTeams: null,
  getTeamsName: [],
  getTeamsEmail: [],
  getTeamsPhone: [],
  getTeamsAssociate: [],
  allDataTeams: [],
  getUniqueTeams: [],
  selectedFilterOptionsTeams: [],
  selectedSortOptionsTeams: [],
  isTeamsFilterDropdownOpen: false, //
  searchValTeams: "",
  selectedDropdownItemTeams: "",
  selectedItemIndexFilterTeams: "",
  selectedItemIndexSortTeams: "",
  sortByTeamsR: "",
  selectedFilterItemsTeams: [],
  loading: false,
};

export const getTeamsNameThunk = createAsyncThunk(
  "teams/fetchName",
  async (dynamicTeamsId: number | any) => {
    try {
      const { data } = await api.get(
        `/users?unique_column=name&type=${dynamicTeamsId}`
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
);

export const getProviderTeamsNameThunk = createAsyncThunk(
  "providerTeams/fetchName",
  async ({ dynamicUserTypeId, dynamicProviderId }: any) => {
    try {
      const { data } = await api.get(
        `/providers/get_provider_users?unique_column=name&type=${dynamicUserTypeId}&tenant_id=${dynamicProviderId}`
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
);

export const getTeamsEmailThunk = createAsyncThunk(
  "teams/fetchEmail",
  async (dynamicTeamsId: number | any) => {
    try {
      const { data } = await api.get(
        `/users?unique_column=email&type=${dynamicTeamsId}`
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
);

export const getProviderTeamsEmailThunk = createAsyncThunk(
  "providerTeams/fetchEmail",
  async ({ dynamicUserTypeId, dynamicProviderId }: any) => {
    try {
      const { data } = await api.get(
        `/providers/get_provider_users?unique_column=email&type=${dynamicUserTypeId}&tenant_id=${dynamicProviderId}`
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
);

export const getTeamsPhoneThunk = createAsyncThunk(
  "teams/fetchPhone",
  async (dynamicTeamsId: number | any) => {
    try {
      const { data } = await api.get(
        `/users?unique_column=phone&type=${dynamicTeamsId}`
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
);

export const getProviderTeamsPhoneThunk = createAsyncThunk(
  "providerTeams/fetchPhone",
  async ({ dynamicUserTypeId, dynamicProviderId }: any) => {
    try {
      const { data } = await api.get(
        `/providers/get_provider_users?unique_column=phone&type=${dynamicUserTypeId}&tenant_id=${dynamicProviderId}`
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
);

export const getTeamsAssociateThunk = createAsyncThunk(
  "teams/fetchAssociate",
  async (dynamicTeamsId: number | any) => {
    try {
      const { data } = await api.get(
        `/users?unique_column=business_name&type=${dynamicTeamsId}`
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
);

export const getProviderTeamsAssociateThunk = createAsyncThunk(
  "providerTeams/fetchAssociate",
  async ({ dynamicUserTypeId, dynamicProviderId }: any) => {
    try {
      const { data } = await api.get(
        `/providers/get_provider_users?unique_column=business_name&type=${dynamicUserTypeId}&tenant_id=${dynamicProviderId}`
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
);

export const getTeamsThunk = createAsyncThunk(
  "teams/fetchBusiness",
  async (dynamicTeamsId: number | any) => {
    try {
      const response = await api.get(
        `/users?paginate=true&page=1&limit=100000&status=1&type=${dynamicTeamsId}`
      );
      return response.data;
    } catch (err) {
      throw err;
    }
  }
);
export const getTeamsByFilterThunk = createAsyncThunk(
  "teams/fetchTeamsByFilter",
  async ({
    filterColumn,
    filterValue,
    searchVal,
    sortColumn,
    sortType,
    status,
    dynamicId,
    TeamsPage,
  }: {
    filterColumn: string | any;
    filterValue: string | any;
    searchVal: string | any;
    sortColumn: string | any;
    sortType: string | any;
    status: string | any;
    dynamicId: string | any;
    TeamsPage: string | any;
  }) => {
    const encodedFilterValue = encodeURIComponent(filterValue);
    const encodeSearchValue = encodeURIComponent(searchVal);

    try {
      const response = await api.get(
        `/users?paginate=true&page=${TeamsPage}&limit=50&search=${encodeSearchValue}&sort_column=${sortColumn}&sort_type=${sortType}&status=${status}&filter_column=${filterColumn}&filter_value=${encodedFilterValue}&type=${dynamicId}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
export const updateTeamsThunk = createAsyncThunk(
  "teams/updateTeams",
  async (teamsData: any, { rejectWithValue }) => {
    try {
      const response = await api.post("/users/update_user", teamsData);
      return response.data;
    } catch (error: string | any) {
      return rejectWithValue(
        error.message || "Failed to upload client details"
      );
    }
  }
);

export const getTeamsByIdThunk = createAsyncThunk(
  "business/fetchBusinessById",
  async ({ dynamicId, teamsId }: number | any) => {
    try {
      const response = await api.get(
        `/users?paginate=true&page=1&limit=10&type=${dynamicId}&id=${teamsId}`
      );
      return response.data;
    } catch (err) {
      throw err;
    }
  }
);

export const getProviderTeamsByIdThunk = createAsyncThunk(
  "providerTeams/fetchProviderTeamsById",
  async({typeId, clientId, tenant_id}:any)=>{
    try{
      const response = await api.get(
        `/providers/get_provider_users?paginate=true&type=${typeId}&sort_column=id&sort_type=desc&id=${clientId}&tenant_id=${tenant_id}`
      )
      return response?.data;
    }catch(error){
      throw error
    }
  }
)
export const teamsSlice = createSlice({
  name: "teams",
  initialState,
  reducers: {
    // Pagination Start
    setTeamsPage: (state) => {
      state.TeamsPage += 1;
    },
    resetTeamsPage: (state) => {
      state.TeamsPage = 1;
    },
    resetTeamsData: (state) => {
      state.allDataTeams = {};
      state.teams = [];
    },
    setTeamsFilterItems: (state, action) => {
      state.TeamsFilterItems = action.payload;
    },
    resetTeamsFilterItems: (state) => {
      state.TeamsFilterItems = {
        filterColumn: "",
        filterValue: "",
        searchVal: "",
        sortColumn: "",
        sortType: "",
        TeamsPage: 1,
        status: "1",
      };
    },
    // Pagination End
    setUserTypeIdForTeams: (state, action) => {
      state.getUserTypeIdForTeams = action.payload;
    },
    setFilterOptionsTeams: (state, action) => {
      state.selectedFilterOptionsTeams = action.payload;
    },
    resetFilterOptionsTeams: (state) => {
      state.selectedFilterOptionsTeams = [];
    },
    setSortOptionsTeams: (state, action) => {
      state.selectedSortOptionsTeams = action.payload;
    },
    resetSortOptionsTeams: (state) => {
      state.selectedSortOptionsTeams = [];
    },
    setSearchValueTeams: (state, action) => {
      state.searchValTeams = action.payload;
    },
    resetSearchValueTeams: (state) => {
      state.searchValTeams = "";
    },
    setIsTeamsFilterDropdownOpen: (state) => {
      state.isTeamsFilterDropdownOpen = true;
    },
    resetIsTeamsFilterDropdownOpen: (state) => {
      state.isTeamsFilterDropdownOpen = false;
    }, // Filter Dropdown
    setSelectedDropdownItemTeams: (state, action) => {
      state.selectedDropdownItemTeams = action.payload;
    },
    resetSelectedDropdownItemTeams: (state) => {
      state.selectedDropdownItemTeams = "";
    },
    setSelectedItemIndexFilterTeams: (state, action) => {
      state.selectedItemIndexFilterTeams = action.payload;
    },
    resetSelectedItemIndexFilterTeams: (state) => {
      state.selectedItemIndexFilterTeams = "";
    },
    setSelectedItemIndexSortTeams: (state, action) => {
      state.selectedItemIndexSortTeams = action.payload;
    },
    resetSelectedItemIndexSortTeams: (state) => {
      state.selectedItemIndexSortTeams = "";
    },
    setSortByTeamsR: (state, action) => {
      state.sortByTeamsR = action.payload;
    },
    resetSortByTeamsR: (state) => {
      state.sortByTeamsR = "";
    },
    setSelectedFilterItemsTeams: (state, action) => {
      state.selectedFilterItemsTeams = action.payload;
    },
    resetSelectedFilterItemsTeams: (state) => {
      state.selectedFilterItemsTeams = "";
    },
    clearCurrentTeamsData: (state) => {
      state.getUniqueTeams = null;
    },
  },
  extraReducers: (builder) => {
    builder //
      .addCase(getTeamsThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTeamsThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.allDataTeams = action.payload;
      })
      .addCase(getTeamsThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      }) //
      .addCase(getTeamsByFilterThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTeamsByFilterThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.allDataTeams = action.payload;
        state.lastPage = action.payload.data.last_page;
        state.teams = [...state.teams, ...action.payload.data.data];
      })
      .addCase(getTeamsByFilterThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      }) //
      .addCase(getTeamsByIdThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTeamsByIdThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.getUniqueTeams = action.payload;
      })
      .addCase(getTeamsByIdThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      }) //
      .addCase(getTeamsNameThunk.pending, (state) => {
        state.loading === true;
      })
      .addCase(getTeamsNameThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.getTeamsName = action.payload;
      })
      .addCase(getTeamsNameThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      }) //
      .addCase(getProviderTeamsNameThunk.pending, (state) => {
        state.loading === true;
      })
      .addCase(getProviderTeamsNameThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.getTeamsName = action.payload;
      })
      .addCase(getProviderTeamsNameThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      }) //
      .addCase(getTeamsEmailThunk.pending, (state) => {
        state.loading === true;
      })
      .addCase(getTeamsEmailThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.getTeamsEmail = action.payload;
      })
      .addCase(getTeamsEmailThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      }) //

      .addCase(getProviderTeamsEmailThunk.pending, (state) => {
        state.loading === true;
      })
      .addCase(getProviderTeamsEmailThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.getTeamsEmail = action.payload;
      })
      .addCase(getProviderTeamsEmailThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      }) //
      .addCase(getTeamsPhoneThunk.pending, (state) => {
        state.loading === true;
      })
      .addCase(getTeamsPhoneThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.getTeamsPhone = action.payload;
      })
      .addCase(getTeamsPhoneThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      }) //

      .addCase(getProviderTeamsPhoneThunk.pending, (state) => {
        state.loading === true;
      })
      .addCase(getProviderTeamsPhoneThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.getTeamsPhone = action.payload;
      })
      .addCase(getProviderTeamsPhoneThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      }) //
      .addCase(getTeamsAssociateThunk.pending, (state) => {
        state.loading === true;
      })
      .addCase(getTeamsAssociateThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.getTeamsAssociate = action.payload;
      })
      .addCase(getTeamsAssociateThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      }) //
      .addCase(getProviderTeamsAssociateThunk.pending, (state) => {
        state.loading === true;
      })
      .addCase(getProviderTeamsAssociateThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.getTeamsAssociate = action.payload;
      })
      .addCase(getProviderTeamsAssociateThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      //Provider Unique Teams
      .addCase(getProviderTeamsByIdThunk.pending,(state)=>{
        state.loading= true;
      })
      .addCase(getProviderTeamsByIdThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.getUniqueTeams = action.payload;
      })
      .addCase(getProviderTeamsByIdThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })


  },
});

export const {
  setUserTypeIdForTeams,
  setFilterOptionsTeams,
  resetFilterOptionsTeams,
  setSortOptionsTeams,
  resetSortOptionsTeams,
  setSearchValueTeams,
  resetSearchValueTeams,
  setIsTeamsFilterDropdownOpen,
  resetIsTeamsFilterDropdownOpen,
  setSelectedDropdownItemTeams, // Filter
  resetSelectedDropdownItemTeams,
  setSelectedItemIndexFilterTeams,
  resetSelectedItemIndexFilterTeams,
  setSelectedItemIndexSortTeams,
  resetSelectedItemIndexSortTeams,
  setSortByTeamsR,
  resetSortByTeamsR,
  setSelectedFilterItemsTeams,
  resetSelectedFilterItemsTeams,
  clearCurrentTeamsData,
  // Page
  setTeamsPage,
  resetTeamsPage,
  resetTeamsData,
  setTeamsFilterItems,
  resetTeamsFilterItems,
} = teamsSlice.actions;
export default teamsSlice.reducer;
