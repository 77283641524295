import React from "react";
import { useParams } from "react-router-dom";

const Iframe = () => {
  const { id }: any = useParams();
  const source = window.atob(id);

  return <iframe src={source} width="100%" height={850}></iframe>;
};

export default Iframe;
