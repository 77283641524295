const UploadSvg = ({ className }: any) => {
  return (
    <div className={className}>
      {/* <svg
        width="34"
        height="29"
        viewBox="0 0 34 29"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.741521 19H2.23465V22.9601C2.23465 23.5885 2.25315 23.9958 2.29014 24.182C2.35403 24.4813 2.50537 24.7224 2.74413 24.9052C2.98626 25.0848 3.31583 25.1746 3.73283 25.1746C4.15655 25.1746 4.47603 25.0898 4.69126 24.9202C4.90648 24.7473 5.03595 24.5362 5.07967 24.2868C5.12339 24.0374 5.14525 23.6234 5.14525 23.0449V19H6.63838V22.8404C6.63838 23.7182 6.59802 24.3383 6.51731 24.7007C6.4366 25.0632 6.28695 25.3691 6.06837 25.6185C5.85314 25.8678 5.56393 26.0673 5.20074 26.217C4.83754 26.3633 4.36337 26.4364 3.77823 26.4364C3.07202 26.4364 2.53563 26.3566 2.16908 26.197C1.80588 26.0341 1.51835 25.8246 1.30649 25.5686C1.09463 25.3092 0.955066 25.0382 0.887807 24.7556C0.790283 24.3367 0.741521 23.7182 0.741521 22.9002V19Z"
          fill="#212121"
        />
        <path
          d="M7.9602 21.015H9.28183V21.793C9.45333 21.527 9.68537 21.3109 9.97795 21.1446C10.2705 20.9784 10.595 20.8953 10.9515 20.8953C11.5736 20.8953 12.1016 21.1363 12.5354 21.6185C12.9693 22.1006 13.1862 22.7722 13.1862 23.6334C13.1862 24.5179 12.9676 25.2062 12.5304 25.6983C12.0932 26.187 11.5636 26.4314 10.9414 26.4314C10.6455 26.4314 10.3765 26.3732 10.1343 26.2569C9.89556 26.1405 9.64334 25.941 9.37767 25.6584V28.3267H7.9602V21.015ZM9.36254 23.5736C9.36254 24.1687 9.48192 24.6093 9.72069 24.8953C9.95945 25.1779 10.2503 25.3192 10.5934 25.3192C10.9229 25.3192 11.197 25.1895 11.4156 24.9302C11.6342 24.6675 11.7435 24.2386 11.7435 23.6434C11.7435 23.0881 11.6308 22.6758 11.4055 22.4065C11.1802 22.1372 10.9011 22.0025 10.5681 22.0025C10.2218 22.0025 9.93423 22.1355 9.70555 22.4015C9.47687 22.6642 9.36254 23.0549 9.36254 23.5736Z"
          fill="#212121"
        />
        <path d="M14.1095 26.3117V19H15.5269V26.3117H14.1095Z" fill="#212121" />
        <path
          d="M16.4503 23.5885C16.4503 23.123 16.5663 22.6725 16.7983 22.2369C17.0304 21.8013 17.3583 21.4688 17.782 21.2394C18.2091 21.01 18.6849 20.8953 19.2095 20.8953C20.02 20.8953 20.6842 21.1563 21.2021 21.6783C21.7199 22.197 21.9789 22.8537 21.9789 23.6484C21.9789 24.4497 21.7166 25.1147 21.192 25.6434C20.6707 26.1687 20.0133 26.4314 19.2196 26.4314C18.7286 26.4314 18.2595 26.3217 17.8122 26.1022C17.3683 25.8828 17.0304 25.5619 16.7983 25.1397C16.5663 24.714 16.4503 24.197 16.4503 23.5885ZM17.903 23.6633C17.903 24.1887 18.0292 24.591 18.2814 24.8703C18.5336 25.1496 18.8447 25.2893 19.2146 25.2893C19.5845 25.2893 19.8939 25.1496 20.1427 24.8703C20.395 24.591 20.5211 24.1854 20.5211 23.6534C20.5211 23.1347 20.395 22.7357 20.1427 22.4564C19.8939 22.1771 19.5845 22.0374 19.2146 22.0374C18.8447 22.0374 18.5336 22.1771 18.2814 22.4564C18.0292 22.7357 17.903 23.138 17.903 23.6633Z"
          fill="#212121"
        />
        <path
          d="M23.9464 22.6309L22.6601 22.4015C22.8047 21.8894 23.0535 21.5104 23.4066 21.2643C23.7597 21.0183 24.2844 20.8953 24.9805 20.8953C25.6127 20.8953 26.0835 20.9701 26.3929 21.1197C26.7023 21.266 26.9192 21.4539 27.0436 21.6833C27.1714 21.9094 27.2353 22.3267 27.2353 22.9352L27.2202 24.5711C27.2202 25.0366 27.242 25.3807 27.2858 25.6035C27.3328 25.8229 27.4186 26.059 27.543 26.3117H26.1407C26.1037 26.2186 26.0583 26.0806 26.0045 25.8978C25.9809 25.8146 25.9641 25.7598 25.954 25.7332C25.7119 25.9659 25.453 26.1405 25.1772 26.2569C24.9015 26.3732 24.6072 26.4314 24.2944 26.4314C23.7429 26.4314 23.3074 26.2835 22.988 25.9875C22.6718 25.6916 22.5138 25.3175 22.5138 24.8653C22.5138 24.5661 22.5861 24.3001 22.7307 24.0673C22.8753 23.8313 23.0771 23.6517 23.336 23.5287C23.5983 23.4023 23.975 23.2926 24.466 23.1995C25.1284 23.0765 25.5875 22.9618 25.8431 22.8554V22.7157C25.8431 22.4464 25.7758 22.2552 25.6413 22.1421C25.5068 22.0258 25.2529 21.9676 24.8796 21.9676C24.6274 21.9676 24.4306 22.0175 24.2894 22.1172C24.1482 22.2136 24.0338 22.3849 23.9464 22.6309ZM25.8431 23.7681C25.6615 23.8279 25.3739 23.8994 24.9805 23.9825C24.587 24.0657 24.3298 24.1471 24.2087 24.2269C24.0237 24.3566 23.9313 24.5212 23.9313 24.7207C23.9313 24.9169 24.0052 25.0865 24.1532 25.2294C24.3012 25.3724 24.4895 25.4439 24.7182 25.4439C24.9738 25.4439 25.2176 25.3608 25.4496 25.1945C25.6211 25.0682 25.7338 24.9135 25.7876 24.7307C25.8246 24.611 25.8431 24.3832 25.8431 24.0474V23.7681Z"
          fill="#212121"
        />
        <path
          d="M33.3442 26.3117H32.0277V25.5337C31.8091 25.8362 31.5501 26.0623 31.2508 26.212C30.9549 26.3583 30.6556 26.4314 30.3529 26.4314C29.7375 26.4314 29.2095 26.187 28.769 25.6983C28.3318 25.2062 28.1132 24.5212 28.1132 23.6434C28.1132 22.7456 28.3268 22.064 28.7539 21.5985C29.181 21.1297 29.7207 20.8953 30.3731 20.8953C30.9717 20.8953 31.4896 21.1413 31.9268 21.6334V19H33.3442V26.3117ZM29.561 23.5486C29.561 24.1139 29.64 24.5229 29.798 24.7756C30.0267 25.1413 30.3462 25.3242 30.7565 25.3242C31.0827 25.3242 31.3601 25.1879 31.5888 24.9152C31.8175 24.6392 31.9318 24.2286 31.9318 23.6833C31.9318 23.0748 31.8208 22.6376 31.5989 22.3716C31.3769 22.1022 31.0928 21.9676 30.7464 21.9676C30.4101 21.9676 30.1276 22.1006 29.8989 22.3666C29.6736 22.6293 29.561 23.0233 29.561 23.5486Z"
          fill="#212121"
        />
        <path d="M0 27.9277H34V29H0V27.9277Z" fill="#212121" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11 5.5L12.4868 6.8629L15.9487 3.6895L15.9487 10.0363C15.9487 10.5685 16.4194 11 17 11C17.5806 11 18.0513 10.5685 18.0513 10.0363L18.0513 3.68949L21.5132 6.86289L23 5.5L17 -2.6167e-06L11 5.5Z"
          fill="#212121"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.0014 11V12C11.0014 12.5523 11.4492 13 12.0014 13L22.0014 13C22.5537 13 23.0014 12.5523 23.0014 12V11H25.0014V12C25.0014 13.6569 23.6583 15 22.0014 15L12.0014 15C10.3446 15 9.00145 13.6569 9.00145 12V11H11.0014Z"
          fill="#212121"
        />
      </svg> */}
      <svg
        width="16"
        height="15"
        viewBox="0 0 16 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.99998 5.5L3.48678 6.8629L6.94866 3.6895L6.94866 10.0363C6.94866 10.5685 7.41936 11 7.99998 11C8.58061 11 9.05131 10.5685 9.05131 10.0363L9.05131 3.68949L12.5132 6.86289L14 5.5L7.99998 -2.6167e-06L1.99998 5.5Z"
          fill="#212121"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.00145 11V12C2.00145 12.5523 2.44916 13 3.00145 13L13.0014 13C13.5537 13 14.0014 12.5523 14.0014 12V11H16.0014V12C16.0014 13.6569 14.6583 15 13.0014 15L3.00145 15C1.3446 15 0.00144958 13.6569 0.00144958 12V11H2.00145Z"
          fill="#212121"
        />
      </svg>
    </div>
  );
};

export default UploadSvg;
