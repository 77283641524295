import { createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  openFormClients: false,
  openWarningSection: false,
};

const documentClientSlice = createSlice({
  name: "documentClientSlice",
  initialState,
  reducers: {
    setOpenFormByClickClient: (state) => {
      state.openFormClients = true;
    },
    resetOpenFormByClickClient: (state) => {
      state.openFormClients = false;
    },
    setWarningMessage: (state, action) => {
      state.openWarningSection = action.payload;
    },
  },
});

export const {
  setOpenFormByClickClient,
  resetOpenFormByClickClient,
  setWarningMessage,
} = documentClientSlice.actions;
export default documentClientSlice.reducer;
