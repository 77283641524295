// RosterCapsuleComponent.jsx
import RosterCapsule from './RosterCapsule';

const RosterCapsuleComponent = ({
  handleClick,
  handleDragStart,
  handleDecrementEndTime,
  handleIncrementEndTime,
  handleDecrementStartime,
  handleIncrementStartTime,
  rosterItemIndex,
  matchingIndex,
  matchingItem,
  initialXRefLeft,
  initialXRefRight,
  minute
}: any) => (
  <div
    className="roster_capsule_wrapper"
    onClick={() => handleClick(rosterItemIndex, matchingItem)}
    onDoubleClick={(e) => e.stopPropagation()}
  >
    <RosterCapsule
      handleDragStart={handleDragStart}
      handleDecrementEndTime={handleDecrementEndTime}
      handleIncrementEndTime={handleIncrementEndTime}
      handleDecrementStartime={handleDecrementStartime}
      handleIncrementStartTime={handleIncrementStartTime}
      rosterItemIndex={rosterItemIndex}
      matchingIndex={matchingIndex}
      matchingItem={matchingItem}
      initialXRefLeft={initialXRefLeft}
      initialXRefRight={initialXRefRight}
      minute={minute}
    />
  </div>
);

export default RosterCapsuleComponent;
