import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../../services/api";

const initialState: any = {
  getProspectsAssociationNames: [],
};

export const getProspectsAssociationNamesThunk = createAsyncThunk(
  "clients/prospectsAssociations/fetchNames",
  async ({ id, model }: any) => {
    try {
      const response = await api.get(
        `/associations/get_names?type=${model}&id=${id}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const deleteAssociationThunk = createAsyncThunk(
  "clients/associations/deleteAssociation",
  async (id: any, { rejectWithValue }) => {
    try {
      const response = await api.post("/associations/delete_association", {
        id,
      });
      if (response.data.status === "success") {
        return response.data;
      } else {
        return rejectWithValue("Failed to delete association");
      }
    } catch (error) {
      return rejectWithValue(
        "An error occurred while deleting the association"
      );
    }
  }
);

const associationProspectsSlice = createSlice({
  name: "associationsProspectsSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      .addCase(getProspectsAssociationNamesThunk.pending, (state) => {
        state.loading = true;
        state.getAssociationNames = [];
        state.error = null;
      })
      .addCase(getProspectsAssociationNamesThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.getProspectsAssociationNames = action.payload;
        state.error = null;
      })
      .addCase(getProspectsAssociationNamesThunk.rejected, (state, action) => {
        state.loading = false;
        state.getAssociationNames = [];
        state.error = action.error.message;
      });
  },
});

export const {} = associationProspectsSlice.actions;
export default associationProspectsSlice.reducer;
