import React, { useContext, useEffect, useRef, useState } from "react";
import {
  BallSvg,
  GreenCircleSvg,
  PlusSvg,
  SlashSvg,
  ZeroSvg,
} from "../Data/SVGs";
import "../Styles/ClinicalNotes.css";
import { Modal, ModalOverlay } from "@chakra-ui/react";
import VerticalModal from "../../../../../../../../components/CommonModal/VerticalModal";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../Controller/redux/Hooks";
import {
  setClinicalDetails,
  setClinicalDetailsInfo,
} from "../../../../../../../../../Controller/redux/features/ClinicalFeature/clinicalSlice";
import { setClinicalAddNote } from "../../../../../../../../../Controller/redux/features/ClientFeature/clientsSlice";
import PlusSVG from "../../FinanceTab/Data/PlusSVG";

interface ClientListProps {
  color?: string;
  setexpandedNoteItem?: () => void;
  expandedNoteItem?: boolean;
  note: string | any;
  note_1: string | any;
  note_2: string | any;
  color__Id?: number | any;
  full_name?: string | any;
  className?: string | any;
  Date?: string | Date;
  Time?: string | Date;
  noteModal?: boolean;
  noteType?: string;
  onClickPlus?: any;
  handleInputField?: any;
  textAreaData?: string;
  NoteInfo?: any;
  setshowGoalsModal?: any;
}

// NORMAL VIEW OF NOTE ITEM
export const ClinicalListItem = ({
  color,
  // setexpandedNoteItem,
  expandedNoteItem = false,
  note,
  note_1,
  note_2,
  color__Id,
  full_name,
  className,
  Date = "21-10-2023, 16:00Hrs",
  Time = " Today 9:15am",
  noteModal = false,
  noteType,
  onClickPlus = () => {},
  handleInputField,
  textAreaData,
  NoteInfo,
  setshowGoalsModal,
}: ClientListProps) => {
  const dispatch = useAppDispatch();
  const noteRef = useRef<any>();
  const { user } = useAppSelector((state) => state.common);
  const userFullName = `${user?.first_name} ${user?.last_name}`;

  const [noteDetails, setNoteDetails] = useState({
    subjectValue: "",
    pinkNoteTriggered: false,
  });

  const handleOverlayClick = () => {
    setNoteDetails((prevDetails) => ({
      ...prevDetails,
      pinkNoteTriggered: false,
    }));
  };

  const [selectedNoteType, setSelectedNoteType] = useState("Default");

  useEffect(() => {
    dispatch(
      setClinicalDetails({
        note: noteDetails.subjectValue,
        note_tag: selectedNoteType,
      })
    );
  }, [selectedNoteType, noteDetails.subjectValue]);

  const { selectedClinicalNoteInfo } = useAppSelector(
    (state) => state.clinical
  );

  useEffect(() => {
    if (selectedClinicalNoteInfo?.id) {
      setSelectedNoteType(selectedClinicalNoteInfo?.note_tag);
    }
  }, [selectedClinicalNoteInfo]);

  return (
    <div
      className={`${className} note__item`}
      ref={noteRef}
      onClick={() => {
        if (noteDetails.pinkNoteTriggered) {
          setNoteDetails((prevDetails) => ({
            ...prevDetails,
            pinkNoteTriggered: false,
          }));
        } else if (color === "#FFE8F8") {
          // setNoteDetails((prevDetails) => ({
          //   ...prevDetails,
          //   pinkNoteTriggered: true,
          // }));
          dispatch(setClinicalDetailsInfo(NoteInfo));
          setshowGoalsModal(true);
          // console.log("hereerererere clicked ", NoteInfo);
        } else if (color === "#faf9ac") {
          dispatch(setClinicalDetailsInfo(NoteInfo));
          dispatch(setClinicalAddNote(true));
        }
      }}
    >
      {noteDetails.pinkNoteTriggered && (
        <Modal
          isOpen={noteDetails.pinkNoteTriggered}
          isCentered
          closeOnOverlayClick={true}
          onClose={handleOverlayClick}
        >
          <ModalOverlay onClick={handleOverlayClick} zIndex={10} />
          <div onClick={(e: any) => e.stopPropagation()}>
            <VerticalModal
              type={"clinical_list_item"}
              mainType={"clinical__note"}
              note_1={note_1}
              note_2={note_2}
              colorId={color__Id}
            />
          </div>
        </Modal>
      )}
      {!noteModal && <div className="date__time">{Time}</div>}
      <div className="note__area" style={{ backgroundColor: color || "white" }}>
        <div className="upper__segment__note">
          <GreenCircleSvg />
          <SlashSvg />
          {!noteModal && (
            <div className="note__sub__title-1">
              {color__Id === 1 || color__Id === 2 || color__Id === 3
                ? "Special Note"
                : "Standard Note"}
            </div>
          )}
          {noteModal && expandedNoteItem ? (
            <select
              name="`type`"
              className="note_type_select_tag"
              id="noteType"
              onChange={(e) => {
                setSelectedNoteType(e?.target?.value);
              }}
              value={selectedNoteType}
            >
              <option value="Default">Default</option>
              <option value="Progress">Progress</option>
              <option value="General">General</option>
              <option value="Finance">Finance</option>
              <option value="Medical">Medical</option>
            </select>
          ) : (
            <div className="note__sub__title-1">{noteType}</div>
          )}
          <div className="note__sub__title-1-client__details">
            {/* <img
              src={ClientImage}
              alt="client-img"
              className="note__sub__title-1-client__image"
            /> */}
            {user?.profile_image_s3 ? (
              <img
                src={user?.profile_image_s3}
                alt="profile"
                className="note__sub__title-1-client__image"
              />
            ) : (
              <div className="initials__name_note">
                {`${user?.first_name[0] || ""}`}
              </div>
            )}
            <span className="note__sub__title-1-client__name">
              {full_name ? full_name : userFullName}{" "}
              <span style={{ marginLeft: "3px" }}>{Date}</span>
            </span>
          </div>
          <div className="upper__segment__note-top-right__icons">
            {!noteModal && (
              <>
                <ZeroSvg />
                <BallSvg />
              </>
            )}
            {noteModal && <PlusSVG onClick={onClickPlus} />}
          </div>
        </div>
        <p className="middle__segment__note__subject-title">{note}</p>
        <textarea
          className={`middle__segment__note__subject-details ${
            expandedNoteItem ? "expanded__note__item" : "shrinked__note__item "
          }`}
          rows={expandedNoteItem ? 10 : 2}
          placeholder={
            expandedNoteItem
              ? "Summary text to show the subtitle text of any event."
              : ""
          }
          value={noteModal ? textAreaData : noteDetails.subjectValue}
          style={{ backgroundColor: color || "white" }}
          onChange={(e) => {
            setNoteDetails({
              ...noteDetails,
              subjectValue: e.target.value,
            });
            handleInputField(e.target.value);
          }}
        />
      </div>
    </div>
  );
};
