import { Fragment, useEffect, useRef, useState } from "react";
// import { backgroundColors } from "../Data/BackgroundColors";
import "../Styles/ClinicalNotes.css";
// import { ClinicalListItem } from "./ClinicalListItem";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../Controller/redux/Hooks";
import {
  createClinicalNoteThunk,
  getAllClinicalNotes,
  resetClinicalPage,
  resetClinicalRecords,
  setClinicalPage,
  updateClinicalNoteThunk,
} from "../../../../../../../../../Controller/redux/features/ClinicalFeature/clinicalSlice";
import { useParams } from "react-router-dom";
import _ from "lodash";
import ClinicalNote from "../../../../../../../../components/ClinicalProgress/Components/ClinicalNote";
import { formatDateAndTime } from "../../../../../../../../../Model/utils/Helper/formatTime";
import { toast } from "react-toastify";
import PlusSVG from "../../FinanceTab/Data/PlusSVG";
import GroupButton from "../../../../../../../Comms/Component/GroupBTN/Index";
// import {
//   getClientUnreadMessagesCommsThunk,
//   getUnreadMessagesCommsThunk,
// } from "../../../../../../../../../Controller/redux/features/CommsFeature/commsSlice";

const ClinicalNotes = ({
  setshowGoalsModal,
  handleOverlayClickOnClilcalNote = () => {},
  isFromComms,
  selectedItemTitle,
  showGoalsModal
}: any) => {
  const [showModal, setshowModal] = useState(false);

  const id: any = useParams()?.id;
  const [clientId, setclientId] = useState<any>("");
  const dispatch = useAppDispatch();
  
  useEffect(() => {
    if (id) {
      let realId = window?.atob(id);
      setclientId(realId);
    }
  }, [id]);

  const { ClinicalNotesData, lastPage, ClinicalPage, isLoading } = useAppSelector(
    (state) => state.clinical
  );

  useEffect(() => {
  }, []);
  useEffect(() => {
    if (clientId && ClinicalPage) {
      dispatch(
        getAllClinicalNotes({ user_id: clientId, ClinicalPage: ClinicalPage })
      );
    }
  }, [clientId, ClinicalPage]);

  const [ClinicalNotesRecords, setClinicalNotesRecords] = useState<any>([]);

  useEffect(() => {
    if (ClinicalNotesData) {
      const uniqueClientData = ClinicalNotesData?.filter(
        (item: any, index: number, array: any) => {
          return array?.findIndex((obj: any) => obj.id === item.id) === index;
        }
      );
      setClinicalNotesRecords(uniqueClientData);
    }
  }, [ClinicalNotesData]);

  // // console.log(ClinicalNotesRecords,'ClinicalNotesRecords')
  const clinicalNotesSectionRef = useRef<any>(null);

  const handleScroll = () => {
    const { scrollTop, clientHeight, scrollHeight }: any =
      clinicalNotesSectionRef.current;

    if (
      scrollTop + clientHeight >= scrollHeight - 10 &&
      ClinicalPage < lastPage
    ) {
      dispatch(setClinicalPage());
    }
  };

  const debouncedApiCall = _.debounce(handleScroll, 300);

  useEffect(() => {
    const scrollableElement: any = clinicalNotesSectionRef.current;
    scrollableElement.addEventListener("scroll", debouncedApiCall);
    return () =>
      scrollableElement.removeEventListener("scroll", debouncedApiCall);
  }, [debouncedApiCall]);

  const [CurrentActiveIndex, setCurrentActiveIndex] = useState<any>(null);
  const activeNoteRef = useRef<any>(null);
  const dynamicColor = useAppSelector((state) => state?.common?.dynamicColor);
  const [isThereAnyChanges, setisThereAnyChanges] = useState(false);

  const handleOnChangeNote = (e: any) => {
    let updatedClinicalArray: any = ClinicalNotesRecords.map(
      (res: any, index: number) => {
        if (index == CurrentActiveIndex) {
          return {
            ...res,
            note: e?.target?.value,
          };
        }
        return res;
      }
    );
    setisThereAnyChanges(true);
    setClinicalNotesRecords(updatedClinicalArray);
  };
  const handleOnChangeNoteTrafficLight = (value: any) => {
    let updatedClinicalArray: any = ClinicalNotesRecords.map(
      (res: any, index: number) => {
        if (index == CurrentActiveIndex) {
          return {
            ...res,
            traffic_light: value,
          };
        }
        return res;
      }
    );
    setisThereAnyChanges(true);
    setClinicalNotesRecords(updatedClinicalArray);
  };
  const onChangeNoteType = (e: any) => {
    let updatedClinicalArray: any = ClinicalNotesRecords.map(
      (res: any, index: number) => {
        if (index == CurrentActiveIndex) {
          return {
            ...res,
            note_tag: e?.target?.value,
          };
        }
        return res;
      }
    );
    setisThereAnyChanges(true);
    setClinicalNotesRecords(updatedClinicalArray);
  };

  const handleAddNote = () => {
    if(CurrentActiveIndex !== null){
      toast.warn("Can't open another note while one is open!")
      return
    }
    let cloneClinicalNotesRecords = [...ClinicalNotesRecords];
    cloneClinicalNotesRecords.unshift({
      id: null,
      goal_id: null,
      note_type: "Progress",
      note: "",
      note_tag: "Default",
      staff_user: null,
    });
    setClinicalNotesRecords(cloneClinicalNotesRecords);
    setCurrentActiveIndex(0);
    setisThereAnyChanges(false);
  };
  let storeCurrentNoteInfo = ClinicalNotesRecords[CurrentActiveIndex];

  // console.warn({
  //   storeCurrentNoteInfo,
  // });
  const { date, time } = formatDateAndTime(new Date());

  let noteDateTime = `${date} ${time.split(" ")[0]}`;
  const handleAddUpdateNote = async (event: any) => {
    // console.log("gere");

    if (storeCurrentNoteInfo.note_type === "Default") {
      toast.warn("Please Select A Note Type Before Saving.");
      return;
    }
    // Update State
    const { date, time } = formatDateAndTime(new Date());

    if (!storeCurrentNoteInfo?.note || !storeCurrentNoteInfo?.note_tag) {
      return;
    }

    if (storeCurrentNoteInfo?.id) {
      if (!isThereAnyChanges) {
        return;
      }
      try {
        const response = await dispatch(
          updateClinicalNoteThunk({
            ...storeCurrentNoteInfo,
            user_id: clientId,
            activity_note_id: storeCurrentNoteInfo?.id,
            note_date_time: `${date} ${time.split(" ")[0]}`,
          })
        );
        if (response?.payload?.status === "success") {
          toast.success("Note updated successfully", {
            progressStyle: { backgroundColor: dynamicColor },
            icon: (
              <span
                style={{
                  marginRight: "8px",
                  borderRadius: "50%",
                  width: "7rem",
                  height: "35px",
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: dynamicColor,
                  backgroundColor: "white",
                  fontSize: "1.5rem",
                  border: `3px solid ${dynamicColor}`,
                }}
              >
                ✓
              </span>
            ),
          });
        }
      } catch (error: any) {
        console.error(error);
      } finally {
        dispatch(getAllClinicalNotes({ user_id: clientId }));
        setCurrentActiveIndex(null);
      }
    } else {
      //  Add State
      try {
        const response = await dispatch(
          createClinicalNoteThunk({
            ...storeCurrentNoteInfo,
            user_id: clientId,
            note_date_time: `${date} ${time.split(" ")[0]}`,
          })
        );
        if (response?.payload?.status === "success") {
          toast.success("Note Created Successfully", {
            progressStyle: { backgroundColor: dynamicColor },
            icon: (
              <span
                style={{
                  marginRight: "8px",
                  borderRadius: "50%",
                  width: "7rem",
                  height: "35px",
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: dynamicColor,
                  backgroundColor: "white",
                  fontSize: "1.5rem",
                  border: `3px solid ${dynamicColor}`,
                }}
              >
                ✓
              </span>
            ),
          });
        }
      } catch (error: any) {
        console.error(error);
      } finally {
        dispatch(getAllClinicalNotes({ user_id: clientId }));
        setCurrentActiveIndex(null);
        console.log("created");
      }
    }
  };

  const debouncedApiCallNote = _.debounce(handleAddUpdateNote, 300);

  useEffect(() => {
    document
      ?.getElementById("saveBTN")
      ?.addEventListener("click", debouncedApiCallNote);
    return () => {
      document
        ?.getElementById("saveBTN")
        ?.removeEventListener("click", debouncedApiCallNote);
    };
  }, [clinicalNotesSectionRef, CurrentActiveIndex]);

  const [SelectedCurrentIndexInfo, setSelectedCurrentIndexInfo] = useState<any>(
    {}
  );
  const [sortType, setSortType] = useState("desc");
  const [selectedItemsArr, setselectedItemsArr] = useState<any>([]);
  const [attachments, setAttachments] = useState([]);



  return (
    <div
      className={`
    ${isFromComms && "comms__notes"}
    clinical__note__updates__wrapper`}
    >
      <span
        className="note__update__title"
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {!isFromComms && (
          <>
            <span>Clinical Notes & Updates: </span>
          </>
        )}
        <GroupButton
          isClinicalNote={true}
          selectedItem={0}
          setSortTypeComms={setSortType}
          setselectedItemsArr1={setselectedItemsArr}
          setAttachments={setAttachments}
          clientId={clientId}
          setIsSubTaskOpen={() => {}}
          handleAddNote={() => handleAddNote()}
        />
        {/* <PlusSVG
          marginLeft="30px"
          height="26"
          width="26"
          onClick={() => handleAddNote()}
        />{" "} */}
      </span>

      <div
        className={`${isFromComms ? "notes__container" : ""}   `}
        style={{
          width: selectedItemTitle === "Notes" ? "430px" : "100%",
        }}
        //@ts-ignore
        ref={clinicalNotesSectionRef}
      >
     
        {ClinicalNotesRecords &&  ClinicalNotesRecords.map((elem: any, index: number) => {
          if (CurrentActiveIndex == index) {
            return (
              <div ref={activeNoteRef} key={index}>
                <ClinicalNote
                  activeNote
                  setshowModal={setshowModal}
                  noteDateTime={noteDateTime}
                  currInfo={storeCurrentNoteInfo}
                  showModal={showModal}
                  clientId={clientId}
                  editiable={false}
                  handleOverlayClickOnClilcalNote={
                    handleOverlayClickOnClilcalNote
                  }
                  isClinicalNote={true}
                  clinicalGoalsNote={elem?.note}
                  elem={elem}
                  isExpanded={CurrentActiveIndex == index}
                  handleOnClick={() => {
                    setCurrentActiveIndex(index);
                  }}
                  dateTime={elem?.note_date_time}
                  noteType={
                    elem?.note_type == "Advance Care"
                      ? "Clinical Progress"
                      : elem?.note_tag
                  }
                  userName={
                    elem?.staff_user
                      ? `${elem?.staff_user?.first_name} ${elem?.staff_user?.last_name}`
                      : null
                  }
                  color={elem?.goal_id == null ? "#FFE8F8" : "#faf9ac"}
                  setshowGoalsModal={setshowGoalsModal}
                  showGoalsModal={showGoalsModal}
                  NoteInfo={elem}
                  onClickEnable={false}
                  dropdown={true}
                  dropdownValue={elem?.note_tag}
                  note={elem?.note}
                  onChangeTextNote={handleOnChangeNote}
                  onChangeNoteType={onChangeNoteType}
                  // setselectedIndex={setCurrentActiveIndex}
                  saveBtn={isThereAnyChanges && elem?.note !== ""}
                  onClickClose={() => {
                    setshowModal(false);
                    setCurrentActiveIndex(null);
                    let updatedClinicalArray: any = ClinicalNotesRecords.map(
                      (res: any, index: number) => {
                        if (index == CurrentActiveIndex) {
                          return {
                            ...SelectedCurrentIndexInfo,
                          };
                        }
                        return res;
                      }
                    ).filter((res: any) => !!res?.id);
                    setClinicalNotesRecords(updatedClinicalArray);
                  }}
                  allData={ClinicalNotesRecords}
                  statusColor={
                    elem.traffic_light == 0
                      ? "#ef3a34"
                      : elem.traffic_light == 1
                      ? "#fbbf14"
                      : elem.traffic_light == 2
                      ? "#2ad400"
                      : "#fbbf14"
                  }
                  handleChangeTrafficLight={(value: string) => {
                    handleOnChangeNoteTrafficLight(value);
                  }}
                />
              </div>
            );
          }
          return (
            <Fragment key={index}>
              <ClinicalNote
                // setselectedIndex={setCurrentActiveIndex}
                isFromClinicalProgressModal={false}
                clinicalGoalsNote={elem?.note}
                noteDateTime={noteDateTime}
                showGoalsModal={showGoalsModal}

                allData={ClinicalNotesRecords}

                currInfo={storeCurrentNoteInfo}
                setshowModal={setshowModal}
                // dropdown={true}

                showModal={showModal}
                setCurrentActiveIndex={setCurrentActiveIndex}
                index={index}
                clientId={clientId}
                dropdownValue={elem?.note_tag}
                elem={elem}
                note={elem?.note}
                handleOnClick={() => {
                  if (!CurrentActiveIndex) {
                    setCurrentActiveIndex(index);
                    setisThereAnyChanges(false);
                    setSelectedCurrentIndexInfo(elem);
                  }
                }}
                dateTime={elem?.note_date_time}
                noteType={
                  elem?.note_type == "Advance Care"
                    ? "Clinical Progress"
                    : elem?.note_tag
                }
                userName={`${elem?.staff_user?.first_name} ${elem?.staff_user?.last_name}`}
                color={ elem?.note_type == "Advance Care"  ? "#FFE8F8" : "#faf9ac"}
                setshowGoalsModal={setshowGoalsModal}
                NoteInfo={elem}
                onClickEnable={true}
                onClickPlus={(e: any) => {
                  if (!CurrentActiveIndex) {
                    handleAddNote();
                  }
                }}
                statusColor={
                  elem.traffic_light == 0
                    ? "#ef3a34"
                    : elem.traffic_light == 1
                    ? "#fbbf14"
                    : elem.traffic_light == 2
                    ? "#2ad400"
                    : "#fbbf14"
                }
              />
            </Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default ClinicalNotes;
