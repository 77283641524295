// @ts-nocheck
export function getTimeDifferenceInMinutes(startTime, endTime) {
  if (!startTime || !endTime) {
    console.error("Invalid startTime or endTime provided.");
    return 0;
  }

  const startParts = startTime.match(/(\d+):(\d+) (\w+)/);
  const endParts = endTime.match(/(\d+):(\d+) (\w+)/);

  if (!startParts || !endParts) {
    console.error("Invalid startTime or endTime format.");
    return 0; 
  }

  let startHour = parseInt(startParts[1]);
  const startMinute = parseInt(startParts[2]);
  const startMeridiem = startParts[3];

  let endHour = parseInt(endParts[1]);
  const endMinute = parseInt(endParts[2]);
  const endMeridiem = endParts[3];

  if (startMeridiem === "PM" && startHour !== 12) {
    startHour += 12;
  }
  if (endMeridiem === "PM" && endHour !== 12) {
    endHour += 12;
  }

  if (startMeridiem === "AM" && startHour === 12) {
    startHour = 0;
  }
  if (endMeridiem === "AM" && endHour === 12) {
    endHour = 0;
  }

  let differenceInMinutes =
    endHour * 60 + endMinute - (startHour * 60 + startMinute);

  if (differenceInMinutes < 0) {
    differenceInMinutes += 24 * 60;
  }

  return differenceInMinutes;
}

export function calculateWidthInPixels(startTime, endTime) {
  const differenceInMinutes = getTimeDifferenceInMinutes(startTime, endTime);
  const differenceInHours = differenceInMinutes / 60;
  const widthInPixels = differenceInHours * 100;
  return widthInPixels;
}

