import React from "react";
import "./index.css";

import useWindowSize from "../../../../Model/utils/useWindowSize";
import { useEffect, useState } from "react";

interface ContentInterface {
  children: string | JSX.Element | JSX.Element[];
}

const Content = ({ children }: ContentInterface) => {
    const { width } = useWindowSize();

    const [isMobileScreen, setIsMobileScreen] = useState<boolean>(false);

    useEffect(() => {
      if (width <= 600) {
        setIsMobileScreen(true);
      } else {
        setIsMobileScreen(false);
      }
    }, [width, children]);


  return (
    <div className={isMobileScreen ? "" : "content__wrapper"}>
      <div className={isMobileScreen ? "" : "content"}>{children}</div>
    </div>
  );
};

export default Content;
