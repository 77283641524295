import "../Styles/ClinicalComponents.css";
import "../Styles/Capsule.css";
import { SearchSVG } from "../../Breadcrumb/Data";
import ClientStatusCircleSvg from "../../../../Model/Assets/SVG/WidgetVillage/ClientStatusCircleSvg";

export const NormalCapsule = ({ className, title, image }: any) => {
  return (
    <div
      title={title?.length > 12 && title}
      className={`${className} normal_capsule_container capsule_container`}
    >
      <span className="normal_text">{title?.slice(0, 12)}</span>
      {image ? (
        <img className="capsule_img" src={image} alt="profile" />
      ) : (
        <div className="capsule_initails ">{`${title ? title[0] : ""}`}</div>
      )}
    </div>
  );
};

export const SearchCapsule = ({ className }: any) => {
  return (
    <div className={`${className} capsule_container  search_capsule_container`}>
      <SearchSVG width="18" height="18" />
    </div>
  );
};
export const TextCapsule = ({ className, title }: any) => {
  return (
    <div className={`${className} text_capsule_container  `}>
      <span className="text_capsule">{title}</span>
    </div>
  );
};

export const TaggedCapsule = ({
  className,
  title,
  image,
  count = 0,
  isTaggedCapsule,
}: any) => {
  return (
    <div className={`${className} normal_capsule_container tagged_container`}>
      <span className="normal_text">{title}</span>
      {image ? (
        <img className="capsule_img" src={image} alt="profile" />
      ) : (
        <div className="capsule_initails ">{`${title[0]}`}</div>
      )}
      {!isTaggedCapsule && (
        <>
          <ClientStatusCircleSvg className="circular_tag_com" count={count} />
        </>
      )}
    </div>
  );
};
