import React from "react";
import "./login.css";
import { useEffect, useState, Fragment, useRef } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { LogoLeft } from "../../../components/LogoLeft/LogoLeft";
import vector from "../../../../Model/Assets/images/Vector.png";
import {
  getDomainDetailsThunk,
  getUserTypesThunk,
} from "../../../../Controller/redux/features/CommonFeature/commonSlice";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../Controller/redux/Hooks";

// function initialFormValues() {
//   return {
//     username: "",
//     password: "",
//   };
// }

import { loginThunk } from "../../../../Controller/redux/features/AuthFeature/authSlice";
import { Spinner } from "@chakra-ui/react";
import { toast } from "react-toastify";

export function Login() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getDomainDetailsThunk());
  }, []);

  const { fromGetDomainDetails } = useAppSelector((state) => state?.common);

  const navigate = useNavigate();

  const [loginRequestStatus, setLoginRequestStatus] = useState("success");

  const {
    register: validation,
    handleSubmit: handlevalidation,
    formState: { errors },
  } = useForm();

  const userNameRef = useRef<HTMLInputElement | null>(null);
  const userPasswordRef = useRef<HTMLInputElement | null>(null);

  const { ref: usernameRef, ...restusername } = validation("username");
  const { ref: passwordRef, ...restpassword } = validation("password");

  async function handleLoginSubmit(e: any) {
    setLoginRequestStatus("loading");
    try {
      const response = await dispatch(loginThunk(e));
      if (response.type === "auth/login/rejected") {
        toast.error("Either Username or Password is Incorrect!");
        setTimeout(() => {
          dispatch(getUserTypesThunk());
        }, 3000);
      } else {
        setLoginRequestStatus("success");
        dispatch(getUserTypesThunk());
      }
    } catch (err) {}

    navigate("/");
  }

  const forgotPassword = () => {
    navigate("/forgot-password");
  };

  return (
    <Fragment>
      <div className="main-section main-section-display login-screen">
        <LogoLeft />

        <div id="wrapper-main">
          <div className="login_section_right">
            <span>v7.0.5</span>
            <div className="login-content-section">
              <div className="login-heading">
                <div className="logo-section">
                  <div>
                    <img
                      src={fromGetDomainDetails?.logo}
                      alt="logo"
                      className="imf-fluid display-block-img"
                    />
                  </div>
                </div>
              </div>
              <form onSubmit={handlevalidation(handleLoginSubmit)}>
                <div
                  className={`form-section ${
                    errors.username || errors.password ? "changeColor" : ""
                  }`}
                >
                  <div className="form-group error ">
                    <h3>
                      Login to Account - {fromGetDomainDetails?.whitelabel_name}
                    </h3>
                    <div>
                      <label>Enter Your Username & Password</label>
                      <div className="input-section">
                        <input
                          {...restusername}
                          type="text"
                          id="username"
                          placeholder="Username"
                          {...validation("username", { required: true })}
                          ref={(e) => {
                            usernameRef(e);
                            userNameRef.current = e;
                          }}
                        />
                      </div>
                      {errors.username && (
                        <span className="errorname">Username is required</span>
                      )}
                    </div>
                    <br />
                    <div className="form-group error error-svg">
                      <div className="input-section">
                        <input
                          {...restpassword}
                          type="password"
                          placeholder="Password"
                          disabled={loginRequestStatus === "loading"}
                          {...validation("password", { required: true })}
                          ref={(e) => {
                            passwordRef(e);
                            userPasswordRef.current = e;
                          }}
                        />
                      </div>
                      {errors.password && (
                        <span className="errorname">Password is required</span>
                      )}
                    </div>
                    <div className="btn-and-forgot">
                      <div className="btn-section">
                        <button className="comman-btn" type="submit">
                          {loginRequestStatus === "loading" ? (
                            <>
                              <Spinner
                                thickness="4px"
                                size="md"
                                speed="0.65s"
                              />
                            </>
                          ) : (
                            "LOGIN"
                          )}
                          <img
                            src={vector}
                            alt="btnimage"
                            className="img-vector"
                          />
                        </button>
                      </div>
                      <div className="forgotPassword">
                        <h6
                          className="forgot_password"
                          onClick={forgotPassword}
                        >
                          Forgot Password?
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
