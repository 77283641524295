export const tabsArr = [
    "Medical",
    "Personal History",
    "Social and Community",
    "Psychological",
    "Communication",
    "Living Skills",
    "Nutrition and Meals",
    "Support Services",
    "Case Management",
    "Clinical Care",
    "Skin Integrity"
]
