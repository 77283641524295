import {useState} from "react";
import TriangularTrafficLightsSm from "../../TrianglularTrafficLights/TriangularTrafficLightsSm";
import {GreenCircleSvg} from "../../../pages/People/ClientPage/Components/UniqueClientScreen/Components/ClinicalTab/Data/SVGs";
import TrafficLights from "./TrafficLights";
import {PolygonSVG} from "../Data/SVGs";
import TriangularTrafficLights from "../../TrianglularTrafficLights/TriangularTrafficLights";

export const TriangleSection = ({
    code,
    onChangeTrafficLight,
    selectedCurrentElement,
    required_key = "traffic_light",
    completionStatus,
    isDocument,
    localStateTrafficLight,
  }: any) => {
    const [showTriangularTrafficLights, setshowTriangularTrafficLights] =
      useState(false);
  
    return (
      <>
        <div className="triangle_section_wrapper">
          <div className="triangle_label">
            {code ? code.substring(0, 5) + "..." : ""}{" "}
          </div>
          <span
            className="lights_trigger"
            onClick={() =>
              setshowTriangularTrafficLights(!showTriangularTrafficLights)
            }
          >
            {isDocument && typeof (completionStatus == Number) ? (
              <PolygonSVG
                color={
                  completionStatus == 0
                    ? "#ef3a34"
                    : completionStatus == 1
                    ? "#fbbf14"
                    : "#2ad400"
                }
              />
            ) : (
              <PolygonSVG
                color={
                  selectedCurrentElement?.traffic_light !== undefined
                    ? selectedCurrentElement.traffic_light === 0
                      ? "#ef3a34"
                      : selectedCurrentElement.traffic_light === 2
                      ? "#2ad400"
                      : "#fbbf14"
                    : localStateTrafficLight === 0
                    ? "#ef3a34"
                    : localStateTrafficLight === 2
                    ? "#2ad400"
                    : "#fbbf14"
                }
              />
            )}
  
            {showTriangularTrafficLights && (
              <>
                <TriangularTrafficLights
                  onChangeTrafficLight={onChangeTrafficLight}
                />
              </>
            )}
          </span>
        </div>
      </>
    );
  };
  
  export const CircleSection = () => {
    const [showCircularTrafficLights, setshowCircularTrafficLights] =
      useState(false);
  
    return (
      <div className="triangle_section_wrapper">
        <span className="lights_trigger">
          <GreenCircleSvg
            size={14}
            onClick={() =>
              setshowCircularTrafficLights(!showCircularTrafficLights)
            }
          />
        </span>
  
        {!!showCircularTrafficLights && (
          <>
            <TrafficLights
              setshowCircularTrafficLights={setshowCircularTrafficLights}
            />
          </>
        )}
      </div>
    );
  };
  export const TriangleSectionSm = ({
    code,
    onChangeTrafficLight,
    daybookTask,
    localStateTrafficLight,
  }: any) => {
    const [showTriangularTrafficLights, setshowTriangularTrafficLights] =
      useState(false);
  
    return (
      <>
        <div className="triangle_section_wrapper">
          <div className="triangle_label">
            {code ? code.substring(0, 5) + "..." : ""}{" "}
          </div>
          <span
            className="lights_trigger_sm"
            onClick={() =>
              setshowTriangularTrafficLights(!showTriangularTrafficLights)
            }
          >
            <PolygonSVG
              width={18}
              height={18}
              color={
                daybookTask?.traffic_light !== undefined
                  ? daybookTask.traffic_light === 0
                    ? "#ef3a34"
                    : daybookTask.traffic_light === 2
                    ? "#2ad400"
                    : "#fbbf14"
                  : localStateTrafficLight === 0
                  ? "#ef3a34"
                  : localStateTrafficLight === 2
                  ? "#2ad400"
                  : "#fbbf14"
              }
            />
  
            {showTriangularTrafficLights && (
              <>
                <TriangularTrafficLightsSm
                  onChangeTrafficLight={onChangeTrafficLight}
                />
              </>
            )}
          </span>
        </div>
      </>
    );
  };
  
  