import "../Styles/ExportSegment.css";
import ReactDatePicker from "react-datepicker";
import { useAppDispatch } from "../../../../../Controller/redux/Hooks";
import {
  getKitchenReportsThunk,
  getReportsThunk,
} from "../../../../../Controller/redux/features/MealsFeatures/ReportsFeature/reportsSlice";
import {
  formatDate,
  formatDateForAPI,
} from "../../../../../Model/utils/Helper/formatDate";
import { useEffect } from "react";

const ExportSegment = ({
  selectedDate,
  setSelectedDate,
  selectedMealType,
  setSelectedMealType,
  handleExportToPDF,
  tab,
}: any) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (selectedDate && selectedMealType && tab == "Sessions Report") {
      dispatch(
        getReportsThunk({
          date: formatDateForAPI(selectedDate),
          mealType: selectedMealType,
        })
      );
    } else if (selectedDate && tab == "Kitchen Report") {
      dispatch(
        getKitchenReportsThunk({ date: formatDateForAPI(selectedDate) })
      );
    }
  }, [tab, selectedDate, selectedMealType]);

  return (
    <div className="export_segment">
      <div className="reports_date_picker_wrapper">
        <ReactDatePicker
          showYearDropdown
          showMonthDropdown
          className="reports_date_picker"
          onChange={(date: any) => {
            setSelectedDate(date);
          }}
          selected={selectedDate}
          placeholderText="Select Date"
        />
      </div>
      <div className="input_fields">
        {tab == "Sessions Report" && (
          <select
            name="mealType"
            id="mealType"
            className="meal_type_select"
            value={selectedMealType}
            onChange={(event) => {
              const selectedType = event.target.value;
              setSelectedMealType(selectedType);
            }}
          >
            <option value="Please Select...">Please Select...</option>
            {[
              "Breakfast",
              "Morning Tea",
              "Lunch",
              "Afternoon Tea",
              "Dinner",
              "Supper",
            ].map((elem) => (
              <option key={elem} value={elem}>
                {elem}
              </option>
            ))}
          </select>
        )}
        {/* <button
          className="toggle_button filter_button"
          onClick={() => {
            if (selectedDate && selectedMealType && tab == "Sessions Report") {
              dispatch(
                getReportsThunk({
                  date: formatDateForAPI(selectedDate),
                  mealType: selectedMealType,
                })
              );
            } else if (selectedDate && tab == "Kitchen Report") {
              dispatch(
                getKitchenReportsThunk({ date: formatDateForAPI(selectedDate) })
              );
            }
          }}
        >
          Filter
        </button> */}
        <button onClick={() => handleExportToPDF()} className="export_button">
          Export
        </button>
      </div>
    </div>
  );
};

export default ExportSegment;
