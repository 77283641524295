import React, { useEffect, useState } from "react";
import UpperBox from "../../components/UpperBox/UpperBox";
import CircularNoteTrigger from "../../components/CircularNoteTrigger/CircularNoteTrigger";
import Iframe from "../../components/Layout/Content/Iframe";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../Controller/redux/Hooks";
import { useNavigate } from "react-router-dom";
import { getDashboardReports } from "../../../Controller/redux/features/ReportsHubFeature/ReportsHubSlice";
import CommentWithNumberSVG from "../../../Model/Assets/SVG/Common/CommentWithNumberSVG";

const ReportsHub = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { getHubReports } = useAppSelector((state) => state.reportsHub);
  const { domainDetail } = useAppSelector((state) => state?.common);

  useEffect(() => {
    dispatch(getDashboardReports());
  }, []);

  return (
    <>
      <div className="main-content-section">
        <div className="container-fluid">
          <div style={{ display: "flex" }}>
            <div style={{ width: "100%" }}>
              <UpperBox type="hub" name={domainDetail?.reports_label} />
              <div className="main-box-hub">
                <div className="main-box-header-home">
                  {getHubReports &&
                    getHubReports.map((elem: any) => {
                      return (
                        <>
                          <div className="vendor-box">
                            <div className="vendor-box-heading">
                              <div className="vender_box_container">
                                <span className="vendor-box-heading-title">
                                  {elem.category}
                                </span>
                              </div>
                            </div>

                            <div className="vendor-box-content">
                              {elem?.reports &&
                                elem?.reports?.map(
                                  (report: any, index: any) => {
                                    return (
                                      <div key={index}>
                                        <button
                                          onClick={() => {
                                            const url =
                                              report?.iframe?.includes(
                                                "https://"
                                              )
                                                ? report?.iframe
                                                : "https://" + report?.iframe;
                                            const encodedUrl = window.btoa(url);
                                            window.open(
                                              `/reports-hub/${encodedUrl}`,
                                              "_blank"
                                            );
                                          }}
                                          className="vendor-box-wrapper"
                                        >
                                          <div className="vendor-box-item">
                                            <img
                                              src={
                                                report?.profile_image_s3 ||
                                                require("./../../../Model/Assets/images/logo-placeholder-image.png")
                                              }
                                              alt="logo"
                                            />
                                            <div
                                              className="hub-vendor-count"
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                navigate(
                                                  `/reports/${window.btoa(
                                                    report?.id
                                                  )}`,
                                                  {
                                                    state: {
                                                      reportComms:
                                                        report?.report_name,
                                                    },
                                                  }
                                                );
                                              }}
                                            >
                                              <CommentWithNumberSVG
                                                number={
                                                  report?.inbox_count_unread
                                                }
                                                fill={report?.inbox_status}
                                              />
                                            </div>
                                          </div>
                                          {report.report_name}
                                        </button>
                                      </div>
                                    );
                                  }
                                )}
                            </div>
                          </div>
                        </>
                      );
                    })}
                </div>

                <div className="main-box-body ">
                  <div className="upper-segment-clinical">
                    <div
                      className="content-wrapper"
                      style={{ display: "block", zIndex: "-1" }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="icon-bottom-section"></div>
        </div>
        <CircularNoteTrigger type="stickyHub" title="Comms" />
      </div>
    </>
  );
};

export default ReportsHub;
