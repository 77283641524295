export const DammyGoalArr: any = {
  1: [

    {
      created_at: "2023-10-25T09:30:17.000000Z",
      deleted_at: null,
      id: 298,
      summary: "Monthly financial report",
      tenant_id: 1,
      title: "Finance Review",
      updated_at: "2023-10-25T09:30:23.000000Z",
      user_id: 803,
      activities: [],
    },
 
  ],
  2: [
    {
      created_at: "2023-10-27T15:30:14.000000Z",
      deleted_at: null,
      id: 302,
      summary: "Quarterly sales review",
      tenant_id: 1,
      title: "Sales Meeting",
      updated_at: "2023-10-27T15:30:20.000000Z",
      user_id: 807,
      activities: [],
    },
    
    {
      created_at: "2023-10-29T14:15:50.000000Z",
      deleted_at: null,
      id: 306,
      summary: "Client meeting and proposal discussion",
      tenant_id: 1,
      title: "Client Proposal Meeting",
      updated_at: "2023-10-29T14:15:56.000000Z",
      user_id: 811,
      activities: [],
    },
  ],
  3: [
    {
      created_at: "2023-10-30T08:30:10.000000Z",
      deleted_at: null,
      id: 307,
      summary: "Staff training session",
      tenant_id: 1,
      title: "Training Workshop",
      updated_at: "2023-10-30T08:30:16.000000Z",
      user_id: 812,
      activities: [],
    },
    {
      created_at: "2023-10-30T10:45:21.000000Z",
      deleted_at: null,
      id: 308,
      summary: "Product development brainstorming",
      tenant_id: 1,
      title: "Product Innovation",
      updated_at: "2023-10-30T10:45:27.000000Z",
      user_id: 813,
      activities: [],
    },
    {
      created_at: "2023-10-31T14:20:36.000000Z",
      deleted_at: null,
      id: 309,
      summary: "Monthly progress review",
      tenant_id: 1,
      title: "Monthly Review Meeting",
      updated_at: "2023-10-31T14:20:42.000000Z",
      user_id: 814,
      activities: [],
    },
    // {
    //   created_at: "2023-10-31T16:10:53.000000Z",
    //   deleted_at: null,
    //   id: 310,
    //   summary: "Team collaboration event",
    //   tenant_id: 1,
    //   title: "Collaboration Day",
    //   updated_at: "2023-10-31T16:10:59.000000Z",
    //   user_id: 815,
    // },
    // {
    //   created_at: "2023-11-01T09:55:12.000000Z",
    //   deleted_at: null,
    //   id: 311,
    //   summary: "Marketing campaign launch",
    //   tenant_id: 1,
    //   title: "Campaign Launch",
    //   updated_at: "2023-11-01T09:55:18.000000Z",
    //   user_id: 816,
    // },
  ],
  4: [
    {
      created_at: "2023-11-01T11:30:27.000000Z",
      deleted_at: null,
      id: 312,
      summary: "Sales target setting",
      tenant_id: 1,
      title: "Sales Target Meeting",
      updated_at: "2023-11-01T11:30:33.000000Z",
      user_id: 817,
      activities: [],
    },
    {
      created_at: "2023-11-02T14:15:44.000000Z",
      deleted_at: null,
      id: 313,
      summary: "New feature development discussion",
      tenant_id: 1,
      title: "Feature Development",
      updated_at: "2023-11-02T14:15:50.000000Z",
      user_id: 818,
      activities: [],
    },
    {
      created_at: "2023-11-02T16:40:02.000000Z",
      deleted_at: null,
      id: 314,
      summary: "Employee performance evaluations",
      tenant_id: 1,
      title: "Performance Reviews",
      updated_at: "2023-11-02T16:40:08.000000Z",
      user_id: 819,
      activities: [],
    },
    // {
    //   created_at: "2023-11-03T09:25:18.000000Z",
    //   deleted_at: null,
    //   id: 315,
    //   summary: "Board of directors meeting",
    //   tenant_id: 1,
    //   title: "Board Meeting",
    //   updated_at: "2023-11-03T09:25:24.000000Z",
    //   user_id: 820,
    // },
    {
      created_at: "2023-11-03T11:50:37.000000Z",
      deleted_at: null,
      id: 316,
      summary: "IT system upgrade planning",
      tenant_id: 1,
      title: "IT Upgrade",
      updated_at: "2023-11-03T11:50:43.000000Z",
      user_id: 821,
      activities: [],
    },
  ],
  5: [
    {
      created_at: "2023-11-04T14:35:56.000000Z",
      deleted_at: null,
      id: 317,
      summary: "Product testing phase kickoff",
      tenant_id: 1,
      title: "Product Testing",
      updated_at: "2023-11-04T14:36:02.000000Z",
      user_id: 822,
      activities: [],
    },
    {
      created_at: "2023-11-04T16:20:14.000000Z",
      deleted_at: null,
      id: 318,
      summary: "Team strategy planning",
      tenant_id: 1,
      title: "Strategy Session",
      updated_at: "2023-11-04T16:20:20.000000Z",
      user_id: 823,
      activities: [],
    },
    {
      created_at: "2023-11-05T10:15:32.000000Z",
      deleted_at: null,
      id: 319,
      summary: "Client feedback review",
      tenant_id: 1,
      title: "Client Feedback Meeting",
      updated_at: "2023-11-05T10:15:38.000000Z",
      user_id: 824,
      activities: [],
    },
    {
      created_at: "2023-11-05T11:45:47.000000Z",
      deleted_at: null,
      id: 320,
      summary: "Marketing budget allocation",
      tenant_id: 1,
      title: "Budget Allocation",
      updated_at: "2023-11-05T11:45:53.000000Z",
      user_id: 825,
      activities: [],
    },
    {
      created_at: "2023-11-06T09:50:59.000000Z",
      deleted_at: null,
      id: 321,
      summary: "Quarterly business review",
      tenant_id: 1,
      title: "Business Review",
      updated_at: "2023-11-06T09:51:05.000000Z",
      user_id: 826,
      activities: [],
    },
  ],
};
