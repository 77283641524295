export const SessionsTableData = [
  {
    name: "Client Name",
    col: 1,
  },
  {
    name: "Meal Name",
    col: 1,
  },
  {
    name: "Meal Size",
    col: 1,
  },
  {
    name: "Meal Type",
    col: 1,
  },
  {
    name: "Table Number",
    col: 1,
  },
  {
    name: "Meal Served",
    col: 1,
    isCheckbox: true,
  },
];
