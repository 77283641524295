export const formatDate = (inputDate: any) => {
  const date = new Date(inputDate);
  const day = date.getDate();
  const month = date.toLocaleString("default", { month: "long" });
  const year = date.getFullYear();
  return `${day} ${month.slice(0, 3)} ${year}`;
};

export const convertDOB = (dobString: any) => {
  const [day, month, year] = dobString?.split(", ");

  const months: any = {
    Jan: "01",
    Feb: "02",
    Mar: "03",
    Apr: "04",
    May: "05",
    Jun: "06",
    Jul: "07",
    Aug: "08",
    Sep: "09",
    Oct: "10",
    Nov: "11",
    Dec: "12",
  };

  const monthNumber = months[month];
  const formattedDay = day.padStart(2, "0");

  return `${year}-${monthNumber}-${formattedDay}`;
};

export const formatDateForAPI = (date: Date | null) => {
  if (date instanceof Date && !isNaN(date.getTime())) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }
  return "";
};

export const formatDateFromAPI = (dateString: String) => {
  if(dateString){
    const dateParts = dateString.split("-");
    return dateParts[1] + "-" + dateParts[0] + "-" + dateParts[2];
  }

};

export function formatCreatedDate(dateString: any) {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const daysOfWeek = ["Sun", "Mon", "Tues", "Wed", "Thurs", "Fri", "Sat"];

  const date = new Date(dateString);
  const dayOfWeek = daysOfWeek[date.getUTCDay()];
  const day = date.getUTCDate();
  const month = months[date.getUTCMonth()];
  const year = date.getUTCFullYear();

  return `${dayOfWeek}, ${day} ${month} ${year}`;
}

export function formatDateToCustomString(dateString: any) {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const [year, month, day] = dateString?.split("-");
  const formattedDate = new Date(`${year}-${month}-${day}`);
  const formattedDay = formattedDate.getDate();
  const formattedMonth = months[formattedDate.getMonth()];
  const formattedYear = formattedDate.getFullYear();

  return `Starting ${formattedDay} ${formattedMonth}, `;
}

export function timeAgo(dateString: any) {
  if (!dateString) return "Invalid date";

  const now = new Date();
  const date = new Date(dateString);
  const diff = now - date;

  if (isNaN(date.getTime())) return "Invalid date";

  const seconds = Math.floor(diff / 1000);
  const minutes = Math.floor(diff / (1000 * 60));
  const hours = Math.floor(diff / (1000 * 60 * 60));
  const days = Math.floor(diff / (1000 * 60 * 60 * 24));
  const weeks = Math.floor(diff / (1000 * 60 * 60 * 24 * 7));
  const months = Math.floor(days / 30.44); // Approximate month length
  const years = Math.floor(days / 365.25); // Considering leap years

  if (seconds < 60) {
    return "Just now";
  } else if (minutes < 60) {
    return `${minutes} minute${minutes !== 1 ? "s" : ""} ago`;
  } else if (hours < 24) {
    return `${hours} hour${hours !== 1 ? "s" : ""} ago`;
  } else if (days === 1) {
    return "Yesterday";
  } else if (days < 7) {
    return `${days} day${days !== 1 ? "s" : ""} ago`;
  } else if (weeks < 4) {
    return `${weeks} week${weeks !== 1 ? "s" : ""} ago`;
  } else if (months < 12) {
    return `${months} month${months !== 1 ? "s" : ""} ago`;
  } else {
    const remainingMonths = Math.floor((days % 365.25) / 30.44);
    return `${years} year${years !== 1 ? "s" : ""}${remainingMonths > 0 ? ` and ${remainingMonths} month${remainingMonths !== 1 ? "s" : ""}` : ""} ago`;
  }
};

export function formatDateFromAPIforCustom(inputDate: any = "") {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  if (inputDate) {
    const [year, month, day] = inputDate?.split("-");
    const formattedMonth = months[parseInt(month) - 1];
    const formattedDay = day.padStart(2, "0");
    const formattedYear = year.slice(-2);

    return `${formattedDay} ${formattedMonth} ${formattedYear}`;
  }
  return "";
}

export const getCurrentDate = () => {
  const currentDate = new Date();
  return currentDate;
};

export const formatPrimaryReview1 = (date: any) => {
  try {
    const [year, month, day] = date.split("-");

    const outputDate = `${day}-${month}-${year}`;

    return outputDate;
  } catch (error) {
    return "";
  }
};

// export const DateFormat = (dateString: string) => {
//   const newDate = new Date(dateString);
//   const year = newDate.getFullYear();
//   const month = newDate.getMonth() + 1;
//   const day = newDate.getDate();
//   const dateOutput = `${day}-${month}-${year}`;
//   return dateOutput;
// };
export const DateFormat = (dateString: string, dateFormat?: string) => {
  const newDate = new Date(dateString);
  const year = newDate.getFullYear();
  const month = (newDate.getMonth() + 1).toString().padStart(2, "0"); // Ensure two-digit month
  const day = newDate.getDate().toString().padStart(2, "0"); // Ensure two-digit day
  let dateOutput: any = `${day}-${month}-${year}`;
  if (dateFormat === "dd-mm-yyyy") {
    dateOutput = `${day}-${month}-${year}`;
  } else if (dateFormat === "mm-dd-yyyy")
    dateOutput = `${month}-${day}-${year}`;
  else if (dateFormat === "yyyy-mm-dd") dateOutput = `${year}-${month}-${day}`;
  return dateOutput;
};
// export const DateFormat = (inputDateTime: string) => {
//   const dateObj = new Date(inputDateTime);
//   const date = dateObj.toLocaleDateString("en-GB");
//   // const time = dateObj.toLocaleTimeString("en-US", { hour12: false });
//   return date ;
// };

export const formatDateForStatusWidget = (date: any) => {
  if (!date) return "";
  const dateParts = date.toLocaleDateString().split("/");
  const formattedDate = `${dateParts[1]}/${dateParts[0]}/${dateParts[2]}`;
  return formattedDate;
};

export function formatDateForClientStatus(inputDateStr: any) {
  let originalDate = new Date(inputDateStr);

  const monthNames = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];

  let formattedDay = originalDate.getDate().toString().padStart(2, "0");
  let formattedDate =
    formattedDay +
    " - " +
    monthNames[originalDate.getMonth()] +
    " - " +
    originalDate.getFullYear();
  return formattedDate;
}
// format 22-10-2023
export function formatDateForComms(inputDateStr: any) {
  let originalDate = new Date(inputDateStr);

  let formattedDay = originalDate.getDate().toString().padStart(2, "0");
  let formattedDate =
    formattedDay +
    "-" +
  originalDate.getMonth() +
    "-" +
    originalDate.getFullYear();
  return formattedDate;
}

export function formatTimeClientStatus(inputDateStr: any) {
  let originalDate = new Date(inputDateStr);

  // Get the current date and time
  let currentDate = new Date();

  // Check if the date is today
  if (
    originalDate.getDate() === currentDate.getDate() &&
    originalDate.getMonth() === currentDate.getMonth() &&
    originalDate.getFullYear() === currentDate.getFullYear()
  ) {
    // If it's today, format as "Today, HH:mmam/pm"
    let hours = originalDate.getHours();
    let minutes = originalDate.getMinutes();
    let ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // 0 should be displayed as 12
    var formattedTime =
      hours?.toString()?.padStart(2, "0") +
      ":" +
      minutes?.toString()?.padStart(2, "0") +
      ampm;
    return "Today, " + formattedTime;
  } else {
    // If it's not today, format as "HH:mmam/pm"
    let hours = originalDate.getHours();
    let minutes = originalDate.getMinutes();
    let ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // 0 should be displayed as 12
    let formattedTime =
      hours.toString().padStart(2, "0") +
      ":" +
      minutes.toString().padStart(2, "0") +
      ampm;
    return formattedTime;
  }
}
export function formatDateStatusApi(inputDateString: any) {
  var date = new Date(inputDateString);

  var year = date.getFullYear();
  var month = (date.getMonth() + 1).toString().padStart(2, "0"); //
  var day = date.getDate().toString().padStart(2, "0");

  return `${year}-${month}-${day}`;
}

// Returns Yesterday , today ,tomorrow,Day After Tomorrow
export function getCurrentDates() {
  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const months = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];

  const currentDate = new Date();
  const yesterday = new Date(currentDate);
  yesterday.setDate(currentDate.getDate() - 1);

  const tomorrow = new Date(currentDate);
  tomorrow.setDate(currentDate.getDate() + 1);

  const dayAfterTomorrow = new Date(currentDate);
  dayAfterTomorrow.setDate(currentDate.getDate() + 2);

  function formatDate(date: any) {
    const day = days[date.getDay()];
    const dayOfMonth = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();
    return `${day}, ${dayOfMonth} ${month} ${year}`;
  }

  const formattedYesterday = formatDate(yesterday);
  const formattedToday = formatDate(currentDate);
  const formattedTomorrow = formatDate(tomorrow);
  const formattedDayAfterTomorrow = formatDate(dayAfterTomorrow);

  return {
    yesterday: formattedYesterday,
    today: formattedToday,
    tomorrow: formattedTomorrow,
    dayAfterTomorrow: formattedDayAfterTomorrow,
  };
}

export function formatTimeAgoForDocumentCurrentDate(date: any) {
  const now = new Date();
  const midnight = new Date(now);
  midnight.setHours(0, 0, 0, 0);

  if (date >= midnight) {
    // It's today
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "pm" : "am";
    const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    return `Today ${formattedHours}:${formattedMinutes} ${ampm}`;
  } else {
    // It's not today
    return "today";
  }
}

// Monday, 16 OCT 2023 Format
export function convertFormatDate(inputDate: any) {
  // Define the day names and month names
  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const months = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];

  // Parse the input date
  const parsedDate = new Date(inputDate);

  // Get the day, month, and year
  const day = days[parsedDate.getDay()];
  const month = months[parsedDate.getMonth()];
  const year = parsedDate.getFullYear();

  // Format the date string
  const formattedDate = `${day}, ${parsedDate.getDate()} ${month} ${year}`;

  return formattedDate;
}

export function formatDateForClinical(inputDate: any) {
  // Create a date object from the input string
  const date = new Date(inputDate);

  // Define month names
  const monthNames = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];

  // Extract day, month, and year from the date
  const day = date.getDate();
  const month = monthNames[date.getMonth()];
  const year = date.getFullYear();

  // Format the date as "DD MONTH YYYY"
  const formattedDate = `${day} ${month} ${year}`;

  return formattedDate;
}



export function convertDateFormat(inputDate: any) {
  const [year, month, day] = inputDate.split('-');

  const originalDate = new Date(year, month - 1, day);

  const formattedDate = `${originalDate.getDate().toString().padStart(2, '0')}-${(originalDate.getMonth() + 1).toString().padStart(2, '0')}-${originalDate.getFullYear()}`;

  return formattedDate;
}


export function formatDateWithoutTime(date: any) {
    const options = { weekday: 'short', month: 'short', day: '2-digit', year: 'numeric' };
    return date.toLocaleDateString('en-US', options);
}

const date = new Date('Tue Mar 05 2024 05:30:00 GMT+0530');
const formattedDate = formatDateWithoutTime(date);





export function extractYearMonthDay(date: any) {
  const year = date?.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  
  return { year, month, day };
}


export function extractTime(timestampStr: any) {
  const dateObj = new Date(timestampStr);
  
  let hours = dateObj.getHours();
  const minutes = dateObj.getMinutes();
  const seconds = dateObj.getSeconds();
  
  const amOrPm = hours >= 12 ? 'PM' : 'AM';
  
  if (hours > 12) {
      hours -= 12;
  }
  if (hours === 0) {
      hours = 12;
  }
  
  const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} ${amOrPm}`;
  
  return formattedTime;
}

const timestampStr = "2024-03-12T17:45:27.000000Z"; // 5:45:27 PM
const time = extractTime(timestampStr);
// console.log("Time:", time);
