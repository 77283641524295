import React from "react";
import { truncateString } from "../../../../Model/utils/Helper/truncateString";
import TildeSVG from "../../../components/ClinicalProgress/Data/TildeSVG";
// import { TriangleSectionSm } from "../../../components/CommonModal/Components/ThreadsBox";
import { DocumentSVG } from "../../../components/CommonModal/Data/SVGs";
import { TagBox } from "../../../components/Tags/CommonTagsComponent/TagBox";
import { CalendarSVG } from "../Data/CalendarSVG";
import MessageCount from "../Data/MessageCount";
// import TriangeSVG from "../Data/TriangeSVG";
import "../Styles/CollapsedDaybookItem.css";
import {TriangleSectionSm} from "../../../components/CommonModal/Components/TriangleCircleSection";
import {useAppSelector} from "../../../../Controller/redux/Hooks";

export const CollapsedDaybookItem = ({
  daybookTask,
  isSelected,
  onChangeTrafficLight,
  localStateTrafficLight,
  getDaybookCall,
  color
}: any) => {
  const { serviceTypeData } = useAppSelector((state) => state.serviceType);


  return (
    <div
      className="collapsed_daybook_item"
      style={{
        outline: isSelected ? "2px solid #06A5FF" : "unset",
      }}
    >
      <CalendarAndCount count={daybookTask?.comm_count || 0} color={color} />
      <div className="right_section">
        <div className="upper_right_section">
          <div className="segment_1">
            <TidleAndEventLabel />
            <span className="service_type_label">
              {daybookTask? daybookTask?.service_types?.name   
               : serviceTypeData
               ? serviceTypeData?.name
               : "Service Type"
              }
            </span>
          </div>
          <div className="segment_2">
            <span className="time_section">
              {daybookTask?.start_time || ""}{" "}
              {daybookTask?.end_time ? "-" + daybookTask?.end_time : "" || ""}
            </span>

            <span className="event_label">
              <TagBox
              isFromCol1
                getDaybookCall={getDaybookCall}
                currentElem={daybookTask}
                isFromDayBook={true}
              />
            </span>
            <span className="time_section">
              <TriangleSectionSm
                daybookTask={daybookTask}
                onChangeTrafficLight={onChangeTrafficLight}
                localStateTrafficLight={localStateTrafficLight}
              />
            </span>
          </div>
        </div>
        <div className="bottom_right_section">
          <p>{truncateString(daybookTask?.title, 58) || "Task Title"}</p>
          {daybookTask?.summary?.length > 0 && (
            <>
              <h6>
                {truncateString(daybookTask?.summary, 80) || "Task Summary"}
              </h6>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export const CalendarAndCount = ({ count, color }: any) => {
  // console.log(color,"color")
  return (
    <>
      <div className="left_section_collapsed_daybook_item">
        <CalendarSVG />
        <MessageCount count={count} color={color} />
      </div>
    </>
  );
};

export const DocumentAndCount = ({ count, color }: any) => {
  return (
    <>
      <div className="left_section_collapsed_daybook_item">
        <DocumentSVG />
        <MessageCount count={count} color={color} />
      </div>
    </>
  );
};

export const TidleAndEventLabel = () => {
  return (
    <>
      <TildeSVG color="#EF3A34" />
      <span className="event_label event_label_text">EVENT</span>
    </>
  );
};
