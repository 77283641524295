import { useEffect, useRef, useState } from "react";
import { tabsArr } from "./Data/Tabs";
import profile from "../../../Model/Assets/images/profile.png";
import "./Styles/VerticalModal.css";
import { AddIconClinicalModal, CommsIcon, StatusIcon } from "./Data/SVGs";
import { BallSvg } from "../../pages/People/ClientPage/Components/UniqueClientScreen/Components/ClinicalTab/Data/SVGs";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../Controller/redux/Hooks";
import {
  getClinicalGoalModalDetailsThunk,
  getGoalsThunk,
  updateGoalsThunk,
} from "../../../Controller/redux/features/ClinicalFeature/clinicalSlice";
import { getServiceTypeThunk } from "../../../Controller/redux/features/CommonFeature/commonSlice";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { formatTimeAgoForDocumentCurrentDate } from "../../../Model/utils/Helper/formatDate";
import YellowSVG from "../../../Model/Assets/SVG/Common/YellowSVG";
import { DammyGoalArr } from "./Data/DammyGoalArr";
import ClinicalProgress from "../ClinicalProgress/ClinicalProgress";
import PlusSVG from "../../pages/People/ClientPage/Components/UniqueClientScreen/Components/FinanceTab/Data/PlusSVG";
import { EditorState } from "draft-js";
import { Radio, RadioGroup, Stack, Switch } from "@chakra-ui/react";
import { DeleteIcon } from "@chakra-ui/icons";
import { CustomFormExpectedArray } from "./Data/CustomFormExpectedArray";
import CustomForm from "./Components/CustomForm";
import { CrossMdSvg } from "../../../Model/Assets/SVG/Common/CrossSvg";
import CustomDatePicker from "../DatePicker/CustomDatePicker";
import GoalPlanner from "./Components/GoalPlanner";
import {
  getCustomFormAnswersVersionsThunk,
  getCustomFormFromAnswerVersionNumberThunk,
  getCustomFormVersionsThunk,
  getCustomFormsIdPaginateTrueByThunk,
  setCurrentActiveFormInfo,
} from "../../../Controller/redux/features/CustomFromFeature/customFormSlice";

const VerticalModal = ({
  title = "",
  type,
  mainType,
  note_1,
  note_2,
  colorId,
  onClickClinicalProgress = () => {},
  setuploadDocument,
  dataObject,
  setshowGoalsModal = () => {},
}: any) => {
  const dispatch = useAppDispatch();

  const [realDataObject, setrealDataObject] = useState<any>(null);
  let allDocs = useAppSelector((state) => state.document.getDocuments);

  let scam = localStorage.getItem("SCAM");
  useEffect(() => {
    if (scam == "true") {
      setrealDataObject(allDocs[0]);
    } else {
      setrealDataObject(dataObject);
    }
  }, [scam, allDocs]);


  // console.log(realrealDataObject,'realrealDataObject')

  /////////////////  SELECTOR HOOKS ///////////////////////////////////////////////////
  const allGoals = useAppSelector((state) => state?.clinical?.allGoalsData);
  const getCustomFormsData = useAppSelector(
    (state) => state?.clinical?.clinicalGoalModalDetails
  );
  const { dynamicColor } = useAppSelector((state) => state?.common);
  ////////////////////////////////////////////////////////////////////////////////////

  const id: any = useParams()?.id;
  let clientId = window.atob(id);

  /////////////////// LOCAL STATES //////////////////////////////////////////////////

  const [selectedTab, setselectedTab] = useState<string>("Medical");
  const [tabTextAreaDetails, setTabTextAreaDetails] = useState<any>({});
  const [expandedGoal, setexpandedGoal] = useState(false);
  const [mouseOverPlusIcon, setmouseOverPlusIcon] = useState(false);
  const [goalsData, setGoalsData] = useState<any[]>([]);

  const [showLights, setShowLights] = useState<boolean>(false);
  const [showLightsId, setShowLightsId] = useState<boolean>(false);
  // const [trafficLightColors, setTrafficColors] = useState<any>([]);
  const [selectedGoal, setselectedGoal] = useState<any>(null);
  const [updateCustomForm, setupdateCustomForm] = useState<any>(null);
  const [expandedNote, setIsExpandedNote] = useState<number>(-1);
  const [showTags, setShowTags] = useState(
    Array(goalsData?.length).fill(false)
  );

  // // console.log(selectedTags,"selectefTAgs")
  const [selectedTags, setSelectedTags] = useState(
    goalsData[expandedNote]?.tags ? goalsData[expandedNote]?.tags : []
  );

  //////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (allGoals?.data) {
      // // console.log("setGoalsData ================>", allGoals.data);
      let updatedGoalInfo = allGoals?.data?.map((res: any) => {
        let updatedActivities = res?.activities.map((info: any) => {
          return {
            ...info,
            responsible_parties_id: info?.responsible_parties_id?.split(","),
          };
        });
        return { ...res, activities: updatedActivities };
      });
      setGoalsData(allGoals?.data);
      // setGoalsData(updatedGoalInfo);
    }
  }, [allGoals?.data]);

  useEffect(() => {
    const element = document.querySelector(".expanded_goal_with_activities");
    if (element) {
      if (expandedNote !== null) {
        element.classList.remove("fade_out");
        element.classList.add("fade_in");
      } else {
        element.classList.remove("fade_in");
        element.classList.add("fade_out");
      }
    }
  }, [expandedNote]);

  const { getDocuments } = useAppSelector((state) => state.document);

  useEffect(() => {
    setupdateCustomForm({
      user_id: clientId,
      tab_details: Object.keys(tabTextAreaDetails).map((tab) => ({
        custom_header_name: tab,
        desc: tabTextAreaDetails[tab] || "",
      })),
    });
  }, [tabTextAreaDetails]);

  const handleOpenTrafficLights = (e: any, id: any) => {
    e?.stopPropagation();
    setShowLightsId(id);
    setShowLights(!showLights);
  };

  let newArr = [
    {
      name: note_1,
      title: "one",
    },
    {
      name: note_2,
      title: "two",
    },
  ];

  useEffect(() => {
    setSelectedTags([]);
  }, [expandedNote]);

  useEffect(() => {
    if (getCustomFormsData) {
      let tempObj: any = {};
      for (let index = 0; index < getCustomFormsData.length; index++) {
        tempObj[getCustomFormsData[index].custom_header_name] =
          getCustomFormsData[index].desc;
      }
      setTabTextAreaDetails(tempObj);
    }
  }, [getCustomFormsData]);

  ///////////////////////////////////// TAB SCREEN ///////////////////////////////////////
  const handleTabChange = (tab: string) => {
    setexpandedGoal(false);
    setselectedTab(tab);
    // @ts-ignore
    setTabTextAreaDetails((prevDetails) => ({
      ...prevDetails,
      [selectedTab]: tabTextAreaDetails[selectedTab] || "",
    }));
  };

  const [isThereAnyChangesGoal, setisThereAnyChangesGoal] = useState(false);

  const handleUpdateGoal = async () => {
    if (!isThereAnyChangesGoal) {
      return;
    }
    const updatedGoalsData = JSON.parse(JSON.stringify(goalsData));

    let currentGoal = updatedGoalsData[expandedNote];
    let currentGoalTags = updatedGoalsData[expandedNote]?.tag;
    currentGoalTags = [...selectedTags];

    const updatedCurrentGoal = {
      ...currentGoal,
      tag: currentGoalTags,
      goal_id: currentGoal?.id,
    };

    let res = await dispatch(updateGoalsThunk({ data: updatedCurrentGoal }));

    await dispatch(getGoalsThunk({ clientId }));
  };

  ////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    goalsData?.forEach((goal, index) => {
      const textarea: any = document.querySelector(`#goal_text_area_${index}`);
      if (textarea) {
        textarea.style.height = "auto";
        textarea.style.height = textarea.scrollHeight + "px";
      }
    });
  }, [goalsData]);

  useEffect(() => {
    dispatch(getGoalsThunk({ clientId }));
    dispatch(getClinicalGoalModalDetailsThunk({ clientId }));
  }, []);

  const [allActivities, setallActivities] = useState<any>([]);

  useEffect(() => {
    setallActivities(
      selectedGoal?.activities?.length > 0
        ? Array.from(selectedGoal?.activities).fill("")
        : []
    );
  }, [selectedGoal]);

  const [goalComplete, setGoalComplete] = useState<any>([]);

  useEffect(() => {
    setGoalComplete(
      goalsData?.length > 0 ? Array.from(goalsData).fill("") : []
    );
  }, [goalsData]);

  // console.log(realDataObject,'realDataObject')
  useEffect(() => {
    if (realDataObject?.custom_form_id) {
      const customFormId = realDataObject.custom_form_id;
      const userId = clientId;
      dispatch(getCustomFormAnswersVersionsThunk({ customFormId, userId }));
    }
  }, [realDataObject, dispatch]);

  const { customFormAnswerVersions } = useAppSelector(
    (state) => state.customForm
  );

  const [isLoading, setIsLoading] = useState(
    type == "clinical_list_item" ? true : false
  );

  useEffect(() => {
    if (type == "clinical_list_item" && allGoals) {
      let getLocalStorge: any = localStorage.getItem("currentClientNumber");
      if (getLocalStorge) {
        getLocalStorge = parseInt(getLocalStorge);
      } else {
        getLocalStorge = 1;
      }

      setTimeout(() => {
        setGoalsData(DammyGoalArr[getLocalStorge]);
        setIsLoading(false);
      }, 1000);
    }
  }, [type, allGoals]);

  const [customeFormArr, setCustomeFormArr] = useState<any[]>([]);
  // @ts-ignore
  const [maxHeight, setMaxHeight] = useState<any>(100);
  useEffect(() => {
    setMaxHeight(expandedNote > -1 ? 1000 : 100);
  }, [expandedNote]);

  const [currentActiveIndex, setCurrentActiveIndex] = useState<any>(null);

  const HanldeAddNewQuestion = () => {
    let copyCustomeFormArr = [...customeFormArr];
    let numberOfRecords = copyCustomeFormArr.push({
      question: EditorState.createEmpty(),
      answerType: "text",
      questionText: "",
      isRequired: true,
      optionArr: [],
      customLogic: false,
      selectedQuestion: "",
      expectedAnswer: "",
    });
    setCustomeFormArr(copyCustomeFormArr);
    setCurrentActiveIndex(numberOfRecords - 1);
    setEditorState(EditorState.createEmpty());
  };

  const [editorState, setEditorState] = useState(EditorState?.createEmpty());
  const [hasUnsavedChanges, sethasunsavedChanges] = useState(false);
  const [selectedVersionValue, setselectedVersionValue] = useState("");
  const [selectedVersionNumber,setselectedVersionNumber] = useState("");


  // console.log(selectedVersionValue,'selectedVersionValue')
let selectedVersionNumberCurrent = customFormAnswerVersions?.find(
  (el: any) => el?.id == selectedVersionValue
)?.version;
useEffect(() => {
  if(selectedVersionNumberCurrent){
    setselectedVersionNumber(selectedVersionNumberCurrent)
  }
},[selectedVersionNumberCurrent])
let currentDocumentId = customFormAnswerVersions?.find(
  (el: any) => el?.id == selectedVersionValue
)?.document_id;
let currentCustomFormId = customFormAnswerVersions?.find(
  (el: any) => el?.id == selectedVersionValue
)?.custom_form_id;
// currentDocumentId

// console.log(selectedVersionNumber,"customFormAnswerVersions")

  useEffect(() => {
    let flag = localStorage.getItem("FIRST_TIME_FORM");
    if (customFormAnswerVersions.length && flag === "true") {
      setselectedVersionValue(customFormAnswerVersions[0].id);
    setselectedVersionNumber(customFormAnswerVersions[0].id)

      localStorage.removeItem("FIRST_TIME_FORM");
    }
  }, [customFormAnswerVersions]);

  // @ts-ignore
  useEffect(() => {
    setMaxHeight(expandedNote > -1 ? 1000 : 100);
  }, [expandedNote]);


  useEffect(() => {
    if (expandedNote) {
      setShowTags(Array(goalsData?.length).fill(false));
    }
  }, [expandedNote]);

  const [
    APIResponseFromBackendCustomForm,
    setAPIResponseFromBackendCustomForm,
  ] = useState<any>([]);

  useEffect(() => {
    if (CustomFormExpectedArray) {
      setAPIResponseFromBackendCustomForm(CustomFormExpectedArray);
      // setCustomeFormArr(CustomFormExpectedArray);
    }
  }, [CustomFormExpectedArray]);

  var onChangeQuestionAnswerValue = (value: string, objInd: number) => {
    let newArray = APIResponseFromBackendCustomForm.map(
      (obj: any, index: number) => {
        if (index === objInd) {
          return { ...obj, selectedAnswer: value };
        }
        return obj;
      }
    );
    setAPIResponseFromBackendCustomForm(newArray);
  };

  const [isEditAddStateForTabs, setIsEditAddStateForTabs] = useState(false);
  const [TabsArray, setTabsArray] = useState<any[]>([]);
  useEffect(() => {
    setTabsArray(tabsArr);
  }, [tabsArr]);

  const [tabInputValue, setTabInputValue] = useState("");
  const [IsOnAddState, setIsOnAddState] = useState(false);

  useEffect(() => {
    setTabInputValue("");
    setIsEditAddStateForTabs(false);
    setIsOnAddState(false);
  }, [selectedTab]);

  const handleAddUpdate = () => {
    if (tabInputValue == "") {
      toast.warning("Please enter tab name.");
      return;
    }

    if (IsOnAddState) {
      let saveAllTabs = TabsArray;
      saveAllTabs.push(tabInputValue);
      setTabsArray(saveAllTabs);
      setTabInputValue("");
      setIsEditAddStateForTabs(false);
      setIsOnAddState(false);
    } else {
      let findIndex = TabsArray.findIndex((res) => res === selectedTab);
      let copyTabsArr = TabsArray;
      copyTabsArr[findIndex] = tabInputValue;
      setTabsArray(copyTabsArr);
      setTabInputValue("");
      setIsEditAddStateForTabs(false);
      setIsOnAddState(false);
      setselectedTab(tabInputValue);
    }
  };

  const selectedQuestionItemRef = useRef();

  useEffect(() => {
    function handleClickOutside(event: any) {
      const isOutsideClick =
        selectedQuestionItemRef.current &&
        // @ts-ignore
        !selectedQuestionItemRef.current.contains(event.target);

      if (isOutsideClick) {
        setCurrentActiveIndex(null);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const { selectedActiveForm, selectedActiveFormTabsArr } = useAppSelector(
    (state) => state?.customForm
  );

  const segmentsWrapperRef = useRef<any>(null);
  // Fatch srvice type records
  useEffect(() => {
    dispatch(getServiceTypeThunk());
  }, []);

  const [closingCustomForm, setclosingCustomForm] = useState(false);

  return (
    <>
      <div
        className={`vertical_modal_wrapper  ${
          type === "clinicalModalFromAdd" || type == "clinical_list_item"
            ? "clinical_active_hide_main"
            : ""
        }`}
      >
        <CrossMdSvg
          className="cross_button"
          onClick={() => {
            handleUpdateGoal();
            if (closingCustomForm) {
              sethasunsavedChanges(true);
            } else {
              setuploadDocument(1);
            }
          }}
        />
        <div
          ref={segmentsWrapperRef}
          className={`segments_wrapper ${
            type === "clinical_list_item" ? "clinical_list_item_active" : ""
          }`}
        >
          <div className="upper_layer_segment">
            <div className="title">
              {title || realDataObject?.title || "Care Plan"}
            </div>

            {customFormAnswerVersions.length > 1 && (
              <>
                <select
                // migrate to external css file.
                  style={{
                    position: "absolute",
                    right: "20px",
                  }}
                  name="version_dropdown"
                  id="version_dropdown"
                  onChange={(e) => {
                    let vId = e.target.value;
                    localStorage.removeItem("FIRST_TIME_FORM");

                    setselectedVersionValue(e.target.value);
                    let vNo = e.target.value.split(" ")[1];

                    let obj = customFormAnswerVersions.find(
                      (el: any) => el.id == vId
                    );
                    let id = obj?.id;
                    if (id) {
                      dispatch(
                        getCustomFormFromAnswerVersionNumberThunk({
                          userId: clientId,
                          customFormId: obj?.custom_form_id,
                          documentId: obj?.document_id,
                          versionNumber: obj?.version,
                        })
                      );
                    }
                  }}
                  value={selectedVersionValue}
                >
                  {customFormAnswerVersions?.map((version: any) => {
                    return (
                      <>
                        <option value={version.id}>
                          Doc - {version.document_id} [v{version.version}]
                        </option>
                      </>
                    );
                  })}
                </select>
              </>
            )}

            <div className="profile_picture_segment">
              <AddIconClinicalModal
                onMouseEnter={() => setmouseOverPlusIcon(true)}
                onMouseLeave={() => setmouseOverPlusIcon(false)}
              />
              <BallSvg />

              <div className="profile_pic_division">
                <img src={profile} height={35} width={35} alt="Profile" />
                <div className="comms_length_sub_division">
                  <CommsIcon />
                </div>
              </div>
            </div>
          </div>

          <CustomForm
            setclosingCustomForm={setclosingCustomForm}
            setuploadDocument={setuploadDocument}
            sethasunsavedChanges={sethasunsavedChanges}
            hasUnsavedChanges={hasUnsavedChanges}
            dataObject={realDataObject}
            segmentsWrapperRef={segmentsWrapperRef}
            answerVersionValue={selectedVersionNumber}
            currentDocumentId={currentDocumentId}
            currentCustomFormId={currentCustomFormId}
          />

          {expandedGoal ? (
            <GoalPlanner
              goalsData={goalsData}
              setGoalsData={setGoalsData}
              clientId={clientId}
              dynamicColor={dynamicColor}
              setisThereAnyChangesGoal={setisThereAnyChangesGoal}
              isThereAnyChangesGoal={isThereAnyChangesGoal}
              selectedTags={selectedTags}
              setSelectedTags={setSelectedTags}
              expandedNote={expandedNote}
              setIsExpandedNote={setIsExpandedNote}
              expandedGoal={expandedGoal}
              setexpandedGoal={setexpandedGoal}
              maxHeight={maxHeight}
              showTags={showTags}
              setShowTags={setShowTags}
            />
          ) : (
            selectedActiveForm?.has_goals == "1" && (
              <div
                className={` ${
                  expandedGoal
                    ? "lower_goals_section_expanded"
                    : "lower_goals_section"
                } `}
              >
                <span
                  onClick={() => {
                    setexpandedGoal(true);
                  }}
                  className="mx-3px"
                >
                  +
                </span>
                Goal Planner
              </div>
            )
          )}
        </div>
      </div>

      {type === "clinicalModalFromAdd" && (
        <ClinicalProgress
          onClickCross={onClickClinicalProgress}
          setshowGoalsModal={setshowGoalsModal}
        />
      )}

      {mainType === "clinical__note" && (
        <>
          <div className="clinical_active_modal_all_goals">
            <div className="side_panel_div" />

            <div className="all_goals_wrapper">
              <div className="top_section_wrapper">
                <div>Advanced Care Note</div>
                <div className="current_time">
                  {formatTimeAgoForDocumentCurrentDate(new Date())}
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
                className={`goals_segment  ${
                  isLoading && "loading_clinical_list_item"
                }`}
              >
                {newArr?.map((goal: any, goalIndex: number) => {
                  return (
                    <div className="sticky_clinical_selected_wrapper">
                      <div className="left_three_dots_wrapper_clinical">
                        {[...Array(3)].map((res: any, ind: number) => {
                          return <div key={ind} className="left-dots"></div>;
                        })}
                      </div>
                      <YellowSVG key={"orange_svg"} />
                      <div className="main_section_wrapper">
                        {[...Array(3)].map((activity: any, index: number) => {
                          return (
                            <>
                              <div
                                key={index}
                                className="goal_title_summary_wrapper"
                              >
                                <div className="goal_activity_item">
                                  <span>Activity:</span>
                                  {activity?.title}
                                </div>
                                <div
                                  onClick={(e: any) =>
                                    handleOpenTrafficLights(e, activity?.id)
                                  }
                                  className="goal_activity_dot"
                                >
                                  <StatusIcon
                                    color={
                                      colorId === 1
                                        ? goalIndex === 0
                                          ? " #2AD400"
                                          : goalIndex === 1 &&
                                            (index === 0 || index === 2)
                                          ? "#2AD400"
                                          : "#FBBF14"
                                        : colorId === 2
                                        ? goalIndex === 0
                                          ? " #2AD400"
                                          : goalIndex === 1 && index === 1
                                          ? "#2AD400"
                                          : "#FBBF14"
                                        : colorId === 3
                                        ? goalIndex === 0
                                          ? " #2AD400"
                                          : goalIndex === 1
                                          ? "#2AD400"
                                          : ""
                                        : ""
                                    }
                                  />
                                </div>
                              </div>
                            </>
                          );
                        })}
                        <textarea
                          className="text_area_each_goal"
                          id="text_area_goal__1"
                          placeholder="Start Typing Here..."
                          value={goal.name}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default VerticalModal;
