import React, { Fragment } from "react";
import { useAppSelector } from "../../../Controller/redux/Hooks";

const ConfirmationModal = ({
  type,
  handleMoveWithSave,
  handleMoveWithoutSaving,
  title,
  yes,
  no,
  
}: any) => {
  const { dynamicColor } = useAppSelector((state) => state?.common);
  return (
    <Fragment>
      {type === "clientScreen" && (
        <>
          <div
  
          >
            <div className="showunsave-modal-navigate">
              <span className="span1">
              {title || "You have unsaved changes on this page."}  
              </span>
              <span className="span2">
            {title ? "" : "  Do you wish to move on and lose these changes?"}  
              </span>
              <div className="btnDiv-modal">
                <button className="button1" onClick={handleMoveWithoutSaving}>
               {no ||   "Move on without saving"}
                </button>
                <button
                  style={{
                    backgroundColor: dynamicColor,
                  }}
                  className="button2"
                  onClick={handleMoveWithSave}
                >
           {yes ||       "Save and move on"}
                </button>
              </div>
            </div>
          </div>
        </>
      )}
      {type === "deletionConfirmation" && (
        <>
          <div
  
          >
            <div className="showunsave-modal-navigate">
              <span className="span1">
              {"Are you sure you want to delete?"}  
              </span>
              <span className="span2">
            {title ? "" : "This action can't be reversed."}  
              </span>
              <div className="btnDiv-modal">
                <button className="button1" onClick={handleMoveWithoutSaving}>
               {no ||   "No"}
                </button>
                <button
                  style={{
                    backgroundColor: dynamicColor,
                  }}
                  className="button2"
                  onClick={handleMoveWithSave}
                >
           {yes ||       "Yes"}
                </button>
              </div>
            </div>
          </div>
        </>
      )}

      {type === "documents" && (
        <>
          <div
            style={{
              height: "100vh",
              width: "100vw",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="showunsave-modal-navigate">
              <span className="span1">
                Document is only partially completed. Do you want to continue
                without saving?
              </span>
              <span className="span2 span2_delete_document_client">
                If you leave before saving, your changes will be lost.
              </span>
              <div className="btnDiv-modal">
                <button
                  className="button1"
                  style={
                    {
                      // transform: "translateY(35%)",
                    }
                  }
                  onClick={handleMoveWithoutSaving}
                >
                  No
                </button>
                <button
                  className="button2"
                  style={{
                    width: "90px",
                    backgroundColor: dynamicColor,
                  }}
                  onClick={handleMoveWithSave}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </Fragment>
  );
};

export default ConfirmationModal;
