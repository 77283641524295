import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../../services/api";

const initialState: any = {
  getDocuments: [],
  getDocumentsWhenInClinicalTab: [],
  isFormOpenTrue: false,
  idOfDocumentToDelete: null,
  hasDeleteDocument: false,
  currentClientId: null,
  warningPopup: false,
  getAllDocuments: [],
  isDocumentsTableDropdownOpen: false,
  selectedTypeOfManipulation: null,
  selectedColumn: null,
  selectedOptions: [],
  selectedSortType: null,
  selectedColumnForSort: null,
  primaryReviewFilterFlag: false,
  uniquePrimaryApprovers: [],
  selectedApprovers: [],
  createDocComms: [],
  commsOfDoc: [],
  selectedDataObject: [],
  updateDocumentData: [],
  getDocumentsTags: [],
  // Filter
  searchValDoc: "",
  selectedDropdownItemDocuments: "",
  selectedItemIndexFilterDocuments: "",
  selectedItemIndexSortDocuments: "",
  sortByDocumentsR: "",
  selectedFilterItemsDocuments: [],
  getUnreadMessages: 0,
  getAllDocumentFiles: [],
  selectedDocument : null,
  selectedInterval: "",
  error:{}
  
};

export const getDocuments = createAsyncThunk(
  "clients/documents/fetchDocuments",
  async ({ clientId = "", providerId = "" }: any) => {
    try {
      let response;
      if(clientId){
         response = await api.get(
          `/documents?paginate=true&sort_column=id&page=1&limit=10000&sort_type=desc&user_id=${clientId}&tenant_id=${providerId}`
        );
      }
   
      return response?.data.data.data;
    } catch (error) {
      throw error;
    }
  }
);


export const getDocumentById = createAsyncThunk(
  "clients/documents/fetchDocuments",
  async ({ clientId = "", providerId = "" , id}: any) => {
    try {
      let response;
      if(clientId){
         response = await api.get(
          `/documents?paginate=true&sort_column=id&page=1&limit=10000&sort_type=desc&user_id=${clientId}&tenant_id=${providerId}&id=${id}`
        );
      }
   
      return response?.data.data.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getDocumentsForClinicalThunk = createAsyncThunk(
  "clients/documents/fetchDocumentsClinical",
  async ({ clientId = "", providerId = "" }: any) => {
    try {
      const response = await api.get(
        `/documents?paginate=true&sort_column=id&page=1&limit=10000&sort_type=desc&user_id=${clientId}&tenant_id=${providerId}`
      );
      return response.data.data.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getDocumentsTagsThunk = createAsyncThunk(
  "clients/documents/fetchDocumentsTags",
  async () => {
    try {
      const response = await api.get(
        `/tags/get_tags_by_type?tag_type=DOCUMENT`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
export const getDocumentsEntity = createAsyncThunk(
  "entity/documents/fetchDocuments",
  async ({ clientId = "", providerId = "" }: any) => {
    try {
      const response = await api.get(
        `/documents?paginate=true&sort_column=id&page=1&limit=1500&sort_type=desc&user_id=${clientId}&tenant_id=${providerId}`
      );
      return response.data.data.data;
    } catch (error) {
      throw error;
    }
  }
);
export const getDocumentsByFilterForEntity = createAsyncThunk(
  "clients/documents/fetchDocumentsByFilterForEntity",
  async ({
    clientId,
    title,
    searchVal,
    sortType,
    filterColumn,
    sortColumn,
    providerId = "",
  }: {
    clientId: any;
    title: any;
    searchVal: any;
    sortType: any;
    filterColumn: any;
    sortColumn: any;
    providerId?: any;
  }) => {
    try {
      const response = await api.get(
        `/documents?paginate=true&page=1&limit=200&sort_column=${sortColumn}&sort_type=${sortType}&user_id=${clientId}&filter_column=${filterColumn}&filter_value=${title}&search=${searchVal}&tenant_id=${providerId}`
      );
      return response.data.data.data;
    } catch (error) {
      throw error;
    }
  }
);
export const getFilteredDocumentsUsingApprovers = createAsyncThunk(
  "clients/documents/filterDocumentsUsingApprovers",
  async ({ clientId, filterValue }: any) => {
    try {
      const response = await api.get(
        `/documents?paginate=true&page=1&limit=1000&filter_column=primary_approver&filter_value=${filterValue}&user_id=${clientId}`
      );
      return response.data.data.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getUniquePrimaryApproversThunk = createAsyncThunk(
  "clients/documents/getUniquePrimaryApproversThunk",
  async (clientId: any) => {
    try {
      const response = await api.get(
        `/documents?unique_column=primary_approver&user_id=${clientId}`
      );
      return response.data.data.data;
    } catch (error) {
      throw error;
    }
  }
);
export const getUniquePrimaryApproversForEntityThunk = createAsyncThunk(
  "clients/documents/getUniquePrimaryApproversEntityThunk",
  async (clientId: any) => {
    try {
      const response = await api.get(
        `/documents?unique_column=primary_approver&entity_id=${clientId}`
      );
      return response.data.data.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getDocumentsFilteredForPrimaryReview = createAsyncThunk(
  "clients/documents/getDocumentsFilteredForPrimaryReview",
  async (clientId: any) => {
    try {
      const response = await api.get(
        `/documents?paginate=true&sort_column=id&page=1&limit=500&sort_type=desc&user_id=${clientId}`
      );
      return response.data.data.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getDocumentsByFilter = createAsyncThunk(
  "clients/documents/fetchDocumentsByFilter",
  async ({
    clientId,
    filterValue,
    searchVal,
    sortType,
    filterColumn,
    sortColumn,
    providerId = "",
  }: {
    clientId: any;
    filterValue: any;
    searchVal: any;
    sortType: any;
    filterColumn: any;
    sortColumn: any;
    providerId?: any;
  }) => {
    try {
      const response = await api.get(
        `/documents?paginate=true&page=1&limit=10000&sort_column=${sortColumn}&sort_type=${sortType}&user_id=${clientId}&filter_column=${filterColumn}&filter_value=${filterValue}&search=${searchVal}&tenant_id=${providerId}`
      );
      return response.data.data.data;
    } catch (error) {
      throw error;
    }
  }
);

// /documents?id=789&user_id=443

export const getCommsOfDocumentThunk = createAsyncThunk(
  "clients/documents/getCommsOfDocument",
  async ({ clientId, docId }: { clientId: any; docId: any }) => {
    try {
      const response = await api.get(
        `/documents?id=${docId}&user_id=${clientId}`
      );
      return response.data.data.data;
    } catch (error) {
      throw error;
    }
  }
);

export const createDocumentCommsThunk = createAsyncThunk(
  "communications/createDocumentCommunication",
  async (payload: any) => {
    try {
      const response = await api.post(
        "/communications/create_communication",
        payload
      );
      if (response.status !== 200) {
        throw new Error("Failed to create communication.");
      }
      return response.data;
    } catch (error: any) {
      throw new Error(error.message || "An error occurred.");
    }
  }
);

export const deleteDocumentThunk = createAsyncThunk(
  "clients/documents/deleteDocument",
  async (
    { documentId, clientId, providerId = "" }: any,
    { rejectWithValue }
  ) => {
    try {
      const response = await api.post("/documents/delete_document", {
        user_id: clientId,
        document_id: documentId,
        tenant_id: providerId,
      });

      if (response.data.status === "success") {
        return response.data;
      } else {
        return rejectWithValue("Failed to delete document");
      }
    } catch (error) {
      return rejectWithValue("An error occurred while deleting the document");
    }
  }
);
export const deleteDocumentEntityThunk = createAsyncThunk(
  "clients/documents/deleteDocumentEntity",
  async (
    { documentId, clientId, providerId = "" }: any,
    { rejectWithValue }
  ) => {
    try {
      const response = await api.post("/documents/delete_document", {
        user_id: clientId,
        document_id: documentId,
        tenant_id: providerId,
        // setConfirmDeleteDocument,
      });

      if (response.data.status === "success") {
        return response.data;
      } else {
        return rejectWithValue("Failed to delete document");
      }
    } catch (error) {
      return rejectWithValue("An error occurred while deleting the document");
    }
  }
);

export const createDocumentThunk = createAsyncThunk(
  "clients/documents/createDocument",
  async (documentDetails: any, { rejectWithValue }) => {
    try {
      if (documentDetails.title || documentDetails.summary) {
        const response = await api.post(
          "/documents/create_document",
          documentDetails
        );

        if (response.data.status === "success") {
          return response.data;
        } else {
          return rejectWithValue(error);
        }
      } else {
        return rejectWithValue(error);
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateDocumentThunk = createAsyncThunk(
  "clients/documents/updateDocument",
  async (updatedDocument: any, { rejectWithValue }) => {
    try {
      const response = await api.post(
        "/documents/update_document",
        updatedDocument
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.response);
    }
  }
);

export const getUnreadMessagesDocsThunk = createAsyncThunk(
  "document/getDocsForUnreadMessages",
  async ({ userId, linkId }: any) => {
    try {
      const response = await api.get(
        `/communications/get_unread_message_count?user_id=${userId}&link_id=${linkId}&comm_type=DOCUMENT`
      );
      return response.data.count;
    } catch (error: string | any) {
      throw error;
    }
  }
);

const documentSlice = createSlice({
  name: "document",
  initialState,
  reducers: {
    setIsFormOpenTrue: (state) => {
      state.isFormOpenTrue = true;
    },
    setIsFormOpenFalse: (state) => {
      state.isFormOpenTrue = false;
    },
    setConfirmDeleteDocument: (state, action) => {
      const { flag, id, clientId } = action.payload;
      state.hasDeleteDocument = flag;
      state.idOfDocumentToDelete = id;
      state.currentClientId = clientId;
    },
    resetConfirmDeleteDocument: (state) => {
      state.hasDeleteDocument = false;
      state.idOfDocumentToDelete = null;
      state.currentClientId = null;
    },
    setWarningPopup: (state, action) => {
      state.warningPopup = action.payload;
    },
    setIsDocumentsDropdownOpen: (state, action) => {
      state.isDocumentsTableDropdownOpen = true;
    },
    resetIsDocumentsDropdownOpen: (state) => {
      state.isDocumentsTableDropdownOpen = false;
      state.selectedTypeOfManipulation = null;
      state.selectedColumn = null;
      state.selectedOptions = null;
      state.selectedColumnForSort = null;
      state.selectedSortType = null;
    },
    setselectedTypeOfManipulation: (state, action) => {
      state.selectedTypeOfManipulation = action.payload;
    },
    setselectedColumn: (state, action) => {
      state.selectedColumn = action.payload;
    },
    resetselectedColumn: (state) => {
      state.selectedColumn = null;
    },
    setselectedOptions: (state, action) => {
      state.selectedOptions = action.payload;
    },
    setinputSearchVal: (state, action) => {
      state.searchValDoc = action.payload;
    },
    resetinputSearchVal: (state, action) => {
      state.searchValDoc = "";
    },
    setsortType: (state, action) => {
      const type = action.payload;
      state.selectedSortType = type;
    },
    setselectedcolumnforsort: (state, action) => {
      state.selectedColumnForSort = action.payload;
    },
    resetselectedcolumnforsort: (state) => {
      state.selectedColumnForSort = null;
    },
    setselectedApprovers: (state, action) => {
      state.selectedApprovers = action.payload;
    },
    resetselectedApprovers: (state) => {
      state.selectedApprovers = [];
    },
    setselectedDataObject: (state, action) => {
      state.selectedDataObject = action.payload;
    },
    resetselectedDataObject: (state) => {
      state.selecetdDataObject = [];
    },
    setUpdateDocumentDataToStore: (state, action) => {
      state.updateDocumentData = action.payload;
    },
    setSelectedInterval: (state, action) => {
      state.selectedInterval = action.payload;
    },
    resetSelectedInterval: (state, action) => {
      state.selectedInterval = null;
    },

    setselectedDocument : (state, action) => {
      state.selectedDocument = action.payload
    },
    resetselectedDocument : (state, action) => {
      state.selectedDocument = null
    },
    // Filter
    setSelectedDropdownItemDocuments: (state, action) => {
      state.selectedDropdownItemDocuments = action.payload;
    },
    resetSelectedDropdownItemDocuments: (state) => {
      state.selectedDropdownItemDocuments = "";
    },
    setSelectedItemIndexFilterDocuments: (state, action) => {
      state.selectedItemIndexFilterDocuments = action.payload;
    },
    resetSelectedItemIndexFilterDocuments: (state) => {
      state.selectedItemIndexFilterDocuments = "";
    },
    setSelectedItemIndexSortDocuments: (state, action) => {
      state.selectedItemIndexSortDocuments = action.payload;
    },
    resetSelectedItemIndexSortDocuments: (state) => {
      state.selectedItemIndexSortDocuments = "";
    },
    setSortByDocumentsR: (state, action) => {
      state.sortByDocumentsR = action.payload;
    },
    resetSortByDocumentsR: (state) => {
      state.sortByDocumentsR = "";
    },
    setSelectedFilterItemsDocuments: (state, action) => {
      state.selectedFilterItemsDocuments = action.payload;
    },
    resetSelectedFilterItemsDocuments: (state) => {
      state.selectedFilterItemsDocuments = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDocumentsForClinicalThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.getDocumentsWhenInClinicalTab = action.payload;
      })
      .addCase(getDocumentsForClinicalThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getDocumentsForClinicalThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDocuments.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDocuments.fulfilled, (state, action) => {
        state.loading = false;
        state.getDocuments = action.payload;
        state.getAllDocuments = action.payload;
      })

      .addCase(getDocuments.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(getDocumentsTagsThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDocumentsTagsThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.getDocumentsTags = action.payload.data;
      })
      .addCase(getDocumentsTagsThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      // getDocumentsTagsThunk
      .addCase(getDocumentsEntity.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDocumentsEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.getDocuments = action.payload;
        state.getAllDocuments = action.payload;
      })
      .addCase(getDocumentsEntity.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getCommsOfDocumentThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCommsOfDocumentThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.commsOfDoc = action.payload;
      })
      .addCase(getCommsOfDocumentThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      // commsOfDoc
      .addCase(getFilteredDocumentsUsingApprovers.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        getFilteredDocumentsUsingApprovers.fulfilled,
        (state, action) => {
          state.loading = false;
          state.getDocuments = action.payload;
        }
      )
      .addCase(getFilteredDocumentsUsingApprovers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getUniquePrimaryApproversThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUniquePrimaryApproversThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.uniquePrimaryApprovers = action.payload;
      })
      .addCase(getUniquePrimaryApproversThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getUniquePrimaryApproversForEntityThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        getUniquePrimaryApproversForEntityThunk.fulfilled,
        (state, action) => {
          state.loading = false;
          state.uniquePrimaryApprovers = action.payload;
        }
      )
      .addCase(
        getUniquePrimaryApproversForEntityThunk.rejected,
        (state, action) => {
          state.loading = false;
          state.error = action.error.message;
        }
      )
      .addCase(getDocumentsFilteredForPrimaryReview.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        getDocumentsFilteredForPrimaryReview.fulfilled,
        (state, action) => {
          state.loading = false;
          const data = action.payload;
          const setFilteredDataByPrimaryApprovers = data.filter(
            (elem: any) => elem?.primary_review != null
          );
          // state.getDocuments = setFilteredDataByPrimaryApprovers;
        }
      )
      .addCase(
        getDocumentsFilteredForPrimaryReview.rejected,
        (state, action) => {
          state.loading = false;
          state.error = action.error.message;
        }
      )
      .addCase(getDocumentsByFilter.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDocumentsByFilter.fulfilled, (state, action) => {
        state.loading = false;
        state.getDocuments = action.payload;
      })
      .addCase(getDocumentsByFilter.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getDocumentsByFilterForEntity.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDocumentsByFilterForEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.getDocuments = action.payload;
      })
      .addCase(getDocumentsByFilterForEntity.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(createDocumentCommsThunk.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createDocumentCommsThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.createDocComms = action.payload;
      })
      .addCase(createDocumentCommsThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "An error occurred.";
      })
      .addCase(createDocumentThunk.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createDocumentThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.createDocument = action.payload;
      })
      .addCase(createDocumentThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action || "An error occurred.";
      })
      .addCase(updateDocumentThunk.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateDocumentThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.updateDocument = action.payload;
      })
      .addCase(updateDocumentThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getUnreadMessagesDocsThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUnreadMessagesDocsThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.getUnreadMessages = action.payload;
      })
      .addCase(getUnreadMessagesDocsThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const {
  setIsFormOpenTrue,
  setWarningPopup,
  setIsFormOpenFalse,
  setConfirmDeleteDocument,
  resetConfirmDeleteDocument,
  setIsDocumentsDropdownOpen,
  resetIsDocumentsDropdownOpen,
  setselectedTypeOfManipulation,
  setselectedColumn,
  setselectedOptions,
  setinputSearchVal,
  resetinputSearchVal,
  setsortType,
  setselectedcolumnforsort,
  resetselectedcolumnforsort,
  resetselectedColumn,
  setselectedApprovers,
  resetselectedApprovers,
  setselectedDataObject,
  setUpdateDocumentDataToStore,
  setSelectedDropdownItemDocuments, // Filter
  resetSelectedDropdownItemDocuments,
  setSelectedItemIndexFilterDocuments,
  resetSelectedItemIndexFilterDocuments,
  setSelectedItemIndexSortDocuments,
  setselectedDocument,
  resetselectedDocument,
  resetSelectedItemIndexSortDocuments,
  setSortByDocumentsR,
  resetSortByDocumentsR,
  setSelectedFilterItemsDocuments,
  resetSelectedFilterItemsDocuments,
  setSelectedInterval,
  resetSelectedInterval
} = documentSlice.actions;

export default documentSlice.reducer;
