import { useAppSelector } from "../../../../Controller/redux/Hooks";
import { statusMap } from "../../../../Model/utils/Helper/statusMap";

import WidgetItems from "../../../pages/People/ClientPage/Components/WidgetItems";

interface StatusClientPropsTypes {
  statusData: any;
  position: any;
  setOpenStatusWidget: (arg: any) => any;
  OpenStatusWidget: any;
  widgetRef: any;
  setAllClientsDataToTrackClickOfStatusClick: (arg: any) => any;
  allClientsDataToTrackClickOfStatusClick: any;
  handleOpenWidget: (arg: any) => any;
  setShowStatusWidget: (arg: any) => any;
  itemsArr: any;
}

const StatusClient: React.FC<StatusClientPropsTypes> = ({
  statusData,
  position,
  setOpenStatusWidget,
  OpenStatusWidget,
  widgetRef,
  setAllClientsDataToTrackClickOfStatusClick,
  allClientsDataToTrackClickOfStatusClick,
  handleOpenWidget,
  setShowStatusWidget,
  itemsArr,
}) => {
  return (
    <div
      className="status-btn status-btn-active status-btn-active-clients"
      style={{
        position: "absolute",
        right: "20px",
        top: "25%",
        color: statusData?.color,
        backgroundColor: statusData?.bgCol,
        minWidth: statusData?.type?.includes("On Leave") ? "90px" : "auto",
        // border: `1px solid ${statusData?.color}`,
        // zIndex: 10,
        fontWeight: 700,
      }}
      onClick={(e) => handleOpenWidget(e)}
    >
      {statusData?.type}
      {OpenStatusWidget && (
        <div ref={widgetRef} className="status-widget-div">
          <WidgetItems
            type={"clientScreen"}
            setAllClientsDataToTrackClickOfStatusClick={
              setAllClientsDataToTrackClickOfStatusClick
            }
            position={position}
            widgetRef={widgetRef}
            allClientsDataToTrackClickOfStatusClick={
              allClientsDataToTrackClickOfStatusClick
            }
            setOpenStatusWidget={setOpenStatusWidget}
            setShowStatusWidget={setShowStatusWidget}
            itemsArr={itemsArr}
          />
        </div>
      )}
    </div>
  );
};

export default StatusClient;
