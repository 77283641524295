export const PlusSVG = () => {
  return (
    <>
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="9" cy="9" r="9" fill="#FF5F00" />
        <path
          d="M9.83516 8.22148H13V9.79642H9.83516V13H8.16484V9.79642H5V8.22148H8.16484V5H9.83516V8.22148Z"
          fill="white"
        />
        <path
          d="M9.83516 8.22148H13V9.79642H9.83516V13H8.16484V9.79642H5V8.22148H8.16484V5H9.83516V8.22148Z"
          fill="white"
        />
      </svg>
    </>
  );
};
