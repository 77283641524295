import "./MobileCard.css";
import "./MobileHomeScreen.css";
import { MobileStickyHeader } from "./MobileCard";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BottomTabs } from "./StickyItems";
import { useAppDispatch, useAppSelector } from "../../../../Controller/redux/Hooks";
import SortSVG from "../../../pages/Daybook/Data/SortSVG";
import { ReplyIcon } from "../../CommonModal/Data/SVGs";
import { MastercardSVG } from "../../../pages/Roster/Data/Mastercard";
import GreenColorSVG from "../../../pages/Comms/Data/GreenColorSVG";
import ClinicalNote from "../../ClinicalProgress/Components/ClinicalNote";
import {getCommsForUserThunk} from "../../../../Controller/redux/features/CommsFeature/commsSlice";
import {setsortType} from "../../../../Controller/redux/features/CommonFeature/documentSlice";

const big_tab_data = [
  {
    id: 1,
    count_number: 87,
    tab_title: "Inbox",
    route: "/comms",
  },
  {
    id: 2,
    count_number: 123,
    tab_title: "Today",
    route: "/my-daybook",
  },
  {
    id: 3,
    count_number: 12,
    tab_title: "Clients",
    route: "/people/clients",
  },
];

const coloredTabData = [
  {
    id: 1,
    color: "#2AD400",
    title: "C893",
  },
  {
    id: 2,
    color: "#2AD400",
    title: "C894",
  },
  {
    id: 3,
    color: "#F1B60D",
    title: "Check List",
  },
  {
    id: 4,
    color: "#EF3A34",
    title: "Check List",
  },
  {
    id: 5,
    color: "#EF3A34",
    title: "Check List",
  },
  {
    id: 6,
    color: "#EF3A34",
    title: "Check List",
  },
];

const SquareTabs = ({
  tab,
  index,
  isActive,
  setActiveItemId,
  activeTab,
  setActiveTab,
}: any) => {
  const navigate = useNavigate();
  return (
    <div
      className={`square_tab_container`}
      onClick={() => {
        setActiveItemId(index);
        navigate(tab.route);
      }}
    >
      <div className={`square_tabs ${isActive ? "active" : ""} `}>
        <p className="count_number">{tab.count_number}</p>
      </div>
      <p className="square_tab_title">{tab.tab_title}</p>
    </div>
  );
};

const ColoredSquares = ({ tab, color }: any) => {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div
        className={`colored_tab_container`}
        style={{ backgroundColor: color }}
      ></div>
      <div>
        <p className="colored_tab_title">{tab.title}</p>
      </div>
    </div>
  );
};

const UrgencySquares = () => {
  return (
    <div className="urgency_container">
      <p className="urgent_text">URGENT</p>
      <div className="colored_tab_list">
        {coloredTabData.map((tab, index) => {
          return <ColoredSquares tab={tab} key={tab.id} color={tab.color} />;
        })}
      </div>
    </div>
  );
};

const RecentEmailComponent = () => {
  const [ sortType, setSortType ] = useState('desc');

  const [clientId, setClientId] = useState("");
  const { user } = useAppSelector((state) => state?.common);
  useEffect(() => {
    if (user) {
      setClientId(user?.id);
    }
  }, [user]);
  const dispatch = useAppDispatch();

  return (
    <>
      <div className="recent_email_container">
        RECENT COMMS
        <SortSVG
          fill="white"
          onClick={() => {
            let toggleSortType = sortType == "desc" ? "asc": "desc";
            setSortType(toggleSortType);
            dispatch(
              getCommsForUserThunk({
                clientId,
                page: 1,
                endpoint: "get_comms",
                sortType: sortType,
                search: "",
                unread: "",
              })
            );
          }}
          Styles={{
            color: "white",
            backgroundColor: "#07A5FF",
            padding: "2px",
            width: "16px",
            height: "16px",
            borderRadius: "50%",
            marginLeft: "6px",
          }}
        />
      </div>
    </>
  );
};

const CardComponent = () => {
  return (
    <>
      <div className="card_component_container">
        <div className="card_header_container">
          <div className="card_header_left_section">
            <span className="mastercard_section">
              <MastercardSVG />
            </span>
            <span className="reply_section">
              <ReplyIcon />
              {`Reply`}
            </span>
          </div>
          <div className="card_header_right_section">
            <span className="date_and_time_section">Yesterday,10.15am</span>
            <span className="plus_icon_section">
              <svg
                width={12}
                height={12}
                viewBox="0 0 12 12"
                fill={"#F3F3F3"}
                style={{
                  cursor: "pointer",
                }}
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="6" cy="6" r="6" fill={"#BBBBBB"} />
                <path
                  d="M6.83126 5.21952H9.99609V6.79447H6.83126V9.99805H5.16093V6.79447H1.99609V5.21952H5.16093V1.99805H6.83126V5.21952Z"
                  fill={"#F3F3F3"}
                />
              </svg>
            </span>
            <div className="tag_container">
              <span className="tag_section">0</span>
              Tag
            </div>
            <span className="green_dot_section">
              <GreenColorSVG />
            </span>
          </div>
        </div>
        <div className="card_title_container">Docs & building tools.</div>
        <div className="card_summary_section">
          <div className="summary_container">
            <p className="summary_text">
              Summary and description of activity across two lines of text.
            </p>
          </div>{" "}
          <svg
            style={{}}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 12 12"
            fill="none"
          >
            <path
              d="M6 0C9.3138 0 12 2.6862 12 6C12 9.3138 9.3138 12 6 12C5.0424 12.0013 4.0985 11.7725 3.2478 11.3328C2.97312 11.1438 2.6433 11.1219 2.35089 11.2821C1.79004 11.5894 0.888309 12.0621 0.560239 11.976C0.431624 11.9423 0.314294 11.875 0.220271 11.7809C0.126247 11.6869 0.0589109 11.5696 0.0251613 11.441C-0.00858834 11.3123 -0.00753788 11.1771 0.028205 11.049L0.669005 8.75519C0.228216 7.90376 -0.00124128 6.95877 5.05016e-06 6C5.05016e-06 2.6862 2.6862 0 6 0ZM4.05 6.6H6.7488C6.86282 6.60003 6.97257 6.64334 7.05588 6.72118C7.13919 6.79902 7.18985 6.90558 7.19762 7.01932C7.20539 7.13307 7.16969 7.24553 7.09774 7.33398C7.02579 7.42242 6.92295 7.48026 6.81 7.4958L6.7488 7.5H4.05C3.93599 7.49996 3.82624 7.45665 3.74293 7.37881C3.65962 7.30098 3.60896 7.19442 3.60119 7.08067C3.59342 6.96692 3.62911 6.85446 3.70106 6.76602C3.77301 6.67757 3.87585 6.61974 3.9888 6.6042L4.05 6.6ZM4.05 4.5H7.953C8.06697 4.50018 8.17661 4.5436 8.25981 4.62149C8.34301 4.69937 8.39355 4.80592 8.40124 4.91962C8.40894 5.03333 8.3732 5.14571 8.30125 5.23409C8.2293 5.32247 8.1265 5.38026 8.0136 5.3958L7.953 5.4H4.05C3.93599 5.39996 3.82624 5.35665 3.74293 5.27881C3.65962 5.20098 3.60896 5.09442 3.60119 4.98067C3.59342 4.86692 3.62911 4.75446 3.70106 4.66602C3.77301 4.57757 3.87585 4.51974 3.9888 4.5042L4.05 4.5Z"
              fill={"#E1E1E1"}
            />
            <text
              x="6"
              y="6"
              fill="#FFF"
              textAnchor="middle"
              fontFamily="Arial"
              fontSize="7px"
              fontStyle="normal"
              fontWeight="700"
              letterSpacing="0.25px"
              alignmentBaseline="central"
            >
              {13}
            </text>
          </svg>
        </div>
      </div>
    </>
  );
};

function MobileHomeScreen() {
  const [uniqueAllCommsData, setuniqueAllCommsData] = useState<any>([]);
  const { getAllComms }: any =
    useAppSelector((state) => state?.comms);
  const [commType, setCommType] = useState("");
  const { user } = useAppSelector((state) => state?.common);
  const dispatch = useAppDispatch();
  const userId = user?.id;
  const [clientId, setClientId] = useState("");
  useEffect(() => {
    if (user) {
      setClientId(user?.id);
    }
  }, [user]);

  useEffect(() => {
    if(clientId){
      dispatch(
        getCommsForUserThunk({
          clientId,
          page: 1,
          endpoint: "get_comms",
          sortType: "desc",
          search: "",
          unread: "",
        })
      );
    }
  },[clientId])
  // console.log(getAllComms,"C")

  useEffect(() => {
    if (getAllComms) {
      setuniqueAllCommsData(getAllComms);
    }
  }, [commType, getAllComms]);
  const [activeItemId, setActiveItemId] = useState(0);
  const [activeTab, setActiveTab] = useState("Inbox");
  const userData = useAppSelector((state) => state.common);
  return (
    <div className="main_container">
      <div style={{ display: "flex", flexDirection: "column" }}>
        <MobileStickyHeader title={"Your  Home"} />
        <div className="tab_list_container">
          {big_tab_data.map((tab, index) => {
            return (
              <SquareTabs
                key={tab.id}
                tab={tab}
                index={index}
                isActive={index === activeItemId}
                setActiveItemId={setActiveItemId}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
              />
            );
          })}
        </div>
        <UrgencySquares />
        <RecentEmailComponent />

        {uniqueAllCommsData?.map((res: any, index: number) => {
              return (
                <>
                 <ClinicalNote
                        isComms={true}
                        index={index}
                        clientId={userId}
                        isFromCol1={true}
                        // isSubTaskOpen={isSubTaskOpen}
                        // setIsSubTaskOpen={setIsSubTaskOpen}
                        elem={res}
                        uniqueAllCommsData={uniqueAllCommsData}
                        noteTitle={"Message"}
                        clinicalGoalsNote={(() => {
                          switch (res.comm_type) {
                            case "COMM":
                              return res?.comm_title || "";
                            case "DAYBOOK":
                              return res.daybook_title || "";
                            case "DOCUMENT":
                              return res.document_title || "";
                            case "MEAL":
                              return res.meal_title || "";
                            default:
                              return "";
                          }
                        })()}
                      />
                </>
              );
          })
        }
      </div>
      <BottomTabs
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        item={userData.user}
        isHomeScreen={true}
      />
    </div>
  );
}

export default MobileHomeScreen;
