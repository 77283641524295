export const PictureSVG = () => {
  return (
    <>
      <svg

        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="14"
        viewBox="0 0 10 10"
        fill="none"
        style={{
          marginTop: "5px",
        }}
      >
        <path
          d="M8.76506 0H1.2249C0.552209 0 0 0.552209 0 1.2249V8.7751C0 9.44779 0.552209 10 1.2249 10H8.7751C9.44779 10 10 9.44779 10 8.7751V1.2249C10 0.552209 9.44779 0 8.7751 0H8.76506ZM1.2249 0.753012H8.7751C9.03614 0.753012 9.24699 0.963855 9.24699 1.2249V6.77711L7.26908 4.17671L5.48193 6.4759L3.09237 3.38353L0.753012 6.25502V1.2249C0.753012 0.963855 0.963855 0.753012 1.2249 0.753012ZM0.753012 8.76506V7.43976L3.06225 4.57831L6.64659 9.22691H1.2249C0.963855 9.22691 0.753012 9.01606 0.753012 8.75502V8.76506ZM8.76506 9.23695H7.6004L5.94378 7.08835L7.249 5.41165L9.23695 8.02209V8.7751C9.23695 9.03614 9.0261 9.24699 8.76506 9.24699V9.23695Z"
          fill="black"
        />
        <path
          d="M6.26332 3.50409C6.86573 3.50409 7.3577 3.01213 7.3577 2.40972C7.3577 1.80731 6.86573 1.31534 6.26332 1.31534C5.66091 1.31534 5.16895 1.80731 5.16895 2.40972C5.16895 3.01213 5.66091 3.50409 6.26332 3.50409ZM6.26332 2.05831C6.45409 2.05831 6.60469 2.20891 6.60469 2.39968C6.60469 2.59044 6.45409 2.74104 6.26332 2.74104C6.07256 2.74104 5.92196 2.59044 5.92196 2.39968C5.92196 2.20891 6.07256 2.05831 6.26332 2.05831Z"
          fill="black"
        />
      </svg>
    </>
  );
};

export const AttachmentSVG = ({isHighlighted}: any) => {
  return (
    <>
      <svg
        style={{
          marginTop: "5px",
          width:"28px",
          height:"15px",
          

        }}

        className="comms_svg_icon"
        xmlns="http://www.w3.org/2000/svg"
        width="28"
        height="14"
        viewBox="0 0 10 10"
        fill="none"
      >
        <path
          d="M2.86573 10C2.09419 10 1.37275 9.6994 0.841683 9.1483C0.300601 8.60721 0 7.87575 0 7.10421C0 6.33267 0.300601 5.61122 0.841683 5.06012L5.3507 0.511022C6.03206 -0.170341 7.33467 -0.170341 8.01603 0.511022C8.36673 0.861723 8.56713 1.35271 8.56713 1.85371C8.56713 2.35471 8.36673 2.84569 8.01603 3.19639L3.48697 7.75551C3.32665 7.91583 3.10621 8.01603 2.86573 8.01603C2.63527 8.03607 2.38477 7.92585 2.22445 7.75551C2.05411 7.58517 1.96393 7.36473 1.96393 7.11423C1.96393 6.86373 2.06413 6.64329 2.22445 6.47295L6.40281 2.27455C6.55311 2.12425 6.78357 2.12425 6.93387 2.27455C7.08417 2.42485 7.08417 2.65531 6.93387 2.80561L2.74549 7.00401C2.74549 7.00401 2.70541 7.07415 2.70541 7.11423C2.70541 7.16433 2.71543 7.19439 2.74549 7.22445C2.76553 7.24449 2.82565 7.25451 2.84569 7.26453C2.88577 7.26453 2.91583 7.25451 2.93587 7.23447L7.48497 2.66533C7.69539 2.45491 7.81563 2.15431 7.81563 1.85371C7.81563 1.55311 7.69539 1.2525 7.48497 1.04208C7.08417 0.641283 6.28257 0.641283 5.88176 1.04208L1.36273 5.6012C0.961924 6.002 0.741483 6.54309 0.741483 7.11423C0.741483 7.68537 0.961924 8.21643 1.36273 8.62725C2.15431 9.43888 3.55711 9.43888 4.3487 8.62725L8.87776 4.06814C9.02806 3.91784 9.25852 3.91784 9.40882 4.06814C9.55912 4.21844 9.55912 4.4489 9.40882 4.5992L4.87976 9.1483C4.33868 9.68938 3.61723 10 2.85571 10H2.86573Z"
          fill={isHighlighted ? "aqua" : "black"}
        />
      </svg>
    </>
  );
};
