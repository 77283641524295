// @ts-nocheck
import "../Styles/RosterWrapper.css";
import React, { useState, useEffect, useRef } from "react";
import { TempArr } from "../Data/TempArr";
import { FilterSVG } from "../Data/FilterSVG";
import { formatTime } from "../Helpers/formatTime";
import { staticTimeline } from "../Data/Timeline";
import StaffSVG from "../Data/StaffSVG";
import RosterCapsuleComponent from "./RosterCapsuleComponent";
import ThirdStageBoxComponent from "./ThirdStageBoxComponent";

const RosterWrapper = () => {
  const clickCountRef = useRef(0);
  const initialXRefLeft = useRef();
  const initialXRefRight = useRef();
  const [currentTime, setCurrentTime] = useState(new Date());
  const [showTooltip, setShowToolTip] = useState(false);
  const [rosterData, setRosterData] = useState([]);
  const [clickedIndex, setClickedIndex] = useState(null);
  const [matchingItemIndex, setMatchingItemIndex] = useState("");
  const [forwards, setForwards] = useState(false);
  const [itemBeingDragged, setitemBeingDragged] = useState({
    currentAppointment: "",
    currentRosterIndex: "",
    destinationRosterIndex: "",
  });

  useEffect(() => {
    if (TempArr) {
      setRosterData(TempArr);
    }
  }, []);

  const currentHour = currentTime.getHours();
  const currentMinute = currentTime.getMinutes();

  const handleClick = (rosterItemIndex, matchingItem) => {
    clickCountRef.current += 1;

    setTimeout(() => {
      setClickedIndex(rosterItemIndex);
      setMatchingItemIndex(matchingItem.type);
      let tempArr = [...rosterData];
      let rosterCurrentItem = tempArr[rosterItemIndex];
      let currentAppointment = rosterCurrentItem.appointment.findIndex(
        (elem) => elem.type === matchingItem.type
      );

      if (clickCountRef.current === 1) {
        tempArr[rosterItemIndex].appointment[
          currentAppointment
        ].current_stage = 3;
      } else if (clickCountRef.current >= 2) {
        tempArr[rosterItemIndex].appointment[
          currentAppointment
        ].current_stage = 1;
      }

      setRosterData(tempArr);
      clickCountRef.current = 0;
    }, 200);
  };

  const handleDragStart = (event, rosterIndex, appointment) => {
    let tempArr = [...rosterData];
    let itemBeingDragged = tempArr[rosterIndex];
    let currentAppointment = itemBeingDragged.appointment.find(
      (elem) => elem.type === appointment.type
    );

    setitemBeingDragged((prev) => ({
      ...prev,
      currentAppointment: currentAppointment,
      currentRosterIndex: rosterIndex,
    }));
  };

  const handleDragOver = (event, rosterItemIndex) => {
    event.preventDefault();
    setitemBeingDragged((prev) => ({
      ...prev,
      destinationRosterIndex: rosterItemIndex,
    }));
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const { currentRosterIndex, destinationRosterIndex, currentAppointment } =
      itemBeingDragged;

    let tempArr = [...rosterData];

    const boundingRect = document
      .querySelector(".time_bar")
      .getBoundingClientRect();
    const clickX = event.clientX - boundingRect.left;
    const clickPercentage = (clickX / boundingRect.width) * 100;

    const totalSlots = staticTimeline.length;
    const slotWidthPercentage = 100 / totalSlots;
    const clickedSlotIndex = Math.floor(clickPercentage / slotWidthPercentage);
    const clickedEventTime = staticTimeline[clickedSlotIndex];

    const updatedCurrentRoster = tempArr[currentRosterIndex].appointment.filter(
      (elem) => elem.type !== currentAppointment.type
    );
    const previousStage = tempArr[currentRosterIndex].appointment.find(
      (elem) => elem.type === currentAppointment.type
    )?.current_stage;
    currentAppointment.start_time = clickedEventTime;

    currentAppointment.current_stage = previousStage;

    tempArr[currentRosterIndex].appointment = updatedCurrentRoster;

    tempArr[destinationRosterIndex].appointment.push(currentAppointment);

    setRosterData(tempArr);
 
    
    setitemBeingDragged({
      currentAppointment: "",
      currentRosterIndex: "",
      destinationRosterIndex: "",
    });
  };

  const handleDecrementStartime = (rosterItemIndex, matchingIndex, deltaX) => {
    const updatedRosterData = [...rosterData];
    const rosterIndex = updatedRosterData.findIndex(
      (elem, index) => index === rosterItemIndex
    );

    if (rosterIndex !== -1) {
      const appointmentIndex = updatedRosterData[
        rosterIndex
      ].appointment.findIndex((item) => item.type === matchingIndex);

      if (appointmentIndex !== -1) {
        const [hours, minutesStr] =
          updatedRosterData[rosterIndex].appointment[
            appointmentIndex
          ].start_time.split(":");
        const [minutes, ampm] = minutesStr.split(" ");

        let updatedHours = parseInt(hours);
        let updatedMinutes = parseInt(minutes);

        if (deltaX < -10) {
          updatedMinutes += -15;
        } else if (deltaX < -15) {
          updatedMinutes += -20;
        } else {
          updatedMinutes += -5;
        }

        if (updatedMinutes < 0) {
          updatedMinutes += 60;
          updatedHours -= 1;
          if (updatedHours < 0) {
            updatedHours += 24;
          }
        }
        if (updatedHours === 0) {
          updatedHours = 12;
        }

        const updatedTime = `${updatedHours
          .toString()
          .padStart(2, "0")}:${updatedMinutes
          .toString()
          .padStart(2, "0")} ${ampm}`;

        updatedRosterData[rosterIndex].appointment[
          appointmentIndex
        ].start_time = updatedTime;

        setRosterData(updatedRosterData);
      }
    }
  };

  const handleIncrementStartTime = (rosterItemIndex, matchingIndex, deltaX) => {
    const updatedRosterData = [...rosterData];
    const rosterIndex = updatedRosterData.findIndex(
      (elem, index) => index === rosterItemIndex
    );

    if (rosterIndex !== -1) {
      const appointmentIndex = updatedRosterData[
        rosterIndex
      ].appointment.findIndex((item) => item.type === matchingIndex);

      if (appointmentIndex !== -1) {
        const [hours, minutesStr] =
          updatedRosterData[rosterIndex].appointment[
            appointmentIndex
          ].start_time.split(":");
        const [minutes, ampm] = minutesStr.split(" ");

        let updatedHours = parseInt(hours);
        let updatedMinutes = parseInt(minutes);

        if (deltaX > 10) {
          updatedMinutes += 15;
        } else if (deltaX > 15) {
          updatedMinutes += 20;
        } else {
          updatedMinutes += 5;
        }

        if (updatedMinutes >= 60) {
          updatedMinutes -= 60;
          updatedHours += 1;
          if (updatedHours >= 24) {
            updatedHours -= 24;
          }
        }

        if (updatedHours === 13) {
          updatedHours = "01";
        }
        const updatedTime = `${updatedHours
          .toString()
          .padStart(2, "0")}:${updatedMinutes
          .toString()
          .padStart(2, "0")} ${ampm}`;

        // console.log(updatedHours);
        updatedRosterData[rosterIndex].appointment[
          appointmentIndex
        ].start_time = updatedTime;

        setRosterData(updatedRosterData);
      }
    }
  };

  const handleDecrementEndTime = (rosterItemIndex, matchingIndex, deltaX) => {
    const updatedRosterData = [...rosterData];
    const rosterIndex = updatedRosterData.findIndex(
      (elem, index) => index === rosterItemIndex
    );

    if (rosterIndex !== -1) {
      const appointmentIndex = updatedRosterData[
        rosterIndex
      ].appointment.findIndex((item) => item.type === matchingIndex);

      if (appointmentIndex !== -1) {
        const [hours, minutesStr] =
          updatedRosterData[rosterIndex].appointment[
            appointmentIndex
          ].end_time.split(":");
        const [minutes, ampm] = minutesStr.split(" ");

        let updatedHours = parseInt(hours);
        let updatedMinutes = parseInt(minutes);

        if (deltaX < -10) {
          updatedMinutes += -15;
        } else if (deltaX < -15) {
          updatedMinutes += -20;
        } else {
          updatedMinutes += -5;
        }

        if (updatedMinutes < 0) {
          updatedMinutes += 60;
          updatedHours -= 1;
          if (updatedHours < 0) {
            updatedHours += 24;
          }
        }

        const updatedTime = `${updatedHours
          .toString()
          .padStart(2, "0")}:${updatedMinutes
          .toString()
          .padStart(2, "0")} ${ampm}`;

        updatedRosterData[rosterIndex].appointment[appointmentIndex].end_time =
          updatedTime;

        setRosterData(updatedRosterData);
      }
    }
  };

  const handleIncrementEndTime = (rosterItemIndex, matchingIndex, deltaX) => {
    const updatedRosterData = [...rosterData];
    const rosterIndex = updatedRosterData.findIndex(
      (elem, index) => index === rosterItemIndex
    );

    if (rosterIndex !== -1) {
      const appointmentIndex = updatedRosterData[
        rosterIndex
      ].appointment.findIndex((item) => item.type === matchingIndex);

      if (appointmentIndex !== -1) {
        const [hours, minutesStr] =
          updatedRosterData[rosterIndex].appointment[
            appointmentIndex
          ].end_time.split(":");
        const [minutes, ampm] = minutesStr.split(" ");

        let updatedHours = parseInt(hours);
        let updatedMinutes = parseInt(minutes);

        if (deltaX > 10) {
          updatedMinutes += 15;
        } else if (deltaX > 15) {
          updatedMinutes += 20;
        } else {
          updatedMinutes += 5;
        }

        if (updatedMinutes >= 60) {
          updatedMinutes -= 60;
          updatedHours += 1;
          if (updatedHours >= 24) {
            updatedHours -= 24;
          }
        }

        const updatedTime = `${updatedHours
          .toString()
          .padStart(2, "0")}:${updatedMinutes
          .toString()
          .padStart(2, "0")} ${ampm}`;

        updatedRosterData[rosterIndex].appointment[appointmentIndex].end_time =
          updatedTime;

        setRosterData(updatedRosterData);
      }
    }
  };

  const renderTimeline = () => {
    return staticTimeline.map((elem, index) => (
      <span
        key={elem}
        className={`event_time${
          index === staticTimeline.length - 1 ? " last_event" : ""
        }`}
      >
        {elem}
      </span>
    ));
  };

  return (
    <div className="roster_wrapper">
      <div className="timeline_filter_wrapper">
        <div className="filter_wrapper">
          <FilterSVG />
          <span className="staff_view">STAFF VIEW</span>
        </div>
        <div className="time_bar">
          {renderTimeline()}
          <div
            className="current_time_indicator"
            onMouseEnter={() => setShowToolTip(true)}
            onMouseLeave={() => setShowToolTip(false)}
            style={{
              left: `${
                ((currentHour * 60 + currentMinute) / (24 * 60)) * 100
              }%`,
            }}
          >
            {showTooltip && (
              <>
                <span className="tooltip-1">{formatTime(currentTime)}</span>
              </>
            )}
          </div>
        </div>
      </div>

      <div className="roster_loop_wrapper">
        {Array.isArray(rosterData) &&
          rosterData.map((elem, rosterItemIndex) => (
            <div
              className="roster_item"
              onDragOver={(event) => handleDragOver(event, rosterItemIndex)}
              onDrop={handleDrop}
              key={elem.name}
            >
              <div className="left_panel_roster">
                <span className="current_staff_name">
                  {elem.name}
                  <StaffSVG />
                </span>
              </div>
              <div className="right_panel_roster">
                <div className="progress_wrapper">
                  <span className="progress_bar" />
                  {staticTimeline.map((time, index) => {
                    const matchingItems = elem.appointment.filter((item) => {
                      const hour = item.start_time.split(":")[0];
                      const timeZone = item.start_time
                        .split(":")[1]
                        .split(" ")[1];
                      return (
                        hour === time.split(":")[0] &&
                        timeZone === time.split(":")[1].split(" ")[1]
                      );
                    });
                    return (
                      <div
                        key={time}
                        className={`time_slot${
                          index === staticTimeline.length - 1
                            ? " last_event"
                            : ""
                        }`}
                      >
                        {matchingItems.map((matchingItem, matchingIndex) => {
                          const minute =
                            parseInt(
                              matchingItem.start_time
                                .split(":")[1]
                                .split(" ")[0]
                            ) || 0;
                          return (
                            <React.Fragment key={matchingIndex}>
                              {matchingItem.current_stage === 2 && (
                                <RosterCapsuleComponent
                                  handleClick={handleClick}
                                  handleDragStart={handleDragStart}
                                  handleDecrementEndTime={
                                    handleDecrementEndTime
                                  }
                                  handleIncrementEndTime={
                                    handleIncrementEndTime
                                  }
                                  handleDecrementStartime={
                                    handleDecrementStartime
                                  }
                                  handleIncrementStartTime={
                                    handleIncrementStartTime
                                  }
                                  rosterItemIndex={rosterItemIndex}
                                  matchingIndex={matchingIndex}
                                  matchingItem={matchingItem}
                                  initialXRefLeft={initialXRefLeft}
                                  initialXRefRight={initialXRefRight}
                                  minute={minute}
                                />
                              )}
                              {clickedIndex === rosterItemIndex &&
                                matchingItem.type === matchingItemIndex &&
                                matchingItem.current_stage === 3 && (
                                  <ThirdStageBoxComponent
                                    rosterItemIndex={rosterItemIndex}
                                    minute={minute}
                                    setClickedIndex={setClickedIndex}
                                    setMatchingItemIndex={setMatchingItemIndex}
                                    forwards={forwards}
                                    rosterData={rosterData}
                                    setRosterData={setRosterData}
                                    matchingItem={matchingItem}
                                    matchingItemIndex={matchingItemIndex}
                                  />
                                )}
                              {!matchingItem.current_stage && (
                                <InitialStageComponent
                                  rosterItemIndex={rosterItemIndex}
                                  minute={minute}
                                  setClickedIndex={setClickedIndex}
                                  setMatchingItemIndex={setMatchingItemIndex}
                                  forwards={forwards}
                                  rosterData={rosterData}
                                  setRosterData={setRosterData}
                                  matchingItem={matchingItem}
                                  setForwards={setForwards}
                                />
                              )}
                            </React.Fragment>
                          );
                        })}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default RosterWrapper;
