import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { api } from "../../../services/api";

const initialState: any = {
  loading: false,
  error: null,
  notificationCount: {},
  notificationDetails: [],
  modalNotifications: [],
  searchValNotifications: "",
  sortByNotifications: "",
  selectedDropdownItemNotifications: "",
  selectedItemIndexFilterNotifications: "",
  selectedFilterItemsNotifications: "",
  selectedItemIndexSortNotifications: "",
};

export const getUserNotificationStats = createAsyncThunk(
  "notifications/notification_stats",
  async () => {
    try {
      const { data } = await api.get(`/getuser_notificationstats`);
      return data;
    } catch (error) {
      throw error;
    }
  }
);

export const getUserNotificationDetails = createAsyncThunk(
  "notifications/notification_details",
  async (status: any) => {
    try {
      const { data } = await api.get(
        `/getuser_notificationdetails?status=${status}`
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
);

export const getModalNotifications = createAsyncThunk(
  "notifications/modal_notifications",
  async ({ status, limit }: any) => {
    try {
      const { data } = await api.get(
        `/getuser_notificationdetails?status=${status}&limit=${limit}`
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
);

export const getUserNotificationDetailsByFilter = createAsyncThunk(
  "notifications/notification_details_by_filter",
  async ({
    filterVal = "",
    columnTypeFilter = "",
    columnTypeSort = "",
    searchVal = "",
    sortType = "",
    status = "",
  }: any) => {
    const encodedFilterVal = encodeURIComponent(filterVal);
    try {
      const { data } = await api.get(
        `/getuser_notificationdetails?paginate=true&page=1&limit=50&sort_column=${columnTypeSort}&sort_type=${sortType}&filter_column=${columnTypeFilter}&filter_value=${encodedFilterVal}&search=${searchVal}&status=${status}`
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
);

const notificationSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    resetNotificationCount: (state) => {
      state.notificationCount = {};
    },
    setInputSearchValNotifications: (state, action) => {
      state.searchValNotifications = action.payload;
    },
    resetInputSearchValNotifications: (state) => {
      state.searchValNotifications = "";
    },
    setSelectedDropdownItemNotifications: (state, action) => {
      state.selectedDropdownItemNotifications = action.payload;
    },
    resetSelectedDropdownItemNotifications: (state) => {
      state.selectedDropdownItemNotifications = null;
    },
    setSelectedItemIndexFilterNotifications: (state, action) => {
      state.selectedItemIndexFilterNotifications = action.payload;
    },
    resetSelectedItemIndexFilterNotifications: (state) => {
      state.selectedItemIndexFilterNotifications = null;
    },
    setSelectedItemIndexSortNotifications: (state, action) => {
      state.selectedItemIndexSortNotifications = action.payload;
    },
    resetSelectedItemIndexSortNotifications: (state) => {
      state.selectedItemIndexSortNotifications = null;
    },
    setSortByNotifications: (state, action) => {
      state.sortByNotifications = action.payload;
    },
    resetSortByNotifications: (state) => {
      state.sortByNotifications = null;
    },
    setSelectedFilterItemsNotifications: (state, action) => {
      state.selectedFilterItemsNotifications = action.payload;
    },
    resetSelectedFilterItemsNotifications: (state) => {
      state.selectedFilterItemsNotifications = null;
    },
    clearCurrentNotificationsData: (state) => {
      state.getUniqueNotifications = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserNotificationStats.pending, (state) => {
        state.loading === true;
      })
      .addCase(getUserNotificationStats.fulfilled, (state, action) => {
        state.loading = false;
        state.notificationCount = action.payload;
      })
      .addCase(getUserNotificationStats.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getUserNotificationDetails.pending, (state) => {
        state.loading === true;
      })
      .addCase(getUserNotificationDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.notificationDetails = action.payload.data;
      })
      .addCase(getUserNotificationDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getUserNotificationDetailsByFilter.pending, (state) => {
        state.loading === true;
      })
      .addCase(
        getUserNotificationDetailsByFilter.fulfilled,
        (state, action) => {
          state.loading = false;
          state.notificationDetails = action.payload.data;
        }
      )
      .addCase(getUserNotificationDetailsByFilter.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getModalNotifications.pending, (state) => {
        state.loading === true;
      })
      .addCase(getModalNotifications.fulfilled, (state, action) => {
        state.loading = false;
        state.modalNotifications = action.payload.data;
      })
      .addCase(getModalNotifications.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const {
  resetNotificationCount,
  setInputSearchValNotifications,
  resetInputSearchValNotifications,
  resetSelectedFilterItemsNotifications,
  setSelectedFilterItemsNotifications,
  setSortByNotifications,
  resetSortByNotifications,
  setSelectedItemIndexSortNotifications,
  resetSelectedItemIndexSortNotifications,
  setSelectedDropdownItemNotifications,
  resetSelectedDropdownItemNotifications,
  setSelectedItemIndexFilterNotifications,
  resetSelectedItemIndexFilterNotifications,
} = notificationSlice.actions;

export default notificationSlice.reducer;
