import React from "react";
import "./index.css";

import { useEffect, useState } from "react";
import {
  getProviderDetailThunk,
  setDeleteBtnColor,
  setDynamicColor,
  setFailColor,
  setFilterIconColor,
  setHasDaybook,
  setHasVillages,
  setIsAuditFlag,
  setIsMealsFlag,
  setSaveBtnColor,
  setSuccessColor,
  setWarningColor,
  setdateFormat,
  sethasHub,
  sethasRoster,
  sethasSil,
} from "../../../../Controller/redux/features/CommonFeature/commonSlice";

import {
  useAppDispatch,
  useAppSelector,
} from "../../../../Controller/redux/Hooks";
import { useLocation, useNavigate } from "react-router-dom";

import bell from "../../../../Model/Assets/png/bell.png";
import daybook from "../../../../Model/Assets/png/daybook.png";
import home from "../../../../Model/Assets/png/home.png";
import meal from "../../../../Model/Assets/png/meal.png";
import people from "../../../../Model/Assets/png/people.png";
import sales from "../../../../Model/Assets/png/sales.png";
import village from "../../../../Model/Assets/png/villages.png";
// import calendar from "../../../../Model/Assets/png/calendar.jpg";

import { trackUserClickThunk } from "../../../../Controller/redux/features/AuditFeature/auditSlice";
import { setWarningPopup } from "../../../../Controller/redux/features/CommonFeature/documentSlice";
import {
  setrouteString,
  setshowUnsavedModal,
} from "../../../../Controller/redux/features/AuthFeature/authSlice";
import { Modal, ModalOverlay } from "@chakra-ui/react";
import CustomForm from "../../CommonModal/Components/CustomForm";

const initialState = {
  people: false,
  comms: false,
  villages: false,
  dailybook: false,
  sales: false,
  hub: false,
  logout: false,
  meals: false,
  providers: false,
  audit: false,
  sil: false,
  rac: false,
  refData: false,
  roster: false,
  "reports-hub": false,
  vendors: false,
};

function SideNavbar(props: any) {
  useEffect(() => {
    dispatch(getProviderDetailThunk());
  }, []);
  const [isOpenCustomModal, setIsOpenCustomModal] = useState(false);
  const isMeals = useAppSelector((state) => state?.common?.isMeals);
  const isAudit = useAppSelector((state) => state?.common?.isAudit);
  const dynamicColor = useAppSelector((state) => state?.common?.dynamicColor);
  const isDaybook = useAppSelector((state) => state?.common?.hasDaybook);
  const isVillages = useAppSelector((state) => state?.common?.hasVillages);
  const hasHub = useAppSelector((state) => state?.common?.hasHub);
  const hasSil = useAppSelector((state) => state?.common?.hasSil);

  const {
    has_sales,
    has_rac,
    contacts_label,
    staff_label,
    locations_label,
    clients_label,
    business_label,
    prospects_label,
    teams_label,
    has_refdata,
    has_comms,
    has_roster,
    has_reports,
  } = useAppSelector((state) => state?.common?.domainDetail);

  useEffect(() => {
    document.documentElement.style.setProperty("--sidebar-color", dynamicColor);
  }, [dynamicColor]);

  const dispatch = useAppDispatch();

  const { user } = useAppSelector((state) => state?.common);
  // const IsUserAdmin = user?.roles[0]?.desc.toLowerCase();
  // const hasProvider = IsUserAdmin === "administrator";

  const { domainDetail } = useAppSelector((state) => state?.common);

  var businessType = domainDetail?.businessmodel_type;
  useEffect(() => {
    if (domainDetail?.has_meals == "1") {
      dispatch(setIsMealsFlag(true));
    }
    if (domainDetail.has_audit === "1") {
      dispatch(setIsAuditFlag(true));
    }
    dispatch(setDynamicColor(domainDetail?.sticky_background_color));
    dispatch(setFilterIconColor(domainDetail?.filtericon_colour));
    dispatch(setHasDaybook(domainDetail?.has_daybook));
    dispatch(setHasVillages(domainDetail?.has_village));
    dispatch(setdateFormat(domainDetail?.date_format));
    dispatch(sethasHub(domainDetail?.has_hub));
    dispatch(setSaveBtnColor(domainDetail?.savebtn_colour));
    dispatch(setDeleteBtnColor(domainDetail?.deletebtn_colour));
    dispatch(setWarningColor(domainDetail?.warning_colour));
    dispatch(setSuccessColor(domainDetail?.success_colour));
    dispatch(setFailColor(domainDetail?.fail_colour));
    dispatch(sethasSil(domainDetail?.has_sil));
    dispatch(sethasRoster(domainDetail?.has_roster));
  }, [domainDetail, isMeals]);

  // console.log()

  const [selectedRoute, setSelectedRoute] = useState("");

  const { unsavedChanges } = useAppSelector((state) => state.auth);

  const isDeleteDocumentActive = useAppSelector(
    (state) => state?.document?.hasDeleteDocument
  );

  const handleNavigation = (route: any) => {
    let modifiedRoute = route.slice(1);
    modifiedRoute =
      modifiedRoute.charAt(0).toUpperCase() + modifiedRoute.slice(1);
    setSelectedRoute(route);
    dispatch(
      trackUserClickThunk({
        desc: `Clicked on ${modifiedRoute}`,
        screen: `${modifiedRoute}`,
      })
    );

    if (isDeleteDocumentActive) {
      dispatch(setWarningPopup(true));
      return;
    }

    if (unsavedChanges) {
      dispatch(setshowUnsavedModal(true));
    } else {
      dispatch(setrouteString(route));

      navigate(route);
    }
  };

  const pathname = useLocation().pathname;

  useEffect(() => {
    dispatch(setrouteString(selectedRoute));
  }, [selectedRoute, setrouteString]);

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (unsavedChanges) {
        event.preventDefault();
        event.returnValue = "";
        dispatch(setshowUnsavedModal(true));
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [unsavedChanges, setshowUnsavedModal]);

  const navigate = useNavigate();
  const location = useLocation();

  var businessType = domainDetail?.businessmodel_type;
  // const isAdmin = useAppSelector(
  //   (state) => state?.common?.user?.roles[0]?.desc
  // );
  // let isAdminActive = isAdmin === "Administrator";
  const isAdminActive = user?.tenant?.id === user?.tenant?.parent_id;
  
  

  const [values, setValues] = useState(initialState);

  useEffect(() => {
    if (window.location.pathname === "/") {
      const newValues: any = { hub: true };
      setValues(newValues);
    } else if (window.location.pathname.includes("my-daybook")) {
      const newValues: any = { dailybook: true };
      setValues(newValues);
    } else if (window.location.pathname.includes("villages")) {
      const newValues: any = { villages: true };
      setValues(newValues);
    } else if (window.location.pathname.includes("sil")) {
      const newValues: any = { sil: true };
      setValues(newValues);
    } else if (window.location.pathname.includes("forms")) {
      const newValues: any = { refData: true };
      setValues(newValues);
    } else if (window.location.pathname.includes("vendors")) {
      const newValues: any = { vendors: true };
      setValues(newValues);
    }
  }, [window.location.pathname, navigate]);

  const handleMouseOutForHub = () => {
    if (location.pathname !== "/") {
      setValues({ ...values, hub: false });
    } else {
      setValues({ ...values, hub: true });
    }
    if (location.pathname.includes("/people")) {
      setValues({ ...values, people: true });
    } else {
      setValues({ ...values, people: false });
    }
    if (location.pathname.includes("/meals")) {
      setValues({ ...values, meals: true });
    } else {
      setValues({ ...values, meals: false });
    }

    if (location.pathname.includes("/forms")) {
      setValues({ ...values, refData: true });
    } else {
      setValues({ ...values, refData: false });
    }
  };

  useEffect(() => {
    if (location.pathname) {
      let routePath = location.pathname;
      if (routePath.split("/")[1] == "people") {
        setValues({ ...initialState, people: true });
      } else if (routePath.split("/")[1] == "comms") {
        setValues({ ...initialState, comms: true });
      } else if (routePath.split("/")[1] == "my-daybook") {
        setValues({ ...initialState, dailybook: true });
      } else if (routePath.split("/")[1] == "ezihealth") {
        setValues({ ...initialState, hub: true });
      } else if (routePath.split("/")[1] == "villages") {
        setValues({ ...initialState, villages: true });
      } else if (routePath.split("/")[1] == "sales") {
        setValues({ ...initialState, sales: true });
      } else if (routePath.split("/")[1] == "meals") {
        setValues({ ...initialState, meals: true });
      } else if (routePath.split("/")[1] == "ref-data") {
        setValues({ ...initialState, refData: true });
      }
    }
  }, [location.pathname, navigate]);

  // const [isShown, setIsShown] = useState(false);

  // const handleClick = (event: any) => {
  //   setIsShown((current) => !current);
  // };

  const [isCareParterUserRole, setIsCareParterUserRole] = useState(false);

  useEffect(() => {
    if (user && user?.roles?.length !== 0) {
      if (user?.roles[0].desc == "Care Partner") {
        setIsCareParterUserRole(true);
      } else {
        setIsCareParterUserRole(false);
      }
    }
  }, [user]);

  return (
    <>
      <div className="sidebar__wrapper">
        <div className="">
          <ul className="">
            <div className="top-four-elements">
              <li
                style={{
                  display: hasHub === "1" ? "none" : "none",
                }}
                className={`list_item ${values.hub ? "active" : "not_active"}`}
                onClick={() => handleNavigation("/")}
                onMouseOver={() => {
                  setValues((prevVals) => ({
                    ...prevVals,
                    hub: true,
                  }));
                }}
                onMouseOut={handleMouseOutForHub}
              >
                <div className="img-section">
                  <div
                    className="icon-box-section"
                    style={{
                      backgroundImage: `url(${home})`,
                      backgroundPosition: "center",
                      backgroundSize: "60%",
                      backgroundRepeat: "no-repeat",
                      backgroundOrigin: "content-box",
                    }}
                  >
                    <div
                      className="icon-box-section"
                      style={{
                        backgroundImage: `url(${home})`,
                        backgroundPosition: "center",
                        backgroundSize: "50%",
                        backgroundRepeat: "no-repeat",
                        backgroundOrigin: "content-box",
                      }}
                    >
                      <div className="tooltip tooltip-hub">
                        <ul>
                          <li> Dashboard</li>
                          <li> Newsroom</li>
                          <li> Solutions Forum</li>
                          <li> Notifications</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <h6 className="side_bar_hub">Hub</h6>
                </div>
              </li>
              <li
                style={{
                  display: isDaybook === "1" ? "block" : "none",
                }}
                // className={`${values.dailybook ? "active" : ""}`}
                className={`${
                  pathname.includes("my-daybook") ? "active" : "not_active"
                }`}
                onClick={() =>
                  setValues({ ...initialState, dailybook: !values.dailybook })
                }
              >
                <div className="img-section">
                  <div
                    onClick={() => handleNavigation("/my-daybook")}
                    className="icon-box-section"
                    style={{
                      backgroundImage: `url(${daybook})`,
                      backgroundColor: "active" ? "#fff" : "#000",
                      backgroundPosition: "center",
                      backgroundSize: "50%",
                      backgroundRepeat: "no-repeat",
                      backgroundOrigin: "content-box",
                    }}
                  ></div>
                  <h6 className="side_bar_hub">Daybook</h6>
                </div>
              </li>
              <li
                className={`${
                  pathname.includes("comms") ? "active" : "not_active"
                }`}
                style={{
                  display: has_comms === "1" ? "block" : "none",
                }}
                onClick={() =>
                  setValues({ ...initialState, comms: !values.comms })
                }
              >
                <div className="img-section section-3">
                  <div
                    onClick={() => handleNavigation("/comms")}
                    className="icon-box-section"
                    style={{
                      backgroundImage: `url(${bell})`,
                      backgroundPosition: "center",
                      backgroundSize: "50%",
                      backgroundRepeat: "no-repeat",
                      backgroundOrigin: "content-box",
                    }}
                  ></div>
                  <h6 className="side_bar_hub">Comms</h6>
                </div>
              </li>
              <li
                style={{
                  display:
                    isVillages === "1" && !isAdminActive ? "block" : "none",
                }}
                className={`${
                  pathname.includes("villages") ? "active" : "not_active"
                }`}
                onClick={() => {
                  handleNavigation("/villages");
                }}
              >
                <div className="img-section section-2">
                  <div
                    className="icon-box-section"
                    style={{
                      backgroundImage: `url(${village})`,
                      backgroundPosition: "center",
                      backgroundSize: "50%",
                      backgroundRepeat: "no-repeat",
                      backgroundOrigin: "content-box",
                    }}
                  ></div>
                  <h6 className="side_bar_hub">Villages</h6>
                </div>
              </li>

              <li
                style={{
                  display: has_rac === "1" && !isAdminActive ? "block" : "none",
                }}
                // className={`${values.rac ? "active" : ""}`}
                className={`${
                  pathname.includes("rac") ? "active" : "not_active"
                }`}
                onClick={() => setValues({ ...initialState, rac: !values.rac })}
              >
                <div className="img-section">
                  <div
                    onClick={() => {
                      handleNavigation("/rac");
                    }}
                    className="icon-box-section"
                    style={{
                      backgroundImage: `url(${village})`,
                      backgroundColor: "active" ? "#fff" : "#000",
                      backgroundPosition: "center",
                      backgroundSize: "50%",
                      backgroundRepeat: "no-repeat",
                      backgroundOrigin: "content-box",
                    }}
                  ></div>
                  <h6 className="side_bar_hub">RAC</h6>
                </div>
              </li>

              <li
                style={{
                  display: hasSil === "1" && !isAdminActive ? "block" : "none",
                }}
                className={`${
                  pathname.includes("sil") ? "active" : "not_active"
                }`}
                onClick={() => {
                  handleNavigation("/sil");
                }}
              >
                <div className="img-section section-2">
                  <div
                    className="icon-box-section"
                    style={{
                      backgroundImage: `url(${village})`,
                      backgroundPosition: "center",
                      backgroundSize: "50%",
                      backgroundRepeat: "no-repeat",
                      backgroundOrigin: "content-box",
                    }}
                  ></div>
                  <h6 className="side_bar_hub">SIL</h6>
                </div>
              </li>

              {
                <li
                  style={{
                    display: has_sales === "1" ? "block" : "none",
                  }}
                  className={`${
                    pathname.includes("sales") ? "active" : "not_active"
                  }`}
                  onClick={() =>
                    setValues({ ...initialState, sales: !values.sales })
                  }
                >
                  <div className="img-section section-3">
                    <div
                      onClick={() => handleNavigation("/sales")}
                      className="icon-box-section"
                      style={{
                        backgroundImage: `url(${sales})`,
                        backgroundColor: "active" ? "#fff" : "#000",
                        backgroundPosition: "center",
                        backgroundSize: "50%",
                        backgroundRepeat: "no-repeat",
                        backgroundOrigin: "content-box",
                      }}
                    ></div>
                    <h6 className="side_bar_hub">Sales</h6>
                  </div>
                </li>
              }
              <li
                style={{
                  display: has_roster === "1" ? "" : "none",
                }}
                className={`${
                  pathname.includes("roster") ? "active" : "not_active"
                }`}
                onClick={() =>
                  setValues({ ...initialState, roster: !values.roster })
                }
              >
                <div className="img-section section-3">
                  <div
                    onClick={() => handleNavigation("/roster")}
                    className="icon-box-section"
                    style={{
                      backgroundImage: `url(${daybook})`,
                      backgroundColor: "active" ? "#fff" : "#000",
                      backgroundPosition: "center",
                      backgroundSize: "50%",
                      backgroundRepeat: "no-repeat",
                      backgroundOrigin: "content-box",
                    }}
                  ></div>
                  <h6 className="side_bar_hub">Roster</h6>
                </div>
              </li>
              {has_reports != 0 && (
                <li
                  className={`${
                    pathname.includes("reports-hub") ? "active" : "not_active"
                  }`}
                  onClick={() =>
                    setValues({
                      ...initialState,
                      "reports-hub": !values["reports-hub"],
                    })
                  }
                >
                  <div className="img-section section-3">
                    <div
                      onClick={() => handleNavigation("/reports-hub")}
                      className="icon-box-section"
                      style={{
                        backgroundImage: `url(${daybook})`,
                        backgroundColor: "active" ? "#fff" : "#000",
                        backgroundPosition: "center",
                        backgroundSize: "50%",
                        backgroundRepeat: "no-repeat",
                        backgroundOrigin: "content-box",
                      }}
                    ></div>
                    <h6 className="side_bar_hub">
                      {domainDetail?.reports_label}
                    </h6>
                  </div>
                </li>
              )}
            </div>

            <div className="bottom-two-elements">
              <li
                className={`${values.people ? "active" : "not_active"}`}
                // onMouseOver={() =>
                //   setValues({
                //     comms: false,
                //     villages: false,
                //     dailybook: false,
                //     sales: false,
                //     hub: false,
                //     meals: false,
                //     logout: false,
                //     people: true,
                //     audit: false,
                //   })
                // }
                // onMouseOut={handleMouseOutForPeople}
              >
                <div className="img-section section-3">
                  <div
                    className="icon-box-section"
                    style={{
                      backgroundImage: `url(${people})`,
                      backgroundPosition: "center",
                      backgroundSize: "50%",
                      backgroundRepeat: "no-repeat",
                      backgroundOrigin: "content-box",
                    }}
                  >
                    <div className="tooltip">
                      <ul>
                        <li
                          onClick={() => {
                            dispatch(
                              trackUserClickThunk({
                                desc: "Clicked on Profiles>Prospects",
                                screen: "Profiles>Prospects",
                              })
                            );

                            navigate("/people/prospects");
                          }}
                        >
                          {prospects_label}
                        </li>

                        <li
                          onClick={() => {
                            dispatch(
                              trackUserClickThunk({
                                desc: `Clicked on Profiles>Clients`,
                                screen: `Profiles>Clients`,
                              })
                            );

                            navigate("/people/clients");
                          }}
                        >
                          {clients_label}
                        </li>
                        <li
                          onClick={() => {
                            dispatch(
                              trackUserClickThunk({
                                desc: `Clicked on Profiles>Business`,
                                screen: `Profiles>Business`,
                              })
                            );

                            navigate("/people/business");
                          }}
                        >
                          {business_label}
                        </li>
                        <li
                          onClick={() => {
                            dispatch(
                              trackUserClickThunk({
                                desc: `Clicked on Profiles>Locations`,
                                screen: `Profiles>Locations`,
                              })
                            );

                            navigate("/people/locations");
                          }}
                        >
                          {locations_label}
                        </li>
                        <li
                          onClick={() => {
                            dispatch(
                              trackUserClickThunk({
                                desc: `Clicked on Profiles>Teams`,
                                screen: `Profiles>Teams`,
                              })
                            );

                            navigate("/people/teams");
                          }}
                        >
                          {teams_label}
                        </li>
                        <li
                          onClick={() => {
                            dispatch(
                              trackUserClickThunk({
                                desc: `Clicked on Profiles>Contacts`,
                                screen: `Profiles>Contacts`,
                              })
                            );

                            navigate("/people/contacts");
                          }}
                          style={{}}
                        >
                          {contacts_label}
                        </li>
                        {
                          <li
                            onClick={() => {
                              dispatch(
                                trackUserClickThunk({
                                  desc: `Clicked on Profiles>Staff`,
                                  screen: `Profiles>Staff`,
                                })
                              );

                              navigate("/people/staff");
                            }}
                          >
                            {businessType === "B2B" ? "Employees" : staff_label}
                          </li>
                        }
                        <li
                          style={{
                            display: isAdminActive ? "block" : "none",
                          }}
                          onClick={() => {
                            handleNavigation("/vendors");
                          }}
                        >
                          Vendors
                        </li>
                        <li
                          style={{
                            display: isAdminActive ? "block" : "none",
                          }}
                          onClick={() => {
                            handleNavigation("/reports");
                          }}
                        >
                          Reports
                        </li>
                      </ul>
                    </div>
                  </div>
                  <h6 className="side_bar_hub">{domainDetail?.profile_name}</h6>
                </div>
              </li>

              {
                isMeals && (
                  <>
                    <li
                      className={`${values.meals ? "active" : "not_active"}`}
                      // onMouseOver={() =>
                      //   setValues({
                      //     comms: false,
                      //     villages: false,
                      //     dailybook: false,
                      //     sales: false,
                      //     meals: true,
                      //     hub: false,
                      //     logout: false,
                      //     people: false,
                      //     audit: false,
                      //   })
                      // }
                      // onMouseOut={() => handleMouseOutForMeals()}
                    >
                      <div className="img-section">
                        <div
                          className="icon-box-section"
                          style={{
                            backgroundImage: `url(${meal})`,
                            backgroundPosition: "center",
                            backgroundSize: "50%",
                            backgroundRepeat: "no-repeat",
                            backgroundOrigin: "content-box",
                          }}
                        >
                          <div className="tooltip tooltip-meals">
                            <ul>
                              <li
                                onClick={() => {
                                  dispatch(
                                    trackUserClickThunk({
                                      desc: "Clicked on Meals>Meal-builder",
                                      screen: "Meals>Meal-builder",
                                    })
                                  );
                                  navigate("/meals/meals-builder");
                                }}
                              >
                                Meal Builder
                              </li>
                              <li
                                onClick={() => {
                                  dispatch(
                                    trackUserClickThunk({
                                      desc: "Clicked on Meals>Menu-builder",
                                      screen: "Meals>Menu-builder",
                                    })
                                  );
                                  navigate("/meals/menu-builder");
                                }}
                              >
                                {" "}
                                Menu Builder
                              </li>
                              {/* <li
                              onClick={() => navigate("/meals/menu-assignment")}
                            >
                              {" "}
                              Menu Assignment
                            </li> */}
                              <li
                                onClick={() => {
                                  dispatch(
                                    trackUserClickThunk({
                                      desc: `Clicked on Meals>Reports`,
                                      screen: `Meals>Reports`,
                                    })
                                  );
                                  navigate("/meals/reports");
                                }}
                              >
                                {" "}
                                Reports
                              </li>
                            </ul>
                          </div>
                        </div>
                        <h6 className="side_bar_hub">Meals</h6>
                      </div>
                    </li>
                  </>
                ) //build
              }

              <li
                style={{
                  display: has_refdata === "1" ? "block" : "none",
                }}
                className={`${values.refData ? "active" : "not_active"}`}
                // onMouseOver={() =>
                //   setValues({
                //     comms: false,
                //     villages: false,
                //     dailybook: false,
                //     sales: false,
                //     meals: true,
                //     hub: false,
                //     logout: false,
                //     people: false,
                //     audit: false,
                //   })
                // }
                // onMouseOut={() => handleMouseOutForMeals()}
              >
                <div className="img-section">
                  <div
                    className="icon-box-section"
                    style={{
                      backgroundImage: `url(${sales})`,
                      backgroundPosition: "center",
                      backgroundSize: "50%",
                      backgroundRepeat: "no-repeat",
                      backgroundOrigin: "content-box",
                    }}
                  >
                    <div className="tooltip tooltip-ref-data">
                      <ul>
                        <li
                          onClick={() => {
                            dispatch(
                              trackUserClickThunk({
                                desc: "Clicked on Ref-Data>Expense-Type",
                                screen: "Ref-Data>Expense-Type",
                              })
                            );
                            navigate("/ref-data/expense-type");
                          }}
                        >
                          Expense Type
                        </li>
                        <li
                          onClick={() => {
                            dispatch(
                              trackUserClickThunk({
                                desc: "Clicked on Ref-Data>Funding-Type",
                                screen: "Ref-Data>Funding-Type",
                              })
                            );
                            navigate("/ref-data/service-type");
                          }}
                        >
                          {" "}
                          Service Type
                        </li>
                        {/* <li
                              onClick={() => navigate("/meals/menu-assignment")}
                            >
                              {" "}
                              Menu Assignment
                            </li> */}
                        <li
                          onClick={() => {
                            dispatch(
                              trackUserClickThunk({
                                desc: `Clicked on Ref-Data>Billing-Set`,
                                screen: `Ref-Data>Billing-Set`,
                              })
                            );
                            navigate("/ref-data/billing-set");
                          }}
                        >
                          {" "}
                          Billing Set
                        </li>{" "}
                        <li
                          onClick={() => {
                            dispatch(
                              trackUserClickThunk({
                                desc: `Clicked on Ref-Data>Pay-Rule`,
                                screen: `Ref-Data>Pay-Rule`,
                              })
                            );
                            navigate("/ref-data/pay-rule");
                          }}
                        >
                          Pay Rule
                        </li>
                        {isAdminActive && (
                          <li
                            onClick={() => {
                              // setIsOpenCustomModal(true);
                              navigate("/forms");
                            }}
                          >
                            Forms
                          </li>
                        )}
                        {isOpenCustomModal && (
                          <>
                            <Modal
                              isOpen={isOpenCustomModal}
                              onClose={() => {
                                setIsOpenCustomModal(false);
                              }}
                              closeOnOverlayClick={false}
                              isCentered
                            >
                              <ModalOverlay
                                zIndex={10}
                                onClick={() => {
                                  setIsOpenCustomModal(false);
                                }}
                              />
                              <div className="vertical_modal_sidebar">
                                <CustomForm isSideBar={true} />
                              </div>
                            </Modal>
                          </>
                        )}
                      </ul>
                    </div>
                  </div>
                  <h6 className="side_bar_hub">
                    {domainDetail?.refdata_label}
                  </h6>
                </div>
              </li>

              {false && (
                <li
                  className={`${
                    pathname.includes("providers") ? "active" : "not_active"
                  }`}
                  onClick={() =>
                    setValues({ ...initialState, providers: !values.providers })
                  }
                >
                  <div className="img-section">
                    <div
                      onClick={() => {
                        handleNavigation("/providers");
                      }}
                      className="icon-box-section"
                      style={{
                        backgroundImage: `url(${people})`,
                        backgroundColor: "active" ? "#fff" : "#000",
                        backgroundPosition: "center",
                        backgroundSize: "50%",
                        backgroundRepeat: "no-repeat",
                        backgroundOrigin: "content-box",
                      }}
                    ></div>
                    <h6 className="side_bar_hub">Providers</h6>
                  </div>
                </li>
              )}

              {isAudit && (
                <li
                  className={`${
                    pathname.includes("audit") ? "active" : "not_active"
                  }`}
                  onClick={() =>
                    setValues({ ...initialState, audit: !values.audit })
                  }
                >
                  <div className="img-section">
                    <div
                      onClick={() => {
                        handleNavigation("/audit");
                      }}
                      className="icon-box-section"
                      style={{
                        backgroundImage: `url(${daybook})`,
                        backgroundColor: "active" ? "#fff" : "#000",
                        backgroundPosition: "center",
                        backgroundSize: "50%",
                        backgroundRepeat: "no-repeat",
                        backgroundOrigin: "content-box",
                      }}
                    ></div>
                    <h6 className="side_bar_hub">Audit</h6>
                  </div>
                </li>
              )}
            </div>
          </ul>
        </div>
      </div>
    </>
  );
}
export default SideNavbar;
