import React, { useRef } from "react";
import HeaderButtons from "./HeaderButtons";
import Status from "./Status";
import StatusClient from "./StatusClient";
import StatusClinical from "./StatusClinical";
import WidgetItems from "../../../pages/People/ClientPage/Components/WidgetItems";
import { useAppSelector } from "../../../../Controller/redux/Hooks";
import { btnArrProvidersSecondTabSection } from "../Data/buttonArr";

interface MainTypeProps {
  screenType:
    | "clinical"
    | "prospects"
    | "teams"
    | "business"
    | "contacts"
    | "staffScreen"
    | "locations"
    | "providers"
    | "clients"
    | "reports";
  type: string;
  handleClick(arg: any): any;
  buttonsArray: any;
  associationTabArray: any;
  currentData: any;
  OpenStatusWidget: any;
  setOpenStatusWidget: (arg: any) => any;
  widgetRef: any;
  position: any;
  setAllClientsDataToTrackClickOfStatusClick: (arg: any) => any;
  allClientsDataToTrackClickOfStatusClick: any;
  setOpenWidgetBusiness: (arg: any) => any;
  setOpenWidgetTeams: (arg: any) => any;
  setOpenWidgetContacts: (arg: any) => any;
  setOpenWidgetProspects: (arg: any) => any;
  setShowStatusWidget: (arg: any) => any;
  statusData: any;
  handleOpenWidget: (arg: any) => any;
  itemsArr: any;
}

const MainType: React.FC<MainTypeProps | any> = ({
  type,
  screenType,
  handleClick,
  buttonsArray,
  associationTabArray,
  currentData,
  setOpenStatusWidget,
  OpenStatusWidget,
  widgetRef,
  allClientsDataToTrackClickOfStatusClick,
  setAllClientsDataToTrackClickOfStatusClick,
  statusData,
  position,
  handleOpenWidget,
  setShowStatusWidget,
  itemsArr,
  isProviderBar,
}) => {
  console.log("screenType", screenType);

  const StatusDetailsArr = useAppSelector(
    (state) => state.common.statusDetailsData?.data
  );

  // console.log('screenType', screenType);

  // console.log(buttonsArray,"buttonsArray")

  const prospectsStatusDetailsArr = useAppSelector(
    (state) => state.common.statusDetailsData?.data
  );

  return (
    <div className="unique-client-details-wrapper">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
        className={`buttonDiv ${screenType === "clinical" ? "clinical" : ""}`}
      >
        {(screenType === "providers" || isProviderBar) && (
          <HeaderButtons
            type={type}
            mainType={screenType}
            handleClick={handleClick}
            buttonsArray={btnArrProvidersSecondTabSection}
            screenType={screenType}
          />
        )}
        <HeaderButtons
          type={type}
          mainType={screenType}
          handleClick={handleClick}
          buttonsArray={buttonsArray}
          screenType={screenType}
        />
      </div>

      <div className="main-box main-box-associations-tab">
        <div
          className={`${
            screenType === "clients" && "main_box_header_client"
          } main-box-header main-box-header-associations-tab ${
            screenType === "contacts" ||
            screenType === "prospects" ||
            screenType === "staffScreen" ||
            screenType === "business" ||
            screenType === "locations" ||
            screenType === "teams" ||
            screenType === "reports" ||
            screenType === "providers" ||
            "billingRule"
              ? "relative"
              : ""
          }`}
        >
          {associationTabArray.map((elem: any, index: number) => {
            return (
              <div key={index} className="item">
                <span
                  className={
                    screenType === "teams"
                      ? "association-tab-upper-item association_teams"
                      : "association-tab-upper-item"
                  }
                >
                  {elem.UpperTitle}
                </span>
                <span className="association-tab-lower-item">
                  {elem.UpperTitle?.includes("Email")
                    ? elem.LowerTitle
                    : elem.LowerTitle?.length > 18
                    ? elem.LowerTitle?.slice(0, 17) + "..."
                    : elem.LowerTitle}
                </span>
              </div>
            );
          })}
          {screenType === "clients" ||
          screenType === "prospects" ||
          screenType === "staffScreen" ||
          screenType === "business" ||
          screenType === "locations" ||
          screenType === "contacts" ||
          screenType === "teams" ||
          screenType === "billingRule" ||
          screenType === "reports" ? (
            <Status
              currentData={currentData}
              setOpenStatusWidget={setOpenStatusWidget}
              OpenStatusWidget={OpenStatusWidget}
              widgetRef={widgetRef}
              position={position}
              setAllClientsDataToTrackClickOfStatusClick={
                setAllClientsDataToTrackClickOfStatusClick
              }
              allClientsDataToTrackClickOfStatusClick={
                allClientsDataToTrackClickOfStatusClick
              }
              screenType={screenType}
              itemsArr={prospectsStatusDetailsArr}
            />
          ) : screenType === "clinical" ? (
            <StatusClinical
              handleOpenWidget={handleOpenWidget}
              setOpenStatusWidget={setOpenStatusWidget}
              setShowStatusWidget={setShowStatusWidget}
              setAllClientsDataToTrackClickOfStatusClick={
                setAllClientsDataToTrackClickOfStatusClick
              }
              allClientsDataToTrackClickOfStatusClick={
                allClientsDataToTrackClickOfStatusClick
              }
              position={position}
              statusData={statusData}
              OpenStatusWidget={OpenStatusWidget}
              widgetRef={widgetRef}
              itemsArr={StatusDetailsArr}
            />
          ) : screenType === "clients" ? (
            <StatusClient
              handleOpenWidget={handleOpenWidget}
              setOpenStatusWidget={setOpenStatusWidget}
              setShowStatusWidget={setShowStatusWidget}
              setAllClientsDataToTrackClickOfStatusClick={
                setAllClientsDataToTrackClickOfStatusClick
              }
              allClientsDataToTrackClickOfStatusClick={
                allClientsDataToTrackClickOfStatusClick
              }
              position={position}
              statusData={statusData}
              OpenStatusWidget={OpenStatusWidget}
              widgetRef={widgetRef}
              itemsArr={StatusDetailsArr}
            />
          ) : screenType === "payRule" ? null : (
            // <span
            //   className="status-btn status-btn-active status-btn-common-tabs-except-clinical-and-details flex justify-center align-items-center cursor-pointer absolute right-[10px] top-[50%] -translate-y-[50%]"
            <span
              className="status-btn status-btn-active status-btn-common-tabs-except-clinical-and-details"
              style={{
                color: statusData?.color,
                backgroundColor: statusData?.bgCol,
                minWidth: statusData?.type?.includes("On Leave")
                  ? "90px"
                  : "auto",
                border: `1px solid ${statusData?.color}`,
                // zIndex: 10,
                fontWeight: 700,
              }}
              onClick={(e) => handleOpenWidget(e)}
            >
              {statusData?.type}
              {OpenStatusWidget && (
                <div ref={widgetRef} className="status-widget-div">
                  <WidgetItems
                    type={"clientScreen"}
                    setAllClientsDataToTrackClickOfStatusClick={
                      setAllClientsDataToTrackClickOfStatusClick
                    }
                    position={position}
                    widgetRef={widgetRef}
                    allClientsDataToTrackClickOfStatusClick={
                      allClientsDataToTrackClickOfStatusClick
                    }
                    setOpenStatusWidget={setOpenStatusWidget}
                    setShowStatusWidget={setShowStatusWidget}
                    itemsArr={StatusDetailsArr}
                  />
                </div>
              )}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default MainType;
