import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../Controller/redux/Hooks";
import {
  getPayRuleDetailsById,
  updatePayRuleSlice,
} from "../../../../../../Controller/redux/features/RefDataFeature/PayRuleFeature/payruleSlice";
import { Spinner } from "@chakra-ui/react";
import CommonUpperBox from "../../../../../components/UpperBox/CommonHeader";
import { getInitials } from "../../../../../../Model/utils/Helper/getInitials";
import CommonHeader from "../../../../../components/CommonHeader/CommonHeader";
import TimePicker from "../../../../../components/Time/Time";
import MultiSelectDropdown from "../../../BillingRule/Components/MultiSelectDropdown";
import CircularNoteTrigger from "../../../../../components/CircularNoteTrigger/CircularNoteTrigger";
import { updateExpenseType } from "../../../../../../Controller/redux/features/RefDataFeature/ExpenseTypeFeature/expensetypeSlice";
import CommonQuickMenu from "../../../../../components/CommonQuickMenu/CommonQuickMenu";

const PayRuleScreen = () => {
  const id: any = useParams()?.id;

  const [clientId, setclientId] = useState<any>("");
  const [payRules, setPayRules] = useState<any>({});
  const [associatedDays, setAssociatedDays] = useState("");
  useEffect(() => {
    if (id) {
      let realId = window?.atob(id);
      setclientId(realId);
    }
  }, [id]);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (clientId) {
      dispatch(getPayRuleDetailsById(clientId));
    }
  }, [clientId]);
  const initialState = {
    name: "",
    associated_award: "",
    rate: "",
    associated_pay_code: "",
    associated_days_of_the_week: "",
    start_time: "",
    end_time: "",
    valid_dates: "",
    tax_rate: "",
    linked_rules: "",
    associated_user_type: "",
    pay_rule_id: "",
    status: "",
    tenant_id: "",
  };

  const { uniquePayRule, loading } = useAppSelector((state) => state.payRule);

  const [currentPayRuleDetails, setCurrentPayRuleDetails] = useState<any>({});

  const [details, setDetails] = useState<any>({});
  const [updatePayRuleDetails, setupdatePayRuleDetails] = useState({
    pay_rule_id: 3,
    name: "pay rulae three",
    associated_award: "",
    rate: "220",
    associated_pay_code: "",
    associated_days_of_the_week: "",
    start_time: "",
    end_time: "",
    valid_dates: "",
    tax_rate: "",
    linked_rules: "",
    associated_user_type: "",
  });

  useEffect(() => {
    setIsThereAnyChanges(false);
    if (uniquePayRule?.id) {
      setTimeout(() => {
        setCurrentPayRuleDetails(uniquePayRule);
      }, 1000);
      //   setDetails(uniquePayRule);
    }
  }, [uniquePayRule]);

  useEffect(() => {
    setIsThereAnyChanges(false);
    setDetails(currentPayRuleDetails);
  }, [currentPayRuleDetails]);

  const dynamicColor = useAppSelector((state) => state?.common?.dynamicColor);
  const [isThereAnyChanges, setIsThereAnyChanges] = useState(false);

  const onChangeValue = (e: any, val?: string) => {
    const { value, name } = e?.target;
    setDetails({ ...details, [name]: value });
    setIsThereAnyChanges(true);
  };
  const [stageTwoToGoalsModal, setstageTwoToGoalsModal] = useState<any>(false);

  // console.log(details, "details");
  return (
    <div>
      {loading && <Spinner position={"absolute"} top={"50%"} left={"50%"} />}
      <CommonUpperBox
        imageSection={currentPayRuleDetails ? true : false}
        title={`${currentPayRuleDetails?.name || ""}`}
        onClickUserImage={() => {}}
        onClickPlus={() => {
          setstageTwoToGoalsModal(true);
        }}
        type={"listing"}
        currentUser={currentPayRuleDetails}
        backgroundWithText={true}
        text={getInitials(currentPayRuleDetails?.name || "")}
      />
      {currentPayRuleDetails?.id && (
        <CommonHeader
          mainType="payRule"
          type="details"
          handleClick={() => {}}
          currentPay={currentPayRuleDetails}
          refData={true}
        />
      )}
      <div>
        <div className="client-screen-personal-details-heading">
          <span className="client-screen-sub-heading-title">
            Pay Rule Details
          </span>
          <span className="client-screen-line"></span>
        </div>
        <div className="main-box-body relative">
          <div
            className={`content-wrapper `}
            // key={index}
          >
            <div className="wrapper-title">Name: </div>
            <input
              className="wrapper-info"
              onChange={onChangeValue}
              name="name"
              value={details?.name}
              placeholder={"Please Enter Name "}
              style={{
                border: "none",
                outline: "none",
                height: "fit-content",
              }}
            />
            <div className="wrapper-line"></div>
          </div>
          <div
            className={`content-wrapper `}
            // key={index}
          >
            <div className="wrapper-title">Associated Award: </div>
            <input
              className="wrapper-info"
              onChange={onChangeValue}
              value={details?.associated_award}
              name="associated_award"
              placeholder={"Please Enter Associated Award "}
              style={{
                border: "none",
                outline: "none",
                height: "fit-content",
              }}
            />
            <div className="wrapper-line"></div>
          </div>
          <div
            className={`content-wrapper `}
            // key={index}
          >
            <div className="wrapper-title">Rate: </div>
            {/* <input
              className="wrapper-info"
              onChange={onChangeValue}
              value={details?.rate}
              name="rate"
              placeholder={"Please Enter Rate "}
              style={{
                border: "none",
                outline: "none",
                height: "fit-content",
              }}
            /> */}
            <div className="service_type_rate_flex">
              <input
                className="wrapper-info"
                value={details?.rate}
                onChange={onChangeValue}
                name="rate"
                placeholder={"Please Enter Rate "}
                style={{
                  border: "none",
                  outline: "none",
                  height: "fit-content",
                }}
              />

              <select
                className="wrapper-info wrapper-info-select-dropdown"
                value={details?.rate}
                onChange={onChangeValue}
                style={{
                  border: "none",
                  outline: "none",
                  height: "fit-content",
                  backgroundColor: "white",
                  // width: "256px",
                }}
              >
                <option value="">Please Select Rate Type...</option>
                {["Hourly", "Fixed"]?.map((elem: any, index: any) => (
                  <option key={index} value={elem}>
                    {elem}
                  </option>
                ))}
              </select>
            </div>
            <div className="wrapper-line"></div>
          </div>{" "}
          <div
            className={`content-wrapper `}
            // key={index}
          >
            <div className="wrapper-title">Associated Pay Code: </div>
            <input
              className="wrapper-info"
              onChange={onChangeValue}
              value={details?.associated_pay_code}
              name="associated_pay_code"
              placeholder={"Please Enter Associated Billing Code "}
              style={{
                border: "none",
                outline: "none",
                height: "fit-content",
              }}
            />
            <div className="wrapper-line"></div>
          </div>{" "}
          <div
            className={`content-wrapper `}
            // key={index}
          >
            <div className="wrapper-title">Associated Days of the Week: </div>
            {/* <input
              className="wrapper-info"
              onChange={onChangeValue}
              value={details?.associated_days_of_the_week}
              name="associated_days_of_the_week"
              placeholder={"Please Enter Associated Days of the Week"}
              style={{
                border: "none",
                outline: "none",
                height: "fit-content",
              }}
            /> */}
            {/* Destructure this associatedDays in API when sending to backend */}
            <MultiSelectDropdown
              options={(option: any) => setAssociatedDays(option?.join(","))}
              value={
                details?.associated_days_of_the_week
                  ? details?.associated_days_of_the_week
                  : ""
              }
            />
            <div className="wrapper-line"></div>
          </div>{" "}
          <div
            className={`content-wrapper `}
            // key={index}
          >
            <div className="wrapper-title">Start Time: </div>
            <div id="billing_start_time">
              <TimePicker
                setTime={(e: any) => {
                  setDetails((prevState: any) => ({
                    ...prevState,
                    start_time: e,
                  }));
                  setIsThereAnyChanges(true);
                }}
                time={details.start_time}
              />
            </div>
            <div className="wrapper-line"></div>
          </div>{" "}
          <div
            className={`content-wrapper `}
            // key={index}
          >
            <div className="wrapper-title">End Time: </div>
            <div id="billing_start_time">
              <TimePicker
                setTime={(e: any) => {
                  setDetails((prevState: any) => ({
                    ...prevState,
                    end_time: e,
                  }));
                  setIsThereAnyChanges(true);
                }}
                time={details.end_time}
              />
            </div>
            <div className="wrapper-line"></div>
          </div>
          <div
            className={`content-wrapper `}
            // key={index}
          >
            <div className="wrapper-title">Valid Dates: </div>
            <input
              className="wrapper-info"
              onChange={onChangeValue}
              value={details?.valid_dates}
              name="valid_dates"
              placeholder={"Please Enter Valid Dates"}
              style={{
                border: "none",
                outline: "none",
                height: "fit-content",
              }}
            />
            <div className="wrapper-line"></div>
          </div>{" "}
          <div
            className={`content-wrapper `}
            // key={index}
          >
            <div className="wrapper-title">Tax Rate: </div>
            <input
              className="wrapper-info"
              onChange={onChangeValue}
              value={details?.tax_rate}
              name="tax_rate"
              placeholder={"Please Enter Tax Rate "}
              style={{
                border: "none",
                outline: "none",
                height: "fit-content",
              }}
            />
            <div className="wrapper-line"></div>
          </div>{" "}
          <div
            className={`content-wrapper `}
            // key={index}
          >
            <div className="wrapper-title">Linked Rules: </div>
            <input
              className="wrapper-info"
              onChange={onChangeValue}
              value={details?.linked_rules}
              name="linked_rules"
              placeholder={"Please Enter Linked Rules "}
              style={{
                border: "none",
                outline: "none",
                height: "fit-content",
              }}
            />
            <div className="wrapper-line"></div>
          </div>{" "}
          <div
            className={`content-wrapper `}
            // key={index}
          >
            <div className="wrapper-title">Associated User Type: </div>
            <input
              className="wrapper-info"
              onChange={onChangeValue}
              value={details?.associated_user_type}
              name="associated_user_type"
              placeholder={"Please Enter Associated User Type "}
              style={{
                border: "none",
                outline: "none",
                height: "fit-content",
              }}
            />
            <div className="wrapper-line"></div>
          </div>{" "}
        </div>
      </div>
      {isThereAnyChanges && (
        <>
          <div
            className="save"
            style={{
              position: "absolute",
              marginRight: "200px",
              bottom: "10px",
              zIndex: 9,
            }}
          >
            <p>Would you like to save your changes?</p>
            <div className="btnDivision">
              <button
                onClick={() => {
                  dispatch(
                    updatePayRuleSlice({
                      ...details,
                      pay_rule_id: details?.id,
                    })
                  );
                  setIsThereAnyChanges(false);
                }}
                style={{
                  backgroundColor: dynamicColor,
                }}
              >
                Save
              </button>
              <button onClick={() => setIsThereAnyChanges(false)}>
                Cancel
              </button>
            </div>
          </div>
        </>
      )}
      <CircularNoteTrigger
        type="stickyHub"
        title="Comms"
        isFinanceScreen={true}
      />
      {stageTwoToGoalsModal && (
        <CommonQuickMenu
          setshowGoalsModal={(value: any) => {}}
          mainType={"listing"}
          setstageTwoToGoalsModal={setstageTwoToGoalsModal}
        />
      )}
    </div>
  );
};

export default PayRuleScreen;
