import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../Controller/redux/Hooks";
import { Spinner } from "@chakra-ui/react";
import CommonUpperBox from "../../../components/UpperBox/CommonHeader";
import { getInitials } from "../../../../Model/utils/Helper/getInitials";
import CommonHeader from "../../../components/CommonHeader/CommonHeader";
import {
  clearUniqueServiceTypeData,
  getServiceTypeDetailsById,
  updateServiceType,
} from "../../../../Controller/redux/features/RefDataFeature/ServiceTypeFeature/serviceTypeSlice";
import { getBillingRuleByFilterThunk } from "../../../../Controller/redux/features/RefDataFeature/BillingRuleFeature/billingruleSlice";
import { toast } from "react-toastify";
import CircularNoteTrigger from "../../../components/CircularNoteTrigger/CircularNoteTrigger";
import CommonQuickMenu from "../../../components/CommonQuickMenu/CommonQuickMenu";
const ServiceTypeScreen = () => {
  const id: any = useParams()?.id;
  const tableRef = useRef(null);
  const [clientId, setclientId] = useState<any>("");
  const tableHeadArrServiceType = [
    "Service Time",
    "Service Rate",
    "Service Code",
    "KM Rate",
    "Travel to KM Code",
    "Travel To Min Code",
    "Travel with Code",
  ];

  // const serviceArray = [];
  useEffect(() => {
    if (id) {
      let realId = window?.atob(id);
      setclientId(realId);
    }
  }, [id]);
  const { user } = useAppSelector((state) => state?.common);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(clearUniqueServiceTypeData());
    dispatch(getServiceTypeDetailsById(clientId));
  }, [clientId]);

  const { uniqueServiceType, loading } = useAppSelector(
    (state) => state.serviceType
  );
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  useEffect(() => {
    if (uniqueServiceType) {
      setIsAdmin(user?.tenant_id === uniqueServiceType[0]?.tenant_id);
    }
  }, [uniqueServiceType, user]);

  const [uniqueBillingRuleInfo, setUniqueBillingRuleInfo] = useState<any>({});
  // console.log({ uniqueBillingRuleInfo });

  const [details, setDetails] = useState<any>({
    name: "",
    tax_rate: "",
    billing_rule_set: "",
    travel_to_km_charged: "",
    travel_with_km_changes: "",
    travel_to_min_charged: "",
  });
  useEffect(() => {
    dispatch(clearUniqueServiceTypeData());

    dispatch(
      getBillingRuleByFilterThunk({
        filterColumn: "",
        filterValue: "",
        searchVal: "",
        sortColumn: "",
        sortType: "",
        //   status: "",
        billingRulePage: "1",
      })
    );
  }, []);
  useEffect(() => {
    dispatch(clearUniqueServiceTypeData());
    if (uniqueServiceType !== null && uniqueServiceType.length === 1) {
      dispatch(clearUniqueServiceTypeData());
      setUniqueBillingRuleInfo(uniqueServiceType[0]);
      setDetails(uniqueServiceType[0]);
    }
  }, [uniqueServiceType]);

  // const [buttonTab, setbuttonTab] = useState({
  //   Details: true,
  // });

  const handleClick = () => {};

  const onChangeValue = (e: any) => {
    const { value, name } = e?.target;
    // // console.log(value, name);
    setDetails({ ...details, [name]: value });
    setIsThereAnyChanges(true);
  };
  const { BillingRuleData } = useAppSelector((state) => state.billingRule);
  const uniqueBillingRule = BillingRuleData?.filter(
    (item: any, index: number, array: any) => {
      return array?.findIndex((obj: any) => obj.id === item.id) === index;
    }
  );
  const dynamicColor = useAppSelector((state) => state?.common?.dynamicColor);
  const [isThereAnyChanges, setIsThereAnyChanges] = useState(false);

  const onChangeServicesType = (e: any, index: number, elem: any) => {
    const { value, name } = e?.target;
    let copyElem = { ...elem };
    copyElem[name] = value;
    let duplicateServicesTypeRule = [...details?.service_type_rules];
    duplicateServicesTypeRule[index] = copyElem;

    setDetails({
      ...details,
      service_type_rules: duplicateServicesTypeRule,
    });
    !isThereAnyChanges && setIsThereAnyChanges(true);
  };

  const handleSaveChanges = async () => {
    try {
      const response = await dispatch(
        updateServiceType({
          ...details,
          service_type_id: details?.id,
        })
      );
      if (response?.payload?.status == "success") {
        toast.success(`Services updated successfully!`, {
          style: {},
          progressStyle: { backgroundColor: dynamicColor },
          icon: (
            <span
              style={{
                marginRight: "8px",
                borderRadius: "50%",
                width: "7rem",
                height: "35px",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: dynamicColor,
                backgroundColor: "white",
                fontSize: "1.5rem",
                border: `3px solid ${dynamicColor}`,
              }}
            >
              ✓
            </span>
          ),
        });
        dispatch(getServiceTypeDetailsById(clientId));
      }
    } catch (error: any) {
      toast.error(error?.message);
    }
  };
  const [stageTwoToGoalsModal, setstageTwoToGoalsModal] = useState<any>(false);

  return (
    <div>
      {loading && <Spinner position={"absolute"} top={"50%"} left={"50%"} />}
      <CommonUpperBox
        imageSection={uniqueBillingRuleInfo ? true : false}
        title={`${uniqueBillingRuleInfo?.name || ""}`}
        onClickUserImage={() => {}}
        onClickPlus={() => {
          // buttonTab.Finance
          //   ? dispatch(setOpenFinanceModelForm(true))
          // :
          setstageTwoToGoalsModal(true);
        }}
        type={"listing"}
        currentUser={uniqueBillingRuleInfo}
        backgroundWithText={true}
        text={getInitials(uniqueBillingRuleInfo?.name || "")}
        // child={buttonTab.Clinical && renderClinicalWidgets()}
      />
      <CommonHeader
        mainType="billingRule"
        type="details"
        handleClick={handleClick}
        currentService={uniqueBillingRuleInfo}
        refData={true}
      />
      <div>
        <div className="client-screen-personal-details-heading">
          <span className="client-screen-sub-heading-title">
            Service Type Details
          </span>
          <span className="client-screen-line"></span>
        </div>

        <div className="main-box-body relative">
          <div
            className={`content-wrapper `}
            // key={index}
          >
            <div className="wrapper-title">Name: </div>
            <input
              className="wrapper-info"
              onChange={onChangeValue}
              name="name"
              readOnly={!isAdmin}
              value={details?.name}
              placeholder={"Please Enter Name "}
              style={{
                border: "none",
                outline: "none",
                height: "fit-content",
              }}
            />
            <div className="wrapper-line"></div>
          </div>
          <div
            className={`content-wrapper `}
            // key={index}
          >
            <div className="wrapper-title">Tax Rate: </div>
            <div className="service_type_rate_flex">
              {isAdmin && (
                <select
                  className="wrapper-info wrapper-info-select-dropdown"
                  style={{
                    border: "none",
                    outline: "none",
                    height: "fit-content",
                    backgroundColor: "white",
                    // width: "256px",
                  }}
                  value={details?.tax_rate || ""}
                  onChange={(e) => {
                    const { value } = e.target;
                    // No need to split and join the string, simply update the value directly
                    setDetails({
                      ...details,
                      tax_rate: value,
                    });
                    setIsThereAnyChanges(true);
                  }}
                >
                  <option value="">Please Select Rate Type...</option>
                  {["GST Free", "GST Inclusive", "GST Exclusive"].map(
                    (elem, index) => (
                      <option key={index} value={elem}>
                        {elem}
                      </option>
                    )
                  )}
                </select>
              )}
              {!isAdmin && (
                <input
                  className="wrapper-info"
                  readOnly={true}
                  value={details?.tax_rate?.split(" ")[1]}
                  style={{
                    border: "none",
                    outline: "none",
                    height: "fit-content",
                  }}
                />
              )}
            </div>
            <div className="wrapper-line"></div>
          </div>{" "}
          <div
            className={`content-wrapper `}
            // key={index}
          >
            <div className="wrapper-title">Linked Rules Set: </div>

            {/* <select
              className="wrapper-info wrapper-info-select-dropdown"
              // onChange={(e) =>
              //   setBillingRules((prevState: any) => ({
              //     ...prevState,
              //     day_restrictions: e.target.value,
              //   }))
              // }
              // value={billingRules.day_restrictions}
              value={details?.billing_rule_set}
              onChange={onChangeValue}
              style={{
                border: "none",
                outline: "none",
                height: "fit-content",
                backgroundColor: "white",
                // width: "256px",
              }}
            >
              <option value="">Please Select...</option>
              {uniqueBillingRule?.map((type: any, index: any) => (
                <option key={index} value={type.id}>
                  {type.set_name}
                </option>
              ))}
            </select> */}

            <input
              type="text"
              name=""
              id=""
              value={details?.billing_rule_set?.set_name}
              readOnly
            />
            <div className="wrapper-line"></div>
          </div>
          <div
            className={`content-wrapper `}
            // key={index}
          >
            <div className="wrapper-title">Travel To KMs Charged: </div>

            {isAdmin && (
              <select
                className="wrapper-info wrapper-info-select-dropdown"
                value={details?.travel_to_km_charged}
                name="travel_to_km_charged"
                onChange={onChangeValue}
                style={{
                  border: "none",
                  outline: "none",
                  height: "fit-content",
                  backgroundColor: "white",
                  // width: "256px",
                }}
              >
                <option value="">Please Select...</option>
                {[
                  { name: "Yes", value: "1" },
                  { name: "No", value: "0" },
                ]?.map((elem: any, index: any) => (
                  <option key={index} value={elem?.value}>
                    {elem?.name}
                  </option>
                ))}
              </select>
            )}
            {!isAdmin && (
              <input
                className="wrapper-info"
                readOnly={true}
                value={details?.travel_to_km_charged === "0" ? "No" : "Yes"}
                style={{
                  border: "none",
                  outline: "none",
                  height: "fit-content",
                }}
              />
            )}
            <div className="wrapper-line"></div>
          </div>
          <div
            className={`content-wrapper `}
            // key={index}
          >
            <div className="wrapper-title">Travel with KMs Changes: </div>
            {/* <input
              className="wrapper-info"
              onChange={onChangeValue}
              value={details?.travel_with_km_changes === "1" ? "Yes" : "No"}
              name="travel_with_km_changes"
              placeholder={"Please Enter Travel with KMs Changes"}
              style={{
                border: "none",
                outline: "none",
                height: "fit-content",
              }}
            /> */}
            {isAdmin && (
              <select
                className="wrapper-info wrapper-info-select-dropdown"
                value={details?.travel_with_km_changes}
                name="travel_with_km_changes"
                onChange={onChangeValue}
                style={{
                  border: "none",
                  outline: "none",
                  height: "fit-content",
                  backgroundColor: "white",
                  // width: "256px",
                }}
              >
                <option value="">Please Select...</option>
                {[
                  { name: "Yes", value: "1" },
                  { name: "No", value: "0" },
                ]?.map((elem: any, index: any) => (
                  <option key={index} value={elem?.value}>
                    {elem?.name}
                  </option>
                ))}
              </select>
            )}
            {!isAdmin && (
              <input
                className="wrapper-info"
                readOnly={true}
                value={details?.travel_with_km_changes === "0" ? "No" : "Yes"}
                style={{
                  border: "none",
                  outline: "none",
                  height: "fit-content",
                }}
              />
            )}
            <div className="wrapper-line"></div>
          </div>
          <div
            className={`content-wrapper `}
            // key={index}
          >
            <div className="wrapper-title">Travel To Minutes Charged: </div>
            {/* <input
              className="wrapper-info"
              onChange={onChangeValue}
              value={details?.travel_to_min_charged === "1" ? "Yes" : "No"}
              name="travel_to_min_charged"
              placeholder={"Please Enter Travel To Minutes Charged"}
              style={{
                border: "none",
                outline: "none",
                height: "fit-content",
              }}
            /> */}
            {isAdmin && (
              <select
                className="wrapper-info wrapper-info-select-dropdown"
                value={details?.travel_to_min_charged}
                name="travel_to_min_charged"
                onChange={onChangeValue}
                style={{
                  border: "none",
                  outline: "none",
                  height: "fit-content",
                  backgroundColor: "white",
                  // width: "256px",
                }}
              >
                <option value="">Please Select...</option>
                {["Yes", "No"]?.map((elem: any, index: any) => (
                  <option key={index} value={elem == "Yes" ? 1 : 0}>
                    {elem}
                  </option>
                ))}
              </select>
            )}
            {!isAdmin && (
              <input
                className="wrapper-info"
                readOnly={true}
                value={details?.travel_to_min_charged === "0" ? "No" : "Yes"}
                style={{
                  border: "none",
                  outline: "none",
                  height: "fit-content",
                }}
              />
            )}

            <div className="wrapper-line"></div>
          </div>
        </div>
      </div>
      <div className="statement_table_wrapper">
        <table
          className="common-table-container "
          style={{ marginTop: "20px" }}
          ref={tableRef}
        >
          <thead>
            <tr className="statement_table_head">
              {tableHeadArrServiceType.map((elem, index) => (
                <th key={index}>
                  <span>{elem}</span>
                </th>
              ))}
            </tr>
          </thead>

          <tbody ref={tableRef} className="service_type_body">
            {details?.service_type_rules &&
              details?.service_type_rules.map((elem: any, index: number) => {
                return (
                  <tr
                    style={{ paddingLeft: "30px" }}
                    key={index}
                    className={
                      "normal-table-row normal-class-table-row-clients-table"
                    }
                  >
                    <td>{elem?.billing_rule?.name}</td>

                    <td>
                      <input
                        type="text"
                        readOnly={!isAdmin}
                        value={`${elem?.service_rate}`}
                        name="service_rate"
                        placeholder="/Hour"
                        style={{
                          width: "70%",
                        }}
                        onChange={(e: any) => {
                          onChangeServicesType(e, index, elem);
                        }}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        readOnly={!isAdmin}
                        value={elem?.service_code}
                        name="service_code"
                        placeholder=""
                        onChange={(e: any) => {
                          onChangeServicesType(e, index, elem);
                        }}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        readOnly={!isAdmin}
                        value={`${elem?.km_rate === null ? "" : elem.km_rate}`}
                        name="km_rate"
                        placeholder=""
                        onChange={(e: any) => {
                          onChangeServicesType(e, index, elem);
                        }}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        readOnly={!isAdmin}
                        value={elem?.travel_to_km_code}
                        name="travel_to_km_code"
                        placeholder=""
                        onChange={(e: any) => {
                          onChangeServicesType(e, index, elem);
                        }}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        readOnly={!isAdmin}
                        value={elem?.travel_to_min_code}
                        name="travel_to_min_code"
                        placeholder=""
                        onChange={(e: any) => {
                          onChangeServicesType(e, index, elem);
                        }}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        readOnly={!isAdmin}
                        value={elem?.travel_with_code}
                        name="travel_with_code"
                        placeholder=""
                        onChange={(e: any) => {
                          onChangeServicesType(e, index, elem);
                        }}
                      />
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      {isThereAnyChanges && (
        <>
          <div
            className="save"
            style={{
              position: "absolute",
              marginRight: "200px",
              bottom: "10px",
              zIndex: 9,
            }}
          >
            <p>Would you like to save your changes?</p>
            <div className="btnDivision">
              <button
                onClick={() => {
                  setIsThereAnyChanges(false);
                  handleSaveChanges();
                }}
                style={{
                  backgroundColor: dynamicColor,
                }}
              >
                Save
              </button>
              <button onClick={() => setIsThereAnyChanges(false)}>
                Cancel
              </button>
            </div>
          </div>
        </>
      )}
      <CircularNoteTrigger
        type="stickyHub"
        title="Comms"
        isFinanceScreen={true}
      />
      {stageTwoToGoalsModal && (
        <CommonQuickMenu
          setshowGoalsModal={(value: any) => {}}
          mainType={"listing"}
          setstageTwoToGoalsModal={setstageTwoToGoalsModal}
        />
      )}
    </div>
  );
};

export default ServiceTypeScreen;
